import Text from 'components/core/text';
import { useExpensesContext } from './context';
import { Option } from 'types/general';
import useGetCompaniesOptions from 'services/queries/companies/use-get-company-options';
import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import { currencyToFloat, formatMoney } from 'utils/money';
import Button from 'components/core/button';
import useTheme from '@mui/material/styles/useTheme';
import useGetOptions from 'services/queries/crud/use-get-options';
import useGetClassifications from 'services/queries/classifications/use-get-classifications';
import { generateClassificationQuery } from 'services/queries/classifications/utils';
import { ClassificationType } from 'types/models/classification';
import { generateGetBranchOptionsQuery } from 'settings/routes/branches/utils';
import useCrudExpense, { CreateExpensePayload } from 'services/queries/expenses/use-crud-expense';
import classNames from 'classnames';
import { formatDate } from 'utils/date';
import Spinner from 'components/core/spinner';
import { useOutletContext, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { GraphqlPaginationVariables } from 'types/graphql';
import { booleanOptions, expenseTypes } from 'utils/statics';
import { ExpenseNature } from 'types/models/expense';
import Alert from '@mui/material/Alert';
import { Controller, useForm } from 'react-hook-form';
import Textarea from 'components/core/form/textarea';
import sumBy from 'lodash/sumBy';
import TableFooter from '@mui/material/TableFooter/TableFooter';
import { Steps } from './context/types';
import DownloadButton from 'components/download';
import { getSaleOrderExpensesKey } from 'services/queries/sales-order/keys';
import { getAllKey } from 'services/queries/crud/keys';
import SvgIcoArrowLeft from 'components/core/icon/files/ico-arrow-left';
import SvgIcoArrowRight from 'components/core/icon/files/ico-arrow-right';

type Payload = {
    reasonValueChange?: string;
    reasonValueProvision?: string;
};

const Summary = () => {
    const { palette } = useTheme();
    const queryClient = useQueryClient();
    const { expenseId, saleOrderId } = useParams();
    const params = useOutletContext<GraphqlPaginationVariables<any>>();
    const { state, goToStep, completeStep } = useExpensesContext();

    const isUpdate = !!expenseId;

    const { general, products = [], documents = [], installments = [] } = state?.content || {};

    const { handleSubmit, control, watch } = useForm<Payload>();

    const { data: companies = [] } = useGetCompaniesOptions();
    const { data: branches = [] } = useGetOptions(generateGetBranchOptionsQuery(), ['branch', 'options']);
    const { data: sectors = [] } = useGetClassifications(generateClassificationQuery(ClassificationType.Sector));
    const { data: paymentForms = [] } = useGetClassifications(generateClassificationQuery(ClassificationType.PaymentForm));
    const { data: documentClassifications = [] } = useGetClassifications(generateClassificationQuery(ClassificationType.DocumentTransaction));
    const { data: origins = [] } = useGetClassifications(generateClassificationQuery(ClassificationType.TransactionOrigin, { classificationId: { _eq: general?.category } }));
    const { data: categories = [] } = useGetClassifications(generateClassificationQuery(ClassificationType.TransactionCategory, { classificationId: { _eq: general?.sector } }));
    const { data: subOrigins = [] } = useGetClassifications(generateClassificationQuery(ClassificationType.TransactionSubOrigin, { classificationId: { _eq: general?.origin } }));

    const { mutateAsync: createOrUpdateExpense, isLoading: isSubmitting } = useCrudExpense(expenseId);

    const getOptionLabel = (options: Option<any>[], name: string) => options?.find((item) => item?.value === (general as any)?.[name])?.label;

    const dueDate = formatDate(general?.dueDate, 'DD/MM/YYYY');
    const provision = formatDate(general?.provision, 'DD/MM/YYYY');

    const sector = getOptionLabel(sectors, 'sector');
    const origin = getOptionLabel(origins, 'origin');
    const branch = getOptionLabel(branches, 'branch');
    const company = getOptionLabel(companies, 'company');
    const category = getOptionLabel(categories, 'category');
    const subOrigin = getOptionLabel(subOrigins, 'subOrigin');
    const provisioned = getOptionLabel(booleanOptions, 'provisioned');
    const withProducts = getOptionLabel(booleanOptions, 'withProducts');
    const constructionRelated = getOptionLabel(booleanOptions, 'constructionRelated');

    const isDocumentsVisible = !!documents?.length;
    const isProductsVisible = Boolean(products.length);
    const isInstallmentsVisible = Boolean(installments.length);

    const totalProducts = Number((sumBy(products, 'total') || 0).toFixed(2));
    const totalInstalmments = Number((sumBy(installments, 'value') || 0).toFixed(2));

    const expenseValue = totalProducts > 0 ? totalProducts : currencyToFloat(general?.amount || '0');
    const hasValueDifference = expenseValue !== totalInstalmments;

    const reasonValueChange = watch('reasonValueChange');

    // Using this flag because we have more form validations in the future
    const isFormInvalid = hasValueDifference && !reasonValueChange && isUpdate;

    const handleBack = () => {
        completeStep(Steps.Summary);
        goToStep('previous');
    };

    const submit = async (data: Payload) => {
        try {
            if (isFormInvalid) {
                return;
            }

            const isSaleOrderNature = general?.nature === ExpenseNature.SaleOrder && saleOrderId;

            const generalFields = {
                reasonValueChange: data.reasonValueChange,
                title: general?.title,
                dueDate: formatDate(general?.dueDate, 'YYYY-MM-DD', null),
                provision: formatDate(general?.provision, 'YYYY-MM-DD', null),
                // The expense value is based on product values
                // If the expense has no products, the amount field is used instead
                value: !!general?.withProducts ? totalProducts : general?.amount!,
                withProducts: !!general?.withProducts,
                provisioned: !!general?.provisioned,
                instalments: general?.installments || 1,
                company: general?.company,
                branch: general?.branch,
                formFavored: {
                    [general?.favored?.type || 'person']: general?.favored?.value!,
                    ...(Boolean(general?.favoredBankAccount) && { favoredBankAccount: general?.favoredBankAccount }),
                },
                paymentForm: general?.paymentForm?.value,
                sector: general?.sector,
                category: general?.category,
                classificationOrigin: general?.origin,
                classificationSubOrigin: general?.subOrigin,
                fiscalCode: general?.fiscalCode,
                type: general?.type,
                documentNumber: general?.documentNumber,
                observations: general?.observations,
                ...(Boolean(general?.orderService) && {
                    withOrderService: true,
                    formOrigin: { orderService: general?.orderService },
                }),
                ...(isSaleOrderNature && {
                    formOrigin: { saleOrder: +saleOrderId },
                }),

                transactions: installments?.map((tx, index) => ({
                    id: tx.transactionId,
                    order: index + 1,
                    description: `Parcela ${index + 1}`,
                    dueDate: !!tx.dueDate ? formatDate(tx.dueDate, 'YYYY-MM-DD') : undefined,
                    paymentForecast: !!tx.provisioning ? formatDate(tx.provisioning, 'YYYY-MM-DD') : undefined,
                    grossValue: tx.value,
                    provisioned: tx.provisioned,
                    paymentForm: tx.paymentForm,
                })),
                ...(!isUpdate && {
                    nature: general?.nature || ExpenseNature.Normal,
                    ...(general?.nature === ExpenseNature.Normal && {
                        repeated: general.repeated,
                        ...(Boolean(general.repeated) && {
                            monthsToRepeat: general.monthsToRepeat,
                        }),
                    }),
                }),
            };

            const hasProducts = !!general?.withProducts && Boolean(products?.length);

            const productsPayload = hasProducts
                ? products?.map(({ id, value, quantity, expenseProduct }) => ({
                      id: expenseProduct,
                      product: id,
                      grossValue: value,
                      quantity,
                  }))
                : undefined;

            const [competenceMonth, competenceYear] = formatDate(general?.competence, 'MM/YYYY', null)?.split('/') || [];

            // @ts-ignore
            const payload: CreateExpensePayload = {
                ...generalFields,
                products: productsPayload,
                ...(Boolean(documents.length) && !isUpdate && { documents }),
                ...(Boolean(competenceMonth) &&
                    Boolean(competenceYear) && {
                        competenceMonth: Number(competenceMonth),
                        competenceYear: Number(competenceYear),
                    }),
            };

            await createOrUpdateExpense(payload);

            queryClient.invalidateQueries(getSaleOrderExpensesKey(saleOrderId));
            queryClient.invalidateQueries(getAllKey('expense', params));
        } catch (error) {
            console.log('submit', error);
        }
    };

    return (
        <>
            {isSubmitting && <Spinner fixed={true} />}
            <div className="p-6 pb-0 mb-6">
                <Text as="label" variant="h6" className="text-heading block mb-6">
                    Informações gerais
                </Text>
                <div className="flex flex-col md:grid md:grid-cols-4 gap-4 mb-6">
                    <Text as="p" className="text-base-500">
                        Empresa:{' '}
                        <Text as="span" className="text-heading">
                            {company || '-'}
                        </Text>
                    </Text>
                    <Text as="p" className="text-base-500">
                        Setor:{' '}
                        <Text as="span" className="text-heading">
                            {sector || '-'}
                        </Text>
                    </Text>
                    <Text as="p" className="text-base-500">
                        Categoria:{' '}
                        <Text as="span" className="text-heading">
                            {category || '-'}
                        </Text>
                    </Text>
                    <Text as="p" className="text-base-500">
                        Origem:{' '}
                        <Text as="span" className="text-heading">
                            {origin || '-'}
                        </Text>
                    </Text>
                    <Text as="p" className="text-base-500">
                        Sub-origem:{' '}
                        <Text as="span" className="text-heading">
                            {subOrigin || '-'}
                        </Text>
                    </Text>
                    <Text as="p" className="text-base-500">
                        Filial:{' '}
                        <Text as="span" className="text-heading">
                            {branch || '-'}
                        </Text>
                    </Text>
                    <Text as="p" className="text-base-500">
                        Favorecido:{' '}
                        <Text as="span" className="text-heading">
                            {general?.favored?.label || '-'}
                        </Text>
                    </Text>
                    <Text as="p" className="text-base-500">
                        Forma de Pagamento:{' '}
                        <Text as="span" className="text-heading">
                            {general?.paymentForm?.label || '-'}
                        </Text>
                    </Text>
                    <Text as="p" className="text-base-500">
                        Parcelas:{' '}
                        <Text as="span" className="text-heading">
                            {general?.installments || '-'}
                        </Text>
                    </Text>
                    <Text as="p" className="text-base-500">
                        Com produto/serviços?{' '}
                        <Text as="span" className="text-heading">
                            {withProducts || '-'}
                        </Text>
                    </Text>
                    <Text as="p" className="text-base-500">
                        Documento:{' '}
                        <Text as="span" className="text-heading">
                            {general?.documentNumber || '-'}
                        </Text>
                    </Text>
                    <Text as="p" className="text-base-500">
                        Valor:{' '}
                        <Text as="span" className="text-heading">
                            {formatMoney(totalProducts || Number(general?.amount || 0))}
                        </Text>
                    </Text>
                    <Text as="p" className="text-base-500">
                        Vencimento:{' '}
                        <Text as="span" className="text-heading">
                            {dueDate || '-'}
                        </Text>
                    </Text>
                    {!isUpdate && (
                        <Text as="p" className="text-base-500">
                            Programação:{' '}
                            <Text as="span" className="text-heading">
                                {provision || '-'}
                            </Text>
                        </Text>
                    )}
                    <Text as="p" className="text-base-500">
                        Provisionada:{' '}
                        <Text as="span" className="text-heading">
                            {provisioned || '-'}
                        </Text>
                    </Text>
                    <Text as="p" className="text-base-500">
                        Tipo de despesa:{' '}
                        <Text as="span" className="text-heading">
                            {expenseTypes.find((item) => item.value === general?.type)?.label || '-'}
                        </Text>
                    </Text>
                    <Text as="p" className="text-base-500  col-span-2">
                        Descrição:{' '}
                        <Text as="span" className="text-heading">
                            {general?.title || '-'}
                        </Text>
                    </Text>
                    <Text as="p" className="text-base-500 col-span-2">
                        Observações:{' '}
                        <Text as="span" className="text-heading">
                            {general?.observations || '-'}
                        </Text>
                    </Text>
                    <Text as="p" className="text-base-500 col-span-2">
                        Despesa relacionada à uma OS?{' '}
                        <Text as="span" className="text-heading">
                            {constructionRelated || '-'}
                        </Text>
                    </Text>
                </div>
                {isProductsVisible && (
                    <div className="mb-6 border border-base-300 rounded-[14px]">
                        <Text as="label" variant="h6" className="text-heading block p-4">
                            Produtos
                        </Text>
                        <div className="w-full overflow-x-auto">
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Text className="text-base-500 uppercase">Produto</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text className="text-base-500 uppercase">Unidade</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text className="text-base-500 uppercase">Valor por unidade</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text className="text-base-500 uppercase">Quantidade</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text className="text-base-500 uppercase">Total</Text>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {products?.map((item) => {
                                        return (
                                            <TableRow key={item.id}>
                                                <TableCell>
                                                    <Text className="text-heading">{item.name}</Text>
                                                </TableCell>
                                                <TableCell>
                                                    <Text className="text-heading">{item.classificationByClassificationUnitId.name}</Text>
                                                </TableCell>
                                                <TableCell>
                                                    <Text className="text-heading">{item.value}</Text>
                                                </TableCell>
                                                <TableCell>
                                                    <Text className="text-heading">{item.quantity}</Text>
                                                </TableCell>
                                                <TableCell>
                                                    <Text className="text-heading">{formatMoney(item.total)}</Text>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                                <TableFooter className="bg-base-200">
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell className="rounded-bl-[14px]" colSpan={4}>
                                            <Text className="text-base-700 font-bold">Total:</Text>
                                        </TableCell>
                                        <TableCell className="rounded-br-[14px]">
                                            <Text className="text-base-700 font-bold">{formatMoney(totalProducts)}</Text>
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </div>
                    </div>
                )}
                {isInstallmentsVisible && (
                    <div className="border border-base-300 rounded-[14px] mb-6">
                        <Text as="label" variant="h6" className="text-heading block p-4">
                            Parcelas
                        </Text>
                        <div className="w-full overflow-x-auto">
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Text className="text-base-500 uppercase">Referente a parcela</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text className="text-base-500 uppercase">Vencimento</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text className="text-base-500 uppercase">Provisionamento</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text className="text-base-500 uppercase">Provisionada</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text className="text-base-500 uppercase">Forma de Pagamento</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text className="text-base-500 uppercase">Valor da Parcela</Text>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {installments?.map((item, index) => {
                                        const paymentForm = paymentForms.find(({ value }) => value === item.paymentForm)?.label;

                                        return (
                                            <TableRow key={`installment_${index}`}>
                                                <TableCell>
                                                    <span className="text-heading">Parcela {index + 1}</span>
                                                </TableCell>
                                                <TableCell>
                                                    <Text className="text-heading">{formatDate(item.dueDate, 'DD/MM/YYYY')}</Text>
                                                </TableCell>
                                                <TableCell>
                                                    <Text className="text-heading">{formatDate(item.provisioning, 'DD/MM/YYYY')}</Text>
                                                </TableCell>
                                                <TableCell>
                                                    <Text className={classNames(item.provisioned ? 'text-system-success-600' : 'text-system-danger-600')}>{item.provisioned ? 'Sim' : 'Não'}</Text>
                                                </TableCell>
                                                <TableCell>
                                                    <Text className="text-heading">{paymentForm}</Text>
                                                </TableCell>
                                                <TableCell>
                                                    <Text className="text-heading">{formatMoney(item.value)}</Text>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                                <TableFooter>
                                    <TableRow className="bg-base-200" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell className="rounded-bl-[14px]" colSpan={5}>
                                            <Text className="text-base-700 font-bold">Total:</Text>
                                        </TableCell>
                                        <TableCell className="rounded-br-[14px]">
                                            <Text className="text-base-700 font-bold">{formatMoney(totalInstalmments)}</Text>
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </div>
                    </div>
                )}
                {isDocumentsVisible && (
                    <div className="border border-base-300 rounded-[14px]">
                        <Text as="label" variant="h6" className="text-heading block p-4">
                            Documentos
                        </Text>
                        <div className="w-full overflow-x-auto">
                            <Table className="mb-4">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Text className="text-base-500 uppercase">Nome</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text className="text-base-500 uppercase">Classificação</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text className="text-base-500 uppercase">Arquivo</Text>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {documents?.map(({ name, localFile, ...document }, index) => {
                                        const fileUrl = localFile instanceof File ? URL.createObjectURL(localFile as any) : undefined;

                                        // document.classification can be a number (id) or a classification object
                                        const classificationId = document?.classification || document.classification?.id;

                                        const classification = documentClassifications?.find(({ value }) => value === classificationId)?.label || '-';

                                        return (
                                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={`file_${index}_${localFile?.name}`}>
                                                <TableCell>
                                                    <Text className="text-heading">{name}</Text>
                                                </TableCell>
                                                <TableCell>
                                                    <Text className="text-heading">{classification}</Text>
                                                </TableCell>
                                                <TableCell>
                                                    {Boolean(localFile.id) ? (
                                                        <DownloadButton fileId={localFile.id} fileName={localFile.originalName} />
                                                    ) : (
                                                        <a className="hover:underline" href={fileUrl} rel="noreferrer" target="_blank">
                                                            <Text className="text-secondary-500 font-medium">{localFile?.name || '-'}</Text>
                                                        </a>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                )}
            </div>
            <form onSubmit={handleSubmit(submit)}>
                {hasValueDifference && isUpdate && (
                    <div className="p-6 pt-0">
                        <div className="border border-system-danger-500 rounded-[14px] bg-system-danger-100 bg-opacity-10">
                            <Alert severity="error" className="bg-system-danger-100 bg-opacity-50 rounded-t-lg rounded-b-none">
                                O valor total da despesa não confere com o somatário das ordens de pagamento. Deseja continuar? Caso sim, apresente a justificativa
                            </Alert>
                            <Controller
                                control={control}
                                name="reasonValueChange"
                                render={({ field }) => {
                                    return <Textarea {...field} required={true} placeholder="Digite aqui sua justificativa" className="block rounded-t-none border-none" />;
                                }}
                            />
                        </div>
                    </div>
                )}
                <div className="border-t p-2 flex justify-between">
                    <Button startIcon={<SvgIcoArrowLeft width={18} color={palette.grey[700]} />} type="button" variant="text" color="inherit" className="min-w-[100px]" onClick={handleBack}>
                        Voltar
                    </Button>
                    <Button
                        type="submit"
                        disabled={isSubmitting || isFormInvalid}
                        loading={isSubmitting}
                        endIcon={<SvgIcoArrowRight width={18} color={isSubmitting || isFormInvalid ? palette.grey[400] : palette.secondary[500]} />}
                        variant="text"
                        color={isSubmitting ? 'inherit' : 'secondary'}
                        className="min-w-[100px]">
                        Salvar despesa
                    </Button>
                </div>
            </form>
        </>
    );
};

export default Summary;
