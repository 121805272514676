import { RouteObject } from 'react-router-dom';
import ListPage from 'pages/shared/crud/list';
import { Rule } from 'types/permissions';
import Page from 'pages/shared/page';
import config from './utils';
import CreateOrUpdatePage from 'pages/shared/crud/create-or-update';

const [list, create, update] = config;

const rules: RouteObject[] = [
    {
        path: 'permissoes',
        element: <Page Component={ListPage} code="permissoes" permissions={[Rule.List]} {...list} />,
        children: [
            {
                path: 'novo',
                element: <Page Component={CreateOrUpdatePage} withModal={true} code="permissoes" permissions={[Rule.Create]} {...create} />,
            },
            {
                path: 'editar/:id',
                element: <Page Component={CreateOrUpdatePage} withModal={true} code="permissoes" permissions={[Rule.Update]} {...update} />,
            },
        ],
    },
];

export default rules;
