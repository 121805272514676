import CurrencyInput from 'components/core/form/currency';
import { Controller, useFormContext } from 'react-hook-form';

type FieldsSimpleTaxRegimeProps = {
    invoiceValue?: number;
};

const FieldsSimpleTaxRegime = ({ invoiceValue = 0 }: FieldsSimpleTaxRegimeProps) => {
    const { control, formState, setValue } = useFormContext();
    return (
        <>
            <div>
                <Controller
                    name="dasAliquot"
                    control={control}
                    render={({ field }) => {
                        return (
                            <CurrencyInput
                                name={field?.name}
                                ref={field.ref}
                                value={field?.value}
                                label="DAS Alíquota %"
                                autoComplete="nope"
                                withFormat={false}
                                decimalSeparator=","
                                thousandSeparator="."
                                decimalScale={4}
                                error={(formState.errors.das as any)?.message}
                                onValueChange={({ floatValue = 0 }) => {
                                    field.onChange(floatValue);
                                    setValue('das', (floatValue / 100) * invoiceValue);
                                }}
                            />
                        );
                    }}
                />
            </div>
            <div>
                <Controller
                    name="das"
                    control={control}
                    render={({ field }) => {
                        return (
                            <CurrencyInput
                                disabled={true}
                                name={field?.name}
                                ref={field.ref}
                                value={field?.value}
                                label="DAS (R$)"
                                autoComplete="nope"
                                decimalScale={2}
                                fixedDecimalScale={true}
                                error={(formState.errors.das as any)?.message}
                                onValueChange={({ floatValue }) => field.onChange(floatValue)}
                            />
                        );
                    }}
                />
            </div>
            <div>
                <Controller
                    name="simpleInss"
                    control={control}
                    render={({ field }) => {
                        return (
                            <CurrencyInput
                                name={field?.name}
                                ref={field.ref}
                                value={field?.value}
                                label="INSS (R$)"
                                decimalScale={2}
                                fixedDecimalScale={true}
                                error={(formState.errors.simpleInss as any)?.message}
                                onValueChange={({ floatValue }) => field.onChange(floatValue)}
                            />
                        );
                    }}
                />
            </div>
        </>
    );
};

export default FieldsSimpleTaxRegime;
