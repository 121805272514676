import Table from '@mui/material/Table/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Text from 'components/core/text';
import { ProductsItem } from './index';
import TableBody from '@mui/material/TableBody';
import ProductsRow from './row';

type ProductsTableProps = {
    isOriginSale: boolean;
    selectedProducts: ProductsItem[];
    onRemoveProduct: (index: number) => void;
};

const ProductsTable = ({ selectedProducts, isOriginSale, onRemoveProduct }: ProductsTableProps) => {
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Text className="text-base-500 uppercase whitespace-nowrap">Produto</Text>
                        </TableCell>
                        {isOriginSale && (
                            <TableCell>
                                <Text className="text-base-500 uppercase whitespace-nowrap">Em Estoque</Text>
                            </TableCell>
                        )}
                        <TableCell>
                            <Text className="text-base-500 uppercase whitespace-nowrap">Valor unitário</Text>
                        </TableCell>
                        <TableCell>
                            <Text className="text-base-500 uppercase whitespace-nowrap">Quantidade</Text>
                        </TableCell>
                        <TableCell className="w-40">
                            <Text className="text-base-500 uppercase whitespace-nowrap">Total</Text>
                        </TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {selectedProducts.map((item, index) => {
                        const isRowDisabled = !isOriginSale ? false : !item.quantityInStock && !item.actualQuantity;

                        return <ProductsRow key={item.id} index={index} item={item} isOriginSale={isOriginSale} isRowDisabled={isRowDisabled} onRemoveProduct={onRemoveProduct} />;
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ProductsTable;
