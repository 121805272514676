import classNames from 'classnames';
import { formatDate } from 'utils/date';
import { Link } from 'react-router-dom';
import { Task } from 'types/models/task';
import SvgIcoCalendar from 'components/core/icon/files/ico-calendar';
import SvgIcoAttachment from 'components/core/icon/files/ico-attachment';
import SvgIcoChat from 'components/core/icon/files/ico-chat';

type TaskCardProps = {
    isDragging: boolean;
    task: Task;
};

const TaskCard = ({ task, isDragging }: TaskCardProps) => {
    const container = classNames('bg-white border border-base-300 rounded-2xl px-4 py-4 flex flex-col', isDragging && 'shadow-md');

    const stats = [
        { Icon: SvgIcoAttachment, value: task.numberOfFiles || 0 },
        { Icon: SvgIcoChat, value: task.numberOfComments || 0 },
    ];

    return (
        <Link to={task.id.toString()} className={container}>
            <div className="flex items-center mb-1">
                <div className="flex items-center gap-2 ">
                    <SvgIcoCalendar width={18} height={18} className="text-base-500" />
                    <span className="text-heading font-normal text-sm uppercase">{formatDate(task.startDate, 'MMM DD')}</span>
                </div>
            </div>
            <p className="text-heading text-base font-medium mb-2">{task.title}</p>
            <div className="flex items-center">
                <div className="flex items-center flex-1 gap-5">
                    {stats.map(({ Icon, value }, index) => (
                        <div key={`info_${index}`} className="flex items-center">
                            <Icon width={16} height={16} className="text-base-500 mr-2" />
                            <span className="text-heading font-normal text-sm">{value}</span>
                        </div>
                    ))}
                </div>
            </div>
        </Link>
    );
};

export default TaskCard;
