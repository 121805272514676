import { RouteObject } from 'react-router-dom';
import { Rule } from 'types/permissions';
import Page from 'pages/shared/page';
import config from './utils';
import CreateOrUpdateProductPage from 'pages/private/products/create-or-update';
import ProductsPage from 'pages/private/products';

const [create, update] = config;

const products: RouteObject[] = [
    {
        // @ts-ignore
        breadcrumb: 'Produtos',
        path: 'produtos',
        element: <Page Component={ProductsPage} code="produtos" permissions={[Rule.List]} />,
        children: [
            {
                path: 'novo',
                element: <Page Component={CreateOrUpdateProductPage} code="produtos" permissions={[Rule.Create]} withModal={true} {...create} />,
            },
            {
                path: 'editar/:productId',
                element: <Page Component={CreateOrUpdateProductPage} code="produtos" permissions={[Rule.Update]} withModal={true} {...update} />,
            },
        ],
    },
];

export default products;
