import { Controller, useForm } from 'react-hook-form';
import Input from 'components/core/form/input';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import FormButtons from 'components/form-buttons';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import dictionary from 'utils/dictionary';
import FormOutcome from './outcome';
import FormIncome from './income';
import Button from 'components/core/button';
import qs from 'qs';
import { TransactionType } from 'types/models/transaction';
import { ReportPageBlockFormState } from 'types/models/reports';

type Props = {
    item?: ReportPageBlockFormState;
    type: TransactionType;
    onClose(): void;
    onSubmit: (data: ReportPageBlockFormState) => void;
};

const ReportPageCreateOrUpdateBlockForm = ({ item, onClose, type, onSubmit }: Props) => {
    const isEdit = Boolean(item);

    const methods = useForm<ReportPageBlockFormState>({
        mode: 'onSubmit',
        resolver: yupResolver(schema) as any,
        defaultValues: item,
    });

    const { control, handleSubmit, formState, watch } = methods;

    const FiltersComponent = type === TransactionType.Outcome ? FormOutcome : FormIncome;

    const handleTestFilters = () => {
        const query = qs.stringify(watch('filter') || {}, {
            addQueryPrefix: true,
            encode: false,
            arrayFormat: 'brackets',
            skipNulls: true,
        });

        const path = type === TransactionType.Income ? '/app/ordens-de-recebimento' : '/app/ordens-de-pagamento';

        return window.open(path + query, '_blank');
    };

    return (
        <>
            <Modal contentClassnames="max-w-full w-[800px]" onClose={onClose} closeOnClickOutside={false}>
                <form className="px-4 py-5 md:px-7" onSubmit={handleSubmit(onSubmit)}>
                    <Text as="h3" variant="h4" className="text-heading mb-5">
                        {isEdit ? 'Atualizar' : 'Novo'} centro de custo
                    </Text>

                    <div className="flex flex-col md:grid md:grid-cols-2 gap-4 mb-6">
                        <Controller name="name" control={control} render={({ field }) => <Input {...field} autoFocus={true} error={formState.errors?.name?.message} label="Nome*" />} />
                        <Controller
                            name="color"
                            control={control}
                            render={({ field }) => <Input {...field} error={formState.errors?.color?.message} label="Cor" type="color" className="w-full h-full" />}
                        />
                    </div>

                    <div className="mb-4">
                        <Text as="h5" variant="h6" className="text-heading mb-3">
                            Filtros
                        </Text>

                        <div className="flex flex-col md:grid md:grid-cols-2 gap-4 mb-6">
                            <FiltersComponent formMethods={methods} path="filter" />
                        </div>
                    </div>

                    <div className="flex justify-between items-center gap-2">
                        <FormButtons
                            isLoading={false}
                            cancelButton={{
                                onClick: onClose,
                            }}
                        />
                        <Button onClick={handleTestFilters} type="button">
                            Testar filtro
                        </Button>
                    </div>
                </form>
            </Modal>
        </>
    );
};

const schema = object({
    name: string().typeError(dictionary.validation.required).required(dictionary.validation.required).trim(),
});

export default ReportPageCreateOrUpdateBlockForm;
