import { PropsWithChildren } from 'react';
import useTheme from '@mui/material/styles/useTheme';
import Button from 'components/core/button';
import SvgIcoPlus from 'components/core/icon/files/ico-plus';

type AddButtonProps = {
    className?: string;
    linkComponent?: any;
    to?: string;
    onClick?: () => void;
    disabled?: boolean;
};

const AddButton = ({ className, children, linkComponent, to, onClick, disabled }: PropsWithChildren<AddButtonProps>) => {
    const { palette } = useTheme();

    return (
        <Button
            LinkComponent={linkComponent}
            to={to}
            startIcon={<SvgIcoPlus width={12} height={12} color={palette.primary[500]} />}
            type="button"
            variant="outlined"
            size="small"
            className={`p-2 px-3 border-0 ${className}`}
            sx={{ '&:hover': { border: 'none' } }}
            onClick={onClick}
            disabled={disabled}>
            {children}
        </Button>
    );
};

export default AddButton;
