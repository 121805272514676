import { useMutation } from 'react-query';
import api from 'services/api';
import useToast from 'hooks/toast/use-toast';

export type UseRescheduleTransactionPayload = {
    paymentForecast: string;
    transactions: number[];
};

const useRescheduleTransaction = (isReceipt = false) => {
    const { showToast } = useToast();

    const endpoint = isReceipt ? '/transactions/incomes/reschedule' : '/transactions/outcomes/reschedule';

    const request = async (payload: UseRescheduleTransactionPayload) => api.patch(endpoint, payload);

    return useMutation(request, {
        onSuccess: () => {
            showToast(`${isReceipt ? 'Recebimentos' : 'Pagamentos'} atualizados com sucesso!`, 'success');
        },
        onError: (error: any) => {
            showToast(error?.response?.data?.title ?? `Tivemos um problema ao atualizar os ${isReceipt ? 'recebimentos' : 'pagamentos'}. Tente novamente.`, 'danger');
        },
    });
};

export default useRescheduleTransaction;
