import dictionary from 'utils/dictionary';
import { TestContext, array, number, object, string } from 'yup';

const MIN_VALUES = {
    value: 0.0001,
    message: 'Digite um valor maior que 0.',
};

const quantityBiggerThanBalance = (value = 0, context?: TestContext<any>) => {
    if (!value) {
        return context?.createError({
            message: MIN_VALUES.message,
            type: 'min',
        });
    }

    return value <= (context?.parent?.balance || 0);
};

export const nationalizeSchema = object({
    dueDate: string().required(dictionary.validation.required),
    products: array(
        object({
            tax: number().min(MIN_VALUES.value, MIN_VALUES.message).required(dictionary.validation.required),
            grossValue: number().min(MIN_VALUES.value, MIN_VALUES.message).required(dictionary.validation.required),
            importProdut: number(),
            quantity: number().test('quantityBiggerThanBalance', 'Quantidade maior que o saldo', quantityBiggerThanBalance),
        })
    ).min(1, dictionary.validation.array.min(1)),
});
