import { CrudPageProps } from 'types/graphql';
import { ProductPayload } from 'types/models/product';

const config: CrudPageProps<Partial<ProductPayload>>[] = [
    {
        title: 'Novo produto',
    },
    {
        title: 'Atualizar produto',
    },
];

export default config;
