import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import { getAllKey } from '../crud/keys';
import useToast from 'hooks/toast/use-toast';
import { GraphqlPaginationVariables } from 'types/graphql';
import { Person } from 'types/models/person';
import { CreateOrUpdateBankAccountPayload } from 'types/models/bank-accounts';
import { useShowApiErrors } from 'hooks/error/show-api-errors';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';

const useCreateOrUpdatePersonBankAccount = (personId?: string, bankAccountId?: string, params?: GraphqlPaginationVariables<Person>) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const { showToastErrors } = useShowApiErrors();
    const goToParentRoute = useNavigateToParentRoute();

    const isUpdate = !!bankAccountId;

    const request = async (payload: CreateOrUpdateBankAccountPayload) => {
        const method = isUpdate ? api.patch : api.post;
        const endpoint = isUpdate ? `/people/${personId}/bank-accounts/${bankAccountId}` : `/people/${personId}/bank-accounts`;

        return method<any>(endpoint, payload);
    };

    return useMutation(request, {
        onSuccess: () => {
            queryClient.invalidateQueries(getAllKey('bankAccount', params));

            goToParentRoute();

            showToast(`Conta bancária ${isUpdate ? 'atualizada' : 'criada'} com sucesso`, 'success');
        },
        onError: (error: any) => {
            showToastErrors(error, error?.response?.data?.title);
        },
    });
};

export default useCreateOrUpdatePersonBankAccount;
