import dictionary from 'utils/dictionary';
import { TestContext, array, boolean, number, object, string } from 'yup';
import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';
import { Status } from 'types/general';
import { CrudPageProps, DatatableColumn } from 'types/graphql';
import { ProgressStatus, SaleOrderOrigin, SaleOrderProduct } from 'types/models/sale-order';
import { formatMoney, formatNumberToPTBR } from 'utils/money';
import TableCell from '@mui/material/TableCell/TableCell';
import { formatDate } from 'utils/date';
import Badge from 'components/core/badge';
import ExternalButton from 'components/buttons/external';
import { SaleOrderLiteral } from '../sales-notes/utils';
import { PersonContractStatusTitle } from 'types/models/person';
import { getNumberValueOrZero } from '../expenses/details/tabs/transactions/utils';
import { moreThanSchema, optionSchema } from 'utils/schema';
import { Rule } from 'types/permissions';
import classNames from 'classnames';
import { OrderServiceStatusEnum } from 'types/models/order-service';
import { ColorVariant } from 'types/colors';
import { ProductsItem } from './create-or-update/tabs/products';

export const saleOrderPeopleWhere = {
    personContracts: {
        contractStatusId: { _eq: PersonContractStatusTitle.Active },
    },
};

export const saleOrderGeneralSchema = (origin: SaleOrderOrigin | null) => {
    return object({
        contractDate: string().required(dictionary.validation.required),
        origin: number().required(dictionary.validation.required),
        type: number().required(dictionary.validation.required),
        consultant: optionSchema,
        company: optionSchema,
        classification: optionSchema,
        customer: optionSchema,
        withFreight: number().optional(),
        freightValue: number().when('withFreight', ([withFreight], schema) => (!!withFreight ? schema.required(dictionary.validation.required) : schema.optional())),
        commission: number(),
        branch: optionSchema,
        ...(origin === SaleOrderOrigin.service && {
            contractPeriodStartDate: string().required(dictionary.validation.required),
            contractPeriodEndDate: string().required(dictionary.validation.required),
        }),
    });
};

export const getSaleOrderProductsQuery = (saleOrderId?: string) => {
    return jsonToGraphQLQuery(
        {
            query: {
                __name: 'GetSaleOrderProducts',
                items: {
                    __aliasFor: 'saleOrderProduct',
                    id: true,
                    productId: true,
                    quantity: true,
                    balance: true,
                    netValue: true,
                    grossValue: true,
                    discount: true,
                    addition: true,
                    product: {
                        id: true,
                        code: true,
                        name: true,
                        classificationByClassificationUnitId: { name: true },
                    },
                    __args: {
                        orderBy: {
                            product: {
                                name: new EnumType('ASC'),
                            },
                        },
                        where: {
                            saleOrderId: { _eq: Number(saleOrderId || 0) },
                            status: { _eq: Status.Active },
                        },
                    },
                },
            },
        },
        { pretty: true }
    );
};

export const getSaleOrderProductByPkQuery = (saleOrderProductId?: string) => {
    if (!saleOrderProductId) {
        return;
    }

    return jsonToGraphQLQuery(
        {
            query: {
                __name: 'GetSaleOrderProductByPk',
                saleOrderProductByPk: {
                    id: true,
                    productId: true,
                    netValue: true,
                    addition: true,
                    discount: true,
                    quantity: true,
                    grossValue: true,
                    product: {
                        code: true,
                        name: true,
                        value: true,
                        classificationByClassificationUnitId: { name: true },
                        classification: { name: true },
                        stockProducts: { quantity: true },
                    },
                    __args: { id: Number(saleOrderProductId || 0) },
                },
            },
        },
        { pretty: true }
    );
};

export const saleOrderProductsListConfig = (saleOrderId?: string, origin?: SaleOrderOrigin, isFinancialBalancePage = false): CrudPageProps<Partial<SaleOrderProduct>> => ({
    graphql: {
        table: 'saleOrderProduct',
        searchableField: 'product.name',
        statusPath: 'status',
        columns: [
            {
                name: 'id',
                options: { display: 'excluded' },
            },
            {
                name: 'product.name',
                options: { display: 'excluded' },
            },
            {
                name: 'product.code',
                label: 'Produto',
                options: {
                    customBodyRender: (value, tableMeta) => (
                        <span className="whitespace-nowrap">
                            {value} {tableMeta.rowData[1]}
                        </span>
                    ),
                },
            },
            {
                name: 'netValue',
                options: { display: 'excluded' },
            },
            {
                name: 'costValue',
                options: { display: 'excluded' },
            },
            {
                name: 'product.classificationByClassificationUnitId.name',
                label: 'Valor de custo',
                options: {
                    display: isFinancialBalancePage ? 'true' : 'excluded',
                    customBodyRender: (value, tableMeta) => `${formatMoney(tableMeta.rowData[4] || 0)} (${value})`,
                },
            },
            {
                name: 'product.classificationByClassificationUnitId.name',
                label: 'Valor Unitário',
                options: {
                    customBodyRender: (value, tableMeta) => `${formatMoney(tableMeta.rowData[3] || 0)} (${value})`,
                },
            },
            {
                name: 'quantity',
                label: 'Quantidade original',
                options: {
                    customBodyRender: (value = 0) => formatNumberToPTBR(value),
                },
            },
            {
                name: 'balance',
                label: 'Saldo',
                options: {
                    display: origin === SaleOrderOrigin.service ? 'true' : 'excluded',
                    customBodyRender: (value = 0) => formatNumberToPTBR(value),
                },
            },
            {
                name: '',
                label: 'Total original',
                options: {
                    customBodyRender: (_, tableMeta) => {
                        const netValue = tableMeta.rowData[3] || 0;
                        const quantity = tableMeta.rowData[7] || 0;

                        const total = netValue * quantity;

                        return formatMoney(total);
                    },
                },
            },
            {
                name: '',
                label: 'Saldo atualizado',
                options: {
                    display: origin === SaleOrderOrigin.service ? 'true' : 'excluded',

                    customBodyRender: (_, tableMeta) => {
                        const netValue = tableMeta.rowData[3] || 0;
                        const balance = tableMeta.rowData[8] || 0;

                        return formatMoney(netValue * balance);
                    },
                },
            },
            {
                name: '',
                label: 'Saldo Financeiro',
                options: {
                    display: isFinancialBalancePage ? 'true' : 'excluded',
                    sort: false,
                    customBodyRender: (_, tableMeta) => {
                        const netValue = tableMeta.rowData[3] || 0;
                        const costValue = tableMeta.rowData[4] || 0;
                        const quantity = tableMeta.rowData[7] || 0;
                        const saleValue = netValue * quantity;

                        const financialBalance = (netValue - costValue) * quantity;
                        const percentage = (financialBalance / saleValue) * 100 || 0;

                        return (
                            <div className={classNames('flex justify-between gap-4', percentage >= 0 ? 'text-system-success-500' : 'text-system-danger-500')}>
                                <span>{formatMoney(financialBalance)}</span>
                                <span>{formatNumberToPTBR(percentage, 2)}%</span>
                            </div>
                        );
                    },
                },
            },
        ],
        where: {
            saleOrderId: { _eq: saleOrderId },
        },
    },
    api: {
        useApiTodelete: true,
        endpoint: `/sale-orders/${saleOrderId}/service-orders`,
    },
    title: 'Produtos',
});

const addressSchema = object({
    cityId: number(),
    stateId: number(),
    compliment: string(),
    neighbourhood: string(),
    number: string(),
    street: string(),
    zip: string(),
});

const mandatoryAddressSchema = object({
    cityId: number().required(dictionary.validation.required),
    stateId: number().required(dictionary.validation.required),
    compliment: string(),
    neighbourhood: string().required(dictionary.validation.required),
    number: string().required(dictionary.validation.required),
    street: string().required(dictionary.validation.required),
    zip: string().required(dictionary.validation.required),
});

export const serviceOrderGeneralSchema = (hasCustomerAddress = false) =>
    object({
        title: string().required(dictionary.validation.required),
        competenceDate: string().required(dictionary.validation.required),
        startDate: string().required(dictionary.validation.required),
        endDate: string().required(dictionary.validation.required),
        observations: string(),
        engineer: object({
            label: string().required(dictionary.validation.required),
            value: number().required(dictionary.validation.required),
        }),
        contractor: object({
            label: string().required(dictionary.validation.required),
            value: number().required(dictionary.validation.required),
        }),
        customerAddress: boolean().required(dictionary.validation.required).default(true),
        address: hasCustomerAddress ? addressSchema : mandatoryAddressSchema,
    });

export const serviceOrderExpensesAdministrativeListColumns: DatatableColumn[] = [
    {
        name: 'id',
        options: { display: 'excluded' },
    },
    {
        name: 'code',
        label: 'Código',
        options: { customBodyRender: (code, { rowData }) => (!!code ? <ExternalButton to={`/app/despesas/${rowData[0]}`}>{code}</ExternalButton> : '-') },
    },
    {
        name: 'title',
        label: 'Descrição',
    },
    {
        name: 'value',
        label: 'Valor',
        options: { customBodyRender: (value) => formatMoney(Number(value)) },
    },
];

export const serviceOrderExtraPurchaseScopeListColumns: DatatableColumn[] = [
    {
        name: 'id',
        options: { display: 'excluded' },
    },
    {
        name: 'product.name',
        label: 'Produto',
        options: {
            customBodyRender: (value, tableMeta) => {
                const productCode = tableMeta.rowData.at(-1);

                return <span className="whitespace-nowrap">{Boolean(value) && Boolean(productCode) ? `#${productCode} - ${value}` : '-'}</span>;
            },
        },
    },
    {
        name: 'purchaseOrderProduct.purchaseOrder.code',
        label: 'Ordem de Compra',
    },
    {
        name: 'expense.code',
        label: 'Despesa',
    },
    {
        name: 'quantity',
        label: 'Quantidade',
        options: {
            customBodyRender: (value = 0) => formatNumberToPTBR(value),
        },
    },
    {
        name: 'netValue',
        label: 'Valor',
        options: { customBodyRender: (value) => formatMoney(value) },
    },
    {
        name: 'quantity',
        label: 'Total',
        options: {
            customBodyRender: (quantity: number = 0, tableMeta: any) => {
                const value = tableMeta.rowData[5] || 0;

                return formatMoney(value * quantity);
            },
        },
    },
    {
        name: 'product.code',
        options: {
            display: 'excluded',
        },
    },
];

export const serviceOrderRevenuesListColumns: DatatableColumn[] = [
    {
        name: 'id',
        options: { display: 'excluded' },
    },
    {
        name: 'code',
        label: 'Código',
    },
    {
        name: 'description',
        label: 'Parcela',
    },
    {
        name: 'dueDate',
        label: 'Vencimento',
        options: { customBodyRender: (value) => formatDate(value) },
    },
    {
        name: 'paymentForm.name',
        label: 'Forma de pagamento',
    },
    {
        name: 'paid',
        label: 'Pago',
        options: {
            customBodyRender: (value) => {
                return (
                    <Badge className="text-center" variant={value ? 'success' : 'error'}>
                        {value ? 'Pago' : 'Não pago'}
                    </Badge>
                );
            },
        },
    },
    {
        name: 'netValue',
        label: 'Total',
        options: { customBodyRender: (value) => formatMoney(value) },
    },
    {
        name: 'actions',
        label: 'Ações',
        options: {
            customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />,
        },
    },
];

export const serviceOrderSaleScopeListConfig = (orderServiceId?: string, isOrderServiceReceiptsPage?: boolean): CrudPageProps<{}> => ({
    graphql: {
        table: 'orderServiceProductSale',
        searchableField: 'product.name',
        statusPath: 'status',
        columns: [
            {
                name: 'id',
                options: { display: 'excluded' },
            },

            {
                name: 'product.name',
                label: 'Nome',
            },
            ...(!isOrderServiceReceiptsPage
                ? [
                      {
                          name: 'product.classificationByClassificationUnitId.name',
                          label: 'Unidade',
                      },
                  ]
                : []),

            {
                name: 'quantity',
                label: 'Quantidade',
                options: {
                    customBodyRender: (value = 0) => formatNumberToPTBR(value),
                },
            },
            ...(isOrderServiceReceiptsPage
                ? [
                      {
                          name: 'value',
                          label: 'Valor Unitário',
                          options: {
                              customBodyRender: (value = 0) => formatMoney(value),
                          },
                      },
                      {
                          name: '',
                          label: 'Total',
                          options: {
                              customBodyRender: (_, tableMeta) => {
                                  const value = tableMeta.rowData[3] || 0;
                                  const quantity = tableMeta.rowData[2] || 0;

                                  return formatMoney(getNumberValueOrZero(value * quantity));
                              },
                          },
                      },
                  ]
                : []),
        ],
        where: {
            orderServiceId: { _eq: orderServiceId },
        },
    },
    hideHeader: true,
});

export const getServiceSalesNotesConfig = (navigate: (path: string) => void, orderServiceId?: number, saleOrderId?: number): CrudPageProps<any> => {
    return {
        graphql: {
            table: 'salesReceipt',
            searchableField: 'number',
            columns: [
                {
                    name: 'id',
                    options: { display: 'excluded' },
                },
                {
                    name: 'salesReceiptOrders.orderService.title',
                    label: 'Ordem de serviço',
                    options: {
                        ...(Boolean(orderServiceId) && { display: 'excluded' }),
                        customBodyRender: (_, tableMeta) => {
                            const orderServiceTitle = tableMeta.rowData.at(-1)?.props?.item?.salesReceiptOrders?.[0]?.orderService?.title;

                            const orderServiceId = tableMeta.rowData.at(-1)?.props?.item?.salesReceiptOrders?.[0]?.orderServiceId;

                            if (!orderServiceId) {
                                return '-';
                            }

                            return <ExternalButton onClick={() => navigate(`../ordens-de-servico/${orderServiceId}`)}>{orderServiceTitle}</ExternalButton>;
                        },
                    },
                },
                {
                    name: 'number',
                    label: 'Número Documento',
                    options: {
                        customBodyRender: (value, tableMeta) => {
                            const saleNoteId = tableMeta.rowData[0];

                            if (!saleNoteId) {
                                return '-';
                            }
                            return <ExternalButton to={`/app/notas-de-venda/${saleNoteId}`}>{value}</ExternalButton>;
                        },
                    },
                },
                {
                    label: 'Data de Emissão',
                    name: 'emission',
                    options: { customBodyRender: (value) => formatDate(value, 'DD/MM/YYYY') },
                },
                {
                    label: 'Tipo',
                    name: 'nature',
                    options: { customBodyRender: (value) => SaleOrderLiteral[value] || '-' },
                },
                {
                    label: 'Valor NF',
                    name: 'invoiceValue',
                    options: { customBodyRender: (value) => formatMoney(value || 0) },
                },
                {
                    label: 'Dedução',
                    name: 'deductionValue',
                    options: { customBodyRender: (value) => formatMoney(value || 0) },
                },
                {
                    name: 'salesReceiptOrders.orderServiceId',
                    options: {
                        display: 'excluded',
                    },
                },
                {
                    name: 'actions',
                    options: {
                        display: 'excluded',
                        customHeadRender: () => <div key="actions" style={{ width: 80 }} />,
                    },
                },
            ],
            where: {
                salesReceiptOrders: {
                    status: { _eq: Status.Active },
                    saleOrderId: { _eq: saleOrderId },
                    ...(Boolean(orderServiceId) && { orderServiceId: { _eq: orderServiceId } }),
                },
                status: { _eq: Status.Active },
            },
            orderBy: {
                number: 'ASC',
            },
        },
        customTableOptions: {
            selectableRows: 'none',
        },
        hideHeader: true,
    };
};

export const orderServicesBadgesMap = {
    [OrderServiceStatusEnum.AwaitingPurchaseProducts]: {
        variant: 'warning',
        bgOpacity: 0.1,
    },
    [OrderServiceStatusEnum.AwaitingRelease]: {
        variant: 'warning',
        bgOpacity: 0.1,
    },
    [OrderServiceStatusEnum.InProductValidation]: {
        variant: 'warning',
        bgOpacity: 0.1,
    },
    [OrderServiceStatusEnum.AwaitingProductApproval]: {
        variant: 'warning',
        bgOpacity: 0.1,
    },
    [OrderServiceStatusEnum.NotStarted]: {
        variant: 'grey',
        bgOpacity: 0.3,
    },
    [OrderServiceStatusEnum.Started]: {
        variant: 'info',
        bgOpacity: 0.3,
    },
    [OrderServiceStatusEnum.WaitingForCompletion]: {
        variant: 'warning',
        bgOpacity: 0.3,
    },
    [OrderServiceStatusEnum.Finished]: {
        variant: 'success',
        bgOpacity: 0.3,
    },
    [OrderServiceStatusEnum.Canceled]: {
        variant: 'error',
        bgOpacity: 0.3,
    },
    [OrderServiceStatusEnum.Stopped]: {
        variant: 'error',
        bgOpacity: 0.1,
    },
};

export const orderServicesDatatableColumns = (saleOrderId?: string): any[] => [
    {
        name: 'id',
        options: { display: 'excluded' },
    },
    {
        label: '',
        name: 'orderServiceStatus.slug',
        options: {
            display: 'excluded',
        },
    },
    {
        label: 'Código',
        name: 'code',
        options: { customBodyRender: (code) => <span className="whitespace-nowrap">{code || '-'}</span> },
    },
    {
        label: 'Nome',
        name: 'title',
        options: { customBodyRender: (title) => <span className="whitespace-nowrap">{title || '-'}</span> },
    },
    {
        name: 'saleOrder.id',
        options: { display: 'excluded' },
    },
    ...(!saleOrderId
        ? [
              {
                  label: 'Pedido de venda',
                  name: 'saleOrder.code',
                  options: {
                      customBodyRender: (value, tableMeta) => {
                          return <ExternalButton to={`/app/pedidos-de-venda/${tableMeta.rowData[4]}`}>{value}</ExternalButton>;
                      },
                  },
              },
          ]
        : []),
    {
        label: 'Data da OS',
        name: 'competenceDate',
        options: {
            customBodyRender: (value) => formatDate(value),
        },
    },
    {
        label: 'Valor',
        name: 'value',
        options: {
            customBodyRender: (value) => formatMoney(value, '-'),
        },
    },
    {
        label: 'Empresa',
        name: 'saleOrder.company.name',
        options: { customBodyRender: (companyName = '-') => <span className="whitespace-nowrap">{companyName}</span> },
    },
    {
        label: 'Filial',
        name: 'saleOrder.branch.name',
        options: { customBodyRender: (branchName) => <span className="whitespace-nowrap">{branchName || '-'}</span> },
    },
    {
        label: 'Dias no Status',
        name: 'daysInCurrentStatus',
        options: {
            customBodyRender: (daysInCurrentStatus, tableMeta) => {
                const finalizedIndex = !saleOrderId ? 12 : 11;
                const finalized = tableMeta.rowData[finalizedIndex];

                return !Boolean(finalized) ? <Badge variant="grey"> {daysInCurrentStatus}</Badge> : '-';
            },
        },
    },
    {
        label: 'Status',
        name: 'orderServiceStatus.title',
        options: {
            customBodyRender: (orderServiceStatus, tableMeta) => {
                const [, orderServiceStatusSlug] = tableMeta.rowData;
                const { variant = 'grey', bgOpacity = 0.3 } = orderServicesBadgesMap[orderServiceStatusSlug] || {};

                return Boolean(orderServiceStatus) ? (
                    <Badge variant={variant} bgOpacity={bgOpacity}>
                        {orderServiceStatus}
                    </Badge>
                ) : (
                    '-'
                );
            },
        },
    },
    {
        name: 'finalized',
        options: { display: 'excluded' },
    },
    {
        name: 'orderServiceProductSales.quantity',
        options: { display: 'excluded' },
    },
    {
        name: 'orderServiceProductSales.status',
        options: { display: 'excluded' },
    },
    {
        name: 'actions',
        label: 'Ações',
        customRoutePath: (item, rule) => {
            if (rule === Rule.Update) {
                return `/app/pedidos-de-venda/${item.saleOrder?.id}/ordens-de-servico/editar/${item.id}`;
            }

            return `/app/pedidos-de-venda/${item.saleOrder?.id}/ordens-de-servico/${item.id}`;
        },
        options: {
            customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />,
        },
    },
];
export const formatPurchaseOrderProductsResponse = (data) => {
    return data.map((item) => ({
        ...item,
        value: item.id,
        label: `${item.code} ${item.name}`,
        productValue: item.value,
    }));
};

export const purchaseOrderProductsSchema = object({
    purchaseProducts: array(
        object({
            product: number().required(dictionary.validation.required),
            productValue: moreThanSchema(),
            quantity: moreThanSchema(),
            provision: string().required(dictionary.validation.required),
        })
    ).min(1, dictionary.validation.array.min(1)),
});

export const DEFAULT_PRODUCT = {
    quantity: 0,
    discount: 0,
    addition: 0,
    netValue: 0,
    grossValue: 0,
};

const quantityLowerThanBalance = (origin: number) => {
    return (quantity = 0, context?: TestContext<any>) => {
        if (origin !== SaleOrderOrigin.sale) {
            return true;
        }

        const actualQuantity = context?.parent?.actualQuantity || 0;
        const balance = context?.parent?.quantityInStock || 0;

        if (Boolean(actualQuantity)) {
            const maximumQuantityValue = actualQuantity + balance;

            return quantity <= maximumQuantityValue;
        }

        return quantity <= balance;
    };
};

const saleOriginProductsValidation = (origin: number) => {
    return (value: Partial<Pick<ProductsItem, 'productId' | 'quantity' | 'netValue' | 'quantityInStock'>>[] = []) => {
        if (origin === SaleOrderOrigin.sale) {
            return value.every((item) => !!item.quantityInStock);
        }

        return true;
    };
};

export const saleOrderProductsSchema = (origin: number) => {
    return object({
        products: array(
            object({
                productId: number().required(dictionary.validation.required),
                quantity: number().test('quantityBiggerThanBalance', 'Quantidade maior que o estoque', quantityLowerThanBalance(origin)),
                netValue: number().required(dictionary.validation.required),
                quantityInStock: number(),
            })
        )
            .min(1, dictionary.validation.array.min(1))
            .test('saleOriginProductsWithoutStocks', 'É preciso que todos os produtos escolhidos tenham estoque.', saleOriginProductsValidation(origin)),
    });
};

export const formatSaleOrderProductsResponse = (data) => {
    return data.map((item) => ({
        ...item,
        value: item.id,
        label: `${item.code} ${item.name}`,
        netValue: 0,
    }));
};

export const getPurchaseOrderProductsQuery = (where, branchId) => {
    return jsonToGraphQLQuery(
        {
            query: {
                __name: 'GetProducts',
                product: {
                    __args: {
                        orderBy: { name: new EnumType('ASC') },
                        where: {
                            status: { _eq: Status.Active },
                            ...where,
                        },
                    },
                    id: true,
                    name: true,
                    code: true,
                    classification: { name: true },
                    classificationByClassificationUnitId: { name: true },
                    stockProducts: {
                        __args: {
                            where: {
                                stock: {
                                    branchId: { _eq: branchId },
                                    main: { _eq: true },
                                },
                            },
                        },
                        id: true,
                        quantity: true,
                    },
                },
            },
        },
        { pretty: true }
    );
};

export const optionsParser = (value?: number, label = '-') => {
    if (!value) {
        return null;
    }

    return { value, label };
};

export const generateProductListColSpan = (origin?: SaleOrderOrigin) => {
    const isOrderServiceOrigin = origin === SaleOrderOrigin.service;

    return isOrderServiceOrigin ? 4 : 3;
};

export const taskTopColors = new Map<ProgressStatus['slug'], { background: string; variant: ColorVariant }>([
    ['pending', { background: 'bg-system-warning-300', variant: 'warning' }],
    ['stopped', { background: 'bg-system-danger-300', variant: 'error' }],
    ['done', { background: 'bg-system-success-300', variant: 'success' }],
    ['partial', { background: 'bg-system-info-300', variant: 'info' }],
]);
