import * as React from 'react';
const SvgIcoBell = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 16" width="1em" height="1em" {...props}>
        <path
            fill="currentColor"
            d="M13.25 11.322c-.56-.65-1.61-1.625-1.61-4.822 0-2.428-1.58-4.372-3.712-4.849V1c0-.552-.416-1-.928-1s-.928.448-.928 1v.651C3.94 2.128 2.359 4.071 2.359 6.5c0 3.197-1.049 4.173-1.61 4.822A1.019 1.019 0 0 0 .5 12c.003.512.377 1 .931 1h11.137c.555 0 .93-.488.932-1a1.018 1.018 0 0 0-.25-.678ZM2.46 11.5c.615-.874 1.289-2.323 1.292-4.982L3.75 6.5C3.75 4.567 5.205 3 7 3s3.25 1.567 3.25 3.5l-.002.018c.003 2.66.677 4.108 1.292 4.982H2.46ZM7 16c1.025 0 1.856-.895 1.856-2H5.144c0 1.105.831 2 1.856 2Z"
        />
    </svg>
);
export default SvgIcoBell;
