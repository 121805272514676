import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import Empty from 'components/empty';
import { SalesReceiptResponse } from 'types/models/sales-notes';

type AccordionTableProps = {
    accordion: {
        title: string;
        content: {
            headTable: string[];
            bodyTable?: any[] | SalesReceiptResponse[];
        };
    };
};

const AccordionTable = ({ accordion }: AccordionTableProps) => {
    return (
        <div className="overflow-x-auto">
            <Table>
                <TableHead>
                    <TableRow>
                        {accordion.content.headTable.map((head, index) => (
                            <TableCell className="text-secondary-500" key={index}>
                                {head}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!!accordion.content.bodyTable?.length ? (
                        accordion.content.bodyTable?.map((contentRow, rowIndex) => (
                            <TableRow key={rowIndex} sx={{ '&:last-child td': { border: 0 } }}>
                                {contentRow?.map((content, index) => (
                                    <TableCell key={index}>{content}</TableCell>
                                ))}
                            </TableRow>
                        ))
                    ) : (
                        <TableRow sx={{ '&:last-child td': { border: 0 } }}>
                            <TableCell colSpan={4}>
                                <Empty title="Nenhum registro encontrado" />
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </div>
    );
};

export default AccordionTable;
