import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { useOutletContext, useParams } from 'react-router-dom';
import useGetTransactions from 'services/queries/transactions/use-get-transactions';
import { CrudPageProps } from 'types/graphql';
import { memo, useCallback } from 'react';
import { formatDate } from 'utils/date';
import Table from '@mui/material/Table/Table';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableBody from '@mui/material/TableBody/TableBody';
import DeductionItem, { OP } from './deduction-item';
import DeductionFilters, { DeductionFiltersPayload } from './filters';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import useCreateOrUpdateDeduction from 'services/queries/order-services/use-create-or-update-deduction';
import FormButtons from 'components/form-buttons';
import { useQueryClient } from 'react-query';
import { getOrderServiceKey } from 'services/queries/order-services/keys';
import { OrderService } from 'types/models/order-service';

export type DeductionPayload = {
    ops: Partial<OP>[];
};

const CreateOrUpdateDeductionsPage = ({ title }: CrudPageProps<{}>) => {
    const queryClient = useQueryClient();
    const getAllParams = useOutletContext();
    const { orderServiceId, saleOrderId } = useParams();

    const orderService = queryClient.getQueryData<Partial<OrderService>>(getOrderServiceKey(orderServiceId));

    const { mutateAsync: getTransactions, isLoading: isLoadingTransactions } = useGetTransactions();
    const { mutateAsync: createOrUpdateDeduction, isLoading: isSubmitting } = useCreateOrUpdateDeduction(saleOrderId, orderServiceId, getAllParams);

    const methods = useForm<DeductionPayload>({
        defaultValues: { ops: [] },
    });

    const { control } = methods;

    const { fields: ops, replace } = useFieldArray({ control, name: 'ops' });

    const handleFilter = useCallback(
        async (data: DeductionFiltersPayload) => {
            try {
                const filters = {
                    ...(Boolean(data.code) && {
                        code: { _ilike: `%${data.code}%` },
                    }),
                    ...((Boolean(data.dueDateStartDate) || Boolean(data.dueDateEndDate)) && {
                        dueDate: {
                            ...(Boolean(data.dueDateStartDate) && {
                                _gte: formatDate(data.dueDateStartDate, 'YYYY-MM-DD'),
                            }),
                            ...(Boolean(data.dueDateEndDate) && {
                                _lte: formatDate(data.dueDateEndDate, 'YYYY-MM-DD'),
                            }),
                        },
                    }),
                    ...(Boolean(data.expense?.companyId) && {
                        expense: {
                            companyId: { _eq: data.expense?.companyId },
                        },
                    }),
                    ...(Boolean(data.invoiceStatus) && {
                        invoiceStatus: { _eq: data.invoiceStatus },
                    }),
                };

                const items = await getTransactions(filters);

                const formattedItems = items.map((item) => ({
                    ...item,
                    checked: false,
                    netValue: orderService?.receipt?.netValue || '0',
                }));

                replace(formattedItems as any);
            } catch (error) {
                console.log('handleFilter', error);
            }
        },
        [getTransactions, replace, orderService?.receipt?.netValue]
    );

    const submit = async (data: DeductionPayload) => {
        try {
            const filteredOps = data.ops.filter((item) => item.checked);

            if (!filteredOps.length) {
                return alert('Nenhuma OP selecionada');
            }

            const payload = filteredOps.map((item) => ({
                transaction: item.id!,
                value: item.netValue!,
            }));

            await createOrUpdateDeduction({ deductions: payload });
        } catch (error) {
            console.log('submit', error);
        }
    };

    return (
        <Modal
            contentClassnames="w-[90%]"
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    {title}
                </Text>
            }
            closeOnClickOutside={false}>
            <div className="p-6 pt-0">
                <DeductionFilters isLoadingTransactions={isLoadingTransactions} onFilter={handleFilter} />
                {Boolean(ops.length) && (
                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(submit)}>
                            <div className="border border-base-300 rounded-[14px] mt-4 overflow-hidden">
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="border-r border-r-base-300" />
                                            <TableCell className="border-r border-r-base-300">
                                                <Text className="text-secondary-500">Código</Text>
                                            </TableCell>
                                            <TableCell className="border-r border-r-base-300">
                                                <Text className="text-secondary-500">Empresa</Text>
                                            </TableCell>
                                            <TableCell className="border-r border-r-base-300">
                                                <Text className="text-secondary-500">Valor</Text>
                                            </TableCell>
                                            <TableCell className="border-r border-r-base-300">
                                                <Text className="text-secondary-500">A deduzir</Text>
                                            </TableCell>
                                            <TableCell className="border-r border-r-base-300">
                                                <Text className="text-secondary-500">Forma de pagamento</Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text className="text-secondary-500">Nota fiscal</Text>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {ops.map((item, index) => {
                                            return <DeductionItem index={index} item={item} key={item.id} />;
                                        })}
                                    </TableBody>
                                </Table>
                            </div>
                            <FormButtons isLoading={isSubmitting} containerClassName="mt-4" />
                        </form>
                    </FormProvider>
                )}
            </div>
        </Modal>
    );
};

export default memo(CreateOrUpdateDeductionsPage);
