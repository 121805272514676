import TableCell from '@mui/material/TableCell/TableCell';
import { jsonToGraphQLQuery, VariableType } from 'json-to-graphql-query';
import { CreateOrUpdateProps } from 'pages/shared/crud/create-or-update';
import { CrudPageProps } from 'types/graphql';
import dictionary from 'utils/dictionary';
import { object, string } from 'yup';
import { EnumType } from 'json-to-graphql-query';
import { Status } from 'types/general';
import { pkColumnsKey } from 'utils/environment';

const schema = object({
    name: string().required(dictionary.validation.required),
});

const form: CreateOrUpdateProps<{ name: string }> = {
    modalClassName: 'w-[600px]',
    formClassName: '!grid-cols-1',
    fields: [
        {
            name: 'name',
            type: 'text',
        },
    ],
    schema,
};

const createOrUpdateBranchMutation = (isEdit = false) => {
    return jsonToGraphQLQuery(
        {
            mutation: {
                __name: 'CreateOrUpdateBranch',
                __variables: {
                    ...(isEdit
                        ? {
                              payload: 'BranchSetInput',
                              id: 'Int!',
                          }
                        : {
                              payload: 'BranchInsertInput!',
                          }),
                },
                item: {
                    __aliasFor: isEdit ? 'updateBranchByPk' : 'insertBranchOne',
                    __args: {
                        ...(isEdit
                            ? {
                                  [pkColumnsKey]: { id: new VariableType('id') },
                                  _set: new VariableType('payload'),
                              }
                            : {
                                  object: new VariableType('payload'),
                              }),
                    },
                    id: true,
                },
            },
        },
        { pretty: true }
    );
};

const config: CrudPageProps<{ name: string }>[] = [
    {
        title: 'Filiais',
        graphql: {
            table: 'branch',
            searchableField: 'name',
            columns: [
                {
                    name: 'id',
                    options: { display: 'excluded' },
                },
                {
                    name: 'name',
                    label: dictionary.content.graphql['name'],
                },
                {
                    name: 'actions',
                    label: 'Ações',
                    options: {
                        customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />,
                    },
                },
            ],
        },
    },
    {
        title: 'Nova filial',
        graphql: {
            table: 'branch',
            query: createOrUpdateBranchMutation(),
        },
        defaultValues: { name: '' },
        form,
    },
    {
        title: 'Atualizar filial',
        graphql: {
            table: 'branch',
            query: createOrUpdateBranchMutation(true),
        },
        defaultValues: { name: '' },
        form,
    },
];

export const generateGetBranchOptionsQuery = () => {
    return jsonToGraphQLQuery({
        query: {
            __name: 'GetBranchOptions',
            items: {
                __aliasFor: 'branch',
                __args: {
                    orderBy: { name: new EnumType('ASC') },
                    where: {
                        status: { _eq: Status.Active },
                    },
                },
                value: { __aliasFor: 'id' },
                label: { __aliasFor: 'name' },
            },
        },
    });
};

export default config;
