import useTheme from '@mui/material/styles/useTheme';
import Badge from 'components/core/badge';
import Modal from 'components/core/modal';
import Spinner from 'components/core/spinner';
import SimpleTable, { SimpleTableContent } from 'components/core/table/simple';
import Text from 'components/core/text';
import { useParams } from 'react-router-dom';
import useGetStockProduct from 'services/queries/stocks/use-get-stock-product';
import { formatDate } from 'utils/date';
import { formatMoney, formatNumberToPTBR } from 'utils/money';
import { generateInventoryMovementColors, generateStockOriginButton } from './utils';
import Empty from 'components/empty';
import StockProductHeader from './stock-product-header';
import Divider from '@mui/material/Divider';
import { stockOrderTypes } from 'utils/statics';

const StockProductPage = () => {
    const { palette } = useTheme();
    const { stockProductId } = useParams();

    const { data: stockProduct, isLoading: isLoadingProduct } = useGetStockProduct(stockProductId);

    const movements = stockProduct?.inventoryMovements?.map<SimpleTableContent>((item) => {
        const { text, variant, textClassName } = generateInventoryMovementColors(item.type, item.reversal);

        return {
            id: item.id || 0,
            contents: [
                {
                    label: <Badge variant={variant}>{text}</Badge>,
                },
                {
                    label: item.stockOrder?.code || '-',
                },
                {
                    label: stockOrderTypes.find((option) => option.value === item.stockOrder?.type)?.label,
                },
                {
                    label: generateStockOriginButton(item.stockOrder),
                },
                {
                    label: formatDate(item.createdAt, 'DD/MM/YYYY HH:mm'),
                },
                { label: `${formatNumberToPTBR(item.quantity || 0)} (${stockProduct?.product?.classificationByClassificationUnitId?.name})` },
                {
                    label: formatMoney(item.value),
                    textClassName: textClassName,
                },
                {
                    label: formatMoney((item.value || 0) * (item.quantity || 0)),
                },
            ],
        };
    });

    return (
        <Modal
            headerLeft={
                <Text as="h3" variant="h5" className="text-heading">
                    {stockProduct?.product?.code} - {stockProduct?.product?.name}
                </Text>
            }
            contentClassnames="w-[1100px]">
            {isLoadingProduct ? (
                <div className="p-4">
                    <Spinner color={palette.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <>
                    <Divider />
                    <StockProductHeader
                        containerClassName="mb-4"
                        value={stockProduct?.value}
                        quantity={stockProduct?.quantity}
                        unitName={stockProduct?.product?.classificationByClassificationUnitId?.name}
                    />
                    <div className="px-4 mb-6">
                        <div className="border border-base-300 rounded-[14px] pt-4 flex-1">
                            <Text variant="body.medium.2xs" className="text-heading px-4 mb-6 block">
                                Movimentações
                            </Text>
                            {Boolean(movements?.length) ? (
                                <SimpleTable columns={['Tipo de Movimentação', 'Remessa', 'Tipo de Remessa', 'Origem', 'Data', 'Quantidade', 'Valor Unitário', 'Total']} items={movements!} />
                            ) : (
                                <Empty className="p-4" title="Nenhum resultado encontrado." />
                            )}
                        </div>
                    </div>
                </>
            )}
        </Modal>
    );
};

export default StockProductPage;

// TODO:
// adicionar o stock product
