import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import Text from 'components/core/text';
import { formatMoney } from 'utils/money';
import { Controller, useFormContext } from 'react-hook-form';
import { IndustrializationPayload } from './index';
import { Option } from 'types/general';
import { Product } from 'types/models/product';
import useGetDebouncedProducts from 'services/queries/products/use-get-debounced-products';
import Select from 'components/core/form/select';
import selectStyles from 'components/core/form/select/styles';
import useTheme from '@mui/material/styles/useTheme';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import CurrencyInput from 'components/core/form/currency';
import SvgIcoTrash from 'components/core/icon/files/ico-trash';

type IndustrializationItemProps = {
    item: IndustrializationPayload['movements'][0];
    index: number;
    onRemoveProduct: () => void;
};

const customRowStyles = {
    '> td': { paddingTop: 0, paddingBottom: 0 },
    '> td:not(:last-child)': { borderRight: '1px solid #e8e9eb' },
    '&:last-child td': { borderBottom: 0 },
    '&:last-child td:last-child > div': { borderBottomRightRadius: '14px' },
};

const transformer = (data: Partial<Pick<Product, 'id' | 'name' | 'code'>>[]) => data.map<Option>((item) => ({ value: item.id!, label: `${item.code} - ${item.name}`! }));

const fieldsMap = {
    quantitySelected: 'QTD',
    product: 'Produto a ser transformado',
    quantityGenerated: 'QTD Gerada',
};

const IndustrializationItem = ({ item, index, onRemoveProduct }: IndustrializationItemProps) => {
    const { palette } = useTheme();
    const { control, formState, watch } = useFormContext<IndustrializationPayload>();

    const { isLoadingProducts, products, searchTerm, setSearchTerm } = useGetDebouncedProducts<Pick<Product, 'id' | 'name' | 'code'>, Option[]>(['id', 'name', 'code'], transformer);

    const getPath = (field: keyof IndustrializationPayload['movements'][0]) => `movements.${index}.${field}` as const;

    const [quantitySelected, quantityGenerated] = watch([getPath('quantitySelected'), getPath('quantityGenerated')]);

    const totalValue = !!quantityGenerated ? (Number(quantitySelected || 0) * item.productValue) / Number(quantityGenerated || 0) : 0;

    const isDisabled = item.quantityInStock <= 0;

    const errors = formState.errors.movements?.[index] || {};
    const hasErrors = !isEmpty(errors);
    const errorsToArray = Object.entries(errors as any) || [];

    const getClasses = (field: keyof IndustrializationPayload['movements'][0]) =>
        classNames(isDisabled ? 'pointer-events-none' : '', !!errors?.[field]?.message ? 'outline outline-1 outline-system-danger-500' : '');

    return (
        <>
            <TableRow sx={customRowStyles}>
                <TableCell className={isDisabled ? 'opacity-20' : 'opacity-100'}>
                    <Text className="whitespace-nowrap">{item.label}</Text>
                </TableCell>
                <TableCell className={isDisabled ? 'opacity-20' : 'opacity-100'}>
                    <Text>{item.quantityInStock}</Text>
                </TableCell>
                <TableCell className={isDisabled ? 'opacity-20' : 'opacity-100'}>
                    <Text>{formatMoney(item.productValue)}</Text>
                </TableCell>
                <TableCell className={isDisabled ? 'opacity-20 px-0' : 'opacity-100 px-0'}>
                    <Controller
                        control={control}
                        name={`movements.${index}.quantitySelected`}
                        render={({ field }) => {
                            return (
                                <CurrencyInput
                                    value={field.value}
                                    placeholder="Digite a quantidade do produto"
                                    inputWrapperClasses="border-none rounded-none hover:outline-primary-300 hover:outline-[1px] hover:outline"
                                    parentClassName={getClasses('quantitySelected')}
                                    className="bg-transparent"
                                    decimalScale={2}
                                    defaultValue={0}
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    fixedDecimalScale={true}
                                    withFormat={false}
                                    onValueChange={({ floatValue = 0 }) => field.onChange(floatValue)}
                                />
                            );
                        }}
                    />
                </TableCell>
                <TableCell className={isDisabled ? 'opacity-20 px-0' : 'opacity-100 px-0 outline-'}>
                    <Controller
                        control={control}
                        name={`movements.${index}.product`}
                        render={({ field }) => {
                            const value = products.find((item) => item.value === field?.value);

                            return (
                                <Select
                                    value={value}
                                    isLoading={isLoadingProducts}
                                    options={!!searchTerm ? products : []}
                                    placeholder="Busque pelo código ou nome"
                                    parentClassName={getClasses('product')}
                                    selectStyles={{
                                        control: (base, data) => ({
                                            ...(selectStyles('medium', false).control!(base, data) as any),
                                            border: 'none',
                                        }),
                                    }}
                                    onInputChange={setSearchTerm}
                                    onChange={(option: any) => field.onChange(option.value)}
                                />
                            );
                        }}
                    />
                </TableCell>
                <TableCell className={isDisabled ? 'opacity-20 px-0' : 'opacity-100 px-0'}>
                    <Controller
                        control={control}
                        name={`movements.${index}.quantityGenerated`}
                        render={({ field }) => {
                            return (
                                <CurrencyInput
                                    value={field.value}
                                    placeholder="Digite a quantidade do produto"
                                    inputWrapperClasses="border-none rounded-none hover:outline-primary-300 hover:outline-[1px] hover:outline"
                                    parentClassName={getClasses('quantityGenerated')}
                                    className="bg-transparent"
                                    decimalScale={2}
                                    defaultValue={0}
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    fixedDecimalScale={true}
                                    withFormat={false}
                                    onValueChange={({ floatValue = 0 }) => field.onChange(floatValue)}
                                />
                            );
                        }}
                    />
                </TableCell>
                <TableCell className={isDisabled ? 'opacity-20' : 'opacity-100'}>{formatMoney(totalValue)}</TableCell>
                <TableCell className="w-[50px] p-0 h-[50px]">
                    <div className="w-full h-full overflow-hidden">
                        <button className="flex items-center justify-center w-full h-full hover:bg-system-danger-100 hover:bg-opacity-50" onClick={onRemoveProduct}>
                            <SvgIcoTrash width={16} height={16} color={palette.error[500]} />
                        </button>
                    </div>
                </TableCell>
            </TableRow>
            {hasErrors && (
                <TableRow sx={{ ...customRowStyles, '&:last-child div': { borderBottomRightRadius: '14px', borderBottomLeftRadius: '14px' } }}>
                    <TableCell colSpan={8} className="!p-0">
                        <div className="px-4 py-2 bg-system-danger-100 bg-opacity-20 overflow-hidden">
                            {errorsToArray.map(([key, value]) => {
                                return (
                                    <Text as="p" className="text-system-danger-500 mb-1 last:mb-0" variant="body.regular.xs" key={key}>
                                        {fieldsMap[key] || 'Campo'}: {(value as any)?.message}
                                    </Text>
                                );
                            })}
                        </div>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};

export default IndustrializationItem;
