import { PropsWithChildren } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { Option } from 'types/general';
import { Gender, MaritalStatus, Person } from 'types/models/person';
import useTheme from '@mui/material/styles/useTheme';
import dictionary from 'utils/dictionary';
import _get from 'lodash/get';
import classNames from 'classnames';
import { formatDate } from 'utils/date';
import Avatar from '@mui/material/Avatar/Avatar';
import { CardHeader } from '@mui/material';
import useGetPersonDetailsTab from 'services/queries/people/use-get-person-details-tab';
import MUICard from 'components/core/card';
import { hexToRgba } from 'utils/string';
import Text from 'components/core/text';
import SvgIcoTravel from 'components/core/icon/files/ico-travel';

const Card = ({ children, className }: PropsWithChildren<{ className?: string }>) => <MUICard className={classNames('p-6', className)}>{children}</MUICard>;

const PersonDetailsTab = () => {
    const { palette } = useTheme();
    const { personId } = useParams();

    const { name, email, emails, gender, address, document, birthday, expeditionDate, maritalStatus, phones, user } = useOutletContext<Partial<Person>>();

    const { data } = useGetPersonDetailsTab(personId);

    const genderLabel = _get(dictionary.content.genders, Gender[gender!]?.toLowerCase(), '');
    const maritalStatusLabel = _get(dictionary.content.maritalStatus, MaritalStatus[maritalStatus!]?.toLowerCase(), '');

    const details: Array<{ title: string; items: Array<Option<any> & { span?: number }> }> = [
        {
            title: 'Informações pessoais',
            items: [
                {
                    value: name || '-',
                    label: 'Nome',
                },
                {
                    value: genderLabel || '-',
                    label: 'Gênero',
                },
                {
                    value: maritalStatusLabel || '-',
                    label: 'Estado civil',
                },
                {
                    value: formatDate(birthday, 'DD/MM/YYYY'),
                    label: 'Data de nascimento',
                },
            ],
        },
        {
            title: 'Documentos',
            items: [
                {
                    value: document || '-',
                    label: 'CPF',
                },
                {
                    value: formatDate(expeditionDate),
                    label: 'Data de expedição',
                },
            ],
        },
        {
            title: 'Informações de contato',
            items: [
                {
                    value: email,
                    label: 'E-mail principal',
                },
                {
                    value: emails?.map(({ email }) => email)?.join(', '),
                    label: 'E-mails secundários',
                },
                {
                    value: phones?.map(({ ddd, number }) => `(${ddd}) ${number}`).join(', '),
                    label: 'Telefone(s)',
                },
                {
                    value: address?.full,
                    label: 'Endereço',
                    span: 3,
                },
            ],
        },
    ];

    return (
        <>
            <div className="grid grid-cols-3 gap-4 mb-4">
                <Card>
                    <CardHeader
                        avatar={
                            <Avatar sx={{ bgcolor: hexToRgba(palette.info[100], 0.2), borderRadius: '14px !important', width: 48, height: 48 }} aria-label="recipe">
                                <SvgIcoTravel color={palette.info[500]} />
                            </Avatar>
                        }
                        classes={{ root: 'p-0' }}
                        title="CONTRATOS"
                        subheader={data?.totalContracts || 0}
                        titleTypographyProps={{ color: palette.grey[500], fontSize: 14 }}
                        subheaderTypographyProps={{ color: palette.heading, fontSize: 25, lineHeight: 1 }}
                    />
                </Card>
                {!!user && (
                    <Card className="grid grid-cols-2 gap-4">
                        <div>
                            <Text as="p" className="mb-1 uppercase text-base-500">
                                Nome de usuário
                            </Text>
                            <Text variant="body.regular.sm" className="break-words text-heading">
                                {user.username || '-'}
                            </Text>
                        </div>
                        <div>
                            <Text as="p" className="mb-1 uppercase text-base-500">
                                Perfil
                            </Text>
                            <Text variant="body.regular.sm" className="break-words text-heading">
                                {user.profile?.name || '-'}
                            </Text>
                        </div>
                    </Card>
                )}
            </div>
            {details.map(({ title, items }, index) => (
                <Card className="mb-4" key={`detail_${index}`}>
                    <p className="text-heading text-2xl mb-4">{title}</p>
                    <div className="grid grid-cols-3 gap-4">
                        {items.map(({ label, value, span }, index) => (
                            <div className={classNames('flex flex-col', span && `col-span-${span}`)} key={`detail-${index}`}>
                                <p className="mb-1 uppercase" style={{ color: palette.grey[500] }}>
                                    {label}
                                </p>
                                <p className="text-sm break-words" style={{ color: palette.heading }}>
                                    {value ?? '-'}
                                </p>
                            </div>
                        ))}
                    </div>
                </Card>
            ))}
        </>
    );
};

export default PersonDetailsTab;
