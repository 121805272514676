import { useOutletContext } from 'react-router-dom';
import { ServiceOrderProvider } from '../context';
import CreateOrUpdateOrderService from './create-or-update';
import { SaleOrder } from 'types/models/sale-order';
import { CrudPageProps } from 'types/graphql';

const CreateOrUpdateOrderServicePage = ({ title }: CrudPageProps<{}>) => {
    const saleOrder = useOutletContext<Partial<SaleOrder>>();

    return (
        <ServiceOrderProvider>
            <CreateOrUpdateOrderService title={title || 'Ordem de serviço'} {...saleOrder} />
        </ServiceOrderProvider>
    );
};

export default CreateOrUpdateOrderServicePage;
