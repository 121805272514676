import useToast from 'hooks/toast/use-toast';
import { useMutation } from 'react-query';
import api from 'services/api';
import { ProviderConsultingResponse } from 'types/models/provider';

const useGetCNPJInformations = () => {
    const { showToast } = useToast();

    //cnpj param must be formatted CNPJ - 19.222.699/0001-62
    const request = async (formattedCNPJ: string) => {
        const { data } = await api.post<ProviderConsultingResponse>('/providers/tax-information', {
            document: formattedCNPJ,
        });

        return data;
    };

    return useMutation(request, {
        onError: () => {
            showToast('Não foi possível consultar as informações do CNPJ inserido.');
        },
    });
};

export default useGetCNPJInformations;
