import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import { Product, ProductPayload } from 'types/models/product';
import { getAllKey } from '../crud/keys';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';
import api from 'services/api';
import { GraphqlPaginationVariables } from 'types/graphql';

const useCreateOrUpdateProduct = (productId?: string, params?: GraphqlPaginationVariables<Product>) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const goToParentRoute = useNavigateToParentRoute();

    const request = async (payload: ProductPayload) => {
        const method = Boolean(productId) ? api.patch : api.post;
        const endpoint = Boolean(productId) ? `/products/${productId}` : `/products`;

        return method(endpoint, payload);
    };

    return useMutation(request, {
        onSuccess: () => {
            queryClient.invalidateQueries(getAllKey('product', params));

            goToParentRoute();

            showToast(`Produto ${Boolean(productId) ? 'atualizado' : 'criado'} com sucesso`, 'success');
        },
        onError: () => {
            showToast(`Ocorreu um erro ao ${Boolean(productId) ? 'atualizar' : 'criar'} o produto`, 'danger');
        },
    });
};

export default useCreateOrUpdateProduct;
