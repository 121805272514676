import ExternalButton from 'components/buttons/external';
import Text from 'components/core/text';
import { Transaction } from 'types/models/transaction';
import { formatDate } from 'utils/date';
import { formatMoney } from 'utils/money';

const ReceiptsContentDetails = ({ paymentForm, dueDate, receipt, createdAt, paymentForecast, netValue, grossValue, payday, addition, discount }: Partial<Transaction>) => {
    return (
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
            <Text className="text-base-500 block">
                Valor Bruto: <strong className="block">{formatMoney(grossValue)}</strong>
            </Text>
            <Text className="text-base-500 block">
                Acréscimos: <strong className="block">{formatMoney(addition)}</strong>
            </Text>
            <Text className="text-base-500 block">
                Descontos: <strong className="block">{formatMoney(discount)}</strong>
            </Text>
            <Text className="text-base-500 block">
                Valor líquido: <strong className="block">{formatMoney(netValue)}</strong>
            </Text>
            <Text className="text-base-500 block">
                Cadastro OR: <strong className="block">{formatDate(createdAt)}</strong>
            </Text>
            <Text className="text-base-500 block">
                Previsão: <strong className="block">{formatDate(paymentForecast)}</strong>
            </Text>
            <Text className="text-base-500 block">
                Cadastro receita: <strong className="block">{formatDate(receipt?.createdAt)}</strong>
            </Text>
            <Text className="text-base-500 block">
                Vencimento: <strong className="block">{formatDate(dueDate)}</strong>
            </Text>
            <Text className="text-base-500 block">
                Data de pagamento: <strong className="block">{formatDate(payday)}</strong>
            </Text>
            <Text className="text-base-500 block">
                Origem:
                {Boolean(receipt?.origin?.saleOrder?.id) ? (
                    <ExternalButton to={`/app/pedidos-de-venda/${receipt.origin.saleOrder.id}`}>{receipt.origin.saleOrder.code}</ExternalButton>
                ) : (
                    <strong className="block">-</strong>
                )}
            </Text>
            <Text className="text-base-500 block">
                Obra:
                {Boolean(receipt?.origin?.saleOrder?.id && receipt?.origin?.orderService?.id) ? (
                    <ExternalButton to={`/app/pedidos-de-venda/${receipt.origin.saleOrder.id}/ordens-de-servico/${receipt.origin.orderService.id}`}>{receipt.origin.orderService.title}</ExternalButton>
                ) : (
                    <strong className="block">-</strong>
                )}
            </Text>
            <Text className="text-base-500 block">
                Consultor: <strong className="block">{receipt?.origin?.saleOrder?.consultant?.title || '-'}</strong>
            </Text>
            <Text className="text-base-500 block">
                Empresa: <strong className="block">{receipt?.origin?.saleOrder?.company?.name || '-'}</strong>
            </Text>
            <Text className="text-base-500 block">
                Forma de pagamento: <strong className="block">{paymentForm?.name || '-'}</strong>
            </Text>
        </div>
    );
};

export default ReceiptsContentDetails;
