import Table from '@mui/material/Table/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { TH } from 'pages/private/sales-orders/service-order/financial-balance/utils';
import { getTransactionDocumentByType, movementDocumentsColumns } from '../../utils';
import DownloadButton from 'components/download';
import Empty from 'components/empty';
import { Transaction } from 'types/models/transaction';
import Badge from 'components/core/badge';
import { useNavigate, useParams } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import ConfirmModal from 'components/core/modal/confirm';
import useDelete from 'services/queries/crud/use-delete';
import { useQueryClient } from 'react-query';
import { getTransactionKey } from 'services/queries/transactions/keys';
import AddButton from 'components/buttons/add';
import SvgIcoEdit from 'components/core/icon/files/ico-edit';
import SvgIcoTrash from 'components/core/icon/files/ico-trash';

type PaymentOrderDocumentsProps = {
    documents: Transaction['documents'];
    transactionId?: string;
};

const PaymentOrderDocuments = ({ documents = [] }: PaymentOrderDocumentsProps) => {
    const [documentToDeleteId, setDocumentToDeleteId] = useState<number | null>(null);

    const queryClient = useQueryClient();

    const { paymentOrderId, receiptOrderId, transactionId } = useParams();

    const idTransaction = paymentOrderId || receiptOrderId || transactionId;
    // variables represent IDs from different routes - all of them represent the same type of ID

    const { mutateAsync: deleteTransactionDocument, isLoading: isDeleteTransactionDocument } = useDelete({
        apiPayload: {
            useApiTodelete: true,
            endpoint: `/transactions/${idTransaction}/documents`,
        },
    });

    const navigate = useNavigate();

    const handleDeleteDocument = async () => {
        try {
            if (Boolean(documentToDeleteId)) {
                await deleteTransactionDocument({ id: documentToDeleteId! });
                queryClient.invalidateQueries(getTransactionKey(idTransaction));
                setDocumentToDeleteId(null);
            }
        } catch (e) {
            console.error('delete - transactionDocument: ', e);
        }
    };

    if (!documents.length) {
        return <Empty className="p-4" title="Não há documentos a serem mostrados." buttonLabel="Criar documento" onClick={() => navigate('documentos/novo')} />;
    }

    const handleGoTo = (path: string) => {
        return navigate(path);
    };

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>{movementDocumentsColumns.map(TH)}</TableRow>
                </TableHead>
                <TableBody>
                    {documents.map((document) => (
                        <TableRow key={document.id}>
                            <TableCell>{document.name || '-'}</TableCell>
                            <TableCell>{document.description || '-'}</TableCell>
                            <TableCell>{Boolean(document.type) ? <Badge variant="info">{getTransactionDocumentByType(document.type)} </Badge> : '-'}</TableCell>
                            <TableCell>
                                <DownloadButton fileId={document.file?.id!} fileName={document.file.originalName!} />
                            </TableCell>
                            <TableCell>
                                <div className="flex items-center gap-1">
                                    <Tooltip title="Editar" placement="top">
                                        <IconButton
                                            size="medium"
                                            color="warning"
                                            className=" hover:bg-system-warning-100 hover:bg-opacity-20"
                                            onClick={handleGoTo.bind(this, `documentos/${document.id}/editar`)}>
                                            <SvgIcoEdit className="text-system-warning-500" width={13} height={13} />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Remover" placement="top">
                                        <IconButton size="medium" color="error" className=" hover:bg-system-error-100" onClick={setDocumentToDeleteId.bind(this, document.id)}>
                                            <SvgIcoTrash className="text-system-error-500" width={13} height={13} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <AddButton className="m-2" onClick={handleGoTo.bind(this, 'documentos/novo')}>
                Novo documento
            </AddButton>
            {Boolean(documentToDeleteId) && (
                <ConfirmModal
                    title="Apagar documento"
                    color="error"
                    onClose={setDocumentToDeleteId.bind(this, null)}
                    description="Você tem certeza que deseja apagar este documento?"
                    isLoading={isDeleteTransactionDocument}
                    onConfirmAction={handleDeleteDocument}
                />
            )}
        </>
    );
};

export default PaymentOrderDocuments;
