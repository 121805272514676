import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { useNavigate, useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import CardsDetails from '../components/cards';
import useGetSaleReceiptById from 'services/queries/sales-notes/use-get-sale-receipt-by-id';
import Badge from 'components/core/badge';
import SaleNoteActions from '../components/actions';
import { useCallback, useMemo, useState } from 'react';
import useTheme from '@mui/material/styles/useTheme';
import Accordion from '@mui/material/Accordion/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary/AccordionSummary';
import { useQueryClient } from 'react-query';
import useDelete from 'services/queries/crud/use-delete';
import { GraphqlPaginationVariables } from 'types/graphql';
import { getAllKey } from 'services/queries/crud/keys';
import ConfirmModal from 'components/core/modal/confirm';
import Spinner from 'components/core/spinner';
import { SaleOrderLiteral, accordionsData, salesNotesFiltersWhere, statusLiterals } from '../utils';
import AccordionTable from './components/accordion-table';
import Divider from '@mui/material/Divider/Divider';
import useFinalizeSaleNote from 'services/queries/sales-notes/use-finalize-sale-note';
import useToast from 'hooks/toast/use-toast';
import { getAllSalesReceiptsSumsKeys } from 'services/queries/sales-notes/keys';
import { SalesReceiptStatus } from 'types/models/sales-notes';
import { formatDate } from 'utils/date';
import { formatMoney } from 'utils/money';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';
import SvgIcoChevronDown from 'components/core/icon/files/ico-chevron-down';

const SaleNotesDetails = () => {
    const navigate = useNavigate();
    const { palette } = useTheme();
    const { showToast } = useToast();
    const { saleNoteId } = useParams();
    const queryClient = useQueryClient();
    const goToParentRoute = useNavigateToParentRoute();
    const params = useOutletContext<GraphqlPaginationVariables<any>>();

    const [isFinalizingSaleNote, setIsFinalizingSaleNote] = useState(false);
    const [saleNoteIdToDelete, setSaleNoteIdToDelete] = useState<number | null>(null);

    const [searchParams] = useSearchParams();

    const objectSearchParams = Object.fromEntries(searchParams);

    const paramsFilters = salesNotesFiltersWhere(objectSearchParams);

    const { mutateAsync: finalizeSaleNote, isLoading: isFinalizing } = useFinalizeSaleNote(+saleNoteId!);
    const { mutateAsync: deleteSaleNote, isLoading: isDeleting } = useDelete({
        apiPayload: { endpoint: '/sales-notes', useApiTodelete: true },
    });

    const { data: noteSaleReceipt, isLoading: isLoadingSaleReceipt } = useGetSaleReceiptById(+saleNoteId!);

    const accordionsDataArray = useMemo(() => accordionsData(noteSaleReceipt), [noteSaleReceipt]);

    const cardsDataArray = useMemo(
        () => [
            { label: 'Empresa', value: noteSaleReceipt?.company?.name || '--' },
            { label: 'Emissão', value: formatDate(noteSaleReceipt?.emission, 'DD/MM/YYYY') },
            { label: 'Tipo', value: SaleOrderLiteral[noteSaleReceipt?.nature as any] || '--' },
            { label: 'ISS retido', value: formatMoney(0) },
            { label: 'Valor NF', value: noteSaleReceipt?.invoiceValue, withIcon: true },
            { label: 'Dedução', value: noteSaleReceipt?.deductionValue, withIcon: true },
            { label: 'Total Deduzido', value: noteSaleReceipt?.totalDeductionValue, withIcon: true },
            { label: 'Saldo', value: noteSaleReceipt?.deductionValue! - noteSaleReceipt?.totalDeductionValue! || 0, withIcon: true },
        ],
        [noteSaleReceipt]
    );

    const handleFinalizeSaleNote = useCallback(async () => {
        try {
            await finalizeSaleNote();

            queryClient.invalidateQueries(getAllKey('salesReceipt', params));

            goToParentRoute();

            showToast('Nota de venda finalizada com sucesso!', 'success');
        } catch (error) {
            showToast('Não foi possível finalizar a nota de venda!', 'danger');

            console.error(`finalizeSaleNote`, error);
        }
    }, [finalizeSaleNote, goToParentRoute, params, queryClient, showToast]);

    const handleDelete = async () => {
        try {
            await deleteSaleNote({ id: saleNoteIdToDelete as any });

            setSaleNoteIdToDelete(null);

            queryClient.invalidateQueries(getAllKey('salesReceipt', params));
            queryClient.invalidateQueries(getAllSalesReceiptsSumsKeys(paramsFilters));

            goToParentRoute();
        } catch (error) {
            console.error('handleDeleteSaleNote', error);
        }
    };

    const handleClose = () => navigate(`/app/notas-de-venda`, { replace: true });

    return (
        <Modal
            closeOnClickOutside={false}
            contentClassnames="w-[1200px]"
            headerLeft={
                <div className="flex items-center">
                    <Text as="h3" variant="h3" className="mr-4 !text-3xl font-medium text-heading">
                        #{noteSaleReceipt?.number}
                    </Text>
                    <Badge variant={statusLiterals.get(noteSaleReceipt?.finalized ? SalesReceiptStatus.Finalized : SalesReceiptStatus.NotFinalized)?.variant! || ('grey' as any)}>
                        {statusLiterals.get(noteSaleReceipt?.finalized ? SalesReceiptStatus.Finalized : SalesReceiptStatus.NotFinalized)?.label}
                    </Badge>
                </div>
            }
            headerRight={
                <SaleNoteActions saleNoteId={+saleNoteId!} noteSaleReceipt={noteSaleReceipt} onSetIsFinalizingSaleNote={setIsFinalizingSaleNote} onSetSaleNoteIdToDelete={setSaleNoteIdToDelete} />
            }
            onClose={handleClose}>
            <Divider />
            {isLoadingSaleReceipt ? (
                <div className="p-4">
                    <Spinner color={palette.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4">
                        {cardsDataArray.map((item, index) => (
                            <CardsDetails {...item} key={index} />
                        ))}
                    </div>
                    <div className="p-6">
                        <div>
                            {accordionsDataArray.map((accordion, index) => (
                                <Accordion className="mb-4 last:mb-0" key={index} defaultExpanded={index === 0} classes={{ root: '!border-[1px] shadow-none !rounded-[16px] before:bg-transparent' }}>
                                    <AccordionSummary classes={{ root: 'px-6' }} expandIcon={<SvgIcoChevronDown width={14} color={palette.heading} />}>
                                        <Text as="h5" variant="h5" className="text-heading text-[20px]">
                                            {accordion.title}
                                        </Text>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <AccordionTable accordion={accordion} />
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </div>
                        {!!saleNoteIdToDelete && (
                            <ConfirmModal
                                title="Apagar nota de venda"
                                onClose={() => setSaleNoteIdToDelete(null)}
                                description="Você tem certeza que deseja apagar esta nota de venda?"
                                isLoading={!!isDeleting}
                                onConfirmAction={handleDelete}
                            />
                        )}
                        {isFinalizingSaleNote && (
                            <ConfirmModal
                                title="Finalizar nota de venda"
                                color="secondary"
                                onClose={() => setIsFinalizingSaleNote(false)}
                                description="Você tem certeza que deseja finalizar esta nota de venda?"
                                isLoading={isFinalizing}
                                onConfirmAction={handleFinalizeSaleNote}
                            />
                        )}
                    </div>
                </>
            )}
        </Modal>
    );
};

export default SaleNotesDetails;
