export type Modify<T, R> = Omit<T, keyof R> & R;

export type ReduxAction<T> = {
    type: string;
    payload: T;
};

export type ID = number | string;

export type Option<T = number> = {
    value: T;
    label: string;
    data?: any;
};

export enum Status {
    Deleted = -1,
    Inactive,
    Active,
}

export enum StatusLabel {
    Deleted = 'Apagado',
    Inactive = 'Inativo',
    Active = 'Ativo',
}

export enum ApprovalStatus {
    Waiting = 1,
    Approved,
    Disapproved,
}

export enum ProjectTransactions {
    Outcome = 1,
    Income,
}

type FlattenObjectKeys<T, Prefix extends string = ''> = {
    // @ts-ignore
    [K in keyof T as string extends K ? never : K]: `${Prefix}${K}` | FlattenKeys<T[K], `${Prefix}${K}.`>;
};

export type FlattenKeys<T, Prefix extends string = ''> = T extends object ? FlattenObjectKeys<T, Prefix>[keyof FlattenObjectKeys<T, Prefix>] : '';

export enum Weekdays {
    Monday = 1,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
}

export enum Priority {
    High = 1,
    Moderate,
    Low,
}
