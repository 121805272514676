import { yupResolver } from '@hookform/resolvers/yup';

import { useEffect } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { PurchaseProduct } from 'types/models/purchase-order';
import { formatDate } from 'utils/date';
import useGetProducts from 'services/queries/products/use-get-products';
import { Product } from 'types/models/product';
import { generateProductsQuery } from 'services/queries/products/utils';
import ManageProductsRow from './row';
import useValidateProducts from 'services/queries/order-services/use-validate-products';
import { useOutletContext, useParams } from 'react-router-dom';
import useGetOrderServicesPurchaseScope, { GetOrderServicesPurchaseScopeParams } from 'services/queries/order-services/use-get-order-services-purchase-scope';
import { INITIAL_PURCHASE_PRODUCTS, formatProductsResponse, productsQueryWhere, purchaseScopeValidationSchema } from './utils';
import dayjs from 'dayjs';
import useConfig from 'store/config/use-config';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { CrudPageProps } from 'types/graphql';
import FormButtons from 'components/form-buttons';
import { getOrderServiceKey, getOrderServicesPurchaseScopeKey } from 'services/queries/order-services/keys';
import { useQueryClient } from 'react-query';
import AddButton from 'components/buttons/add';
import { Option } from 'types/general';
import Table from '@mui/material/Table/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Spinner from 'components/core/spinner';
import { PurchaseScopeActions } from 'types/models/order-service';

export type ManagePurchaseProduct = {
    id?: string | number;
    approved?: boolean | null;
    product?: Option | null;
    quantity: number;
    value: number;
    provision: string;
    justification: string;
    purchaseProductId?: number;
    action?: PurchaseScopeActions;
};

export type ManageProductsPayload = {
    purchaseProducts: ManagePurchaseProduct[];
};

const ManageProductsPage = ({ title }: CrudPageProps<{}>) => {
    const { config } = useConfig();
    const queryClient = useQueryClient();
    const { saleOrderId, orderServiceId } = useParams();

    const ctx = useOutletContext<GetOrderServicesPurchaseScopeParams>();

    const filters = {
        ...ctx?.filters,
        type: 'validation',
        totalPerPage: config.tables.rowsPerPage,
    };

    const { isLoading, data: products = [] } = useGetOrderServicesPurchaseScope<PurchaseProduct[]>({ ...ctx, filters });

    const { mutateAsync: validateProducts, isLoading: isSubmitting } = useValidateProducts('validate', saleOrderId, orderServiceId);
    const { data: filteredProducts = [] } = useGetProducts<Product>(generateProductsQuery(['id', 'name'], productsQueryWhere), true, formatProductsResponse);

    const methods = useForm<ManageProductsPayload>({
        mode: 'onSubmit',
        resolver: yupResolver(purchaseScopeValidationSchema(true)) as any,
        resetOptions: {
            keepDirty: false,
            keepTouched: false,
        },
        defaultValues: {
            purchaseProducts: [],
        },
    });

    const { control, reset, handleSubmit } = methods;

    const { append, fields: purchaseProducts, remove } = useFieldArray({ name: 'purchaseProducts', control });

    const submit = async (data: ManageProductsPayload) => {
        try {
            const payload = {
                purchaseProducts: data.purchaseProducts.map((item) => {
                    const { purchaseProductId, ...rest } = item;

                    return {
                        ...rest,
                        id: purchaseProductId,
                        product: item.product?.value! as any,
                        provision: formatDate(item.provision, 'YYYY-MM-DD'),
                    };
                }),
            };

            await validateProducts(payload);

            queryClient.invalidateQueries(getOrderServiceKey(orderServiceId));
            queryClient.invalidateQueries(getOrderServicesPurchaseScopeKey(ctx?.endpoint, filters));
        } catch (error) {
            console.log('submit', submit);
        }
    };

    useEffect(() => {
        if (!!products.length) {
            reset({
                purchaseProducts: products.map((item) => {
                    const [date] = item.provision?.split('T') || [];
                    const provision = dayjs(date).utc(false).toDate();

                    return {
                        purchaseProductId: item.id,
                        product: {
                            value: item.product.id!,
                            label: item.product.name!,
                        },
                        provision: item.provision ? provision.toISOString() : '',
                        quantity: item.quantity,
                        value: item.value,
                        justification: '',
                        action: undefined,
                    };
                }),
            });
        }
    }, [products, reset]);

    return (
        <Modal
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    {title}
                </Text>
            }
            closeOnClickOutside={false}
            contentClassnames="min-w-[1240px] max-w-[90%]">
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(submit)}>
                    {isLoading ? (
                        <Spinner fixed={false} wrapperClasses="p-6" />
                    ) : (
                        <>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>PRODUTO</TableCell>
                                            <TableCell>PREVISÃO COMPRA</TableCell>
                                            <TableCell>QUANTIDADE</TableCell>
                                            <TableCell>VALOR MÁXIMO</TableCell>
                                            <TableCell>JUSTIFICATIVA</TableCell>
                                            <TableCell>AÇÕES</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {purchaseProducts.map((item, rowIndex) => {
                                            return <ManageProductsRow item={item} onRemove={remove.bind(this, rowIndex)} filteredProducts={filteredProducts} rowIndex={rowIndex} key={item.id} />;
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <div className="p-4">
                                <AddButton className="mb-4" onClick={append.bind(this, INITIAL_PURCHASE_PRODUCTS)}>
                                    Adicionar produto
                                </AddButton>
                                <FormButtons isLoading={isSubmitting} />
                            </div>
                        </>
                    )}
                </form>
            </FormProvider>
        </Modal>
    );
};

export default ManageProductsPage;
