import Card from 'components/core/card';
import useTheme from '@mui/material/styles/useTheme';
import { memo, useMemo } from 'react';
import classNames from 'classnames';
import { ReportPageDetailsBlockType } from '..';
import { ReportPageBlock } from 'types/models/reports';
import Avatar from '@mui/material/Avatar/Avatar';
import { hexToRgba } from 'utils/string';
import { formatMoney } from 'utils/money';
import { TransactionType } from 'types/models/transaction';
import { getNumberValueOrZero } from 'pages/private/expenses/details/tabs/transactions/utils';
import SvgIcoDollar from 'components/core/icon/files/ico-dollar';

type ReportCreditHeaderCardProps = {
    className?: string;
    title: string;
    currentYear?: number;
    previousYear?: number;
    resumeCurrentYear?: ReportPageBlock['resume'];
    resumePreviousYear?: ReportPageBlock['resume'];
    reportPageType?: TransactionType;
    isAverage?: boolean;
    type?: ReportPageDetailsBlockType;
    currentYearTotal?: number;
    averageFromValues?: number;
};

const ReportPageDetailsBlockCardValue = ({
    className,
    title,
    resumeCurrentYear,
    resumePreviousYear,
    previousYear,
    reportPageType,
    type,
    currentYearTotal = 0,
    averageFromValues,
}: ReportCreditHeaderCardProps) => {
    const { palette } = useTheme();

    const { total = 0, average = 0 } = resumeCurrentYear || {};

    const getValueVariant = (value = 0) => {
        if (!value) {
            return 'text-base-500';
        }

        if (reportPageType === TransactionType.Income) {
            return value < 0 ? 'text-system-danger-500' : 'text-system-success-500';
        }

        return value < 0 ? 'text-system-success-500' : 'text-system-danger-500';
    };

    const comparison = useMemo(() => {
        const [totalCurrentYear, totalPreviousYear] = [resumeCurrentYear?.total || 0, resumePreviousYear?.total || 0];

        return {
            percentage: getNumberValueOrZero(((totalCurrentYear - totalPreviousYear) / totalPreviousYear) * 100),
            diff: getNumberValueOrZero(totalCurrentYear - totalPreviousYear),
        };
    }, [resumeCurrentYear, resumePreviousYear]);

    const percentageFromTotal = getNumberValueOrZero((100 * total) / currentYearTotal);

    return (
        <Card className={classNames('p-4', className)}>
            <div className="flex flex-col xl:flex-row items-center overflow-x-auto w-full max-w-full justify-center">
                <Avatar className="mb-4 xl:mr-5 xl:mb-0" sx={{ bgcolor: hexToRgba(palette.secondary[100], 0.2), borderRadius: '14px !important', width: 88, height: 88 }} aria-label="recipe">
                    <SvgIcoDollar color={palette.secondary[500]} width={48} height={48} />
                </Avatar>
                <div className="flex flex-col justify-center w-full text-center xl:text-left mb-2 xl:mb-0">
                    <p className="text-base-500 text-base font-normal">{title}</p>
                    <span className="flex-1 mr-1">
                        <strong className="font-medium text-2xl text-heading flex-1">{formatMoney(total)}</strong>
                        {type === 'block' && <span className="text-base-500 mb-1 text-sm">{formatMoney(percentageFromTotal).replace('R$', '')}%</span>}
                    </span>
                    {type === 'block' && (
                        <div className="flex text-sm">
                            <span className="text-base-500">{formatMoney(averageFromValues)}</span>
                        </div>
                    )}
                    {type === 'resume' && (
                        <div className="flex text-sm">
                            <span className="text-base-500">{formatMoney(average)}</span>
                        </div>
                    )}
                </div>
                <div className="flex flex-col items-center xl:items-end xl:mt-auto xl:mb-2">
                    <small className="text-xs text-right">x {previousYear}</small>
                    <div className={classNames('text-base flex items-center text-right whitespace-nowrap', getValueVariant(comparison.percentage))}>
                        <span>
                            {comparison.diff > 0 ? '+' : ''}
                            {formatMoney(comparison.percentage).replace('R$', '')}%
                        </span>
                    </div>
                    <div className={classNames('text-sm flex items-center text-right whitespace-nowrap', getValueVariant(comparison.diff))}>
                        <span>
                            {comparison.diff > 0 ? '+' : ''}
                            {formatMoney(comparison.diff)}
                        </span>
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default memo(ReportPageDetailsBlockCardValue);
