import { memo } from 'react';
import classNames from 'classnames';
import { DRETableProps } from 'types/models/reports';

type ReportPageDetailsBlockTableHeaderProps = {
    rounded?: boolean;
} & Pick<DRETableProps, 'headers' | 'minHeaderWidth' | 'hasFirstEmptyHeaderColumn' | 'emptyHeaderColumnWidth'>;

const ReportPageDetailsBlockTableHeader = ({ headers = [], minHeaderWidth, hasFirstEmptyHeaderColumn = true, emptyHeaderColumnWidth, rounded = true }: ReportPageDetailsBlockTableHeaderProps) => {
    return (
        <thead className="thead">
            {headers.map((items, index) => {
                return (
                    <tr key={`table-header-${index}`}>
                        {hasFirstEmptyHeaderColumn && <th style={{ minWidth: minHeaderWidth, width: emptyHeaderColumnWidth }}></th>}
                        {items.map(({ title, ...props }, i) => {
                            const thBorder = classNames(
                                `text-center p-1 py-3 bg-slate-100 border-2 border-white`,
                                rounded && index === 0 && 'last:rounded-tr-2xl',
                                rounded && index === 0 && !hasFirstEmptyHeaderColumn ? 'first:rounded-tl-2xl' : '',
                                props?.className
                            );

                            return (
                                <th key={`table-header-item-${title}-${i}`} {...props} className={thBorder}>
                                    {title}
                                </th>
                            );
                        })}
                    </tr>
                );
            })}
        </thead>
    );
};

export default memo(ReportPageDetailsBlockTableHeader);
