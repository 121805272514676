import * as React from 'react';
const SvgIcoArrowLeft = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 10" width="1em" height="1em" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M5.17 9.106a.83.83 0 0 0 .055-1.173L3 5.491H15.17a.83.83 0 1 0 0-1.66H3L5.225 1.39A.83.83 0 1 0 3.997.27L0 4.661l3.997 4.39a.83.83 0 0 0 1.173.055Z"
            clipRule="evenodd"
        />
    </svg>
);
export default SvgIcoArrowLeft;
