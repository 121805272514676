import { useEffect } from 'react';
import DocumentsFields from './fields';
import Modal from 'components/core/modal';
import { FormProvider, useForm } from 'react-hook-form';
import Button from 'components/core/button';
import Text from 'components/core/text';
import useGetClassifications from 'services/queries/classifications/use-get-classifications';
import Spinner from 'components/core/spinner';
import useTheme from '@mui/material/styles/useTheme';
import dictionary from 'utils/dictionary';
import { object, string, number, mixed } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { generateClassificationQuery } from 'services/queries/classifications/utils';
import { ClassificationType } from 'types/models/classification';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';
import { useParams } from 'react-router-dom';

type DocumentsFormProps = {
    title: string;
    onSubmit(data: DocumentPayload): void;
    isSubmitting?: boolean;
    isBuilding?: boolean;
    defaultValues?: Partial<DocumentPayload>;
    classificationType: ClassificationType;
};

export type DocumentPayload = {
    file: any;
    classification: number;
    name: string;
    description: string;
    observation: string;
    type?: number;
};

const schema = (isTransaction = false) =>
    object({
        file: mixed()
            .required(dictionary.validation.required)
            .test('fileName', 'File not found', (value: any) => !!value?.name || !!value?.originalName || !!value?.filename),
        name: string().typeError(dictionary.validation.required).required(dictionary.validation.required).trim(),
        description: string().default('').optional(),
        observation: string().default('').optional(),
        ...(Boolean(isTransaction)
            ? { type: number().typeError(dictionary.validation.required).required(dictionary.validation.required) }
            : { classification: number().typeError(dictionary.validation.required).required(dictionary.validation.required) }),
    });

const DocumentsForm = ({ title, onSubmit, isSubmitting = false, isBuilding = false, defaultValues, classificationType }: DocumentsFormProps) => {
    const { paymentOrderId, receiptOrderId, transactionId } = useParams();

    const isTransaction = !!paymentOrderId || !!receiptOrderId || !!transactionId;
    // variables represent IDs from different routes - all of them represent the same type of ID

    const { palette } = useTheme();
    const goToParentRoute = useNavigateToParentRoute();

    const { data: classifications = [], isLoading: isLoadingClassifications } = useGetClassifications(generateClassificationQuery(classificationType));

    const methods = useForm<DocumentPayload>({
        mode: 'onSubmit',
        defaultValues: {
            name: '',
            description: '',
            observation: '',
        },
        resolver: yupResolver(schema(isTransaction)) as any,
    });

    const isSpinnerVisible = isLoadingClassifications || isBuilding;

    useEffect(() => {
        if (!!defaultValues) {
            methods.reset(defaultValues);
        }
    }, [defaultValues, methods]);

    return (
        <Modal closeOnClickOutside={false} contentClassnames="w-[1100px]" onClose={goToParentRoute} hideCloseButton={isSpinnerVisible}>
            {isSpinnerVisible ? (
                <div className="p-10">
                    <Spinner color={palette.secondary[100]} fixed={false} size={30} />
                </div>
            ) : (
                <FormProvider {...methods}>
                    <form className="py-5 px-7" onSubmit={methods.handleSubmit(onSubmit)}>
                        <Text as="h3" variant="h4" className="text-heading mb-5">
                            {title}
                        </Text>
                        <DocumentsFields isTransaction={isTransaction} classifications={classifications} />
                        <div className="flex items-center">
                            <Button disabled={isSubmitting} loading={isSubmitting} type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4">
                                Enviar
                            </Button>
                            <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={goToParentRoute}>
                                Cancelar
                            </Button>
                        </div>
                    </form>
                </FormProvider>
            )}
        </Modal>
    );
};

export default DocumentsForm;
