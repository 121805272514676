import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import ListPage from 'pages/shared/crud/list';
import { memo } from 'react';
import { Link, useParams } from 'react-router-dom';
import useChangeInvoiceStatus from 'services/queries/order-services/use-change-invoice-status';
import { orderServiceDeductionsListConfig } from './utils';
import DetailedCard from 'pages/private/reports/screens/sales/totals/detailed-card';
import { Rule } from 'types/permissions';
import IconButton from '@mui/material/IconButton';
import PageHeader from 'components/page/header';
import useTheme from '@mui/material/styles/useTheme';
import { useGetDeductionTotals } from 'services/queries/deductions/use-get-deduction-totals';
import SvgIcoPlus from 'components/core/icon/files/ico-plus';

const OrderServiceDeductionsPage = ({ page }: WithPagePermissionsProps) => {
    const { orderServiceId, saleOrderId } = useParams();
    const { palette } = useTheme();

    const { mutateAsync: updateInvoiceStatus, isLoading: isSubmittingChangeInvoiceStatus } = useChangeInvoiceStatus();

    const { data: deductionsTotals } = useGetDeductionTotals(saleOrderId, orderServiceId);
    const { data: deductedTotals } = useGetDeductionTotals(saleOrderId, orderServiceId, true);

    const handleChangeInvoiceStatus = (transactionId?: number) => async () => {
        try {
            if (!transactionId) {
                return;
            }

            await updateInvoiceStatus(transactionId);
        } catch (error) {
            console.log('first', error);
        }
    };

    const listConfig = orderServiceDeductionsListConfig(handleChangeInvoiceStatus, isSubmittingChangeInvoiceStatus, orderServiceId, saleOrderId);
    const actions = page?.rules.map((item) => item.key);

    const canCreateDeduction = actions?.includes(Rule.Create);

    return (
        <>
            <PageHeader
                className="mb-6"
                title={listConfig.title}
                right={
                    canCreateDeduction ? (
                        <IconButton component={Link} to="novo" color="secondary" size="large" sx={{ backgroundColor: palette.secondary.main, '&:hover': { backgroundColor: palette.secondary.dark } }}>
                            <SvgIcoPlus width={16} height={16} color={palette.grey[100]} />
                        </IconButton>
                    ) : undefined
                }
            />
            <div className="flex sm:flex-row flex-col items-center gap-4 mb-4">
                <DetailedCard title="Total deduzido" className="w-full" hasPercentage={false} value={deductedTotals?.aggregate?.sum?.value || 0} />
                <DetailedCard title="Total a deduzir" className="w-full" hasPercentage={false} value={deductionsTotals?.aggregate?.sum?.value || 0} />
            </div>
            <ListPage {...listConfig} hideHeader={true} page={page} />
        </>
    );
};

export default memo(OrderServiceDeductionsPage);
