import { useOutletContext, useParams } from 'react-router-dom';
import { GraphqlPaginationVariables } from 'types/graphql';
import useCreateOrUpdateStockBalance, { CreateOrUpdateStockBalancePayload } from 'services/queries/stocks/use-create-or-update-stock-balance';
import Divider from '@mui/material/Divider/Divider';
import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import RemoveButton from 'components/buttons/remove';
import CurrencyInput from 'components/core/form/currency';
import Select from 'components/core/form/select';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import Button from 'components/core/button';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { NumberFormatValues } from 'react-number-format/types/types';
import { Product } from 'types/models/product';
import { formatMoney } from 'utils/money';
import { stockBalanceProductsFormatResponse, stockBalanceSchema } from './utils';
import { yupResolver } from '@hookform/resolvers/yup';

import useGetDebouncedStockProducts from 'services/queries/stocks/use-get-debounced-stock-products';

const CreateOrUpdateStockBalancePage = () => {
    const { stockId, balanceId } = useParams();

    const ctxParams = useOutletContext<GraphqlPaginationVariables<any>>();

    const { formState, control, setValue, watch, handleSubmit } = useForm<any>({
        resolver: yupResolver(stockBalanceSchema),
        defaultValues: { products: [] },
    });

    const { append, remove } = useFieldArray({ name: 'products', control });

    const { mutateAsync: createOrUpdateStockBalance, isLoading: isSubmitting } = useCreateOrUpdateStockBalance(stockId, balanceId, ctxParams);
    const { isLoadingProducts, products, searchTerm, setSearchTerm } = useGetDebouncedStockProducts<Product, any[]>(stockId, stockBalanceProductsFormatResponse);

    const submit = async (data: CreateOrUpdateStockBalancePayload) => {
        try {
            const payload = {
                products: data?.products.map(({ value, quantity, id }) => ({
                    value,
                    quantity,
                    product: id!,
                })),
            };

            await createOrUpdateStockBalance(payload);
        } catch (e) {
            console.error('submit: create-or-update flow balance', e);
        }
    };

    const handleSelectProducts = (option: any) => {
        append({
            ...option,
            quantity: 0,
            value: 0,
            total: 0,
        });

        setSearchTerm('');
    };

    const handleChangeQuantity = (item: any, index: number) => (values: NumberFormatValues) => {
        const { floatValue: quantity = 0 } = values;

        setValue(`products.${index}.quantity`, quantity);
        setValue(`products.${index}.total`, quantity * item.value);
    };

    const handleChangeValue = (item: any, index: number) => (values: NumberFormatValues) => {
        const { floatValue: value = 0 } = values;

        setValue(`products.${index}.value`, value);
        setValue(`products.${index}.total`, item?.quantity * value);
    };

    const handleRemoveProduct = (index: number) => () => remove(index);
    const selectedProducts = watch('products') || [];

    return (
        <Modal
            closeOnClickOutside={false}
            contentClassnames="w-[1400px]"
            headerLeft={
                <Text as="h3" className="text-primary-500 !text-2xl">
                    {!!balanceId ? 'Editar' : 'Cadastrar'} balanço
                </Text>
            }>
            <Divider />
            <form className="p-6" onSubmit={handleSubmit(submit)}>
                <div className="mb-6">
                    <Select
                        isLoading={isLoadingProducts}
                        value={searchTerm}
                        options={!!searchTerm ? products : []}
                        label="Produto (código ou nome)"
                        placeholder="Digite o código ou nome do produto"
                        components={!Boolean(searchTerm) ? { NoOptionsMessage: () => null } : undefined}
                        onInputChange={setSearchTerm}
                        onChange={handleSelectProducts}
                    />
                </div>
                {!!selectedProducts.length && (
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Text className="text-base-500 uppercase">Produto</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text className="text-base-500 uppercase">Unidade</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text className="text-base-500 uppercase whitespace-nowrap">Valor uni. (atual)</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text className="text-base-500 uppercase whitespace-nowrap">Quantidade (atual)</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text className="text-base-500 uppercase">Valor unitário</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text className="text-base-500 uppercase">Quantidade</Text>
                                    </TableCell>
                                    <TableCell className="max-w-[170px] min-w-[170px]">
                                        <Text className="text-base-500 uppercase">Total</Text>
                                    </TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selectedProducts.map((item: any, index) => {
                                    return (
                                        <TableRow key={item.id}>
                                            <TableCell>
                                                <Text className="text-heading">{item.name}</Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text className="text-heading">{item.classificationByClassificationUnitId?.name}</Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text className="text-heading">{formatMoney(item.currentValue)}</Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text className="text-heading">{item.currentQuantity || 0}</Text>
                                            </TableCell>
                                            <TableCell>
                                                <Controller
                                                    name={`products.${index}.value`}
                                                    control={control}
                                                    render={({ field }) => {
                                                        return (
                                                            <CurrencyInput
                                                                left={
                                                                    <Text as="span" variant="body.medium.sm" className="text-heading">
                                                                        R$
                                                                    </Text>
                                                                }
                                                                name={field.name}
                                                                value={field.value}
                                                                onValueChange={handleChangeValue(item, index)}
                                                                placeholder="Ex: 1000"
                                                                parentClassName="min-w-[200px] max-w-[200px]"
                                                                error={formState.errors.products?.[index]?.value?.message}
                                                            />
                                                        );
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Controller
                                                    name={`products.${index}.quantity`}
                                                    control={control}
                                                    render={({ field }) => {
                                                        return (
                                                            <CurrencyInput
                                                                name={field.name}
                                                                value={field.value}
                                                                withFormat={false}
                                                                placeholder="Ex: 1000"
                                                                parentClassName="min-w-[150px] max-w-[150px]"
                                                                onValueChange={handleChangeQuantity(item, index)}
                                                                error={formState.errors.products?.[index]?.quantity?.message}
                                                            />
                                                        );
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Text variant="h6" className="text-heading whitespace-nowrap">
                                                    {formatMoney(item.total)}
                                                </Text>
                                            </TableCell>
                                            <TableCell>
                                                <RemoveButton className="!static opacity-100 mt-0" onClick={handleRemoveProduct(index)} />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                <div className="mt-4 w-full flex justify-end">
                    <Button disabled={!selectedProducts.length} loading={isSubmitting} type="submit" variant="text" className="min-w-[100px]" color="secondary">
                        Adicionar balanço
                    </Button>
                </div>
            </form>
        </Modal>
    );
};

export default CreateOrUpdateStockBalancePage;
