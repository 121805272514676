import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import Text from 'components/core/text';
import Modal from 'components/core/modal';
import Spinner from 'components/core/spinner';
import Input from 'components/core/form/input';
import theme from 'settings/theme';
import Select from 'components/core/form/select';
import FormButtons from 'components/form-buttons';
import useGetOptions from 'services/queries/crud/use-get-options';
import CurrencyInput from 'components/core/form/currency';
import DatePicker from 'components/core/datepicker';
import { getOrderServicesByCompanyIdKey, getSaleOrdersByCompanyIdKey, queries } from 'services/queries/sales-notes/keys';
import { CompanyTaxRegime } from 'types/models/company';
import useGetReceiptsBySaleOrdersId from 'services/queries/sales-notes/use-get-receipts-by-ids';
import Dropzone from 'components/dropzone';
import RemoveButton from 'components/buttons/remove';
import { generateFileUrl } from 'utils/file';
import ErrorMessage from 'components/error/message';
import { SalesNotePayload } from 'types/models/sales-notes';
import { initialValues, resetTaxesFields, schema } from '../utils';
import DeductionValuesTable from './deduction-values-table';
import useGetCompanies from 'services/queries/companies/use-get-companies';
import { Option } from 'types/general';
import FieldsSimpleTaxRegime from './fields-simple-tax-regime';
import FieldsPresumedProfitTaxRegime from './fields-presumed-profit-tax-regime';
import DownloadButton from 'components/download';
import { booleanOptions, saleOrderOrigins } from 'utils/statics';
import { SaleOrderOrigin } from 'types/models/sale-order';
import useGetSaleOrdersProducts from 'services/queries/sales-order/use-get-sale-orders-products';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import useGetSalesNotesTaxes from 'services/queries/sales-notes/use-get-sales-notes-taxes';
import useDebounce from 'hooks/keyboard/use-debounce';
import isEmpty from 'lodash/isEmpty';
import useGetCustomerOptions from 'services/queries/customers/use-get-customer-options';
import { generateCustomerOptionsQuery } from 'services/queries/customers/utils';

type ProfileCreateProps = {
    defaultValues?: SalesNotePayload;
    isLoading: boolean;
    isSubmittingForm: boolean;
    fileHook?: any;
    saleNoteId?: number;
    onSubmit: (data: SalesNotePayload) => void;
};

type GerenicCompanyType = {
    id: number;
    name: string;
    taxRegime: number;
};

const companyQueryFields = ['id', 'name', 'taxRegime'];

const CreateOrUpdateSalesNote = ({ fileHook, saleNoteId, defaultValues, isLoading, isSubmittingForm, onSubmit }: ProfileCreateProps) => {
    const [selectedOrders, setSelectedOrders] = useState<Option[]>([]);
    const [companyTaxRegime, setCompanyTaxRegime] = useState<CompanyTaxRegime | null>(null);
    const [selectedOrigin, setSelectedOrigin] = useState<SaleOrderOrigin | null>(null);

    const [file, setFile] = fileHook;

    const selectedsIdOrders = useMemo(() => selectedOrders?.map((item: any) => item?.value), [selectedOrders]);

    const methods = useForm<SalesNotePayload>({
        mode: 'onSubmit',
        resolver: yupResolver(schema(companyTaxRegime!, selectedOrigin)) as any,
        defaultValues: initialValues,
    });

    const { control, handleSubmit, formState, reset, setValue, watch } = methods;

    const { data: companies = [], isLoading: isLoadingCompanies } = useGetCompanies<GerenicCompanyType[]>(companyQueryFields);
    const { data: customers = [], isLoading: isLoadingCustomers } = useGetCustomerOptions(generateCustomerOptionsQuery());

    const saleOrdersProductsRequests = useGetSaleOrdersProducts(selectedOrders);

    const [company, natureValue, deductionValues, deductionValue, cityTaxOption, invoiceValue = 0, dasAliquot = 0, emissionDate, customer, withImportedProducts] = watch([
        'company',
        'nature',
        'deductionValues',
        'deductionValue',
        'cityTax',
        'invoiceValue',
        'dasAliquot',
        'emission',
        'customer',
        'withImportedProducts',
    ]);

    const isSaleNature = natureValue === SaleOrderOrigin.sale;

    const debounceInvoiceValue = useDebounce(invoiceValue, 500);
    const debounceDeductionValue = useDebounce(deductionValue, 500);

    const { data: serviceOrders = [], isLoading: isLoadingServiceOrders } = useGetOptions(
        queries.getServiceOrdersByCompanyId(company?.id, customer),
        getOrderServicesByCompanyIdKey(company?.id, customer),
        Boolean(company?.id) && !isSaleNature && Boolean(customer),
        (data) => {
            return data.map((item) => ({
                value: item.value,
                label: `${item.label} ${item.title}`,
            }));
        }
    );

    const { mutateAsync: getSalesNotesTaxes, data: taxes, isLoading: isGettingTaxes } = useGetSalesNotesTaxes(company?.id);

    const isLoadingSaleOrdersProducts = useMemo(() => saleOrdersProductsRequests.some((item) => item.isLoading), [saleOrdersProductsRequests]);

    const { data: saleOrders = [], isLoading: isLoadingSaleOrders } = useGetOptions(
        queries.getSaleOrderByCompanyId(company?.id, natureValue, customer),
        getSaleOrdersByCompanyIdKey(company?.id, customer),
        Boolean(company?.id) && Boolean(customer)
    );

    const { data: receipts = [] } = useGetReceiptsBySaleOrdersId(queries.getReceiptsByIds(selectedsIdOrders, isSaleNature), selectedsIdOrders);

    const companyOptions = useMemo(() => {
        return companies.map((item) => ({
            label: item.name,
            value: item.id,
            taxRegime: item.taxRegime,
        }));
    }, [companies]);

    const handleCompanyChange = useCallback(
        (field) => (option: any) => {
            resetTaxesFields(setValue);

            setValue('customer', undefined);
            setCompanyTaxRegime(option?.taxRegime);
            setValue('invoiceValue', 0);
            setSelectedOrders([]);
            setValue('salesOrders', undefined);
            return field.onChange({ id: +option?.value, taxRegime: option?.taxRegime });
        },
        [setValue]
    );

    useEffect(() => {
        if (!!defaultValues) {
            reset(defaultValues);

            if (!!defaultValues?.file?.id) {
                setFile(defaultValues?.file);
            }

            setCompanyTaxRegime(defaultValues?.company?.taxRegime!);
            setSelectedOrders(defaultValues?.salesOrders || []);
        }
    }, [defaultValues, reset, setFile]);

    useEffect(() => {
        if (!selectedOrders.length) {
            return setValue('deductionValue', 0);
        }

        const deductionValuesTotal = deductionValues?.reduce((acc, act) => (acc += act?.deductionValue || 0), 0);

        return setValue('deductionValue', deductionValuesTotal);
    }, [deductionValues, selectedOrders, setValue]);

    useEffect(() => {
        if (!!selectedOrders?.length) {
            const saleOrdersProductsData = saleOrdersProductsRequests.map((item) => item.data);
            const arr: SalesNotePayload['deductionValues'] = [];
            const baseKeyName = isSaleNature ? 'saleOrder' : 'orderService';

            for (const selectedOrderIndex in selectedOrders) {
                const productData = saleOrdersProductsData[selectedOrderIndex];

                if (Boolean(productData)) {
                    const saleOrderTotalDeductionValue = productData!.reduce((acc, act) => {
                        const stockValue = (act.costValue || 0) * (act.quantity || 0);

                        acc += stockValue;

                        return acc;
                    }, 0);

                    arr.push({
                        deductionValue: isSaleNature ? saleOrderTotalDeductionValue : deductionValues?.[selectedOrderIndex]?.deductionValue || 0,
                        [baseKeyName]: selectedOrders[selectedOrderIndex].value,
                    });
                }
            }

            if (!!arr.length && !isEqual(deductionValues, arr)) {
                setValue(`deductionValues`, arr);
            }
        }
    }, [selectedOrders, saleOrdersProductsRequests, isSaleNature, deductionValues, setValue]);

    useEffect(() => {
        if (!!debounceInvoiceValue && !!natureValue && !!selectedsIdOrders && !!customer && !!debounceDeductionValue && !!emissionDate && companyTaxRegime === CompanyTaxRegime.PresumedProfit) {
            const handleGetSalesNotesTaxes = async () => {
                try {
                    const taxes = await getSalesNotesTaxes({
                        deductionValue: debounceDeductionValue,
                        invoiceValue: debounceInvoiceValue,
                        nature: natureValue,
                        emissionDate,
                        ...(!!cityTaxOption?.value && {
                            cityTax: cityTaxOption.value!,
                        }),
                        ...(isSaleNature && {
                            withImportedProducts,
                        }),
                        customer: customer,
                    });

                    if (!isEmpty(taxes)) {
                        if (companyTaxRegime === CompanyTaxRegime.PresumedProfit) {
                            setValue('irpj', taxes?.irpj?.value || 0);
                            setValue('csll', taxes?.csll?.value || 0);
                            setValue('cofins', taxes?.cofins?.value || 0);
                            setValue('pis', taxes?.pis?.value || 0);
                            setValue('irpjAdditional', taxes?.irpjAdditional?.value || 0);

                            if (!isSaleNature) {
                                setValue('iss', taxes?.iss?.value || 0);
                                return setValue('presumedInss', taxes?.inss?.value || 0);
                            }

                            setValue('icms', taxes?.icms?.value || 0);
                        }
                    }
                } catch (e) {
                    console.error('GetSalesNotesTaxes: ', e);
                }
            };

            handleGetSalesNotesTaxes();
        }
    }, [
        debounceInvoiceValue,
        natureValue,
        debounceDeductionValue,
        cityTaxOption,
        companyTaxRegime,
        dasAliquot,
        emissionDate,
        getSalesNotesTaxes,
        setValue,
        isSaleNature,
        selectedsIdOrders,
        customer,
        withImportedProducts,
    ]);

    return (
        <Modal closeOnClickOutside={false} contentClassnames="w-[1000px]">
            {isLoading ? (
                <div className="p-4">
                    <Spinner color={theme.extend.colors.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <FormProvider {...methods}>
                    <form className="px-4 py-5 md:px-7" onSubmit={handleSubmit(onSubmit)}>
                        <Text as="h3" variant="h4" className="text-heading mb-5">
                            {!!saleNoteId ? 'Atualizar nota de venda' : 'Nova nota de venda'}
                        </Text>
                        <div className="md:grid md:grid-cols-4 gap-4 mb-6">
                            <Controller
                                name="nature"
                                control={control}
                                render={({ field }) => {
                                    const value = saleOrderOrigins.find((item) => item.value === +field?.value!);

                                    return (
                                        <Select
                                            {...field}
                                            value={value}
                                            options={saleOrderOrigins}
                                            label="Tipo"
                                            placeholder="Selecione uma opção"
                                            error={(formState.errors.nature as any)?.message}
                                            onChange={(option: any) => {
                                                setValue('company', undefined);
                                                setValue('salesOrders', undefined);
                                                setValue('deductionValues', undefined);
                                                setValue('dasAliquot', 0);
                                                setValue('transactions', undefined);
                                                setValue('customer', undefined);
                                                resetTaxesFields(setValue);
                                                setCompanyTaxRegime(null);
                                                setSelectedOrders([]);
                                                setSelectedOrigin(option.value);
                                                return field.onChange(option.value);
                                            }}
                                        />
                                    );
                                }}
                            />
                            <div className="col-span-3">
                                <Controller
                                    name="company"
                                    control={control}
                                    render={({ field }) => {
                                        const finder = companies.find((item) => +item.id === +field.value?.id!);

                                        const value = {
                                            label: finder?.name,
                                            value: finder?.id,
                                            taxRegime: finder?.taxRegime,
                                        };

                                        return (
                                            <Select
                                                {...field}
                                                value={Boolean(finder) ? value : null}
                                                isLoading={isLoadingCompanies}
                                                options={companyOptions}
                                                label="Empresa"
                                                placeholder="Selecione uma opção"
                                                error={(formState.errors.company?.id as any)?.message}
                                                onChange={handleCompanyChange(field)}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <Controller
                                control={control}
                                name="customer"
                                render={({ field }) => {
                                    const value = customers.find((item) => item.value === field.value);

                                    return (
                                        <Select
                                            {...field}
                                            label="Cliente"
                                            parentClassName="col-span-2"
                                            options={customers}
                                            isLoading={isLoadingCustomers}
                                            value={value || null}
                                            onChange={(option: any) => field.onChange(option.value)}
                                            error={formState.errors.customer?.message}
                                        />
                                    );
                                }}
                            />
                            <div className="col-span-2">
                                <Controller
                                    name="salesOrders"
                                    control={control}
                                    render={({ field }) => {
                                        const options = isSaleNature ? saleOrders : serviceOrders;

                                        const values = options.filter((item) => field?.value?.find((value) => value.value === item.value));

                                        return (
                                            <Select
                                                {...field}
                                                isLoading={isLoadingSaleOrders || isLoadingServiceOrders}
                                                value={values}
                                                isMulti={true}
                                                isClearable={true}
                                                options={options}
                                                label={isSaleNature ? 'Pedidos de venda' : 'Ordens de serviço'}
                                                placeholder="Selecione uma ou mais opções"
                                                error={(formState.errors.salesOrders as any)?.message}
                                                onChange={(option: any) => {
                                                    setSelectedOrders(option);

                                                    return field.onChange(option);
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="col-span-4">
                                <Controller
                                    name="transactions"
                                    control={control}
                                    render={({ field }) => {
                                        const values = receipts.filter((item) => field?.value?.find(({ value }) => value === item?.value));

                                        return (
                                            <Select
                                                {...field}
                                                value={values}
                                                isMulti={true}
                                                options={receipts}
                                                label="Ordens de Recebimento"
                                                placeholder="Selecione uma ou mais opções"
                                                error={(formState.errors.transactions as any)?.message}
                                                onChange={(option: any) => {
                                                    const totalInvoiceValue = option.reduce((acc, curr) => {
                                                        acc += curr.netValue;

                                                        return acc;
                                                    }, 0);

                                                    setValue('invoiceValue', totalInvoiceValue);
                                                    return field.onChange(option);
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            {Boolean(selectedOrders.length) && (
                                <DeductionValuesTable isLoadingSaleOrders={isLoadingSaleOrdersProducts} isSaleNature={isSaleNature} selectedOrdersServices={selectedOrders} />
                            )}

                            <>
                                <div>
                                    <Controller
                                        name="number"
                                        control={control}
                                        render={({ field }) => {
                                            return <Input {...field} value={field?.value} label="N° Documento" error={formState.errors.number?.message} />;
                                        }}
                                    />
                                </div>
                                <Controller
                                    name="emission"
                                    control={control}
                                    render={({ field }) => {
                                        return (
                                            <DatePicker
                                                inputProps={{
                                                    label: 'Data da emissão',
                                                    error: formState.errors.emission?.message,
                                                    isClearable: true,
                                                    ...field,
                                                }}
                                            />
                                        );
                                    }}
                                />

                                <Controller
                                    name="orderServiceNumber"
                                    control={control}
                                    render={({ field }) => {
                                        return (
                                            <Input
                                                {...field}
                                                parentClassName={classNames(!isSaleNature ? 'col-span-2' : '')}
                                                label="Código da Obra"
                                                disabled={isSaleNature}
                                                error={formState.errors.orderServiceNumber?.message}
                                            />
                                        );
                                    }}
                                />
                                {isSaleNature && (
                                    <Controller
                                        name="withImportedProducts"
                                        control={control}
                                        render={({ field }) => {
                                            const value = booleanOptions.find((item) => item.value === field.value);

                                            return (
                                                <Select
                                                    {...field}
                                                    options={booleanOptions}
                                                    value={value}
                                                    label="Com produtos importados"
                                                    onChange={(option: any) => field.onChange(option.value)}
                                                    error={formState.errors.withImportedProducts?.message}
                                                />
                                            );
                                        }}
                                    />
                                )}
                                <div className="col-span-2 mb-4 flex flex-col justify-center relative">
                                    <Text as="p" className="text-sm block mb-1.5 text-base-500">
                                        Arquivo
                                    </Text>
                                    <Controller
                                        name="file"
                                        control={control}
                                        render={({ field }) => {
                                            const handleChangeFile = (filesList: File[]) => {
                                                const [file] = filesList || [];

                                                setFile(file);
                                                field.onChange(file);
                                            };

                                            const handleClickRemove = () => {
                                                setFile(undefined);
                                                setValue('file', undefined);
                                            };

                                            const { value } = field;

                                            if (Boolean(file)) {
                                                const link = value?.path && value.filename ? generateFileUrl(value?.filename, value?.path) : URL.createObjectURL(file);

                                                return (
                                                    <div className="flex items-center relative">
                                                        {!!value?.id ? (
                                                            <DownloadButton fileName={value?.filename!} fileId={value.id} />
                                                        ) : (
                                                            <a className="flex items-center" href={link} rel="noreferrer" target="_blank">
                                                                <div className="flex flex-col">
                                                                    <span className="font-medium text-heading">{file?.name || file?.originalName || file?.filename}</span>
                                                                    <span className="italic text-sm">Clique para visualizar o arquivo.</span>
                                                                </div>
                                                            </a>
                                                        )}
                                                        <RemoveButton onClick={handleClickRemove} className="opacity-100 right-[0px] top-[50%] translate-y-[-50%]" />
                                                    </div>
                                                );
                                            }

                                            return <Dropzone svgClasses="!mb-0 mr-2" containerClasses="h-[48px] flex !flex-row" multiple={false} onUploadFiles={handleChangeFile} />;
                                        }}
                                    />
                                    <ErrorMessage className="mt-2" visible={Boolean(formState.errors?.file)}>
                                        Selecione um arquivo
                                    </ErrorMessage>
                                </div>
                                <div>
                                    <Controller
                                        name="invoiceValue"
                                        control={control}
                                        render={({ field }) => {
                                            return (
                                                <CurrencyInput
                                                    ref={field.ref}
                                                    name={field.name}
                                                    value={field?.value}
                                                    disabled={true}
                                                    aria-disabled={true}
                                                    label="Valor da Nota"
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    error={(formState.errors.invoiceValue as any)?.message}
                                                    onValueChange={({ floatValue }) => field.onChange(floatValue)}
                                                />
                                            );
                                        }}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        name="deductionValue"
                                        control={control}
                                        render={({ field }) => {
                                            return (
                                                <CurrencyInput
                                                    name={field.name}
                                                    aria-disabled={true}
                                                    disabled={true}
                                                    readOnly={true}
                                                    value={field.value || 0}
                                                    fixedDecimalScale={true}
                                                    label="Valor Dedução"
                                                    decimalScale={2}
                                                    error={(formState.errors?.deductionValue as any)?.message}
                                                />
                                            );
                                        }}
                                    />
                                </div>
                                {companyTaxRegime === CompanyTaxRegime.Simple && <FieldsSimpleTaxRegime invoiceValue={debounceInvoiceValue} />}
                                {companyTaxRegime === CompanyTaxRegime.PresumedProfit && <FieldsPresumedProfitTaxRegime isLoading={isGettingTaxes} taxes={taxes} isSaleNature={isSaleNature} />}
                            </>
                        </div>
                        <FormButtons isLoading={isSubmittingForm} />
                    </form>
                </FormProvider>
            )}
        </Modal>
    );
};

export default memo(CreateOrUpdateSalesNote);
