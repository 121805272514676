import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';
import { Status } from 'types/general';
import { WhereClause } from 'types/graphql';
import { OrderServiceStatus } from 'types/models/order-service';

export const generateOrderServiceStatusOptionsQuery = (where?: WhereClause<Partial<OrderServiceStatus>>['where']) => {
    return jsonToGraphQLQuery(
        {
            query: {
                __name: 'GetOrderServiceStatusOptions',
                items: {
                    __aliasFor: 'orderServiceStatus',
                    __args: {
                        orderBy: { title: new EnumType('ASC') },
                        where: {
                            status: { _eq: Status.Active },
                            ...(Boolean(where) && where)
                        }
                    },
                    value: { __aliasFor: 'id' },
                    label: { __aliasFor: 'title' }
                }
            }
        },
        { pretty: true }
    );
};
