import { createContext, PropsWithChildren, useContext, useState } from 'react';

type ReportsContextTypes = {
    showValues: boolean;
    toggleShowValues?: () => void;
};

const ReportsContext = createContext<ReportsContextTypes>({ showValues: true });

const ReportsContextProvider = ({ children }: PropsWithChildren) => {
    const [showValues, setShowValues] = useState(true);

    const values = {
        showValues,
        toggleShowValues: setShowValues.bind(this, (prev) => !prev),
    };

    return <ReportsContext.Provider value={values}>{children}</ReportsContext.Provider>;
};

const useReportsContext = () => {
    return useContext(ReportsContext);
};

export { useReportsContext, ReportsContextProvider };
