import { months } from 'utils/date';
import ReportPageDetailsBlockTable from '../details/block/table';
import { ReportPageByYearResponse, ReportPageResponse, TableItem, TableItemValuesValue } from 'types/models/reports';
import Text from 'components/core/text';
import ReportPageGroupedYearsChartBar from './bar';
import ReportPageDetailsChartDoughnut from './doughnut';
import { formatMoney } from 'utils/money';

type ReportPageByYearsDetailsProps = {
    dataYears: ReportPageByYearResponse[];
    isLoading: boolean;
    years: number[];
    detailsData?: ReportPageResponse;
};

const ReportPageByYearsDetails = ({ dataYears = [], years = [], isLoading = true, detailsData }: ReportPageByYearsDetailsProps) => {
    const headers = years.map((item) => ({ title: item.toString(), className: 'max-w-[250px]' }));
    const monthsWithTotal = [...months, 'Total'];

    const yearsItems = monthsWithTotal.map((month, indexMonth) => {
        const isTotalIndex = indexMonth === monthsWithTotal.length - 1;

        const cellValues = dataYears.map((item): TableItemValuesValue => {
            const monthItem = item?.months[indexMonth];

            return {
                value: isTotalIndex ? item?.resume?.total : monthItem?.value || 0,
                type: 'currency',
                className: isTotalIndex ? 'font-bold text-base-500 !w-[120px]' : 'font-normal text-base-500 max-w-[250px] w-[250px]',
            };
        });

        return {
            values: [
                {
                    title: month,
                    values: cellValues,
                    className: isTotalIndex ? 'font-bold w-[120px] max-w-[120px]' : 'font-normal',
                },
            ],
        };
    });

    const blocksItems = (blockId: number): TableItem[] => {
        return monthsWithTotal.map((month, indexMonth): TableItem => {
            const isTotalIndex = indexMonth === monthsWithTotal.length - 1;

            const cellValues = dataYears.map((item, index): TableItemValuesValue => {
                const blockItem = item?.blocks?.find((block) => block.id === blockId);
                const blockItemByMonth = blockItem?.reportMonths[indexMonth];

                const totalYears = yearsItems.at(-1);
                const totalYearsValue = totalYears?.values?.[0]?.values?.[index]?.value || 0;

                const percentageValue = ((blockItem?.resume?.total || 0) / (+totalYearsValue || 0)) * 100;

                return {
                    value: (isTotalIndex ? blockItem?.resume?.total : blockItemByMonth?.value) || 0,
                    type: 'custom',
                    customComponent: (value) => (
                        <div className="p-2">
                            <div className="flex justify-between gap-2">
                                <span>R$</span>
                                <span>{formatMoney(value)}</span>
                            </div>
                            {isTotalIndex && <span className="block w-full text-right text-xs">{isFinite(percentageValue) ? percentageValue.toFixed(2) : 0}%</span>}
                        </div>
                    ),
                    className: isTotalIndex ? 'font-bold text-base-500' : 'font-normal text-base-500 max-w-[250px] w-[250px]',
                };
            });

            return {
                values: [
                    {
                        title: month,
                        values: cellValues,
                        className: isTotalIndex ? 'font-bold' : 'font-normal',
                    },
                ],
            };
        });
    };

    return (
        <>
            <div className="grid md:grid-cols-4 gap-4 mb-6">
                <ReportPageGroupedYearsChartBar doughnutChartId={`doughnut-chart-card`} years={years} dataBlock={dataYears || []} />
                <ReportPageDetailsChartDoughnut doughnutChartId={`doughnut-chart-card`} years={years} blocks={dataYears || []} />
            </div>
            <ReportPageDetailsBlockTable emptyHeaderColumnWidth="120px" isLoading={isLoading} headers={[headers]} items={yearsItems} fontSize={14} className="mb-10" />

            {!isLoading &&
                detailsData?.blocks?.map((item, index) => {
                    const blocks = dataYears.map((requests) => {
                        const blockItem = requests?.blocks?.find((block) => block.id === item.id);

                        return blockItem!;
                    });

                    return (
                        <div key={item?.id} className="mb-10">
                            <Text as="h1" variant="h4" className="text-heading mb-6">
                                {item?.name || '-'}
                            </Text>
                            <div className="grid md:grid-cols-4 gap-4 mb-6">
                                <ReportPageGroupedYearsChartBar doughnutChartId={`doughnut-chart-card_${item?.id}_${index}`} years={years} dataBlock={blocks || []} />
                                <ReportPageDetailsChartDoughnut doughnutChartId={`doughnut-chart-card_${item?.id}_${index}`} years={years} blocks={blocks || []} />
                            </div>
                            <ReportPageDetailsBlockTable emptyHeaderColumnWidth="120px" key={item?.id} isLoading={isLoading} headers={[headers]} items={blocksItems(item?.id)} fontSize={14} />
                        </div>
                    );
                })}
        </>
    );
};

export default ReportPageByYearsDetails;
