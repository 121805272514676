import { useShowApiErrors } from 'hooks/error/show-api-errors';
import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import { getImportFlowKey } from './keys';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';
import useToast from 'hooks/toast/use-toast';

const useCreateNationalization = (importFlowId?: string) => {
    const { showToastErrors } = useShowApiErrors();
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const goToParentRoute = useNavigateToParentRoute();

    const request = async (payload: any) => {
        const { data } = await api.post(`/imports/${importFlowId}/expenses`, payload);

        return data;
    };

    return useMutation(request, {
        onError: (error: any) => {
            showToastErrors(error, error?.response?.data?.title);
        },
        onSuccess: () => {
            showToast('Nacionalização criada com sucesso!', 'success');
            queryClient.invalidateQueries(getImportFlowKey(importFlowId));
            goToParentRoute();
        },
    });
};

export default useCreateNationalization;
