import Select from 'components/core/form/select';
import { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Button from 'components/core/button';
import { useTableAdvancedSearch } from 'components/core/table/datatable/components/advanced-search';
import { useListPageContext } from 'pages/shared/crud/list/context';
import qs from 'qs';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { number, object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useGetQueryParam from 'hooks/router/use-get-query-param';
import { transactionsTypeOptions } from 'utils/statics';
import FilterPeriod from 'pages/private/payment-order/filter/period';
import dictionary from 'utils/dictionary';

type Payload = {
    type?: number | null;
    startPayday: string;
    endPayday: string;
};

type BankAccountTransactionsFiltersProps = {
    onSetPage: (page: number) => void;
};

const schema = object({
    startPayday: string().required(dictionary.validation.required),
    endPayday: string().required(dictionary.validation.required),
    type: number().nullable(),
});

const BankAccountTransactionsFilters = ({ onSetPage }: BankAccountTransactionsFiltersProps) => {
    const { onClose } = useTableAdvancedSearch();
    const { setHasSelectedFilters } = useListPageContext();

    const { control, handleSubmit, reset, formState } = useForm<Payload>({
        mode: 'onSubmit',
        resolver: yupResolver(schema),
    });

    const navigate = useNavigate();

    const type = useGetQueryParam('filter[type]');
    const endPayday = useGetQueryParam('filter[endPayday]');
    const startPayday = useGetQueryParam('filter[startPayday]');

    const submit = useCallback(
        (data: Payload) => {
            onSetPage(1);
            const payload = {
                startPayday: Boolean(data?.startPayday) ? dayjs(data.startPayday).format('YYYY-MM-DD') : null,
                endPayday: Boolean(data.endPayday) ? dayjs(data.endPayday).format('YYYY-MM-DD') : null,
                type: data.type,
            };

            const filters = qs.stringify(
                { filter: payload },
                {
                    addQueryPrefix: true,
                    encode: false,
                    arrayFormat: 'brackets',
                    skipNulls: true,
                }
            );

            setHasSelectedFilters?.(Boolean(filters));

            navigate(filters);

            onClose();
        },
        [setHasSelectedFilters, onClose, navigate, onSetPage]
    );

    useEffect(() => {
        reset({
            type: Boolean(type) ? Number(type) : null,
            startPayday: !startPayday ? dayjs().date(1).toISOString() : dayjs(startPayday).toISOString(),
            endPayday: !endPayday ? dayjs().utc(true).endOf('month').toISOString() : dayjs(endPayday).toISOString(),
        });

        setHasSelectedFilters?.(true);

        // eslint-disable-next-line
    }, [reset]);

    return (
        <form onSubmit={handleSubmit(submit)}>
            <div className="mb-5">
                <FilterPeriod
                    containerClasses="mb-4"
                    control={control}
                    label="Período de pagamento"
                    firstInput={{
                        inputProps: {
                            label: 'Início',
                            name: 'startPayday',
                            error: formState.errors.startPayday?.message,
                            isClearable: true,
                        },
                    }}
                    secondInput={{
                        inputProps: {
                            label: 'Fim',
                            name: 'endPayday',
                            error: formState.errors.endPayday?.message,
                            isClearable: true,
                        },
                    }}
                />
                <Controller
                    name="type"
                    control={control}
                    render={({ field }) => {
                        const value = transactionsTypeOptions.find((item) => item.value.toString() === field.value?.toString());

                        return <Select {...field} isClearable={true} value={value} label="Tipo" options={transactionsTypeOptions} onChange={(option: any) => field.onChange(option?.value)} />;
                    }}
                />
            </div>
            <div className="flex">
                <Button type="submit" variant="contained" color="secondary" className="min-w-[100px] ml-auto">
                    Filtrar
                </Button>
            </div>
        </form>
    );
};

export default BankAccountTransactionsFilters;
