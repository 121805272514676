import Button from 'components/core/button';
import Checkbox from 'components/core/form/checkbox';
import Input from 'components/core/form/input';
import Select from 'components/core/form/select';
import Text from 'components/core/text';
import FilterPeriod from 'pages/private/payment-order/filter/period';
import { Controller, useForm } from 'react-hook-form';
import useGetCompaniesOptions from 'services/queries/companies/use-get-company-options';
import { TransactionInvoiceStatus } from 'types/models/transaction';

export type DeductionFiltersPayload = {
    code: string;
    dueDateStartDate?: string;
    dueDateEndDate?: string;
    expense?: { companyId?: number };
    invoiceStatus?: TransactionInvoiceStatus;
};

type DeductionFiltersProps = {
    isLoadingTransactions: boolean;
    onFilter: (data: DeductionFiltersPayload) => void;
};

const INITIAL_VALUES = {
    code: '',
    dueDateStartDate: '',
    dueDateEndDate: '',
    invoiceStatus: undefined,
    expense: { companyId: undefined },
};

const DeductionFilters = ({ isLoadingTransactions, onFilter }: DeductionFiltersProps) => {
    const { data: companies = [] } = useGetCompaniesOptions();

    const { control, setValue, handleSubmit } = useForm<DeductionFiltersPayload>({
        defaultValues: INITIAL_VALUES,
    });

    return (
        <div className="border border-base-300 rounded-[14px] p-4">
            <Text variant="h6" className="text-heading mb-4 block">
                Buscar ordens de pagamento
            </Text>
            <div className="flex flex-col md:grid grid-cols-4 gap-4">
                <Controller control={control} name="code" render={({ field }) => <Input {...field} label="Código" placeholder="Digite o código da transação" />} />
                <FilterPeriod
                    containerClasses="col-span-1 sm:col-span-2 2xl:col-span-1"
                    label="Período de vencimento"
                    control={control}
                    firstInput={{
                        inputProps: {
                            label: 'Início',
                            name: 'dueDateStartDate',
                        },
                    }}
                    secondInput={{
                        inputProps: {
                            label: 'Fim',
                            name: 'dueDateEndDate',
                        },
                    }}
                />
                <Controller
                    name="expense.companyId"
                    control={control}
                    render={({ field }) => {
                        const value = companies.find((item) => item.value === field.value);

                        return <Select {...field} value={value} options={companies} label="Empresa" placeholder="Selecione uma opção" onChange={(option: any) => field.onChange(option.value)} />;
                    }}
                />
                <Controller
                    name="invoiceStatus"
                    control={control}
                    render={({ field }) => (
                        <Checkbox
                            {...field}
                            value={field.value}
                            label="Com nota fiscal?"
                            checked={Boolean(field.value)}
                            containerClasseName="border border-base-300 rounded-[14px] p-[13px] self-end"
                            onChange={(event) => {
                                const isChecked = event.currentTarget.checked;
                                setValue('invoiceStatus', isChecked ? TransactionInvoiceStatus.Sent : 0);
                            }}
                        />
                    )}
                />
                <div className="flex items-center justify-end md:justify-start">
                    <Button
                        loading={isLoadingTransactions}
                        disabled={isLoadingTransactions}
                        type="button"
                        variant="contained"
                        color="secondary"
                        className="min-w-[100px]"
                        onClick={handleSubmit(onFilter)}>
                        Buscar OP's
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default DeductionFilters;
