import IconButton from '@mui/material/IconButton/IconButton';
import useTheme from '@mui/material/styles/useTheme';
import SvgIcoDots from 'components/core/icon/files/ico-dots';
import Menu from 'components/menu';
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useGetDownloadFile from 'services/queries/files/use-get-download-file';
import { SalesReceiptResponse } from 'types/models/sales-notes';
import { hexToRgba } from 'utils/string';

type SaleNoteActionsProps = {
    noteSaleReceipt?: SalesReceiptResponse;
    saleNoteId?: number;
    onSetSaleNoteIdToDelete: Dispatch<SetStateAction<number | null>>;
    onSetIsFinalizingSaleNote: Dispatch<SetStateAction<boolean>>;
};

const SaleNoteActions = ({ noteSaleReceipt, saleNoteId, onSetSaleNoteIdToDelete, onSetIsFinalizingSaleNote }: SaleNoteActionsProps) => {
    const { palette } = useTheme();
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const { mutateAsync: getFileToDownload } = useGetDownloadFile();

    const handleClose = () => setAnchorEl(null);
    const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

    const handleDownloadFile = useCallback(async () => {
        try {
            if (!noteSaleReceipt?.file.id) {
                return;
            }

            await getFileToDownload(noteSaleReceipt.file.id);
        } catch (error) {
            console.log('handleDownloadFile', error);
        }
    }, [noteSaleReceipt?.file.id, getFileToDownload]);

    const content = useMemo(() => {
        return [
            {
                label: 'Download',
                icon: {
                    color: palette.info[500],
                    name: 'ico-download',
                },
                onClick: () => handleDownloadFile(),
            },
            {
                label: 'Finalizar',
                icon: {
                    color: palette.success[500],
                    name: 'ico-check-square',
                },
                onClick: () => {
                    handleClose();
                    onSetIsFinalizingSaleNote(true);
                },
            },
            {
                label: 'Editar',
                icon: {
                    color: palette.warning[500],
                    name: 'ico-edit',
                },
                onClick: () => navigate(`../editar/${saleNoteId}`),
            },
        ];
    }, [palette, handleDownloadFile, onSetIsFinalizingSaleNote, navigate, saleNoteId]);

    const footer = useMemo(() => {
        return [
            {
                className: 'text-system-danger-500',
                label: 'Excluir',
                icon: {
                    color: palette.error.main,
                    name: 'ico-trash',
                },
                onClick: () => {
                    onSetSaleNoteIdToDelete(+saleNoteId!);
                    setAnchorEl(null);
                },
            },
        ];
    }, [palette, saleNoteId, onSetSaleNoteIdToDelete]);

    return (
        <>
            <IconButton sx={{ backgroundColor: !!anchorEl ? hexToRgba(palette.primary[100], 0.3) : palette.grey[100] }} color="primary" onClick={handleOpen}>
                <SvgIcoDots />
            </IconButton>
            <Menu className="z-[9999]" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} content={content} footer={footer} />
        </>
    );
};

export default SaleNoteActions;
