import { jsonToGraphQLQuery } from 'json-to-graphql-query/lib/jsonToGraphQLQuery';
import { EnumType } from 'json-to-graphql-query/lib/types/EnumType';
import { VariableType } from 'json-to-graphql-query/lib/types/VariableType';

export const getNotificationsKey = ['notifications', 'all'];

export const getAllNotificationsQuery = jsonToGraphQLQuery(
    {
        query: {
            __name: 'GetAllNotifications',
            __variables: {
                userId: 'Int!',
            },
            items: {
                __aliasFor: 'notification',
                __args: {
                    where: {
                        userId: { _eq: new VariableType('userId') },
                        read: { _eq: false },
                    },
                    orderBy: {
                        createdAt: new EnumType('DESC'),
                    },
                },
                id: true,
                read: true,
                pageUri: true,
                readDate: true,
                subject: true,
                text: true,
                createdAt: true,
            },
        },
    },
    { pretty: true }
);

export const readNotificationQuery = jsonToGraphQLQuery(
    {
        mutation: {
            __variables: {
                ids: '[Int!]!',
                payload: 'NotificationSetInput!',
            },
            updateNotification: {
                __args: {
                    where: {
                        id: {
                            _in: new VariableType('ids'),
                        },
                    },
                    _set: new VariableType('payload'),
                },
                returning: {
                    id: true,
                },
            },
        },
    },
    { pretty: true }
);
