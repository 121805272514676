import { useQuery } from 'react-query';
import api from 'services/api';

import { getSaleOrderSchedulesKey } from './keys';
import { SaleOrderSchedulesResponse } from 'types/models/sale-order';

const useGetSaleOrderSchedules = (saleOrderId?: string) => {
    const request = async () => {
        const { data } = await api.get<SaleOrderSchedulesResponse[]>(`/sale-orders/${saleOrderId}/schedules`);

        return data;
    };

    return useQuery(getSaleOrderSchedulesKey(saleOrderId), request, { enabled: Boolean(saleOrderId) });
};

export default useGetSaleOrderSchedules;
