export const getPurchaseOrderKey = (purchaseOrderId?: string) => ['purchase-order', purchaseOrderId];
export const getPurchaseOrderProductsKey = (purchaseOrderProductIds?: string[]) => ['purchase-order', 'products', purchaseOrderProductIds];
export const getPendingPurchaseOrderProductsKey = (endpoint: string, filters: any, page?: number, totalPerPage?: number) => [
    'purchase-order',
    'products',
    'pending',
    endpoint,
    filters,
    page,
    totalPerPage,
];
export const getPendingPurchaseOrderProductsApiKey = (purchaseOrderId?: string) => ['purchase-order', purchaseOrderId, 'products'];
export const getAllPurchaseOrderProductsApiKey = (endpoint: string) => ['purchase-order', 'all', 'products', endpoint];
export const getPurchaseOrdersKey = (endpoint: string) => ['purchase-orders', endpoint];
