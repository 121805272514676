import classNames from 'classnames';
import Text from 'components/core/text';
import CardsDetails from 'pages/private/sales-notes/components/cards';
import { formatNumberToPTBR } from 'utils/money';

type StockProductHeaderProps = {
    value?: number;
    quantity?: number;
    unitName?: string;
    containerClassName?: string;
};

const StockProductHeader = ({ value = 0, quantity = 0, unitName = '-', containerClassName }: StockProductHeaderProps) => {
    return (
        <div className={classNames('grid grid-cols-1 lg:grid-cols-3', containerClassName)}>
            <CardsDetails label="Valor Unitário" withIcon={true} value={value} />
            <CardsDetails
                withIcon={true}
                content={
                    <>
                        <Text as="p" className="text-base-500 font-normal text-sm mb-2">
                            {`Quantidade (${unitName})`}
                        </Text>
                        <Text as="strong" className="font-medium text-xl text-heading flex-1 mb-3">
                            {formatNumberToPTBR(quantity)}
                        </Text>
                    </>
                }
            />
            <CardsDetails label="Valor Total" withIcon={true} value={value * quantity} />
        </div>
    );
};

export default StockProductHeader;
