import * as React from 'react';
const SvgIcoFileText = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        className="ico-file-text_svg__feather ico-file-text_svg__feather-file-text"
        viewBox="0 0 24 24"
        width="1em"
        height="1em"
        {...props}>
        <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
        <path d="M14 2v6h6m-4 5H8m8 4H8m2-8H8" />
    </svg>
);
export default SvgIcoFileText;
