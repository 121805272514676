import OrderServicesReportTotals from './totals';
import OrderServicesReportFilters from './filters';
import { DataContentFiltersConfig, GenericReportFilterPayload, OrderServicesReportTotalsValues } from '../../types';
import ReportPageHeader from '../../../components/report-page-header';
import { memo, useState } from 'react';
import { OrderServicesReportFiltersPayload } from 'types/models/reports';

type OrderServicesReportHeaderProps = {
    onSetFilters: (data?: GenericReportFilterPayload<OrderServicesReportFiltersPayload>) => void;
    orderServicesTotals: OrderServicesReportTotalsValues;
    filters?: GenericReportFilterPayload<OrderServicesReportFiltersPayload>;
    dataContentFiltersConfig: DataContentFiltersConfig;
};

const OrderServicesReportHeader = ({ orderServicesTotals, filters, onSetFilters, dataContentFiltersConfig }: OrderServicesReportHeaderProps) => {
    const [isFilterVisible, setIsFilterVisible] = useState(false);

    return (
        <>
            <ReportPageHeader title="Ordens de serviço" onOpenFilters={setIsFilterVisible.bind(this, true)} />
            <OrderServicesReportTotals orderServicesTotals={orderServicesTotals} />
            {isFilterVisible && (
                <OrderServicesReportFilters dataContentFiltersConfig={dataContentFiltersConfig} filters={filters} onSetFilters={onSetFilters} onCloseFilters={setIsFilterVisible.bind(this, false)} />
            )}
        </>
    );
};

export default memo(OrderServicesReportHeader);
