import IconButton from '@mui/material/IconButton';
import useTheme from '@mui/material/styles/useTheme';
import SvgIcoPlus from 'components/core/icon/files/ico-plus';
import ConfirmModal from 'components/core/modal/confirm';
import Datatable from 'components/core/table/datatable';
import PageHeader from 'components/page/header';
import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { Link, Outlet } from 'react-router-dom';
import useDelete from 'services/queries/crud/use-delete';
import { getPaginatedWithFiltersKey } from 'services/queries/general/keys';
import useGetPaginatedWithFilters from 'services/queries/general/use-get-paginated-with-filters';
import { salesOrdersColumns } from 'settings/routes/sales-orders/utils';
import useConfig from 'store/config/use-config';
import { SaleOrder } from 'types/models/sale-order';
import { Rule } from 'types/permissions';

const ENDPOINT = '/sale-orders';

const SalesOrdersPage = ({ page: pagePermissions }: WithPagePermissionsProps) => {
    const [filters, setFilters] = useState<any[]>([]);
    const [orderBy, setOrderBy] = useState<any>(null);
    const [page, setPage] = useState(1);
    const [saleOrderToDeleteId, setSaleOrderToDeleteId] = useState<any>(null);

    const { palette } = useTheme();
    const queryClient = useQueryClient();
    const { config } = useConfig();

    const { data } = useGetPaginatedWithFilters<Partial<SaleOrder>>({
        endpoint: ENDPOINT,
        filters,
        page,
        orderBy,
    });
    const { mutateAsync: deleteSaleOrder, isLoading: isDeletingSaleOrder } = useDelete({
        apiPayload: { endpoint: `/sale-orders`, useApiTodelete: true },
    });

    const handleDeleteSaleOrder = async () => {
        try {
            if (!!saleOrderToDeleteId) {
                await deleteSaleOrder({ id: saleOrderToDeleteId });

                queryClient.invalidateQueries(getPaginatedWithFiltersKey(ENDPOINT, filters, page, config.tables.rowsPerPage, orderBy));
                setSaleOrderToDeleteId(null);
            }
        } catch (e) {
            console.error('handleDeleteSaleOrder: ', e);
        }
    };

    const actions = pagePermissions?.rules.map((item) => item.key);
    const canCreateSaleOrder = actions?.includes(Rule.Create);

    return (
        <>
            <PageHeader
                className="mb-6"
                title="Pedidos de venda"
                right={
                    canCreateSaleOrder ? (
                        <IconButton component={Link} to="novo" color="secondary" size="large" sx={{ backgroundColor: palette.secondary.main, '&:hover': { backgroundColor: palette.secondary.dark } }}>
                            <SvgIcoPlus width={16} height={16} color={palette.grey[100]} />
                        </IconButton>
                    ) : undefined
                }
            />
            <Datatable
                data={data?.items || []}
                columns={salesOrdersColumns || []}
                actions={actions}
                onDelete={setSaleOrderToDeleteId}
                options={{
                    searchPlaceholder: 'Buscar pedido de venda',
                    sort: true,
                    filter: false,
                    search: true,
                    print: false,
                    download: false,
                    viewColumns: false,
                    serverSide: true,
                    searchAlwaysOpen: true,
                    enableNestedDataAccess: '.',
                    pagination: true,
                    elevation: 21,
                    selectableRows: 'none',
                    count: data?.total,
                    page,
                    onChangePage: setPage,
                    onSearchChange: (searchText) => {
                        setFilters((prev) => ({ ...prev, searchText }));
                    },
                    onColumnSortChange: (changedColumn, direction) => {
                        setOrderBy({ [changedColumn]: direction.toUpperCase() });
                    },
                }}
            />
            <Outlet />
            {Boolean(saleOrderToDeleteId) && (
                <ConfirmModal
                    onConfirmAction={handleDeleteSaleOrder}
                    onClose={setSaleOrderToDeleteId.bind(this, null)}
                    description="Você tem certeza que deseja apagar este pedido de venda?"
                    title="Apagar pedido de venda"
                    isLoading={isDeletingSaleOrder}
                />
            )}
        </>
    );
};

export default SalesOrdersPage;
