import { WhereClause } from 'types/graphql';
import { convertArrayToObject } from './array';
import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';
import { Status } from 'types/general';

export type QueryConfig<T> = {
    name: string;
    table: string;
    fields: string[];
    id?: number | string;
    where?: WhereClause<Partial<T>>['where'];
    orderByKey?: string;
};

export const generateQuery = <T>(config: QueryConfig<T>) => {
    const { fields, name, table, id, where, orderByKey } = config;

    if (!fields.length) {
        throw new Error('Missing query fields');
    }

    const fieldsToGraphql = convertArrayToObject(fields);

    return jsonToGraphQLQuery(
        {
            query: {
                __name: name,
                [table]: {
                    __args: {
                        ...(Boolean(orderByKey) && {
                            orderBy: { [orderByKey!]: new EnumType('ASC') },
                        }),
                        ...(Boolean(id) && { id }),
                        ...(Boolean(where) && {
                            where: {
                                status: { _eq: Status.Active },
                                ...where,
                            },
                        }),
                    },
                    ...fieldsToGraphql,
                },
            },
        },
        { pretty: true }
    );
};
