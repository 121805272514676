import TableCell from '@mui/material/TableCell/TableCell';
import classNames from 'classnames';
import Badge from 'components/core/badge';
import Text from 'components/core/text';
import { getNumberValueOrZero } from 'pages/private/expenses/details/tabs/transactions/utils';
import { generateInventoryMovementColors } from 'pages/private/stocks/view/products/utils';
import { Link } from 'react-router-dom';
import { GetOrderServicesBalanceTotalReponse } from 'services/queries/order-services/use-get-order-services-balance-total';
import { DatatableColumn } from 'types/graphql';
import { formatDate } from 'utils/date';
import { formatMoney, formatNumberToPTBR } from 'utils/money';
import { stockOrderTypes } from 'utils/statics';

export const TH = (value: any) => (
    <TableCell key={value.label}>
        <Text variant="body.medium.sm" as="span" className="text-secondary-500 whitespace-nowrap">
            {value.label}
        </Text>
    </TableCell>
);

export const serviceOrderFinancialBalanceListColumns: DatatableColumn[] = [
    {
        name: 'id',
        options: { display: 'excluded' },
    },
    {
        name: 'product.name',
        label: 'Produto',
        options: {
            customHeadRender: TH,
            customBodyRender: (value, tableMeta) => {
                const productCode = tableMeta.rowData.at(-1);
                const id = tableMeta.rowData[0];

                return (
                    <Link to={id.toString()}>
                        <Text as="span" className="text-secondary-700 font-medium whitespace-nowrap">
                            {Boolean(value) && Boolean(productCode) ? `#${productCode} - ${value}` : '-'}
                        </Text>
                    </Link>
                );
            },
        },
    },
    {
        name: 'quantity',
        label: 'Quantidade',
        options: {
            customBodyRender: (value) => formatNumberToPTBR(Number(value)),
            customHeadRender: TH,
        },
    },
    {
        name: 'value',
        label: 'Valor Unitário',
        options: {
            customBodyRender: (value) => formatMoney(Number(value)),
            customHeadRender: TH,
        },
    },
    {
        name: 'budget',
        label: 'Budget',
        options: {
            customBodyRender: (value) => formatMoney(Number(value)),
            customHeadRender: TH,
        },
    },
    {
        name: 'dataBalance.totalExpense',
        label: 'Total Despesa',
        options: {
            customBodyRender: (value) => formatMoney(Number(value)),
            customHeadRender: TH,
        },
    },
    {
        name: 'dataBalance.totalExpensePaid',
        label: 'Total Pago',
        options: {
            customBodyRender: (value) => formatMoney(Number(value)),
            customHeadRender: TH,
        },
    },
    {
        name: 'dataBalance.totalExpenseOpen',
        label: 'A Pagar',
        options: {
            customBodyRender: (value) => formatMoney(Number(value)),
            customHeadRender: TH,
        },
    },
    {
        name: 'dataBalance.refund',
        label: 'Estorno',
        options: {
            customBodyRender: (value) => {
                return <span className={value > 0 ? 'text-system-success-500' : ''}>{formatMoney(Number(value))}</span>;
            },

            customHeadRender: TH,
        },
    },
    {
        name: 'dataBalance',
        label: 'Saldo',
        options: {
            customBodyRender: (dataBalance) => {
                let dataBalanceClass = 'text-system-default-500';

                if (dataBalance.expenseBalance != undefined && dataBalance.expenseBalance != 0) {
                    dataBalanceClass = dataBalance.expenseBalance > 0 ? 'text-system-success-500' : 'text-system-danger-500';
                }

                return (
                    <div className={classNames('flex justify-between items-center', dataBalanceClass)}>
                        <Text as="span" className="font-medium whitespace-nowrap mr-1">
                            {formatMoney(Number(dataBalance.expenseBalance))}
                        </Text>
                        <Text as="span" className="!text-xs whitespace-nowrap block">
                            ({formatNumberToPTBR(dataBalance.expensePercentageBalance || 0)}%)
                        </Text>
                    </div>
                );
            },
            customHeadRender: TH,
        },
    },
    {
        name: 'product.code',
        options: {
            display: 'excluded',
        },
    },
];

const generateFinancialBalanceValue = (value?: number) => {
    const newValue = getNumberValueOrZero(value);

    if (newValue < 0) {
        return 0;
    }

    return newValue;
};

const taxContentClasses = (newClasses?: string) => classNames('text-system-danger-500', newClasses);

export const generateFinancialBalanceTableTaxContent = (taxes?: GetOrderServicesBalanceTotalReponse['taxes']) => {
    const totalTaxes = Object.entries(taxes || {}).reduce((acc, [, { value = 0 }]) => {
        const newValue = value < 0 ? 0 : value;

        return acc + newValue;
    }, 0);

    return [
        {
            id: 0,
            label: 'COFINS',
            value: generateFinancialBalanceValue(taxes?.cofins?.value),
            percentage: generateFinancialBalanceValue(taxes?.cofins?.percentage),
            className: taxContentClasses(),
        },
        {
            id: 1,
            label: 'CSLL',
            value: generateFinancialBalanceValue(taxes?.csll?.value),
            percentage: generateFinancialBalanceValue(taxes?.csll?.percentage),
            className: taxContentClasses(),
        },
        {
            id: 2,
            label: 'ICMS',
            value: generateFinancialBalanceValue(taxes?.icms?.value),
            percentage: generateFinancialBalanceValue(taxes?.icms?.percentage),
            className: taxContentClasses(),
        },
        {
            id: 3,
            label: 'IRPJ',
            value: generateFinancialBalanceValue(taxes?.irpj?.value),
            percentage: generateFinancialBalanceValue(taxes?.irpj?.percentage),
            className: taxContentClasses(),
        },
        {
            id: 4,
            label: 'IRPJ ADICIONAL',
            value: generateFinancialBalanceValue(taxes?.irpjAdditional?.value),
            percentage: generateFinancialBalanceValue(taxes?.irpjAdditional?.percentage),
            className: taxContentClasses(),
        },
        {
            id: 5,
            label: 'PIS',
            value: generateFinancialBalanceValue(taxes?.pis?.value),
            percentage: generateFinancialBalanceValue(taxes?.pis?.percentage),
            className: taxContentClasses(),
        },
        {
            id: 6,
            label: 'ISS',
            value: generateFinancialBalanceValue(taxes?.iss?.value),
            percentage: generateFinancialBalanceValue(taxes?.iss?.percentage),
            className: taxContentClasses(),
        },
        {
            id: 7,
            label: 'TOTAL',
            value: generateFinancialBalanceValue(totalTaxes),
            className: taxContentClasses('font-bold'),
            rowClassName: 'bg-base-200',
        },
    ];
};

export const financialBalanceStockMovementsColumns = (productUnit: string): DatatableColumn[] => [
    {
        name: 'type',
        label: 'Tipo de Movimentação',
        options: {
            customBodyRender: (value, tableMeta) => {
                if (!value) {
                    return '-';
                }

                const isReversal = tableMeta.rowData[3];
                const { text, variant } = generateInventoryMovementColors(value, isReversal);

                return <Badge variant={variant}>{text}</Badge>;
            },
        },
    },
    {
        name: 'order.code',
        label: 'Remessa',
        options: {
            customBodyRender: (value = '-') => value,
        },
    },
    {
        name: 'order.type',
        label: 'Tipo de Remessa',
        options: {
            customBodyRender: (value) => {
                const orderType = stockOrderTypes.find((item) => item.value === value);

                return orderType?.label || '-';
            },
        },
    },
    {
        name: 'reversal',
        options: {
            display: 'excluded',
        },
    },
    {
        name: 'createdAt',
        label: 'Data',
        options: {
            customBodyRender: (value) => formatDate(value, 'DD/MM/YYYY HH:mm'),
        },
    },
    {
        name: 'quantity',
        label: 'Quantidade',
        options: {
            customBodyRender: (value = 0) => {
                // using dangerouslySetInnerHTML for unit (abbreviation) that may contain HTML Entities.
                return <span dangerouslySetInnerHTML={{ __html: `${formatNumberToPTBR(value)} ${productUnit}` }} />;
            },
        },
    },
    {
        name: 'value',
        label: 'Valor Unitário',
        options: {
            customBodyRender: (value = 0) => formatMoney(value),
        },
    },
    {
        name: 'total',
        label: 'Total',
        options: {
            customBodyRender: (_, tableMeta) => {
                const quantity = tableMeta.rowData[5] || 0;
                const value = tableMeta.rowData[6] || 0;

                return formatMoney(value * quantity);
            },
        },
    },
];

const financialBalanceResumesClassesMap = {
    positive: {
        text: '!text-system-success-500',
        bg: '!bg-system-success-100',
    },
    negative: {
        text: '!text-system-danger-500',
        bg: '!bg-system-danger-100',
    },
};

const financialBalanceResumeInverseMap = {
    positive: 'negative',
    negative: 'positive',
};

export const generateFinancialBalanceResumeClasses = (value = 0, isInverse = false) => {
    const type = value > 0 ? 'positive' : 'negative';
    const mappingType = isInverse ? financialBalanceResumeInverseMap[type] : type;

    const baseClassName = '!text-sm';
    const textClassName = classNames(baseClassName, financialBalanceResumesClassesMap[mappingType].text);
    const bgClassName = financialBalanceResumesClassesMap[mappingType].bg;

    return {
        value: Boolean(value) ? textClassName : baseClassName,
        icon: Boolean(value) ? classNames(bgClassName, textClassName) : '',
    };
};
