import TableCell from '@mui/material/TableCell/TableCell';
import { CrudPageProps } from 'types/graphql';
import dictionary from 'utils/dictionary';

const config: CrudPageProps<{}>[] = [
    {
        graphql: {
            table: 'page',
            searchableField: 'title',
            columns: [
                {
                    name: 'id',
                    options: { display: 'excluded' },
                },
                {
                    name: 'title',
                    label: dictionary.content.graphql['title'],
                },
                {
                    name: 'name',
                    label: dictionary.content.graphql['name'],
                },
                {
                    name: 'slug',
                    label: dictionary.content.graphql['slug'],
                },
                {
                    name: 'permalink',
                    label: dictionary.content.graphql['permalink'],
                },
                {
                    name: 'controller',
                    label: dictionary.content.graphql['controller'],
                },
                {
                    name: 'actions',
                    label: 'Ações',
                    options: {
                        customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />,
                    },
                },
            ],
        },
        title: 'Páginas',
    },
];

export default config;
