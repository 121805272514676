import { object, string, number, array, boolean } from 'yup';
import { isValidCNPJ, isValidCPF } from '@brazilian-utils/brazilian-utils';
import { Status } from 'types/general';
import dictionary from 'utils/dictionary';
import { Gender, PersonContractType } from 'types/models/person';
import { banckAccountSchema } from 'pages/private/bank-accounts/create-or-update/utils';

const cnpjSchema = string()
    .when('type', ([type], schema) => {
        return type === PersonContractType.Juridic ? schema.required(dictionary.validation.required) : schema.nullable();
    })
    .test('is-valid-cpnj', 'CNPJ inválido', (value = '') => {
        if (!value?.length) {
            return true;
        }

        return isValidCNPJ(value?.replace(/[^\d]+/g, ''));
    });

const cpfSchema = string()
    .when('type', ([type], schema) => {
        return type === PersonContractType.Personal ? schema.required(dictionary.validation.required) : schema.nullable();
    })
    .test('is-valid-cpf', 'CPF inválido', (value = '') => {
        if (!value?.length) {
            return true;
        }

        return isValidCPF(value?.replace(/[^\d]+/g, ''));
    });

const customerAddress = {
    cityId: number().required(dictionary.validation.required),
    compliment: string().optional(),
    neighbourhood: string().trim().required(dictionary.validation.required),
    number: string().trim().required(dictionary.validation.required),
    stateId: number().required(dictionary.validation.required),
    street: string().trim().required(dictionary.validation.required),
    zip: string().trim().required(dictionary.validation.required),
};

const providerAddress = {
    cityId: number(),
    compliment: string().optional(),
    neighbourhood: string().trim(),
    number: string().trim(),
    stateId: number(),
    street: string().trim(),
    zip: string().trim(),
};

const customerOrProviderSchema = (bankAccountFieldName: string, id?: number, isUpdate = false, isCustomer = false) => {
    return object({
        title: string().required(dictionary.validation.required).trim(),
        status: number().default(Status.Active),
        type: number().oneOf([PersonContractType.Personal, PersonContractType.Juridic]).optional(),
        document: cpfSchema,
        juridicName: string().when('type', ([type], schema) => {
            return type === PersonContractType.Juridic ? schema.required(dictionary.validation.required) : schema;
        }),
        juridicFantasyName: string().when('type', ([type], schema) => {
            return type === PersonContractType.Juridic ? schema.required(dictionary.validation.required) : schema;
        }),
        juridicDocument: cnpjSchema,
        ...(isCustomer && {
            phones: array(
                object({
                    ddd: string().min(2, dictionary.validation.string.min(2)).max(2, dictionary.validation.string.max(2)).trim(),
                    number: string(),
                })
            ),
            hasStateRegistration: boolean().when('type', ([type], schema) => {
                return type === PersonContractType.Juridic ? schema.required(dictionary.validation.required) : schema;
            }),
        }),
        ...(!id && {
            customerContacts: array(
                object({
                    gender: number().oneOf([Gender.Female, Gender.Male, Gender.Other]),
                    name: string().trim(),
                    phones: array(
                        object({
                            ddd: string().min(2, dictionary.validation.string.min(2)).max(2, dictionary.validation.string.max(2)).trim(),
                            number: string(),
                        })
                    ),
                    title: string().trim(),
                })
            ),
        }),
        ...(!isUpdate && {
            address: object(isCustomer ? customerAddress : providerAddress),
            ...(!isCustomer && {
                [bankAccountFieldName]: banckAccountSchema,
            }),
        }),
    });
};

export default customerOrProviderSchema;
