import { getPaymentFlowRemittancesKey } from './keys';
import { useQuery } from 'react-query';
import api from 'services/api';
import { File } from 'types/models/file';
import { PaymentFlowTransaction } from 'types/models/payment-flow';
import { TransactionType } from 'types/models/transaction';

type PaymentFlowRemittancesResponse = {
    id: number;
    file: File;
    total: number;
    transactions: PaymentFlowTransaction[];
    type: TransactionType;
};

const useGetPaymentFlowRemittances = (paymentFlowId?: string, remittanceId?: number | null) => {
    const request = async () => {
        const { data } = await api.get<PaymentFlowRemittancesResponse>(`payment-flows/${paymentFlowId}/remittance-files/${remittanceId}`);

        return data;
    };

    return useQuery(getPaymentFlowRemittancesKey(paymentFlowId, remittanceId), request, {
        enabled: Boolean(paymentFlowId) && Boolean(remittanceId),
    });
};

export default useGetPaymentFlowRemittances;
