import { ExpenseNature } from 'types/models/expense';
import dictionary from 'utils/dictionary';
import { object, number, string, array } from 'yup';

export const purchaseOrderExpenseSchema = object({
    nature: number().required(dictionary.validation.required).default(ExpenseNature.Normal),
    fiscalCode: number().required(dictionary.validation.required),
    company: number().required(dictionary.validation.required),
    branch: number().required(dictionary.validation.required),
    formFavored: object({
        value: number().required(dictionary.validation.required),
        label: string().required(dictionary.validation.required),
        type: string().required(dictionary.validation.required),
    }).required(dictionary.validation.required),
    paymentForm: object({
        value: number().required(dictionary.validation.required),
        label: string().required(dictionary.validation.required),
    }),
    dueDate: string().trim().typeError(dictionary.validation.required),
    provision: string().trim().required(dictionary.validation.required).default(null).nullable().notRequired(),
    title: string().trim().required(dictionary.validation.required).typeError(dictionary.validation.required),
    groupedTransactions: array(number()),
    competence: string().required(dictionary.validation.required),
});
