import { FormPayload } from 'services/queries/forms/use-create-or-update-form';
import { FormFieldType, FormGroupType, QuizType } from 'types/models/form';
import dictionary from 'utils/dictionary';
import { array, boolean, number, object, string } from 'yup';

export const DEFAULT_FORM_GROUP: FormPayload['groups'][0] = {
    order: 1,
    title: '',
    type: FormGroupType.Normal,
    fields: [
        {
            name: '',
            type: FormFieldType.Text,
            required: true,
            order: 1,
        },
    ],
};

const quizTypes = Object.values(QuizType).filter(Number) as number[];
const fieldTypes = Object.values(FormFieldType).filter(Number) as number[];
const groupTypes = Object.values(FormGroupType).filter(Number) as number[];

export const formSchema = object({
    title: string().required(dictionary.validation.required),
    type: number().oneOf(quizTypes).required(dictionary.validation.required),
    groups: array(
        object({
            title: string().required(dictionary.validation.required),
            order: number().positive().required(dictionary.validation.required),
            type: number().oneOf(groupTypes).required(dictionary.validation.required),
            fields: array(
                object({
                    name: string().required(dictionary.validation.required),
                    type: number().oneOf(fieldTypes).required(dictionary.validation.required),
                    order: number().positive().required(dictionary.validation.required),
                    required: boolean().required(dictionary.validation.required).default(true),
                    options: array(
                        object({
                            name: string().required(dictionary.validation.required),
                            subfield: array(
                                object({
                                    name: string().optional(),
                                    type: number().oneOf(fieldTypes).optional(),
                                    order: number().positive().optional(),
                                    required: boolean().optional(),
                                    options: array(object({ name: string() })).optional(),
                                })
                            ).optional(),
                        })
                    ).optional(),
                })
            )
                .min(1, dictionary.validation.array.min(1))
                .default([]),
        })
    )
        .min(1, dictionary.validation.array.min(1))
        .default([]),
});
