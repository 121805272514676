import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { memo } from 'react';
import General from './general';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Installments from './installments';
import { ExpenseNature } from 'types/models/expense';
import FormButtons from 'components/form-buttons';
import { LoanPayload } from 'types/models/loan';
import { array, boolean, number, object, string } from 'yup';
import dictionary from 'utils/dictionary';
import { formatDate } from 'utils/date';
import useCreateOrUpdateLoan from 'services/queries/loans/use-create-or-update-loan';
import { useOutletContext } from 'react-router-dom';
import { GetPaginatedWithFiltersParams } from 'services/queries/general/use-get-paginated-with-filters';

type CreateOrUpdateLoanProps = {
    title: string;
};

const loanSchema = object({
    company: number().required(dictionary.validation.required),
    branch: number().required(dictionary.validation.required),
    bankAccount: number().required(dictionary.validation.required),
    value: number().required(dictionary.validation.required),
    instalments: number().nullable().default(null).notRequired(),
    loanDate: string().required(dictionary.validation.required).typeError(dictionary.validation.required),
    expense: object({
        instalmentValue: number().required(dictionary.validation.required),
        nature: number().required(dictionary.validation.required),
        dueDate: string().required(dictionary.validation.required).typeError(dictionary.validation.required),
        provision: string().required(dictionary.validation.required),
        title: string().required(dictionary.validation.required).typeError(dictionary.validation.required),
        observations: string().notRequired(),
        paymentForm: number().required(dictionary.validation.required),
        fiscalCode: number().required(dictionary.validation.required),
        formFavored: object({
            value: number().required(dictionary.validation.required),
            label: string().required(dictionary.validation.required),
            type: string().required(dictionary.validation.required),
            favoredBankAccount: object({
                value: number().optional(),
                label: string().optional(),
            }),
        }).required(dictionary.validation.required),
        transactions: array(
            object({
                dueDate: string().required(dictionary.validation.required),
                paymentForecast: string().required(dictionary.validation.required),
                provisioned: boolean().default(false).notRequired(),
                grossValue: number().required(dictionary.validation.required),
                paymentForm: number().required(dictionary.validation.required),
                description: string().required(dictionary.validation.required).typeError(dictionary.validation.required),
            })
        ).when('instalments', ([instalments], schema) => (instalments > 1 ? schema.min(1, dictionary.validation.array.min(1)).required(dictionary.validation.required) : schema)),
    }),
});

const CreateOrUpdateLoanPage = ({ title = 'Novo empréstimo' }: CreateOrUpdateLoanProps) => {
    const loanListParams = useOutletContext<GetPaginatedWithFiltersParams>();

    const { mutateAsync: createOrUpdateLoan, isLoading: isSubmitting } = useCreateOrUpdateLoan(loanListParams);

    const methods = useForm<LoanPayload>({
        mode: 'onSubmit',
        shouldFocusError: true,
        defaultValues: {
            loanDate: '',
            expense: {
                nature: ExpenseNature.Normal,
                dueDate: '',
                provision: '',
                title: '',
            },
        },
        resolver: yupResolver(loanSchema) as any,
    });

    const installments = methods.watch('instalments', 0);

    const submit = async (data: LoanPayload) => {
        try {
            const payload = {
                ...data,
                loanDate: formatDate(data.loanDate, 'YYYY-MM-DD', null),
                expense: {
                    ...data.expense,
                    instalmentValue: Number(data.expense.instalmentValue.toFixed(2)),
                    dueDate: formatDate(data.expense.dueDate, 'YYYY-MM-DD', null),
                    provision: formatDate(data.expense.provision, 'YYYY-MM-DD', null),
                    formFavored: {
                        [data.expense.formFavored.type]: data.expense.formFavored.value,
                        favoredBankAccount: data.expense.formFavored.favoredBankAccount.value,
                    },
                    transactions: (data.expense?.transactions || []).map((item) => ({
                        ...item,
                        dueDate: formatDate(item.dueDate, 'YYYY-MM-DD', null),
                        paymentForecast: formatDate(item.paymentForecast, 'YYYY-MM-DD', null),
                        grossValue: Number(item.grossValue.toFixed(2)),
                    })),
                },
            };

            await createOrUpdateLoan(payload);
        } catch (error) {
            console.log('submit', error);
        }
    };

    return (
        <Modal
            contentClassnames="min-w-[90%]"
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    {title}
                </Text>
            }
            closeOnClickOutside={false}>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(submit)}>
                    <General />
                    {installments > 1 && <Installments />}
                    <FormButtons isLoading={isSubmitting} containerClassName="p-6 pt-0" />
                </form>
            </FormProvider>
        </Modal>
    );
};

export default memo(CreateOrUpdateLoanPage);
