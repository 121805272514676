import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';
import { Status } from 'types/general';

export const queryCompanyBankAccountsOptions = (whereClause?: any, table = 'companyBankAccount') => {
    return jsonToGraphQLQuery(
        {
            query: {
                __name: 'GetBankAccountsOptions',
                items: {
                    __aliasFor: table,
                    __args: {
                        where: {
                            ...(Boolean(whereClause) && whereClause),
                            bankAccount: {
                                status: { _eq: Status.Active },
                                ...(Boolean(whereClause?.bankAccount) && whereClause.bankAccount),
                            },
                        },
                        orderBy: {
                            bankAccount: {
                                name: new EnumType('ASC'),
                            },
                        },
                    },
                    bankAccount: {
                        value: { __aliasFor: 'id' },
                        label: { __aliasFor: 'name' },
                    },
                },
            },
        },
        { pretty: true }
    );
};

export const getBankaccountsByCompaniesIdsQuery = (companiesIds: Array<Number>) => {
    return jsonToGraphQLQuery(
        {
            query: {
                __name: 'GetBankAccountsOptionsByCompaniesIds',
                items: {
                    __aliasFor: 'companyBankAccount',
                    __args: {
                        where: {
                            bankAccount: {
                                status: { _eq: Status.Active },
                            },
                            companyId: { _in: companiesIds },
                        },
                        orderBy: {
                            bankAccount: {
                                name: new EnumType('ASC'),
                            },
                        },
                    },
                    bankAccount: {
                        value: { __aliasFor: 'id' },
                        label: { __aliasFor: 'name' },
                    },
                },
            },
        },
        { pretty: true }
    );
};
