import { RouteObject } from 'react-router-dom';
import { Rule } from 'types/permissions';
import Page from 'pages/shared/page';
import SchedulePage from 'pages/private/schedules';
import UpdateScheduleStatus from 'pages/private/schedules/update-status';

const schedules: RouteObject[] = [
    {
        // @ts-ignore
        breadcrumb: 'Agendamentos',
        path: 'agendamentos',
        element: <Page Component={SchedulePage} title="Agendamentos" code="agendamentos" permissions={[Rule.List, Rule.Create]} />,
        children: [
            {
                path: ':scheduleId/status/:statusType',
                element: <Page Component={UpdateScheduleStatus} title="agendamento" code="agendamentos" permissions={[Rule.Update]} withModal={true} />,
            },
        ],
    },
];

export default schedules;
