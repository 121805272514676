import utc from 'dayjs/plugin/utc';
import minMax from 'dayjs/plugin/minMax';
import dayjs, { Dayjs } from 'dayjs';

dayjs.extend(utc);
dayjs.extend(minMax);

export const createDateWithTimezone = (date: string) => dayjs.tz(date, 'America/Sao_Paulo');

export const formatDate = (date?: string | Date | Dayjs | null, format = 'DD/MM/YYYY', fallbackMessage: any = '-') => {
    if (!isValidDate(date)) {
        return fallbackMessage;
    }

    return dayjs(date).utc(false).locale('pt-br').format(format);
};

export const isValidDate = (date: any) => dayjs(date).isValid();

// Preventing the date picker component to break
export const convertToDateOrReturnUndefined = (date?: any) => {
    if (date === null || !isValidDate(new Date(date))) {
        return undefined;
    }

    return new Date(date);
};

export const weekDays = [
    { label: 'Segunda-feira', value: 1 },
    { label: 'Terça-feira', value: 2 },
    { label: 'Quarta-feira', value: 3 },
    { label: 'Quinta-feira', value: 4 },
    { label: 'Sexta-feira', value: 5 },
    { label: 'Sábado', value: 6 },
    { label: 'Domingo', value: 7 },
];

export const timeToInt = (value?: string): number => {
    if (!value) {
        return 0;
    }

    const [hours, minutes] = value.split(':');
    return (Number(hours) || 0) * 60 + Number(minutes);
};

export const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

export enum Months {
    January = 1,
    February,
    March,
    April,
    May,
    June,
    July,
    August,
    September,
    October,
    November,
    December,
}

export const monthsByNumber: { [key: number]: string } = {
    [Months.January]: 'Janeiro',
    [Months.February]: 'Fevereiro',
    [Months.March]: 'Março',
    [Months.April]: 'Abril',
    [Months.May]: 'Maio',
    [Months.June]: 'Junho',
    [Months.July]: 'Julho',
    [Months.August]: 'Agosto',
    [Months.September]: 'Setembro',
    [Months.October]: 'Outubro',
    [Months.November]: 'Novembro',
    [Months.December]: 'Dezembro',
};

export const splitDateTime = (date?: string, time?: string) => {
    const day = !!date ? formatDate(date) : undefined;
    const hour = !!time ? dayjs.utc(time).format('HH:mm') : undefined;

    return {
        day,
        hour,
    };
};

export const currentDateWithoutTimezone = dayjs.utc().utcOffset(0, true).toISOString();

export const monthsOptions = months.map((m, i) => ({ value: i + 1, label: m }));

export const generateYearsArr = (startYearParam = dayjs().year(), plusYear = 10) => {
    const years = Array.from({ length: plusYear + 1 }, (_, index) => startYearParam + index);

    return years;
};

export const januaryFirstOfCurrentYear = dayjs().date(1).month(0).format('YYYY-MM-DD');

export const getLowestDate = (dates: Dayjs[]) => {
    if (!dates.length) {
        return null;
    }

    return dayjs.min(dates);
};

export const getHighestDate = (dates: Dayjs[]) => {
    if (!dates.length) {
        return null;
    }

    return dayjs.max(dates);
};
