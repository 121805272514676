import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';
import { purchaseProductsTypeWhereClause } from 'pages/private/sales-orders/service-order/order-purchase/create-or-update/utils';
import { ApprovalStatus, Status } from 'types/general';

export const getDebouncedStockProductsQuery = (stockId?: string, searchTerm = '') => {
    return jsonToGraphQLQuery({
        query: {
            __name: 'GetDebouncedStockProducts',
            product: {
                __aliasFor: 'product',
                __args: {
                    where: {
                        status: { _eq: Status.Active },
                        approvalStatus: { _eq: ApprovalStatus.Approved },
                        ...purchaseProductsTypeWhereClause,
                        ...(Boolean(searchTerm) && {
                            _or: [{ name: { _ilike: `%${searchTerm}%` } }, { code: { _ilike: `%${searchTerm}%` } }],
                        }),
                    },
                    orderBy: {
                        name: new EnumType('ASC'),
                    },
                },
                id: true,
                name: true,
                code: true,
                classification: {
                    name: true,
                },
                classificationByClassificationUnitId: {
                    name: true,
                },
                stockProducts: {
                    __args: {
                        where: {
                            stockId: {
                                _eq: stockId,
                            },
                        },
                    },
                    value: true,
                    quantity: true,
                },
            },
        },
    });
};

export const getStockProductQuery = (stockProductId?: string) => {
    return jsonToGraphQLQuery(
        {
            query: {
                __name: 'GetStockProductById',
                stockProductByPk: {
                    __aliasFor: 'stockProductByPk',
                    __args: {
                        id: stockProductId,
                    },
                    id: true,
                    quantity: true,
                    value: true,
                    product: {
                        code: true,
                        name: true,
                        classificationByClassificationUnitId: {
                            name: true,
                        },
                    },
                    inventoryMovements: {
                        __args: {
                            orderBy: {
                                createdAt: new EnumType('ASC'),
                            },
                            where: {
                                status: { _eq: Status.Active },
                            },
                        },
                        id: true,
                        type: true,
                        quantity: true,
                        value: true,
                        createdAt: true,
                        stockOrder: {
                            code: true,
                            type: true,
                            stockOrigin: {
                                stockOriginSaleOrder: {
                                    saleOrder: {
                                        id: true,
                                        code: true,
                                    },
                                },
                                stockOriginPurchaseOrder: {
                                    purchaseOrder: {
                                        id: true,
                                        code: true,
                                    },
                                },
                            },
                        },
                        reversal: true,
                    },
                },
            },
        },
        { pretty: true }
    );
};

export const getStocksQuery = jsonToGraphQLQuery(
    {
        query: {
            __name: 'GetStocks',
            items: {
                __aliasFor: 'stock',
                __args: {
                    where: {
                        main: {
                            _eq: true,
                        },
                        status: {
                            _eq: Status.Active,
                        },
                    },
                },
                value: {
                    __aliasFor: 'id',
                },
                label: {
                    __aliasFor: 'name',
                },
            },
        },
    },
    { pretty: true }
);
