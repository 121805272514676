import useToast from 'hooks/toast/use-toast';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
import { PaymentFlow } from 'types/models/payment-flow';

export type GeneratePaymentFlowPayload = {
    company: number;
    companyBankAccount: number;
    provision: string;
    transactions: number[];
};

const useTransactionGeneratePaymentFlow = () => {
    const navigate = useNavigate();
    const { showToast } = useToast();

    const request = async (payload: Omit<GeneratePaymentFlowPayload, 'company'>) => api.post<PaymentFlow>('/payment-flows', payload);

    return useMutation(request, {
        onSuccess: ({ data: paymentFlow }) => {
            showToast('Fluxo de pagamento criado com sucesso', 'success');

            navigate(`/app/fluxos-de-pagamento/${paymentFlow.id}`);
        },
        onError: (error: any) => {
            showToast(error?.response?.data?.title ?? 'Ocorreu um problema ao tentar criar o fluxo de pagamento', 'danger');
        },
    });
};

export default useTransactionGeneratePaymentFlow;
