import { useShowApiErrors } from 'hooks/error/show-api-errors';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';
import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import { getPaginatedWithFiltersKey } from '../general/keys';
import useConfig from 'store/config/use-config';

const useCreateOrUpdateImportFlow = (filters?: any) => {
    const { showToastErrors } = useShowApiErrors();
    const { config } = useConfig();
    const { showToast } = useToast();

    const goToParentRoute = useNavigateToParentRoute();
    const queryClient = useQueryClient();

    const request = async (payload: any) => {
        const { data } = await api.post('/imports', payload);

        return data;
    };

    return useMutation(request, {
        onSuccess: () => {
            const { page, endpoint, ...restFilters } = filters || {};

            showToast('Importação cadastrado com sucesso!', 'success');
            goToParentRoute();
            queryClient.invalidateQueries(getPaginatedWithFiltersKey(endpoint, restFilters, page, config.tables.rowsPerPage));
        },
        onError: (error: any) => {
            showToastErrors(error, error?.response?.data?.title);
        },
    });
};

export default useCreateOrUpdateImportFlow;
