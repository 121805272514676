import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';
import { Status } from 'types/general';
import { SaleOrder, SaleOrderType } from 'types/models/sale-order';
import { QueryConfig } from 'utils/query';

export const getSaleOrderKey = (saleOrderId?: string) => ['sale-order', saleOrderId];
export const getSaleOrderProductsKey = (saleOrderId?: string | number) => ['sale-order', saleOrderId, 'products'];
export const getSaleOrderProductKey = (saleOrderProductId?: string) => ['sale-order-product', saleOrderProductId];
export const getSaleOrderDocumentKey = (saleOrderId?: string, saleOrderDocumentId?: string) => ['sale-order', saleOrderId, 'documents', saleOrderDocumentId];
export const getSaleOrderServiceOrdersKey = (saleOrderId?: string) => ['sale-order', saleOrderId, 'service-orders'];
export const getBoardManagerKey = (saleOrderId?: string, orderServiceId?: string) => ['boars-manager', saleOrderId, orderServiceId];
export const getSaleOrderStatusOptionsKey = ['get', 'sale-order', 'options', 'status'];
export const getSaleOrderSchedulesKey = (saleOrderId?: string) => ['get', 'sale-order', saleOrderId, 'schedules'];
export const getSaleOrderScheduleDaysOptionsKey = ['get', 'sale-order', 'options', 'schedule', 'days'];
export const getSaleOrderScheduleHoursOptionsKey = (saleOrderScheduleDayId?: string) => ['get', 'sale-order', 'options', 'schedule', 'hours', 'day', saleOrderScheduleDayId];
export const getSaleOrderExpensesKey = (saleOrderId?: string) => ['sale-order', saleOrderId, 'expenses'];
export const getSaleOrdersKey = (config: QueryConfig<SaleOrder>) => ['sale-orders', config];
export const getConsultantSaleOrdersKey = (consultantId?: number, saleOrderType?: SaleOrderType) => ['get', 'consultant', consultantId, 'sale-orders', 'saleOrderType', saleOrderType];
export const getSaleOrderFinancialBalanceKey = (saleOrderId?: string) => ['sale-order', saleOrderId, 'financial-balance'];

export const generateSaleOrdersProductsQuery = (saleOrderId?: number) => {
    return jsonToGraphQLQuery(
        {
            query: {
                __name: 'GetSaleOrdersProducts',
                items: {
                    __aliasFor: 'saleOrderProduct',
                    __args: {
                        orderBy: {
                            product: {
                                name: new EnumType('ASC'),
                            },
                        },
                        where: {
                            status: { _eq: Status.Active },
                            saleOrderId: {
                                _eq: saleOrderId,
                            },
                        },
                    },
                    id: true,
                    quantity: true,
                    saleOrderId: true,
                    costValue: true,
                },
            },
        },
        { pretty: true }
    );
};
