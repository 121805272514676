import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';
import { Status } from 'types/general';

export const getDashboardsKey = ['dashboards'];
export const getDashboardFinancialBalanceKeys = ['dashboard', 'financial', 'balance'];

export const getDashboardsQuery = jsonToGraphQLQuery(
    {
        query: {
            __name: 'GetDashboards',
            items: {
                __aliasFor: 'dashboardTemplate',
                __args: {
                    where: {
                        status: { _eq: Status.Active },
                    },
                    orderBy: { name: new EnumType('ASC') },
                },
                value: { __aliasFor: 'id' },
                label: { __aliasFor: 'name' },
            },
        },
    },
    { pretty: true }
);
