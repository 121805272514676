import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import { OrderServiceWorkReportPayload } from 'types/models/order-service';
import { getOrderServiceWorkReportsKey } from './keys';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';

type Payload = Omit<OrderServiceWorkReportPayload, 'files'> & {
    files: Array<Number>;
};

const useCreateOrUpdateOrderServiceWorkReport = (saleOrderId?: string, orderServiceId?: string) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const goToParentRoute = useNavigateToParentRoute();

    const request = (payload: Payload) => api.post(`/sale-orders/${saleOrderId}/service-orders/${orderServiceId}/galleries`, payload);

    return useMutation(request, {
        onSuccess: () => {
            showToast('Relatório de obra cadastrada com sucesso', 'success');

            queryClient.invalidateQueries(getOrderServiceWorkReportsKey(orderServiceId!));

            goToParentRoute();
        },
        onError: () => {
            showToast('Ocorreu um erro ao cadastrar o relatório de obra', 'danger');
        },
    });
};

export default useCreateOrUpdateOrderServiceWorkReport;
