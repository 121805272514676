import QueryString from 'qs';
import { useQuery } from 'react-query';
import api from 'services/api';
import useConfig from 'store/config/use-config';
import { PurchaseOrder } from 'types/models/purchase-order';
import { Pagination } from 'types/pagination';
import { getPurchaseOrdersKey } from './keys';

type Params = {
    page: number;
    orderBy: { [key: string]: string } | null;
    filter: {
        searchText?: string | null;
    };
};

const useGetPurchaseOrders = ({ page, orderBy, filter }: Params) => {
    const { config } = useConfig();

    const query = QueryString.stringify(
        {
            page,
            filter,
            orderBy,
            totalPerPage: config.tables.rowsPerPage,
        },
        { addQueryPrefix: true, encode: false, arrayFormat: 'brackets', skipNulls: true }
    );

    const endpoint = '/purchase-orders' + query;

    const request = async () => {
        const { data } = await api.get<Pagination<PurchaseOrder>>(endpoint);

        return data;
    };

    return useQuery(getPurchaseOrdersKey(endpoint), request);
};

export default useGetPurchaseOrders;
