import DocumentsForm, { DocumentPayload } from 'components/documents';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import useUploadFile from 'services/queries/files/use-upload-file';
import { getTransactionKey } from 'services/queries/transactions/keys';
import useCreateOrUpdateTransactionDocument from 'services/queries/transactions/use-create-or-update-transaction-document';
import useGetTransactionDocument from 'services/queries/transactions/use-get-transaction-document';
import { ClassificationType } from 'types/models/classification';

const CreateOrUpdateTransactionDocumentPage = () => {
    const { paymentOrderId, receiptOrderId, transactionId, documentId } = useParams();

    const id = paymentOrderId || receiptOrderId || transactionId;
    // variables represent IDs from different routes - all of them represent the same type of ID

    const title = Boolean(documentId) ? 'Editar documento' : 'Criar documento';

    const { uploadFile, isLoading: isSendingFile } = useUploadFile();

    const queryClient = useQueryClient();
    const goToParentRoute = useNavigateToParentRoute();

    const { mutateAsync: createOrUpdateDocument, isLoading: isSubmittingDocument } = useCreateOrUpdateTransactionDocument(id, documentId);

    const { data: transactionDocument, isLoading: isLoadingTransactionDocument } = useGetTransactionDocument(id, documentId);

    const isSubmitting = isSendingFile || isSubmittingDocument;

    const defaultValues: Partial<DocumentPayload> = {
        file: transactionDocument?.file,
        type: transactionDocument?.type,
        name: transactionDocument?.name,
        description: transactionDocument?.description,
        observation: transactionDocument?.observation,
    };

    const submit = async (data: Partial<DocumentPayload>) => {
        try {
            if (!!data?.file?.id) {
                data.file = data.file.id;
            } else {
                await uploadFile(
                    data.file,
                    ({ id }) => {
                        data.file = id;
                    },
                    true
                );
            }

            if (!data?.file) {
                throw new Error();
            }

            await createOrUpdateDocument(data);
            queryClient.invalidateQueries(getTransactionKey(id));

            goToParentRoute();
        } catch (e) {
            console.error('submit - createOrUpdateTransactionDocument: ', e);
        }
    };

    return (
        <DocumentsForm
            classificationType={ClassificationType.DocumentTransaction}
            defaultValues={defaultValues}
            title={title}
            onSubmit={submit}
            isSubmitting={isSubmitting}
            isBuilding={isLoadingTransactionDocument}
        />
    );
};

export default CreateOrUpdateTransactionDocumentPage;
