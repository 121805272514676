import { useMutation } from 'react-query';
import api from 'services/api';
import useToast from 'hooks/toast/use-toast';

export type UseReportInvoicingPayload = {
    paymentForecast: string;
    transactions: number[];
};

const useTransactionReportInvoicing = () => {
    const { showToast } = useToast();

    const request = async (payload: UseReportInvoicingPayload) => api.patch('/transactions/outcomes/inform-billing', payload);

    return useMutation(request, {
        onSuccess: () => {
            showToast('Pagamentos atualizados com sucesso!', 'success');
        },
        onError: (error: any) => {
            showToast(error?.response?.data?.title ?? 'Tivemos um problema ao atualizar os pagamentos. Tente novamente.', 'danger');
        },
    });
};

export default useTransactionReportInvoicing;
