import { useMutation } from 'react-query';
import apiGraphQl from 'services/graphql';
import { affectedRowsKey } from 'utils/environment';

const useCreatePageRules = () => {
    const request = async (payload: any) => {
        return apiGraphQl(
            `mutation CreatePageRules($payload: [PageRuleInsertInput!]!) {
                item: insertPageRule(objects: $payload) {
                    ${affectedRowsKey}
                }
            }`,
            { payload }
        );
    };

    return useMutation(request);
};

export default useCreatePageRules;
