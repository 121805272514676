import Spinner from 'components/core/spinner';
import { useCallback, useMemo, useState } from 'react';
import { Outlet, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import ExpenseTotalCard from '../../expenses/total-card';
import useGetOrderServicesPurchaseScopeTotal from 'services/queries/order-services/use-get-order-services-purchase-scope-total';
import Text from 'components/core/text';
import TableAdvancedSearch from 'components/core/table/datatable/components/advanced-search';
import ServiceOrderPurchaseScopeFilter from './filter';
import { OrderService, OrderServiceStatusEnum } from 'types/models/order-service';
import Badge from 'components/core/badge';
import { canDoActions } from './utils';
import RegularProductsList from './regular-products';
import FabNav from 'components/fab-nav';
import { MenuItem } from 'components/menu';
import { getOrderServiceKey } from 'services/queries/order-services/keys';
import { useQueryClient } from 'react-query';
import useChangeOrderServiceStatus from 'services/queries/order-services/use-change-order-service-status';
import ConfirmModal, { ConfirmModalProps } from 'components/core/modal/confirm';
import useAuth from 'store/auth/use-auth';

const ServiceOrderPurchaseScopePage = () => {
    const { auth } = useAuth();
    const { orderServiceId, saleOrderId } = useParams();

    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const orderService = useOutletContext<Partial<OrderService>>();

    const [filters, setFilters] = useState<any>();
    const [modalContent, setModalContent] = useState<Omit<ConfirmModalProps, 'onClose'>>();

    const endpoint = `/sale-orders/${saleOrderId}/service-orders/${orderServiceId}/purchase-products`;

    const requestConfig = {
        endpoint,
        filters,
    };

    const { data: totals, isLoading: isLoadingTotals } = useGetOrderServicesPurchaseScopeTotal(`${endpoint}?type=numbers`);
    const { mutateAsync: releaseToValidation, isLoading: isReleasingToValidation } = useChangeOrderServiceStatus(saleOrderId, orderServiceId);

    const handleCloseConfirmModal = () => setModalContent(undefined);

    const handleChangeOsStatus = useCallback(async () => {
        try {
            await releaseToValidation('release-for-validation');

            queryClient.invalidateQueries(getOrderServiceKey(orderServiceId));

            setModalContent(undefined);
        } catch (error) {
            console.log('handleChangeOsStatus', error);
        }
    }, [releaseToValidation, queryClient, orderServiceId]);

    const fabOptions = useMemo(() => {
        const arr: MenuItem[] = [];

        if (auth.credentials.user.superAdmin && orderService.orderServiceStatus?.slug === OrderServiceStatusEnum.AwaitingProductApproval) {
            arr.push({ label: 'Aprovar produtos', onClick: () => navigate('aprovar-produtos') });
        } else if (orderService.orderServiceStatus?.slug === OrderServiceStatusEnum.InProductValidation) {
            arr.push({ label: 'Validar produtos', onClick: () => navigate('validar-produtos') });
        } else if (auth.credentials.user.superAdmin && orderService.orderServiceStatus?.slug === OrderServiceStatusEnum.AwaitingRelease) {
            arr.push({
                label: 'Liberar para validação',
                onClick: () => {
                    setModalContent({
                        title: 'Liberar para validação',
                        description: 'Tem certeza que deseja enviar este escopo de compra para validação?',
                        onConfirmAction: handleChangeOsStatus,
                        isLoading: isReleasingToValidation,
                        color: 'secondary',
                    });
                },
            });
        } else if (auth.credentials.user.superAdmin && orderService.orderServiceStatus?.slug === OrderServiceStatusEnum.AwaitingPurchaseProducts) {
            arr.push({
                label: 'Adicionar Escopo de Compra',
                onClick: () => navigate('adicionar-escopo-de-compra'),
            });
        }

        return arr;
    }, [orderService.orderServiceStatus?.slug, isReleasingToValidation, handleChangeOsStatus, navigate, auth.credentials.user.superAdmin]);

    return (
        <>
            <div className="pb-20">
                {isLoadingTotals && <Spinner fixed={true} />}
                <div className="flex items-center mb-6 flex-row justify-between">
                    <Text as="h3" variant="h5" className="text-heading mr-1 flex-1">
                        Escopo de compra
                    </Text>
                    <div className="flex items-baseline">
                        <Badge variant="info" className="mr-4">
                            {orderService.orderServiceStatus?.title}
                        </Badge>
                        <TableAdvancedSearch
                            hasSelectedFilters={true}
                            component={<ServiceOrderPurchaseScopeFilter endpoint={`${endpoint}?type=products`} onSubmit={setFilters} />}
                            buttonClassName="!bg-white shadow-lg !relative"
                        />
                    </div>
                </div>
                {canDoActions.includes(orderService.orderServiceStatus?.slug || '') && (
                    <div className="flex flex-col md:grid grid-cols-3 gap-6 mb-6">
                        <ExpenseTotalCard
                            variant="toBePaid"
                            title="Budget Solicitado"
                            value={{
                                data: totals?.totalSpent,
                            }}
                        />
                        <ExpenseTotalCard
                            variant="paid"
                            title="Budget em aberto"
                            value={{
                                data: totals?.totalBalance,
                            }}
                        />
                        <ExpenseTotalCard
                            variant="total"
                            title="Budget Total"
                            value={{
                                data: totals?.totalPurchase,
                            }}
                        />
                    </div>
                )}
                <RegularProductsList orderServiceSlug={orderService.orderServiceStatus?.slug} requestConfig={requestConfig} onSetFilters={setFilters} />
            </div>
            {Boolean(fabOptions.length) && <FabNav options={fabOptions} />}
            {!!modalContent && <ConfirmModal {...modalContent} isLoading={false} onClose={handleCloseConfirmModal} />}
            <Outlet context={requestConfig} />
        </>
    );
};

export default ServiceOrderPurchaseScopePage;
