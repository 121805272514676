import { useQuery } from 'react-query';
import api from 'services/api';
import { getConsultantsKey } from './keys';
import { Person } from 'types/models/person';
import { Option } from 'types/general';

type ReturnType<T> = T extends true ? Option[] : T extends false ? Partial<Person>[] : never;

const useGetConsultants = <T extends true | false>(withOptionsFormat: T = false as T, isEnabled = false) => {
    const request = async () => {
        const { data } = await api.get<Partial<Person>[]>(`/consultants`);

        return data;
    };

    return useQuery(getConsultantsKey, request, {
        enabled: isEnabled,
        select: (data = []) => {
            if (!withOptionsFormat) {
                return data as ReturnType<T>;
            }

            const options = data.map<Option>((item) => ({ value: Number(item.id || 0), label: item.title! }));

            return options as ReturnType<T>;
        },
    });
};

export default useGetConsultants;
