import * as React from 'react';
const SvgIcoContacts = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" width="1em" height="1em" {...props}>
        <path
            fill="currentColor"
            d="M4.811 3.811c.2-.2.47-.311.752-.311H14.6v9.375H5.563c-.37 0-.733.08-1.063.23V4.563c0-.281.112-.552.311-.75ZM4.5 15.438A1.063 1.063 0 0 0 5.563 16.5H14.6v-2.125H5.563A1.062 1.062 0 0 0 4.5 15.438Zm11.6-1.813V2.75a.75.75 0 0 0-.75-.75H5.563A2.562 2.562 0 0 0 3 4.563v10.875A2.563 2.563 0 0 0 5.563 18h9.787a.75.75 0 0 0 .75-.75v-3.625Z"
        />
    </svg>
);
export default SvgIcoContacts;
