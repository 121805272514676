import { useQuery } from 'react-query';
import api from 'services/api';
import { getImportFlowNationalizationsKey } from './keys';
import { ImportFlowNationalizations } from 'types/models/import-flow';

const useGetImportFlowNationalizations = (importFlowId?: string) => {
    const request = async () => {
        const { data } = await api.get<ImportFlowNationalizations[]>(`/imports/${importFlowId}/nationalizations`);

        return data;
    };

    return useQuery(getImportFlowNationalizationsKey(importFlowId), request, {
        enabled: Boolean(importFlowId),
    });
};

export default useGetImportFlowNationalizations;
