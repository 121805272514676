import ExpenseTotalCard from 'pages/private/sales-orders/service-order/expenses/total-card';
import { generateReportTotalsClasses, getExpenseCardVariant } from '../../utils';
import { SalesOrderReportTotalsValues } from '../../types';
import { useReportsContext } from 'pages/private/reports/context';

type SalesOrderReportTotalsProps = {
    salesOrderTotals: SalesOrderReportTotalsValues;
};

const SalesOrderReportTotals = ({ salesOrderTotals }: SalesOrderReportTotalsProps) => {
    const { showValues } = useReportsContext();

    return (
        <div className="mb-6 grid xl:grid-cols-3 2xl:grid-cols-5 gap-4">
            <ExpenseTotalCard
                containerClassName={generateReportTotalsClasses().cardContainer}
                title="Venda"
                value={{
                    data: salesOrderTotals.sale.value,
                    contentContainerClassName: generateReportTotalsClasses().contentContainer,
                    className: generateReportTotalsClasses({ showValues }).value,
                    containerClassName: generateReportTotalsClasses({ showValues }).valueContainer,
                    isVisible: showValues,
                }}
                head={{
                    containerClassName: generateReportTotalsClasses().headContainer,
                }}
                variant="paid"
            />
            <ExpenseTotalCard
                containerClassName={generateReportTotalsClasses().cardContainer}
                title="Custo Total"
                value={{
                    data: salesOrderTotals.totalCost.value,
                    contentContainerClassName: generateReportTotalsClasses().contentContainer,
                    className: generateReportTotalsClasses({ showValues }).value,
                    containerClassName: generateReportTotalsClasses({ showValues }).valueContainer,
                    isVisible: showValues,
                }}
                head={{
                    containerClassName: generateReportTotalsClasses().headContainer,
                }}
                percentage={{
                    data: salesOrderTotals.totalCost.percentage,
                }}
                variant="toBePaid"
            />
            <ExpenseTotalCard
                containerClassName={generateReportTotalsClasses().cardContainer}
                title="Imposto"
                value={{
                    data: salesOrderTotals.tax.value,
                    contentContainerClassName: generateReportTotalsClasses().contentContainer,
                    className: generateReportTotalsClasses({ showValues }).value,
                    containerClassName: generateReportTotalsClasses({ showValues }).valueContainer,
                    isVisible: showValues,
                }}
                head={{
                    containerClassName: generateReportTotalsClasses().headContainer,
                }}
                percentage={{
                    data: salesOrderTotals.tax.percentage,
                }}
                variant="toBePaid"
            />
            <ExpenseTotalCard
                containerClassName={generateReportTotalsClasses().cardContainer}
                title="Comissão"
                value={{
                    data: salesOrderTotals.commission.value,
                    contentContainerClassName: generateReportTotalsClasses().contentContainer,
                    className: generateReportTotalsClasses({ showValues }).value,
                    containerClassName: generateReportTotalsClasses({ showValues }).valueContainer,
                    isVisible: showValues,
                }}
                head={{
                    containerClassName: generateReportTotalsClasses().headContainer,
                }}
                percentage={{
                    data: salesOrderTotals.commission.percentage,
                }}
                variant="toBePaid"
            />
            <ExpenseTotalCard
                containerClassName={generateReportTotalsClasses().cardContainer}
                title="Resultado"
                value={{
                    data: salesOrderTotals.result.value,
                    contentContainerClassName: generateReportTotalsClasses().contentContainer,
                    className: generateReportTotalsClasses({ showValues }).value,
                    containerClassName: generateReportTotalsClasses({ showValues }).valueContainer,
                    isVisible: showValues,
                }}
                head={{
                    containerClassName: generateReportTotalsClasses().headContainer,
                }}
                percentage={{
                    data: salesOrderTotals.result.percentage,
                }}
                variant={getExpenseCardVariant(salesOrderTotals.result.value)}
            />
        </div>
    );
};

export default SalesOrderReportTotals;
