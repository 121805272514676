import { RouteObject } from 'react-router-dom';
import { Rule } from 'types/permissions';
import Page from 'pages/shared/page';
import CreateOrUpdateSaleOrderPage from 'pages/private/sales-orders/create-or-update';
import SaleOrderPage from 'pages/private/sales-orders/view';
import SaleOrderDetailsPage from 'pages/private/sales-orders/details';
import SaleOrderProductsPage from 'pages/private/sales-orders/products-list';
import SaleOrderDocumentsPage from 'pages/private/sales-orders/documents';
import CreateOrUpdateSaleOrderDocumentPage from 'pages/private/sales-orders/documents/create-or-update';
import CreateOrUpdateOrderServicePage from 'pages/private/sales-orders/service-order/create-or-update';
import ServiceOrderPage from 'pages/private/sales-orders/service-order/view';
import ServiceOrderDetailsPage from 'pages/private/sales-orders/service-order/details';
import OrderServiceListPage from 'pages/private/sales-orders/service-order';
import OrderServiceDeductionsPage from 'pages/private/sales-orders/service-order/deduction';
import CreateOrUpdateDeductionsPage from 'pages/private/sales-orders/service-order/deduction/create-or-update';
import OrderPurchasePage from 'pages/private/sales-orders/service-order/order-purchase';
import CreateOrUpdateOrderPurchasePage from 'pages/private/sales-orders/service-order/order-purchase/create-or-update';
import ServiceOrderPurchaseScopePage from 'pages/private/sales-orders/service-order/purchase-scope/view';
import OrderServiceSalesNotesPage from 'pages/private/sales-orders/service-order/sales-notes';
import PurchaseOrderPage from 'pages/private/sales-orders/service-order/purchase-order';
import SaleOrderDeductionPage from 'pages/private/sales-orders/deduction';
import PurchaseOrderExpensesCreateOrUpdatePage from 'pages/private/purchase-orders/expenses';
import SaleOrderSalesNotesPage from 'pages/private/sales-orders/sales-notes';
import ServiceOrderExpenses from 'pages/private/sales-orders/service-order/expenses';
import ServiceOrderProducts from 'pages/private/sales-orders/service-order/sale-order-product';
import ServiceOrderExtraPurchaseScopePage from 'pages/private/sales-orders/service-order/extra-purchase-scope';
import ServiceOrderExpensesAdministrative from 'pages/private/sales-orders/service-order/expenses-administrative';
import ServiceOrderProductsCategories from 'pages/private/sales-orders/service-order/categories';
import ServiceOrderFinancialBalancePage from 'pages/private/sales-orders/service-order/financial-balance';
import StockDeliveryPage from 'pages/private/stocks/stock-delivery';
import { ExpenseNature } from 'types/models/expense';
import WorksReportPage from 'pages/private/sales-orders/service-order/works-report';
import CreateOrUpdateOrderServiceWorkReportPage from 'pages/private/sales-orders/service-order/works-report/create-or-update';
import TimelinePage from 'pages/private/sales-orders/timeline';
import TaskPage from 'pages/private/tasks/view';
import SaleOrderSchedulePage from 'pages/private/sales-orders/schedule';
import CreateOrUpdateSaleOrdersSchedulesPage from 'pages/private/sales-orders/schedule/create-or-update';
import CreateOrUpdateExpensePage from 'pages/private/expenses/crud';
import SaleOrderExpensesPage from 'pages/private/sales-orders/expenses';
import RevenuesPage from 'pages/private/revenues';
import ProductFinalApprovalPage from 'pages/private/sales-orders/service-order/purchase-scope/final-approval';
import PaymentOrderPage from 'pages/private/payment-order/view';
import TransactionUpdatePage from 'pages/private/expenses/details/tabs/transactions/update';
import SalesOrdersPage from 'pages/private/sales-orders';
import ServiceOrderSaleScopePage from 'pages/private/sales-orders/service-order/sale-scope';
import CreateOrUpdateTransactionDocumentPage from 'pages/private/payment-order/view/documents/create-or-update';
import SaleOrderFinancialBalancePage from 'pages/private/sales-orders/financial-balance';
import ManageProductsPage from 'pages/private/sales-orders/service-order/purchase-scope/manage-products';
import CreatePurchaseScopePage from 'pages/private/sales-orders/service-order/purchase-scope/create';
import ServiceOrderFinancialBalanceDetailsPage from 'pages/private/sales-orders/service-order/financial-balance/details';
import ServiceOrderExpenseProductsPage from 'pages/private/sales-orders/service-order/expense-products';
import OrderServiceMovementsPage from 'pages/private/sales-orders/service-order/movements';

const salesOrders: RouteObject[] = [
    {
        // @ts-ignore
        breadcrumb: 'Pedidos de venda',
        path: 'pedidos-de-venda',
        element: <Page Component={SalesOrdersPage} code="pedidos-venda" permissions={[Rule.List]} />,
        children: [
            {
                path: 'novo',
                element: <Page Component={CreateOrUpdateSaleOrderPage} code="pedidos-venda" permissions={[Rule.Create]} withModal={true} title="Novo pedido de venda" />,
            },
            {
                path: 'editar/:saleOrderId',
                element: <Page Component={CreateOrUpdateSaleOrderPage} code="pedidos-venda" permissions={[Rule.Update]} withModal={true} title="Atualizar pedido de venda" />,
            },
        ],
    },
    {
        path: 'pedidos-de-venda/:saleOrderId',
        element: <Page Component={SaleOrderPage} code="pedidos-venda" permissions={[Rule.Show]} />,
        children: [
            {
                index: true,
                element: <Page Component={SaleOrderDetailsPage} code="pedidos-venda" permissions={[Rule.Show]} />,
            },
            {
                path: 'editar',
                element: <Page Component={CreateOrUpdateSaleOrderPage} code="pedidos-venda" permissions={[Rule.Update]} withModal={true} title="Atualizar pedido de venda" />,
            },
            {
                path: 'escopo-de-venda',
                element: <Page Component={SaleOrderProductsPage} code="pedidos-venda-escopo-venda" permissions={[Rule.Show, Rule.List]} />,
            },
            {
                path: 'documentos',
                element: <Page Component={SaleOrderDocumentsPage} code="pedidos-venda-documentos" permissions={[Rule.Show, Rule.List]} />,
                children: [
                    {
                        path: 'novo',
                        element: <Page Component={CreateOrUpdateSaleOrderDocumentPage} code="pedidos-venda-documentos" title="Novo documento" permissions={[Rule.Create]} />,
                    },
                    {
                        path: 'editar/:saleOrderDocumentId',
                        element: <Page Component={CreateOrUpdateSaleOrderDocumentPage} code="pedidos-venda-documentos" title="Atualizar documento" permissions={[Rule.Update]} />,
                    },
                ],
            },
            {
                path: 'despesas',
                element: <Page Component={SaleOrderExpensesPage} code="pedidos-venda-documentos" permissions={[Rule.Show, Rule.List]} />,
                children: [
                    {
                        path: 'novo',
                        element: <Page Component={CreateOrUpdateExpensePage} nature={ExpenseNature.SaleOrder} code="despesas" permissions={[Rule.Create]} withModal={true} />,
                    },
                    {
                        path: 'editar/:expenseId',
                        element: <Page Component={CreateOrUpdateExpensePage} nature={ExpenseNature.SaleOrder} code="despesas" title="Editar despesa" permissions={[Rule.Update]} withModal={true} />,
                    },
                ],
            },
            {
                path: 'ordens-de-servico',
                element: <Page Component={OrderServiceListPage} code="pedidos-venda-ordem-servico" permissions={[Rule.List]} />,
                children: [
                    {
                        path: 'novo',
                        element: <Page Component={CreateOrUpdateOrderServicePage} code="pedidos-venda-ordem-servico" title="Nova ordem de serviço" permissions={[Rule.Create]} />,
                    },
                    {
                        path: 'editar/:orderServiceId',
                        element: <Page Component={CreateOrUpdateOrderServicePage} code="pedidos-venda-ordem-servico" title="Atualizar ordem de serviço" permissions={[Rule.Update]} />,
                    },
                ],
            },
            {
                path: 'agendamentos',
                element: <Page Component={SaleOrderSchedulePage} code="pedidos-venda-agendamentos" title="Agendamentos" permissions={[Rule.List, Rule.Show]} />,
                children: [
                    {
                        path: 'novo',
                        element: <Page Component={CreateOrUpdateSaleOrdersSchedulesPage} code="pedidos-venda-agendamentos" permissions={[Rule.Create, Rule.Update]} />,
                    },
                ],
            },
            {
                path: 'ordens-de-servico/:orderServiceId',
                element: <Page Component={ServiceOrderPage} code="pedidos-venda-ordem-servico" permissions={[Rule.Show]} />,
                children: [
                    {
                        index: true,
                        element: <Page Component={ServiceOrderDetailsPage} code="pedidos-venda-ordem-servico-detalhes" permissions={[Rule.Show]} />,
                    },
                    {
                        path: 'escopo-de-compra',
                        element: <Page Component={ServiceOrderPurchaseScopePage} code="pedidos-venda-ordem-servico-escopo-compra" title="Escopos de compra" permissions={[Rule.List]} />,
                        children: [
                            {
                                path: 'validar-produtos',
                                element: <Page Component={ManageProductsPage} code="pedido-venda-validar-produtos" title="Validar produtos" permissions={[Rule.Show]} withModal={true} />,
                            },
                            {
                                path: 'aprovar-produtos',
                                element: <Page Component={ProductFinalApprovalPage} code="pedido-venda-aprovar-produtos" title="Aprovar produtos" permissions={[Rule.Show]} withModal={true} />,
                            },
                            {
                                path: 'nova-ordem-de-compra',
                                element: (
                                    <Page Component={CreateOrUpdateOrderPurchasePage} code="pedido-venda-ordem-compra" title="Nova ordem de compra" permissions={[Rule.Create]} withModal={true} />
                                ),
                            },
                            {
                                path: 'adicionar-escopo-de-compra',
                                element: <Page Component={CreatePurchaseScopePage} code="pedido-venda-novo-escopo-compra" title="Novo escopo de compra" permissions={[Rule.Create]} withModal={true} />,
                            },
                        ],
                    },
                    {
                        path: 'escopo-de-compra-extra',
                        element: (
                            <Page Component={ServiceOrderExtraPurchaseScopePage} code="pedidos-venda-ordem-servico-escopo-compra-extra" title="Escopos de compra extra" permissions={[Rule.Show]} />
                        ),
                    },
                    {
                        path: 'escopo-de-venda',
                        element: <Page Component={ServiceOrderSaleScopePage} code="pedidos-venda-ordem-servico-escopo-venda" title="Escopos de venda" permissions={[Rule.List]} />,
                    },
                    {
                        path: 'deducoes',
                        element: <Page Component={OrderServiceDeductionsPage} code="pedidos-venda-ordem-servico-deducao" permissions={[Rule.List]} />,
                        children: [
                            {
                                path: 'novo',
                                element: <Page Component={CreateOrUpdateDeductionsPage} code="pedidos-venda-ordem-servico-deducao" title="Nova dedução" permissions={[Rule.Create]} withModal={true} />,
                            },
                        ],
                    },
                    {
                        path: 'produtos',
                        element: <Page Component={ServiceOrderProducts} code="pedidos-venda-ordem-servico-produtos" title="Produtos" permissions={[Rule.Show]} />,
                    },
                    {
                        path: 'cronograma',
                        element: <Page Component={TimelinePage} code="pedidos-venda-ordem-servico-cronograma" title="Atualizar documento" permissions={[Rule.Show]} />,
                        children: [
                            {
                                path: ':taskId',
                                element: <Page Component={TaskPage} withModal={true} code="pedidos-venda-ordem-servico-cronograma" permissions={[Rule.Show]} />,
                            },
                        ],
                    },
                    {
                        path: 'ordens-de-compra',
                        element: <Page Component={OrderPurchasePage} code="pedido-venda-ordem-compra" title="Atualizar documento" permissions={[Rule.List, Rule.Show]} />,
                        children: [
                            {
                                path: 'novo',
                                element: (
                                    <Page Component={CreateOrUpdateOrderPurchasePage} code="pedido-venda-ordem-compra" title="Nova ordem de compra" permissions={[Rule.Create]} withModal={true} />
                                ),
                            },
                        ],
                    },
                    {
                        path: 'ordens-de-compra/:purchaseOrderId',
                        element: <Page Component={PurchaseOrderPage} code="pedido-venda-ordem-compra" title="Atualizar documento" permissions={[Rule.List, Rule.Show]} />,
                        children: [
                            {
                                path: 'despesas/novo/compra',
                                element: <Page Component={PurchaseOrderExpensesCreateOrUpdatePage} code="despesas" nature={ExpenseNature.PurchaseOrder} permissions={[Rule.Create]} withModal={true} />,
                            },
                            {
                                path: 'entrega-do-estoque',
                                element: <Page Component={StockDeliveryPage} code="pedido-venda-ordem-compra" permissions={[Rule.Create]} withModal={true} />,
                            },
                            {
                                path: 'entrega-direta/despesas/novo/compra',
                                element: (
                                    <Page Component={PurchaseOrderExpensesCreateOrUpdatePage} nature={ExpenseNature.PurchaseOrderBilled} code="despesas" permissions={[Rule.Create]} withModal={true} />
                                ),
                            },
                        ],
                    },
                    {
                        path: 'relatorio-de-obras',
                        element: <Page Component={WorksReportPage} code="pedidos-venda-ordem-servico-relatorio" title="Relatório de obras" permissions={[Rule.List]} />,
                        children: [
                            {
                                path: 'novo',
                                element: <Page Component={CreateOrUpdateOrderServiceWorkReportPage} code="pedidos-venda-ordem-servico-relatorio" withModal={true} permissions={[Rule.Create]} />,
                            },
                        ],
                    },
                    {
                        path: 'notas-de-vendas',
                        element: (
                            <Page
                                Component={OrderServiceSalesNotesPage}
                                code="pedidos-venda-ordem-servico-notas-venda"
                                title="Notas de vendas"
                                permissions={[Rule.Show, Rule.List]}
                                hideHeader={true}
                            />
                        ),
                    },
                    {
                        path: 'despesas',
                        element: <Page Component={ServiceOrderExpenses} code="pedidos-venda-ordem-servico" title="Despesas" permissions={[Rule.Show]} />,
                    },
                    {
                        path: 'produtos-despesas',
                        element: <Page Component={ServiceOrderExpenseProductsPage} code="pedidos-venda-ordem-servico-produtos-despesas" title="Produtos / Despesas" permissions={[Rule.Show]} />,
                    },
                    {
                        path: 'despesas-administrativas',
                        element: (
                            <Page
                                Component={ServiceOrderExpensesAdministrative}
                                code="pedidos-venda-ordem-servico-escopo-despesas-administrativas"
                                title="Despesas Administrativas"
                                permissions={[Rule.Show]}
                            />
                        ),
                    },
                    {
                        path: 'categorias',
                        element: <Page Component={ServiceOrderProductsCategories} code="pedidos-venda-ordem-servico-categorias" title="Categorias" permissions={[Rule.Show]} />,
                    },
                    {
                        path: 'receitas',
                        element: <Page Component={RevenuesPage} isOrderService={true} withSaleScope={true} code="pedidos-venda-ordem-servico-receitas" title="Receitas" permissions={[Rule.Show]} />,
                        children: [
                            {
                                path: ':receiptOrderId',
                                element: <Page Component={PaymentOrderPage} code="pedidos-venda-ordem-servico-receitas" permissions={[Rule.Show]} withModal={true} />,
                                children: [
                                    {
                                        path: 'editar',
                                        element: <Page Component={TransactionUpdatePage} code="pedidos-venda-ordem-servico-receitas" permissions={[Rule.Show]} withModal={true} />,
                                    },
                                    {
                                        path: 'documentos/novo',
                                        element: (
                                            <Page
                                                Component={CreateOrUpdateTransactionDocumentPage}
                                                code="pedidos-venda-ordem-servico-receitas-documentos"
                                                permissions={[Rule.Create]}
                                                withModal={true}
                                            />
                                        ),
                                    },
                                    {
                                        path: 'documentos/:documentId/editar',
                                        element: (
                                            <Page
                                                Component={CreateOrUpdateTransactionDocumentPage}
                                                code="pedidos-venda-ordem-servico-receitas-documentos"
                                                permissions={[Rule.Update]}
                                                withModal={true}
                                            />
                                        ),
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'saldo-financeiro',
                        element: <Page Component={ServiceOrderFinancialBalancePage} code="pedidos-venda-ordem-servico-saldo-financeiro" title="Saldo financeiro" permissions={[Rule.List]} />,
                        children: [
                            {
                                path: ':financialBalanceId',
                                element: <Page Component={ServiceOrderFinancialBalanceDetailsPage} code="pedidos-venda-ordem-servico-saldo-financeiro" withModal={true} permissions={[Rule.Show]} />,
                            },
                        ],
                    },
                    {
                        path: 'movimentacoes',
                        element: <Page Component={OrderServiceMovementsPage} code="pedidos-venda-ordem-servico-movimentacoes" permissions={[Rule.List]} />,
                    },
                ],
            },
            {
                path: 'deducoes',
                element: <Page Component={SaleOrderDeductionPage} code="pedidos-venda-deducoes" permissions={[Rule.List]} />,
            },
            {
                path: 'notas-de-venda',
                element: <Page Component={SaleOrderSalesNotesPage} code="pedidos-venda-notas-venda" permissions={[Rule.List, Rule.Show]} />,
            },
            {
                path: 'receitas',
                element: <Page Component={RevenuesPage} isOrderService={false} code="pedidos-venda-receitas" permissions={[Rule.List]} />,
                children: [
                    {
                        path: ':receiptOrderId',
                        element: <Page Component={PaymentOrderPage} code="pedidos-venda-receitas" permissions={[Rule.Show]} withModal={true} />,
                        children: [
                            {
                                path: 'editar',
                                element: <Page Component={TransactionUpdatePage} code="pedidos-venda-receitas" permissions={[Rule.Show]} withModal={true} />,
                            },
                            {
                                path: 'documentos/novo',
                                element: <Page Component={CreateOrUpdateTransactionDocumentPage} code="pedidos-venda-receitas-documentos" permissions={[Rule.Create]} withModal={true} />,
                            },
                            {
                                path: 'documentos/:documentId/editar',
                                element: <Page Component={CreateOrUpdateTransactionDocumentPage} code="pedidos-venda-receitas-documentos" permissions={[Rule.Update]} withModal={true} />,
                            },
                        ],
                    },
                ],
            },
            {
                path: 'saldo-financeiro',
                element: <Page Component={SaleOrderFinancialBalancePage} code="pedidos-venda-saldo-financeiro" permissions={[Rule.List]} />,
            },
        ],
    },
];

export default salesOrders;
