import classnames from 'classnames';
import { memo } from 'react';
import { TableItemValuesValue } from 'types/models/reports';
import { formatMoney } from 'utils/money';

type DRETableBodyItemProps = TableItemValuesValue & { className?: string };

const ReportPageDetailsBlockTableBodyItem = ({ type = 'blank', value, className, customComponent }: DRETableBodyItemProps) => {
    const currency = classnames(`dre-currency  px-2 border-slate-100`);

    switch (type) {
        case 'currency':
            return (
                <td className={classnames(currency, className)}>
                    <div className="flex justify-between px-2">
                        <span>R$</span>
                        <span>{formatMoney(value as number)?.replace('R$', '')}</span>
                    </div>
                </td>
            );

        case 'percentage':
            return <td className={classnames(currency, className, 'font-bold text-center')}>{formatMoney(value as number)?.replace('R$', '')}%</td>;

        case 'blank':
            return <td className={classnames(currency, className, 'border-dashed')}></td>;

        case 'custom':
            return <td className={classnames(currency, className)}>{customComponent!(value)}</td>;

        case 'text':
            return <td className={classnames(currency, className, 'text-center')}>{value}</td>;

        default:
            return <td className={classnames(currency, className, 'text-center')}>{formatMoney(value as number)?.replace('R$', '')}</td>;
    }
};

export default memo(ReportPageDetailsBlockTableBodyItem);
