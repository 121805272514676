import TableCell from '@mui/material/TableCell/TableCell';
import { CrudPageProps } from 'types/graphql';
import { Gender } from 'types/models/person';
import dictionary from 'utils/dictionary';
import { array, number, object, string } from 'yup';
import { CrudDetails } from 'types/graphql';
import { BankAccountType } from 'types/models/bank';
import { pixTypes } from 'utils/statics';

export const customerContactSchema = object({
    name: string().required(dictionary.validation.required).trim(),
    title: string().required(dictionary.validation.required).trim(),
    gender: number().oneOf([Gender.Female, Gender.Male, Gender.Other]).required(dictionary.validation.required),
    email: string().required(dictionary.validation.required).trim(),
    phones: array(
        object({
            ddd: string().min(2, dictionary.validation.string.min(2)).max(2, dictionary.validation.string.max(2)).trim(),
            number: string(),
        }).required(dictionary.validation.required)
    )
        .default(null)
        .optional(),
});

export const providerContactsListConfig = (id): CrudPageProps<{}> => ({
    graphql: {
        table: 'providerContact',
        searchableField: 'person.name',
        statusPath: 'status',
        columns: [
            {
                name: 'id',
                options: { display: 'excluded' },
            },
            {
                name: 'person.title',
                label: dictionary.content.graphql.title,
            },
            {
                name: 'person.name',
                label: dictionary.content.graphql.name,
            },
            {
                name: 'person.phones',
                label: dictionary.content.graphql.phones,
                options: {
                    customBodyRender: (value) => {
                        return value?.map((item) => `(${item.ddd}) ${item.number}`).join(', ') || '-';
                    },
                },
            },
            {
                name: 'person.email',
                label: dictionary.content.graphql.email,
            },
            {
                name: 'actions',
                label: 'Ações',
                options: {
                    customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />,
                },
            },
        ],
        where: {
            providerId: { _eq: id },
        },
    },
    title: 'Contatos',
    withBorder: true,
});

export const providerBankAccountConfig: CrudDetails = {
    table: 'providerBankAccount',
    fields: [
        'id',
        'bankAccount.bankId',
        'bankAccount.name',
        'bankAccount.type',
        'bankAccount.account',
        'bankAccount.accountDigit',
        'bankAccount.agency',
        'bankAccount.agencyDigit',
        'bankAccount.pix',
        'bankAccount.pixType',
        'bankAccount.className',
        'bankAccount.status',
    ],
};

export const providerBankAccountsListConfig = (id?: number): CrudPageProps<{}> => ({
    graphql: {
        table: 'providerBankAccount',
        searchableField: 'bankAccount.name',
        statusPath: 'bankAccount.status',
        columns: [
            {
                name: 'id',
                options: { display: 'excluded' },
            },
            {
                name: 'bankAccount.name',
                label: 'Nome',
            },
            {
                name: 'bankAccount.bank.name',
                label: 'Banco',
            },
            {
                name: 'bankAccount.type',
                label: dictionary.content.graphql.type,
                options: {
                    customBodyRender: (value) => (value === BankAccountType.Open ? 'Conta corrente' : 'Conta poupança'),
                },
            },
            {
                name: 'bankAccount.agencyDigit',
                options: { display: 'excluded' },
            },
            {
                name: 'bankAccount.agency',
                label: 'Agência',
                options: {
                    customBodyRender: (agency: string, { rowData }) => {
                        const [, , , , agencyDigit] = rowData;

                        return !!agencyDigit ? `${agency}-${agencyDigit}` : agency;
                    },
                },
            },
            {
                name: 'bankAccount.accountDigit',
                options: { display: 'excluded' },
            },
            {
                name: 'bankAccount.account',
                label: 'Conta',
                options: {
                    customBodyRender: (account: string, { rowData }) => {
                        const [, , , , , , accountDigit] = rowData;

                        return !!accountDigit ? `${account}-${accountDigit}` : account;
                    },
                },
            },
            {
                name: 'bankAccount.pixType',
                options: { display: 'excluded' },
            },
            {
                name: 'bankAccount.pix',
                label: 'PIX',
                options: {
                    customBodyRender: (pix, { rowData }) => {
                        const [, , , , , , , , pixType] = rowData;
                        const finded = pixTypes.find((item) => item.value === pixType);

                        const pixTypeFinal = !!finded && finded.label.toLowerCase();

                        let text = '';

                        if (!!pix) {
                            text += pix;
                        }

                        if (!!pixTypeFinal) {
                            text += ` (${pixTypeFinal})`;
                        }

                        return text || '-';
                    },
                },
            },
            {
                name: 'actions',
                label: 'Ações',
                options: {
                    customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />,
                },
            },
        ],
        where: {
            providerId: { _eq: id },
        },
    },
    api: {
        endpoint: `/providers/${id}/contacts`,
        useApiTodelete: true,
    },
    title: 'Contas bancárias',
    withBorder: true,
});
