import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import { getSaleOrderKey, getSaleOrderServiceOrdersKey } from './keys';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';
import { useShowApiErrors } from 'hooks/error/show-api-errors';
import { getPaginatedWithFiltersKey } from '../general/keys';

const useCreateOrUpdateServiceOrder = (saleOrderId?: string, orderServiceId?: string, ctx?: any) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const { showToastErrors } = useShowApiErrors();
    const goToParentRoute = useNavigateToParentRoute();

    const isUpdate = Boolean(orderServiceId);

    const request = async (payload) => {
        const method = isUpdate ? api.patch : api.post;
        const endpoint = isUpdate ? `/sale-orders/${saleOrderId}/service-orders/${orderServiceId}` : `/sale-orders/${saleOrderId}/service-orders`;

        return method(endpoint, payload);
    };

    return useMutation(request, {
        onSuccess: () => {
            showToast(`Ordem de serviço ${isUpdate ? 'atualizada' : 'criada'} com sucesso`, 'success');

            queryClient.invalidateQueries(getSaleOrderKey(saleOrderId));
            queryClient.invalidateQueries(getSaleOrderServiceOrdersKey(saleOrderId));

            if (!!ctx?.params) {
                queryClient.invalidateQueries(getPaginatedWithFiltersKey(ctx.params.endpoint, ctx.params.filters, ctx.params.page, ctx.params.rowsPerPage, ctx.params.orderBy));
            }

            goToParentRoute();
        },
        onError: (error: any) => {
            showToastErrors(error, error?.response?.data?.title || `Ocorreu um erro ao ${isUpdate ? 'atualizar' : 'criar'} a ordem de serviço`);
        },
    });
};

export default useCreateOrUpdateServiceOrder;
