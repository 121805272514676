import TableCell from '@mui/material/TableCell/TableCell';
import { jsonToGraphQLQuery, VariableType } from 'json-to-graphql-query';
import { CreateOrUpdateProps } from 'pages/shared/crud/create-or-update';
import { Status } from 'types/general';
import { CrudPageProps } from 'types/graphql';
import { ClassificationType } from 'types/models/classification';
import dictionary from 'utils/dictionary';
import { pkColumnsKey } from 'utils/environment';
import { object, string } from 'yup';

const schema = object({
    name: string().required(dictionary.validation.required),
});

const form: CreateOrUpdateProps<{ name: string }> = {
    modalClassName: 'w-[600px]',
    formClassName: '!grid-cols-1',
    fields: [
        {
            name: 'name',
            type: 'text',
        },
    ],
    beforeSend: (data) => ({ ...data, type: ClassificationType.Position }),
    schema,
};

const createOrUpdatePositionMutation = (isEdit = false) => {
    return jsonToGraphQLQuery(
        {
            mutation: {
                __name: 'CreateOrUpdatePosition',
                __variables: {
                    ...(isEdit
                        ? {
                              payload: 'ClassificationSetInput',
                              id: 'Int!',
                          }
                        : {
                              payload: 'ClassificationInsertInput!',
                          }),
                },
                item: {
                    __aliasFor: isEdit ? 'updateClassificationByPk' : 'insertClassificationOne',
                    __args: {
                        ...(isEdit
                            ? {
                                  [pkColumnsKey]: { id: new VariableType('id') },
                                  _set: new VariableType('payload'),
                              }
                            : {
                                  object: new VariableType('payload'),
                              }),
                    },
                    id: true,
                },
            },
        },
        { pretty: true }
    );
};

const config: CrudPageProps<{ name: string }>[] = [
    {
        title: 'Cargos',
        graphql: {
            table: 'classification',
            searchableField: 'name',
            columns: [
                {
                    name: 'id',
                    options: { display: 'excluded' },
                },
                {
                    name: 'name',
                    label: dictionary.content.graphql['name'],
                },
                {
                    name: 'actions',
                    label: 'Ações',
                    options: {
                        customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />,
                    },
                },
            ],
            where: {
                type: { _eq: ClassificationType.Position },
                status: { _eq: Status.Active },
            },
            orderBy: { name: 'ASC' },
        },
    },
    {
        title: 'Novo cargo',
        graphql: {
            table: 'classification',
            query: createOrUpdatePositionMutation(),
        },
        defaultValues: { name: '' },
        form,
    },
    {
        title: 'Atualizar cargo',
        graphql: {
            table: 'classification',
            query: createOrUpdatePositionMutation(true),
        },
        defaultValues: { name: '' },
        form,
    },
];

export default config;
