import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import ListPage from 'pages/shared/crud/list';
import { useParams } from 'react-router-dom';
import { remittanceListConfig } from './utils';

const StockRemittancePage = ({ page }: WithPagePermissionsProps) => {
    const { stockId } = useParams();

    const config = remittanceListConfig(stockId);

    return <ListPage page={page} {...config} className="pb-10" />;
};

export default StockRemittancePage;
