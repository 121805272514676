import Badge from 'components/core/badge';
import { ApprovalStatus } from 'types/general';

type ApprovalBadgeProps = {
    status?: ApprovalStatus;
    className?: string;
};

const ApprovalBadge = ({ status, className = 'mr-2' }: ApprovalBadgeProps) => {
    if (!status) {
        return null;
    }

    if (status === ApprovalStatus.Approved) {
        return (
            <Badge variant="success" className={className}>
                Aprovada
            </Badge>
        );
    }

    if (status === ApprovalStatus.Disapproved) {
        return (
            <Badge variant="error" className={className}>
                Não aprovada
            </Badge>
        );
    }

    return (
        <Badge variant="warning" className={className}>
            Aguardando aprovação
        </Badge>
    );
};

export default ApprovalBadge;
