import RemoveButton from 'components/buttons/remove';
import Input from 'components/core/form/input';
import Select from 'components/core/form/select';
import Text from 'components/core/text';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FormFieldType } from 'types/models/form';
import { booleanOptions, formFieldTypes } from 'utils/statics';
import FormItemOptions from './item-options';
import { FormPayload } from 'services/queries/forms/use-create-or-update-form';
import get from 'lodash/get';

type FormItemProps = {
    groupIndex: number;
    index: number;
    onRemove: (index: number) => void;
};

const fieldsWithOptions = [FormFieldType.Checkbox, FormFieldType.Radio, FormFieldType.Select, FormFieldType.SelectSimple, FormFieldType.CheckboxWithField, FormFieldType.RadioWithField];

const FormItemField = ({ groupIndex, index, onRemove }: FormItemProps) => {
    const { control, formState, setValue } = useFormContext<FormPayload>();

    const fieldPath = `groups.${groupIndex}.fields.${index}` as const;

    const fieldType = useWatch({ control, name: `${fieldPath}.type` });
    const fieldOrder = useWatch({ control, name: `${fieldPath}.order` });

    const hasOptions = fieldsWithOptions.includes(fieldType);

    const getError = (field: keyof FormPayload['groups'][0]['fields'][0]) => get(formState.errors, `groups.[${groupIndex}].fields.[${index}].[${field}].message`);

    const handleSelectFieldType = (option) => {
        setValue(`${fieldPath}.type`, option?.value);

        const hasOptions = fieldsWithOptions.includes(option.value);

        setValue(`${fieldPath}.options`, hasOptions ? [{ name: '', subfields: [] }] : undefined);
    };

    return (
        <div className="box-with-border p-0 mb-4 last:mb-0">
            <div className="flex flex-row items-center justify-between p-4 border-b border-b-base-300">
                <Text variant="h6" className="text-secondary-900">
                    Campo {fieldOrder}
                </Text>
                {!!index && <RemoveButton className="!static !m-0 opacity-100" onClick={onRemove.bind(this, index)} />}
            </div>
            <div className="grid grid-cols-3 gap-4 p-4">
                <Controller
                    control={control}
                    name={`${fieldPath}.name`}
                    render={({ field }) => {
                        return <Input {...field} label="Nome" error={getError('name')} />;
                    }}
                />
                <Controller
                    control={control}
                    name={`${fieldPath}.type`}
                    render={({ field }) => {
                        const value = formFieldTypes.find((item) => item.value === field.value);

                        return <Select {...field} value={value} options={formFieldTypes} label="Tipo" error={getError('type')} onChange={handleSelectFieldType} />;
                    }}
                />
                <Controller
                    control={control}
                    name={`${fieldPath}.required`}
                    render={({ field }) => {
                        const value = booleanOptions.find((item) => item.value === field.value);

                        return (
                            <Select
                                {...field}
                                value={value}
                                options={booleanOptions}
                                label="Campo obrigatório?"
                                error={getError('required')}
                                onChange={(option: any) => field.onChange(option.value)}
                            />
                        );
                    }}
                />
            </div>
            {hasOptions && <FormItemOptions groupIndex={groupIndex} fieldIndex={index} />}
        </div>
    );
};

export default FormItemField;
