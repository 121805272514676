import Fab from '@mui/material/Fab/Fab';
import { MenuProps } from '@mui/material/Menu/Menu';
import useTheme from '@mui/material/styles/useTheme';
import SvgIcoClose from 'components/core/icon/files/ico-close';
import SvgIcoSettings from 'components/core/icon/files/ico-settings';
import Menu, { MenuItem } from 'components/menu';
import { MouseEvent, useState } from 'react';

type FabNavProps = {
    options?: MenuItem[];
};

const config: Pick<MenuProps, 'PaperProps' | 'anchorOrigin' | 'transformOrigin'> = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'right',
    },
};

const FabNav = ({ options = [] }: FabNavProps) => {
    const { palette } = useTheme();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleOpenFab = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
    const handleCloseFab = () => setAnchorEl(null);

    if (!options.length) {
        return null;
    }

    const formattedOptions = options.map((item) => ({
        ...item,
        onClick: (e: MouseEvent<Element, globalThis.MouseEvent>) => {
            item.onClick(e);

            handleCloseFab();
        },
    }));

    const Icon = Boolean(anchorEl) ? SvgIcoClose : SvgIcoSettings;

    return (
        <nav className="fixed right-4 bottom-4">
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} className="-mt-[72px]" onClose={handleCloseFab} content={formattedOptions} {...config} />
            <Fab color="primary" onClick={handleOpenFab}>
                <Icon color={palette.grey[100]} />
            </Fab>
        </nav>
    );
};

export default FabNav;
