import apiGraphQl from 'services/graphql';
import { useMutation, useQueryClient } from 'react-query';
import { getNotificationsKey, readNotificationQuery } from './keys';
import dayjs from 'dayjs';

type Payload = { read: boolean; readDate: string; updatedAt: string };

type Response = {
    updateNotification: {
        returning: Array<{
            id: number;
        }>;
    };
};

const useReadNotifications = () => {
    const queryClient = useQueryClient();

    const request = async (ids: number[]) => {
        const payload: Payload = {
            read: true,
            updatedAt: dayjs().utc(true).toISOString(),
            readDate: dayjs().utc(true).toISOString(),
        };

        return apiGraphQl<Response>(readNotificationQuery, { ids, payload });
    };

    return useMutation(request, {
        onSuccess: () => {
            queryClient.invalidateQueries(getNotificationsKey);
        },
    });
};

export default useReadNotifications;
