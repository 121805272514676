import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';
import { Status } from 'types/general';
import { SaleOrderOrigin } from 'types/models/sale-order';

export const getAllServiceOrdersKey = ['get', 'all', 'orderService', 'options'];
export const getSaleOrdersByCompanyIdKey = (companyId?: number, customerId?: number) => ['get', 'all', 'saleOrders', 'options', 'company', companyId, 'customer', customerId];
export const getOrderServicesByCompanyIdKey = (companyId?: number, customerId?: number) => ['get', 'all', 'orderServices', 'options', 'company', companyId, 'customer', customerId];
export const getReceiptsByIdsKey = (ids?: number[]) => ['get', 'all', 'transaction', 'options', ids];
export const getAllSalesReceiptsSumsKeys = (where?: any) => ['get', 'salesReceiptAggregate', 'sums', where];
export const getSaleNoteByIdKey = (saleNoteId?: number) => ['salesReceipt', 'get', saleNoteId];
export const getCitiesTaxKey = ['get', 'all', 'citiesTax', 'options'];
export const getStatesTaxKey = ['get', 'all', 'statesTax', 'options'];

export const queries = {
    getAllServiceOrders: jsonToGraphQLQuery(
        {
            query: {
                __name: 'GetAllServiceOrdersOptions',
                items: {
                    __aliasFor: 'orderService',
                    __args: {
                        where: {
                            status: {
                                _eq: Status.Active,
                            },
                        },
                        orderBy: {
                            title: new EnumType('ASC'),
                        },
                    },
                    value: {
                        __aliasFor: 'id',
                    },
                    label: {
                        __aliasFor: 'code',
                    },
                    title: true,
                },
            },
        },
        { pretty: true }
    ),
    getCitiesTaxOptions: jsonToGraphQLQuery(
        {
            query: {
                __name: 'GetCitiesTaxOptions',
                items: {
                    __aliasFor: 'cityTax',
                    __args: {
                        where: {
                            status: {
                                _eq: Status.Active,
                            },
                        },
                    },
                    id: true,
                    city: {
                        id: true,
                        name: true,
                    },
                    cityId: true,
                },
            },
        },
        { pretty: true }
    ),
    getSaleOrderByCompanyId: (companyId?: number, nature?: SaleOrderOrigin, customerId?: number) => {
        return jsonToGraphQLQuery(
            {
                query: {
                    __name: 'GetSaleOrderByCompanyId',
                    items: {
                        __args: {
                            where: {
                                status: {
                                    _eq: Status.Active,
                                },
                                companyId: {
                                    _eq: companyId,
                                },
                                customerId: {
                                    _eq: customerId,
                                },
                                ...(Boolean(nature) && {
                                    origin: {
                                        _eq: nature,
                                    },
                                }),
                            },
                            orderBy: {
                                code: new EnumType('ASC'),
                            },
                        },
                        __aliasFor: 'saleOrder',
                        value: {
                            __aliasFor: 'id',
                        },
                        label: {
                            __aliasFor: 'code',
                        },
                        branchId: true,
                    },
                },
            },
            { pretty: true }
        );
    },
    getServiceOrdersByCompanyId: (companyId?: number, customerId?: number) => {
        return jsonToGraphQLQuery(
            {
                query: {
                    __name: 'GetServiceOrdersByCompanyId',
                    items: {
                        __args: {
                            where: {
                                status: {
                                    _eq: Status.Active,
                                },
                                saleOrder: {
                                    companyId: {
                                        _eq: companyId,
                                    },
                                    customerId: {
                                        _eq: customerId,
                                    },
                                },
                            },
                            orderBy: {
                                code: new EnumType('ASC'),
                            },
                        },
                        __aliasFor: 'orderService',
                        value: {
                            __aliasFor: 'id',
                        },
                        label: {
                            __aliasFor: 'code',
                        },
                        title: true,
                    },
                },
            },
            { pretty: true }
        );
    },
    getReceiptsByIds: (serviceOrdersIds: number[], isSaleNature = false) => {
        const baseKeyName = isSaleNature ? 'saleOrderId' : 'orderServiceId';

        return jsonToGraphQLQuery(
            {
                query: {
                    __name: 'GetReceiptsByIdOptions',
                    items: {
                        __aliasFor: 'transaction',
                        __args: {
                            where: {
                                receipt: {
                                    receiptOrigin: {
                                        receiptOriginSaleOrder: {
                                            [baseKeyName]: {
                                                _in: serviceOrdersIds,
                                            },
                                        },
                                    },
                                },
                                status: {
                                    _eq: Status.Active,
                                },
                            },
                        },
                        value: {
                            __aliasFor: 'id',
                        },
                        label: {
                            __aliasFor: 'code',
                        },
                        netValue: true,
                    },
                },
            },
            { pretty: true }
        );
    },
    getStatesTaxOptions: jsonToGraphQLQuery({
        query: {
            __name: 'GetAllStateTaxOptions',
            items: {
                __aliasFor: 'stateTax',
                __args: {
                    where: {
                        status: {
                            _eq: Status.Active,
                        },
                    },
                    orderBy: {
                        state: {
                            name: new EnumType('ASC'),
                        },
                    },
                },
                value: {
                    __aliasFor: 'id',
                },
                label: {
                    __aliasFor: 'state',
                    state: {
                        __aliasFor: 'name',
                    },
                },
            },
        },
    }),
};
