import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useState } from 'react';
import OrderServicePurchaseOrderTableProducts from '../../../sale-order-product/purchase-order-table-products';
import OrderServiceExpenseTableProducts from '../../../expense-products/expense-products-table';
import OrderServiceFinancialBalanceMovements from './stock';
import { OrderServiceFinancialBalance } from 'types/models/order-service';

type OrderServiceFinancialBalanceDetailsTabsProps = {
    purchaseOrders?: OrderServiceFinancialBalance['purchaseOrders'];
    expenses?: OrderServiceFinancialBalance['expenses'];
    stockProduct?: OrderServiceFinancialBalance['stockProduct'];
    productUnit?: string;
};

const OrderServiceFinancialBalanceDetailsTabs = ({ purchaseOrders = [], expenses = [], productUnit = '-', stockProduct }: OrderServiceFinancialBalanceDetailsTabsProps) => {
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const handleChangeTab = (_: any, nextSlug: any) => setActiveTabIndex(nextSlug);

    return (
        <>
            <Tabs variant="scrollable" value={activeTabIndex} onChange={handleChangeTab}>
                <Tab label="ORDENS DE COMPRAS" className="text-base py-5 mx-4" />
                <Tab label="DESPESAS" className="text-base py-5" />
                <Tab label="ESTOQUE" className="text-base py-5 mx-4" />
            </Tabs>
            {activeTabIndex === 0 && <OrderServicePurchaseOrderTableProducts withPurchaseProductStatus={true} purchaseOrders={purchaseOrders} />}
            {activeTabIndex === 1 && <OrderServiceExpenseTableProducts expenses={expenses} />}
            {activeTabIndex === 2 && <OrderServiceFinancialBalanceMovements productUnit={productUnit} stockProduct={stockProduct} />}
        </>
    );
};

export default OrderServiceFinancialBalanceDetailsTabs;
