import { useQuery } from 'react-query';
import apiGraphQl from 'services/graphql';

import { getSaleOrderScheduleHoursOptionsKey } from './keys';
import { getSaleOrderScheduleHoursOptionsQuery } from './utils';
import { Option } from 'types/general';

const useGetSaleOrderScheduleHoursOptions = (saleOrderScheduleDayId?: string) => {
    const request = async () => {
        const { items: scheduleHoursOptions } = await apiGraphQl<{ items: Option[] }>(getSaleOrderScheduleHoursOptionsQuery(saleOrderScheduleDayId));

        return scheduleHoursOptions;
    };

    return useQuery(getSaleOrderScheduleHoursOptionsKey(saleOrderScheduleDayId), request, { enabled: Boolean(saleOrderScheduleDayId) });
};

export default useGetSaleOrderScheduleHoursOptions;
