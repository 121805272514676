import * as React from 'react';
const SvgIcoCloseCircle = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M4.468.22a.75.75 0 0 1 .53-.22h6.003a.75.75 0 0 1 .53.22l4.25 4.248c.14.14.219.332.219.53v6.003a.75.75 0 0 1-.22.53l-4.248 4.25a.75.75 0 0 1-.53.219H4.998a.75.75 0 0 1-.53-.22L.22 11.532a.75.75 0 0 1-.22-.53V4.998a.75.75 0 0 1 .22-.53L4.468.22ZM5.31 1.5 1.5 5.31v5.38l3.81 3.81h5.38l3.81-3.81V5.31L10.69 1.5H5.31Z"
            clipRule="evenodd"
        />
        <path
            fill="currentColor"
            fillRule="evenodd"
            stroke="currentColor"
            d="M10.305 10.88a.407.407 0 1 0 .576-.575L8.576 8l2.305-2.305a.407.407 0 1 0-.576-.576L8 7.424 5.695 5.119a.407.407 0 1 0-.576.576L7.424 8l-2.305 2.305a.407.407 0 0 0 .576.576L8 8.576l2.305 2.305Z"
            clipRule="evenodd"
        />
    </svg>
);
export default SvgIcoCloseCircle;
