import { RouteObject } from 'react-router-dom';
import PaymentOrdersListPage from 'pages/private/payment-order';
import Page from 'pages/shared/page';
import { Rule } from 'types/permissions';
import PaymentOrderPage from 'pages/private/payment-order/view';
import CreateOrUpdateTransactionDocumentPage from 'pages/private/payment-order/view/documents/create-or-update';
import TransactionUpdatePage from 'pages/private/expenses/details/tabs/transactions/update';

const paymentOrders: RouteObject[] = [
    {
        // @ts-ignore
        breadcrumb: 'Ordens de Pagamento',
        path: 'ordens-de-pagamento',
        element: <Page Component={PaymentOrdersListPage} code="ordens-pagamento" permissions={[Rule.List]} />,
        children: [
            {
                path: ':paymentOrderId',
                element: <Page Component={PaymentOrderPage} code="ordens-pagamento" title="Ordem de pagamento" permissions={[Rule.Show]} withModal={true} />,
                children: [
                    {
                        path: 'editar',
                        element: <Page Component={TransactionUpdatePage} code="ordens-pagamento" permissions={[Rule.Show]} withModal={true} />,
                    },
                    {
                        path: 'documentos/novo',
                        element: <Page Component={CreateOrUpdateTransactionDocumentPage} code="ordens-pagamento-documentos" permissions={[Rule.Create]} withModal={true} />,
                    },
                    {
                        path: 'documentos/:documentId/editar',
                        element: <Page Component={CreateOrUpdateTransactionDocumentPage} code="ordens-pagamento-documentos" permissions={[Rule.Update]} withModal={true} />,
                    },
                ],
            },
        ],
    },
];

export default paymentOrders;
