import useDebounce from 'hooks/keyboard/use-debounce';
import { useState } from 'react';
import useGetProducts from './use-get-products';
import { generateProductsQuery } from './utils';
import { ApprovalStatus, Status } from 'types/general';

const TERM_KEYS = ['name', 'code'];

const useGetDebouncedProducts = <T, K>(productsQueryFields: string[], transformer?: (data: Partial<T>[]) => K, whereClause = {}, termKeys = TERM_KEYS) => {
    const [name, code] = termKeys;

    const [searchTerm, setSearchTerm] = useState('');

    const debouncedSelectedProduct = useDebounce(searchTerm, 500);

    const where = {
        status: { _eq: Status.Active },
        approvalStatus: { _eq: ApprovalStatus.Approved },
        ...(Boolean(searchTerm) && {
            _or: [{ [name]: { _ilike: `%${searchTerm}%` } }, { [code]: { _ilike: `%${searchTerm}%` } }],
        }),
        ...(Boolean(whereClause) && whereClause),
    };

    const { data: products = [], isLoading: isLoadingProducts } = useGetProducts<T>(generateProductsQuery(productsQueryFields, where), !!debouncedSelectedProduct, transformer);

    return {
        products: products as K,
        isLoadingProducts,
        searchTerm,
        setSearchTerm,
    };
};

export default useGetDebouncedProducts;
