import { useMutation } from 'react-query';
import api from 'services/api';
import useToast from 'hooks/toast/use-toast';

export type UseRequestChangePayload = {
    requestedDate: string;
    justification: string;
    transactions: number[];
};

const useTransactionRequestChange = () => {
    const { showToast } = useToast();

    const request = async (payload: UseRequestChangePayload) => api.post('/transactions/outcomes/request-change', payload);

    return useMutation(request, {
        onSuccess: () => {
            showToast('Pagamentos atualizados com sucesso!', 'success');
        },
        onError: (error: any) => {
            showToast(error?.response?.data?.title ?? 'Tivemos um problema ao atualizar os pagamentos. Tente novamente.', 'danger');
        },
    });
};

export default useTransactionRequestChange;
