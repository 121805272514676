import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { object, string } from 'yup';
import dictionary from 'utils/dictionary';
import DatePicker from 'components/core/datepicker';
import { formatDate } from 'utils/date';
import { pluralize } from 'utils/string';
import SelectedItemsTable from './table';
import useRescheduleTransaction, { UseRescheduleTransactionPayload } from 'services/queries/transactions/use-transaction-reschedule';
import { OPsActionsProps } from './report-payment';
import FormButtons from 'components/form-buttons';
import { excludeDates } from './ops-grouping/general';

type FormState = Omit<UseRescheduleTransactionPayload, 'transactions'>;

const schema = object({
    paymentForecast: string().typeError(dictionary.validation.required).required(dictionary.validation.required),
});

const RescheduleTransaction = ({ selectedItems, isReceipt = false, onClose, onSuccess }: OPsActionsProps) => {
    const selectedIds = selectedItems?.map(({ id }) => id) || [];

    const { handleSubmit, control, formState } = useForm<FormState>({
        mode: 'onSubmit',
        resolver: yupResolver(schema),
    });

    const { mutateAsync: rescheduleTransaction, isLoading: isSubmitting } = useRescheduleTransaction(isReceipt);

    const getError = (name: keyof FormState) => formState?.errors?.[name]?.message as string;

    const submit = async (data: FormState) => {
        try {
            const payload: UseRescheduleTransactionPayload = {
                paymentForecast: formatDate(data.paymentForecast, 'YYYY-MM-DD'),
                transactions: selectedIds,
            };

            await rescheduleTransaction(payload);

            onSuccess?.();
        } catch (error) {
            console.log('submit', error);
        }
    };

    return (
        <Modal
            contentClassnames="min-w-[650px]"
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    Reprogramar
                </Text>
            }
            closeOnClickOutside={false}
            onClose={onClose}>
            <form className="p-6 pt-0" onSubmit={handleSubmit(submit)}>
                <Text as="p" className="text-base-500 mb-3 text-base block">
                    Total de {pluralize(selectedIds?.length, 'despesas', 'despesa')} selecionadas para pagar
                </Text>
                <SelectedItemsTable items={selectedItems} isReceiptOrders={isReceipt} />
                <div className="flex flex-col gap-4 mb-6">
                    <Controller
                        name="paymentForecast"
                        control={control}
                        render={({ field }) => (
                            <DatePicker
                                removeFirstDate={false}
                                withProvisionLogic={!isReceipt ? true : false}
                                inputProps={{
                                    label: 'Data solicitada',
                                    placeholderText: 'Selecione uma data',
                                    error: getError('paymentForecast'),
                                    ...field,
                                }}
                                muiDatePickerProps={{
                                    shouldDisableDate: !isReceipt ? excludeDates : undefined,
                                }}
                            />
                        )}
                    />
                </div>
                <FormButtons
                    isLoading={isSubmitting}
                    cancelButton={{
                        onClick: onClose,
                    }}
                />
            </form>
        </Modal>
    );
};

export default RescheduleTransaction;
