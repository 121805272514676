import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import Text from 'components/core/text';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import CurrencyInput from 'components/core/form/currency';
import DatePicker from 'components/core/datepicker';
import Select from 'components/core/form/select';
import Switch from 'components/core/switch';
import { formatMoney } from 'utils/money';
import useGetClassifications from 'services/queries/classifications/use-get-classifications';
import { generateClassificationQuery } from 'services/queries/classifications/utils';
import { ClassificationType } from 'types/models/classification';
import { memo, useEffect } from 'react';
import { generateDueAndProvisioningDates, getProvisioningOptions } from 'pages/private/expenses/crud/utils';
import { LoanPayload } from 'types/models/loan';
import { currentDateWithoutTimezone } from 'utils/date';
import dayjs from 'dayjs';

const provisionOptions = getProvisioningOptions({ date: currentDateWithoutTimezone });

const Installments = () => {
    const { formState, control, watch, reset } = useFormContext<LoanPayload>();

    const principalValue = watch('value', 0);
    const instalmentValue = watch('expense.instalmentValue', 0);
    const installments = Number(watch('instalments', 0));
    const dueDateForm = watch('expense.dueDate');
    const paymentForm = watch('expense.paymentForm');

    const { data: paymentForms = [] } = useGetClassifications(generateClassificationQuery(ClassificationType.PaymentForm));

    const { fields: transactions, replace } = useFieldArray({ control, name: 'expense.transactions' });

    const transactionsTotal = watch('expense.transactions', []).reduce((acc, curr) => acc + curr.grossValue, 0);
    const remainingValue = principalValue - transactionsTotal;

    useEffect(() => {
        if (installments > 1 && !!dueDateForm) {
            const currentPaymentForm = paymentForms.find((item) => item.value === paymentForm);

            const arr = Array.from(Array(installments), (_, index) => {
                const dueDateDayjs = dayjs(dueDateForm).add(index, 'month').toISOString();
                const { dueDate, firstPaymentForecast, restPaymentForecast } = generateDueAndProvisioningDates(dueDateDayjs, index);

                return {
                    order: index + 1,
                    description: `Parcela ${index + 1}`,
                    dueDate,
                    paymentForecast: !index ? firstPaymentForecast : restPaymentForecast,
                    grossValue: instalmentValue,
                    provisioned: false,
                    paymentForm: currentPaymentForm?.value || paymentForms[0]?.value,
                };
            });

            replace(arr);
        } else {
            replace([]);
        }
    }, [installments, paymentForms, dueDateForm, instalmentValue, paymentForm, reset, watch, replace]);

    return (
        <div className="px-6 mb-6">
            <div className="border border-base-300 p-4 rounded-[14px] mb-6">
                <Text as="h6" variant="h5" className="text-heading mb-4">
                    Parcelas
                </Text>
                {transactions.length > 1 && (
                    <>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Text className="text-base-500 uppercase">Referente a parcela</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text className="text-base-500 uppercase">Vencimento</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text className="text-base-500 uppercase">Provisionamento</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text className="text-base-500 uppercase">Provisionada</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text className="text-base-500 uppercase">Valor da Parcela</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text className="text-base-500 uppercase">Forma de Pagamento</Text>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {transactions.map((item, index) => {
                                    const getError = (name: string) => formState?.errors?.expense?.transactions?.[index]?.[name]?.message;

                                    return (
                                        <TableRow key={item.id}>
                                            <TableCell>
                                                <span className="text-heading text-base font-medium">Parcela {index + 1}</span>
                                            </TableCell>
                                            <TableCell>
                                                <Controller
                                                    name={`expense.transactions.${index}.dueDate`}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <DatePicker
                                                            inputProps={{
                                                                ...field,
                                                                placeholderText: 'Vencimento',
                                                                error: getError('dueDate'),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Controller
                                                    name={`expense.transactions.${index}.paymentForecast`}
                                                    control={control}
                                                    render={({ field }) => {
                                                        const value = provisionOptions.find((item) => item.value.includes(field.value));

                                                        return (
                                                            <Select
                                                                {...field}
                                                                value={value}
                                                                options={provisionOptions}
                                                                placeholder="Provisionamento"
                                                                error={getError('paymentForecast')}
                                                                onChange={(option: any) => field.onChange(option.value)}
                                                            />
                                                        );
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Controller
                                                    name={`expense.transactions.${index}.provisioned`}
                                                    control={control}
                                                    render={({ field }) => <Switch value={field.value} onChange={(value) => field.onChange(value)} defaultLabel="Não" activeLabel="Sim" />}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Controller
                                                    name={`expense.transactions.${index}.grossValue`}
                                                    control={control}
                                                    render={({ field }) => {
                                                        return (
                                                            <CurrencyInput
                                                                left={
                                                                    <Text as="span" variant="body.medium.sm" className="text-heading">
                                                                        R$
                                                                    </Text>
                                                                }
                                                                value={field.value}
                                                                onValueChange={({ floatValue = 0 }) => field.onChange(floatValue)}
                                                                placeholder="Valor"
                                                                parentClassName="w-[150px]"
                                                                error={getError('value')}
                                                            />
                                                        );
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Controller
                                                    name={`expense.transactions.${index}.paymentForm`}
                                                    control={control}
                                                    render={({ field }) => {
                                                        const value = paymentForms.find((item) => item.value === field.value);

                                                        return (
                                                            <Select
                                                                {...field}
                                                                value={value}
                                                                options={paymentForms}
                                                                placeholder="Forma de Pagamento"
                                                                error={getError('paymentForm')}
                                                                onChange={(option: any) => field.onChange(option.value)}
                                                            />
                                                        );
                                                    }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        <div className="bg-secondary-100 py-4 px-6 bg-opacity-20 text-right mt-4 rounded-[14px]">
                            <div>
                                <Text className="text-base-700">Total das parcelas: </Text>
                                <Text variant="body.medium.2xs" className="text-heading">
                                    {formatMoney(transactionsTotal)}
                                </Text>
                            </div>
                            {remainingValue > 0 && (
                                <div>
                                    <Text className="text-base-700">Faltam: </Text>
                                    <Text variant="body.medium.2xs" className="text-heading">
                                        {formatMoney(remainingValue)}
                                    </Text>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default memo(Installments);
