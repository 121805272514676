import Datatable from 'components/core/table/datatable';
import useGetOrderServicesPurchaseScope, { GetOrderServicesPurchaseScopeParams } from 'services/queries/order-services/use-get-order-services-purchase-scope';
import { canDoActions, listColumnsApprovedScope, orderServiceSaleScopeOrderByMap } from './utils';
import { OrderServiceStatus } from 'types/models/order-service';
import ServiceOrderPurchaseScopePageActions from './actions';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import useConfig from 'store/config/use-config';
import { PurchaseProductRequest } from 'types/models/purchase-order';
import PurchaseScopeRequestsModal from './requests-modal';

type RegularProductsListProps = {
    orderServiceSlug?: OrderServiceStatus['slug'];
    requestConfig: GetOrderServicesPurchaseScopeParams;
    onSetFilters?: React.Dispatch<any>;
};

const RegularProductsList = ({ requestConfig, orderServiceSlug, onSetFilters }: RegularProductsListProps) => {
    const [page, setPage] = useState(1);
    const [historyRequests, setHistoryRequests] = useState<PurchaseProductRequest[]>([]);

    const navigate = useNavigate();
    const { config } = useConfig();

    const { data: products, isLoading: isLoadingProducts } = useGetOrderServicesPurchaseScope({
        ...requestConfig,
        filters: {
            ...requestConfig.filters,
            page,
            totalPerPage: config.tables.rowsPerPage,
        },
    });

    const handleClickAction = (route: string) => (tableSelectedProducts: any[]) => {
        if (!tableSelectedProducts?.length) {
            return;
        }

        navigate(route, {
            state: { tableSelectedProducts },
        });
    };

    return (
        <>
            <Datatable
                withBorder={false}
                data={products?.items || []}
                options={{
                    sort: true,
                    filter: false,
                    search: false,
                    print: false,
                    download: false,
                    viewColumns: false,
                    serverSide: true,
                    searchAlwaysOpen: false,
                    enableNestedDataAccess: '.',
                    pagination: false,
                    elevation: 21,
                    page,
                    selectableRows: canDoActions.includes(orderServiceSlug || '') ? 'multiple' : 'none',
                    count: products?.total,
                    onChangePage: setPage,
                    ...(canDoActions.includes(orderServiceSlug || '') && {
                        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
                            <ServiceOrderPurchaseScopePageActions items={products?.items || []} onClick={handleClickAction} {...{ selectedRows, displayData, setSelectedRows }} />
                        ),
                    }),

                    onColumnSortChange: (changedColumn, direction) => {
                        const orderByKeyName = orderServiceSaleScopeOrderByMap[changedColumn];

                        if (!orderByKeyName) {
                            return;
                        }

                        const orderBy = {
                            [orderByKeyName]: direction.toUpperCase(),
                        };

                        onSetFilters?.((prev) => ({
                            ...prev,
                            orderBy,
                        }));
                    },
                }}
                columns={listColumnsApprovedScope(setHistoryRequests)}
                loading={isLoadingProducts}
                className="toolbar-without-search"
            />
            {Boolean(historyRequests.length) && <PurchaseScopeRequestsModal onClose={setHistoryRequests.bind(this, [])} requests={historyRequests} />}
        </>
    );
};

export default RegularProductsList;
