import { WhereClause } from 'types/graphql';
import { State } from 'types/models/address';

export const getAllStatesOptionsKey = ['states', 'all', 'options'];
export const getStateKey = (variables?: WhereClause<Partial<State>>) => ['state', variables];

const queries = {
    getAll: `query GetStatesOptions {
        items: state(orderBy: {name: ASC}) {
            value: id
            label: name
        }
    }`,
    getState: `query GetState ($where: StateBoolExp) {
        state(where: $where) {
            id
        }
    }`,
};

export default queries;
