import classNames from 'classnames';
import theme from 'settings/theme';

type SpinnerProps = Partial<{
    className: string;
    color: string;
    size: number;
    fixed: boolean;
    parentClasses: string;
    wrapperClasses: string;
}>;

const Spinner = ({ className, color = theme.extend.colors.primary[300], size = 35, fixed = true, parentClasses, wrapperClasses }: SpinnerProps) => {
    const svgClasses = classNames(animation, className);
    const containerClasses = classNames(container, fixed && fixedContainer, parentClasses);

    return (
        <div className={containerClasses}>
            <div className={wrapperClasses}>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 18 18" className={svgClasses}>
                    <path fill={color} stroke={color} strokeWidth={1} d="M15 8c0 3.9-3.1 7-7 7s-7-3-7-7h-1c0 4 3.6 8 8 8s8-3.6 8-8h-1z" style={{ transform: 'translate(1px, 1px)' }}></path>
                </svg>
            </div>
        </div>
    );
};

const { animation, container, fixedContainer } = {
    animation: 'animate-spin',
    container: 'flex items-center justify-center p-0 m-0',
    fixedContainer: 'absolute left-0 top-0 w-full h-full z-10'
};

export default Spinner;
