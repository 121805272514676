import TableCell from '@mui/material/TableCell/TableCell';
import PersonAdvancedSearch from 'components/advanced-search/person';
import Badge from 'components/core/badge';
import get from 'lodash/get';
import { ColorVariant } from 'types/colors';
import { CrudPageProps } from 'types/graphql';
import { Person, PersonContractStatusTitle, PersonContractType } from 'types/models/person';
import { formatDate } from 'utils/date';
import dictionary from 'utils/dictionary';

const config: CrudPageProps<Person>[] = [
    {
        graphql: {
            table: 'person',
            searchableField: 'name',
            columns: [
                {
                    name: 'id',
                    options: { display: 'excluded' },
                },
                {
                    name: 'name',
                    label: dictionary.content.graphql['name'],
                },
                {
                    name: 'document',
                    label: dictionary.content.graphql['document'],
                },
                {
                    name: 'birthday',
                    label: dictionary.content.graphql['birthday'],
                    options: {
                        customBodyRender: (value) => formatDate(value),
                    },
                },
                {
                    name: 'maritalStatus',
                    label: dictionary.content.graphql['maritalStatus'],
                    options: {
                        customBodyRender: (value) => Object.values(dictionary.content.maritalStatus)[value - 1] || '-',
                    },
                },
                {
                    name: 'gender',
                    label: dictionary.content.graphql['gender'],
                    options: {
                        customBodyRender: (value) => Object.values(dictionary.content.genders)[value - 1] || '-',
                    },
                },
                {
                    name: 'phones',
                    label: dictionary.content.graphql['phones'],
                    options: {
                        customBodyRender: (value) => {
                            return value?.map((item) => `(${item.ddd}) ${item.number}`).join(', ') || '-';
                        },
                    },
                },

                {
                    name: 'personContracts.contractStatusId',
                    label: 'Status do contrato',
                    options: {
                        customBodyRender: (_, tableMeta) => {
                            const status = get(tableMeta, 'rowData[8].props.item.personContracts[0].contractStatusId');

                            const statusColors = new Map<PersonContractStatusTitle, ColorVariant>([
                                [PersonContractStatusTitle.Active, 'success'],
                                [PersonContractStatusTitle.MaternityLeave, 'grey'],
                                [PersonContractStatusTitle.SicknessAllowance, 'grey'],
                                [PersonContractStatusTitle.Resignation, 'warning'],
                                [PersonContractStatusTitle.Finished, 'error'],
                                [PersonContractStatusTitle.Transferred, 'info'],
                                [PersonContractStatusTitle.Vacation, 'primary'],
                                [PersonContractStatusTitle.MedicalCertificate, 'grey'],
                            ]);

                            const label = get(dictionary.content.personContractStatusTitle, PersonContractStatusTitle[status]);

                            if (!label) {
                                return '-';
                            }

                            return (
                                <Badge variant={statusColors.get(status)!} className="px-[10px] py-[6px]">
                                    {label}
                                </Badge>
                            );
                        },
                    },
                },

                {
                    name: 'actions',
                    label: 'Ações',
                    options: {
                        customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />,
                    },
                },
            ],
            where: {
                type: {
                    _eq: PersonContractType.Personal,
                },
                personContracts: {
                    contractStatusId: { _eq: PersonContractStatusTitle.Active },
                },
            },
        },
        api: {
            endpoint: '/people',
            useApiTodelete: true,
        },
        title: 'Pessoas',
        advancedSearchComponent: <PersonAdvancedSearch />,
    },
    {
        title: 'Nova pessoa',
    },
];

export default config;
