import { useQuery } from 'react-query';
import api from 'services/api';
import { getOrderServiceStockProductsKey } from './keys';
import { StockProduct } from 'types/models/stock';

const useGetOrderServiceStockProducts = (saleOrderId?: string, orderServiceId?: string) => {
    const request = async () => {
        const { data } = await api.get<StockProduct[]>(`/sale-orders/${saleOrderId}/service-orders/${orderServiceId}/stock-products`);

        return data;
    };

    return useQuery(getOrderServiceStockProductsKey(saleOrderId, orderServiceId), request, { enabled: Boolean(saleOrderId) && Boolean(orderServiceId) });
};

export default useGetOrderServiceStockProducts;
