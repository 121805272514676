import Tooltip from '@mui/material/Tooltip/Tooltip';
import useTheme from '@mui/material/styles/useTheme';
import ExternalButton from 'components/buttons/external';
import Badge from 'components/core/badge';
import SvgIcoCheck from 'components/core/icon/files/ico-check';
import SvgIcoClose from 'components/core/icon/files/ico-close';
import useGetDownloadFile from 'services/queries/files/use-get-download-file';
import { TransactionInvoiceStatus } from 'types/models/transaction';

type NFStatusProps = {
    invoiceStatus: TransactionInvoiceStatus;
    fiscalDocumentName: string;
    fiscalDocumentId: number;
};

const NFStatus = ({ fiscalDocumentName, fiscalDocumentId, invoiceStatus }: NFStatusProps) => {
    const { palette } = useTheme();
    const { mutateAsync: getFileToDownload, isLoading: isDownloading } = useGetDownloadFile();

    const handleDownloadFile = async () => {
        try {
            await getFileToDownload(fiscalDocumentId);
        } catch (error) {
            console.log('handleDownloadFile', error);
        }
    };

    const variants = [
        { badge: 'grey', iconColor: palette.grey[500], Icon: SvgIcoClose, name: 'Liberada envio sistema' },
        { badge: 'error', iconColor: palette.error[700], Icon: SvgIcoClose, name: 'Aguardando envio' },
        { badge: 'warning', iconColor: palette.warning[700], Icon: SvgIcoCheck, name: 'Liberada envio admin' },
        { badge: 'success', iconColor: palette.success[500], Icon: SvgIcoCheck, name: 'NF enviada' },
        { badge: 'info', iconColor: palette.success[500], Icon: SvgIcoCheck, name: 'Solicitada liberação envio admin' },
    ];

    const status = variants[invoiceStatus || 0];

    if (invoiceStatus === TransactionInvoiceStatus.Sent) {
        return (
            <ExternalButton disabled={isDownloading} onClick={handleDownloadFile}>
                {isDownloading ? 'Carregando...' : fiscalDocumentName}
            </ExternalButton>
        );
    }

    return (
        <Tooltip title={status.name} placement="top">
            <span>
                <Badge variant={status.badge as any} className="!rounded-full w-7 h-7 flex items-center justify-center">
                    <status.Icon color={status.iconColor} width={8} />
                </Badge>
            </span>
        </Tooltip>
    );
};

export default NFStatus;
