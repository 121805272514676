import Tab from '@mui/material/Tab/Tab';
import Tabs from '@mui/material/Tabs/Tabs';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { useCallback, useEffect } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import Products from './products';
import { General as GeneralType, Steps } from './context/types';
import { useExpensesContext } from './context';
import General from './general';
import Documents from './documents';
import Installments, { ExpenseInstallments } from './installments';
import { ExpenseDetailsResponse } from 'services/queries/expenses/types';
import { ExpenseNature } from 'types/models/expense';
import useGetExpense from 'services/queries/expenses/use-get-expense';
import { createDateWithTimezone } from 'utils/date';
import Summary from './summary';
import dayjs from 'dayjs';

type CreateOrUpdateSaleOrderProps = {
    nature?: ExpenseNature;
    title: string;
};

const CreateOrUpdateExpense = ({ nature, title }: CreateOrUpdateSaleOrderProps) => {
    const { expenseId } = useParams();
    const data = useOutletContext<ExpenseDetailsResponse>();
    const { state, changeStep, setSteps, setContent } = useExpensesContext();

    const { data: expense } = useGetExpense(expenseId);

    const isUpdate = !!expenseId;
    const currentExpense = expense || data;

    const setupWizard = useCallback(() => {
        const general: GeneralType = {
            code: currentExpense?.code ?? undefined,
            title: currentExpense?.title ?? undefined,
            dueDate: currentExpense?.dueDate ?? undefined,
            amount: currentExpense?.value?.toString() || '0',
            withProducts: !!currentExpense ? !!currentExpense?.products?.length : undefined,
            provisioned: !!currentExpense?.provisioned ?? undefined,
            installments: currentExpense?.instalments ?? undefined,
            company: currentExpense?.company?.id ?? undefined,
            branch: currentExpense?.branch?.id ?? undefined,
            documentNumber: currentExpense?.documentNumber ?? undefined,
            fiscalCode: currentExpense?.fiscalCode?.id ?? undefined,
            paymentForm: {
                value: currentExpense?.paymentForm?.id ?? undefined,
                label: currentExpense?.paymentForm?.name ?? undefined,
            },
            sector: currentExpense?.sector?.id ?? undefined,
            category: currentExpense?.category?.id ?? undefined,
            origin: currentExpense?.classificationOrigin?.id ?? undefined,
            subOrigin: currentExpense?.classificationSubOrigin?.id ?? undefined,
            constructionRelated: !!currentExpense?.expenseOrigin?.orderService?.id ?? undefined,
            orderService: currentExpense?.expenseOrigin?.orderService?.id ?? undefined,
            type: currentExpense?.type ?? undefined,
            observations: currentExpense?.observations ?? undefined,
            favoredBankAccount: currentExpense?.favoredBankAccount?.id ?? undefined,
            nature: currentExpense?.nature || nature,
            ...(Boolean(currentExpense?.competenceMonth) &&
                Boolean(currentExpense?.competenceYear) && {
                    competence: dayjs()
                        .month(currentExpense.competenceMonth - 1)
                        .year(currentExpense.competenceYear)
                        .toISOString(),
                }),
        };

        if (!!currentExpense?.favored?.person?.id || !!currentExpense?.favored?.provider?.id) {
            const entity = currentExpense?.favored?.person || currentExpense?.favored?.provider;

            general.favored = {
                value: entity?.id!,
                label: entity?.title!,
                type: !!currentExpense?.favored?.person ? 'person' : 'provider',
            } as any;
        }

        const products: any[] =
            currentExpense?.products?.map(({ id, product, quantity = 0, netValue = 0 }) => ({
                name: product?.name,
                productId: product?.id,
                id: product?.id,
                expenseProduct: id,
                classificationByClassificationUnitId: { name: product?.unit?.name },
                value: netValue,
                quantity,
                total: netValue * quantity,
            })) || [];

        const documents =
            currentExpense?.documents.map((item) => ({
                ...item,
                file: item.file.id,
                localFile: item.file,
            })) || [];

        const installments: ExpenseInstallments[] = (currentExpense?.transactions || [])?.map((item) => ({
            dueDate: createDateWithTimezone(item.dueDate).format(),
            paymentForm: item.paymentForm?.id!,
            provisioned: item.provisioned,
            provisioning: !!item.paymentForecast ? createDateWithTimezone(item.paymentForecast).format() : undefined,
            value: Number(item.grossValue.toFixed(2)),
            transactionId: item.id,
            paid: item.paid,
        }));

        setContent({ general, products, documents, installments });

        // eslint-disable-next-line
    }, [data, expense, isUpdate]);

    const getComponent = (slug: Steps) => {
        const items = new Map<Steps, any>([
            [Steps.GeneralInfo, General],
            [Steps.Products, Products],
            [Steps.Installments, Installments],
            [Steps.Documents, Documents],
            [Steps.Summary, Summary],
        ]);

        const Component = items.get(slug);

        return <Component nature={nature || currentExpense?.nature} /> ?? null;
    };

    const handleChangeTab = useCallback((_: any, nextSlug: any) => changeStep(state.currentStep, nextSlug), [state.currentStep, changeStep]);

    const setupSteps = useCallback(() => {
        const arr = state.steps;

        for (const step of arr) {
            if (step.slug === Steps.Products) {
                step.hide = !(!!currentExpense?.products?.length || !!state.content?.general?.withProducts);
            }

            if (step.slug === Steps.Installments) {
                step.hide = !isUpdate;
            }

            if (step.slug === Steps.Documents) {
                step.hide = isUpdate;
            }
        }

        setSteps(arr);

        // eslint-disable-next-line
    }, [currentExpense?.instalments, currentExpense?.products?.length, isUpdate]);

    useEffect(() => {
        setupWizard();
    }, [setupWizard]);

    useEffect(() => {
        setupSteps();
    }, [setupSteps]);

    return (
        <Modal
            contentClassnames="min-w-[90%]"
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    {title}
                </Text>
            }
            closeOnClickOutside={false}>
            <Tabs classes={{ root: 'px-4' }} value={state.currentStep} onChange={handleChangeTab}>
                {state.steps
                    .filter(({ hide = false }) => !hide)
                    .map((item, index) => {
                        const isDisabled = !state.completeds.find((step) => step === item.slug) && item.slug !== state.currentStep && !isUpdate;

                        return <Tab value={item.slug} disabled={isDisabled} key={`${item.slug}_${index}`} label={item.label.toUpperCase()} />;
                    })}
            </Tabs>
            {getComponent(state.currentStep)}
        </Modal>
    );
};

export default CreateOrUpdateExpense;
