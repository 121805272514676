import useToast from 'hooks/toast/use-toast';
import { useQuery } from 'react-query';
import api from 'services/api';
import _orderBy from 'lodash/orderBy';
import { getReportPageKey } from './keys';
import { ReportPageResponse } from 'types/models/reports';

const useGetReportPage = ({ id, onError, onSuccess }: { id?: string; onError?(): void; onSuccess?(data: ReportPageResponse): void }) => {
    const { showToast } = useToast();

    const request = async () => {
        const { data } = await api.get<ReportPageResponse>(`/reports/pages/${id}`);

        return { ...data, years: _orderBy(data?.years, ['year'], ['desc']) };
    };

    return useQuery(getReportPageKey(id), request, {
        onError: () => {
            onError?.();

            showToast(`Ocorreu um erro ao tentar obter os dados da página de relatório`, 'danger');
        },
        enabled: Boolean(id),
        onSuccess,
    });
};

export default useGetReportPage;
