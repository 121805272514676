import Datatable from 'components/core/table/datatable';
import { useEffect, useMemo, useState } from 'react';
import useGetContractsReport from 'services/queries/reports/use-get-contracts-report';
import useConfig from 'store/config/use-config';
import { contractsReportColumns, salesReportTotalsAcessors } from './utils';
import { useMainContext } from 'pages/private/main/context';
import ContractsReportHeader from './header';
import { ReportFilterPayload } from 'pages/private/reports/screens/types';
import { generateOrderServicesReportTotals } from '../order-services/utils';
import { ContractsReportResponse } from 'types/models/reports';
import { formatDate } from 'utils/date';
import useGetDataContentConfigFilters from '../../hooks/use-get-data-content-filters';
import isEmpty from 'lodash/isEmpty';
import { useReportsContext } from '../../context';
import { datatableColumnSort } from 'utils/datatable';

const ContractsReportContent = () => {
    const [page, setPage] = useState(0);
    const [filters, setFilters] = useState<ReportFilterPayload | undefined>();
    const [isFilterVisible, setIsFilterVisible] = useState(false);

    const { showValues } = useReportsContext();
    const { handleToggleSidebar } = useMainContext();
    const { config } = useConfig();

    const dataContentFiltersConfig = useGetDataContentConfigFilters();

    const { data = [], isLoading } = useGetContractsReport(filters);

    const reportTotals = useMemo(() => generateOrderServicesReportTotals<ContractsReportResponse>(data, salesReportTotalsAcessors), [data]);

    const configFilters = {
        isFilterVisible,
        filters,
        onClearFilters: () => {
            setFilters(undefined);
            setIsFilterVisible(false);
        },
        onSubmitFilters: (data?: ReportFilterPayload['filter']) => {
            if (!isEmpty(data)) {
                const { startDate, endDate, ...rest } = data;

                setFilters({
                    filter: {
                        ...rest,
                        ...(!!startDate && {
                            startDate: formatDate(startDate, 'YYYY-MM-DD'),
                        }),
                        ...(!!endDate && {
                            endDate: formatDate(endDate, 'YYYY-MM-DD'),
                        }),
                    },
                });
            }

            setIsFilterVisible(false);
        },
        onToggleFilters: (value: boolean) => setIsFilterVisible(value),
    };

    useEffect(() => {
        handleToggleSidebar(true);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <ContractsReportHeader dataContentFiltersConfig={dataContentFiltersConfig} reportTotals={reportTotals} configFilters={configFilters} />
            <Datatable
                data={data}
                withPagination={true}
                loading={isLoading}
                columns={contractsReportColumns(showValues)}
                options={{
                    sort: true,
                    filter: false,
                    search: true,
                    searchAlwaysOpen: true,
                    print: false,
                    download: false,
                    viewColumns: false,
                    serverSide: false,
                    enableNestedDataAccess: '.',
                    pagination: true,
                    elevation: 21,
                    searchPlaceholder: 'Buscar contrato',
                    page,
                    rowsPerPage: config.tables.rowsPerPage,
                    onChangePage: setPage,
                    customSort: datatableColumnSort,
                }}
            />
        </>
    );
};

export default ContractsReportContent;
