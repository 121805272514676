import { Outlet, useOutletContext } from 'react-router-dom';
import SaleOrderCustomer from './customer';
import Card from 'components/core/card';
import CardHeader from '@mui/material/CardHeader/CardHeader';
import Avatar from '@mui/material/Avatar/Avatar';
import { hexToRgba } from 'utils/string';
import useTheme from '@mui/material/styles/useTheme';
import { SaleOrder, SaleOrderOrigin } from 'types/models/sale-order';
import PageHeader from 'components/page/header';
import Text from 'components/core/text';
import { formatDate } from 'utils/date';
import dictionary from 'utils/dictionary';
import SvgIcoCart from 'components/core/icon/files/ico-cart';

const SaleOrderDetailsPage = () => {
    const { palette } = useTheme();

    const saleOrder = useOutletContext<Partial<SaleOrder>>();

    // const valueOfSaleScope = useMemo(() => saleOrder?.saleOrderProducts?.reduce((acc, curr) => acc + (curr.quantity || 0) * (curr.netValue || 0), 0), [saleOrder?.saleOrderProducts]);

    return (
        <>
            <PageHeader title={`#${saleOrder.code}`} className="mb-8" />
            <div className="flex">
                <div className="flex-1 md:mr-7 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                    <Card className="p-4 flex items-end">
                        <div>
                            <Text as="p" className="text-base-500 mb-2">
                                <strong className="mr-1">Empresa:</strong> {saleOrder.company?.name || '-'}
                            </Text>
                            <Text as="p" className="text-base-500 mb-2">
                                <strong className="mr-1">Filial:</strong> {saleOrder.branch?.name || '-'}
                            </Text>
                            <Text className="text-base-500 mb-2 flex items-baseline">
                                <strong className="mr-1">Consultor:</strong> {saleOrder.consultant?.title || '-'}
                            </Text>
                            <Text as="p" className="text-base-500 mb-2">
                                <strong className="mr-1">Classificação:</strong> {saleOrder.classification?.name || '-'}
                            </Text>
                            <Text as="p" className="text-base-500 mb-2">
                                <strong>Data do pedido:</strong> {formatDate(saleOrder.contractDate)}
                            </Text>
                            {saleOrder.origin === SaleOrderOrigin.service && (
                                <Text as="p" className="text-base-500 mb-2">
                                    <strong>Vigência do contrato:</strong> {formatDate(saleOrder.startContractDate)} - {formatDate(saleOrder.endContractDate)}
                                </Text>
                            )}
                            <Text as="p" className="text-base-500 mb-2">
                                <strong>Origem:</strong> {Object.values(dictionary.content.saleOrderOrigins)[(saleOrder.origin || 1) - 1] || '-'}
                            </Text>
                            <Text as="p" className="text-base-500">
                                <strong>Tipo:</strong> {Object.values(dictionary.content.saleOrderTypes)[(saleOrder.type || 1) - 1] || '-'}
                            </Text>
                        </div>
                    </Card>
                    <SaleOrderCustomer {...saleOrder.customer} className="md:col-span-2" />
                    {/* <Card className="p-4 flex items-center justify-center">
                        <CardHeader
                            avatar={
                                <Avatar sx={{ bgcolor: hexToRgba(palette.success[100], 0.2), borderRadius: '14px !important', width: 48, height: 48 }} aria-label="recipe">
                                    <Icon name="ico-dollar-sign" color={palette.success[500]} />
                                </Avatar>
                            }
                            classes={{ root: 'p-0 flex-col text-center', avatar: 'mr-0 mb-[10px]' }}
                            title={formatMoney(valueOfSaleScope || 0)}
                            subheader="Valor total do escopo de venda"
                            titleTypographyProps={{ color: palette.heading, fontSize: 32, fontWeight: 500 }}
                            subheaderTypographyProps={{ color: palette.grey[500] }}
                        />
                    </Card> */}
                    <Card className="p-4">
                        <CardHeader
                            avatar={
                                <Avatar sx={{ bgcolor: hexToRgba(palette.info[100], 0.2), borderRadius: '14px !important', width: 48, height: 48 }} aria-label="recipe">
                                    <SvgIcoCart color={palette.info[500]} />
                                </Avatar>
                            }
                            classes={{ root: 'p-0 flex-col text-center', avatar: 'mr-0 mb-[10px]' }}
                            title={saleOrder?.products?.length || 0}
                            subheader="Produtos no pedido"
                            titleTypographyProps={{ color: palette.heading, fontSize: 32, fontWeight: 500 }}
                            subheaderTypographyProps={{ color: palette.grey[500] }}
                        />
                    </Card>
                </div>
            </div>
            <Outlet />
        </>
    );
};

export default SaleOrderDetailsPage;
