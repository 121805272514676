import Modal from 'components/core/modal';
import ResetUserPasswordForm, { UserResetPasswordPayload } from './reset-password-form';
import useChangePassword from 'services/queries/auth/use-change-password';

type ResetUserPasswordProps = {
    onResetPasswordModal: () => void;
};

const ResetUserPassword = ({ onResetPasswordModal }: ResetUserPasswordProps) => {
    const { mutateAsync: userPasswordMutation, isLoading: isSubmitting } = useChangePassword();

    const handleSubmit = async (data: UserResetPasswordPayload) => {
        try {
            await userPasswordMutation(data);

            onResetPasswordModal();
        } catch (error) {
            console.log('handleSubmit', error);
        }
    };

    return (
        <Modal closeOnClickOutside={false} contentClassnames="w-[500px]" onClose={onResetPasswordModal}>
            <ResetUserPasswordForm isSubmitting={isSubmitting} onSubmit={handleSubmit} />
        </Modal>
    );
};

export default ResetUserPassword;
