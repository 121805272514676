import Badge from 'components/core/badge';
import Spinner from 'components/core/spinner';
import Empty from 'components/empty';
import PageHeader from 'components/page/header';
import { useCallback, useMemo, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import useGetPurchaseOrder from 'services/queries/purchase-order/use-get-purchase-order';
import { PurchaseStatus } from 'types/models/purchase-order';
import OSData from '../details/os-data';
import Card from 'components/core/card';
import Text from 'components/core/text';
import ExternalButton from 'components/buttons/external';
import { formatDate } from 'utils/date';
import PurchaseOrderProducts from './products';
import DatatableToolbarActions from './actions';
import { stockTypes } from 'utils/statics';
import { purchaseStatusesConfig } from 'utils/status';
import { formatMoney } from 'utils/money';
import { getPurchaseOrderConfig, orderServicePurchaseProductColumns } from './utils';
import useGetPurchaseOrderProductsApi from 'services/queries/purchase-order/use-get-purchase-order-products-api';
import useChangePurchaseOrderStatus from 'services/queries/purchase-order/use-change-purchase-order-status';
import ConfirmModal from 'components/core/modal/confirm';

type PurchaseOrderPageProps = {
    source?: 'stock' | 'other';
};

const canDoActionsStatuses = [PurchaseStatus.Open, PurchaseStatus.Processed];

const PurchaseOrderPage = ({ source = 'other' }: PurchaseOrderPageProps) => {
    const { purchaseOrderId } = useParams();

    const toolbarAnchor = useState<HTMLElement | null>(null);
    const [confirmAction, setConfirmAction] = useState<'finalize' | 'cancel'>();

    const { data: products = [], isError: isErrorProductsPurchaseOrder, isLoading: isLoadingProducts } = useGetPurchaseOrderProductsApi(purchaseOrderId);
    const { data: purchaseOrder, isError: isErrorPurchaseOrder, isLoading } = useGetPurchaseOrder(getPurchaseOrderConfig(purchaseOrderId), purchaseOrderId);

    const { mutateAsync: changePurchaseOrderStatus, isLoading: isLoadingChangePurchaseOrderStatus } = useChangePurchaseOrderStatus(purchaseOrderId);

    const status = useMemo(() => purchaseStatusesConfig[purchaseOrder?.purchaseStatus!] ?? purchaseStatusesConfig[PurchaseStatus.Open], [purchaseOrder?.purchaseStatus]);

    const handleSetConfirmAction = useCallback((value?: 'finalize' | 'cancel') => () => setConfirmAction(value), []);

    const handleChangePurchaseOrderStatus = useCallback(
        (status: 'finalize' | 'cancel') => async () => {
            try {
                await changePurchaseOrderStatus(status);

                toolbarAnchor[1](null);
                handleSetConfirmAction(undefined)();
            } catch (error) {
                console.log('handleChangePurchaseOrderStatus', error);
            }
        },
        [toolbarAnchor, changePurchaseOrderStatus, handleSetConfirmAction]
    );

    const purchaseOrderActions = useMemo(() => {
        const content: any[] = [];
        const footer: any[] = [];

        if (canDoActionsStatuses.includes(purchaseOrder?.purchaseStatus!)) {
            content.push({
                label: 'Finalizar pedido',
                onClick: handleSetConfirmAction('finalize'),
            });

            footer.push({
                label: 'Cancelar ordem de compra',
                className: 'text-system-danger-500',
                onClick: handleSetConfirmAction('cancel'),
            });
        }

        return {
            content,
            footer,
        };
    }, [handleSetConfirmAction, purchaseOrder?.purchaseStatus]);

    const stockType = useMemo(() => {
        return stockTypes.find((status) => status.value === purchaseOrder?.purchaseOrderOrigin?.purchaseOrderOriginStock?.stock?.type);
    }, [purchaseOrder]);

    const canDoActions = useMemo(() => canDoActionsStatuses.includes(purchaseOrder?.purchaseStatus!), [purchaseOrder]);

    if (isLoading || isLoadingProducts) {
        return <Spinner wrapperClasses="p-2 bg-base-100 rounded-full" />;
    }

    if (!purchaseOrder || isErrorPurchaseOrder || isErrorProductsPurchaseOrder) {
        return <Empty title="Ordem de compra não encontrada" />;
    }

    return (
        <div className="pb-10">
            <PageHeader
                className="mb-6"
                title={
                    <div className="flex items-center">
                        #{purchaseOrder?.code}
                        <div className="flex ml-2">
                            <Badge variant={status.variant} className="mr-2">
                                {status.label}
                            </Badge>
                        </div>
                    </div>
                }
                right={canDoActions ? <DatatableToolbarActions toolbarAnchor={toolbarAnchor} className="!p-0" {...purchaseOrderActions} /> : undefined}
            />
            <div className="grid grid-cols-1 gap-4 mb-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4">
                <Card className="p-4 flex flex-col justify-between">
                    <Text as="h6" variant="h6" className="text-heading mb-4">
                        Ordem de compra
                    </Text>
                    <div>
                        <Text as="p" className="text-base-500 leading-5 mb-2">
                            <strong>Data:</strong> {formatDate(purchaseOrder?.createdAt)}
                        </Text>
                        {Boolean(purchaseOrder?.branch?.name) && (
                            <Text as="p" className="text-base-500 leading-5 mb-2">
                                <strong>Filial:</strong> {purchaseOrder?.branch?.name}
                            </Text>
                        )}
                        <Text as="span" className="text-base-500 leading-5 mb-2 flex items-baseline">
                            <strong className="mr-2">Criado por:</strong>
                            <ExternalButton to={`/app/pessoas/${purchaseOrder?.person?.id}`}>{purchaseOrder?.person?.title}</ExternalButton>
                        </Text>
                    </div>
                </Card>
                {source !== 'stock' ? (
                    <OSData />
                ) : (
                    <Card className="p-4 flex flex-col justify-between">
                        <Text as="h6" variant="h6" className="text-heading mb-4">
                            Estoque
                        </Text>
                        <div>
                            <Text as="p" className="text-base-500 leading-5 mb-2">
                                <strong>Nome:</strong> {purchaseOrder.purchaseOrderOrigin?.purchaseOrderOriginStock?.stock?.name || '-'}
                            </Text>
                            <Text as="p" className="text-base-500 leading-5 mb-2">
                                <strong>Tipo:</strong> {stockType?.label || '-'}
                            </Text>
                            <Text as="p" className="text-base-500 leading-5 flex items-baseline">
                                <strong className="mr-2">Valor em estoque:</strong> {formatMoney(Number(purchaseOrder.purchaseOrderOrigin?.purchaseOrderOriginStock?.stock?.value || 0))}
                            </Text>
                        </div>
                    </Card>
                )}
            </div>
            <PurchaseOrderProducts
                canDoActions={canDoActions}
                isLoadingProducts={isLoadingProducts}
                purchaseOrderId={purchaseOrderId}
                products={products}
                columns={orderServicePurchaseProductColumns}
            />
            {Boolean(confirmAction) && (
                <ConfirmModal
                    title={confirmAction === 'finalize' ? 'Finalizar pedido' : 'Cancelar ordem de compra'}
                    description={`Você tem certeza que deseja ${confirmAction === 'finalize' ? 'finalizar este pedido?' : 'cancelar esta ordem de compra'} ?`}
                    isLoading={isLoadingChangePurchaseOrderStatus}
                    onClose={handleSetConfirmAction(undefined)}
                    onConfirmAction={handleChangePurchaseOrderStatus(confirmAction!)}
                />
            )}
            <Outlet />
        </div>
    );
};

export default PurchaseOrderPage;
