import useToast from 'hooks/toast/use-toast';
import { useQuery } from 'react-query';
import api from 'services/api';
import apiGraphQl from 'services/graphql';
import { Person } from 'types/models/person';
import { convertToDateOrReturnUndefined } from 'utils/date';
import { formatMoney } from 'utils/money';
import { getPeopleDetailsKey, getPersonKey } from './keys';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';

const useGetPerson = (id?: string, isDetails = false) => {
    const { showToast } = useToast();
    const goToParentRoute = useNavigateToParentRoute();

    const fetcher = async () => {
        const { data } = await api.get<Person>(`/people/${id}`);

        const { contracts } = await apiGraphQl<{ contracts: any[] }>(
            `query GetPersonContract($id: Int!) {
                contracts: personContract(where: {personId: {_eq: $id}, finalDate: {_isNull: true}}, limit: 1, orderBy: {id: DESC}) {
                    id
                    finalDate
                    juridicDocument
                    juridicFantasyName
                    juridicName
                    startDate
                    salary
                    position: positionId
                    sector: sectorId
                    type
                    ${isDetails ? `personContractStatus { title }` : ''}
                }
            }`,
            { id }
        );

        const [contract] = contracts || [];

        return {
            ...data,
            ...(!!contract && {
                contracts: {
                    ...contract,
                    startDate: convertToDateOrReturnUndefined(contract?.startDate),
                    finalDate: convertToDateOrReturnUndefined(contract?.finalDate),
                    salary: formatMoney(contract?.salary),
                },
            }),
        };
    };

    return useQuery(isDetails ? getPeopleDetailsKey(id) : getPersonKey(id), fetcher, {
        enabled: !!id,
        onError: () => {
            goToParentRoute();
            showToast('Tivemos um problema ao obter os detalhes dessa pessoa. Tente novamente.', 'danger');
        },
    });
};

export default useGetPerson;
