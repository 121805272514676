import { useShowApiErrors } from 'hooks/error/show-api-errors';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';
import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import { getStockKey } from './keys';
import { Option } from 'types/general';

export type RefundStockProductPayload = {
    incomingStock: number;
    date: string;
    products: Array<
        {
            stockProduct: number;
            quantity: number;
            productValue?: number;
            quantityInStock?: number;
        } & Partial<Option>
    >;
};

const useCreateStockProductRefund = (stockId?: string) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();
    const { showToastErrors } = useShowApiErrors();
    const goToParentRoute = useNavigateToParentRoute();

    const request = async (payload: RefundStockProductPayload) => {
        const { data } = await api.patch(`/stocks/${stockId}/products/refund`, payload);

        return data;
    };

    return useMutation(request, {
        onSuccess: () => {
            showToast('Estorno realizado com sucesso!', 'success');
            queryClient.invalidateQueries(getStockKey(stockId));
            goToParentRoute();
        },
        onError: (error: any) => {
            const errorTitle = error?.response?.data?.title || 'Ocorreu um erro ao criar um estorno para este estoque.';
            showToastErrors(error, errorTitle);
        },
    });
};

export default useCreateStockProductRefund;
