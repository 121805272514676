import { SVGAttributes, lazy, Suspense, memo, useMemo } from 'react';
import Spinner from '../spinner';
import retry from 'utils/retry';
import theme from 'settings/theme';
import classNames from 'classnames';

type Size = Partial<{ width: number; height: number | string }>;

type IconProps = {
    parentClassNames?: string;
} & Size &
    SVGAttributes<SVGSVGElement>;

const LoadingIcon = ({ width, height }: Size) => (
    <div style={{ width: `${width}px`, height: `${height}px` }}>
        <div className={container}>
            <Spinner color={theme.extend.colors.base[500]} size={12} fixed={false} className="block" />
        </div>
    </div>
);

const Icon = ({ name, width = 20, height = 20, parentClassNames, ...rest }: IconProps) => {
    const Element = useMemo(() => lazy(() => retry(() => import(`./files/${name}`))), [name]);

    return (
        <Suspense fallback={<LoadingIcon width={width} height={height} />}>
            <span className={classNames(parentClassNames, container)}>
                <Element {...rest} width={width} height={height} />
            </span>
        </Suspense>
    );
};

const { container } = {
    container: 'pointer-events-none transition-all ease-in-out duration-200',
};

export default memo(Icon);
