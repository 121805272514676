import useToast from 'hooks/toast/use-toast';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
import { CreateOrUpdateBankAccountPayload } from 'types/models/bank-accounts';

const useCreateOrUpdateBankAccount = (bankAccountId?: string) => {
    const navigate = useNavigate();
    const { showToast } = useToast();

    const isUpdate = Boolean(bankAccountId);

    const request = (payload: CreateOrUpdateBankAccountPayload) => {
        const method = isUpdate ? api.patch : api.post;
        const endpoint = isUpdate ? `/company-bank-accounts/${bankAccountId}` : `/company-bank-accounts`;

        return method(endpoint, payload);
    };

    return useMutation(request, {
        onSuccess: ({ data }) => {
            navigate(`../${data.id}`);

            showToast(`Conta bancária ${isUpdate ? 'atualizada' : 'criada'} com sucesso`, 'success');
        },
        onError: () => {
            showToast(`Ocorreu um erro ao ${isUpdate ? 'atualizar' : 'criar'} a conta bancária`, 'danger');
        },
    });
};

export default useCreateOrUpdateBankAccount;
