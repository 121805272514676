import Datatable from 'components/core/table/datatable';
import { useParams } from 'react-router-dom';
import useGetExpenseCommissions from 'services/queries/expenses/use-get-expense-commissions';
import { expenseCommissionsColumns } from './utils';

const CommissionsPage = () => {
    const { expenseId } = useParams();

    const { data = [], isLoading } = useGetExpenseCommissions(expenseId);

    return (
        <Datatable
            columns={expenseCommissionsColumns}
            data={data}
            loading={isLoading}
            options={{
                sort: true,
                selectableRowsHeader: false,
                selectableRowsHideCheckboxes: true,
                filter: false,
                print: false,
                download: false,
                search: false,
                viewColumns: false,
                serverSide: true,
                enableNestedDataAccess: '.',
                elevation: 21,
            }}
            withPagination={false}
            withItemsPerPage={false}
            className="toolbar-without-search"
        />
    );
};

export default CommissionsPage;
