import { useMutation } from 'react-query';
import api from 'services/api';
import { Classification } from 'types/models/classification';
import { File } from 'types/models/file';
import { Transaction, TransactionDocumentType } from 'types/models/transaction';

type TransactionSendInvoiceFilePayload = {
    name: string;
    file: number;
};

type ResponseTransactionSendInvoiceFile = {
    classification: Partial<Classification>;
    file: Pick<File, 'filename' | 'id' | 'mimeType' | 'originalName' | 'path' | 'pathTmp' | 'title'>;
    id: number;
    name: string;
    transaction: Pick<Transaction, 'id' | 'code'>;
    type: TransactionDocumentType;
};

const useTransactionSendInvoiceFile = (outcomeId: number) => {
    const request = async (payload: TransactionSendInvoiceFilePayload) => api.post<ResponseTransactionSendInvoiceFile>(`/transactions/outcomes/${outcomeId}/invoice`, payload);

    return useMutation(request);
};

export default useTransactionSendInvoiceFile;
