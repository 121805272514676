import { useEffect } from 'react';
import Text from 'components/core/text';
import Modal from 'components/core/modal';
import Spinner from 'components/core/spinner';
import theme from 'settings/theme';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { personContractSchema } from 'pages/private/people/create-or-update/utils';
import { CreatePersonContractPayload } from 'types/models/person';
import PersonContract from 'components/forms/person-contract';
import FormButtons from 'components/form-buttons';

type PersonDetailsContractsCreateFormProps = {
    isBuilding: boolean;
    isSubmitting: boolean;
    onSubmit: (data: CreatePersonContractPayload) => void;
    title: string;
    defaultValues?: any;
    isCreate: boolean;
};

const PersonDetailsContractsCreateForm = ({ isBuilding, isSubmitting, onSubmit, defaultValues, title, isCreate = false }: PersonDetailsContractsCreateFormProps) => {
    const methods = useForm<CreatePersonContractPayload>({
        mode: 'onSubmit',
        resolver: yupResolver(personContractSchema) as any,
    });

    const { handleSubmit, reset } = methods;

    useEffect(() => {
        if (!!defaultValues) {
            reset(defaultValues);
        }
    }, [defaultValues, reset]);

    return (
        <Modal closeOnClickOutside={false} contentClassnames="min-w-[800px]">
            {isBuilding ? (
                <div className="p-4">
                    <Spinner color={theme.extend.colors.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <FormProvider {...methods}>
                    <form className="py-5 px-7" onSubmit={handleSubmit(onSubmit)}>
                        <Text as="h3" variant="h4" className="text-heading mb-5">
                            {title}
                        </Text>
                        <PersonContract {...methods} className="mb-8" hideFinalDate={isCreate} />
                        <FormButtons isLoading={isSubmitting} />
                    </form>
                </FormProvider>
            )}
        </Modal>
    );
};

export default PersonDetailsContractsCreateForm;
