import { useQuery } from 'react-query';
import { getPurchaseOrderKey } from './keys';
import apiGraphQl from 'services/graphql';
import { QueryConfig, generateQuery } from 'utils/query';
import { PurchaseOrder } from 'types/models/purchase-order';

type Response = {
    purchaseOrderByPk: Partial<PurchaseOrder>;
};

const useGetPurchaseOrder = (config: QueryConfig<PurchaseOrder>, purchaseOrderId?: string) => {
    const request = async () => {
        const { purchaseOrderByPk } = await apiGraphQl<Response>(generateQuery<PurchaseOrder>(config));

        return purchaseOrderByPk;
    };

    return useQuery(getPurchaseOrderKey(purchaseOrderId), request, { enabled: !!purchaseOrderId });
};

export default useGetPurchaseOrder;
