import { useState } from 'react';
import Avatar from '@mui/material/Avatar/Avatar';
import Text from 'components/core/text';
import TimeAgo from 'components/timeago';
import { useParams } from 'react-router-dom';
import IconButton from '@mui/material/IconButton/IconButton';
import useTheme from '@mui/material/styles/useTheme';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import useAuth from 'store/auth/use-auth';
import { hexToRgba } from 'utils/string';
import useDeleteTaskComment from 'services/queries/tasks/use-delete-task-comment';
import { TaskComment as TaskCommentModel } from 'types/models/task';
import ConfirmModal from 'components/core/modal/confirm';
import SvgIcoTrash from 'components/core/icon/files/ico-trash';

const TaskComment = ({ person, createdAt, text, id }: TaskCommentModel) => {
    const { auth } = useAuth();
    const { palette } = useTheme();
    const { taskId } = useParams();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const { mutateAsync: deleteComment, isLoading: isDeletingComment } = useDeleteTaskComment(id, taskId);

    return (
        <>
            <div className="flex items-start mb-8 last:mb-0">
                <Avatar src={person.profileImage?.url as string} sx={{ width: 30, height: 30, marginRight: 2 }}>
                    {person.title.charAt(0)}
                </Avatar>
                <div className="flex-1">
                    <div className="flex items-baseline mb-[2px]">
                        <Text as="span" variant="body.medium.2xs" className="text-heading mr-2">
                            {person.title}
                        </Text>
                        <TimeAgo date={createdAt} className="text-base-500 text-xs" />
                    </div>
                    <Text as="p" variant="body.regular.sm" className="text-base-700 whitespace-pre-wrap">
                        {text}
                    </Text>
                </div>
                {auth.credentials.user.person?.id === person.id && (
                    <Tooltip placement="left" title="Apagar comentário" disableFocusListener={true}>
                        <IconButton className="relative opacity-100" sx={{ '&:hover': { backgroundColor: hexToRgba(palette.error[100], 0.3) } }} onClick={() => setIsModalOpen(true)}>
                            <SvgIcoTrash width={14} height={14} color={palette.error[500]} />
                        </IconButton>
                    </Tooltip>
                )}
            </div>
            {isModalOpen && (
                <ConfirmModal
                    title="Apagar comentário"
                    description="Você tem certeza que deseja apagar este comentário?"
                    isLoading={isDeletingComment}
                    onClose={() => setIsModalOpen(false)}
                    onConfirmAction={deleteComment}
                    color="error"
                />
            )}
        </>
    );
};

export default TaskComment;
