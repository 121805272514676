import { useQuery } from 'react-query';
import api from 'services/api';
import { getOrderServiceFinancialBalanceKey } from './keys';
import { OrderServiceFinancialBalance } from 'types/models/order-service';

const useGetOrderServiceFinancialBalance = (orderServiceId?: string, saleOrderId?: string, financialBalanceId?: string) => {
    const request = async () => {
        const endpoint = `/sale-orders/${saleOrderId}/service-orders/${orderServiceId}/balance/${financialBalanceId}`;
        const { data } = await api.get<OrderServiceFinancialBalance>(endpoint);

        return data;
    };

    return useQuery(getOrderServiceFinancialBalanceKey(orderServiceId, saleOrderId, financialBalanceId), request, {
        enabled: Boolean(orderServiceId) && Boolean(saleOrderId) && Boolean(financialBalanceId),
    });
};

export default useGetOrderServiceFinancialBalance;
