import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import apiGraphQl from 'services/graphql';
import useUploadFile from 'services/queries/files/use-upload-file';
import { getPeopleDetailsKey } from './keys';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';
import { pkColumnsKey } from 'utils/environment';

const useChangePersonImage = (props: { id?: string }) => {
    const { id } = props || {};
    const client = useQueryClient();
    const { showToast } = useToast();
    const goToParentRoute = useNavigateToParentRoute();

    const { uploadFile } = useUploadFile();

    const request = async (payload: File) => {
        let fileId;

        await uploadFile(
            payload,
            ({ id }) => {
                fileId = id;
            },
            true
        );

        if (!fileId) {
            throw new Error();
        }

        return apiGraphQl(
            `mutation changePersonFile($personId: Int!, $fileId: Int!) {
            item: updatePersonByPk(${pkColumnsKey}: {id: $personId}, _set: {fileId: $fileId}) {
              id
              fileId
            }
          }
          `,
            { personId: Number(id), fileId }
        );
    };

    return useMutation(request, {
        onSuccess: () => {
            client.invalidateQueries(getPeopleDetailsKey(id));

            goToParentRoute();

            showToast('Foto atualizada com sucesso!', 'success');
        },
        onError: () => {
            showToast('Tivemos um problema ao atualizar a foto. Tente novamente.', 'danger');
        },
    });
};

export default useChangePersonImage;
