import CreateOrUpdateBankAccount from './create-or-update';
import { useParams } from 'react-router-dom';
import useCreateOrUpdateBankAccount from 'services/queries/bank-accounts/use-create-or-update-bank-account';
import useGetBankAccountById from 'services/queries/bank-accounts/use-get-bank-account-by-id';

const CreateOrUpdateBankAccountPage = () => {
    const { bankAccountId } = useParams();

    const { data: bankAccountsDetails, isLoading: isBuilding } = useGetBankAccountById(Number(bankAccountId));
    const { mutate: createOrUpdateBankAccount, error, isLoading: isSubmitting } = useCreateOrUpdateBankAccount(bankAccountId);

    const defaultValues = {
        bank: bankAccountsDetails?.bank?.id,
        company: bankAccountsDetails?.company?.id,
        name: bankAccountsDetails?.name || '',
        agency: bankAccountsDetails?.agency || '',
        agencyDigit: bankAccountsDetails?.agencyDigit || '',
        account: bankAccountsDetails?.account || '',
        accountDigit: bankAccountsDetails?.accountDigit || '',
        openingBalance: bankAccountsDetails?.openingBalance,
        status: bankAccountsDetails?.status,
    };

    return <CreateOrUpdateBankAccount defaultValues={defaultValues} isBuilding={isBuilding} isSubmitting={isSubmitting} error={error} onSubmit={createOrUpdateBankAccount} />;
};

export default CreateOrUpdateBankAccountPage;
