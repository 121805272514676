import { useEffect, useMemo, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import CreateOrUpdateProfile, { groupBy, RulesPayload } from './create-or-update';
import { slugify } from 'utils/string';
import { Status } from 'types/general';
import { Profile } from 'types/models/profile';
import { GraphqlPaginationVariables } from 'types/graphql';
import useGetPages from 'services/queries/pages/use-get-pages';
import useCreateOrUpdateProfile from 'services/queries/profiles/use-create-or-update-profile';
import useGetProfile from 'services/queries/profiles/use-get-profile';
import { getProfileQuery } from '../utils';
import useDeleteProfilePageRule from 'services/queries/profiles/use-delete-profile-page-rule';
import useCreateProfilePageRule from 'services/queries/profiles/use-create-profile-page-rule';
import useGetDashboards from 'services/queries/dashboard/use-get-dashboards';
import { getDashboardsQuery } from 'services/queries/dashboard/keys';
import useToast from 'hooks/toast/use-toast';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';

const CreateOrUpdateProfilePage = () => {
    const { showToast } = useToast();
    const { profileId } = useParams();
    const goToParentRoute = useNavigateToParentRoute();
    const params = useOutletContext<GraphqlPaginationVariables<Profile>>();

    const [isLoading, setIsLoading] = useState(false);
    const [selectedRules, setSelectedRules] = useState<RulesPayload[]>([]);

    const { isLoading: isLoadingPages, data: pages = [] } = useGetPages();
    const { isLoading: isLoadingDashboards, data: dashboards = [] } = useGetDashboards(getDashboardsQuery);
    const { data: profile, isLoading: isLoadingProfile } = useGetProfile(getProfileQuery(profileId), profileId);

    const { mutateAsync: createProfilePageRule } = useCreateProfilePageRule();
    const { mutateAsync: deleteProfilePageRule } = useDeleteProfilePageRule(profileId);
    const { mutateAsync: createOrUpdateProfile } = useCreateOrUpdateProfile(params, profileId);

    const isBuilding = useMemo(() => isLoadingProfile || isLoadingDashboards || isLoadingPages, [isLoadingProfile, isLoadingPages, isLoadingDashboards]);

    const handleSelectRules = (pageId: number, values: number[]) => {
        const isSelected = selectedRules.some((item) => item.pageId === pageId);

        const newRules = isSelected
            ? selectedRules.map((item) => ({
                  pageId: pageId === item.pageId ? pageId : item.pageId,
                  rulesIds: pageId === item.pageId ? values : item.rulesIds,
              }))
            : [...selectedRules, { pageId, rulesIds: values }];

        setSelectedRules(newRules);
    };

    const handleSubmit = async (data: any) => {
        try {
            setIsLoading(true);
            const payload = {
                dashboardTemplateId: data.dashboardTemplateId,
                name: data.name.trim(),
                slug: slugify(data.name.trim()),
                status: Status.Active,
            };

            if (!profileId) {
                payload['profilePageRules'] = {
                    data: selectedRules.flatMap((item) => item.rulesIds.map((rule) => ({ ruleId: rule, pageId: item.pageId }))),
                };
            }

            await createOrUpdateProfile(payload);

            // Delete current rules
            if (!!profileId && !!selectedRules.length) {
                await deleteProfilePageRule();

                // Add new rules
                for (const rule of selectedRules) {
                    const pageRules = rule.rulesIds.map((id) => ({
                        pageId: rule.pageId,
                        profileId: +profileId,
                        ruleId: id,
                    }));

                    await createProfilePageRule(pageRules);
                }
            }

            goToParentRoute();

            showToast(`Perfil ${!!profileId ? 'atualizado' : 'criado'} com sucesso`, 'success');
        } catch (error) {
            console.log('handleSubmit', error);
        } finally {
            setIsLoading(false);
        }
    };

    const defaultValues = useMemo(
        () => ({
            name: profile?.name || '',
            profilePageRules: Array.from(groupBy(profile?.profilePageRules || [], ({ pageId }) => pageId)).map(([key, value]) => ({
                pageId: key,
                rulesIds: value.map(({ ruleId }) => ruleId),
            })),
        }),
        [profile]
    );

    useEffect(() => {
        if (!!profile) {
            setSelectedRules(
                Array.from(groupBy(profile?.profilePageRules || [], ({ pageId }) => pageId)).map(([key, value]) => ({
                    pageId: key,
                    rulesIds: value.map(({ ruleId }) => ruleId),
                }))
            );
        }
    }, [profile]);

    return (
        <CreateOrUpdateProfile
            dashboards={dashboards}
            pages={pages}
            profile={profile}
            selectedRules={selectedRules}
            onSelectRules={handleSelectRules}
            onSubmit={handleSubmit}
            isSubmittingForm={isLoading}
            defaultValues={defaultValues}
            isLoading={isBuilding}
        />
    );
};

export default CreateOrUpdateProfilePage;
