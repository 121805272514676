import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import { Link, Outlet, useOutletContext, useParams } from 'react-router-dom';
import { SaleOrder } from 'types/models/sale-order';
import { memo, useState } from 'react';
import { orderServicesDatatableColumns } from 'pages/private/sales-orders/utils';
import useGetPaginatedWithFilters from 'services/queries/general/use-get-paginated-with-filters';
import { Rule } from 'types/permissions';
import PageHeader from 'components/page/header';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Datatable from 'components/core/table/datatable';
import { OrderService } from 'types/models/order-service';
import OrderServiceFilters from 'components/advanced-search/order-service';
import { getOrderServiceColumnOrderBy } from './utils';
import ConfirmModal from 'components/core/modal/confirm';
import useDelete from 'services/queries/crud/use-delete';
import { useQueryClient } from 'react-query';
import { getPaginatedWithFiltersKey } from 'services/queries/general/keys';
import useConfig from 'store/config/use-config';
import useGetDataContentConfigFilters from 'pages/private/reports/hooks/use-get-data-content-filters';
import { OrderServiceFiltersPayload } from './types';
import SvgIcoPlus from 'components/core/icon/files/ico-plus';

const OrderServiceListPage = ({ page: pagePermissions }: WithPagePermissionsProps) => {
    const [orderServiceToDeleteId, setOrderServiceToDeleteId] = useState<number | null>(null);
    const [hasSelectedFilters, setHasSelectedFilters] = useState(false);
    const [filters, setFilters] = useState<OrderServiceFiltersPayload>();
    const [orderBy, setOrderBy] = useState<any>(null);
    const [page, setPage] = useState(1);

    const { saleOrderId } = useParams();
    const { palette } = useTheme();
    const queryClient = useQueryClient();
    const { config } = useConfig();

    const endpoint = Boolean(saleOrderId) ? `/sale-orders/${saleOrderId}/service-orders` : '/service-orders';

    const { data: orderServices, isLoading: isLoadingOrderServices } = useGetPaginatedWithFilters<Partial<OrderService>>({
        endpoint,
        filters,
        page,
        orderBy,
    });

    const { mutateAsync: deleteOrderService, isLoading: isDeletingOrderService } = useDelete({
        apiPayload: {
            useApiTodelete: true,
            endpoint: `/sale-orders/${saleOrderId}/service-orders`,
        },
    });

    const dataContentFiltersConfig = useGetDataContentConfigFilters({
        withConsultants: true,
        withSaleOrders: true,
        withStatuses: true,
    });

    const saleOrder = useOutletContext<Partial<SaleOrder>>();

    const columns = orderServicesDatatableColumns(saleOrderId);

    const actions = pagePermissions?.rules.map((item) => item.key);
    const canCreateSaleOrder = actions?.includes(Rule.Create) && Boolean(saleOrderId);

    const handleDeleteOrderService = async () => {
        try {
            if (Boolean(orderServiceToDeleteId)) {
                await deleteOrderService({ id: orderServiceToDeleteId! });
                queryClient.invalidateQueries(getPaginatedWithFiltersKey(endpoint, filters, page, config.tables.rowsPerPage, orderBy));

                setOrderServiceToDeleteId(null);
            }
        } catch (e) {
            console.error('handleDeleteOrderService: ', e);
        }
    };

    return (
        <>
            <PageHeader
                className="mb-6"
                title="Ordens de serviço"
                right={
                    canCreateSaleOrder ? (
                        <IconButton component={Link} to="novo" color="secondary" size="large" sx={{ backgroundColor: palette.secondary.main, '&:hover': { backgroundColor: palette.secondary.dark } }}>
                            <SvgIcoPlus width={16} height={16} color={palette.grey[100]} />
                        </IconButton>
                    ) : undefined
                }
            />
            <Datatable
                data={orderServices?.items! || []}
                columns={columns || []}
                actions={actions}
                onDelete={setOrderServiceToDeleteId as any}
                hasSelectedFilters={hasSelectedFilters}
                loading={isLoadingOrderServices}
                advancedSearchComponent={
                    <OrderServiceFilters dataContentFiltersConfig={dataContentFiltersConfig} filters={filters} onSetFilters={setFilters} onSetHasSelectedFilters={setHasSelectedFilters} />
                }
                options={{
                    searchPlaceholder: 'Buscar ordem de serviço',
                    sort: true,
                    filter: false,
                    search: true,
                    print: false,
                    download: false,
                    viewColumns: false,
                    serverSide: true,
                    searchAlwaysOpen: true,
                    enableNestedDataAccess: '.',
                    pagination: true,
                    elevation: 21,
                    selectableRows: 'none',
                    count: orderServices?.total,
                    page,
                    onChangePage: setPage,
                    onSearchChange: (searchText) => {
                        setFilters((prev) => ({ ...prev, searchText }));
                    },
                    onColumnSortChange: (changedColumn, direction) => {
                        const columnKey = getOrderServiceColumnOrderBy(changedColumn);

                        setOrderBy({ [columnKey]: direction.toUpperCase() });
                    },
                }}
            />
            <Outlet
                context={{
                    ...saleOrder,
                    params: {
                        orderBy,
                        page,
                        filters,
                        endpoint,
                        rowsPerPage: config.tables.rowsPerPage,
                    },
                }}
            />
            {Boolean(saleOrderId) && Boolean(orderServiceToDeleteId) && (
                <ConfirmModal
                    onConfirmAction={handleDeleteOrderService}
                    onClose={setOrderServiceToDeleteId.bind(this, null)}
                    description="Você tem certeza que deseja apagar esta ordem de serviço?"
                    title="Apagar ordem de serviço"
                    isLoading={isDeletingOrderService}
                />
            )}
        </>
    );
};

export default memo(OrderServiceListPage);
