import IconButton from '@mui/material/IconButton';
import useTheme from '@mui/material/styles/useTheme';
import Datatable from 'components/core/table/datatable';
import PageHeader from 'components/page/header';

import { Link, Outlet, useParams } from 'react-router-dom';
import useGetSaleOrderSchedules from 'services/queries/sales-order/use-get-sale-order-schedules';
import { saleOrderSchedulesColumns } from './utils';
import SvgIcoPlus from 'components/core/icon/files/ico-plus';

const SaleOrderSchedulePage = () => {
    const { palette } = useTheme();
    const { saleOrderId } = useParams();

    const { data: saleOrderSchedules = [], isLoading: isLoadingSaleOderSchedules } = useGetSaleOrderSchedules(saleOrderId);

    return (
        <>
            <PageHeader
                className="mb-8"
                title="Agendamentos"
                right={
                    <IconButton component={Link} to="novo" color="secondary" size="large" sx={{ backgroundColor: palette.secondary.main, '&:hover': { backgroundColor: palette.secondary.dark } }}>
                        <SvgIcoPlus width={16} height={16} color={palette.grey[100]} />
                    </IconButton>
                }
            />
            <Datatable
                data={saleOrderSchedules}
                columns={saleOrderSchedulesColumns}
                loading={isLoadingSaleOderSchedules}
                options={{
                    enableNestedDataAccess: '.',
                    elevation: 21,
                    search: true,
                    searchAlwaysOpen: true,
                    filter: false,
                    selectableRows: 'none',
                    searchPlaceholder: 'Buscar Agendamento',
                }}
                withItemsPerPage={false}
            />
            <Outlet />
        </>
    );
};

export default SaleOrderSchedulePage;
