import Select from 'components/core/form/select';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import useGetPersonContractStatusOptions from 'services/queries/people/use-person-contract-status-options';
import _get from 'lodash/get';
import Button from 'components/core/button';
import { useTableAdvancedSearch } from 'components/core/table/datatable/components/advanced-search';
import { useListPageContext } from 'pages/shared/crud/list/context';
import { PersonContractStatusTitle } from 'types/models/person';

type FormState = {
    contractStatusId: PersonContractStatusTitle;
};

const PersonAdvancedSearch = () => {
    const { onClose } = useTableAdvancedSearch();
    const { params, onChangeParam } = useListPageContext();

    const { control, handleSubmit, reset } = useForm<FormState>({
        mode: 'onSubmit',
    });

    const { data: contractStatusOptions = [], isLoading: isLoadingStatus } = useGetPersonContractStatusOptions(true);

    const onSubmit = (data: FormState) => {
        // Sends the data to the list form
        onChangeParam({
            // sending name as "contracts" to make sure the query will not send any empty objects
            name: data?.contractStatusId === undefined ? 'personContracts' : 'personContracts.contractStatusId._eq',
            value: data?.contractStatusId,
        });

        // Closes the filter
        onClose();
    };

    useEffect(() => {
        const contractStatusId: PersonContractStatusTitle | undefined = _get(params, 'where.personContracts.contractStatusId._eq');

        if (contractStatusId) {
            reset({ contractStatusId });
        }
    }, [params, reset]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-5">
                <Controller
                    name="contractStatusId"
                    control={control}
                    render={({ field }) => {
                        const value = contractStatusOptions.find((item) => (item as any)?.value === field.value);

                        return (
                            <Select
                                {...field}
                                isLoading={isLoadingStatus}
                                value={value}
                                options={contractStatusOptions}
                                label="Status do contrato"
                                placeholder="Selecione uma opção"
                                onChange={(option: any) => field.onChange(option?.value)}
                                isClearable={true}
                            />
                        );
                    }}
                />
            </div>

            <div className="flex">
                <Button type="submit" variant="contained" color="secondary" className="min-w-[100px] ml-auto">
                    Filtrar
                </Button>
            </div>
        </form>
    );
};

export default PersonAdvancedSearch;
