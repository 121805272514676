import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import useTheme from '@mui/material/styles/useTheme';
import SvgIcoFileText from 'components/core/icon/files/ico-file-text';
import SvgIcoTrash from 'components/core/icon/files/ico-trash';
import Text from 'components/core/text';
import { OrderServiceWorkReportPayload } from 'types/models/order-service';

type WorksReportFileItemProps = {
    item: OrderServiceWorkReportPayload['files'][0];
    index: number;
    onRemoveFile: (index: number) => void;
};

const WorksReportFileItem = ({ item, index, onRemoveFile }: WorksReportFileItemProps) => {
    const { palette } = useTheme();

    const { localFile } = item;

    const fileUrl = (localFile as any)?.url || !!localFile ? URL.createObjectURL(localFile!) : '';

    return (
        <TableRow>
            <TableCell>
                <div className="flex items-center">
                    <SvgIcoFileText width={16} height={16} className="mr-1 text-base-500" />
                    <a className="hover:underline" href={fileUrl} rel="noreferrer" target="_blank">
                        <Text className="text-secondary-500 font-medium">{localFile?.name}</Text>
                    </a>
                </div>
            </TableCell>
            <TableCell>
                <Tooltip title="Remover da lista">
                    <IconButton size="small" type="button" color="error" onClick={onRemoveFile.bind(this, index)}>
                        <SvgIcoTrash width={16} height={16} color={palette.error[500]} />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
};

export default WorksReportFileItem;
