import React from 'react';
import useTheme from '@mui/material/styles/useTheme';
import ButtonBase from '@mui/material/ButtonBase/ButtonBase';
import Menu from 'components/menu';
import Text from 'components/core/text';
import Avatar from 'components/core/avatar';
import SvgIcoCaretDown from 'components/core/icon/files/ico-caret-down';

type HeaderUserProps = {
    name: string;
    onLogout: () => void;
    onOpenResetPasswordModal: () => void;
};

const rippleProps = { classes: { child: 'text-secondary-100' } };

const HeaderUser = ({ name, onOpenResetPasswordModal, onLogout }: HeaderUserProps) => {
    const { palette } = useTheme();

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handleClose = () => setAnchorEl(null);
    const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

    return (
        <>
            <ButtonBase className="flex items-center py-[6px] pl-[10px] pr-[14px] rounded-[14px] overflow-hidden" TouchRippleProps={rippleProps} onClick={handleOpen}>
                <Avatar size="micro" firstLetter={name.charAt(0)} />
                <Text as="p" variant="body.regular.sm" className="text-heading ml-3 mr-1.5 whitespace-nowrap text-ellipsis xs:max-w-[120px] overflow-hidden">
                    {name}
                </Text>
                <SvgIcoCaretDown color={palette.heading} width={7.2} height={4.8} />
            </ButtonBase>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                content={[
                    {
                        label: 'Alterar Senha',
                        icon: {
                            color: palette.grey[500],
                            name: 'ico-lock',
                        },
                        onClick: () => {
                            onOpenResetPasswordModal();
                            setAnchorEl(null);
                        },
                    },
                ]}
                footer={[
                    {
                        label: 'Sair',
                        icon: {
                            color: palette.grey[500],
                            name: 'ico-logout',
                        },
                        onClick: onLogout,
                    },
                ]}
            />
        </>
    );
};

export default HeaderUser;
