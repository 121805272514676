import dictionary from 'utils/dictionary';
import { optionSchema } from 'utils/schema';
import { array, number, object, string } from 'yup';

export const importFlowGeneralTabSchema = object({
    company: number().required(dictionary.validation.required),
    branch: number().required(dictionary.validation.required),
    invoice: string().required(dictionary.validation.required),
    formFavoredManufacturer: optionSchema,
    dueDateManufacturer: string().required(dictionary.validation.required),
    provisionManufacturer: string().required(dictionary.validation.required),
    classificationOrigin: number().required(dictionary.validation.required),
    classificationSubOrigin: number().required(dictionary.validation.required),
    fiscalCode: number().required(dictionary.validation.required),
});

export const importFlowProductsTabSchema = object({
    products: array(
        object({
            name: string().required(dictionary.validation.required),
            product: number().required(dictionary.validation.required),
            quantity: number().required(dictionary.validation.required),
            baseValue: number().required(dictionary.validation.required),
            complementaryValue: number().required(dictionary.validation.required),
        })
    ).min(1, dictionary.validation.array.min(1)),
});

export const importFlowExpensesTabSchema = object({
    formFavoredCustomBroker: optionSchema,
    dueDateCustomBroker: string().required(dictionary.validation.required),
    provisionCustomBroker: string().required(dictionary.validation.required),
});
