import { jsonToGraphQLQuery } from 'json-to-graphql-query/lib/jsonToGraphQLQuery';
import { EnumType } from 'json-to-graphql-query/lib/types/EnumType';
import { Status } from 'types/general';
import { WhereClause } from 'types/graphql';
import { convertArrayToObject } from 'utils/array';

export const generateCompanies = (fields: string[], where?: WhereClause<Partial<unknown>>['where']) => {
    if (!fields.length) {
        throw new Error('Missing query fields');
    }

    const fieldsToGraphql = convertArrayToObject(fields);

    return jsonToGraphQLQuery(
        {
            query: {
                __name: 'GetCompanies',
                company: {
                    __aliasFor: 'company',
                    __args: {
                        orderBy: { name: new EnumType('ASC') },
                        where: {
                            status: { _eq: Status.Active },
                            ...(Boolean(where) && where)
                        }
                    },
                    ...fieldsToGraphql
                }
            }
        },
        { pretty: true }
    );
};
