import TableContainer from '@mui/material/TableContainer/TableContainer';
import Table from '@mui/material/Table/Table';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableBody from '@mui/material/TableBody/TableBody';
import Empty from 'components/empty';
import { PendingMoneyDeductionsOrderServices } from './create-pending-money-deduction';
import PendingMoneyDeductionItem from './pending-money-deduction-item';

type PendingMoneyOrderServiceTableProps = {
    orderServices: PendingMoneyDeductionsOrderServices[];
};

const PendingMoneyOrderServiceTable = ({ orderServices }: PendingMoneyOrderServiceTableProps) => {
    return (
        <TableContainer className="mb-8">
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className="text-secondary-500">#</TableCell>
                        <TableCell className="text-secondary-500">Código</TableCell>
                        <TableCell className="text-secondary-500">Valor</TableCell>
                        <TableCell className="text-secondary-500">Total a deduzir</TableCell>
                        <TableCell className="text-secondary-500">Total deduzido</TableCell>
                        <TableCell className="text-secondary-500">Valor a deduzir</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Boolean(orderServices?.length) ? (
                        orderServices.map((orderService, index: number) => <PendingMoneyDeductionItem key={orderService.id} item={orderService} index={index} />)
                    ) : (
                        <TableRow sx={{ '&:last-child td': { borderBottom: 0 } }}>
                            <TableCell colSpan={6}>
                                <Empty title="Nenhuma ordem de serviço encontrada" />
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default PendingMoneyOrderServiceTable;
