import { useQuery } from 'react-query';
import api from 'services/api';
import useToast from 'hooks/toast/use-toast';
import qs from 'qs';
import { Pagination } from 'types/pagination';
import { PurchaseProduct } from 'types/models/purchase-order';
import { getOrderServicesPurchaseScopeKey } from './keys';

export type GetOrderServicesPurchaseScopeParams = {
    endpoint: string;
    filters: any;
};

const useGetOrderServicesPurchaseScope = <T = Pagination<PurchaseProduct>>({ endpoint, filters }: GetOrderServicesPurchaseScopeParams) => {
    const { showToast } = useToast();

    const request = async () => {
        const query = qs.stringify(filters, { addQueryPrefix: true, encode: false, arrayFormat: 'brackets' });

        const { data } = await api.get<T>(endpoint + query);

        return data;
    };

    return useQuery(getOrderServicesPurchaseScopeKey(endpoint, filters), request, {
        enabled: !!endpoint,
        onError: () => {
            showToast(`Ocorreu um erro ao obter a listagem. Tente novamente.`, 'danger');
        },
    });
};

export default useGetOrderServicesPurchaseScope;
