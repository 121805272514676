import { yupResolver } from '@hookform/resolvers/yup';
import useTheme from '@mui/material/styles/useTheme';
import Button from 'components/core/button';
import DatePicker from 'components/core/datepicker';
import Select from 'components/core/form/select';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import useGetClassifications from 'services/queries/classifications/use-get-classifications';
import { generateClassificationQuery } from 'services/queries/classifications/utils';
import useGetCompaniesOptions from 'services/queries/companies/use-get-company-options';
import useGetCustomerOptions from 'services/queries/customers/use-get-customer-options';
import { generateCustomerOptionsQuery } from 'services/queries/customers/utils';
import { ClassificationType } from 'types/models/classification';
import { formatDate } from 'utils/date';
import { useSaleOrderContext } from '../context';
import { useParams } from 'react-router-dom';
import { saleOrderGeneralSchema } from 'pages/private/sales-orders/utils';
import { SaleOrderOrigin, SaleOrderType } from 'types/models/sale-order';
import { saleOrderOrigins, saleOrderTypes } from 'utils/statics';
import useGetBranchesOptions from 'services/queries/branches/use-get-branches';
import { generateBranchOptionsQuery } from 'services/queries/branches/utils';
import IconButton from '@mui/material/IconButton/IconButton';
import CreateOrUpdateCustomerPage from '../../../customers/create-or-update';
import { useQueryClient } from 'react-query';
import { getCustomerOptionsKey } from 'services/queries/customers/keys';
import Checkbox from 'components/core/form/checkbox';
import CurrencyInput from 'components/core/form/currency';
import Text from 'components/core/text';
import classNames from 'classnames';
import { Steps } from '../context/types';
import useGetConsultants from 'services/queries/people/use-get-consultants';
import { NumberFormatValues } from 'react-number-format';
import { Option } from 'types/general';
import { ProductsItem } from './products';
import { Product } from 'types/models/product';
import Spinner from 'components/core/spinner';
import isEmpty from 'lodash/isEmpty';
import FilterPeriod from 'pages/private/payment-order/filter/period';
import SvgIcoPlus from 'components/core/icon/files/ico-plus';
import SvgIcoArrowRight from 'components/core/icon/files/ico-arrow-right';

export type SaleOrderPayload = {
    contractDate: string;
    consultant: Option | null;
    branch: Option | null;
    company: Option | null;
    classification: Option | null;
    customer: Option | null;
    origin: SaleOrderOrigin;
    type: SaleOrderType;
    withFreight?: number;
    freightValue?: number;
    commission: number;
    contractPeriodStartDate?: string;
    contractPeriodEndDate?: string;
    products: (ProductsItem & {
        product: Pick<Product, 'code' | 'id' | 'name'> & {
            unit: {
                name: string;
            };
        };
        stockBalance: number;
        id?: number;
    })[];
};

export const INITIAL_VALUES = {
    contractDate: '',
    commission: 5,
    contractStartDate: '',
    contractEndDate: '',
};

type GeneralProps = {
    isLoadingSaleOrder: boolean;
};

const General = ({ isLoadingSaleOrder }: GeneralProps) => {
    const { palette } = useTheme();
    const { saleOrderId } = useParams();
    const queryClient = useQueryClient();
    const { state, goToStep } = useSaleOrderContext();
    const { completeStep, setContent } = useSaleOrderContext();

    const [isCreateCustomerModalVisible, setIsCreateCustomerModalVisible] = useState(false);
    const [saleOrderOrigin, setSaleOrderOrigin] = useState<SaleOrderOrigin | null>(null);

    const { data: companies = [] } = useGetCompaniesOptions();
    const { data: people = [] } = useGetConsultants(true, true);
    const { data: branches = [] } = useGetBranchesOptions(generateBranchOptionsQuery());
    const { data: customers = [] } = useGetCustomerOptions(generateCustomerOptionsQuery());
    const { data: types = [] } = useGetClassifications(generateClassificationQuery(ClassificationType.SaleOrderType));

    const { handleSubmit, reset, formState, control, setValue, watch } = useForm<SaleOrderPayload>({
        mode: 'onSubmit',
        defaultValues: INITIAL_VALUES,
        resolver: yupResolver(saleOrderGeneralSchema(saleOrderOrigin)) as any,
    });

    const [withFreight, origin] = watch(['withFreight', 'origin']);

    const isOriginSale = SaleOrderOrigin.sale === origin;
    const isOriginService = SaleOrderOrigin.service === origin;

    const generalState = useMemo(() => state?.content, [state]);

    const handleSuccessCreateCustomer = (customerId: number, title: string) => {
        queryClient.invalidateQueries(getCustomerOptionsKey);

        setTimeout(() => {
            setIsCreateCustomerModalVisible(false);
            setValue('customer', { value: customerId, label: title });
        }, 2000);
    };

    const handleSelectOrigin = (onChange, option) => {
        if (option.value !== SaleOrderOrigin.sale && !!withFreight) {
            setValue('withFreight', undefined);
            setValue('freightValue', undefined);
        }

        setValue('contractPeriodStartDate', '');
        setValue('contractPeriodEndDate', '');

        setSaleOrderOrigin(option.value);
        onChange(option.value);
    };

    const submit = (data: SaleOrderPayload) => {
        const payload: SaleOrderPayload = {
            ...data,
            contractDate: formatDate(data.contractDate, 'YYYY-MM-DD'),
            contractPeriodStartDate: formatDate(data.contractPeriodStartDate, 'YYYY-MM-DD'),
            contractPeriodEndDate: formatDate(data.contractPeriodEndDate, 'YYYY-MM-DD'),
        };

        setContent(payload);
        completeStep(Steps.GeneralInfo);

        goToStep('next');
    };

    const handleValueChange = (onChange: (...event: any[]) => void, value: NumberFormatValues) => {
        return onChange(value.floatValue);
    };

    const handleChangeSelect = (onChange: (...event: any[]) => void, option: any) => {
        return onChange(option);
    };

    useEffect(() => {
        if (!isEmpty(generalState)) {
            reset(generalState);
        }
    }, [generalState, reset]);

    if (isLoadingSaleOrder) {
        return <Spinner fixed={false} size={30} parentClasses="p-6" />;
    }

    return (
        <>
            <form onSubmit={handleSubmit(submit)}>
                <div className="p-6">
                    <div className="grid grid-cols-1 gap-4 mb-6 sm:grid-cols-2 md:grid-cols-3">
                        <Controller
                            control={control}
                            name="branch"
                            render={({ field }) => {
                                const value = branches.find((item) => item.value === field.value?.value);

                                return (
                                    <Select
                                        {...field}
                                        value={value}
                                        options={branches}
                                        label="Filial"
                                        placeholder="Selecione uma opção"
                                        parentClassName="mb-4 md:mb-0"
                                        onChange={handleChangeSelect.bind(this, field.onChange)}
                                        error={formState.errors.branch?.message}
                                    />
                                );
                            }}
                        />
                        <Controller
                            control={control}
                            name="consultant"
                            render={({ field }) => {
                                const value = people.find((item) => item.value === field.value?.value);

                                return (
                                    <Select
                                        {...field}
                                        value={value}
                                        options={people}
                                        label="Consultor"
                                        placeholder="Selecione uma opção"
                                        parentClassName="mb-4 md:mb-0"
                                        error={formState.errors.consultant?.message}
                                        onChange={handleChangeSelect.bind(this, field.onChange)}
                                    />
                                );
                            }}
                        />
                        <Controller
                            control={control}
                            name="company"
                            render={({ field }) => {
                                const value = companies.find((item) => item.value === field.value?.value);

                                return (
                                    <Select
                                        {...field}
                                        value={value}
                                        options={companies}
                                        label="Empresa"
                                        placeholder="Selecione uma opção"
                                        parentClassName="mb-4 md:mb-0"
                                        onChange={handleChangeSelect.bind(this, field.onChange)}
                                        error={formState.errors.company?.message}
                                    />
                                );
                            }}
                        />
                        <Controller
                            control={control}
                            name="classification"
                            render={({ field }) => {
                                const value = types.find((item) => item.value === field.value?.value);

                                return (
                                    <Select
                                        {...field}
                                        value={value}
                                        options={types}
                                        label="Classificação"
                                        placeholder="Selecione uma opção"
                                        parentClassName="mb-4 md:mb-0"
                                        onChange={handleChangeSelect.bind(this, field.onChange)}
                                        error={formState.errors.classification?.message}
                                    />
                                );
                            }}
                        />
                        <Controller
                            control={control}
                            name="contractDate"
                            render={({ field }) => {
                                return (
                                    <DatePicker
                                        inputProps={{
                                            ...field,
                                            label: 'Data do pedido',
                                            placeholderText: 'Selecione uma data',
                                            error: formState.errors.contractDate?.message,
                                        }}
                                    />
                                );
                            }}
                        />
                        <Controller
                            control={control}
                            name="customer"
                            render={({ field }) => {
                                const value = customers.find((item) => item.value === field.value?.value);

                                return (
                                    <div className="flex">
                                        <Select
                                            {...field}
                                            value={value}
                                            options={customers}
                                            label="Cliente"
                                            placeholder="Selecione uma opção"
                                            parentClassName="mb-4 md:mb-0 flex-1 favored-select"
                                            onChange={handleChangeSelect.bind(this, field.onChange)}
                                            error={formState.errors.customer?.message}
                                        />
                                        <IconButton
                                            className="h-[48px] mt-[26px] px-3 rounded-l-none rounded-r-[14px] bg-primary-500 min-w-[50px] hover:bg-primary-700"
                                            type="button"
                                            color="primary"
                                            onClick={setIsCreateCustomerModalVisible.bind(this, true)}>
                                            <SvgIcoPlus width={16} height={16} color={palette.grey[100]} />
                                        </IconButton>
                                    </div>
                                );
                            }}
                        />
                        <Controller
                            control={control}
                            name="type"
                            render={({ field }) => {
                                const value = saleOrderTypes.find((item) => item.value === field.value);

                                return (
                                    <Select
                                        value={value}
                                        options={saleOrderTypes}
                                        label="Tipo de pedido"
                                        placeholder="Selecione uma opção"
                                        parentClassName="mb-4 md:mb-0"
                                        error={formState.errors.type?.message}
                                        onChange={(option: any) => field.onChange(option.value)}
                                    />
                                );
                            }}
                        />
                        <Controller
                            control={control}
                            name="origin"
                            render={({ field }) => {
                                const value = saleOrderOrigins.find((item) => item.value === field.value);

                                return (
                                    <Select
                                        value={value}
                                        options={saleOrderOrigins}
                                        isDisabled={Boolean(saleOrderId)}
                                        label="Origem"
                                        placeholder="Selecione uma opção"
                                        parentClassName="mb-4 md:mb-0"
                                        error={formState.errors.origin?.message}
                                        onChange={handleSelectOrigin.bind(this, field.onChange)}
                                    />
                                );
                            }}
                        />
                        {isOriginService && (
                            <>
                                <FilterPeriod
                                    label="Vigência do contrato"
                                    control={control}
                                    firstInput={{
                                        inputProps: {
                                            label: 'Início',
                                            name: 'contractPeriodStartDate',
                                            error: formState.errors.contractPeriodStartDate?.message,
                                            isClearable: false,
                                        },
                                    }}
                                    secondInput={{
                                        inputProps: {
                                            label: 'Fim',
                                            name: 'contractPeriodEndDate',
                                            error: formState.errors.contractPeriodEndDate?.message,
                                            isClearable: false,
                                        },
                                    }}
                                />
                                <Controller
                                    control={control}
                                    name="commission"
                                    render={({ field }) => {
                                        return (
                                            <CurrencyInput
                                                ref={field.ref}
                                                name={field.name}
                                                right={
                                                    <Text as="span" variant="body.medium.sm" className="text-heading">
                                                        %
                                                    </Text>
                                                }
                                                maxLength={7}
                                                max={100}
                                                rightClasses="bg-base-200 px-4"
                                                value={field.value}
                                                label="Comissão"
                                                placeholder="Ex: 10,00"
                                                parentClassName="mb-4 md:mb-0"
                                                onValueChange={(values) => {
                                                    const newValues = {
                                                        ...values,
                                                        floatValue: (values.floatValue || 0) > 100 ? 100 : values.floatValue,
                                                    };

                                                    handleValueChange(field.onChange, newValues);
                                                }}
                                                error={formState.errors.commission?.message}
                                            />
                                        );
                                    }}
                                />
                            </>
                        )}
                        {isOriginSale && !saleOrderId && (
                            <>
                                <div className="h-12 border border-base-300 pl-4 pt-0 rounded-xl mt-[26px] flex overflow-hidden relative">
                                    <Controller
                                        name="withFreight"
                                        control={control}
                                        render={({ field }) => (
                                            <Checkbox
                                                {...field}
                                                value={field.value}
                                                label="Pedido possui frete?"
                                                containerClasseName="py-[13px]"
                                                checked={Boolean(field.value)}
                                                onChange={(event) => {
                                                    const isChecked = event.currentTarget.checked;
                                                    setValue('withFreight', isChecked ? 1 : 0);
                                                }}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="freightValue"
                                        control={control}
                                        render={({ field }) => {
                                            return (
                                                <CurrencyInput
                                                    value={field.value}
                                                    onValueChange={handleValueChange.bind(this, field.onChange)}
                                                    placeholder="Ex: 1000"
                                                    parentClassName={classNames(
                                                        'ml-4 border-l border-l-base-300 absolute top-0 z-1 !w-[239px] transition-[right]',
                                                        !!withFreight ? 'right-0' : '-right-[239px]'
                                                    )}
                                                    inputWrapperClasses="border-none h-[46px] rounded-none"
                                                    error={formState.errors.freightValue?.message}
                                                    left={
                                                        <Text as="span" variant="body.medium.sm" className="text-heading">
                                                            R$
                                                        </Text>
                                                    }
                                                    leftClasses="bg-base-200 px-4"
                                                />
                                            );
                                        }}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="border-t p-2 flex justify-end">
                    <Button endIcon={<SvgIcoArrowRight width={18} color={palette.secondary[500]} />} type="submit" variant="text" color="secondary" className="min-w-[100px]">
                        Avançar
                    </Button>
                </div>
            </form>
            {isCreateCustomerModalVisible && <CreateOrUpdateCustomerPage title="Novo cliente" onClose={setIsCreateCustomerModalVisible.bind(this, false)} onSuccess={handleSuccessCreateCustomer} />}
        </>
    );
};

export default General;
