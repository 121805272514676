import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import DatePicker from 'components/core/datepicker';
import Input from 'components/core/form/input';
import useGetPeopleOptions from 'services/queries/people/use-get-people-options';
import { generatePeopleOptionsQuery } from 'services/queries/people/utils';
import Select from 'components/core/form/select';
import Editor from 'components/core/form/editor';
import Button from 'components/core/button';
import useTheme from '@mui/material/styles/useTheme';
import { useServiceOrderContext } from './context';
import ServiceOrderAddress from './create-or-update/address';
import { useEffect, useState } from 'react';
import { Option } from 'types/general';
import { serviceOrderGeneralSchema } from 'pages/private/sales-orders/utils';
import { PersonType } from 'types/models/person';
import useGetCustomer from 'services/queries/customers/use-get-customer';
import { customerAddressQuery } from './create-or-update/utils';
import { useParams } from 'react-router-dom';
import { booleanOptions } from 'utils/statics';
import { Steps } from './types';
import SvgIcoArrowRight from 'components/core/icon/files/ico-arrow-right';

export type ServiceOrderGeneralPayload = {
    title: string;
    startDate: string;
    competenceDate: string;
    endDate: string;
    observations: string;
    engineer: Option;
    contractor: Option;
    customerAddress: boolean;
    address?: any;
    canRegisterPurchaseScope?: boolean;
};

type ServiceOrderGeneralProps = {
    customerId?: number;
    canUpdatePurchaseScope?: boolean;
};

const peopleWhere = {
    type: { _eq: PersonType.Internal },
};

const ServiceOrderGeneral = ({ customerId, canUpdatePurchaseScope = true }: ServiceOrderGeneralProps) => {
    const { palette } = useTheme();
    const { state, setContent, goToStep, completeStep, hideStep } = useServiceOrderContext();
    const { orderServiceId } = useParams();
    const [hasCustomerAddress, setHasCustomerAddress] = useState(false);

    const { data: people = [] } = useGetPeopleOptions(generatePeopleOptionsQuery(peopleWhere));
    const { data: customer } = useGetCustomer(customerAddressQuery, customerId?.toString());

    const methods = useForm<ServiceOrderGeneralPayload>({
        mode: 'onSubmit',
        defaultValues: {
            competenceDate: '',
            endDate: '',
            startDate: '',
            title: '',
            customerAddress: true,
            canRegisterPurchaseScope: true,
        },
        resolver: yupResolver(serviceOrderGeneralSchema(hasCustomerAddress)) as any,
    });

    const { formState, control, reset, handleSubmit, watch } = methods;

    const customerAddress = watch('customerAddress');

    const submit = async (data: ServiceOrderGeneralPayload) => {
        try {
            setContent(data);
            completeStep(Steps.GeneralInfo);
            goToStep('next');
        } catch (error) {
            console.log('submit', error);
        }
    };

    const hanleChangeRegisterPurchaseScope = (onChange: (...event: any[]) => void) => (option: any) => {
        hideStep({ slug: Steps.PurchaseScope, hide: !option?.value });

        onChange(option?.value);
    };

    useEffect(() => {
        setHasCustomerAddress(customerAddress);
    }, [customerAddress]);

    useEffect(() => {
        if (state.content.title) {
            reset({
                contractor: state.content.contractor,
                customerAddress: state.content.customerAddress,
                endDate: state.content.endDate,
                engineer: state.content.engineer,
                observations: state.content.observations,
                startDate: state.content.startDate,
                competenceDate: state.content.competenceDate,
                title: state.content.title,
                address: {
                    ...state.content.address,
                    compliment: state.content.address?.compliment || '',
                },
                canRegisterPurchaseScope: state.content.canRegisterPurchaseScope,
            });
        }
    }, [state.content, reset]);

    useEffect(() => {
        if (typeof state.content.customerAddress !== 'undefined') {
            methods.setValue('customerAddress', !!state.content.customerAddress);
        } else {
            methods.setValue('customerAddress', !!customer?.addressCustomers?.[0]?.address);
        }
        // eslint-disable-next-line
    }, [customer]);

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(submit)}>
                <div className="p-6">
                    <div className="grid gap-4 mb-6 sm:grid-cols-4">
                        <Controller control={control} name="title" render={({ field }) => <Input {...field} label="Nome da Obra" error={formState.errors.title?.message} />} />
                        <Controller
                            control={control}
                            name="competenceDate"
                            render={({ field }) => {
                                return (
                                    <DatePicker
                                        inputProps={{
                                            error: formState.errors.startDate?.message,
                                            label: 'Data da OS',
                                            placeholderText: 'Selecione uma data',
                                            ...field,
                                        }}
                                    />
                                );
                            }}
                        />
                        <Controller
                            control={control}
                            name="startDate"
                            render={({ field }) => {
                                return (
                                    <DatePicker
                                        inputProps={{
                                            error: formState.errors.startDate?.message,
                                            label: 'Data inicial',
                                            placeholderText: 'Selecione uma data',
                                            ...field,
                                        }}
                                    />
                                );
                            }}
                        />
                        <Controller
                            control={control}
                            name="endDate"
                            render={({ field }) => {
                                return (
                                    <DatePicker
                                        inputProps={{
                                            error: formState.errors.startDate?.message,
                                            label: 'Data final',
                                            placeholderText: 'Selecione uma data',
                                            ...field,
                                        }}
                                    />
                                );
                            }}
                        />
                    </div>
                    <div className="grid gap-4 mb-6 sm:grid-cols-4">
                        <Controller
                            control={control}
                            name="engineer"
                            render={({ field }) => {
                                const value = people.find((item) => item.value === field.value?.value);

                                return (
                                    <Select
                                        {...field}
                                        value={value}
                                        options={people}
                                        label="Engenheiro Responsável"
                                        placeholder="Selecione uma opção"
                                        parentClassName="mb-4 md:mb-0"
                                        error={(formState as any).errors.engineer?.value?.message}
                                    />
                                );
                            }}
                        />
                        <Controller
                            control={control}
                            name="contractor"
                            render={({ field }) => {
                                const value = people.find((item) => item.value === field.value?.value);

                                return (
                                    <Select
                                        {...field}
                                        value={value}
                                        options={people}
                                        label="Empreiteiro Responsável"
                                        placeholder="Selecione uma opção"
                                        parentClassName="mb-4 md:mb-0"
                                        error={(formState as any).errors.contractor?.value?.message}
                                    />
                                );
                            }}
                        />
                        <Controller
                            control={control}
                            name="canRegisterPurchaseScope"
                            render={({ field }) => {
                                const value = booleanOptions.find((item) => item.value === field.value);

                                const isRowDisabled = (state.content.canRegisterPurchaseScope && Boolean(orderServiceId)) || (Boolean(orderServiceId) && !canUpdatePurchaseScope);

                                return (
                                    <Select
                                        {...field}
                                        value={value}
                                        options={booleanOptions}
                                        isDisabled={isRowDisabled}
                                        label="Cadastrar escopo de compra?"
                                        placeholder="Selecione uma opção"
                                        parentClassName="mb-4 md:mb-0"
                                        onChange={hanleChangeRegisterPurchaseScope(field.onChange)}
                                        error={formState.errors.canRegisterPurchaseScope?.message}
                                    />
                                );
                            }}
                        />
                    </div>
                    <ServiceOrderAddress address={customer?.addressCustomers?.[0]?.address} isUpdate={Boolean(orderServiceId)} />
                    <Controller control={control} name="observations" render={({ field }) => <Editor {...field} label="Observações" />} />
                </div>
                <div className="border-t p-2 flex justify-end">
                    <Button endIcon={<SvgIcoArrowRight width={18} color={palette.secondary[500]} />} type="submit" variant="text" color="secondary" className="min-w-[100px]">
                        Avançar
                    </Button>
                </div>
            </form>
        </FormProvider>
    );
};

export default ServiceOrderGeneral;
