import { useQuery } from 'react-query';
import api from 'services/api';
import { SalesReceiptResponse } from 'types/models/sales-notes';
import { getSaleNoteByIdKey } from './keys';

const useGetSaleReceiptById = (saleNoteId?: number) => {
    const request = async () => api.get<SalesReceiptResponse>(`/sales-notes/${saleNoteId}`).then(({ data }) => data);

    return useQuery(getSaleNoteByIdKey(saleNoteId), request, { enabled: !!saleNoteId });
};

export default useGetSaleReceiptById;
