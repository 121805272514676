import { Divider } from '@mui/material';
import DatePicker from 'components/core/datepicker';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import FormButtons from 'components/form-buttons';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import useTransactionRefund from 'services/queries/transactions/use-transaction-refund';

type RefundModalProps = {
    onClose: () => void;
};

export type RefundModalPayload = {
    date: string;
};

const RefundModal = ({ onClose }: RefundModalProps) => {
    const { paymentOrderId, receiptOrderId, transactionId } = useParams();

    const id = paymentOrderId || receiptOrderId || transactionId;
    // variables represent IDs from different routes - all of them represent the same type of ID

    const { handleSubmit, formState, control } = useForm<RefundModalPayload>({
        mode: 'onSubmit',
    });

    const { mutateAsync: mutateTransactionRefund, isLoading: isSubmitting } = useTransactionRefund(id!);

    const submit = async (data: RefundModalPayload) => {
        try {
            await mutateTransactionRefund(data);
            onClose();
        } catch (e) {
            console.error('submit - refundModalForm: ', e);
        }
    };

    return (
        <Modal
            contentClassnames="w-[600px]"
            onClose={onClose}
            closeOnClickOutside={false}
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    Estorno
                </Text>
            }>
            <Divider />
            <form className="p-6" onSubmit={handleSubmit(submit)}>
                <Controller
                    name="date"
                    control={control}
                    render={({ field }) => (
                        <DatePicker
                            containerClassNames="mb-4"
                            inputProps={{
                                placeholderText: 'Ex: 30/10/2024',
                                label: 'Data do estorno',
                                error: formState.errors.date?.message,
                                ...field,
                            }}
                        />
                    )}
                />
                <FormButtons
                    isLoading={isSubmitting}
                    cancelButton={{
                        onClick: onClose,
                    }}
                />
            </form>
        </Modal>
    );
};

export default RefundModal;
