import { ChangeEvent, useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useGetOrderServices, { OrderServicesResponse } from 'services/queries/order-services/use-get-order-services';
import Modal from 'components/core/modal';
import Divider from '@mui/material/Divider/Divider';
import Text from 'components/core/text';
import Input from 'components/core/form/input';
import Button from 'components/core/button';
import useCreateOrderServiceDeduction from 'services/queries/order-services/use-create-order-service-deductions';
import qs from 'qs';
import { pendingMoneyDeductionSchema } from '../utils';
import useGetTransaction from 'services/queries/transactions/use-get-transaction';
import Spinner from 'components/core/spinner';
import PendingMoneyOrderServiceTable from './pending-money-order-service-table';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';
import SvgIcoSearch from 'components/core/icon/files/ico-search';

export type PendingMoneyDeductionsOrderServices = OrderServicesResponse & { valueToDeduct: number; isChecked: boolean };

export type PendingMoneyDeductionsPayload = {
    orderServices: PendingMoneyDeductionsOrderServices[];
};

const CreatePendingMoneyDeductionPage = () => {
    const { transactionId } = useParams();

    const goToParentRoute = useNavigateToParentRoute();

    const ctx = useOutletContext<{ filters: any }>();

    const [code, setCode] = useState('');
    const [searchText, setSearchText] = useState('');
    const [filters, setFilters] = useState('');

    const handleChangeText =
        (type: 'code' | 'searchText') =>
        ({ target }: ChangeEvent<HTMLInputElement>) => {
            const value = target.value;

            if (type === 'code') {
                return setCode(value);
            }

            return setSearchText(value);
        };

    const { data: orderServicesData, isLoading: isLoadingOrderServices } = useGetOrderServices(filters);
    const { mutateAsync: createOrderServiceDeduction, isLoading: isSubmittingOrderServiceDeduction } = useCreateOrderServiceDeduction(ctx?.filters);
    const { data: transaction, isLoading: isLoadingTransaction } = useGetTransaction(transactionId);

    const methods = useForm<PendingMoneyDeductionsPayload>({
        mode: 'onSubmit',
        resolver: yupResolver(pendingMoneyDeductionSchema(transaction?.netValue || 0)) as any,
    });

    const { control, watch, handleSubmit } = methods;

    const { replace } = useFieldArray({ control, name: 'orderServices' });

    const handleSearchOrderServices = () => {
        const queryFilter = qs.stringify(
            {
                filter: {
                    ...(Boolean(code) && { code }),
                    ...(Boolean(searchText) && { searchText }),
                },
            },
            { addQueryPrefix: true, encode: false, arrayFormat: 'brackets', skipNulls: true }
        );

        setFilters(queryFilter);
    };

    const orderServices = watch('orderServices') || [];

    const submit = async (data: PendingMoneyDeductionsPayload) => {
        try {
            const payload = {
                deductions: data.orderServices
                    .filter(({ isChecked }) => isChecked)
                    .map((item) => ({
                        transaction: Number(transactionId),
                        orderService: item.id,
                        value: item.valueToDeduct,
                    })),
            };

            await createOrderServiceDeduction(payload);
        } catch (error) {
            console.error('submit - createPendingMoneyDeduction: ', error);
        }
    };

    useEffect(() => {
        if (Boolean(orderServicesData?.length)) {
            const formattedData = orderServicesData!.map((item) => ({
                ...item,
                valueToDeduct: transaction?.netValue!,
                isChecked: false,
            }));

            return replace(formattedData);
        }

        return replace([]);
    }, [transaction?.netValue, orderServicesData, replace]);

    return (
        <Modal
            closeOnClickOutside={false}
            contentClassnames="w-[900px]"
            onClose={goToParentRoute}
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    Adicionar deduções
                </Text>
            }>
            <Divider />
            <div className="p-6">
                {isLoadingTransaction ? (
                    <Spinner fixed={false} />
                ) : (
                    <>
                        <div className="flex items-end flex-wrap md:flex-nowrap gap-4 mb-10">
                            <Input label="Código da OS" placeholder="Digite o código da OS" onChange={handleChangeText('code')} />
                            <Input label="Busca por texto livre" placeholder="Busca por texto livre" onChange={handleChangeText('searchText')} />
                            <Button
                                className="rounded-full min-w-[33px] min-h-[33px] px-0 mb-[8px] hover:bg-secondary-700 text-white bg-secondary-500"
                                type="button"
                                size="medium"
                                title="Buscar ordens de serviço"
                                variant="contained"
                                loading={isLoadingOrderServices}
                                onClick={handleSearchOrderServices}>
                                {!isLoadingOrderServices && <SvgIcoSearch width={16} height={16} />}
                            </Button>
                        </div>
                        <FormProvider {...methods}>
                            <form onSubmit={handleSubmit(submit)}>
                                <PendingMoneyOrderServiceTable orderServices={orderServices} />
                                <div className="flex gap-4">
                                    <Button
                                        disabled={!orderServices.length || isSubmittingOrderServiceDeduction}
                                        loading={isSubmittingOrderServiceDeduction}
                                        type="submit"
                                        variant="contained"
                                        color="secondary">
                                        Inserir dedução no pedido
                                    </Button>
                                    <Button color="inherit" variant="outlined" onClick={goToParentRoute}>
                                        Cancelar
                                    </Button>
                                </div>
                            </form>
                        </FormProvider>
                    </>
                )}
            </div>
        </Modal>
    );
};

export default CreatePendingMoneyDeductionPage;
