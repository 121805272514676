import * as React from 'react';
const SvgIcoTag = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" width="1em" height="1em" {...props}>
        <path
            fill="currentColor"
            d="M2 2.75A.75.75 0 0 1 2.75 2h7.565a.75.75 0 0 1 .53.22l6.5 6.498v.002a2.263 2.263 0 0 1 0 3.192l-5.425 5.424a2.262 2.262 0 0 1-3.202 0l-6.498-6.49a.75.75 0 0 1-.22-.53V2.75Zm14.283 8.102a.763.763 0 0 0 0-1.074L10.005 3.5H3.5v6.504l6.279 6.272a.767.767 0 0 0 .832.166.762.762 0 0 0 .248-.166l5.424-5.424ZM9.778 6.95a1 1 0 0 1 1.414 0l2.829 2.828a1 1 0 0 1 0 1.414l-2.829 2.829a1 1 0 0 1-1.414 0L6.95 11.192a1 1 0 0 1 0-1.414L9.778 6.95ZM6.533 5.783a.75.75 0 0 0 0 1.5h.008a.75.75 0 1 0 0-1.5h-.008Z"
        />
    </svg>
);
export default SvgIcoTag;
