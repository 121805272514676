import { jsonToGraphQLQuery } from 'json-to-graphql-query';
import { useQuery } from 'react-query';
import apiGraphQl from 'services/graphql';
import { Address } from 'types/models/address';
import { getEntityAddressByPkKey } from './keys';

type Response = {
    [key in 'addressCustomerByPk' | 'addressProviderByPk']: { address: Partial<Address> };
};

const getAddressQuery = (key: string, addressId?: string) => {
    return jsonToGraphQLQuery(
        {
            query: {
                __name: 'GetEntityAddressByPk',
                [key]: {
                    __args: { id: Number(addressId || 0) },
                    address: {
                        cityId: true,
                        compliment: true,
                        neighbourhood: true,
                        number: true,
                        stateId: true,
                        street: true,
                        zip: true,
                    },
                },
            },
        },
        { pretty: true }
    );
};

const useGetAddress = (isCustomer: boolean, entityAddressId?: string) => {
    const key = isCustomer ? 'addressCustomerByPk' : 'addressProviderByPk';

    const request = async () => {
        const response = await apiGraphQl<Response>(getAddressQuery(key, entityAddressId));

        return response[key];
    };

    return useQuery(getEntityAddressByPkKey(entityAddressId), request, { enabled: !!entityAddressId });
};

export default useGetAddress;
