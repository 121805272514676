import RemoveButton from 'components/buttons/remove';
import Dropzone from 'components/dropzone';
import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import Text from 'components/core/text';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import Input from 'components/core/form/input';
import { ClassificationType } from 'types/models/classification';
import { generateClassificationQuery } from 'services/queries/classifications/utils';
import useGetClassifications from 'services/queries/classifications/use-get-classifications';
import Select from 'components/core/form/select';
import { PurchaseOrderExpensePayload } from 'types/models/purchase-order';
import SvgIcoFileText from 'components/core/icon/files/ico-file-text';

const Documents = () => {
    const { data: classifications = [] } = useGetClassifications(generateClassificationQuery(ClassificationType.DocumentTransaction));

    const { formState, control } = useFormContext<PurchaseOrderExpensePayload>();

    const { append, remove, fields: documents } = useFieldArray({ name: 'documents', control });

    const handleUploadFile = async (filesList: File[]) => {
        try {
            for (const localFile of filesList) {
                const fileName = localFile?.name?.split('.')?.[0];

                append({
                    name: fileName || '',
                    localFile,
                });
            }
        } catch (error) {
            console.log('handleChangeFile', error);
        }
    };

    const removeFile = (index: number) => () => remove(index);

    return (
        <div className="px-6 mb-6">
            <div className="border border-base-300 p-4 rounded-[14px] pb-0 mb-6">
                <Text as="h6" variant="h5" className="text-heading mb-4">
                    Documentos
                </Text>
                <Table className="mb-6">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Text className="text-base-500 uppercase">Nome</Text>
                            </TableCell>
                            <TableCell>
                                <Text className="text-base-500 uppercase">Classificação</Text>
                            </TableCell>
                            <TableCell>
                                <Text className="text-base-500 uppercase">Arquivo</Text>
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!documents?.length ? (
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell colSpan={4}>
                                    <Text className="text-base-500 block">Nenhum arquivo adicionado ainda.</Text>
                                </TableCell>
                            </TableRow>
                        ) : (
                            documents.map(({ localFile }, index) => {
                                const fileUrl = (localFile as any)?.url || !!localFile ? URL.createObjectURL(localFile) : '';

                                return (
                                    <TableRow key={`file_${index}_${localFile.name}`}>
                                        <TableCell>
                                            <Controller
                                                name={`documents.${index}.name`}
                                                control={control}
                                                render={({ field }) => <Input {...field} error={formState?.errors?.documents?.[index]?.name?.message} autoComplete="nope" placeholder="Nome" />}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Controller
                                                name={`documents.${index}.classification`}
                                                control={control}
                                                render={({ field }) => {
                                                    const value = classifications.find((item) => item.value === field.value);

                                                    return (
                                                        <Select
                                                            {...field}
                                                            value={value}
                                                            options={classifications}
                                                            placeholder="Selecione uma opção"
                                                            onChange={(option: any) => field.onChange(option.value)}
                                                            error={formState?.errors?.documents?.[index]?.classification?.message}
                                                        />
                                                    );
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <div className="flex items-center">
                                                <SvgIcoFileText width={16} height={16} className="mr-1 text-base-500" />
                                                <a className="hover:underline" href={fileUrl} rel="noreferrer" target="_blank">
                                                    <Text className="text-secondary-500 font-medium">{localFile?.name}</Text>
                                                </a>
                                            </div>
                                        </TableCell>
                                        <TableCell width={42}>
                                            <RemoveButton className="!static opacity-100 mt-0" onClick={removeFile(index)} />
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        )}
                    </TableBody>
                </Table>
                <Dropzone multiple={true} onUploadFiles={handleUploadFile} containerClasses="mb-6" />
            </div>
        </div>
    );
};

export default Documents;
