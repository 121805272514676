import { forwardRef, useCallback, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import { provisionWeekDays } from 'pages/private/expenses/crud/utils';
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker/DatePicker';
import { DatePickerProps as MUIDatePickerProps } from '@mui/x-date-pickers/DatePicker/DatePicker.types';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs/AdapterDayjs';
import Input, { InputProps } from '../form/input';
import IconButton from '@mui/material/IconButton';
import theme from 'settings/theme';
import useClickOutside from 'hooks/mouse/use-click-outside';
import { createDateWithTimezone } from 'utils/date';
import SvgIcoClose from '../icon/files/ico-close';
import SvgIcoCalendar from '../icon/files/ico-calendar';

export type DatePickerProps = {
    removeFirstDate?: boolean;
    withProvisionLogic?: boolean;
    containerClassNames?: string;
    inputProps?: InputProps & {
        placeholderText?: string;
        value?: string;
        isClearable?: boolean;
        onChange: MUIDatePickerProps<Dayjs>['onChange'];
    };
    muiDatePickerProps?: MUIDatePickerProps<Dayjs>;
};

const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>((props, ref) => {
    const [isDatepickerOpen, setIsDatepickerOpen] = useState(false);

    const divRef = useRef<HTMLDivElement>(null);
    const popperRef = useRef<HTMLDivElement>(null);

    const { withProvisionLogic = false, removeFirstDate = true, containerClassNames } = props;
    const { minDate: minDateProp, open: openProp, onOpen, onClose, ...datepickerRest } = props.muiDatePickerProps || {};

    const { name, label, isClearable = false, disabled = false, value: valueInputProp, placeholderText = 'Selecione uma data', onChange, ...inputRest } = props.inputProps || {};

    const value = useMemo(() => {
        if (!valueInputProp) {
            return null;
        }

        const dateValueISO = createDateWithTimezone(valueInputProp).toISOString();

        return dayjs(dateValueISO);
    }, [valueInputProp]);

    const minDate = useMemo(() => {
        if (!withProvisionLogic && !minDateProp) {
            return;
        }

        if (Boolean(minDateProp)) {
            return minDateProp;
        }

        const today = dayjs();
        const validDates: Dayjs[] = [];

        let currentDateToCheck = today.add(1, 'day');

        while (validDates.length < 2) {
            if (provisionWeekDays.includes(currentDateToCheck.day())) {
                validDates.push(currentDateToCheck);
            }

            currentDateToCheck = currentDateToCheck.add(1, 'day');
        }

        const ix = removeFirstDate ? 1 : 0;

        return validDates[ix];
    }, [withProvisionLogic, removeFirstDate, minDateProp]);

    const handleOpen = useCallback(() => {
        if (!!onOpen) {
            return onOpen();
        }

        setIsDatepickerOpen(true);
    }, [onOpen]);

    const handleClose = useCallback(() => {
        if (!!onClose) {
            return onClose();
        }

        setIsDatepickerOpen(false);
    }, [onClose]);

    useClickOutside(popperRef, handleClose);

    const containerClasses = classNames('relative', containerClassNames);

    return (
        <div className={containerClasses} ref={divRef}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                <MUIDatePicker
                    onChange={onChange}
                    dayOfWeekFormatter={(date) => dayjs(date).format('ddd').charAt(0).toUpperCase()}
                    views={['year', 'month', 'day']}
                    format="DD/MM/YYYY"
                    fixedWeekNumber={6}
                    slotProps={{
                        popper: {
                            anchorEl: divRef.current,
                            ref: popperRef,
                            className: 'z-[99999]',
                        },
                        field: {
                            readOnly: true,
                            clearable: isClearable,
                        },
                        actionBar: { actions: ['accept'] },
                    }}
                    slots={{
                        field: (fieldProps) => {
                            const inputValue = createDateWithTimezone(fieldProps.value);
                            const formattedInputValue = inputValue.isValid() ? inputValue.format(fieldProps.ownerState?.format) : '';

                            return (
                                <Input
                                    name={name}
                                    placeholder={placeholderText}
                                    label={label}
                                    value={formattedInputValue}
                                    ref={ref}
                                    autoComplete="nope"
                                    autoFocus={false}
                                    disabled={disabled}
                                    right={
                                        !!value && isClearable && !disabled ? (
                                            <IconButton
                                                className="p-0 w-[20px] h-[20px] bg-primary-100 bg-opacity-40 hover:bg-primary-100 hover:bg-opacity-20"
                                                onClick={onChange?.bind(this, '' as any) as any}>
                                                <SvgIcoClose width={8} height={8} color={theme.extend.colors.heading} />
                                            </IconButton>
                                        ) : (
                                            <SvgIcoCalendar width={20} height={20} color={theme.extend.colors.base[500]} />
                                        )
                                    }
                                    readOnly={true}
                                    rightClasses="bg-base-200 px-4"
                                    onChange={onChange}
                                    onFocus={fieldProps.ownerState.onOpen.bind(this)}
                                    {...inputRest}
                                />
                            );
                        },
                    }}
                    minDate={minDate}
                    open={openProp ?? isDatepickerOpen}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    {...datepickerRest}
                    value={value}
                />
            </LocalizationProvider>
        </div>
    );
});

export default DatePicker;
