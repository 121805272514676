import { FormProvider, useForm } from 'react-hook-form';
import { memo, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { genericReportDefaultValues, genericReportSchema } from 'pages/private/reports/screens/utils';
import { DataContentFiltersConfig, GenericReportFilterPayload, ReportFilterPayload } from 'pages/private/reports/screens/types';
import GenericReportFiltersContent from '../../../components/generic-filters';
import ModalFilter from 'components/core/modal/filter';
import dayjs from 'dayjs';

type ContractsReportFiltersProps = {
    onCloseFilters: () => void;
    filters?: GenericReportFilterPayload;
    withConsultants?: boolean;
    onSubmit: (data?: ReportFilterPayload['filter']) => void;
    onClear?: () => void;
    dataContentFiltersConfig: DataContentFiltersConfig;
};

const ContractsReportFilters = ({ filters, onCloseFilters, withConsultants = true, onSubmit, onClear, dataContentFiltersConfig }: ContractsReportFiltersProps) => {
    const methods = useForm<ReportFilterPayload['filter']>({
        mode: 'onSubmit',
        resolver: yupResolver(genericReportSchema(withConsultants)) as any,
        defaultValues: genericReportDefaultValues,
    });

    const { reset } = methods;

    useEffect(() => {
        if (!!filters) {
            const { endDate, startDate, ...rest } = filters.filter || {};

            reset({
                ...rest,
                ...(Boolean(startDate) && { startDate: dayjs(startDate).toISOString() }),
                ...(Boolean(endDate) && { endDate: dayjs(endDate).toISOString() }),
            });
        }
    }, [filters, reset]);

    return (
        <ModalFilter onSubmit={methods.handleSubmit(onSubmit)} onClose={onCloseFilters} formMethods={methods} onClear={onClear}>
            <div className="flex flex-col gap-2 relative">
                <FormProvider {...methods}>
                    <GenericReportFiltersContent dataContentFiltersConfig={dataContentFiltersConfig} withConsultants={withConsultants} />
                </FormProvider>
            </div>
        </ModalFilter>
    );
};

export default memo(ContractsReportFilters);
