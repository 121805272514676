import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';
import { Status } from 'types/general';
import { WhereClause } from 'types/graphql';
import { Company } from 'types/models/company';

export const generateBranchOptionsQuery = (where?: WhereClause<Partial<Company>>['where']) => {
    return jsonToGraphQLQuery(
        {
            query: {
                __name: 'GetBranchOptions',
                items: {
                    __aliasFor: 'branch',
                    __args: {
                        orderBy: { name: new EnumType('ASC') },
                        where: {
                            status: { _eq: Status.Active },
                            ...(Boolean(where) && where)
                        }
                    },
                    value: { __aliasFor: 'id' },
                    label: { __aliasFor: 'name' }
                }
            }
        },
        { pretty: true }
    );
};
