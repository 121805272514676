import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PurchaseProductStatusBadge from 'components/badges/purchase-product-status';
import Text from 'components/core/text';
import Empty from 'components/empty';
import { Link } from 'react-router-dom';
import { ExpenseProduct } from 'types/models/expense';
import { formatMoney, formatNumberToPTBR } from 'utils/money';

type ExpenseProductsTableProps = {
    expenses: ExpenseProduct[];
};

const OrderServiceExpenseTableProducts = ({ expenses = [] }: ExpenseProductsTableProps) => {
    if (!expenses.length) {
        return <Empty className="p-4" title="Não há despesas a serem mostradas." />;
    }

    return (
        <TableContainer>
            <Table>
                <TableHead className="border-b">
                    <TableRow>
                        <TableCell>
                            <Text as="span" className="text-base-500">
                                Despesa
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text as="span" className="text-base-500">
                                Justificativa
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text as="span" className="text-base-500">
                                Status
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text as="span" className="text-base-500">
                                Quantidade
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text as="span" className="text-base-500">
                                Valor Unitário
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text as="span" className="text-base-500">
                                Total
                            </Text>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {expenses.map(({ expense, id, justification, purchaseOrderProduct, quantity = 0, netValue = 0 }) => (
                        <TableRow key={id} classes={{ root: 'hover:bg-base-200 hover:bg-opacity-50' }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell>
                                <Link to={`/app/despesas/${expense.id}`}>
                                    <Text as="span" className="text-secondary-700 font-medium">
                                        {expense?.code || '-'}
                                    </Text>
                                </Link>
                            </TableCell>
                            <TableCell>
                                <Text as="span" className="text-base-500 whitespace-nowrap">
                                    {justification ?? '-'}
                                </Text>
                            </TableCell>
                            <TableCell>
                                <PurchaseProductStatusBadge purchaseProductStatus={purchaseOrderProduct.purchaseProductStatus} />
                            </TableCell>
                            <TableCell>
                                <Text as="span" className="text-base-500">
                                    {formatNumberToPTBR(quantity)}
                                </Text>
                            </TableCell>
                            <TableCell>
                                <Text as="span" className="text-base-500">
                                    {formatMoney(netValue)}
                                </Text>
                            </TableCell>
                            <TableCell>
                                <Text as="span" className="text-base-500">
                                    {formatMoney(quantity * netValue)}
                                </Text>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default OrderServiceExpenseTableProducts;
