import { useQuery } from 'react-query';
import api from 'services/api';
import { getExternalProviderKey } from './keys';
import { ProviderConsultingResponse } from 'types/models/provider';

const useGetExternalProvider = (providerId: number) => {
    const request = async () => {
        const { data } = await api.get<ProviderConsultingResponse>(`/providers/${providerId}/office`);

        return data;
    };

    return useQuery(getExternalProviderKey(providerId), request, {
        enabled: Boolean(providerId),
    });
};

export default useGetExternalProvider;
