import InterMenuTab, { InterMenuTabProps } from './tab';

const InterMenuDesk = (props: InterMenuTabProps) => {
    return (
        <div className="hidden lg:flex bg-base-100 border-r border-r-base-300 w-full flex-col border-t border-t-base-300 z-10 sm:h-full lg:w-[200px] xl:w-[250px] overflow-y-auto lg:pt-2">
            <InterMenuTab orientation="vertical" {...props} />
        </div>
    );
};

export default InterMenuDesk;
