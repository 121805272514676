import { useQuery } from 'react-query';
import apiGraphQl from 'services/graphql';
import { Option } from 'types/general';
import { getPeopleOptionsKey } from './keys';

type Response = {
    items: Option[];
};

const useGetPeopleOptions = (query: string) => {
    const request = async () => apiGraphQl<Response>(query).then(({ items }) => items);

    return useQuery(getPeopleOptionsKey, request);
};

export default useGetPeopleOptions;
