import { PropsWithChildren } from 'react';
import useTheme from '@mui/material/styles/useTheme';
import IconButton from '@mui/material/IconButton/IconButton';
import classNames from 'classnames';
import { hexToRgba } from 'utils/string';
import SvgIcoTrash from 'components/core/icon/files/ico-trash';

type RemoveButtonProps = {
    size?: 'small' | 'medium' | 'large';
    onClick: () => void;
    className?: string;
};

const RemoveButton = ({ children, onClick, className = '', size = 'large' }: PropsWithChildren<RemoveButtonProps>) => {
    const { palette } = useTheme();

    const containerClassName = classNames('absolute -right-[18px] top-[50%] -mt-[18px] opacity-0 group-hover:opacity-100 transition z-[10]', className);

    return (
        <div className={containerClassName}>
            <IconButton
                size={size}
                type="button"
                color="error"
                sx={{ backgroundColor: palette.grey[100], border: `1px solid ${palette.error.main}`, '&:hover': { backgroundColor: hexToRgba(palette.error[100], 0.5) } }}
                onClick={onClick}>
                {children || <SvgIcoTrash width={size === 'small' ? 13 : 16} height={size === 'small' ? 13 : 16} color={palette.error[500]} />}
            </IconButton>
        </div>
    );
};

export default RemoveButton;
