import Modal from 'components/core/modal';
import { OPsActionsProps } from './report-payment';
import Text from 'components/core/text';
import { pluralize } from 'utils/string';
import SelectedItemsTable from './table';
import FormButtons from 'components/form-buttons';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { array, number, object, string } from 'yup';
import dictionary from 'utils/dictionary';
import DatePicker from 'components/core/datepicker';
import { excludeDates } from './ops-grouping/general';
import useTransactionGeneratePaymentFlow, { GeneratePaymentFlowPayload } from 'services/queries/transactions/use-transaction-generate-payment-flow';
import Select from 'components/core/form/select';
import useGetCompaniesOptions from 'services/queries/companies/use-get-company-options';
import { useState } from 'react';
import useGetOptions from 'services/queries/crud/use-get-options';
import { getCompanyBankAccountsOptionsKeys } from 'services/queries/contribution/keys';
import { formatDate } from 'utils/date';
import { queryCompanyBankAccountsOptions } from 'services/queries/bank-accounts/utils';

const schema = object({
    provision: string().required(dictionary.validation.required).trim(),
    company: number().required(dictionary.validation.required),
    companyBankAccount: number().required(dictionary.validation.required),
    transactions: array(number().required(dictionary.validation.required)).required(dictionary.validation.required),
});

const GeneratePaymentFlow = ({ selectedItems, onClose }: OPsActionsProps) => {
    const [selecteds] = useState(selectedItems);

    const selectedIds = selecteds?.map(({ id }) => id) || [];

    const { data: companies = [], isLoading: isLoadingCompanies } = useGetCompaniesOptions();
    const { mutateAsync: generatePaymentFlow, isLoading: isSubmitting } = useTransactionGeneratePaymentFlow();

    const { handleSubmit, control, formState, watch } = useForm<GeneratePaymentFlowPayload>({
        mode: 'onSubmit',
        defaultValues: {
            provision: '',
            transactions: selectedIds,
        },
        resolver: yupResolver(schema),
    });

    const company = watch('company');

    const { data: companyBankAccounts = [], isLoading: isLoadingCompanyBankAccounts } = useGetOptions(
        queryCompanyBankAccountsOptions({ companyId: { _eq: company } }),
        getCompanyBankAccountsOptionsKeys([company]),
        Boolean(company),
        (items = []) => items.map((item) => item.bankAccount)
    );

    const submit = async (data: GeneratePaymentFlowPayload) => {
        try {
            const payload = {
                companyBankAccount: data.companyBankAccount,
                provision: formatDate(data.provision, 'YYYY-MM-DD'),
                transactions: data.transactions,
            };

            await generatePaymentFlow(payload);
        } catch (error) {
            console.log('submit', error);
        }
    };

    return (
        <Modal
            contentClassnames="min-w-[600px]"
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    Gerar fluxo de pagamento
                </Text>
            }
            closeOnClickOutside={false}
            onClose={onClose}>
            <form className="p-6 pt-0" onSubmit={handleSubmit(submit)}>
                <Text as="p" className="text-base-500 mb-3 text-base block">
                    Total de {pluralize(selectedIds?.length, 'despesas', 'despesa')} selecionadas para gerar fluxo de pagamento.
                </Text>
                <SelectedItemsTable items={selectedItems} />
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                    <Controller
                        name="company"
                        control={control}
                        render={({ field }) => {
                            const value = companies.find((item) => item.value === field.value);

                            return (
                                <Select
                                    {...field}
                                    value={value}
                                    label="Empresas"
                                    isLoading={isLoadingCompanies}
                                    options={companies}
                                    error={formState.errors.company?.message}
                                    onChange={(option: any) => field.onChange(option.value)}
                                />
                            );
                        }}
                    />
                    <Controller
                        name="companyBankAccount"
                        control={control}
                        render={({ field }) => {
                            const value = companyBankAccounts.find((item) => item.value === field.value);

                            return (
                                <Select
                                    {...field}
                                    isDisabled={!company}
                                    value={value}
                                    label="Contas bancárias"
                                    isLoading={isLoadingCompanyBankAccounts}
                                    options={companyBankAccounts}
                                    error={formState.errors.companyBankAccount?.message}
                                    onChange={(option: any) => field.onChange(option.value)}
                                />
                            );
                        }}
                    />
                </div>
                <Controller
                    name="provision"
                    control={control}
                    render={({ field }) => (
                        <DatePicker
                            inputProps={{
                                label: 'Data de provisionamento',
                                placeholderText: 'Selecione uma data',
                                error: formState.errors.provision?.message,
                                ...field,
                            }}
                            muiDatePickerProps={{
                                shouldDisableDate: excludeDates,
                            }}
                            withProvisionLogic={true}
                            containerClassNames="mb-6"
                        />
                    )}
                />
                <FormButtons
                    isLoading={isSubmitting}
                    cancelButton={{
                        onClick: onClose,
                    }}
                />
            </form>
        </Modal>
    );
};

export default GeneratePaymentFlow;
