import { useMemo, useState } from 'react';
import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import ListPage from 'pages/shared/crud/list';
import CardsDetails from './components/cards';
import { salesNoteListConfig, salesNotesFiltersWhere, totalsCards } from './utils';
import { useNavigate } from 'react-router-dom';
import TotalsCardTaxes from './view/components/cards-taxes';
import IconButton from '@mui/material/IconButton/IconButton';
import useTheme from '@mui/material/styles/useTheme';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import Button from 'components/core/button';
import Filter from './filter';
import PageHeader from 'components/page/header';
import useGetSalesReceiptsSums from 'services/queries/sales-notes/use-get-sales-receipts-sums';
import { getAllSalesReceiptsSumsKeys } from 'services/queries/sales-notes/keys';
import QueryString from 'qs';
import SvgIcoPlus from 'components/core/icon/files/ico-plus';
import SvgIcoFilter from 'components/core/icon/files/ico-filter';

const SalesNotesListPage = ({ page }: WithPagePermissionsProps) => {
    const { palette } = useTheme();
    const navigate = useNavigate();

    const [isFilterVisible, setIsFilterVisible] = useState(false);

    const params = QueryString.parse(window.location.search, { ignoreQueryPrefix: true });
    const whereClause = salesNotesFiltersWhere(params);

    const { data: salesReceiptsResume } = useGetSalesReceiptsSums(whereClause);

    const totalsCardsWithData = useMemo(() => totalsCards(salesReceiptsResume!), [salesReceiptsResume]);

    const listConfig = salesNoteListConfig(whereClause);

    const handleToggleFilter = (value: boolean) => () => setIsFilterVisible(value);

    const handleGoToCreateSaleNote = () => navigate({ pathname: 'novo', search: window.location.search });

    const cardsMapFn = (card, index) => <TotalsCardTaxes key={index} index={index} item={card} />;

    return (
        <div>
            <PageHeader
                title="Notas de Vendas"
                className="mb-6"
                right={
                    <div className="flex items-center gap-4">
                        <div className="flex justify-end">
                            <Tooltip title="Adicionar Nota de Venda" placement="left">
                                <IconButton
                                    color="secondary"
                                    size="large"
                                    sx={{ backgroundColor: palette.secondary.main, '&:hover': { backgroundColor: palette.secondary.dark } }}
                                    onClick={handleGoToCreateSaleNote}>
                                    <SvgIcoPlus width={16} height={16} color={palette.grey[100]} />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <Button
                            color="inherit"
                            variant="outlined"
                            type="button"
                            size="medium"
                            className="bg-white"
                            onClick={handleToggleFilter(true)}
                            endIcon={<SvgIcoFilter width={17} height={17} color="currentColor" />}>
                            Filtrar
                        </Button>
                    </div>
                }
            />
            <div className="mt-4 mb-8 grid grid-cols-1 gap-4 md:grid-cols-3">
                {totalsCardsWithData.totals.map((card, index) => (
                    <CardsDetails variant="list" key={index} {...card} withIcon={true} />
                ))}
            </div>
            <ListPage {...listConfig} className="mb-8" hideHeader={true} page={page} hideCreateButton={true} queriesToInvalidate={[getAllSalesReceiptsSumsKeys(whereClause)]} />
            <div className="flex w-full bg-white rounded-2xl shadow-lg p-4 my-4 divide-x divide-gray-200">{totalsCardsWithData.taxes.map(cardsMapFn)}</div>
            <div className="w-[50%] mx-auto my-4 justify-center bg-white rounded-2xl shadow-lg p-4 flex divide-x divide-gray-200">{totalsCardsWithData.withhelds.map(cardsMapFn)}</div>
            {isFilterVisible && <Filter onClose={handleToggleFilter(false)} />}
        </div>
    );
};

export default SalesNotesListPage;
