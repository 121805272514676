import { ReportsContextProvider } from '../../context';
import ContractsReportContent from './content';

const ContractsReportPage = () => {
    return (
        <ReportsContextProvider>
            <ContractsReportContent />
        </ReportsContextProvider>
    );
};

export default ContractsReportPage;
