import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import { getSchedulesKey } from './keys';
import { ScheduleUpdateStatusPayload } from 'types/models/schedule';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';

const useUpdateScheduleStatus = (scheduleId?: string, filters?: string) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const goToParentRoute = useNavigateToParentRoute();

    const request = async (payload: ScheduleUpdateStatusPayload) => api.patch(`/schedules/${scheduleId}/status`, payload);

    return useMutation(request, {
        onSuccess: () => {
            showToast(`Status do agendamento atualizado com sucesso`, 'success');

            goToParentRoute();

            queryClient.invalidateQueries(getSchedulesKey(filters));
        },
        onError: (error: any) => {
            const errorMessage = error?.response?.data?.title || 'Ocorreu um erro ao atualizar o status do agendamento. Por favor, tente novamente!';
            showToast(errorMessage, 'danger');
        },
    });
};

export default useUpdateScheduleStatus;
