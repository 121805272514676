import { jsonToGraphQLQuery, VariableType } from 'json-to-graphql-query';
import { CreateOrUpdateQueryConfig, CrudDetails, GraphqlPaginationVariables, GraphqlQueryPayload } from 'types/graphql';
import { pkColumnsKey } from 'utils/environment';

export const getAllKey = (table: string, variables?: GraphqlPaginationVariables<any>) => ['crud', 'all', table, JSON.stringify(variables || {})].filter(Boolean);
export const getDetailsKey = (queryConfig: CrudDetails, id?: string | null) => ['crud', 'details', queryConfig, id];

const generateQueryObject = (term: string) => {
    const keys = term.split('.');

    const [, ...rest] = keys;

    return keys.join(' {\n') + '\n' + (rest || []).map(() => `}`).join('\n');
};

const generateGetAllQuery = ({ columns, table }: GraphqlQueryPayload) => {
    const arr =
        columns?.map((item) => {
            if (item.name.includes('.')) {
                return generateQueryObject(item.name);
            }

            return item.name;
        }) || [];

    const queryName = `Get${table.charAt(0).toUpperCase()}${table.slice(1)}s`;

    const tableWithFirstChatUpper = table.charAt(0).toUpperCase() + table.slice(1);

    return `query ${queryName} ($orderBy: [${tableWithFirstChatUpper}OrderBy!], $where: ${tableWithFirstChatUpper}BoolExp, $limit: Int, $offset: Int) {
        items: ${table} (where: $where, orderBy: $orderBy, limit: $limit, offset: $offset) {
            ${arr.join('\n')}
        }
        pagination: ${table}Aggregate (where: $where) {
            aggregate {
              count
            }
        }
    }`;
};

const generateGetDetailsQuery = ({ fields, table }: CrudDetails) => {
    const arr =
        fields?.map((name) => {
            if (name.includes('.')) {
                return generateQueryObject(name);
            }

            return name;
        }) || [];

    const queryName = `Get${table?.charAt(0).toUpperCase()}${table?.slice(1)}Details`;

    return `query ${queryName}($id: Int!) {
        item: ${table}ByPk(id: $id) {
            ${arr.join('\n')}
        }
      }`;
};

const generateDeleteQuery = (table: string, withSlug = false) => {
    let params = `status: -1`;

    if (withSlug) {
        params += `\n slug: $slug`;
    }

    const tableWithFirstChatUpper = table.charAt(0).toUpperCase() + table.slice(1);

    return `mutation deleteItem($id: Int!, $slug: String) {
        item: update${tableWithFirstChatUpper}ByPk(${pkColumnsKey}: {id: $id}, _set: {${params}}) {
          id
          status
        }
    }`;
};

const generateCreateOrUpdateQuery = ({ create, queryName, update, id }: CreateOrUpdateQueryConfig) => {
    return jsonToGraphQLQuery(
        {
            mutation: {
                __name: queryName,
                __variables: {
                    ...(!!id
                        ? {
                              payload: update.payloadType,
                              id: 'Int!',
                          }
                        : {
                              payload: create.payloadType,
                          }),
                },
                item: {
                    __aliasFor: !!id ? update.mutation : create.mutation,
                    __args: {
                        ...(!!id
                            ? {
                                  [pkColumnsKey]: { id: new VariableType('id') },
                                  _set: new VariableType('payload'),
                              }
                            : {
                                  object: new VariableType('payload'),
                              }),
                    },
                    id: true,
                },
            },
        },
        { pretty: true }
    );
};

const crudQueries = {
    getAll: generateGetAllQuery,
    delete: generateDeleteQuery,
    getDetails: generateGetDetailsQuery,
    createOrUpdate: generateCreateOrUpdateQuery,
};

export default crudQueries;
