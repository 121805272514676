import { createContext, PropsWithChildren, useContext } from 'react';

type State = { isSidebarSmall: boolean; handleToggleSidebar(value: boolean): void };

const Context = createContext<State>({ isSidebarSmall: false, handleToggleSidebar: () => {} });

export const MainContextProvider = ({ children, ...values }: PropsWithChildren<State>) => {
    return <Context.Provider value={values}>{children}</Context.Provider>;
};

export const useMainContext = () => {
    return useContext(Context);
};
