import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { memo, useMemo } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import General from './general';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { dateLowerThanHigherProvisionType, purchaseOrderExpenseSchema } from './utils';
import Products from './products';
import Installments from './installments';
import { ExpenseNature } from 'types/models/expense';
import Documents from './documents';
import sumBy from 'lodash/sumBy';
import { formatDate, getHighestDate } from 'utils/date';
import useCrudExpense from 'services/queries/expenses/use-crud-expense';
import { useQueryClient } from 'react-query';
import { GraphqlPaginationVariables } from 'types/graphql';
import { PurchaseOrderExpensePayload } from 'types/models/purchase-order';
import FormButtons from 'components/form-buttons';
import QueryString from 'qs';
import { EntryBuyProductsPayload } from 'pages/private/sales-orders/service-order/purchase-order/entry';
import dayjs, { Dayjs } from 'dayjs';
import useGetPurchaseOrderProducts from 'services/queries/purchase-order/use-get-purchase-order-products';
import Alert from '@mui/material/Alert';
import { getAllPurchaseOrderProductsApiKey } from 'services/queries/purchase-order/keys';
import { getAllKey } from 'services/queries/crud/keys';

type CreateOrUpdateSaleOrderProps = {
    title: string;
    nature: ExpenseNature;
};

export type PurchaseOrderExpensesParams = {
    companyId?: string;
    purchaseProductsIds?: string[];
    purchaseOrderProducts?: EntryBuyProductsPayload['purchaseOrderProducts'];
};

type RouteParams = { listEndpoint?: string } & GraphqlPaginationVariables<any>;

const redirectSuccessPath = -1;

const PurchaseOrderExpensesCreateOrUpdatePage = ({ title = 'Nova despesa', nature }: CreateOrUpdateSaleOrderProps) => {
    const queryClient = useQueryClient();
    const { purchaseOrderId } = useParams();
    const params = useOutletContext<RouteParams>();
    const queryParams: PurchaseOrderExpensesParams = QueryString.parse(window.location.search, { ignoreQueryPrefix: true });

    const { data: purchaseOrderProducts = [], isLoading: isLoadingProducts } = useGetPurchaseOrderProducts(queryParams.purchaseProductsIds);

    const { mutateAsync: createOrUpdateExpanse, isLoading: isSubmitting } = useCrudExpense(undefined, purchaseOrderId, redirectSuccessPath);

    const highestProductProvision = useMemo(() => {
        const dates: Dayjs[] = [];

        for (const expenseProduct of purchaseOrderProducts) {
            const date = dayjs(expenseProduct.provision);

            if (date.isValid()) {
                dates.push(date);
            }
        }

        return getHighestDate(dates)?.toISOString();
    }, [purchaseOrderProducts]);

    const methods = useForm<PurchaseOrderExpensePayload>({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        shouldFocusError: true,
        defaultValues: {
            nature: Number(nature) || ExpenseNature.PurchaseOrder,
            instalments: 0,
            documentNumber: '',
            title: '',
            products: [],
            company: +queryParams.companyId!,
            competence: dayjs().toISOString(),
            transactions: [],
        },
        resolver: yupResolver(purchaseOrderExpenseSchema(highestProductProvision)) as any,
    });

    const hasProvisioningError = useMemo(() => {
        //@ts-ignore
        const hasInstallmentsProvisioningError = (methods.formState.errors?.transactions || []).some((item) => item.provisioning?.type === dateLowerThanHigherProvisionType);
        const hasProvisionError = methods.formState.errors.provision?.type === dateLowerThanHigherProvisionType;

        return hasInstallmentsProvisioningError || hasProvisionError;
    }, [methods.formState.errors.transactions, methods.formState.errors.provision]);

    const submit = async (data: PurchaseOrderExpensePayload) => {
        const { competence, ...rest } = data;

        const [competenceMonth, competenceYear] = formatDate(competence, 'MM/YYYY', null)?.split('/') || [];

        try {
            const payload = {
                ...rest,
                nature: Number(nature) || ExpenseNature.PurchaseOrder,
                dueDate: formatDate(data.dueDate, 'YYYY-MM-DD', null),
                provision: formatDate(data.provision, 'YYYY-MM-DD', null),
                formFavored: {
                    [data.formFavored?.type || 'person']: data.formFavored?.value,
                    ...(Boolean(data.favoredBankAccount) && { favoredBankAccount: data.favoredBankAccount }),
                },
                value: sumBy(data.products, ({ quantity, value }) => quantity * value),
                products: data.products.map((item) => ({
                    product: item.productId,
                    purchaseOrderProduct: item.purchaseOrderProduct,
                    grossValue: item.value,
                    quantity: item.quantity,
                    justification: item.justification,
                })),
                documents: data.documents,
                ...(data.instalments > 1 && {
                    transactions: data.transactions.map((item, index) => ({
                        order: index + 1,
                        description: `Parcela ${index + 1}`,
                        dueDate: formatDate(item.dueDate, 'YYYY-MM-DD', null),
                        paymentForecast: formatDate(item.provisioning, 'YYYY-MM-DD', null),
                        grossValue: Number(item.value.toFixed(2)),
                        provisioned: item.provisioned,
                        paymentForm: item.paymentForm,
                    })),
                }),
                ...(Boolean(competenceMonth) &&
                    Boolean(competenceYear) && {
                        competenceMonth: Number(competenceMonth),
                        competenceYear: Number(competenceYear),
                    }),
            };

            await createOrUpdateExpanse(payload as any);

            if (!!params?.listEndpoint) {
                queryClient.invalidateQueries(getAllPurchaseOrderProductsApiKey(params.listEndpoint));
            }

            queryClient.invalidateQueries(getAllKey('purchaseOrderProduct', params));
        } catch (error) {
            console.log('submit', error);
        }
    };

    return (
        <Modal
            contentClassnames="min-w-[90%]"
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    {title}
                </Text>
            }
            closeOnClickOutside={false}>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(submit)}>
                    <General />
                    <Products
                        isLoadingPurchaseOrderProducts={isLoadingProducts}
                        purchaseOrderProducts={purchaseOrderProducts}
                        queryPurchaseOrderProducts={queryParams.purchaseOrderProducts}
                        purchaseProductsIds={queryParams.purchaseProductsIds}
                    />
                    <Installments />
                    <Documents />
                    {hasProvisioningError && (
                        <div className="mb-4 px-6">
                            <Alert severity="warning">
                                <Text as="p" className="mb-1">
                                    Esta despesa contém campos com datas inferiores do que a maior data de provisão dos produtos
                                </Text>
                            </Alert>
                        </div>
                    )}
                    <FormButtons isLoading={isSubmitting} containerClassName="p-6 pt-0" />
                </form>
            </FormProvider>
        </Modal>
    );
};

export default memo(PurchaseOrderExpensesCreateOrUpdatePage);
