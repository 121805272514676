import * as React from 'react';
const SvgIcoTravel = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M6.55.75c-.583 0-1.142.23-1.555.642-.413.411-.645.97-.645 1.553v.694H2.2c-1.212 0-2.2.98-2.2 2.194v7.223c0 1.215.988 2.195 2.2 2.195h2.882a1.033 1.033 0 0 0 .035 0H13.8c1.212 0 2.2-.98 2.2-2.195V5.833a2.197 2.197 0 0 0-2.2-2.194h-2.15v-.694c0-.583-.232-1.142-.645-1.553A2.202 2.202 0 0 0 9.449.75h-2.9Zm3.6 2.889v-.694a.692.692 0 0 0-.205-.49.703.703 0 0 0-.496-.205h-2.9a.703.703 0 0 0-.495.204.692.692 0 0 0-.204.49v.695h4.3Zm-4.3 1.5h4.3v8.611h-4.3V5.14Zm-1.5 0H2.2c-.39 0-.7.313-.7.694v7.223c0 .38.31.695.7.695h2.15V5.139Zm7.3 8.611V5.14h2.15c.388 0 .699.313.699.694v7.223c0 .38-.31.695-.7.695h-2.15Z"
            clipRule="evenodd"
        />
    </svg>
);
export default SvgIcoTravel;
