import dictionary from 'utils/dictionary';
import { number, object, string } from 'yup';

export const personContractStatusFormSchema = {
    promotion: {
        position: number().typeError(dictionary.validation.required).required(dictionary.validation.required),
        startDate: string().typeError(dictionary.validation.required).required(dictionary.validation.required),
        salary: object({
            formattedValue: string(),
            value: string(),
            floatValue: number(),
        }),
    },
    resignation: {
        finalDate: string().typeError(dictionary.validation.required).required(dictionary.validation.required),
        earlyWarningStartDate: string().typeError(dictionary.validation.required).required(dictionary.validation.required),
        earlyWarningFinalDate: string().typeError(dictionary.validation.required).required(dictionary.validation.required),
    },
    situation: {
        status: number().typeError(dictionary.validation.required).required(dictionary.validation.required),
        startDate: string().typeError(dictionary.validation.required).required(dictionary.validation.required),
    },
    reactivate: {
        startDate: string().typeError(dictionary.validation.required).required(dictionary.validation.required),
    },
};
