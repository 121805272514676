import { useState } from 'react';
import NavItem, { menuItemStyles, subItemClasses } from './item';
import useCredentials from 'store/credentials/use-credentials';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import SvgIcoHome from 'components/core/icon/files/ico-home';

type NavigationProps = {
    isSidebarSmall: boolean;
};

const Nav = ({ isSidebarSmall }: NavigationProps) => {
    const activeHook = useState(-1);

    const { credentials } = useCredentials();

    const handleNavLinkClasses = ({ isActive }) => classNames(subItemClasses, menuItemStyles.subItemText, isActive ? 'active-link' : null);

    const handleCloseSubNavs = () => {
        const [, setActiveHookState] = activeHook;

        setActiveHookState(-1);
    };

    return (
        <ul>
            <li title="Página inicial">
                <NavLink end={true} to="/app" className={handleNavLinkClasses} onClick={handleCloseSubNavs}>
                    <span className={menuItemStyles.tick} />
                    <div className={`w-[100%] flex ${isSidebarSmall ? 'justify-center' : ''}`}>
                        <SvgIcoHome className={`transition-[color] ${!isSidebarSmall ? 'mr-4' : ''}`} width={16} height={16} />
                        {!isSidebarSmall && 'Página inicial'}
                    </div>
                </NavLink>
            </li>
            {credentials.menu.map((item, index) => {
                return <NavItem isSidebarSmall={isSidebarSmall} activeHook={activeHook} index={index} item={item} key={index} />;
            })}
        </ul>
    );
};

export default Nav;
