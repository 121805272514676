import { forwardRef } from 'react';
import { NumberFormatBase, NumericFormat, NumericFormatProps, OnValueChange, PatternFormatProps } from 'react-number-format';
import Input from '../input';
import classNames from 'classnames';
import { Modify } from 'types/general';

type CurrencyInputType = {
    placeholder?: string;
    label?: string;
    error?: string;
    inputWrapperClasses?: string;
    leftClasses?: string;
    parentClassName?: string;
    left?: JSX.Element;
    right?: JSX.Element;
    rightClasses?: string;
    showErrorMessage?: boolean;
    withFormat?: boolean;
} & NumericFormatProps &
    PatternFormatProps;

type CurrencyInputProps = Modify<
    CurrencyInputType,
    {
        dangerouslySetInnerHTML?: { __html: string };
        format?: string;
    }
>;

const currencyFormatter = (value) => {
    // if (!Number(value)) {
    //     return '0';
    // }

    const amount = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value / 100);

    return amount.replace('R$', '');
};

const CurrencyInput = forwardRef<HTMLInputElement, CurrencyInputProps>(
    ({ left, leftClasses, right, rightClasses, withFormat = true, decimalScale, decimalSeparator, fixedDecimalScale, ...props }, ref) => {
        const leftClassNames = classNames('bg-base-200 px-4', leftClasses);
        const rightElementClasses = classNames('flex items-center', rightClasses);

        if (!withFormat) {
            return (
                <NumericFormat
                    {...props}
                    decimalScale={decimalScale}
                    decimalSeparator={decimalSeparator}
                    fixedDecimalScale={fixedDecimalScale}
                    getInputRef={ref}
                    customInput={Input}
                    leftClasses={leftClassNames}
                    rightClasses={rightElementClasses}
                    left={left}
                    right={right}
                />
            );
        }

        const handleValueChange: OnValueChange = (values, source) => {
            props.onValueChange?.({ ...values, floatValue: (values.floatValue || 0) / 100 }, source);

            // const target = source.event?.target as any;
            // const value = target?.value;
            // const selection = (value?.trim?.().length || 0) + 1;

            // target?.setSelectionRange?.(selection, selection);
        };

        return (
            <NumberFormatBase
                {...props}
                value={Number(props.value || 0) * 100}
                format={currencyFormatter}
                getInputRef={ref}
                customInput={Input}
                leftClasses={leftClassNames}
                rightClasses={rightElementClasses}
                left={left}
                right={right}
                onValueChange={handleValueChange}
            />
        );
    }
);

export default CurrencyInput;
