import { useCallback, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import Text from 'components/core/text';
import { Controller, useForm } from 'react-hook-form';
import dictionary from 'utils/dictionary';
import { object, ref, string } from 'yup';
import theme from 'settings/theme';
import { IconButton } from '@mui/material';
import Input from 'components/core/form/input';
import Button from 'components/core/button';
import SvgIcoShow from 'components/core/icon/files/ico-show';
import SvgIcoHide from 'components/core/icon/files/ico-hide';

export type UserResetPasswordPayload = {
    password: string;
    newPassword: string;
    confirmPassword: string;
};

type ResetUserPasswordFormProps = {
    onSubmit: (data: UserResetPasswordPayload) => void;
    isSubmitting: boolean;
};

const ResetUserPasswordForm = ({ onSubmit, isSubmitting }: ResetUserPasswordFormProps) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState({
        password: false,
        newPassword: false,
        confirmPassword: false,
    });

    const schema = object({
        password: string().required(dictionary.validation.required),
        newPassword: string().required(dictionary.validation.required),
        confirmPassword: string()
            .required(dictionary.validation.required)
            .oneOf([ref('newPassword')], dictionary.validation.equal),
    });

    const handlePasswordVisibility = useCallback((stateToChange: string) => {
        setIsPasswordVisible((state) => {
            return { ...state, [stateToChange]: !state[stateToChange] };
        });
    }, []);

    const Toggle = useMemo(
        () => (stateKey: string) => {
            const Icon = isPasswordVisible[stateKey] ? SvgIcoShow : SvgIcoHide;

            return (
                <IconButton color="primary" className="p-1.5 m-1.5" onClick={() => handlePasswordVisibility(stateKey)}>
                    <Icon color={theme.extend.colors.primary[500]} width={16} height={16} />
                </IconButton>
            );
        },
        [isPasswordVisible, handlePasswordVisibility]
    );

    const { control, formState, handleSubmit } = useForm({
        mode: 'onSubmit',
        defaultValues: {
            password: '',
            newPassword: '',
            confirmPassword: '',
        },
        resolver: yupResolver(schema),
    });

    return (
        <form className="w-full rounded-t-2xl p-7 sm:rounded-b-2xl sm:p-20 xl:max-w-2xl" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-7">
                <Text as="h1" variant="h1" className="text-heading text-center">
                    Alterar senha
                </Text>
            </div>
            <div className="w-full mb-6">
                <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                        <Input {...field} label="Senha atual" type={isPasswordVisible['password'] ? 'text' : 'password'} error={formState.errors.password?.message} right={Toggle('password')} />
                    )}
                />
            </div>
            <div className="w-full mb-6">
                <Controller
                    name="newPassword"
                    control={control}
                    render={({ field }) => (
                        <Input
                            {...field}
                            label="Nova senha"
                            type={isPasswordVisible['newPassword'] ? 'text' : 'password'}
                            error={formState.errors.newPassword?.message}
                            right={Toggle('newPassword')}
                        />
                    )}
                />
            </div>
            <div className="w-full mb-6">
                <Controller
                    name="confirmPassword"
                    control={control}
                    render={({ field }) => (
                        <Input
                            {...field}
                            label="Confirmação de senha"
                            type={isPasswordVisible['confirmPassword'] ? 'text' : 'password'}
                            error={JSON.stringify((formState.errors.confirmPassword?.message as any)?.password)}
                            right={Toggle('confirmPassword')}
                        />
                    )}
                />
            </div>
            <div className="w-full">
                <Button type="submit" loading={isSubmitting} size="medium" variant="contained" color="primary" fullWidth={true}>
                    Alterar senha
                </Button>
            </div>
        </form>
    );
};

export default ResetUserPasswordForm;
