import { useState } from 'react';
import { MenuItem } from 'components/menu';
import DatatableToolbarActions from 'pages/private/sales-orders/service-order/purchase-order/actions';

type CustomToolbarSelectProps = {
    content: MenuItem[];
    footer?: MenuItem[];
};

/**
 * Custom component to help you with customToolbarSelect
 */
const DatatableToolbar = ({ content, footer }: CustomToolbarSelectProps) => {
    const toolbarAnchor = useState<HTMLElement | null>(null);

    return <DatatableToolbarActions toolbarAnchor={toolbarAnchor} content={content} footer={footer} />;
};

export default DatatableToolbar;
