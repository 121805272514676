import { forwardRef, memo, MutableRefObject, useEffect } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import Input from 'components/core/form/input';
import Select from 'components/core/form/select';
import { Option } from 'types/general';
import { PostmonAddress } from 'types/models/address';

type FormProps = {
    cities: Option[];
    states: Option[];
    onGetCity: (name: string) => void;
    onGetState: (name: string) => void;
    onSelectState: (value: number) => void;
    city?: number;
    className?: string;
    state?: number;
} & UseFormReturn<any, any>;

const Form = forwardRef<HTMLInputElement, FormProps>(({ city, cities, className, state, states, onGetCity, onGetState, onSelectState, ...props }, zipRef) => {
    const { control, formState, setValue } = props;

    const handleGetAddress = async (event: any) => {
        const zip = event.target.value;

        setValue('address.zip', zip);

        const regx = /^\d{5}-\d{3}$/;

        if (regx.test(zip)) {
            try {
                const response = await fetch(`https://api.postmon.com.br/v1/cep/${zip}`);

                const data: PostmonAddress = await response.json();

                setValue('address.neighbourhood', data.bairro);
                setValue('address.street', data.logradouro);

                onGetState(data.estado_info.nome);
                onGetCity(data.cidade);

                const findedItem = states.find((item) => item.label === data.estado_info.nome);

                if (findedItem) {
                    setValue('address.stateId', findedItem.value);
                    onSelectState(findedItem.value);
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const handleSelectState = (onChange) => (option: any) => {
        onSelectState(option.value);

        setValue('address.zip', '');
        setValue('address.neighbourhood', '');
        setValue('address.street', '');

        onChange(option.value);
    };

    useEffect(() => {
        if (state) {
            setValue('address.stateId', state);
        }
    }, [state, setValue]);

    useEffect(() => {
        if (Boolean(cities.length) && !!city) {
            setValue('address.cityId', city);
        }
    }, [cities, city, setValue]);

    return (
        <div className={`md:grid md:grid-cols-4 gap-4 ${className}`}>
            <Controller
                name="address.zip"
                control={control}
                render={({ field }) => (
                    <Input
                        {...field}
                        ref={(el) => {
                            if (!!zipRef) {
                                (zipRef as MutableRefObject<HTMLInputElement | null>).current = el;
                            }

                            field.ref(el);
                        }}
                        autoComplete="nope"
                        type="text"
                        mask="99999-999"
                        label="CEP"
                        error={(formState as any).errors.address?.zip?.message}
                        onBlur={handleGetAddress}
                    />
                )}
            />
            <Controller
                name="address.stateId"
                control={control}
                render={({ field }) => {
                    const value = states.find((item) => item.value === field.value);

                    return (
                        <Select
                            {...field}
                            value={value}
                            options={states}
                            label="Estado"
                            placeholder="Selecione uma opção"
                            error={(formState as any).errors.address?.stateId?.message}
                            onChange={handleSelectState(field.onChange)}
                        />
                    );
                }}
            />
            <Controller
                name="address.cityId"
                control={control}
                render={({ field }) => {
                    const value = cities.find((item) => item.value === field.value);

                    return (
                        <Select
                            {...field}
                            value={value}
                            options={cities}
                            label="Cidade"
                            placeholder="Selecione uma opção"
                            error={(formState as any).errors.address?.cityId?.message}
                            onChange={(option: any) => field.onChange(option.value)}
                        />
                    );
                }}
            />
            <Controller
                name="address.neighbourhood"
                control={control}
                render={({ field }) => <Input {...field} autoComplete="nope" label="Bairro" error={(formState as any).errors.address?.neighbourhood?.message} />}
            />
            <Controller
                name="address.street"
                control={control}
                render={({ field }) => <Input {...field} parentClassName="col-span-2" autoComplete="nope" label="Logradouro" error={(formState as any).errors.address?.street?.message} />}
            />
            <Controller name="address.number" control={control} render={({ field }) => <Input {...field} label="Número" error={(formState as any).errors.address?.number?.message} />} />
            <Controller name="address.compliment" control={control} render={({ field }) => <Input {...field} label="Complemento" error={(formState as any).errors.address?.compliment?.message} />} />
        </div>
    );
});

export default memo(Form);
