import { useQuery } from 'react-query';
import { Option } from 'types/general';
import apiGraphQl from 'services/graphql';
import { Pagination } from 'types/pagination';
import peopleQueries, { getAllPersonContractStatusOptionsKey } from './keys';

const useGetPersonContractStatusOptions = (enabled = false) => {
    const getPersonContractStatusOptions = () => apiGraphQl<Pagination<Option>>(peopleQueries.getAllPersonContractStatusOptions).then((data) => data.items);

    return useQuery(getAllPersonContractStatusOptionsKey, getPersonContractStatusOptions, { enabled });
};

export default useGetPersonContractStatusOptions;
