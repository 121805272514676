import { useEffect } from 'react';
import Login from './login';
import useAuth from 'store/auth/use-auth';
import { AuthPayload } from 'types/models/user';
import useAuthMutation from 'services/queries/auth/use-auth';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
    const { auth } = useAuth();
    const navigate = useNavigate();
    const { isLoading, mutateAsync } = useAuthMutation();

    const handleLogin = async (data: AuthPayload) => {
        try {
            const payload = {
                password: data.password.trim(),
                username: data.username.trim(),
            };

            await mutateAsync(payload);
        } catch (error) {
            console.log('error', error);
        }
    };

    useEffect(() => {
        if (auth.isAuthenticated) {
            navigate('/app');
        }
        // eslint-disable-next-line
    }, []);

    return <Login isLoading={isLoading} onSubmit={handleLogin} />;
};

export default LoginPage;
