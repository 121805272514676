import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';
import useToast from 'hooks/toast/use-toast';
import { ManagePurchaseProduct } from 'pages/private/sales-orders/service-order/purchase-scope/manage-products';
import { useMutation } from 'react-query';
import api from 'services/api';
import { Modify } from 'types/general';

export type ValidateProductsPayload = {
    purchaseProducts: Modify<
        ManagePurchaseProduct,
        {
            product?: number;
        }
    >[];
};

const useValidateProducts = (action: 'validate' | 'approve', saleOrderId?: string, orderServiceId?: string) => {
    const { showToast } = useToast();
    const goToParentRoute = useNavigateToParentRoute();

    const request = async (payload: ValidateProductsPayload) => api.patch(`/sale-orders/${saleOrderId}/service-orders/${orderServiceId}/purchase-products/${action}`, payload);

    return useMutation(request, {
        onSuccess: () => {
            showToast('Escopo validado com sucesso', 'success');

            goToParentRoute();
        },
        onError: () => {
            showToast('Ocorreu um erro ao validar o escopo', 'danger');
        },
    });
};

export default useValidateProducts;
