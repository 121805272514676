import Spinner from 'components/core/spinner';
import Empty from 'components/empty';
import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useGetPerson from 'services/queries/people/use-get-person';
import useBreadcrumb from 'store/breadcrumb/use-breadcrumb';
import PersonDetailsScreen from './view';

const PeoplePage = ({ page }: WithPagePermissionsProps) => {
    const { personId } = useParams();
    const { data, error, isLoading } = useGetPerson(personId, true);

    const subpages = useMemo(() => page?.subpages || [], [page]);

    useBreadcrumb(data?.name);

    if (isLoading) {
        return (
            <div className="relative w-full h-full">
                <Spinner />
            </div>
        );
    }

    if (Boolean(error) || !data) {
        return <Empty title="Pessoa não encontrada" />;
    }

    return <PersonDetailsScreen subpages={subpages} person={data} />;
};

export default PeoplePage;
