import TableCell from '@mui/material/TableCell/TableCell';
import { CreateOrUpdateProps } from 'pages/shared/crud/create-or-update';
import { Status } from 'types/general';
import { CrudPageProps } from 'types/graphql';
import { ClassificationType } from 'types/models/classification';
import dictionary from 'utils/dictionary';
import { object, string } from 'yup';
import { createOrUpdateClassificationMutation } from '../shared/util-classification';

const { required } = dictionary.validation;

const schema = object({
    name: string().required(required),
});

type Payload = {
    name: string;
};

const form: CreateOrUpdateProps<Payload> = {
    modalClassName: 'w-[500px]',
    formClassName: '!grid-cols-1 ',
    fields: [
        {
            name: 'name',
            type: 'text',
        },
    ],
    beforeSend: (data) => ({ ...data, type: ClassificationType.ProductCategory }),
    schema,
};

export const configProductCategoryClassification: CrudPageProps<Partial<Payload>>[] = [
    {
        title: 'Categorias de produto',
        graphql: {
            table: 'classification',
            searchableField: 'name',
            columns: [
                {
                    name: 'id',
                    options: { display: 'excluded' },
                },
                {
                    name: 'name',
                    label: dictionary.content.graphql['name'],
                },

                {
                    name: 'actions',
                    label: 'Ações',
                    options: {
                        customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />,
                    },
                },
            ],
            where: {
                type: { _eq: ClassificationType.ProductCategory },
                status: { _eq: Status.Active },
            },
            orderBy: { name: 'ASC' },
        },
    },
    {
        title: `Nova categoria de produto`,
        graphql: {
            table: 'classification',
            query: createOrUpdateClassificationMutation(),
        },
        defaultValues: { name: '' },
        form,
    },
    {
        title: `Atualizar categoria de produto`,
        graphql: {
            table: 'classification',
            query: createOrUpdateClassificationMutation(true),
        },
        defaultValues: { name: '' },
        form,
    },
];
