import { useQuery } from 'react-query';
import api from 'services/api';
import useToast from 'hooks/toast/use-toast';
import { getOrderServicesProductsCategoriesKey } from './keys';

type Params = {
    orderServiceId?: string;
    saleOrderId?: string;
};

const useGetOrderServicesProductsCategories = (props: Params) => {
    const { orderServiceId, saleOrderId } = props || {};
    const { showToast } = useToast();

    const fetcher = async (): Promise<Response> => {
        return api.get<Response>(`/sale-orders/${saleOrderId}/service-orders/${orderServiceId}/product-categories`).then(({ data }) => data);
    };

    return useQuery(getOrderServicesProductsCategoriesKey({ orderServiceId, saleOrderId }), fetcher, {
        enabled: !!orderServiceId && !!saleOrderId,
        onError: () => {
            showToast(`Ocorreu um erro ao obter a listagem. Tente novamente.`, 'danger');
        },
    });
};

export type GetOrderServicesProductsCategoriesExpense = {
    id: number;
    expense: {
        id: number;
        code: string;
        value: string;
        title: string;
        dueDate: string;
        payday: any;
    };
    product: {
        unit: {
            id: number;
            name: string;
            abbreviation: string;
            code: any;
            type: number;
        };
        code: string;
        name: string;
        id: number;
    };
    purchaseOrderProduct: {
        purchaseOrderProductExtra: any;
        id: number;
        purchaseOrder: {
            id: number;
            code: string;
        };
        classification: {
            id: number;
            name: string;
            abbreviation: any;
            code: any;
            type: number;
        };
        quantity: number;
        purchaseProductStatus: number;
    };
    quantity: number;
    justification?: string;
    grossValue: number;
    netValue: number;
    discount: any;
    addition: any;
};

type Response = Array<{
    id: number;
    name: string;
    total: string;
    totalPaid: string;
    expenses: Array<GetOrderServicesProductsCategoriesExpense>;
}>;

export default useGetOrderServicesProductsCategories;
