const dictionary = {
    validation: {
        required: 'Campo obrigatório',
        invalid: {
            email: 'E-mail inválido',
        },
        equal: {
            password: 'As senhas não são iguais',
        },
        integer: 'O valor precisa ser do tipo número',
        date: {
            min: 'Data final menor que inicial',
            max: '',
        },
        number: {
            min: (value: number) => `O valor mínimo permitido é ${value}`,
            max: (value: number) => `O valor máximo permitido é ${value}`,
            moreThan: (value: number) => `O valor deve ser maior que ${value}`,
        },
        string: {
            min: (value: number) => `O valor deve ter no mínimo ${value} caractere(s)`,
            max: (value: number) => `O valor deve ter no máximo ${value} caractere(s)`,
        },
        array: {
            min: (value: number) => `É necessário ao menos ${value} item`,
            max: (value: number) => `É necessário no máximo ${value} item`,
        },
        type: (type: string) => `O valor inserido deve ser do tipo ${type}`,
    },
    content: {
        graphql: {
            abbreviation: 'Abreviação',
            code: 'Código',
            title: 'Título',
            'customer.title': 'Cliente',
            'branch.name': 'Unidade',
            'projectStatus.title': 'Status',
            status: 'Status',
            name: 'Nome',
            initials: 'Iniciais',
            permalink: 'Link',
            slug: 'Slug',
            email: 'E-mail',
            username: 'Nome de usuário',
            'profile.name': 'Perfil',
            juridicName: 'Nome jurídico',
            juridicFantasyName: 'Nome fantasia',
            juridicDocument: 'CNPJ',
            maritalStatus: 'Estado civil',
            document: 'CPF',
            birthday: 'Data de nascimento',
            gender: 'Gênero',
            phones: 'Telefones',
            'types.projectType.title': 'Tipo de projeto',
            startDate: 'Data inicial',
            finalDate: 'Data final',
            classification: 'Classificação',
            partner: 'Parceiro',
            salary: 'Salário',
            file: 'Arquivo',
            observations: 'Observações',
            keyRule: 'Chave',
            controller: 'Controller',
            'subpages.page.title': 'Subpáginas',
            icon: 'Ícone',
            orderMenu: 'Ordem',
            serialNumber: 'Número de série',
            description: 'Descrição',
            payday: 'Data de pagamento',
            dueDate: 'Data de vencimento',
            value: 'Valor',
            number: 'Código',
            subject: 'Assunto',
            person: 'Criador',
            profile: 'Perfil',
            'companyPartnersAggregate.aggregate.count': 'sócios',
            type: 'Tipo',
            agency: 'Agência',
            account: 'Conta',
            pix: 'PIX',
            pixType: 'Tipo chave PIX',
            bankName: 'Banco',
            bank: 'Banco',
            approved: 'Aprovado',
            unit: 'Unidade',
            category: 'Categoria',
            contractDate: 'Data do contrato',
            'customer.juridicFantasyName': 'Cliente',
            taxRegime: 'Regime Tributário',
            sector: 'Setor',
            origin: 'Origem',
            subOrigin: 'Sub Origem',
        },
        datatable: {
            body: {
                noMatch: 'Nenhum resultado encontrado.',
                toolTip: 'Ordenar',
            },
            pagination: {
                next: 'Próxima',
                previous: 'Anteiror',
                rowsPerPage: 'Registros por página:',
                displayRows: 'de',
            },
            selectedRows: {
                delete: 'Apagar',
                deleteAria: 'Apagar linhas selecionadas',
                text: 'linha(s) selecionada(s)',
            },
            filter: {
                all: 'TODOS',
                reset: 'RESETAR',
                title: 'FILTROS',
            },
            viewColumns: {
                title: 'Exibir colunas',
                titleAria: 'Exibir/esconder colunas',
            },
            toolbar: {
                filterTable: 'Abrir filtros',
                print: 'Imprimir',
                search: 'Buscar',
                viewColumns: 'Mostrar/ocultar',
            },
        },
        managerResponsibility: 'Responsabilidade de gerenciamento',
        objectives: 'Objetivos',
        premises: 'Instalações',
        restrictions: 'Restrições',
        risks: 'Riscos',
        type: 'Tipo',
        code: 'Código',
        'customer.title': 'Cliente',
        'branch.name': 'Unidade',
        'director.name': 'Responsável',
        'bank.name': 'Banco',
        genders: {
            female: 'Feminino',
            male: 'Masculino',
            other: 'Outro',
        },
        meetingRecordStatus: {
            deleted: 'Apagada',
            inactive: 'Rascunho',
            active: 'Publicada',
        },
        maritalStatus: {
            single: 'Solteiro(a)',
            married: 'Casado(a)',
            divorced: 'Divorciado(a)',
            widowed: 'Viúvo(a)',
        },
        jobSeniorities: {
            intern: 'Estagiário',
            junior: 'Júnior',
            intermediate: 'Pleno',
            senior: 'Senior',
        },
        contractTypes: {
            personal: 'Pessoa física',
            juridic: 'Pessoa jurídica',
        },
        bankAccountTypes: {
            open: 'Conta corrente',
            savings: 'Conta poupança',
        },
        person: {
            email: 'E-mail',
        },
        pixTypes: {
            phone: 'Telefone',
            email: 'E-mail',
            cpfCnpj: 'CPF/CNPJ',
            random: 'Chave aleatória',
        },
        personContractStatusTitle: {
            Active: 'Ativo',
            MaternityLeave: 'Licença Maternidade',
            SicknessAllowance: 'Auxílio Doença',
            Vacation: 'Férias',
            MedicalCertificate: 'Atestado',
            Transferred: 'Transferido',
            Finished: 'Encerrado',
            Resignation: 'Demissão',
        },
        emailTypes: {
            personal: 'Pessoal',
            professional: 'Profissional',
        },
        personContractType: {
            promotion: 'Promoção',
            resignation: 'Demissão',
            situation: 'Situação',
            reactivate: 'Reativar',
        },
        productTypes: {
            sale: 'Venda',
            purchase: 'Compra',
            both: 'Ambos',
        },
        saleOrderTypes: {
            public: 'Público',
            private: 'Privado',
            incentivizedProjects: 'Projetos incentivados',
            socialCounterparts: 'Contrapartidas sociais',
        },
        saleOrderOrigins: {
            service: 'Serviço',
            sale: 'Venda',
        },
        statuses: {
            active: 'Ativo',
            inactive: 'Inativo',
            deleted: 'Apagado',
        },
        priorities: {
            high: 'Alta',
            moderate: 'Média',
            low: 'Baixa',
        },
        purchaseProductStatuses: {
            canceled: 'Cancelado',
            waiting: 'Aguardando',
            bought: 'Comprado',
            delivered: 'Entregue',
            waitingPayment: 'Aguardando pagamento',
            finished: 'Finalizado',
            waitingDelivered: 'Aguardando entrega',
        },
        purchaseStatuses: {
            open: 'Aberta',
            processed: 'Processada',
            finished: 'Finalizada',
            canceled: 'Cancelada',
        },
        transactions: {
            income: 'Receita',
            outcome: 'Despesa',
            contribution: 'Aporte',
        },
        natures: {
            normal: 'Normal',
            purchaseOrder: 'Ordem de compra',
            stockPurchaseOrder: 'Ordem de compra do estoque',
            orderService: 'Ordem de serviço',
            saleOrder: 'Pedido de venda',
            import: 'Importação',
            purchaseOrderBilled: 'Ordem de compra faturada',
            stockTransfer: 'Transferência entre estoques',
        },
        productType: {
            sale: 'Venda',
            purchase: 'Compra',
            both: 'Ambos',
        },
        financialTypes: {
            analytic: 'Analítico',
            financial: 'Financeiro',
        },
        stockTypes: {
            default: 'Padrão',
            orderService: 'Ordem de serviço',
        },
        salesReports: {
            waiting: 'Aguardando',
            inProgress: 'Em Execução',
            completed: 'Finalizado',
        },
        salesReportsTypes: {
            public: 'Público',
            private: 'Privado',
        },
        schedules: {
            canceled: 'Cancelado',
            requested: 'Solicitado',
            delivered: 'Entregue',
        },
        approvalStatus: {
            waiting: 'Aguardando aprovação',
            approved: 'Aprovada',
            disapproved: 'Não aprovada',
        },
        stockOrderType: {
            transfer: 'Transferência',
            use: 'Uso',
            outcome: 'Saída',
            industrialization: 'Industrialização',
            import: 'Importação',
            reversal: 'Estorno',
        },
        expenseTypes: {
            fixed: 'Fixa',
            probable: 'Habitual/Provável',
            variable: 'Variável',
        },
        transactionPrintTypes: {
            notGrouped: 'Não agrupar',
            forecastDate: 'Data de previsão',
            paymentDate: 'Data de pagamento',
            dueDate: 'Data de vencimento',
            favored: 'Favorecido',
            paymentForm: 'Forma de pagamento',
        },
        transactionDocument: {
            bill: 'Conta',
            invoice: 'Fatura',
            paymentVoucher: 'Comprovante de pagamento',
        },
        formFields: {
            text: 'Texto',
            checkbox: 'Múltipla escolha',
            radio: 'Opção única',
            select: 'Opções com Autocomplete',
            selectSimple: 'Opções',
            date: 'Data',
            dateTime: 'Data e hora',
            time: 'Hora',
            integer: 'Inteiro',
            decimal: 'Número',
            checkboxWithField: 'Múltiplica escolha com campo',
            radioWithField: 'Opção única com campo',
        },
        formTypes: {
            visit: 'Visita',
        },
        taxes: {
            irpjAdditional: 'IRPJ Adicional',
            irpj: 'IRPJ',
            icms: 'ICMS',
            pis: 'PIS',
            others: 'Outros',
            ipi: 'IPI',
            csll: 'CSLL',
            inss: 'INSS',
            iss: 'ISS',
            cofins: 'COFINS',
        },
        formGroupTypes: {
            normal: 'Normal',
            conditional: 'Condicional',
        },
    },
};

export default dictionary;
