import { useCallback, useRef } from 'react';
import { DropzoneInputProps } from 'react-dropzone';
import useTheme from '@mui/material/styles/useTheme';
import Text from 'components/core/text';
import classNames from 'classnames';
import Spinner from 'components/core/spinner';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import SvgIcoUploadCloud from 'components/core/icon/files/ico-upload-cloud';
import SvgIcoClose from 'components/core/icon/files/ico-close';

type UploaderProps = {
    inputProps: DropzoneInputProps;
    svgClasses?: string;
    hasText?: boolean;
    isLoading?: boolean;
    file?: File;
    disabled: boolean;
    onClear?: () => void;
};

const Uploader = ({ inputProps, svgClasses, hasText = true, isLoading = false, disabled, file, onClear }: UploaderProps) => {
    const { palette } = useTheme();
    const inputRef = useRef<HTMLInputElement>(null);

    const svgClassnames = classNames('mb-3', svgClasses);

    const handleInputFile = useCallback(() => {
        if (!inputRef.current) {
            return;
        }

        inputRef.current?.click();
    }, [inputRef]);

    const handleClear = (e) => {
        e.stopPropagation();
        onClear?.();
    };

    return (
        <>
            <input ref={inputRef} {...inputProps} disabled={disabled} className="hidden" />
            {isLoading ? <Spinner fixed={false} size={24} /> : <SvgIcoUploadCloud width={24} height={20} color={palette.grey[500]} className={svgClassnames} />}
            {file ? (
                <>
                    <Tooltip placement="left" title="Remover arquivo" disableFocusListener={true}>
                        <button className="rounded-lg absolute right-2 top-2 p-2 hover:bg-system-danger-100 hover:bg-opacity-30" type="button" onClick={handleClear}>
                            <SvgIcoClose color={palette.error[500]} width={10} height={10} />
                        </button>
                    </Tooltip>
                    <Text as="p" variant="body.regular.sm" className="text-base-500 text-center">
                        {file.name}
                    </Text>
                </>
            ) : (
                hasText && (
                    <Text as="p" variant="body.regular.sm" className="text-base-500 text-center">
                        Arraste para cá ou{' '}
                        <button type="button" className="text-secondary-500" onClick={handleInputFile}>
                            procure
                        </button>{' '}
                        para fazer upload
                    </Text>
                )
            )}
        </>
    );
};

export default Uploader;
