import ExternalButton from 'components/buttons/external';
import Card from 'components/core/card';
import Text from 'components/core/text';
import { useOutletContext } from 'react-router-dom';
import { OrderService } from 'types/models/order-service';
import { formatDate } from 'utils/date';

type OSDataProps = {
    haveOS?: boolean;
    haveClient?: boolean;
    haveConstruction?: boolean;
};

const OSData = ({ haveConstruction = true, haveClient = true, haveOS = true }: OSDataProps) => {
    const serviceOrder = useOutletContext<Partial<OrderService> | undefined>();

    return (
        <>
            {haveConstruction && (
                <Card className="p-4 flex flex-col justify-between">
                    <Text as="h6" variant="h6" className="text-heading mb-4">
                        Dados da obra
                    </Text>
                    <div>
                        <Text className="text-base-500 mb-2 flex items-baseline">
                            <strong className="mr-1">Ordem de serviço:</strong>
                            <ExternalButton to={`/app/pedidos-de-venda/${serviceOrder?.saleOrder?.id}/ordens-de-servico/${serviceOrder?.id}`}>{serviceOrder?.code}</ExternalButton>
                        </Text>
                        <Text as="p" className="text-base-500 mb-2">
                            <strong>Previsão de início:</strong> {formatDate(serviceOrder?.startDate)}
                        </Text>
                        <Text as="p" className="text-base-500 mb-2">
                            <strong>Previsão de finalização:</strong> {formatDate(serviceOrder?.endDate)}
                        </Text>
                        <Text as="p" className="text-base-500 mb-2">
                            <strong>Data de Conclusão:</strong> {formatDate(serviceOrder?.completionDate)}
                        </Text>
                        <Text as="p" className="text-base-500 leading-5 mb-2">
                            <strong>Endereço:</strong> {serviceOrder?.address?.full || '-'}
                        </Text>
                        {Boolean(serviceOrder?.observations) && (
                            <Text as="p" className="text-base-500 leading-5">
                                <strong>Observações:</strong>
                                <div className="rich-content" dangerouslySetInnerHTML={{ __html: serviceOrder?.observations! }} />
                            </Text>
                        )}
                    </div>
                </Card>
            )}
            {haveOS && (
                <Card className="p-4 flex flex-col justify-between">
                    <Text as="h6" variant="h6" className="text-heading mb-4">
                        Dados do pedido
                    </Text>
                    <div>
                        <Text as="p" className="text-base-500 leading-5 mb-2">
                            <strong>Pedido:</strong> {serviceOrder?.saleOrder?.code || '-'}
                        </Text>
                        <Text as="p" className="text-base-500 leading-5 mb-2">
                            <strong>Empresa:</strong> {serviceOrder?.saleOrder?.company?.name || '-'}
                        </Text>
                        <Text as="p" className="text-base-500 leading-5 mb-2">
                            <strong>Engenheiro Responsável:</strong> {serviceOrder?.engineer?.title || '-'}
                        </Text>
                        <Text as="p" className="text-base-500 leading-5">
                            <strong>Empreiteiro Responsável:</strong> {serviceOrder?.contractor?.title || '-'}
                        </Text>
                    </div>
                </Card>
            )}
            {haveClient && (
                <Card className="p-4 flex flex-col justify-between">
                    <Text as="h6" variant="h6" className="text-heading mb-4">
                        Dados do cliente
                    </Text>
                    <div>
                        <Text as="p" className="text-base-500 leading-5 mb-2">
                            <strong>Cliente:</strong> {serviceOrder?.saleOrder?.customer?.title || '-'}
                        </Text>
                        <Text as="p" className="text-base-500 leading-5 mb-2">
                            <strong>E-mail:</strong> {serviceOrder?.saleOrder?.customer?.emails?.map((item) => item.email).join(',') || '-'}
                        </Text>
                        <Text as="p" className="text-base-500 leading-5 mb-2">
                            <strong>Telefone:</strong> {serviceOrder?.saleOrder?.customer?.phones?.map(({ ddd, number }) => `(${ddd}) ${number}`).join(',') || '-'}
                        </Text>
                        <Text as="p" className="text-base-500 leading-5">
                            <strong>Endereço:</strong> {serviceOrder?.saleOrder?.customer?.address?.full || '-'}
                        </Text>
                    </div>
                </Card>
            )}
        </>
    );
};

export default OSData;
