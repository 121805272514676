import { BaseModel } from './base';
import { PersonContract } from './person';

export enum ClassificationType {
    Position = 1,
    Sector,
    ProductCategory,
    ProductUnityAbbreviation,
    ProductClassification,
    SaleOrderType,
    PaymentForm,
    TransactionCategory,
    TransactionOrigin,
    TransactionSubOrigin,
    OrderServiceType,
    DocumentTransaction,
    DocumentSaleOrder,
    DocumentOrderService,
    ProductSubCategory,
    ExpenseNature,
    ExpenseFiscalCode,
}

export enum ClassificationSectorCode {
    Management = 1,
    Commercial,
    Marketing,
    OrderService,
    TopField,
}

export enum ClassificationCategoryCode {
    Commissions = '1',
    Materials = '4',
    DecorativeExpense = '2.DECOR.01',
}

export type Classification = {
    classificationId: number;
    code: ClassificationSectorCode;
    name: string;
    type: ClassificationType;
    classification: Partial<Classification>;
    classifications: Partial<Classification>[];
    father?: {
        id: number;
        name: string;
    };
    personContracts: Partial<PersonContract>[];
    personContractsBySectorId: Partial<PersonContract>[];
} & BaseModel;
