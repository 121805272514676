import { useQuery } from 'react-query';
import { getSaleOrderKey } from './keys';
import api from 'services/api';
import { SaleOrder } from 'types/models/sale-order';

const useGetSaleOrder = (saleOrderId?: string) => {
    const request = async () => {
        const { data } = await api.get<Partial<SaleOrder>>(`/sale-orders/${saleOrderId}`);

        return data;
    };

    return useQuery(getSaleOrderKey(saleOrderId), request, { enabled: !!saleOrderId });
};

export default useGetSaleOrder;
