import Table from '@mui/material/Table/Table';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableBody from '@mui/material/TableBody/TableBody';
import useTheme from '@mui/material/styles/useTheme';
import classNames from 'classnames';
import Card from 'components/core/card';
import Spinner from 'components/core/spinner';
import Text from 'components/core/text';
import Empty from 'components/empty';
import { useQueryClient } from 'react-query';
import { PurchaseProduct } from 'types/models/purchase-order';
import { formatDate } from 'utils/date';
import { formatMoney } from 'utils/money';
import Button from 'components/core/button';
import { useFieldArray, useForm } from 'react-hook-form';
import { ManageProductsPayload } from '../manage-products';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import ErrorMessage from 'components/error/message';
import { getOrderServiceKey, getOrderServicesPurchaseScopeKey, getOrderServicesPurchaseScopeTotal } from 'services/queries/order-services/keys';
import { useOutletContext, useParams } from 'react-router-dom';
import useValidateProducts, { ValidateProductsPayload } from 'services/queries/order-services/use-validate-products';
import useGetOrderServicesPurchaseScope, { GetOrderServicesPurchaseScopeParams } from 'services/queries/order-services/use-get-order-services-purchase-scope';
import ProductsSimpleTable from '../simple-table';
import { ApprovalStatus } from 'types/general';
import { purchaseScopeValidationSchema } from '../manage-products/utils';
import Modal from 'components/core/modal';
import { CrudPageProps } from 'types/graphql';
import FormButtons from 'components/form-buttons';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import Badge from 'components/core/badge';
import { generateFinalApprovalActionsText } from '../../utils';
import { optionsParser } from 'pages/private/sales-orders/utils';
import SvgIcoArrowRight from 'components/core/icon/files/ico-arrow-right';
import SvgIcoCheck from 'components/core/icon/files/ico-check';
import SvgIcoClose from 'components/core/icon/files/ico-close';

const ProductFinalApprovalPage = ({ title }: CrudPageProps<{}>) => {
    const { palette } = useTheme();
    const queryClient = useQueryClient();
    const { saleOrderId, orderServiceId } = useParams();
    const ctx = useOutletContext<GetOrderServicesPurchaseScopeParams>();

    const filters = {
        ...ctx?.filters,
        type: 'validation',
    };

    const { isLoading, data: products = [] } = useGetOrderServicesPurchaseScope<PurchaseProduct[]>({ ...ctx, filters });
    const { mutateAsync: validateProducts, isLoading: isSubmitting } = useValidateProducts('approve', saleOrderId, orderServiceId);

    const { control, reset, setValue, watch, handleSubmit, formState } = useForm<ManageProductsPayload>({
        mode: 'onSubmit',
        resolver: yupResolver(purchaseScopeValidationSchema(false, false)) as any,
        defaultValues: {
            purchaseProducts: [],
        },
    });

    useFieldArray({ name: 'purchaseProducts', control });

    const submit = async (data: ManageProductsPayload) => {
        try {
            const payload: ValidateProductsPayload = {
                purchaseProducts: data.purchaseProducts.map((item) => {
                    delete item['requests'];

                    return {
                        ...item,
                        provision: formatDate(item.provision, 'YYYY-MM-DD'),
                        product: item.product?.value,
                    };
                }),
            };

            await validateProducts(payload);

            queryClient.invalidateQueries(getOrderServiceKey(orderServiceId));
            queryClient.invalidateQueries(getOrderServicesPurchaseScopeKey(ctx?.endpoint, filters));

            const endpoint = `/sale-orders/${saleOrderId}/service-orders/${orderServiceId}/purchase-products?type=numbers`;

            queryClient.invalidateQueries(getOrderServicesPurchaseScopeTotal(endpoint));
        } catch (error) {
            console.log('submit', error);
        }
    };

    useEffect(() => {
        if (!!products.length) {
            reset({
                purchaseProducts: products.map((item) => ({
                    id: item.id,
                    product: optionsParser(item.product.id, item.product.name),
                    provision: item.provision,
                    quantity: item.quantity,
                    value: item.value,
                    ...(item.approvalStatus === ApprovalStatus.Approved && {
                        approved: true,
                    }),
                })),
            });
        }
    }, [products, reset]);

    if (isLoading) {
        return (
            <div className="p-4">
                <Spinner color={palette.secondary[100]} fixed={false} size={20} />
            </div>
        );
    }

    if (!products.length) {
        return <Empty title="Produtos não encontrados" />;
    }

    return (
        <Modal
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    {title}
                </Text>
            }
            closeOnClickOutside={false}
            contentClassnames="min-w-[1240px] max-w-[90%]">
            <form className="p-6 pt-2 border-t border-t-base-300" onSubmit={handleSubmit(submit)}>
                <div className="flex gap-6 mb-2">
                    <div className="bg-base-100 rounded-[20px] p-4 flex-[2]">
                        <Text as="strong" variant="body.medium.2xs" className="text-center block uppercase text-heading">
                            Original
                        </Text>
                    </div>
                    <div className="bg-base-100 rounded-[20px] p-4 flex-1">
                        <Text as="strong" variant="body.medium.2xs" className="text-center block uppercase text-heading">
                            Alteração
                        </Text>
                    </div>
                </div>
                {products.map((item, index) => {
                    const isLastChild = products.length - 1 === index;
                    const lastRequest = item.requests?.[item.requests.length - 1];

                    const diffProduct = lastRequest?.product?.name !== item.product?.name;
                    const diffProvision = lastRequest?.provision !== item.provision;
                    const diffQuantity = lastRequest?.quantity !== item.quantity;
                    const diffBalance = lastRequest?.balance !== item.balance;
                    const diffValue = lastRequest?.value !== item.value;
                    const diffJustification = lastRequest?.justification !== item.justification;

                    const approved = watch(`purchaseProducts.${index}.approved`);
                    const approvedError = formState.errors.purchaseProducts?.[index]?.approved?.message;

                    return (
                        <div className={classNames('flex gap-6 relative', isLastChild ? '' : 'mb-4')} key={item.id}>
                            <Card className="flex-[2] border border-base-300" elevation={0}>
                                {Boolean(item.requests?.length) ? (
                                    <ProductsSimpleTable
                                        requests={item.requests!}
                                        diffBalance={diffBalance}
                                        diffProduct={diffProduct}
                                        diffProvision={diffProvision}
                                        diffQuantity={diffQuantity}
                                        diffValue={diffValue}
                                    />
                                ) : (
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        <Text variant="body.regular.sm" as="strong" className="text-base-500 whitespace-nowrap">
                                                            Produto
                                                        </Text>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Text variant="body.regular.sm" as="strong" className="text-base-500 whitespace-nowrap">
                                                            Previsão de compra
                                                        </Text>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Text variant="body.regular.sm" as="strong" className="text-base-500 whitespace-nowrap">
                                                            Quantidade
                                                        </Text>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Text variant="body.regular.sm" as="strong" className="text-base-500 whitespace-nowrap">
                                                            Saldo
                                                        </Text>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Text variant="body.regular.sm" as="strong" className="text-base-500 whitespace-nowrap">
                                                            Valor máximo
                                                        </Text>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow classes={{ root: 'bg-base-200' }} sx={{ '&:last-child > td': { border: 'none' } }}>
                                                    <TableCell>
                                                        <Text variant="body.regular.sm" as="span" className="text-base-500 whitespace-nowrap">
                                                            {item.product?.name || '-'}
                                                        </Text>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Text variant="body.regular.sm" as="span" className="text-base-500">
                                                            {formatDate(item.provision)}
                                                        </Text>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Text variant="body.regular.sm" as="span" className="text-base-500">
                                                            {item.quantity || 0}
                                                        </Text>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Text variant="body.regular.sm" as="span" className="text-base-500">
                                                            {item.balance || 0}
                                                        </Text>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Text variant="body.regular.sm" as="span" className="text-base-500">
                                                            {formatMoney(item.value)}
                                                        </Text>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}
                            </Card>
                            <Card
                                elevation={0}
                                className={classNames(
                                    'p-6 flex-1 overflow-visible relative border',
                                    item.approvalStatus === ApprovalStatus.Approved ? 'border-system-success-200 bg-system-success-100 bg-opacity-40' : '',
                                    item.approvalStatus === ApprovalStatus.Disapproved ? 'bg-system-danger-100' : '',
                                    item.approvalStatus === ApprovalStatus.Waiting ? 'bg-base-100' : ''
                                )}>
                                <div className="absolute top-[50%] left-[-32px] -mt-[20px] h-10 w-10 shadow-md rounded-full flex items-center justify-center bg-base-100">
                                    <SvgIcoArrowRight color={palette.grey[700]} />
                                </div>
                                {item.approvalStatus === ApprovalStatus.Approved && (
                                    <div className="flex h-full items-center justify-center">
                                        <div className="mr-2 w-7">
                                            <SvgIcoCheck color={palette.success[500]} />
                                        </div>
                                        <Text variant="body.medium.sm" className="text-system-success-500">
                                            Aprovado
                                        </Text>
                                    </div>
                                )}
                                {item.approvalStatus === ApprovalStatus.Disapproved && (
                                    <div className="flex h-full items-center justify-center">
                                        <div className="mr-2 w-7">
                                            <SvgIcoClose color={palette.error[500]} />
                                        </div>
                                        <Text variant="body.medium.sm" className="text-system-error-500">
                                            Reprovado
                                        </Text>
                                    </div>
                                )}
                                {item.approvalStatus === ApprovalStatus.Waiting && (
                                    <>
                                        <div className="-mx-4 px-4 py-2 -my-2 -mt-3 rounded-[14px] flex items-center justify-between mb-3">
                                            <Text
                                                variant="body.regular.sm"
                                                as="span"
                                                className="flex items-center text-base-500 flex-1 after:border-b-base-300 after:content-[''] after:flex-1 after:mx-4 after:h-[1px] after:border-b after:border-dashed after:block">
                                                Ação
                                            </Text>
                                            <Text variant="body.regular.sm" as="span">
                                                <Badge variant="info">{generateFinalApprovalActionsText(item.action!)}</Badge>
                                            </Text>
                                        </div>
                                        <div className="-mx-4 px-4 py-2 -my-2 -mt-3 rounded-[14px] flex items-center justify-between mb-3">
                                            <Text
                                                variant="body.regular.sm"
                                                as="span"
                                                className="flex items-center text-base-500 flex-1 after:border-b-base-300 after:content-[''] after:flex-1 after:mx-4 after:h-[1px] after:border-b after:border-dashed after:block">
                                                Produto
                                            </Text>
                                            <Text variant="body.regular.sm" as="span" className={diffProduct ? 'bg-system-success-100 px-1 text-system-success-900 bg-opacity-50' : 'text-base-500'}>
                                                {item.product?.name || '-'}
                                            </Text>
                                        </div>
                                        <div className="-mx-4 px-4 py-2 -my-2 -mt-3 rounded-[14px] flex items-center justify-between mb-3">
                                            <Text
                                                variant="body.regular.sm"
                                                as="span"
                                                className="flex items-center text-base-500 flex-1 after:border-b-base-300 after:content-[''] after:flex-1 after:mx-4 after:h-[1px] after:border-b after:border-dashed after:block">
                                                Previsão de compra
                                            </Text>
                                            <Text variant="body.regular.sm" as="span" className={diffProvision ? 'bg-system-success-100 px-1 text-system-success-900 bg-opacity-50' : 'text-base-500'}>
                                                {formatDate(item.provision)}
                                            </Text>
                                        </div>
                                        <div className="-mx-4 px-4 py-2 -my-2 -mt-3 rounded-[14px] flex items-center justify-between mb-3">
                                            <Text
                                                variant="body.regular.sm"
                                                as="span"
                                                className="flex items-center text-base-500 flex-1 after:border-b-base-300 after:content-[''] after:flex-1 after:mx-4 after:h-[1px] after:border-b after:border-dashed after:block">
                                                Quantidade
                                            </Text>
                                            <Text variant="body.regular.sm" as="span" className={diffQuantity ? 'bg-system-success-100 px-1 text-system-success-900 bg-opacity-50' : 'text-base-500'}>
                                                {item.quantity || 0}
                                            </Text>
                                        </div>
                                        <div className="-mx-4 px-4 py-2 -my-2 -mt-3 rounded-[14px] flex items-center justify-between mb-3">
                                            <Text
                                                variant="body.regular.sm"
                                                as="span"
                                                className="flex items-center text-base-500 flex-1 after:border-b-base-300 after:content-[''] after:flex-1 after:mx-4 after:h-[1px] after:border-b after:border-dashed after:block">
                                                Saldo
                                            </Text>
                                            <Text variant="body.regular.sm" as="span" className={diffBalance ? 'bg-system-success-100 px-1 text-system-success-900 bg-opacity-50' : 'text-base-500'}>
                                                {item.balance || 0}
                                            </Text>
                                        </div>
                                        <div className="-mx-4 px-4 py-2 -my-2 -mt-3 rounded-[14px] flex items-center justify-between mb-3">
                                            <Text
                                                variant="body.regular.sm"
                                                as="span"
                                                className="flex items-center text-base-500 flex-1 after:border-b-base-300 after:content-[''] after:flex-1 after:mx-4 after:h-[1px] after:border-b after:border-dashed after:block">
                                                Valor máximo
                                            </Text>
                                            <Text variant="body.regular.sm" as="span" className={diffValue ? 'bg-system-success-100 px-1 text-system-success-900 bg-opacity-50' : 'text-base-500'}>
                                                {formatMoney(item.value)}
                                            </Text>
                                        </div>
                                        <div className="-mx-4 px-4 py-2 -my-2 -mt-3 rounded-[14px] flex items-center justify-between">
                                            <Text
                                                variant="body.regular.sm"
                                                as="span"
                                                className="flex items-center text-base-500 flex-1 after:border-b-base-300 after:content-[''] after:flex-1 after:mx-4 after:h-[1px] after:border-b after:border-dashed after:block">
                                                Justificativa
                                            </Text>
                                            <Text
                                                variant="body.regular.sm"
                                                as="span"
                                                className={diffJustification ? 'bg-system-success-100 px-1 text-system-success-900 bg-opacity-50' : 'text-base-500'}>
                                                {item.justification || '-'}
                                            </Text>
                                        </div>
                                        <div className="flex justify-end mt-8">
                                            <Button
                                                type="button"
                                                variant={typeof approved === 'boolean' && approved === true ? 'contained' : 'text'}
                                                size="small"
                                                color="success"
                                                className={typeof approved === 'boolean' && approved === false ? 'opacity-50' : ''}
                                                startIcon={<SvgIcoCheck width={14} />}
                                                onClick={() => setValue(`purchaseProducts.${index}.approved`, true)}>
                                                Aprovar
                                            </Button>
                                            <Button
                                                type="button"
                                                variant={typeof approved === 'boolean' && approved === false ? 'contained' : 'text'}
                                                size="small"
                                                color="error"
                                                className={typeof approved === 'boolean' && approved === true ? 'opacity-50' : ''}
                                                startIcon={<SvgIcoClose width={12} />}
                                                onClick={() => setValue(`purchaseProducts.${index}.approved`, false)}>
                                                Reprovar
                                            </Button>
                                        </div>
                                        <ErrorMessage className="block text-center mt-4 bg-system-danger-100 bg-opacity-30 p-2 rounded-xl" visible={!!approvedError}>
                                            {approvedError}
                                        </ErrorMessage>
                                    </>
                                )}
                            </Card>
                        </div>
                    );
                })}
                <FormButtons isLoading={isSubmitting} containerClassName="mt-6" />
            </form>
        </Modal>
    );
};

export default ProductFinalApprovalPage;
