import { useQuery } from 'react-query';
import api from 'services/api';
import { Visit } from 'types/models/visit';

export const getVisitKey = (visitId?: number) => ['visits', visitId];

const useGetVisit = (visitId?: number) => {
    const request = async () => {
        const { data } = await api.get<Visit>(`/visits/${visitId}`);

        return data;
    };

    return useQuery(getVisitKey(visitId), request, { enabled: !!visitId });
};

export default useGetVisit;
