import * as React from 'react';
const SvgIcoClose = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 10" width="1em" height="1em" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M8.687 9.609a.652.652 0 1 0 .922-.922L5.922 5l3.687-3.687A.652.652 0 0 0 8.687.39L5 4.078 1.313.391a.652.652 0 1 0-.922.922L4.078 5 .391 8.687a.652.652 0 0 0 .922.922L5 5.922l3.687 3.687Z"
            clipRule="evenodd"
        />
    </svg>
);
export default SvgIcoClose;
