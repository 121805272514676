import { useQueries } from 'react-query';
import { generateSaleOrdersProductsQuery, getSaleOrderProductsKey } from './keys';
import apiGraphQl from 'services/graphql';
import { Pagination } from 'types/pagination';
import { SaleOrderProduct } from 'types/models/sale-order';

const useGetSaleOrdersProducts = (saleOrders: any[] = []) => {
    const request = async (saleOrderId: number) => {
        const { items } = await apiGraphQl<Pagination<Partial<SaleOrderProduct>>>(generateSaleOrdersProductsQuery(saleOrderId));

        return items;
    };

    const requests = saleOrders.map((item) => ({
        queryKey: getSaleOrderProductsKey(item.value),
        queryFn: () => request(item.value),
        enabled: Boolean(item.value),
    }));

    return useQueries(requests);
};

export default useGetSaleOrdersProducts;
