import { useEffect, useState } from 'react';
import classNames from 'classnames';
import Text from '../text';

type SwitchProps = {
    value: boolean;
    onChange: (value: boolean) => void;
    activeLabel?: string;
    className?: string;
    defaultLabel?: string;
};

const Switch = ({ defaultLabel, activeLabel, className, value, onChange }: SwitchProps) => {
    const [active, setActive] = useState(value);

    const handleChange = () => {
        setActive(!active);
        onChange(!active);
    };

    useEffect(() => {
        setActive(value);
    }, [value]);

    const containerClasses = classNames('flex items-center group', className);
    const circleWrapperClasses = classNames('w-[40px] h-[24px] p-[3px] rounded-xl relative transition-all', active ? `bg-secondary-500` : 'bg-base-400');
    const circleClasses = classNames('bg-base-100 block w-[18px] h-[18px] rounded-full absolute -t-[3px] transition-all', active ? `left-[18px]` : 'left-[3px]');

    return (
        <button type="button" className={containerClasses} onClick={handleChange}>
            <span className={circleWrapperClasses} id={active ? 'active' : 'inactive'}>
                <span className={circleClasses} />
            </span>
            {defaultLabel && activeLabel && (
                <Text as="label" className="text-base-500 cursor-pointer whitespace-nowrap overflow-hidden text-ellipsis ml-2">
                    {active ? activeLabel : defaultLabel}
                </Text>
            )}
        </button>
    );
};

export default Switch;
