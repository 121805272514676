import useTheme from '@mui/material/styles/useTheme';
import IconButton from '@mui/material/IconButton/IconButton';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import Empty from 'components/empty';
import Text from 'components/core/text';
import AddButton from 'components/buttons/add';
import Input from 'components/core/form/input';
import { hexToRgba } from 'utils/string';
import { emailTypes } from 'utils/statics';
import Select from 'components/core/form/select';
import selectStyles from 'components/core/form/select/styles';
import SvgIcoTrash from 'components/core/icon/files/ico-trash';

type EmailsProps = {
    className?: string;
    withLabel?: boolean;
};

const Emails = ({ className, withLabel = false }: EmailsProps) => {
    const { palette } = useTheme();
    const { control, formState } = useFormContext();

    const { fields, append, remove } = useFieldArray({ name: 'emails', control });

    const handleAdd = () => append('');
    const handleDelete = (index: number) => () => remove(index);

    return (
        <div className={className}>
            {withLabel && (
                <Text as="label" variant="body.regular.sm" className="block mb-1.5 text-base-500">
                    E-mail(s)
                </Text>
            )}
            {Boolean(fields.length) ? (
                fields.map((item, index) => {
                    return (
                        <div key={item.id} className="flex relative mb-2">
                            <div className="relative flex-1">
                                <div className="absolute right-[10px] top-[50%] -mt-[18px]">
                                    <IconButton
                                        size="large"
                                        type="button"
                                        color="error"
                                        sx={{ backgroundColor: palette.grey[100], '&:hover': { backgroundColor: hexToRgba(palette.error[100], 0.5) } }}
                                        onClick={handleDelete(index)}>
                                        <SvgIcoTrash width={12} height={12} color={palette.error[500]} />
                                    </IconButton>
                                </div>
                                <Controller
                                    name={`emails.${index}.email`}
                                    control={control}
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            type="text"
                                            autoFocus={false}
                                            error={formState.errors?.emails?.[index]?.email?.message}
                                            inputWrapperClasses="rounded-br-none rounded-tr-none"
                                        />
                                    )}
                                />
                            </div>
                            <Controller
                                name={`emails.${index}.type`}
                                control={control}
                                render={({ field }) => {
                                    const value = emailTypes.find((item) => item.value === field.value);

                                    return (
                                        <Select
                                            {...field}
                                            value={value}
                                            options={emailTypes}
                                            placeholder="Selecione uma opção"
                                            error={formState.errors?.emails?.[index]?.type?.message}
                                            onChange={(option: any) => field.onChange(option.value)}
                                            parentClassName="w-[200px]"
                                            selectStyles={{
                                                control: (base, data) => ({
                                                    ...(selectStyles('medium', Boolean(formState.errors?.emails?.[index]?.type?.message)).control!(base, data) as any),
                                                    borderTopLeftRadius: 0,
                                                    borderBottomLeftRadius: 0,
                                                    borderLeft: 0,
                                                }),
                                            }}
                                        />
                                    );
                                }}
                            />
                        </div>
                    );
                })
            ) : (
                <Empty title="Nenhum e-mail adicionado até o momento" className="mb-4" />
            )}
            <div className="py-2 -mt-2">
                <AddButton onClick={handleAdd}>Mais e-mails</AddButton>
            </div>
        </div>
    );
};

export default Emails;
