import Card from 'components/core/card';
import { Chart as ChartJS, LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip, LineController, BarController } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { months } from 'utils/date';
import { useEffect, useMemo, useState } from 'react';
import Spinner from 'components/core/spinner';
import classNames from 'classnames';
import { getReportPageBlockColor, reportPagesChartOptions } from '../../utils';
import { ReportPageBlock } from 'types/models/reports';
import useTheme from '@mui/material/styles/useTheme';

ChartJS.register(LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip, LineController, BarController);

const labels = months;

type ReportPageDetailsChartBarProps = {
    blocks: ReportPageBlock[];
    monthsAverage: number[];
    className?: string;
};

const ReportPageDetailsChartBar = ({ blocks = [], monthsAverage = [], className }: ReportPageDetailsChartBarProps) => {
    const { palette } = useTheme();

    const data = useMemo(() => {
        const datasets: any[] = [
            {
                type: 'line' as const,
                label: 'Média mensal',
                backgroundColor: palette.grey[100],
                borderColor: palette.heading,
                borderWidth: 2,
                fill: false,
                data: monthsAverage,
            },
        ];

        for (const [index, block] of blocks.entries()) {
            const backgroundColor = block?.color ?? getReportPageBlockColor(index);

            const payload = {
                type: 'bar' as const,
                label: block.name,
                backgroundColor,
                data: block?.reportMonths?.map(({ value }) => value) || [],
            };

            datasets.push(payload);
        }

        return {
            labels,
            datasets,
        };
    }, [blocks, monthsAverage, palette]);

    const [height, setHeight] = useState<number>();

    useEffect(() => {
        // get chart height based on doughnut chart div element
        const interval = setInterval(() => {
            const doughnutChartHeight = document.getElementById('doughnut-chart-card')?.clientHeight!;

            if (doughnutChartHeight) {
                setHeight(doughnutChartHeight);

                clearInterval(interval);
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <Card className={classNames('p-5 h-full', className)}>
            <div style={{ height: height ?? '100%' }} className={classNames(!Boolean(height) && 'flex items-center justify-center')}>
                {Boolean(height) ? <Chart type="bar" options={reportPagesChartOptions} data={data} updateMode="resize" className="w-full" /> : <Spinner fixed={false} />}
            </div>
        </Card>
    );
};

export default ReportPageDetailsChartBar;
