import * as React from 'react';
const SvgIcoShow = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12" width="1em" height="1em" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M1.864 6.415c-.103-.16-.19-.3-.257-.415a12.178 12.178 0 0 1 1.585-2.115c1.173-1.247 2.8-2.39 4.808-2.39 2.007 0 3.635 1.143 4.808 2.39A12.187 12.187 0 0 1 14.393 6a12.187 12.187 0 0 1-1.585 2.115c-1.173 1.247-2.8 2.39-4.808 2.39-2.007 0-3.635-1.143-4.808-2.39a12.178 12.178 0 0 1-1.328-1.7Zm14.057-.75L15.25 6l.67.335v.002l-.003.005-.008.015a4.686 4.686 0 0 1-.128.235 13.677 13.677 0 0 1-1.88 2.545C12.604 10.517 10.607 12 8.001 12s-4.603-1.482-5.902-2.863a13.672 13.672 0 0 1-1.88-2.545 8.214 8.214 0 0 1-.127-.235l-.008-.015-.002-.005-.001-.002L.75 6a92.73 92.73 0 0 1-.67-.335v-.002l.003-.005.008-.015a4.944 4.944 0 0 1 .128-.235 13.673 13.673 0 0 1 1.88-2.545C3.396 1.483 5.393 0 7.999 0s4.604 1.482 5.902 2.863a13.678 13.678 0 0 1 1.88 2.545 8.214 8.214 0 0 1 .127.235l.008.015.002.005.001.002ZM15.25 6l.67-.334a.744.744 0 0 1 0 .668L15.25 6ZM.08 5.666.75 6l-.67.334a.745.745 0 0 1 0-.668ZM6.772 6c0-.675.55-1.223 1.227-1.223.678 0 1.227.548 1.227 1.223S8.677 7.223 8 7.223A1.225 1.225 0 0 1 6.773 6ZM8 3.283A2.722 2.722 0 0 0 5.273 6c0 1.5 1.22 2.717 2.727 2.717A2.722 2.722 0 0 0 10.727 6c0-1.5-1.22-2.717-2.727-2.717Z"
            clipRule="evenodd"
        />
    </svg>
);
export default SvgIcoShow;
