import ListPage from 'pages/shared/crud/list';
import { RouteObject } from 'react-router-dom';
import { Rule } from 'types/permissions';
import Page from 'pages/shared/page';
import config from './utils';
import PaymentFlowPage from 'pages/private/payment-flow/view';

const [list] = config;

const branches: RouteObject[] = [
    {
        // @ts-ignore
        breadcrumb: 'Fluxos de pagamento',
        path: 'fluxos-de-pagamento',
        element: <Page Component={ListPage} code="fluxos-de-pagamento" permissions={[Rule.List]} {...list} />,
        children: [
            {
                path: ':paymentFlowId',
                element: <Page Component={PaymentFlowPage} code="fluxos-de-pagamento" permissions={[Rule.Show]} />,
            },
        ],
    },
];

export default branches;
