import { CrudPageProps, DatatableColumn } from 'types/graphql';
import DatatableToolbarActions from './actions';
import { useCallback, useMemo, useState } from 'react';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import ExternalButton from 'components/buttons/external';
import { useNavigate } from 'react-router-dom';
import { PurchaseOrderProduct, PurchaseOrderProductsActions, PurchaseProductStatus } from 'types/models/purchase-order';
import useChangePurchaseOrderProductStatus from 'services/queries/purchase-order/use-change-purchase-order-product-status';
import ModalConfirmAction from 'pages/private/home/dashboards/components/pending-purchase-orders/modal-confirm-action';
import useDeletePurchaseOrderProducts from 'services/queries/purchase-order/use-delete-purchase-order-products';
import Datatable from 'components/core/table/datatable';
import { ApprovalStatus } from 'types/general';
import QueryString from 'qs';
import { MUIDataTableOptions } from 'mui-datatables';
import { getAllPurchaseOrderProductsApiKey } from 'services/queries/purchase-order/keys';
import { useQueryClient } from 'react-query';
import { purchaseOrderChangeStatusValidator } from './utils';
import EntryBuyProductsModal from './entry';

type PurchaseOrderProductsProps = {
    purchaseOrderId?: string;
    columns: DatatableColumn[];
    products: PurchaseOrderProduct[];
    isLoadingProducts: boolean;
    tableOptions?: MUIDataTableOptions;
    canDoActions: boolean;
    withJustification?: boolean;
    listEndpoint?: string;
} & CrudPageProps<{}>;

const cannotSelectStatuses = [PurchaseProductStatus.Finished, PurchaseProductStatus.Canceled];

const PurchaseOrderProducts = ({
    canDoActions,
    purchaseOrderId,
    products,
    isLoadingProducts,
    columns,
    advancedSearchComponent,
    tableOptions,
    withJustification = true,
    listEndpoint,
}: PurchaseOrderProductsProps) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const toolbarAnchor = useState<HTMLElement | null>(null);
    const [justification, setJustification] = useState<string>();
    const [rowsSelected, setRowsSelected] = useState<number[]>([]);
    const [isConfirmActionVisible, setIsConfirmActionVisible] = useState(false);
    const [selectedRows, setSelectedRows] = useState<PurchaseOrderProduct[]>([]);
    const [action, setAction] = useState<PurchaseOrderProductsActions>();
    const [isEntryBuyProductsModalOpen, setIsEntryBuyProductsModalOpen] = useState(false);

    const { mutateAsync: deleteProducts, isLoading: isDeletingProducts } = useDeletePurchaseOrderProducts(purchaseOrderId);
    const { mutateAsync: changePurchaseOrderProductStatus, isLoading: isChangingStatus } = useChangePurchaseOrderProductStatus(purchaseOrderId);

    const customColumns = useMemo(() => {
        const arr = [...columns];

        if (withJustification) {
            arr.push({
                label: 'Justificativa do pedido',
                name: 'justification',
                options: {
                    sort: false,
                    customBodyRender: (value) => {
                        if (!value) {
                            return '-';
                        }

                        return <ExternalButton onClick={() => setJustification(value)}>Visualizar</ExternalButton>;
                    },
                },
            });
        }

        return arr;
    }, [withJustification, columns]);

    const handleChangeStatusClick = useCallback(
        (action: PurchaseOrderProductsActions) => () => {
            const hasForbiddenProducts = purchaseOrderChangeStatusValidator(selectedRows, action);

            if (hasForbiddenProducts) {
                return alert('Um ou mais produtos selecionados não podem executar esta ação.');
            }

            setAction(action);
            setIsConfirmActionVisible(true);

            const [, setToolbarAnchorEl] = toolbarAnchor;

            setToolbarAnchorEl(null);
            setRowsSelected([]);
        },
        [selectedRows, toolbarAnchor]
    );

    const handleBuyProducts = useCallback(() => {
        const [, setToolbarAnchorEl] = toolbarAnchor;

        setToolbarAnchorEl(null);

        const canBuyProduct = selectedRows.every((item) => {
            const isProductApproved = item.approvalStatus === ApprovalStatus.Approved;
            const isPurchaseProductStatusWaiting = item.purchaseProductStatus === PurchaseProductStatus.Waiting;
            const isAllProductsProvisionEquals = Boolean(item.provision) === Boolean(selectedRows[0].provision);

            return isProductApproved && isPurchaseProductStatusWaiting && isAllProductsProvisionEquals;
        });

        if (!canBuyProduct) {
            return alert('Não é possível realizar a compra de um ou mais produtos selecionados');
        }

        const hasDifferentsCompanies = selectedRows.every((item) => item.purchaseOrder.company?.id === selectedRows[0].purchaseOrder.company?.id);
        const withAllProductsSameBilledFlag = selectedRows.every((item) => item.billed === selectedRows[0].billed);

        if (!withAllProductsSameBilledFlag) {
            return alert('Não é possível realizar a compra de produtos misturados - Faturados e Não Faturados');
        }

        if (!hasDifferentsCompanies) {
            return alert('Não é possível realizar a compra de produtos de diferentes empresas');
        }

        setRowsSelected([]);

        const ids = selectedRows.map((item) => item.id);

        setSelectedRows([]);

        const query = QueryString.stringify(
            {
                purchaseProductsIds: ids,
                companyId: selectedRows[0].purchaseOrder.company?.id,
            },
            { addQueryPrefix: true, encode: false, arrayFormat: 'brackets', skipNulls: true }
        );

        navigate(`despesas/novo/compra${query}`);
    }, [navigate, selectedRows, toolbarAnchor]);

    const handleGoToStockDelivery = useCallback(() => {
        const canDoAction = selectedRows.every((item) => item.approvalStatus === ApprovalStatus.Approved && item.stockBalance > 0);

        if (!canDoAction) {
            return alert('Um ou mais produtos ainda não foram aprovados ou não possuem estoque.');
        }

        setRowsSelected([]);

        const purchaseOrderProducts = selectedRows.map((item) => ({
            purchaseOrderProductId: item.id,
            productId: item.product.id,
            quantityPurchaseOrder: item.quantity,
            unit: item.product.unit?.name,
        }));

        setSelectedRows([]);

        const url = QueryString.stringify({ purchaseOrderProducts }, { addQueryPrefix: true, encode: false });

        navigate(`entrega-do-estoque` + url);
    }, [navigate, selectedRows]);

    const handleBuyEntryProducts = useCallback(() => {
        const [, setToolbarAnchorEl] = toolbarAnchor;
        setToolbarAnchorEl(null);

        const hasForbiddenProducts = purchaseOrderChangeStatusValidator(selectedRows, PurchaseOrderProductsActions.Entry);
        const hasDifferentCompanies = selectedRows.some((item) => item.purchaseOrder.company?.id !== selectedRows[0].purchaseOrder.company?.id);

        if (hasForbiddenProducts) {
            return alert('Não é possível realizar a entrega direta de um ou mais produtos selecionados.');
        }

        if (hasDifferentCompanies) {
            return alert('Não é possível realizar a entrega direta de um ou mais produtos selecionados com empresas diferentes.');
        }

        setRowsSelected([]);

        setIsEntryBuyProductsModalOpen(true);
    }, [selectedRows, toolbarAnchor]);

    const actionsPerItem = useMemo(() => {
        const hasExpense = selectedRows.every((item) => Boolean(item.expenseProduct?.expense?.id));

        return {
            content: [
                {
                    label: 'Aprovar produtos',
                    onClick: handleChangeStatusClick(PurchaseOrderProductsActions.Approve),
                },
                {
                    label: 'Não aprovar',
                    onClick: handleChangeStatusClick(PurchaseOrderProductsActions.Disapprove),
                },
                {
                    label: 'Comprar produtos',
                    onClick: handleBuyProducts,
                },
                {
                    label: 'Entrega direta',
                    onClick: hasExpense ? handleChangeStatusClick(PurchaseOrderProductsActions.Entry) : handleBuyEntryProducts,
                    // If the 'purchase order product' has an expense, it means that it's not possible to buy a product (create expense) but rather to change the status with the 'entry' action.
                },
                {
                    label: 'Entrega do estoque',
                    onClick: handleGoToStockDelivery,
                },
                {
                    label: 'Excluir produtos',
                    onClick: handleChangeStatusClick(PurchaseOrderProductsActions.Delete),
                },
            ],
            footer: [
                {
                    className: 'text-system-danger-500',
                    label: 'Cancelar produtos',
                    onClick: handleChangeStatusClick(PurchaseOrderProductsActions.Cancel),
                },
            ],
        };
    }, [handleChangeStatusClick, handleGoToStockDelivery, handleBuyProducts, handleBuyEntryProducts, selectedRows]);

    const handleCloseConfirmModal = useCallback(() => {
        setAction(undefined);
        setSelectedRows([]);
        setRowsSelected([]);
        setIsConfirmActionVisible(false);
    }, []);

    const handleChangeStatus = useCallback(async () => {
        try {
            if (!action) {
                return;
            }

            const purchaseOrderProducts = selectedRows.map((item) => item.id);

            if (action === PurchaseOrderProductsActions.Delete) {
                await deleteProducts({ purchaseOrderProducts });
            } else {
                await changePurchaseOrderProductStatus({ action, purchaseOrderProducts });

                if (!!listEndpoint) {
                    queryClient.invalidateQueries(getAllPurchaseOrderProductsApiKey(listEndpoint));
                }
            }

            handleCloseConfirmModal();
        } catch (error) {
            console.log('handleChangeStatus', error);
        }
    }, [action, selectedRows, deleteProducts, changePurchaseOrderProductStatus, handleCloseConfirmModal, listEndpoint, queryClient]);

    const items = useMemo(() => {
        return selectedRows.map((item) => ({
            id: item.id,
            quantity: item.quantity || 0,
            purchaseOrder: {
                code: item.purchaseOrder?.code || '-',
                origin: {
                    orderService: {
                        title: (item as any).purchaseOrder?.origin?.orderService?.title || (item as any).purchaseOrder?.origin?.stock?.name || '-',
                    },
                },
            },
            product: {
                code: item.product.code,
                name: item.product.name,
            },
        }));
    }, [selectedRows]);

    const handleCloseEntryBuyModal = () => {
        setIsEntryBuyProductsModalOpen(false);
        setSelectedRows([]);
    };

    return (
        <>
            <Datatable
                columns={customColumns}
                data={products}
                title=""
                loading={isLoadingProducts}
                options={{
                    search: true,
                    searchAlwaysOpen: true,
                    print: false,
                    download: false,
                    selectableRowsOnClick: true,
                    enableNestedDataAccess: '.',
                    pagination: false,
                    elevation: 21,
                    searchPlaceholder: 'Buscar produto',
                    selectableRows: canDoActions ? 'multiple' : 'none',
                    rowsSelected,
                    setRowProps: (_, dataIndex) => {
                        const product = products[dataIndex];
                        const cantShowCheckbox = cannotSelectStatuses.includes(product.purchaseProductStatus) || product.approvalStatus === ApprovalStatus.Disapproved;

                        if (cantShowCheckbox) {
                            return {
                                ['data-disabled-checkbox']: 'true', // create a data attribute in <tr> element
                            };
                        }

                        return {};
                    },
                    customToolbarSelect: () => <DatatableToolbarActions toolbarAnchor={toolbarAnchor} {...actionsPerItem} />,
                    onRowSelectionChange: (_, __, rowsSelected = []) => {
                        const arr = products.filter((_, index) => rowsSelected.includes(index));

                        setRowsSelected(rowsSelected);
                        setSelectedRows(arr);
                    },
                    isRowSelectable: (dataIndex) => {
                        const product = products[dataIndex];

                        if (cannotSelectStatuses.includes(product.purchaseProductStatus)) {
                            return false;
                        }

                        return true;
                    },
                    ...tableOptions,
                }}
                advancedSearchComponent={advancedSearchComponent}
                hideFooter={!!purchaseOrderId}
            />
            {Boolean(justification) && (
                <Modal
                    contentClassnames="w-[500px]"
                    headerLeft={
                        <Text as="h3" variant="h4" className="text-heading">
                            Justificativa
                        </Text>
                    }
                    onClose={() => setJustification(undefined)}>
                    <div className="rich-content p-6 pt-0" dangerouslySetInnerHTML={{ __html: justification! }} />
                </Modal>
            )}
            {isConfirmActionVisible && !!action && (
                <ModalConfirmAction action={action} items={items} isSubmitting={isChangingStatus || isDeletingProducts} onClose={handleCloseConfirmModal} onSubmit={handleChangeStatus} />
            )}
            {isEntryBuyProductsModalOpen && Boolean(selectedRows.length) && <EntryBuyProductsModal selectedProducts={selectedRows} onClose={handleCloseEntryBuyModal} />}
        </>
    );
};

export default PurchaseOrderProducts;
