import { useMutation, useQueryClient } from 'react-query';
import apiGraphQl from 'services/graphql';
import { getAllKey } from './keys';
import useToast from 'hooks/toast/use-toast';
import queries from './keys';
import { ApiPayload, GraphqlPaginationVariables, GraphqlQueryPayload } from 'types/graphql';
import api from 'services/api';
import { useShowApiErrors } from 'hooks/error/show-api-errors';

type ArgsType<T> = {
    queryConfig?: GraphqlQueryPayload;
    variables?: GraphqlPaginationVariables<T>;
    apiPayload?: ApiPayload;
    onSuccess?(): void;
};

type Payload =
    | {
          id?: number;
          slug?: string;
      }
    | undefined;

const useDelete = <T>(props: ArgsType<T>) => {
    const { queryConfig, variables, apiPayload, onSuccess } = props || {};

    const { showToast } = useToast();
    const { showToastErrors } = useShowApiErrors();
    const queryClient = useQueryClient();

    const request = async (payload: Payload) => {
        if (apiPayload?.useApiTodelete) {
            return api.delete(apiPayload?.endpoint + `/${payload?.id}`);
        }

        return apiGraphQl<any>(queries.delete(queryConfig?.table || '', !!payload?.slug), {
            id: payload?.id,
            ...(payload?.slug && { slug: payload?.slug }),
        });
    };

    return useMutation(request, {
        onSuccess: () => {
            if (queryConfig) {
                queryClient.invalidateQueries(getAllKey(queryConfig.table, variables));
            }

            showToast('Registro apagado com sucesso!', 'success');

            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (error: any) => {
            showToastErrors(error, error?.response?.data?.title || 'Ocorreu um erro ao apagar o registro. Tente novamente.');
        },
    });
};

export default useDelete;
