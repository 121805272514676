import Button from 'components/core/button';
import Menu, { MenuItem } from 'components/menu';
import { useState } from 'react';
import useTheme from '@mui/material/styles/useTheme';
import { useNavigate } from 'react-router-dom';
import SvgIcoChevronDown from 'components/core/icon/files/ico-chevron-down';

type BankAccountActionsProps = {
    isActive: boolean;
};

const BankAccountActions = ({ isActive }: BankAccountActionsProps) => {
    const { palette } = useTheme();
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const content: MenuItem[] = [
        {
            label: 'Nova receita',
            icon: {
                color: palette.grey[500],
                name: 'ico-dollar-sign',
            },
            onClick: () => {
                setAnchorEl(null);
                navigate('nova-receita');
            },
        },
        ...(isActive
            ? [
                  {
                      label: 'Transferência entre contas',
                      icon: {
                          color: palette.grey[500],
                          name: 'ico-send-outline',
                      },
                      onClick: () => {
                          setAnchorEl(null);
                          navigate('transferencia-contas');
                      },
                  },
              ]
            : []),
        {
            label: 'Inclusão de aportes',
            icon: {
                color: palette.grey[500],
                name: 'ico-dollar-sign',
            },
            onClick: () => {
                setAnchorEl(null);
                navigate('inclusao-aportes');
            },
        },
        {
            label: 'Exportar arquivos',
            icon: {
                color: palette.grey[500],
                name: 'ico-download',
            },
            onClick: () => {
                setAnchorEl(null);
                navigate('exportar-arquivos');
            },
        },
    ];

    return (
        <>
            <Button type="button" variant="contained" color="primary" onClick={handleOpen} endIcon={<SvgIcoChevronDown width={13} color="#fff" />}>
                Ações
            </Button>
            <Menu className="z-[99999]" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} content={content} />
        </>
    );
};

export default BankAccountActions;
