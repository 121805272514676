import AddButton from 'components/buttons/add';
import Text from 'components/core/text';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { FormPayload } from 'services/queries/forms/use-create-or-update-form';
import get from 'lodash/get';
import RemoveButton from 'components/buttons/remove';
import Input from 'components/core/form/input';

type FormItemSubfieldOptionsProps = {
    subfieldsPath: `groups.${number}.fields.${number}.options.${number}.subfields`;
    subfieldIndex: number;
};

const FormItemSubfieldOptions = ({ subfieldIndex, subfieldsPath }: FormItemSubfieldOptionsProps) => {
    const { control, formState } = useFormContext<FormPayload>();

    const { fields: options, append: appendOption, remove: removeOption } = useFieldArray({ control, name: `${subfieldsPath}.${subfieldIndex}.options` });

    return (
        <div className="box-with-border p-0 m-4 mt-0">
            <div className="flex flex-row items-center justify-between p-4 border-b border-b-base-300">
                <Text variant="body.medium.sm" className="text-secondary-700 flex-1">
                    Opções
                </Text>
                <AddButton onClick={appendOption.bind(this, { name: '' })}>Adicionar opção</AddButton>
            </div>
            <div className="p-4">
                {options.map((item, subfieldOptionIndex) => {
                    const getError = (field: string) => get(formState.errors, `${subfieldsPath}.${subfieldIndex}.options.${subfieldOptionIndex}.${field}.message`);

                    return (
                        <div className="flex flex-row items-end gap-4 mb-4 last:mb-0" key={item.id}>
                            <Controller
                                control={control}
                                defaultValue=""
                                name={`${subfieldsPath}.${subfieldIndex}.options.${subfieldOptionIndex}.name`}
                                render={({ field }) => {
                                    return <Input {...field} label="Nome" parentClassName="flex-1" error={getError('name')} />;
                                }}
                            />
                            {!!subfieldOptionIndex && <RemoveButton className="!static !m-0 opacity-100 mb-[7px]" onClick={removeOption.bind(this, subfieldOptionIndex)} />}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default FormItemSubfieldOptions;
