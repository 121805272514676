import { useMutation } from 'react-query';
import apiGraphQl from 'services/graphql';
import useToast from 'hooks/toast/use-toast';
import { pkColumnsKey } from 'utils/environment';

const query = `mutation ($payload: OrderServiceDeductionSetInput, $id: Int!) {
    updateOrderServiceDeductionByPk(${pkColumnsKey}: { id: $id }, _set: $payload) { id }
}`;

const useChangeInvoiceStatus = () => {
    const { showToast } = useToast();

    const request = async (orderServiceDeductionId: number) => {
        const payload = {
            id: orderServiceDeductionId,
            payload: { invoiceSent: true },
        };

        return apiGraphQl(query, payload);
    };

    return useMutation(request, {
        onSuccess: () => {
            showToast('Dedução atualizada', 'success');

            window.location.reload();
        },
        onError: () => {
            showToast('Ocorreu um erro ao atualizar a transação', 'danger');
        },
    });
};

export default useChangeInvoiceStatus;
