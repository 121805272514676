import { useQueries } from 'react-query';
import api from 'services/api';
import { getTransactionByCodeKey } from './keys';
import { Transaction } from 'types/models/transaction';

const useGetTransactionsById = (ids: number[]) => {
    const request = async (id: number) => {
        const { data } = await api.get<Transaction>(`/transactions/${id}`);

        return data;
    };

    const requests = ids.map((id) => ({
        queryKey: getTransactionByCodeKey(id),
        queryFn: () => request(id),
        enabled: Boolean(id),
    }));

    return useQueries(requests);
};

export default useGetTransactionsById;
