import AddButton from 'components/buttons/add';
import Text from 'components/core/text';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FormPayload } from 'services/queries/forms/use-create-or-update-form';
import FormItemOption from './item-option';

type FormItemOptionsProps = {
    fieldIndex: number;
    groupIndex: number;
};

export const FORM_ITEM_DEFAULT_OPTION = {
    name: '',
};

const FormItemOptions = ({ fieldIndex, groupIndex }: FormItemOptionsProps) => {
    const { control } = useFormContext<FormPayload>();

    const itemPath = `groups.${groupIndex}.fields.${fieldIndex}` as const;

    const { fields, append, remove } = useFieldArray({ control, name: `${itemPath}.options` });

    return (
        <div className="box-with-border p-0 m-4 mt-0">
            <div className="flex flex-row items-center justify-between p-4 border-b border-b-base-300">
                <Text variant="body.medium.sm" className="text-secondary-700 flex-1">
                    Opções
                </Text>
                <AddButton onClick={append.bind(this, { name: '', subfields: [] })}>Adicionar opção</AddButton>
            </div>
            <div className="p-4">
                {fields.map((item, index) => {
                    return <FormItemOption key={item.id} fieldIndex={fieldIndex} groupIndex={groupIndex} optionIndex={index} onRemoveOption={remove} />;
                })}
            </div>
        </div>
    );
};

export default FormItemOptions;
