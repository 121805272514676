import classNames from 'classnames';
import { januaryFirstOfCurrentYear } from 'utils/date';
import { array, number, object, string } from 'yup';
import { GenerateReportTotalsClassesParams } from './types';
import { Product } from 'types/models/product';

export const getExpenseCardVariant = (value: number) => (value > 0 ? 'paid' : 'toBePaid');

export const genericReportDefaultValues = {
    startDate: '',
    endDate: '',
};

export const handleChangeReportSelect = (onChange: (...event: any[]) => void) => {
    return (option: any) => {
        if (Array.isArray(option)) {
            const ids = option.map((item) => item.value);
            return onChange(ids);
        }

        onChange(option?.value);
    };
};

export const genericReportSchema = (withConsultants = true, withTypes = true) => {
    return object({
        branch: number().optional(),
        companies: array(number()).optional(),
        startDate: string().nullable(),
        endDate: string().nullable(),
        ...(withConsultants && {
            consultants: array(number()).optional(),
        }),
        ...(withTypes && {
            types: array(number()).optional(),
        }),
    });
};

export const generateReportValuePercentageData = (value = 0, percentage?: number) => ({
    value,
    ...(typeof percentage !== 'undefined' && {
        percentage,
    }),
});

export const generateReportDefaultValuePercentage = (hasPercentage = true) => ({
    value: 0,
    ...(hasPercentage && { percentage: 0 }),
});

export const reportDatatableOptions = {
    sort: true,
    filter: false,
    search: true,
    searchAlwaysOpen: true,
    print: false,
    download: false,
    viewColumns: false,
    serverSide: false,
    enableNestedDataAccess: '.',
    pagination: true,
    elevation: 21,
};

export const initialReportFilters = {
    filter: {
        startDate: januaryFirstOfCurrentYear,
    },
};

export const generateReportTotalsClasses = (params?: GenerateReportTotalsClassesParams) => {
    const { showValues = true, value = '', headContainer = '', cardContainer = '', valueContainer = '', contentContainer = '' } = params || {};

    return {
        value,
        headContainer: classNames('xl:items-end xl:gap-2 h-full flex-1', headContainer),
        cardContainer: classNames('justify-between', cardContainer),
        valueContainer: classNames(!showValues && 'bg-gray-200 rounded-lg w-[80px] h-[20px] mb-1', valueContainer),
        contentContainer: classNames('xl:!items-end xl:!text-base', contentContainer),
    };
};

export const reportFontsizeButtonClasses = 'p-0 w-[48px] h-[48px] min-w-0 bg-white !text-heading border-[#e5e7eb] font-medium hover:border-[#C6C9CC] hover:bg-white';

export const productsOptionsTransformer = (data: Partial<Product>[]) => {
    return data.map((item) => ({
        label: item.name,
        value: item.id,
        code: item.code,
    }));
};
