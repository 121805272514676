import React, { useCallback, useMemo } from 'react';
import MUIDataTable, { MUIDataTableProps } from 'mui-datatables';
import DatatableFooter from './components/footer';
import DatatableActions from './components/actions';
import { Modify } from 'types/general';
import dictionary from 'utils/dictionary';
import { DatatableColumn } from 'types/graphql';
import DataTableSearchInput from './components/search-input';
import classNames from 'classnames';
import { Rule } from 'types/permissions';
import { useLocation, useNavigate } from 'react-router-dom';
import useConfig from 'store/config/use-config';
import TableCell from '@mui/material/TableCell';
import Text from 'components/core/text';

type DatatableProps = {
    footerLeft?: React.ReactNode;
    loading?: boolean;
    withItemsPerPage?: boolean;
    withPagination?: boolean;
    onDelete?: (id?: number, slug?: string) => void;
    withBorder?: boolean;
    hideFooter?: boolean;
    data?: any;
    actions?: Rule[];
    advancedSearchComponent?: React.ReactNode;
    className?: string;
    hasSelectedFilters?: boolean;
    customActions?: (item: any, rule?: Rule) => any[];
    customFooterActions?: (item: any) => any[];
    pageKeyContent?: string;
} & Modify<MUIDataTableProps, { columns: DatatableColumn[]; title?: string }>;

const NOT_FOUND_INDEX = -1;

const Datatable = ({
    actions = [],
    columns,
    data = [],
    footerLeft,
    loading = false,
    options,
    withItemsPerPage = true,
    withPagination = true,
    onDelete,
    withBorder = false,
    hideFooter = false,
    advancedSearchComponent,
    hasSelectedFilters,
    title,
    customActions,
    customFooterActions,
    pageKeyContent,
    ...props
}: DatatableProps) => {
    const { config, setConfig } = useConfig();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const canShow = actions.includes(Rule.Show);

    const customRoutePath = useMemo(() => columns.find((item) => item.name === 'actions')?.customRoutePath, [columns]);

    const items = useMemo(() => {
        return data.map((item) => ({
            ...item,
            ...(Boolean(actions.length) && {
                actions: (
                    <DatatableActions
                        customActions={customActions}
                        customFooterActions={customFooterActions}
                        customRoutePath={customRoutePath}
                        item={item}
                        key={(item as any)?.id}
                        actions={actions}
                        onDelete={onDelete}
                    />
                ),
            }),
        }));
    }, [actions, data, customRoutePath, onDelete, customActions, customFooterActions]);

    const { searchPlaceholder } = options || {};

    const className = classNames('', props?.className, withBorder && 'border border-base-300 rounded-[20px]');

    const handleCellClick = useCallback(
        (...args) => {
            if (!items.length) {
                return;
            }

            const [, cellMeta] = args;
            const actionsColumnIndex = columns.findIndex((item) => item.name === 'actions');
            const hasColumnIndex = actionsColumnIndex !== NOT_FOUND_INDEX;

            const handleGoTo = (event: React.MouseEvent<Element, MouseEvent>, url: string) => {
                if (event.ctrlKey || event.metaKey) {
                    return window.open(url, '_blank');
                }

                return navigate(url);
            };

            if (canShow && hasColumnIndex) {
                const hasSelectedRows = !!options?.selectableRowsOnClick;
                const hasExpandableRows = !!options?.expandableRows || !!options?.expandableRowsOnClick;

                const isColumnLink = () => {
                    const target = cellMeta.event.target as HTMLAnchorElement;
                    const parentTarget = target?.parentElement as HTMLAnchorElement;

                    return !!target?.href || !!parentTarget?.href;
                };

                const isActionsColumn = cellMeta.colIndex === actionsColumnIndex;
                const canCellClick = !isActionsColumn && !isColumnLink() && !hasExpandableRows && !hasSelectedRows;

                if (canCellClick) {
                    const dataItem = data[cellMeta.rowIndex];
                    const url = customRoutePath?.(dataItem) || `${pathname}/${dataItem.id}`;

                    handleGoTo(cellMeta.event, url);
                }
            }
        },
        [canShow, columns, customRoutePath, data, navigate, options?.expandableRows, options?.expandableRowsOnClick, options?.selectableRowsOnClick, pathname, items]
    );

    const handleChangeRowProps = useCallback(
        (row: any[], dataIndex: number, rowIndex: number) => {
            const actionsColumnIndex = columns.findIndex((item) => item.name === 'actions');
            const hasColumnIndex = actionsColumnIndex !== NOT_FOUND_INDEX;

            const { style, ...rest } = (options?.setRowProps?.(row, dataIndex, rowIndex) as any) || {};

            return {
                style: {
                    cursor: canShow && hasColumnIndex ? 'pointer' : 'auto',
                    ...style,
                },
                ...rest,
            };
        },
        [canShow, columns, options]
    );

    const updatedColumns = useMemo(() => {
        return columns.map((item, index) => {
            const displayConfig = config.tables.pages?.[pageKeyContent!]?.columns?.[index]?.options?.display;
            const display = displayConfig ?? item.options?.display ?? true;

            return {
                ...item,
                options: {
                    ...item.options,
                    ...(!item.options?.customHeadRender && {
                        customHeadRender: [true, undefined].includes(item.options?.sort)
                            ? undefined
                            : (columnMeta) => {
                                  return (
                                      <TableCell>
                                          <Text variant="body.medium.2xs" className="text-base-500 uppercase text-sm !font-medium">
                                              {columnMeta.label}
                                          </Text>
                                      </TableCell>
                                  );
                              },
                    }),
                    display,
                },
            };
        });
    }, [config.tables.pages, pageKeyContent, columns]);

    const hasViewColumnsAndPageKeyContent = Boolean(options?.viewColumns) && Boolean(pageKeyContent);

    const datatableOptions = useMemo(() => {
        const handleViewColumnsChange = (changedColumn: string, action: string) => {
            const newColumnsArr: DatatableColumn[] = updatedColumns.map((column: DatatableColumn) => {
                if (column.name === changedColumn) {
                    return {
                        ...column,
                        options: {
                            ...column.options,
                            display: action === 'add' ? true : false,
                        },
                    };
                }
                return column;
            });

            setConfig('tables', {
                ...config.tables,
                pages: {
                    ...config.tables.pages,
                    [pageKeyContent!]: {
                        ...config.tables.pages?.[pageKeyContent!],
                        columns: newColumnsArr,
                    },
                },
            });
        };

        return {
            textLabels: dictionary.content.datatable,
            print: false,
            viewColumns: false,
            filter: false,
            download: false,
            ...(!options?.rowsSelected &&
                !options?.customToolbar &&
                !options?.customToolbarSelect && {
                    selectableRowsHideCheckboxes: true,
                }),
            ...options,
            ...(hasViewColumnsAndPageKeyContent && {
                onViewColumnsChange: handleViewColumnsChange,
            }),
            responsive: 'standard',
            onCellClick: handleCellClick,
            setRowProps: handleChangeRowProps,
            customSearchRender: (searchText = '', handleSearch) => (
                <DataTableSearchInput
                    hasSelectedFilters={hasSelectedFilters}
                    advancedSearchComponent={advancedSearchComponent}
                    searchPlaceholder={searchPlaceholder || 'Buscar'}
                    searchText={searchText}
                    handleSearch={handleSearch}
                />
            ),
            searchProps: {
                // @ts-ignore
                autoFocus: !options?.searchAlwaysOpen,
            },
            fixedHeader: true,
        };
    }, [
        options,
        advancedSearchComponent,
        handleCellClick,
        handleChangeRowProps,
        hasSelectedFilters,
        hasViewColumnsAndPageKeyContent,
        searchPlaceholder,
        config.tables,
        pageKeyContent,
        setConfig,
        updatedColumns,
    ]);

    return (
        <div className={className}>
            <MUIDataTable
                {...props}
                title={title || ''}
                columns={updatedColumns}
                data={items}
                options={datatableOptions as MUIDataTableProps['options']}
                components={{
                    TableFooter: (footerProps) => {
                        return hideFooter ? null : (
                            <DatatableFooter
                                {...footerProps}
                                isServerSide={Boolean(options?.serverSide)}
                                loading={loading}
                                footerLeft={footerLeft}
                                withPagination={withPagination}
                                withItemsPerPage={withItemsPerPage}
                            />
                        );
                    },
                }}
            />
        </div>
    );
};

export default Datatable;
