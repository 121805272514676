import { useMemo, useState } from 'react';
import ReportPageHeader from '../../../components/report-page-header';
import ReportProductsFilters from './filters';
import { DataContentFiltersConfig, GenericReportFilterPayload } from '../../types';
import { ProductsReportNumbersResponse, ReportProductsFiltersPayload } from 'types/models/reports';
import useGetProductsReport from 'services/queries/reports/use-get-products-report';
import classNames from 'classnames';
import Spinner from 'components/core/spinner';
import ExpenseTotalCard from 'pages/private/sales-orders/service-order/expenses/total-card';
import ContractsReportTotalsCardHead from '../../order-services/header/totals-head';
import { useReportsContext } from 'pages/private/reports/context';
import { generateReportTotalsClasses } from '../../utils';

type ProductsReportHeaderProps = {
    endpoint: string;
    dataContentFiltersConfig: DataContentFiltersConfig;
    title: string;
    filters?: GenericReportFilterPayload<ReportProductsFiltersPayload>;
    onSetFilters: (data?: GenericReportFilterPayload<ReportProductsFiltersPayload>) => void;
    isSale: boolean;
};

const ProductsReportHeader = ({ endpoint, title, filters, onSetFilters, dataContentFiltersConfig, isSale }: ProductsReportHeaderProps) => {
    const [isFilterVisible, setIsFilterVisible] = useState(false);

    const { showValues } = useReportsContext();

    const { data = [], isLoading } = useGetProductsReport<ProductsReportNumbersResponse>({ endpoint, filters });

    const containerClasses = useMemo(() => {
        const cols = `xl:grid-cols-${data.length}`;

        return classNames('mb-8 grid grid-cols-1 gap-4 sm:grid-cols-3 md:grid-cols-4', cols);
    }, [data.length]);

    return (
        <>
            <ReportPageHeader title={title} onOpenFilters={setIsFilterVisible.bind(this, true)} />
            {isLoading ? (
                <Spinner fixed={false} className="my-4" />
            ) : (
                <div className={containerClasses}>
                    {data.map((productTotals, index) => (
                        <ExpenseTotalCard
                            containerClassName="justify-between"
                            head={{
                                data: <ContractsReportTotalsCardHead title={productTotals.name} />,
                                containerClassName: 'xl:items-end xl:gap-2 h-full',
                            }}
                            variant="paid"
                            key={index}
                            percentage={{ data: productTotals.percentage }}
                            value={{
                                data: productTotals.value,
                                contentContainerClassName: 'xl:!items-end 2xl:!text-[0.95rem]',
                                containerClassName: generateReportTotalsClasses({ showValues }).valueContainer,
                                className: generateReportTotalsClasses({ showValues }).value,
                                isVisible: showValues,
                            }}
                        />
                    ))}
                </div>
            )}
            {isFilterVisible && (
                <ReportProductsFilters
                    dataContentFiltersConfig={dataContentFiltersConfig}
                    onCloseFilters={setIsFilterVisible.bind(this, false)}
                    onSetFilters={onSetFilters}
                    filters={filters}
                    isSale={isSale}
                />
            )}
        </>
    );
};

export default ProductsReportHeader;
