import React, { Suspense } from 'react';
import withPagePermissions from 'hocs/with-page-permissions';
import { Rule } from 'types/permissions';
import { CrudPageProps } from 'types/graphql';
import Spinner from 'components/core/spinner';

export type PageProps<T = {}> = {
    Component: React.ElementType;
    permissions: Rule[];
    code: string;
    withModal?: boolean;
    [key: string]: any;
} & CrudPageProps<T>;

const Page = <T,>({ Component, code, permissions, withModal = false, ...props }: PageProps<T>) => {
    return (
        <Suspense
            fallback={
                <div className="relative w-full h-full">
                    <Spinner />
                </div>
            }>
            <Component code={code} permissions={permissions} withModal={withModal} {...props} />
        </Suspense>
    );
};

export default withPagePermissions(Page);
