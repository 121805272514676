import ExternalButton from 'components/buttons/external';
import { DatatableColumn } from 'types/graphql';
import { formatDate } from 'utils/date';
import { formatMoney } from 'utils/money';
import { generateExpenseTransactionStatus } from '../expenses/details/tabs/transactions/utils';

export const receiptOrderListColumns: DatatableColumn[] = [
    {
        name: 'id',
        options: { display: 'excluded' },
    },
    {
        name: 'receipt.origin.saleOrder.id',
        options: { display: 'excluded' },
    },
    {
        name: 'receipt.origin.orderService.id',
        options: { display: 'excluded' },
    },
    {
        name: 'code',
        label: 'Código',
        options: {
            customBodyRender: (value, tableMeta) => {
                const [id] = tableMeta.rowData;

                return Boolean(value) ? <ExternalButton to={`${id}`}>{value}</ExternalButton> : '-';
            },
        },
    },
    {
        name: 'dueDate',
        label: 'Vencimento',
        options: { customBodyRender: (value) => formatDate(value, 'DD/MM/YYYY') },
    },
    {
        name: 'payday',
        label: 'Pagamento',
        options: { customBodyRender: (value) => formatDate(value, 'DD/MM/YYYY') },
    },

    {
        name: 'description',
        label: 'Descrição',
        options: {
            customBodyRender: (value = '-') => value,
        },
    },
    {
        name: 'receipt.origin.saleOrder.code',
        label: 'Origem',
        options: {
            customBodyRender: (value, tableMeta) => {
                const [, saleOrderId] = tableMeta.rowData;

                return Boolean(value) ? <ExternalButton to={`/app/pedidos-de-venda/${saleOrderId}`}>{value}</ExternalButton> : '-';
            },
        },
    },
    {
        name: 'receipt.origin.orderService.title',
        label: 'Obra',
        options: {
            customBodyRender: (value, tableMeta) => {
                const [, saleOrderId, orderServiceId] = tableMeta.rowData;

                return Boolean(value) ? <ExternalButton to={`/app/pedidos-de-venda/${saleOrderId}/ordens-de-servico/${orderServiceId}`}>{value}</ExternalButton> : '-';
            },
        },
    },
    {
        name: 'receipt.origin.saleOrder.consultant.title',
        label: 'Consultor',
        options: {
            customBodyRender: (value = '-') => value,
        },
    },
    {
        name: 'receipt.origin.saleOrder.customer.title',
        label: 'Cliente',
        options: {
            customBodyRender: (value = '-') => value,
        },
    },
    {
        name: 'receipt.origin.saleOrder.company.name',
        label: 'Empresa',
        options: {
            customBodyRender: (value = '-') => value,
        },
    },
    {
        name: 'netValue',
        label: 'Valor parcela',
        options: { customBodyRender: (value = 0) => formatMoney(Number(value)) },
    },
    {
        name: 'paymentForm.name',
        label: 'Forma de pagamento',
        options: {
            customBodyRender: (value = '-') => value,
        },
    },
    {
        name: 'paid',
        label: 'Status',
        options: {
            customBodyRender: (paid, { rowData }) => generateExpenseTransactionStatus({ paid, payday: rowData[5], dueDate: rowData[4] }),
        },
    },
];
