import Select from 'components/core/form/select';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { memo, useMemo, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useLocation, useOutletContext, useParams } from 'react-router-dom';
import useGetProducts from 'services/queries/products/use-get-products';
import { CrudPageProps } from 'types/graphql';
import { formatDate } from 'utils/date';
import {
    generateOrderServicePurchaseProductsQuery,
    purchaseOrderSchema,
    purchaseProductFormatResponse,
    purchaseProductQueryFields,
    purchaseProductsTypeWhereClause,
    servicePurchaseProductsQueryFields,
} from './utils';
import ModalProducts from './modal-products';
import { OrderService, OrderServiceProductPurchase } from 'types/models/order-service';
import ExternalButton from 'components/buttons/external';
import { Product } from 'types/models/product';
import FormButtons from 'components/form-buttons';
import useCreateOrUpdatePurchaseOrder from 'services/queries/order-services/use-create-or-update-purchase-order';
import { yupResolver } from '@hookform/resolvers/yup';
import { ApprovalStatus } from 'types/general';
import { generateProductsQuery } from 'services/queries/products/utils';
import useDebounce from 'hooks/keyboard/use-debounce';
import { useQueryClient } from 'react-query';
import { getOrderServiceKey, getOrderServicePurchaseProductsKey } from 'services/queries/order-services/keys';
import OrderServicePurchaseOrderTable from './products-table';
import { PurchaseOrderPayload } from 'types/models/purchase-order';

const CreateOrUpdateOrderPurchasePage = ({ title }: CrudPageProps<{}>) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [isProductsModalVisible, setIsProductsModalVisible] = useState(false);

    const ctx = useOutletContext();
    const { orderServiceId, saleOrderId } = useParams();
    const { state: routeState } = useLocation();
    const queryClient = useQueryClient();

    const debouncedProductName = useDebounce(searchTerm, 500);

    const { tableSelectedProducts = [] }: { tableSelectedProducts: any[] } = routeState || {};

    const methods = useForm<PurchaseOrderPayload>({
        defaultValues: { products: tableSelectedProducts },
        resolver: yupResolver(purchaseOrderSchema) as any,
    });

    const { control, handleSubmit } = methods;

    const { append, remove, fields: selectedProducts } = useFieldArray({ name: 'products', control, keyName: 'fieldId' });

    const { mutateAsync: createOrUpdatePurchaseOrder, isLoading: isSubmitting } = useCreateOrUpdatePurchaseOrder(ctx, orderServiceId, saleOrderId);
    const { saleProducts = [] } = queryClient.getQueryData<OrderService>(getOrderServiceKey(orderServiceId)) || {};

    const saleProductsOptions = useMemo(() => {
        return saleProducts.map((item) => ({
            value: item.id!,
            label: item.product?.name!,
        }));
    }, [saleProducts]);

    const where = useMemo(
        () => ({
            approvalStatus: { _eq: ApprovalStatus.Approved },
            ...purchaseProductsTypeWhereClause,
            ...(Boolean(debouncedProductName) && {
                _or: [{ name: { _ilike: `%${debouncedProductName}%` } }, { code: { _ilike: `%${debouncedProductName}%` } }],
            }),
        }),
        [debouncedProductName]
    );

    const { data: products = [], isLoading: isLoadingProducts } = useGetProducts<Product>(
        generateProductsQuery(purchaseProductQueryFields, where),
        !!debouncedProductName,
        purchaseProductFormatResponse
    );

    const handleRemoveProduct = (index: number) => remove(index);

    const { data: servicePurchaseProducts = [] } = useGetProducts<OrderServiceProductPurchase>(
        generateOrderServicePurchaseProductsQuery(servicePurchaseProductsQueryFields, { orderServiceId: { _eq: orderServiceId } }),
        true,
        undefined,
        getOrderServicePurchaseProductsKey.bind(this, orderServiceId)
    );

    const handleSelectProducts = (option: any) => {
        const finded = servicePurchaseProducts.find((item) => item.product?.id === option.value);

        append({
            balance: finded?.balance || 0,
            id: option.value,
            orderServiceProductPurchase: finded?.id || null,
            product: {
                code: option.code,
                name: option.name,
                classificationByClassificationUnitId: { name: option.classificationByClassificationUnitId.name },
            },
            preApproved: !!finded,
            billed: false,
            provision: '',
            purchaseValue: 0,
        });

        setSearchTerm('');
    };

    const submit = async (data: PurchaseOrderPayload) => {
        try {
            const payload = {
                formOrigin: { orderService: Number(orderServiceId) },
                products: data.products.map((item) => ({
                    approved: item.preApproved,
                    orderServiceProductSale: item.orderServiceProductSale!,
                    justification: item.justification || '',
                    orderServiceProductPurchase: item.orderServiceProductPurchase!,
                    product: item.id,
                    quantity: item.quantity || 0,
                    billed: item.billed,
                    provision: formatDate(item.provision, 'YYYY-MM-DD'),
                    ...(item.billed && {
                        purchaseValue: item.purchaseValue,
                    }),
                })),
            };

            await createOrUpdatePurchaseOrder(payload);
        } catch (error) {
            console.log('submit', error);
        }
    };

    const filteredProducts = useMemo(() => {
        return products.filter((item) => !selectedProducts.find((prod) => prod.id === item.id!));
    }, [products, selectedProducts]);

    return (
        <>
            <Modal
                contentClassnames="w-[90%]"
                headerLeft={
                    <Text as="h3" variant="h4" className="text-heading">
                        {title}
                    </Text>
                }
                headerRight={
                    <Text as="label" className="pl-0 block text-base-500">
                        Data do pedido: <Text className="font-bold">{formatDate(new Date())}</Text>
                    </Text>
                }
                closeOnClickOutside={false}>
                <FormProvider {...methods}>
                    <form className="p-6 pt-0" onSubmit={handleSubmit(submit)}>
                        <div className="flex sm:justify-end justify-start sm:mb-6 mb-6">
                            <ExternalButton onClick={setIsProductsModalVisible.bind(this, true)}>Ver produtos do escopo de compra</ExternalButton>
                        </div>
                        <Select
                            isLoading={isLoadingProducts}
                            value={debouncedProductName}
                            options={!!debouncedProductName ? filteredProducts : []}
                            label="Produto (código ou nome)"
                            placeholder="Digite o código ou nome do produto"
                            components={!Boolean(searchTerm) ? { NoOptionsMessage: () => null } : undefined}
                            onChange={handleSelectProducts}
                            onInputChange={setSearchTerm}
                        />
                        {Boolean(selectedProducts.length) && (
                            <OrderServicePurchaseOrderTable onRemoveProduct={handleRemoveProduct} selectedProducts={selectedProducts} saleProductsOptions={saleProductsOptions} />
                        )}
                        {Boolean(selectedProducts.length) && <FormButtons isLoading={isSubmitting} containerClassName="mt-6" />}
                    </form>
                </FormProvider>
            </Modal>
            {isProductsModalVisible && <ModalProducts products={servicePurchaseProducts} onClose={() => setIsProductsModalVisible(false)} />}
        </>
    );
};

export default memo(CreateOrUpdateOrderPurchasePage);
