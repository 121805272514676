import TableCell from '@mui/material/TableCell/TableCell';
import { CrudPageProps } from 'types/graphql';
import dictionary from 'utils/dictionary';

const config: CrudPageProps<{}>[] = [
    {
        title: 'Usuários',
        customTableOptions: {
            selectableRows: 'none',
        },
        graphql: {
            table: 'users',
            columns: [
                {
                    name: 'id',
                    options: { display: 'excluded' },
                },
                {
                    name: 'username',
                    label: dictionary.content.graphql['name'],
                },
                {
                    name: 'person.email',
                    label: dictionary.content.graphql['email'],
                },
                {
                    name: 'profile.name',
                    label: dictionary.content.graphql['profile.name'],
                },
                {
                    name: 'actions',
                    label: 'Ações',
                    options: {
                        customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />,
                    },
                },
            ],
        },
    },
];

export default config;
