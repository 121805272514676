import classNames from 'classnames';
import SvgIcoCheckboxFilled from 'components/core/icon/files/ico-checkbox-filled';
import SvgIcoCheckboxOutline from 'components/core/icon/files/ico-checkbox-outline';
import Text from 'components/core/text';
import theme from 'settings/theme';
import { FormField, FormFieldType } from 'types/models/form';

type SubfieldOptionProps = {
    subfield: Pick<FormField, 'type' | 'value' | 'id' | 'name' | 'order' | 'required' | 'options'> & {
        selectedValue: any;
    };
    subOption: {
        id: number;
        label: string;
        value: string;
        subfields?: Array<
            Pick<FormField, 'id' | 'name' | 'type' | 'order' | 'required' | 'options' | 'value'> & {
                selectedValue: any;
            }
        >;
    };
};

const optionClasses = 'box-with-border px-3 py-2';

const SubfieldOption = ({ subfield, subOption }: SubfieldOptionProps) => {
    const isTheSubOptionAnswer = subfield.type === FormFieldType.Checkbox ? subfield.selectedValue.split(',').includes(subOption.value) : subOption.value === subfield.selectedValue;

    const IconSubOption = isTheSubOptionAnswer ? SvgIcoCheckboxFilled : SvgIcoCheckboxOutline;

    return (
        <div className={classNames(optionClasses, 'flex-row-center justify-start mb-1 last:mb-0')} key={subOption.id}>
            <Text as="p" variant="body.regular.xs" className="text-base-500 flex-1">
                {subOption.label}
            </Text>
            <IconSubOption width={20} height={20} color={isTheSubOptionAnswer ? theme.extend.colors.system.success[500] : theme.extend.colors.base[400]} />
        </div>
    );
};

export default SubfieldOption;
