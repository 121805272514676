import { useQuery } from 'react-query';
import api from 'services/api';
import { Task } from 'types/models/task';
import { getTaskKey } from './keys';

const useGetTask = (taskId?: string) => {
    const request = async () => {
        const { data } = await api.get<Task>(`/tasks/${taskId}`);

        return data;
    };

    return useQuery(getTaskKey(taskId), request);
};

export default useGetTask;
