import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import { getPurchaseOrderKey } from './keys';

const useChangePurchaseOrderStatus = (purchaseOrderId?: string) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const request = async (status: 'finalize' | 'cancel') => api.patch(`/purchase-orders/${purchaseOrderId}/status`, { status });

    return useMutation(request, {
        onSuccess: () => {
            queryClient.invalidateQueries(getPurchaseOrderKey(purchaseOrderId));

            showToast(`Ordem de compra atualizada com sucesso`, 'success');
        },
        onError: () => {
            showToast(`Ocorreu um erro ao atualizar a ordem de compra`, 'danger');
        },
    });
};

export default useChangePurchaseOrderStatus;
