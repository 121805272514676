import { useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { getOrderServiceWorkReportsKey } from 'services/queries/order-services/keys';
import Text from 'components/core/text';
import useGetOrderServiceGalleries from 'services/queries/order-services/use-get-order-service-work-reports';
import AddButton from 'components/buttons/add';

import Spinner from 'components/core/spinner';
import ConfirmModal from 'components/core/modal/confirm';
import useDelete from 'services/queries/crud/use-delete';
import useTheme from '@mui/material/styles/useTheme';
import Empty from 'components/empty';
import WorkReportGalleryItem from './gallery-item';

const WorksReportPage = () => {
    const [workReportToDeleteId, setWorkReportToDeleteId] = useState<number>();

    const { orderServiceId, saleOrderId } = useParams();
    const { palette } = useTheme();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const { mutateAsync: deleteOrderServiceWorkReport, isLoading: isDeletingOrderServiceWorkReport } = useDelete({
        apiPayload: { endpoint: `/sale-orders/${saleOrderId}/service-orders/${orderServiceId}/galleries`, useApiTodelete: true },
    });

    const { data: workReports, isLoading: isLoadingWorkReports } = useGetOrderServiceGalleries(orderServiceId, saleOrderId);

    const handleDeleteWorkReport = async () => {
        try {
            if (!Boolean(workReportToDeleteId)) {
                return;
            }

            await deleteOrderServiceWorkReport({ id: workReportToDeleteId });

            queryClient.invalidateQueries(getOrderServiceWorkReportsKey(orderServiceId, saleOrderId));

            setWorkReportToDeleteId(undefined);
        } catch (error) {
            console.error('Houve um erro ao deletar', error);
        }
    };

    const handleGoToCreateWorkReport = () => navigate('novo');

    return (
        <>
            <div className="flex items-center mb-6 flex-row justify-between">
                <Text as="h3" variant="h5" className="text-heading mr-1">
                    Relatório de Obras
                </Text>
                <AddButton onClick={handleGoToCreateWorkReport}>Novo relatório de obra</AddButton>
            </div>
            <div className="max-h-[calc(100%-61px)] overflow-auto">
                <div className="grid grid-cols-1 sm:grid-cols-4 gap-10">
                    {isLoadingWorkReports ? (
                        <Spinner color={palette.secondary[100]} fixed={false} size={20} />
                    ) : Boolean(workReports?.items?.length) ? (
                        workReports?.items!.map((item, index) => <WorkReportGalleryItem key={item.id} index={index} item={item} onSetWorkReportToDeleteId={setWorkReportToDeleteId} />)
                    ) : (
                        <Empty title="Nenhum relatório de obra encontrado." />
                    )}
                </div>
            </div>
            <Outlet />
            {Boolean(workReportToDeleteId) && (
                <ConfirmModal
                    title="Apagar relatório de obra?"
                    description="Você tem certeza que deseja apagar este relatório de obra?"
                    isLoading={isDeletingOrderServiceWorkReport}
                    onClose={() => setWorkReportToDeleteId(undefined)}
                    onConfirmAction={handleDeleteWorkReport}
                />
            )}
        </>
    );
};

export default WorksReportPage;
