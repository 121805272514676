import Text from 'components/core/text';
import { generateAddress } from 'utils/string';
import { Controller, useFormContext } from 'react-hook-form';
import Checkbox from 'components/core/form/checkbox';
import Address from 'components/forms/address';
import { Address as AddressType } from 'types/models/address';
import classNames from 'classnames';

type ServiceOrderAddressProps = {
    address?: AddressType;
    isUpdate?: boolean;
};

const ServiceOrderAddress = ({ address, isUpdate = false }: ServiceOrderAddressProps) => {
    const methods = useFormContext<any>();

    const customerAddress = methods.watch('customerAddress');

    return (
        <div className="mb-4 border border-base-300 rounded-[14px] p-4">
            <div className={classNames('sm:flex sm:items-center', !customerAddress ? 'mb-4' : '')}>
                <div className="sm:flex-1 sm:mb-0 sm:mr-6">
                    <Text as="label" variant="h6" className="text-heading block">
                        Endereço do cliente
                    </Text>
                    {!!address && <Text className="text-base-700">{generateAddress(address)}</Text>}
                </div>
                {!isUpdate && (
                    <div className="border border-base-300 p-[13px] rounded-xl">
                        <Controller
                            name="customerAddress"
                            control={methods.control}
                            render={({ field }) => (
                                <Checkbox {...field} value={field.value as any} label="Usar este endereço" error={(methods as any).formState.errors.customerAddress?.message} checked={field.value} />
                            )}
                        />
                    </div>
                )}
            </div>
            {!customerAddress && <Address className="mt-4 mb-2" {...methods} />}
        </div>
    );
};

export default ServiceOrderAddress;
