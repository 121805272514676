import CloseIcon from '@mui/icons-material/Close';
import ButtonGroup from '@mui/material/ButtonGroup/ButtonGroup';
import { useMemo } from 'react';
import Button from '@mui/material/Button/Button';
import { Controller, useFormContext } from 'react-hook-form';
import { ManageProductsPayload } from './index';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import RemoveButton from 'components/buttons/remove';
import { PurchaseScopeActions } from 'types/models/order-service';

type ManageProductsActionProps = {
    action?: PurchaseScopeActions;
    index: number;
    item: Partial<ManageProductsPayload['purchaseProducts'][0]>;
    onRemove: () => void;
};

const options = [
    { value: PurchaseScopeActions.Reprove, label: 'Reprovar', bgColor: 'hover:bg-system-danger-300 hover:border-system-danger-300' },
    { value: PurchaseScopeActions.Approve, label: 'Aprovar', bgColor: 'hover:bg-system-success-300 hover:border-system-success-300' },
    { value: PurchaseScopeActions.Delete, label: 'Remover', bgColor: 'hover:bg-gray-500  hover:border-gray-500' },
];

const ManageProductsAction = ({ action, index, item, onRemove }: ManageProductsActionProps) => {
    const { control, formState, setValue, getValues, trigger } = useFormContext<ManageProductsPayload>();

    const hasError = !!formState.errors.purchaseProducts?.[index]?.action?.message;
    const isNotNewPurchaseProduct = Boolean(action) && action !== PurchaseScopeActions.New;

    const buttonGroupColor = useMemo(() => {
        if (!action) {
            return 'secondary';
        }

        if (action === PurchaseScopeActions.Reprove) {
            return 'error';
        }

        if (action === PurchaseScopeActions.Delete) {
            return 'inherit';
        }

        return 'success';
    }, [action]);

    const generateButtonTextAction = (action?: PurchaseScopeActions) => {
        if (action === PurchaseScopeActions.Reprove) {
            return 'Reprovado';
        }

        if (action === PurchaseScopeActions.Approve) {
            return 'Aprovado';
        }

        return 'Removido';
    };

    const handleSelectChoice = async (action?: PurchaseScopeActions) => {
        const initialValue = formState.defaultValues?.purchaseProducts?.[index];
        const row = getValues(`purchaseProducts.${index}`);

        if (hasError) {
            await trigger(`purchaseProducts.${index}`);
        }

        const isStateEqual = isEqual(initialValue, row);

        if (action === PurchaseScopeActions.New) {
            return setValue(`purchaseProducts.${index}.action`, action);
        }

        if (action === PurchaseScopeActions.Reprove && isStateEqual) {
            return alert('Não é possível reprovar um produto sem alteração');
        }

        if (!isStateEqual && action === PurchaseScopeActions.Approve) {
            return alert('Não é possível aprovar um produto com alteração');
        }

        setValue(`purchaseProducts.${index}.action`, action);
    };

    return (
        <nav className="relative">
            {Boolean(item.purchaseProductId) ? (
                <>
                    <ButtonGroup className="flex" disableElevation={true} color={buttonGroupColor} variant={!action && hasError ? 'outlined' : 'contained'}>
                        {isNotNewPurchaseProduct && (
                            <Button size="small" className="flex-1">
                                {generateButtonTextAction(action)}
                            </Button>
                        )}
                        {isNotNewPurchaseProduct && (
                            <Button
                                size="small"
                                onClick={() => {
                                    return setValue(`purchaseProducts.${index}.action`, undefined);
                                }}
                                className="p-0">
                                <CloseIcon fontSize="small" />
                            </Button>
                        )}
                    </ButtonGroup>
                    {!action && (
                        <ButtonGroup className="flex">
                            <Controller
                                control={control}
                                name={`purchaseProducts.${index}.action`}
                                render={() => {
                                    return (
                                        <>
                                            {options.map((option, ix) => (
                                                <Button
                                                    key={ix}
                                                    className={classNames(option.bgColor, 'hover:text-white hover:border')}
                                                    onClick={() => {
                                                        handleSelectChoice(option?.value);
                                                    }}>
                                                    {option.label}
                                                </Button>
                                            ))}
                                        </>
                                    );
                                }}
                            />
                        </ButtonGroup>
                    )}
                </>
            ) : (
                <RemoveButton className="opacity-100 left-0 w-full" onClick={onRemove} />
            )}
        </nav>
    );
};

export default ManageProductsAction;
