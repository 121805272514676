import { DocumentPayload } from 'components/documents';
import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import useUploadFile from '../files/use-upload-file';
import { getExpenseDocumentsKey } from './keys';
import { getAllKey } from '../crud/keys';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';

const useCreateOrUpdateExpenseDocument = (expenseId?: string, expenseDocumentId?: string) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const { uploadFile } = useUploadFile();
    const goToParentRoute = useNavigateToParentRoute();

    const isUpdate = Boolean(expenseDocumentId);

    const request = async (payload: DocumentPayload) => {
        if (!!payload?.file?.id) {
            payload.file = payload.file.id;
        } else {
            await uploadFile(
                payload.file,
                ({ id }) => {
                    payload.file = id;
                },
                true
            );
        }

        if (!payload?.file) {
            throw new Error();
        }

        const method = isUpdate ? api.patch : api.post;
        const endpoint = isUpdate ? `/expenses/${expenseId}/documents/${expenseDocumentId}` : `/expenses/${expenseId}/documents`;

        return method(endpoint, payload);
    };

    return useMutation(request, {
        onSuccess: () => {
            queryClient.invalidateQueries(getExpenseDocumentsKey(expenseId));

            queryClient.invalidateQueries(
                getAllKey('expenseDocument', {
                    limit: 10,
                    offset: 0,
                    orderBy: {
                        document: {
                            name: 'ASC',
                        },
                    },
                    where: {
                        expenseId: { _eq: +expenseId! },
                        document: {
                            status: { _neq: -1 },
                        },
                    },
                } as any)
            );

            goToParentRoute();
            showToast(`Documento ${isUpdate ? 'atualizado' : 'criado'} com sucesso`, 'success');
        },
        onError: () => {
            showToast(`Ocorreu um erro ao ${isUpdate ? 'atualizar' : 'criar'} o documento`, 'danger');
        },
    });
};

export default useCreateOrUpdateExpenseDocument;
