import { jsonToGraphQLQuery } from 'json-to-graphql-query';
import { Status } from 'types/general';

export const getDeductionsTotalKey = (saleOrderId?: string, orderServiceId?: string, isDeducted?: boolean) => [
    'get',
    'deductions',
    'totals',
    'saleOrder',
    saleOrderId,
    'orderService',
    orderServiceId,
    'deducted',
    isDeducted,
];

export const getDeductionsTotalQuery = (saleOrderId?: number, orderServiceId?: number, isDeducted?: boolean) =>
    jsonToGraphQLQuery({
        query: {
            __name: 'GetDeductionsTotal',
            totals: {
                __aliasFor: 'orderServiceDeductionAggregate',
                __args: {
                    where: {
                        status: {
                            _eq: Status.Active,
                        },
                        ...(Boolean(saleOrderId) && {
                            saleOrderId: {
                                _eq: saleOrderId,
                            },
                        }),
                        ...(Boolean(orderServiceId) && {
                            orderServiceId: {
                                _eq: orderServiceId,
                            },
                        }),
                        ...(isDeducted && {
                            deducted: {
                                _eq: isDeducted,
                            },
                        }),
                    },
                },
                aggregate: {
                    sum: {
                        value: true,
                    },
                },
            },
        },
    });
