import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { object, string } from 'yup';
import dictionary from 'utils/dictionary';
import DatePicker from 'components/core/datepicker';
import { formatDate } from 'utils/date';
import { pluralize } from 'utils/string';
import SelectedItemsTable from './table';
import useTransactionRequestChange, { UseRequestChangePayload } from 'services/queries/transactions/use-transaction-request-change';
import Textarea from 'components/core/form/textarea';
import { OPsActionsProps } from './report-payment';
import FormButtons from 'components/form-buttons';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

type FormState = Omit<UseRequestChangePayload, 'transactions'>;

const schema = object({
    requestedDate: string().typeError(dictionary.validation.required).required(dictionary.validation.required),
    justification: string().typeError(dictionary.validation.required).required(dictionary.validation.required),
});

const RequestChange = ({ selectedItems, onClose, onSuccess }: OPsActionsProps) => {
    const selectedIds = selectedItems?.map(({ id }) => id) || [];

    const { handleSubmit, control, formState } = useForm<FormState>({
        mode: 'onSubmit',
        defaultValues: {
            requestedDate: dayjs().utc(true).toISOString(),
        },
        resolver: yupResolver(schema),
    });

    const { isLoading: isSubmitting, mutateAsync: requestChange } = useTransactionRequestChange();

    const submit = async (data: FormState) => {
        try {
            const payload: UseRequestChangePayload = {
                ...data,
                requestedDate: formatDate(data.requestedDate, 'YYYY-MM-DD', null),
                transactions: selectedIds,
            };

            await requestChange(payload);
            onSuccess?.();
        } catch (error) {
            console.log('submit', error);
        }
    };

    const getError = (name: keyof FormState) => formState?.errors?.[name]?.message as string;

    return (
        <Modal
            contentClassnames="min-w-[650px]"
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    Solicitar alteração de provisionamento
                </Text>
            }
            closeOnClickOutside={false}
            onClose={onClose}>
            <form className="p-6 pt-0" onSubmit={handleSubmit(submit)}>
                <Text as="p" className="text-base-500 mb-3 text-base block">
                    Total de {pluralize(selectedIds?.length, 'despesas', 'despesa')} selecionadas para pagar
                </Text>
                <SelectedItemsTable items={selectedItems} />
                <Controller
                    name="requestedDate"
                    control={control}
                    render={({ field }) => (
                        <DatePicker
                            containerClassNames="mb-6"
                            inputProps={{
                                label: 'Data solicitada',
                                placeholderText: 'Selecione uma data',
                                error: getError('requestedDate'),
                                ...field,
                            }}
                        />
                    )}
                />
                <Controller
                    name="justification"
                    control={control}
                    render={({ field }) => <Textarea {...field} className="mb-6" label="Justificativa" placeholder="Adicione alguma justificativa" error={getError('justification')} />}
                />
                <FormButtons
                    isLoading={isSubmitting}
                    cancelButton={{
                        onClick: onClose,
                    }}
                />
            </form>
        </Modal>
    );
};

export default RequestChange;
