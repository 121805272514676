import TableCell from '@mui/material/TableCell/TableCell';
import ExternalButton from 'components/buttons/external';
import DownloadButton from 'components/download';
import { CrudPageProps } from 'types/graphql';

export const saleOrderDocumentsListConfig = (handleSetContent: any, saleOrderId?: string): CrudPageProps<{}> => ({
    graphql: {
        table: 'saleOrderDocument',
        searchableField: 'document.name',
        statusPath: 'document.status',
        columns: [
            {
                name: 'id',
                options: { display: 'excluded' },
            },
            {
                name: 'document.name',
                label: 'Nome',
            },
            {
                name: 'document.classification.name',
                label: 'Unidade',
            },
            {
                name: 'document.description',
                label: 'Descrição',
                options: { customBodyRender: (value) => <ExternalButton onClick={handleSetContent({ title: 'Descrição', content: value })}>Visualizar</ExternalButton> },
            },
            {
                name: 'document.observation',
                label: 'Observações',
                options: { customBodyRender: (value) => <ExternalButton onClick={handleSetContent({ title: 'Observação', content: value })}>Visualizar</ExternalButton> },
            },
            {
                name: 'document.file.id',
                options: { display: 'excluded' },
            },
            {
                name: 'document.file.originalName',
                label: 'Arquivo',
                options: {
                    customBodyRender: (value, tableMeta) => {
                        if (!value) {
                            return '-';
                        }

                        const [, , , , , fileId] = tableMeta.rowData;

                        return <DownloadButton fileName={value} fileId={fileId} />;
                    },
                },
            },
            {
                name: 'actions',
                label: 'Ações',
                options: {
                    customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />,
                },
            },
        ],
        where: {
            saleOrderId: { _eq: saleOrderId },
        },
    },
    api: {
        useApiTodelete: true,
        endpoint: `/sale-orders/${saleOrderId}/documents`,
    },
    title: 'Documentos',
});
