import { ChangeEvent, useEffect, useLayoutEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import useTheme from '@mui/material/styles/useTheme';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import Text from 'components/core/text';
import Address from 'components/forms/address';
import Modal from 'components/core/modal';
import Contacts from 'components/contacts';
import Spinner from 'components/core/spinner';
import Input from 'components/core/form/input';
import Dropzone from 'components/dropzone';
import RemoveButton from 'components/buttons/remove';
import customerOrProviderSchema from './utils';
import { generateFileUrl } from 'utils/file';
import { Customer } from 'types/models/customer';
import { Provider } from 'types/models/provider';
import Phones from 'components/phones';
import Emails from 'components/emails';
import Select from 'components/core/form/select';
import { booleanOptions, contractTypes } from 'utils/statics';
import { PersonContractType } from 'types/models/person';
import FormButtons from 'components/form-buttons';
import DownloadButton from 'components/download';
import BankAccountForm from '../bank-account';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';
import { capitalize, formatCEP, isValidCEP, isValidCNPJ } from '@brazilian-utils/brazilian-utils';
import useGetCNPJInformations from 'services/queries/providers/use-get-cnpj-informations';
import isEmpty from 'lodash/isEmpty';

type CompanyFormProps = {
    isBuilding: boolean;
    isSubmitting: boolean;
    title: string;
    contactFieldName: string;
    bankAccountFieldName: string;
    onSubmit: (data: Partial<Customer> | Partial<Provider>) => void;
    defaultValues?: any;
    showImage?: boolean;
    onClose?: () => void;
    isCustomer?: boolean;
};

const CustomerOrProviderForm = ({
    bankAccountFieldName,
    contactFieldName,
    isBuilding,
    isSubmitting,
    title,
    onSubmit,
    defaultValues,
    showImage = false,
    isCustomer = false,
    onClose,
}: CompanyFormProps) => {
    const { palette } = useTheme();
    const { customerId, providerId } = useParams();
    const goToParentRoute = useNavigateToParentRoute();

    const addressZipRef = useRef<HTMLInputElement | null>(null);

    const isUpdate = !!customerId || !!providerId;

    const methods = useForm<any>({
        mode: 'onSubmit',
        resolver: yupResolver(customerOrProviderSchema(bankAccountFieldName, defaultValues?.id, isUpdate, isCustomer)),
        defaultValues,
    });

    const { control, formState, handleSubmit, reset, setValue } = methods;

    const { mutateAsync: getCNPJInformations, isLoading: isLoadingProviderInformations } = useGetCNPJInformations();

    const handleClose = () => {
        if (onClose) {
            return onClose();
        }

        goToParentRoute();
    };

    const contractType = useWatch({ control, name: 'type' });
    const zipCode = useWatch({ control, name: 'address.zip' });

    const handleBlurJuridicDocument: React.FocusEventHandler<HTMLInputElement> = async ({ target }) => {
        if (!isUpdate && isValidCNPJ(target.value)) {
            try {
                const data = await getCNPJInformations(target.value);

                if (!isEmpty(data)) {
                    const formattedCep = formatCEP(data.address.zip);
                    const fantasyName = data.alias || data.company.name;

                    setValue('address.zip', formattedCep);
                    setValue('juridicFantasyName', capitalize(fantasyName));
                    setValue('juridicName', capitalize(data.company.name));
                    setValue('title', capitalize(fantasyName));
                }
            } catch (error) {
                setValue('juridicFantasyName', '');
                setValue('juridicName', '');
                setValue('title', '');

                console.error('getCNPJDetailsError: ', error);
            }
        }
    };

    const handleChangeJuridicFantasyName = (onChange: (...event: any[]) => void) => {
        return ({ target }: ChangeEvent<HTMLInputElement>) => {
            onChange(target.value);
            setValue('title', target.value);
        };
    };

    useEffect(() => {
        if (!!defaultValues?.id) {
            reset(defaultValues);
        }
    }, [defaultValues, reset]);

    useLayoutEffect(() => {
        if (!isUpdate && isValidCEP(zipCode)) {
            addressZipRef.current?.focus();
        }
    }, [zipCode, addressZipRef, isUpdate]);

    return (
        <>
            <Modal contentClassnames="w-[1200px]" onClose={handleClose} closeOnClickOutside={false}>
                {isBuilding ? (
                    <div className="p-4">
                        <Spinner color={palette.secondary[100]} fixed={false} size={20} />
                    </div>
                ) : (
                    <FormProvider {...methods}>
                        <form className="px-4 py-5 md:px-7" onSubmit={handleSubmit(onSubmit)}>
                            <Text as="h3" variant="h5" className="text-heading mb-5">
                                {title}
                            </Text>
                            <div className="md:grid md:grid-cols-4 gap-4 mb-4">
                                <Controller
                                    name="type"
                                    control={control}
                                    render={({ field }) => {
                                        const value = contractTypes.find((item) => item.value === field.value);

                                        return (
                                            <Select
                                                {...field}
                                                value={value}
                                                options={contractTypes}
                                                label={`Tipo de ${isCustomer ? 'cliente' : 'fornecedor'}`}
                                                placeholder="Selecione uma opção"
                                                parentClassName="mb-4 md:mb-0"
                                                error={(formState as any).errors.type?.message}
                                                onChange={(option: any) => field.onChange(option.value)}
                                            />
                                        );
                                    }}
                                />
                                {contractType === PersonContractType.Juridic && (
                                    <>
                                        <Controller
                                            name="juridicDocument"
                                            control={control}
                                            render={({ field }) => (
                                                <Input
                                                    {...field}
                                                    autoComplete="nope"
                                                    mask="99.999.999/9999-99"
                                                    label="CNPJ"
                                                    error={(formState as any).errors.juridicDocument?.message}
                                                    inputWrapperClasses="mb-4 md:mb-0"
                                                    onBlur={handleBlurJuridicDocument}
                                                />
                                            )}
                                        />
                                        <Controller
                                            name="juridicName"
                                            control={control}
                                            render={({ field }) => (
                                                <Input
                                                    {...field}
                                                    disabled={isLoadingProviderInformations}
                                                    autoFocus={false}
                                                    error={(formState as any).errors.juridicName?.message}
                                                    label="Razão Social"
                                                    inputWrapperClasses="mb-4 md:mb-0"
                                                />
                                            )}
                                        />
                                        <Controller
                                            name="juridicFantasyName"
                                            control={control}
                                            render={({ field }) => (
                                                <Input
                                                    {...field}
                                                    autoFocus={false}
                                                    disabled={isLoadingProviderInformations}
                                                    error={(formState as any).errors.juridicFantasyName?.message}
                                                    label="Nome fantasia"
                                                    inputWrapperClasses="mb-4 md:mb-0"
                                                    onChange={handleChangeJuridicFantasyName(field.onChange)}
                                                />
                                            )}
                                        />
                                        {isCustomer && (
                                            <Controller
                                                name="hasStateRegistration"
                                                control={control}
                                                render={({ field }) => {
                                                    const value = booleanOptions.find((item) => item.value === field.value);

                                                    return (
                                                        <Select
                                                            {...field}
                                                            label="Possui Inscrição Estadual"
                                                            value={value}
                                                            options={booleanOptions}
                                                            onChange={(option: any) => field.onChange(option.value)}
                                                            error={(formState as any).errors?.hasStateRegistration?.message}
                                                        />
                                                    );
                                                }}
                                            />
                                        )}
                                    </>
                                )}
                                {contractType === PersonContractType.Personal && (
                                    <Controller
                                        name="document"
                                        control={control}
                                        render={({ field }) => (
                                            <Input
                                                {...field}
                                                autoFocus={false}
                                                error={(formState as any).errors.document?.message}
                                                label="CPF"
                                                autoComplete="nope"
                                                mask="999.999.999-99"
                                                inputWrapperClasses="mb-4 md:mb-0"
                                            />
                                        )}
                                    />
                                )}
                                {Boolean(contractType) && contractType !== PersonContractType.Juridic && (
                                    <Controller
                                        name="title"
                                        control={control}
                                        render={({ field }) => (
                                            <Input {...field} autoFocus={false} error={(formState as any).errors.title?.message} label="Título" inputWrapperClasses="mb-4 md:mb-0" />
                                        )}
                                    />
                                )}
                            </div>
                            <div className="md:grid md:grid-cols-2 gap-4 mb-4">
                                <div className="border border-base-300 rounded-[14px] p-4 mb-4 md:mb-0">
                                    <Text as="h5" variant="h6" className="text-heading mb-3">
                                        Telefone(s)
                                    </Text>
                                    <Phones accessor="phones" index={0} />
                                </div>
                                <div className="border border-base-300 rounded-[14px] p-4">
                                    <Text as="h5" variant="h6" className="text-heading mb-3">
                                        E-mail(s)
                                    </Text>
                                    <Emails />
                                </div>
                            </div>
                            {showImage && (
                                <div className="border border-base-300 rounded-[14px] p-4 mb-4 relative">
                                    <Text as="label" variant="body.regular.sm" className="block mb-4 text-base-500">
                                        Logo
                                    </Text>
                                    <Controller
                                        name="file"
                                        control={control}
                                        render={({ field }) => {
                                            const handleChangeFile = (filesList: File[]) => {
                                                const [file] = filesList || [];

                                                field.onChange(file);
                                            };

                                            const handleClickRemove = () => {
                                                setValue('file', '');
                                            };

                                            const { value } = field;

                                            if (!!value) {
                                                const currentImage = !!value?.id ? generateFileUrl(value?.filename, value.path) : URL.createObjectURL(field?.value);

                                                return (
                                                    <div className="flex items-center">
                                                        {!!value?.id ? (
                                                            <DownloadButton fileName={value?.filename!} fileId={value.id} />
                                                        ) : (
                                                            <a className="flex items-center" href={currentImage} rel="noreferrer" target="_blank">
                                                                <div className="w-12 h-12 bg-center bg-cover rounded-[14px] mr-4" style={{ backgroundImage: `url(${currentImage})` }} />
                                                                <div>
                                                                    <span>{value?.originalName ?? value?.name}</span>
                                                                </div>
                                                            </a>
                                                        )}
                                                        <RemoveButton onClick={handleClickRemove} className="opacity-100 right-[18px]" />
                                                    </div>
                                                );
                                            }

                                            return <Dropzone multiple={false} onUploadFiles={handleChangeFile} accept={{ 'image/*': ['.jpeg', '.png'] }} />;
                                        }}
                                    />
                                </div>
                            )}
                            {!isUpdate && (
                                <>
                                    <div className="border border-base-300 rounded-[14px] p-4 mb-4">
                                        <Text as="h5" variant="h6" className="text-heading mb-3">
                                            Endereço
                                        </Text>
                                        <Address {...methods} ref={addressZipRef} className="mb-0" />
                                    </div>
                                    <Contacts fieldName={contactFieldName} className="mb-4" />
                                    {!isCustomer && (
                                        <div className="border border-base-300 rounded-[14px] p-4 mb-6">
                                            <Text as="h5" variant="h6" className="text-heading mb-3">
                                                Conta bancária
                                            </Text>
                                            <BankAccountForm baseFormPath={bankAccountFieldName} isCompanyForm={false} />
                                        </div>
                                    )}
                                </>
                            )}
                            <FormButtons
                                isLoading={isSubmitting}
                                cancelButton={{
                                    onClick: handleClose,
                                }}
                            />
                        </form>
                    </FormProvider>
                )}
            </Modal>
        </>
    );
};

export default CustomerOrProviderForm;
