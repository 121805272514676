import Datatable from 'components/core/table/datatable';
import { Outlet, useParams } from 'react-router-dom';
import { useState } from 'react';
import useGetOrderServicesReceipts from 'services/queries/order-services/use-get-order-services-receipts';
import { serviceOrderRevenuesListColumns } from 'pages/private/sales-orders/utils';
import useConfig from 'store/config/use-config';
import { Rule } from 'types/permissions';
import ServiceOrderSaleScopePage from '../sales-orders/service-order/sale-scope';
import Text from 'components/core/text';

type RevenuesPageProps = {
    isOrderService: boolean;
    withSaleScope?: boolean;
};

const RevenuesPage = ({ isOrderService, withSaleScope = false }: RevenuesPageProps) => {
    const { config } = useConfig();
    const { orderServiceId, saleOrderId } = useParams();

    const [page, setPage] = useState(1);

    const baseEndpoint = `/sale-orders/${saleOrderId}`;
    const endpoint = isOrderService ? `${baseEndpoint}/service-orders/${orderServiceId}/receipts` : `${baseEndpoint}/receipts`;

    const { data, isLoading: isLoadingList } = useGetOrderServicesReceipts({
        endpoint,
        filters: {
            page,
            totalPerPage: config.tables.rowsPerPage,
        },
    });

    const { items = [] } = data || {};

    return (
        <>
            <div className="mb-8">
                <div className="flex items-center mb-6 flex-row justify-between">
                    <Text as="h3" variant="h5" className="text-heading mr-1">
                        Receitas
                    </Text>
                </div>
                <Datatable
                    actions={[Rule.Show]}
                    withBorder={false}
                    data={items}
                    options={{
                        sort: true,
                        filter: false,
                        search: false,
                        print: false,
                        download: false,
                        viewColumns: false,
                        serverSide: true,
                        searchAlwaysOpen: false,
                        selectableRows: 'none',
                        enableNestedDataAccess: '.',
                        pagination: true,
                        elevation: 21,
                        count: data?.total,
                        page,
                        onChangePage: setPage,
                    }}
                    columns={serviceOrderRevenuesListColumns}
                    loading={isLoadingList}
                    className="toolbar-without-search"
                />
            </div>

            {withSaleScope && <ServiceOrderSaleScopePage isOrderServiceReceiptsPage={true} />}

            <Outlet />
        </>
    );
};

export default RevenuesPage;
