import { useCallback, useMemo, useState } from 'react';
import { object, string } from 'yup';
import { Link } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import IconButton from '@mui/material/IconButton/IconButton';
import Button from 'components/core/button';
import Input from 'components/core/form/input';
import theme from 'settings/theme';
import dictionary from 'utils/dictionary';
import { AuthPayload } from 'types/models/auth';
import logoFull from 'assets/images/logo-full-horizontal.png';
import useResponsive from 'hooks/responsive/use-responsive';
import SvgIcoShow from 'components/core/icon/files/ico-show';
import SvgIcoHide from 'components/core/icon/files/ico-hide';

type LoginProps = {
    isLoading: boolean;
    onSubmit: (payload: AuthPayload) => void;
};

const schema = object({
    username: string().required(dictionary.validation.required),
    password: string().required(dictionary.validation.required),
});

const Login = ({ isLoading, onSubmit }: LoginProps) => {
    const { isMobile } = useResponsive();
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const { control, handleSubmit, formState } = useForm<AuthPayload>({
        mode: 'onSubmit',
        defaultValues: {
            password: '',
            username: '',
        },
        resolver: yupResolver(schema),
    });

    const handlePasswordVisibility = useCallback(() => setIsPasswordVisible(!isPasswordVisible), [isPasswordVisible]);

    const Toggle = useMemo(() => {
        const Icon = isPasswordVisible ? SvgIcoShow : SvgIcoHide;

        return (
            <IconButton color="primary" className="p-1.5 m-1.5" onClick={handlePasswordVisibility}>
                <Icon color={theme.extend.colors.primary[500]} width={16} height={16} />
            </IconButton>
        );
    }, [isPasswordVisible, handlePasswordVisibility]);

    return (
        <section className={styles.container}>
            {isMobile && (
                <div className={styles.logo}>
                    <img alt={theme.metadata.name} src={logoFull} />
                </div>
            )}
            <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                {!isMobile && (
                    <div className={styles.logo}>
                        <img alt={theme.metadata.name} src={logoFull} />
                    </div>
                )}
                <div className="w-full mb-4">
                    <Controller name="username" control={control} render={({ field }) => <Input {...field} label="E-mail ou usuário" error={formState.errors.username?.message} />} />
                </div>
                <div className="w-full mb-5">
                    <Controller
                        name="password"
                        control={control}
                        render={({ field }) => <Input {...field} label="Senha" error={formState.errors.password?.message} type={isPasswordVisible ? 'text' : 'password'} right={Toggle} />}
                    />
                </div>
                <div className={styles.forgotPassword}>
                    <Link className="text-primary-500" to="/resetar-senha">
                        Esqueceu a senha?
                    </Link>
                </div>
                <Button disabled={isLoading} loading={isLoading} type="submit" size="medium" variant="contained" color="primary" fullWidth={true}>
                    Entrar
                </Button>
            </form>
        </section>
    );
};

const styles = {
    container: 'w-full h-full bg-secondary-100 bg-public bg-opacity-40 flex flex-col items-end sm:px-40 sm:justify-center md:px-60 xl:items-center',
    forgotPassword: 'flex justify-end mb-5',
    form: 'bg-white w-full rounded-t-2xl p-7 sm:rounded-b-2xl sm:p-20 xl:max-w-2xl',
    logo: 'flex items-center justify-center w-full flex-1 sm:flex-none sm:mb-10',
    title: 'mb-4 sm:text-center text-heading sm:mb-10',
};

export default Login;
