import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { useLocation, useOutletContext, useParams } from 'react-router-dom';
import AddressForm from 'components/forms/address';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { addressSchema } from 'components/forms/address/utils';
import FormButtons from 'components/form-buttons';
import useCreateOrUpdateAddress from 'services/queries/address/use-create-or-update-address';
import { AddressClassNames } from 'types/models/address';
import { GraphqlPaginationVariables } from 'types/graphql';
import useGetAddress from 'services/queries/address/use-get-entity-address';
import { useEffect } from 'react';
import Spinner from 'components/core/spinner';
import useTheme from '@mui/material/styles/useTheme';

type AddressPayload = {
    address: {
        neighbourhood: string;
        number: string;
        cityId: number;
        stateId: number;
        street: string;
        zip: string;
        compliment?: string;
    };
};

const schema = object({ address: addressSchema });

const CreateOrUpdateAddressPage = () => {
    const { palette } = useTheme();
    const { pathname } = useLocation();
    const { entityAddressId, customerId, providerId } = useParams();

    const params = useOutletContext<GraphqlPaginationVariables<{}>>();

    const isCustomer = pathname.includes('clientes');
    const graphqlTable = isCustomer ? 'addressCustomer' : 'addressProvider';

    const { data: entityAddress, isLoading: isLoadingAddress } = useGetAddress(isCustomer, entityAddressId);
    const { mutateAsync: createOrUpdateAddress, isLoading: isSubmitting } = useCreateOrUpdateAddress(graphqlTable, params, Number(entityAddressId));

    const methods = useForm<AddressPayload>({
        mode: 'onSubmit',
        resolver: yupResolver(schema) as any,
        defaultValues: {
            address: {
                compliment: '',
                neighbourhood: '',
                number: '',
                street: '',
                zip: '',
            },
        },
    });

    const { reset, handleSubmit } = methods;

    const isUpdate = !!entityAddressId;
    const id = isCustomer ? customerId : providerId;

    const submit = async (data: AddressPayload) => {
        try {
            const entityIdKey = isCustomer ? 'customerId' : 'providerId';
            const className = isCustomer ? AddressClassNames.Customer : AddressClassNames.Provider;

            const payload = {
                ...data.address,
                className,
                ...(!isUpdate && {
                    [graphqlTable]: {
                        data: { [entityIdKey]: id },
                    },
                }),
            };

            await createOrUpdateAddress(payload);
        } catch (error) {
            console.log('submit', error);
        }
    };

    useEffect(() => {
        if (!!entityAddress) {
            reset({ address: entityAddress.address });
        }
    }, [entityAddress, reset]);

    return (
        <Modal
            contentClassnames="w-[980px]"
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    {isUpdate ? 'Atualizar' : 'Novo'} endereço
                </Text>
            }
            closeOnClickOutside={false}>
            {isLoadingAddress ? (
                <div className="pb-8">
                    <Spinner color={palette.primary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <form onSubmit={handleSubmit(submit)}>
                    <div className="px-6">
                        <AddressForm {...methods} className="border border-base-300 p-4 rounded-[14px]" />
                    </div>
                    <FormButtons isLoading={isSubmitting} containerClassName="p-6" />
                </form>
            )}
        </Modal>
    );
};

export default CreateOrUpdateAddressPage;
