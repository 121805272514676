import Tabs from '@mui/material/Tabs/Tabs';
import Tab from '@mui/material/Tab/Tab';
import { Outlet } from 'react-router-dom';
import { useTabRoute, UseTabRouteChildren } from 'hooks/tab/use-tab-route';
import useCreateNav from 'hooks/nav/use-create-nav';
import { Page } from 'types/models/page';
import { Provider as ProviderModel } from 'types/models/provider';
import useResponsive from 'hooks/responsive/use-responsive';

type ProviderProps = {
    provider: Partial<ProviderModel>;
    subpages: Page[];
};

const Provider = ({ provider, subpages }: ProviderProps) => {
    const { isMobile } = useResponsive();

    const nav = useCreateNav<UseTabRouteChildren>(subpages, (subpage) => ({
        label: subpage.title,
        ...(!!subpage.link && { path: subpage.link }),
    }));

    const { handleChangeTab, activeTabIndex, tabLabels } = useTabRoute(nav);

    return (
        <div className="flex flex-col bg-base-100 -ml-6 -mr-4 -my-4 md:-my-6 overflow-hidden md:h-[calc(100%+48px)] md:max-h-[calc(100%+48px)] md:flex-row">
            <div className="bg-base-200 md:w-[242px] md:h-full md:overflow-y-auto md:overflow-x-visible">
                <Tabs
                    classes={{ indicator: 'hidden' }}
                    visibleScrollbar={!isMobile}
                    orientation={isMobile ? 'horizontal' : 'vertical'}
                    variant="scrollable"
                    value={activeTabIndex}
                    onChange={handleChangeTab}>
                    {tabLabels.map((label, index) => {
                        const isLastChild = tabLabels.length - 1 === index;

                        return (
                            <Tab
                                classes={{ root: 'bg-base-200', selected: '!bg-base-100' }}
                                className={`${!isLastChild && !isMobile && 'border-b border-solid border-b-base-300'} items-start md:w-full p-6 max-w-none`}
                                label={label}
                                key={`${label}_${index}`}
                            />
                        );
                    })}
                </Tabs>
            </div>
            <section className="flex-1 border-x border-x-base-300 overflow-y-auto relative md:w-full h-full flex pl-6 pr-4 py-6 flex-col">
                <Outlet context={provider} />
            </section>
        </div>
    );
};

export default Provider;
