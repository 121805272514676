import Tooltip from '@mui/material/Tooltip/Tooltip';
import classNames from 'classnames';
import Icon from './index';

const allIcons = [
    'ico-add-user',
    'ico-check-square',
    'ico-delete',
    'ico-filter',
    'ico-instagram',
    'ico-pantone',
    'ico-send',
    'ico-tasks-in-progress',
    'ico-alert',
    'ico-check',
    'ico-discount',
    'ico-flag',
    'ico-link',
    'ico-pdf',
    'ico-settings',
    'ico-tasks-outline',
    'ico-archive',
    'ico-chevron-down',
    'ico-dollar-sign',
    'ico-folder-move',
    'ico-list-2',
    'ico-phone',
    'ico-share',
    'ico-tasks-plus',
    'ico-arrow-first',
    'ico-chevron-left',
    'ico-dollar',
    'ico-folder-outline',
    'ico-list',
    'ico-pin',
    'ico-shopping',
    'ico-tasks',
    'ico-arrow-last',
    'ico-chevron-right',
    'ico-dots-horizontal',
    'ico-folder',
    'ico-lock',
    'ico-plus',
    'ico-show',
    'ico-text',
    'ico-arrow-left',
    'ico-chevron-top',
    'ico-dots',
    'ico-grid',
    'ico-logout',
    'ico-printer',
    'ico-smartphone',
    'ico-trash',
    'ico-arrow-right',
    'ico-children',
    'ico-download',
    'ico-gym',
    'ico-mail',
    'ico-project',
    'ico-smile',
    'ico-travel',
    'ico-attachment',
    'ico-clock',
    'ico-drag',
    'ico-hide',
    'ico-map',
    'ico-read-mail',
    'ico-sort',
    'ico-twitter',
    'ico-bell',
    'ico-close-circle',
    'ico-edit',
    'ico-home',
    'ico-menu',
    'ico-refresh',
    'ico-square',
    'ico-upload-cloud',
    'ico-calendar',
    'ico-close',
    'ico-excel',
    'ico-image',
    'ico-minus',
    'ico-reply-all',
    'ico-star-outline',
    'ico-upload',
    'ico-camera',
    'ico-coffee',
    'ico-external',
    'ico-important-outline',
    'ico-more',
    'ico-reply',
    'ico-star',
    'ico-user-outline',
    'ico-caret-down',
    'ico-contacts',
    'ico-facebook',
    'ico-important',
    'ico-move',
    'ico-save',
    'ico-sun',
    'ico-user',
    'ico-chart',
    'ico-copy',
    'ico-file-text',
    'ico-inbox',
    'ico-music',
    'ico-search',
    'ico-tag',
    'ico-version',
    'ico-chat',
    'ico-csv',
    'ico-filter-2',
    'ico-info',
    'ico-notebook',
    'ico-send-outline',
    'ico-tasks-check',
    'ico-zap',
    'ico-cart',
    'ico-push-pin',
] as const;

export type Icons = typeof allIcons;

type AllIconsProps = {
    selected: string;
    onSelectIcon: (name: string) => void;
};

const AllIcons = ({ selected, onSelectIcon }: AllIconsProps) => {
    return (
        <div className="grid grid-cols-4 md:grid-cols-8 gap-4">
            {allIcons.map((name) => {
                const buttonStyles = classNames(
                    'flex justify-center p-2 text-base-500 rounded-md hover:text-primary-300 hover:bg-primary-100 hover:bg-opacity-20',
                    selected === name ? 'border-2 border-solid border-primary-300 text-primary-300' : ''
                );

                return (
                    <Tooltip placement="top" title={name} disableFocusListener={true} key={name}>
                        <button type="button" className={buttonStyles} onClick={() => onSelectIcon(name)}>
                            <Icon name={name} width={20} height={20} />
                        </button>
                    </Tooltip>
                );
            })}
        </div>
    );
};

export default AllIcons;
