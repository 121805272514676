import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { useState } from 'react';
import { expenseTransactionsColumns } from './utils';
import useGetExpenseTransactions from 'services/queries/expenses/use-get-expense-transactions';
import Datatable from 'components/core/table/datatable';
import { Rule } from 'types/permissions';
import QueryString from 'qs';
import useConfig from 'store/config/use-config';
import { ExpenseDetailsResponse } from 'services/queries/expenses/types';

const ExpenseTransactionsPage = () => {
    const [page, setPage] = useState(1);

    const { config } = useConfig();
    const { expenseId } = useParams();
    const [searchText, setSearchText] = useState('');
    const { transferBetweenStocks } = useOutletContext<ExpenseDetailsResponse>();

    const decimalPlaces = transferBetweenStocks ? 4 : 2;

    const queryFilters = QueryString.stringify(
        {
            filter: {
                ...(Boolean(searchText) && { searchText }),
            },
            page,
            totalPerPage: config.tables.rowsPerPage,
        },
        { addQueryPrefix: true, encode: false, arrayFormat: 'brackets', skipNulls: true }
    );

    const { data: transactions, isLoading: isLoading } = useGetExpenseTransactions(expenseId, queryFilters);

    return (
        <>
            <Datatable
                actions={[Rule.Show]}
                columns={expenseTransactionsColumns(decimalPlaces)}
                data={transactions?.items || []}
                loading={isLoading}
                options={{
                    sort: true,
                    selectableRowsHeader: false,
                    selectableRowsHideCheckboxes: true,
                    filter: false,
                    search: true,
                    searchAlwaysOpen: true,
                    print: false,
                    download: false,
                    viewColumns: false,
                    serverSide: true,
                    enableNestedDataAccess: '.',
                    pagination: true,
                    elevation: 21,
                    count: transactions?.total,
                    page,
                    searchPlaceholder: 'Buscar transação',
                    onChangePage: setPage,
                    onSearchChange: (text) => setSearchText(text || ''),
                }}
            />
            <Outlet />
        </>
    );
};

export default ExpenseTransactionsPage;
