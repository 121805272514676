import Table from '@mui/material/Table/Table';
import TableRow from '@mui/material/TableRow/TableRow';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import Card from 'components/core/card';
import Text from 'components/core/text';
import { Fragment, ReactElement } from 'react';
import { formatMoney, formatNumberToPTBR } from 'utils/money';
import classNames from 'classnames';

export type ServiceOrderFinancialBalanceTotalsTableItem = {
    id: number;
    value: number;
    label: string;
    percentage?: number;
    className?: string;
    secondValue?: number;
    secondClassName?: string;
    percentageSecondValue?: number;
    customLabelElement?: ReactElement;
    rowClassName?: string;
};

type ServiceOrderFinancialBalanceTotalsTableProps = {
    items: ServiceOrderFinancialBalanceTotalsTableItem[];
    className?: string;
};

const rowClasses = (classes?: string) => classNames('hover:bg-base-200 hover:bg-opacity-50', classes);

const ServiceOrderFinancialBalanceTotalsTable = ({ items = [], className }: ServiceOrderFinancialBalanceTotalsTableProps) => {
    if (!items.length) {
        return null;
    }

    return (
        <Card className={classNames('p-0', className)}>
            <Table>
                <TableBody>
                    {items.map(({ id, label = '-', value, percentage, className, secondValue, secondClassName, percentageSecondValue, customLabelElement, rowClassName }) => {
                        return (
                            <Fragment key={id}>
                                <TableRow classes={{ root: rowClasses(rowClassName) }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell>
                                        {customLabelElement || (
                                            <Text as="span" className="text-gray-600 font-medium uppercase">
                                                {label}
                                            </Text>
                                        )}
                                    </TableCell>
                                    <TableCell className="text-right">
                                        <Text as="span" className={classNames('text-base-500', className)}>
                                            {formatMoney(Number(value || 0))} {typeof percentage !== 'undefined' && <span className="text-xs">({formatNumberToPTBR(percentage)}%)</span>}
                                        </Text>
                                    </TableCell>
                                    {!!secondValue && (
                                        <TableCell className="text-right">
                                            <Text as="span" className={classNames('text-base-500', secondClassName)}>
                                                {formatMoney(secondValue)} {!!percentageSecondValue && <span className="text-xs">({formatNumberToPTBR(percentageSecondValue)}%)</span>}
                                            </Text>
                                        </TableCell>
                                    )}
                                </TableRow>
                            </Fragment>
                        );
                    })}
                </TableBody>
            </Table>
        </Card>
    );
};

export default ServiceOrderFinancialBalanceTotalsTable;
