import Modal from 'components/core/modal';
import ProductsSimpleTable from '../simple-table';
import { PurchaseProductRequest } from 'types/models/purchase-order';
import Text from 'components/core/text';

type PurchaseScopeRequestsModalProps = {
    requests: PurchaseProductRequest[];
    onClose: () => void;
};

const PurchaseScopeRequestsModal = ({ requests = [], onClose }: PurchaseScopeRequestsModalProps) => {
    return (
        <Modal
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    Versões anteriores
                </Text>
            }
            onClose={onClose}
            closeOnClickOutside={false}
            contentClassnames="w-[1100px]">
            <div className="bg-base-100 rounded-[14px] border border-base- overflow-hidden mx-4 mb-4">
                <ProductsSimpleTable withVersionsHeader={true} requests={requests} />
            </div>
        </Modal>
    );
};

export default PurchaseScopeRequestsModal;
