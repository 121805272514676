import Datatable from 'components/core/table/datatable';
import { useState } from 'react';
import useConfig from 'store/config/use-config';
import { financialBalanceStockMovementsColumns } from '../../../utils';
import Text from 'components/core/text';
import { OrderServiceFinancialBalance } from 'types/models/order-service';
import { datatableColumnSort } from 'utils/datatable';

type FinancialBalanceStockMovementsProps = {
    movements: OrderServiceFinancialBalance['stockProduct']['movements'];
    productUnit?: string;
};

const FinancialBalanceStockMovements = ({ movements = [], productUnit = '-' }: FinancialBalanceStockMovementsProps) => {
    const [page, setPage] = useState(0);

    const { config } = useConfig();

    return (
        <div className="border border-gray-200 rounded-xl">
            <Text variant="body.medium.2xs" className="text-heading p-4 block">
                Movimentações
            </Text>
            <Datatable
                data={movements}
                withPagination={true}
                columns={financialBalanceStockMovementsColumns(productUnit)}
                options={{
                    sort: true,
                    filter: false,
                    search: true,
                    searchAlwaysOpen: true,
                    print: false,
                    download: false,
                    viewColumns: false,
                    serverSide: false,
                    enableNestedDataAccess: '.',
                    pagination: true,
                    elevation: 0,
                    searchPlaceholder: 'Buscar movimento',
                    page,
                    rowsPerPage: config.tables.rowsPerPage,
                    onChangePage: setPage,
                    customSort: datatableColumnSort,
                }}
            />
        </div>
    );
};

export default FinancialBalanceStockMovements;
