import { RouteObject } from 'react-router-dom';
import { Rule } from 'types/permissions';
import Page from 'pages/shared/page';
import CreateOrUpdateExpensePage from 'pages/private/expenses/crud';
import ExpenseDetails from 'pages/private/expenses/details';
import ExpenseTabDetails from 'pages/private/expenses/details/tabs/details';
import ExpenseTransactionsPage from 'pages/private/expenses/details/tabs/transactions';
import ExpenseProducts from 'pages/private/expenses/details/tabs/products';
import ExpenseDocuments from 'pages/private/expenses/details/tabs/documents';
import ExpenseLogsPage from 'pages/private/expenses/details/tabs/logs';
import ExpenseDocumentsForm from 'pages/private/expenses/details/tabs/documents/form';
import PaymentOrderPage from 'pages/private/payment-order/view';
import TransactionUpdatePage from 'pages/private/expenses/details/tabs/transactions/update';
import ApproveOrDisapproveExpensePage from 'pages/private/expenses/approve-and-disapprove';
import ExpenseBudgetsPage from 'pages/private/expenses/details/tabs/budgets';
import ExpensesPage from 'pages/private/expenses';
import GroupedTransactionsPage from 'pages/private/expenses/details/tabs/grouped-transactions';
import CreateOrUpdateTransactionDocumentPage from 'pages/private/payment-order/view/documents/create-or-update';
import CommissionsPage from 'pages/private/expenses/details/tabs/commissions';
import { ExpenseNature } from 'types/models/expense';

const expenses: RouteObject[] = [
    {
        // @ts-ignore
        breadcrumb: 'Despesas',
        path: 'despesas',
        element: <Page Component={ExpensesPage} code="despesas" permissions={[Rule.List]} />,
        children: [
            {
                path: 'novo',
                element: <Page Component={CreateOrUpdateExpensePage} nature={ExpenseNature.Normal} code="despesas" permissions={[Rule.Create]} withModal={true} />,
            },
            {
                path: 'editar/:expenseId',
                element: <Page Component={CreateOrUpdateExpensePage} code="despesas" title="Editar despesa" permissions={[Rule.Update]} withModal={true} />,
            },
        ],
    },
    {
        // @ts-ignore
        path: 'despesas/:expenseId',
        element: <Page Component={ExpenseDetails} code="despesas" permissions={[Rule.Show]} />,
        children: [
            {
                index: true,
                element: <Page Component={ExpenseTabDetails} code="despesas-detalhes" permissions={[Rule.Show]} />,
            },
            {
                path: 'editar',
                element: <Page Component={CreateOrUpdateExpensePage} title="Editar despesa" code="despesas" permissions={[Rule.Show]} withModal={true} />,
            },
            {
                path: 'movimentacoes-financeiras',
                element: <Page Component={ExpenseTransactionsPage} code="despesas-movimentacoes-financeiras" permissions={[Rule.List]} />,
                children: [
                    {
                        path: ':transactionId',
                        element: <Page Component={PaymentOrderPage} code="despesas-movimentacoes-financeiras" permissions={[Rule.Show]} withModal={true} />,
                        children: [
                            {
                                path: 'editar',
                                element: <Page Component={TransactionUpdatePage} code="despesas-movimentacoes-financeiras" permissions={[Rule.Update]} withModal={true} />,
                            },
                            {
                                path: 'documentos/novo',
                                element: <Page Component={CreateOrUpdateTransactionDocumentPage} code="despesas-movimentacoes-financeiras-documentos" permissions={[Rule.Create]} withModal={true} />,
                            },
                            {
                                path: 'documentos/:documentId/editar',
                                element: <Page Component={CreateOrUpdateTransactionDocumentPage} code="despesas-movimentacoes-financeiras-documentos" permissions={[Rule.Update]} withModal={true} />,
                            },
                        ],
                    },
                ],
            },
            {
                path: 'produtos',
                element: <Page Component={ExpenseProducts} code="despesas-produtos" permissions={[Rule.Show]} />,
            },
            {
                path: 'documentos',
                element: <Page Component={ExpenseDocuments} code="despesas-documentos" permissions={[Rule.List]} />,
                children: [
                    {
                        path: 'novo',
                        element: <Page Component={ExpenseDocumentsForm} withModal={true} code="despesas-documentos" permissions={[Rule.Create]} />,
                    },
                    {
                        path: 'editar/:documentId',
                        element: <Page Component={ExpenseDocumentsForm} withModal={true} code="despesas-documentos" permissions={[Rule.Update]} />,
                    },
                ],
            },
            {
                path: 'logs',
                element: <Page Component={ExpenseLogsPage} code="despesas-logs" permissions={[Rule.Show]} withModal={false} />,
            },
            {
                path: 'aprovar',
                element: <Page Component={ApproveOrDisapproveExpensePage} code="despesas-aprovar" mode="approve" permissions={[Rule.Update]} withModal={true} />,
            },
            {
                path: 'reprovar',
                element: <Page Component={ApproveOrDisapproveExpensePage} code="despesas-reprovar" mode="disapprove" permissions={[Rule.Update]} withModal={true} />,
            },
            {
                //@ts-ignore
                breadcrumb: 'Orçamentos',
                path: 'orcamentos',
                element: <Page Component={ExpenseBudgetsPage} code="despesas-orcamentos" permissions={[Rule.List]} />,
            },
            {
                path: 'movimentacoes-financeiras-agrupadas',
                element: <Page Component={GroupedTransactionsPage} code="despesas-movimentacoes-financeiras-agrupadas" permissions={[Rule.List]} />,
                children: [
                    {
                        path: ':transactionId',
                        element: <Page Component={PaymentOrderPage} code="despesas-movimentacoes-financeiras-agrupadas" permissions={[Rule.Show]} withModal={true} />,
                        children: [
                            {
                                path: 'editar',
                                element: <Page Component={TransactionUpdatePage} code="despesas-movimentacoes-financeiras-agrupadas" permissions={[Rule.Update]} withModal={true} />,
                            },
                            {
                                path: 'documentos/novo',
                                element: (
                                    <Page
                                        Component={CreateOrUpdateTransactionDocumentPage}
                                        code="despesas-movimentacoes-financeiras-agrupadas-documentos"
                                        permissions={[Rule.Create]}
                                        withModal={true}
                                    />
                                ),
                            },
                            {
                                path: 'documentos/:documentId/editar',
                                element: (
                                    <Page
                                        Component={CreateOrUpdateTransactionDocumentPage}
                                        code="despesas-movimentacoes-financeiras-agrupadas-documentos"
                                        permissions={[Rule.Update]}
                                        withModal={true}
                                    />
                                ),
                            },
                        ],
                    },
                ],
            },
            {
                path: 'comissoes',
                element: <Page Component={CommissionsPage} code="comissoes" permissions={[Rule.List]} />,
            },
        ],
    },
];

export default expenses;
