import apiGraphQl from 'services/graphql';
import { getCitiesTaxKey, queries } from './keys';
import { useQuery } from 'react-query';
import { City } from 'types/models/address';

type Response = {
    items: Array<{
        id: number;
        cityId: number;
        city: Pick<City, 'id' | 'name'>;
    }>;
};
const useGetCitiesTaxOptions = () => {
    const request = async () => {
        const { items } = await apiGraphQl<Response>(queries.getCitiesTaxOptions);

        return items;
    };

    return useQuery(getCitiesTaxKey, request);
};

export default useGetCitiesTaxOptions;
