import { Product } from 'types/models/product';
import { ServiceOrderFinancialPayload } from './create-or-update/financial';
import { ServiceOrderSaleScopePayload } from './create-or-update/sale-scope';
import { ServiceOrderGeneralPayload } from './general';
import { ServiceOrderPurchaseScopePayload } from './purchase-scope';
import { ApprovalStatus } from 'types/general';
import { OrderServicesReportFiltersPayload } from 'types/models/reports';
import { ReportFilterPayload } from 'pages/private/reports/screens/types';

type Content = Partial<ServiceOrderGeneralPayload> & Partial<ServiceOrderSaleScopePayload> & Partial<ServiceOrderPurchaseScopePayload> & Partial<ServiceOrderFinancialPayload>;

export type ReducerAction = {
    type: ContextActions;
    payload: any;
};

export type ContextActions = 'SET_STEP_COMPLETED' | 'SET_CURRENT_STEP' | 'SET_CONTENT' | 'SET_STEPS';

export type Step = {
    slug: Steps;
    label: string;
    hide: boolean;
};

export type ServiceOrderState = {
    completeds: Steps[];
    currentStep: Steps;
    steps: Step[];
    content: Content;
};

export enum Steps {
    GeneralInfo = 'GENERAL',
    SaleScope = 'SALE',
    PurchaseScope = 'PURCHASE',
    Financial = 'FINANCIAL',
}

export type ContextType = {
    state: ServiceOrderState;
    setCurrentStep: (slug: Steps) => void;
    changeStep: (currentStepSlug: Steps, nextStepSlug?: Steps, previousStepSlug?: Steps) => void;
    completeStep: (step: Steps) => void;
    getStep: (slug: Steps) => void;
    setContent: (content: ServiceOrderState['content']) => void;
    hideStep: (steps: Pick<Step, 'hide' | 'slug'>) => void;
    goToStep: (action: 'previous' | 'next') => void;
    setSteps: (steps: Step[]) => void;
};

export type FinancialBalanceTypeResponse = {
    id: number;
    provision: string;
    budget: number;
    maximumValue: number | null;
    justification: string | null;
    dataBalance: {
        totalExpense: number;
        totalExpensePaid: number;
        totalExpenseOpen: number;
    };
    value: number;
    approvalStatus: ApprovalStatus;
    quantity: number;
    balance: number;
    product: Partial<Product>;
};

export type OrderServiceFiltersPayload = ReportFilterPayload<OrderServicesReportFiltersPayload>['filter'] & { searchText?: string | null };
