import Divider from '@mui/material/Divider';
import Card from 'components/core/card';
import SimpleTable from 'components/core/table/simple';
import Text from 'components/core/text';
import Empty from 'components/empty';
import { generateReportsPercentageValue } from 'pages/private/reports/screens/order-services/utils';
import { useOutletContext } from 'react-router-dom';
import { ImportFlow } from 'types/models/import-flow';
import { formatMoney, formatNumberToPTBR } from 'utils/money';

const ImportFlowDetailsTabPage = () => {
    const { importFlow } = useOutletContext<{ importFlow: ImportFlow }>();

    const products = (importFlow.products || []).map((item) => {
        const generatePercentage = generateReportsPercentageValue(item.netValue);

        return {
            id: item.id || 0,
            contents: [
                {
                    label: <span className="whitespace-nowrap">{Boolean(item.product?.code && item.product?.name) ? `#${item.product?.code} - ${item.product?.name}` : '-'}</span>,
                },
                {
                    label: formatNumberToPTBR(item.quantity),
                },
                {
                    label: formatNumberToPTBR(item.balance),
                },
                {
                    label: (
                        <span className="whitespace-nowrap">
                            {formatMoney(item.baseValue)} <small>({formatNumberToPTBR(generatePercentage(item.baseValue))}%)</small>
                        </span>
                    ),
                },
                {
                    label: (
                        <span className="whitespace-nowrap">
                            {formatMoney(item.complementaryValue)} <small>({formatNumberToPTBR(generatePercentage(item.complementaryValue))}%)</small>
                        </span>
                    ),
                },
                {
                    label: (
                        <span className="whitespace-nowrap">
                            {formatMoney(item.average)} <small>({formatNumberToPTBR(generatePercentage(item.average))}%)</small>
                        </span>
                    ),
                },
                {
                    label: formatMoney(item.netValue),
                },
            ],
        };
    });

    return (
        <div className="grid md:grid-cols-[35%_1fr] gap-6">
            <Card className="h-fit">
                <Text className="text-heading text-xl block p-4">Detalhes</Text>
                <Divider className="-mx-4" />
                <div className="flex flex-col gap-2 p-4">
                    <div className="flex items-center gap-2">
                        <Text className="text-base-500">Código: </Text>
                        <Text className="text-heading">{Boolean(importFlow?.code) ? `#${importFlow?.code}` : '-'}</Text>
                    </div>
                    <div className="flex items-center gap-2">
                        <Text className="text-base-500">Invoice: </Text>
                        <Text className="text-heading">{importFlow?.invoice || '-'}</Text>
                    </div>
                    <div className="flex items-center gap-2">
                        <Text className="text-base-500">Empresa: </Text>
                        <Text className="text-heading">{importFlow?.company?.name || '-'}</Text>
                    </div>
                    <div className="flex items-center gap-2">
                        <Text className="text-base-500">Filial: </Text>
                        <Text className="text-heading">{importFlow?.branch?.name || '-'}</Text>
                    </div>
                    <div className="flex items-center gap-2">
                        <Text className="text-base-500">Fabricante: </Text>
                        <Text className="text-heading">{importFlow?.favoredManufacturer?.provider?.title || importFlow?.favoredManufacturer?.person?.title || '-'}</Text>
                    </div>
                    <div className="flex items-center gap-2">
                        <Text className="text-base-500">Despachante: </Text>
                        <Text className="text-heading">{importFlow?.favoredCustomBroker?.provider?.title || importFlow?.favoredCustomBroker?.person?.title || '-'}</Text>
                    </div>
                </div>
            </Card>
            <Card>
                <Text className="text-heading text-xl block p-4">Produtos</Text>
                <Divider className="-mx-4" />
                {Boolean(products?.length) ? (
                    <SimpleTable columns={['Produto', 'QTD', 'Saldo', 'I', 'F', 'N', 'Final']} items={products} />
                ) : (
                    <Empty className="p-4" title="Não há produtos a serem mostrados." />
                )}
            </Card>
        </div>
    );
};

export default ImportFlowDetailsTabPage;
