import * as React from 'react';
const SvgIcoAttachment = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M10.848 1.371a2.11 2.11 0 0 0-1.502.63L2.899 8.524a3.599 3.599 0 0 0-1.035 2.53c0 .949.372 1.859 1.035 2.529a3.507 3.507 0 0 0 2.494 1.046 3.51 3.51 0 0 0 2.495-1.046l6.447-6.523a.68.68 0 0 1 .964-.003.689.689 0 0 1 .003.97L8.855 14.55A4.867 4.867 0 0 1 5.393 16a4.865 4.865 0 0 1-3.461-1.45A4.974 4.974 0 0 1 .5 11.053c0-1.31.515-2.568 1.432-3.496l6.447-6.523A3.471 3.471 0 0 1 10.848 0a3.47 3.47 0 0 1 2.468 1.034 3.545 3.545 0 0 1 1.021 2.492 3.55 3.55 0 0 1-1.02 2.492l-6.455 6.523a2.076 2.076 0 0 1-2.952 0 2.117 2.117 0 0 1 0-2.975l5.956-6.02a.68.68 0 0 1 .965-.002.689.689 0 0 1 .002.97l-5.956 6.019a.74.74 0 0 0 0 1.041.715.715 0 0 0 1.018 0l6.454-6.522c.4-.404.624-.953.624-1.526a2.17 2.17 0 0 0-.624-1.525 2.111 2.111 0 0 0-1.501-.63Z"
            clipRule="evenodd"
        />
    </svg>
);
export default SvgIcoAttachment;
