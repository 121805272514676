import Modal from 'components/core/modal';
import Select from 'components/core/form/select';
import Input from 'components/core/form/input';
import DatePicker from 'components/core/datepicker';
import Spinner from 'components/core/spinner';
import Text from 'components/core/text';
import useGetTransaction from 'services/queries/transactions/use-get-transaction';
import useGetClassifications from 'services/queries/classifications/use-get-classifications';
import { useParams } from 'react-router-dom';
import { formatMoney } from 'utils/money';
import { useEffect } from 'react';
import { formatDate } from 'utils/date';
import { Controller, useForm } from 'react-hook-form';
import useCreateOrEditTransaction, { EditTransactionPayload } from 'services/queries/transactions/use-create-or-edit-transaction';
import { generateClassificationQuery } from 'services/queries/classifications/utils';
import { ClassificationType } from 'types/models/classification';
import { yupResolver } from '@hookform/resolvers/yup';
import useGetOptions from 'services/queries/crud/use-get-options';
import { companyBankAccountsKeys, expenseTransactionSchema, formatBankAccountResponse, generateExpenseTransactionStatus, getCompanyBankAccountsQuery } from './utils';
import { useQueryClient } from 'react-query';
import { getTransactionKey } from 'services/queries/transactions/keys';
import classNames from 'classnames';
import FormButtons from 'components/form-buttons';

const TransactionUpdatePage = () => {
    const { transactionId, paymentOrderId, receiptOrderId } = useParams();
    const queryClient = useQueryClient();

    const { control, formState, handleSubmit, reset } = useForm<EditTransactionPayload>({
        mode: 'onSubmit',
        shouldFocusError: true,
        resolver: yupResolver(expenseTransactionSchema) as any,
    });

    const { data: transaction, isLoading } = useGetTransaction(transactionId || paymentOrderId || receiptOrderId);
    const { data: paymentForms = [] } = useGetClassifications(generateClassificationQuery(ClassificationType.PaymentForm));

    const { data: companyBankAccounts = [] } = useGetOptions(getCompanyBankAccountsQuery, companyBankAccountsKeys, true, formatBankAccountResponse);

    const { mutateAsync: transactionMutate, isLoading: isSaving } = useCreateOrEditTransaction({
        transactionId: transaction?.id,
        expenseId: transaction?.expense?.id,
    });

    const { code, paid = false, dueDate, payday = '' } = transaction || {};

    const submit = async (data: EditTransactionPayload) => {
        try {
            await transactionMutate({
                ...data,
                dueDate: formatDate(data.dueDate, 'YYYY-MM-DD'),
            });
            queryClient.invalidateQueries(getTransactionKey(transaction?.id?.toString()));
        } catch (e) {
            console.error('submit - transaction: ', e);
        }
    };

    const getError = (name: string) => formState?.errors?.[name]?.message as string;

    useEffect(() => {
        if (!!transaction) {
            reset({
                dueDate: transaction?.dueDate || undefined,
                companyBankAccount: transaction?.companyBankAccount?.id || undefined,
                barCode: transaction?.barCode || '',
                paymentForm: transaction?.paymentForm?.id,
            });
        }
    }, [transaction, reset]);

    if (isLoading) {
        return <Spinner fixed={true} />;
    }

    return (
        <>
            <Modal
                contentClassnames="w-[1200px]"
                closeOnClickOutside={false}
                headerLeft={
                    <div className="flex items-center">
                        <Text as="h3" variant="h4" className="text-heading mr-3">
                            {code}
                        </Text>

                        {!!dueDate && generateExpenseTransactionStatus({ paid, payday, dueDate })}
                    </div>
                }>
                <form onSubmit={handleSubmit(submit)}>
                    <div className="px-6 pt-2 pb-4">
                        <h2 className="text-heading text-lg font-medium mb-1">{transaction?.installmentDescription}</h2>
                        <p className="text-base text-base-500 mb-4">{transaction?.description}</p>
                        <div className="md:grid md:grid-cols-3 gap-4 mb-8">
                            <div>
                                <h2 className="text-heading text-lg font-medium mb-1">Valor</h2>
                                <p className="text-base text-base-500">{formatMoney(transaction?.grossValue || 0)}</p>
                            </div>
                            <div>
                                <h2 className="text-heading text-lg font-medium mb-1">Previsão de Pagamento</h2>
                                <p className="text-base text-base-500">{formatDate(transaction?.paymentForecast, 'DD/MM/YYYY')}</p>
                            </div>
                            <div>
                                <h2 className="text-heading text-lg font-medium mb-1">Data de Pagamento</h2>
                                <p className="text-base text-base-500">{formatDate(transaction?.payday, 'DD/MM/YYYY')}</p>
                            </div>
                        </div>
                        <div className="md:grid md:grid-cols-4 gap-4">
                            <Controller
                                name="companyBankAccount"
                                control={control}
                                render={({ field }) => {
                                    const value = companyBankAccounts?.find((item) => item?.value === field.value);

                                    return (
                                        <Select
                                            {...field}
                                            value={value}
                                            options={companyBankAccounts}
                                            label="Conta Bancária"
                                            placeholder="Selecione uma opção"
                                            onChange={(option: any) => field.onChange(option.value)}
                                            error={getError('companyBankAccount')}
                                        />
                                    );
                                }}
                            />
                            <Controller
                                name="paymentForm"
                                control={control}
                                render={({ field }) => {
                                    const value = paymentForms?.find((item) => item?.value === field.value);

                                    return (
                                        <Select
                                            {...field}
                                            value={value}
                                            options={paymentForms}
                                            label="Forma de Pagamento"
                                            placeholder="Selecione uma opção"
                                            onChange={(option: any) => field.onChange(option.value)}
                                            error={getError('paymentForm')}
                                        />
                                    );
                                }}
                            />
                            <Controller
                                name="barCode"
                                control={control}
                                render={({ field }) => <Input {...field} autoComplete="nope" label="Código de Barras" parentClassName="col-span-2" error={getError('barCode')} />}
                            />
                            <Controller
                                name="dueDate"
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        inputProps={{
                                            label: 'Vencimento',
                                            error: getError('dueDate'),
                                            ...field,
                                        }}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className="bg-secondary-100 py-4 px-6 bg-opacity-20 text-right">
                        <Text className="text-base-700 font-medium">Total: </Text>
                        <Text variant="body.medium.2xs" className={classNames((transaction?.grossValue || 0) < 0 ? 'text-system-danger-500' : 'text-heading')}>
                            {formatMoney(transaction?.grossValue || 0)}
                        </Text>
                    </div>
                    <div className="border-t p-6 flex items-center justify-between">
                        <FormButtons isLoading={isSaving} />
                    </div>
                </form>
            </Modal>
        </>
    );
};

export default TransactionUpdatePage;
