import { useMutation } from 'react-query';
import api from 'services/api';
import { SaleOrder } from 'types/models/sale-order';

const useCreateOrUpdateSaleOrder = (saleOrderId?: string) => {
    const request = async (payload) => {
        const method = Boolean(saleOrderId) ? api.patch : api.post;
        const endpoint = Boolean(saleOrderId) ? `/sale-orders/${saleOrderId}` : '/sale-orders';

        return method<SaleOrder>(endpoint, payload);
    };

    return useMutation(request);
};

export default useCreateOrUpdateSaleOrder;
