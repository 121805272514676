import classNames from 'classnames';
import toast, { Toast as ToastType } from 'react-hot-toast';
import theme from 'settings/theme';
import SvgIcoCloseCircle from 'components/core/icon/files/ico-close-circle';
import SvgIcoAlert from 'components/core/icon/files/ico-alert';
import SvgIcoCheck from 'components/core/icon/files/ico-check';

type ToastOptions = Partial<Pick<ToastType, 'id' | 'duration' | 'ariaProps' | 'className' | 'style' | 'iconTheme'>>;

const colors = {
    danger: ['border-system-danger-300', SvgIcoCloseCircle],
    warning: ['border-system-warning-300', SvgIcoAlert],
    success: ['border-system-success-300', SvgIcoCheck],
};

export const genId = (() => {
    let count = 0;

    return () => {
        return (++count).toString();
    };
})();

const useToast = () => {
    const showToast = (message: string, variant: 'danger' | 'success' | 'warning' = 'danger', options?: ToastOptions) => {
        const id = genId();
        const [color, Icon] = colors[variant];

        return toast(message, {
            ...options,
            id,
            className: classNames(styles.toast, color),
            icon: <Icon color={theme.extend.colors.system[variant][500]} className={styles.icon} />,
            duration: 5000,
        });
    };

    return {
        showToast,
    };
};

const styles = {
    icon: 'mr-3',
    toast: 'bg-base-100 flex rounded-lg p-4 border-r-4 toast text-base-500 text-base text-sm',
};

export default useToast;
