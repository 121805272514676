import Select from 'components/core/form/select';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import FormButtons from 'components/form-buttons';
import { useState } from 'react';
import usePrintTransactions from 'services/queries/transactions/use-print-transactions';
import { Option } from 'types/general';
import { TransactionPrintType } from 'types/models/transaction';
import { transactionPrintTypes } from 'utils/statics';
import omit from 'lodash/omit';

type OPsPrintProps = {
    filters: any;
    onClose: () => void;
    onSuccess: () => void;
};

const OPsPrint = ({ filters, onClose }: OPsPrintProps) => {
    const [printType, setPrintType] = useState<Option<TransactionPrintType>>();

    const { mutateAsync: printTransactions, isLoading: isSubmitting } = usePrintTransactions();

    const handleSubmit = async (event) => {
        try {
            event.preventDefault();

            if (!printType) {
                return;
            }

            const { file } = await printTransactions({
                filter: omit(filters, 'page'),
                printType: printType.value,
                type: 'print',
            });

            window.open(file, '_blank');
        } catch (error) {
            console.log('hamdleSubmit', error);
        }
    };

    return (
        <Modal
            contentClassnames="min-w-[400px]"
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    Tipo de impressão
                </Text>
            }
            closeOnClickOutside={false}
            onClose={onClose}>
            <form className="px-6 pb-6" onSubmit={handleSubmit}>
                <Select value={printType} label="Agrupado por" options={transactionPrintTypes} className="mb-6" onChange={(option: any) => setPrintType(option)} />
                <FormButtons
                    isLoading={isSubmitting}
                    cancelButton={{
                        onClick: onClose,
                    }}
                />
            </form>
        </Modal>
    );
};

export default OPsPrint;
