import useToast from 'hooks/toast/use-toast';
import { useMutation } from 'react-query';
import api from 'services/api';

const { REACT_APP_API_URL, REACT_APP_API_VERSION } = process.env;

const useGetDownloadFile = () => {
    const { showToast } = useToast();

    const request = async (fileId: number) => {
        const { data: fileToken } = await api.get<{ token: string }>(`/files/${fileId}`);

        const base64 = await fetch(`${REACT_APP_API_URL! + REACT_APP_API_VERSION!}/download/${fileToken.token}`);
        const blob = await base64.blob();
        const blobUrl = URL.createObjectURL(blob);

        window.open(blobUrl, '_blank');
    };

    return useMutation(request, {
        onError: () => {
            showToast('Ocorreu um erro ao fazer o download do arquivo. Tente novamente.', 'danger');
        },
    });
};

export default useGetDownloadFile;
