import TableCell from '@mui/material/TableCell/TableCell';
import { CrudPageProps } from 'types/graphql';
import dictionary from 'utils/dictionary';
import { pixTypes } from 'utils/statics';

export const personBankAccountsListConfig = (personId?: number): CrudPageProps<{}> => ({
    graphql: {
        table: 'bankAccount',
        searchableField: 'bank.name',
        columns: [
            {
                name: 'id',
                options: { display: 'excluded' },
            },
            {
                name: 'bank.name',
                label: dictionary.content.graphql['bank'],
            },
            {
                name: 'account',
                label: dictionary.content.graphql['account'],
                options: {
                    customBodyRender: (data, tableMeta) => {
                        const [, , , accountDigit] = tableMeta.rowData;

                        return <div>{accountDigit ? `${data}-${accountDigit}` : data}</div>;
                    },
                },
            },
            {
                name: 'accountDigit',
                label: dictionary.content.graphql['accountDigit'],
                options: { display: 'excluded' },
            },
            {
                name: 'agency',
                label: dictionary.content.graphql['agency'],
                options: {
                    customBodyRender(data, tableMeta) {
                        const [, , , , , agencyDigit] = tableMeta.rowData;

                        return <div>{agencyDigit ? `${data}-${agencyDigit}` : data}</div>;
                    },
                },
            },
            {
                name: 'agencyDigit',
                label: dictionary.content.graphql['agencyDigit'],
                options: { display: 'excluded' },
            },
            {
                name: 'pix',
                label: 'Pix',
                options: {
                    customBodyRender: (pix, { rowData }) => {
                        const [, , , , , , , pixType] = rowData;
                        const finded = pixTypes.find((item) => item.value === pixType);

                        const pixTypeFinal = !!finded && finded.label.toLowerCase();

                        let text = '';

                        if (!!pix) {
                            text += pix;
                        }

                        if (!!pixTypeFinal) {
                            text += ` (${pixTypeFinal})`;
                        }

                        return text || '-';
                    },
                },
            },
            {
                name: 'pixType',
                options: { display: 'excluded' },
            },
            {
                name: 'actions',
                label: 'Ações',
                options: {
                    customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />,
                },
            },
        ],
        where: {
            personBankAccount: {
                personId: { _eq: personId },
            },
        },
    },
    api: {
        useApiTodelete: true,
        endpoint: `/people/${personId}/bank-accounts`,
    },
});
