import { useMemo } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { UseQueryResult } from 'react-query';
import useTheme from '@mui/material/styles/useTheme';
import IconButton from '@mui/material/IconButton/IconButton';
import { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import PageHeader from 'components/page/header';
import Datatable from 'components/core/table/datatable';
import dictionary from 'utils/dictionary';
import { Profile } from 'types/models/profile';
import { GraphqlPagination, GraphqlPaginationVariables } from 'types/graphql';
import { Page } from 'types/models/page';
import { useListPageContext } from './context';
import get from 'lodash/get';
import SvgIcoPlus from 'components/core/icon/files/ico-plus';

export type CustomProfile = Pick<Profile, 'id' | 'name' | 'slug' | 'status'>;

type ListProps = {
    columns: MUIDataTableColumn[];
    params: GraphqlPaginationVariables<any>;
    searchableField: string;
    title: string;
    onChangePage: (currentPage: number) => void;
    onChangeRowsPerPage: (numberOfRows: number) => void;
    onColumnSortChange: (changedColumn: string, direction: 'asc' | 'desc') => void;
    onDelete: (id?: number, slug?: string) => void;
    onSearchChange: (searchText: string | null) => void;
    hideHeader?: boolean;
    hideCreateButton?: boolean | ((items: any[]) => boolean);
    withBorder?: boolean;
    page?: Page;
    advancedSearchComponent?: React.ReactNode;
    tableOptions?: MUIDataTableOptions;
    additionalContextData?: any;
    className?: string;
    columnsToSearch?: string[];
    createPagePath: string;
    customActions?: (item: any) => any[];
    customFooterActions?: (item: any) => any[];
} & UseQueryResult<GraphqlPagination<any>, unknown>;

const List = ({
    additionalContextData,
    className,
    columns,
    columnsToSearch,
    page,
    data,
    isLoading,
    params,
    searchableField,
    title,
    onChangePage,
    onChangeRowsPerPage,
    onColumnSortChange,
    onDelete,
    onSearchChange,
    hideHeader = false,
    hideCreateButton = false,
    withBorder = false,
    advancedSearchComponent,
    tableOptions,
    createPagePath,
    customActions,
    customFooterActions,
}: ListProps) => {
    const { palette } = useTheme();
    const { hasSelectedFilters } = useListPageContext();

    data?.items.forEach((item) => {
        if (!Object.keys(item).includes('id')) {
            const key = Object.keys(item)[0];

            item.id = item[key]?.id;
        }
    });

    const options = useMemo<MUIDataTableOptions>(() => {
        const searchableValue = get(params?.where, `${searchableField}._ilike`);
        const searchableValueAllColumns = get(params?.where?._or?.[0], `${columnsToSearch?.[0]}._ilike`);

        return {
            ...tableOptions,
            searchText: searchableValue || searchableValueAllColumns || '',
            elevation: 21,
            count: data?.pagination?.aggregate.count || 0,
            page: params.page,
            enableNestedDataAccess: '.',
            serverSide: true,
            filter: false,
            searchAlwaysOpen: true,
            searchPlaceholder: `Pesquisar${dictionary.content.graphql[searchableField] ? ` por ${dictionary.content.graphql[searchableField].toLowerCase()}` : '...'}`,
            onChangePage,
            onChangeRowsPerPage,
            onColumnSortChange,
            onSearchChange,
        };
    }, [onChangePage, onChangeRowsPerPage, onColumnSortChange, onSearchChange, data, params, searchableField, tableOptions, columnsToSearch]);

    const actions = useMemo(() => page?.rules.map((item) => item.key) || [], [page?.rules]);

    const isCreateButtonHidden = typeof hideCreateButton === 'function' ? hideCreateButton(data?.items || []) : hideCreateButton;

    return (
        <>
            <div className={className}>
                {!hideHeader && (
                    <PageHeader
                        className="mb-6"
                        title={title}
                        right={
                            !isCreateButtonHidden ? (
                                <IconButton
                                    component={Link}
                                    to={createPagePath}
                                    color="secondary"
                                    size="large"
                                    sx={{ backgroundColor: palette.secondary.main, '&:hover': { backgroundColor: palette.secondary.dark } }}>
                                    <SvgIcoPlus width={16} height={16} color={palette.grey[100]} />
                                </IconButton>
                            ) : undefined
                        }
                    />
                )}
                <Datatable
                    advancedSearchComponent={advancedSearchComponent}
                    hasSelectedFilters={hasSelectedFilters}
                    actions={actions}
                    withBorder={withBorder}
                    data={data?.items || []}
                    options={options}
                    columns={columns}
                    loading={isLoading}
                    withItemsPerPage={true}
                    onDelete={onDelete}
                    customActions={customActions}
                    customFooterActions={customFooterActions}
                />
            </div>
            <Outlet context={{ ...params, ...additionalContextData }} />
        </>
    );
};

export default List;
