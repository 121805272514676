import ApprovalBadge from 'components/badges/approval';
import PurchaseProductStatusBadge from 'components/badges/purchase-product-status';
import ExternalButton from 'components/buttons/external';
import Badge from 'components/core/badge';
import get from 'lodash/get';
import { ApprovalStatus } from 'types/general';
import { DatatableColumn } from 'types/graphql';
import { formatDate } from 'utils/date';
import { formatMoney, formatNumberToPTBR } from 'utils/money';

export const stockPurchaseOrdersColumns: DatatableColumn[] = [
    {
        name: 'id',
        options: { display: 'excluded' },
    },
    {
        name: 'product.code',
        options: { display: 'excluded' },
    },
    {
        label: 'Produto',
        name: 'product.name',
        options: {
            customBodyRender: (value, { rowData }) => {
                const billed = rowData[15];
                const code = rowData[1];

                if (!value) {
                    return '-';
                }

                return (
                    <>
                        <span className="whitespace-nowrap !block">{`${value} (${code})`}</span>
                        {billed && <Badge variant="warning">Faturado</Badge>}
                    </>
                );
            },
        },
    },
    {
        label: 'Em estoque',
        name: 'stockBalance',
        options: {
            customBodyRender: (stockBalance = 0) => formatNumberToPTBR(stockBalance),
        },
    },
    {
        name: 'product.unit.name',
        options: { display: 'excluded' },
    },
    {
        label: 'Quantidade solicitada',
        name: 'quantity',
        options: {
            customBodyRender: (value = 0, tableMeta) => {
                const unit = get(tableMeta, 'rowData[4]');

                return `${formatNumberToPTBR(value)} ${unit}`;
            },
        },
    },
    {
        label: 'Valor de compra',
        name: 'purchaseValue',
        options: {
            customBodyRender: (value) => (Number.isFinite(value) ? formatMoney(value, undefined, 4) : '-'),
        },
    },
    {
        label: 'Empresa',
        name: 'purchaseOrder.company.name',
        options: {
            customBodyRender: (value = '-') => <span className="whitespace-nowrap">{value}</span>,
        },
    },
    {
        label: 'Despesa',
        name: 'expenseProduct',
        options: {
            customBodyRender: (expenseProduct) => {
                if (!expenseProduct) {
                    return '-';
                }

                return (
                    <>
                        <ExternalButton to={`/app/despesas/${expenseProduct.expense?.id}`}>{expenseProduct.expense.code}</ExternalButton>
                        {expenseProduct.expense.billed && <Badge variant="warning">Faturada</Badge>}
                    </>
                );
            },
        },
    },
    {
        label: 'Provisão',
        name: 'provision',
        options: {
            customBodyRender: (value) => formatDate(value),
        },
    },
    {
        label: 'Status',
        name: 'purchaseProductStatus',
        options: {
            customBodyRender: (value) => {
                return <PurchaseProductStatusBadge purchaseProductStatus={value} />;
            },
        },
    },
    {
        label: 'Aprovado',
        name: 'approvalStatus',
        options: {
            customBodyRender: (value: ApprovalStatus) => <ApprovalBadge status={value} />,
        },
    },
    {
        name: 'purchaseOrder.code',
        options: { display: 'excluded' },
    },
    {
        name: 'purchaseOrder.origin.orderService.title',
        options: { display: 'excluded' },
    },
    {
        name: 'purchaseOrder.origin.stock.name',
        options: { display: 'excluded' },
    },
    {
        name: 'billed',
        options: { display: 'excluded' },
    },
];
