import Card from 'components/core/card';
import { Chart as ChartJS, LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip, LineController, BarController } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import theme from 'settings/theme';
import { useEffect, useMemo, useState } from 'react';
import Spinner from 'components/core/spinner';
import classNames from 'classnames';
import { getReportPageBlockColor, reportPagesChartOptions } from '../details/utils';
import { months } from 'utils/date';
import sum from 'lodash/sum';
import { ReportPageBlock, ReportPageByYearResponse } from 'types/models/reports';

ChartJS.register(LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip, LineController, BarController);

type ReportPageDetailsGroupedYearsProps = {
    years: number[];
    doughnutChartId: string;
    dataBlock: ReportPageBlock[] | ReportPageByYearResponse[];
};

const ReportPageGroupedYearsChartBar = ({ years = [], dataBlock = [], doughnutChartId = 'doughnut-chart-card' }: ReportPageDetailsGroupedYearsProps) => {
    const data = useMemo(() => {
        const mediaValuesArr = months.map((_, index) => {
            const values = dataBlock.map((item) => {
                const valuesByMonth = (item?.reportMonths || item?.months || [])[index];
                return valuesByMonth?.value;
            });

            return values;
        });

        const summedMediaValues = mediaValuesArr.map((months) => sum(months));
        const mediaValues = summedMediaValues.map((item) => {
            const value = (item || 0) / years.length;

            return isFinite(value) ? value.toFixed(2) : 0;
        });

        const datasets: any[] = [
            {
                type: 'line' as const,
                label: 'Média mensal',
                borderColor: theme.extend.colors.heading,
                borderWidth: 2,
                fill: false,
                data: mediaValues,
            },
        ];

        for (const [index, year] of years.entries()) {
            const backgroundColor = getReportPageBlockColor(index);

            const payload = {
                type: 'bar' as const,
                label: year,
                backgroundColor,
                data: months.map((_, monthIndex) => {
                    const getValuesByMonth = dataBlock?.map((values) => {
                        return (values?.reportMonths || values?.months || [])[monthIndex];
                    });

                    const getByYear = getValuesByMonth?.find((item) => item?.year === year);
                    return getByYear?.value || 0;
                }),
            };

            datasets.push(payload);
        }

        return {
            labels: months,
            datasets,
        };
    }, [years, dataBlock]);

    const [height, setHeight] = useState<number>();

    useEffect(() => {
        // get chart height based on doughnut chart div element
        const interval = setInterval(() => {
            const doughnutChartHeight = document.getElementById(doughnutChartId)?.clientHeight!;

            if (doughnutChartHeight) {
                setHeight(doughnutChartHeight);

                clearInterval(interval);
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [doughnutChartId]);

    return (
        <Card className="p-5 h-full md:col-span-3">
            <div style={{ height: height ?? '100%' }} className={classNames(!Boolean(height) && 'flex items-center justify-center')}>
                {Boolean(height) ? <Chart type="bar" options={reportPagesChartOptions} data={data} updateMode="resize" className="w-full" /> : <Spinner fixed={false} />}
            </div>
        </Card>
    );
};

export default ReportPageGroupedYearsChartBar;
