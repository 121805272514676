import { Address } from 'types/models/address';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';

export const sanitize = (sentence: string, term: RegExp, replacer: string) => sentence.split(term).join(replacer);

export const slugify = (text: string) => {
    return text
        .toString()
        .toLowerCase()
        .replace(/[àÀáÁâÂãäÄÅåª]+/g, 'a')
        .replace(/[èÈéÉêÊëË]+/g, 'e')
        .replace(/[ìÌíÍîÎïÏ]+/g, 'i')
        .replace(/[òÒóÓôÔõÕöÖº]+/g, 'o')
        .replace(/[ùÙúÚûÛüÜ]+/g, 'u')
        .replace(/[ýÝÿŸ]+/g, 'y')
        .replace(/[ñÑ]+/g, 'n')
        .replace(/[çÇ]+/g, 'c')
        .replace(/[ß]+/g, 'ss')
        .replace(/[Ææ]+/g, 'ae')
        .replace(/[Øøœ]+/g, 'oe')
        .replace(/[%]+/g, 'pct')
        .replace(/\s+/g, '-')
        .replace(/[^\w](\.|-{1})/g, '');
};

export const pluralize = (quantity: number, plural: string, singular: string): string => `${quantity} ${quantity === 1 ? singular : plural}`;

export const hexToRgba = (hex: string, alpha?: number) => {
    const regx = new RegExp(`.{1,${hex.length > 4 ? 2 : 1}}`, 'g');
    const pairs = hex
        .replace('#', '')
        .match(regx)!
        .map((pair) => parseInt(pair.length === 1 ? pair + pair : pair, 16));

    const rgba = `rgba(${pairs.join(', ')}, ${alpha || 1})`;

    return rgba;
};

export const generateAddress = (address?: Address, fallbackMessage = '-') => {
    if (!address) {
        return fallbackMessage;
    }

    const { street, number, state, city, neighbourhood, zip } = omitBy(address, isNil);

    return `${[street, number, neighbourhood, city?.name].join(', ')} - ${state?.initials}, ${zip}`;
};

export const convertStringToObject = (str: string, value: any) => {
    const keys = str.split('.');

    const result = {};

    let currentObj = result;

    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        currentObj[key] = {};

        if (i === keys.length - 1) {
            currentObj[key] = value;
        } else {
            currentObj = currentObj[key];
        }
    }

    return result;
};

export function formatPhoneNumber(phoneNumber: string) {
    const regex = new RegExp(/^\s*(\d{2}|\d{0})[-. ]?(\d{5}|\d{4})[-. ]?(\d{4})[-. ]?\s*$/);

    const match = phoneNumber.match(regex);

    if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`;
    }

    return phoneNumber;
}
