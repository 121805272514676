import { CrudPageProps } from 'types/graphql';
import { SaleOrderProvider } from './context';
import CreateOrUpdateSaleOrder from './create-or-update';

const CreateOrUpdateSaleOrderPage = ({ title }: CrudPageProps<{}>) => {
    return (
        <SaleOrderProvider>
            <CreateOrUpdateSaleOrder title={title || 'Pedido de venda'} />
        </SaleOrderProvider>
    );
};

export default CreateOrUpdateSaleOrderPage;
