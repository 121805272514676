import TableCell from '@mui/material/TableCell/TableCell';
import ExternalButton from 'components/buttons/external';
import { ColorVariant } from 'types/colors';
import { CrudPageProps } from 'types/graphql';
import { InventoryMovementType } from 'types/models/inventory-movements';
import { StockOrder } from 'types/models/stock';
import { formatMoney } from 'utils/money';

export const stockProductsListConfig = (stockId): CrudPageProps<{}> => ({
    graphql: {
        table: 'stockProduct',
        statusPath: 'status',
        excludedSearchColumnsName: ['value', 'quantity', ''],
        columns: [
            {
                name: 'id',
                options: { display: 'excluded' },
            },
            {
                name: 'product.code',
                options: { display: 'excluded' },
            },
            {
                name: 'product.classificationByClassificationUnitId.name',
                options: { display: 'excluded' },
            },
            {
                name: 'product.name',
                label: 'Produto',
                options: {
                    customBodyRender: (name: string, { rowData }) => {
                        const [, code] = rowData;

                        return `${code} - ${name || ''} `;
                    },
                },
            },
            {
                name: 'quantity',
                label: 'Quantidade',
                options: {
                    customBodyRender: (value: 0, { rowData }) => {
                        const [, , unit] = rowData;

                        return `${value.toLocaleString('pt-BR', { minimumFractionDigits: 2 })} (${unit})`;
                    },
                },
            },
            {
                name: 'value',
                label: 'Valor',
                options: {
                    customBodyRender: (value: number) => formatMoney(value),
                },
            },
            {
                name: '',
                label: 'Total produto',
                options: {
                    customBodyRender: (_, { rowData }) => {
                        const [, , , , quantity, value] = rowData;

                        return formatMoney((quantity || 0) * (value || 0));
                    },
                },
            },
            {
                name: 'actions',
                label: 'Ações',
                options: {
                    customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />,
                },
            },
        ],
        orderBy: { id: 'ASC' },
        where: {
            stockId: { _eq: stockId },
        },
    },
    hideHeader: true,
});

export const generateStockOriginButton = (stockOrder?: Partial<StockOrder>) => {
    if (Boolean(stockOrder?.stockOrigin?.stockOriginPurchaseOrder?.purchaseOrder?.id)) {
        return (
            <ExternalButton href={`/app/ordens-de-compra-de-estoque/${stockOrder?.stockOrigin?.stockOriginPurchaseOrder?.purchaseOrder?.id}`}>
                {stockOrder?.stockOrigin?.stockOriginPurchaseOrder?.purchaseOrder?.code}
            </ExternalButton>
        );
    }

    if (Boolean(stockOrder?.stockOrigin?.stockOriginSaleOrder?.saleOrder?.id)) {
        return (
            <ExternalButton href={`/app/pedidos-de-venda/${stockOrder?.stockOrigin?.stockOriginSaleOrder?.saleOrder?.id}`}>
                {stockOrder?.stockOrigin?.stockOriginSaleOrder?.saleOrder?.code}
            </ExternalButton>
        );
    }

    return '-';
};

type InventoryMovementsColors = {
    [K in InventoryMovementType]: {
        variant: ColorVariant;
        text: 'Entrada' | 'Saída' | 'Estorno';
        textClassName: string;
    };
};

export const generateInventoryMovementColors = (type?: InventoryMovementType, isReversal = false) => {
    const inventoryMovementMap: InventoryMovementsColors = {
        [InventoryMovementType.Income]: {
            variant: isReversal ? 'warning' : 'success',
            text: isReversal ? 'Estorno' : 'Entrada',
            textClassName: isReversal ? 'text-system-warning-500' : 'text-system-success-500',
        },
        [InventoryMovementType.Outcome]: {
            variant: 'error',
            text: 'Saída',
            textClassName: 'text-system-danger-500',
        },
    };

    return inventoryMovementMap[type || InventoryMovementType.Income];
};
