import { useMutation } from 'react-query';
import apiGraphQl from 'services/graphql';
import { affectedRowsKey } from 'utils/environment';

const useDeleteProfilePageRule = (profileId?: string) => {
    const request = async () => {
        return apiGraphQl(
            `mutation DeleteProfilePageRules($profileId: Int!) {
            item: deleteProfilePageRule(where: {profileId: {_eq: $profileId}}) {
                ${affectedRowsKey}
            }
        }`,
            { profileId }
        );
    };

    return useMutation(request);
};

export default useDeleteProfilePageRule;
