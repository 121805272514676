import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import ListPage from 'pages/shared/crud/list';
import { useLocation, useNavigate } from 'react-router-dom';
import { configReportPages } from './utils';

const PageReportsPage = ({ page }: WithPagePermissionsProps) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const handleGoTo = (path: string) => (event: React.MouseEvent<Element, MouseEvent>) => {
        if (event.ctrlKey || event.metaKey) {
            return window.open(`${pathname}/${path}`, '_blank');
        }

        navigate(path);
    };

    const [list] = configReportPages(handleGoTo);

    return <ListPage page={page} {...list} />;
};

export default PageReportsPage;
