import { formatCurrency } from '@brazilian-utils/brazilian-utils';
import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from '@mui/material/TableRow/TableRow';
import ExternalButton from 'components/buttons/external';
import Badge from 'components/core/badge';
import SimpleTable from 'components/core/table/simple';
import { Status } from 'types/general';
import { CrudPageProps } from 'types/graphql';
import { InventoryMovementType } from 'types/models/inventory-movements';
import { formatDate } from 'utils/date';
import { formatMoney } from 'utils/money';
import { stockOrderTypes } from 'utils/statics';

export const remittanceListConfig = (stockId?: string): CrudPageProps<{}> => ({
    title: 'Remessas',
    hideHeader: true,
    graphql: {
        table: 'stockOrder',
        searchableField: 'code',
        columns: [
            {
                name: 'id',
                options: { display: 'excluded' },
            },
            {
                name: 'code',
                label: 'Código',
            },
            {
                name: 'stock.id',
                options: { display: 'excluded' },
            },
            {
                name: 'stock.name',
                label: 'Estoque de saída',
                options: {
                    customBodyRender: (stockName, { rowData }) => {
                        const [, , outgoingStockId] = rowData;

                        if (outgoingStockId === Number(stockId)) {
                            return <Badge variant="error">{stockName || '-'}</Badge>;
                        }

                        return <span className="text-system-danger-500">{stockName || '-'}</span>;
                    },
                },
            },
            {
                name: 'stockByStockIncomingId.id',
                options: { display: 'excluded' },
            },
            {
                name: 'stockByStockIncomingId.name',
                label: 'Estoque de entrada',
                options: {
                    customBodyRender: (incomingStockName, { rowData }) => {
                        const [, , , , incomingStockId] = rowData;

                        if (incomingStockId === Number(stockId)) {
                            // Render as badge to hightlight the current stock
                            return <Badge variant="success">{incomingStockName || '-'}</Badge>;
                        }

                        return <span className="text-system-success-500">{incomingStockName || '-'}</span>;
                    },
                },
            },
            {
                name: 'stockOrigin.stockOriginPurchaseOrder.purchaseOrder.id',
                options: { display: 'excluded' },
            },
            {
                name: 'stockOrigin.stockOriginPurchaseOrder.purchaseOrder.code',
                options: { display: 'excluded' },
            },
            {
                name: 'stockOrigin.stockOriginSaleOrder.saleOrder.id',
                options: { display: 'excluded' },
            },
            {
                name: 'stockOrigin.stockOriginSaleOrder.saleOrder.code',
                options: { display: 'excluded' },
            },
            {
                name: '',
                label: 'Origem',
                options: {
                    customBodyRender: (_, { rowData }) => {
                        const [, , , , , , purchaseOrderId, purchaseOrderCode, saleOrderId, saleOrderCode] = rowData;

                        if (!!purchaseOrderId && !!purchaseOrderCode) {
                            const path = purchaseOrderCode.includes('OCE')
                                ? `/app/ordens-de-compra-de-estoque/${purchaseOrderId}`
                                : `/app/pedidos-de-venda/10/ordens-de-servico/9/ordens-de-compra/${purchaseOrderId}`;

                            return <ExternalButton to={path}>{purchaseOrderCode}</ExternalButton>;
                        }

                        if (!!saleOrderId && !!saleOrderCode) {
                            return <ExternalButton to={`/app/pedidos-de-venda/${saleOrderId}`}>{saleOrderCode}</ExternalButton>;
                        }

                        return '-';
                    },
                },
            },
            {
                name: 'type',
                label: 'Tipo',
                options: {
                    customBodyRender: (type) => {
                        const findedType = stockOrderTypes.find((item) => item.value === type);

                        return findedType?.label || '-';
                    },
                },
            },
            {
                name: 'createdAt',
                label: 'Data',
                options: {
                    customBodyRender: (value) => formatDate(value),
                },
            },
            {
                name: 'netValue',
                label: 'Valor',
                options: { customBodyRender: (value) => formatMoney(value) },
            },
            {
                name: 'inventoryMovements.id',
                options: { display: 'excluded' },
            },
            {
                name: 'inventoryMovements.type',
                options: { display: 'excluded' },
            },
            {
                name: 'inventoryMovements.quantity',
                options: { display: 'excluded' },
            },
            {
                name: 'inventoryMovements.value',
                options: { display: 'excluded' },
            },
            {
                name: 'inventoryMovements.stock.name',
                options: { display: 'excluded' },
            },
            {
                name: 'inventoryMovements.stockProduct.product.name',
                options: { display: 'excluded' },
            },
            {
                name: 'inventoryMovements.stockProduct.product.code',
                options: { display: 'excluded' },
            },
            {
                name: 'inventoryMovements.stockProduct.product.classificationByClassificationUnitId.name',
                options: { display: 'excluded' },
            },
            {
                name: 'inventoryMovements',
                options: { display: 'excluded' },
            },
        ],
        where: {
            status: { _eq: Status.Active },
            _or: [{ stockIncomingId: { _eq: stockId } }, { stockOutgoingId: { _eq: stockId } }],
        },
        orderBy: { createdAt: 'DESC' },
    },
    customTableOptions: {
        selectableRows: 'none',
        expandableRows: true,
        expandableRowsOnClick: true,
        enableNestedDataAccess: '.',
        renderExpandableRow: (rowData: any[]) => {
            const inventoryMovements = rowData[rowData.length - 1] || [];

            const movements = inventoryMovements.map((item: any) => ({
                id: item.id || 0,
                contents: [
                    {
                        label: <Badge variant={item.type === InventoryMovementType.Income ? 'success' : 'error'}>{item.type === InventoryMovementType.Income ? 'Entrada' : 'Saída'}</Badge>,
                    },
                    { label: `#${item.stockProduct.product.code} - ${item.stockProduct.product.name}` },
                    { label: `${formatCurrency(item.quantity)} (${item.stockProduct.product.classificationByClassificationUnitId.name})` }, // Ex: convert 10.10 to 10,10
                    {
                        label: formatMoney(item.value),
                        textClassName: item.type === InventoryMovementType.Income ? 'text-system-success-500' : 'text-system-danger-500',
                    },
                    {
                        label: <span className="text-system-danger-500">{item.type === InventoryMovementType.Outcome ? item.stock.name : '-'}</span>,
                    },
                    {
                        label: <span className="text-system-success-500">{item.type === InventoryMovementType.Income ? item.stock.name : '-'}</span>,
                    },
                ],
            }));

            return (
                <TableRow className="bg-base-200">
                    <TableCell colSpan={rowData.length + 1}>
                        <div className="bg-base-100 rounded-[14px] border border-base-300 overflow-hidden">
                            <SimpleTable columns={['Tipo', 'Produto', 'Quantidade', 'Valor', 'Saiu de', 'Entrou em']} items={movements || []} />
                        </div>
                    </TableCell>
                </TableRow>
            );
        },
    },
});
