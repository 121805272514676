import { useEffect, useMemo, useState } from 'react';
import SalesOrderReportHeader from './header';
import useConfig from 'store/config/use-config';
import { GenericReportFilterPayload } from '../types';
import { SalesOrderReportFiltersPayload } from 'types/models/reports';
import { initialReportFilters } from '../utils';
import { useMainContext } from 'pages/private/main/context';
import useGetSalesOrdersReport from 'services/queries/reports/use-get-sales-orders-report';
import useGetDataContentConfigFilters from '../../hooks/use-get-data-content-filters';
import { generateSalesOrderReportTotals, salesOrdersReportColumns } from './utils';
import Datatable from 'components/core/table/datatable';
import { ClassificationType } from 'types/models/classification';
import { useReportsContext } from '../../context';
import { datatableColumnSort } from 'utils/datatable';

const ReportSaleOrdersContent = () => {
    const [filters, setFilters] = useState<GenericReportFilterPayload<SalesOrderReportFiltersPayload>>(initialReportFilters);
    const [page, setPage] = useState(0);

    const { config } = useConfig();
    const { showValues } = useReportsContext();
    const { handleToggleSidebar } = useMainContext();

    const { data = [], isLoading } = useGetSalesOrdersReport(filters);

    const dataContentFiltersConfig = useGetDataContentConfigFilters({ classificationType: ClassificationType.SaleOrderType });

    const salesOrderReportTotals = useMemo(() => generateSalesOrderReportTotals(data), [data]);

    const handleChangeFilters = (data?: GenericReportFilterPayload<SalesOrderReportFiltersPayload>) => {
        setFilters(data);
        setPage(0);
    };

    useEffect(() => {
        handleToggleSidebar(true);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <SalesOrderReportHeader dataContentFiltersConfig={dataContentFiltersConfig} filters={filters} salesOrderTotals={salesOrderReportTotals} onSetFilters={handleChangeFilters} />
            <Datatable
                data={data}
                withPagination={true}
                loading={isLoading}
                columns={salesOrdersReportColumns(showValues)}
                options={{
                    sort: true,
                    filter: false,
                    search: true,
                    searchAlwaysOpen: true,
                    print: false,
                    download: false,
                    viewColumns: false,
                    serverSide: false,
                    enableNestedDataAccess: '.',
                    pagination: true,
                    elevation: 21,
                    searchPlaceholder: 'Buscar pedido de venda',
                    page,
                    rowsPerPage: config.tables.rowsPerPage,
                    onChangePage: setPage,
                    customSort: datatableColumnSort,
                }}
            />
        </>
    );
};

export default ReportSaleOrdersContent;
