import RemoveButton from 'components/buttons/remove';
import Button from 'components/core/button';
import Dropzone from 'components/dropzone';
import { useMemo, useState } from 'react';

type ChangeImageModalFormProps = {
    isSubmitting: boolean;
    onCancel(): void;
    onSubmit(data: File): void;
};

const ChangeImageModalForm = ({ isSubmitting, onCancel, onSubmit }: ChangeImageModalFormProps) => {
    const [file, setFile] = useState<File>();

    const handleChangeFile = (filesList: File[]) => {
        const [newFile] = filesList || [];

        setFile(newFile);
    };

    const handleClickRemove = () => setFile(undefined);

    const previewImageUrl = useMemo(() => (!!file ? URL.createObjectURL(file) : ''), [file]);

    return (
        <div>
            <div className="mb-6">
                {!!previewImageUrl ? (
                    <div className="flex items-center">
                        <a className="flex items-center" href={previewImageUrl} rel="noreferrer" target="_blank">
                            <div className="w-[200px] h-[200px] bg-center bg-cover rounded-[14px] mr-4" style={{ backgroundImage: `url(${previewImageUrl})` }}></div>
                            <div className="flex flex-col">
                                <span className="font-medium text-heading">{file?.name}</span>
                                <span className="italic text-sm">Clique para visualizar a imagem inteira.</span>
                            </div>
                        </a>

                        {!isSubmitting && <RemoveButton onClick={handleClickRemove} className="opacity-100 right-[18px]" />}
                    </div>
                ) : (
                    <Dropzone
                        multiple={false}
                        onUploadFiles={handleChangeFile}
                        accept={{
                            'image/*': ['.jpeg', '.png'],
                        }}
                        containerClasses="h-[200px]"
                    />
                )}
            </div>

            <div className="flex items-center">
                {!!file && (
                    <Button onClick={() => onSubmit(file)} disabled={isSubmitting} loading={isSubmitting} type="button" variant="contained" color="secondary" className="min-w-[100px] mr-4">
                        Enviar
                    </Button>
                )}
                {!isSubmitting && (
                    <Button color="inherit" className="min-w-[100px]" variant="outlined" type="button" onClick={onCancel}>
                        Cancelar
                    </Button>
                )}
            </div>
        </div>
    );
};

export default ChangeImageModalForm;
