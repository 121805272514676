import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import PersonDetailsSidebar from './sidebar';
import Tab from '@mui/material/Tab/Tab';
import Tabs from '@mui/material/Tabs/Tabs';
import { useTabRoute, UseTabRouteChildren } from 'hooks/tab/use-tab-route';
import { Person, PersonContract } from 'types/models/person';
import { Page } from 'types/models/page';
import { Modify } from 'types/general';

type PersonDetailsScreenProps = {
    person: Modify<Person, { contracts?: PersonContract }>;
    subpages: Page[];
};

const PersonDetailsScreen = ({ subpages, person }: PersonDetailsScreenProps) => {
    const nav = useMemo(() => {
        return (
            subpages.map<UseTabRouteChildren>((item) => ({
                label: item.title,
                path: item.link,
            })) || []
        );
    }, [subpages]);

    const { handleChangeTab, activeTabIndex, tabLabels } = useTabRoute(nav);

    return (
        <div className="bg-base-100 flex -ml-6 -mr-4 -my-6 h-[calc(100%+48px)] max-h-[calc(100%+48px)] overflow-hidden">
            <PersonDetailsSidebar person={person} />
            <section className="flex-1 bg-base-200 overflow-y-auto relative w-full h-full pb-6">
                <div className="bg-base-100 mb-6 pl-6 pr-4">
                    <Tabs variant="scrollable" value={activeTabIndex} onChange={handleChangeTab}>
                        {tabLabels.map((label) => (
                            <Tab label={label} key={label} className="text-base py-5" />
                        ))}
                    </Tabs>
                </div>
                <div className="px-6">
                    <Outlet context={person} />
                </div>
            </section>
        </div>
    );
};

export default PersonDetailsScreen;
