import TableCell from '@mui/material/TableCell/TableCell';
import { jsonToGraphQLQuery, VariableType } from 'json-to-graphql-query';
import { CreateOrUpdateProps } from 'pages/shared/crud/create-or-update';
import { Status } from 'types/general';
import { CrudPageProps } from 'types/graphql';
import { ClassificationType } from 'types/models/classification';
import dictionary from 'utils/dictionary';
import { pkColumnsKey } from 'utils/environment';
import { object, string } from 'yup';

const schema = object({
    name: string().required(dictionary.validation.required),
    code: string().required(dictionary.validation.required),
    abbreviation: string().required(dictionary.validation.required),
});

type FormProps = {
    name: string;
    code: string;
    abbreviation: string;
};

const form: CreateOrUpdateProps<FormProps> = {
    modalClassName: 'w-[600px]',
    formClassName: '!grid-cols-2 gap-4',
    fields: [
        {
            name: 'name',
            type: 'text',
        },
        {
            name: 'code',
            type: 'text',
        },
        {
            name: 'abbreviation',
            type: 'text',
        },
    ],
    beforeSend: (data) => ({ ...data, type: ClassificationType.Sector }),
    schema,
};

const createOrUpdateSectorMutation = (isEdit = false) => {
    return jsonToGraphQLQuery(
        {
            mutation: {
                __name: 'CreateOrUpdateSector',
                __variables: {
                    ...(isEdit
                        ? {
                              payload: 'ClassificationSetInput',
                              id: 'Int!',
                          }
                        : {
                              payload: 'ClassificationInsertInput!',
                          }),
                },
                item: {
                    __aliasFor: isEdit ? 'updateClassificationByPk' : 'insertClassificationOne',
                    __args: {
                        ...(isEdit
                            ? {
                                  [pkColumnsKey]: { id: new VariableType('id') },
                                  _set: new VariableType('payload'),
                              }
                            : {
                                  object: new VariableType('payload'),
                              }),
                    },
                    id: true,
                },
            },
        },
        { pretty: true }
    );
};

const config: CrudPageProps<Partial<FormProps>>[] = [
    {
        title: 'Setores',
        graphql: {
            table: 'classification',
            searchableField: 'name',
            columns: [
                {
                    name: 'id',
                    options: { display: 'excluded' },
                },
                {
                    name: 'code',
                    label: dictionary.content.graphql['code'],
                },
                {
                    name: 'name',
                    label: dictionary.content.graphql['name'],
                },
                {
                    name: 'abbreviation',
                    label: dictionary.content.graphql['abbreviation'],
                    options: {
                        customBodyRender: (value) => value || '-',
                    },
                },
                {
                    name: 'actions',
                    label: 'Ações',
                    options: {
                        customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />,
                    },
                },
            ],
            where: {
                type: { _eq: ClassificationType.Sector },
                status: { _eq: Status.Active },
            },
            orderBy: { name: 'ASC' },
        },
    },
    {
        title: 'Novo setor',
        graphql: {
            table: 'classification',
            query: createOrUpdateSectorMutation(),
        },
        defaultValues: { name: '' },
        form,
    },
    {
        title: 'Atualizar setor',
        graphql: {
            table: 'classification',
            query: createOrUpdateSectorMutation(true),
        },
        defaultValues: { name: '' },
        form,
    },
];

export default config;
