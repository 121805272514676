import Card from 'components/core/card';
import Avatar from '@mui/material/Avatar/Avatar';
import { hexToRgba } from 'utils/string';
import useTheme from '@mui/material/styles/useTheme';
import { formatMoney, formatNumberToPTBR } from 'utils/money';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { getNumberValueOrZero } from 'pages/private/expenses/details/tabs/transactions/utils';
import SvgIcoDollar from 'components/core/icon/files/ico-dollar';

type Variant = 'total' | 'toBePaid' | 'paid';

type ExpenseTotalCardProps = {
    variant: Variant;
    containerClassName?: string;
    avatarClassName?: string;
    iconClassName?: string;
    title?: string;
    head?: Partial<{
        data: React.ReactElement;
        containerClassName: string;
    }>;
    value?: Partial<{
        data: number | string;
        contentContainerClassName: string;
        className: string;
        containerClassName: string;
        isVisible: boolean;
    }>;
    percentage?: Partial<{
        data: number;
        className: string;
    }>;
};

const DEFAULT_VALUE = {
    isVisible: true,
};

const ExpenseTotalCard = ({ value = DEFAULT_VALUE, containerClassName, variant, percentage, head, avatarClassName, iconClassName, title = '-' }: ExpenseTotalCardProps) => {
    const { palette } = useTheme();

    const { isVisible = true } = value;

    const color = useMemo(() => {
        const colorPallete = new Map([
            ['total', { iconBackground: hexToRgba(palette.secondary[100], 0.2), iconColor: palette.secondary[500], titleColor: 'text-heading' }],
            ['toBePaid', { iconBackground: hexToRgba(palette.error[100], 0.2), iconColor: palette.error[500], titleColor: 'text-system-danger-600' }],
            ['paid', { iconBackground: hexToRgba(palette.success[100], 0.2), iconColor: palette.success[500], titleColor: 'text-system-success-600' }],
        ]);

        return colorPallete.get(variant);
    }, [variant, palette]);

    const headContainerClasses = classNames('flex flex-col', head?.containerClassName);
    const avatarClasses = classNames('mb-4 xl:mr-5 xl:mb-0', avatarClassName);

    return (
        <Card className="p-4 flex">
            <div className={classNames('flex flex-col xl:flex-row items-center w-full max-w-full', containerClassName)}>
                <Avatar className={avatarClasses} sx={{ bgcolor: color?.iconBackground, borderRadius: '14px !important', width: 45, height: 45 }} aria-label="recipe">
                    <SvgIcoDollar className={iconClassName} color={color?.iconColor} width={28} height={28} />
                </Avatar>
                <div className={headContainerClasses}>
                    {head?.data || <p className="text-base-500 text-center xl:text-start text-base font-normal">{title}</p>}
                    <div className={classNames('flex flex-col justify-center w-full text-center xl:text-left mb-2 xl:mb-0 text-xl items-center xl:items-start', value?.contentContainerClassName)}>
                        <div className={classNames(value?.containerClassName)}>
                            {isVisible && <strong className={classNames('font-medium', color?.titleColor, value?.className)}>{formatMoney(getNumberValueOrZero(Number(value?.data)))}</strong>}
                        </div>
                        {Number.isFinite(percentage?.data) && (
                            <small className={classNames('text-sm flex-1 w-full text-center xl:text-end', color?.titleColor)}>{formatNumberToPTBR(percentage?.data!)}%</small>
                        )}
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default ExpenseTotalCard;
