import Select from 'components/core/form/select';
import { Controller, useFormContext } from 'react-hook-form';
import Text from 'components/core/text';
import DatePicker from 'components/core/datepicker';
import _get from 'lodash/get';
import { memo, useCallback, useMemo, useState } from 'react';
import useGetClassifications from 'services/queries/classifications/use-get-classifications';
import { generateClassificationQuery } from 'services/queries/classifications/utils';
import { ClassificationType } from 'types/models/classification';
import useGetOptions from 'services/queries/crud/use-get-options';
import { generateGetBranchOptionsQuery } from 'settings/routes/branches/utils';
import Spinner from 'components/core/spinner';
import useGetCompaniesOptions from 'services/queries/companies/use-get-company-options';
import useGetBankAccountsByEntity from 'services/queries/bank-accounts/use-get-bank-accounts-by-entity';
import { LoanPayload } from 'types/models/loan';
import CurrencyInput from 'components/core/form/currency';
import FavoredSelect from 'components/favored-select';
import { favoredPeopleWhere } from 'pages/private/expenses/crud/general';
import { excludeDates } from 'pages/private/payment-order/actions/ops-grouping/general';
import Input from 'components/core/form/input';
import Textarea from 'components/core/form/textarea';
import useGetCompanyBankAccounts from 'services/queries/bank-accounts/use-get-bank-accounts';
import { BankAccount } from 'types/models/bank';
import QueryString from 'qs';
import ModalProviderConsulting from 'pages/private/providers/view/modal-provider-consulting';

const queryFilters = QueryString.stringify({ type: 'all' }, { addQueryPrefix: true, encode: false, arrayFormat: 'brackets', skipNulls: true });

const General = () => {
    const [providerId, setProviderId] = useState<number | null>(null);

    const { control, formState, watch } = useFormContext<LoanPayload>();

    const { data: companies = [] } = useGetCompaniesOptions();

    const [paymentForm, favored, expenseValue] = watch(['expense.paymentForm', 'expense.formFavored', 'expense.instalmentValue']);

    const { data: bankAccounts = [], isLoading: isLoadingBankAccounts } = useGetCompanyBankAccounts<BankAccount[]>(queryFilters);
    const { data: branches = [], isLoading: isLoadingBranches } = useGetOptions(generateGetBranchOptionsQuery(), ['branch', 'options']);
    const { data: paymentForms = [], isLoading: isLoadingPaymentForms } = useGetClassifications(generateClassificationQuery(ClassificationType.PaymentForm));
    const { data: fiscalCodes = [], isLoading: isLoadingFiscalCodes } = useGetClassifications(generateClassificationQuery(ClassificationType.ExpenseFiscalCode));

    const isBankAccountFieldVisible = useMemo(() => {
        return paymentForms
            .filter((item) => item.data.withBankAccount)
            .map(({ value }) => value)
            .includes(paymentForm);
    }, [paymentForm, paymentForms]);

    const { data: favoredBankAccounts = [], isLoading: isLoadingFavoredBankAccounts } = useGetBankAccountsByEntity(favored, isBankAccountFieldVisible);

    const bankAccountsOptions = bankAccounts.map((item) => ({
        value: item.id,
        label: item.name,
    }));

    const isBuilding = useMemo(() => isLoadingBranches || isLoadingPaymentForms || isLoadingBankAccounts, [isLoadingBranches, isLoadingPaymentForms, isLoadingBankAccounts]);

    const getError = useCallback((name: string) => _get(formState.errors, `${name}.message`) as any, [formState]);

    if (isBuilding) {
        return (
            <div className="p-6 mb-6">
                <div className="border border-base-300 p-4 rounded-[14px]">
                    <Spinner fixed={false} size={24} wrapperClasses="p-2 bg-base-100 rounded-full" />
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="px-6 mb-6">
                <div className="border border-base-300 p-4 rounded-[14px] mb-6">
                    <Text as="h6" variant="h5" className="text-heading mb-4">
                        Geral
                    </Text>
                    <div className="md:grid md:grid-cols-4 gap-4">
                        <Controller
                            name="company"
                            control={control}
                            render={({ field }) => {
                                const value = companies.find((item) => item.value === field.value);

                                return (
                                    <Select
                                        {...field}
                                        value={value}
                                        options={companies}
                                        label="Empresa"
                                        placeholder="Selecione uma opção"
                                        parentClassName="col-span-2"
                                        onChange={(option: any) => field.onChange(option.value)}
                                        autoFocus={true}
                                        error={getError('company')}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="branch"
                            control={control}
                            render={({ field }) => {
                                const value = branches.find((item) => item.value === field.value);

                                return (
                                    <Select
                                        {...field}
                                        isLoading={isLoadingBranches}
                                        value={value}
                                        options={branches}
                                        label="Filial"
                                        placeholder="Selecione uma opção"
                                        onChange={(option: any) => field.onChange(option.value)}
                                        error={getError('branch')}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="bankAccount"
                            control={control}
                            render={({ field }) => {
                                const value = bankAccountsOptions.find((item) => item.value === field.value);

                                return (
                                    <Select
                                        {...field}
                                        value={value}
                                        options={bankAccountsOptions}
                                        isLoading={isLoadingBankAccounts}
                                        label="Conta bancária"
                                        placeholder="Selecione uma opção"
                                        onChange={(option: any) => field.onChange(option.value)}
                                        error={getError('favoredBankAccount')}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="loanDate"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <DatePicker
                                        inputProps={{
                                            ...field,
                                            label: 'Data do empréstimo',
                                            placeholderText: 'Selecione uma data',
                                            error: getError('loanDate'),
                                        }}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="value"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <CurrencyInput
                                        label="Principal"
                                        left={
                                            <Text as="span" variant="body.medium.sm" className="text-heading">
                                                R$
                                            </Text>
                                        }
                                        value={field.value}
                                        onValueChange={({ floatValue = 0 }) => field.onChange(floatValue)}
                                        placeholder="Valor"
                                        parentClassName="w-[150px]"
                                        error={getError('value')}
                                    />
                                );
                            }}
                        />
                    </div>
                </div>
                <div className="border border-base-300 p-4 rounded-[14px]">
                    <Text as="h6" variant="h5" className="text-heading mb-4">
                        Despesa
                    </Text>
                    <div className="md:grid md:grid-cols-4 gap-4">
                        <Controller
                            name="expense.title"
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    error={getError('expense.title')}
                                    autoComplete="nope"
                                    type="text"
                                    placeholder="Adicione alguma descrição"
                                    label="Descrição*"
                                    parentClassName="col-span-4"
                                />
                            )}
                        />
                        <Controller
                            name="expense.formFavored"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <FavoredSelect
                                        peopleWhere={favoredPeopleWhere}
                                        error={formState.errors?.expense?.formFavored?.message}
                                        onChange={field.onChange}
                                        value={field.value}
                                        onOpenProviderConsultingModal={setProviderId.bind(this, field.value?.value!)}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="expense.paymentForm"
                            control={control}
                            render={({ field }) => {
                                const value = paymentForms.find((item) => item.value === field.value);

                                return (
                                    <Select
                                        {...field}
                                        isLoading={isLoadingPaymentForms}
                                        value={value}
                                        options={paymentForms}
                                        label="Forma de Pagamento*"
                                        placeholder="Selecione uma opção"
                                        onChange={(option: any) => field.onChange(option.value)}
                                        error={getError('expense.paymentForm')}
                                    />
                                );
                            }}
                        />
                        {isBankAccountFieldVisible && (
                            <Controller
                                name="expense.formFavored.favoredBankAccount"
                                control={control}
                                render={({ field }) => {
                                    const value = favoredBankAccounts.find((item) => item.value === field.value?.value);

                                    return (
                                        <Select
                                            {...field}
                                            value={value}
                                            options={favoredBankAccounts}
                                            isDisabled={!Boolean(favoredBankAccounts?.length)}
                                            isLoading={isLoadingFavoredBankAccounts}
                                            label="Conta bancária"
                                            placeholder="Selecione uma opção"
                                            onChange={(option: any) => field.onChange(option)}
                                            error={getError('expense.formFavored.favoredBankAccount')}
                                        />
                                    );
                                }}
                            />
                        )}
                        <Controller
                            name="expense.dueDate"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <DatePicker
                                        inputProps={{
                                            ...field,
                                            label: 'Vencimento',
                                            placeholderText: 'Selecione uma data',
                                            error: getError('expense.dueDate'),
                                        }}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="expense.provision"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <DatePicker
                                        inputProps={{
                                            ...field,
                                            label: 'Programação',
                                            placeholderText: 'Selecione uam data',
                                            error: getError('expense.provision'),
                                        }}
                                        muiDatePickerProps={{
                                            shouldDisableDate: excludeDates,
                                        }}
                                        withProvisionLogic={true}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="expense.fiscalCode"
                            control={control}
                            render={({ field }) => {
                                const value = fiscalCodes.find((item) => item.value === (field.value as any));

                                return (
                                    <Select
                                        {...field}
                                        isLoading={isLoadingFiscalCodes}
                                        value={value}
                                        options={fiscalCodes}
                                        label="CFOP"
                                        placeholder="Selecione uma opção"
                                        onChange={(option: any) => field.onChange(option.value)}
                                        error={getError('expense.fiscalCode')}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="expense.instalmentValue"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <CurrencyInput
                                        label="Valor da parcela"
                                        left={
                                            <Text as="span" variant="body.medium.sm" className="text-heading">
                                                R$
                                            </Text>
                                        }
                                        value={field.value}
                                        onValueChange={({ floatValue = 0 }) => field.onChange(floatValue)}
                                        placeholder="Valor"
                                        parentClassName="w-[150px]"
                                        error={getError('value')}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="instalments"
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    disabled={!expenseValue || expenseValue <= 0}
                                    autoComplete="nope"
                                    type="number"
                                    min="1"
                                    placeholder="0"
                                    label="Parcelas"
                                    step={1}
                                    error={getError('instalments')}
                                />
                            )}
                        />
                        <Controller
                            name="expense.observations"
                            control={control}
                            render={({ field }) => (
                                <Textarea {...field} error={getError('expense.observations')} label="Observações" placeholder="Adicione alguma observação" parentClassName="col-span-4" />
                            )}
                        />
                    </div>
                </div>
            </div>
            {Boolean(providerId) && <ModalProviderConsulting providerId={providerId!} onClose={setProviderId.bind(this, null)} />}
        </>
    );
};

export default memo(General);
