import { useQueries } from 'react-query';
import useToast from 'hooks/toast/use-toast';
import api from 'services/api';
import { getReportPageByYearKey } from './keys';
import { ReportPageByYearResponse } from 'types/models/reports';

const useGetReportPageByYears = ({ id, years = [] }: { id?: string; years?: number[] }) => {
    const { showToast } = useToast();

    const request = async (year: number) => {
        const { data } = await api.get<ReportPageByYearResponse>(`/reports/pages/${id}/${year}`);

        return data;
    };

    const requests = years.map((year) => ({
        queryKey: getReportPageByYearKey(id, year),
        queryFn: () => request(year),
        enabled: Boolean(year),
        onError: () => {
            showToast('Ocorreu um erro ao buscar os agrupados de anos, por favor, tente novamente.', 'danger');
        },
    }));

    return useQueries(requests);
};

export default useGetReportPageByYears;
