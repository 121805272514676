import useToast from 'hooks/toast/use-toast';
import { useMutation } from 'react-query';
import api from 'services/api';

const useTransactionReleaseInvoiceSending = (outcomeId: number) => {
    const { showToast } = useToast();

    const request = async () => api.patch(`/transactions/outcomes/${outcomeId}/request-release-invoice`);

    return useMutation(request, {
        onSuccess: () => {
            showToast('Invoice liberado com sucesso', 'success');
        },
        onError: () => {
            showToast('Ocorreu um erro ao liberar o invoice', 'danger');
        },
    });
};

export default useTransactionReleaseInvoiceSending;
