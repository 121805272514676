import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { paymentFlowModalConfirmationActions, paymentFlowModalSchema } from './utils';
import { PaymentFlowTransaction } from 'types/models/payment-flow';
import FormButtons from 'components/form-buttons';
import classNames from 'classnames';
import Divider from '@mui/material/Divider';
import { PaymentFlowAction } from '.';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import DatePicker from 'components/core/datepicker';
import { excludeDates } from 'pages/private/payment-order/actions/ops-grouping/general';
import TransactionsTable from './transactions-table';

export type ModalConfirmationPayload = {
    provision?: string;
    transactions: number[];
};

type ModalConfirmationProps = {
    action: PaymentFlowAction | null;
    onSubmit: (payload: ModalConfirmationPayload) => void;
    onClose: () => void;
    items: PaymentFlowTransaction[];
    isSubmitting: boolean;
};

const PaymentFlowModalConfirmation = ({ action, items = [], isSubmitting = false, onSubmit, onClose }: ModalConfirmationProps) => {
    const { formState, control, handleSubmit } = useForm<ModalConfirmationPayload>({
        mode: 'onSubmit',
        resolver: action === 'transfer' ? (yupResolver(paymentFlowModalSchema) as any) : undefined,
    });

    return (
        <Modal
            contentClassnames="w-[800px]"
            closeOnClickOutside={false}
            onClose={onClose}
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    {paymentFlowModalConfirmationActions[action || 'delete'].title}
                </Text>
            }>
            <form onSubmit={handleSubmit(onSubmit)} className="px-6 pb-4">
                <Text as="p" className="text-base-500 mb-3 text-base block">
                    {paymentFlowModalConfirmationActions[action || 'delete'].description}
                </Text>

                <TransactionsTable transactions={items} />

                {action === 'transfer' && (
                    <>
                        <Divider className="-mx-6 mb-4" />
                        <div className="my-4" onSubmit={handleSubmit(onSubmit)}>
                            <Controller
                                name="provision"
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <DatePicker
                                            inputProps={{
                                                placeholderText: 'Selecione a data de previsão',
                                                label: 'Provisão',
                                                error: formState.errors.provision?.message,
                                                ...field,
                                            }}
                                            muiDatePickerProps={{
                                                shouldDisableDate: excludeDates,
                                            }}
                                            withProvisionLogic={true}
                                        />
                                    );
                                }}
                            />
                        </div>
                    </>
                )}
                <FormButtons
                    cancelButton={{
                        onClick: onClose,
                    }}
                    submitButton={{
                        className: classNames(action === 'delete' ? 'bg-system-danger-500 text-white' : ''),
                    }}
                    isLoading={isSubmitting}
                />
            </form>
        </Modal>
    );
};

export default PaymentFlowModalConfirmation;
