import * as React from 'react';
const SvgIcoDollar = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" width="1em" height="1em" {...props}>
        <path
            fill="currentColor"
            d="M12.37 9.268c-.627-.347-1.295-.611-1.956-.885-.383-.16-.75-.344-1.074-.602-.638-.509-.515-1.335.231-1.662.212-.093.433-.122.658-.136a4.991 4.991 0 0 1 2.472.49c.39.188.519.128.65-.278.14-.43.255-.866.384-1.299.086-.29-.02-.482-.294-.604a6.283 6.283 0 0 0-1.56-.466c-.707-.11-.707-.113-.71-.823C11.168 2 11.168 2 10.163 2c-.145 0-.29-.003-.436 0-.47.014-.549.096-.562.569-.007.211 0 .423-.003.638-.004.627-.007.618-.608.836C7.1 4.572 6.2 5.564 6.105 7.15c-.086 1.404.648 2.353 1.801 3.043.71.427 1.497.678 2.25 1.012.294.129.575.277.82.482.724.598.591 1.593-.268 1.97-.46.201-.945.25-1.444.188-.77-.096-1.507-.298-2.2-.658-.407-.211-.526-.155-.665.285-.119.38-.225.763-.33 1.146-.142.516-.09.638.403.88.628.303 1.298.459 1.983.568.535.085.551.109.558.664.003.25.003.505.007.756.003.318.155.503.482.51.37.006.744.006 1.114-.004.304-.007.459-.172.459-.48 0-.343.017-.69.003-1.033-.016-.35.136-.53.473-.622a3.735 3.735 0 0 0 1.946-1.249c1.415-1.718.876-4.233-1.127-5.34Z"
        />
    </svg>
);
export default SvgIcoDollar;
