import { ApprovalStatus } from 'types/general';
import { BaseModel } from './base';
import { File } from './file';
import { CompanyBankAccount } from './company';
import { Transaction } from './transaction';

export type PaymentFlow = {
    companyBankAccountId: number;
    total: string;
    paidTotal: string;
    paymentFlowStatus: ApprovalStatus;
    provision: string;
    paymentFlowFiles: PaymentFlowFiles[];
    files: PaymentFlowFiles[];
    paymentFlowTransactions: PaymentFlowTransaction[];
    companyBankAccount: Partial<CompanyBankAccount>;
} & BaseModel;

export type PaymentFlowTransaction = {
    eligibleForRemittance: boolean;
    inRemittance: boolean;
    paymentFlowTransactionStatus: PaymentFlowTransactionStatus;
    transaction: Partial<Transaction>;
} & BaseModel;

export enum PaymentFlowTransactionStatus {
    Deleted = -1,
    Waiting = 1,
    Paid = 2,
    Transferred = 3,
    Debt = 4,
}

export type PaymentFlowFiles = {
    id: number;
    total: string;
    file: File;
    type: any; // TODO:
};
