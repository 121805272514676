import { Controller, ControllerRenderProps, FieldValues, useFormContext } from 'react-hook-form';
import _get from 'lodash/get';
import Input from 'components/core/form/input';
import Textarea from 'components/core/form/textarea';
import Select from 'components/core/form/select';
import { Option } from 'types/general';
import Text from 'components/core/text';
import RemoveButton from 'components/buttons/remove';
import Dropzone from 'components/dropzone';
import ErrorMessage from 'components/error/message';
import { generateFileUrl } from 'utils/file';
import DownloadButton from 'components/download';
import { transactionDocumentTypes } from 'utils/statics';

type DocumentsFieldsProps = {
    classifications: Option[];
    isTransaction: boolean;
};

const DocumentsFields = ({ classifications = [], isTransaction = false }: DocumentsFieldsProps) => {
    const { control, formState, setValue, register } = useFormContext();

    const getError = (name: string) => _get(formState.errors, `${name}.message`) as any;

    const handleChangeFile = (field: ControllerRenderProps<FieldValues, 'file'>) => (filesList: File[]) => {
        const [file] = filesList || [];

        field.onChange(file);
    };

    const handleClickRemove = () => setValue('file', '');

    return (
        <>
            <div className="flex flex-col md:grid grid-cols-2 gap-4 mb-4">
                <Input {...register('name')} autoFocus={true} error={getError('name')} label="Nome*" />
                {isTransaction ? (
                    <Controller
                        name="type"
                        control={control}
                        render={({ field }) => {
                            const value = transactionDocumentTypes.find((item) => item.value === field.value);

                            return (
                                <Select
                                    {...field}
                                    value={value}
                                    options={transactionDocumentTypes}
                                    label="Tipo*"
                                    placeholder="Selecione um tipo"
                                    error={getError('type')}
                                    onChange={(option: any) => field.onChange(option.value)}
                                />
                            );
                        }}
                    />
                ) : (
                    <Controller
                        name="classification"
                        control={control}
                        render={({ field }) => {
                            const value = classifications.find((item) => item.value === field.value);

                            return (
                                <Select
                                    {...field}
                                    value={value}
                                    options={classifications}
                                    label="Classificação*"
                                    placeholder="Selecione uma classificação"
                                    error={getError('classification')}
                                    onChange={(option: any) => field.onChange(option.value)}
                                />
                            );
                        }}
                    />
                )}
                <Textarea {...register('description')} label="Descrição" placeholder="Descrição" error={getError('description')} />
                <Textarea {...register('observation')} label="Observação" placeholder="Observação" error={getError('observation')} />
            </div>
            <div className="mb-6 relative">
                <Text as="label" variant="body.regular.sm" className="block mb-1.5 text-base-500">
                    Arquivo*
                </Text>
                <Controller
                    name="file"
                    control={control}
                    render={({ field }) => {
                        if (!!field.value) {
                            const link = field.value?.id ? generateFileUrl(field.value?.filename, field.value?.path) : URL.createObjectURL(field.value);

                            return (
                                <div className="flex items-center h-[120px] border border-base-300 rounded-[14px] px-4 py-3 relative">
                                    {!!field.value?.id ? (
                                        <DownloadButton fileName={field?.value.originalName} fileId={field.value.id} />
                                    ) : (
                                        <a href={link} target="_blank" rel="noreferrer">
                                            <div className="flex flex-col">
                                                <span className="font-medium text-heading mb-1">{field.value?.originalName ?? field.value?.name}</span>
                                                <span className="italic text-sm">Clique para visualizar o arquivo.</span>
                                            </div>
                                        </a>
                                    )}
                                    <RemoveButton onClick={handleClickRemove} className="!opacity-100 !right-4" />
                                </div>
                            );
                        }

                        return <Dropzone multiple={false} onUploadFiles={handleChangeFile(field)} />;
                    }}
                />
                <ErrorMessage visible={Boolean(formState.errors?.file)}>Selecione um arquivo</ErrorMessage>
            </div>
        </>
    );
};

export default DocumentsFields;
