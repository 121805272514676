import { RouteObject } from 'react-router-dom';
import { Rule } from 'types/permissions';
import Page from 'pages/shared/page';
import SalesNotesListPage from 'pages/private/sales-notes';
import CreateOrUpdateSaleNotesPage from 'pages/private/sales-notes/create-or-update';
import SaleNotesDetails from 'pages/private/sales-notes/view/details';

const saleNotes: RouteObject[] = [
    {
        // @ts-ignore
        breadcrumb: 'Notas de vendas',
        path: 'notas-de-venda',
        element: <Page Component={SalesNotesListPage} code="notas-de-vendas" permissions={[Rule.List]} />,
        children: [
            {
                path: 'novo',
                element: <Page Component={CreateOrUpdateSaleNotesPage} title="Nova nota de venda" code="notas-de-vendas" permissions={[Rule.Create]} withModal={true} />,
            },
            {
                path: ':saleNoteId',
                element: <Page Component={SaleNotesDetails} title="Detalhes da nota de venda" code="notas-de-vendas" permissions={[Rule.Show, Rule.List]} withModal={true} />,
            },
            {
                path: 'editar/:saleNoteId',
                element: <Page Component={CreateOrUpdateSaleNotesPage} title="Editar nota de venda" code="notas-de-vendas" permissions={[Rule.Update]} withModal={true} />,
            },
        ],
    },
];

export default saleNotes;
