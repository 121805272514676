import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Text from 'components/core/text';
import { PaymentFlowTransaction } from 'types/models/payment-flow';
import { formatMoney } from 'utils/money';
import TableFooter from '@mui/material/TableFooter';
import sumBy from 'lodash/sumBy';
import Empty from 'components/empty';

type TransactionsTableProps = {
    transactions: PaymentFlowTransaction[];
    isRemittanceDetails?: boolean;
};

const TransactionsTable = ({ transactions = [], isRemittanceDetails = false }: TransactionsTableProps) => {
    const totalItemsValue = sumBy(transactions, 'transaction.netValue');

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        <Text as="span" className="text-base-500">
                            Código
                        </Text>
                    </TableCell>
                    <TableCell>
                        <Text as="span" className="text-base-500">
                            Descrição
                        </Text>
                    </TableCell>
                    {!isRemittanceDetails && (
                        <>
                            <TableCell>
                                <Text as="span" className="text-base-500">
                                    Favorecido
                                </Text>
                            </TableCell>
                            <TableCell>
                                <Text as="span" className="text-base-500">
                                    Forma de pagamento
                                </Text>
                            </TableCell>
                        </>
                    )}
                    <TableCell>
                        <Text as="span" className="text-base-500">
                            Valor
                        </Text>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {transactions.map((item) => (
                    <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell>
                            <Text as="span" className="text-base-500">
                                {item.transaction?.code || '-'}
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text as="span" className="text-base-500 whitespace-nowrap">
                                {item.transaction?.description || '-'}
                            </Text>
                        </TableCell>
                        {!isRemittanceDetails && (
                            <>
                                <TableCell>
                                    <Text as="span" className="text-base-500 whitespace-nowrap">
                                        {item.transaction?.expense?.favored?.title || '-'}
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text as="span" className="text-base-500">
                                        {item.transaction?.expense?.paymentForm?.name || '-'}
                                    </Text>
                                </TableCell>
                            </>
                        )}
                        <TableCell>
                            <Text as="span" className="text-base-500">
                                {formatMoney(item.transaction.netValue)}
                            </Text>
                        </TableCell>
                    </TableRow>
                ))}

                {!transactions.length && (
                    <TableRow>
                        <TableCell colSpan={5}>
                            <Empty title="Não há transações a serem mostradas" />
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
            <TableFooter>
                <TableRow className="bg-base-200">
                    <TableCell className="border-b-0" colSpan={isRemittanceDetails ? 2 : 4}>
                        <Text className="text-base-500 uppercase font-bold">Total</Text>
                    </TableCell>
                    <TableCell className="border-b-0">
                        <Text className="text-heading font-bold">{formatMoney(totalItemsValue)}</Text>
                    </TableCell>
                </TableRow>
            </TableFooter>
        </Table>
    );
};

export default TransactionsTable;
