import { useMutation } from 'react-query';
import api from 'services/api';
import useToast from 'hooks/toast/use-toast';

export type UseReportPaymentPayload = {
    companyBankAccount: number;
    payday: string;
    transactions: number[];
};

const useTransactionReportPaymentOrReceipt = (isReceipt = false) => {
    const { showToast } = useToast();

    const endpoint = isReceipt ? '/transactions/incomes/report-payment' : '/transactions/outcomes/report-payment';

    const request = async (payload: UseReportPaymentPayload) => api.patch(endpoint, payload);

    return useMutation(request, {
        onSuccess: () => {
            showToast(`${isReceipt ? 'Recebimentos' : 'Pagamentos'} atualizados com sucesso!`, 'success');
        },
        onError: (error: any) => {
            showToast(error?.response?.data?.title ?? `Tivemos um problema ao atualizar os ${isReceipt ? 'recebimentos' : 'pagamentos'}. Tente novamente.`, 'danger');
        },
    });
};

export default useTransactionReportPaymentOrReceipt;
