import Spinner from 'components/core/spinner';
import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import ListPage from 'pages/shared/crud/list';
import useCustomerDetails from '../hooks/use-customer-details';
import { customerDetailsContactsListConfig } from './utils';

const CustomerDetailsContacts = ({ page }: WithPagePermissionsProps) => {
    const { id } = useCustomerDetails();

    if (!id) {
        return (
            <div className="relative w-full h-full">
                <Spinner />
            </div>
        );
    }

    const listConfig = customerDetailsContactsListConfig(id);

    return <ListPage page={page} {...listConfig} />;
};

export default CustomerDetailsContacts;
