import { jsonToGraphQLQuery } from 'json-to-graphql-query/lib/jsonToGraphQLQuery';
import { EnumType } from 'json-to-graphql-query/lib/types/EnumType';
import { Status } from 'types/general';
import { BankAccountType } from 'types/models/bank';
import { generateYearsArr } from 'utils/date';
import dictionary from 'utils/dictionary';
import { mixed, number, object, string } from 'yup';

export const banckAccountSchema = object({
    bank: number().required(dictionary.validation.required),
    type: number().oneOf([BankAccountType.Open, BankAccountType.Savings]),
    agency: string().required(dictionary.validation.required),
    account: string().required(dictionary.validation.required),
    accountDigit: string().required(dictionary.validation.required),
    name: string().required(dictionary.validation.required),
    agencyDigit: string(),
    company: number(),
    openingBalance: number(),
    status: number(),
    pix: string(),
    pixType: string(),
});

const commonBankAccountActionsSchema = object({
    dueDate: string().required(dictionary.validation.required),
    grossValue: number().required(dictionary.validation.required),
    description: string().required(dictionary.validation.required),
});

export const contributionSchema = object({
    companyPartner: number().required(dictionary.validation.required),
    paymentForm: number().required(dictionary.validation.required),
}).concat(commonBankAccountActionsSchema);

export const incomeSchema = object({
    paymentForm: number().required(dictionary.validation.required),
}).concat(commonBankAccountActionsSchema);

export const transferBankAccountSchema = (companyId: number) => {
    return object({
        company: number().required(dictionary.validation.required),
        companyBankAccount: number().required(dictionary.validation.required),
        dueDate: string().required(dictionary.validation.required),
        grossValue: number().required(dictionary.validation.required),
        observation: string().required(dictionary.validation.required),
        document: mixed().when('company', ([company], schema) => {
            if (company === companyId) {
                return schema;
            }

            return schema.required(dictionary.validation.required);
        }),
    });
};

export const queryConfigCompanyPartners = (whereClause?: object) =>
    jsonToGraphQLQuery({
        query: {
            __name: 'getCompanyPartner',
            items: {
                __aliasFor: 'companyPartner',
                __args: {
                    where: {
                        status: {
                            _eq: Status.Active,
                        },
                        ...(Boolean(whereClause) && whereClause),
                    },
                    orderBy: {
                        person: {
                            name: new EnumType('ASC'),
                        },
                    },
                },
                value: { __aliasFor: 'id' },
                person: {
                    label: { __aliasFor: 'name' },
                },
            },
        },
    });

export const formatPartnersContribution = (items: any = []) => items.map((item) => ({ value: item?.value, label: item?.person?.label }));

export const exportFilesYearsOptions = generateYearsArr(undefined, 10).map((item) => ({ label: String(item), value: item }));
