import { useQuery } from 'react-query';
import apiGraphQl from 'services/graphql';
import { Option, Status } from 'types/general';
import { getCompanyOptionsKey } from './keys';
import { WhereClause } from 'types/graphql';
import { Company } from 'types/models/company';
import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';

type Response = {
    items: Option[];
};

const generateCompanyOptionsQuery = (where?: WhereClause<Partial<Company>>['where']) => {
    return jsonToGraphQLQuery(
        {
            query: {
                __name: 'GetCompanyOptions',
                items: {
                    __aliasFor: 'company',
                    __args: {
                        orderBy: { name: new EnumType('ASC') },
                        where: {
                            status: { _eq: Status.Active },
                            ...(Boolean(where) && where),
                        },
                    },
                    value: { __aliasFor: 'id' },
                    label: { __aliasFor: 'name' },
                },
            },
        },
        { pretty: true }
    );
};

const useGetCompaniesOptions = (where?: WhereClause<Partial<Company>>['where'], query = generateCompanyOptionsQuery(where), enabled = true) => {
    const request = async () => {
        const { items } = await apiGraphQl<Response>(query);

        return items;
    };

    return useQuery(getCompanyOptionsKey, request, { enabled });
};

export default useGetCompaniesOptions;
