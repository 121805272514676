import Select from 'components/core/form/select';
import { memo, useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import Button from 'components/core/button';
import GenericReportFiltersContent from 'pages/private/reports/components/generic-filters';
import { DataContentFiltersConfig } from 'pages/private/reports/screens/types';
import { genericReportDefaultValues, handleChangeReportSelect } from 'pages/private/reports/screens/utils';
import { useTableAdvancedSearch } from 'components/core/table/datatable/components/advanced-search';
import { yupResolver } from '@hookform/resolvers/yup';
import { orderServicesReportSchema } from 'pages/private/reports/screens/order-services/utils';
import dayjs from 'dayjs';
import { formatDate } from 'utils/date';
import { OrderServiceFiltersPayload } from 'pages/private/sales-orders/service-order/types';
import { removeFalsyValues } from 'utils/object';
import isEmpty from 'lodash/isEmpty';
import FilterPeriod from 'pages/private/payment-order/filter/period';

type OrderServiceFiltersProps = {
    filters?: OrderServiceFiltersPayload;
    onSetFilters: (data?: OrderServiceFiltersPayload) => void;
    onSetHasSelectedFilters: React.Dispatch<React.SetStateAction<boolean>>;
    dataContentFiltersConfig: DataContentFiltersConfig;
};

const OrderServiceFilters = ({ filters, dataContentFiltersConfig, onSetFilters, onSetHasSelectedFilters }: OrderServiceFiltersProps) => {
    const { onClose } = useTableAdvancedSearch();

    const { saleOrders = [], orderServiceStatus = [] } = dataContentFiltersConfig;

    const methods = useForm<OrderServiceFiltersPayload>({
        mode: 'onSubmit',
        resolver: yupResolver(orderServicesReportSchema) as any,
        defaultValues: genericReportDefaultValues,
    });

    const { control, handleSubmit, reset, formState } = methods;

    const submit = (data: OrderServiceFiltersPayload) => {
        const truthValues: OrderServiceFiltersPayload = removeFalsyValues(data);

        const { startDate, endDate, startConclusionDate, endConclusionDate, ...rest } = truthValues;

        onSetFilters({
            ...rest,
            searchText: filters?.searchText,
            ...(!!startDate && {
                startDate: formatDate(startDate, 'YYYY-MM-DD'),
            }),
            ...(!!endDate && {
                endDate: formatDate(endDate, 'YYYY-MM-DD'),
            }),
            ...(!!startConclusionDate && {
                startConclusionDate: formatDate(startConclusionDate, 'YYYY-MM-DD'),
            }),
            ...(!!endConclusionDate && {
                endConclusionDate: formatDate(endConclusionDate, 'YYYY-MM-DD'),
            }),
        });

        onSetHasSelectedFilters(!isEmpty(truthValues));

        onClose();
    };

    useEffect(() => {
        if (!!filters) {
            const { endDate, startDate, startConclusionDate, endConclusionDate, ...rest } = filters;

            reset({
                ...rest,
                ...(Boolean(startDate) && { startDate: dayjs(startDate).toISOString() }),
                ...(Boolean(endDate) && { endDate: dayjs(endDate).toISOString() }),
                ...(Boolean(startConclusionDate) && { startConclusionDate: dayjs(startConclusionDate).toISOString() }),
                ...(Boolean(endConclusionDate) && { endConclusionDate: dayjs(endConclusionDate).toISOString() }),
            });
        }
    }, [filters, reset]);

    return (
        <form onSubmit={handleSubmit(submit)} className="max-h-[450px] sm:max-h-[630px] overflow-y-auto">
            <div className="mb-5 flex flex-col gap-2">
                <FormProvider {...methods}>
                    <GenericReportFiltersContent dataContentFiltersConfig={dataContentFiltersConfig}>
                        <Controller
                            control={control}
                            name="saleOrders"
                            render={({ field }) => {
                                const value = saleOrders.filter((item) => field?.value?.includes(item.value));

                                return (
                                    <Select
                                        {...field}
                                        options={saleOrders}
                                        value={value}
                                        isDisabled={!saleOrders.length}
                                        isClearable={true}
                                        isMulti={true}
                                        placeholder="Selecione um contrato"
                                        label="Contratos"
                                        error={formState.errors.saleOrders?.message}
                                        parentClassName="min-w-full"
                                        onChange={handleChangeReportSelect(field.onChange)}
                                    />
                                );
                            }}
                        />
                        <Controller
                            control={control}
                            name="orderServiceStatus"
                            render={({ field }) => {
                                const value = orderServiceStatus.filter((item) => field?.value?.includes(item.value));

                                return (
                                    <Select
                                        {...field}
                                        options={orderServiceStatus}
                                        value={value}
                                        isDisabled={!orderServiceStatus.length}
                                        isClearable={true}
                                        isMulti={true}
                                        placeholder="Selecione um status"
                                        label="Status"
                                        error={formState.errors.orderServiceStatus?.message}
                                        parentClassName="min-w-full"
                                        onChange={handleChangeReportSelect(field.onChange)}
                                    />
                                );
                            }}
                        />
                        <FilterPeriod
                            control={control}
                            label="Período de conclusão"
                            firstInput={{
                                inputProps: {
                                    label: 'Início',
                                    name: 'startConclusionDate',
                                    error: formState.errors.startConclusionDate?.message,
                                },
                            }}
                            secondInput={{
                                inputProps: {
                                    label: 'Fim',
                                    name: 'endConclusionDate',
                                    error: formState.errors.endConclusionDate?.message,
                                },
                            }}
                        />
                    </GenericReportFiltersContent>
                </FormProvider>
            </div>
            <div className="flex">
                <Button type="submit" variant="contained" color="secondary" className="min-w-[100px] ml-auto">
                    Filtrar
                </Button>
            </div>
        </form>
    );
};

export default memo(OrderServiceFilters);
