import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { GetOrderServicesBalanceTotalReponse } from 'services/queries/order-services/use-get-order-services-balance-total';
import { generateFinancialBalanceTableTaxContent } from '../utils';
import ServiceOrderFinancialBalanceTotalsTable from './table';

type FinancialBalanceModalTaxesProps = {
    onClose: () => void;
    taxes?: GetOrderServicesBalanceTotalReponse['taxes'];
};

const FinancialBalanceModalTaxes = ({ taxes, onClose }: FinancialBalanceModalTaxesProps) => {
    return (
        <Modal
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    Impostos
                </Text>
            }
            contentClassnames="w-[400px]"
            closeOnClickOutside={false}
            onClose={onClose}>
            <ServiceOrderFinancialBalanceTotalsTable className="shadow-none border border-base-400 rounded-xl mb-4 mx-4" items={generateFinancialBalanceTableTaxContent(taxes)} />
        </Modal>
    );
};

export default FinancialBalanceModalTaxes;
