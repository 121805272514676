import Tab from '@mui/material/Tab/Tab';
import Tabs from '@mui/material/Tabs/Tabs';
import classNames from 'classnames';
import { UseTabRouteChildren, useTabRoute } from 'hooks/tab/use-tab-route';

export type InterMenuTabProps = {
    orientation?: 'horizontal' | 'vertical';
    navItems: UseTabRouteChildren[];
};

const InterMenuTab = ({ orientation, navItems }: InterMenuTabProps) => {
    const { handleChangeTab, activeTabIndex, tabLabels } = useTabRoute(navItems);

    return (
        <Tabs
            orientation={orientation}
            variant="scrollable"
            value={activeTabIndex}
            onChange={handleChangeTab}
            className="border-b-0"
            sx={{
                '.Mui-disabled': {
                    display: 'none',
                },
            }}>
            {tabLabels.map((label) => (
                <Tab label={label} key={label} className={classNames(orientation === 'vertical' ? 'text-sm py-4 items-start px-5 md:text-left' : 'text-base py-5')} />
            ))}
        </Tabs>
    );
};

export default InterMenuTab;
