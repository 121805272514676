import { formatMoney } from 'utils/money';
import { hexToRgba } from 'utils/string';

const QuarterItem = ({ value, percentage, color }: { value: number; percentage: number; color: string }) => (
    <div className="w-full border-slate-100 absolute top-0 left-0 h-full">
        <div className="flex justify-between px-2 h-full items-center">
            <span>R$</span>
            <span>{formatMoney(value as number)?.replace('R$', '')}</span>
        </div>
        <div className="w-full h-full absolute top-0 left-0 opacity-20">
            <div
                className="bg-sky-600  h-full"
                style={{
                    width: `${percentage < 0 ? 0 : percentage}%`,
                    maxWidth: '100%',
                    background: `linear-gradient(90deg, ${color} 0%, ${hexToRgba(color, 0.3)} 100%)`,
                }}></div>
        </div>
    </div>
);

export default QuarterItem;
