import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';
import { Status } from 'types/general';
import { DatatableColumn } from 'types/graphql';
import { formatDate } from 'utils/date';
import { formatMoney } from 'utils/money';

export const columnsContributionsTable: DatatableColumn[] = [
    {
        name: 'code',
        label: 'Código',
        options: {
            customBodyRender: (value) => value || '-'
        }
    },
    {
        name: 'companyBankAccount.company.name',
        label: 'Empresa',
        options: {
            customBodyRender: (value) => value || '-'
        }
    },
    {
        name: 'companyBankAccount.name',
        label: 'Conta Bancária',
        options: {
            customBodyRender: (value) => value || '-'
        }
    },
    {
        name: 'companyPartner.person.name',
        label: 'Sócio',
        options: {
            customBodyRender: (value) => value || '-'
        }
    },
    {
        name: 'payday',
        label: 'Data',
        options: {
            customBodyRender: (value?: string) => formatDate(value)
        }
    },
    {
        name: 'grossValue',
        label: 'Valor',
        options: {
            customBodyRender: (value) => formatMoney(value)
        }
    }
];

export const queryConfigCompanies = jsonToGraphQLQuery({
    query: {
        __name: 'GetCompaniesOptions',
        items: {
            __aliasFor: 'company',
            __args: {
                orderBy: {
                    name: new EnumType('ASC')
                },
                where: {
                    status: { _eq: Status.Active }
                }
            },
            label: { __aliasFor: 'name' },
            value: { __aliasFor: 'id' }
        }
    }
});
