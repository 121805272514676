import Datatable from 'components/core/table/datatable';
import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import { expensesDatatableColumns } from './utils';
import { Rule } from 'types/permissions';
import PageHeader from 'components/page/header';
import { Link, Outlet } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import useTheme from '@mui/material/styles/useTheme';
import { useState } from 'react';
import useGetPaginatedWithFilters from 'services/queries/general/use-get-paginated-with-filters';
import { Expense } from 'types/models/expense';
import SvgIcoPlus from 'components/core/icon/files/ico-plus';

const ENDPOINT = '/expenses';

const ExpensesPage = ({ page: pagePermissions }: WithPagePermissionsProps) => {
    const [page, setPage] = useState(1);
    const [filters, setFilters] = useState<any[]>([]);
    const [orderBy, setOrderBy] = useState<any>(null);

    const { data: expenses } = useGetPaginatedWithFilters<Expense>({ endpoint: ENDPOINT, filters, page, orderBy });

    const { palette } = useTheme();

    const actions = pagePermissions?.rules.map((item) => item.key);
    const canCreateExpense = actions?.includes(Rule.Create);

    return (
        <>
            <PageHeader
                className="mb-6"
                title="Despesas"
                right={
                    canCreateExpense ? (
                        <IconButton component={Link} to="novo" color="secondary" size="large" sx={{ backgroundColor: palette.secondary.main, '&:hover': { backgroundColor: palette.secondary.dark } }}>
                            <SvgIcoPlus width={16} height={16} color={palette.grey[100]} />
                        </IconButton>
                    ) : undefined
                }
            />
            <Datatable
                actions={actions}
                data={expenses?.items || []}
                columns={expensesDatatableColumns(false)}
                options={{
                    sort: true,
                    filter: false,
                    search: true,
                    print: false,
                    download: false,
                    viewColumns: false,
                    serverSide: true,
                    searchAlwaysOpen: true,
                    enableNestedDataAccess: '.',
                    pagination: true,
                    elevation: 21,
                    count: expenses?.total,
                    page,
                    onChangePage: setPage,
                    onSearchChange: (searchText) => {
                        setFilters((prev) => ({ ...prev, searchText }));
                    },
                    onColumnSortChange: (changedColumn, direction) => {
                        setOrderBy({ [changedColumn]: direction.toUpperCase() });
                    },
                }}
            />
            <Outlet />
        </>
    );
};

export default ExpensesPage;
