import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import { CreateIncomePayload } from 'types/models/bank-accounts';
import { getBankAccountByIdKey, getBankAccountTransactionsKey } from './keys';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';

const useCreateBankAccountContribution = (queryFilters: string, bankAccountId?: string) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const goToParentRoute = useNavigateToParentRoute();

    const request = (payload: CreateIncomePayload) => api.post(`/company-bank-accounts/${bankAccountId}/transactions/contributions`, payload);

    return useMutation(request, {
        onSuccess: () => {
            showToast('Aporte cadastrado com sucesso', 'success');

            queryClient.invalidateQueries(getBankAccountByIdKey(Number(bankAccountId)));
            queryClient.invalidateQueries(getBankAccountTransactionsKey(Number(bankAccountId), queryFilters));

            goToParentRoute();
        },
        onError: () => {
            showToast('Ocorreu um erro ao incluir um novo aporte', 'danger');
        },
    });
};

export default useCreateBankAccountContribution;
