import OrderServiceListPage from 'pages/private/sales-orders/service-order';
import Page from 'pages/shared/page';
import { RouteObject } from 'react-router-dom';
import { Rule } from 'types/permissions';

const serviceOrders: RouteObject[] = [
    {
        path: 'ordens-de-servico',
        element: <Page Component={OrderServiceListPage} code="ordens-de-servico" permissions={[Rule.List]} />,
    },
];

export default serviceOrders;
