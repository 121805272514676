import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store';
import { Creators as UpdateActions } from 'store/update';

const useUpdate = () => {
    const dispatch = useDispatch();
    const update = useAppSelector(({ update }) => update);

    return {
        update,
        init: () => dispatch(UpdateActions.initServiceWorker()),
        updateApplication: (payload: ServiceWorkerRegistration) => dispatch(UpdateActions.updateApplication(payload)),
    };
};

export default useUpdate;
