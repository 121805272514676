import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import ListPage from 'pages/shared/crud/list';
import Spinner from 'components/core/spinner';
import { customerProviderAddressesListConfig } from './utils';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ConfirmModal from 'components/core/modal/confirm';
import { useState } from 'react';
import useDelete from 'services/queries/crud/use-delete';

const CustomerAddressesPage = ({ page }: WithPagePermissionsProps) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { customerId, providerId } = useParams();

    const [addressId, setAddressId] = useState<number>();

    const isCustomer = pathname.includes('clientes');
    const id = isCustomer ? customerId : providerId;

    const { mutateAsync: deleteAddress, isLoading: isDeleting } = useDelete({
        apiPayload: {
            useApiTodelete: true,
            endpoint: isCustomer ? '/customer-address' : '/provider-address',
        },
    });

    const handleGoTo = (path: string) => (event: React.MouseEvent<Element, MouseEvent>) => {
        if (event.ctrlKey || event.metaKey) {
            return window.open(`${pathname}/${path}`, '_blank');
        }

        navigate(path);
    };

    const handleDelete = async () => {
        try {
            if (!!addressId) {
                await deleteAddress({ id: addressId });

                setAddressId(undefined);
            }
        } catch (error) {
            console.log('handleDelete', error);
        }
    };

    const listConfig = customerProviderAddressesListConfig({
        customerId,
        providerId,
        onDelete: setAddressId,
        onGoTo: handleGoTo,
    });

    if (!id) {
        return (
            <div className="relative w-full h-full">
                <Spinner />
            </div>
        );
    }

    return (
        <>
            <ListPage page={page} {...listConfig} />
            {!!addressId && (
                <ConfirmModal
                    title="Apagar endereço"
                    description="Você tem certeza que deseja apagar este endereço?"
                    isLoading={isDeleting}
                    onClose={setAddressId.bind(this, undefined)}
                    onConfirmAction={handleDelete}
                />
            )}
        </>
    );
};

export default CustomerAddressesPage;
