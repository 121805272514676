import { useState } from 'react';
import { useParams } from 'react-router-dom';
import ListPage from 'pages/shared/crud/list';
import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { saleOrderDocumentsListConfig } from './utils';

type Content = { title: string; content: string };

const SaleOrderDocumentsPage = ({ page }: WithPagePermissionsProps) => {
    const { saleOrderId } = useParams();

    const [content, setContent] = useState<Content | undefined>();

    const handleSetContent = (content?: Content) => () => setContent(content);

    const listConfig = saleOrderDocumentsListConfig(handleSetContent, saleOrderId);

    return (
        <>
            <ListPage page={page} {...listConfig} />
            {Boolean(content) && (
                <Modal
                    contentClassnames="w-[500px]"
                    headerLeft={
                        <Text as="h3" variant="h4" className="text-heading">
                            {content?.title}
                        </Text>
                    }
                    onClose={handleSetContent(undefined)}>
                    <div className="rich-content p-6 pt-0" dangerouslySetInnerHTML={{ __html: content?.content! }} />
                </Modal>
            )}
        </>
    );
};

export default SaleOrderDocumentsPage;
