import { useQuery } from 'react-query';
import api from 'services/api';
import { getOrderServiceMovementsKey } from './keys';
import { OrderServiceMovements } from 'types/models/order-service';

const useGetOrderServiceMovements = (orderServiceId?: string, saleOrderId?: string) => {
    const request = async () => {
        const { data } = await api.get<OrderServiceMovements[]>(`/sale-orders/${saleOrderId}/service-orders/${orderServiceId}/movements`);

        return data;
    };

    return useQuery(getOrderServiceMovementsKey(orderServiceId, saleOrderId), request, {
        enabled: Boolean(orderServiceId) && Boolean(saleOrderId),
    });
};

export default useGetOrderServiceMovements;
