import { useQuery } from 'react-query';
import apiGraphQl from 'services/graphql';
import { getSaleOrdersKey } from './keys';
import { QueryConfig, generateQuery } from 'utils/query';
import { SaleOrder } from 'types/models/sale-order';
import { Option } from 'types/general';

type ReturnType<T> = T extends true ? Option[] : T extends false ? Partial<SaleOrder>[] : never;

const useGetSaleOrders = <T extends true | false>(config: QueryConfig<SaleOrder>, enabled = false, withOptionsFormat: T = false as T) => {
    const request = async () => {
        const { saleOrder } = await apiGraphQl<{ saleOrder: Partial<SaleOrder>[] }>(generateQuery<SaleOrder>(config));

        return saleOrder;
    };

    return useQuery(getSaleOrdersKey(config), request, {
        enabled,
        select: (data = []) => {
            if (!withOptionsFormat) {
                return data as ReturnType<T>;
            }

            const options = data.map<Option>((item) => ({ value: Number(item.id || 0), label: item.code! }));

            return options as ReturnType<T>;
        },
    });
};

export default useGetSaleOrders;
