import { useQuery } from 'react-query';
import { Status } from 'types/general';
import apiGraphQl from 'services/graphql';
import queries, { getAllKey } from './keys';
import { GraphqlPagination, GraphqlPaginationVariables, GraphqlQueryPayload } from 'types/graphql';
import _set from 'lodash/set';

const useGetAll = <T>(queryConfig: GraphqlQueryPayload, variables: GraphqlPaginationVariables<T>) => {
    const getAll = async () => {
        const query = {
            ...queryConfig,
            columns: queryConfig.columns?.filter((item) => item.name !== 'actions'), // REMOVE ACTIONS COLUMN FROM QUERY PAYLOAD
        };

        const params = {
            limit: variables.limit,
            offset: variables.offset,
            orderBy: variables.orderBy,
            where: variables.where,
        };

        const finalVariables = () => {
            if (!(params as any).where?.status) {
                // Set status column key and value
                _set(params, `where.${queryConfig?.statusPath ?? 'status'}`, { _eq: Status.Active });
            }

            return params;
        };

        return apiGraphQl<GraphqlPagination<T>>(queries.getAll(query), finalVariables());
    };

    return useQuery(getAllKey(queryConfig.table, variables), getAll);
};

export default useGetAll;
