import { useState } from 'react';
import { FinancialBalanceTypeResponse } from '../types';
import { Outlet, useParams } from 'react-router-dom';
import { serviceOrderFinancialBalanceListColumns } from './utils';

import Datatable from 'components/core/table/datatable';
import Text from 'components/core/text';
import ServiceOrderFinancialBalanceTotals from './totals';
import useGetOrderServicesBalanceTotal from 'services/queries/order-services/use-get-order-services-balance-total';
import Spinner from 'components/core/spinner';
import useGetPaginatedWithFilters from 'services/queries/general/use-get-paginated-with-filters';

const ServiceOrderFinancialBalancePage = () => {
    const { orderServiceId, saleOrderId } = useParams();
    const [page, setPage] = useState(1);

    const INITIAL_ENDPOINT = `/sale-orders/${saleOrderId}/service-orders/${orderServiceId}/balance`;

    const { data: financialBalanceData, isLoading: isLoadingList } = useGetPaginatedWithFilters<FinancialBalanceTypeResponse>({
        endpoint: INITIAL_ENDPOINT,
        page,
    });
    const { data: totals, isLoading: isLoadingTotals } = useGetOrderServicesBalanceTotal(`${INITIAL_ENDPOINT}?type=numbers`);

    return (
        <>
            <div className="flex items-center mb-6 flex-row justify-between">
                <Text as="h3" variant="h4" className="text-heading mr-1">
                    Saldo financeiro
                </Text>
            </div>
            {isLoadingTotals ? <Spinner fixed={false} parentClasses="p-4" /> : <ServiceOrderFinancialBalanceTotals totals={totals} />}
            <Text as="h3" variant="h5" className="text-heading mb-5">
                Itens
            </Text>
            <Datatable
                withBorder={false}
                data={financialBalanceData?.items || []}
                options={{
                    sort: false,
                    filter: false,
                    search: false,
                    print: false,
                    download: false,
                    viewColumns: false,
                    serverSide: true,
                    searchAlwaysOpen: false,
                    selectableRows: 'none',
                    enableNestedDataAccess: '.',
                    pagination: true,
                    elevation: 21,
                    count: financialBalanceData?.total,
                    page,
                    onChangePage: setPage,
                }}
                columns={serviceOrderFinancialBalanceListColumns}
                loading={isLoadingList}
                className="toolbar-without-search mb-10"
            />
            <Outlet />
        </>
    );
};

export default ServiceOrderFinancialBalancePage;
