import Button from 'components/core/button';
import Select from 'components/core/form/select';
import { useTableAdvancedSearch } from 'components/core/table/datatable/components/advanced-search';
import { Controller, useForm } from 'react-hook-form';
import useGetOrderServicesPurchaseScopeProducts from 'services/queries/order-services/use-get-order-services-purchase-scope-products';

type ServiceOrderPurchaseScopeFilterProps = {
    endpoint: string;
    onSubmit: (data: any) => void;
};

type FormState = {
    'filter[products]': number[];
};

const ServiceOrderPurchaseScopeFilter = ({ onSubmit, endpoint }: ServiceOrderPurchaseScopeFilterProps) => {
    const { onClose: onCloseFilter } = useTableAdvancedSearch();

    const { control, handleSubmit } = useForm<FormState>({
        mode: 'onSubmit',
    });

    const { data: productsOptions = [], isLoading: isLoadingProducts } = useGetOrderServicesPurchaseScopeProducts(endpoint);

    const submit = (data: FormState) => {
        onCloseFilter();

        onSubmit(data);
    };

    return (
        <form onSubmit={handleSubmit(submit)}>
            <div className="mb-5">
                <Controller
                    name="filter[products]"
                    control={control}
                    render={({ field }) => {
                        const value = productsOptions?.filter((item) => field?.value?.includes(item?.value));

                        return (
                            <Select
                                {...field}
                                isLoading={isLoadingProducts}
                                value={value}
                                options={productsOptions}
                                label="Produto(s)"
                                placeholder="Selecione uma opção"
                                onChange={(option: any) => {
                                    const ids = option.map((item) => item.value);

                                    field.onChange(ids);
                                }}
                                isMulti={true}
                                isClearable={true}
                            />
                        );
                    }}
                />
            </div>
            <div className="flex">
                <Button type="submit" variant="contained" color="secondary" className="min-w-[100px] ml-auto">
                    Filtrar
                </Button>
            </div>
        </form>
    );
};

export default ServiceOrderPurchaseScopeFilter;
