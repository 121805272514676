import ExternalButton from 'components/buttons/external';
import { MUIDataTableColumn } from 'mui-datatables';
import { formatMoney, formatNumberToPTBR } from 'utils/money';

export const expenseCommissionsColumns: MUIDataTableColumn[] = [
    {
        name: 'id',
        options: {
            display: 'excluded',
        },
    },
    {
        name: 'saleOrder',
        label: 'Pedido de venda',
        options: {
            customBodyRender: (value) => {
                const href = `/app/pedidos-de-venda/${value?.id}`;

                return Boolean(value) ? <ExternalButton href={href}>{value.code}</ExternalButton> : '-';
            },
        },
    },
    {
        name: 'orderService',
        label: 'Ordem de serviço',
        options: {
            customBodyRender: (value) => {
                const href = `/app/pedidos-de-venda/${value?.saleOrder?.id}/ordens-de-servico/${value?.id}`;

                return Boolean(value) ? <ExternalButton href={href}>{value.code}</ExternalButton> : '-';
            },
        },
    },
    {
        name: 'percentage',
        label: 'Percentual',
        options: {
            customBodyRender: (value = 0) => `${formatNumberToPTBR(value, 2, 2)}%`,
        },
    },
    {
        name: 'value',
        label: 'Valor',
        options: {
            customBodyRender: (value = 0) => formatMoney(value),
        },
    },
];
