import { createActions, createReducer } from 'reduxsauce';
import { UpdateActionsType } from './types';
import { UpdateActions } from './types';
import { UpdateState } from './types';
import { ReduxAction } from 'types/general';

export const { Creators, Types } = createActions<Record<UpdateActionsType, UpdateActionsType>, UpdateActions>({
    initServiceWorker: [],
    updateApplication: ['payload'],
});

export const UPDATE_INITIAL_STATE: UpdateState = {
    isServiceWorkerInitialized: false,
    isServiceWorkerUpdated: false,
    serviceWorkerRegistration: null,
};

const initServiceWorker = (state = UPDATE_INITIAL_STATE) => ({
    ...state,
    isServiceWorkerInitialized: !state.isServiceWorkerInitialized,
});

const updateApplication = (state = UPDATE_INITIAL_STATE, action: ReduxAction<ServiceWorkerRegistration>) => ({
    ...state,
    isServiceWorkerUpdated: !state.isServiceWorkerUpdated,
    serviceWorkerRegistration: action.payload,
});

export default createReducer(UPDATE_INITIAL_STATE, {
    [Types.UPDATE_APPLICATION]: updateApplication,
    [Types.INIT_SERVICE_WORKER]: initServiceWorker,
});
