import { useQuery } from 'react-query';
import api from 'services/api';
import _sum from 'lodash/sum';
import { getOrderServicesExpensesKeyTotal } from './keys';

const useGetOrderServicesExpensesTotal = (endpoint: string) => {
    const request = async (): Promise<Response & { total: number }> => {
        const response = await api.get<Response>(endpoint).then(({ data }) => data);

        if (!response) {
            throw new Error();
        }

        return {
            ...response,
            total: _sum([response.open, response.paid]),
        };
    };

    return useQuery(getOrderServicesExpensesKeyTotal(endpoint), request, {
        enabled: true,
    });
};

export type Response = {
    open: number;
    paid: number;
};

export default useGetOrderServicesExpensesTotal;
