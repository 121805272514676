import { useQuery } from 'react-query';
import api from 'services/api';
import { getProductsReportKey } from './keys';
import qs from 'qs';
import { ProductsReportResponse, ReportProductsFiltersPayload } from 'types/models/reports';
import { GenericReportFilterPayload } from 'pages/private/reports/screens/types';

type Params = {
    endpoint: string;
    filters?: GenericReportFilterPayload<ReportProductsFiltersPayload>;
};

const useGetProductsReport = <T = ProductsReportResponse>({ endpoint, filters }: Params) => {
    const queryFilters = qs.stringify(
        {
            filter: filters?.filter,
            ...(Boolean(filters?.type) && { type: filters!.type }),
        },
        { addQueryPrefix: true, encode: false, arrayFormat: 'brackets', skipNulls: true }
    );

    const request = async () => {
        const { data } = await api.get<T[]>(endpoint + queryFilters);

        return data;
    };

    return useQuery(getProductsReportKey(endpoint, queryFilters), request);
};

export default useGetProductsReport;
