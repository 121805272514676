import { Controller, UseFormReturn } from 'react-hook-form';
import FavoredSelect from 'components/favored-select';
import { Option } from 'types/general';
import { natures, outcomeProvisioned, paidStatuses } from 'utils/statics';
import Select from 'components/core/form/select';
import useGetClassifications from 'services/queries/classifications/use-get-classifications';
import { generateClassificationQuery } from 'services/queries/classifications/utils';
import { ClassificationType } from 'types/models/classification';
import useGetCompaniesOptions from 'services/queries/companies/use-get-company-options';

type Props = {
    formMethods: UseFormReturn<any, any>;
    path?: string;
};

const ReportPageCreateOrUpdateBlockFormOutcome = ({ formMethods, path }: Props) => {
    const { watch, control } = formMethods;

    const getFieldName = (name: string) => (Boolean(path) ? `${path}.${name}` : name);

    const { data: companies = [] } = useGetCompaniesOptions();
    const { data: sectorsOptions = [] } = useGetClassifications(generateClassificationQuery(ClassificationType.Sector));

    const sectors = watch(getFieldName('sectors'));

    const { data: categoriesOptions = [] } = useGetClassifications(generateClassificationQuery(ClassificationType.TransactionCategory, { classificationId: { _in: sectors } }), !!sectors);

    const categories = watch(getFieldName('categories'));

    const { data: origins = [] } = useGetClassifications(generateClassificationQuery(ClassificationType.TransactionOrigin, { classificationId: { _in: categories } }), !!categories);

    const classificationOrigins = watch(getFieldName('origins'));

    const { data: subOrigins = [] } = useGetClassifications(
        generateClassificationQuery(ClassificationType.TransactionSubOrigin, { classificationId: { _in: classificationOrigins } }),
        !!classificationOrigins
    );

    return (
        <>
            <Controller
                name={getFieldName('sectors')}
                control={control}
                render={({ field }) => {
                    const value = sectorsOptions.filter((item) => field?.value?.includes(item?.value));

                    return (
                        <Select
                            {...field}
                            value={value}
                            options={sectorsOptions}
                            label="Setor"
                            placeholder="Selecione uma opção"
                            onChange={(option: any) => {
                                const ids = option.map((item) => item.value);

                                field.onChange(ids);
                            }}
                            isMulti={true}
                            isClearable={true}
                        />
                    );
                }}
            />
            <Controller
                name={getFieldName('categories')}
                control={control}
                render={({ field }) => {
                    const value = categoriesOptions.filter((item) => field?.value?.includes(item?.value));

                    return (
                        <Select
                            {...field}
                            value={value}
                            options={categoriesOptions}
                            label="Categoria"
                            onChange={(option: any) => {
                                const ids = option.map((item) => item.value);

                                field.onChange(ids);
                            }}
                            isMulti={true}
                            isClearable={true}
                            placeholder="Selecione uma opção"
                        />
                    );
                }}
            />
            <Controller
                name={getFieldName('origins')}
                control={control}
                render={({ field }) => {
                    const value = origins.filter((item) => field?.value?.includes(item?.value));

                    return (
                        <Select
                            {...field}
                            value={value}
                            options={origins}
                            label="Origem"
                            placeholder="Selecione uma opção"
                            onChange={(option: any) => {
                                const ids = option.map((item) => item.value);

                                field.onChange(ids);
                            }}
                            isMulti={true}
                            isClearable={true}
                        />
                    );
                }}
            />
            <Controller
                name={getFieldName('subOrigins')}
                control={control}
                render={({ field }) => {
                    const value = subOrigins.filter((item) => field?.value?.includes(item?.value));

                    return (
                        <Select
                            {...field}
                            value={value}
                            options={subOrigins}
                            label="Sub-origem"
                            placeholder="Selecione uma opção"
                            onChange={(option: any) => {
                                const ids = option.map((item) => item.value);

                                field.onChange(ids);
                            }}
                            isMulti={true}
                            isClearable={true}
                        />
                    );
                }}
            />
            <Controller
                name={getFieldName('favored')}
                control={control}
                render={({ field }) => {
                    return <FavoredSelect hideActions={true} onChange={(option: Option) => field.onChange(option)} value={field.value} />;
                }}
            />

            <Controller
                name={getFieldName('companies')}
                control={control}
                render={({ field }) => {
                    const value = companies.filter((item) => field?.value?.includes(item?.value));

                    return (
                        <Select
                            {...field}
                            value={value}
                            options={companies}
                            label="Empresa"
                            placeholder="Selecione uma opção"
                            isClearable={true}
                            onChange={(option: any) => {
                                const ids = option.map((item) => item.value);

                                field.onChange(ids);
                            }}
                            isMulti={true}
                        />
                    );
                }}
            />
            <Controller
                name={getFieldName('natures')}
                control={control}
                render={({ field }) => {
                    const value = natures.filter((item) => field?.value?.includes(item?.value));

                    return (
                        <Select
                            {...field}
                            value={value}
                            options={natures}
                            label="Natureza"
                            placeholder="Selecione uma opção"
                            isClearable={true}
                            onChange={(option: any) => {
                                const ids = option.map((item) => item.value);

                                field.onChange(ids);
                            }}
                            isMulti={true}
                        />
                    );
                }}
            />

            <Controller
                name={getFieldName('transactionProvisioned')}
                control={control}
                render={({ field }) => {
                    const value = outcomeProvisioned.find((item) => item.value === field.value);

                    return (
                        <Select
                            {...field}
                            value={value}
                            options={outcomeProvisioned}
                            label="Provisionada"
                            placeholder="Selecione uma opção"
                            onChange={(option: any) => field.onChange(option.value)}
                            isClearable={true}
                        />
                    );
                }}
            />

            <Controller
                name={getFieldName('paid')}
                control={control}
                render={({ field }) => {
                    const value = paidStatuses.find((item) => item.value === field.value);

                    return (
                        <Select
                            {...field}
                            value={value || null}
                            options={paidStatuses}
                            label="Status"
                            placeholder="Selecione uma opção"
                            onChange={(option: any) => field.onChange(!!option ? option.value : null)}
                            isClearable={true}
                        />
                    );
                }}
            />
        </>
    );
};

export default ReportPageCreateOrUpdateBlockFormOutcome;
