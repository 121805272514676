import { ChartOptions } from 'chart.js';
import theme from 'settings/theme';
import { ReportPageByYearResponse } from 'types/models/reports';

export const getMonthsAverage = (months: ReportPageByYearResponse['months'] = []) => months.map(({ resultData }) => resultData?.average || 0);

export const getReportPageBlockColor = (index: number) => {
    const { system, secondary } = theme.extend.colors;

    const defaultColors = (weight: number) => {
        return [system.danger[weight], system.info[weight], system.success[weight], system.warning[weight], secondary[weight]];
    };

    const colors: string[] = [...defaultColors(500), ...defaultColors(700), ...defaultColors(900)];

    return colors[index];
};

const textStyle: ChartOptions<'bar'> = {
    color: theme.extend.colors.base[500],
    font: {
        family: 'Poppins',
        weight: 'normal',
        size: 14,
    },
};

export const reportPagesChartOptions: any = {
    responsive: true,
    interaction: {
        intersect: false,
    },
    plugins: {
        title: {
            align: 'start',
            color: theme.extend.colors.heading,
            font: {
                family: 'Poppins',
                weight: '500',
                size: 18,
            },
        },
        legend: {
            align: 'end',
            position: 'top' as const,
            labels: {
                ...textStyle,
                usePointStyle: true,
                boxWidth: 8,
                boxHeight: 8,
            },
        },
    },
    maintainAspectRatio: false,
    layout: {
        padding: 0,
    },
    elements: {
        bar: {
            borderWidth: 0,
            borderRadius: 10,
        },
    },
    scales: {
        x: {
            max: 100,
            min: 0,
            grid: {
                display: false,
            },
        },
        y: {
            grid: {
                display: false,
            },
        },
    },
};
