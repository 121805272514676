import { ApprovalStatus } from 'types/general';
import { DatatableColumn } from 'types/graphql';
import { PurchaseScopeActions } from 'types/models/order-service';
import dictionary from 'utils/dictionary';
import { optionSchema } from 'utils/schema';
import { array, boolean, number, object, string } from 'yup';
import { purchaseProductsTypeWhereClause } from '../../order-purchase/create-or-update/utils';

export const purchaseScopeValidationSchema = (withJustification: boolean, isValidation = true) => {
    return object({
        purchaseProducts: array(
            object({
                id: number(),
                ...(isValidation ? { action: string().required('Necessária validação do produto') } : { approved: boolean().required('Necessária validação do produto') }),
                product: optionSchema,
                quantity: number().required(dictionary.validation.required),
                value: number().required(dictionary.validation.required),
                provision: string().required(dictionary.validation.required),
                ...(withJustification && {
                    justification: string().when('action', ([action], schema) => (action !== PurchaseScopeActions.Approve ? schema.required(dictionary.validation.required) : schema)),
                }),
            })
        ),
    });
};

export const productsQueryWhere = {
    approvalStatus: { _eq: ApprovalStatus.Approved },
    ...purchaseProductsTypeWhereClause,
};

export const formatProductsResponse = (data) => data.map((item) => ({ value: item.id, label: item.name }));

export const manageProductsColumns: DatatableColumn[] = [
    {
        name: 'id',
        options: { display: 'excluded' },
    },
    {
        name: 'product.name',
        label: 'Produto',
        options: { customBodyRender: (productName) => <span className="whitespace-nowrap">{productName || '-'}</span> },
    },
    {
        name: 'provision',
        label: 'Previsão compra',
    },
    {
        name: 'quantity',
        label: 'Quantidade',
    },
    {
        name: 'value',
        label: 'Valor máximo',
    },
    {
        name: 'justification',
        label: 'Justificativa',
    },
    {
        name: 'action',
        label: 'Ações',
    },
];

export const INITIAL_PURCHASE_PRODUCTS = {
    approved: undefined,
    justification: '',
    provision: '',
    quantity: 0,
    value: 0,
    action: PurchaseScopeActions.New,
};
