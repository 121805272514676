import { RouteObject } from 'react-router-dom';
import { Rule } from 'types/permissions';
import Page from 'pages/shared/page';
import FormsPage from 'pages/private/forms';
import CreateOrUpdateFormPage from 'pages/private/forms/create-or-update';
import FormPage from 'pages/private/forms/view';

const forms: RouteObject[] = [
    {
        // @ts-ignore
        breadcrumb: 'Formulários',
        path: 'formularios',
        element: <Page Component={FormsPage} code="formularios" title="Formulários" permissions={[Rule.List]} />,
        children: [
            {
                path: 'novo',
                element: <Page Component={CreateOrUpdateFormPage} code="formularios" title="Novo formulário" permissions={[Rule.Create]} withModal={true} />,
            },
            {
                path: 'editar/:formId',
                element: <Page Component={CreateOrUpdateFormPage} code="formularios" title="Atualizar formulário" permissions={[Rule.Update]} withModal={true} />,
            },
            {
                path: ':formId',
                element: <Page Component={FormPage} code="formularios" permissions={[Rule.Show]} withModal={true} />,
            },
        ],
    },
];

export default forms;
