import Select from 'components/core/form/select';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { memo, useEffect } from 'react';
import { formatDate } from 'utils/date';
import { yupResolver } from '@hookform/resolvers/yup';
import { orderServicesReportSchema } from '../utils';
import { genericReportDefaultValues, handleChangeReportSelect } from 'pages/private/reports/screens/utils';
import { DataContentFiltersConfig, GenericReportFilterPayload, ReportFilterPayload } from 'pages/private/reports/screens/types';
import GenericReportFiltersContent from '../../../components/generic-filters';
import ModalFilter from 'components/core/modal/filter';
import dayjs from 'dayjs';
import { OrderServicesReportFiltersPayload } from 'types/models/reports';
import isEmpty from 'lodash/isEmpty';

type OrderServicesReportFiltersProps = {
    onSetFilters: (data?: GenericReportFilterPayload<OrderServicesReportFiltersPayload>) => void;
    onCloseFilters: () => void;
    filters?: GenericReportFilterPayload<OrderServicesReportFiltersPayload>;
    dataContentFiltersConfig: DataContentFiltersConfig;
};

const OrderServicesReportFilters = ({ filters, onSetFilters, onCloseFilters, dataContentFiltersConfig }: OrderServicesReportFiltersProps) => {
    const { saleOrders = [], orderServiceStatus = [] } = dataContentFiltersConfig;

    const methods = useForm<ReportFilterPayload<OrderServicesReportFiltersPayload>['filter']>({
        mode: 'onSubmit',
        resolver: yupResolver(orderServicesReportSchema) as any,
        defaultValues: genericReportDefaultValues,
    });

    const { formState, control, handleSubmit, reset } = methods;

    const submit = (data?: ReportFilterPayload<OrderServicesReportFiltersPayload>['filter']) => {
        if (!isEmpty(data)) {
            const { startDate, endDate, ...rest } = data;

            onSetFilters({
                filter: {
                    ...rest,
                    ...(!!startDate && {
                        startDate: formatDate(startDate, 'YYYY-MM-DD'),
                    }),
                    ...(!!endDate && {
                        endDate: formatDate(endDate, 'YYYY-MM-DD'),
                    }),
                },
            });
        }

        onCloseFilters();
    };

    const handleClear = () => {
        onSetFilters();
        onCloseFilters();
    };

    useEffect(() => {
        if (!!filters) {
            const { endDate, startDate, ...rest } = filters.filter || {};

            reset({
                ...rest,
                ...(Boolean(startDate) && { startDate: dayjs(startDate).toISOString() }),
                ...(Boolean(endDate) && { endDate: dayjs(endDate).toISOString() }),
            });
        }
    }, [filters, reset]);

    return (
        <ModalFilter formMethods={methods} onSubmit={handleSubmit(submit)} onClose={onCloseFilters} onClear={handleClear}>
            <div className="flex flex-col gap-2">
                <FormProvider {...methods}>
                    <GenericReportFiltersContent dataContentFiltersConfig={dataContentFiltersConfig}>
                        <Controller
                            control={control}
                            name="saleOrders"
                            render={({ field }) => {
                                const value = saleOrders.filter((item) => field?.value?.includes(item.value));

                                return (
                                    <Select
                                        {...field}
                                        options={saleOrders}
                                        value={value}
                                        isDisabled={!saleOrders.length}
                                        isClearable={true}
                                        isMulti={true}
                                        placeholder="Selecione um contrato"
                                        label="Contratos"
                                        error={formState.errors.saleOrders?.message}
                                        parentClassName="min-w-full"
                                        onChange={handleChangeReportSelect(field.onChange)}
                                    />
                                );
                            }}
                        />
                        <Controller
                            control={control}
                            name="orderServiceStatus"
                            render={({ field }) => {
                                const value = orderServiceStatus.filter((item) => field?.value?.includes(item.value));

                                return (
                                    <Select
                                        {...field}
                                        options={orderServiceStatus}
                                        value={value}
                                        isDisabled={!orderServiceStatus.length}
                                        isClearable={true}
                                        isMulti={true}
                                        placeholder="Selecione um status"
                                        label="Status"
                                        error={formState.errors.orderServiceStatus?.message}
                                        parentClassName="min-w-full"
                                        onChange={handleChangeReportSelect(field.onChange)}
                                    />
                                );
                            }}
                        />
                    </GenericReportFiltersContent>
                </FormProvider>
            </div>
        </ModalFilter>
    );
};

export default memo(OrderServicesReportFilters);
