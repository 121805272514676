import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import { getTaskKey } from './keys';

type Payload = { text: string };

const useCreateTaskComment = (taskId?: string) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const request = async (payload: Payload) => api.post(`/tasks/${taskId}/comments`, payload);

    return useMutation(request, {
        onSuccess: () => {
            queryClient.invalidateQueries(getTaskKey(taskId));

            showToast('Comentário criado com sucesso', 'success');
        },
        onError: () => {
            showToast('Ocorreu um erro ao criar o comentário', 'danger');
        },
    });
};

export default useCreateTaskComment;
