import TableCell from '@mui/material/TableCell';
import { MUIDataTableColumn } from 'mui-datatables';

export const importFlowColumns: MUIDataTableColumn[] = [
    {
        name: 'id',
        options: { display: 'excluded' },
    },
    {
        name: 'code',
        label: 'Código',
        options: {
            customBodyRender: (value) => {
                return value ? `#${value}` : '-';
            },
        },
    },
    {
        name: 'invoice',
        label: 'Invoice',
        options: {
            customBodyRender: (value) => value || '-',
        },
    },
    {
        name: 'company.name',
        label: 'Empresa',
        options: {
            customBodyRender: (value) => value || '-',
        },
    },
    {
        name: 'branch.name',
        label: 'Filial',
        options: {
            customBodyRender: (value) => value || '-',
        },
    },
    {
        name: 'actions',
        label: 'Ações',
        options: {
            customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />,
        },
    },
];

export const generateQuantityValueArr = (withThirdColumn = true) => {
    const className = 'w-[160px] min-w-[160px]';

    const quantityValueArr = [
        { title: 'Quantidade', className },
        { title: 'R$', className },
    ];

    if (withThirdColumn) {
        quantityValueArr.push({ title: 'F', className });
    }

    return quantityValueArr;
};
