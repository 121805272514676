import Datatable from 'components/core/table/datatable';
import { Rule } from 'types/permissions';
import PageHeader from 'components/page/header';
import { Link, Outlet } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import useTheme from '@mui/material/styles/useTheme';
import { PageProps } from 'pages/shared/page';
import useGetPaginatedWithFilters from 'services/queries/general/use-get-paginated-with-filters';
import { DatatableColumn } from 'types/graphql';
import { Form } from 'types/models/form';
import { formTypes } from 'utils/statics';
import TableCell from '@mui/material/TableCell/TableCell';
import ConfirmModal from 'components/core/modal/confirm';
import { useQueryClient } from 'react-query';
import { getPaginatedWithFiltersKey } from 'services/queries/general/keys';
import useConfig from 'store/config/use-config';
import useDelete from 'services/queries/crud/use-delete';
import SvgIcoPlus from 'components/core/icon/files/ico-plus';

const ENDPOINT = '/quizzes';

const columns: DatatableColumn[] = [
    {
        name: 'id',
        options: { display: 'excluded' },
    },
    {
        name: 'title',
        label: 'Título',
    },
    {
        name: 'type',
        label: 'Tipo',
        options: {
            customBodyRender: (value = 0) => {
                const formType = formTypes.find((item) => item.value === value);

                return <span className="whitespace-nowrap">{formType?.label || '-'}</span>;
            },
        },
    },
    {
        name: 'actions',
        label: 'Ações',
        options: { customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} /> },
    },
];

const FormsPage = ({ page: pagePermissions, title }: PageProps<{}>) => {
    const { palette } = useTheme();
    const { config } = useConfig();
    const queryClient = useQueryClient();

    const [page, setPage] = useState(1);
    const [filters, setFilters] = useState<any[]>([]);
    const [orderBy, setOrderBy] = useState<any>(null);
    const [formIdToDelete, setFormIdToDelete] = useState<number>();

    const { data: quizzes } = useGetPaginatedWithFilters<Form>({ endpoint: ENDPOINT, filters, page, orderBy });

    const { mutateAsync: deleteForm, isLoading: isDeleting } = useDelete({
        apiPayload: { endpoint: ENDPOINT, useApiTodelete: true },
    });

    const actions = pagePermissions?.rules.map((item) => item.key);
    const canCreateLoan = actions?.includes(Rule.Create);

    const handleDeleteForm = async () => {
        try {
            if (!!formIdToDelete) {
                await deleteForm({ id: formIdToDelete });

                queryClient.invalidateQueries(getPaginatedWithFiltersKey(ENDPOINT, filters, page, config.tables.rowsPerPage, orderBy));

                setFormIdToDelete(undefined);
            }
        } catch (error) {
            console.error('handleDeleteForm: ', error);
        }
    };

    return (
        <>
            <PageHeader
                className="mb-6"
                title={title}
                right={
                    canCreateLoan ? (
                        <IconButton component={Link} to="novo" color="secondary" size="large" sx={{ backgroundColor: palette.secondary.main, '&:hover': { backgroundColor: palette.secondary.dark } }}>
                            <SvgIcoPlus width={16} height={16} color={palette.grey[100]} />
                        </IconButton>
                    ) : undefined
                }
            />
            <Datatable
                actions={actions}
                data={quizzes?.items || []}
                columns={columns}
                options={{
                    sort: true,
                    filter: false,
                    search: true,
                    print: false,
                    download: false,
                    viewColumns: false,
                    serverSide: true,
                    searchAlwaysOpen: true,
                    enableNestedDataAccess: '.',
                    pagination: true,
                    elevation: 21,
                    count: quizzes?.total,
                    page,
                    onChangePage: setPage,
                    onSearchChange: (searchText) => setFilters((prev) => ({ ...prev, searchText })),
                    onColumnSortChange: (changedColumn, direction) => setOrderBy({ [changedColumn]: direction.toUpperCase() }),
                }}
                onDelete={setFormIdToDelete}
            />
            <Outlet context={{ endpoint: ENDPOINT, filters, page, orderBy }} />
            {Boolean(formIdToDelete) && (
                <ConfirmModal
                    onConfirmAction={handleDeleteForm}
                    onClose={setFormIdToDelete.bind(this, undefined)}
                    description="Você tem certeza que deseja apagar este formulário?"
                    title="Apagar formulário"
                    isLoading={isDeleting}
                />
            )}
        </>
    );
};

export default FormsPage;
