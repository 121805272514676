import { useState } from 'react';
import Tabs from '@mui/material/Tabs/Tabs';
import Tab from '@mui/material/Tab/Tab';
import { Task } from 'types/models/task';
import TaskFiles from './files';
import TaskComments from './comments';

type TaskInteractionsProps = {
    comments: Task['comments'];
    files: Task['files'];
};

type TabValue = 'COMMENTS' | 'FILES';

const TaskInteractions = ({ comments, files }: TaskInteractionsProps) => {
    const [tab, setTab] = useState<TabValue>('FILES');

    const handleChangeTab = (_: any, nextSlug: any) => setTab(nextSlug);

    return (
        <>
            <Tabs variant="scrollable" classes={{ root: 'px-6 bg-base-100 z-[10]' }} indicatorColor="secondary" value={tab} onChange={handleChangeTab}>
                <Tab value="FILES" label={`ARQUIVOS (${files.length})`} />
                <Tab value="COMMENTS" label={`COMENTÁRIOS (${comments.length})`} />
            </Tabs>
            {tab === 'FILES' && <TaskFiles files={files} />}
            {tab === 'COMMENTS' && <TaskComments items={comments} />}
        </>
    );
};

export default TaskInteractions;
