import Spinner from 'components/core/spinner';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import useChangePersonContractStatus from 'services/queries/people/use-person-change-contract-status';
import useGetPersonContractStatusOptions from 'services/queries/people/use-person-contract-status-options';
import theme from 'settings/theme';
import { formatDate } from 'utils/date';
import Modal from '../index';
import PersonContractStatusForm from './form';
import { useQueryClient } from 'react-query';
import { getPeopleDetailsKey } from 'services/queries/people/keys';
import useToast from 'hooks/toast/use-toast';
import { PersonContractType } from 'types/models/person';

type PersonContractStatusProps = {
    type: PersonContractType;
    onClose?(): void;
    contractId?: number;
    personId?: number;
};

const PersonContractStatus = ({ type, ...props }: PersonContractStatusProps) => {
    const params = useParams();
    const ctx = useOutletContext();
    const navigate = useNavigate();

    const queryClient = useQueryClient();

    const { showToast } = useToast();

    const personId = props?.personId! || +params?.personId!;
    const contractId = props?.contractId! || +params?.contractId!;

    const { data: contractStatus = [], isLoading: isLoadingStatus } = useGetPersonContractStatusOptions(type === ('situation' as any));

    const handleClose = () => {
        if (!!props?.onClose) {
            props.onClose();
        } else {
            navigate(`/app/pessoas/${personId}/contratos`);
        }
    };

    const { isLoading: isSubmitting, mutateAsync: changePersonContractStatus } = useChangePersonContractStatus(contractId, personId, ctx);

    const handleSubmit = async (data: any) => {
        try {
            const payload = {
                ...data,
                type,
            };

            if (['promotion', 'situation', 'reactivate'].includes(type as any)) {
                payload.startDate = formatDate(data?.startDate, 'DD/MM/YYYY');
            }

            if (type === ('promotion' as any)) {
                payload.salary = data?.salary?.floatValue;
            }

            if (type === ('resignation' as any)) {
                payload.finalDate = formatDate(data?.startDate, 'DD/MM/YYYY');
                payload.earlyWarningStartDate = formatDate(data?.startDate, 'DD/MM/YYYY');
                payload.earlyWarningFinalDate = formatDate(data?.startDate, 'DD/MM/YYYY');
            }

            await changePersonContractStatus(payload);

            handleClose();

            queryClient.invalidateQueries(getPeopleDetailsKey(String(personId)));

            showToast('Contrato atualizado com sucesso.', 'success');
        } catch (error) {
            showToast('Desculpe, tivemos um problema para atualizar o contrato.', 'danger');
        }
    };

    return (
        <Modal closeOnClickOutside={false} onClose={handleClose} contentClassnames="min-w-[800px]">
            {isLoadingStatus ? (
                <div className="p-4">
                    <Spinner color={theme.extend.colors.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <PersonContractStatusForm contractStatus={contractStatus} onClose={handleClose} isSubmitting={isSubmitting} type={type} onSubmit={handleSubmit} />
            )}
        </Modal>
    );
};

export default PersonContractStatus;
