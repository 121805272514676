import { useMainContext } from 'pages/private/main/context';
import { OrderServicesReportTotalsValues } from '../../types';
import { generateReportTotalsClasses, getExpenseCardVariant } from '../../utils';
import ExpenseTotalCard from 'pages/private/sales-orders/service-order/expenses/total-card';
import classNames from 'classnames';
import ContractsReportTotalsCardHead from './totals-head';
import { generateOrderServiceReportTotalsClasses } from '../utils';
import { memo } from 'react';
import { useReportsContext } from 'pages/private/reports/context';

type OrderServicesReportTotalsProps = {
    orderServicesTotals: OrderServicesReportTotalsValues;
    isContractReport?: boolean;
};

const OrderServicesReportTotals = ({ orderServicesTotals, isContractReport = false }: OrderServicesReportTotalsProps) => {
    const { isSidebarSmall } = useMainContext();
    const { showValues } = useReportsContext();

    const classes = generateOrderServiceReportTotalsClasses(isSidebarSmall, isContractReport);

    const containerClasses = classNames('mb-6 grid gap-4', classes);
    const avatarClasses = classNames(isSidebarSmall && '2xl:!w-[1.875rem] 2xl:!h-[1.875rem] 2xl:!rounded-[10px] !p-[5px]');
    const iconClasses = classNames(isSidebarSmall && '2xl:w-[1.25rem] 2xl:h-[1.25rem]');
    const valueContentContainerClasses = classNames('xl:text-xl xl:!items-end', isSidebarSmall && '2xl:text-[0.78rem]');

    return (
        <div className={containerClasses}>
            {isContractReport && (
                <>
                    <ExpenseTotalCard
                        avatarClassName={avatarClasses}
                        iconClassName={iconClasses}
                        containerClassName={generateReportTotalsClasses().cardContainer}
                        variant="paid"
                        value={{
                            data: orderServicesTotals.contractValue.value,
                            contentContainerClassName: valueContentContainerClasses,
                            containerClassName: generateReportTotalsClasses({ showValues }).valueContainer,
                            isVisible: showValues,
                            className: generateReportTotalsClasses({ showValues }).value,
                        }}
                        head={{
                            data: <ContractsReportTotalsCardHead title="VC" tooltipTitle="Valor do contrato" />,
                            containerClassName: generateReportTotalsClasses().headContainer,
                        }}
                    />
                    <ExpenseTotalCard
                        avatarClassName={avatarClasses}
                        iconClassName={iconClasses}
                        containerClassName={generateReportTotalsClasses().cardContainer}
                        variant="paid"
                        value={{
                            data: orderServicesTotals.contractBalanceValue.value,
                            contentContainerClassName: valueContentContainerClasses,
                            containerClassName: generateReportTotalsClasses({ showValues }).valueContainer,
                            isVisible: showValues,
                            className: generateReportTotalsClasses({ showValues }).value,
                        }}
                        percentage={{
                            data: orderServicesTotals.contractBalanceValue.percentage,
                        }}
                        head={{
                            data: <ContractsReportTotalsCardHead title="VSC" tooltipTitle="Valor do Saldo do Contrato" />,
                            containerClassName: generateReportTotalsClasses().headContainer,
                        }}
                    />
                </>
            )}
            <ExpenseTotalCard
                avatarClassName={avatarClasses}
                iconClassName={iconClasses}
                containerClassName={generateReportTotalsClasses().cardContainer}
                value={{
                    data: orderServicesTotals.receipt.value,
                    contentContainerClassName: valueContentContainerClasses,
                    containerClassName: generateReportTotalsClasses({ showValues }).valueContainer,
                    isVisible: showValues,
                    className: generateReportTotalsClasses({ showValues }).value,
                }}
                variant="paid"
                head={{
                    data: <ContractsReportTotalsCardHead title="REC" tooltipTitle="Receita" />,
                    containerClassName: generateReportTotalsClasses().headContainer,
                }}
            />
            <ExpenseTotalCard
                avatarClassName={avatarClasses}
                iconClassName={iconClasses}
                containerClassName={generateReportTotalsClasses().cardContainer}
                variant="toBePaid"
                value={{
                    data: orderServicesTotals.originalBudget.value,
                    contentContainerClassName: valueContentContainerClasses,
                    containerClassName: generateReportTotalsClasses({ showValues }).valueContainer,
                    isVisible: showValues,
                    className: generateReportTotalsClasses({ showValues }).value,
                }}
                percentage={{
                    data: orderServicesTotals.originalBudget.percentage,
                }}
                head={{
                    data: <ContractsReportTotalsCardHead title="CO" tooltipTitle="Custo Original" />,
                    containerClassName: generateReportTotalsClasses().headContainer,
                }}
            />
            <ExpenseTotalCard
                avatarClassName={avatarClasses}
                iconClassName={iconClasses}
                containerClassName={generateReportTotalsClasses().cardContainer}
                variant="toBePaid"
                value={{
                    data: orderServicesTotals.updatedBudget.value,
                    contentContainerClassName: valueContentContainerClasses,
                    containerClassName: generateReportTotalsClasses({ showValues }).valueContainer,
                    isVisible: showValues,
                    className: generateReportTotalsClasses({ showValues }).value,
                }}
                percentage={{
                    data: orderServicesTotals.updatedBudget.percentage,
                }}
                head={{
                    data: <ContractsReportTotalsCardHead title="CA" tooltipTitle="Custo Atualizado" />,
                    containerClassName: generateReportTotalsClasses().headContainer,
                }}
            />
            {!isContractReport && (
                <ExpenseTotalCard
                    avatarClassName={avatarClasses}
                    iconClassName={iconClasses}
                    containerClassName={generateReportTotalsClasses().cardContainer}
                    variant={getExpenseCardVariant(orderServicesTotals.difference.value)}
                    value={{
                        data: orderServicesTotals.difference.value,
                        contentContainerClassName: valueContentContainerClasses,
                        containerClassName: generateReportTotalsClasses({ showValues }).valueContainer,
                        isVisible: showValues,
                        className: generateReportTotalsClasses({ showValues }).value,
                    }}
                    percentage={{
                        data: orderServicesTotals.difference.percentage,
                    }}
                    head={{
                        data: <ContractsReportTotalsCardHead title="DIFF" tooltipTitle="Diferença" />,
                        containerClassName: generateReportTotalsClasses().headContainer,
                    }}
                />
            )}

            <ExpenseTotalCard
                avatarClassName={avatarClasses}
                iconClassName={iconClasses}
                containerClassName={generateReportTotalsClasses().cardContainer}
                value={{
                    data: orderServicesTotals.tax.value,
                    contentContainerClassName: valueContentContainerClasses,
                    containerClassName: generateReportTotalsClasses({ showValues }).valueContainer,
                    isVisible: showValues,
                    className: generateReportTotalsClasses({ showValues }).value,
                }}
                percentage={{
                    data: orderServicesTotals.tax.percentage,
                }}
                variant="toBePaid"
                head={{
                    data: <ContractsReportTotalsCardHead title="IMP" tooltipTitle="Imposto" />,
                    containerClassName: generateReportTotalsClasses().headContainer,
                }}
            />
            <ExpenseTotalCard
                avatarClassName={avatarClasses}
                iconClassName={iconClasses}
                containerClassName={generateReportTotalsClasses().cardContainer}
                value={{
                    data: orderServicesTotals.commission.value,
                    contentContainerClassName: valueContentContainerClasses,
                    containerClassName: generateReportTotalsClasses({ showValues }).valueContainer,
                    isVisible: showValues,
                    className: generateReportTotalsClasses({ showValues }).value,
                }}
                percentage={{
                    data: orderServicesTotals.commission.percentage,
                }}
                variant="toBePaid"
                head={{
                    data: <ContractsReportTotalsCardHead title="COM" tooltipTitle="Comissão" />,
                    containerClassName: generateReportTotalsClasses().headContainer,
                }}
            />
            <ExpenseTotalCard
                avatarClassName={avatarClasses}
                iconClassName={iconClasses}
                containerClassName={generateReportTotalsClasses().cardContainer}
                variant={getExpenseCardVariant(orderServicesTotals.balance.value)}
                value={{
                    data: orderServicesTotals.balance.value,
                    contentContainerClassName: valueContentContainerClasses,
                    containerClassName: generateReportTotalsClasses({ showValues }).valueContainer,
                    isVisible: showValues,
                    className: generateReportTotalsClasses({ showValues }).value,
                }}
                percentage={{
                    data: orderServicesTotals.balance.percentage,
                }}
                head={{
                    data: <ContractsReportTotalsCardHead title="SL" tooltipTitle="Saldo Líquido" />,
                    containerClassName: generateReportTotalsClasses().headContainer,
                }}
            />
            <ExpenseTotalCard
                avatarClassName={avatarClasses}
                iconClassName={iconClasses}
                containerClassName={generateReportTotalsClasses().cardContainer}
                variant={getExpenseCardVariant(orderServicesTotals.updatedBalance.value)}
                percentage={{
                    data: orderServicesTotals.updatedBalance.percentage,
                }}
                value={{
                    data: orderServicesTotals.updatedBalance.value,
                    contentContainerClassName: valueContentContainerClasses,
                    containerClassName: generateReportTotalsClasses({ showValues }).valueContainer,
                    isVisible: showValues,
                    className: generateReportTotalsClasses({ showValues }).value,
                }}
                head={{
                    data: <ContractsReportTotalsCardHead title="SLA" tooltipTitle="Saldo Líquido Atualizado" />,
                    containerClassName: generateReportTotalsClasses().headContainer,
                }}
            />
        </div>
    );
};

export default memo(OrderServicesReportTotals);
