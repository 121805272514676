import { convertEnumToOptions } from './enum';
import { EmailType, Gender, MaritalStatus, PersonContractType } from 'types/models/person';
import { BankAccountType, PixType } from 'types/models/bank';
import { ProductType } from 'types/models/product';
import { SaleOrderOrigin, SaleOrderScheduleStatus, SaleOrderType } from 'types/models/sale-order';
import { Status, Priority, ApprovalStatus, Option } from 'types/general';
import { FinancialType, PurchaseProductStatus, PurchaseStatus } from 'types/models/purchase-order';
import { ExpenseNature, ExpenseType } from 'types/models/expense';
import { StockOrderType, StockType } from 'types/models/stock';
import { SalesStatus } from 'types/models/reports';
import { TransactionDocumentType, TransactionPrintType, TransactionType } from 'types/models/transaction';
import { FormFieldType, FormGroupType, QuizType } from 'types/models/form';
import { ProviderConsultingType } from 'types/models/provider';

export const maritalStatuses = convertEnumToOptions(MaritalStatus, 'maritalStatus');

export const genders = convertEnumToOptions(Gender, 'genders');

export const contractTypes = convertEnumToOptions(PersonContractType, 'contractTypes');

export const bankAccountTypes = convertEnumToOptions(BankAccountType, 'bankAccountTypes');

export const pixTypes = convertEnumToOptions<string>(PixType, 'pixTypes');

export const emailTypes = convertEnumToOptions(EmailType, 'emailTypes');

export const productTypes = convertEnumToOptions(ProductType, 'productTypes');

export const saleOrderTypes = convertEnumToOptions(SaleOrderType, 'saleOrderTypes');

export const saleOrderOrigins = convertEnumToOptions(SaleOrderOrigin, 'saleOrderOrigins');

export const statuses = convertEnumToOptions(Status, 'statuses');

export const priorities = convertEnumToOptions(Priority, 'priorities');

export const purchaseStatuses = convertEnumToOptions(PurchaseStatus, 'purchaseStatuses');

export const purchaseProductStatuses = convertEnumToOptions(PurchaseProductStatus, 'purchaseProductStatuses');

export const natures = convertEnumToOptions(ExpenseNature, 'natures');

export const financialTypes = convertEnumToOptions(FinancialType, 'financialTypes');

export const stockTypes = convertEnumToOptions(StockType, 'stockTypes');

export const salesStatuses = convertEnumToOptions(SalesStatus, 'salesReports');

export const scheduleStatus = convertEnumToOptions(SaleOrderScheduleStatus, 'schedules');

export const approvalStatuses = convertEnumToOptions(ApprovalStatus, 'approvalStatus');

export const stockOrderTypes = convertEnumToOptions(StockOrderType, 'stockOrderType');

export const expenseTypes = convertEnumToOptions(ExpenseType, 'expenseTypes');

export const transactionPrintTypes = convertEnumToOptions(TransactionPrintType, 'transactionPrintTypes');

export const transactionDocumentTypes = convertEnumToOptions(TransactionDocumentType, 'transactionDocument');

export const formFieldTypes = convertEnumToOptions(FormFieldType, 'formFields');

export const formTypes = convertEnumToOptions(QuizType, 'formTypes');

export const formGroupTypes = convertEnumToOptions(FormGroupType, 'formGroupTypes');

export const booleanOptions = [
    { value: true, label: 'Sim' },
    { value: false, label: 'Não' },
];

export const originOptions = [
    { value: 1, label: 'Serviço' },
    { value: 2, label: 'Venda' },
];

export const receiptClassificationTypes = [
    { value: 1, label: 'Público' },
    { value: 2, label: 'Privado' },
    { value: 3, label: 'Projetos Incentivados' },
    { value: 4, label: 'Contrapartidas Sociais' },
];

export const salesNotesOptions = [
    { value: 1, label: 'Com Nota de Venda' },
    { value: 2, label: 'Sem Nota de Venda' },
];

export const saleOrderOriginsLiteral = {
    [SaleOrderOrigin.service]: 'Serviço',
    [SaleOrderOrigin.sale]: 'Venda',
};

export const saleOrderTypeLiteral = {
    [SaleOrderType.public]: 'Público',
    [SaleOrderType.private]: 'Privado',
};

export const transactionsLiteral = {
    [TransactionType.Outcome]: 'Despesa',
    [TransactionType.Income]: 'Receita',
    [TransactionType.Contribution]: 'Aporte',
};

export const transactionsTypeOptions: Option[] = [
    {
        value: TransactionType.Income,
        label: 'Entrada',
    },
    {
        value: TransactionType.Outcome,
        label: 'Saída',
    },
];

export const allLabelOption: Option<null> = { value: null, label: 'Todas' };

export const paidStatuses = [allLabelOption, { value: true, label: 'Pago' }, { value: false, label: 'Não pago' }];

export const provisionedOptions = [allLabelOption, ...booleanOptions];

export const reportPagesTransactionTypes = [
    {
        value: TransactionType.Outcome,
        label: 'Despesa',
    },
    {
        value: TransactionType.Income,
        label: 'Receita',
    },
];

export const outcomeProvisioned = [
    allLabelOption,
    {
        value: 'provisioned',
        label: 'Sim',
    },
    {
        value: 'not-provisioned',
        label: 'Não',
    },
];

export const invoiceStatuses = [allLabelOption, { value: 0, label: 'Sem Nota Fiscal' }, { value: 1, label: 'Com Nota Fiscal' }];

export const providerConsultingTypeMap = {
    [ProviderConsultingType.Natural]: 'Pessoa Física',
    [ProviderConsultingType.Legal]: 'Pessoa Jurídica',
    [ProviderConsultingType.Foreign]: 'Pessoa Residente no Exterior',
    [ProviderConsultingType.Unknown]: 'Desconhecida',
};
