import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import { getSaleOrderSchedulesKey } from './keys';
import { useShowApiErrors } from 'hooks/error/show-api-errors';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';

const useCreateOrUpdateSaleOrderSchedule = (saleOrderId?: string) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const { showToastErrors } = useShowApiErrors();
    const goToParentRoute = useNavigateToParentRoute();

    const request = async (payload) => api.post(`/sale-orders/${saleOrderId}/schedules`, payload);

    return useMutation(request, {
        onSuccess: () => {
            queryClient.invalidateQueries(getSaleOrderSchedulesKey(saleOrderId));

            showToast('Agendamento criado com sucesso', 'success');

            goToParentRoute();
        },
        onError: (error: any) => {
            const defaultMessage = 'Ocorreu um erro ao criar o agendamento. Por favor, tente novamente!';
            showToastErrors(error, defaultMessage);
        },
    });
};

export default useCreateOrUpdateSaleOrderSchedule;
