import Tooltip from '@mui/material/Tooltip/Tooltip';
import classNames from 'classnames';
import { useState } from 'react';
import Lightbox from 'react-image-lightbox';

type VisitGalleryProps = {
    items: string[];
    className?: string;
};

const VisitGallery = ({ items, className }: VisitGalleryProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    const imageClasses = classNames('aspect-square bg-no-repeat bg-center bg-cover rounded-xl', className);

    const handleToggleLightBox = (isOpen: boolean, index: number) => {
        setIsOpen(isOpen);
        setPhotoIndex(index);
    };

    return (
        <>
            {items.map((item, index) => {
                return (
                    <Tooltip key={item} placement="top" title="Ver imagem" disableFocusListener={true}>
                        <button className={imageClasses} style={{ backgroundImage: `url(${item})` }} onClick={handleToggleLightBox.bind(this, true, index)} />
                    </Tooltip>
                );
            })}
            {isOpen && (
                <Lightbox
                    mainSrc={items[photoIndex]}
                    nextSrc={items[(photoIndex + 1) % items.length]}
                    prevSrc={items[(photoIndex + items.length - 1) % items.length]}
                    onCloseRequest={handleToggleLightBox.bind(this, false, 0)}
                    onMovePrevRequest={setPhotoIndex.bind(this, (photoIndex + items.length - 1) % items.length)}
                    onMoveNextRequest={setPhotoIndex.bind(this, (photoIndex + 1) % items.length)}
                    clickOutsideToClose={false}
                    onImageLoad={window.dispatchEvent.bind(this, new Event('resize'))}
                />
            )}
        </>
    );
};

export default VisitGallery;
