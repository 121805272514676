import { ImportFlowContextProvider } from './context';
import CreateOrUpdateImportFlow from './create-or-update';

type CreateOrUpdateExpensePageProps = any;

const CreateOrUpdateImportFlowPage = ({ title }: CreateOrUpdateExpensePageProps) => {
    return (
        <ImportFlowContextProvider>
            <CreateOrUpdateImportFlow title={title || 'Nova Importação'} />
        </ImportFlowContextProvider>
    );
};

export default CreateOrUpdateImportFlowPage;
