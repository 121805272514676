import { ApprovalStatus } from 'types/general';
import { BaseModel } from './base';
import { OrderService } from './order-service';
import { Product } from './product';
import { PurchaseOrder, PurchaseOrderOriginStock } from './purchase-order';
import { Person } from './person';
import { InventoryMovement } from './inventory-movements';
import { SaleOrder } from './sale-order';

export enum StockType {
    Default = 1,
    OrderService,
}

export type Stock = {
    orderServiceId: number;
    name: string;
    type: StockType;
    inventoryMovements: Partial<InventoryMovement>[];
    purchaseOrderOriginStocks: Partial<PurchaseOrderOriginStock>[];
    stockOrdersByStockIncomingId: any; // TODO:
    stockBalances: Partial<StockBalance>[];
    stockOrders: Partial<StockOrder>[];
    stockProducts: Partial<StockProduct>[];
    orderServices: Partial<OrderService>[];
    value: string;
} & BaseModel;

export type StockBalanceProduct = {
    stockBalanceId: number;
    stockProductId: number;
    quantity: number;
    currentQuantity: number;
    stockBalance: Partial<StockBalance>;
    stockProduct: Partial<StockProduct>;
} & BaseModel;

export type StockProduct = {
    stockId: number;
    productId: number;
    quantity: number;
    value: number;
    product: Partial<Product>;
    stock: Partial<Stock>;
    stockBalanceProducts: Partial<StockBalanceProduct>[];
    inventoryMovements: Partial<InventoryMovement>[];
} & BaseModel;

export type StockBalance = {
    stockId: number;
    personId: number;
    code: string;
    approvalStatus: ApprovalStatus;
    justification: string;
    stockBalanceProducts: Partial<StockBalanceProduct>[];
    person: Partial<Person>;
    stock: Partial<Stock>;
};

export enum StockOrderType {
    Transfer = 1,
    Use,
    Outcome,
    Industrialization,
    Reversal,
    Import,
}

export type StockOriginSaleOrder = {
    id: number;
    saleOrder: Partial<SaleOrder>;
    saleOrderId: number;
};

type StockOriginPurchaseOrder = {
    id: number;
    purchaseOrder: Partial<PurchaseOrder>;
    purchaseOrderId: number;
};

export type StockOrigin = {
    stockOrders: StockOrder[];
    stockOriginSaleOrder: StockOriginSaleOrder;
    stockOriginPurchaseOrder: StockOriginPurchaseOrder;
} & BaseModel;

export type StockOrder = {
    stockIncomingId: number;
    stockOutgoingId: number;
    code: string;
    grossValue: number;
    netValue: number;
    discount: number;
    addition: number;
    inventoryMovements: Partial<InventoryMovement>[];
    stock: Partial<Stock>;
    stockByStockIncomingId: any; // TODO:
    type: StockOrderType;
    stockOrigin: StockOrigin;
} & BaseModel;
