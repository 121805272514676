import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import { GraphqlPaginationVariables } from 'types/graphql';
import { ExpenseProduct } from 'types/models/expense';
import { getAllKey } from '../crud/keys';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';

export type CreateOrUpdateStockBalancePayload = {
    justification?: string;
    products: Array<
        {
            value: number;
            quantity: number;
            product: number;
            total?: number;
        } & Partial<ExpenseProduct>
    >;
};

const useCreateOrUpdateStockBalance = (stockId?: string, balanceId?: string, params?: GraphqlPaginationVariables<any>) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const goToParentRoute = useNavigateToParentRoute();

    const endpoint = `stocks/${stockId}/balances`;

    const method = !!balanceId ? api.patch : api.post;
    const request = (payload: CreateOrUpdateStockBalancePayload) => method(endpoint, payload);

    return useMutation(request, {
        onSuccess: () => {
            queryClient.invalidateQueries(getAllKey('stockBalance', params));

            goToParentRoute();

            showToast(`Balanço ${!!balanceId ? 'atualizado' : 'cadastrado'} com sucesso!`, 'success');
        },
        onError(error) {
            showToast(`Ocorreu um erro ao ${!!balanceId ? 'cadastrar' : 'atualizar'} o balanço, por favor, tente novamente.`, 'danger');
            console.error('submit: create-or-update flow balance', error);
        },
    });
};

export default useCreateOrUpdateStockBalance;
