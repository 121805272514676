import { createActions, createReducer } from 'reduxsauce';
import { ReduxAction } from 'types/general';
import { Credentials } from 'types/models/credentials';
import { CredentialsActionsType, CredentialsActions } from './types';

export const { Creators, Types } = createActions<Record<CredentialsActionsType, CredentialsActionsType>, CredentialsActions>({
    setCredentials: ['payload'],
    clearCredentials: [],
});

export const CREDENTIALS_INITIAL_STATE: Credentials = {
    menu: [],
    pages: [],
};

const setCredentials = (state = CREDENTIALS_INITIAL_STATE, action: ReduxAction<Credentials>): Credentials => ({
    ...state,
    ...action.payload,
});

const clearCredentials = (state = CREDENTIALS_INITIAL_STATE): Credentials => ({ ...state, ...CREDENTIALS_INITIAL_STATE });

export default createReducer<Credentials>(CREDENTIALS_INITIAL_STATE, {
    [Types.SET_CREDENTIALS]: setCredentials,
    [Types.CLEAR_CREDENTIALS]: clearCredentials,
});
