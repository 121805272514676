import TableRow from '@mui/material/TableRow';
import { ProductsItem, ProductsPayload } from './index';
import TableCell from '@mui/material/TableCell';
import Text from 'components/core/text';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { formatMoney, formatNumberToPTBR } from 'utils/money';
import CurrencyInput from 'components/core/form/currency';
import RemoveButton from 'components/buttons/remove';
import { useParams } from 'react-router-dom';
import { NumberFormatValues } from 'react-number-format/types/types';

type ProductsRowProps = {
    isOriginSale: boolean;
    isRowDisabled: boolean;
    item: ProductsItem;
    index: number;
    onRemoveProduct: (index: number) => void;
};

const ProductsRow = ({ isOriginSale, isRowDisabled, item, index, onRemoveProduct }: ProductsRowProps) => {
    const { saleOrderProductId } = useParams();

    const isUpdate = Boolean(saleOrderProductId);

    const { control, formState, setValue } = useFormContext<ProductsPayload>();

    const netValue = useWatch({ control, name: `products.${index}.netValue`, defaultValue: 0 });
    const quantity = useWatch({ control, name: `products.${index}.quantity`, defaultValue: 0 });

    const handleChangeValue = (index: number, onChange: (...event: any[]) => void, type: 'netValue' | 'quantity') => async (values: NumberFormatValues) => {
        const { floatValue = 0 } = values;

        onChange(floatValue);

        if (type === 'quantity') {
            return setValue(`products.${index}.total`, floatValue * netValue!);
        }

        setValue(`products.${index}.total`, floatValue * quantity);
    };

    const rowTotal = useWatch({ control, name: `products.${index}.total` });

    return (
        <TableRow classes={{ root: !isRowDisabled ? 'opacity-100 hover:bg-base-200' : 'bg-base-300 opacity-30 cursor-no-drop' }}>
            <TableCell>
                <Text className="text-base-500">
                    #{item.code} - {item.name}
                </Text>
            </TableCell>
            {isOriginSale && (
                <TableCell>
                    <Text className="text-base-500">
                        {formatNumberToPTBR(item.quantityInStock || 0)} ({item.unitName})
                    </Text>
                </TableCell>
            )}
            <TableCell>
                <Controller
                    name={`products.${index}.netValue`}
                    control={control}
                    render={({ field }) => {
                        return (
                            <CurrencyInput
                                disabled={isRowDisabled}
                                value={field.value}
                                onValueChange={handleChangeValue(index, field.onChange, 'netValue')}
                                placeholder="Ex: 1000"
                                withFormat={false}
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={4}
                                fixedDecimalScale={true}
                                parentClassName="w-[120px]"
                                error={formState.errors.products?.[index]?.netValue?.message}
                            />
                        );
                    }}
                />
            </TableCell>
            <TableCell>
                <Controller
                    name={`products.${index}.quantity`}
                    control={control}
                    render={({ field }) => {
                        return (
                            <CurrencyInput
                                disabled={isRowDisabled}
                                value={field.value}
                                onValueChange={handleChangeValue(index, field.onChange, 'quantity')}
                                placeholder="Ex: 1000"
                                parentClassName="w-[120px]"
                                error={formState.errors.products?.[index]?.quantity?.message}
                            />
                        );
                    }}
                />
            </TableCell>
            <TableCell>
                <Text variant="body.medium.2xs" className="text-secondary-500 whitespace-nowrap">
                    {formatMoney(rowTotal)}
                </Text>
            </TableCell>
            <TableCell>{!isUpdate && <RemoveButton className="!static opacity-100 mt-0" onClick={onRemoveProduct.bind(this, index)} />}</TableCell>
        </TableRow>
    );
};

export default ProductsRow;
