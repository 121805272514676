import Table from '@mui/material/Table/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Text from 'components/core/text';
import TableBody from '@mui/material/TableBody';
import NationalizeProductsRow from './row';

type NationalizeProductsTableProps = {
    selectedProducts: any[];
    onRemoveProduct: (index: number) => void;
};

const NationalizeProductsTable = ({ selectedProducts, onRemoveProduct }: NationalizeProductsTableProps) => {
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Text className="text-base-500 uppercase whitespace-nowrap">Produto</Text>
                        </TableCell>
                        <TableCell>
                            <Text className="text-base-500 uppercase whitespace-nowrap">Valor Base</Text>
                        </TableCell>
                        <TableCell>
                            <Text className="text-base-500 uppercase whitespace-nowrap">Valor Frete</Text>
                        </TableCell>
                        <TableCell className="min-w-[180px]">
                            <Text className="text-base-500 uppercase whitespace-nowrap">Saldo</Text>
                        </TableCell>
                        <TableCell className="min-w-[180px]">
                            <Text className="text-base-500 uppercase whitespace-nowrap">QTD</Text>
                        </TableCell>
                        <TableCell className="min-w-[180px]">
                            <Text className="text-base-500 uppercase whitespace-nowrap">Valor</Text>
                        </TableCell>
                        <TableCell className="min-w-[180px]">
                            <Text className="text-base-500 uppercase whitespace-nowrap">ICMS</Text>
                        </TableCell>
                        <TableCell className="w-40">
                            <Text className="text-base-500 uppercase whitespace-nowrap">Preço</Text>
                        </TableCell>
                        <TableCell className="w-40">
                            <Text className="text-base-500 uppercase whitespace-nowrap">Total</Text>
                        </TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {selectedProducts.map((item, index) => (
                        <NationalizeProductsRow key={item.id} index={index} item={item} onRemoveProduct={onRemoveProduct} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default NationalizeProductsTable;
