import { RouteObject } from 'react-router-dom';
import users from './users';
import menu from './menu';
import pages from './pages';
import rules from './rules';
import profiles from './profiles';
import home from './home';
import branches from './branches';
import reports from './reports';
import companies from './companies';
import positions from './positions';
import sectors from './sectors';
import customers from './customers';
import providers from './providers';
import people from './people';
import products from './products';
import expenses from './expenses';
import salesOrders from './sales-orders';
import categories from './category';
import origins from './origin';
import suborigins from './sub-origin';
import paymentOrders from './payment-order';
import receiptOrder from './receipt-order';
import saleNotes from './sales-notes';
import orderServices from './orders-services';
import bankAccounts from './bank-accounts';
import financials from './financial';
import purchasesOrders from './purchases-orders';
import contributions from './contributions';
import productsCategory from './products-category';
import stocks from './stocks';
import paymwentsFlow from './payments-flow';
import commissions from './commissions';
import schedules from './schedules';
import loans from './loans';
import forms from './forms';
import visits from './visits';
import notifications from './notifications';

const PRIVATE_ROUTES: RouteObject[] = [
    ...reports,
    ...home,
    ...users,
    ...menu,
    ...pages,
    ...rules,
    ...profiles,
    ...branches,
    ...companies,
    ...positions,
    ...sectors,
    ...customers,
    ...providers,
    ...people,
    ...products,
    ...expenses,
    ...salesOrders,
    ...saleNotes,
    ...categories,
    ...origins,
    ...suborigins,
    ...paymentOrders,
    ...receiptOrder,
    ...orderServices,
    ...financials,
    ...purchasesOrders,
    ...bankAccounts,
    ...productsCategory,
    ...contributions,
    ...stocks,
    ...paymwentsFlow,
    ...commissions,
    ...schedules,
    ...loans,
    ...forms,
    ...visits,
    ...notifications,
];

export default PRIVATE_ROUTES;
