import { useShowApiErrors } from 'hooks/error/show-api-errors';
import useToast from 'hooks/toast/use-toast';
import { useMutation } from 'react-query';
import api from 'services/api';

export type FinishOsPayload = {
    products: Array<{
        productId: number;
        name: string;
        quantityInStock: number;
        stocks: Array<{ stockId: number; quantity: number }>;
    }>;
};

type Payload = {
    products: Array<{
        id: number;
        stocks: Array<{ id: number; quantity: number }>;
    }>;
};

const useFinishOS = (saleOrderId?: string, orderServiceId?: string) => {
    const { showToast } = useToast();
    const { showToastErrors } = useShowApiErrors();

    const request = async (payload: Payload) => {
        const newPayload = {
            status: 'finalize',
            ...payload,
        };

        return api.patch(`/sale-orders/${saleOrderId}/service-orders/${orderServiceId}/status`, newPayload);
    };

    return useMutation(request, {
        onSuccess: () => {
            showToast('OS finalizada com sucesso', 'success');
        },
        onError: (error: any) => {
            showToastErrors(error, error?.response?.data?.title);
        },
    });
};

export default useFinishOS;
