import { useQuery } from 'react-query';
import apiGraphQl from 'services/graphql';
import { Option } from 'types/general';
import { Pagination } from 'types/pagination';
import { getDashboardsKey } from './keys';

const useGetDashboards = (query: string) => {
    const request = async () => apiGraphQl<Pagination<Option>>(query).then(({ items }) => items);

    return useQuery(getDashboardsKey, request);
};

export default useGetDashboards;
