import { useParams } from 'react-router-dom';
import CreateOrUpdatePerson from './create-or-update';
import { formatDate } from 'utils/date';
import { CreatePersonPayload, Person } from 'types/models/person';
import useCreateOrUpdatePerson from 'services/queries/people/use-create-or-update-person';
import { CrudPageProps } from 'types/graphql';
import useGetPerson from 'services/queries/people/use-get-person';
import { memo, useEffect, useMemo, useState } from 'react';
import useGetProfilesOptions from 'services/queries/profiles/use-get-profiles';
import omit from 'lodash/omit';

type CreateOrUpdatePersonPageProps = {
    onClose?: () => void;
    onSuccess?: (person: Person) => void;
} & CrudPageProps<{}>;

const CreateOrUpdatePersonPage = ({ title, onClose, onSuccess }: CreateOrUpdatePersonPageProps) => {
    const [isUser, setIsUser] = useState(false);

    const { data: profiles = [] } = useGetProfilesOptions(isUser);
    const { personId } = useParams();

    const { data: personDetails, isLoading: isLoadingDetails } = useGetPerson(personId);
    const { mutateAsync: createOrUpdatePerson, isLoading: isSubmitting, error } = useCreateOrUpdatePerson(personId, onSuccess);

    const defaultValues = useMemo<Partial<CreatePersonPayload>>(
        () => ({
            birthday: personDetails?.birthday || '',
            document: personDetails?.document || '',
            emails: personDetails?.emails || [],
            name: personDetails?.name || '',
            phones: personDetails?.phones || [],
            title: personDetails?.title || '',
            address: {
                ...personDetails?.address,
                stateId: personDetails?.address?.state?.id,
                cityId: personDetails?.address?.city?.id,
            },
            email: personDetails?.email || '',
            ...(Boolean(personDetails?.gender) && { gender: personDetails?.gender }),
            ...(Boolean(personDetails?.maritalStatus) && { maritalStatus: personDetails?.maritalStatus }),
            ...(Boolean(personDetails?.user) && {
                user: {
                    username: personDetails?.user.username,
                    profile: personDetails?.user?.profile?.id,
                },
            }),
        }),
        [personDetails]
    );

    const handleSubmit = async (data: CreatePersonPayload) => {
        try {
            const payload: any = {
                ...omit(data, 'isUser'),
                birthday: formatDate(data.birthday),
                address: {
                    neighbourhood: data.address.neighbourhood,
                    number: data.address.number,
                    street: data.address.street,
                    zip: data.address.zip,
                    compliment: data.address.compliment,
                    cityId: data.address.cityId,
                    stateId: data.address.stateId,
                },
                contracts: [
                    {
                        ...data?.contracts,
                        startDate: formatDate(data.contracts?.startDate, 'DD/MM/YYYY'),
                        ...(Boolean(data.contracts?.finalDate) && { finalDate: formatDate(data.contracts?.finalDate, 'DD/MM/YYYY') }),
                    },
                ],
                ...(Boolean(data.user) && { user: data.user }),
            };

            if (!!personId) {
                delete payload['contracts'];
                delete payload.address.id;
                delete payload.address.city;
                delete payload.address.state;
                delete payload.address.full;
            }

            await createOrUpdatePerson(payload);
        } catch (error) {
            console.log('handleSubmit', error);
        }
    };

    useEffect(() => {
        if (Boolean(personDetails?.user)) {
            setIsUser(true);
        }
    }, [personDetails?.user, setIsUser]);

    return (
        <CreateOrUpdatePerson
            title={title || ''}
            isBuilding={isLoadingDetails}
            isSubmitting={isSubmitting}
            onClose={onClose}
            onSubmit={handleSubmit}
            defaultValues={defaultValues}
            error={error}
            onChangeUser={setIsUser}
            profiles={profiles}
            isUser={isUser}
        />
    );
};

export default memo(CreateOrUpdatePersonPage);
