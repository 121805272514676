import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import Card from 'components/core/card';
import { useMemo } from 'react';
import _map from 'lodash/map';
import { Doughnut } from 'react-chartjs-2';
import { formatMoney } from 'utils/money';
import { getReportPageBlockColor } from '../details/utils';
import sumBy from 'lodash/sumBy';
import { ReportPageBlock } from 'types/models/reports';

ChartJS.register(ArcElement, Tooltip);

type ReportPageDetailsChartDoughnutProps = {
    blocks: ReportPageBlock[] | any[];
    doughnutChartId?: string;
    years?: number[];
};

const ReportPageDetailsChartDoughnut = ({ blocks = [], doughnutChartId = 'doughnut-chart-card', years = [] }: ReportPageDetailsChartDoughnutProps) => {
    const values = useMemo(
        () =>
            years.map((year, index) => {
                const color = getReportPageBlockColor(index);

                const totalYearsValue = sumBy(blocks, 'resume.total');
                const blockItem = blocks?.find((block) => (block?.reportMonths || block?.months)?.find((item) => item.year === year));
                const percentageValue = ((blockItem?.resume.total || 0) / totalYearsValue || 0) * 100;

                return {
                    percentage: isFinite(percentageValue) ? percentageValue : 0,
                    name: year.toString(),
                    color,
                };
            }),
        [blocks, years]
    );

    const data = useMemo(() => {
        return {
            labels: _map(years, (year) => year.toString()),
            datasets: [
                {
                    data: _map(values, (value) => value.percentage.toFixed(2)),
                    backgroundColor: _map(values, 'color'),
                    borderWidth: 0,
                    borderRadius: 30,
                    spacing: 4,
                    rotation: 234,
                },
            ],
        };
    }, [values, years]);

    return (
        <Card className="p-5">
            <div id={doughnutChartId}>
                <div className="h-[280px] mb-3 flex justify-center">
                    <Doughnut
                        data={data}
                        options={{
                            cutout: '75%',

                            plugins: {
                                legend: {
                                    display: false,
                                },
                                tooltip: {
                                    enabled: true,
                                },
                            },
                        }}
                    />
                </div>

                <div className="flex flex-col gap-1">
                    {values?.map(({ name, percentage = 0 }, index) => {
                        const color = getReportPageBlockColor(index);

                        return (
                            <div key={`legend_${index}`} className="flex items-center gap-1">
                                <div className="w-[20px] h-[20px] rounded-lg" style={{ background: color }}></div>
                                <span className="text-sm text-base-500">
                                    {formatMoney(percentage).replace('R$', '')}% &bull; {name}
                                </span>
                            </div>
                        );
                    })}
                </div>
            </div>
        </Card>
    );
};

export default ReportPageDetailsChartDoughnut;
