import dayjs, { Dayjs } from 'dayjs';
import { Option } from 'types/general';
import { ExpenseNature } from 'types/models/expense';
import { formatDate } from 'utils/date';
import dictionary from 'utils/dictionary';
import { object, number, string, boolean, mixed } from 'yup';

const MONDAY = 1;
const THURSDAY = 4;

export const provisionWeekDays = [MONDAY, THURSDAY];

type GenerateProvisioningDatesParams = {
    date: string;
    numberOfDays?: number;
    maxRegisters?: number;
    skipDatesCount?: number;
};

export const generateProvisioningDates = ({ date, numberOfDays = 180, skipDatesCount = 1, maxRegisters }: GenerateProvisioningDatesParams) => {
    const arr = [...new Array(numberOfDays).keys()]
        .map((i) => {
            return dayjs(date)
                .add(i + 1, 'day')
                .toDate();
        })
        .filter((date) => provisionWeekDays.includes(date.getDay()));

    const sliced = arr.slice(skipDatesCount);

    if (!!maxRegisters) {
        const filtered = sliced.slice(0, maxRegisters);

        return filtered;
    }

    return sliced;
};

export const getProvisioningOptions = (params: GenerateProvisioningDatesParams): Option<string>[] => {
    return generateProvisioningDates(params).map((date) => ({
        value: date.toISOString(),
        label: `${formatDate(date, 'DD/MM/YYYY - ddd')}`,
    }));
};

export const generalSchema = (isRootExpense = true, isUpdate = false, nature?: ExpenseNature) =>
    object({
        company: number().required(dictionary.validation.required),
        sector: number().required(dictionary.validation.required),
        category: number().required(dictionary.validation.required).typeError(dictionary.validation.required),
        origin: number().required(dictionary.validation.required),
        subOrigin: number().required(dictionary.validation.required).typeError(dictionary.validation.required),
        branch: number().required(dictionary.validation.required),
        favored: object({
            value: number().required(dictionary.validation.required),
            label: string().required(dictionary.validation.required),
            type: string().required(dictionary.validation.required),
        }).required(dictionary.validation.required),
        paymentForm: mixed().required(dictionary.validation.required),
        installments: number().required(dictionary.validation.required).min(1, 'É preciso ter ao menos uma parcela'),
        document: string().default(null).nullable().notRequired(),
        amount: string().when('withProducts', ([withProducts], schema) => (!!withProducts ? schema : schema.required(dictionary.validation.required))),
        dueDate: string().required(dictionary.validation.required).typeError(dictionary.validation.required),
        provisioned: boolean().default(false),
        constructionRelated: boolean().optional(),
        title: string().required(dictionary.validation.required).typeError(dictionary.validation.required),
        observations: string().notRequired(),
        nature: number().optional(),
        favoredBankAccount: number().when('paymentForm', ([paymentForm], schema) => {
            if (!!paymentForm?.data?.withBankAccount) {
                return schema.required(dictionary.validation.required);
            }

            return schema;
        }),
        type: number().required(dictionary.validation.required),
        fiscalCode: number().required(dictionary.validation.required),
        ...(Boolean(isRootExpense) && {
            withProducts: boolean().required(dictionary.validation.required),
            orderService: number()
                .nullable()
                .when('constructionRelated', ([constructionRelated], schema) => {
                    return constructionRelated ? schema.required(dictionary.validation.required) : schema;
                }),
        }),
        competence: string().required(dictionary.validation.required),
        ...(!isUpdate && {
            provision: string().required(dictionary.validation.required),
            ...(nature === ExpenseNature.Normal && {
                repeated: boolean().default(false),
                monthsToRepeat: number()
                    .when('repeated', ([repeated], schema) => {
                        return repeated ? schema.min(1, dictionary.validation.number.min(1)) : schema;
                    })
                    .default(1),
            }),
        }),
    });

export const getClosestPreviousDate = (datesArray, referenceDateStr) => {
    const referenceDate = dayjs(referenceDateStr);

    let closestDate: Dayjs | null = null;

    datesArray.forEach((dateStr) => {
        const date = dayjs(dateStr);

        if (date.isSameOrBefore(referenceDate) && (closestDate === null || date.isAfter(closestDate))) {
            closestDate = date;
        }
    });

    return !!closestDate ? (closestDate as Dayjs).format('YYYY-MM-DD') : '';
};

export const generateDueAndProvisioningDates = (dueDateParam: string, index: number) => {
    // Installment due date
    const dueDate = dayjs(dueDateParam).add(index, 'month').toISOString();

    const skipProvisionDays = !index ? 1 : 0;

    // The first payment forecast date
    // This date is used ONLY for first payment because the first installment must skip the first provisioning option
    const firstPaymentForecast = dayjs(generateProvisioningDates({ date: dueDate, numberOfDays: 7, skipDatesCount: skipProvisionDays })[0]).format('YYYY-MM-DD');

    // An array with provisioning options based on second and next due date
    const dates = generateProvisioningDates({ date: dueDateParam, numberOfDays: 7, skipDatesCount: skipProvisionDays });
    // const dates = generateProvisioningDates(notFirstDueDate, 14, !index);

    // The payment forecast date for all another installments
    const restPaymentForecast = getClosestPreviousDate(dates, dueDate);

    return {
        dueDate: dueDateParam,
        firstPaymentForecast,
        restPaymentForecast,
    };
};
