import { memo, useMemo } from 'react';
import pick from 'lodash/pick';
import { useOutletContext, useParams } from 'react-router-dom';
import CustomerOrProviderForm from 'components/forms/company/form';
import useUploadFile from 'services/queries/files/use-upload-file';
import { CrudPageProps, GraphqlPaginationVariables } from 'types/graphql';
import { Status } from 'types/general';
import { AddressClassNames } from 'types/models/address';
import useCreateProvider, { CreateProviderResponse } from 'services/queries/providers/use-create-provider';
import useUpdateProvider from 'services/queries/providers/use-update-provider';
import useGetProvider from 'services/queries/providers/use-get-provider';
import { BankAccountClassNames, BankAccountType } from 'types/models/bank';
import omit from 'lodash/omit';

type CreateOrUpdateProviderPageProps = CrudPageProps<{}> & {
    onSuccess?: (data: CreateProviderResponse['item']) => void;
    onClose?: () => void;
};

const CreateOrUpdateProviderPage = ({ title, onSuccess, onClose }: CreateOrUpdateProviderPageProps) => {
    const { providerId } = useParams();

    const params = useOutletContext<GraphqlPaginationVariables<any>>();

    const isUpdate = !!providerId;

    const { isLoading: isLoadingFile, uploadFile } = useUploadFile();
    const { mutateAsync: updateProvider, isLoading: isUpdating } = useUpdateProvider(params);
    const { data: provider, isLoading: isLoadingProviderDetails } = useGetProvider(providerId);
    const { mutateAsync: createProvider, isLoading: isCreating } = useCreateProvider(onSuccess);

    const defaultValues = useMemo(
        () => ({
            id: provider?.id,
            type: provider?.type,
            title: provider?.title || '',
            phones: provider?.phones || [],
            emails: provider?.emails || [],
            status: Status.Active,
            ...(!isUpdate && {
                address: {
                    ...provider?.addressProviders?.[0]?.address,
                    cityId: provider?.addressProviders?.[0]?.address?.cityId,
                    stateId: provider?.addressProviders?.[0]?.address?.stateId,
                    compliment: provider?.addressProviders?.[0]?.address?.compliment,
                },
                providerBankAccounts: {
                    type: BankAccountType.Open,
                    status: Status.Active,
                },
            }),
            file: provider?.file,
            juridicDocument: provider?.juridicDocument ?? undefined,
            juridicName: provider?.juridicName ?? undefined,
            juridicFantasyName: provider?.juridicFantasyName ?? undefined,
            name: provider?.name ?? undefined,
            document: provider?.document ?? undefined,
        }),
        [provider, isUpdate]
    );

    const handleSubmit = async (data: any) => {
        try {
            const hasChangedImage = !data?.file?.id && !!data?.file?.path;

            if (hasChangedImage) {
                await uploadFile(data?.file, ({ id }) => (data.fileId = id), true);
            } else {
                data.fileId = data?.file?.id ?? null;
            }

            const addressProviders = pick(data?.address, ['stateId', 'cityId', 'compliment', 'neighbourhood', 'number', 'street', 'zip']);

            delete data.file;

            if (isUpdate) {
                const rest = omit(data, 'address', 'providerBankAccounts');

                const payload: any = {
                    id: providerId,
                    data: rest,
                };

                await updateProvider(payload);
            } else {
                delete data.address;
                delete data.image;

                const payload: any = {
                    ...data,
                    providerContacts: data.providerContacts?.length
                        ? {
                              data: data.providerContacts.map((item) => ({
                                  person: { data: item },
                              })),
                          }
                        : undefined,
                    providerBankAccounts: !!data.providerBankAccounts
                        ? {
                              data: [
                                  {
                                      bankAccount: {
                                          data: {
                                              bankId: data.providerBankAccounts.bank,
                                              agency: data.providerBankAccounts.agency,
                                              agencyDigit: data.providerBankAccounts.agencyDigit,
                                              account: data.providerBankAccounts.account,
                                              accountDigit: data.providerBankAccounts.accountDigit,
                                              type: data.providerBankAccounts.type,
                                              className: BankAccountClassNames.Provider,
                                              pix: data.providerBankAccounts.pix,
                                              pixType: data.providerBankAccounts.pixType,
                                              ...(Boolean(data.providerBankAccounts.name) && { name: data.providerBankAccounts.name }),
                                          },
                                      },
                                  },
                              ],
                          }
                        : undefined,
                    ...(Boolean(addressProviders?.stateId) && {
                        addressProviders: {
                            data: {
                                address: {
                                    data: {
                                        compliment: addressProviders.compliment,
                                        neighbourhood: addressProviders.neighbourhood,
                                        number: addressProviders.number,
                                        street: addressProviders.street,
                                        zip: addressProviders.zip,
                                        cityId: addressProviders.cityId,
                                        stateId: addressProviders.stateId,
                                        className: AddressClassNames.Provider,
                                        status: Status.Active,
                                    },
                                },
                            },
                        },
                    }),
                };

                await createProvider(payload);
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    return (
        <CustomerOrProviderForm
            contactFieldName="providerContacts"
            bankAccountFieldName="providerBankAccounts"
            defaultValues={defaultValues}
            isBuilding={isLoadingProviderDetails}
            isSubmitting={isCreating || isUpdating || isLoadingFile}
            title={title || ''}
            onSubmit={handleSubmit}
            showImage={true}
            onClose={onClose}
        />
    );
};

export default memo(CreateOrUpdateProviderPage);
