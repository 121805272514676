import Divider from '@mui/material/Divider/Divider';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { FormProvider, useForm } from 'react-hook-form';
import { PurchaseOrderProduct } from 'types/models/purchase-order';
import EntryProductsTable from './table-products';
import FormButtons from 'components/form-buttons';
import qs from 'qs';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert/Alert';
import { yupResolver } from '@hookform/resolvers/yup';
import { entryBuyProductsSchema } from '../utils';

type EntryBuyProductsModalProps = {
    selectedProducts: PurchaseOrderProduct[];
    onClose: () => void;
};

export type EntryBuyProductsPayload = {
    purchaseOrderProducts: Array<{
        purchaseOrderProductId: number;
        quantity: number;
    }>;
};

const EntryBuyProductsModal = ({ selectedProducts = [], onClose }: EntryBuyProductsModalProps) => {
    const navigate = useNavigate();

    const methods = useForm<EntryBuyProductsPayload>({
        mode: 'onSubmit',
        resolver: yupResolver(entryBuyProductsSchema) as any,
    });

    const submit = (data: EntryBuyProductsPayload) => {
        const purchaseProductsIds = data.purchaseOrderProducts.map((item) => item.purchaseOrderProductId);

        const query = qs.stringify(
            {
                purchaseProductsIds,
                companyId: selectedProducts[0].purchaseOrder.company?.id,
                purchaseOrderProducts: data.purchaseOrderProducts,
            },
            { addQueryPrefix: true, encode: false, arrayFormat: 'indices', skipNulls: true }
        );

        onClose();
        navigate(`entrega-direta/despesas/novo/compra${query}`);
    };

    return (
        <Modal
            contentClassnames="w-[800px]"
            closeOnClickOutside={false}
            onClose={onClose}
            headerLeft={
                <Text as="h3" variant="h5" className="text-heading">
                    Entrega direta
                </Text>
            }>
            <Divider />
            <Alert className="rounded-none" severity="warning">
                <Text as="p">Confirmar em cada item abaixo, a quantidade exata entregue na obra para criação da despesa.</Text>
            </Alert>
            <FormProvider {...methods}>
                <form className="p-4" onSubmit={methods.handleSubmit(submit)}>
                    <div className="-mx-4 mb-4">
                        <EntryProductsTable selectedProducts={selectedProducts} />
                    </div>
                    <FormButtons
                        isLoading={false}
                        submitButton={{
                            label: 'Prosseguir',
                        }}
                        cancelButton={{
                            onClick: onClose,
                        }}
                    />
                </form>
            </FormProvider>
        </Modal>
    );
};

export default EntryBuyProductsModal;
