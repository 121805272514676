import { Controller, useFormContext } from 'react-hook-form';
import Select from 'components/core/form/select';
import Text from 'components/core/text';
import { Option } from 'types/general';
import DatePicker from 'components/core/datepicker';
import CurrencyInput from 'components/core/form/currency';
import { useCallback, useEffect } from 'react';
import { ServiceOrderFinancialPayload } from './financial';

type FinancialItemProps = {
    index: number;
    paymentForms: Option[];
    item: ServiceOrderFinancialPayload['transactions'][0];
    firstPaymentForm?: number;
};

const FinancialItem = ({ item, paymentForms, index, firstPaymentForm }: FinancialItemProps) => {
    const { formState, control, setValue, watch } = useFormContext<ServiceOrderFinancialPayload>();

    const getPath = useCallback((key: keyof ServiceOrderFinancialPayload['transactions'][0]) => `transactions.${index}.${key}` as const, [index]);

    const grossValue = (watch(getPath('grossValue')) || 0) as number;

    useEffect(() => {
        if (!item.paymentForm && firstPaymentForm) {
            setValue(getPath('paymentForm'), firstPaymentForm);
        }
    }, [item, setValue, firstPaymentForm, index, getPath]);

    return (
        <div className="border border-base-300 rounded-[14px] p-4 mb-2">
            <Text as="label" variant="h6" className="text-heading mb-2 block">
                {item.description}
            </Text>
            <div className="flex-1 grid gap-4 sm:grid-cols-3">
                <Controller
                    control={control}
                    name={getPath('dueDate') as any}
                    render={({ field }) => {
                        return (
                            <DatePicker
                                inputProps={{
                                    label: 'Vencimento',
                                    error: formState.errors?.transactions?.[index]?.dueDate?.message,
                                    placeholderText: 'Selecione uma data de vencimento',
                                    ...field,
                                }}
                            />
                        );
                    }}
                />
                <div>
                    <Controller
                        control={control}
                        name={getPath('grossValue')}
                        render={({ field }) => {
                            return (
                                <CurrencyInput
                                    value={grossValue}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    onValueChange={({ floatValue }) => field.onChange(floatValue)}
                                    label="Valor da parcela"
                                    placeholder="Ex: 1000"
                                    error={formState.errors?.transactions?.[index]?.grossValue?.message}
                                    left={
                                        <Text as="span" variant="body.medium.sm" className="text-heading">
                                            R$
                                        </Text>
                                    }
                                />
                            );
                        }}
                    />
                </div>
                <Controller
                    control={control}
                    name={getPath('paymentForm')}
                    render={({ field }) => {
                        const value = paymentForms.find((item) => item.value === field.value);

                        return (
                            <Select
                                {...field}
                                value={value}
                                options={paymentForms}
                                error={formState.errors?.transactions?.[index]?.paymentForm?.message}
                                label="Forma de pagamento"
                                onChange={(option: any) => field.onChange(option.value)}
                            />
                        );
                    }}
                />
            </div>
        </div>
    );
};

export default FinancialItem;
