import { useMutation } from 'react-query';
import apiGraphQl from 'services/graphql';
import { affectedRowsKey } from 'utils/environment';

const useDeletePageRules = () => {
    const request = async (pageId: number) => {
        return apiGraphQl(
            `mutation DeletePageRules($pageId: Int!) {
                item: deletePageRule(where: {pageId: {_eq: $pageId}}) {
                    ${affectedRowsKey}
                }
            }`,
            { pageId }
        );
    };

    return useMutation(request);
};

export default useDeletePageRules;
