import Datatable from 'components/core/table/datatable';
import { useParams } from 'react-router-dom';
import useGetExpenseLogs from 'services/queries/expenses/use-get-expense-logs';
import { expenseLogsColumns } from '../utils';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ReactJson from '@usulpro/react-json-view';
import { useState } from 'react';

const ExpenseLogsPage = () => {
    const [page, setPage] = useState(1);

    const { expenseId } = useParams();

    const { data: expenseLogs = [], isLoading: isLoadingLogs } = useGetExpenseLogs({
        expenseId,
        filters: {
            page,
        },
    });

    return (
        <Datatable
            withBorder={false}
            data={expenseLogs}
            options={{
                sort: true,
                filter: false,
                search: false,
                print: false,
                download: false,
                viewColumns: false,
                serverSide: true,
                searchAlwaysOpen: false,
                enableNestedDataAccess: '.',
                pagination: false,
                elevation: 21,
                selectableRows: 'none',
                expandableRows: Boolean(expenseLogs?.length),
                expandableRowsOnClick: true,
                onChangePage: setPage,
                renderExpandableRow: (rowData: any[], rowMeta) => {
                    const { dataIndex } = rowMeta;

                    return (
                        <TableRow className="bg-base-200">
                            <TableCell colSpan={rowData.length + 1}>
                                <div className="bg-base-100 rounded-[14px] border border-base-300 overflow-hidden p-4">
                                    <ReactJson src={expenseLogs[dataIndex] || {}} name={null} displayDataTypes={false} displayObjectSize={false} />
                                </div>
                            </TableCell>
                        </TableRow>
                    );
                },
            }}
            columns={expenseLogsColumns}
            loading={isLoadingLogs}
            withItemsPerPage={false}
            className="toolbar-without-search"
        />
    );
};

export default ExpenseLogsPage;
