import ExternalButton from 'components/buttons/external';
import useGetDownloadFile from 'services/queries/files/use-get-download-file';

type DownloadButtonProps = {
    fileId: number;
    fileName: string;
};

const DownloadButton = ({ fileId, fileName }: DownloadButtonProps) => {
    const { mutateAsync: getFileToDownload, isLoading: isDownloadingFile } = useGetDownloadFile();

    const handleDownloadFile = async () => {
        try {
            await getFileToDownload(fileId);
        } catch (error) {
            console.log('handleDownloadFile', error);
        }
    };

    return (
        <ExternalButton disabled={isDownloadingFile} onClick={handleDownloadFile}>
            {fileName}
        </ExternalButton>
    );
};

export default DownloadButton;
