import { useShowApiErrors } from 'hooks/error/show-api-errors';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';
import useToast from 'hooks/toast/use-toast';
import { useMutation } from 'react-query';
import api from 'services/api';

const useCreateCommission = () => {
    const { showToast } = useToast();
    const { showToastErrors } = useShowApiErrors();

    const goToParentRoute = useNavigateToParentRoute();

    const request = async (payload: any) => api.post('/commissions', payload);

    return useMutation(request, {
        onSuccess: () => {
            showToast('Comissão cadastrada com sucesso', 'success');
            goToParentRoute();
        },
        onError: (error: any) => {
            showToastErrors(error, error?.response?.data?.title);
        },
    });
};

export default useCreateCommission;
