import React, { useMemo, useState } from 'react';
import Menu from '@mui/material/Menu/Menu';
import Badge from '@mui/material/Badge/Badge';
import Divider from '@mui/material/Divider/Divider';
import useTheme from '@mui/material/styles/useTheme';
import IconButton from '@mui/material/IconButton/IconButton';
import NotificationItem from './item';
import Text from 'components/core/text';
import SvgIcoBell from 'components/core/icon/files/ico-bell';
import useGetNofications from 'services/queries/notifications/use-get-notifications';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import SvgIcoExternal from 'components/core/icon/files/ico-external';
import Button from 'components/core/button';
import useReadNotifications from 'services/queries/notifications/use-read-notifications';
import useToast from 'hooks/toast/use-toast';

const Notifications = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const { data: notifications = [] } = useGetNofications();
    const { mutateAsync: readNotifications, isLoading: isReading } = useReadNotifications();

    const { palette } = useTheme();
    const { showToast } = useToast();

    const notificationsCount = useMemo(() => notifications.length, [notifications]);

    const handleReadAllNotifications = async () => {
        try {
            const notificationsIds = notifications.map((item) => item.id);

            await readNotifications(notificationsIds);
        } catch (error) {
            console.error('readAllNotifications: ', error);
            showToast('Ocorreu um erro ao ler todas as notificações', 'danger');
        }
    };

    const handleClose = () => setAnchorEl(null);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

    const handleGoToNotificationsPage = () => {
        window.open('/app/notificacoes', '_blank');
        handleClose();
    };

    return (
        <div className="relative px-2 border-x">
            <IconButton color="primary" aria-label="cart" className="p-[10px]" onClick={handleOpen}>
                <Badge badgeContent={notificationsCount} color="error">
                    <SvgIcoBell color={palette.heading} width={16} height={16} />
                </Badge>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                open={Boolean(anchorEl)}
                slotProps={{
                    paper: {
                        classes: {
                            elevation8: 'shadow-none w-[calc(100%-32px)] sm:w-[540px] max-w-[540px] rounded-[14px] custom-shadow',
                        },
                    },
                }}
                onClose={handleClose}>
                <div className="flex items-center flex-wrap px-4 py-2 pb-3 gap-2">
                    <Text as="span" variant="h6" className="text-heading flex-1">
                        Notificações
                    </Text>
                    <div className="flex items-center flex-wrap gap-2">
                        {Boolean(notifications.length) && (
                            <Button loading={isReading} color="secondary" variant="text" size="small" onClick={handleReadAllNotifications}>
                                Marcar todas como lida
                            </Button>
                        )}
                        <Tooltip title="Ver todas as notificações" placement="top" disableFocusListener={true}>
                            <IconButton onClick={handleGoToNotificationsPage}>
                                <SvgIcoExternal width={18} height={18} />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                <Divider />
                <div className="p-3 notifications">
                    {Boolean(notifications.length) ? (
                        notifications.map((item) => <NotificationItem key={item.id} {...item} onCloseMenu={handleClose} />)
                    ) : (
                        <div className="px-4 py-2">
                            <Text as="p" className="text-base-500 italic">
                                Nenhuma notificação encontrada
                            </Text>
                        </div>
                    )}
                </div>
            </Menu>
        </div>
    );
};

export default Notifications;
