import classnames from 'classnames';
import DRETableBody from './body';
import DRETableHeader from './header';
import { memo, useMemo } from 'react';
import Card from 'components/core/card';
import _max from 'lodash/max';
import _sum from 'lodash/sum';
import './table.css';
import Empty from 'components/empty';
import Spinner from 'components/core/spinner';
import { DRETableProps, TableItem } from 'types/models/reports';

type Props = {
    withCard?: boolean;
    rounded?: boolean;
    onClickRow?: (values: TableItem['values'][0]) => void;
} & DRETableProps;

const DRETable = ({ isLoading, fontSize, items = [], headers = [], minHeaderWidth, className, title, hasFirstEmptyHeaderColumn = true, withCard = true, rounded = true, onClickRow }: Props) => {
    const table = classnames(`dre-table table-auto border-collapse w-full text-base-500`);

    const numberOfCols = useMemo(() => _max(headers.map((items) => _sum(items.map(({ colSpan = 1 }) => colSpan)))) || 0, [headers]);

    const Container = withCard ? Card : 'div';

    return (
        <Container className={classnames('p-5 overflow-x-auto', className)}>
            {title && <h3 className="mb-5 font-medium text-heading text-lg">{title}</h3>}
            {!isLoading && items?.length ? (
                <table className={table} style={{ fontSize }}>
                    <DRETableHeader rounded={rounded} hasFirstEmptyHeaderColumn={hasFirstEmptyHeaderColumn} minWidth={minHeaderWidth} headers={headers} />
                    <DRETableBody items={items} numberOfCols={numberOfCols} onClickRow={onClickRow} />
                </table>
            ) : (
                !isLoading && <Empty title="Nenhum registro encontrado." />
            )}
            {isLoading && <Spinner fixed={false} />}
        </Container>
    );
};

export default memo(DRETable);
