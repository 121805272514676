import { createContext, PropsWithChildren, useContext, useReducer } from 'react';
import * as Types from './types';
import { Steps } from './types';

const INITIAL_STATE: Types.ExpensesState = {
    completeds: [],
    content: {},
    currentStep: Types.Steps.GeneralInfo,
    steps: [
        {
            slug: Types.Steps.GeneralInfo,
            label: 'Informações gerais',
            hide: false,
        },
        {
            slug: Types.Steps.Products,
            label: 'Produtos',
            hide: false,
        },
        {
            slug: Types.Steps.Installments,
            label: 'Parcelas',
            hide: false,
        },
        {
            slug: Types.Steps.Documents,
            label: 'Documentos',
            hide: false,
        },
        {
            slug: Types.Steps.Summary,
            label: 'Resumo',
            hide: false,
        },
    ],
};

const ExpensesContext = createContext({} as Types.ContextType);

const actions = new Map<Types.ContextActions, keyof Types.ExpensesState>([
    ['SET_CONTENT', 'content'],
    ['SET_CURRENT_STEP', 'currentStep'],
    ['SET_STEP_COMPLETED', 'completeds'],
    ['SET_STEPS', 'steps'],
]);

const ExpensesContextProvider = ({ children }: PropsWithChildren) => {
    const [state, dispatch] = useReducer((state: Types.ExpensesState, stateAction: Types.ReducerAction) => {
        try {
            const action = actions.get(stateAction.type);

            if (!action) {
                throw new Error();
            }

            return { ...state, [action]: stateAction.payload };
        } catch (error) {
            console.log('error', error);
            return state;
        }
    }, INITIAL_STATE);

    const _getNextVisibleStep = (currentIndex: number) => {
        for (let i = currentIndex + 1; i < state.steps.length; i++) {
            if (!state.steps[i].hide) {
                return i;
            }
        }

        return -1; // No visible step found
    };

    const _getPreviousVisibleStep = (currentIndex: number) => {
        for (let i = currentIndex - 1; i >= 0; i--) {
            if (!state.steps[i].hide) {
                return i;
            }
        }

        return -1; // No visible step found
    };

    const getStep = (slug: Types.Steps) => state.steps.find((item) => item.slug === slug);

    const completeStep = (step: Types.Steps) => {
        const stepExistsOnCompletedArray = state.completeds.some((item) => item === step);
        const completedPayload = stepExistsOnCompletedArray ? state.completeds : [...state.completeds, step];

        dispatch({ type: 'SET_STEP_COMPLETED', payload: completedPayload });
    };

    const setCurrentStep = (slug: Types.Steps) => {
        const step = getStep(slug);

        dispatch({ type: 'SET_CURRENT_STEP', payload: step?.slug });
    };

    const changeStep = (currentStepSlug: Types.Steps, nextStepSlug?: Types.Steps, previousStepSlug?: Types.Steps) => {
        const step = getStep(currentStepSlug);

        if (!step) {
            throw new Error();
        }

        if (previousStepSlug) {
            return setCurrentStep(previousStepSlug);
        }

        if (nextStepSlug) {
            setCurrentStep(nextStepSlug);
        }
    };

    const setContent = (content) => {
        const payload = {
            ...state.content,
            ...content,
        };

        dispatch({ type: 'SET_CONTENT', payload });
    };

    const hideStep = ({ slug, hide }: { slug: Steps; hide: boolean }) => {
        dispatch({ type: 'SET_STEPS', payload: state.steps.map((step) => (step.slug === slug ? { ...step, hide } : step)) });
    };

    const setSteps = (steps: Types.Step[]) => {
        dispatch({ type: 'SET_STEPS', payload: steps });
    };

    const goToStep = (action: 'previous' | 'next') => {
        const ix = state.steps.findIndex((item) => item.slug === state.currentStep);

        if (ix === -1) {
            return;
        }

        const helper = action === 'previous' ? _getPreviousVisibleStep : _getNextVisibleStep;

        const prevStepIndex = helper(ix);

        if (prevStepIndex === -1) {
            return;
        }

        const step = state.steps[prevStepIndex];

        setCurrentStep(step.slug);
    };

    const values = {
        state,
        setCurrentStep,
        changeStep,
        completeStep,
        getStep,
        setContent,
        hideStep,
        setSteps,
        goToStep,
    };

    return <ExpensesContext.Provider value={values}>{children}</ExpensesContext.Provider>;
};

const useExpensesContext = () => {
    return useContext(ExpensesContext);
};

export { useExpensesContext, ExpensesContextProvider };
