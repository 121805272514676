import DatePicker, { DatePickerProps } from 'components/core/datepicker';
import { Control, Controller } from 'react-hook-form';
import Text from 'components/core/text';

type DatePickerInputs = {
    inputProps: {
        label?: string;
        name: string;
        error?: string;
    } & Partial<DatePickerProps['inputProps']>;
    muiDatePickerProps?: DatePickerProps['muiDatePickerProps'];
};

type FilterPeriodProps = {
    control: Control<any, any>;
    label?: string;
    isDisabled?: boolean;
    containerClasses?: string;
    firstInput: DatePickerInputs;
    secondInput: DatePickerInputs;
};

const FilterPeriod = ({ control, label = 'Período', firstInput, secondInput, isDisabled = false, containerClasses }: FilterPeriodProps) => {
    const { name: firstInputName, label: firstInputLabel = 'Data Início', ...firstInputRest } = firstInput.inputProps;
    const { name: secondInputName, label: secondInputLabel = 'Data Fim', ...secondInputRest } = secondInput.inputProps;

    return (
        <div className={containerClasses}>
            <Text as="label" variant="body.regular.sm" className="block mb-1.5 text-base-500">
                {label}
            </Text>
            <div className="grid grid-cols-2 gap-2">
                <Controller
                    name={firstInputName}
                    control={control}
                    render={({ field }) => (
                        <DatePicker
                            inputProps={{
                                error: firstInput.inputProps.error,
                                disabled: isDisabled,
                                placeholderText: firstInputLabel,
                                isClearable: firstInput.inputProps.isClearable ?? true,
                                ...firstInputRest,
                                ...field,
                            }}
                            muiDatePickerProps={firstInput.muiDatePickerProps}
                        />
                    )}
                />
                <Controller
                    name={secondInputName}
                    control={control}
                    render={({ field }) => (
                        <DatePicker
                            inputProps={{
                                error: secondInput.inputProps.error,
                                disabled: isDisabled,
                                placeholderText: secondInputLabel,
                                isClearable: secondInput.inputProps.isClearable ?? true,
                                ...secondInputRest,
                                ...field,
                            }}
                            muiDatePickerProps={secondInput.muiDatePickerProps}
                        />
                    )}
                />
            </div>
        </div>
    );
};

export default FilterPeriod;
