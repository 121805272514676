import React, { useCallback, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useTheme from '@mui/material/styles/useTheme';
import IconButton from '@mui/material/IconButton/IconButton';
import Menu from 'components/menu';
import { Rule } from 'types/permissions';
import { MenuItem } from 'components/menu';
import SvgIcoDots from 'components/core/icon/files/ico-dots';

type DatatableActionsProps = {
    item: any;
    actions: Rule[];
    customRoutePath?: (item: any, rule?: Rule) => string;
    onDelete?: (id?: number, slug?: string) => void;
    customActions?: (item: any) => any[];
    customFooterActions?: (item: any) => any[];
};

const DatatableActions = ({ actions, customRoutePath, item, onDelete, customActions, customFooterActions }: DatatableActionsProps) => {
    const navigate = useNavigate();
    const { palette } = useTheme();
    const { pathname } = useLocation();
    const containerRef = useRef<HTMLDivElement>(null);

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handleClose = () => setAnchorEl(null);
    const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

    const handleGoTo = useCallback(
        (path?: string, rule?: Rule) => (event: React.MouseEvent<Element, MouseEvent>) => {
            handleClose();

            const customPath = customRoutePath?.(item, rule);

            if (event.ctrlKey || event.metaKey) {
                const newRoute = customPath || `${pathname}/${path}`;

                return window.open(newRoute, '_blank');
            }

            const url = customPath || path;

            if (!!url) {
                navigate(url);
            }
        },
        [navigate, customRoutePath, item, pathname]
    );

    const handleDelete = useCallback(() => onDelete?.(item?.id, item?.slug), [item?.id, item?.slug, onDelete]);

    const options = useMemo(() => {
        const customActionsArr = customActions?.(item) || [];

        if (!!customActionsArr.length) {
            handleClose();

            return customActionsArr;
        }

        const content = new Map<Rule, MenuItem>([
            [
                Rule.Show,
                {
                    label: 'Abrir',
                    icon: { name: 'ico-show', color: palette.grey[500] },
                    onClick: handleGoTo(item?.id?.toString(), Rule.Show),
                },
            ],
            [
                Rule.Update,
                {
                    label: 'Editar',
                    icon: { name: 'ico-edit', color: palette.grey[500] },
                    onClick: handleGoTo(`editar/${item?.id}`, Rule.Update),
                },
            ],
        ]);

        const newActions = actions.filter((action) => {
            if (Boolean(item?.system) && (action === Rule.Update || action === Rule.Delete)) {
                return;
            }

            return action;
        });

        const arr: MenuItem[] = newActions
            .map((item) => content.get(item)!)
            .filter(Boolean)
            .sort((a, b) => a.label.localeCompare(b.label));

        return arr;
    }, [actions, palette, handleGoTo, customActions, item]);

    const footer = useMemo(() => {
        const customActionsArr = customFooterActions?.(item) || [];

        if (!!customActionsArr.length) {
            handleClose();

            return customActionsArr;
        }

        const hasDeleteAction = actions.some((item) => item === Rule.Delete);

        if (!hasDeleteAction) {
            return [];
        }

        return [
            {
                className: 'text-system-danger-500',
                label: 'Apagar',
                icon: {
                    color: palette.error.main,
                    name: 'ico-trash',
                },
                onClick: handleDelete,
            },
        ];
    }, [actions, palette, handleDelete, customFooterActions, item]);

    if (!Boolean(options.length) && !Boolean(footer?.length)) {
        return null;
    }

    return (
        <div className="relative !overflow-visible" ref={containerRef}>
            <IconButton onClick={handleOpen}>
                <SvgIcoDots width={16} height={16} color={palette.grey[500]} />
            </IconButton>
            <Menu anchorEl={anchorEl} container={containerRef.current} open={Boolean(anchorEl)} onClose={handleClose} content={options} footer={footer} />
        </div>
    );
};

export default DatatableActions;
