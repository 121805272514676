import ExternalButton from 'components/buttons/external';
import Badge from 'components/core/badge';
import dayjs from 'dayjs';
import { scheduleStatusBadgeLiteral } from 'services/queries/sales-order/utils';
import { DatatableColumn } from 'types/graphql';
import { formatDate } from 'utils/date';
import dictionary from 'utils/dictionary';
import { object, string } from 'yup';

export const saleOrderSchedulesColumns: DatatableColumn[] = [
    {
        name: 'id',
        options: { display: 'excluded' },
    },
    {
        name: 'day',
        label: 'Data e Hora',
        options: {
            customBodyRender: (value) => {
                const day = formatDate(value?.day);
                const hour = value?.time ? dayjs?.utc(value?.time).format('HH:mm') : '-';

                return `${day} - ${hour}`;
            },
        },
    },
    {
        name: 'requester',
        label: 'Solicitante',
        options: {
            customBodyRender: (value) => {
                if (!value?.id) {
                    return '-';
                }

                return <ExternalButton href={`/app/pessoas/${value?.id}`}>{value?.name || value?.title}</ExternalButton>;
            },
        },
    },
    {
        name: 'scheduleStatus',
        label: 'Status',
        options: {
            customBodyRender: (value) => {
                if (!Boolean(value)) {
                    return '-';
                }

                const scheduleStatus = scheduleStatusBadgeLiteral[value];

                return <Badge variant={scheduleStatus?.color}>{scheduleStatus?.label}</Badge>;
            },
        },
    },
    {
        name: 'withdrawalDate',
        label: 'Data da Retirada',
        options: {
            customBodyRender: (value) => formatDate(value) || '-',
        },
    },
    {
        name: 'deliveryman',
        label: 'Entregue por',
        options: {
            customBodyRender: (value) => value?.name || value?.title || '-',
        },
    },
    {
        name: 'observations',
        label: 'Observações',
        options: {
            customBodyRender: (value = '') => value,
        },
    },
];

export const saleOrderScheduleSchema = object({
    hour: string().required(dictionary.validation.required),
    day: string().required(dictionary.validation.required),
});
