import { useEffect } from 'react';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { useTableAdvancedSearch } from 'components/core/table/datatable/components/advanced-search';
import useGetClassifications from 'services/queries/classifications/use-get-classifications';
import { generateClassificationQuery } from 'services/queries/classifications/utils';
import { ClassificationType } from 'types/models/classification';

import Select from 'components/core/form/select';
import Button from 'components/core/button';
import { productTypes } from 'utils/statics';
import { ProductsFilterPayload, ProductsFilters } from 'types/models/product';

type ProductsAdvancedSearchProps = {
    filters: ProductsFilters;
    onSubmit: (data: ProductsFilters) => void;
};

const ProductsAdvancedSearch = ({ onSubmit, filters }: ProductsAdvancedSearchProps) => {
    const { onClose } = useTableAdvancedSearch();

    const { control, handleSubmit, reset } = useForm<ProductsFilterPayload>({
        mode: 'onSubmit',
    });

    const { data: classificationsOptions, isLoading: isLoadingClassifications } = useGetClassifications(generateClassificationQuery(ClassificationType.ProductClassification));

    const { data: categoriesOptions, isLoading: isLoadingCategories } = useGetClassifications(generateClassificationQuery(ClassificationType.ProductCategory));

    const submit = (data: ProductsFilterPayload) => {
        onSubmit(data);
        onClose();
    };

    useEffect(() => {
        const classifications = filters?.classifications;
        const categories = filters?.categories;
        const types = filters?.types;

        reset({
            ...(Boolean(classifications?.length) && { classifications }),
            ...(Boolean(categories?.length) && { categories }),
            ...(Boolean(types?.length) && { types }),
        });
    }, [filters, reset]);

    const handleChangeSelected = (field: ControllerRenderProps<ProductsFilterPayload, any>) => (option: any) => {
        const ids = option.map((item) => item.value);

        return field.onChange(ids);
    };

    return (
        <form onSubmit={handleSubmit(submit)}>
            <div className="grid gap-2 mb-2 w-full">
                <Controller
                    name="categories"
                    control={control}
                    render={({ field }) => {
                        const value = categoriesOptions?.filter((item) => field.value?.includes(item.value));

                        return (
                            <Select
                                {...field}
                                isLoading={isLoadingCategories}
                                value={value}
                                options={categoriesOptions || []}
                                label="Categorias"
                                placeholder="Selecione uma opção"
                                onChange={handleChangeSelected(field)}
                                isClearable={true}
                                isMulti={true}
                                className="max-w-[350px]"
                            />
                        );
                    }}
                />
                <Controller
                    name="classifications"
                    control={control}
                    render={({ field }) => {
                        const value = classificationsOptions?.filter((item) => field.value?.includes(item.value));

                        return (
                            <Select
                                {...field}
                                isLoading={isLoadingClassifications}
                                value={value}
                                options={classificationsOptions || []}
                                label="Classificações"
                                placeholder="Selecione uma opção"
                                onChange={handleChangeSelected(field)}
                                isClearable={true}
                                isMulti={true}
                                className="max-w-[350px]"
                            />
                        );
                    }}
                />
                <Controller
                    name="types"
                    control={control}
                    render={({ field }) => {
                        const value = productTypes.filter((item) => field.value?.includes(item.value));

                        return (
                            <Select
                                {...field}
                                isLoading={isLoadingClassifications}
                                value={value}
                                options={productTypes}
                                label="Tipos"
                                placeholder="Selecione uma opção"
                                onChange={handleChangeSelected(field)}
                                isClearable={true}
                                isMulti={true}
                                className="max-w-[350px]"
                            />
                        );
                    }}
                />
            </div>
            <div className="flex">
                <Button type="submit" variant="contained" color="secondary" className="min-w-[100px] ml-auto">
                    Filtrar
                </Button>
            </div>
        </form>
    );
};

export default ProductsAdvancedSearch;
