import * as React from 'react';
const SvgIcoDownload = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M10.899 7.755a.736.736 0 0 1 .99 1.088L8 12.386 4.107 8.843a.736.736 0 0 1 .991-1.088l2.165 1.971V.736a.736.736 0 1 1 1.471 0v8.99L10.9 7.755Zm-9.444 4.608a.727.727 0 0 0-1.455 0v.97A2.667 2.667 0 0 0 2.667 16h10.666A2.667 2.667 0 0 0 16 13.333v-.97a.727.727 0 0 0-1.454 0v.97c0 .67-.543 1.212-1.213 1.212H2.667c-.67 0-1.212-.543-1.212-1.212v-.97Z"
            clipRule="evenodd"
        />
    </svg>
);
export default SvgIcoDownload;
