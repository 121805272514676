import TableCell from '@mui/material/TableCell';
import { formatDate } from 'utils/date';
import { formatMoney } from 'utils/money';
import { DatatableColumn } from 'types/graphql';
import { getNumberValueOrZero } from '../expenses/details/tabs/transactions/utils';

export const loanDatatableColumns: DatatableColumn[] = [
    {
        name: 'id',
        options: { display: 'excluded' },
    },
    {
        name: 'code',
        label: 'Código',
        options: {
            customBodyRender: (value) => <span className="whitespace-nowrap">{value || '-'}</span>,
        },
    },
    {
        name: 'company.name',
        label: 'Empresa',
        options: {
            customBodyRender: (value) => <span className="whitespace-nowrap">{value || '-'}</span>,
        },
    },
    {
        name: 'branch.name',
        label: 'Filial',
        options: {
            customBodyRender: (value) => value || '-',
        },
    },
    {
        name: 'bankAccount.name',
        label: 'Conta bancária',
        options: {
            customBodyRender: (value) => value || '-',
        },
    },
    {
        name: 'loanDate',
        label: 'Data do empréstimo',
        options: {
            customBodyRender: (value) => formatDate(value, 'DD/MM/YYYY'),
        },
    },
    {
        name: 'value',
        label: 'Principal',
        options: {
            customBodyRender: (value) => formatMoney(getNumberValueOrZero(value)),
        },
    },
    {
        name: 'paidValue',
        label: 'Valor pago',
        options: {
            customBodyRender: (value) => formatMoney(getNumberValueOrZero(value)),
        },
    },
    {
        name: 'actions',
        label: 'Ações',
        options: {
            customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />,
        },
    },
];
