import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Text from 'components/core/text';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import CurrencyInput from 'components/core/form/currency';
import RemoveButton from 'components/buttons/remove';
import { NumberFormatValues } from 'react-number-format/types/types';
import { formatMoney, formatNumberToPTBR } from 'utils/money';
import { getNumberValueOrZero } from 'pages/private/expenses/details/tabs/transactions/utils';

type NationalizeProductsRowProps = {
    item: any;
    index: number;
    onRemoveProduct: (index: number) => void;
};

const NationalizeProductsRow = ({ item, index, onRemoveProduct }: NationalizeProductsRowProps) => {
    const { control, formState } = useFormContext<any>();

    const value = useWatch({ control, name: `products.${index}.grossValue` }) || 0;
    const quantity = useWatch({ control, name: `products.${index}.quantity` }) || 0;
    const baseValue = useWatch({ control, name: `products.${index}.baseValue` }) || 0;
    const complementaryValue = useWatch({ control, name: `products.${index}.complementaryValue` }) || 0;

    const priceValue = Number(baseValue) + Number(complementaryValue) + Number(value);
    const total = Number(value) * Number(quantity);

    const handleChangeValue = (onChange: (...event: any[]) => void) => async (values: NumberFormatValues) => {
        const { floatValue = 0 } = values;

        onChange(floatValue);
    };

    return (
        <TableRow classes={{ root: 'opacity-100 hover:bg-base-200' }}>
            <TableCell>
                <Text className="text-base-500 whitespace-nowrap">{item.name || '-'}</Text>
            </TableCell>
            <TableCell>
                <Text className="text-base-500">{formatMoney(getNumberValueOrZero(item.baseValue))}</Text>
            </TableCell>
            <TableCell>
                <Text className="text-base-500">{formatMoney(getNumberValueOrZero(item.complementaryValue))}</Text>
            </TableCell>
            <TableCell>
                <Text className="text-base-500">{formatNumberToPTBR(getNumberValueOrZero(item.balance))}</Text>
            </TableCell>
            <TableCell>
                <Controller
                    name={`products.${index}.quantity`}
                    control={control}
                    render={({ field }) => {
                        return (
                            <CurrencyInput
                                value={field.value}
                                onValueChange={handleChangeValue(field.onChange)}
                                placeholder="Ex: 1000"
                                parentClassName="w-[120px]"
                                error={formState.errors.products?.[index]?.quantity?.message}
                            />
                        );
                    }}
                />
            </TableCell>
            <TableCell>
                <Controller
                    name={`products.${index}.grossValue`}
                    control={control}
                    render={({ field }) => {
                        return (
                            <CurrencyInput
                                value={field.value}
                                onValueChange={handleChangeValue(field.onChange)}
                                placeholder="Ex: 1000"
                                withFormat={false}
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={4}
                                fixedDecimalScale={true}
                                parentClassName="min-w-[150px]"
                                error={formState.errors.products?.[index]?.grossValue?.message}
                            />
                        );
                    }}
                />
            </TableCell>
            <TableCell>
                <Controller
                    name={`products.${index}.tax`}
                    control={control}
                    render={({ field }) => {
                        return (
                            <CurrencyInput
                                value={field.value}
                                onValueChange={handleChangeValue(field.onChange)}
                                placeholder="Ex: 1000"
                                withFormat={false}
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={4}
                                fixedDecimalScale={true}
                                parentClassName="w-[120px]"
                                error={formState.errors.products?.[index]?.tax?.message}
                            />
                        );
                    }}
                />
            </TableCell>
            <TableCell>
                <Text className="text-base-500">{formatMoney(priceValue)}</Text>
            </TableCell>
            <TableCell className="whitespace-nowrap">{formatMoney(total)}</TableCell>
            <TableCell>{<RemoveButton className="!static opacity-100 mt-0" onClick={onRemoveProduct.bind(this, index)} />}</TableCell>
        </TableRow>
    );
};

export default NationalizeProductsRow;
