import classNames from 'classnames';
import Text from 'components/core/text';

type EmptyProps = {
    title: string;
    buttonLabel?: string;
    className?: string;
    onClick?: () => void;
    titleClassName?: string;
};

const Empty = ({ buttonLabel, className, title, onClick, titleClassName }: EmptyProps) => {
    return (
        <div className={className}>
            <Text as="span" variant="body.regular.xs" className={classNames('text-base-500 mr-1 italic', titleClassName)}>
                {title}
            </Text>
            {buttonLabel && onClick && (
                <button onClick={onClick}>
                    <Text as="span" variant="body.regular.xs" className="text-secondary-500">
                        {buttonLabel}
                    </Text>
                </button>
            )}
        </div>
    );
};

export default Empty;
