import Select from 'components/core/form/select';
import useDebounce from 'hooks/keyboard/use-debounce';
import { useState } from 'react';
import useGetFavoredOptions from 'services/queries/expenses/use-get-favored-options';
import { Option } from 'types/general';
import FavoredSelectActions from './actions';
import './styles.css';
import classNames from 'classnames';
import { FormFavoredType } from 'types/models/favored';

type FavoredSelectProps = {
    error?: string;
    value?: Option & { type?: FormFavoredType };
    onChange(value: any): void;
    hideActions?: boolean;
    isClearable?: boolean;
    isDisabled?: boolean;
    peopleWhere?: any;
    providersWhere?: any;
    customLabel?: string;
    onOpenProviderConsultingModal?: () => void;
};

type GroupedOption = {
    label: string;
    options: Option[];
};

const FavoredSelect = ({ hideActions = false, isClearable = false, isDisabled = false, peopleWhere, providersWhere, customLabel = 'Favorecido', ...props }: FavoredSelectProps) => {
    const [searchText, setSearchText] = useState('');

    const debouncedSearchText = useDebounce(searchText, 600);

    const { data, isLoading } = useGetFavoredOptions(debouncedSearchText, peopleWhere, providersWhere);

    const options: readonly GroupedOption[] = [
        {
            label: 'Funcionário',
            options: data?.people?.map((item) => ({ ...item, type: 'person' })) || [],
        },
        {
            label: 'Fornecedor',
            options: data?.providers?.map((item) => ({ ...item, type: 'provider' })) || [],
        },
    ];

    return (
        <>
            <div className="flex">
                <Select
                    parentClassName={classNames('flex-1', !hideActions && 'favored-select')}
                    isLoading={isLoading}
                    isDisabled={isDisabled}
                    options={options}
                    label={customLabel}
                    placeholder="Digite um funcionário ou fornecedor"
                    onInputChange={setSearchText}
                    isClearable={isClearable}
                    {...props}
                />
                {!hideActions && <FavoredSelectActions onOpenProviderConsultingModal={props.onOpenProviderConsultingModal} value={props.value} onSelect={props.onChange} />}
            </div>
        </>
    );
};

export default FavoredSelect;
