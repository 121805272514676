module.exports = {
    fontFamily: {
        sans: ['Poppins', 'sans-serif'],
    },
    metadata: {
        name: 'Topfield SGI',
        shortName: 'SGI',
        slug: 'topfield-sgi',
    },
    extend: {
        animation: {
            spin: 'spin 0.7s linear infinite',
            bounceInRight: 'bounceInRight 0.5s 0s both',
        },
        backgroundImage: {
            notFound: "url('assets/images/bg-404.png')",
            public: "url('assets/images/bg-public.png')",
        },
        borderRadius: {
            '2lg': '10px',
        },
        colors: {
            base: {
                100: '#FFF',
                200: '#F8F8F8',
                300: '#E8E9EB',
                400: '#C6C9CC',
                500: '#6d7178',
                700: '#595F69',
            },
            primary: {
                100: '#84BED5',
                200: '#66A7C9',
                300: '#498EBC',
                400: '#2C74AF',
                500: '#1057a2',
                600: '#0C3090',
                700: '#080F7D',
                800: '#15056A',
                900: '#210356',
            },
            secondary: {
                100: '#A8EEFF',
                200: '#7EE1FD',
                300: '#54D3FA',
                400: '#2BC1F5',
                500: '#05aeef',
                600: '#199DDB',
                700: '#2D8FC7',
                800: '#4285B3',
                900: '#567E9E',
            },
            system: {
                danger: {
                    100: '#FEBCB9',
                    200: '#FEABA7',
                    300: '#FE9B95',
                    400: '#FD8A84',
                    500: '#FD7972',
                    600: '#DD6A64',
                    700: '#BE5B56',
                    800: '#9E4C47',
                    900: '#7F3D39',
                },
                success: {
                    100: '#A4E4B6',
                    200: '#8DDDA4',
                    300: '#77D792',
                    400: '#60D07F',
                    500: '#49C96D',
                    600: '#40B05F',
                    700: '#379752',
                    800: '#2E7E44',
                    900: '#256537',
                },
                warning: {
                    100: '#FFE9A0',
                    200: '#FFE388',
                    300: '#FFDD70',
                    400: '#FFD858',
                    500: '#FFD240',
                    600: '#DFB838',
                    700: '#BF9E30',
                    800: '#9F8328',
                    900: '#806920',
                },
                info: {
                    50: '#e1f5fe',
                    100: '#b3e5fc',
                    200: '#81d4fa',
                    300: '#4fc3f7',
                    400: '#29b6f6',
                    500: '#03a9f4',
                    600: '#039be5',
                    700: '#0288d1',
                    800: '#0277bd',
                    900: '#01579b',
                },
            },
            heading: '#0057a2',
            white: '#FFF',
        },
        fontSize: {
            '2xs': ['0.9375rem', '1.375rem'],
            '3xl': ['1.75rem', '2.625rem'],
            '7xl': ['3.5rem', '5.25rem'],
        },
        screens: {
            sm: '767px',
            md: '1023px',
            lg: '1280px',
            xl: '1281px',
            '2xl': '1536px',
        },
        toast: {
            inset: '1rem',
        },
        transitionProperty: {
            width: 'width',
        },
    },
};
