import { generateGetTransactionsQuery } from 'pages/private/sales-orders/service-order/deduction/create-or-update/utils';
import { useMutation } from 'react-query';
import apiGraphQl from 'services/graphql';
import { Transaction } from 'types/models/transaction';
import { Pagination } from 'types/pagination';

export const getTransactionsKey = (where: any) => ['transactions', where];

const useGetTransactions = () => {
    const request = async (where: any) => {
        const { items } = await apiGraphQl<Pagination<Partial<Transaction>>>(generateGetTransactionsQuery(where));

        return items;
    };

    return useMutation(request);
};

export default useGetTransactions;
