import * as React from 'react';
const SvgIcoCheck = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 13" width="1em" height="1em" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M15.729 1.58a.925.925 0 1 0-1.31-1.308l-9.772 9.772L1.58 6.979A.925.925 0 1 0 .27 8.29l3.675 3.675c.015.015.028.031.044.046a.92.92 0 0 0 .788.262.915.915 0 0 0 .569-.31L15.729 1.582Z"
            clipRule="evenodd"
        />
    </svg>
);
export default SvgIcoCheck;
