import createCache from '@emotion/cache';
import lightBlue from '@mui/material/colors/lightBlue';
import createTheme from '@mui/material/styles/createTheme';
import shadows, { Shadows } from '@mui/material/styles/shadows';
import theme from './index';
import { ptBR } from '@mui/x-date-pickers/locales';
import { hexToRgba } from 'utils/string';

export const MATERIAL_CACHE = createCache({ key: 'sgi', prepend: true });

const MATERIAL_DATEPICKER_STYLES = {
    '&': {
        color: theme.extend.colors.heading,
    },
    '&:hover': {
        backgroundColor: hexToRgba(theme.extend.colors.heading, 0.15),
    },
    '&:focus': {
        border: `1px dashed ${theme.extend.colors.heading}`,
        backgroundColor: hexToRgba(theme.extend.colors.heading, 0.15),
    },
    '&.Mui-selected': {
        backgroundColor: `${theme.extend.colors.heading} !important`,
    },
};

export const materialTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 767,
            md: 1023,
            lg: 1279,
            xl: 1280,
        },
    },
    typography: {
        fontFamily: "'Poppins', sans-serif",
        button: { textTransform: 'none' },
    },
    components: {
        MUIDataTable: {
            styleOverrides: {
                paper: {
                    borderRadius: 20,
                },
                responsiveScroll: {
                    overflowX: 'auto',
                    overflowY: 'visible',
                },
            },
        },
        MUIDataTableHeadCell: {
            styleOverrides: {
                data: {
                    color: theme.extend.colors.base[500],
                    textTransform: 'uppercase',
                    whiteSpace: 'nowrap',
                    fontSize: '0.875rem',
                },
                root: {
                    padding: '4px 16px',
                },
                sortAction: {
                    alignItems: 'center',
                },
            },
        },
        MUIDataTableBody: {
            styleOverrides: {
                emptyTitle: {
                    fontSize: 'inherit',
                    fontStyle: 'italic',
                    padding: '0 8px',
                    textAlign: 'left',
                },
            },
        },
        MUIDataTableBodyCell: {
            styleOverrides: {
                stackedCommon: {
                    color: theme.extend.colors.base[500],
                },
            },
        },
        MUIDataTableSelectCell: {
            styleOverrides: {
                root: {
                    paddingLeft: 0,
                    textAlign: 'center',
                    '> div': {
                        justifyContent: 'center',
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottomColor: theme.extend.colors.base[300],
                },
                paddingCheckbox: {
                    backgroundColor: '#FFF',
                },
            },
        },
        MUIDataTableBodyRow: {
            styleOverrides: {
                root: {
                    '&[data-disabled-checkbox] .MuiTableCell-paddingCheckbox': {
                        pointerEvents: 'none',
                        visibility: 'hidden',
                    },
                    '&.mui-row-selected': {
                        backgroundColor: theme.extend.colors.base[200],
                        '&:hover': {
                            backgroundColor: theme.extend.colors.base[200],
                        },
                    },
                    '&:last-child': {
                        td: {
                            borderBottom: 0,
                        },
                    },
                },
            },
        },
        MuiPagination: {
            styleOverrides: {
                ul: {
                    li: {
                        '&:not(:last-child)': {
                            marginRight: 8,
                        },
                    },
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    minWidth: '224px !important',
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    color: theme.extend.colors.base[700],
                    fontSize: '0.875rem',
                    paddingLeft: 20,
                    paddingRight: 20,
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    minWidth: '2rem !important',
                },
            },
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    borderRadius: '0.625rem',
                    margin: 0,
                    '&:disabled': {
                        backgroundColor: `${theme.extend.colors.base[300]} !important`,
                        color: theme.extend.colors.base[500],
                    },
                    '&.Mui-selected': {
                        color: '#FFF',
                    },
                    fontSize: '0.75rem',
                    minWidth: '2rem',
                    height: '2rem',
                },
                previousNext: {
                    backgroundColor: '#E6F5F7 !important',
                    color: theme.extend.colors.secondary[500],
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    '&:disabled': {
                        backgroundColor: `${theme.extend.colors.base[300]}`,
                    },
                    padding: '0.5rem',
                },
            },
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    '&:disabled': {
                        cursor: 'no-drop',
                        pointerEvents: 'unset',
                    },
                },
            },
        },
        MUIDataTableSearch: {
            styleOverrides: {
                searchText: {
                    flex: 1,
                    fontStyle: 'italic',
                    '.MuiInput-root': {
                        color: theme.extend.colors.base[500],
                        fontSize: '0.875rem',
                        '&:before': {
                            display: 'none',
                        },
                        '&:after': {
                            display: 'none',
                        },
                    },
                },
                main: {
                    border: `1px solid ${theme.extend.colors.base[300]}`,
                    borderRadius: 14,
                    paddingLeft: 12,
                },
                searchIcon: {
                    marginRight: 12,
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    minHeight: 'unset !important',
                },
            },
        },
        MUIDataTableToolbar: {
            styleOverrides: {
                root: {
                    padding: 24,
                },
                left: {
                    width: '100%',
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: '#EDEDED',
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    height: 17,
                    minWidth: 17,
                },
                label: {
                    fontSize: '0.625rem',
                    padding: '0 5px',
                },
                // @ts-ignore
                colorError: {
                    color: theme.extend.colors.base[100],
                },
                // @ts-ignore
                filledError: {
                    backgroundColor: theme.extend.colors.system.danger[500],
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                popper: {
                    zIndex: 9999,
                },
            },
        },
        MuiBadge: {
            styleOverrides: {
                badge: {
                    padding: 4,
                    minWidth: 18,
                    fontSize: '0.625rem',
                    lineHeight: 1,
                    height: 'unset',
                },
                colorError: {
                    color: theme.extend.colors.base[100],
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 20,
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    borderBottom: `1px solid ${theme.extend.colors.base[300]}`,
                },
            },
        },
        MuiButtonGroup: {
            styleOverrides: {
                root: {
                    borderRadius: 14,
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    padding: 12,
                },
            },
        },
        MuiButton: {
            variants: [
                {
                    props: { variant: 'outlined', color: 'inherit' },
                    style: {
                        borderColor: theme.extend.colors.base[300],
                        color: theme.extend.colors.base[700],
                    },
                },
                {
                    props: { variant: 'contained', color: 'error' },
                    style: {
                        // borderColor: theme.extend.colors.base[300],
                        color: theme.extend.colors.base[100],
                    },
                },
            ],
            styleOverrides: {
                sizeSmall: {
                    fontSize: '0.75rem',
                    padding: '5.5px 12px',
                },
                sizeMedium: {
                    fontSize: '0.9375rem',
                    padding: '8px 12px',
                },
                sizeLarge: {
                    fontSize: '0.9375rem',
                    padding: '8px 14px',
                },
                root: {
                    borderRadius: 14,
                },
                containedSecondary: {
                    color: theme.extend.colors.base[100],
                },
                containedSuccess: {
                    color: theme.extend.colors.base[100],
                },
                containedError: {
                    color: theme.extend.colors.base[100],
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    borderRadius: 14,
                },
                action: {
                    paddingTop: 2,
                },
            },
        },
        MuiLocalizationProvider: {
            defaultProps: {
                localeText: ptBR.components.MuiLocalizationProvider.defaultProps.localeText,
            },
        },
        MuiDayCalendar: {
            styleOverrides: {
                weekContainer: {
                    gap: '4px',
                },
                weekDayLabel: {
                    color: theme.extend.colors.heading,
                },
            },
        },
        MuiPickersYear: {
            styleOverrides: {
                yearButton: {
                    ...MATERIAL_DATEPICKER_STYLES,
                    textAlign: 'center',
                },
            },
        },
        MuiPickersMonth: {
            styleOverrides: {
                monthButton: {
                    ...MATERIAL_DATEPICKER_STYLES,
                    textAlign: 'center',
                },
            },
        },
        MuiPickersDay: {
            styleOverrides: {
                root: MATERIAL_DATEPICKER_STYLES,
            },
        },
        MuiPickersCalendarHeader: {
            styleOverrides: {
                label: {
                    color: theme.extend.colors.heading,
                },
                switchViewButton: {
                    color: theme.extend.colors.heading,
                },
            },
        },
        MuiPickersArrowSwitcher: {
            styleOverrides: {
                button: {
                    color: theme.extend.colors.heading,
                    '&.Mui-disabled': {
                        backgroundColor: 'unset',
                    },
                },
            },
        },
        MuiPickersLayout: {
            styleOverrides: {
                contentWrapper: {
                    gridColumn: 1,
                },
                root: {
                    '& .MuiPickersLayout-toolbar': {
                        gridRow: 1,
                    },
                },
            },
        },
        MuiDatePickerToolbar: {
            styleOverrides: {
                root: {
                    '& .MuiTypography-overline': {
                        color: theme.extend.colors.heading,
                    },
                },
                title: {
                    color: theme.extend.colors.heading,
                },
            },
        },
        MuiModal: {
            styleOverrides: {
                root: {
                    zIndex: 9999,
                },
            },
        },
    },
    palette: {
        info: lightBlue,
        primary: {
            ...theme.extend.colors.primary,
            main: theme.extend.colors.primary[500],
            light: theme.extend.colors.primary[100],
            dark: theme.extend.colors.primary[700],
        },
        secondary: {
            ...theme.extend.colors.secondary,
            main: theme.extend.colors.secondary[500],
            light: theme.extend.colors.secondary[100],
            dark: theme.extend.colors.secondary[700],
        },
        error: theme.extend.colors.system.danger,
        warning: theme.extend.colors.system.warning,
        success: theme.extend.colors.system.success,
        grey: theme.extend.colors.base,
        heading: theme.extend.colors.heading,
    },
    shadows: shadows.map((item, index) => {
        if (index === 21) {
            return '-4px 8px 24px rgba(44, 63, 88, 0.15)';
        }

        if (index === 22) {
            return '0px 8px 24px rgba(44, 63, 88, 0.1)';
        }

        if (index === 23) {
            return '0px 20px 50px rgba(44, 63, 88, 0.15)';
        }

        return item;
    }) as Shadows,
});
