import TableCell from '@mui/material/TableCell/TableCell';
import { DatatableColumn } from 'types/graphql';
import { formatDate } from 'utils/date';
import { formatMoney } from 'utils/money';
import UploadNf from './upload-nf';
import { array, boolean, number, object, string } from 'yup';
import dictionary from 'utils/dictionary';
import ExternalButton from 'components/buttons/external';
import { generateExpenseTransactionStatus } from '../expenses/details/tabs/transactions/utils';

export const pendingMoneyOrdersListColumns = (refetch): DatatableColumn[] => [
    {
        name: 'id',
        options: { display: 'excluded' },
    },
    {
        name: 'code',
        label: 'Código',
        options: {
            customBodyRender: (code, { rowData }) => {
                return !!code ? <ExternalButton to={`/app/ordens-de-pagamento/${rowData[0]}`}>{code}</ExternalButton> : '-';
            },
        },
    },
    {
        name: 'paymentForecast',
        label: 'Provisionamento',
        options: { customBodyRender: (value) => formatDate(value, 'DD/MM/YYYY') },
    },
    {
        name: 'dueDate',
        label: 'Vencimento',
        options: {
            customBodyRender: (value) => formatDate(value, 'DD/MM/YYYY'),
            display: 'false',
        },
    },
    {
        name: 'payday',
        label: 'Pagamento',
        options: {
            customBodyRender: (value) => formatDate(value, 'DD/MM/YYYY'),
            display: 'false',
        },
    },
    {
        name: 'expense.nature.name',
        label: 'Natureza',
        options: {
            customBodyRender: (value) => value || '-',
            display: 'false',
        },
    },
    {
        name: 'description',
        label: 'Descrição',
        options: { customBodyRender: (value = '-') => <span className="whitespace-nowrap">{value}</span> },
    },
    {
        name: 'expense.sector.name',
        label: 'Setor',
    },
    {
        name: 'expense.category.name',
        label: 'Categoria',
        options: { customBodyRender: (value = '-') => <span className="whitespace-nowrap">{value}</span> },
    },
    {
        name: 'expense.favored.document',
        options: { display: 'excluded' },
    },
    {
        name: 'expense.favored.title',
        label: 'Favorecido',
        options: { customBodyRender: (value, tableMeta) => <span className="whitespace-nowrap">{`${value} ${!!tableMeta.rowData[10] ? `(${tableMeta.rowData[10]})` : ''}`}</span> },
    },
    {
        name: 'expense.company.name',
        label: 'Empresa',
        options: { customBodyRender: (company = '-') => <span className="whitespace-nowrap">{company}</span> },
    },
    {
        name: 'netValue',
        label: 'Valor',
        options: { customBodyRender: (value) => formatMoney(value) },
    },
    {
        name: 'paymentForm.name',
        label: 'Forma de pagamento',
    },
    {
        name: 'paid',
        label: 'Status',
        options: {
            customBodyRender: (paid, { rowData }) => generateExpenseTransactionStatus({ paid, payday: rowData[5], dueDate: rowData[3] }),
        },
    },
    {
        name: 'expense.classificationOrigin.name',
        label: 'Origem',
        options: { customBodyRender: (value = '-') => <span className="whitespace-nowrap">{value}</span> },
    },
    {
        name: 'file',
        label: '',
        options: {
            customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />,
            customBodyRender: (_, tableMeta) => <UploadNf key={tableMeta.rowData[0]} data={tableMeta.rowData} onSuccess={refetch} />,
        },
    },
];

export const pendingMoneyDeductionSchema = (invoiceValue: number) =>
    object({
        orderServices: array(
            object({
                isChecked: boolean().optional(),
                totalToBeDeducted: number().optional(),
                totalDeducted: number().optional(),
                code: string().optional(),
                title: string().optional(),
                value: number().optional(),
                valueToDeduct: number()
                    .required(dictionary.validation.required)
                    .test('totalToBeDeducted', 'Valor deve ser menor ou igual ao valor da nota fiscal.', (value?: number) => (value || 0) <= (Number(invoiceValue) || 0)),
            })
        ),
    });
