import { array, boolean, number, object, string } from 'yup';
import dictionary from 'utils/dictionary';
import { EmailType, PersonContractType } from 'types/models/person';

const { required, invalid } = dictionary.validation;

const juridicSchemaCallback = (type, schema) => (type === PersonContractType.Juridic ? schema.required(required) : schema.nullable(true).notRequired());

export const personContractSchema = object({
    position: number(),
    salary: number(),
    startDate: string(),
    finalDate: string(),
    type: number(),
    juridicName: string().when('type', juridicSchemaCallback),
    juridicFantasyName: string().when('type', juridicSchemaCallback),
    juridicDocument: string().when('type', juridicSchemaCallback),
    consultant: boolean().default(false),
});

const personSchema = (isEdit = false, isUser = false) => {
    return object({
        name: string().typeError(required).required(required),
        title: string().typeError(required).required(required),
        document: string().typeError(required).required(required),
        birthday: string().typeError(required).required(required),
        gender: number().typeError(required).required(required),
        maritalStatus: number().typeError(required).required(required),
        email: string().typeError(required).email(invalid.email).required(required).default(''),
        address: object({
            compliment: string().optional(),
            neighbourhood: string().typeError(required).required(required),
            number: string().typeError(required).required(required),
            street: string().typeError(required).required(required),
            zip: string().typeError(required).required(required),
        }),
        phones: array(
            object({
                ddd: string().min(2, dictionary.validation.string.min(2)).max(2, dictionary.validation.string.max(2)).typeError(required).trim(),
                number: string().typeError(required),
            })
        ),
        emails: array(
            object({
                type: number().oneOf([EmailType.Personal, EmailType.Professional]),
                email: string().typeError(required),
            })
        ),
        ...(!isEdit && {
            contracts: personContractSchema,
        }),
        ...(isUser && {
            user: object({
                username: string().typeError(required).required(required).default(''),
                profile: number().positive().typeError(required).required(required),
            }),
        }),
    });
};

export default personSchema;
