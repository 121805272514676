import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { useLocation, useParams } from 'react-router-dom';
import StockDeliveryItem, { StockDeliveryPayload } from './stock-delivery-item';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import QueryString from 'qs';
import useReceiveProductsFromStock from 'services/queries/stocks/use-receive-products-from-stock';
import FormButtons from 'components/form-buttons';
import { stockDeliverySchema } from '../utils';

export type StockDeliveryParams = {
    purchaseOrderProducts: Array<{
        purchaseOrderProductId: string;
        productId: string;
        quantityPurchaseOrder: string;
        unit: string;
    }>;
};

const StockDeliveryPage = () => {
    const { search } = useLocation();
    const { purchaseOrderId } = useParams();

    const params = QueryString.parse(search, { ignoreQueryPrefix: true }) as StockDeliveryParams;

    const { mutateAsync: receiveProductsFromStock, isLoading: isSubmitting } = useReceiveProductsFromStock(purchaseOrderId);

    const methods = useForm<StockDeliveryPayload>({
        mode: 'all',
        defaultValues: {
            purchaseOrderProducts: [],
        },
        resolver: yupResolver(stockDeliverySchema(params.purchaseOrderProducts)) as any,
    });

    const submit = async (data: StockDeliveryPayload) => {
        try {
            const payload = {
                purchaseOrderProducts: data.purchaseOrderProducts
                    .filter((item) => !!item.stocks.length)
                    .map((item) => ({
                        ...item,
                        stocks: item.stocks
                            .filter(({ quantity, isChecked }) => !!quantity && isChecked)
                            .map((item) => ({
                                id: item.id,
                                quantity: item.quantity,
                            })),
                    })),
            };

            await receiveProductsFromStock(payload as any);
        } catch (error) {
            console.log('submit', error);
        }
    };

    return (
        <Modal
            contentClassnames="w-[800px]"
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    Entrega do estoque
                </Text>
            }
            closeOnClickOutside={false}>
            <FormProvider {...methods}>
                <form className="px-6 pb-6" onSubmit={methods.handleSubmit(submit)}>
                    <div className="mb-6">
                        {params.purchaseOrderProducts.map((productIds, index) => {
                            return <StockDeliveryItem productIds={productIds} index={index} key={index} />;
                        })}
                    </div>
                    <FormButtons
                        isLoading={isSubmitting}
                        submitButton={{
                            isDisabled: !methods.formState.isValid,
                        }}
                    />
                </form>
            </FormProvider>
        </Modal>
    );
};

export default StockDeliveryPage;
