import Modal from 'components/core/modal';
import Text from 'components/core/text';
import ChangeImageModalForm from './form';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';

type ChangeImageModalProps = {
    onSubmit(data: File): void;
    isLoading?: boolean;
    title?: string;
};

const ChangeImageModal = ({ title = 'Alterar foto', onSubmit, isLoading = false }: ChangeImageModalProps) => {
    const goToParentRoute = useNavigateToParentRoute();

    return (
        <Modal
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    {title}
                </Text>
            }
            contentClassnames="xs:w-full sm:min-w-[500px] md:min-w-[800px]"
            closeOnClickOutside={false}
            hideCloseButton={isLoading}>
            <div className="p-6 pt-0">
                <ChangeImageModalForm isSubmitting={isLoading} onCancel={goToParentRoute} onSubmit={onSubmit} />
            </div>
        </Modal>
    );
};

export default ChangeImageModal;
