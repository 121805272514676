import { yupResolver } from '@hookform/resolvers/yup';
import Divider from '@mui/material/Divider';
import DatePicker from 'components/core/datepicker';
import CurrencyInput from 'components/core/form/currency';
import Editor from 'components/core/form/editor';
import Select from 'components/core/form/select';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import FormButtons from 'components/form-buttons';
import { Controller, useForm } from 'react-hook-form';
import useGetClassifications from 'services/queries/classifications/use-get-classifications';
import { generateClassificationQuery } from 'services/queries/classifications/utils';
import { ClassificationType } from 'types/models/classification';
import { contributionSchema, queryConfigCompanyPartners } from '../utils';
import { CreateContributionPayload } from 'types/models/bank-accounts';
import { getCompanyPartnersKey } from 'services/queries/bank-accounts/keys';
import useGetOptions from 'services/queries/crud/use-get-options';
import { useOutletContext } from 'react-router-dom';

type CreateContributionProps = {
    onSubmit: (data: CreateContributionPayload) => void;
    isSubmitting: boolean;
};

const whereClause = (companyId: number) => ({
    companyId: { _eq: companyId },
});

const CreateContribution = ({ onSubmit, isSubmitting }: CreateContributionProps) => {
    const ctx = useOutletContext<{ companyId: number }>();

    const { data: paymentForms = [] } = useGetClassifications(generateClassificationQuery(ClassificationType.PaymentForm));
    const { data: companyPartnersOptions = [] } = useGetOptions(queryConfigCompanyPartners(whereClause(ctx?.companyId)), getCompanyPartnersKey(), Boolean(ctx?.companyId), (items) =>
        items.map((item) => ({ value: item?.value, label: item?.person?.label }))
    );

    const methods = useForm<CreateContributionPayload>({
        resolver: yupResolver(contributionSchema),
        mode: 'onSubmit',
    });

    const { control, handleSubmit, formState } = methods;

    return (
        <Modal closeOnClickOutside={false} contentClassnames="w-[800px]">
            <Text as="h4" className="!text-heading font-medium !text-2xl p-4">
                Criar novo aporte
            </Text>
            <Divider />
            <form onSubmit={handleSubmit(onSubmit)} className="p-4">
                <div className="mt-4">
                    <Controller control={control} name="description" render={({ field }) => <Editor {...field} label="Descrição" error={formState?.errors?.description?.message} />} />
                </div>
                <div className="mt-4 grid grid-cols-2 gap-4">
                    <Controller
                        control={control}
                        name="companyPartner"
                        render={({ field }) => {
                            const value = companyPartnersOptions.find((paymentForm) => paymentForm.value === +field.value);

                            return (
                                <Select
                                    options={companyPartnersOptions}
                                    label="Sócio(a)"
                                    value={value}
                                    onChange={(option: any) => field.onChange(option.value)}
                                    error={formState.errors.companyPartner?.message}
                                />
                            );
                        }}
                    />
                    <Controller
                        control={control}
                        name="paymentForm"
                        render={({ field }) => {
                            const value = paymentForms.find((paymentForm) => paymentForm.value === +field.value);

                            return (
                                <Select
                                    options={paymentForms}
                                    label="Forma de Pagamento"
                                    value={value}
                                    onChange={(option: any) => field.onChange(option.value)}
                                    error={formState.errors.paymentForm?.message}
                                />
                            );
                        }}
                    />
                    <Controller
                        control={control}
                        name="dueDate"
                        render={({ field }) => {
                            return (
                                <DatePicker
                                    inputProps={{
                                        label: 'Data de Recebimento',
                                        error: formState.errors.dueDate?.message,
                                        placeholderText: 'Ex: 00/00/0000',
                                        ...field,
                                    }}
                                />
                            );
                        }}
                    />
                    <Controller
                        control={control}
                        name="grossValue"
                        render={({ field }) => {
                            return (
                                <CurrencyInput
                                    ref={field.ref}
                                    name={field.name}
                                    label="Valor"
                                    value={field.value}
                                    decimalScale={2}
                                    decimalSeparator="."
                                    fixedDecimalScale={true}
                                    placeholder="Ex: R$ 2.500,40"
                                    onValueChange={({ floatValue }) => field.onChange(floatValue)}
                                    error={formState.errors.grossValue?.message}
                                />
                            );
                        }}
                    />
                </div>
                <FormButtons isLoading={isSubmitting} containerClassName="mt-4" />
            </form>
        </Modal>
    );
};

export default CreateContribution;
