import dictionary from 'utils/dictionary';
import { optionSchema } from 'utils/schema';
import { number, object, string } from 'yup';

export const expenseFormSchema = object({
    classificationOrigin: optionSchema.required(dictionary.validation.required),
    classificationSubOrigin: optionSchema.required(dictionary.validation.required),
    provision: string().required(dictionary.validation.required),
    sector: optionSchema.required(dictionary.validation.required),
    category: optionSchema.required(dictionary.validation.required),
    fiscalCode: optionSchema.required(dictionary.validation.required),
    formFavored: object({
        person: optionSchema,
        favoredBankAccount: object({
            value: number().optional(),
            label: string().optional(),
        }),
    }),
    paymentForm: optionSchema,
});
