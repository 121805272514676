import { useQuery } from 'react-query';
import { getStockProductKey } from './keys';
import apiGraphQl from 'services/graphql';
import { StockProduct } from 'types/models/stock';
import { getStockProductQuery } from './utils';

type Response = {
    stockProductByPk: Partial<StockProduct>;
};

const useGetStockProduct = (stockProductId?: string) => {
    const request = async () => {
        const { stockProductByPk } = await apiGraphQl<Response>(getStockProductQuery(stockProductId));

        return stockProductByPk;
    };

    return useQuery(getStockProductKey(stockProductId), request, { enabled: !!stockProductId });
};

export default useGetStockProduct;
