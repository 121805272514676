import Select from 'components/core/form/select';
import { memo, useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Button from 'components/core/button';
import { useTableAdvancedSearch } from 'components/core/table/datatable/components/advanced-search';
import { approvalStatuses, booleanOptions, purchaseProductStatuses } from 'utils/statics';

import { getCompanyOptionsKey } from 'services/queries/companies/keys';

import useGetOptions from 'services/queries/crud/use-get-options';
import { queryConfigCompanies } from 'pages/private/contributions/utils';
import { PurchaseOrderProductsFiltersPayload, PurchaseProductStatus } from 'types/models/purchase-order';
import { ApprovalStatus } from 'types/general';
import FilterPeriod from 'pages/private/payment-order/filter/period';
import { formatDate } from 'utils/date';
import dayjs from 'dayjs';

const filteredApprovalStatusOptions = approvalStatuses.filter((option) => option.value !== ApprovalStatus.Disapproved);
const billedOptions = [
    {
        value: null,
        label: 'Todos',
    },
    ...booleanOptions,
];

type PurchaseOrderProductsFiltersProps = {
    onSetFilters: React.Dispatch<React.SetStateAction<Partial<PurchaseOrderProductsFiltersPayload>>>;
    filters: Partial<PurchaseOrderProductsFiltersPayload>;
    onSetPage: (page: number) => void;
};

const PurchaseOrderProductsFilters = ({ filters, onSetFilters, onSetPage }: PurchaseOrderProductsFiltersProps) => {
    const { onClose } = useTableAdvancedSearch();

    const { data: companyOptions = [] } = useGetOptions(queryConfigCompanies, getCompanyOptionsKey, true);

    const { control, handleSubmit, reset } = useForm<PurchaseOrderProductsFiltersPayload>({
        mode: 'onSubmit',
        defaultValues: {
            purchaseProductStatus: [PurchaseProductStatus.Waiting],
            billed: null,
        },
    });

    const submit = useCallback(
        (data: Partial<PurchaseOrderProductsFiltersPayload>) => {
            onSetPage(1);

            onSetFilters({
                approvalStatus: data.approvalStatus,
                company: data.company,
                purchaseProductStatus: data.purchaseProductStatus,
                ...(data.billed != null && {
                    billed: data.billed,
                }),
                ...(!!data.startProvision && {
                    startProvision: formatDate(data.startProvision, 'YYYY-MM-DD'),
                }),
                ...(!!data.endProvision && {
                    endProvision: formatDate(data.endProvision, 'YYYY-MM-DD'),
                }),
            });

            onClose();
        },
        [onClose, onSetFilters, onSetPage]
    );

    useEffect(() => {
        const { purchaseProductStatus, company, approvalStatus, billed, startProvision, endProvision } = filters || {};

        reset({
            purchaseProductStatus,
            company,
            approvalStatus,
            billed,
            ...(Boolean(startProvision) && { startProvision: dayjs(startProvision).toISOString() }),
            ...(Boolean(endProvision) && { endProvision: dayjs(endProvision).toISOString() }),
        });
    }, [filters, reset]);

    return (
        <form onSubmit={handleSubmit(submit)}>
            <Controller
                name="purchaseProductStatus"
                control={control}
                render={({ field }) => {
                    const value = purchaseProductStatuses.filter((item) => field?.value?.includes(item.value));

                    return (
                        <Select
                            {...field}
                            value={value}
                            isMulti={true}
                            options={purchaseProductStatuses}
                            label="Status"
                            placeholder="Selecione uma opção"
                            onChange={(option: any) => {
                                const ids = option.map((item) => item.value);

                                field.onChange(ids);
                            }}
                            isClearable={true}
                            parentClassName="mb-4"
                        />
                    );
                }}
            />
            <Controller
                name="approvalStatus"
                control={control}
                render={({ field }) => {
                    const value = filteredApprovalStatusOptions.find((item) => item.value === field?.value);

                    return (
                        <Select
                            {...field}
                            value={value}
                            options={filteredApprovalStatusOptions}
                            label="Status de Aprovação"
                            placeholder="Selecione uma opção"
                            onChange={(option: any) => field?.onChange(option?.value)}
                            isClearable={true}
                            parentClassName="mb-4"
                        />
                    );
                }}
            />
            <Controller
                name="billed"
                control={control}
                render={({ field }) => {
                    const value = billedOptions.find((item) => item.value === field?.value);

                    return (
                        <Select
                            {...field}
                            value={value}
                            options={billedOptions}
                            label="Faturados"
                            placeholder="Selecione uma opção"
                            onChange={(option: any) => field?.onChange(option?.value)}
                            isClearable={true}
                            parentClassName="mb-4"
                        />
                    );
                }}
            />
            <Controller
                name="company"
                control={control}
                render={({ field }) => {
                    const value = companyOptions.find((item) => (item as any)?.value === field.value);

                    return (
                        <Select
                            {...field}
                            value={value}
                            options={companyOptions}
                            label="Empresa"
                            placeholder="Selecione uma opção"
                            onChange={(option: any) => field.onChange(option?.value)}
                            isClearable={true}
                            parentClassName="mb-4"
                        />
                    );
                }}
            />
            <FilterPeriod
                control={control}
                label="Período de provisão"
                firstInput={{
                    inputProps: {
                        name: 'startProvision',
                    },
                }}
                secondInput={{
                    inputProps: {
                        name: 'endProvision',
                    },
                }}
                containerClasses="mb-5"
            />
            <div className="flex">
                <Button type="submit" variant="contained" color="secondary" className="min-w-[100px] ml-auto">
                    Filtrar
                </Button>
            </div>
        </form>
    );
};

export default memo(PurchaseOrderProductsFilters);
