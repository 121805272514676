import Modal from 'components/core/modal';
import { useEffect } from 'react';
import Text from 'components/core/text';
import { useOutletContext, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ContactsFields from 'components/contacts/fields';
import Spinner from 'components/core/spinner';
import useCreateOrUpdate from 'services/queries/crud/use-create-or-update';
import queries from 'services/queries/providers/keys';
import useGetDetails from 'services/queries/crud/use-get-details';
import { CrudDetails, GraphqlPaginationVariables } from 'types/graphql';
import { customerContactSchema } from '../utils';
import { ProviderContact } from 'types/models/provider';
import FormButtons from 'components/form-buttons';

const detailsConfig: CrudDetails = {
    table: 'providerContact',
    fields: ['person.name', 'person.title', 'person.phones', 'person.gender', 'person.email', 'person.id'],
};

const ProviderContactsForm = () => {
    const { contactId, providerId = '' } = useParams();

    const ctx = useOutletContext<GraphqlPaginationVariables<any>>();

    const methods = useForm<any>({
        mode: 'onSubmit',
        resolver: yupResolver(customerContactSchema),
    });

    const { handleSubmit, reset } = methods;

    const isUpdate = !!contactId;

    const { data: contact, isLoading: isLoadingDetails } = useGetDetails<ProviderContact>(detailsConfig, contactId);

    const { mutateAsync: createOrUpdateContact, isLoading: isSubmitting } = useCreateOrUpdate(
        {
            id: contact?.person.id.toString(),
            graphql: {
                query: isUpdate ? queries.updateContact : queries.createContact,
                table: 'providerContact',
            },
        },
        ctx
    );

    const submit = async (data: any) => {
        try {
            const payload = {
                providerId,
                person: { data },
            };

            await createOrUpdateContact(isUpdate ? data : payload);
        } catch (error) {
            console.log('error', error);
        }
    };

    useEffect(() => {
        if (!!contact?.person) {
            reset(contact.person);
        }
    }, [contact, reset]);

    return (
        <Modal contentClassnames="w-[1200px]">
            {isLoadingDetails ? (
                <div className="h-[150px]">
                    <Spinner size={30} fixed={true} />
                </div>
            ) : (
                <FormProvider {...methods}>
                    <form className="py-5 px-7" onSubmit={handleSubmit(submit)}>
                        <Text as="h3" variant="h4" className="text-heading mb-5">
                            {isUpdate ? 'Atualizar' : 'Novo'} contato
                        </Text>
                        <div className="mb-7 group relative">
                            <ContactsFields />
                        </div>
                        <FormButtons isLoading={isSubmitting} />
                    </form>
                </FormProvider>
            )}
        </Modal>
    );
};

export default ProviderContactsForm;
