import useToast from 'hooks/toast/use-toast';
import { useQuery } from 'react-query';
import api from 'services/api';
import { Form } from 'types/models/form';

const useGetForm = (formId?: number) => {
    const { showToast } = useToast();

    const request = async () => {
        const { data } = await api.get<Form>(`/quizzes/${formId}`);

        return data;
    };

    return useQuery(getFormKey(formId), request, {
        enabled: !!formId,
        onError: () => {
            showToast('Ocorreu um erro ao carregar o formulário', 'danger');
        },
    });
};

export const getFormKey = (formId?: number) => ['forms', formId];

export default useGetForm;
