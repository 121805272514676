import Button from 'components/core/button';
import Modal from 'components/core/modal';
import Spinner from 'components/core/spinner';
import Datatable from 'components/core/table/datatable';
import Text from 'components/core/text';
import Empty from 'components/empty';
import { useParams } from 'react-router-dom';
import useGetPaymentFlow from 'services/queries/payment-flows/use-get-payment-flow';
import { formatDate } from 'utils/date';
import { formatMoney } from 'utils/money';
import { remittanceFilesColumns, transactionsTableColumns } from './utils';
import useGenerateRemittance from 'services/queries/payment-flows/use-generate-remittance';
import { PaymentFlowTransaction, PaymentFlowTransactionStatus } from 'types/models/payment-flow';
import useCheckTransactionEligility from 'services/queries/payment-flows/use-check-transaction-eligility';
import useGetDownloadFile from 'services/queries/files/use-get-download-file';
import DatatableToolbar from 'components/core/table/datatable/components/toolbar';
import { getDataTableSelectedIndexes } from 'utils/get-data-table-selected-indexes';
import { useState } from 'react';
import useDeletePaymentFlowTransactions from 'services/queries/payment-flows/use-delete-payment-flow-transactions';
import PaymentFlowModalConfirmation, { ModalConfirmationPayload } from './modal-confirmation';
import useTransferPaymentFlowTransactions from 'services/queries/payment-flows/use-transfer-payment-flow';
import useToast from 'hooks/toast/use-toast';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import RemittanceDetails from './remittance-details';

export type PaymentFlowAction = 'delete' | 'transfer';

const PaymentFlowPage = () => {
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const [selectedRowsWithData, setSelectedRowsWithData] = useState<PaymentFlowTransaction[]>([]);
    const [action, setAction] = useState<PaymentFlowAction | null>(null);
    const [remittanceToShowId, setRemittanceToShowId] = useState<number | null>(null);

    const { paymentFlowId } = useParams();

    const { mutateAsync: getFileToDownload, isLoading: isDownloadingFile } = useGetDownloadFile();
    const { mutateAsync: checkTransactionEligility, isLoading: isLoadingTransactionEligibility } = useCheckTransactionEligility(paymentFlowId);
    const { data: paymentFlow, isLoading: isLoadingPaymentFlow, error } = useGetPaymentFlow(paymentFlowId);
    const { mutateAsync: generateRemittance, isLoading: isGeneratingFile } = useGenerateRemittance(paymentFlowId);
    const { mutateAsync: deletePaymentFlowTransactions, isLoading: isDeletingPaymentFlowTransactions } = useDeletePaymentFlowTransactions(paymentFlowId);
    const { mutateAsync: transferPaymentFlowTransactions, isLoading: isTransferingPaymentFlowTransactions } = useTransferPaymentFlowTransactions(paymentFlowId);

    const isSubmitting = isDeletingPaymentFlowTransactions || isTransferingPaymentFlowTransactions;
    const isSubmittingEligibityOrRemittance = isLoadingTransactionEligibility || isGeneratingFile;

    const { showToast } = useToast();

    const handleCheckEligibility = (indexes: number[], type: 'eligibility' | 'remittance') => async () => {
        try {
            const arr: PaymentFlowTransaction[] = [];
            const txs = paymentFlow?.paymentFlowTransactions || [];

            if (!indexes.length) {
                return showToast('Você deve selecionar ao menos uma transação!', 'warning');
            }

            for (const ix of indexes) {
                arr.push(txs[ix]);
            }

            const hasEligibleItems = arr.some((item) => item.eligibleForRemittance);

            if (hasEligibleItems && type === 'eligibility') {
                return showToast('Você selecionou transações que já estão elegíveis', 'warning');
            }

            const hasPaymentFormDifferentOfPix = arr.some((item) => item.transaction.expense?.paymentForm?.name !== 'PIX');

            if (hasPaymentFormDifferentOfPix) {
                return showToast('Você selecionou transações com formas de pagamento diferente de PIX', 'warning');
            }

            const txsIds = arr.map((item) => item.id);

            if (type === 'eligibility') {
                await checkTransactionEligility({ transactions: txsIds });
            }

            if (type === 'remittance') {
                await generateRemittance({ paymentFlowTransactions: txsIds });
            }
        } catch (error) {
            console.log('handleCheckEligibility', error);
        }
    };

    const handleOpenModalActions = (indexes: number[], selectedRows: any, action: PaymentFlowAction) => {
        const arr: PaymentFlowTransaction[] = [];
        const paymentFlowTransaction = paymentFlow?.paymentFlowTransactions || [];

        for (const ix of indexes) {
            arr.push(paymentFlowTransaction[ix]);
        }

        const hasDeletePaymentFlowRow = arr.some((item) => item.paymentFlowTransactionStatus === PaymentFlowTransactionStatus.Deleted);

        if (hasDeletePaymentFlowRow) {
            return showToast('Você selecionou transações que estão excluídas.', 'warning');
        }

        const selectedRowsIndex = selectedRows.data.map((item) => item.index);

        setSelectedRowsWithData(arr);
        setAction(action);
        setSelectedRows(selectedRowsIndex);
    };

    const handleCloseActionsFlows = () => {
        setAction(null);
        setSelectedRowsWithData([]);
        setSelectedRows([]);
    };

    const submit = async (data: ModalConfirmationPayload) => {
        try {
            const transactions = selectedRowsWithData.map((item) => item.id);

            if (action === 'delete') {
                await deletePaymentFlowTransactions({ transactions });
            }

            if (action === 'transfer') {
                await transferPaymentFlowTransactions({ transactions, provision: formatDate(data.provision, 'YYYY-MM-DD') });
            }

            handleCloseActionsFlows();
        } catch (e) {
            console.error('submit - paymentFlow: ', e);
        }
    };

    const handleChangeTab = (_: any, nextSlug: any) => setActiveTabIndex(nextSlug);

    const handleDownloadFile = async () => {
        try {
            const [file] = paymentFlow?.files || [];

            if (!file?.file?.id) {
                return;
            }

            await getFileToDownload(file.file.id);
        } catch (error) {
            console.log('handleDownloadFile', error);
        }
    };

    if (isLoadingPaymentFlow) {
        return (
            <div className="relative w-full h-full">
                <Spinner />
            </div>
        );
    }

    if (Boolean(error) || !paymentFlow) {
        return <Empty title="Cliente não encontrado" />;
    }

    return (
        <Modal
            headerLeft={
                <Text variant="h4" className="text-heading">
                    Fluxo de pagamento
                </Text>
            }
            headerRight={
                Boolean(paymentFlow.files.length) ? (
                    <Button disabled={isDownloadingFile} loading={isDownloadingFile} onClick={handleDownloadFile}>
                        Baixar arquivo
                    </Button>
                ) : undefined
            }
            contentClassnames="min-w-[1024px]"
            closeOnClickOutside={false}>
            <div className="border-t border-t-base-300">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 border-b border-b-base-300">
                    <div className="py-4 px-6 flex flex-1 items-center justify-between flex-wrap border-r border-r-base-300">
                        <div>
                            <Text as="p" className="text-base-500 font-normal text-sm mb-2">
                                Provisão
                            </Text>
                            <Text as="strong" className="font-medium text-xl text-heading flex-1 mb-3">
                                {formatDate(paymentFlow.provision)}
                            </Text>
                        </div>
                    </div>
                    <div className="border-r border-r-base-300">
                        <div className="py-4 px-6 border-b border-b-base-300 flex gap-2 items-baseline justify-between">
                            <Text className="text-base-500 font-normal text-sm">
                                Empresa: <strong className="text-heading">{paymentFlow.companyBankAccount.company?.name}</strong>
                            </Text>
                            <Text className="text-base-500 font-normal text-sm">
                                Banco: <strong className="text-heading">{(paymentFlow as any).companyBankAccount.bank?.name}</strong>
                            </Text>
                        </div>
                        <div className="py-2 px-6 flex items-baseline justify-between">
                            <Text className="text-base-500 font-normal text-sm">
                                Conta:{' '}
                                <strong className="text-heading">
                                    {(paymentFlow as any).companyBankAccount.account}-{(paymentFlow as any).companyBankAccount.accountDigit}
                                </strong>
                            </Text>
                            <Text className="text-base-500 font-normal text-sm">
                                Ag:{' '}
                                <strong className="text-heading">
                                    {(paymentFlow as any).companyBankAccount.agency}-{(paymentFlow as any).companyBankAccount.agencyDigit}
                                </strong>
                            </Text>
                        </div>
                    </div>
                    <div className="py-4 px-6 flex items-center justify-between flex-wrap border-r border-r-base-300">
                        <div>
                            <Text as="p" className="text-base-500 font-normal text-sm mb-2">
                                Total pago
                            </Text>
                            <Text as="strong" className="font-medium text-xl text-heading flex-1 mb-3">
                                {formatMoney(Number(paymentFlow.paidTotal))}
                            </Text>
                        </div>
                    </div>
                    <div className="py-4 px-6 flex items-center justify-between flex-wrap">
                        <div>
                            <Text as="p" className="text-base-500 font-normal text-sm mb-2">
                                Total despesa
                            </Text>
                            <Text as="strong" className="font-medium text-xl text-heading flex-1 mb-3">
                                {formatMoney(Number(paymentFlow.total))}
                            </Text>
                        </div>
                    </div>
                </div>
                <Tabs variant="scrollable" value={activeTabIndex} onChange={handleChangeTab}>
                    <Tab label="ORDENS DE PAGAMENTOS" className="text-base py-5" />
                    <Tab label="REMESSAS" className="text-base py-5" />
                </Tabs>
                {activeTabIndex === 0 && (
                    <>
                        <Datatable
                            data={paymentFlow?.paymentFlowTransactions || []}
                            columns={transactionsTableColumns}
                            options={{
                                elevation: 0,
                                enableNestedDataAccess: '.',
                                filter: false,
                                searchAlwaysOpen: false,
                                searchOpen: false,
                                pagination: false,
                                download: false,
                                filterArrayFullMatch: false,
                                print: false,
                                viewColumns: false,
                                search: false,
                                serverSide: true,
                                selectableRowsOnClick: true,
                                rowsSelected: selectedRows,
                                customToolbarSelect: (selectedRows) => {
                                    const indexes = getDataTableSelectedIndexes(selectedRows);

                                    return (
                                        <DatatableToolbar
                                            content={[
                                                {
                                                    label: 'Eleger para remessa',
                                                    onClick: handleCheckEligibility(indexes, 'eligibility'),
                                                },
                                                {
                                                    label: 'Gerar remessas',
                                                    onClick: handleCheckEligibility(indexes, 'remittance'),
                                                },
                                                {
                                                    label: 'Transferir de fluxo',
                                                    onClick: handleOpenModalActions.bind(this, indexes, selectedRows, 'transfer'),
                                                },
                                                {
                                                    label: 'Excluir fluxos',
                                                    onClick: handleOpenModalActions.bind(this, indexes, selectedRows, 'delete'),
                                                },
                                            ]}
                                        />
                                    );
                                },
                            }}
                            hideFooter={true}
                        />
                        {isSubmittingEligibityOrRemittance && <Spinner fixed={true} />}
                    </>
                )}
                {activeTabIndex === 1 && (
                    <Datatable
                        hideFooter={true}
                        options={{
                            elevation: 0,
                            enableNestedDataAccess: '.',
                            filter: false,
                            searchAlwaysOpen: false,
                            searchOpen: false,
                            pagination: false,
                            download: false,
                            filterArrayFullMatch: false,
                            print: false,
                            viewColumns: false,
                            search: false,
                        }}
                        data={paymentFlow?.files || []}
                        columns={remittanceFilesColumns(setRemittanceToShowId)}
                    />
                )}
                {Boolean(selectedRowsWithData.length) && (
                    <PaymentFlowModalConfirmation action={action} isSubmitting={isSubmitting} onClose={handleCloseActionsFlows} onSubmit={submit} items={selectedRowsWithData} />
                )}
                {Boolean(remittanceToShowId) && <RemittanceDetails onClose={setRemittanceToShowId.bind(this, null)} remittanceId={remittanceToShowId} />}
            </div>
        </Modal>
    );
};

export default PaymentFlowPage;
