import { useQuery } from 'react-query';
import api from 'services/api';
import qs from 'qs';
import useToast from 'hooks/toast/use-toast';
import { removeFalsyValues } from 'utils/object';
import { getPaginatedWithFiltersKey } from './keys';
import { Pagination } from 'types/pagination';
import useConfig from 'store/config/use-config';

export type GetPaginatedWithFiltersParams = {
    endpoint: string;
    filters?: any;
    page: number;
    orderBy?: any;
    [key: string]: any;
};

const useGetPaginatedWithFilters = <T>({ page = 1, endpoint, filters, orderBy, ...params }: GetPaginatedWithFiltersParams) => {
    const { config } = useConfig();
    const { showToast } = useToast();

    const request = async () => {
        const formattedFilters = removeFalsyValues(filters || {});

        const queryParams = {
            page,
            filter: formattedFilters,
            totalPerPage: config.tables.rowsPerPage,
            ...(Boolean(orderBy) && { orderBy }),
            ...params,
        };

        const query = qs.stringify(queryParams, { addQueryPrefix: true, encode: false, arrayFormat: 'brackets' });

        const { data } = await api.get<Pagination<T>>(endpoint + query);

        return data;
    };

    return useQuery(getPaginatedWithFiltersKey(endpoint, filters, page, config.tables.rowsPerPage, orderBy), request, {
        enabled: true,
        onError: () => {
            showToast(`Ocorreu um erro ao obter a listagem. Tente novamente.`, 'danger');
        },
    });
};

export default useGetPaginatedWithFilters;
