import { useQuery } from 'react-query';
import api from 'services/api';
import { getImportFlowKey } from './keys';
import { ImportFlow } from 'types/models/import-flow';

const useGetImportFlow = (importFlowId?: string) => {
    const request = async () => {
        const { data } = await api.get<ImportFlow>(`/imports/${importFlowId}`);

        return data;
    };

    return useQuery(getImportFlowKey(importFlowId), request, {
        enabled: Boolean(importFlowId),
    });
};

export default useGetImportFlow;
