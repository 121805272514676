import Datatable from 'components/core/table/datatable';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Text from 'components/core/text';
import { serviceOrderExpensesAdministrativeListColumns } from 'pages/private/sales-orders/utils';
import useGetPaginatedWithFilters from 'services/queries/general/use-get-paginated-with-filters';
import { OrderServicesExpensesResponse } from './expenses';

const ServiceOrderExpensesAdministrative = () => {
    const { orderServiceId, saleOrderId } = useParams();

    const endpoint = `/sale-orders/${saleOrderId}/service-orders/${orderServiceId}/expenses`;

    const [page, setPage] = useState(1);

    const { data, isLoading: isLoadingList } = useGetPaginatedWithFilters<OrderServicesExpensesResponse>({
        endpoint,
        page,
        type: 'administrative',
    });

    const { items = [] } = data || {};

    return (
        <div>
            <div className="flex items-center mb-6 flex-row justify-between">
                <Text as="h3" variant="h5" className="text-heading mr-1">
                    Despesas Administrativas
                </Text>
            </div>
            <Datatable
                withBorder={false}
                data={items || []}
                options={{
                    sort: true,
                    filter: false,
                    search: false,
                    print: false,
                    download: false,
                    viewColumns: false,
                    serverSide: true,
                    searchAlwaysOpen: false,
                    selectableRows: 'none',
                    enableNestedDataAccess: '.',
                    pagination: true,
                    elevation: 21,
                    count: data?.total,
                    page,
                    onChangePage: (currentPage) => {
                        setPage(currentPage);
                    },
                }}
                columns={serviceOrderExpensesAdministrativeListColumns}
                loading={isLoadingList}
                className="toolbar-without-search"
            />
        </div>
    );
};

export default ServiceOrderExpensesAdministrative;
