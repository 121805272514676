import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import { getPaymentFlowKey } from './keys';
import { ModalConfirmationPayload } from 'pages/private/payment-flow/view/modal-confirmation';

const useTransferPaymentFlowTransactions = (paymentFlowId?: string) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const request = async (payload: ModalConfirmationPayload) => api.put(`/payment-flows/${paymentFlowId}/transactions`, payload);

    return useMutation(request, {
        onSuccess: () => {
            showToast('Os fluxos selecionados foram transferidos com sucesso!', 'success');
            queryClient.invalidateQueries(getPaymentFlowKey(paymentFlowId));
        },
        onError: () => {
            showToast('Ocorreu um erro ao tentar transferir os fluxos selecionados, por favor, tente novamente.');
        },
    });
};

export default useTransferPaymentFlowTransactions;
