import { useMemo } from 'react';
import useTheme from '@mui/material/styles/useTheme';
import { generateFileUrl } from 'utils/file';
import Avatar from '@mui/material/Avatar/Avatar';
import { CardHeader } from '@mui/material';
import { Option } from 'types/general';
import { generateAddress, hexToRgba } from 'utils/string';
import useCustomerDetails from './hooks/use-customer-details';
import { PersonContractType } from 'types/models/person';
import Text from 'components/core/text';
import SvgIcoMail from 'components/core/icon/files/ico-mail';
import SvgIcoPhone from 'components/core/icon/files/ico-phone';

const CustomerTabDetails = () => {
    const { palette } = useTheme();

    const { title, juridicName, juridicDocument, juridicFantasyName, file, addressCustomers = [], emails, phones, type, document } = useCustomerDetails();

    const amounts = useMemo(
        () => [
            {
                label: 'E-mail(s)',
                value: emails?.map(({ email }) => email).join(', ') || '-',
                Icon: SvgIcoMail,
            },
            {
                label: 'Telefone(s)',
                value: phones?.map(({ ddd, number }) => `(${ddd}) ${number}`).join(', ') || '-',
                Icon: SvgIcoPhone,
            },
        ],
        [emails, phones]
    );

    const details: Option<any>[] = [
        {
            label: 'Título',
            value: title,
        },
        ...(type === PersonContractType.Personal ? [{ label: 'CPF', value: document }] : []),
        ...(type === PersonContractType.Juridic
            ? [
                  {
                      label: 'Nome jurídico',
                      value: juridicName,
                  },
                  {
                      label: 'Nome fantasia',
                      value: juridicFantasyName,
                  },
                  {
                      label: 'CNPJ',
                      value: juridicDocument,
                  },
              ]
            : []),
    ];

    const formattedAddresses = useMemo(
        () =>
            addressCustomers
                .filter(({ address }) => !!address)
                .map(({ address }, index) => {
                    return (
                        <Text as="p" className="text-heading" key={`address_${index}`}>
                            {generateAddress(address)}
                        </Text>
                    );
                }),
        [addressCustomers]
    );

    return (
        <div>
            <div className="mb-7">
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: palette.grey[200], borderRadius: '14px !important', width: 48, height: 48 }} aria-label="recipe">
                            {!!file?.filename ? <img src={generateFileUrl(file?.filename, file?.path)} /> : <span className="uppercase text-base-500">{title?.charAt(0)}</span>}
                        </Avatar>
                    }
                    classes={{ root: 'p-0' }}
                    title={title}
                    subheader={juridicName}
                    titleTypographyProps={{ fontSize: 18, color: palette.heading, fontWeight: 500 }}
                />
            </div>
            <div className="grid grid-cols-1 mb-6 md:grid-cols-3">
                {amounts.map(({ value, label, Icon }, index) => (
                    <CardHeader
                        key={`${label}-${index}`}
                        avatar={
                            <Avatar sx={{ bgcolor: hexToRgba(palette.info[100], 0.2), borderRadius: '14px !important', width: 48, height: 48 }} aria-label="recipe">
                                <Icon color={palette.info[500]} />
                            </Avatar>
                        }
                        classes={{ root: 'p-0 mb-2 md:mb-0' }}
                        title={label}
                        subheader={value}
                        titleTypographyProps={{ color: palette.grey[500] }}
                        subheaderTypographyProps={{ color: palette.heading }}
                    />
                ))}
            </div>
            <Text as="p" className="uppercase mb-3 text-gray-600 !text-base">
                Detalhes
            </Text>
            <div className="grid grid-cols-2 mb-10 gap-4 md:grid-cols-4">
                {details.map(({ label, value }, index) => (
                    <div className="flex flex-col" key={`detail-${index}`}>
                        <Text as="p" className="mb-1 text-gray-500 !text-base">
                            {label}
                        </Text>
                        <Text as="p" className="text-sm text-heading">
                            {value}
                        </Text>
                    </div>
                ))}
            </div>
            {Boolean(formattedAddresses) && (
                <>
                    <Text as="p" className="uppercase mb-3 text-gray-600 !text-base">
                        Endereço{!!formattedAddresses?.length && 's'}
                    </Text>
                    <div className="mb-10 gap-4 text-sm">{formattedAddresses}</div>
                </>
            )}
        </div>
    );
};

export default CustomerTabDetails;
