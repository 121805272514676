import classNames from 'classnames';
import TruncatedText from 'components/core/text/truncated-text';
import { PropsWithChildren } from 'react';

type DatatableRowColumnProps = {
    className?: string;
    isTruncatedColumn?: boolean;
    withNoWrapWhitespace?: boolean;
    dataId?: number;
};

const DatatableRowColumn = ({ className, isTruncatedColumn = false, withNoWrapWhitespace = true, dataId, children }: PropsWithChildren<DatatableRowColumnProps>) => {
    if (isTruncatedColumn && !!dataId) {
        return <TruncatedText dataId={dataId}>{children}</TruncatedText>;
    }

    return <div className={classNames(withNoWrapWhitespace && 'whitespace-nowrap', className)}>{children}</div>;
};

export default DatatableRowColumn;
