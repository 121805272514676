import { memo, useState } from 'react';
import classnames from 'classnames';
import ReportPageDetailsBlockTableBodyItem from './item';
import ReportPageDetailsBlockTableBodyHeader from './header';
import { TableItem } from 'types/models/reports';

type DRETableBodyProps = {
    items: TableItem[];
    numberOfCols: number;
};

const ReportPageDetailsBlockTableBody = ({ items, numberOfCols }: DRETableBodyProps) => {
    const borderColor = `border-slate-100`;
    const descriptionItem = classnames(`dre-description-item pl-5 p-2 break-words`);

    const tbody = classnames('border-b-2', borderColor);

    const getInitialClosedItems = () => {
        return (items?.map(({ isClosed = false }, index) => (isClosed ? index : null)).filter((i) => i !== null) as number[]) || [];
    };

    const [closedItems, setClosedItems] = useState<number[]>(getInitialClosedItems());

    const toggleItem = (index: number) => () => setClosedItems((old) => (old.includes(index) ? old.filter((i) => i !== index) : [...old, index]));

    const hasToggleButton = false;

    return (
        <>
            {items.map((item, index) => {
                const isClosed = closedItems.includes(index);

                return (
                    <tbody className={tbody} key={`${item.title}-${index}`}>
                        <ReportPageDetailsBlockTableBodyHeader
                            hasToggleButton={hasToggleButton}
                            headers={item?.headers || []}
                            onToggle={toggleItem(index)}
                            title={item?.title || ''}
                            numberOfCols={numberOfCols}
                            isClosed={isClosed}
                        />

                        {isClosed
                            ? null
                            : item?.values?.map((item, i) => {
                                  const { values, title, className, customBodyComponent } = item;

                                  if (customBodyComponent) {
                                      return customBodyComponent(values);
                                  }

                                  const remainingColumns = numberOfCols - values?.length > 0 ? [...new Array(numberOfCols - values?.length).keys()] : [];

                                  return (
                                      <tr className="dre-tr" key={`${title}_${item.title}_${i}`}>
                                          {Boolean(title) && <td className={classnames(descriptionItem, className)}>{title}</td>}
                                          {values.map((value, index) => {
                                              const key = `tr_${title}_${item.title}_${value.value}_${index}`;

                                              return (
                                                  <ReportPageDetailsBlockTableBodyItem
                                                      className={classnames(className, value.className, !title && i === 0 ? 'rounded-tl-lg' : '')}
                                                      {...value}
                                                      key={key}
                                                  />
                                              );
                                          })}

                                          {Boolean(hasToggleButton) &&
                                              remainingColumns.map((indexBlank) => (
                                                  <ReportPageDetailsBlockTableBodyItem value={0} type="blank" key={`${title}_${item.title}_blank_${index}_${indexBlank}`} />
                                              ))}
                                      </tr>
                                  );
                              })}
                    </tbody>
                );
            })}
        </>
    );
};

export default memo(ReportPageDetailsBlockTableBody);
