import React, { createContext, useContext, useState } from 'react';
import { GraphqlPaginationVariables } from 'types/graphql';

type ListPageContextState = {
    params?: GraphqlPaginationVariables<any>;
    hasSelectedFilters?: boolean;
    setHasSelectedFilters?: React.Dispatch<React.SetStateAction<boolean>>;
    onChangeParams(data: any): void;
    getParam(name: string): any;
    onChangeParam(data: { name: string; value: any }): void; // Util to update a single param
};

/**
 * This context will help us manage the state of the table's params
 */
const ListPageContext = createContext<ListPageContextState>({
    params: undefined,
    hasSelectedFilters: false,
    onChangeParams: () => {},
    getParam: () => {},
    onChangeParam: () => {},
});

export const useListPageContext = () => {
    return useContext(ListPageContext);
};

export const ListPageContextProvider = ({ children, ...rest }: { children: React.ReactNode } & ListPageContextState) => {
    const [hasSelectedFilters, setHasSelectedFilters] = useState(false);

    return <ListPageContext.Provider value={{ ...rest, hasSelectedFilters, setHasSelectedFilters }}>{children}</ListPageContext.Provider>;
};
