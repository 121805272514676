import useToast from 'hooks/toast/use-toast';
import { useMutation } from 'react-query';
import api from 'services/api';

const useChangeOrderServiceStatus = (saleOrderId?: string, orderServiceId?: string) => {
    const { showToast } = useToast();

    const request = async (status: string) => api.patch(`/sale-orders/${saleOrderId}/service-orders/${orderServiceId}/status`, { status });

    return useMutation(request, {
        onSuccess: () => {
            showToast('Escopo enviado para validação com sucesso', 'success');
        },
        onError: () => {
            showToast('Ocorreu um erro ao enviar o escopo para validação', 'danger');
        }
    });
};

export default useChangeOrderServiceStatus;
