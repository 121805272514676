import { DocumentPayload } from 'components/documents';
import useToast from 'hooks/toast/use-toast';
import { useMutation } from 'react-query';
import api from 'services/api';

const useCreateOrUpdateTransactionDocument = (transactionId?: string, documentId?: string) => {
    const { showToast } = useToast();

    const request = async (payload: Partial<DocumentPayload>) => {
        const endpoint = Boolean(documentId) ? `/transactions/${transactionId}/documents/${documentId}` : `/transactions/${transactionId}/documents`;
        const method = Boolean(documentId) ? api.patch : api.post;

        return await method(endpoint, payload);
    };

    return useMutation(request, {
        onSuccess: () => {
            showToast(`Documento ${Boolean(documentId) ? 'atualizado' : 'criado'} com sucesso!`, 'success');
        },
        onError: () => {
            showToast(`Ocorreu um erro ao ${Boolean(documentId) ? 'atualizar' : 'criar'} o documento, por favor, tente novamente.`, 'danger');
        },
    });
};

export default useCreateOrUpdateTransactionDocument;
