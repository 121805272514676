import TableCell from '@mui/material/TableCell';
import Badge from 'components/core/badge';
import Text from 'components/core/text';
import { DatatableColumn } from 'types/graphql';
import { ResponseBankAccount } from 'types/models/bank-accounts';
import { TransactionType } from 'types/models/transaction';
import { formatDate } from 'utils/date';
import dictionary from 'utils/dictionary';
import { formatMoney } from 'utils/money';
import { statuses } from 'utils/statics';

const transactionTypesWithColor = [
    {
        label: 'Entrada',
        value: TransactionType.Income,
        color: 'success',
    },
    {
        label: 'Saída',
        value: TransactionType.Outcome,
        color: 'error',
    },
    {
        label: 'Aportes',
        value: TransactionType.Contribution,
        color: 'info',
    },
];

export const columnDatatableTransactions: DatatableColumn[] = [
    {
        name: 'payday',
        label: dictionary.content.graphql.payday,
        options: {
            customBodyRender: (value: any) => (Boolean(value) ? formatDate(value) : '-'),
        },
    },
    {
        name: 'code',
        label: dictionary.content.code,
        options: {
            customBodyRender: (value: any) => value || '-',
        },
    },
    {
        name: '',
        label: 'NF',
        options: {
            customBodyRender: (value: any) => value || '-',
        },
    },
    {
        name: '',
        label: 'Favorecido/Cliente',
        options: {
            customBodyRender: (value: any) => value || '-',
        },
    },
    {
        name: 'title',
        label: dictionary.content.graphql.description,
        options: {
            customBodyRender: (value) => {
                return <Text dangerouslySetInnerHTML={{ __html: value || '-' }} />;
            },
        },
    },
    {
        name: 'observation',
        label: dictionary.content.graphql.observations,
        options: {
            customBodyRender: (value) => {
                return <Text dangerouslySetInnerHTML={{ __html: value || '-' }} />;
            },
        },
    },
    {
        name: 'paymentForm.name',
        label: 'Forma de pagamento',
        options: {
            customBodyRender: (value: any) => value || '-',
        },
    },
    {
        name: '',
        label: 'Referente',
        options: {
            customBodyRender: (value: any) => value || '-',
        },
    },
    {
        name: 'paid',
        label: dictionary.content.graphql.status,
        options: {
            customBodyRender: (value) => {
                return (
                    <Badge className="text-center" variant={value ? 'success' : 'error'}>
                        {value ? 'Pago' : 'Não pago'}
                    </Badge>
                );
            },
        },
    },
    {
        name: 'type',
        label: dictionary.content.graphql.type,
        options: {
            customBodyRender: (value, tableMeta) => {
                const isTransfered = tableMeta.rowData.at(-2);
                const transactionFinderByType = transactionTypesWithColor.find((transaction) => +transaction.value === +value);

                const arrTypes = [transactionFinderByType || {}, ...(isTransfered ? [{ label: 'Transferência', color: 'warning' }] : [])].filter(Boolean);
                return (
                    <div className="inline-flex flex-col items-start gap-2">
                        {arrTypes.map((item, key) => (
                            <Badge key={key} variant={(item.color as any) || 'warning'} className="text-center">
                                {item.label}
                            </Badge>
                        ))}
                    </div>
                );
            },
        },
    },
    {
        name: 'netValue',
        label: 'Valor',
        options: {
            customBodyRender: (value: any) => formatMoney(value || 0),
        },
    },
    {
        name: 'transfer',
        label: 'Transferência',
        options: {
            display: 'excluded',
        },
    },
    {
        name: 'actions',
        customRoutePath: (item) => `transacoes/${item.id}`,
        options: { display: 'excluded' },
    },
];

export const columnDatatableBankAccounts = (showStatus = true): DatatableColumn[] => [
    {
        name: 'bank.name',
        label: 'Banco',
        options: { customBodyRender: (value) => <span className="whitespace-nowrap">{value}</span> },
    },
    {
        name: 'name',
        label: 'Nome',
        options: { customBodyRender: (value) => <span className="whitespace-nowrap">{value}</span> },
    },
    {
        name: 'agency',
        label: dictionary.content.graphql.agency,
        options: {
            customBodyRender: (value: any, tableMeta) => {
                const agencyDigit = tableMeta.rowData[3];
                return `${value}${Boolean(agencyDigit) ? `-${agencyDigit}` : ''}`;
            },
        },
    },
    {
        name: 'agencyDigit',
        label: 'Dígito da agência',
        options: {
            display: 'excluded',
        },
    },
    {
        name: 'account',
        label: dictionary.content.graphql.account,
        options: {
            customBodyRender: (value: any, tableMeta) => {
                const accountDigit = tableMeta.rowData[5];
                return (
                    <Text as="span" className="inline-block min-w-[100px]">
                        {`${value}${Boolean(accountDigit) ? `-${accountDigit}` : ''}`}
                    </Text>
                );
            },
        },
    },
    {
        name: 'accountDigit',
        label: 'Dígito da conta',
        options: {
            display: 'excluded',
        },
    },
    {
        name: 'openingBalance',
        label: 'Saldo Inicial',
        options: {
            customBodyRender: (value: number) => formatMoney(value || 0),
        },
    },

    {
        name: 'totalExpenses',
        label: 'Total Despesas',
        options: {
            customBodyRender: (value: number) => <Text className="text-system-error-500">{formatMoney(value || 0)}</Text>,
        },
    },
    {
        name: 'totalIncomes',
        label: 'Total Receitas',
        options: {
            customBodyRender: (value: number) => <Text className="text-system-success-500">{formatMoney(value || 0)}</Text>,
        },
    },
    {
        name: 'totalContributions',
        label: 'Total Aportes',
        options: {
            customBodyRender: (value: number) => <Text className="text-system-info-500">{formatMoney(value || 0)}</Text>,
        },
    },
    {
        name: 'balance',
        label: 'Saldo Atual',
        options: {
            customBodyRender: (value: number) => {
                const isNegativeValue = value < 0;

                return <Text className={isNegativeValue ? 'text-system-danger-500' : 'text-system-success-500'}>{formatMoney(value || 0)}</Text>;
            },
        },
    },
    {
        name: 'company.name',
        label: 'Empresa',
    },

    {
        name: 'status',
        label: dictionary.content.graphql.status,
        options: {
            sort: false,
            customHeadRender: (value) => (
                <TableCell key={value.label}>
                    <Text variant="body.medium.sm" as="span" className="text-base-500 !text-[15px] !font-medium">
                        {value.label?.toUpperCase()}
                    </Text>
                </TableCell>
            ),
            ...(!showStatus && { display: 'excluded' }),
            customBodyRender: (value) => {
                const finderStatus = statuses.find((status) => status.value === value);

                return (
                    <Badge className="text-center" variant={value ? 'success' : 'error'}>
                        {finderStatus?.label}
                    </Badge>
                );
            },
        },
    },
    {
        name: 'actions',
        label: 'Ações',
        options: {
            customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />,
        },
    },
];

export const cardsDetailsData = (details?: Partial<ResponseBankAccount>) => [
    {
        label: 'Saldo inicial',
        value: details?.openingBalance,
    },
    {
        label: 'Despesas',
        value: details?.totalExpenses,
        valueClassName: '!text-system-danger-500',
    },
    {
        label: 'Receitas',
        value: details?.totalIncome,
        valueClassName: '!text-system-success-500',
    },
    {
        label: 'Aportes',
        value: details?.totalContributions,
        valueClassName: '!text-system-info-500',
    },
    {
        label: 'Saldo',
        value: details?.balance,
        valueClassName: (details?.balance || 0) >= 0 ? '!text-system-success-500' : '!text-system-danger-500',
    },
];

export const mappedOrderByBankAccountsColumns = {
    name: 'bac.name',
    agency: 'bac.agency',
    account: 'bac.account',
    openingBalance: 'bac.openingBalance',
    totalExpenses: 'bac.totalExpenses',
    totalIncomes: 'bac.totalIncome',
    totalContributions: 'bac.totalContributions',
    balance: 'bac.balance',
    ['company.name']: 'c.name',
    ['bank.name']: 'b.name',
};
