import Modal from 'components/core/modal';
import { useParams } from 'react-router-dom';
import useGetStockBalance from 'services/queries/stocks/use-get-stock-balance';
import Text from 'components/core/text';
import useTheme from '@mui/material/styles/useTheme';
import Spinner from 'components/core/spinner';
import Divider from '@mui/material/Divider/Divider';
import { generateDetailsCard } from './utils';
import CardsDetails from 'pages/private/sales-notes/components/cards';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import Table from '@mui/material/Table/Table';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableBody from '@mui/material/TableBody/TableBody';
import { formatMoney } from 'utils/money';
import Empty from 'components/empty';
import ApprovalBadge from 'components/badges/approval';

const BalanceDetailsPage = () => {
    const { palette } = useTheme();
    const { stockId, balanceId } = useParams();

    const { data: stockBalance, isLoading: isLoadingStockBalance } = useGetStockBalance(stockId, balanceId);

    const cardsDetails = generateDetailsCard(stockBalance);

    return (
        <Modal
            headerLeft={
                !isLoadingStockBalance ? (
                    <div className="flex gap-3">
                        <Text as="h3" variant="h5" className="text-heading">
                            {stockBalance?.code || '-'}
                        </Text>
                        <ApprovalBadge status={stockBalance?.approvalStatus} />
                    </div>
                ) : (
                    <div />
                )
            }
            contentClassnames="w-[1000px]">
            {isLoadingStockBalance ? (
                <div className="p-4">
                    <Spinner color={palette.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <>
                    <Divider />
                    <div className="grid grid-cols-1 sm:grid-cols-3">
                        {cardsDetails.map((item, index) => (
                            <CardsDetails variant="details" {...item} key={`stock_balance_card_${index}`} />
                        ))}
                    </div>

                    <div className="rounded-xl border border-gray-200 mx-6 my-4">
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="text-secondary-500">Produto</TableCell>
                                        <TableCell className="text-secondary-500">Valor anterior</TableCell>
                                        <TableCell className="text-secondary-500">QTD anterior</TableCell>
                                        <TableCell className="text-secondary-500">Valor</TableCell>
                                        <TableCell className="text-secondary-500">QTD</TableCell>
                                        <TableCell className="text-secondary-500">Total</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!stockBalance?.products.length ? (
                                        (stockBalance.products || []).map((item, index) => (
                                            <TableRow key={`stock_balance_tbody_row_${index}`} sx={{ '&:last-child td': { borderBottom: 'none' } }}>
                                                <TableCell>{item.stockProduct.product?.name || '-'}</TableCell>
                                                <TableCell>{formatMoney(item.currentValue)}</TableCell>
                                                <TableCell>{item.currentQuantity || 0}</TableCell>
                                                <TableCell>{formatMoney(item.value)}</TableCell>
                                                <TableCell>{item.quantity || 0}</TableCell>
                                                <TableCell>{formatMoney(item.value * (item.quantity || 0))}</TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <Empty title="Nenhum produto cadastrado nesse balanço." className="mb-2" />
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </>
            )}
        </Modal>
    );
};

export default BalanceDetailsPage;
