import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from '@mui/material/TableRow/TableRow';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import Checkbox from 'components/core/form/checkbox';
import CurrencyInput from 'components/core/form/currency';
import Text from 'components/core/text';
import { useFormContext, Controller } from 'react-hook-form';
import { TransactionInvoiceStatus } from 'types/models/transaction';
import { formatMoney } from 'utils/money';
import { DeductionPayload } from './index';

export type OP = {
    id: number;
    checked: boolean;
    code: string;
    dueDate: string;
    expense: {
        companyId: number;
        company: { name: string };
    };
    invoiceStatus: TransactionInvoiceStatus;
    netValue: number;
    grossValue: number;
    classificationByClassificationPaymentFormId: { name: string };
};

type DeductionItemProps = {
    item: Partial<OP>;
    index: number;
};

const DeductionItem = ({ item, index }: DeductionItemProps) => {
    const { control, watch, setValue, setError, clearErrors, formState } = useFormContext<DeductionPayload>();

    const getPath = (key: keyof Partial<OP>) => `ops.${index}.${key}` as const;

    const isChecked = watch(getPath('checked' as any) as any);

    const handleChangeValue = (values) => {
        if (values.floatValue > (item.grossValue || 0)) {
            return setError(getPath('netValue'), { message: 'Valor a deduzir é maior que saldo disponível' });
        } else {
            clearErrors(getPath('netValue'));
        }

        setValue(getPath('netValue'), values.floatValue);
    };

    const handleToggleCheck = () => setValue(getPath('checked'), !isChecked);

    return (
        <TableRow classes={{ root: 'hover:bg-base-200' }} sx={{ '&:last-child td, &:last-child th': { borderBottomWidth: 0 } }}>
            <TableCell className="px-[22px] py-0 w-[60px] border-r border-r-base-300">
                <Checkbox checked={isChecked} onChange={handleToggleCheck} />
            </TableCell>
            <TableCell className="p-0 pl-4 border-r border-r-base-300 pr-4">
                <Text className="text-base-500">{item.code}</Text>
            </TableCell>
            <TableCell className="p-0 pl-4 border-r border-r-base-300 pr-4">
                <Text className="text-base-500">{item.expense?.company?.name}</Text>
            </TableCell>
            <TableCell className="p-0 pl-4 border-r border-r-base-300 pr-4">
                <Text className="text-base-500">{formatMoney(item.grossValue)}</Text>
            </TableCell>
            <TableCell className="p-0 border-r border-r-base-300">
                <Tooltip open={true} placement="top" title={formState.errors.ops?.[index]?.netValue?.message} disableFocusListener={true}>
                    <span>
                        <Controller
                            control={control}
                            name={getPath('netValue') as any}
                            render={({ field }) => {
                                return (
                                    <CurrencyInput
                                        value={field.value}
                                        disabled={!isChecked}
                                        placeholder="Ex: 1000,00"
                                        left={
                                            <Text as="span" variant="body.medium.sm" className="text-heading">
                                                R$
                                            </Text>
                                        }
                                        inputWrapperClasses="border-none rounded-none"
                                        className={`focus:outline-2 -outline-offset-2 outline ${
                                            !!formState.errors.ops?.[index]?.netValue?.message ? 'text-system-danger-500 outline-system-danger-100' : 'outline-primary-100'
                                        }`}
                                        onValueChange={handleChangeValue}
                                    />
                                );
                            }}
                        />
                    </span>
                </Tooltip>
            </TableCell>
            <TableCell className="p-0 pl-4 border-r border-r-base-300 pr-4">
                <Text className="text-base-500">{item.classificationByClassificationPaymentFormId?.name}</Text>
            </TableCell>
            <TableCell className="p-0 pl-4 pr-4">
                <Text className="text-base-500">{item.invoiceStatus === TransactionInvoiceStatus.Sent ? 'com NF' : 'sem NF'}</Text>
            </TableCell>
        </TableRow>
    );
};

export default DeductionItem;
