import Select from 'components/core/form/select';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import FormButtons from 'components/form-buttons';
import { Controller, useForm } from 'react-hook-form';
import { monthsOptions } from 'utils/date';
import { exportFilesYearsOptions } from '../utils';
import useExportBankAccountsFiles, { ExportFilesPayload } from 'services/queries/bank-accounts/use-export-files';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

const ExportFilesPage = () => {
    const { bankAccountId } = useParams();

    const { mutateAsync: exportFiles, isLoading: isExportingFiles } = useExportBankAccountsFiles(bankAccountId);

    const { formState, control, handleSubmit } = useForm<ExportFilesPayload>({
        mode: 'onSubmit',
        defaultValues: {
            year: dayjs().year(),
            month: dayjs().month() + 1,
        },
    });

    const submit = async (data: ExportFilesPayload) => {
        try {
            await exportFiles(data);
        } catch (e) {
            console.error('submit: ', e);
        }
    };

    const handleSelectChange = (onChange: (...event: any[]) => void) => (option: any) => {
        onChange(option.value);
    };

    return (
        <Modal
            headerLeft={
                <Text as="h3" variant="h5" className="text-heading">
                    Exportar arquivos
                </Text>
            }
            closeOnClickOutside={false}
            contentClassnames="w-[700px]">
            <form onSubmit={handleSubmit(submit)} className="p-6 pt-1">
                <Controller
                    control={control}
                    name="year"
                    render={({ field }) => {
                        const value = exportFilesYearsOptions.find((item) => item.value === field.value);

                        return (
                            <Select
                                {...field}
                                value={value}
                                className="mb-4"
                                onChange={handleSelectChange(field.onChange)}
                                options={exportFilesYearsOptions}
                                label="Ano"
                                placeholder="Selecione uma opção"
                                error={formState?.errors?.year?.message}
                            />
                        );
                    }}
                />

                <Controller
                    control={control}
                    name="month"
                    render={({ field }) => {
                        const value = monthsOptions.find((item) => item.value === field.value);

                        return (
                            <Select
                                {...field}
                                value={value}
                                onChange={handleSelectChange(field.onChange)}
                                className="mb-4"
                                options={monthsOptions}
                                label="Meses"
                                error={formState?.errors?.month?.message}
                            />
                        );
                    }}
                />
                <FormButtons isLoading={isExportingFiles} />
            </form>
        </Modal>
    );
};

export default ExportFilesPage;
