import routes from 'settings/routes';
import { matchRoutes, useLocation } from 'react-router-dom';

const useMatchRoutes = (basename?: string) => {
    const location = useLocation();

    return matchRoutes(routes, location, basename);
};

export default useMatchRoutes;
