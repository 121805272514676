import useToast from 'hooks/toast/use-toast';
import { useQuery } from 'react-query';
import api from 'services/api';
import apiGraphQl from 'services/graphql';
import { convertToDateOrReturnUndefined } from 'utils/date';
import { getPersonContractKey } from './keys';

const useGetPersonContract = <T>(props: { contractId?: string; personId?: string; isDetails?: boolean }) => {
    const { contractId, personId, isDetails = false } = props || {};

    const { showToast } = useToast();

    const fetcher = async () => {
        if (isDetails) {
            const { data } = await api.get(`/people/${personId}/contracts/${contractId}`);

            return data;
        }

        // Get data to fill the edit form
        const { contract } = await apiGraphQl<{ contract: any }>(
            `query GetPersonContractById($id: Int!) {
                contract: personContractByPk(id: $id) {
                    finalDate: finalDate
                    juridicDocument: juridicDocument
                    juridicFantasyName: juridicFantasyName
                    juridicName: juridicName
                    startDate: startDate
                    salary
                    position: positionId
                    sector: sectorId
                    type,
                    consultant
                }
              }`,
            { id: contractId }
        );

        return {
            ...contract,
            startDate: convertToDateOrReturnUndefined(contract?.startDate),
            finalDate: convertToDateOrReturnUndefined(contract?.finalDate),
        };
    };

    return useQuery<T>(getPersonContractKey(contractId, isDetails), fetcher, {
        enabled: !!contractId,
        onError: () => {
            showToast('Tivemos um problema ao obter os detalhes desse contrato. Tente novamente.', 'danger');
        },
    });
};

export default useGetPersonContract;
