import { DatatableColumn } from 'types/graphql';
import { array, number, object, string } from 'yup';
import { generateReportDefaultValuePercentage, generateReportValuePercentageData, genericReportSchema } from '../utils';
import { OrderServicesReportTotalsValues } from '../types';
import { getNumberValueOrZero } from 'pages/private/expenses/details/tabs/transactions/utils';
import get from 'lodash/get';
import ExternalButton from 'components/buttons/external';
import { orderServicesBadgesMap } from 'pages/private/sales-orders/utils';
import Badge from 'components/core/badge';
import ReportColumnValuePercentage from '../../components/value-percentage';
import { saleOrderTypes } from 'utils/statics';
import classNames from 'classnames';

export const orderServicesReportColumns = (showValues = true): DatatableColumn[] => {
    return [
        {
            name: 'id',
            options: {
                display: 'excluded',
            },
        },
        {
            name: 'code',
            label: 'Código',
            options: {
                customBodyRender: (value, tableMeta) => {
                    const [id] = tableMeta.rowData;
                    const saleOrderId = tableMeta.rowData.at(-1);

                    return Boolean(value) ? <ExternalButton href={`/app/pedidos-de-venda/${saleOrderId}/ordens-de-servico/${id}`}>#{value}</ExternalButton> : '-';
                },
            },
        },
        {
            name: 'saleOrder.type',
            label: 'Tipo do Pedido',
            options: {
                customBodyRender: (type) => {
                    const findedType = saleOrderTypes.find((item) => item.value === type);

                    return <span className="whitespace-nowrap">{findedType?.label || '-'}</span>;
                },
            },
        },
        {
            name: 'title',
            label: 'Nome',
            options: {
                customBodyRender: (value = '-') => <span className="whitespace-nowrap">{value}</span>,
            },
        },
        {
            name: 'company.name',
            label: 'Empresa',
            options: {
                customBodyRender: (value = '-') => <span className="whitespace-nowrap">{value}</span>,
            },
        },
        {
            name: 'branch.name',
            label: 'Filial',
            options: {
                customBodyRender: (value = '-') => <span className="whitespace-nowrap">{value}</span>,
            },
        },
        {
            label: 'Status',
            name: 'orderServiceStatus.title',
            options: {
                customBodyRender: (orderServiceStatus, tableMeta) => {
                    const orderServiceStatusSlug = tableMeta.rowData.at(-2);
                    const { variant = 'grey', bgOpacity = 0.3 } = orderServicesBadgesMap[orderServiceStatusSlug] || {};

                    return Boolean(orderServiceStatus) ? (
                        <Badge variant={variant} bgOpacity={bgOpacity}>
                            {orderServiceStatus}
                        </Badge>
                    ) : (
                        '-'
                    );
                },
            },
        },
        {
            name: 'receipts.invoice',
            label: 'Receita',
            options: {
                customBodyRender: (value = 0) => {
                    const formattedValue = getNumberValueOrZero(value);

                    return <ReportColumnValuePercentage showValues={showValues} showPercentage={false} value={formattedValue} classes="text-system-success-500" />;
                },
            },
        },
        {
            name: 'totalBudget',
            label: 'Custo Original',
            options: {
                customBodyRender: (value = 0, tableMeta) => {
                    const formattedValue = getNumberValueOrZero(value);

                    const { columnIndex, rowData } = tableMeta;
                    const percentage = rowData[columnIndex + 1]; // get the next column value (percentage)

                    return <ReportColumnValuePercentage showValues={showValues} value={formattedValue} percentage={percentage} classes="text-system-danger-500" />;
                },
            },
        },
        {
            name: 'totalBudgetPercentage',
            options: {
                display: 'excluded',
            },
        },
        {
            name: 'results.potential',
            label: 'Custo Atualizado',
            options: {
                customBodyRender: (value = 0, tableMeta) => {
                    const formattedValue = getNumberValueOrZero(value);

                    const { columnIndex, rowData } = tableMeta;
                    const percentage = rowData[columnIndex + 1]; // get the next column value (percentage)

                    return <ReportColumnValuePercentage showValues={showValues} value={formattedValue} percentage={percentage} classes="text-system-danger-500" />;
                },
            },
        },
        {
            name: 'results.potentialPercentage',
            options: {
                display: 'excluded',
            },
        },
        {
            name: 'results.currentRealized',
            label: 'Diferença',
            options: {
                customBodyRender: (value = 0, tableMeta) => {
                    const formattedValue = getNumberValueOrZero(value);

                    const { columnIndex, rowData } = tableMeta;
                    const percentage = rowData[columnIndex + 1]; // get the next column value (percentage)

                    const spanClassByValue = formattedValue <= 0 ? 'text-system-danger-500' : 'text-system-success-500';

                    return <ReportColumnValuePercentage showValues={showValues} value={formattedValue} percentage={percentage} classes={spanClassByValue} />;
                },
            },
        },
        {
            name: 'results.currentRealizedPercentage',
            options: {
                display: 'excluded',
            },
        },
        {
            name: 'balance.tax',
            label: 'Imposto',
            options: {
                customBodyRender: (value = 0, tableMeta) => {
                    const formattedValue = getNumberValueOrZero(value);

                    const { columnIndex, rowData } = tableMeta;
                    const percentage = rowData[columnIndex + 1]; // get the next column value (percentage)

                    return <ReportColumnValuePercentage showValues={showValues} value={formattedValue} percentage={percentage} classes="text-system-danger-500" />;
                },
            },
        },
        {
            name: 'balance.taxPercentage',
            options: { display: 'excluded' },
        },
        {
            name: 'balance.commission',
            label: 'Comissão',
            options: {
                customBodyRender: (value = 0, tableMeta) => {
                    const formattedValue = getNumberValueOrZero(value);

                    const { columnIndex, rowData } = tableMeta;
                    const percentage = rowData[columnIndex + 1]; // get the next column value (percentage)

                    return <ReportColumnValuePercentage showValues={showValues} value={formattedValue} percentage={percentage} classes="text-system-danger-500" />;
                },
            },
        },
        {
            name: 'balance.commissionPercentage',
            options: { display: 'excluded' },
        },
        {
            name: 'balance.netValue.projected',
            label: 'Saldo Líquido',
            options: {
                customBodyRender: (value = 0, tableMeta) => {
                    const formattedValue = getNumberValueOrZero(value);

                    const { columnIndex, rowData } = tableMeta;
                    const percentage = rowData[columnIndex + 1]; // get the next column value (percentage)

                    const spanClassByValue = formattedValue <= 0 ? 'text-system-danger-500' : 'text-system-success-500';

                    return <ReportColumnValuePercentage showValues={showValues} value={formattedValue} percentage={percentage} classes={spanClassByValue} />;
                },
            },
        },
        {
            name: 'balance.netValue.projectedPercentage',
            options: {
                display: 'excluded',
            },
        },
        {
            name: 'balance.netValue.current',
            label: 'Saldo Líquido Atualizado',
            options: {
                customBodyRender: (value = 0, tableMeta) => {
                    const formattedValue = getNumberValueOrZero(value);

                    const { columnIndex, rowData } = tableMeta;
                    const percentage = rowData[columnIndex + 1]; // get the next column value (percentage)

                    const spanClassByValue = formattedValue <= 0 ? 'text-system-danger-500' : 'text-system-success-500';

                    return <ReportColumnValuePercentage showValues={showValues} value={formattedValue} percentage={percentage} classes={spanClassByValue} />;
                },
            },
        },
        {
            name: 'balance.netValue.currentPercentage',
            options: {
                display: 'excluded',
            },
        },
        {
            name: 'orderServiceStatus.slug',
            options: {
                display: 'excluded',
            },
        },
        {
            name: 'saleOrder.id',
            options: {
                display: 'excluded',
            },
        },
    ];
};

export const INITIAL_ORDER_SERVICES_REPORT_TOTALS = {
    receipt: generateReportDefaultValuePercentage(false),
    originalBudget: generateReportDefaultValuePercentage(),
    updatedBudget: generateReportDefaultValuePercentage(),
    balance: generateReportDefaultValuePercentage(),
    updatedBalance: generateReportDefaultValuePercentage(),
    contractValue: generateReportDefaultValuePercentage(),
    contractBalanceValue: generateReportDefaultValuePercentage(false),
    difference: generateReportDefaultValuePercentage(),
    tax: generateReportDefaultValuePercentage(),
    commission: generateReportDefaultValuePercentage(),
};

export const generateReportsPercentageValue = (baseValue = 0) => {
    return (value = 0) => {
        const percentage = (value / baseValue) * 100;

        return getNumberValueOrZero(percentage);
    };
};

const orderServicesReportAcessors = [
    'receipts.invoice',
    'totalBudget',
    'results.potential',
    'balance.netValue.projected',
    'balance.netValue.current',
    null,
    null,
    'results.currentRealized',
    'balance.tax',
    'balance.commission',
];

const INITIAL_ORDER_SERVICES_REPORT_TOTALS_VALUES = {
    receipt: 0,
    originalBudget: 0,
    updatedBudget: 0,
    balance: 0,
    updatedBalance: 0,
    contractValue: 0,
    contractBalanceValue: 0,
    difference: 0,
    tax: 0,
    commission: 0,
};

export const generateOrderServicesReportTotals = <T,>(orderServicesReports: T[], acessors = orderServicesReportAcessors): OrderServicesReportTotalsValues => {
    const [
        receiptAcessor,
        originalBudgetAcessor,
        updatedBudgetAcessor,
        balanceAcessor,
        updatedBalanceAcessor,
        contractValueAcessor,
        contractBalanceValueAcessor,
        differenceAcessor,
        taxAccessor,
        commissionAcessor,
    ] = acessors;

    const getValueByAcessor = (curr: T) => (acessor: string | null) => getNumberValueOrZero(get(curr, acessor!));

    const { receipt, originalBudget, updatedBudget, balance, updatedBalance, contractValue, contractBalanceValue, difference, tax, commission } = orderServicesReports.reduce((acc, curr) => {
        const getReportValue = getValueByAcessor(curr);

        return {
            receipt: acc.receipt + getReportValue(receiptAcessor),
            originalBudget: acc.originalBudget + getReportValue(originalBudgetAcessor),
            updatedBudget: acc.updatedBudget + getReportValue(updatedBudgetAcessor),
            balance: acc.balance + getReportValue(balanceAcessor),
            updatedBalance: acc.updatedBalance + getReportValue(updatedBalanceAcessor),
            contractValue: acc.contractValue + getReportValue(contractValueAcessor),
            contractBalanceValue: acc.contractBalanceValue + getReportValue(contractBalanceValueAcessor),
            difference: acc.difference + getReportValue(differenceAcessor),
            tax: acc.tax + getReportValue(taxAccessor),
            commission: acc.commission + getReportValue(commissionAcessor),
        };
    }, INITIAL_ORDER_SERVICES_REPORT_TOTALS_VALUES);

    const generatePercentage = generateReportsPercentageValue(receipt);
    const generateDifferencePercentage = generateReportsPercentageValue(originalBudget);

    return orderServicesReports.reduce((acc, curr) => {
        const getReportValue = getValueByAcessor(curr);

        const totalsOriginalBudgetValue = acc.originalBudget.value + getReportValue(originalBudgetAcessor);
        const totalsUpdatedBudgetValue = acc.updatedBudget.value + getReportValue(updatedBudgetAcessor);
        const totalsBalanceValue = acc.balance.value + getReportValue(balanceAcessor);
        const totalsUpdatedBalanceValue = acc.updatedBalance.value + getReportValue(updatedBalanceAcessor);
        const totalsContractValue = acc.contractValue.value + getReportValue(contractValueAcessor);
        const totalsContractBalanceValue = acc.contractBalanceValue.value + getReportValue(contractBalanceValueAcessor);
        const totalsDifferenceValue = acc.difference.value + getReportValue(differenceAcessor);
        const totalsTaxValue = acc.tax.value + getReportValue(taxAccessor);
        const totalsCommissionValue = acc.commission.value + getReportValue(commissionAcessor);

        return {
            receipt: generateReportValuePercentageData(receipt),
            originalBudget: generateReportValuePercentageData(totalsOriginalBudgetValue, generatePercentage(originalBudget)),
            updatedBudget: generateReportValuePercentageData(totalsUpdatedBudgetValue, generatePercentage(updatedBudget)),
            balance: generateReportValuePercentageData(totalsBalanceValue, generatePercentage(balance)),
            updatedBalance: generateReportValuePercentageData(totalsUpdatedBalanceValue, generatePercentage(updatedBalance)),
            contractValue: generateReportValuePercentageData(totalsContractValue),
            contractBalanceValue: generateReportValuePercentageData(totalsContractBalanceValue, getNumberValueOrZero((contractBalanceValue / contractValue) * 100)),
            tax: generateReportValuePercentageData(totalsTaxValue, generatePercentage(tax)),
            commission: generateReportValuePercentageData(totalsCommissionValue, generatePercentage(commission)),
            difference: generateReportValuePercentageData(totalsDifferenceValue, generateDifferencePercentage(difference)),
        };
    }, INITIAL_ORDER_SERVICES_REPORT_TOTALS);
};

export const orderServicesReportSchema = genericReportSchema().concat(
    object({
        saleOrders: array(number()),
        orderServiceStatus: array(number()),
        startConclusionDate: string(),
        endConclusionDate: string(),
    })
);

export const generateOrderServiceReportTotalsClasses = (isSiderbarSmall: boolean, isContractReport: boolean, classes?: string) => {
    if (isContractReport) {
        return classNames('xl:grid-cols-3', isSiderbarSmall && '2xl:grid-cols-9', classes);
    }

    return classNames('xl:grid-cols-4', isSiderbarSmall && '2xl:grid-cols-8', classes);
};
