import { City } from 'types/models/address';
import { WhereClause } from 'types/graphql';

export const getCityKey = (variables?: WhereClause<Partial<City>>) => ['city', variables];
export const getAllCitiesOptionsKey = (stateId?: number) => ['cities', 'all', 'options', stateId];

const queries = {
    getAll: `query GetCitiesOptionsByState ($stateId: Int!) {
        items: city(orderBy: {name: ASC}, where: {stateId: {_eq: $stateId}}) {
            value: id
            label: name
        }
    }`,
    getCity: `query GetCity ($where: CityBoolExp) {
        city(where: $where) {
            id
        }
    }`,
};

export default queries;
