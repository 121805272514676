import { ComponentClass, ComponentType, forwardRef, memo, MouseEventHandler } from 'react';
import ReactSelect, { components, GroupBase, MultiValueGenericProps, MultiValueProps } from 'react-select';
import { SortableContainer, SortableContainerProps, SortableElement, SortEndHandler, SortableHandle } from 'react-sortable-hoc';
import { Option } from 'types/general';
import { DropdownIndicator, SelectProps } from '../select';
import Text from 'components/core/text';
import selectDefaultStyles from '../select/styles';
import ErrorMessage from 'components/error/message';
import classNames from 'classnames';
import { arrayMove } from 'utils/array';

const SortableMultiValue: ComponentType<MultiValueProps<Option, true, GroupBase<Option>>> = SortableElement((props: MultiValueProps<Option>) => {
    const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const innerProps = { ...props.innerProps, onMouseDown };

    return <components.MultiValue {...props} innerProps={innerProps} />;
});

const SortableMultiValueLabel: ComponentType<MultiValueGenericProps<Option, true, GroupBase<Option>>> = SortableHandle((props: MultiValueGenericProps) => <components.MultiValueLabel {...props} />);

const SortableSelectContained: ComponentClass<any & SortableContainerProps> = SortableContainer(ReactSelect);

const SortableSelect = forwardRef<ReactSelect, SelectProps>(
    (
        {
            errorMessageClasses,
            placeholder = 'Selecione uma opção',
            showError = true,
            isSearchable = true,
            label,
            components,
            error,
            parentClassName,
            size = 'medium',
            selectStyles,
            labelClassName,
            ...rest
        },
        ref
    ) => {
        const handleSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
            const newValue = arrayMove(rest.value as Option[], oldIndex, newIndex);

            // @ts-ignore
            rest?.onChange?.(newValue);
        };

        const container = classNames('relative', parentClassName);
        const labelClasses = classNames(labelClassName, 'pl-0 block mb-1.5', error ? 'text-system-danger-500' : 'text-base-500');

        return (
            <div className={container}>
                {Boolean(label) && (
                    <Text className={labelClasses} as="label">
                        {label}
                    </Text>
                )}
                <SortableSelectContained
                    {...rest}
                    useDragHandle={true}
                    axis="xy"
                    onSortEnd={handleSortEnd}
                    distance={4}
                    styles={{ ...selectDefaultStyles(size, Boolean(error)), ...selectStyles } as any}
                    placeholder={placeholder}
                    components={{
                        ...components,
                        MultiValue: SortableMultiValue,
                        MultiValueLabel: SortableMultiValueLabel,
                        DropdownIndicator: () => <DropdownIndicator size={size} />,
                    }}
                    noOptionsMessage={() => 'Nenhum resultado'}
                    loadingMessage={() => 'Carregando...'}
                    classNamePrefix="react-select"
                    isSearchable={isSearchable}
                    ref={ref as any}
                    menuPortalTarget={document.body}
                    getHelperDimensions={({ node }) => node.getBoundingClientRect()}
                    maxMenuHeight={200}
                />
                <ErrorMessage className={errorMessageClasses} visible={showError && Boolean(error)}>
                    {error}
                </ErrorMessage>
            </div>
        );
    }
);

export default memo(SortableSelect);
