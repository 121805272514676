import Input from 'components/core/form/input';
import Text from 'components/core/text';
import { Controller, useFormContext } from 'react-hook-form';
import { FormPayload } from 'services/queries/forms/use-create-or-update-form';

type FormConditionalFieldProps = {
    groupPath: `groups.${number}`;
};

const FormConditionalField = ({ groupPath }: FormConditionalFieldProps) => {
    const { control, formState } = useFormContext<FormPayload>();

    return (
        <div className="box-with-border border-system-warning-200 p-0 mb-6">
            <div className="py-2 px-4 bg-system-warning-100 bg-opacity-50 rounded-t-[14px] border-b border-b-system-warning-200">
                <Text variant="body.regular.xs" className="text-system-warning-900">
                    Os campos abaixo configuram a pergunta que aparecerá para o usuário no momento do preenchimento.
                </Text>
            </div>
            <Controller
                control={control}
                defaultValue=""
                name={`${groupPath}.conditionalFieldTitle`}
                render={({ field }) => {
                    return (
                        <Input
                            {...field}
                            label="Nome"
                            placeholder="Ex: Esta obra precisará de terraplanagem?"
                            error={formState.errors.groups?.[0]?.conditionalFieldTitle?.message}
                            parentClassName="p-4"
                        />
                    );
                }}
            />
        </div>
    );
};

export default FormConditionalField;
