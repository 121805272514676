import IconButton from '@mui/material/IconButton/IconButton';
import classNames from 'classnames';
import SvgIcoDots from 'components/core/icon/files/ico-dots';
import Menu, { MenuItem } from 'components/menu';

export type DatatableToolbarActionsProps = {
    content: MenuItem[];
    toolbarAnchor: [HTMLElement | null, React.Dispatch<React.SetStateAction<HTMLElement | null>>];
    footer?: MenuItem[];
    className?: string;
};

const DatatableToolbarActions = ({ content, footer, className, toolbarAnchor }: DatatableToolbarActionsProps) => {
    const [toolbarAnchorEl, setToolbarAnchorEl] = toolbarAnchor;

    const containerClasses = classNames('px-4', className);

    const handleClose = () => setToolbarAnchorEl(null);

    const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
        if (!!toolbarAnchorEl) {
            return setToolbarAnchorEl(null);
        }

        setToolbarAnchorEl(event.currentTarget);
    };

    return (
        <div className={containerClasses}>
            <IconButton size="medium" type="button" color="primary" onClick={handleToggle}>
                <SvgIcoDots width={20} height={20} />
            </IconButton>
            <Menu anchorEl={toolbarAnchorEl} open={Boolean(toolbarAnchorEl)} content={content} footer={footer} onClose={handleClose} className="z-[9998]" />
        </div>
    );
};

export default DatatableToolbarActions;
