import { BaseModel } from './base';
import { Classification } from './classification';
import { Expense, ExpenseTransaction } from './expense';
import { OrderServiceDeduction } from './order-service';
import { BankAccount } from './bank';
import { Status } from 'types/general';
import { File } from './file';

export enum TransactionType {
    Outcome,
    Income,
    Contribution,
}

export type Transaction = {
    favoredBankAccountId: number;
    favoredBankAccount: Partial<BankAccount>;
    companyBankAccountId: number;
    classificationCategoryId: number;
    classificationPaymentFormId: number;
    transactionRelatedId: number;
    expenseId: number;
    receiptId: number;
    companyPartnerId: number;
    code: string;
    type: TransactionType;
    transactionOrder: number;
    description: string;
    installmentDescription: string;
    fiscalDocument: string;
    observation: string;
    financialBalance: number;
    paid: boolean;
    provisioned: boolean;
    transfer: boolean;
    visibleExtract: boolean;
    grouped: boolean;
    invoiceStatus: TransactionInvoiceStatus;
    dueDate: string;
    payday: string;
    paymentForecast: string;
    grossValue: number;
    netValue: number;
    discount: number;
    addition: number;
    barCode: string;
    expanseHasTransactions: any[]; // TODO:
    orderServiceDeductions: Partial<OrderServiceDeduction>[];
    receiptHasTransactions: any[]; // TODO:
    salesReceiptHasTransactions: any; // TODO:
    transactionDocuments: any[]; // TODO:
    transactionRequestChanges: Partial<TransactionRequestChange>[];
    transactions: any[]; // TODO:
    expenseHasTransactions: Partial<ExpenseTransaction>[];
    bankAccount: Partial<BankAccount>;
    classification: Partial<Classification>;
    classificationByClassificationPaymentFormId: Partial<Classification>;
    paymentForm: Partial<Classification>;
    companyBankAccount: Partial<BankAccount>;
    companyPartner: any; // TODO:
    expense: Partial<Expense> | Partial<Expense>;
    receipt: any; // TODO:
    transaction: Partial<Transaction>;
    withholdingTaxInss: number;
    withholdingTaxIss: number;
    withholdingTaxCofins: number;
    withholdingTaxCsll: number;
    withholdingTaxIrpj: number;
    withholdingTaxPis: number;
    withholdingTaxOthers: number;
    order: number;
    documents?: Array<
        Pick<Transaction, 'classification' | 'description' | 'id' | 'observation' | 'transaction'> & {
            name: string;
            type: TransactionDocumentType;
            file: Partial<File>;
        }
    >;
} & BaseModel;

export enum TransactionInvoiceStatus {
    ReleasedShippingSystem,
    WaitingToBeSent,
    AdminShippingReleased,
    Sent,
    RequestedReleaseAdminSubmission,
}

export enum TransactionRequestChangeType {
    ProvisionDate = 1,
}

export type TransactionRequestChange = {
    transactionId: number;
    personId: number;
    requestedDate: string;
    type: TransactionRequestChangeType;
    justification: string;
    approved: boolean;
    status: Status;
} & BaseModel;

export enum TransactionDocumentType {
    Invoice = 1,
    PaymentVoucher,
    Bill,
}

export enum TransactionPrintType {
    NotGrouped = 'not-grouped',
    ForecastDate = 'forecast-date',
    PaymentDate = 'payment-date',
    DueDate = 'due-date',
    Favored = 'favored',
    PaymentForm = 'payment-form',
}
