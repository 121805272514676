import DatePicker from 'components/core/datepicker';
import CurrencyInput from 'components/core/form/currency';
import Editor from 'components/core/form/editor';
import Select from 'components/core/form/select';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import FormButtons from 'components/form-buttons';
import { Controller, useForm } from 'react-hook-form';
import useGetOptions from 'services/queries/crud/use-get-options';
import { CreateTransferBetweenAccountsPayload } from 'types/models/bank-accounts';
import { yupResolver } from '@hookform/resolvers/yup';
import { transferBankAccountSchema } from '../utils';
import { queryCompanyBankAccountsOptions } from 'services/queries/bank-accounts/utils';
import useGetCompaniesOptions from 'services/queries/companies/use-get-company-options';
import { getCompanyBankAccountsOptionsKeys } from 'services/queries/contribution/keys';
import Dropzone from 'components/dropzone';
import { useParams } from 'react-router-dom';

type CreateIncomeProps = {
    onSubmit: (data: CreateTransferBetweenAccountsPayload) => void;
    isSubmitting: boolean;
    companyId: number;
};

const CreateTransferBetweenAccounts = ({ onSubmit, isSubmitting, companyId }: CreateIncomeProps) => {
    const { bankAccountId } = useParams();

    const { control, handleSubmit, formState, watch, setValue } = useForm<CreateTransferBetweenAccountsPayload>({
        resolver: yupResolver(transferBankAccountSchema(companyId)) as any,
        mode: 'onSubmit',
    });

    const [company] = watch(['company']);

    const { data: companies = [], isLoading: isLoadingCompanies } = useGetCompaniesOptions();

    const { data: bankAccounts = [], isLoading: isLoadingBankAccounts } = useGetOptions(
        queryCompanyBankAccountsOptions(
            !!company
                ? {
                      companyId: { _eq: company },
                      id: { _neq: bankAccountId },
                  }
                : undefined
        ),
        getCompanyBankAccountsOptionsKeys([company!]),
        !!company,
        (items) => items.map((item) => ({ value: item?.bankAccount?.value, label: item?.bankAccount?.label }))
    );

    const handleChangeFile =
        (onChange: (...event: any[]) => void) =>
        (filesList: File[] = []) => {
            onChange(filesList[0]);
        };

    const handleChangeCompany = (onChange: (...event: any[]) => void) => (option: any) => {
        setValue('companyBankAccount', null as any);
        onChange(option?.value);
    };

    return (
        <Modal
            headerLeft={
                <Text as="h3" variant="h5" className="text-heading">
                    Criar nova transferência entre contas
                </Text>
            }
            closeOnClickOutside={false}
            contentClassnames="w-[1000px]">
            <form onSubmit={handleSubmit(onSubmit)} className="p-6 pt-1">
                <div className="grid grid-cols-4 gap-4 mb-4">
                    <Controller
                        control={control}
                        name="company"
                        render={({ field }) => {
                            const value = companies.find((item) => item.value === +field.value!);

                            return (
                                <Select
                                    options={companies}
                                    isLoading={isLoadingCompanies}
                                    label="Empresa"
                                    value={value}
                                    onChange={handleChangeCompany(field.onChange)}
                                    error={formState.errors.company?.message}
                                />
                            );
                        }}
                    />
                    <Controller
                        control={control}
                        name="companyBankAccount"
                        render={({ field }) => {
                            const value = bankAccounts.find((bankAccount) => bankAccount.value === +field.value) || null;

                            return (
                                <Select
                                    isDisabled={!company}
                                    isLoading={isLoadingBankAccounts}
                                    options={bankAccounts}
                                    label="Conta Bancária"
                                    value={value}
                                    onChange={(option: any) => field.onChange(option.value)}
                                    error={formState.errors.companyBankAccount?.message}
                                />
                            );
                        }}
                    />
                    <Controller
                        control={control}
                        name="dueDate"
                        render={({ field }) => {
                            return (
                                <DatePicker
                                    inputProps={{
                                        placeholderText: 'Ex: 00/00/0000',
                                        label: 'Data de Transferência',
                                        error: formState.errors.dueDate?.message,
                                        ...field,
                                    }}
                                />
                            );
                        }}
                    />
                    <Controller
                        control={control}
                        name="grossValue"
                        render={({ field }) => {
                            return (
                                <CurrencyInput
                                    label="Valor"
                                    value={field.value}
                                    decimalScale={2}
                                    decimalSeparator="."
                                    fixedDecimalScale={true}
                                    placeholder="Ex: R$ 2.500,40"
                                    onValueChange={({ floatValue }) => field.onChange(floatValue)}
                                    error={formState.errors.grossValue?.message}
                                    left={
                                        <Text as="span" variant="body.medium.sm" className="text-heading">
                                            R$
                                        </Text>
                                    }
                                />
                            );
                        }}
                    />
                </div>
                <div className="mb-4">
                    <Text as="label" variant="body.regular.sm" className="block mb-1.5 text-base-500">
                        Documento
                    </Text>
                    <Controller
                        name="document"
                        control={control}
                        render={({ field }) => {
                            return <Dropzone file={field.value} error={formState.errors.document?.message} multiple={false} onUploadFiles={handleChangeFile(field.onChange)} />;
                        }}
                    />
                </div>
                <Controller control={control} name="observation" render={({ field }) => <Editor {...field} label="Observações" error={formState?.errors?.observation?.message} />} />
                <FormButtons isLoading={isSubmitting} containerClassName="mt-4" />
            </form>
        </Modal>
    );
};

export default CreateTransferBetweenAccounts;
