import Text from 'components/core/text';
import { useFormContext, useWatch } from 'react-hook-form';
import { formatMoney } from 'utils/money';

const NationalizeTotalProducts = () => {
    const { control } = useFormContext<any>();

    const products = useWatch({ name: 'products', control });

    const total = products.reduce((acc, curr) => {
        const total = Number(curr.grossValue) * Number(curr.quantity);

        acc += total;

        return acc;
    }, 0);

    return (
        <div className="bg-[#eefcff] py-4 px-6 text-right mb-6">
            <Text className="text-base-700">Total: </Text>
            <Text variant="body.medium.2xs" className="text-heading">
                {formatMoney(total)}
            </Text>
        </div>
    );
};

export default NationalizeTotalProducts;
