import { useOutletContext, useParams } from 'react-router-dom';
import useGetPersonContract from 'services/queries/people/use-get-person-contract';
import useCreateOrUpdatePersonContract from 'services/queries/people/use-create-or-update-person-contract';
import { CreatePersonContractPayload } from 'types/models/person';
import { formatDate } from 'utils/date';
import PersonDetailsContractsCreateForm from './form';

const CreateOrUpdatePersonContractPage = () => {
    const ctx = useOutletContext();

    const { contractId, personId } = useParams();

    const title = `${!!contractId ? 'Editar' : 'Novo'} Contrato`;

    const { mutateAsync: createOrUpdatePersonContract, isLoading: isSubmitting } = useCreateOrUpdatePersonContract(personId, contractId, ctx);

    const { data: personContract, isLoading: isLoadingDetails } = useGetPersonContract({ contractId });

    const handleSubmit = async (data: CreatePersonContractPayload) => {
        try {
            const payload = {
                ...data,
                startDate: formatDate(data?.startDate, 'DD/MM/YYYY'),
                finalDate: !!data?.finalDate ? formatDate(data?.finalDate, 'DD/MM/YYYY') : null,
                consultant: data?.consultant,
            };

            await createOrUpdatePersonContract(payload);
        } catch (error) {
            console.log('handleSubmit', error);
        }
    };

    return <PersonDetailsContractsCreateForm isCreate={!contractId} isBuilding={isLoadingDetails} title={title} defaultValues={personContract} isSubmitting={isSubmitting} onSubmit={handleSubmit} />;
};

export default CreateOrUpdatePersonContractPage;
