import { useQuery } from 'react-query';
import apiGraphQl from 'services/graphql';
import { getProductsKey } from './keys';

type Response<T> = {
    product: Partial<T>[];
};

const useGetProducts = <T>(query: string, enabled: boolean, onSuccess?: (data: Partial<T>[]) => void, key = getProductsKey) => {
    const request = async () => {
        const { product } = await apiGraphQl<Response<T>>(query);

        return onSuccess?.(product) || product;
    };

    return useQuery(key(), request, { enabled });
};

export default useGetProducts;
