import * as React from 'react';
const SvgIcoImage = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M2.361 1.5a.861.861 0 0 0-.861.861V13.64a.86.86 0 0 0 .593.818l8.599-8.598a.75.75 0 0 1 1.06 0L14.5 8.606V2.361a.861.861 0 0 0-.861-.861H2.36ZM14.5 10.727 11.222 7.45l-7.05 7.05h9.467a.861.861 0 0 0 .861-.861v-2.912ZM0 2.361A2.361 2.361 0 0 1 2.361 0H13.64A2.361 2.361 0 0 1 16 2.361V13.64A2.361 2.361 0 0 1 13.639 16H2.36A2.361 2.361 0 0 1 0 13.639V2.36Zm5.18 2.361a.458.458 0 1 0 0 .917.458.458 0 0 0 0-.917Zm-1.958.459a1.958 1.958 0 1 1 3.917 0 1.958 1.958 0 0 1-3.917 0Z"
            clipRule="evenodd"
        />
    </svg>
);
export default SvgIcoImage;
