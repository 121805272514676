import Home from 'pages/private/home';
import { RouteObject } from 'react-router-dom';

const home: RouteObject[] = [
    {
        // @ts-ignore
        breadcrumb: 'Página inicial',
        path: '',
        element: <Home />,
    },
];

export default home;
