import Select from 'components/core/form/select';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import useGetDebouncedProducts from 'services/queries/products/use-get-debounced-products';
import { Product } from 'types/models/product';
import { reportProductsFiltersSchema, saleTypeOptions } from '../utils';
import { memo, useEffect } from 'react';
import { formatDate } from 'utils/date';
import { yupResolver } from '@hookform/resolvers/yup';
import { genericReportDefaultValues, handleChangeReportSelect, productsOptionsTransformer } from '../../utils';
import { DataContentFiltersConfig, GenericReportFilterPayload, ReportFilterPayload } from '../../types';
import GenericReportFiltersContent from '../../../components/generic-filters';
import dayjs from 'dayjs';
import ModalFilter from 'components/core/modal/filter';
import { ReportProductsFiltersPayload } from 'types/models/reports';
import isEmpty from 'lodash/isEmpty';

type ReportProductsFiltersProps = {
    onSetFilters: (data?: GenericReportFilterPayload<ReportProductsFiltersPayload>) => void;
    onCloseFilters: () => void;
    filters?: GenericReportFilterPayload<ReportProductsFiltersPayload>;
    dataContentFiltersConfig: DataContentFiltersConfig;
    isSale: boolean;
};

const ReportProductsFilters = ({ filters, onSetFilters, onCloseFilters, dataContentFiltersConfig, isSale }: ReportProductsFiltersProps) => {
    const { classifications = [] } = dataContentFiltersConfig;

    const { isLoadingProducts, products: productsExpenseOptions, searchTerm, setSearchTerm } = useGetDebouncedProducts<Product, any[]>(['id', 'name', 'code'], productsOptionsTransformer);

    const methods = useForm<ReportFilterPayload<ReportProductsFiltersPayload>['filter']>({
        mode: 'onSubmit',
        resolver: yupResolver(reportProductsFiltersSchema) as any,
        defaultValues: {
            ...genericReportDefaultValues,
            saleType: 'all',
        },
    });

    const { formState, control, handleSubmit, reset } = methods;

    const submit = (data?: ReportFilterPayload<ReportProductsFiltersPayload>['filter']) => {
        if (!isEmpty(data)) {
            const { startDate, endDate, saleType, ...rest } = data;

            onSetFilters({
                filter: {
                    ...rest,
                    ...(!!startDate && { startDate: formatDate(startDate, 'YYYY-MM-DD') }),
                    ...(!!endDate && { endDate: formatDate(endDate, 'YYYY-MM-DD') }),
                    ...(Boolean(saleType) && saleType !== 'all' && { saleType }),
                },
            });
        }

        onCloseFilters();
    };

    const handleClear = () => {
        onSetFilters();
        onCloseFilters();
    };

    useEffect(() => {
        if (!!filters) {
            const { endDate, startDate, ...rest } = filters.filter || {};

            reset({
                ...rest,
                ...(Boolean(startDate) && { startDate: dayjs(startDate).toISOString() }),
                ...(Boolean(endDate) && { endDate: dayjs(endDate).toISOString() }),
            });
        }
    }, [filters, reset]);

    return (
        <ModalFilter formMethods={methods} onSubmit={handleSubmit(submit)} onClose={onCloseFilters} onClear={handleClear}>
            <div className="flex flex-col gap-2">
                <FormProvider {...methods}>
                    <GenericReportFiltersContent dataContentFiltersConfig={dataContentFiltersConfig} labelPeriod="Período de venda" withConsultants={false} withTypes={false}>
                        <Controller
                            control={control}
                            name="products"
                            render={({ field }) => {
                                const value = productsExpenseOptions.filter((item) => field?.value?.includes(item.value));
                                return (
                                    <Select
                                        {...field}
                                        value={value}
                                        isClearable={true}
                                        isMulti={true}
                                        isLoading={isLoadingProducts}
                                        components={!Boolean(searchTerm) ? { NoOptionsMessage: () => null } : undefined}
                                        options={productsExpenseOptions}
                                        onInputChange={setSearchTerm}
                                        label="Produtos"
                                        onChange={handleChangeReportSelect(field.onChange)}
                                        parentClassName="min-w-full"
                                        placeholder="Busca por código/nome"
                                        error={formState.errors.products?.message}
                                    />
                                );
                            }}
                        />

                        {isSale ? (
                            <>
                                <Controller
                                    control={control}
                                    name="saleType"
                                    render={({ field }) => {
                                        const value = saleTypeOptions.find((item) => item?.value === field?.value);

                                        return (
                                            <Select
                                                {...field}
                                                options={saleTypeOptions}
                                                value={value}
                                                isClearable={true}
                                                placeholder="Selecione um tipo de venda"
                                                label="Tipo de venda"
                                                error={formState.errors.saleType?.message}
                                                parentClassName="min-w-full"
                                                onChange={handleChangeReportSelect(field.onChange)}
                                            />
                                        );
                                    }}
                                />

                                <Controller
                                    control={control}
                                    name="classifications"
                                    render={({ field }) => {
                                        const value = classifications.filter((item) => field?.value?.includes(item.value));

                                        return (
                                            <Select
                                                {...field}
                                                options={classifications}
                                                value={value}
                                                isDisabled={!classifications.length}
                                                isClearable={true}
                                                isMulti={true}
                                                placeholder="Selecione uma classificação"
                                                label="Classificação"
                                                error={formState.errors.classifications?.message}
                                                parentClassName="min-w-full"
                                                onChange={handleChangeReportSelect(field.onChange)}
                                            />
                                        );
                                    }}
                                />
                            </>
                        ) : (
                            <Controller
                                control={control}
                                name="categories"
                                render={({ field }) => {
                                    const value = classifications.filter((item) => field?.value?.includes(item.value));

                                    return (
                                        <Select
                                            {...field}
                                            options={classifications}
                                            value={value}
                                            isDisabled={!classifications.length}
                                            isClearable={true}
                                            isMulti={true}
                                            placeholder="Selecione uma categoria"
                                            label="Categoria"
                                            error={formState.errors.classifications?.message}
                                            parentClassName="min-w-full"
                                            onChange={handleChangeReportSelect(field.onChange)}
                                        />
                                    );
                                }}
                            />
                        )}
                    </GenericReportFiltersContent>
                </FormProvider>
            </div>
        </ModalFilter>
    );
};

export default memo(ReportProductsFilters);
