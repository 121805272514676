import Button from 'components/core/button';
import useTheme from '@mui/material/styles/useTheme';
import SvgIcoArrowLeft from 'components/core/icon/files/ico-arrow-left';
import SvgIcoArrowRight from 'components/core/icon/files/ico-arrow-right';

type ItemFooterProps = {
    isValid: boolean;
    isSubmitting?: boolean;
    onGoBack?: () => void;
};

const ItemFooter = ({ isValid, isSubmitting = false, onGoBack }: ItemFooterProps) => {
    const { palette } = useTheme();

    return (
        <div className="border-t p-2 flex">
            {!!onGoBack && (
                <Button startIcon={<SvgIcoArrowLeft width={18} color={palette.grey[700]} />} type="button" variant="text" color="inherit" className="min-w-[100px]" onClick={onGoBack}>
                    Voltar
                </Button>
            )}
            <Button
                disabled={isSubmitting || !isValid}
                loading={isSubmitting}
                endIcon={<SvgIcoArrowRight width={18} color={isSubmitting || !isValid ? palette.grey[400] : palette.secondary[500]} />}
                type="submit"
                variant="text"
                color={isSubmitting || !isValid ? 'inherit' : 'secondary'}
                className="min-w-[100px] ml-auto">
                Avançar
            </Button>
        </div>
    );
};

export default ItemFooter;
