import InterMenuDesk from './desk';
import InterMenuMobile from './mobile';
import { InterMenuTabProps } from './tab';

const InterMenu = (props: InterMenuTabProps) => {
    return (
        <>
            <InterMenuDesk {...props} />
            <InterMenuMobile {...props} />
        </>
    );
};

export default InterMenu;
