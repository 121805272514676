import Tab from '@mui/material/Tab/Tab';
import Tabs from '@mui/material/Tabs/Tabs';
import Modal from 'components/core/modal';
import { useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import useGetTransaction from 'services/queries/transactions/use-get-transaction';
import PaymentOrderDetails from './details';
import Spinner from 'components/core/spinner';
import PaymentOrderExpense from './expense';
import { Expense } from 'types/models/expense';
import PaymentOrderDocuments from './documents/documents';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';

const PaymentOrderPage = () => {
    const [activeTab, setActiveTab] = useState(1);

    const { paymentOrderId, receiptOrderId, transactionId } = useParams();

    const id = paymentOrderId || receiptOrderId || transactionId;
    // variables represent IDs from different routes - all of them represent the same type of ID

    const goToParentRoute = useNavigateToParentRoute();

    const { data: transaction, isLoading } = useGetTransaction(id);

    const handleChangeTab = (_, value: number) => setActiveTab(value);

    return (
        <>
            <Modal contentClassnames="w-[1200px]" closeOnClickOutside={false} onClose={goToParentRoute}>
                <Tabs variant="scrollable" classes={{ root: 'bg-base-100 z-[10]' }} indicatorColor="secondary" value={activeTab} onChange={handleChangeTab}>
                    <Tab classes={{ selected: 'bg-base-100', root: 'py-5' }} label="DETALHES" value={1} />
                    {Boolean(paymentOrderId || transactionId) && <Tab classes={{ selected: 'bg-base-100', root: 'py-5' }} label="DESPESAS" value={2} />}
                    <Tab classes={{ selected: 'bg-base-100', root: 'py-5' }} label="DOCUMENTOS DA MOVIMENTAÇÃO" value={3} />
                </Tabs>
                <div className="sm:min-h-[518px]">
                    {isLoading && !transaction ? (
                        <Spinner fixed={false} size={20} />
                    ) : (
                        <>
                            {activeTab === 1 && <PaymentOrderDetails {...transaction!} />}
                            {activeTab === 2 && (
                                <PaymentOrderExpense
                                    addition={transaction!.addition}
                                    discount={transaction!.discount}
                                    withholdingTaxInss={transaction!.withholdingTaxInss}
                                    withholdingTaxIss={transaction!.withholdingTaxIss}
                                    companyBankAccount={transaction!.companyBankAccount}
                                    favoredBankAccount={transaction!.favoredBankAccount}
                                    transactionType={transaction!.type}
                                    {...(transaction!.expense as Expense)}
                                />
                            )}
                            {activeTab === 3 && <PaymentOrderDocuments documents={transaction!.documents} transactionId={id} />}
                        </>
                    )}
                </div>
            </Modal>
            <Outlet />
        </>
    );
};

export default PaymentOrderPage;
