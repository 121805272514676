import { useMutation } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import api from 'services/api';
import { Auth } from 'store/auth/types';
import useAuth from 'store/auth/use-auth';
import useToast from 'hooks/toast/use-toast';
import { AuthPayload } from 'types/models/user';

const useAuthMutation = () => {
    const { setAuth } = useAuth();
    const navigate = useNavigate();
    const { showToast } = useToast();
    const [search] = useSearchParams();

    const request = async (payload: AuthPayload) => api.post<Auth>('/tokens', payload);

    return useMutation(request, {
        onSuccess: ({ data }) => {
            api.defaults.headers.common.Authorization = `Bearer ${data.token}`;

            setAuth(data);

            const path = search.get('redirectPath') || 'app';

            navigate(path);
        },
        onError: () => {
            showToast('Ocorreu um erro ao tentar entrar', 'danger');
        }
    });
};

export default useAuthMutation;
