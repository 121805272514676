import { useMutation, useQueryClient } from 'react-query';
import useToast from 'hooks/toast/use-toast';
import { getBoardManagerKey } from '../sales-order/keys';
import api from 'services/api';

type Payload = {
    taskId: number;
    taskStatusId: number;
};

const useUpdateTaskStatus = (saleOrderId, orderServiceId) => {
    const { showToast } = useToast();

    const queryClient = useQueryClient();

    const request = async ({ taskId, taskStatusId }: Payload) => api.put(`/tasks/${taskId}/status`, { status: taskStatusId });

    return useMutation(request, {
        onError: () => {
            showToast('Tivemos um problema para mover a tarefa, tente novamente.', 'danger');
        },
        onSuccess: () => {
            showToast('Tarefa movida com sucesso.', 'success');

            queryClient.invalidateQueries(getBoardManagerKey(saleOrderId, orderServiceId));
        },
    });
};

export default useUpdateTaskStatus;
