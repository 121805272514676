import { Navigate, RouteObject } from 'react-router-dom';
import { Rule } from 'types/permissions';
import Page from 'pages/shared/page';
import config from './utils';
import ListPage from 'pages/shared/crud/list';
import CreateOrUpdatePersonPage from 'pages/private/people/create-or-update';
import PeoplePage from 'pages/private/people/view';
import PersonDetailsTab from 'pages/private/people/view/tabs/details';
import ChangePersonImagePage from 'pages/private/people/change-image';
import PersonDetailsContracts from 'pages/private/people/view/tabs/contracts';
import CreateOrUpdatePersonContractPage from 'pages/private/people/view/tabs/contracts/create-or-update';
import PersonContractStatus from 'components/core/modal/person-contract-status';
import PersonContractDetails from 'pages/private/people/view/tabs/contracts/details';
import PersonBankAccountsTab from 'pages/private/people/view/tabs/bank-accounts';
import CreateOrUpdatePersonBankAccountPage from 'pages/private/people/view/tabs/bank-accounts/create-or-update';

const [list, create] = config;

const people: RouteObject[] = [
    {
        path: 'pessoas',
        element: <Page Component={ListPage} code="pessoas" permissions={[Rule.List]} {...list} />,
        children: [
            {
                path: 'novo',
                element: <Page Component={CreateOrUpdatePersonPage} withModal={true} code="pessoas" permissions={[Rule.Create]} {...create} />,
            },
            {
                path: 'editar/:personId',
                element: <Page Component={CreateOrUpdatePersonPage} withModal={true} code="pessoas" permissions={[Rule.Update]} title="Atualizar pessoa" />,
            },
        ],
    },
    {
        path: 'pessoas/:personId',
        element: <Page Component={PeoplePage} code="pessoas" permissions={[Rule.Show]} />,
        children: [
            {
                index: true,
                element: <Navigate to="detalhes" replace={true} />,
            },
            {
                path: 'editar',
                element: <Page Component={CreateOrUpdatePersonPage} withModal={true} code="pessoas" permissions={[Rule.Update]} title="Atualizar pessoa" />,
            },
            {
                path: 'alterar-foto',
                element: <Page Component={ChangePersonImagePage} withModal={true} code="pessoas" permissions={[Rule.Update]} />,
            },
            {
                path: 'detalhes',
                element: <Page Component={PersonDetailsTab} code="pessoas" permissions={[Rule.Show]} title="Detalhes da pessoa" />,
                // @ts-ignore
                label: 'Detalhes',
            },
            {
                path: 'contratos',
                element: <Page Component={PersonDetailsContracts} code="pessoas-contratos" permissions={[Rule.Show, Rule.List]} />,
                // @ts-ignore
                label: 'Contratos',
                children: [
                    {
                        path: 'novo',
                        element: <Page Component={CreateOrUpdatePersonContractPage} withModal={true} key="create-contract" code="pessoas-contratos" permissions={[Rule.Create]} />,
                    },
                    {
                        path: 'editar/:contractId',
                        element: <Page Component={CreateOrUpdatePersonContractPage} withModal={true} key="edit-contract" code="pessoas-contratos" permissions={[Rule.Update]} />,
                    },
                    {
                        path: 'reativar/:contractId',
                        element: <Page Component={PersonContractStatus} withModal={true} type="reactivate" code="pessoas-contratos" permissions={[Rule.Update]} />,
                    },
                    {
                        path: ':contractId',
                        element: <Page Component={PersonContractDetails} withModal={true} code="pessoas-contratos" permissions={[Rule.Show]} />,
                    },
                ],
            },
            {
                path: 'contas-bancarias',
                element: <Page Component={PersonBankAccountsTab} code="pessoas-contas-bancarias" permissions={[Rule.List]} title="Contas Bancárias" />,
                children: [
                    {
                        path: 'novo',
                        element: <Page Component={CreateOrUpdatePersonBankAccountPage} permissions={[Rule.Create]} code="pessoas-contas-bancarias" withModal={true} title="Cadastrar conta bancária" />,
                    },
                    {
                        path: 'editar/:bankAccountId',
                        element: <Page Component={CreateOrUpdatePersonBankAccountPage} permissions={[Rule.Update]} code="pessoas-contas-bancarias" withModal={true} title="Editar conta bancária" />,
                    },
                ],
            },
        ],
    },
];

export default people;
