import { useEffect, useMemo, useState } from 'react';
import Modal from 'components/core/modal';
import { useOutletContext, useParams } from 'react-router-dom';
import Text from 'components/core/text';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from 'components/core/form/input';
import { createOrUpdatePageMutation, getPageQuery, pageSchema } from './utils';
import { GraphqlPaginationVariables } from 'types/graphql';
import { Page } from 'types/models/page';
import Checkbox from 'components/core/form/checkbox';
import { Modify, Option, Status } from 'types/general';
import useCreateOrUpdatePage from 'services/queries/pages/use-create-or-update-page';
import useGetPage from 'services/queries/pages/use-get-page';
import useTheme from '@mui/material/styles/useTheme';
import Switch from 'components/core/switch';
import useGetPages from 'services/queries/pages/use-get-pages';
import useDeleteSubPages from 'services/queries/pages/use-delete-subpages';
import useCreateSubPages from 'services/queries/pages/use-create-subpages';
import useGetRules from 'services/queries/rules/use-get-rules';
import useDeletePageRules from 'services/queries/rules/use-delete-page-rule';
import useCreatePageRules from 'services/queries/rules/use-create-page-rule';
import useToast from 'hooks/toast/use-toast';
import { hexToRgba } from 'utils/string';
import FormButtons from 'components/form-buttons';
import { getRulesQuery } from 'services/queries/rules/keys';
import { useQueryClient } from 'react-query';
import SortableSelect from 'components/core/form/select-sortable';
import { getCredentialsKey } from 'services/queries/credentials/keys';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';

export type PagePayload = {
    hasSubpages: boolean;
    subpages: Array<Option & { orderMenu: number }>;
    pageRules: string[];
} & Modify<Pick<Page, 'keyPage' | 'name' | 'slug' | 'permalink' | 'system' | 'title' | 'controller'>, { system: number }>;

const CreateOrUpdatePagesPage = () => {
    const { pageId } = useParams();
    const { palette } = useTheme();
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const goToParentRoute = useNavigateToParentRoute();
    const params = useOutletContext<GraphqlPaginationVariables<Page>>();

    const [isLoading, setIsLoading] = useState(false);

    const { mutateAsync: createSubPages } = useCreateSubPages();
    const { mutateAsync: createPageRules } = useCreatePageRules();

    const { mutateAsync: deleteSubPages } = useDeleteSubPages();
    const { mutateAsync: deletePageRules } = useDeletePageRules();

    const { mutateAsync: createOrUpdatePage } = useCreateOrUpdatePage(params, createOrUpdatePageMutation(pageId), Number(pageId) || 0);

    const { data: pages = [] } = useGetPages();
    const { data: rules = [] } = useGetRules(getRulesQuery);
    const { data: page } = useGetPage(getPageQuery(pageId), pageId);

    const { control, formState, handleSubmit, reset, setValue, watch, register } = useForm<PagePayload>({
        mode: 'onSubmit',
        shouldFocusError: true,
        resolver: yupResolver(pageSchema) as any,
        defaultValues: {
            keyPage: '',
            slug: '',
            permalink: '',
            system: 0,
            title: '',
            hasSubpages: false,
            subpages: [],
            pageRules: [],
            controller: '',
        },
    });

    const [hasSubpages, pageRules, subpages] = watch(['hasSubpages', 'pageRules', 'subpages']);

    const formattedPages = useMemo(() => pages.map((item) => ({ value: item.id, label: item.name })), [pages]);

    const handleSubpages = (value: boolean) => {
        setValue('hasSubpages', value);

        if (hasSubpages) {
            setValue('subpages', []);
            return setValue('hasSubpages', false);
        }
    };

    const submit = async (data: PagePayload) => {
        try {
            setIsLoading(true);
            delete (data as any)['hasSubpages'];

            if (Boolean(pageId)) {
                delete (data as any)['pageRules'];
                delete (data as any)['subpages'];
            }

            const { subpages: dataSubPages, ...rest } = data;

            const payload = {
                ...rest,
                system: Boolean(data.system),
            };

            if (Boolean(dataSubPages?.length)) {
                payload['subpages'] = {
                    data: dataSubPages?.map((item) => ({
                        orderMenu: item?.orderMenu,
                        pageId: item?.value,
                        status: Status.Active,
                    })),
                } as any;
            }

            if (Boolean(payload.pageRules?.length)) {
                payload.pageRules = {
                    data: payload.pageRules.map((item) => ({ ruleId: item })),
                } as any;
            } else {
                // @ts-ignore
                payload.pageRules = undefined;
            }

            await createOrUpdatePage(payload as any);

            // DELETE ALL SUBPAGES
            if (Boolean(pageId)) {
                if (!!subpages.length) {
                    await deleteSubPages(Number(pageId));

                    const newSubpages = subpages?.map((item: any) => ({
                        orderMenu: item.orderMenu,
                        status: Status.Active,
                        pageId: item.value,
                        pageParentId: pageId,
                    }));

                    await createSubPages(newSubpages);
                }

                if (!!pageRules.length) {
                    await deletePageRules(Number(pageId));

                    const newPageRules = pageRules.map((item) => ({
                        ruleId: item,
                        pageId: pageId,
                    }));

                    await createPageRules(newPageRules);
                }
            }

            goToParentRoute();
            showToast(`Página ${!!pageId ? 'atualizada' : 'criada'} com sucesso`, 'success');

            // Refetch credentials
            queryClient.invalidateQueries(getCredentialsKey);
        } catch (error) {
            console.log('submit', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (page) {
            const subpagesArr = (page.subpages || []).map((item) => ({
                label: item.page?.name,
                value: item.pageId,
                orderMenu: item.orderMenu,
            }));

            reset({
                ...page,
                system: page.system ? 1 : 0,
                subpages: subpagesArr,
                pageRules: (page.pageRules || []).map((item) => item.id.toString()),
                ...(Boolean(page.subpages?.length) && { hasSubpages: true }),
            });
        }
    }, [page, reset]);

    return (
        <Modal
            contentClassnames="w-[850px]"
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    {pageId ? 'Atualizar' : 'Nova'} página
                </Text>
            }
            closeOnClickOutside={false}>
            <form className="px-6 pb-6" onSubmit={handleSubmit(submit)}>
                <div className="md:grid md:grid-cols-4 gap-4 mb-4">
                    <Controller
                        name="title"
                        control={control}
                        render={({ field }) => <Input {...field} error={formState.errors.title?.message} label="Título (exibição)" inputWrapperClasses="mb-4 md:mb-0" />}
                    />
                    <Controller name="name" control={control} render={({ field }) => <Input {...field} error={formState.errors.name?.message} label="Nome" inputWrapperClasses="mb-4 md:mb-0" />} />
                    <Controller name="slug" control={control} render={({ field }) => <Input {...field} error={formState.errors.slug?.message} label="Slug" inputWrapperClasses="mb-4 md:mb-0" />} />
                    <Controller name="controller" control={control} render={({ field }) => <Input {...field} error={formState.errors.controller?.message} label="Controller" />} />
                </div>
                <div className="md:grid md:grid-cols-3 gap-4 mb-6">
                    <Controller
                        name="keyPage"
                        control={control}
                        render={({ field }) => <Input {...field} error={formState.errors.keyPage?.message} label="Chave" inputWrapperClasses="mb-4 md:mb-0" />}
                    />
                    <Controller
                        name="permalink"
                        control={control}
                        render={({ field }) => <Input {...field} error={formState.errors.permalink?.message} label="Link" inputWrapperClasses="mb-4 md:mb-0" />}
                    />
                    <div className="h-12 border border-base-300 p-[13px] rounded-xl mt-[26px]">
                        <Controller
                            name="system"
                            control={control}
                            render={({ field }) => (
                                <Checkbox
                                    {...field}
                                    value={field.value}
                                    label="Página do sistema"
                                    checked={Boolean(field.value)}
                                    onChange={(event) => {
                                        const isChecked = event.currentTarget.checked;
                                        setValue('system', isChecked ? 1 : 0);
                                    }}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="mb-6 border border-base-300 rounded-[14px] p-4">
                    <Text variant="body.medium.sm" as="span" className="text-heading mb-3 block">
                        Permissões
                    </Text>
                    <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
                        {rules.map((item) => {
                            const isChecked = pageRules.find((selected) => +selected === item.value);

                            return <Checkbox {...register('pageRules')} checked={!!isChecked} value={item.value} key={item.value} label={item.label} />;
                        })}
                    </div>
                </div>
                <div className="-mx-6 px-6 py-5 mb-6" style={{ backgroundColor: hexToRgba(palette.secondary[100], 0.2) }}>
                    <div className="flex justify-between items-center">
                        <Text variant="body.medium.sm" as="span" className="text-secondary-700">
                            Esta página possui subpáginas?
                        </Text>
                        <Switch value={hasSubpages} onChange={handleSubpages} />
                    </div>
                </div>
                {hasSubpages && (
                    <>
                        <Controller
                            name="subpages"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <SortableSelect
                                        {...field}
                                        size="small"
                                        value={subpages}
                                        isMulti={true}
                                        options={formattedPages}
                                        label="Páginas"
                                        placeholder="Selecione uma opção"
                                        error={(formState as any).errors.subpages?.message}
                                        parentClassName="mb-6"
                                        onChange={(options: any) => {
                                            const arr = options?.map((item, index) => ({ ...item, orderMenu: index + 1 }));

                                            setValue('subpages', arr);
                                        }}
                                    />
                                );
                            }}
                        />
                    </>
                )}
                <FormButtons isLoading={isLoading} />
            </form>
        </Modal>
    );
};

export default CreateOrUpdatePagesPage;
