import { useMemo } from 'react';
import useTheme from '@mui/material/styles/useTheme';
import { generateFileUrl } from 'utils/file';
import Avatar from '@mui/material/Avatar/Avatar';
import { CardHeader } from '@mui/material';
import { Option } from 'types/general';
import { generateAddress, hexToRgba } from 'utils/string';
import { PersonContractType } from 'types/models/person';
import { useOutletContext } from 'react-router-dom';
import { Provider } from 'types/models/provider';
import Text from 'components/core/text';
import SvgIcoPhone from 'components/core/icon/files/ico-phone';

const ProviderTabDetails = () => {
    const { palette } = useTheme();

    const ctx = useOutletContext<Partial<Provider>>();

    const amounts = [
        {
            label: 'E-mail(s)',
            value: ctx?.emails?.map(({ email }) => email).join(', ') || '-',
            icon: 'ico-phone',
        },
        {
            label: 'Telefone(s)',
            value: ctx?.phones?.map(({ ddd, number }) => `(${ddd}) ${number}`).join(', ') || '-',
            icon: 'ico-phone',
        },
    ];

    const details: Option<any>[] = useMemo(() => {
        const arr = [
            {
                label: 'Título',
                value: ctx?.title,
            },
        ];

        if (ctx?.type === PersonContractType.Juridic) {
            arr.push({ label: 'Nome jurídico', value: ctx?.juridicName }, { label: 'Nome fantasia', value: ctx?.juridicFantasyName }, { label: 'CNPJ', value: ctx?.juridicDocument });
        } else {
            arr.push({ label: 'Nome', value: ctx?.title }, { label: 'CPF', value: ctx?.document });
        }

        return arr;
    }, [ctx?.title, ctx?.document, ctx?.juridicDocument, ctx?.juridicFantasyName, ctx?.juridicName, ctx?.type]);

    const formattedAddresses = useMemo(
        () =>
            (ctx?.addressProviders || [])
                .filter(({ address }) => !!address)
                .map(({ address }) => {
                    return generateAddress(address);
                }),
        [ctx?.addressProviders]
    );

    return (
        <div>
            <div className="mb-7">
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: '#FFF', border: '1px solid #c4c4c4', borderRadius: '14px !important', width: 48, height: 48 }} aria-label="recipe">
                            {!!ctx?.file && <img src={generateFileUrl(ctx.file?.filename, ctx.file?.path)} className="max-w-[90%]" />}
                        </Avatar>
                    }
                    classes={{ root: 'p-0' }}
                    title={ctx?.title}
                    subheader={ctx?.juridicName}
                    titleTypographyProps={{ fontSize: 18, color: palette.heading, fontWeight: 500 }}
                />
            </div>
            <div className="grid grid-cols-1 mb-6 md:grid-cols-3">
                {amounts.map(({ value, label }, index) => (
                    <CardHeader
                        key={`${label}-${index}`}
                        avatar={
                            <Avatar sx={{ bgcolor: hexToRgba(palette.info[100], 0.2), borderRadius: '14px !important', width: 48, height: 48 }} aria-label="recipe">
                                <SvgIcoPhone color={palette.info[500]} />
                            </Avatar>
                        }
                        classes={{ root: 'p-0 mb-2 md:mb-0' }}
                        title={label}
                        subheader={value}
                        titleTypographyProps={{ color: palette.grey[500] }}
                        subheaderTypographyProps={{ color: palette.heading }}
                    />
                ))}
            </div>
            <Text as="p" className="uppercase mb-3 !text-base text-gray-600">
                Detalhes
            </Text>
            <div className="grid grid-cols-2 mb-10 gap-4 md:grid-cols-4">
                {details.map(({ label, value }, index) => (
                    <div className="flex flex-col" key={`detail-${index}`}>
                        <Text as="p" className="mb-1 !text-base" style={{ color: palette.grey[500] }}>
                            {label}
                        </Text>
                        <Text as="p" className="text-sm text-heading">
                            {value}
                        </Text>
                    </div>
                ))}
            </div>

            {!!formattedAddresses.length && (
                <>
                    <Text as="p" className="uppercase mb-3 !text-base text-gray-600">
                        Endereço{formattedAddresses.length > 1 && 's'}
                    </Text>
                    <div className="mb-10 gap-4 text-sm" style={{ color: palette.heading }}>
                        {formattedAddresses}
                    </div>
                </>
            )}
        </div>
    );
};

export default ProviderTabDetails;
