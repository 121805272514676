import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import apiService from 'services/api';
import apiGraphQl from 'services/graphql';
import { getAllKey } from './keys';
import { GraphqlPaginationVariables } from 'types/graphql';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';

type Config = {
    api?: {
        endpoint: string;
        method?: 'POST' | 'PATCH' | 'PUT';
    };
    graphql?: {
        query?: string;
        table?: string;
    };
    onError?(error: unknown): void;
    onSuccess?(data: any): void;
    id?: string;
};

const useCreateOrUpdate = <T>(config: Config, variables?: GraphqlPaginationVariables<any>) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const goToParentRoute = useNavigateToParentRoute();

    const { api, graphql, onSuccess, onError, id } = config || {};

    const request = async (payload: T) => {
        if (!!graphql?.query) {
            const params = { payload, ...(!!id && { id }) };

            return apiGraphQl(graphql?.query, params);
        }

        return apiService[api?.method! || 'POST'](api?.endpoint!, payload);
    };

    return useMutation(request, {
        onError: (error) => {
            console.log('error', error);
            showToast('Ocorreu um erro ao salvar. Tente novamente.', 'danger');

            if (onError) {
                onError(error);
            }
        },
        onSuccess: (data) => {
            showToast('Registro salvo com sucesso!', 'success');

            queryClient.invalidateQueries(getAllKey(graphql?.table!, variables));

            goToParentRoute();

            onSuccess?.(data);
        },
    });
};

export default useCreateOrUpdate;
