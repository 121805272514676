import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import FinishOSProduct from './product';
import { yupResolver } from '@hookform/resolvers/yup';
import { array, number, object } from 'yup';
import useGetOrderServiceStockProducts from 'services/queries/order-services/use-get-order-service-stock-products';
import { useParams } from 'react-router-dom';
import Spinner from 'components/core/spinner';
import useTheme from '@mui/material/styles/useTheme';
import FormButtons from 'components/form-buttons';
import useFinishOS, { FinishOsPayload } from 'services/queries/order-services/use-finish-os';
import dictionary from 'utils/dictionary';
import { useEffect } from 'react';
import useGetOptions from 'services/queries/crud/use-get-options';
import { generateStocksOptionsQuery } from 'pages/private/stocks/create-or-update-purchase-order/utils';
import { getStocksOptionsKey } from 'services/queries/stocks/keys';
import { getOrderServiceKey } from 'services/queries/order-services/keys';
import { useQueryClient } from 'react-query';

type ModalFinishOSProps = {
    onClose: () => void;
};

const schema = object({
    products: array(
        object({
            productId: number().required(dictionary.validation.required),
            quantityInStock: number(),
            stocks: array(
                object({
                    stockId: number().required(dictionary.validation.required),
                    quantity: number().required(dictionary.validation.required),
                })
            )
                .test('quantityInvalid', 'A soma das quantidades solicitadas é maior que o total solicitado', (stocks = [], ctx) => {
                    const sum = stocks.reduce((acc, curr) => acc + curr.quantity, 0);
                    const quantityInStock = ctx?.parent?.quantityInStock || 0;

                    return sum <= quantityInStock;
                })
                .default([]),
        })
    ).default([]),
});

const ModalFinishOS = ({ onClose }: ModalFinishOSProps) => {
    const { palette } = useTheme();
    const queryClient = useQueryClient();
    const { saleOrderId, orderServiceId } = useParams();

    const { mutateAsync: handleFinishOS, isLoading: isSubmitting } = useFinishOS(saleOrderId, orderServiceId);
    const { data: stocks = [], isLoading: isLoadingStocks } = useGetOptions(generateStocksOptionsQuery(), getStocksOptionsKey, true);
    const { data: stockProducts = [], isLoading: isLoadingStockProducts } = useGetOrderServiceStockProducts(saleOrderId, orderServiceId);

    const methods = useForm<FinishOsPayload>({
        mode: 'onSubmit',
        defaultValues: {
            products: [],
        },
        resolver: yupResolver(schema) as any,
    });

    const { fields: products, replace } = useFieldArray({ control: methods.control, name: 'products' });

    const submit = async (data: FinishOsPayload) => {
        try {
            const payload = {
                products: data.products.map((item) => ({
                    id: item.productId,
                    stocks: item.stocks.map((stock) => ({
                        id: stock.stockId,
                        quantity: stock.quantity,
                    })),
                })),
            };

            await handleFinishOS(payload);

            await queryClient.invalidateQueries(getOrderServiceKey(orderServiceId));

            onClose();
        } catch (error) {
            console.log('submit', error);
        }
    };

    useEffect(() => {
        if (Boolean(stockProducts.length)) {
            const arr = stockProducts.map((item) => ({
                productId: item.product.id!,
                name: item.product.name!,
                quantityInStock: item.quantity || 0,
                stocks: [],
            }));

            replace(arr);
        }
    }, [stockProducts, replace]);

    return (
        <Modal
            contentClassnames="w-[800px]"
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    Encerrar OS
                </Text>
            }
            closeOnClickOutside={false}
            onClose={onClose}>
            {isLoadingStockProducts ? (
                <div className="px-6 pb-6">
                    <Spinner color={palette.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <FormProvider {...methods}>
                    <form className="px-6 pb-6" onSubmit={methods.handleSubmit(submit)}>
                        <div className="mb-6">
                            {products.map((stockProduct, index) => {
                                return <FinishOSProduct {...stockProduct} allStocks={stocks} isLoadingStocks={isLoadingStocks} index={index} key={stockProduct.id} />;
                            })}
                        </div>
                        <FormButtons
                            isLoading={isSubmitting}
                            cancelButton={{
                                onClick: onClose,
                            }}
                        />
                    </form>
                </FormProvider>
            )}
        </Modal>
    );
};

export default ModalFinishOS;
