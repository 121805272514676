import * as React from 'react';
const SvgIcoArrowDown = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 10 16" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M9.784 10.83a.83.83 0 0 0-1.173-.055L6.17 13V.83a.83.83 0 0 0-1.66 0V13l-2.442-2.224a.83.83 0 1 0-1.118 1.228L5.339 16l4.39-3.997a.83.83 0 0 0 .055-1.173Z"
            clipRule="evenodd"
        />
    </svg>
);
export default SvgIcoArrowDown;
