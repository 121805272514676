import Text from 'components/core/text';
import classNames from 'classnames';
import useResponsive from 'hooks/responsive/use-responsive';
import Breadcrumb from 'components/core/breadcrumb';

type PageHeaderProps = {
    title: React.ReactNode | string;
    titleSize?: 'small' | 'medium';
    className?: string;
    right?: JSX.Element;
};

const PageHeader = ({ className, title, titleSize = 'medium', right }: PageHeaderProps) => {
    const { isSmallerThenTabletLandscape } = useResponsive();
    const headerClasses = classNames('flex items-center justify-between', className);

    return (
        <>
            {isSmallerThenTabletLandscape && <Breadcrumb className="mb-4" />}
            <header className={headerClasses}>
                <Text as={titleSize === 'medium' ? 'h1' : 'h6'} variant={titleSize === 'medium' ? 'h4' : 'h6'} className="text-heading mr-4">
                    {title}
                </Text>
                {right}
            </header>
        </>
    );
};

export default PageHeader;
