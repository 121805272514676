import React, { useEffect, useState } from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import Header from 'components/header';
import Sidebar from 'components/sidebar';
import withAuth from 'hocs/with-auth';
import useGetCredentials from 'services/queries/credentials/use-get-credentials';
import useAuth from 'store/auth/use-auth';
import Spinner from 'components/core/spinner';
import useBreadcrumb from 'store/breadcrumb/use-breadcrumb';
import routes from 'settings/routes';
import useResponsive from 'hooks/responsive/use-responsive';
import { MainContextProvider } from './context';
import { router } from 'index';

const formatRoute = ({ ...rest }: RouteObject) => ({
    ...rest,
    children: rest.children?.map(formatRoute),
});

const MainPage = () => {
    const { auth } = useAuth();
    const { setRoutes } = useBreadcrumb();
    const { isSmallerThenLarge } = useResponsive();
    const { isLoading } = useGetCredentials(auth.isAuthenticated);

    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [isSidebarSmall, setIsSidebarSmall] = useState(false);

    const handleToggleSidebar = React.useCallback(
        (value?: boolean) => {
            if (isSmallerThenLarge) {
                return setIsMenuVisible(value ?? !isMenuVisible);
            }

            setIsSidebarSmall(value ?? !isSidebarSmall);
        },
        [isSmallerThenLarge, isMenuVisible, isSidebarSmall]
    );

    const handleRoutes = () => {
        const formattedRoutes: RouteObject[] = routes.map(formatRoute);

        setRoutes(formattedRoutes);
    };

    useEffect(() => {
        router.subscribe(() => setIsMenuVisible(false));
    }, []);

    useEffect(() => {
        handleRoutes();
        // eslint-disable-next-line
    }, []);

    if (isLoading) {
        return (
            <div className="relative w-full h-full">
                <Spinner />
            </div>
        );
    }

    return (
        <main className={styles.main}>
            {(!isSmallerThenLarge || isMenuVisible) && <Sidebar isSidebarSmall={isSidebarSmall} onClose={handleToggleSidebar} />}
            <section className={styles.content}>
                <Header onToggleSidebar={handleToggleSidebar} />
                <div className="bg-base-100 outlet p-4 pb-0 md:pl-6 md:pr-4 md:py-6 lg:bg-transparent h-full">
                    <MainContextProvider isSidebarSmall={isSidebarSmall} handleToggleSidebar={handleToggleSidebar}>
                        <Outlet />
                    </MainContextProvider>
                </div>
            </section>
        </main>
    );
};

const styles = {
    main: 'w-full h-full bg-[#f4f4f4] flex',
    content: 'flex-1 h-full max-h-full flex flex-col justify-start w-[calc(100%-270px)]',
};

export default withAuth(MainPage);
