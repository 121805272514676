import TableCell from '@mui/material/TableCell/TableCell';
import { MUIDataTableColumn } from 'mui-datatables';
import { formatMoney } from 'utils/money';
import { saleOrderOriginsLiteral, saleOrderTypes } from 'utils/statics';

export const salesOrdersColumns: MUIDataTableColumn[] = [
    {
        name: 'id',
        options: { display: 'excluded' },
    },
    {
        name: 'code',
        label: 'Código',
        options: {
            customBodyRender: (value) => value || '-',
        },
    },
    {
        name: 'type',
        label: 'Tipo do pedido',
        options: {
            customBodyRender: (type) => {
                const findedType = saleOrderTypes.find((item) => item.value === type);

                return findedType?.label || '-';
            },
        },
    },
    {
        name: 'company.name',
        label: 'Empresa',
        options: {
            customBodyRender: (value) => <span className="whitespace-nowrap">{value || '-'}</span>,
        },
    },
    {
        name: 'branch.name',
        label: 'Filial',
        options: {
            customBodyRender: (value) => <span className="whitespace-nowrap">{value || '-'}</span>,
        },
    },
    {
        name: 'customer.title',
        label: 'Cliente',
        options: {
            customBodyRender: (value) => <span className="whitespace-nowrap">{value || '-'}</span>,
        },
    },
    {
        name: 'classification.name',
        label: 'Tipo de obra',
        options: {
            customBodyRender: (value) => <span className="whitespace-nowrap">{value || '-'}</span>,
        },
    },
    {
        name: 'origin',
        label: 'Origem',
        options: {
            customBodyRender: (origin) => saleOrderOriginsLiteral[origin] || 'Serviço',
        },
    },
    {
        name: 'consultant.title',
        label: 'Consultor',
        options: {
            customBodyRender: (value) => value || '-',
        },
    },
    {
        name: 'netValue',
        label: 'Total',
        options: {
            customBodyRender: (value) => formatMoney(value),
        },
    },
    {
        name: 'actions',
        label: 'Ações',
        options: {
            customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />,
        },
    },
];
