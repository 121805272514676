import RemoveButton from 'components/buttons/remove';
import { Controller, useFormContext } from 'react-hook-form';
import Input from 'components/core/form/input';
import CurrencyInput from 'components/core/form/currency';
import Text from 'components/core/text';
import Dropzone from 'components/dropzone';
import { useCallback } from 'react';
import useUploadFile from 'services/queries/files/use-upload-file';
import { ExpenseProductsPayload } from 'types/models/expense';

type ExpenseBudgetProps = {
    index: number;
    onRemoveBudget: (index: number) => void;
};

const MIN_BUDGETS = 3;

const ExpenseBudget = ({ index, onRemoveBudget }: ExpenseBudgetProps) => {
    const { control, formState, setValue } = useFormContext<ExpenseProductsPayload>();

    const { isSuccess, uploadFile } = useUploadFile();

    const handleUploadFiles = useCallback(
        async (files: File[]) => {
            try {
                const [localFile] = files;

                const apiFile = await uploadFile(localFile);

                if (!apiFile) {
                    throw new Error('File not found');
                }

                setValue(`budgets.${index}.file`, apiFile.id);
            } catch (error) {
                console.log('handleUploadFiles', error);
            }
        },
        [uploadFile, setValue, index]
    );

    return (
        <div className="border border-base-300 p-4 rounded-[14px] flex mb-4 items-center">
            <Controller
                control={control}
                name={`budgets.${index}.provider`}
                render={({ field }) => {
                    return <Input {...field} autoFocus={false} label="Fornecedor" parentClassName="mr-4" error={formState.errors.budgets?.[index]?.provider?.message} />;
                }}
            />
            <Controller
                name={`budgets.${index}.value`}
                control={control}
                render={({ field }) => {
                    return (
                        <CurrencyInput
                            left={
                                <Text as="span" variant="body.medium.sm" className="text-heading">
                                    R$
                                </Text>
                            }
                            autoFocus={false}
                            label="Valor"
                            value={field.value}
                            onValueChange={({ floatValue }) => setValue(`budgets.${index}.value`, floatValue)}
                            placeholder="Ex: 1000"
                            parentClassName="w-[150px] mr-4"
                            error={formState.errors.budgets?.[index]?.value?.message}
                        />
                    );
                }}
            />
            <div>
                <Dropzone
                    isSuccess={isSuccess}
                    hasText={false}
                    error={formState.errors.budgets?.[index]?.file?.message}
                    svgClasses="!mb-0"
                    containerClasses="h-[48px] flex !flex-row mr-4 !w-auto mt-[26px]"
                    multiple={false}
                    onUploadFiles={handleUploadFiles}
                />
            </div>
            {index > MIN_BUDGETS - 1 && <RemoveButton className="!static opacity-100 mt-[26px]" onClick={() => onRemoveBudget(index)} />}
        </div>
    );
};

export default ExpenseBudget;
