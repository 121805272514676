import * as React from 'react';
const SvgIcoReadMail = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" width="1em" height="1em" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M5.064 3.685A.63.63 0 0 1 5.51 3.5h8.28a.63.63 0 0 1 .63.631v3.836l-4.77 3.518-4.77-3.517V4.131c0-.167.066-.328.184-.446ZM3.499 8.814v7.141c0 .253.24.545.63.545H15.17c.392 0 .63-.292.63-.545V8.813l-5.706 4.208a.75.75 0 0 1-.89 0L3.5 8.814ZM15.92 4.13v2.144c.788.287 1.381 1.012 1.381 1.912v7.768c0 1.171-1.003 2.045-2.13 2.045H4.13C3.003 18 2 17.127 2 15.955V8.187c0-.9.593-1.625 1.38-1.912V4.131A2.132 2.132 0 0 1 5.51 2h8.28a2.13 2.13 0 0 1 2.13 2.131ZM6.39 7.583a.5.5 0 0 1 .5-.5h5.52a.5.5 0 0 1 0 1H6.89a.5.5 0 0 1-.5-.5Zm.5-2.571a.5.5 0 0 0 0 1h1.38a.5.5 0 0 0 0-1H6.89Z"
            clipRule="evenodd"
        />
    </svg>
);
export default SvgIcoReadMail;
