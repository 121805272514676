import { useQuery } from 'react-query';
import { getSaleOrderProductsKey } from './keys';
import apiGraphQl from 'services/graphql';
import { Pagination } from 'types/pagination';
import { SaleOrderProduct } from 'types/models/sale-order';

const useGetSaleOrderProducts = (query: string, saleOrderId?: string) => {
    const request = async () => {
        const { items } = await apiGraphQl<Pagination<Partial<SaleOrderProduct>>>(query);

        return items;
    };

    return useQuery(getSaleOrderProductsKey(saleOrderId), request);
};

export default useGetSaleOrderProducts;
