import ItemFooter from './item-footer';
import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import Text from 'components/core/text';
import dayjs from 'dayjs';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import CurrencyInput from 'components/core/form/currency';
import { array, boolean, number, object, string } from 'yup';
import dictionary from 'utils/dictionary';
import { yupResolver } from '@hookform/resolvers/yup';
import DatePicker from 'components/core/datepicker';
import Select from 'components/core/form/select';
import Switch from 'components/core/switch';
import { useExpensesContext } from './context';
import { formatMoney, generateInstallments } from 'utils/money';
import _sumBy from 'lodash/sumBy';
import useGetClassifications from 'services/queries/classifications/use-get-classifications';
import { generateClassificationQuery } from 'services/queries/classifications/utils';
import { ClassificationType } from 'types/models/classification';
import { generateDueAndProvisioningDates } from './utils';
import { useMemo } from 'react';
import { currencyToFloat } from 'utils/money';
import AddButton from 'components/buttons/add';
import { useParams } from 'react-router';
import RemoveButton from 'components/buttons/remove';
import { excludeDates } from 'pages/private/payment-order/actions/ops-grouping/general';
import { Steps } from './context/types';

export type ExpenseInstallments = {
    id?: number;
    dueDate: string;
    provisioning?: string;
    provisioned: boolean;
    value: number;
    paymentForm: number;
    transactionId?: number;
    paid?: boolean;
};

type Payload = {
    installments: Array<ExpenseInstallments>;
};

const schema = object({
    installments: array(
        object({
            dueDate: string().required(dictionary.validation.required),
            provisioning: string().required(dictionary.validation.required),
            provisioned: boolean().default(false).notRequired(),
            value: number().required(dictionary.validation.required),
            paymentForm: number().required(dictionary.validation.required),
        })
    )
        .min(1, dictionary.validation.array.min(1))
        .required(dictionary.validation.required),
});

const Installments = () => {
    const { expenseId } = useParams();
    const { goToStep, state, setContent, completeStep } = useExpensesContext();

    const { data: paymentForms = [] } = useGetClassifications(generateClassificationQuery(ClassificationType.PaymentForm));

    const isUpdate = !!expenseId;

    const numberOfInstallments = state?.content?.general?.installments!;

    const expenseValue = useMemo(() => {
        // If the expense has products, use the sum of all products
        if (!!state?.content?.general?.withProducts) {
            const total = _sumBy(state?.content?.products, ({ quantity, value }) => quantity * value);

            return Number(total.toFixed(2));
        }

        // If the expense hasnt products, use the "expense value" field
        return currencyToFloat(state?.content?.general?.amount! || '0');
    }, [state?.content]);

    const installments =
        numberOfInstallments === state.content?.installments?.length || isUpdate
            ? state.content?.installments
            : Array.from(Array(numberOfInstallments), (_, index) => {
                  const dueDate = dayjs(state.content?.general?.dueDate).add(index, 'month').toISOString();
                  const { firstPaymentForecast, restPaymentForecast } = generateDueAndProvisioningDates(dueDate, index);

                  return {
                      dueDate,
                      provisioning: !index ? firstPaymentForecast : restPaymentForecast,
                      provisioned: false,
                      value: generateInstallments(expenseValue, numberOfInstallments)[index],
                      paymentForm: state.content?.general?.paymentForm?.value || paymentForms[0]?.value,
                      paid: false,
                  };
              });

    const { formState, handleSubmit, control, watch, getValues } = useForm<Payload>({
        defaultValues: { installments },
        resolver: yupResolver(schema) as any,
    });

    const { fields, append, remove } = useFieldArray({ control, name: 'installments' });

    const submit = async (data: Payload) => {
        setContent({ installments: data.installments });
        completeStep(Steps.Installments);
        goToStep('next');
    };

    const handleBack = () => {
        setContent({ installments: getValues().installments });
        goToStep('previous');
    };

    const total = watch('installments').reduce((acc, curr) => acc + curr.value, 0);
    const remainingValue = Number(expenseValue.toFixed(2)) - Number(total.toFixed(2));

    // The form always be valid if the current page is expense update
    const isFormValid = isUpdate || Number(expenseValue.toFixed(2)) === Number(total.toFixed(2));

    const handleAddInstallment = () => {
        append(
            {
                dueDate: '',
                paymentForm: fields[0].paymentForm,
                provisioning: '',
                provisioned: false,
                value: 0,
                paid: false,
            },
            { shouldFocus: false }
        );
    };

    return (
        <form onSubmit={handleSubmit(submit)}>
            <div className="w-full overflow-x-auto pt-4">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Text className="text-base-500 uppercase">Referente a parcela</Text>
                            </TableCell>
                            <TableCell>
                                <Text className="text-base-500 uppercase">Vencimento</Text>
                            </TableCell>
                            <TableCell>
                                <Text className="text-base-500 uppercase">Provisionamento</Text>
                            </TableCell>
                            <TableCell>
                                <Text className="text-base-500 uppercase">Provisionada</Text>
                            </TableCell>
                            <TableCell>
                                <Text className="text-base-500 uppercase">Valor da Parcela</Text>
                            </TableCell>
                            <TableCell>
                                <Text className="text-base-500 uppercase">Forma de Pagamento</Text>
                            </TableCell>
                            {isUpdate && (
                                <TableCell>
                                    <Text className="text-base-500 uppercase">Ações</Text>
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fields.map((item, index) => {
                            const getError = (name: string) => formState?.errors?.installments?.[index]?.[name]?.message;
                            const canShowRemoveButton = !item.paid && index !== 0;

                            return (
                                <TableRow
                                    classes={{ root: !!item.transactionId && item.paid ? 'bg-base-300 opacity-30 cursor-no-drop pointer-events-none' : 'opacity-100 hover:bg-base-200' }}
                                    key={item.id}>
                                    <TableCell>
                                        <span className="text-heading text-base font-medium">Parcela {index + 1}</span>
                                    </TableCell>
                                    <TableCell>
                                        <Controller
                                            name={`installments.${index}.dueDate`}
                                            control={control}
                                            render={({ field }) => (
                                                <DatePicker
                                                    inputProps={{
                                                        placeholderText: 'Vencimento',
                                                        error: getError('dueDate'),
                                                        ...field,
                                                    }}
                                                />
                                            )}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Controller
                                            name={`installments.${index}.provisioning`}
                                            control={control}
                                            render={({ field }) => {
                                                return (
                                                    <DatePicker
                                                        inputProps={{
                                                            placeholderText: 'Selecione a data de previsão',
                                                            error: getError('provisioning'),
                                                            ...field,
                                                        }}
                                                        muiDatePickerProps={{
                                                            shouldDisableDate: excludeDates,
                                                        }}
                                                        withProvisionLogic={true}
                                                    />
                                                );
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Controller
                                            name={`installments.${index}.provisioned`}
                                            control={control}
                                            render={({ field }) => <Switch value={field.value} onChange={(value) => field.onChange(value)} defaultLabel="Não" activeLabel="Sim" />}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Controller
                                            name={`installments.${index}.value`}
                                            control={control}
                                            render={({ field }) => {
                                                return (
                                                    <CurrencyInput
                                                        left={
                                                            <Text as="span" variant="body.medium.sm" className="text-heading">
                                                                R$
                                                            </Text>
                                                        }
                                                        value={field.value}
                                                        onValueChange={({ floatValue = 0 }) => field.onChange(floatValue)}
                                                        placeholder="Valor"
                                                        parentClassName="w-[150px]"
                                                        error={getError('value')}
                                                    />
                                                );
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Controller
                                            name={`installments.${index}.paymentForm`}
                                            control={control}
                                            render={({ field }) => {
                                                const value = paymentForms.find((item) => item.value === field.value);

                                                return (
                                                    <Select
                                                        {...field}
                                                        value={value}
                                                        options={paymentForms}
                                                        placeholder="Forma de Pagamento"
                                                        error={getError('paymentForm')}
                                                        onChange={(option: any) => field.onChange(option.value)}
                                                    />
                                                );
                                            }}
                                        />
                                    </TableCell>
                                    {isUpdate && <TableCell>{canShowRemoveButton && <RemoveButton className="!static opacity-100 mt-0" onClick={remove.bind(this, index)} />}</TableCell>}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </div>
            <div className="bg-secondary-100 py-4 px-6 bg-opacity-20 flex items-center">
                {isUpdate && <AddButton onClick={handleAddInstallment}>Nova parcela</AddButton>}
                <div className="flex-1 text-right">
                    <div>
                        <Text className="text-base-700">Total das parcelas: </Text>
                        <Text variant="body.medium.2xs" className="text-heading">
                            {formatMoney(total)}
                        </Text>
                    </div>
                    {!!remainingValue && (
                        <div>
                            <Text className="text-system-danger-500">{Math.sign(remainingValue) !== -1 ? 'Faltando: ' : 'Ultrapassando: '}</Text>
                            <Text variant="body.medium.2xs" className="text-system-danger-500">
                                {formatMoney(Math.abs(remainingValue))}
                            </Text>
                        </div>
                    )}
                </div>
            </div>
            <ItemFooter isValid={isFormValid} onGoBack={handleBack} />
        </form>
    );
};

export default Installments;
