import * as React from 'react';
const SvgIcoSearch = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M1.75 7a5.25 5.25 0 1 1 10.5 0 5.25 5.25 0 0 1-10.5 0ZM7 .25a6.75 6.75 0 1 0 4.213 12.024l3.506 3.506a.75.75 0 0 0 1.06-1.06l-3.505-3.507A6.75 6.75 0 0 0 7 .25Z"
            clipRule="evenodd"
        />
    </svg>
);
export default SvgIcoSearch;
