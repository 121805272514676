import * as React from 'react';
const SvgIcoTasksCheck = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 18 20" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M5.125 1.675c0-.111.084-.175.156-.175h5.438c.071 0 .156.064.156.175v1.85c0 .111-.085.175-.156.175H5.28c-.072 0-.156-.064-.156-.175v-1.85Zm7.25 1.676v.174c0 .91-.727 1.675-1.656 1.675H5.28c-.929 0-1.656-.765-1.656-1.675V3.35H2.562c-.276 0-.545.112-.745.316a1.12 1.12 0 0 0-.317.783V17.4c0 .296.116.578.317.783.2.204.469.316.745.316h10.875c.277 0 .545-.112.746-.316a1.12 1.12 0 0 0 .316-.784v-4.912l1.5-1.42v6.332a2.62 2.62 0 0 1-.745 1.833c-.479.49-1.132.767-1.817.767H2.562a2.544 2.544 0 0 1-1.817-.767A2.62 2.62 0 0 1 0 17.4V4.45c0-.684.266-1.344.745-1.833a2.544 2.544 0 0 1 1.817-.766h1.063v-.176C3.625.765 4.352 0 5.281 0h5.438c.929 0 1.656.764 1.656 1.675v.176h1.062c.685 0 1.338.277 1.817.766.185.19.339.404.457.636a3.04 3.04 0 0 0-.867.577l-.376.356a1.11 1.11 0 0 0-.285-.519c-.2-.204-.47-.316-.746-.316h-1.062Zm5.142 1.862a.725.725 0 0 1 0 1.066l-8.922 8.453a.803.803 0 0 1-.603.26.813.813 0 0 1-.563-.221l-.029-.028-.01-.01-3.157-2.992a.726.726 0 0 1 0-1.066.826.826 0 0 1 1.126 0l2.634 2.495 8.398-7.957a.826.826 0 0 1 1.126 0Z"
            clipRule="evenodd"
        />
    </svg>
);
export default SvgIcoTasksCheck;
