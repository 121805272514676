import { useOutletContext, useParams } from 'react-router-dom';
import CreateOrUpdatePersonBankAccount from './create-or-update';
import { CrudPageProps, GraphqlPaginationVariables } from 'types/graphql';
import useGetPersonBankAccount from 'services/queries/people/use-get-person-bank-account';
import useCreateOrUpdatePersonBankAccount from 'services/queries/people/use-create-or-update-person-bank-account';
import { BankAccount, BankAccountType } from 'types/models/bank';
import { useMemo } from 'react';
import { CreateOrUpdateBankAccountPayload } from 'types/models/bank-accounts';

const CreateOrUpdatePersonBankAccountPage = ({ title }: CrudPageProps<{}>) => {
    const { personId, bankAccountId } = useParams();

    const params = useOutletContext<GraphqlPaginationVariables<BankAccount>>();

    const { data: personBankAccountDetails, isLoading: isLoadingDetails } = useGetPersonBankAccount(personId, bankAccountId);
    const { mutateAsync: createOrUpdateBankAccount, isLoading: isSubmiting, error } = useCreateOrUpdatePersonBankAccount(personId, bankAccountId, params);

    const handleSubmit = async (data: BankAccount & { bank: number; main: boolean }) => {
        try {
            const payload = {
                bank: data.bank,
                agency: data.agency,
                agencyDigit: data.agencyDigit,
                account: data.account,
                accountDigit: data.accountDigit,
                pix: data.pix,
                pixType: data.pixType,
                type: data.type,
                name: data.name,
            };

            await createOrUpdateBankAccount(payload);
        } catch (error) {
            console.log('error', error);
        }
    };

    const defaultValues = useMemo<Partial<CreateOrUpdateBankAccountPayload>>(
        () => ({
            bank: personBankAccountDetails?.bank.id,
            name: personBankAccountDetails?.name,
            agency: personBankAccountDetails?.agency || '',
            agencyDigit: personBankAccountDetails?.agencyDigit || '',
            account: personBankAccountDetails?.account || '',
            accountDigit: personBankAccountDetails?.accountDigit || '',
            pix: personBankAccountDetails?.pix || '',
            pixType: personBankAccountDetails?.pixType || '',
            type: BankAccountType.Open,
        }),
        [personBankAccountDetails]
    );

    return <CreateOrUpdatePersonBankAccount title={title || ''} isBuilding={isLoadingDetails} isSubmitting={isSubmiting} onSubmit={handleSubmit} defaultValues={defaultValues} error={error} />;
};

export default CreateOrUpdatePersonBankAccountPage;
