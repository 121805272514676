import { useQuery } from 'react-query';
import { getSaleOrderProductKey } from './keys';
import apiGraphQl from 'services/graphql';
import { SaleOrderProduct } from 'types/models/sale-order';
import { ExpenseProducts } from 'types/models/expense';

type Response = {
    saleOrderProductByPk: Partial<SaleOrderProduct> & Pick<ExpenseProducts, 'classificationByClassificationUnitId'>;
};

const useGetSaleOrderProduct = (query?: string, saleOrderProductId?: string) => {
    const request = async () => {
        const { saleOrderProductByPk } = await apiGraphQl<Response>(query!);

        return saleOrderProductByPk;
    };

    return useQuery(getSaleOrderProductKey(saleOrderProductId), request, {
        enabled: Boolean(saleOrderProductId) && Boolean(query),
        cacheTime: 0,
    });
};

export default useGetSaleOrderProduct;
