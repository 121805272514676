import classnames from 'classnames';
import ReportPageDetailsBlockTableBody from './body';
import ReportPageDetailsBlockTableHeader from './header';
import { useMemo } from 'react';
import Card from 'components/core/card';
import _max from 'lodash/max';
import _sum from 'lodash/sum';
import './table.css';
import Empty from 'components/empty';
import Spinner from 'components/core/spinner';
import { DRETableProps } from 'types/models/reports';

type ReportPageDetailsBlockTableProps = {
    withCard?: boolean;
    rounded?: boolean;
} & DRETableProps;

const ReportPageDetailsBlockTable = ({
    isLoading,
    fontSize,
    items = [],
    headers = [],
    minHeaderWidth,
    className,
    title,
    hasFirstEmptyHeaderColumn = true,
    emptyHeaderColumnWidth,
    withCard = true,
    rounded = true,
}: ReportPageDetailsBlockTableProps) => {
    const table = classnames(`dre-table table-auto border-collapse w-full text-base-500`);

    const numberOfCols = useMemo(() => _max(headers.map((items) => _sum(items.map(({ colSpan = 1 }) => colSpan)))) || 0, [headers]);

    const Container = withCard ? Card : 'div';

    return (
        <Container className={classnames('p-5 overflow-x-auto', className)}>
            {title && <h3 className="mb-5 font-medium text-heading text-lg">{title}</h3>}
            {!isLoading && items?.length ? (
                <table className={table} style={{ fontSize }}>
                    <ReportPageDetailsBlockTableHeader
                        rounded={rounded}
                        hasFirstEmptyHeaderColumn={hasFirstEmptyHeaderColumn}
                        minHeaderWidth={minHeaderWidth}
                        headers={headers}
                        emptyHeaderColumnWidth={emptyHeaderColumnWidth}
                    />
                    <ReportPageDetailsBlockTableBody items={items} numberOfCols={numberOfCols} />
                </table>
            ) : (
                !isLoading && <Empty title="Nenhum registro encontrado." />
            )}

            {isLoading && <Spinner fixed={false} />}
        </Container>
    );
};

export default ReportPageDetailsBlockTable;
