import Datatable from 'components/core/table/datatable';
import { useMemo, useState } from 'react';
import PageHeader from 'components/page/header';
import IconButton from '@mui/material/IconButton/IconButton';
import useTheme from '@mui/material/styles/useTheme';
import { Link, Outlet, useParams } from 'react-router-dom';
import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import { listStockPurchaseOrderColumns } from './utils';
import useGetPaginatedWithFilters from 'services/queries/general/use-get-paginated-with-filters';
import { PurchaseOrder } from 'types/models/purchase-order';
import SvgIcoPlus from 'components/core/icon/files/ico-plus';

const ListStockPurchaseOrderPage = ({ page: pageWithPermissions }: WithPagePermissionsProps) => {
    const { palette } = useTheme();
    const { stockId } = useParams();

    const endpoint = !!stockId ? `/stocks/${stockId}/purchase-orders` : '/stock-purchase-orders';

    const [filters, setFilters] = useState<any>({});
    const [page, setPage] = useState(1);

    const { data: stocks, isLoading } = useGetPaginatedWithFilters<Partial<PurchaseOrder>>({
        page,
        filters,
        endpoint,
    });

    const actions = useMemo(() => pageWithPermissions?.rules.map((item) => item.key) || [], [pageWithPermissions?.rules]);

    return (
        <>
            {!stockId && (
                <PageHeader
                    title="Estoques ordens de compra"
                    className="mb-8"
                    right={
                        <IconButton component={Link} to="novo" color="secondary" size="large" sx={{ backgroundColor: palette.secondary.main, '&:hover': { backgroundColor: palette.secondary.dark } }}>
                            <SvgIcoPlus width={16} height={16} color={palette.grey[100]} />
                        </IconButton>
                    }
                />
            )}
            <Datatable
                actions={actions}
                columns={listStockPurchaseOrderColumns}
                data={stocks?.items || []}
                loading={isLoading}
                options={{
                    sort: true,
                    selectableRowsHeader: false,
                    selectableRowsHideCheckboxes: true,
                    filter: false,
                    search: true,
                    searchAlwaysOpen: true,
                    print: false,
                    download: false,
                    viewColumns: false,
                    serverSide: true,
                    enableNestedDataAccess: '.',
                    pagination: true,
                    elevation: 21,
                    count: stocks?.total,
                    page,
                    searchPlaceholder: 'Buscar estoque',
                    onChangePage: setPage,
                    onSearchChange: (searchText) => {
                        setFilters((prev) => ({ ...prev, searchText }));
                    },
                }}
            />
            <Outlet />
        </>
    );
};

export default ListStockPurchaseOrderPage;
