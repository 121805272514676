import { useEffect, useMemo } from 'react';
import { number, object, string } from 'yup';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Page from './page';
import Text from 'components/core/text';
import Modal from 'components/core/modal';
import Spinner from 'components/core/spinner';
import Input from 'components/core/form/input';
import theme from 'settings/theme';
import dictionary from 'utils/dictionary';
import { Profile } from 'types/models/profile';
import { PagesResponse } from 'services/queries/pages/use-get-pages';
import { Modify, Option } from 'types/general';
import Select from 'components/core/form/select';
import FormButtons from 'components/form-buttons';

export type RulesPayload = {
    pageId: number;
    rulesIds: number[];
};

type ProfileCreateProps = {
    dashboards: Option[];
    defaultValues: Pick<Profile, 'name' | 'profilePageRules'>;
    pages: PagesResponse['items'];
    isLoading: boolean;
    isSubmittingForm: boolean;
    selectedRules: RulesPayload[];
    onSelectRules: (pageId: number, rules: number[]) => void;
    onSubmit: (data: any) => void;
    profile?: Partial<Profile>;
};

type Payload = Modify<Pick<Profile, 'name' | 'profilePageRules' | 'dashboardTemplateId'>, { profilePageRules?: any }>;

const schema = object({
    name: string().required(dictionary.validation.required),
    dashboardTemplateId: number().required(dictionary.validation.required),
});

export const groupBy = (list, keyGetter) => {
    const map = new Map();

    list.forEach((item) => {
        const key = keyGetter(item);

        const collection = map.get(key);

        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });

    return map;
};

const CreateOrUpdateProfile = ({ defaultValues, dashboards, pages, isLoading, isSubmittingForm, selectedRules, profile, onSelectRules, onSubmit }: ProfileCreateProps) => {
    const { profileId } = useParams();

    const { control, handleSubmit, formState, reset } = useForm<Payload>({
        mode: 'onSubmit',
        resolver: yupResolver(schema),
        defaultValues,
    });

    const isEmpty = useMemo(() => !Boolean(pages?.length), [pages]);

    const handleSelectItem = (pageId: number) => (values: number[]) => onSelectRules(pageId, values);

    useEffect(() => {
        if (!!profile) {
            reset({
                name: profile.name,
                dashboardTemplateId: profile.dashboardTemplateId,
                profilePageRules: Array.from(groupBy(profile?.profilePageRules || [], ({ pageId }) => pageId)).map(([key, value]) => ({
                    pageId: key,
                    rulesIds: value.map(({ ruleId }) => ruleId),
                })),
            });
        }
    }, [profile, reset]);

    return (
        <Modal closeOnClickOutside={false} contentClassnames="w-[1000px]">
            {isLoading ? (
                <div className="p-4">
                    <Spinner color={theme.extend.colors.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <form className="px-4 py-5 md:px-7" onSubmit={handleSubmit(onSubmit)}>
                    <Text as="h3" variant="h4" className="text-heading mb-5">
                        {!!profileId ? 'Atualizar perfil' : 'Novo perfil'}
                    </Text>
                    <div className="flex flex-col md:grid md:grid-cols-2 gap-4 mb-6">
                        <Controller name="name" control={control} render={({ field }) => <Input {...field} label="Nome" error={formState.errors.name?.message} />} />
                        <Controller
                            name="dashboardTemplateId"
                            control={control}
                            render={({ field }) => {
                                const value = dashboards.find((item) => item.value === field.value);

                                return (
                                    <Select
                                        {...field}
                                        value={value}
                                        options={dashboards}
                                        label="Dashboard"
                                        placeholder="Selecione uma opção"
                                        error={formState.errors.dashboardTemplateId?.message}
                                        onChange={(option: any) => field.onChange(option.value)}
                                    />
                                );
                            }}
                        />
                    </div>
                    <Text as="h6" variant="h6" className="text-heading mb-1">
                        Páginas
                    </Text>
                    <Text as="span" className="block mb-6 text-base-500 italic" variant="body.regular.xs">
                        Selecione as permissões que este perfil possuirá em cada página.
                    </Text>
                    {isEmpty ? (
                        <Text as="p" className="text-base-400 italic mb-7">
                            Nenhum resultado encontrado.
                        </Text>
                    ) : (
                        <>
                            <div className="md:grid md:grid-cols-4 gap-10 mb-8">
                                {pages?.map((item) => {
                                    const pageSelected = selectedRules.find((rule) => rule.pageId === item.id);

                                    return <Page {...item} rulesSelecteds={pageSelected?.rulesIds} title={item.name} rules={item.rules} key={item.id} onSelectItem={handleSelectItem(item.id)} />;
                                })}
                            </div>
                            <FormButtons isLoading={isSubmittingForm} />
                        </>
                    )}
                </form>
            )}
        </Modal>
    );
};

export default CreateOrUpdateProfile;
