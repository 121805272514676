import TableCell from '@mui/material/TableCell/TableCell';
import { CrudPageProps } from 'types/graphql';
import dictionary from 'utils/dictionary';

const config: CrudPageProps<{}>[] = [
    {
        graphql: {
            table: 'profile',
            searchableField: 'name',
            columns: [
                {
                    name: 'id',
                    options: { display: 'excluded' }
                },
                {
                    name: 'name',
                    label: dictionary.content.graphql['name']
                },
                {
                    name: 'slug',
                    label: dictionary.content.graphql['slug']
                },
                {
                    name: 'actions',
                    label: 'Ações',
                    options: {
                        customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />
                    }
                }
            ],
            where: {
                system: { _eq: false }
            }
        },
        title: 'Perfis'
    }
];

export default config;
