import { memo, PropsWithChildren, useRef } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import IconButton from '@mui/material/IconButton/IconButton';
import theme from 'settings/theme';
import useClickOutside from 'hooks/mouse/use-click-outside';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';
import SvgIcoClose from '../icon/files/ico-close';

type ModalProps = {
    headerLeft?: JSX.Element;
    headerRight?: JSX.Element;
    contentClassnames?: string;
    containerClassnames?: string;
    headerClassNames?: string;
    onClose?: () => void;
    /** * @default true */
    closeOnClickOutside?: boolean;
    /** * @default false */
    hideCloseButton?: boolean;
};

const Modal = ({
    children,
    closeOnClickOutside = true,
    contentClassnames,
    headerLeft,
    headerRight,
    headerClassNames,
    onClose,
    hideCloseButton = false,
    containerClassnames,
}: PropsWithChildren<ModalProps>) => {
    const ref = useRef<HTMLDivElement>(null);
    const goToParentRoute = useNavigateToParentRoute();

    const contentClasses = classNames(styles.content, contentClassnames);
    const containerClasses = classNames(styles.container, containerClassnames);
    const headerClasses = classNames(styles.header, Boolean(headerLeft) ? 'py-4 px-6' : 'p-0', headerClassNames);

    const closeButton = classNames(!Boolean(headerLeft) && Boolean(onClose) ? '!absolute right-4 top-4 z-50' : '', 'bg-base-200');

    const handleClose = () => {
        if (!!onClose) {
            return onClose();
        }

        goToParentRoute();
    };

    useClickOutside(ref, () => {
        if (!closeOnClickOutside) {
            return;
        }

        handleClose();
    });

    return ReactDOM.createPortal(
        <div className={containerClasses}>
            <div ref={ref} className={contentClasses}>
                {(Boolean(onClose) || Boolean(headerLeft)) && (
                    <header className={headerClasses}>
                        <div className="flex-1">{headerLeft}</div>
                        <div className="flex items-center">
                            {headerRight && <div className="mr-4">{headerRight}</div>}
                            {!hideCloseButton && (
                                <IconButton type="button" className={closeButton} size="large" color="default" onClick={handleClose}>
                                    <SvgIcoClose color={theme.extend.colors.heading} width={10} height={10} />
                                </IconButton>
                            )}
                        </div>
                    </header>
                )}
                {children}
            </div>
        </div>,
        document.body
    );
};

const styles = {
    container: 'fixed left-0 top-0 w-full h-full bg-base-700/30 flex items-center justify-center z-[9998] overflow-y-auto',
    content: 'bg-base-100 rounded-2xl max-w-[90%] max-h-[90%] overflow-y-auto relative shadow-md',
    header: 'flex items-center sticky left-0 top-0 z-10 bg-base-100',
};

export default memo(Modal);
