import IconButton from '@mui/material/IconButton';
import useTheme from '@mui/material/styles/useTheme';
import Datatable from 'components/core/table/datatable';
import PageHeader from 'components/page/header';
import { Link, Outlet, useParams } from 'react-router-dom';
import useGetSaleOrderExpenses from 'services/queries/sales-order/use-get-sale-order-expenses';
import { saleOrderExpensesListColumns } from './utils';
import { useCallback, useState } from 'react';
import QueryString from 'qs';
import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import ConfirmModal from 'components/core/modal/confirm';
import useDelete from 'services/queries/crud/use-delete';
import { useQueryClient } from 'react-query';
import { getSaleOrderExpensesKey } from 'services/queries/sales-order/keys';
import useConfig from 'store/config/use-config';
import SvgIcoPlus from 'components/core/icon/files/ico-plus';

const SaleOrderExpensesPage = ({ page: routePage }: WithPagePermissionsProps) => {
    const { config } = useConfig();
    const { palette } = useTheme();
    const { saleOrderId } = useParams();
    const queryClient = useQueryClient();

    const [page, setPage] = useState(1);
    const [expenseIdToDelete, setExpenseIdToDelete] = useState<number>();

    const queryFilters = QueryString.stringify(
        {
            page,
            totalPerPage: config.tables.rowsPerPage,
        },
        { addQueryPrefix: true, encode: false, arrayFormat: 'brackets', skipNulls: true }
    );

    const { data: expenses, isLoading: isLoadingExpenses } = useGetSaleOrderExpenses(saleOrderId, queryFilters);

    const { mutateAsync: deleteExpense, isLoading: isDeletingBankAccount } = useDelete<any>({
        apiPayload: { endpoint: `/expenses`, useApiTodelete: true },
    });

    const handleDeleteExpense = useCallback(async () => {
        try {
            if (!expenseIdToDelete) {
                return;
            }

            await deleteExpense({ id: expenseIdToDelete });

            queryClient.invalidateQueries(getSaleOrderExpensesKey(saleOrderId));

            setExpenseIdToDelete(undefined);
        } catch (error) {
            console.error('Houve um erro ao deletar', error);
        }
    }, [queryClient, expenseIdToDelete, deleteExpense, saleOrderId]);

    return (
        <>
            <PageHeader
                className="mb-8"
                title="Despesas"
                right={
                    <IconButton component={Link} to="novo" color="secondary" size="large" sx={{ backgroundColor: palette.secondary.main, '&:hover': { backgroundColor: palette.secondary.dark } }}>
                        <SvgIcoPlus width={16} height={16} color={palette.grey[100]} />
                    </IconButton>
                }
            />
            <Datatable
                actions={routePage?.rules.map(({ key }) => key) || []}
                data={expenses?.items || []}
                columns={saleOrderExpensesListColumns}
                loading={isLoadingExpenses}
                options={{
                    enableNestedDataAccess: '.',
                    elevation: 21,
                    search: true,
                    searchAlwaysOpen: true,
                    filter: false,
                    selectableRows: 'none',
                    searchPlaceholder: 'Buscar despesa',
                    page,
                    pagination: true,
                    onChangePage: setPage,
                    serverSide: true,
                }}
                onDelete={setExpenseIdToDelete}
            />
            <Outlet />
            {Boolean(expenseIdToDelete) && (
                <ConfirmModal
                    title="Apagar despesa"
                    description="Você tem certeza que deseja apagar esta despesa?"
                    isLoading={isDeletingBankAccount}
                    onClose={setExpenseIdToDelete.bind(this, undefined)}
                    onConfirmAction={handleDeleteExpense}
                />
            )}
        </>
    );
};

export default SaleOrderExpensesPage;
