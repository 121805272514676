import IconButton from '@mui/material/IconButton/IconButton';
import useTheme from '@mui/material/styles/useTheme';
import Button from 'components/core/button';
import SvgIcoChevronDown from 'components/core/icon/files/ico-chevron-down';
import SvgIcoDots from 'components/core/icon/files/ico-dots';
import Menu from 'components/menu';
import useResponsive from 'hooks/responsive/use-responsive';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

type SaleOrderActionsProps = {
    onCancelSaleOrder: () => void;
};

const SaleOrderActions = ({ onCancelSaleOrder }: SaleOrderActionsProps) => {
    const navigate = useNavigate();
    const { palette } = useTheme();
    const { isMobile } = useResponsive();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleClose = () => setAnchorEl(null);
    const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

    const content = [
        {
            label: 'Imprimir escopo',
            icon: {
                color: palette.grey[500],
                name: 'ico-printer',
            },
            onClick: () => null,
        },
        {
            label: 'Editar pedido',
            icon: {
                color: palette.grey[500],
                name: 'ico-edit',
            },
            onClick: () => {
                navigate('editar');
                setAnchorEl(null);
            },
        },
    ];

    const footer = useMemo(() => {
        return [
            {
                className: 'text-system-danger-500',
                label: 'Cancelar pedido',
                icon: {
                    color: palette.error.main,
                    name: 'ico-trash',
                },
                onClick: onCancelSaleOrder,
            },
        ];
    }, [palette, onCancelSaleOrder]);

    return (
        <div className="fixed right-4 bottom-16 sm:bottom-4">
            {isMobile ? (
                <IconButton size="large" type="button" color="primary" sx={{ backgroundColor: palette.primary[500] }} onClick={handleOpen}>
                    <SvgIcoDots width={20} height={20} color="#FFF" />
                </IconButton>
            ) : (
                <Button type="button" variant="contained" color="primary" onClick={handleOpen} endIcon={<SvgIcoChevronDown width={13} color="#fff" />}>
                    Ações
                </Button>
            )}
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} content={content} footer={footer} />
        </div>
    );
};

export default SaleOrderActions;
