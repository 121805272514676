import Input from 'components/core/form/input';
import Select from 'components/core/form/select';
import { Controller, useFormContext } from 'react-hook-form';
import _get from 'lodash/get';
import { genders } from 'utils/statics';
import Phones from 'components/phones';

type ContactsFieldsProps = {
    baseName?: string;
    index?: number;
    withUser?: boolean;
};

const ContactsFields = ({ baseName, index }: ContactsFieldsProps) => {
    const { control, formState } = useFormContext();

    const getName = (name: string) => {
        const start = baseName ? `${baseName}.` : '';

        return index !== undefined ? `${start}${index}.${name}` : `${start}${name}`;
    };

    const getError = (name: string): any => _get(formState.errors, `${getName(name)}.message`);

    return (
        <>
            <div className="md:grid md:grid-cols-3 gap-4 mb-4">
                <Controller
                    name={getName('name')}
                    control={control}
                    render={({ field }) => <Input {...field} autoFocus={true} error={getError('name')} label="Nome" inputWrapperClasses="mb-4 md:mb-0" />}
                />
                <Controller
                    name={getName('title')}
                    control={control}
                    render={({ field }) => <Input {...field} autoFocus={false} error={getError('title')} label="Título" inputWrapperClasses="mb-4 md:mb-0" />}
                />
                <Controller
                    name={getName('gender')}
                    control={control}
                    render={({ field }) => {
                        const value = genders.find((item) => item.value === field.value);

                        return (
                            <Select
                                {...field}
                                value={value}
                                options={genders}
                                label="Gênero"
                                placeholder="Selecione uma opção"
                                error={getError('gender')}
                                onChange={(option: any) => field.onChange(option.value)}
                                parentClassName="mb-4 md:mb-0"
                            />
                        );
                    }}
                />
                <Controller name={getName('email')} control={control} render={({ field }) => <Input {...field} type="email" autoFocus={false} error={getError('email')} label="E-mail" />} />
            </div>
            <Phones className="mb-4" accessor={getName('phones')} index={index || 0} withLabel={true} />
        </>
    );
};

export default ContactsFields;
