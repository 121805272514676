import { yupResolver } from '@hookform/resolvers/yup';
import Select from 'components/core/form/select';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import FormButtons from 'components/form-buttons';
import { Controller, useForm } from 'react-hook-form';
import useGetSaleOrderScheduleDaysOptions from 'services/queries/sales-order/use-get-sale-order-schedules-days-options';
import useGetSaleOrderScheduleHoursOptions from 'services/queries/sales-order/use-get-sale-order-schedules-hours-options';
import { saleOrderScheduleSchema } from '../utils';
import { SaleOrderSchedulePayload } from 'types/models/sale-order';

type CreateOrUpdateSaleOrderScheduleProps = {
    onSubmit: (data: SaleOrderSchedulePayload) => void;
    isSubmitting: boolean;
};

const CreateOrUpdateSaleOrderSchedule = ({ onSubmit, isSubmitting }: CreateOrUpdateSaleOrderScheduleProps) => {
    const { watch, formState, control, handleSubmit, setValue } = useForm<SaleOrderSchedulePayload>({
        mode: 'onSubmit',
        resolver: yupResolver(saleOrderScheduleSchema),
        defaultValues: {
            day: '',
            hour: '',
        },
    });

    const scheduleDay = watch('day');

    const { data: scheduleDaysOptions = [] } = useGetSaleOrderScheduleDaysOptions();
    const { data: scheduleHoursOptions = [] } = useGetSaleOrderScheduleHoursOptions(scheduleDay);

    return (
        <Modal
            contentClassnames="w-[700px]"
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    Cadastrar agendamento
                </Text>
            }
            closeOnClickOutside={false}>
            <form onSubmit={handleSubmit(onSubmit)} className="grid sm:grid-cols-2 gap-4 p-5">
                <Controller
                    name="day"
                    control={control}
                    render={({ field }) => {
                        const value = scheduleDaysOptions?.find((item) => +item?.value === +field?.value);

                        return (
                            <Select
                                {...field}
                                error={formState?.errors?.day?.message}
                                label="Data do Agendamento"
                                value={value}
                                options={scheduleDaysOptions}
                                onChange={(option: any) => {
                                    field.onChange(option?.value);
                                    setValue('hour', '');
                                }}
                            />
                        );
                    }}
                />
                <Controller
                    name="hour"
                    control={control}
                    render={({ field }) => {
                        const value = scheduleHoursOptions?.find((item) => +item?.value === +field?.value);
                        return (
                            <Select
                                {...field}
                                error={formState?.errors?.hour?.message}
                                label="Hora do Agendamento"
                                value={value || null}
                                options={scheduleHoursOptions}
                                onChange={(option: any) => field.onChange(option?.value)}
                            />
                        );
                    }}
                />
                <FormButtons isLoading={isSubmitting} />
            </form>
        </Modal>
    );
};

export default CreateOrUpdateSaleOrderSchedule;
