import useToast from 'hooks/toast/use-toast';
import { createOrUpdateAddressMutation } from 'pages/private/customer-provider-address/utils';
import { useMutation, useQueryClient } from 'react-query';
import apiGraphQl from 'services/graphql';
import { getAllKey } from '../crud/keys';
import { GraphqlPaginationVariables } from 'types/graphql';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';

const useCreateOrUpdateAddress = (table: string, params: GraphqlPaginationVariables<{}>, addressId?: number) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const goToParentRoute = useNavigateToParentRoute();

    const request = async (payload) => {
        const data = {
            ...(addressId && { id: addressId }),
            payload,
        };

        return apiGraphQl(createOrUpdateAddressMutation(addressId), data);
    };

    return useMutation(request, {
        onSuccess: () => {
            queryClient.invalidateQueries(getAllKey(table, params));

            goToParentRoute();

            showToast('Endereço salvo com sucesso', 'success');
        },
        onError: () => {
            showToast('Ocorreu um erro ao salvar o endereço', 'danger');
        },
    });
};

export default useCreateOrUpdateAddress;
