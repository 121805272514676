import TableCell from '@mui/material/TableCell/TableCell';
import { CrudPageProps } from 'types/graphql';
import { Gender } from 'types/models/person';
import dictionary from 'utils/dictionary';
import { array, number, object, string } from 'yup';

export const customerContactSchema = object({
    name: string().required(dictionary.validation.required).trim(),
    title: string().required(dictionary.validation.required).trim(),
    gender: number().oneOf([Gender.Female, Gender.Male, Gender.Other]).required(dictionary.validation.required),
    email: string().required(dictionary.validation.required).trim(),
    phones: array(
        object({
            ddd: string().min(2, dictionary.validation.string.min(2)).max(2, dictionary.validation.string.max(2)).trim(),
            number: string(),
        }).required(dictionary.validation.required)
    )
        .default(null)
        .optional(),
});

export const customerDetailsContactsListConfig = (customerId?: number): CrudPageProps<{}> => ({
    graphql: {
        table: 'customerContact',
        searchableField: 'person.name',
        statusPath: 'status',
        columns: [
            {
                name: 'id',
                options: { display: 'excluded' },
            },
            {
                name: 'person.title',
                label: dictionary.content.graphql.title,
            },
            {
                name: 'person.name',
                label: dictionary.content.graphql.name,
            },
            {
                name: 'person.phones',
                label: dictionary.content.graphql.phones,
                options: {
                    customBodyRender: (value) => {
                        return value?.map((item) => `(${item.ddd}) ${item.number}`).join(', ') || '-';
                    },
                },
            },
            {
                name: 'person.email',
                label: dictionary.content.graphql.email,
            },
            {
                name: 'actions',
                label: 'Ações',
                options: {
                    customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />,
                },
            },
        ],
        where: {
            customerId: { _eq: customerId },
        },
    },
    title: 'Contatos',
    withBorder: true,
});
