import { useQuery } from 'react-query';
import { getExpenseDocumentKey } from './keys';
import apiGraphQl from 'services/graphql';
import { convertArrayToObject } from 'utils/array';
import { jsonToGraphQLQuery } from 'json-to-graphql-query';
import { Document } from 'types/models/document';

type Response = {
    expenseDocumentByPk: Partial<{
        id: number;
        document: Document;
    }>;
};

const defaultFields = [
    'id',
    'document.name',
    'document.observation',
    'document.description',
    'document.classificationId',
    'document.file.id',
    'document.file.filename',
    'document.file.path',
    'document.file.originalName',
];

type Params = {
    fields?: string[];
    expenseId?: string;
    documentId?: string;
};

const useGetExpenseDocument = ({ fields = defaultFields, expenseId, documentId }: Params) => {
    const fieldsToGraphql = convertArrayToObject(fields);

    const request = async () => {
        return apiGraphQl<Response>(
            jsonToGraphQLQuery(
                {
                    query: {
                        __name: 'GetExpenseDocument',
                        expenseDocumentByPk: {
                            __args: { id: Number(documentId || 0) },
                            ...fieldsToGraphql,
                        },
                    },
                },
                { pretty: true }
            )
        ).then((data) => data.expenseDocumentByPk);
    };

    return useQuery(getExpenseDocumentKey(expenseId, documentId), request, { enabled: Boolean(documentId) });
};

export default useGetExpenseDocument;
