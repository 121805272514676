import TableContainer from '@mui/material/TableContainer/TableContainer';
import Datatable from 'components/core/table/datatable';
import useConfig from 'store/config/use-config';
import { LegacyData } from 'types/models/reports';
import { monthReportLegacyColumns } from './utils';
import TableFooter from '@mui/material/TableFooter/TableFooter';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import { formatMoney } from 'utils/money';

type MonthReportDetailsLegacyTabProps = {
    data: LegacyData[];
};

const MonthReportDetailsLegacyTab = ({ data }: MonthReportDetailsLegacyTabProps) => {
    const { config } = useConfig();

    const total = data.reduce((total, curr) => total + Number(curr.netValue || 0), 0);

    return (
        <TableContainer>
            <Datatable
                data={data}
                columns={monthReportLegacyColumns}
                options={{
                    elevation: 0,
                    enableNestedDataAccess: '.',
                    filter: false,
                    searchAlwaysOpen: true,
                    searchOpen: true,
                    searchPlaceholder: 'Pesquisar',
                    count: data.length,
                    pagination: true,
                    download: false,
                    print: false,
                    viewColumns: false,
                    rowsPerPage: config.tables.rowsPerPage,
                    search: false,
                    customTableBodyFooterRender: () => {
                        return (
                            <TableFooter className="border-t border-base-300">
                                <TableRow className="bg-base-300">
                                    <TableCell className="border-none !text-black font-semibold text-opacity-[0.87] !text-[0.875rem]">TOTAL</TableCell>
                                    <TableCell className="border-none !text-black text-opacity-[0.87] !text-[0.875rem]" colSpan={9}>
                                        <strong>{formatMoney(total)}</strong>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        );
                    },
                }}
                className="toolbar-without-search"
            />
        </TableContainer>
    );
};

export default MonthReportDetailsLegacyTab;
