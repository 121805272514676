import classNames from 'classnames';
import Text from 'components/core/text';
import { useFormContext, useWatch } from 'react-hook-form';
import { RefundStockProductPayload } from 'services/queries/stocks/use-create-stock-product-refund';
import { formatMoney } from 'utils/money';

const StockProductRefundTotal = () => {
    const { control } = useFormContext<RefundStockProductPayload>();

    const products = useWatch({ control, name: 'products', defaultValue: [] });

    const total = products.reduce((acc, curr) => {
        acc += (curr.productValue || 0) * (curr.quantity || 0);

        return acc;
    }, 0);

    return (
        <div className="bg-secondary-100 py-4 px-6 bg-opacity-20 text-right sm:col-span-2 mb-6">
            <Text className="text-base-700 font-medium">Total: </Text>
            <Text variant="body.medium.2xs" className={classNames(total < 0 ? 'text-system-danger-500' : 'text-heading')}>
                {formatMoney(total)}
            </Text>
        </div>
    );
};

export default StockProductRefundTotal;
