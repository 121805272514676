import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Badge from 'components/core/badge';
import SvgIcoShow from 'components/core/icon/files/ico-show';
import { DatatableColumn } from 'types/graphql';
import { PurchaseProductRequest } from 'types/models/purchase-order';
import { formatDate } from 'utils/date';
import { formatMoney, formatNumberToPTBR } from 'utils/money';

export const canDoActions = ['not-started', 'started', 'waiting-for-completion', 'finished'];
export const pendingOS = ['awaiting-release', 'in-product-validation', 'awaiting-product-approval'];

export const listColumnsApprovedScope = (onSetHistoryRequests: React.Dispatch<React.SetStateAction<PurchaseProductRequest[]>>): DatatableColumn[] => [
    {
        name: 'id',
        options: { display: 'excluded' },
    },
    {
        name: 'product.name',
        label: 'Produto',
        options: {
            customBodyRender: (productName = '-', tableMeta) => {
                const productCode = tableMeta.rowData.at(-1);
                return <span className="whitespace-nowrap">{Boolean(productName) && Boolean(productCode) ? `#${productCode} - ${productName}` : '-'}</span>;
            },
        },
    },
    {
        name: 'product.unit.abbreviation',
        label: 'Unidade',
        options: { display: 'excluded' },
    },
    {
        name: 'provision',
        label: 'Previsão compra',
        options: { customBodyRender: (value) => formatDate(value, 'DD/MM/YYYY') },
    },
    {
        name: 'quantity',
        label: 'Quantidade',
        options: {
            customBodyRender: (quantity = 0, tableMeta: any) => {
                const unit = tableMeta.rowData[2] || '';

                // using dangerouslySetInnerHTML for unit (abbreviation) that may contain HTML Entities.
                return <span dangerouslySetInnerHTML={{ __html: `${formatNumberToPTBR(quantity)} ${unit}` }} />;
            },
        },
    },
    {
        name: 'balance',
        label: 'Saldo',
        options: {
            customBodyRender: (value = 0) => {
                return formatNumberToPTBR(value);
            },
        },
    },
    {
        name: 'value',
        label: 'Valor máximo',
        options: { customBodyRender: (value = 0) => formatMoney(value) },
    },
    {
        name: 'balance',
        label: 'Total Saldo',
        options: {
            sort: false,
            customBodyRender: (balance: number = 0, tableMeta: any) => {
                const value = tableMeta.rowData[6] || 0;
                return formatMoney(balance * value);
            },
        },
    },
    {
        name: 'quantity',
        label: 'Total',
        options: {
            sort: false,
            customBodyRender: (quantity: number = 0, tableMeta: any) => {
                const value = tableMeta.rowData[6] || 0;
                return formatMoney(value * quantity);
            },
        },
    },
    {
        name: 'requests',
        options: { display: 'excluded' },
    },
    {
        name: '',
        label: 'Versão',
        options: {
            sort: false,
            customBodyRender: (_, { rowData }) => {
                const requests = rowData[9] || [];

                return (
                    <div className="flex items-center gap-2">
                        <Badge variant={Boolean(requests.length) ? 'warning' : 'info'}>{Boolean(requests.length) ? 'Alterado' : 'Original'}</Badge>
                        {Boolean(requests.length) && (
                            <Tooltip placement="top" title="Ver versões anteriores" disableFocusListener={true}>
                                <IconButton
                                    onClick={onSetHistoryRequests.bind(this, requests)}
                                    TouchRippleProps={{ classes: { child: 'text-system-info-100' } }}
                                    className="text-system-info-500 hover:bg-opacity-30 hover:bg-system-info-100">
                                    <SvgIcoShow width={16} height={16} />
                                </IconButton>
                            </Tooltip>
                        )}
                    </div>
                );
            },
        },
    },
    {
        name: 'product.code',
        options: { display: 'excluded' },
    },
];

export const orderServiceSaleScopeOrderByMap = {
    'product.name': 'pp.name',
    provision: 'p.provision',
    quantity: 'p.quantity',
    balance: 'p.balance',
    value: 'p.maximumValue',
};
