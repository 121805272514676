import ListPage from 'pages/shared/crud/list';
import { RouteObject } from 'react-router-dom';
import { Rule } from 'types/permissions';
import Page from 'pages/shared/page';
import config from './utils';
import CreateOrUpdateCompanyPage from 'pages/private/companies/create-or-update';

const [list, create, update] = config;

const companies: RouteObject[] = [
    {
        // @ts-ignore
        breadcrumb: 'Empresas',
        path: 'empresas',
        element: <Page Component={ListPage} code="empresas" permissions={[Rule.List]} {...list} />,
        children: [
            {
                path: 'novo',
                element: <Page Component={CreateOrUpdateCompanyPage} code="empresas" permissions={[Rule.Create]} withModal={true} {...create} />,
            },
            {
                path: 'editar/:companyId',
                element: <Page Component={CreateOrUpdateCompanyPage} code="empresas" permissions={[Rule.Update]} withModal={true} {...update} />,
            },
        ],
    },
];

export default companies;
