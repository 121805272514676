import React, { ErrorInfo, PropsWithChildren } from 'react';
import ErrorPage from 'pages/shared/error';

interface IState {
    hasError: boolean;
}

class ErrorBoundary extends React.Component<PropsWithChildren<{}>, IState> {
    public state: IState = {
        hasError: false
    };

    public componentDidCatch = (error: Error, errorInfo: ErrorInfo) => {
        console.error('error', error);
        console.error('errorInfo', errorInfo);

        // Display fallback UI
        this.setState({ hasError: true });
    };

    public render() {
        const { hasError } = this.state;

        if (hasError) {
            return <ErrorPage title="Ops!" subtitle="Tivemos um problema" />;
        }

        return <>{this.props.children}</>;
    }
}

export default ErrorBoundary;
