import { BaseModel } from './base';

export enum FormFieldType {
    Text = 1,
    Checkbox = 3,
    Radio,
    Select,
    Date,
    DateTime,
    Time,
    Integer,
    Decimal,
    SelectSimple,
    CheckboxWithField,
    RadioWithField,
}

export enum QuizType {
    Visit = 1,
}

export type Form = {
    title: string;
    type: QuizType;
    active: boolean;
    groups: FormGroup[];
} & BaseModel;

export type FormField = {
    id: string;
    name: string;
    type: FormFieldType;
    order: number;
    options: Array<{
        id: number;
        label: string;
        value: string;
        subfields?: Array<Pick<FormField, 'id' | 'name' | 'type' | 'order' | 'required' | 'options' | 'value'> & { selectedValue: any }>;
    }>;
    fieldId?: string;
    value?: any;
    subfields?: any[];
    required: boolean;
};

export enum FormGroupType {
    Normal = 1,
    Conditional,
}

export type FormGroup = {
    id: number;
    title: string;
    order: number;
    fields: FormField[];
    type: FormGroupType;
    conditionalFieldTitle?: string;
    conditionalFieldType?: FormFieldType;
};
