import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import Text from 'components/core/text';
import TableFooter from '@mui/material/TableFooter/TableFooter';
import ErrorMessage from 'components/error/message';
import FinishOSItem from './finish-os-item';
import Button from 'components/core/button';
import useTheme from '@mui/material/styles/useTheme';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FinishOsPayload } from 'services/queries/order-services/use-finish-os';
import { Option } from 'types/general';
import SvgIcoPlus from 'components/core/icon/files/ico-plus';

type FinishOSProductProps = {
    index: number;
    allStocks: Option[];
    isLoadingStocks: boolean;
} & FinishOsPayload['products'][0];

const FinishOSProduct = ({ name, productId, quantityInStock, index, allStocks, isLoadingStocks }: FinishOSProductProps) => {
    const { palette } = useTheme();
    const { control, formState, watch } = useFormContext<FinishOsPayload>();

    const stocksPath = `products.${index}.stocks` as const;

    const { append, remove } = useFieldArray({ control, name: stocksPath });

    const handleAddStock = () => append({ stockId: 0, quantity: 0 });

    const quantityGreaterThanTotal = formState.errors?.products?.[index]?.stocks?.message;

    const formStocks = watch(stocksPath) || [];

    const totalQuantity = formStocks?.reduce((acc, curr) => Number(acc || 0) + Number(curr?.quantity || 0), 0);

    const selectedStocks = watch(`products.${index}.stocks`) || [];

    return (
        <div className="rounded-[14px] overflow-hidden border border-base-300 mb-6 last:mb-0">
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={2} className="py-3">
                            <Text variant="body.medium.2xs" className="text-heading flex-1">
                                {name}
                            </Text>
                        </TableCell>
                        <TableCell className="py-3 text-right">
                            <Text variant="body.medium.2xs" className="text-base-500">
                                <span>Qtd em estoque: </span>
                                <strong className="text-secondary-500">{quantityInStock}</strong>
                            </Text>
                        </TableCell>
                    </TableRow>
                    <TableRow className="bg-base-200">
                        <TableCell className="w-[49px]" />
                        <TableCell className="py-2 border-x border-x-base-300">
                            <Text className="text-base-500">Estoque</Text>
                        </TableCell>
                        <TableCell className="py-2">
                            <Text className="text-base-500 text-right block">Qtd solicitada</Text>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {formStocks.map((_, ix) => {
                        return (
                            <FinishOSItem stocks={allStocks} isLoadingStocks={isLoadingStocks} productIndex={index} stockIndex={ix} key={`${productId}_${ix}`} onRemoveStock={remove.bind(this, ix)} />
                        );
                    })}
                    {!!quantityGreaterThanTotal && (
                        <TableRow className="bg-system-danger-100 bg-opacity-20" sx={{ '&:last-child td': { borderBottom: 0 } }}>
                            <TableCell colSpan={3}>
                                <ErrorMessage visible={true}>{quantityGreaterThanTotal}</ErrorMessage>
                            </TableCell>
                        </TableRow>
                    )}
                    {selectedStocks.length !== allStocks.length && (
                        <TableRow>
                            <TableCell className="py-2" colSpan={3}>
                                <Button
                                    startIcon={<SvgIcoPlus width={12} height={12} color={palette.primary[500]} />}
                                    type="button"
                                    variant="outlined"
                                    size="small"
                                    className="p-2 px-3 border-0"
                                    sx={{ '&:hover': { border: 'none' } }}
                                    onClick={handleAddStock}>
                                    Adicionar estoque
                                </Button>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter className="bg-base-200 border-t border-t-300">
                    <TableRow sx={{ '&:last-child td': { borderBottom: 0 } }}>
                        <TableCell colSpan={3}>
                            <Text className="text-base-500 text-right block">
                                Total selecionado:
                                <strong className="ml-1 text-secondary-500">{totalQuantity}</strong>
                            </Text>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </div>
    );
};

export default FinishOSProduct;
