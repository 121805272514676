import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
import { getAllKey } from '../crud/keys';
import { getAllSalesReceiptsSumsKeys } from './keys';
import { GraphqlPaginationVariables } from 'types/graphql';

const useCreateOrUpdateSaleNote = (saleNoteId?: string, params?: GraphqlPaginationVariables<any>, paramsFilters?: any) => {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const isUpdate = !!saleNoteId;

    const request = async (payload) => {
        const endpoint = isUpdate ? `/sales-notes/${saleNoteId}` : '/sales-notes';
        const method = isUpdate ? api.patch : api.post;

        return method(endpoint, payload);
    };

    return useMutation(request, {
        onSuccess: ({ data }) => {
            queryClient.invalidateQueries(getAllKey('salesReceipt', params));
            queryClient.invalidateQueries(getAllSalesReceiptsSumsKeys(paramsFilters));

            navigate(`../${data.id}`);

            showToast(`Nota de Venda ${isUpdate ? 'atualizada' : 'cadastrada'} com sucesso!`, 'success');
        },
        onError: () => {
            showToast('Ocorreu um erro ao cadastrar uma Nota de Venda', 'danger');
        },
    });
};

export default useCreateOrUpdateSaleNote;
