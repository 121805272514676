import * as React from 'react';
const SvgIcoMail = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" width="1em" height="1em" {...props}>
        <path
            fill="currentColor"
            d="M3.585 5.369A.704.704 0 0 1 4.2 5h11.6c.264 0 .496.15.615.369L10 9.859 3.585 5.37ZM2 5.687V14.4c0 1.212.988 2.2 2.2 2.2h11.6c1.212 0 2.2-.988 2.2-2.2 0-.968.003-1.935.006-2.903a463.39 463.39 0 0 0-.006-5.8V5.68a2.219 2.219 0 0 0-2.2-2.18H4.2A2.204 2.204 0 0 0 2 5.687Zm14.5 1.454V14.4c0 .383-.317.7-.7.7H4.2a.704.704 0 0 1-.7-.7V7.14l6.07 4.25a.75.75 0 0 0 .86 0l6.07-4.25Z"
        />
    </svg>
);
export default SvgIcoMail;
