import RemoveButton from 'components/buttons/remove';
import Text from 'components/core/text';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import FormItemField from './item-field';
import AddButton from 'components/buttons/add';
import Input from 'components/core/form/input';
import { FormPayload } from 'services/queries/forms/use-create-or-update-form';
import { FormFieldType, FormGroupType } from 'types/models/form';
import Select from 'components/core/form/select';
import { formGroupTypes } from 'utils/statics';
import FormConditionalField from './conditional-group';
import classNames from 'classnames';

type FormItemGroupProps = {
    index: number;
    onRemove: (index: number) => void;
};

const FormItemGroup = ({ index, onRemove }: FormItemGroupProps) => {
    const { control, formState, setValue } = useFormContext<FormPayload>();

    const groupPath = `groups.${index}` as const;

    const groupType = useWatch({ control, name: `${groupPath}.type` });
    const groupOrder = useWatch({ control, name: `${groupPath}.order` });

    const { fields, append, remove } = useFieldArray({ control, name: `${groupPath}.fields` });

    const defaultField = {
        name: '',
        order: fields.length + 1,
        type: FormFieldType.Text,
        required: true,
    };

    const handleSelectFieldType = (option) => {
        setValue(`${groupPath}.type`, option?.value);
        setValue(`${groupPath}.conditionalFieldTitle`, undefined);
        setValue(`${groupPath}.conditionalFieldType`, undefined);
    };

    const isConditionalGroup = groupType === FormGroupType.Conditional;

    return (
        <div className={classNames('box-with-border p-0 mb-4 last:mb-0 relative', isConditionalGroup ? 'border border-system-warning-200' : '')}>
            <div className="flex flex-row items-center justify-between p-4 border-b border-b-base-300 sticky left-0 top-0 rounded-t-[14px] bg-white z-20">
                <Text variant="h5" className="text-primary-500">
                    Grupo {groupOrder}
                </Text>
                <div className="flex flex-row items-center">
                    <AddButton onClick={append.bind(this, defaultField)}>Adicionar campo</AddButton>
                    {!!index && <RemoveButton className="!static !m-0 opacity-100 !ml-2" onClick={onRemove.bind(this, index)} />}
                </div>
            </div>
            {isConditionalGroup && (
                <div className="py-2 px-4 bg-system-warning-100 bg-opacity-50 border-b border-b-system-warning-200">
                    <Text variant="body.regular.xs" className="text-system-warning-900">
                        Você está configurando um grupo condicional. Desta forma, o usuário que responderá o formulário só terá acesso aos campos deste grupo caso a opção selecionada atenda a
                        condição.
                    </Text>
                </div>
            )}
            <div className="p-4">
                <div className="flex gap-4 mb-6">
                    <Controller
                        control={control}
                        name={`${groupPath}.title`}
                        render={({ field }) => {
                            return <Input {...field} label="Título" error={formState.errors.groups?.[0]?.title?.message} parentClassName="flex-1" />;
                        }}
                    />
                    <Controller
                        control={control}
                        name={`${groupPath}.type`}
                        render={({ field }) => {
                            const value = formGroupTypes.find((item) => item.value === field.value);

                            return (
                                <Select
                                    {...field}
                                    label="Tipo"
                                    value={value}
                                    options={formGroupTypes}
                                    error={(formState as any).errors.groups?.[0]?.type?.message}
                                    onChange={handleSelectFieldType}
                                    parentClassName="w-[200px]"
                                />
                            );
                        }}
                    />
                </div>
                {isConditionalGroup && <FormConditionalField groupPath={groupPath} />}
                {fields.map((item, ix) => {
                    return <FormItemField groupIndex={index} index={ix} key={item.id} onRemove={remove} />;
                })}
            </div>
        </div>
    );
};

export default FormItemGroup;
