import Modal from 'components/core/modal';
import Text from 'components/core/text';
import Dropzone from 'components/dropzone';
import FormButtons from 'components/form-buttons';
import { MouseEventHandler, useState } from 'react';
import { Accept } from 'react-dropzone';

type ModalImportFileProps = {
    accept?: Accept;
    isLoading: boolean;
    onClose: () => void;
    onUploadFiles: (file: File[]) => void;
};

const ModalImportFile = ({ accept, isLoading, onClose, onUploadFiles }: ModalImportFileProps) => {
    const [files, setFiles] = useState<File[]>([]);

    const handleUploadFile: MouseEventHandler<HTMLButtonElement> = (event) => {
        event.stopPropagation();
        onUploadFiles(files);
    };

    return (
        <Modal
            contentClassnames="w-[500px]"
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    Importar arquivo
                </Text>
            }
            closeOnClickOutside={false}
            onClose={onClose}>
            <div className="p-6 pt-0">
                <Dropzone accept={accept} isLoading={isLoading} file={files[0]} containerClasses="mb-6" onUploadFiles={setFiles} onClear={setFiles.bind(this, [])} />
                <FormButtons
                    isLoading={isLoading}
                    submitButton={{
                        type: 'button',
                        onClick: handleUploadFile,
                    }}
                    cancelButton={{
                        onClick: onClose,
                    }}
                />
            </div>
        </Modal>
    );
};

export default ModalImportFile;
