import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { number, object, string } from 'yup';
import dictionary from 'utils/dictionary';
import useGetOptions from 'services/queries/crud/use-get-options';
import Select from 'components/core/form/select';
import useTransactionReportPaymentOrReceipt, { UseReportPaymentPayload } from 'services/queries/transactions/use-transaction-report-payment';
import DatePicker from 'components/core/datepicker';
import { formatDate } from 'utils/date';
import { pluralize } from 'utils/string';
import SelectedItemsTable from './table';
import { Transaction } from 'types/models/transaction';
import { getCompanyBankAccountsQuery } from 'pages/private/expenses/details/tabs/transactions/utils';
import FormButtons from 'components/form-buttons';

export type OPsActionsProps = {
    selectedItems: Transaction[];
    onClose: () => void;
    onSuccess?: () => void;
    isReceipt?: boolean;
};

type Classifications = Array<{
    id: number;
    bankAccount: { name: string };
}>;

type FormState = Omit<UseReportPaymentPayload, 'transactions'>;

const schema = object({
    companyBankAccount: number().typeError(dictionary.validation.required).required(dictionary.validation.required),
    payday: string().typeError(dictionary.validation.required).required(dictionary.validation.required).trim(),
});

const serializer = (data: Classifications) => {
    return (
        data?.map((item) => ({
            value: item.id,
            label: item.bankAccount?.name || '-',
        })) || []
    );
};

const ReportPaymentOrReceipt = ({ selectedItems, isReceipt = false, onClose, onSuccess }: OPsActionsProps) => {
    const { data: companyBankAccounts = [] } = useGetOptions(getCompanyBankAccountsQuery, ['providerBankAccount', 'options'], true, serializer);

    const { handleSubmit, control, formState } = useForm<FormState>({
        mode: 'onSubmit',
        defaultValues: { payday: new Date().toISOString() },
        resolver: yupResolver(schema),
    });

    const selectedIds = selectedItems?.map(({ id }) => id) || [];

    const { isLoading: isSubmitting, mutateAsync: sendReport } = useTransactionReportPaymentOrReceipt(isReceipt);

    const submit = async (data: FormState) => {
        try {
            const payload: UseReportPaymentPayload = {
                ...data,
                payday: formatDate(data.payday, 'YYYY-MM-DD'),
                transactions: selectedIds,
            };

            await sendReport(payload);

            onSuccess?.();
        } catch (error) {
            console.log('submit', error);
        }
    };

    const getError = (name: keyof FormState) => formState?.errors?.[name]?.message as string;

    return (
        <Modal
            contentClassnames="min-w-[650px]"
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    Informar {isReceipt ? 'receita' : 'pagamento'}
                </Text>
            }
            closeOnClickOutside={false}
            onClose={onClose}>
            <form className="p-6 pt-0" onSubmit={handleSubmit(submit)}>
                <Text as="p" className="text-base-500 mb-3 text-base block">
                    Total de {pluralize(selectedIds?.length, 'despesas', 'despesa')} selecionadas para pagar
                </Text>
                <SelectedItemsTable items={selectedItems} isReceiptOrders={isReceipt} />
                <div className="flex flex-col gap-4 mb-6 md:grid md:grid-cols-2">
                    <Controller
                        name="companyBankAccount"
                        control={control}
                        render={({ field }) => {
                            const value = companyBankAccounts?.find((item) => item?.value === field.value);

                            return (
                                <Select
                                    {...field}
                                    value={value}
                                    options={companyBankAccounts}
                                    label="Conta Bancária"
                                    placeholder="Selecione uma opção"
                                    onChange={(option: any) => field.onChange(option.value)}
                                    error={getError('companyBankAccount')}
                                />
                            );
                        }}
                    />
                    <Controller
                        name="payday"
                        control={control}
                        render={({ field }) => (
                            <DatePicker
                                inputProps={{
                                    label: 'Data de Pagamento',
                                    placeholderText: 'Selecione uma data',
                                    error: getError('payday'),
                                    ...field,
                                }}
                            />
                        )}
                    />
                </div>
                <FormButtons
                    isLoading={isSubmitting}
                    cancelButton={{
                        onClick: onClose,
                    }}
                />
            </form>
        </Modal>
    );
};

export default ReportPaymentOrReceipt;
