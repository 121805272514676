import useGetBranchesOptions from 'services/queries/branches/use-get-branches';
import { generateBranchOptionsQuery } from 'services/queries/branches/utils';
import useGetCompaniesOptions from 'services/queries/companies/use-get-company-options';
import useGetConsultants from 'services/queries/people/use-get-consultants';
import { ClassificationType } from 'types/models/classification';
import { DataContentFiltersConfig } from '../screens/types';
import useGetClassifications from 'services/queries/classifications/use-get-classifications';
import { generateClassificationQuery } from 'services/queries/classifications/utils';
import useGetSaleOrders from 'services/queries/sales-order/use-get-sale-orders';
import { saleOrdersFilterConfig } from 'pages/private/sales-orders/service-order/utils';
import useGetServiceOrderStatuses from 'services/queries/order-services/use-get-order-service-statuses';
import { generateOrderServiceStatusOptionsQuery } from 'services/queries/order-services/utils';

export type ProductsReportContentFiltersParams = Partial<{
    classificationType: ClassificationType;
    withConsultants: boolean;
    withSaleOrders: boolean;
    withStatuses: boolean;
    isSale: boolean;
}>;

const useGetDataContentConfigFilters = (params?: ProductsReportContentFiltersParams) => {
    const { classificationType, withConsultants = true, withSaleOrders = false, withStatuses = false } = params || {};

    const { data: companies = [] } = useGetCompaniesOptions();
    const { data: branches = [] } = useGetBranchesOptions(generateBranchOptionsQuery());
    const { data: consultants = [] } = useGetConsultants(true, withConsultants);
    const { data: classifications = [] } = useGetClassifications(generateClassificationQuery(classificationType), Boolean(classificationType));
    const { data: saleOrders = [] } = useGetSaleOrders<true>(saleOrdersFilterConfig, withSaleOrders, true);
    const { data: orderServiceStatus = [] } = useGetServiceOrderStatuses(generateOrderServiceStatusOptionsQuery(), withStatuses);

    const dataContentFiltersConfig: DataContentFiltersConfig = {
        companies,
        branches,
        ...(withConsultants && { consultants }),
        ...(Boolean(classificationType) && { classifications }),
        ...(withSaleOrders && { saleOrders }),
        ...(withStatuses && { orderServiceStatus }),
    };

    return dataContentFiltersConfig;
};

export default useGetDataContentConfigFilters;
