import { useQuery } from 'react-query';
import api from 'services/api';
import { getSaleOrderFinancialBalanceKey } from './keys';
import { SaleOrderFinancialBalanceResponse } from 'types/models/sale-order';

const useGetSaleOrderFinancialBalance = (saleOrderId?: string) => {
    const request = async () => {
        const { data } = await api.get<SaleOrderFinancialBalanceResponse>(`/sale-orders/${saleOrderId}/balance`);

        return data;
    };

    return useQuery(getSaleOrderFinancialBalanceKey(saleOrderId), request, {
        enabled: Boolean(saleOrderId),
    });
};

export default useGetSaleOrderFinancialBalance;
