import TableCell from '@mui/material/TableCell/TableCell';
import ExternalButton from 'components/buttons/external';
import Badge from 'components/core/badge';
import Datatable from 'components/core/table/datatable';
import PageHeader from 'components/page/header';
import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import { useState } from 'react';
import useGetPurchaseOrders from 'services/queries/purchase-order/use-get-purchase-orders';
import { PurchaseStatus } from 'types/models/purchase-order';
import { purchaseStatusesConfig } from 'utils/status';

type OrderBy = {
    [key: string]: string;
};

const PurchaseOrderListPage = ({ page: pageWithPermissions }: WithPagePermissionsProps) => {
    const [page, setPage] = useState(1);
    const [orderBy, setOrderBy] = useState<OrderBy | null>(null);
    const [searchText, setSearchText] = useState<string | null>(null);

    const filter = {
        searchText,
    };

    const { data: purchaseOrders, isLoading: isLoadingPurchaseOrders } = useGetPurchaseOrders({ page, orderBy, filter });

    const rules = pageWithPermissions?.rules.map((item) => item.key) || [];

    const handleChangeOrderBy = (changedColumn: string, direction: 'desc' | 'asc') => {
        setOrderBy({ [changedColumn]: direction.toUpperCase() });
    };

    return (
        <>
            <PageHeader className="mb-8" title="Ordens de compra" />
            <Datatable
                actions={rules}
                data={purchaseOrders?.items || []}
                loading={isLoadingPurchaseOrders}
                options={{
                    elevation: 21,
                    enableNestedDataAccess: '.',
                    filter: false,
                    searchAlwaysOpen: true,
                    searchOpen: true,
                    searchPlaceholder: 'Pesquisar',
                    count: purchaseOrders?.total,
                    page,
                    pagination: true,
                    download: false,
                    filterArrayFullMatch: false,
                    print: false,
                    viewColumns: false,
                    search: true,
                    serverSide: true,
                    onChangePage: setPage,
                    onSearchChange: setSearchText,
                    onColumnSortChange: handleChangeOrderBy,
                }}
                columns={[
                    {
                        name: 'id',
                        options: { display: 'excluded' },
                    },
                    { name: 'code', label: 'CÓDIGO' },
                    {
                        name: 'origin.orderService.id',
                        options: { display: 'excluded' },
                    },
                    {
                        name: 'origin.orderService.saleOrder.id',
                        options: { display: 'excluded' },
                    },
                    {
                        name: 'origin.orderService.saleOrder.code',
                        options: { display: 'excluded' },
                    },
                    {
                        name: 'origin.orderService.code',
                        label: 'PV/OS',
                        options: {
                            sort: false,
                            customBodyRender: (orderServiceCode: string | null, { rowData }) => {
                                const [, , orderServiceId, saleOrderId, saleOrderCode] = rowData;

                                if (!saleOrderCode && !orderServiceCode) {
                                    return '-';
                                }

                                return (
                                    <div className="flex">
                                        {!!saleOrderCode ? <ExternalButton to={`/app/pedidos-de-venda/${saleOrderId}`}>{saleOrderCode}</ExternalButton> : '-'}
                                        <span className="mx-2">/</span>
                                        {!!orderServiceCode ? <ExternalButton to={`/app/pedidos-de-venda/${saleOrderId}/ordens-de-servico/${orderServiceId}`}>{orderServiceCode}</ExternalButton> : '-'}
                                    </div>
                                );
                            },
                        },
                    },
                    {
                        name: 'requester.title',
                        label: 'Solicitante',
                        options: {
                            sort: false,
                            customBodyRender: (value) => value || '-',
                        },
                    },
                    {
                        name: 'purchaseStatus',
                        label: 'Status',
                        options: {
                            customBodyRender: (value: PurchaseStatus) => {
                                return <Badge variant={purchaseStatusesConfig[value].variant ?? 'info'}>{purchaseStatusesConfig[value]?.label || 'Aberta'}</Badge>;
                            },
                        },
                    },
                    {
                        name: 'actions',
                        customRoutePath: (item) => {
                            return `/app/pedidos-de-venda/${item.origin?.orderService?.saleOrder?.id}/ordens-de-servico/${item.origin?.orderService?.id}/ordens-de-compra/${item.id}`;
                        },
                        label: 'Ações',
                        options: {
                            customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />,
                        },
                    },
                ]}
            />
        </>
    );
};

export default PurchaseOrderListPage;
