import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';
import { array, number, object, string } from 'yup';
import dictionary from 'utils/dictionary';

export const menuGroupSchema = object({
    name: string().required(dictionary.validation.required),
    icon: string().required(dictionary.validation.required),
    subpages: array(number()),
});

export const getMenuGroupQuery = (menuGroupId?: string) => {
    return jsonToGraphQLQuery(
        {
            query: {
                __name: 'GetMenuGroup',
                menuGroupByPk: {
                    __args: { id: Number(menuGroupId || 0) },
                    icon: true,
                    orderMenu: true,
                    name: true,
                    menuPages: {
                        __args: { orderBy: { orderMenu: new EnumType('ASC') } },
                        orderMenu: true,
                        page: {
                            id: true,
                            name: true,
                        },
                    },
                },
            },
        },
        { pretty: true }
    );
};
