import { yupResolver } from '@hookform/resolvers/yup';
import Input from 'components/core/form/input';
import Select from 'components/core/form/select';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import FormButtons from 'components/form-buttons';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import useGetClassifications from 'services/queries/classifications/use-get-classifications';
import { generateClassificationQuery } from 'services/queries/classifications/utils';
import useCreateOrUpdateProduct from 'services/queries/products/use-create-or-update-product';
import useGetProduct from 'services/queries/products/use-get-product';
import { CrudPageProps } from 'types/graphql';
import { ClassificationType } from 'types/models/classification';
import { ProductPayload } from 'types/models/product';
import { getProductQuery } from './utils';
import { number, object, string } from 'yup';
import dictionary from 'utils/dictionary';
import { productTypes } from 'utils/statics';

const schema = object({
    name: string().required(dictionary.validation.required),
    type: number().required(dictionary.validation.required),
    category: number().required(dictionary.validation.required),
    unit: number().required(dictionary.validation.required),
    classification: number().required(dictionary.validation.required),
});

const CreateOrUpdateProductPage = ({ title }: CrudPageProps<{}>) => {
    const { productId } = useParams();

    const { data: product } = useGetProduct(getProductQuery(productId), productId);
    const { data: categories = [] } = useGetClassifications(generateClassificationQuery(ClassificationType.ProductCategory));
    const { data: units = [] } = useGetClassifications(generateClassificationQuery(ClassificationType.ProductUnityAbbreviation));
    const { data: classifications = [] } = useGetClassifications(generateClassificationQuery(ClassificationType.ProductClassification));

    const { mutateAsync: createOrUpdateProduct, isLoading: isSubmitting } = useCreateOrUpdateProduct(productId);

    const { control, formState, handleSubmit, reset } = useForm<ProductPayload>({
        resolver: yupResolver(schema),
        defaultValues: {
            name: '',
        },
    });

    const submit = async (data: ProductPayload) => {
        try {
            await createOrUpdateProduct(data);
        } catch (error) {
            console.log('submit', error);
        }
    };

    useEffect(() => {
        if (product) {
            reset({
                unit: product.classificationUnitId!,
                category: product.classificationCategoryId,
                name: product.name,
                classification: product.classificationId,
                type: product.type,
            });
        }
    }, [product, reset]);

    return (
        <Modal
            contentClassnames="w-[800px]"
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    {title}
                </Text>
            }
            closeOnClickOutside={false}>
            <form className="px-6 pb-6" onSubmit={handleSubmit(submit)}>
                <div className="mb-6">
                    <Controller control={control} name="name" render={({ field }) => <Input {...field} label="Nome" />} />
                </div>
                <div className="flex flex-col md:grid grid-cols-2 gap-4 mb-6">
                    <Controller
                        control={control}
                        name="type"
                        render={({ field }) => {
                            const value = productTypes.find((item) => item.value === field.value);

                            return (
                                <Select {...field} label="Tipo" options={productTypes} value={value} onChange={(option: any) => field.onChange(option.value)} error={formState.errors.type?.message} />
                            );
                        }}
                    />
                    <Controller
                        control={control}
                        name="category"
                        render={({ field }) => {
                            const value = categories.find((item) => item.value === field.value);

                            return <Select value={value} label="Categoria" options={categories} error={formState.errors.category?.message} onChange={(option: any) => field.onChange(option.value)} />;
                        }}
                    />
                    <Controller
                        control={control}
                        name="unit"
                        render={({ field }) => {
                            const value = units.find((item) => item.value === field.value);

                            return <Select value={value} label="Unidade" options={units} error={formState.errors.unit?.message} onChange={(option: any) => field.onChange(option.value)} />;
                        }}
                    />
                    <Controller
                        control={control}
                        name="classification"
                        render={({ field }) => {
                            const value = classifications.find((item) => item.value === field.value);

                            return (
                                <Select
                                    value={value}
                                    label="Classificação"
                                    options={classifications}
                                    error={formState.errors.classification?.message}
                                    onChange={(option: any) => field.onChange(option.value)}
                                />
                            );
                        }}
                    />
                </div>
                <FormButtons isLoading={isSubmitting} />
            </form>
        </Modal>
    );
};

export default CreateOrUpdateProductPage;
