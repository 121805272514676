import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import { getOrderServicesProductsCategoriesKey } from './keys';

type Payload = { classification: number; id: number }[];

type Params = {
    orderServiceId?: string;
    saleOrderId?: string;
    onSucess?(): void;
};

const useUpdateOrderServicesProductsCategories = ({ orderServiceId, saleOrderId, onSucess }: Params) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const request = async (payload: Payload) => api.patch(`/sale-orders/${saleOrderId}/service-orders/${orderServiceId}/product-categories`, payload);

    return useMutation(request, {
        onSuccess: () => {
            if (onSucess) {
                onSucess();
            }

            showToast('Subcategorias atualizadas com sucesso!', 'success');

            queryClient.invalidateQueries(getOrderServicesProductsCategoriesKey({ orderServiceId, saleOrderId }));
        },
        onError: () => {
            showToast('Ocorreu um erro, tente novamente.', 'danger');
        },
    });
};

export default useUpdateOrderServicesProductsCategories;
