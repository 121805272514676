import { useQuery } from 'react-query';
import apiGraphQl from 'services/graphql';

import { formatDate } from 'utils/date';
import { getSaleOrderScheduleDaysOptionsKey } from './keys';
import { getSaleOrderScheduleDaysOptionsQuery } from './utils';
import { Option } from 'types/general';

const useGetSaleOrderScheduleDaysOptions = () => {
    const request = async () => {
        const { items: scheduleDaysOptions = [] } = await apiGraphQl<{ items: Option[] }>(getSaleOrderScheduleDaysOptionsQuery);

        const formattedDateOptions = scheduleDaysOptions?.map((item) => ({ ...item, label: formatDate(item?.label) }));

        return formattedDateOptions;
    };

    return useQuery(getSaleOrderScheduleDaysOptionsKey, request);
};

export default useGetSaleOrderScheduleDaysOptions;
