import Select from 'components/core/form/select';
import PageHeader from 'components/page/header';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useGetReportPage from 'services/queries/report-page/use-get-report-page';
import useGetReportPageByYear from 'services/queries/report-page/use-get-report-page-by-year';
import _max from 'lodash/max';
import ReportPageDetailsContent from './content';

const ReportPageDetailsPage = () => {
    const { reportPageId } = useParams();
    const [year, setYear] = useState<number>();
    const [compareYear, setCompareYear] = useState<number>();

    const navigate = useNavigate();

    const details = useGetReportPage({
        id: reportPageId,
        onSuccess: (data) => {
            const greaterYear = data?.years?.find(({ year }) => year === new Date().getFullYear())?.year || _max(data?.years?.map(({ year }) => year) || []);

            if (greaterYear) {
                setYear(greaterYear);
                setCompareYear(greaterYear - 1);
            }
        },
        onError: () => {
            navigate('/app/relatorios/paginas-de-relatorio');
        },
    });

    const { name, type: reportPageType, generated } = details?.data || {};

    const haveSelectedYears = Boolean(year) && Boolean(compareYear);
    const isGenerated = !!details?.isSuccess && !!generated;
    const isGetYearEnabled = !!isGenerated && haveSelectedYears;

    const formatYearToOption = ({ year }: { year: number }) => ({ value: year, label: year.toString() });
    const getYearInputValue = (value?: number) => details?.data?.years?.map(formatYearToOption)?.find((item: any) => item.value === value);

    const {
        data: currentYear,
        isLoading: currentYearIsLoading,
        isError: currentYearIsError,
    } = useGetReportPageByYear({
        id: reportPageId,
        year,
        enabled: isGetYearEnabled,
    });

    const {
        data: previousYear,
        isLoading: previousYearIsLoading,
        isError: previousYearIsError,
    } = useGetReportPageByYear({
        id: reportPageId,
        year: compareYear,
        enabled: isGetYearEnabled,
        filters: {
            yearCompare: getYearInputValue(year)?.value,
        },
    });

    const isLoading = details?.isLoading || previousYearIsLoading || currentYearIsLoading;
    const isError = details?.isError || currentYearIsError || previousYearIsError;

    const filterYears = useCallback(
        (yearToOmit?: number) => {
            const years = (Boolean(yearToOmit) ? details?.data?.years?.filter(({ year }) => year !== yearToOmit) : details?.data?.years) || [];

            return years.map(formatYearToOption) || [];
        },
        [details?.data]
    );

    const yearOptions = useMemo(() => filterYears(compareYear), [filterYears, compareYear]);
    const compareYearOptions = useMemo(() => filterYears(year), [filterYears, year]);

    return (
        <>
            {isGenerated && (
                <PageHeader
                    title={name}
                    className="mb-5 xl:mb-0"
                    right={
                        <>
                            {isGenerated && (
                                <div className="flex gap-3">
                                    <Select
                                        label="Ano vigente"
                                        className="min-w-[100px]"
                                        value={getYearInputValue(year)}
                                        options={yearOptions}
                                        placeholder="Selecione um ano"
                                        onChange={(option: any) => setYear(option?.value)}
                                        isClearable={true}
                                    />
                                    <Select
                                        label="Ano comparação"
                                        className="min-w-[100px]"
                                        value={getYearInputValue(compareYear)}
                                        options={compareYearOptions}
                                        placeholder="Selecione um ano"
                                        onChange={(option: any) => setCompareYear(option?.value)}
                                        isClearable={true}
                                    />
                                </div>
                            )}
                        </>
                    }
                />
            )}
            <ReportPageDetailsContent
                haveSelectedYears={haveSelectedYears}
                isError={isError}
                currentYear={currentYear}
                reportPageType={reportPageType}
                previousYear={previousYear}
                year={year}
                compareYear={compareYear}
                isGenerated={isGenerated}
                isLoading={isLoading}
            />
        </>
    );
};

export default ReportPageDetailsPage;
