import Badge from 'components/core/badge';
import { DatatableColumn } from 'types/graphql';
import { splitDateTime } from 'utils/date';
import { scheduleStatus } from 'utils/statics';
import Text from 'components/core/text';
import ExternalButton from 'components/buttons/external';
import TableCell from '@mui/material/TableCell/TableCell';
import ScheduleActions from './actions';
import { SaleOrderScheduleStatus, saleOrderScheduleStatus } from 'types/models/sale-order';
import { array, number, object, string } from 'yup';
import dictionary from 'utils/dictionary';

export const schedulesColumns: DatatableColumn[] = [
    {
        name: 'id',
        options: { display: 'excluded' },
    },
    {
        name: 'day',
        label: 'Agendamento para',
        options: {
            customBodyRender: (value) => {
                const { day, hour } = splitDateTime(value?.day, value?.time);

                return (
                    <>
                        {!!day && (
                            <>
                                <Text as="p" className="!text-lg mb-2">
                                    {day}
                                </Text>
                                {!!hour && (
                                    <Text as="p" className="text-sm">
                                        {hour}
                                    </Text>
                                )}
                            </>
                        )}
                    </>
                );
            },
        },
    },
    {
        name: 'saleOrder',
        label: 'Pedido',
        options: {
            customBodyRender: (value) => {
                if (!Boolean(value?.id)) {
                    return '-';
                }

                return <ExternalButton to={`/app/pedidos-de-venda/${value?.id}`}>{value?.code}</ExternalButton>;
            },
        },
    },
    {
        name: 'requester',
        label: 'Cliente',
        options: {
            customBodyRender: (value) => {
                return (
                    <div className="min-w-[220px]">
                        <Text as="p">{value?.name || value?.title || '-'}</Text>
                        <Text as="p">Documento: {value?.document || '-'}</Text>
                    </div>
                );
            },
        },
    },
    {
        name: '',
        label: 'Consultor',
        options: {
            customBodyRender: (value) => {
                return (
                    <div className="min-w-[220px]">
                        <Text as="p">{value?.name || value?.title || '-'}</Text>
                    </div>
                );
            },
        },
    },
    {
        name: 'deliveryman',
        label: 'Atendente',
        options: {
            customBodyRender: (value) => {
                return (
                    <div className="min-w-[220px]">
                        <Text as="p">{value?.name || value?.title || '-'}</Text>
                    </div>
                );
            },
        },
    },

    {
        name: 'observations',
        label: 'Observações',
        options: {
            customBodyRender: (value) => (Boolean(value) ? <Text dangerouslySetInnerHTML={{ __html: value }} /> : '-'),
        },
    },
    {
        name: 'scheduleStatus',
        label: 'Status',
        options: {
            customBodyRender: (value) => {
                if (!Boolean(value)) {
                    return '-';
                }

                const scheduleStatus = saleOrderScheduleStatus[value];

                return <Badge variant={scheduleStatus.color || 'grey'}>{scheduleStatus.label || '-'}</Badge>;
            },
        },
    },
    {
        name: 'actions',
        label: 'Ações',
        options: {
            customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />,
            customBodyRender: (_, tableMeta) => {
                const columnScheduleId = 0;
                const columnScheduleStatus = 7;

                const scheduleId = tableMeta.rowData[columnScheduleId];
                const scheduleStatus = tableMeta.rowData[columnScheduleStatus];

                return <ScheduleActions scheduleId={scheduleId} scheduleStatus={scheduleStatus} />;
            },
        },
    },
];

export const validScheduleStatusType = scheduleStatus.map((status) => status.value);

export const schedulesWithObservationInput = [SaleOrderScheduleStatus.Canceled, SaleOrderScheduleStatus.Delivered];

export const scheduleStatusOptionsWithoutRescheduled = scheduleStatus.filter((status) => status.value !== SaleOrderScheduleStatus.Rescheduled);

export const scheduleFilterSchema = object({
    startDate: string().required(dictionary.validation.required),
    endDate: string().required(dictionary.validation.required),
    status: array(number()),
});

export const scheduleUpdateStatusSchema = (status: SaleOrderScheduleStatus) =>
    object({
        ...(schedulesWithObservationInput.includes(status) && {
            observations: status === SaleOrderScheduleStatus.Canceled ? string().required(dictionary.validation.required) : string().optional(),
        }),
        ...(status === SaleOrderScheduleStatus.Rescheduled && {
            day: string().required(dictionary.validation.required),
            hour: string().required(dictionary.validation.required),
        }),
    });
