import Button from 'components/core/button';
import Input from 'components/core/form/input';
import Text from 'components/core/text';
import Dropzone from 'components/dropzone';
import useToast from 'hooks/toast/use-toast';
import { ChangeEventHandler, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useUploadFile from 'services/queries/files/use-upload-file';
import useTransactionReleaseInvoiceSending from 'services/queries/transactions/use-transaction-release-invoice-sending';
import useTransactionSendInvoiceFile from 'services/queries/transactions/use-transaction-send-invoice-file';
import { File as FileApiModel } from 'types/models/file';

type UploadNfProps = {
    data: any[];
    onSuccess: () => void;
};

const UploadNf = ({ data: rowData, onSuccess }: UploadNfProps) => {
    const [name, setName] = useState('');
    const navigate = useNavigate();
    const { showToast } = useToast();

    const { mutateAsync: sendInvoiceFile, isLoading: isSendingTransactionInvoiceValue, isSuccess: isSuccessTrasactionsSendInvoiceValue } = useTransactionSendInvoiceFile(rowData[0]);

    const handleSendInvoiceFile = useCallback(
        async (data?: FileApiModel) => {
            try {
                if (!name) {
                    return showToast('Número da nota fiscal obrigatório', 'warning');
                }

                if (!data?.id) {
                    return showToast('Arquivo obrigatório', 'warning');
                }

                const { data: responseInvoice } = await sendInvoiceFile({ name, file: data.id });

                if (Boolean(responseInvoice?.transaction.id)) {
                    const url = `${responseInvoice.transaction.id}`;
                    navigate(url);
                }
            } catch (error) {
                console.log('handleSendInvoiceFile', error);
            }
        },
        [name, navigate, sendInvoiceFile, showToast]
    );

    const { error, isSuccess, uploadFile, isLoading: isUploading } = useUploadFile(handleSendInvoiceFile);
    const { mutateAsync: releaseInvoice, isLoading: isSubmittingReleaseInvoice } = useTransactionReleaseInvoiceSending(rowData[0]);

    const handleUploadFiles = useCallback(
        async (files: File[]) => {
            try {
                const [localFile] = files;

                if (!name) {
                    return showToast('Número da nota fiscal obrigatório', 'warning');
                }

                await uploadFile(localFile);
            } catch (error) {
                console.log('handleUploadFiles', error);
            }
        },
        [uploadFile, showToast, name]
    );

    const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => setName(e.currentTarget.value);

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();

            await releaseInvoice();

            onSuccess();
        } catch (error) {
            console.log('handleSubmit', error);
        }
    };

    const isDisabled = isSendingTransactionInvoiceValue || isSuccessTrasactionsSendInvoiceValue || isUploading;

    return (
        <form className="flex items-center whitespace-nowrap" onSubmit={handleSubmit}>
            <Input disabled={isDisabled} value={name} placeholder="Número NF" parentClassName="!w-[200px] mr-2" onChange={handleChange} />
            <Dropzone
                isLoading={isUploading}
                isSuccess={isSuccess}
                error={error ? 'Erro ao fazer o upload do arquivo' : ''}
                disabled={!Boolean(name.length)}
                svgClasses="!mb-0 m-auto"
                containerClasses="h-[48px] flex !flex-row !w-auto"
                multiple={false}
                onUploadFiles={handleUploadFiles}
                hasText={false}
            />
            <Text className="mx-4 text-base-500">ou</Text>
            <Button disabled={isDisabled} type="submit" loading={isSubmittingReleaseInvoice} variant="contained" color="primary" size="small" className="whitespace-nowrap w-auto">
                Liberar envio
            </Button>
        </form>
    );
};

export default UploadNf;
