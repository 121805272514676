import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Text from 'components/core/text';
import StockRefundProductRow from './row';
import { RefundStockProductPayload } from 'services/queries/stocks/use-create-stock-product-refund';
import { FieldArrayWithId, UseFieldArrayRemove } from 'react-hook-form';

type StockProductRefundTableProps = {
    selectedProducts: FieldArrayWithId<RefundStockProductPayload, 'products', 'id'>[];
    onRemove: UseFieldArrayRemove;
};

const StockProductRefundTable = ({ selectedProducts = [], onRemove }: StockProductRefundTableProps) => {
    return (
        <TableContainer className="sm:col-span-2">
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className="pl-0">
                            <Text className="text-base-500 uppercase">Produto</Text>
                        </TableCell>
                        <TableCell className="pl-0">
                            <Text className="text-base-500 uppercase">Valor Unitário</Text>
                        </TableCell>
                        <TableCell className="pl-0">
                            <Text className="text-base-500 uppercase">Em Estoque</Text>
                        </TableCell>
                        <TableCell className="pl-0">
                            <Text className="text-base-500 uppercase">Quantidade</Text>
                        </TableCell>
                        <TableCell className="pl-0">
                            <Text className="text-base-500 uppercase">Total</Text>
                        </TableCell>
                        <TableCell className="px-4" />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {selectedProducts.map((item, index) => {
                        return <StockRefundProductRow key={item.id} onRemove={onRemove} index={index} item={item} />;
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default StockProductRefundTable;
