import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';
import { ApprovalStatus, Status } from 'types/general';
import { WhereClause } from 'types/graphql';
import { Product } from 'types/models/product';
import { convertArrayToObject } from 'utils/array';

export const generateProductsQuery = (fields: string[], where?: WhereClause<Partial<Product>>['where'], aliasForTable?: string) => {
    if (!fields.length) {
        throw new Error('Missing query fields');
    }

    const fieldsToGraphql = convertArrayToObject(fields);

    return jsonToGraphQLQuery(
        {
            query: {
                __name: 'GetProducts',
                [aliasForTable || 'product']: {
                    ...(Boolean(aliasForTable) && { __aliasFor: 'product' }),
                    __args: {
                        orderBy: { name: new EnumType('ASC') },
                        where: {
                            status: { _eq: Status.Active },
                            ...(Boolean(where) && where),
                        },
                    },
                    ...fieldsToGraphql,
                },
            },
        },
        { pretty: true }
    );
};

export const generateProductsExpenseOptionsQuery = (value: string) =>
    jsonToGraphQLQuery(
        {
            query: {
                __name: 'GetProductsExpenseOptions',
                product: {
                    __aliasFor: 'product',
                    __args: {
                        where: {
                            status: {
                                _eq: Status.Active,
                            },
                            approvalStatus: { _eq: ApprovalStatus.Approved },
                            ...(Boolean(value) && {
                                _or: [{ name: { _ilike: `%${value}%` } }, { code: { _ilike: `%${value}%` } }],
                            }),
                        },
                    },
                    label: { __aliasFor: 'name' },
                    value: { __aliasFor: 'id' },
                    code: { __aliasFor: 'code' },
                },
            },
        },
        { pretty: true }
    );
