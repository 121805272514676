import { useQuery } from 'react-query';
import { getStockProductsKey } from './keys';
import { StockProduct } from 'types/models/stock';
import api from 'services/api';

const useGetStockProducts = (stockId?: string) => {
    const request = async () => {
        const { data } = await api.get<StockProduct[]>(`/stocks/${stockId}/products`);

        return data;
    };

    return useQuery(getStockProductsKey(stockId), request, {
        enabled: !!stockId,
    });
};

export default useGetStockProducts;
