import CurrencyInput from 'components/core/form/currency';
import Select from 'components/core/form/select';
import { Controller, useFormContext } from 'react-hook-form';
import { useMemo } from 'react';
import TaxWithheldWithPercentage from '../components/tax-withheld-percentage';
import { verifyIsTaxFieldDisabled } from '../utils';
import { SalesNotesTaxesResponse } from 'services/queries/sales-notes/use-get-sales-notes-taxes';
import Spinner from 'components/core/spinner';
import useGetCitiesTaxOptions from 'services/queries/sales-notes/use-get-cities-tax';

type FieldsPresumedProfitTaxRegimeProps = {
    isSaleNature: boolean;
    taxes?: SalesNotesTaxesResponse;
    isLoading: boolean;
};

const FieldsPresumedProfitTaxRegime = ({ isSaleNature, taxes, isLoading: isLoadingProp }: FieldsPresumedProfitTaxRegimeProps) => {
    const { control, formState } = useFormContext();

    const { data: cities = [], isLoading: isLoadingCities } = useGetCitiesTaxOptions();

    const citiesOptions = useMemo(() => {
        if (Boolean(cities.length)) {
            const citiesToOptions = cities.map((city) => ({
                label: city.city?.name,
                value: city.id,
            }));

            return [...citiesToOptions!, { label: 'Outra', value: null }];
        }

        return [];
    }, [cities]);

    const verifyIsFieldDisabledByKey = verifyIsTaxFieldDisabled(taxes);

    const isLoading = isLoadingCities || isLoadingProp;

    if (isLoading) {
        return (
            <div className="h-[150px]">
                <Spinner size={30} fixed={true} />
            </div>
        );
    }

    if (!taxes) {
        return null;
    }

    return (
        <>
            {!isSaleNature && (
                <div>
                    <Controller
                        name="cityTax"
                        control={control}
                        render={({ field }) => {
                            const value = citiesOptions?.find((item) => item.value === field.value?.value);

                            return (
                                <Select
                                    {...field}
                                    value={value}
                                    options={citiesOptions}
                                    label="Cidade"
                                    placeholder="Selecione uma opção"
                                    error={(formState.errors.cityTax as any)?.message}
                                    onChange={(option) => {
                                        field.onChange(option);
                                    }}
                                />
                            );
                        }}
                    />
                </div>
            )}

            <div>
                <Controller
                    name="irpj"
                    control={control}
                    render={({ field }) => {
                        return (
                            <CurrencyInput
                                ref={field.ref}
                                name={field?.name}
                                disabled={verifyIsFieldDisabledByKey('irpj')}
                                aria-disabled={verifyIsFieldDisabledByKey('irpj')}
                                min={0}
                                fixedDecimalScale={true}
                                value={field?.value as any}
                                label="IRPJ (R$)"
                                decimalScale={2}
                                error={(formState.errors.irpj as any)?.message}
                                onValueChange={({ floatValue }) => field.onChange(floatValue)}
                                right={
                                    <TaxWithheldWithPercentage name="irpjWithheld">
                                        {Boolean(taxes?.irpj?.percentage && field.value) && <span className="text-system-info-500 !text-xs">{`${taxes?.irpj?.percentage?.toFixed(2)}%`}</span>}
                                    </TaxWithheldWithPercentage>
                                }
                            />
                        );
                    }}
                />
            </div>
            <div>
                <Controller
                    name="irpjAdditional"
                    control={control}
                    render={({ field }) => {
                        return (
                            <CurrencyInput
                                ref={field.ref}
                                name={field?.name}
                                value={field?.value}
                                disabled={verifyIsFieldDisabledByKey('irpjAdditional')}
                                aria-disabled={verifyIsFieldDisabledByKey('irpjAdditional')}
                                fixedDecimalScale={true}
                                label="IRPJ Ad."
                                decimalScale={2}
                                error={(formState.errors.irpjAdditional as any)?.message}
                                onValueChange={({ floatValue }) => field.onChange(floatValue)}
                                right={
                                    <TaxWithheldWithPercentage name="irpjAdditionalWithheld">
                                        {Boolean(taxes?.irpjAdditional?.percentage && field.value) && (
                                            <span className="text-system-info-500 !text-xs">{`${taxes?.irpjAdditional?.percentage?.toFixed(2)}%`}</span>
                                        )}
                                    </TaxWithheldWithPercentage>
                                }
                            />
                        );
                    }}
                />
            </div>
            <div>
                <Controller
                    name="csll"
                    control={control}
                    render={({ field }) => {
                        return (
                            <CurrencyInput
                                ref={field.ref}
                                name={field?.name}
                                disabled={verifyIsFieldDisabledByKey('csll')}
                                aria-disabled={verifyIsFieldDisabledByKey('csll')}
                                value={field?.value}
                                label="CSLL (R$)"
                                decimalScale={2}
                                error={(formState.errors.csll as any)?.message}
                                fixedDecimalScale={true}
                                onValueChange={({ floatValue }) => field.onChange(floatValue)}
                                right={
                                    <TaxWithheldWithPercentage name="csllWithheld">
                                        {Boolean(taxes?.csll?.percentage && field.value) && <span className="text-system-info-500 !text-xs">{`${taxes?.csll?.percentage?.toFixed(2)}%`}</span>}
                                    </TaxWithheldWithPercentage>
                                }
                            />
                        );
                    }}
                />
            </div>
            <div>
                <Controller
                    name="cofins"
                    control={control}
                    render={({ field }) => {
                        return (
                            <CurrencyInput
                                ref={field.ref}
                                name={field?.name}
                                disabled={verifyIsFieldDisabledByKey('cofins')}
                                aria-disabled={verifyIsFieldDisabledByKey('cofins')}
                                value={field?.value}
                                fixedDecimalScale={true}
                                label="COFINS (R$)"
                                decimalScale={2}
                                error={(formState.errors.cofins as any)?.message}
                                onValueChange={({ floatValue }) => field.onChange(floatValue)}
                                right={
                                    <TaxWithheldWithPercentage name="cofinsWithheld">
                                        {Boolean(taxes?.cofins?.percentage && field.value) && <span className="text-system-info-500 !text-xs">{`${taxes?.cofins?.percentage?.toFixed(2)}%`}</span>}
                                    </TaxWithheldWithPercentage>
                                }
                            />
                        );
                    }}
                />
            </div>
            <div>
                <Controller
                    name="pis"
                    control={control}
                    render={({ field }) => {
                        return (
                            <CurrencyInput
                                ref={field.ref}
                                name={field?.name}
                                disabled={verifyIsFieldDisabledByKey('pis')}
                                aria-disabled={verifyIsFieldDisabledByKey('pis')}
                                fixedDecimalScale={true}
                                value={field?.value}
                                label="PIS (R$)"
                                decimalScale={2}
                                error={(formState.errors.pis as any)?.message}
                                onValueChange={({ floatValue }) => field.onChange(floatValue)}
                                right={
                                    <TaxWithheldWithPercentage name="pisWithheld">
                                        {Boolean(taxes?.pis?.percentage && field.value) && <span className="text-system-info-500 !text-xs">{`${taxes?.pis?.percentage?.toFixed(2)}%`}</span>}
                                    </TaxWithheldWithPercentage>
                                }
                            />
                        );
                    }}
                />
            </div>

            {isSaleNature ? (
                <div>
                    <Controller
                        name="icms"
                        control={control}
                        render={({ field }) => {
                            return (
                                <CurrencyInput
                                    ref={field.ref}
                                    name={field?.name}
                                    value={field?.value}
                                    disabled={verifyIsFieldDisabledByKey('icms')}
                                    aria-disabled={verifyIsFieldDisabledByKey('icms')}
                                    label="ICMS (R$)"
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    error={(formState.errors.icms as any)?.message}
                                    onValueChange={({ floatValue }) => field.onChange(floatValue)}
                                    right={
                                        <TaxWithheldWithPercentage name="icmsWithheld">
                                            {Boolean(taxes?.icms?.percentage && field.value) && <span className="text-system-info-500 !text-xs">{`${taxes?.icms?.percentage?.toFixed(2)}%`}</span>}
                                        </TaxWithheldWithPercentage>
                                    }
                                />
                            );
                        }}
                    />
                </div>
            ) : (
                <>
                    <div>
                        <Controller
                            name="presumedInss"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <CurrencyInput
                                        name={field.name}
                                        ref={field.ref}
                                        disabled={verifyIsFieldDisabledByKey('inss')}
                                        aria-disabled={verifyIsFieldDisabledByKey('inss')}
                                        fixedDecimalScale={true}
                                        value={field?.value}
                                        label="INSS (R$)"
                                        decimalScale={2}
                                        error={(formState.errors.presumedInss as any)?.message}
                                        onValueChange={({ floatValue }) => field.onChange(floatValue)}
                                        right={
                                            <TaxWithheldWithPercentage name="inssWithheld">
                                                {Boolean(taxes?.inss?.percentage && field.value) && <span className="text-system-info-500 !text-xs">{`${taxes?.inss.percentage}%`}</span>}
                                            </TaxWithheldWithPercentage>
                                        }
                                    />
                                );
                            }}
                        />
                    </div>
                    <div>
                        <Controller
                            name="iss"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <CurrencyInput
                                        ref={field.ref}
                                        name={field?.name}
                                        value={field?.value}
                                        disabled={verifyIsFieldDisabledByKey('iss')}
                                        aria-disabled={verifyIsFieldDisabledByKey('iss')}
                                        label="ISS (R$)"
                                        decimalScale={2}
                                        error={(formState.errors.iss as any)?.message}
                                        fixedDecimalScale={true}
                                        onValueChange={({ floatValue }) => field.onChange(floatValue)}
                                        right={
                                            <TaxWithheldWithPercentage name="issWithheld">
                                                {Boolean(taxes?.iss?.percentage && field.value) && <span className="text-system-info-500 !text-xs">{`${taxes?.iss?.percentage?.toFixed(2)}%`}</span>}
                                            </TaxWithheldWithPercentage>
                                        }
                                    />
                                );
                            }}
                        />
                    </div>
                </>
            )}
        </>
    );
};

export default FieldsPresumedProfitTaxRegime;
