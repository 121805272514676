import * as React from 'react';
const SvgIcoLink = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M11.479 0A4.571 4.571 0 0 0 8.27 1.275L6.953 2.579a.748.748 0 0 0-.003 1.06.755.755 0 0 0 1.065.004L9.32 2.35a3.061 3.061 0 0 1 4.278.04 3.036 3.036 0 0 1 .041 4.26l-2.28 2.27a3.056 3.056 0 0 1-2.378.884 3.064 3.064 0 0 1-2.228-1.212.755.755 0 0 0-1.054-.151.748.748 0 0 0-.152 1.05 4.556 4.556 0 0 0 3.327 1.81 4.58 4.58 0 0 0 3.551-1.32l2.285-2.275.009-.01a4.531 4.531 0 0 0-.056-6.366A4.57 4.57 0 0 0 11.48 0ZM7.125 4.7a4.579 4.579 0 0 0-3.551 1.32L1.289 8.293l-.009.01a4.531 4.531 0 0 0 .056 6.366 4.571 4.571 0 0 0 6.394.055l1.31-1.304a.748.748 0 0 0 0-1.06.755.755 0 0 0-1.065 0l-1.296 1.29a3.062 3.062 0 0 1-4.278-.04 3.035 3.035 0 0 1-.041-4.26l2.28-2.27a3.056 3.056 0 0 1 2.378-.884 3.064 3.064 0 0 1 2.228 1.212c.249.332.72.4 1.054.151a.748.748 0 0 0 .152-1.05A4.555 4.555 0 0 0 7.125 4.7Z"
            clipRule="evenodd"
        />
    </svg>
);
export default SvgIcoLink;
