import * as React from 'react';
const SvgIcoLock = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 15 16" width="1em" height="1em" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M5.242 2.342a2.875 2.875 0 0 1 4.908 2.033v2.15H4.4v-2.15c0-.763.303-1.494.842-2.033ZM3.647 8.025h8.703a.7.7 0 0 1 .7.7V13.8a.7.7 0 0 1-.7.7H2.2a.7.7 0 0 1-.7-.7V8.725a.7.7 0 0 1 .7-.7h1.447Zm-.747-1.5v-2.15a4.375 4.375 0 0 1 8.75 0v2.15h.7a2.2 2.2 0 0 1 2.2 2.2V13.8a2.2 2.2 0 0 1-2.2 2.2H2.2A2.2 2.2 0 0 1 0 13.8V8.725a2.2 2.2 0 0 1 2.2-2.2h.7Z"
            clipRule="evenodd"
        />
    </svg>
);
export default SvgIcoLock;
