import TableCell from '@mui/material/TableCell/TableCell';
import ApprovalBadge from 'components/badges/approval';
import { CrudPageProps } from 'types/graphql';
import { formatDate } from 'utils/date';
import { formatMoney } from 'utils/money';

const config: CrudPageProps<{ name: string }>[] = [
    {
        title: 'Fluxos de pagamento',
        graphql: {
            table: 'paymentFlow',
            searchableField: 'companyBankAccount.bankAccount.name',
            columns: [
                {
                    name: 'id',
                    options: { display: 'excluded' },
                },
                {
                    name: 'companyBankAccount.bankAccount.name',
                    label: 'Conta bancária',
                    options: { customBodyRender: (bankAccount?: string) => bankAccount || '-' },
                },
                {
                    name: 'provision',
                    label: 'Provisão',
                    options: { customBodyRender: (provision?: string) => formatDate(provision) },
                },
                {
                    name: 'total',
                    label: 'Total',
                    options: { customBodyRender: (total?: number) => formatMoney(total) },
                },
                {
                    name: 'paidTotal',
                    label: 'Total pago',
                    options: { customBodyRender: (paidTotal?: number) => formatMoney(paidTotal) },
                },
                {
                    name: 'paymentFlowStatus',
                    label: 'Status',
                    options: { customBodyRender: (status?: number) => <ApprovalBadge status={status} /> },
                },
                {
                    name: 'actions',
                    label: 'Ações',
                    options: {
                        customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />,
                    },
                },
            ],
        },
        api: {
            useApiTodelete: true,
            endpoint: '/payment-flows',
        },
    },
];

export default config;
