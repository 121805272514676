import Datatable from 'components/core/table/datatable';
import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import { Outlet, useOutletContext } from 'react-router-dom';
import { expenseTransactionsColumns } from '../transactions/utils';
import { ExpenseDetailsResponse } from 'services/queries/expenses/types';

const GroupedTransactionsPage = ({ page }: WithPagePermissionsProps) => {
    const { groupedTransactions = [], transferBetweenStocks } = useOutletContext<ExpenseDetailsResponse>();

    const actions = page?.rules.map((item) => item.key);
    const decimalPlaces = transferBetweenStocks ? 4 : 2;

    return (
        <>
            <Datatable
                actions={actions || []}
                columns={expenseTransactionsColumns(decimalPlaces)}
                data={groupedTransactions}
                options={{
                    sort: true,
                    selectableRowsHeader: false,
                    selectableRowsHideCheckboxes: true,
                    filter: false,
                    search: true,
                    searchAlwaysOpen: true,
                    print: false,
                    download: false,
                    viewColumns: false,
                    serverSide: true,
                    enableNestedDataAccess: '.',
                    pagination: true,
                    elevation: 21,
                }}
            />
            <Outlet />
        </>
    );
};

export default GroupedTransactionsPage;
