import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { getAllKey } from '../crud/keys';
import queries from './keys';
import useToast from 'hooks/toast/use-toast';
import { CreateOrUpdateCustomerPayload, Customer } from 'types/models/customer';
import { GraphqlPaginationVariables } from 'types/graphql';
import { useShowApiErrors } from 'hooks/error/show-api-errors';
import apiGraphQl from 'services/graphql';
import omit from 'lodash/omit';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';

type CreateOrUpdateCustomerResponse = {
    item: {
        id: number;
        title: string;
    };
};

const useCreateOrUpdateCustomer = (params: GraphqlPaginationVariables<Customer>, withRedirect = true) => {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const { customerId } = useParams();
    const queryClient = useQueryClient();
    const { showToastErrors } = useShowApiErrors();
    const goToParentRoute = useNavigateToParentRoute();

    const request = async (payload: CreateOrUpdateCustomerPayload) => {
        const { data, ...rest } = payload;

        return apiGraphQl<CreateOrUpdateCustomerResponse>(queries.createOrUpdateCustomer(!!customerId, !!rest?.addressId), {
            ...(!!rest?.addressId && {
                addressId: rest.addressId,
                addressPayload: rest.addressPayload,
            }),
            ...(!!customerId && { id: data?.id }),
            payload: !!customerId ? omit(data, 'customerContacts') : payload,
        });
    };

    return useMutation(request, {
        onSuccess: ({ item }) => {
            if (withRedirect) {
                navigate(`../${item.id}`);
            } else {
                goToParentRoute();
            }

            queryClient.invalidateQueries(getAllKey('customer', params));
            showToast(`Cliente ${!!customerId ? 'Atualizado' : 'cadastrado'} com sucesso`, 'success');
        },
        onError: (err: any) => {
            const errMessage = err?.response?.errors?.[0]?.message;

            if (errMessage.includes('duplicate')) {
                if (errMessage.includes('customer_document_key')) {
                    showToast('CPF já cadastrado no sistema', 'danger');
                }

                if (errMessage.includes('customer_juridic_document_key')) {
                    showToast('CNPJ já cadastrado no sistema', 'danger');
                }
            } else {
                showToastErrors(err);
            }
        },
    });
};

export default useCreateOrUpdateCustomer;
