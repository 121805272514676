import { useQuery } from 'react-query';
import api from 'services/api';
import { getOrderServiceKey } from './keys';
import { OrderService } from 'types/models/order-service';

const useGetServiceOrder = (saleOrderId?: string, orderServiceId?: string) => {
    const request = async () => {
        const { data } = await api.get<Partial<OrderService>>(`/sale-orders/${saleOrderId}/service-orders/${orderServiceId}`);

        return data;
    };

    return useQuery(getOrderServiceKey(orderServiceId), request, { enabled: Boolean(orderServiceId) && Boolean(saleOrderId) });
};

export default useGetServiceOrder;
