import useTheme from '@mui/material/styles/useTheme';
import Button from 'components/core/button';
import { useServiceOrderContext } from '../context';
import { Steps } from '../types';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SaleOrderProduct } from 'types/models/sale-order';
import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import Text from 'components/core/text';
import SaleScopeItem from './sale-scope-item';
import { useEffect } from 'react';
import TableFooter from '@mui/material/TableFooter/TableFooter';
import { formatMoney } from 'utils/money';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import { serviceOrderSaleScopeSchema } from './utils';
import { useParams } from 'react-router-dom';
import SvgIcoArrowLeft from 'components/core/icon/files/ico-arrow-left';
import SvgIcoArrowRight from 'components/core/icon/files/ico-arrow-right';

type ServiceOrderSaleScopeProps = {
    saleOrderProducts: Partial<SaleOrderProduct>[];
};

export type SaleScopeItemPayload = {
    product: {
        id: number;
        name: string;
    };
    saleProductId?: number;
    value: number;
    quantity: number;
    balance: number;
    predictedStartDate: string;
    predictedEndDate: string;
    checked: boolean;
    isRegisteredSaleProduct?: boolean;
    actualQuantity?: number;
};

export type ServiceOrderSaleScopePayload = {
    saleProducts: Partial<SaleScopeItemPayload>[];
};

const ServiceOrderSaleScope = ({ saleOrderProducts }: ServiceOrderSaleScopeProps) => {
    const { palette } = useTheme();
    const { goToStep, setContent, completeStep, state } = useServiceOrderContext();

    const { orderServiceId } = useParams();

    const methods = useForm<ServiceOrderSaleScopePayload>({
        mode: 'onSubmit',
        defaultValues: { saleProducts: [] },
        resolver: yupResolver(serviceOrderSaleScopeSchema(Boolean(orderServiceId))) as any,
    });

    const { control, formState, handleSubmit, reset, watch, getValues } = methods;

    const { fields } = useFieldArray({ control, name: 'saleProducts' });

    const handleBack = () => {
        setContent({ saleProducts: getValues('saleProducts') });
        goToStep('previous');
    };

    const submit = (data: ServiceOrderSaleScopePayload) => {
        setContent(data);
        completeStep(Steps.SaleScope);
        goToStep('next');
    };

    const products = watch('saleProducts').filter((item) => item.checked);

    const finalTotalValue = products.reduce((acc, curr) => acc + (curr.quantity || 0) * (curr.value || 0), 0);

    useEffect(() => {
        if (saleOrderProducts) {
            if (!!state.content.saleProducts?.length) {
                reset({ saleProducts: state.content.saleProducts });
            } else {
                const arr = saleOrderProducts.map((item) => ({
                    checked: !!item.balance,
                    predictedEndDate: '',
                    predictedStartDate: '',
                    balance: item.balance,
                    value: item.netValue,
                    product: {
                        id: item.product?.id,
                        name: item.product?.name,
                    },
                    quantity: 0,
                }));

                reset({ saleProducts: arr });
            }
        }
    }, [state.content.saleProducts, saleOrderProducts, reset]);

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(submit)}>
                <TableContainer>
                    <Table stickyHeader={true}>
                        <TableHead>
                            <TableRow>
                                <TableCell className="bg-base-200 px-0 py-4" />
                                <TableCell className="bg-base-200 px-0 py-4 pr-4 md:pr-0">
                                    <Text as="span" className="text-secondary-500 font-semibold">
                                        Produto
                                    </Text>
                                </TableCell>
                                <TableCell className="bg-base-200 px-0 py-4 pr-4 lg:pr-0">
                                    <Text as="span" className="text-secondary-500 font-semibold">
                                        Saldo
                                    </Text>
                                </TableCell>
                                <TableCell className="bg-base-200 px-0 py-4 pr-4 lg:pr-0">
                                    <Text as="span" className="text-secondary-500 font-semibold">
                                        Valo Unitário
                                    </Text>
                                </TableCell>
                                <TableCell className="bg-base-200 px-0 py-4 pr-4 md:pr-0">
                                    <Text as="span" className="text-secondary-500 font-semibold">
                                        Quantidade
                                    </Text>
                                </TableCell>
                                <TableCell className="bg-base-200 px-0 py-4 pr-4 md:pr-0">
                                    <Text as="span" className="text-secondary-500 font-semibold whitespace-nowrap">
                                        Previsão de início
                                    </Text>
                                </TableCell>
                                <TableCell className="bg-base-200 px-0 py-4 pr-4 md:pr-0">
                                    <Text as="span" className="text-secondary-500 font-semibold whitespace-nowrap">
                                        Previsão de finalização
                                    </Text>
                                </TableCell>
                                <TableCell className="bg-base-200 px-0 py-4 w-[155px]">
                                    <Text as="span" className="text-secondary-500 font-semibold whitespace-nowrap">
                                        Total
                                    </Text>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {fields.map((item, index) => {
                                return <SaleScopeItem key={item.id} index={index} isOrderServiceUpdate={Boolean(orderServiceId)} {...item} />;
                            })}
                        </TableBody>
                        <TableFooter>
                            <TableRow className="bg-base-200">
                                <TableCell className="border-b-0 pr-0" colSpan={7}>
                                    <Text className="text-base-500 uppercase font-bold text-right block">Total</Text>
                                </TableCell>
                                <TableCell className="border-b-0">
                                    <Text className="text-heading font-bold">{formatMoney(finalTotalValue)}</Text>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
                <div className="border-t p-2 flex justify-between">
                    <Button startIcon={<SvgIcoArrowLeft width={18} color={palette.grey[700]} />} type="button" variant="text" color="inherit" className="min-w-[100px]" onClick={handleBack}>
                        Voltar
                    </Button>
                    <Button
                        disabled={!formState.isValid}
                        endIcon={<SvgIcoArrowRight width={18} color={palette.secondary[500]} />}
                        type="submit"
                        variant="text"
                        color="secondary"
                        className="min-w-[100px]">
                        Avançar
                    </Button>
                </div>
            </form>
        </FormProvider>
    );
};

export default ServiceOrderSaleScope;
