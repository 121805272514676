import Select from 'components/core/form/select';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { useMemo, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useOutletContext, useParams } from 'react-router-dom';
import useGetStockProducts from 'services/queries/stocks/use-get-stock-products';
import { Option } from 'types/general';
import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import FormButtons from 'components/form-buttons';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import IndustrializationItem from './item';
import { yupResolver } from '@hookform/resolvers/yup';
import { array, number, object, ref, string } from 'yup';
import useIndustrialization from 'services/queries/products/use-industrialization';
import { GraphqlPaginationVariables } from 'types/graphql';
import dictionary from 'utils/dictionary';

export type IndustrializationPayload = {
    movements: Array<{
        label: string;
        quantityInStock: number;
        productValue: number;
        value: number;
        quantitySelected: number;
        product: number;
        quantityGenerated: number;
    }>;
};

const schema = object({
    movements: array(
        object({
            label: string(),
            quantityInStock: number(),
            productValue: number(),
            value: number(),
            quantitySelected: number().required(dictionary.validation.required).max(ref('quantityInStock'), 'A quantidade selecionada é maior que a quantidade disponível'),
            product: number().required(dictionary.validation.required),
            quantityGenerated: number().required(dictionary.validation.required),
        })
    ),
});

const IndustrializationPage = () => {
    const { stockId } = useParams();

    const [selectedProduct, setSelectedProduct] = useState<Option | null>(null);

    const ctx = useOutletContext<GraphqlPaginationVariables>();

    const { data: stockProducts = [], isLoading: isLoadingStockProducts } = useGetStockProducts(stockId);
    const { mutateAsync: handleIndustrialization, isLoading: isSubmitting } = useIndustrialization(stockId, ctx);

    const formattedStockProducts = useMemo(() => {
        return stockProducts
            .filter((item) => item.quantity > 0)
            .map((item) => ({
                label: `${item.product.code} - ${item.product.name}`,
                quantityInStock: item.quantity,
                value: item.id,
                productValue: item.value,
                quantityGenerated: 0,
                quantitySelected: 0,
            }));
    }, [stockProducts]);

    const methods = useForm<IndustrializationPayload>({
        mode: 'all',
        defaultValues: { movements: [] },
        resolver: yupResolver(schema) as any,
    });

    const { append, remove, fields: selectedProducts } = useFieldArray({ control: methods.control, name: 'movements' });

    const filteredProducts = useMemo(() => formattedStockProducts.filter((item) => !selectedProducts.find((prod) => prod.value === item.value!)), [formattedStockProducts, selectedProducts]);

    const handleSelectProducts = (option: any) => {
        setSelectedProduct(null);
        append(option, { shouldFocus: false });
    };

    const submit = async (data: IndustrializationPayload) => {
        try {
            const payload = {
                movements: data.movements
                    .filter((item) => item.quantityInStock >= 0)
                    .map((item) => ({
                        quantity: item.quantitySelected,
                        stockProduct: item.value,
                        movements: [{ product: item.product, quantity: item.quantityGenerated }],
                    })),
            };

            await handleIndustrialization(payload);
        } catch (error) {
            console.log('submit', error);
        }
    };

    return (
        <Modal
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    Industrialização
                </Text>
            }
            contentClassnames="xs:w-full md:min-w-[1200px] w-[90%]"
            closeOnClickOutside={false}>
            <FormProvider {...methods}>
                <form className="px-6 pb-6" onSubmit={methods.handleSubmit(submit)}>
                    <Select
                        value={selectedProduct}
                        isLoading={isLoadingStockProducts}
                        options={filteredProducts}
                        label="Produto (código/nome)"
                        placeholder="Selecione um ou mais produtos"
                        parentClassName="mb-6 w-[500px]"
                        isSearchable={false}
                        onChange={handleSelectProducts}
                    />
                    {Boolean(selectedProducts.length) && (
                        <div className="border border-base-300 rounded-[14px] mb-6">
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow sx={{ '> th:not(:last-child)': { borderRight: '1px solid #e8e9eb' } }}>
                                            <TableCell className="w-[30%]">
                                                <Text className="text-base-500 uppercase whitespace-nowrap">Produto</Text>
                                            </TableCell>
                                            <TableCell className="w-[7%]">
                                                <Text className="text-base-500 uppercase whitespace-nowrap">Em estoque</Text>
                                            </TableCell>
                                            <TableCell className="w-[7%]">
                                                <Text className="text-base-500 uppercase whitespace-nowrap">Valor</Text>
                                            </TableCell>
                                            <TableCell className="w-[10%]">
                                                <Text className="text-base-500 uppercase whitespace-nowrap">QTD</Text>
                                            </TableCell>
                                            <TableCell className="w-[20%]">
                                                <Text className="text-base-500 uppercase whitespace-nowrap">Produto a ser transformado</Text>
                                            </TableCell>
                                            <TableCell className="w-[10%]">
                                                <Text className="text-base-500 uppercase whitespace-nowrap">QTD gerada</Text>
                                            </TableCell>
                                            <TableCell className="w-[8%]">
                                                <Text className="text-base-500 uppercase whitespace-nowrap">Valor final</Text>
                                            </TableCell>
                                            <TableCell className="w-[3%]" />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {selectedProducts.map((item, index) => {
                                            return <IndustrializationItem key={item.id} index={index} item={item} onRemoveProduct={remove.bind(this, index)} />;
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    )}
                    <FormButtons isLoading={isSubmitting} />
                </form>
            </FormProvider>
        </Modal>
    );
};

export default IndustrializationPage;
