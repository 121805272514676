import Text from 'components/core/text';
import { useEffect, useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { formatMoney } from 'utils/money';
import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import _sum from 'lodash/sum';
import ProductItem from './item';
import ExpenseBudget from './budgets';
import AddButton from 'components/buttons/add';
import { PurchaseOrderExpensePayload, PurchaseOrderProduct } from 'types/models/purchase-order';
import Spinner from 'components/core/spinner';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import { EntryBuyProductsPayload } from 'pages/private/sales-orders/service-order/purchase-order/entry';
import { getNumberValueOrZero } from 'pages/private/expenses/details/tabs/transactions/utils';

type ProductsProps = {
    purchaseProductsIds?: string[];
    queryPurchaseOrderProducts?: EntryBuyProductsPayload['purchaseOrderProducts'];
    purchaseOrderProducts?: Partial<PurchaseOrderProduct>[];
    isLoadingPurchaseOrderProducts?: boolean;
};

const Products = ({ purchaseProductsIds = [], queryPurchaseOrderProducts = [], purchaseOrderProducts = [], isLoadingPurchaseOrderProducts }: ProductsProps) => {
    const { control, watch, reset } = useFormContext<PurchaseOrderExpensePayload>();

    const { fields: budgets, append: appendBudget, remove: removeBudget } = useFieldArray({ name: 'budgets', control });

    const selectedProducts = watch('products');

    const prevConsolidated = useMemo(() => {
        return purchaseOrderProducts.reduce((acc, curr) => {
            const value = curr.billed ? curr.purchaseValue : curr.orderServiceProductPurchase?.value;

            return (curr.quantity || 0) * getNumberValueOrZero(value) + acc;
        }, 0);
    }, [purchaseOrderProducts]);

    const consolidated = _sum(selectedProducts.map(({ value, quantity }) => quantity * value));

    const canAddNewBudgets = useMemo(() => consolidated > prevConsolidated, [consolidated, prevConsolidated]);

    useFieldArray({ name: 'products', control });

    const handleAddBudget = () => appendBudget({ provider: '' });

    useEffect(() => {
        if (canAddNewBudgets && !budgets.length) {
            appendBudget({ provider: '' }, { shouldFocus: false });
            appendBudget({ provider: '' }, { shouldFocus: false });
            appendBudget({ provider: '' }, { shouldFocus: false });
        }

        if (!canAddNewBudgets && !!budgets.length) {
            removeBudget();
        }
    }, [canAddNewBudgets, budgets, removeBudget, appendBudget]);

    useEffect(() => {
        if (Boolean(purchaseOrderProducts.length)) {
            const values = watch();

            reset({
                ...values,
                products: purchaseOrderProducts.map((item) => {
                    const purchaseOrderProductById = queryPurchaseOrderProducts.find((purchaseOrderProduct) => Number(purchaseOrderProduct.purchaseOrderProductId) === item.id);
                    const value = item.billed ? item.purchaseValue : item.orderServiceProductPurchase?.value;

                    return {
                        productId: item.product?.id,
                        quantity: Number(purchaseOrderProductById?.quantity ?? (item.quantity || 0)),
                        name: `${item.product?.code} - ${item.product?.name}`,
                        classification: { name: item.product?.classification?.name },
                        classificationByClassificationUnitId: { name: item.product?.classificationByClassificationUnitId?.name },
                        value: getNumberValueOrZero(value),
                        purchaseOrderProduct: item.id,
                        purchaseOrder: {
                            id: item.purchaseOrder?.id,
                            code: item.purchaseOrder?.code,
                        },
                        orderService: {
                            id: item.orderServiceProductPurchase?.orderService?.id,
                            title: item.orderServiceProductPurchase?.orderService?.title,
                            saleOrderId: item.orderServiceProductPurchase?.orderService?.saleOrderId,
                        },
                        total: Number(purchaseOrderProductById?.quantity ?? (item.quantity || 0)) * getNumberValueOrZero(value),
                        provision: item.provision || '',
                        justification: '',
                        billed: item.billed,
                    };
                }),
            });
        }
        // eslint-disable-next-line
    }, [purchaseOrderProducts, reset, watch]);

    if (isLoadingPurchaseOrderProducts) {
        return (
            <div className="px-6 mb-6">
                <div className="border border-base-300 p-4 rounded-[14px] mt-6">
                    <Spinner fixed={false} size={24} wrapperClasses="p-2 bg-base-100 rounded-full" />
                </div>
            </div>
        );
    }

    return (
        <div className="px-6 mb-6">
            <div className="border border-base-300 p-4 rounded-[14px] mb-6">
                <div className="border border-base-300 p-4 rounded-[14px] pb-0 mb-6 overflow-hidden">
                    <Text as="h6" variant="h5" className="text-heading mb-4">
                        Produtos
                    </Text>
                    <TableContainer>
                        <Table className="-mx-4 w-[calc(100%+32px)]">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Text className="text-base-500 uppercase whitespace-nowrap">Produto</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text className="text-base-500 uppercase whitespace-nowrap">Unidade</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text className="text-base-500 uppercase whitespace-nowrap">Provisão</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text className="text-base-500 uppercase whitespace-nowrap">Valor por unidade</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text className="text-base-500 uppercase whitespace-nowrap">Ordem de compra / Obra</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text className="text-base-500 uppercase whitespace-nowrap">Quantidade</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text className="text-base-500 uppercase whitespace-nowrap">Total</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text className="text-base-500 uppercase whitespace-nowrap">Justificativa</Text>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selectedProducts?.map((item, index) => {
                                    return (
                                        <ProductItem
                                            key={`${item.productId}_${index}`}
                                            isJustificationEnabled={Boolean(queryPurchaseOrderProducts.length)}
                                            isQuantityDisabled={Boolean(purchaseProductsIds.length)}
                                            item={item}
                                            index={index}
                                        />
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                {canAddNewBudgets && (
                    <div className="border border-base-300 p-4 rounded-[14px] mb-6">
                        <Text as="h6" variant="h5" className="text-heading mb-4">
                            Orçamento
                        </Text>
                        {budgets.map((item, index) => {
                            return <ExpenseBudget index={index} key={item.id} onRemoveBudget={removeBudget} />;
                        })}
                        <AddButton onClick={handleAddBudget}>Novo orçamento</AddButton>
                    </div>
                )}
                {Boolean(selectedProducts.length) && (
                    <div className="bg-secondary-100 py-4 px-6 bg-opacity-20 text-right rounded-[14px]">
                        <Text className="text-base-700">Total consolidado: </Text>
                        <Text variant="body.medium.2xs" className="text-heading">
                            {formatMoney(consolidated)}
                        </Text>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Products;
