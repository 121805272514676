export type ReducerAction = {
    type: ContextActions;
    payload: any;
};

export type ContextActions = 'SET_STEP_COMPLETED' | 'SET_CURRENT_STEP' | 'SET_CONTENT' | 'SET_STEPS';

export type Step = {
    slug: Steps;
    label: string;
    hide: boolean;
};

export type SaleOrderState = {
    completeds: Steps[];
    currentStep: Steps;
    steps: Step[];
    content: any;
    reset?: any;
};

export enum Steps {
    GeneralInfo = 'GENERAL',
    Products = 'PRODUCTS',
    Financial = 'FINANCIAL',
    Expense = 'EXPENSE',
    Summary = 'SUMMARY',
}

export type ContextType = {
    state: SaleOrderState;
    setCurrentStep: (slug: Steps) => void;
    changeStep: (currentStepSlug: Steps, nextStepSlug?: Steps, previousStepSlug?: Steps) => void;
    completeStep: (step: Steps) => void;
    getStep: (slug: Steps) => void;
    setContent: (content: SaleOrderState['content']) => void;
    hideStep: (steps: Pick<Step, 'hide' | 'slug'>) => void;
    goToStep: (action: 'previous' | 'next') => void;
    setSteps: (steps: Step[]) => void;
};
