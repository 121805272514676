import TableCell from '@mui/material/TableCell/TableCell';
import { jsonToGraphQLQuery, VariableType } from 'json-to-graphql-query';
import { CreateOrUpdateProps } from 'pages/shared/crud/create-or-update';
import useGetClassifications from 'services/queries/classifications/use-get-classifications';
import { generateClassificationQuery } from 'services/queries/classifications/utils';
import { Status } from 'types/general';
import { CrudPageProps } from 'types/graphql';
import { ClassificationType } from 'types/models/classification';
import dictionary from 'utils/dictionary';
import { pkColumnsKey } from 'utils/environment';
import { number, object, string } from 'yup';

const { required } = dictionary.validation;

const schema = object({
    classificationId: number().required(required),
    name: string().required(required),
    code: string().nullable(),
    abbreviation: string().nullable(),
});

type FormProps = {
    classificationId: string | number;
    name: string;
    code: string;
    abbreviation: string;
};

const literalClassificationByType = {
    [ClassificationType.TransactionCategory]: {
        label: 'Categoria',
        classificationColumnName: 'Setor',
        columns: [
            {
                name: 'name',
                label: dictionary.content.graphql['name'],
            },
            {
                name: 'classification.name',
                label: dictionary.content.graphql['sector'],
            },
            {
                name: 'code',
                label: dictionary.content.graphql['code'],
                options: {
                    customBodyRender: (value) => value || '-',
                },
            },
            {
                name: 'abbreviation',
                label: dictionary.content.graphql['abbreviation'],
                options: {
                    customBodyRender: (value) => value || '-',
                },
            },
        ],
    },
    [ClassificationType.TransactionOrigin]: {
        label: 'Origem',
        classificationColumnName: 'Categoria',
        columns: [
            {
                name: 'name',
                label: dictionary.content.graphql['name'],
                options: {
                    customBodyRender: (value) => value || '-',
                },
            },
            {
                name: 'classification.name',
                label: dictionary.content.graphql['category'],
                options: {
                    customBodyRender: (value) => value || '-',
                },
            },
            {
                name: 'classification.classification.name',
                label: dictionary.content.graphql['sector'],
                options: {
                    customBodyRender: (value) => value || '-',
                },
            },
        ],
    },
    [ClassificationType.TransactionSubOrigin]: {
        label: 'Sub-Origem',
        classificationColumnName: 'Origem',
        columns: [
            {
                name: 'name',
                label: dictionary.content.graphql['name'],
                options: {
                    customBodyRender: (value) => value || '-',
                },
            },
            {
                name: 'classification.name',
                label: dictionary.content.graphql['origin'],
                options: {
                    customBodyRender: (value) => value || '-',
                },
            },
            {
                name: 'classification.classification.name',
                label: dictionary.content.graphql['category'],
                options: {
                    customBodyRender: (value) => value || '-',
                },
            },
            {
                name: 'classification.classification.classification.name',
                label: dictionary.content.graphql['sector'],
                options: {
                    customBodyRender: (value) => value || '-',
                },
            },
        ],
    },
};

export const createOrUpdateClassificationMutation = (isUpdate = false) => {
    return jsonToGraphQLQuery(
        {
            mutation: {
                __name: 'CreateOrUpdateClassification',
                __variables: {
                    ...(isUpdate
                        ? {
                              payload: 'ClassificationSetInput',
                              id: 'Int!',
                          }
                        : {
                              payload: 'ClassificationInsertInput!',
                          }),
                },
                item: {
                    __aliasFor: isUpdate ? 'updateClassificationByPk' : 'insertClassificationOne',
                    __args: {
                        ...(isUpdate
                            ? {
                                  [pkColumnsKey]: { id: new VariableType('id') },
                                  _set: new VariableType('payload'),
                              }
                            : {
                                  object: new VariableType('payload'),
                              }),
                    },
                    id: true,
                },
            },
        },
        { pretty: true }
    );
};

const configClassification = (classificationType: ClassificationType, classificationTypeOptions: ClassificationType, title: string) => {
    const form: CreateOrUpdateProps<FormProps> = {
        modalClassName: 'w-[600px]',
        formClassName: '!grid-cols-2 gap-4',
        fields: [
            {
                name: 'classificationId',
                optionsQuery: () => useGetClassifications(generateClassificationQuery(classificationTypeOptions)),
                type: 'select',
                label: literalClassificationByType[classificationType].classificationColumnName,
            },
            {
                name: 'name',
                type: 'text',
            },
            {
                name: 'code',
                type: 'text',
                label: dictionary.content.graphql.code,
            },
            {
                name: 'abbreviation',
                type: 'text',
            },
        ],
        beforeSend: (data) => ({ ...data, type: classificationType }),
        schema,
    };

    const config: CrudPageProps<Partial<FormProps>>[] = [
        {
            title,
            graphql: {
                table: 'classification',
                searchableField: 'name',
                columns: [
                    {
                        name: 'id',
                        options: { display: 'excluded' },
                    },
                    ...literalClassificationByType[classificationType].columns,
                    {
                        name: 'actions',
                        label: 'Ações',
                        options: {
                            customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />,
                        },
                    },
                ],
                where: {
                    type: { _eq: classificationType },
                    status: { _eq: Status.Active },
                },
                orderBy: { name: 'ASC' },
            },
        },
        {
            title: `Nova ${title.toLowerCase()}`,
            graphql: {
                table: 'classification',
                query: createOrUpdateClassificationMutation(),
            },
            defaultValues: { name: '', abbreviation: '', code: '' },
            form,
        },
        {
            title: `Atualizar ${title.toLowerCase()}`,
            graphql: {
                table: 'classification',
                query: createOrUpdateClassificationMutation(true),
            },
            defaultValues: { name: '', abbreviation: '', code: '' },
            form,
        },
    ];

    return config;
};

export default configClassification;
