import { Controller, UseFormReturn } from 'react-hook-form';
import FilterPeriod from './period';
import FavoredSelect from 'components/favored-select';
import { ApprovalStatus, Option } from 'types/general';
import { approvalStatuses, booleanOptions, financialTypes, invoiceStatuses, natures, paidStatuses, provisionedOptions } from 'utils/statics';
import Select from 'components/core/form/select';
import useGetClassifications from 'services/queries/classifications/use-get-classifications';
import { generateClassificationQuery } from 'services/queries/classifications/utils';
import { ClassificationType } from 'types/models/classification';
import useGetCompaniesOptions from 'services/queries/companies/use-get-company-options';
import { PersonType } from 'types/models/person';
import qs from 'qs';
import useGetCompanyBankAccounts from 'services/queries/bank-accounts/use-get-bank-accounts';
import { BankAccount } from 'types/models/bank';
import useGetBranchesOptions from 'services/queries/branches/use-get-branches';
import { generateBranchOptionsQuery } from 'services/queries/branches/utils';
import { fieldExists } from 'utils/validation';

type PaymentOrderFilterFieldsProps = {
    visibleFields?: string[];
    formMethods: UseFormReturn<any, any>;
    path?: string;
};

const favoredPeopleWhere = {
    type: { _eq: PersonType.Internal },
};

const filteredApprovalStatuses = approvalStatuses.filter((item) => item.value !== ApprovalStatus.Disapproved);

const PaymentOrderFilterFields = ({ visibleFields, formMethods, path }: PaymentOrderFilterFieldsProps) => {
    const { watch, control } = formMethods;

    const getFieldName = (name: string) => (Boolean(path) ? `${path}.${name}` : name);

    const queryFilters = qs.stringify({ type: 'all' }, { addQueryPrefix: true, encode: false, arrayFormat: 'brackets', skipNulls: true });

    const { data: companyBankAccounts = [], isLoading: isLoadingCompanyBankAccounts } = useGetCompanyBankAccounts<BankAccount[]>(queryFilters);
    const { data: companies = [] } = useGetCompaniesOptions();
    const { data: sectorsOptions = [] } = useGetClassifications(generateClassificationQuery(ClassificationType.Sector));
    const { data: branches = [] } = useGetBranchesOptions(generateBranchOptionsQuery());

    const sectors = watch(getFieldName('sectors'));

    const { data: categoriesOptions = [] } = useGetClassifications(generateClassificationQuery(ClassificationType.TransactionCategory, { classificationId: { _in: sectors } }), !!sectors);

    const categories = watch(getFieldName('categories'));

    const { data: origins = [] } = useGetClassifications(generateClassificationQuery(ClassificationType.TransactionOrigin, { classificationId: { _in: categories } }), !!categories);

    const classificationOrigins = watch(getFieldName('classificationOrigins'));

    const { data: subOrigins = [] } = useGetClassifications(
        generateClassificationQuery(ClassificationType.TransactionSubOrigin, { classificationId: { _in: classificationOrigins } }),
        !!classificationOrigins
    );

    const bankAccountsOptions = companyBankAccounts.map((item) => ({
        value: item.id,
        label: item.name,
    }));

    return (
        <>
            {fieldExists('sectors', visibleFields) && (
                <Controller
                    name={getFieldName('sectors')}
                    control={control}
                    render={({ field }) => {
                        const value = sectorsOptions.filter((item) => field?.value?.includes(item?.value));

                        return (
                            <Select
                                {...field}
                                value={value}
                                options={sectorsOptions}
                                label="Setor"
                                placeholder="Selecione uma opção"
                                onChange={(option: any) => {
                                    const ids = option.map((item) => item.value);

                                    field.onChange(ids);
                                }}
                                isMulti={true}
                                isClearable={true}
                            />
                        );
                    }}
                />
            )}
            {fieldExists('categories', visibleFields) && (
                <Controller
                    name={getFieldName('categories')}
                    control={control}
                    render={({ field }) => {
                        const value = categoriesOptions.filter((item) => field?.value?.includes(item?.value));

                        return (
                            <Select
                                {...field}
                                value={value}
                                options={categoriesOptions}
                                label="Categoria"
                                onChange={(option: any) => {
                                    const ids = option.map((item) => item.value);

                                    field.onChange(ids);
                                }}
                                isMulti={true}
                                isClearable={true}
                                placeholder="Selecione uma opção"
                            />
                        );
                    }}
                />
            )}
            {fieldExists('classificationOrigins', visibleFields) && (
                <Controller
                    name={getFieldName('classificationOrigins')}
                    control={control}
                    render={({ field }) => {
                        const value = origins.filter((item) => field?.value?.includes(item?.value));

                        return (
                            <Select
                                {...field}
                                value={value}
                                options={origins}
                                label="Origem"
                                placeholder="Selecione uma opção"
                                onChange={(option: any) => {
                                    const ids = option.map((item) => item.value);

                                    field.onChange(ids);
                                }}
                                isMulti={true}
                                isClearable={true}
                            />
                        );
                    }}
                />
            )}
            {fieldExists('classificationSubOrigins', visibleFields) && (
                <Controller
                    name={getFieldName('classificationSubOrigins')}
                    control={control}
                    render={({ field }) => {
                        const value = subOrigins.filter((item) => field?.value?.includes(item?.value));

                        return (
                            <Select
                                {...field}
                                value={value}
                                options={subOrigins}
                                label="Sub-origem"
                                placeholder="Selecione uma opção"
                                onChange={(option: any) => {
                                    const ids = option.map((item) => item.value);

                                    field.onChange(ids);
                                }}
                                isMulti={true}
                                isClearable={true}
                            />
                        );
                    }}
                />
            )}
            {fieldExists('favored', visibleFields) && (
                <Controller
                    name={getFieldName('favored')}
                    control={control}
                    render={({ field }) => {
                        return <FavoredSelect hideActions={true} peopleWhere={favoredPeopleWhere} onChange={(option: Option) => field.onChange(option)} value={field.value} />;
                    }}
                />
            )}
            {fieldExists('bankAccounts', visibleFields) && (
                <Controller
                    name={getFieldName('bankAccounts')}
                    control={control}
                    render={({ field }) => {
                        const value = bankAccountsOptions.filter((item) => field.value?.includes(item.value));

                        return (
                            <Select
                                {...field}
                                value={value}
                                isLoading={isLoadingCompanyBankAccounts}
                                options={bankAccountsOptions}
                                label="Conta Bancária"
                                placeholder="Selecione uma opção"
                                isClearable={true}
                                onChange={(option: any) => {
                                    const ids = option.map((item) => item.value);

                                    field.onChange(ids);
                                }}
                                isMulti={true}
                            />
                        );
                    }}
                />
            )}
            {fieldExists('provisionStartDate', visibleFields) && fieldExists('provisionEndDate', visibleFields) && (
                <FilterPeriod
                    control={control}
                    label="Período de previsão"
                    firstInput={{
                        inputProps: {
                            name: getFieldName('provisionStartDate'),
                            label: 'Inicio',
                        },
                    }}
                    secondInput={{
                        inputProps: {
                            name: getFieldName('provisionEndDate'),
                            label: 'Fim',
                        },
                    }}
                />
            )}
            {fieldExists('startPayday', visibleFields) && fieldExists('endPayday', visibleFields) && (
                <FilterPeriod
                    control={control}
                    label="Período de pagamento"
                    firstInput={{
                        inputProps: {
                            name: getFieldName('startPayday'),
                            label: 'Inicio',
                        },
                    }}
                    secondInput={{
                        inputProps: {
                            name: getFieldName('endPayday'),
                            label: 'Fim',
                        },
                    }}
                />
            )}
            {fieldExists('startDueDate', visibleFields) && fieldExists('endDueDate', visibleFields) && (
                <FilterPeriod
                    control={control}
                    label="Período de vencimento"
                    firstInput={{
                        inputProps: {
                            name: getFieldName('startDueDate'),
                            label: 'Inicio',
                        },
                    }}
                    secondInput={{
                        inputProps: {
                            name: getFieldName('endDueDate'),
                            label: 'Fim',
                        },
                    }}
                />
            )}
            {fieldExists('startCreated', visibleFields) && fieldExists('endCreated', visibleFields) && (
                <FilterPeriod
                    control={control}
                    label="Período de cadastro"
                    firstInput={{
                        inputProps: {
                            name: getFieldName('startCreated'),
                            label: 'Inicio',
                        },
                    }}
                    secondInput={{
                        inputProps: {
                            name: getFieldName('endCreated'),
                            label: 'Fim',
                        },
                    }}
                />
            )}
            {fieldExists('companies', visibleFields) && (
                <Controller
                    name={getFieldName('companies')}
                    control={control}
                    render={({ field }) => {
                        const value = companies.filter((item) => field?.value?.includes(item?.value));

                        return (
                            <Select
                                {...field}
                                value={value}
                                options={companies}
                                label="Empresa"
                                placeholder="Selecione uma opção"
                                isClearable={true}
                                onChange={(option: any) => {
                                    const ids = option.map((item) => item.value);

                                    field.onChange(ids);
                                }}
                                isMulti={true}
                            />
                        );
                    }}
                />
            )}
            {fieldExists('branch', visibleFields) && (
                <Controller
                    name={getFieldName('branch')}
                    control={control}
                    render={({ field }) => {
                        const value = branches.find((item) => item.value === field.value);

                        return (
                            <Select
                                {...field}
                                value={value}
                                options={branches}
                                label="Filial"
                                placeholder="Selecione uma opção"
                                isClearable={true}
                                onChange={(option: any) => field.onChange(option.value)}
                            />
                        );
                    }}
                />
            )}
            {fieldExists('natures', visibleFields) && (
                <Controller
                    name={getFieldName('natures')}
                    control={control}
                    render={({ field }) => {
                        const value = natures.filter((item) => field?.value?.includes(item?.value));

                        return (
                            <Select
                                {...field}
                                value={value}
                                options={natures}
                                label="Natureza"
                                placeholder="Selecione uma opção"
                                isClearable={true}
                                onChange={(option: any) => {
                                    const ids = option.map((item) => item.value);

                                    field.onChange(ids);
                                }}
                                isMulti={true}
                            />
                        );
                    }}
                />
            )}
            {fieldExists('approvalStatus', visibleFields) && (
                <Controller
                    name={getFieldName('approvalStatus')}
                    control={control}
                    render={({ field }) => {
                        const value = filteredApprovalStatuses.find((item) => item.value === field.value);

                        return (
                            <Select
                                {...field}
                                value={value}
                                options={filteredApprovalStatuses}
                                label="Condição"
                                placeholder="Selecione uma opção"
                                onChange={(option: any) => field.onChange(option?.value)}
                                isClearable={true}
                            />
                        );
                    }}
                />
            )}
            {fieldExists('paid', visibleFields) && (
                <Controller
                    name={getFieldName('paid')}
                    control={control}
                    render={({ field }) => {
                        const value = paidStatuses.find((item) => item.value === field.value);

                        return (
                            <Select
                                {...field}
                                value={value || null}
                                options={paidStatuses}
                                label="Status"
                                placeholder="Selecione uma opção"
                                onChange={(option: any) => field.onChange(!!option ? option.value : null)}
                                isClearable={true}
                            />
                        );
                    }}
                />
            )}
            {fieldExists('provisioned', visibleFields) && (
                <Controller
                    name={getFieldName('provisioned')}
                    control={control}
                    render={({ field }) => {
                        const value = provisionedOptions.find((item) => item.value === field.value);

                        return (
                            <Select
                                {...field}
                                value={value || null}
                                options={provisionedOptions}
                                label="Provisionada"
                                placeholder="Selecione uma opção"
                                onChange={(option: any) => field.onChange(!!option ? option.value : null)}
                                isClearable={true}
                            />
                        );
                    }}
                />
            )}
            {fieldExists('invoiceStatus', visibleFields) && (
                <Controller
                    name={getFieldName('invoiceStatus')}
                    control={control}
                    render={({ field }) => {
                        const value = invoiceStatuses.find((item) => item.value === field.value);

                        return (
                            <Select
                                {...field}
                                value={value || null}
                                options={invoiceStatuses}
                                label="Status NF"
                                placeholder="Selecione uma opção"
                                onChange={(option: any) => field.onChange(!!option ? option.value : null)}
                                isClearable={true}
                            />
                        );
                    }}
                />
            )}
            {fieldExists('financialType', visibleFields) && (
                <Controller
                    name={getFieldName('financialType')}
                    control={control}
                    render={({ field }) => {
                        const value = financialTypes?.find((item) => item.value === field.value);

                        return (
                            <Select
                                {...field}
                                value={value || null}
                                options={financialTypes}
                                label="Analítico e Financeiro"
                                placeholder="Selecione uma opção"
                                onChange={(option: any) => field.onChange(!!option ? option.value : null)}
                                isClearable={true}
                            />
                        );
                    }}
                />
            )}
            {fieldExists('billed', visibleFields) && (
                <Controller
                    name={getFieldName('billed')}
                    control={control}
                    render={({ field }) => {
                        const value = booleanOptions?.find((item) => item.value === field.value);

                        return (
                            <Select
                                {...field}
                                value={value || null}
                                options={booleanOptions}
                                label="Faturadas"
                                placeholder="Selecione uma opção"
                                onChange={(option: any) => field.onChange(option?.value)}
                                isClearable={true}
                            />
                        );
                    }}
                />
            )}
        </>
    );
};

export default PaymentOrderFilterFields;
