import { PropsWithChildren, useRef } from 'react';
import Text, { TextProps } from './index';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import classNames from 'classnames';
import useIsOverflow from 'hooks/dom/use-is-overflow';
import SvgIcoInfo from '../icon/files/ico-info';

type TruncatedTextProps = {
    dataId: number;
} & TextProps;

const TruncatedText = ({ dataId, className, children, ...props }: PropsWithChildren<TruncatedTextProps>) => {
    const textWrapperRef = useRef<HTMLDivElement>(null);
    const isOverflow = useIsOverflow({ ref: textWrapperRef, id: dataId });

    const classes = classNames('max-w-[250px] flex-1 overflow-x-hidden text-ellipsis whitespace-nowrap', className);

    return (
        <div className="flex gap-2 items-center">
            <div className={classes} ref={textWrapperRef}>
                <Text className="flex-1" {...props}>
                    {children}
                </Text>
            </div>
            {isOverflow && (
                <Tooltip placement="top" title={children}>
                    <span className="text-system-info-500 hover:bg-system-info-50 rounded-full p-2 cursor-help">
                        <SvgIcoInfo width={11} height={11} />
                    </span>
                </Tooltip>
            )}
        </div>
    );
};

export default TruncatedText;
