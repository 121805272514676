import Select from 'components/core/form/select';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import FormButtons from 'components/form-buttons';
import useDebounce from 'hooks/keyboard/use-debounce';
import { useMemo, useState } from 'react';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useOutletContext, useParams } from 'react-router-dom';
import NationalizeProductsTable from './table';
import NationalizeTotalProducts from './total';
import { yupResolver } from '@hookform/resolvers/yup';
import { nationalizeSchema } from './utils';
import DatePicker from 'components/core/datepicker';
import useCreateNationalization from 'services/queries/import-flow/use-create-nationalization';
import { formatDate } from 'utils/date';

const ImportFlowNationalizePage = () => {
    const [selectedProduct, setSelectedProduct] = useState('');

    const { importFlowId } = useParams();
    const { mutateAsync: createNationalization, isLoading: isCreatingNationalization } = useCreateNationalization(importFlowId);

    const debouncedSelectedProduct = useDebounce(selectedProduct, 500);

    const ctx = useOutletContext<any>();

    const products = useMemo(() => {
        return (ctx?.importFlow?.products || []).map((item) => ({
            baseValue: item.baseValue,
            balance: item.balance,
            complementaryValue: item.complementaryValue,
            value: item.id,
            productId: item.product?.id,
            label: `#${item.product?.code} - ${item.product?.name}`,
            quantity: item.quantity,
        }));
    }, [ctx?.importFlow?.products]);

    const methods = useForm<any>({
        mode: 'all',
        reValidateMode: 'onChange',
        criteriaMode: 'all',
        defaultValues: { products: [] },
        resolver: yupResolver(nationalizeSchema),
    });

    const { handleSubmit, control, formState } = methods;

    const { append, remove, fields: selectedProducts } = useFieldArray({ name: 'products', control });

    const filteredProducts = useMemo(() => products.filter((item) => !selectedProducts.find((prod: any) => prod.importProduct === item.value!)), [products, selectedProducts]);

    const submit = async (data: any) => {
        try {
            const payload = {
                dueDate: formatDate(data.dueDate, 'YYYY-MM-DD'),
                products: data.products?.map((item) => ({
                    product: item.product,
                    importProduct: item.importProduct,
                    quantity: item.quantity,
                    tax: item.tax,
                    grossValue: item.grossValue,
                })),
            };

            await createNationalization(payload);
        } catch (error) {
            console.error('submit - nationaliazation: ', error);
        }
    };

    const handleSelectProducts = (option: any) => {
        if (!option.balance) {
            setSelectedProduct('');
            return alert('Produto sem saldo, por favor, escolha outro.');
        }

        append({
            name: option.label,
            product: option.productId,
            importProduct: option.value,
            quantity: 0,
            baseValue: option.baseValue || 0,
            complementaryValue: option.complementaryValue || 0,
            balance: option.balance || 0,
            grossValue: 0,
            tax: 0,
        });

        setSelectedProduct('');
    };

    return (
        <Modal
            contentClassnames="w-[1200px]"
            headerLeft={
                <>
                    <Text as="h3" variant="h4" className="text-heading">
                        Nacionalizar
                    </Text>
                </>
            }
            closeOnClickOutside={false}>
            <form onSubmit={handleSubmit(submit)}>
                <FormProvider {...methods}>
                    <div className="px-6 pb-6 pt-2">
                        <Controller
                            name="dueDate"
                            control={control}
                            render={({ field }) => (
                                <DatePicker
                                    containerClassNames="mb-4 sm:max-w-[250px]"
                                    inputProps={{
                                        label: 'Data de vencimento',
                                        placeholderText: 'Selecione uma data',
                                        error: formState?.errors?.dueDate?.message as any,
                                        ...field,
                                    }}
                                />
                            )}
                        />
                        <div className="mb-6">
                            <Select
                                value={debouncedSelectedProduct}
                                options={!!debouncedSelectedProduct ? filteredProducts : []}
                                label="Produto (código ou nome)"
                                placeholder="Digite o código ou nome do produto"
                                components={!Boolean(debouncedSelectedProduct) ? { NoOptionsMessage: () => null } : undefined}
                                onChange={handleSelectProducts}
                                onInputChange={setSelectedProduct}
                                error={formState?.errors?.products?.message as any}
                            />
                        </div>
                        {Boolean(selectedProducts.length) && <NationalizeProductsTable selectedProducts={selectedProducts} onRemoveProduct={remove} />}
                        {Boolean(selectedProducts.length) && <NationalizeTotalProducts />}
                        <FormButtons isLoading={isCreatingNationalization} />
                    </div>
                </FormProvider>
            </form>
        </Modal>
    );
};

export default ImportFlowNationalizePage;
