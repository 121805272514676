import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PurchaseProductStatusBadge from 'components/badges/purchase-product-status';
import Text from 'components/core/text';
import Empty from 'components/empty';
import { Link, useParams } from 'react-router-dom';
import { OrderServiceFinancialBalance } from 'types/models/order-service';
import { formatDate } from 'utils/date';

type OrderServicePurchaseOrderTableProductProps = {
    purchaseOrders: OrderServiceFinancialBalance['purchaseOrders'];
    withPurchaseProductStatus?: boolean;
};

const OrderServicePurchaseOrderTableProducts = ({ purchaseOrders = [], withPurchaseProductStatus = false }: OrderServicePurchaseOrderTableProductProps) => {
    const { orderServiceId, saleOrderId } = useParams();

    if (!purchaseOrders.length) {
        return <Empty className="p-4" title="Não há ordens de compras a serem mostrados." />;
    }

    return (
        <TableContainer>
            <Table>
                <TableHead className="border-b">
                    <TableRow>
                        <TableCell>
                            <Text as="span" className="text-base-500">
                                Ordem de Compra
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text as="span" className="text-base-500">
                                Justificativa
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text as="span" className="text-base-500">
                                Data
                            </Text>
                        </TableCell>
                        {withPurchaseProductStatus && (
                            <TableCell>
                                <Text as="span" className="text-base-500">
                                    Status
                                </Text>
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {purchaseOrders.map((purchaseOrder) => (
                        <TableRow key={purchaseOrder.id} classes={{ root: 'hover:bg-base-200 hover:bg-opacity-50' }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell>
                                <Link to={`/app/pedidos-de-venda/${saleOrderId}/ordens-de-servico/${orderServiceId}/ordens-de-compra/${purchaseOrder.id}`}>
                                    <Text as="span" className="text-secondary-700 font-medium">
                                        {purchaseOrder.code || '-'}
                                    </Text>
                                </Link>
                            </TableCell>
                            <TableCell>
                                <Text as="span" className="text-base-500">
                                    {purchaseOrder?.justification ?? '-'}
                                </Text>
                            </TableCell>
                            <TableCell>
                                <Text as="span" className="text-base-500">
                                    {formatDate(purchaseOrder?.createdAt, 'DD/MM/YYYY')}
                                </Text>
                            </TableCell>
                            {withPurchaseProductStatus && (
                                <TableCell>
                                    <PurchaseProductStatusBadge purchaseProductStatus={purchaseOrder.purchaseProductStatus} />
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default OrderServicePurchaseOrderTableProducts;
