import Spinner from 'components/core/spinner';
import Datatable from 'components/core/table/datatable';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import useGetOrderServicesPurchaseScope from 'services/queries/order-services/use-get-order-services-purchase-scope';
import Text from 'components/core/text';
import TableAdvancedSearch from 'components/core/table/datatable/components/advanced-search';
import ServiceOrderPurchaseScopeFilter from './purchase-scope/view/filter';
import { serviceOrderExtraPurchaseScopeListColumns } from 'pages/private/sales-orders/utils';
import useConfig from 'store/config/use-config';

const ServiceOrderExtraPurchaseScopePage = () => {
    const { config } = useConfig();
    const { orderServiceId, saleOrderId } = useParams();

    const [page, setPage] = useState(1);
    const [filters, setFilters] = useState<any>();

    const INITIAL_ENDPOINT = `/sale-orders/${saleOrderId}/service-orders/${orderServiceId}/extra-products`;

    const endpoint = INITIAL_ENDPOINT;

    const { data, isLoading: isLoadingList } = useGetOrderServicesPurchaseScope({
        endpoint,
        filters: {
            page,
            totalPerPage: config.tables.rowsPerPage,
            ...filters,
        },
    });

    const { items = [] } = data || {};

    const handleSubmitFilter = (data: any) => setFilters(data);

    return (
        <div>
            {isLoadingList && <Spinner fixed={true} />}
            <div className="flex items-center mb-6 flex-row justify-between">
                <Text as="h3" variant="h5" className="text-heading mr-1">
                    Escopo de compra extra
                </Text>
                <TableAdvancedSearch
                    hasSelectedFilters={true}
                    component={<ServiceOrderPurchaseScopeFilter endpoint={`${INITIAL_ENDPOINT}?type=products`} onSubmit={handleSubmitFilter} />}
                    buttonClassName="!bg-white shadow-lg !relative"
                />
            </div>
            <Datatable
                actions={[]}
                withBorder={false}
                data={items || []}
                options={{
                    sort: true,
                    filter: false,
                    search: false,
                    print: false,
                    download: false,
                    viewColumns: false,
                    serverSide: true,
                    searchAlwaysOpen: false,
                    selectableRows: 'none',
                    enableNestedDataAccess: '.',
                    pagination: true,
                    elevation: 21,
                    count: data?.total,
                    page,
                    onChangePage: (currentPage) => {
                        setPage(currentPage);
                    },
                }}
                columns={serviceOrderExtraPurchaseScopeListColumns}
                loading={isLoadingList}
                className="toolbar-without-search"
            />
        </div>
    );
};

export default ServiceOrderExtraPurchaseScopePage;
