import PageHeader from 'components/page/header';
import { memo, useCallback, useMemo, useState } from 'react';
import PaymentOrderFilter from './filter';
import useGetTransactionsOutcome from 'services/queries/transactions/use-get-transactions-outcome';
import Datatable from 'components/core/table/datatable';
import { useQueryClient } from 'react-query';
import { getTransactionsOutcomeKey } from 'services/queries/transactions/keys';
import _at from 'lodash/at';
import { Transaction } from 'types/models/transaction';
import { FinancialType } from 'types/models/purchase-order';
import { ordersTableColumns } from './utils';
import Toolbar from './toolbar';
import { Outlet, useLocation } from 'react-router-dom';
import ReportPaymentOrReceipt from './actions/report-payment';
import RequestChange from './actions/request-change';
import RescheduleTransaction from './actions/reschedule';
import GeneratePaymentFlow from './actions/generate-payment-flow';
import OPsGrouping from './actions/ops-grouping';
import { MenuItem } from 'components/menu';
import useConfig from 'store/config/use-config';
import { ApprovalStatus } from 'types/general';
import Text from 'components/core/text';
import { formatMoney } from 'utils/money';
import Button from 'components/core/button';
import OPsPrint from './actions/ops-print';
import { UseFormReturn } from 'react-hook-form';
import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import { getNumberValueOrZero } from '../expenses/details/tabs/transactions/utils';
import ReportInvoicing from './actions/report-invoincing';
import { formatDate } from 'utils/date';
import IconButton from '@mui/material/IconButton/IconButton';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import qs from 'qs';
import omit from 'lodash/omit';
import { queryStringURLNumberDecoder } from 'utils/url';
import SvgIcoPrinter from 'components/core/icon/files/ico-printer';
import SvgIcoHide from 'components/core/icon/files/ico-hide';
import SvgIcoShow from 'components/core/icon/files/ico-show';
import SvgIcoFilter from 'components/core/icon/files/ico-filter';

const endpoint = '/transactions/outcomes';

const PaymentOrdersListPage = ({ page }: WithPagePermissionsProps) => {
    const { config, setConfig } = useConfig();
    const queryClient = useQueryClient();
    const { search } = useLocation();

    const filtersParams = qs.parse(search, {
        ignoreQueryPrefix: true,
        decoder: queryStringURLNumberDecoder,
    });

    const [currentModal, setCurrentModal] = useState<string>();
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [selectedItems, setSelectedItems] = useState<Transaction[]>([]);
    const [selectedRowsIndexes, setSelectedRowsIndexes] = useState<number[]>([]);

    const [filters, setFilters] = useState<any>({
        approvalStatus: ApprovalStatus.Approved,
        financialType: FinancialType.Analytic,
        ...omit(filtersParams, ['origins', 'subOrigins', 'transactionProvisioned', 'paid']),
        ...(Boolean(filtersParams?.origins) && { classificationOrigins: filtersParams.origins }),
        ...(Boolean(filtersParams?.subOrigins) && { classificationSubOrigins: filtersParams.subOrigins }),
        ...(Boolean(filtersParams?.transactionProvisioned) && { provisioned: filtersParams.transactionProvisioned === 'provisioned' }),
        ...(Boolean(filtersParams?.paid) && { paid: filtersParams?.paid === 'true' }),
        page: 1,
    });

    const {
        data: orders,
        isLoading: isLoadingList,
        remove,
    } = useGetTransactionsOutcome({
        endpoint,
        filters: {
            ...filters,
            totalPerPage: config.tables.rowsPerPage,
        },
    });

    const { data: totalOrders, remove: updateTotalOrders } = useGetTransactionsOutcome<{ total: number }>({
        endpoint,
        filters: {
            ...filters,
            totalPerPage: config.tables.rowsPerPage,
        },
        type: 'numbers',
    });

    const handleSubmitFilter = (hideModalFilter = true) => {
        return async (data: any) => {
            remove();
            updateTotalOrders();
            setFilters({
                ...data,
                ...(Boolean(data.provisionStartDate) && { provisionStartDate: formatDate(data.provisionStartDate, 'YYYY-MM-DD') }),
                ...(Boolean(data.provisionEndDate) && { provisionEndDate: formatDate(data.provisionEndDate, 'YYYY-MM-DD') }),
                ...(Boolean(data.startPayday) && { startPayday: formatDate(data.startPayday, 'YYYY-MM-DD') }),
                ...(Boolean(data.endPayday) && { endPayday: formatDate(data.endPayday, 'YYYY-MM-DD') }),
                ...(Boolean(data.startDueDate) && { startDueDate: formatDate(data.startDueDate, 'YYYY-MM-DD') }),
                ...(Boolean(data.endDueDate) && { endDueDate: formatDate(data.endDueDate, 'YYYY-MM-DD') }),
                ...(Boolean(data.startCreated) && { startCreated: formatDate(data.startCreated, 'YYYY-MM-DD') }),
                ...(Boolean(data.endCreated) && { endCreated: formatDate(data.endCreated, 'YYYY-MM-DD') }),
                page: 1,
            });
            if (hideModalFilter) {
                setIsFilterVisible(false);
            }
        };
    };

    const handleCloseModal = () => {
        setCurrentModal(undefined);
        setSelectedItems([]);
    };

    const handleUpdatePayments = () => {
        queryClient.invalidateQueries(getTransactionsOutcomeKey(endpoint, filters));
        handleCloseModal();
    };

    const handleOpenModal = useCallback(
        (modal: string) => () => {
            setSelectedRowsIndexes([]);
            setCurrentModal(modal);
        },
        []
    );

    const Component = useMemo(() => {
        const modals = {
            'informar-pagamento': ReportPaymentOrReceipt,
            'informar-faturamento': ReportInvoicing,
            'solicitar-alteração-de-provisionamento': RequestChange,
            reprogramar: RescheduleTransaction,
            'gerar-fluxo-de-pagamento': GeneratePaymentFlow,
            'agrupar-ops': OPsGrouping,
            print: OPsPrint,
        };

        return modals[currentModal!] ?? null;
    }, [currentModal]);

    const purchaseOrderActions = useMemo<MenuItem[]>(() => {
        return [
            {
                label: 'Informar pagamento',
                onClick: handleOpenModal('informar-pagamento'),
            },
            {
                label: 'Informar faturamento',
                onClick: handleOpenModal('informar-faturamento'),
            },
            {
                label: 'Solicitar alteração de provisionamento',
                onClick: handleOpenModal('solicitar-alteração-de-provisionamento'),
            },
            {
                label: 'Reprogramar',
                onClick: handleOpenModal('reprogramar'),
            },
            {
                label: 'Agrupar OPs',
                onClick: () => {
                    const hasForbiddenStatus = selectedItems.some((item) => {
                        return (item.payday && item.paid) || item.expense.company?.id !== selectedItems[0].expense.company?.id;
                    });

                    if (hasForbiddenStatus) {
                        return alert('Somente as OPs em aberto e com a mesma empresa podem ser agrupadas');
                    }

                    handleOpenModal('agrupar-ops')();
                },
            },
            {
                label: 'Gerar fluxo de pagamento',
                onClick: handleOpenModal('gerar-fluxo-de-pagamento'),
            },
        ];
    }, [selectedItems, handleOpenModal]);

    const handleSort = (changedColumn: string, direction: 'desc' | 'asc') => {
        return setFilters((prev) => ({
            ...prev,
            orderBy: { [changedColumn]: direction },
        }));
    };

    const FormActions = ({ formMethods }: { formMethods: UseFormReturn<any, any> }) => {
        const handleClear = () => handleSubmitFilter(true)(undefined);

        const handlePrint = () => {
            const values = formMethods.getValues();

            handleSubmitFilter(false)(values);
            setCurrentModal('print');
        };

        return (
            <div className="flex items-center">
                <div className="flex flex-1">
                    <Button type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-2">
                        Filtrar
                    </Button>
                    <div className="text-base-700">
                        <Button startIcon={<SvgIcoPrinter width={16} height={16} />} type="button" color="inherit" className="min-w-[100px]" onClick={handlePrint}>
                            Imprimir
                        </Button>
                    </div>
                </div>
                <Button type="button" color="primary" className="min-w-[100px] underline" variant="text" onClick={handleClear}>
                    Limpar filtros
                </Button>
            </div>
        );
    };

    const pageKeyContent = `${page?.key}_${page?.id}`;
    const isTruncatedColumn = config.tables.pages?.[pageKeyContent]?.isTruncatedColumn ?? false;

    const toggleTruncateColumns = () => {
        setConfig('tables', {
            ...config.tables,
            pages: {
                ...config.tables.pages,
                [pageKeyContent!]: {
                    ...config.tables.pages?.[pageKeyContent!],
                    isTruncatedColumn: !isTruncatedColumn,
                },
            },
        });
    };

    const TruncatedIcon = isTruncatedColumn ? SvgIcoHide : SvgIcoShow;

    return (
        <>
            {isFilterVisible && <PaymentOrderFilter filters={filters} onSubmit={handleSubmitFilter(true)} onClose={setIsFilterVisible.bind(this, false)} FormActionsComponent={FormActions} />}
            <PageHeader
                className="mb-6"
                title="Ordens de pagamento"
                right={
                    <div className="flex gap-2">
                        <div className="flex items-center">
                            <Tooltip title={`${isTruncatedColumn ? 'Expandir' : 'Contrair'} colunas`} placement="top" disableFocusListener={true}>
                                <IconButton onClick={toggleTruncateColumns}>
                                    <TruncatedIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className="flex items-center rounded-xl bg-primary-100 bg-opacity-20">
                            <Text variant="body.regular.sm" className="text-heading py-1 px-3 !font-bold">
                                Total OPs: {formatMoney(totalOrders?.total)}
                            </Text>
                        </div>
                        <Button
                            color="inherit"
                            variant="outlined"
                            type="button"
                            size="medium"
                            className="bg-white"
                            onClick={setIsFilterVisible.bind(this, true)}
                            endIcon={<SvgIcoFilter width={17} height={17} color="currentColor" />}>
                            Filtrar
                        </Button>
                    </div>
                }
            />
            <Datatable
                withBorder={false}
                data={orders?.items || []}
                pageKeyContent={pageKeyContent}
                options={{
                    search: true,
                    searchAlwaysOpen: true,
                    searchPlaceholder: `Pesquisar ordem de pagamento`,
                    sort: true,
                    filter: false,
                    print: false,
                    download: false,
                    viewColumns: true,
                    serverSide: true,
                    enableNestedDataAccess: '.',
                    pagination: true,
                    elevation: 21,
                    count: orders?.total,
                    page: filters.page,
                    rowsSelected: selectedRowsIndexes || [],
                    onSearchChange: (searchText) => setFilters((prev) => ({ ...prev, searchText })),
                    onColumnSortChange: handleSort,
                    onChangePage: (currentPage) => setFilters((prev) => ({ ...prev, page: currentPage })),
                    customToolbarSelect: () => {
                        const total = _at(orders?.items || [], selectedRowsIndexes || []).reduce((acc, curr) => acc + getNumberValueOrZero((curr.netValue || 0).toFixed(2)), 0);

                        return (
                            <div className="flex items-center">
                                <Text variant="body.regular.sm" className="text-heading bg-primary-100 bg-opacity-20 py-1 px-3 rounded-lg !font-bold">
                                    Total OPs selecionadas: {formatMoney(total)}
                                </Text>
                                <Toolbar content={purchaseOrderActions} />
                            </div>
                        );
                    },
                    onRowSelectionChange: (_: any, __: any, rowsSelected) => {
                        setSelectedRowsIndexes?.(rowsSelected || []);

                        const arr = _at(orders?.items || [], rowsSelected || []);

                        setSelectedItems(arr);
                    },
                }}
                columns={ordersTableColumns(isTruncatedColumn)}
                loading={isLoadingList}
            />
            <Outlet />
            {!!currentModal && <Component selectedItems={selectedItems} filters={filters} onClose={setCurrentModal.bind(this, undefined)} onSuccess={handleUpdatePayments} />}
        </>
    );
};

export default memo(PaymentOrdersListPage);
