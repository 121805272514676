import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import { getTransactionsOutcomeKey } from '../transactions/keys';
import { useShowApiErrors } from 'hooks/error/show-api-errors';
import { OrderServiceDeduction } from 'types/models/order-service';
import { Modify } from 'types/general';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';

type PickedOrderServiceDeduction = Pick<OrderServiceDeduction, 'value' | 'orderService' | 'transaction'>;
type ModifiedOrderServiceDeduction = Modify<PickedOrderServiceDeduction, { orderService: number; transaction: number }>;

type CreateOrderServiceDeductionPayload = {
    deductions: Array<ModifiedOrderServiceDeduction>;
};

const useCreateOrderServiceDeduction = (filters: any) => {
    const { showToast } = useToast();
    const { showToastErrors } = useShowApiErrors();
    const queryClient = useQueryClient();

    const goToParentRoute = useNavigateToParentRoute();

    const request = async (payload: CreateOrderServiceDeductionPayload) => api.post('/order-service-deductions', payload);

    return useMutation(request, {
        onSuccess: () => {
            showToast('Dedução criada com sucesso!', 'success');

            queryClient.invalidateQueries(getTransactionsOutcomeKey('/transactions/outcomes', filters));

            goToParentRoute();
        },
        onError: (error: any) => {
            const fallbackMessage = 'Ocorreu um erro ao tentar criar uma dedução, por favor, tente novamente.';
            showToastErrors(error, error?.response?.data?.title || fallbackMessage);
        },
    });
};

export default useCreateOrderServiceDeduction;
