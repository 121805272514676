import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';
import { Status } from 'types/general';
import { affectedRowsKey, pkColumnsKey } from 'utils/environment';

export const getProfileKey = (profileId?: string) => ['profile', profileId];
export const getProfilesKey = ['get', 'all', 'profile', 'options'];

const queries = {
    createProfile: `mutation ($payload: ProfileInsertInput!) {
        item: insertProfileOne(object: $payload) {
            id
        }
    }`,
    updateProfile: `mutation ($payload: ProfileSetInput!, $id: Int!) {
        updateProfileByPk(${pkColumnsKey}: { id: $id }, _set: $payload) {
            id
        }
    }`,
    createProfilePageRules: `mutation ($payload: [ProfilePageRuleInsertInput!]!) {
        item: insertProfilePageRule(objects: $payload) {
            ${affectedRowsKey}
        }
    }`,
    getProfileOptions: jsonToGraphQLQuery(
        {
            query: {
                __name: 'GetProfilesOptions',
                items: {
                    __aliasFor: 'profile',
                    __args: {
                        orderBy: { name: new EnumType('ASC') },
                        where: { status: { _eq: Status.Active }, system: { _eq: false } },
                    },
                    value: {
                        __aliasFor: 'id',
                    },
                    label: {
                        __aliasFor: 'name',
                    },
                },
            },
        },
        { pretty: true }
    ),
};

export default queries;
