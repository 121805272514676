import { useQuery } from 'react-query';
import { Option } from 'types/general';
import { GraphqlPagination } from 'types/graphql';
import { getProfilesKey } from './keys';

import apiGraphQl from 'services/graphql';
import profile from './keys';

const useGetProfilesOptions = (enabled: boolean) => {
    const request = async () => {
        const { items } = await apiGraphQl<GraphqlPagination<Option>>(profile.getProfileOptions);

        return items;
    };

    return useQuery(getProfilesKey, request, { enabled });
};

export default useGetProfilesOptions;
