import Spinner from 'components/core/spinner';
import { lazy, Suspense } from 'react';
import useAuth from 'store/auth/use-auth';
import retry from 'utils/retry';

const Home = () => {
    const { auth } = useAuth();

    const Dashboard = lazy(() => retry(() => import(`${auth.credentials.user.profile?.dashboardTemplate?.key?.replace('_', '-') || './dashboards/default'}/index.tsx`)));

    return (
        <Suspense
            fallback={
                <div className="relative w-full h-full">
                    <Spinner />
                </div>
            }>
            <Dashboard />
        </Suspense>
    );
};

export default Home;
