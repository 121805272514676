import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import useToast from 'hooks/toast/use-toast';
import { useNavigate } from 'react-router-dom';
import { getExpenseKey } from './keys';

export type UseReportPaymentPayload = {
    mode: 'approve' | 'disapprove';
};

export type UseMutateExpenseStatusProps = {
    expenseId?: string | number;
};

const useChangeExpenseStatus = ({ expenseId }: UseMutateExpenseStatusProps) => {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const request = async (payload: UseReportPaymentPayload) => {
        const { mode } = payload;

        return api.patch(`/expenses/status/${mode}`, { expenses: [expenseId] });
    };

    return useMutation(request, {
        onSuccess: () => {
            showToast('Despesa atualizada com sucesso!', 'success');
            navigate(`/app/despesas/${expenseId}`, { replace: true });

            queryClient.invalidateQueries(getExpenseKey(expenseId));
        },
        onError: (error: any) => {
            showToast(error?.response?.data?.title ?? 'Tivemos um problema ao atualizar a despesa. Tente novamente.', 'danger');
        },
    });
};

export default useChangeExpenseStatus;
