import Datatable from 'components/core/table/datatable';
import { Rule } from 'types/permissions';
import PageHeader from 'components/page/header';
import { Link, Outlet } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import useTheme from '@mui/material/styles/useTheme';
import { PageProps } from 'pages/shared/page';
import useGetPaginatedWithFilters from 'services/queries/general/use-get-paginated-with-filters';
import { DatatableColumn } from 'types/graphql';
import ConfirmModal from 'components/core/modal/confirm';
import { useQueryClient } from 'react-query';
import { getPaginatedWithFiltersKey } from 'services/queries/general/keys';
import useConfig from 'store/config/use-config';
import useDelete from 'services/queries/crud/use-delete';
import { Visit } from 'types/models/visit';
import TableCell from '@mui/material/TableCell/TableCell';
import SvgIcoPlus from 'components/core/icon/files/ico-plus';

const ENDPOINT = '/visits';

const columns: DatatableColumn[] = [
    {
        name: 'id',
        options: { display: 'excluded' },
    },
    { name: 'code', label: 'Código' },
    { name: 'quizAnswer.quiz.title', label: 'Formulário' },
    { name: 'person.name', label: 'Respondido por' },
    {
        name: 'actions',
        label: 'Ações',
        options: { customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} /> },
    },
];

const VisitsPage = ({ page: pagePermissions, title }: PageProps<{}>) => {
    const { palette } = useTheme();
    const { config } = useConfig();
    const queryClient = useQueryClient();

    const [page, setPage] = useState(1);
    const [filters, setFilters] = useState<any[]>([]);
    const [orderBy, setOrderBy] = useState<any>(null);
    const [visitIdToDelete, setVisitIdToDelete] = useState<number>();

    const { data: visits } = useGetPaginatedWithFilters<Visit>({ endpoint: ENDPOINT, filters, page, orderBy });

    const { mutateAsync: deleteVisit, isLoading: isDeleting } = useDelete({
        apiPayload: { endpoint: ENDPOINT, useApiTodelete: true },
    });

    const actions = pagePermissions?.rules.map((item) => item.key);
    const canCreateLoan = actions?.includes(Rule.Create);

    const handleDeleteVisit = async () => {
        try {
            if (!!visitIdToDelete) {
                await deleteVisit({ id: visitIdToDelete });

                queryClient.invalidateQueries(getPaginatedWithFiltersKey(ENDPOINT, filters, page, config.tables.rowsPerPage, orderBy));

                setVisitIdToDelete(undefined);
            }
        } catch (error) {
            console.error('handleDeleteVisit: ', error);
        }
    };

    return (
        <>
            <PageHeader
                className="mb-6"
                title={title}
                right={
                    canCreateLoan ? (
                        <IconButton component={Link} to="novo" color="secondary" size="large" sx={{ backgroundColor: palette.secondary.main, '&:hover': { backgroundColor: palette.secondary.dark } }}>
                            <SvgIcoPlus width={16} height={16} color={palette.grey[100]} />
                        </IconButton>
                    ) : undefined
                }
            />
            <Datatable
                actions={actions}
                data={visits?.items || []}
                columns={columns}
                options={{
                    sort: true,
                    filter: false,
                    search: true,
                    print: false,
                    download: false,
                    viewColumns: false,
                    serverSide: true,
                    searchAlwaysOpen: true,
                    enableNestedDataAccess: '.',
                    pagination: true,
                    elevation: 21,
                    count: visits?.total,
                    page,
                    onChangePage: setPage,
                    onSearchChange: (searchText) => setFilters((prev) => ({ ...prev, searchText })),
                    onColumnSortChange: (changedColumn, direction) => setOrderBy({ [changedColumn]: direction.toUpperCase() }),
                }}
                onDelete={setVisitIdToDelete}
            />
            <Outlet context={{ endpoint: ENDPOINT, filters, page, orderBy }} />
            {Boolean(visitIdToDelete) && (
                <ConfirmModal
                    onConfirmAction={handleDeleteVisit}
                    onClose={setVisitIdToDelete.bind(this, undefined)}
                    description="Você tem certeza que deseja apagar este visita?"
                    title="Apagar visita"
                    isLoading={isDeleting}
                />
            )}
        </>
    );
};

export default VisitsPage;
