import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from '@mui/material/TableRow/TableRow';
import Text from 'components/core/text';
import { Controller, ControllerRenderProps, useFormContext, useWatch } from 'react-hook-form';
import { StockProduct } from 'types/models/stock';
import { StockDeliveryPayload } from './stock-delivery-item';
import ErrorMessage from 'components/error/message';
import CurrencyInput from 'components/core/form/currency';
import { OnValueChange } from 'react-number-format';
import Checkbox from 'components/core/form/checkbox';
import Tooltip from '@mui/material/Tooltip';
import classNames from 'classnames';
import { formatNumberToPTBR } from 'utils/money';

type StockDeliveryItemRowProps = {
    stockIndex: number;
    purchaseOrderProductIndex: number;
} & Partial<StockProduct>;

const StockDeliveryItemRow = ({ stock, quantity = 0, purchaseOrderProductIndex, stockIndex }: StockDeliveryItemRowProps) => {
    const { formState, control, setValue } = useFormContext<StockDeliveryPayload>();

    const quantityFieldName = `purchaseOrderProducts.${purchaseOrderProductIndex}.stocks.${stockIndex}.quantity` as const;
    const quantityInStockError = formState.errors?.purchaseOrderProducts?.[purchaseOrderProductIndex]?.stocks?.[stockIndex]?.quantity?.message;

    const handleChange: OnValueChange = (values) => setValue(quantityFieldName, values.floatValue || 0);
    const handleCheckChange = (field: ControllerRenderProps<StockDeliveryPayload, `purchaseOrderProducts.${number}.stocks.${number}.isChecked`>) => {
        field.onChange(!field.value);

        if (field.value) {
            setValue(`purchaseOrderProducts.${purchaseOrderProductIndex}.stocks.${stockIndex}.quantity`, 0);
        }
    };

    const isRowChecked = useWatch({ control, name: `purchaseOrderProducts.${purchaseOrderProductIndex}.stocks.${stockIndex}.isChecked`, defaultValue: false });

    return (
        <>
            <TableRow
                classes={{ root: !isRowChecked ? 'bg-base-200 cursor-no-drop' : 'hover:bg-base-200' }}
                sx={{ '&:last-child td': { borderBottom: 0 } }}
                className={!!quantityInStockError && isRowChecked ? 'bg-system-danger-100 bg-opacity-20' : 'bg-base-100'}>
                <TableCell className="border-r border-r-base-300 py-0 opacity-100">
                    <Controller
                        control={control}
                        name={`purchaseOrderProducts.${purchaseOrderProductIndex}.stocks.${stockIndex}.isChecked`}
                        render={({ field }) => (
                            <Tooltip placement="top" title="Selecionar estoque" disableFocusListener={true}>
                                <Checkbox name={field?.name} ref={field?.ref} checked={!!field?.value} onChange={handleCheckChange.bind(this, field)} />
                            </Tooltip>
                        )}
                    />
                </TableCell>
                <TableCell className="border-r border-r-base-300 py-0">
                    <Text className={classNames(!isRowChecked ? 'text-base-400' : 'text-base-500')}>{stock?.name}</Text>
                </TableCell>
                <TableCell className="border-r border-r-base-300 py-0">
                    <Text className={classNames('text-right block', !isRowChecked ? 'text-base-400' : 'text-base-500')}>{formatNumberToPTBR(quantity)}</Text>
                </TableCell>
                <TableCell className="p-0">
                    <Controller
                        control={control}
                        name={quantityFieldName}
                        render={({ field }) => {
                            return (
                                <CurrencyInput
                                    value={field.value}
                                    withFormat={true}
                                    decimalScale={2}
                                    className={classNames('border-none bg-transparent text-right', !isRowChecked && '!text-base-400')}
                                    inputWrapperClasses="border-none rounded-none"
                                    placeholder="Digite uma quantidade"
                                    disabled={!isRowChecked}
                                    onValueChange={handleChange}
                                />
                            );
                        }}
                    />
                </TableCell>
            </TableRow>
            {!!quantityInStockError && isRowChecked && (
                <TableRow sx={{ '&:last-child td': { borderBottom: 0 } }}>
                    <TableCell colSpan={4}>
                        <ErrorMessage visible={true}>{quantityInStockError}</ErrorMessage>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};

export default StockDeliveryItemRow;
