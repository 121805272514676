import { useQuery } from 'react-query';
import apiGraphQl from 'services/graphql';
import { getBankAccountsByEntityKeys } from './keys';
import { Option } from 'types/general';
import { Pagination } from 'types/pagination';
import { queryCompanyBankAccountsOptions } from './utils';
import { FormFavoredType } from 'types/models/favored';

const useGetBankAccountsByEntity = (favored?: Option & { type: FormFavoredType }, isBankAccountVisible = false) => {
    const isProvider = favored?.type === 'provider';

    const request = async () => {
        const key = isProvider ? 'providerId' : 'personId';

        const whereClause = {
            [key]: { _eq: favored?.value },
        };

        const { items } = await apiGraphQl<Pagination<{ bankAccount: Option }>>(queryCompanyBankAccountsOptions(whereClause, isProvider ? 'providerBankAccount' : 'personBankAccount'));

        return items.map(({ bankAccount }) => bankAccount);
    };

    return useQuery(getBankAccountsByEntityKeys(favored?.type, favored?.value), request, { enabled: isBankAccountVisible });
};

export default useGetBankAccountsByEntity;
