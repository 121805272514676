import Tooltip from '@mui/material/Tooltip/Tooltip';
import ButtonBase from '@mui/material/ButtonBase/ButtonBase';
import Text from 'components/core/text';
import { Notification } from 'types/models/notification';
import useReadNotifications from 'services/queries/notifications/use-read-notifications';
import { useNavigate } from 'react-router-dom';
import TimeAgo from 'components/timeago';
import useToast from 'hooks/toast/use-toast';

type NotificationItemProps = {
    onCloseMenu: () => void;
} & Notification;

const NotificationItem = ({ id, subject, text, createdAt, pageUri, onCloseMenu }: NotificationItemProps) => {
    const navigate = useNavigate();
    const { showToast } = useToast();

    const { mutateAsync: readNotifications } = useReadNotifications();

    const handleClick = async () => {
        try {
            await readNotifications([id]);
        } catch (error) {
            console.error('readNotification: ', error);
            showToast('Ocorreu um erro ao ler a notificação.', 'danger');
        } finally {
            if (!!pageUri) {
                navigate('/' + pageUri);
            }
            onCloseMenu();
        }
    };

    return (
        <Tooltip
            title={
                <>
                    {subject}
                    <br />
                    {text}
                </>
            }
            placement="left">
            <ButtonBase className="w-full px-3 py-2 hover:bg-base-200 flex rounded-[14px] justify-[unset] bg-base-200" onClick={handleClick}>
                <div className="grow-[1] min-w-0">
                    <Text as="span" variant="body.regular.sm" className="text-heading block whitespace-nowrap text-ellipsis overflow-hidden">
                        {subject}
                    </Text>
                    <Text as="span" variant="body.regular.xs" className="text-base-500 block whitespace-nowrap text-ellipsis overflow-hidden italic">
                        {text}
                    </Text>
                    {!!createdAt && (
                        <Text as="span" variant="body.regular.xs" className="text-base-400 block whitespace-nowrap text-ellipsis overflow-hidden mt-2">
                            <TimeAgo date={createdAt} />
                        </Text>
                    )}
                </div>
                <div className="bg-secondary-500 h-[6px] rounded-full basis-[6px] shrink-0 ml-2" />
            </ButtonBase>
        </Tooltip>
    );
};

export default NotificationItem;
