import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
import { getAllKey } from '../crud/keys';

type Payload = {
    formOrigin: { orderService: number };
    products: Array<{
        quantity: number;
        approved: boolean;
        justification: string;
        product: number;
        orderServiceProductSale: number;
        orderServiceProductPurchase: number | null;
    }>;
};

const useCreateOrUpdatePurchaseOrder = (queryListParams?: any, orderServiceId?: string, saleOrderId?: string) => {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const request = async (payload: Payload) => api.post('/purchase-orders', payload);

    return useMutation(request, {
        onSuccess: ({ data }) => {
            showToast('Ordem de compra criada com sucesso', 'success');

            if (!!queryListParams) {
                queryClient.invalidateQueries(getAllKey('purchaseOrder', queryListParams));
            }

            navigate(`/app/pedidos-de-venda/${saleOrderId}/ordens-de-servico/${orderServiceId}/ordens-de-compra/${data.id}`);
        },
        onError: (error: any) => {
            const defaultMessage = 'Ocorreu um erro ao criar a ordem de compra';
            const apiErrorMessage = error?.response?.data?.title || defaultMessage;

            showToast(apiErrorMessage?.toLowerCase()?.includes('validation') ? defaultMessage : apiErrorMessage, 'danger');
        },
    });
};

export default useCreateOrUpdatePurchaseOrder;
