import { forwardRef } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { FormFactoryField } from 'types/form-factory';
import Input from '../input';
import Select from '../select';

type FormFactoryItemProps = ControllerRenderProps<any> & FormFactoryField<any> & { error?: string };

const LocalSelect = forwardRef<any, Omit<FormFactoryItemProps, 'type'>>((props, ref) => {
    const { optionsQuery, staticOptions = [], ...rest } = props;

    const query = optionsQuery?.();
    const arr = query?.data || staticOptions;

    const value = arr.find((item) => (item as any)?.value === rest.value);

    return <Select {...rest} isDisabled={rest.disabled} options={arr} ref={ref} value={value} onChange={(option: any) => rest.onChange(option.value)} />;
});

const FormFactoryItem = forwardRef(({ type, ...field }: FormFactoryItemProps, ref: any) => {
    switch (type) {
        case 'text':
        case 'number':
        case 'email':
            return <Input type={type} {...field} ref={ref} />;
        case 'select':
            return <LocalSelect {...field} ref={ref} />;

        default:
            return null;
    }
});

export default FormFactoryItem;
