import { EnumType, jsonToGraphQLQuery, VariableType } from 'json-to-graphql-query';
import { Status } from 'types/general';
import { saleOrderScheduleStatus, SaleOrderScheduleStatus } from 'types/models/sale-order';
import { convertArrayToObject } from 'utils/array';
import { affectedRowsKey, pkColumnsKey } from 'utils/environment';

export const createOrUpdateSaleOrderMutation = (saleOrderId?: string) => {
    return jsonToGraphQLQuery(
        {
            mutation: {
                __name: 'CreateOrUpdateSaleOrder',
                __variables: {
                    ...(saleOrderId
                        ? {
                              payload: 'SaleOrderSetInput',
                              id: 'Int!',
                          }
                        : {
                              payload: 'SaleOrderInsertInput!',
                          }),
                },
                item: {
                    __aliasFor: saleOrderId ? 'updateSaleOrderByPk' : 'insertSaleOrderOne',
                    __args: {
                        ...(saleOrderId
                            ? {
                                  [pkColumnsKey]: { id: new VariableType('id') },
                                  _set: new VariableType('payload'),
                              }
                            : {
                                  object: new VariableType('payload'),
                              }),
                    },
                    id: true,
                },
            },
        },
        { pretty: true }
    );
};

export const createOrUpdateSaleOrderProductMutation = (saleOrderProductId?: string) => {
    return jsonToGraphQLQuery(
        {
            mutation: {
                __name: 'CreateOrUpdateSaleOrderProduct',
                __variables: {
                    ...(saleOrderProductId
                        ? {
                              payload: 'SaleOrderProductSetInput',
                              id: 'Int!',
                          }
                        : {
                              payload: '[SaleOrderProductInsertInput!]!',
                          }),
                },
                item: {
                    __aliasFor: saleOrderProductId ? 'updateSaleOrderProductByPk' : 'insertSaleOrderProduct',
                    __args: {
                        ...(saleOrderProductId
                            ? {
                                  [pkColumnsKey]: { id: new VariableType('id') },
                                  _set: new VariableType('payload'),
                              }
                            : {
                                  objects: new VariableType('payload'),
                              }),
                    },
                    ...(Boolean(saleOrderProductId) ? { id: true } : { [affectedRowsKey]: true }),
                },
            },
        },
        { pretty: true }
    );
};

export const getSaleOrderDocument = (fields: string[], saleOrderDocumentId?: string) => {
    const fieldsToGraphql = convertArrayToObject(fields);

    return jsonToGraphQLQuery(
        {
            query: {
                __name: 'GetSaleOrderDocument',
                saleOrderDocumentByPk: {
                    __args: { id: Number(saleOrderDocumentId || 0) },
                    ...fieldsToGraphql,
                },
            },
        },
        { pretty: true }
    );
};

export const getSaleOrderStatusOptionsQuery = jsonToGraphQLQuery({
    query: {
        __name: 'GetSaleOrderStatusOptions',
        items: {
            __aliasFor: 'saleOrderStatus',
            label: {
                __aliasFor: 'title',
            },
            value: {
                __aliasFor: 'id',
            },
            __args: {
                orderBy: {
                    title: new EnumType('ASC'),
                },
                where: {
                    status: { _eq: Status.Active },
                },
            },
        },
    },
});

export const getSaleOrderScheduleDaysOptionsQuery = jsonToGraphQLQuery(
    {
        query: {
            __name: 'GetSaleOrderScheduleDaysOptions',
            items: {
                __aliasFor: 'scheduleDay',
                __args: {
                    orderBy: {
                        scheduleDay: new EnumType('ASC'),
                    },
                    where: {
                        status: { _eq: Status.Active },
                        open: { _eq: true },
                    },
                    distinctOn: new EnumType('scheduleDay'),
                },
                label: { __aliasFor: 'scheduleDay' },
                value: { __aliasFor: 'id' },
            },
        },
    },
    { pretty: true }
);

export const getSaleOrderScheduleHoursOptionsQuery = (scheduleDayId?: string) =>
    jsonToGraphQLQuery(
        {
            query: {
                __name: 'GetSaleOrderScheduleHoursByScheduleDay',
                items: {
                    __aliasFor: 'scheduleDay',
                    __args: {
                        orderBy: {
                            scheduleDay: new EnumType('ASC'),
                        },
                        where: {
                            status: { _eq: Status.Active },
                            open: { _eq: true },
                            id: { _eq: scheduleDayId },
                        },
                    },
                    label: { __aliasFor: 'scheduleTime' },
                    value: { __aliasFor: 'id' },
                },
            },
        },
        { pretty: true }
    );

export const scheduleStatusBadgeLiteral = {
    [SaleOrderScheduleStatus.Canceled]: {
        label: saleOrderScheduleStatus[SaleOrderScheduleStatus.Canceled],
        color: 'error',
    },
    [SaleOrderScheduleStatus.Requested]: {
        label: saleOrderScheduleStatus[SaleOrderScheduleStatus.Requested],
        color: 'info',
    },
    [SaleOrderScheduleStatus.Delivered]: {
        label: saleOrderScheduleStatus[SaleOrderScheduleStatus.Delivered],
        color: 'success',
    },
};
