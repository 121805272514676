import { useQuery } from 'react-query';
import apiGraphQl from 'services/graphql';
import { Option } from 'types/general';
import { getBranchesOptionsKey } from './keys';

type Response = {
    items: Option[];
};

const useGetBranchesOptions = (query: string) => {
    const request = async () => apiGraphQl<Response>(query).then(({ items }) => items);

    return useQuery(getBranchesOptionsKey, request);
};

export default useGetBranchesOptions;
