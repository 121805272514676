import CreateCommissionPage from 'pages/private/commissions/create';
import Page from 'pages/shared/page';
import { RouteObject } from 'react-router-dom';
import { Rule } from 'types/permissions';

const comissions: RouteObject[] = [
    {
        // @ts-ignore
        breadcrumb: 'Nova comissão',
        path: 'comissoes/novo',
        element: <Page Component={CreateCommissionPage} code="comissoes" permissions={[Rule.Create]} withModal={true} />,
    },
];

export default comissions;
