import Tab from '@mui/material/Tab/Tab';
import Tabs from '@mui/material/Tabs/Tabs';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { useCallback } from 'react';
import { Steps } from './context/types';
import { useImportFlowContext } from './context';
import General from './tabs/general';
import Products from './tabs/products';
import Expenses from './tabs/expenses';

type CreateOrUpdateImportFlowProps = {
    title: string;
};

const CreateOrUpdateImportFlow = ({ title }: CreateOrUpdateImportFlowProps) => {
    const { state, changeStep } = useImportFlowContext();

    const getComponent = (slug: Steps) => {
        const items = new Map<Steps, any>([
            [Steps.GeneralInfo, General],
            [Steps.Products, Products],
            [Steps.Expenses, Expenses],
        ]);

        const Component = items.get(slug);

        return <Component /> ?? null;
    };

    const handleChangeTab = useCallback((_: any, nextSlug: any) => changeStep(state.currentStep, nextSlug), [state.currentStep, changeStep]);

    return (
        <Modal
            contentClassnames="w-[1400px]"
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    {title}
                </Text>
            }
            closeOnClickOutside={false}>
            <Tabs classes={{ root: 'px-4' }} value={state.currentStep} onChange={handleChangeTab}>
                {state.steps
                    .filter(({ hide = false }) => !hide)
                    .map((item, index) => {
                        const isDisabled = !state.completeds.find((step) => step === item.slug) && item.slug !== state.currentStep;

                        return <Tab value={item.slug} disabled={isDisabled} key={`${item.slug}_${index}`} label={item.label.toUpperCase()} />;
                    })}
            </Tabs>
            {getComponent(state.currentStep)}
        </Modal>
    );
};

export default CreateOrUpdateImportFlow;
