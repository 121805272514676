import * as React from 'react';
const SvgIcoPin = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" width="1em" height="1em" {...props}>
        <path
            fill="currentColor"
            d="M6.018 5.018a5.182 5.182 0 0 1 8.846 3.664c0 2.005-1.308 3.964-2.76 5.498a19.31 19.31 0 0 1-2.422 2.148A19.302 19.302 0 0 1 7.26 14.18C5.806 12.646 4.5 10.687 4.5 8.682c0-1.374.546-2.693 1.518-3.664Zm3.247 12.856.417-.624.416.624a.75.75 0 0 1-.833 0Zm0 0 .417-.624c.416.624.417.624.417.623h.001l.005-.004.015-.01.056-.038.2-.142a20.802 20.802 0 0 0 2.816-2.467c1.513-1.597 3.172-3.922 3.172-6.53A6.682 6.682 0 1 0 3 8.682c0 2.608 1.659 4.933 3.171 6.53a20.804 20.804 0 0 0 3.017 2.609l.055.038.016.01.004.003.002.002Zm-.81-9.192a1.227 1.227 0 1 1 2.454 0 1.227 1.227 0 0 1-2.454 0Zm1.227-2.727a2.727 2.727 0 1 0 0 5.454 2.727 2.727 0 0 0 0-5.454Z"
        />
    </svg>
);
export default SvgIcoPin;
