import { combineReducers } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import auth, { AUTH_INITIAL_STATE } from './auth';
import config from './config';
import breadcrumb, { BREADCRUMB_INITIAL_STATE } from './breadcrumb';
import credentials, { CREDENTIALS_INITIAL_STATE } from './credentials';
import update from './update';
import { Types as AuthTypes } from './auth';
import { ReduxAction } from 'types/general';

const appReducer = combineReducers({
    auth,
    breadcrumb,
    config,
    credentials,
    update,
});

export type ReduxStore = ReturnType<typeof appReducer> & PersistPartial;

const rootReducer = (state: ReduxStore, action: ReduxAction<any>) => {
    if (action.type === AuthTypes.LOGOUT) {
        return appReducer(
            {
                auth: AUTH_INITIAL_STATE,
                breadcrumb: BREADCRUMB_INITIAL_STATE,
                credentials: CREDENTIALS_INITIAL_STATE,
                config: state.config,
                update: state.update,
            },
            action
        );
    }

    return appReducer(state, action);
};

export default rootReducer;
