import * as React from 'react';
const SvgIcoDollarSign = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 16" width="1em" height="1em" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M5.755.75a.75.75 0 0 0-1.5 0v1.886h-.898a3.057 3.057 0 0 0 0 6.114h.898v3.114H1.05a.75.75 0 0 0 0 1.5h3.205v1.886a.75.75 0 0 0 1.5 0v-1.886h.897a3.057 3.057 0 1 0 0-6.114h-.897V4.136H8.3a.75.75 0 1 0 0-1.5H5.755V.75Zm-1.5 3.386h-.898a1.557 1.557 0 1 0 0 3.114h.898V4.136Zm1.5 4.614v3.114h.897a1.557 1.557 0 1 0 0-3.114h-.897Z"
            clipRule="evenodd"
        />
    </svg>
);
export default SvgIcoDollarSign;
