export type ReducerAction = {
    type: ContextActions;
    payload: any;
};

export type ContextActions = 'SET_STEP_COMPLETED' | 'SET_CURRENT_STEP' | 'SET_CONTENT' | 'SET_STEPS';

export type Step = {
    slug: Steps;
    label: string;
    hide?: boolean;
};

export type General = any;

export type Context = {
    general?: General;
    products?: any[];
    expense?: any;
};

export type ImportFlowState = {
    completeds: Steps[];
    currentStep: Steps;
    steps: Step[];
    content?: Context;
};

export enum Steps {
    GeneralInfo = 'GENERAL',
    Products = 'PRODUCTS',
    Expenses = 'EXPENSES',
}

export type ContextType = {
    state: ImportFlowState;
    setCurrentStep: (slug: Steps) => void;
    changeStep: (currentStepSlug: Steps, nextStepSlug?: Steps, previousStepSlug?: Steps) => void;
    completeStep: (step: Steps) => void;
    getStep: (slug: Steps) => void;
    setContent: (content: ImportFlowState['content']) => void;
    hideStep: (payload: { slug: Steps; hide: boolean }) => void;
    setSteps: (payload: Step[]) => void;
    goToStep: (action: 'previous' | 'next') => void;
};
