import Datatable from 'components/core/table/datatable';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import ExpenseTotalCard from './total-card';
import useGetOrderServicesExpensesTotal from 'services/queries/order-services/use-get-order-services-expenses-total';
import Spinner from 'components/core/spinner';
import Text from 'components/core/text';
import { serviceOrderExpensesListColumns } from './utils';

import { Expense } from 'types/models/expense';
import useGetPaginatedWithFilters from 'services/queries/general/use-get-paginated-with-filters';
import { Pagination } from 'types/pagination';

export type OrderServicesExpensesResponse = Pagination<{
    id: number;
    expense: Pick<Expense, 'id' | 'code' | 'value' | 'title'>;
    code: string;
    description: string;
    valueByOrderService: number;
    totalDeductions: number;
}>;

const ServiceOrderExpenses = () => {
    const { orderServiceId, saleOrderId } = useParams();

    const endpoint = `/sale-orders/${saleOrderId}/service-orders/${orderServiceId}/expenses`;

    const [page, setPage] = useState(1);

    const { data: expenses, isLoading: isLoadingList } = useGetPaginatedWithFilters<OrderServicesExpensesResponse>({
        endpoint,
        page,
    });
    const { data: totals, isLoading: isLoadingTotals } = useGetOrderServicesExpensesTotal(`${endpoint}?type=numbers`);

    const { items = [] } = expenses || {};

    return (
        <div>
            {isLoadingTotals && <Spinner fixed={true} />}
            <div className="flex items-center mb-6 flex-row justify-between">
                <Text as="h3" variant="h5" className="text-heading mr-1">
                    Despesas
                </Text>
            </div>
            <div className="flex flex-col md:grid grid-cols-3 gap-6 mb-6">
                <ExpenseTotalCard
                    variant="toBePaid"
                    title="Em aberto"
                    value={{
                        data: totals?.open,
                    }}
                />
                <ExpenseTotalCard
                    variant="paid"
                    title="Pagos"
                    value={{
                        data: totals?.paid,
                    }}
                />
                <ExpenseTotalCard
                    variant="total"
                    title="Total"
                    value={{
                        data: totals?.total,
                    }}
                />
            </div>
            <Datatable
                actions={[]}
                withBorder={false}
                data={items}
                options={{
                    sort: true,
                    filter: false,
                    search: false,
                    print: false,
                    download: false,
                    viewColumns: false,
                    serverSide: true,
                    searchAlwaysOpen: false,
                    selectableRows: 'none',
                    enableNestedDataAccess: '.',
                    pagination: true,
                    elevation: 21,
                    count: expenses?.total,
                    page,
                    onChangePage: (currentPage) => {
                        setPage(currentPage);
                    },
                }}
                columns={serviceOrderExpensesListColumns}
                loading={isLoadingList}
                className="toolbar-without-search"
            />
        </div>
    );
};

export default ServiceOrderExpenses;
