import { useQuery } from 'react-query';
import { Option } from 'types/general';
import apiGraphQl from 'services/graphql';
import { Pagination } from 'types/pagination';
import cities, { getAllCitiesOptionsKey } from './keys';

const useGetCitiesOptions = (stateId?: number) => {
    const request = () => apiGraphQl<Pagination<Option>>(cities.getAll, { stateId }).then((data) => data.items);

    return useQuery(getAllCitiesOptionsKey(stateId), request, { enabled: !!stateId });
};

export default useGetCitiesOptions;
