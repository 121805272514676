import { ElementType, useCallback, useEffect, useMemo, useState } from 'react';
import useCredentials from 'store/credentials/use-credentials';
import Forbidden from 'components/forbidden';
import { Page } from 'types/models/page';
import Spinner from 'components/core/spinner';
import { PageProps } from 'pages/shared/page';

export type WithPagePermissionsProps = {
    page?: Page;
};

const withPagePermissions = <T,>(WrappedComponent: ElementType) => {
    return ({ code, permissions, withModal = false, ...props }: PageProps<T>) => {
        const [page, setPage] = useState<Page>();
        const [isLoading, setIsLoading] = useState(true);

        const { credentials } = useCredentials();

        const getRules = useCallback(() => {
            try {
                const page = credentials.pages.find((item) => item.slug === code);

                setPage(page);
            } catch (error) {
                console.log('error', error);
            } finally {
                setIsLoading(false);
            }
        }, [credentials.pages, code]);

        const hasAccess = useMemo(() => {
            if (!page) {
                return false;
            }

            const canAccess = page.rules.some((item) => permissions.includes(item.key));

            return canAccess;
        }, [page, permissions]);

        useEffect(() => {
            getRules();
        }, [getRules]);

        if (isLoading) {
            return (
                <div className="relative w-full h-full">
                    <Spinner wrapperClasses="p-2 bg-base-100 rounded-full" />
                </div>
            );
        }

        if (!hasAccess) {
            return <Forbidden isOpenWithModal={withModal} title="Acesso negado" description="Você não possui permissão para acessar esta área." />;
        }

        return <WrappedComponent page={page} {...props} />;
    };
};

export default withPagePermissions;
