import ApprovalBadge from 'components/badges/approval';
import Badge from 'components/core/badge';
import { ApprovalStatus } from 'types/general';
import { Expense } from 'types/models/expense';

type ExpenseBadgesProps = {
    approvalStatus: ApprovalStatus;
} & Pick<Expense, 'billed' | 'payday' | 'edited' | 'provisioned'>;

const ExpenseBadges = ({ billed, payday, edited, provisioned, approvalStatus }: ExpenseBadgesProps) => {
    return (
        <div className="flex items-center gap-2">
            <ApprovalBadge status={approvalStatus} className="mr-0" />

            {billed && <Badge variant="warning">Faturada</Badge>}

            {Boolean(payday) && <Badge variant="success">Paga</Badge>}

            {edited && <Badge variant="info">Editada</Badge>}

            {provisioned && <Badge variant="success">Provisionada</Badge>}
        </div>
    );
};

export default ExpenseBadges;
