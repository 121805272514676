import Tab from '@mui/material/Tab/Tab';
import Tabs from '@mui/material/Tabs/Tabs';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { useCallback, useEffect } from 'react';
import { useSaleOrderContext } from './context';
import General, { INITIAL_VALUES } from './tabs/general';
import Products from './tabs/products';
import Summary from './tabs/summary';
import { Steps } from './context/types';
import useResponsive from 'hooks/responsive/use-responsive';
import SaleOrderFinancial from './tabs/financial';
import Expense from './tabs/expense';
import { SaleOrderOrigin } from 'types/models/sale-order';
import { useParams } from 'react-router-dom';
import useGetSaleOrder from 'services/queries/sales-order/use-get-sale-order';
import { optionsParser } from '../utils';
import dayjs from 'dayjs';

type CreateSaleOrderProps = {
    title: string;
};

const CreateOrUpdateSaleOrder = ({ title }: CreateSaleOrderProps) => {
    const { isMobile } = useResponsive();
    const { state, changeStep, setSteps, setContent } = useSaleOrderContext();
    const { saleOrderId } = useParams();

    const { data: saleOrder, isLoading: isLoadingSaleOrder } = useGetSaleOrder(saleOrderId);

    const handleChangeTab = useCallback((_: any, nextSlug: any) => changeStep(state.currentStep, nextSlug), [state.currentStep, changeStep]);

    const getComponent = (slug: Steps) => {
        const items = {
            [Steps.GeneralInfo]: General,
            [Steps.Products]: Products,
            [Steps.Financial]: SaleOrderFinancial,
            [Steps.Expense]: Expense,
            [Steps.Summary]: Summary,
        };

        const Component = items[slug];

        if (!Component) {
            return null;
        }

        return <Component isLoadingSaleOrder={isLoadingSaleOrder} />;
    };

    const setupSteps = useCallback(() => {
        const arr = state.steps;

        for (const step of arr) {
            if (state.content.origin === SaleOrderOrigin.sale) {
                if (step.slug === Steps.Financial) {
                    step.hide = false;
                }

                if (step.slug === Steps.Expense) {
                    step.hide = !state.content.withFreight || Boolean(saleOrderId);
                }
            } else {
                if (step.slug === Steps.Financial) {
                    step.hide = true;
                }

                if (step.slug === Steps.Expense) {
                    step.hide = true;
                }
            }
        }

        setSteps(arr);
        // eslint-disable-next-line
    }, [saleOrderId, state.steps, state.content.withFreight, state.content.origin]);

    useEffect(() => {
        setupSteps();
    }, [setupSteps]);

    useEffect(() => {
        if (!!saleOrder) {
            const { branch, classification, consultant, company, customer, contractDate, origin, type, commission, startContractDate, endContractDate, products = [], transactions = [] } = saleOrder;

            const defaultValues = {
                branch: optionsParser(branch?.id, branch?.name),
                classification: optionsParser(classification?.id, classification?.name),
                consultant: optionsParser(consultant?.id, consultant?.title),
                company: optionsParser(company?.id, company?.name),
                customer: optionsParser(customer?.id, customer?.title),
                contractDate,
                origin,
                type,
                commission: commission || INITIAL_VALUES.commission,
                products: products.map((item) => ({
                    name: item.product?.name,
                    code: item.product?.code,
                    quantity: item.quantity,
                    quantityInStock: item.stockBalance || 0,
                    productId: item.product?.id,
                    netValue: item.netValue || 0,
                    actualQuantity: item.quantity,
                    total: (item.netValue || 0) * (item.quantity || 0),
                    unitName: item.product?.unit?.name,
                    id: item.id,
                })),
                transactions: transactions.map((item) => ({
                    transactionId: item.id,
                    dueDate: item.dueDate,
                    paid: item.paid,
                    paymentForm: item.paymentForm?.id,
                    grossValue: item.grossValue || 0,
                })),
                ...(Boolean(startContractDate) && {
                    contractPeriodStartDate: dayjs(startContractDate).toISOString(),
                }),
                ...(Boolean(endContractDate) && {
                    contractPeriodEndDate: dayjs(endContractDate).toISOString(),
                }),
            };

            setContent(defaultValues);
        }

        // eslint-disable-next-line
    }, [saleOrder]);

    return (
        <Modal
            contentClassnames="min-w-[90%]"
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    {title}
                </Text>
            }
            closeOnClickOutside={false}>
            <Tabs classes={{ root: 'px-4' }} variant={isMobile ? 'scrollable' : 'standard'} value={state.currentStep} onChange={handleChangeTab}>
                {state.steps
                    .filter(({ hide = false }) => !hide)
                    .map((item, index) => {
                        const isDisabled = !state.completeds.find((step) => step === item.slug) && item.slug !== state.currentStep;

                        return <Tab value={item.slug} disabled={isDisabled} key={`${item.slug}_${index}`} label={item.label.toUpperCase()} />;
                    })}
            </Tabs>
            {getComponent(state.currentStep)}
        </Modal>
    );
};

export default CreateOrUpdateSaleOrder;
