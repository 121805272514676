import * as React from 'react';
const SvgIcoChart = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" width="1em" height="1em" {...props}>
        <path
            fill="currentColor"
            d="M2 12.73c0-.404.348-.73.778-.73h2.444c.43 0 .778.326.778.73V17H2v-4.27Zm6-4.935C8 7.356 8.348 7 8.778 7h2.444c.43 0 .778.356.778.795V17H8V7.795Zm6-3.978c0-.451.348-.817.778-.817h2.444c.43 0 .778.366.778.817V17h-4V3.817Z"
        />
    </svg>
);
export default SvgIcoChart;
