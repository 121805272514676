import Spinner from 'components/core/spinner';
import Empty from 'components/empty';
import InterMenu from 'components/intern-menu';
import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import { UseTabRouteChildren } from 'hooks/tab/use-tab-route';
import { useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import useGetServiceOrder from 'services/queries/order-services/use-get-service-order';

const ServiceOrderPage = ({ page }: WithPagePermissionsProps) => {
    const { orderServiceId, saleOrderId, purchaseOrderId } = useParams();

    const { data: serviceOrder, isLoading, isError } = useGetServiceOrder(saleOrderId, orderServiceId);

    const nav = useMemo(() => {
        return (
            page?.subpages.map<UseTabRouteChildren>((item) => ({
                label: item.title,
                path: item.link,
            })) || []
        );
    }, [page?.subpages]);

    // useBreadcrumb(serviceOrder?.code);

    if (isLoading) {
        return (
            <div className="relative w-full h-[calc(100%-95px)] p-6">
                <Spinner />
            </div>
        );
    }

    if (isError || !serviceOrder) {
        return <Empty title="Ordem de serviço não encontrada" className="py-6" />;
    }

    return (
        <div className="bg-base-100 flex flex-col relative -mx-4 overflow-hidden h-full lg:flex-row sm:-ml-6 sm:-mr-4 sm:-mb-6">
            {!Boolean(purchaseOrderId) && <InterMenu navItems={nav} />}
            <div className="flex-1 bg-base-200 relative w-full sm:h-full pb-[52px] sm:pb-0 px-6 pt-8 overflow-y-auto">
                <Outlet context={serviceOrder} />
            </div>
        </div>
    );
};

export default ServiceOrderPage;

// TODO:
// colocar o codigo da OS no breadcrumb
