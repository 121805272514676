import { RouteObject } from 'react-router-dom';
import ListPage from 'pages/shared/crud/list';
import CreateOrUpdateMenuGroupPage from 'pages/private/menu/create-or-update';
import { Rule } from 'types/permissions';
import Page from 'pages/shared/page';
import config from './utils';

const [list] = config;

const menu: RouteObject[] = [
    {
        path: 'menu',
        element: <Page Component={ListPage} code="menu" permissions={[Rule.List]} {...list} />,
        children: [
            {
                path: 'novo',
                element: <Page Component={CreateOrUpdateMenuGroupPage} withModal={true} code="menu" permissions={[Rule.Create]} />,
            },
            {
                path: 'editar/:menuGroupId',
                element: <Page Component={CreateOrUpdateMenuGroupPage} withModal={true} code="menu" permissions={[Rule.Update]} />,
            },
        ],
    },
];

export default menu;
