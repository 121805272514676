import { useQuery } from 'react-query';
import { getAllPurchaseOrderProductsApiKey } from './keys';
import api from 'services/api';
import { PurchaseOrderProduct } from 'types/models/purchase-order';
import { Pagination } from 'types/pagination';

const useGetAllPurchaseOrderProductsApi = (endpoint: string) => {
    const request = async () => {
        const { data } = await api.get<Pagination<PurchaseOrderProduct>>(endpoint);

        return data;
    };

    return useQuery(getAllPurchaseOrderProductsApiKey(endpoint), request);
};

export default useGetAllPurchaseOrderProductsApi;
