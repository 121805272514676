import Input from 'components/core/form/input';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import Dropzone from 'components/dropzone';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { OrderServiceWorkReportPayload } from 'types/models/order-service';

import { yupResolver } from '@hookform/resolvers/yup';
import { schemaOrderServiceWorkReport } from './utils';
import FormButtons from 'components/form-buttons';
import dayjs from 'dayjs';
import WorksReportFilesTable from './files-table';
import classNames from 'classnames';

type CreateOrUpdateOrderServiceWorkReportProps = {
    onSubmit: (data: OrderServiceWorkReportPayload) => void;
    isSubmitting: boolean;
};

const CreateOrUpdateOrderServiceWorkReport = ({ onSubmit, isSubmitting }: CreateOrUpdateOrderServiceWorkReportProps) => {
    const { control, formState, handleSubmit } = useForm<OrderServiceWorkReportPayload>({
        mode: 'onSubmit',
        resolver: yupResolver(schemaOrderServiceWorkReport) as any,
        defaultValues: {
            description: '',
            files: [],
            title: dayjs().format('DD/MM/YYYY HH:mm'),
        },
    });

    const handleChangeFile = (filesList: File[]) => {
        try {
            for (const localFile of filesList) {
                const fileName = localFile?.name?.split('.')?.[0];

                append({
                    name: fileName,
                    localFile,
                });
            }
        } catch (error) {
            console.log('handleChangeFile', error);
        }
    };

    const { append, remove, fields: files } = useFieldArray({ name: 'files', control });

    return (
        <Modal closeOnClickOutside={false} contentClassnames="w-[800px]">
            <form className="py-5 px-7" onSubmit={handleSubmit(onSubmit)}>
                <Text as="h3" variant="h4" className="text-heading mb-5">
                    Criar novo relatório de obra
                </Text>
                <div className="grid grid-cols-2 gap-4">
                    <Controller name="description" control={control} render={({ field }) => <Input {...field} label="Descrição" error={formState?.errors?.description?.message} />} />
                    <Controller name="title" control={control} render={({ field }) => <Input disabled={true} label="Título" error={formState.errors?.title?.message} {...field} />} />
                    <Controller
                        name="files"
                        control={control}
                        render={({ field }) => (
                            <Dropzone
                                {...field}
                                containerClasses={classNames('col-span-2', !files.length && 'mb-6')}
                                onUploadFiles={handleChangeFile}
                                error={formState?.errors?.files?.message}
                                accept={{ 'image/*': ['.jpeg', '.png', '.jpg'] }}
                            />
                        )}
                    />
                    {Boolean(files.length) && <WorksReportFilesTable files={files} onRemoveFile={remove} />}
                </div>
                <FormButtons isLoading={isSubmitting} />
            </form>
        </Modal>
    );
};

export default CreateOrUpdateOrderServiceWorkReport;
