import PageHeader from 'components/page/header';
import useGetQueryParam from 'hooks/router/use-get-query-param';
import qs from 'qs';
import { useEffect, useMemo, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatDate } from 'utils/date';
import dayjs from 'dayjs';
import OriginExpenseReportActions from 'pages/private/reports/screens/origin-expenses/header/actions';
import Select from 'components/core/form/select';
import { scheduleFilterSchema, scheduleStatusOptionsWithoutRescheduled } from '../utils';
import { ScheduleFilterPayload } from 'types/models/schedule';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import FilterPeriod from 'pages/private/payment-order/filter/period';

type SchedulePageProps = {
    title: string;
};

const ScheduleHeader = ({ title }: SchedulePageProps) => {
    const { search } = useLocation();

    const queryFiltersParsed = useMemo(() => qs.parse(search, { ignoreQueryPrefix: true }) as any, [search]);

    const startDate = useGetQueryParam('filter[startDate]');
    const endDate = useGetQueryParam('filter[endDate]');

    const { handleSubmit, control, formState, reset } = useForm<ScheduleFilterPayload>({
        mode: 'onSubmit',
        resolver: yupResolver(scheduleFilterSchema) as any,
    });

    const formRef = useRef(null);
    const navigate = useNavigate();

    const submit = (data: ScheduleFilterPayload) => {
        const filters = qs.stringify(
            {
                filter: {
                    startDate: formatDate(data.startDate, 'YYYY-MM-DD'),
                    endDate: formatDate(data.endDate, 'YYYY-MM-DD'),
                    status: data.status,
                },
            },
            {
                addQueryPrefix: true,
                encode: false,
                arrayFormat: 'brackets',
            }
        );

        navigate(filters);
    };

    useEffect(() => {
        reset({
            ...(Boolean(startDate) && { startDate: dayjs(startDate).toISOString() }),
            ...(Boolean(endDate) && { endDate: dayjs(endDate).toISOString() }),
            status: queryFiltersParsed.filter?.status,
        });
    }, [startDate, endDate, reset, queryFiltersParsed.filter?.status]);

    const formClasses = classNames('flex flex-wrap gap-2', Boolean(formState.errors?.endDate?.message) || Boolean(formState.errors.startDate?.message) ? 'items-start' : 'items-end');

    return (
        <div className="mb-7 flex flex-col xl:flex-row xl:items-center">
            <PageHeader title={title} className="flex-1 mb-5 xl:mb-0" />
            <form ref={formRef} onSubmit={handleSubmit(submit)} className={formClasses}>
                <FilterPeriod
                    label="Período do agendamento"
                    control={control}
                    firstInput={{
                        inputProps: {
                            label: 'Início',
                            name: 'startDate',
                            error: formState.errors.startDate?.message,
                        },
                    }}
                    secondInput={{
                        inputProps: {
                            label: 'Fim',
                            name: 'endDate',
                            error: formState.errors.endDate?.message,
                        },
                    }}
                />
                <div className="flex items-end flex-wrap gap-2 w-full sm:w-auto">
                    <Controller
                        control={control}
                        name="status"
                        render={({ field }) => {
                            const fieldValuesNumber = field.value?.map((item) => Number(item));
                            const value = scheduleStatusOptionsWithoutRescheduled?.filter((item) => fieldValuesNumber?.includes(Number(item.value)));
                            return (
                                <Select
                                    {...field}
                                    isMulti={true}
                                    parentClassName="min-w-full w-full sm:w-auto sm:min-w-[350px]"
                                    label="Status"
                                    options={scheduleStatusOptionsWithoutRescheduled}
                                    value={value}
                                    onChange={(value: any) => {
                                        const ids = value.map((item) => item.value);
                                        field.onChange(ids);
                                    }}
                                    error={formState.errors?.status?.message}
                                />
                            );
                        }}
                    />
                    <OriginExpenseReportActions formRef={formRef} />
                </div>
            </form>
        </div>
    );
};

export default ScheduleHeader;
ScheduleHeader;
