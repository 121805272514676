import { useParams } from 'react-router-dom';
import { useState } from 'react';
import Modal from 'components/core/modal';
import useTheme from '@mui/material/styles/useTheme';
import Badge from 'components/core/badge';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import IconButton from '@mui/material/IconButton/IconButton';
import Spinner from 'components/core/spinner';
import Empty from 'components/empty';
import Text from 'components/core/text';
import { formatDate } from 'utils/date';
import useGetTask from 'services/queries/tasks/use-get-task';
import TaskInteractions from './interactions';
import { taskTopColors } from 'pages/private/sales-orders/utils';
import SvgIcoLink from 'components/core/icon/files/ico-link';

const TaskPage = () => {
    const { taskId } = useParams();
    const { palette } = useTheme();

    const [urlCopied, setUrlCopied] = useState(false);

    const { data: task, error, isLoading } = useGetTask(taskId);

    const handleCopy = async () => {
        try {
            const url = window.location.href;

            await navigator?.clipboard?.writeText(url);
            setUrlCopied(true);

            setTimeout(() => {
                setUrlCopied(false);
            }, 5000);
        } catch (error) {
            console.log('handleCopy', error);
        }
    };

    const { variant: taskVariant = 'grey' } = taskTopColors.get(task?.progressStatus.slug!) || {};

    return (
        <Modal
            closeOnClickOutside={false}
            contentClassnames="w-[780px]"
            headerLeft={
                !isLoading && !error ? (
                    <div className="flex flex-wrap gap-2 items-center">
                        <Badge className="mr-2" variant={taskVariant}>
                            {task?.progressStatus?.title}
                        </Badge>
                    </div>
                ) : undefined
            }
            headerRight={
                !isLoading && !error ? (
                    <Tooltip placement="top" title={urlCopied ? 'Copiado' : 'Copiar link'} disableFocusListener={true}>
                        <IconButton color="secondary" sx={{ '&:hover': { backgroundColor: palette.grey[200] } }} onClick={handleCopy}>
                            <SvgIcoLink color={palette.grey[700]} width={16} height={16} />
                        </IconButton>
                    </Tooltip>
                ) : undefined
            }>
            {isLoading ? (
                <div className="p-4">
                    <Spinner color={palette.secondary[100]} fixed={false} size={20} />
                </div>
            ) : !!error ? (
                <Empty title="Tarefa não encontrada" className="p-5" />
            ) : (
                <>
                    <div className="px-6 pb-4">
                        {(task?.title || task?.code) && (
                            <div className="mb-6">
                                {task?.title && (
                                    <div className="flex flex-col sm:flex-row gap-2 sm:items-center">
                                        <Text as="h5" variant="h5" className="text-heading flex-1 !font-bold">
                                            {task?.title}
                                        </Text>
                                        {task?.code && (
                                            <Text as="span" variant="body.regular.xs" className="font-normal text-base-500 italic sm:ml-2">
                                                #{task?.code}
                                            </Text>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                        <div className="mb-6">
                            <Text as="span" variant="body.medium.sm" className="text-base-500 uppercase block mb-2">
                                Descrição
                            </Text>
                            {!!task?.description ? <div className="rich-content" dangerouslySetInnerHTML={{ __html: task.description }} /> : <Empty title="Tarefa não possui descrição" />}
                        </div>
                        <div className="flex items-start justify-between gap-10 mb-10">
                            <div className="flex flex-wrap items-start gap-4">
                                <div>
                                    <Text as="span" variant="body.medium.sm" className="text-base-500 uppercase block mb-1">
                                        Início:
                                    </Text>
                                    <Badge className="capitalize" variant="grey">
                                        {formatDate(task?.startDate, 'ddd[.] DD.MM.YYYY')}
                                    </Badge>
                                </div>
                                <div>
                                    <Text as="span" variant="body.medium.sm" className="text-base-500 uppercase block mb-1">
                                        Fim:
                                    </Text>
                                    <Badge className="capitalize" variant="grey">
                                        {formatDate(task?.endDate, 'ddd[.] DD.MM.YYYY')}
                                    </Badge>
                                </div>
                            </div>
                        </div>
                    </div>
                    <TaskInteractions comments={task?.comments || []} files={task?.files || []} />
                </>
            )}
        </Modal>
    );
};

export default TaskPage;
