import { useQuery } from 'react-query';
import api from 'services/api';
import useToast from 'hooks/toast/use-toast';
import { Option } from 'types/general';
import { getOrderServicesPurchaseScopeProductsKey } from './keys';

const useGetOrderServicesPurchaseScopeProducts = (endpoint: string) => {
    const { showToast } = useToast();

    const request = async (): Promise<Option<number>[]> => {
        const { data } = await api.get<Response>(endpoint);

        return data?.map(({ id, name }) => ({ value: id, label: name }));
    };

    return useQuery(getOrderServicesPurchaseScopeProductsKey(endpoint), request, {
        enabled: !!endpoint,
        onError: () => {
            showToast(`Ocorreu um erro ao obter a listagem. Tente novamente.`, 'danger');
        },
    });
};

type Response = Array<{
    id: number;
    name: string;
}>;

export default useGetOrderServicesPurchaseScopeProducts;
