import { useMutation } from 'react-query';
import api from 'services/api';

type Payload = {
    file: number;
};

const useTransactionSendPendingOrderPayment = (outcomeId: number) => {
    const request = async (payload: Payload) => api.patch(`/transactions/outcomes/pending/${outcomeId}`, payload);

    return useMutation(request);
};

export default useTransactionSendPendingOrderPayment;
