import TableCell from '@mui/material/TableCell/TableCell';
import ExternalButton from 'components/buttons/external';
import { DatatableColumn } from 'types/graphql';
import { formatDate } from 'utils/date';
import { currencyToFloat, formatMoney } from 'utils/money';
import { natures } from 'utils/statics';

export const saleOrderExpensesListColumns: DatatableColumn[] = [
    {
        name: 'id',
        options: { display: 'excluded' },
    },
    {
        name: 'code',
        label: 'Código',
        options: {
            customBodyRender: (code, { rowData }) => (!!code ? <ExternalButton to={`/app/despesas/${rowData[0]}`}>{code}</ExternalButton> : '-'),
        },
    },

    {
        name: 'branch.name',
        label: 'Filial',
        options: {
            customBodyRender: (branch = '-') => <span className="whitespace-nowrap">{branch}</span>,
        },
    },

    {
        name: 'nature',
        label: 'Natureza',
        options: {
            customBodyRender: (value) => {
                const nature = natures.find((item) => item.value === value);

                return nature?.label || '-';
            },
        },
    },
    {
        name: 'dueDate',
        label: 'Vencimento',
        options: {
            customBodyRender: (value) => formatDate(value, 'DD/MM/YYYY'),
        },
    },
    {
        name: 'payday',
        label: 'Pago em',
        options: {
            customBodyRender: (value) => formatDate(value, 'DD/MM/YYYY'),
        },
    },
    {
        name: 'value',
        label: 'Total',
        options: {
            customBodyRender: (value = '0') => {
                return formatMoney(currencyToFloat(value?.toString() || '0'));
            },
        },
    },

    {
        name: 'actions',
        label: 'Ações',
        options: {
            customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />,
        },
    },
];
