import camelCase from 'lodash/camelCase';
import { Option } from 'types/general';
import dictionary from './dictionary';

export const convertEnumToOptions = <T = number>(enumValue: any, dictionaryKey: string): Option<T>[] => {
    const arr: Option<T>[] = [];

    Object.keys(enumValue as any)
        .filter((key) => isNaN(+key))
        .forEach((key) => {
            arr.push({
                value: enumValue[key],
                label: dictionary.content[dictionaryKey][camelCase(key)]
            });
        });

    return arr;
};
