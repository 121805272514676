import { useQuery } from 'react-query';
import { getOrderServiceStatusesKey } from './keys';
import apiGraphQl from 'services/graphql';
import { Option } from 'types/general';
import { Pagination } from 'types/pagination';

const useGetServiceOrderStatuses = (query: string, enabled = true) => {
    const request = async () => apiGraphQl<Pagination<Option>>(query).then(({ items }) => items);

    return useQuery(getOrderServiceStatusesKey, request, {
        enabled,
    });
};

export default useGetServiceOrderStatuses;
