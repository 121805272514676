import { RouteObject } from 'react-router-dom';
import ReceiptOrderPage from 'pages/private/receipt-order';
import PaymentOrderPage from 'pages/private/payment-order/view';
import Page from 'pages/shared/page';
import { Rule } from 'types/permissions';
import TransactionUpdatePage from 'pages/private/expenses/details/tabs/transactions/update';
import CreateOrUpdateTransactionDocumentPage from 'pages/private/payment-order/view/documents/create-or-update';

const receiptOrder: RouteObject[] = [
    {
        // @ts-ignore
        breadcrumb: 'Ordens de Recebimento',
        path: 'ordens-de-recebimento',
        element: <Page Component={ReceiptOrderPage} permissions={[Rule.List]} code="ordens-de-recebimento" />,
        children: [
            {
                path: ':receiptOrderId',
                element: <Page Component={PaymentOrderPage} permissions={[Rule.Show]} withModal={true} code="ordens-de-recebimento" />,
                children: [
                    {
                        path: 'editar',
                        element: <Page Component={TransactionUpdatePage} code="ordens-de-recebimento" permissions={[Rule.Update]} withModal={true} />,
                    },
                    {
                        path: 'documentos/novo',
                        element: <Page Component={CreateOrUpdateTransactionDocumentPage} code="ordens-de-recebimento-documentos" permissions={[Rule.Create]} withModal={true} />,
                    },
                    {
                        path: 'documentos/:documentId/editar',
                        element: <Page Component={CreateOrUpdateTransactionDocumentPage} code="ordens-de-recebimento-documentos" permissions={[Rule.Update]} withModal={true} />,
                    },
                ],
            },
        ],
    },
];

export default receiptOrder;
