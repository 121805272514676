import { useQuery } from 'react-query';
import api from 'services/api';
import { getConsultantSaleOrdersKey } from './keys';
import { ConsultantSaleOrderType, SaleOrder, SaleOrderType } from 'types/models/sale-order';

type ConsultantSaleOrdersResponse = Pick<SaleOrder, 'id' | 'netValue' | 'code'> & {
    type: ConsultantSaleOrderType;
    commission: number;
};

const useGetConsultantSaleOrders = (consultantId?: number, saleOrderType?: SaleOrderType) => {
    const request = async () => {
        const { data } = await api.get<Partial<ConsultantSaleOrdersResponse>[]>(`/consultants/${consultantId}/sale-orders/${saleOrderType}`);

        return data;
    };

    return useQuery(getConsultantSaleOrdersKey(consultantId, saleOrderType), request, {
        enabled: Boolean(consultantId) && Boolean(saleOrderType),
    });
};

export default useGetConsultantSaleOrders;
