import { useMemoizedEffect } from 'hooks/router/use-memoized-effect';
import { useMainContext } from 'pages/private/main/context';
import { useState } from 'react';

export const useReportsScreen = () => {
    const { handleToggleSidebar } = useMainContext();
    useMemoizedEffect(() => handleToggleSidebar(true));

    const [fontSize, setFontSize] = useState(11); // px
    const [year, setYear] = useState(new Date().getFullYear());

    return {
        fontSize,
        setFontSize,
        year,
        setYear,
    };
};
