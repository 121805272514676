import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import Text from 'components/core/text';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import CurrencyInput from 'components/core/form/currency';
import DatePicker from 'components/core/datepicker';
import Select from 'components/core/form/select';
import Switch from 'components/core/switch';
import { formatMoney, generateInstallments } from 'utils/money';
import useGetClassifications from 'services/queries/classifications/use-get-classifications';
import { generateClassificationQuery } from 'services/queries/classifications/utils';
import { ClassificationType } from 'types/models/classification';
import { memo, useCallback, useEffect } from 'react';
import { PurchaseOrderExpensePayload } from 'types/models/purchase-order';
import Input from 'components/core/form/input';
import get from 'lodash/get';
import { generateDueAndProvisioningDates, getProvisioningOptions } from 'pages/private/expenses/crud/utils';
import { currentDateWithoutTimezone } from 'utils/date';
import dayjs from 'dayjs';

export type ExpenseInstallments = {
    dueDate: string;
    provisioning: string;
    provisioned: boolean;
    value: number;
    paymentForm: number;
};

const provisionDates = getProvisioningOptions({ date: currentDateWithoutTimezone });

const Installments = () => {
    const { formState, control, watch, reset } = useFormContext<PurchaseOrderExpensePayload>();

    const products = watch('products', []);
    const installments = Number(watch('instalments', 0));

    const { data: paymentForms = [] } = useGetClassifications(generateClassificationQuery(ClassificationType.PaymentForm));

    const { fields: transactions, replace } = useFieldArray({ control, name: 'transactions' });

    const expenseValue = products.reduce((acc, curr) => (curr.value || 0) * (curr.quantity || 0) + acc, 0);
    const transactionsTotal = watch('transactions', []).reduce((acc, curr) => acc + curr.value, 0);
    const remainingValue = expenseValue - transactionsTotal;

    const getError = useCallback((name: string) => get(formState.errors, `${name}.message`) as any, [formState]);

    useEffect(() => {
        if (installments > 1) {
            const arr = Array.from(Array(installments), (_, index) => {
                const dueDateForm = dayjs(watch('dueDate')).add(index, 'month').toISOString();
                const { dueDate, firstPaymentForecast, restPaymentForecast } = generateDueAndProvisioningDates(dueDateForm, index);

                return {
                    dueDate,
                    provisioning: !index ? firstPaymentForecast : restPaymentForecast,
                    provisioned: false,
                    value: generateInstallments(expenseValue, installments)[index],
                    paymentForm: paymentForms[0]?.value,
                };
            });

            replace(arr);
        } else {
            replace([]);
        }
    }, [installments, expenseValue, paymentForms, reset, watch, replace]);

    return (
        <div className="px-6 mb-6">
            <div className="border border-base-300 p-4 rounded-[14px] mb-6">
                <Text as="h6" variant="h5" className="text-heading mb-4">
                    Parcelas
                </Text>
                <Controller
                    name="instalments"
                    control={control}
                    render={({ field }) => (
                        <Input
                            {...field}
                            autoComplete="nope"
                            type="number"
                            min="1"
                            placeholder="0"
                            label="Parcelas"
                            step={1}
                            inputWrapperClasses="col-span-1"
                            error={getError('installments')}
                            parentClassName="!w-[200px]"
                        />
                    )}
                />
                {transactions.length > 1 && (
                    <div className="mt-4">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Text className="text-base-500 uppercase">Referente a parcela</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text className="text-base-500 uppercase">Vencimento</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text className="text-base-500 uppercase">Provisionamento</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text className="text-base-500 uppercase">Provisionada</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text className="text-base-500 uppercase">Valor da Parcela</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text className="text-base-500 uppercase">Forma de Pagamento</Text>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {transactions.map((item, index) => {
                                    const getError = (name: string) => formState?.errors?.transactions?.[index]?.[name]?.message;

                                    return (
                                        <TableRow key={item.id}>
                                            <TableCell>
                                                <span className="text-heading text-base font-medium">Parcela {index + 1}</span>
                                            </TableCell>
                                            <TableCell>
                                                <Controller
                                                    name={`transactions.${index}.dueDate`}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <DatePicker
                                                            inputProps={{
                                                                placeholderText: 'Vencimento',
                                                                error: getError('dueDate'),
                                                                ...field,
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Controller
                                                    name={`transactions.${index}.provisioning`}
                                                    control={control}
                                                    render={({ field }) => {
                                                        const value = provisionDates.find((item) => item.value.includes(field.value || ''));

                                                        return (
                                                            <Select
                                                                {...field}
                                                                value={value}
                                                                options={provisionDates}
                                                                placeholder="Provisionamento"
                                                                error={getError('provisioning')}
                                                                onChange={(option: any) => field.onChange(option.value)}
                                                            />
                                                        );
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Controller
                                                    name={`transactions.${index}.provisioned`}
                                                    control={control}
                                                    render={({ field }) => <Switch value={field.value} onChange={(value) => field.onChange(value)} defaultLabel="Não" activeLabel="Sim" />}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Controller
                                                    name={`transactions.${index}.value`}
                                                    control={control}
                                                    render={({ field }) => {
                                                        return (
                                                            <CurrencyInput
                                                                left={
                                                                    <Text as="span" variant="body.medium.sm" className="text-heading">
                                                                        R$
                                                                    </Text>
                                                                }
                                                                value={field.value}
                                                                onValueChange={({ floatValue = 0 }) => field.onChange(floatValue)}
                                                                placeholder="Valor"
                                                                parentClassName="w-[150px]"
                                                                error={getError('value')}
                                                            />
                                                        );
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Controller
                                                    name={`transactions.${index}.paymentForm`}
                                                    control={control}
                                                    render={({ field }) => {
                                                        const value = paymentForms.find((item) => item.value === field.value);

                                                        return (
                                                            <Select
                                                                {...field}
                                                                value={value}
                                                                options={paymentForms}
                                                                placeholder="Forma de Pagamento"
                                                                error={getError('paymentForm')}
                                                                onChange={(option: any) => field.onChange(option.value)}
                                                            />
                                                        );
                                                    }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        <div className="bg-secondary-100 py-4 px-6 bg-opacity-20 text-right mt-4 rounded-[14px]">
                            <div>
                                <Text className="text-base-700">Total das parcelas: </Text>
                                <Text variant="body.medium.2xs" className="text-heading">
                                    {formatMoney(transactionsTotal)}
                                </Text>
                            </div>
                            {!!remainingValue && (
                                <div>
                                    <Text className="text-base-700">Faltam: </Text>
                                    <Text variant="body.medium.2xs" className="text-heading">
                                        {formatMoney(remainingValue)}
                                    </Text>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default memo(Installments);
