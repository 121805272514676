import { EnumType, jsonToGraphQLQuery, VariableType } from 'json-to-graphql-query';
import { array, boolean, number, object, string } from 'yup';
import dictionary from 'utils/dictionary';
import { pkColumnsKey } from 'utils/environment';

export const getPageQuery = (pageId?: string) => {
    return jsonToGraphQLQuery({
        query: {
            __name: 'GetPage',
            pageByPk: {
                __args: { id: Number(pageId || 0) },
                keyPage: true,
                name: true,
                slug: true,
                permalink: true,
                system: true,
                title: true,
                controller: true,
                subpages: {
                    __args: {
                        orderBy: { orderMenu: new EnumType('ASC') },
                    },
                    id: true,
                    pageId: true,
                    orderMenu: true,
                    page: {
                        __aliasFor: 'pageByPageId',
                        name: true,
                        title: true,
                    },
                },
                pageRules: {
                    id: { __aliasFor: 'ruleId' },
                },
            },
        },
    });
};

export const createOrUpdatePageMutation = (pageId?: string) => {
    return jsonToGraphQLQuery(
        {
            mutation: {
                __name: 'CreateOrUpdatePage',
                __variables: {
                    ...(pageId
                        ? {
                              payload: 'PageSetInput',
                              id: 'Int!',
                          }
                        : {
                              payload: 'PageInsertInput!',
                          }),
                },
                item: {
                    __aliasFor: pageId ? 'updatePageByPk' : 'insertPageOne',
                    __args: {
                        ...(pageId
                            ? {
                                  [pkColumnsKey]: { id: new VariableType('id') },
                                  _set: new VariableType('payload'),
                              }
                            : {
                                  object: new VariableType('payload'),
                              }),
                    },
                    id: true,
                },
            },
        },
        { pretty: true }
    );
};

export const pageSchema = object({
    keyPage: string().required(dictionary.validation.required),
    name: string().required(dictionary.validation.required),
    slug: string().required(dictionary.validation.required),
    permalink: string(),
    system: boolean().required(dictionary.validation.required).default(false),
    title: string().required(dictionary.validation.required),
    controller: string().required(dictionary.validation.required),
    hasSubpages: boolean(),
    subpages: array(
        object({
            label: string(),
            value: number(),
            orderMenu: number(),
        })
    ),
    pageRules: array(number()),
});
