import { BankAccount } from './bank';
import { BaseModel } from './base';
import { PaymentFlow } from './payment-flow';
import { Person } from './person';
import { Transaction } from './transaction';

export type CompanyPayload = {
    name: string;
    taxRegime?: number;
    companyPartners: Array<{
        person: { name: string };
    }>;
};

export type Company = {
    name: string;
    companyPartners: CompanyPartners[];
} & BaseModel;

export type CompanyPartners = {
    companyId: number;
    personId: number;
    company: Company;
    person: Person;
} & BaseModel;

export enum CompanyTaxRegime {
    Simple = 1,
    PresumedProfit,
    RealProfit,
}

export type CompanyBankAccount = {
    id: number;
    companyId: number;
    openingBalance: number;
    paymentFlows: Partial<PaymentFlow>[];
    transactions: Partial<Transaction>[];
    bankAccount: Partial<BankAccount>;
    company: Partial<Company>;
};
