import useTheme from '@mui/material/styles/useTheme';
import Spinner from 'components/core/spinner';
import Datatable from 'components/core/table/datatable';
import Text from 'components/core/text';
import CardsDetails from 'pages/private/sales-notes/components/cards';
import { Outlet, useParams } from 'react-router-dom';
import useGetBankAccountById from 'services/queries/bank-accounts/use-get-bank-account-by-id';
import { cardsDetailsData, columnDatatableTransactions } from '../utils';
import useBreadcrumb from 'store/breadcrumb/use-breadcrumb';
import useGetBankAccountTransactions from 'services/queries/bank-accounts/use-get-bank-accounts-transactions';
import BankAccountActions from './actions';
import PageHeader from 'components/page/header';
import BankAccountTransactionsFilters from 'components/advanced-search/bank-account-transactions';
import { useMemo, useState } from 'react';
import { MUIDataTableOptions } from 'mui-datatables';
import useGetQueryParam from 'hooks/router/use-get-query-param';
import qs from 'qs';
import dayjs from 'dayjs';
import { Rule } from 'types/permissions';
import useConfig from 'store/config/use-config';
import { Status } from 'types/general';

const BankAccountsDetails = () => {
    const { palette } = useTheme();
    const { config } = useConfig();
    const { bankAccountId } = useParams();

    const [page, setPage] = useState(1);
    const [searchText, setSearchText] = useState<string | null>('');

    const type = useGetQueryParam('filter[type]');
    const endPayday = useGetQueryParam('filter[endPayday]');
    const startPayday = useGetQueryParam('filter[startPayday]');

    const queryFilters = qs.stringify(
        {
            filter: {
                startPayday: !startPayday ? dayjs().utc(true).date(1).format('YYYY-MM-DD') : startPayday,
                endPayday: !endPayday ? dayjs().utc(true).endOf('month').format('YYYY-MM-DD') : endPayday,
                searchText,
                ...(Boolean(type) && { type }),
            },
            page,
            totalPerPage: config.tables.rowsPerPage,
        },
        { addQueryPrefix: true, encode: false, arrayFormat: 'brackets', skipNulls: true }
    );

    const { data: bankAccountDetails, isLoading: isLoadingBankAccountDetails } = useGetBankAccountById(Number(bankAccountId));
    const { data: bankAccountTransactions, isLoading: isLoadingBankAccountTransactions } = useGetBankAccountTransactions(Number(bankAccountId), queryFilters);

    const cardsDetailsDataMemoized = useMemo(() => cardsDetailsData(bankAccountDetails), [bankAccountDetails]);

    const optionsDatatable: MUIDataTableOptions = {
        enableNestedDataAccess: `.`,
        download: false,
        filter: false,
        filterArrayFullMatch: false,
        print: false,
        viewColumns: false,
        selectableRowsHideCheckboxes: true,
        searchAlwaysOpen: true,
        searchOpen: true,
        search: true,
        serverSide: true,
        searchPlaceholder: 'Buscar transação',
        count: bankAccountTransactions?.total,
        page,
        pagination: true,
        onChangePage: setPage,
        onSearchChange: setSearchText,
    };

    useBreadcrumb(bankAccountDetails?.bank?.name);

    return (
        <>
            {isLoadingBankAccountDetails ? (
                <div className="p-4">
                    <Spinner color={palette.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <div>
                    <div className="flex justify-between items-center">
                        <PageHeader title={`Conta Bancária - ${bankAccountDetails?.name}`} />
                        <BankAccountActions isActive={bankAccountDetails?.status === Status.Active} />
                    </div>
                    <div className="mt-3">
                        <Text as="p" className="text-md  text-gray-500">
                            <Text as="span" className="font-semibold text-primary-500">
                                Empresa:
                            </Text>{' '}
                            {bankAccountDetails?.company.name}
                        </Text>
                        <Text as="p" className="text-md  text-gray-500">
                            <Text as="span" className="font-semibold text-primary-500">
                                Banco:
                            </Text>{' '}
                            {bankAccountDetails?.bank?.name}
                        </Text>
                        <Text as="p" className="text-md mt text-gray-500">
                            <Text as="span" className="font-semibold text-primary-500">
                                Dados bancários:
                            </Text>{' '}
                            Agência: {bankAccountDetails?.agency}
                            {bankAccountDetails?.agencyDigit ? `-${bankAccountDetails?.agencyDigit}` : ''} / Conta-corrente: {bankAccountDetails?.account}
                            {bankAccountDetails?.accountDigit ? `-${bankAccountDetails?.accountDigit}` : ''}
                        </Text>
                    </div>
                    <div className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 xl:grid-cols-5 gap-5 my-8">
                        {cardsDetailsDataMemoized.map((item, index) => (
                            <CardsDetails variant="list" key={index} {...item} />
                        ))}
                    </div>
                    <Datatable
                        actions={[Rule.Show]}
                        loading={isLoadingBankAccountTransactions}
                        options={optionsDatatable}
                        className="shadow-none"
                        columns={columnDatatableTransactions}
                        data={bankAccountTransactions?.items || []}
                        advancedSearchComponent={<BankAccountTransactionsFilters onSetPage={setPage} />}
                        hasSelectedFilters={true}
                    />
                </div>
            )}
            <Outlet context={{ actualDatatablePage: page, queryFilters, companyId: bankAccountDetails?.company.id }} />
        </>
    );
};

export default BankAccountsDetails;
