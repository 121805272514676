import TableCell from '@mui/material/TableCell/TableCell';
import { VariableType, jsonToGraphQLQuery } from 'json-to-graphql-query';
import theme from 'settings/theme';
import { CrudPageProps } from 'types/graphql';
import { pkColumnsKey } from 'utils/environment';

type CustomerProviderAddressesListConfigParams = {
    customerId?: string;
    providerId?: string;
    onGoTo: (path: string) => void;
    onDelete: (id: number) => void;
};

export const customerProviderAddressesListConfig = ({ customerId, providerId, onGoTo, onDelete }: CustomerProviderAddressesListConfigParams): CrudPageProps<{}> => {
    return {
        graphql: {
            table: !!customerId ? 'addressCustomer' : 'addressProvider',
            searchableField: 'address.zip',
            statusPath: 'address.status',
            columns: [
                {
                    name: 'id',
                    options: { display: 'excluded' },
                },
                {
                    name: 'address.id',
                    options: { display: 'excluded' },
                },
                {
                    name: 'address.street',
                    label: 'Logradouro',
                    options: { customBodyRender: (value = '-') => <span className="whitespace-nowrap">{value}</span> },
                },
                {
                    name: 'address.number',
                    label: 'Número',
                    options: { customBodyRender: (value = '-') => value },
                },
                {
                    name: 'address.compliment',
                    label: 'Complemento',
                    options: { customBodyRender: (value = '-') => value },
                },
                {
                    name: 'address.neighbourhood',
                    label: 'Bairro',
                    options: { customBodyRender: (value = '-') => value },
                },
                {
                    name: 'address.city.name',
                    label: 'Cidade',
                    options: { customBodyRender: (value = '-') => value },
                },
                {
                    name: 'address.state.name',
                    label: 'Estado',
                    options: { customBodyRender: (value = '-') => value },
                },
                {
                    name: 'address.zip',
                    label: 'CEP',
                    options: { customBodyRender: (value = '-') => value },
                },
                {
                    name: 'actions',
                    label: 'Ações',
                    options: {
                        customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />,
                    },
                },
            ],
            where: {
                ...(Boolean(customerId) && {
                    customerId: { _eq: customerId },
                }),
                ...(Boolean(providerId) && {
                    providerId: { _eq: providerId },
                }),
            },
        },
        title: 'Endereços',
        withBorder: true,
        hideCreateButton: (items) => !!items.length,
        customActions: (item) => [
            {
                label: 'Editar',
                icon: { name: 'ico-edit', color: theme.extend.colors.base[500] },
                onClick: onGoTo?.(`editar/${item?.id}`),
            },
        ],
        customFooterActions: (item) => [
            {
                label: 'Apagar',
                className: 'text-system-danger-500',
                icon: { name: 'ico-trash', color: theme.extend.colors.system.danger[500] },
                onClick: () => onDelete(item.address?.id),
            },
        ],
    };
};

export const createOrUpdateAddressMutation = (addressId?: number) => {
    return jsonToGraphQLQuery(
        {
            mutation: {
                __name: 'CreateOrUpdateAddress',
                __variables: {
                    ...(addressId
                        ? {
                              payload: 'AddressSetInput',
                              id: 'Int!',
                          }
                        : {
                              payload: 'AddressInsertInput!',
                          }),
                },
                item: {
                    __aliasFor: !!addressId ? 'updateAddressByPk' : 'insertAddressOne',
                    __args: {
                        ...(addressId
                            ? {
                                  [pkColumnsKey]: { id: new VariableType('id') },
                                  _set: new VariableType('payload'),
                              }
                            : {
                                  object: new VariableType('payload'),
                              }),
                    },
                    id: true,
                },
            },
        },
        { pretty: true }
    );
};
