import { useEffect, useMemo } from 'react';
import Modal from 'components/core/modal';
import { useOutletContext, useParams } from 'react-router-dom';
import Text from 'components/core/text';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from 'components/core/form/input';
import { getMenuGroupQuery, menuGroupSchema } from './utils';
import { GraphqlPaginationVariables } from 'types/graphql';
import { Page } from 'types/models/page';
import useGetPages from 'services/queries/pages/use-get-pages';
import AllIcons from 'components/core/icon/all';
import useCreateOrUpdateMenuGroup from 'services/queries/menus/use-create-or-update-menu-group';
import useGetMenuGroup from 'services/queries/menus/use-get-menu-group';
import FormButtons from 'components/form-buttons';
import SortableSelect from 'components/core/form/select-sortable';
import { Option } from 'types/general';

export type MenuGroupPayload = {
    name: string;
    icon: string;
    order: number;
    pages: Array<
        Option & {
            orderMenu: number;
        }
    >;
};

const CreateOrUpdateMenuGroupPage = () => {
    const { menuGroupId } = useParams();

    const params = useOutletContext<GraphqlPaginationVariables<Page>>();

    const { data: dataPages = [] } = useGetPages();
    const { data: menuGroup } = useGetMenuGroup(getMenuGroupQuery(menuGroupId), menuGroupId);
    const { mutateAsync: createOrUpdateMenuGroup, isLoading: isSubmitting } = useCreateOrUpdateMenuGroup(params, menuGroupId);

    const { control, formState, handleSubmit, setValue, watch, reset } = useForm<MenuGroupPayload>({
        mode: 'onSubmit',
        shouldFocusError: true,
        resolver: yupResolver(menuGroupSchema) as any,
        defaultValues: {
            order: 0,
            name: '',
            icon: '',
            pages: [],
        },
    });

    const [icon, pages] = watch(['icon', 'pages']);

    const formattedPages = useMemo(() => dataPages.map((item) => ({ value: item.id, label: item.name })), [dataPages]);

    const submit = async (data: MenuGroupPayload) => {
        try {
            const payload = {
                ...data,
                order: Number(data.order),
                pages: data.pages.map((item) => ({
                    page: item.value,
                    order: item.orderMenu,
                })),
            };

            await createOrUpdateMenuGroup(payload as any);
        } catch (error) {
            console.log('submit', error);
        }
    };

    useEffect(() => {
        if (menuGroup) {
            const pages = (menuGroup.menuPages || []).map((item) => ({
                value: item.value,
                label: item.label,
                orderMenu: item.orderMenu,
            }));

            reset({
                order: menuGroup.orderMenu,
                icon: menuGroup.icon,
                name: menuGroup.name,
                pages,
            });
        }
    }, [menuGroup, reset]);

    return (
        <Modal
            contentClassnames="w-[650px]"
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    {menuGroupId ? 'Atualizar' : 'Novo'} grupo de menu
                </Text>
            }
            closeOnClickOutside={false}>
            <form className="px-6 pb-6" onSubmit={handleSubmit(submit)}>
                <div className="grid grid-cols-2 gap-4 mb-6">
                    <Controller name="name" control={control} render={({ field }) => <Input {...field} error={formState.errors.name?.message} label="Nome" />} />
                    <Controller name="order" control={control} render={({ field }) => <Input {...field} min={0} type="number" label="Ordem" />} />
                </div>
                <Controller
                    name="pages"
                    control={control}
                    render={({ field }) => {
                        return (
                            <SortableSelect
                                {...field}
                                value={pages}
                                isMulti={true}
                                options={formattedPages}
                                size="small"
                                label="Páginas"
                                placeholder="Selecione uma opção"
                                error={(formState as any).errors.subpages?.message}
                                parentClassName="mb-6"
                                onChange={(options: any) => {
                                    const arr = options.map((item, index) => ({ ...item, orderMenu: index + 1 }));

                                    setValue(`pages`, arr);
                                }}
                            />
                        );
                    }}
                />
                <div className="border border-base-300 rounded-[14px] p-4 mb-6">
                    <Text as="label" variant="body.regular.sm" className="block mb-2 text-base-500">
                        Ícone
                    </Text>
                    <AllIcons selected={icon} onSelectIcon={(name: string) => setValue('icon', name)} />
                </div>
                <FormButtons isLoading={isSubmitting} />
            </form>
        </Modal>
    );
};

export default CreateOrUpdateMenuGroupPage;
