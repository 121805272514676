import { useMutation } from 'react-query';
import apiGraphQl from 'services/graphql';
import { affectedRowsKey } from 'utils/environment';

const useCreateCompanyPartners = () => {
    const request = async (payload: any) => {
        return apiGraphQl(
            `mutation CreateCompanyPartners($payload: [CompanyPartnerInsertInput!]!) {
                item: insertCompanyPartner(objects: $payload) {
                    ${affectedRowsKey}
                }
            }`,
            { payload }
        );
    };

    return useMutation(request);
};

export default useCreateCompanyPartners;
