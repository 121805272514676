import Badge from 'components/core/badge';
import { PurchaseProductStatus } from 'types/models/purchase-order';
import { purchaseProductStatuses } from 'utils/statics';

const variants = {
    [PurchaseProductStatus.Finished]: 'success',
    [PurchaseProductStatus.Bought]: 'success',
    [PurchaseProductStatus.Delivered]: 'success',
    [PurchaseProductStatus.Canceled]: 'error',
    [PurchaseProductStatus.Waiting]: 'warning',
    [PurchaseProductStatus.WaitingPayment]: 'warning',
    [PurchaseProductStatus.WaitingDelivered]: 'warning',
};

type PurchaseProductStatusBadgeProps = {
    purchaseProductStatus?: PurchaseProductStatus;
};
const PurchaseProductStatusBadge = ({ purchaseProductStatus }: PurchaseProductStatusBadgeProps) => {
    if (purchaseProductStatus === undefined || purchaseProductStatus === null) {
        return <span>-</span>;
    }

    const status = purchaseProductStatuses.find((item) => item.value === purchaseProductStatus);

    return <Badge variant={variants[status?.value!] ?? 'grey'}>{status?.label}</Badge>;
};

export default PurchaseProductStatusBadge;
