import { useEffect, useRef } from 'react';

export const useMemoizedEffect = (cb: () => any) => {
    const wasUsed = useRef(false);

    useEffect(() => {
        if (!wasUsed.current) {
            wasUsed.current = true;
            cb();
        }
    }, [cb]);
};
