import TableCell from '@mui/material/TableCell';
import { formatDate } from 'utils/date';
import { formatMoney } from 'utils/money';
import { natures } from 'utils/statics';
import { getNumberValueOrZero } from './details/tabs/transactions/utils';
import { DatatableColumn } from 'types/graphql';
import { Rule } from 'types/permissions';
import ApprovalBadge from 'components/badges/approval';

export const expensesDatatableColumns = (isImportPage = false): DatatableColumn[] => [
    {
        name: 'id',
        options: { display: 'excluded' },
    },
    {
        name: 'code',
        label: 'Código',
        options: {
            customBodyRender: (value) => <span className="whitespace-nowrap">{value || '-'}</span>,
        },
    },
    {
        name: 'title',
        label: 'Título',
        options: {
            customBodyRender: (value) => value || '-',
        },
    },
    {
        name: 'company.name',
        label: 'Empresa',
        options: {
            customBodyRender: (value) => <span className="whitespace-nowrap">{value || '-'}</span>,
        },
    },
    {
        name: 'sector.name',
        label: 'Setor',
        options: {
            customBodyRender: (value) => value || '-',
        },
    },
    {
        name: 'category.name',
        label: 'Categoria',
        options: {
            customBodyRender: (value) => value || '-',
        },
    },
    ...(!isImportPage
        ? [
              {
                  name: 'branch.name',
                  label: 'Filial',
                  options: {
                      customBodyRender: (value) => value || '-',
                  },
              },
              {
                  name: 'nature',
                  label: 'Natureza',
                  options: {
                      customBodyRender: (value) => {
                          const nature = natures.find((item) => item.value === value);

                          return nature?.label || '-';
                      },
                  },
              },
          ]
        : []),
    {
        name: 'dueDate',
        label: 'Vencimento',
        options: {
            customBodyRender: (value) => formatDate(value, 'DD/MM/YYYY'),
        },
    },

    {
        name: 'value',
        label: 'Total',
        options: {
            customBodyRender: (value) => formatMoney(getNumberValueOrZero(value)),
        },
    },
    {
        name: 'approvalStatus',
        label: 'Status',
        options: {
            customBodyRender: (status) => <ApprovalBadge status={status} />,
        },
    },
    {
        name: 'actions',
        label: 'Ações',
        customRoutePath: (item, rule) => {
            if (isImportPage) {
                return `/app/despesas/${item.id}`;
            }

            return rule === Rule.Update ? `${item.id}/editar` : `${item.id}`;
        },
        options: {
            customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />,
        },
    },
];
