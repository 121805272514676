import { useQuery } from 'react-query';
import api from 'services/api';
import { getBankAccountTransactionsKey } from './keys';
import { ResponseBankAccountTransactions } from 'types/models/bank-accounts';

const useGetBankAccountTransactions = (bankAccountId: number, queryFilters: string) => {
    const endpoint = `company-bank-accounts/${bankAccountId}/transactions${queryFilters}`;

    const request = () => api.get<ResponseBankAccountTransactions>(endpoint).then(({ data }) => data);

    return useQuery(getBankAccountTransactionsKey(bankAccountId, queryFilters), request, {
        enabled: Boolean(queryFilters) || Boolean(bankAccountId),
    });
};

export default useGetBankAccountTransactions;
