import TableCell from '@mui/material/TableCell/TableCell';
import Badge from 'components/core/badge';
import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';
import { Status } from 'types/general';
import { DatatableColumn } from 'types/graphql';
import { PurchaseStatus } from 'types/models/purchase-order';
import dictionary from 'utils/dictionary';
import { purchaseStatuses } from 'utils/statics';
import { TestContext, array, boolean, number, object, ref, string } from 'yup';
import { StockDeliveryParams } from './stock-delivery';

export const getStockOptionsQuery = (where: any) => {
    return jsonToGraphQLQuery(
        {
            query: {
                __name: 'GetStocksOptions',
                items: {
                    __aliasFor: 'stock',
                    __args: {
                        orderBy: { name: new EnumType('ASC') },
                        where: {
                            status: { _eq: Status.Active },
                            ...(Boolean(where) && where),
                        },
                    },
                    value: { __aliasFor: 'id' },
                    label: { __aliasFor: 'name' },
                },
            },
        },
        { pretty: true }
    );
};

export const listStockPurchaseOrderColumns: DatatableColumn[] = [
    { name: 'id', options: { display: 'excluded' } },
    {
        name: 'code',
        label: 'Código',
        options: {
            customBodyRender: (value = '-') => value,
        },
    },
    { name: 'origin.stock.name', label: 'Estoque' },
    { name: 'person.title', label: 'Solicitado por' },
    { name: 'company.name', label: 'Empresa' },
    {
        name: 'purchaseStatus',
        label: 'OC Status',
        options: {
            customBodyRender: (value: PurchaseStatus) => {
                const status = purchaseStatuses.find((item) => item.value === value);

                const variants = {
                    [PurchaseStatus.Canceled]: 'error',
                    [PurchaseStatus.Finished]: 'success',
                    [PurchaseStatus.Open]: 'grey',
                    [PurchaseStatus.Processed]: 'info',
                };

                if (!status?.label) {
                    return '-';
                }

                return <Badge variant={variants[status?.value!] ?? 'grey'}>{status?.label ?? '-'}</Badge>;
            },
        },
    },
    {
        name: 'actions',
        label: 'Ações',
        options: { customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} /> },
    },
];

export const stockDeliverySchema = (purchaseOrderProducts: StockDeliveryParams['purchaseOrderProducts']) => {
    return object({
        purchaseOrderProducts: array(
            object({
                id: number().required(dictionary.validation.required),
                stocks: array(
                    object({
                        id: number().required(dictionary.validation.required),
                        quantityInStock: number(),
                        quantity: number().max(ref('quantityInStock'), 'A quantidade solicitada não pode ser maior que a quantidade em estoque'),
                        nane: string(),
                        isChecked: boolean(),
                    })
                )
                    .test('isTotalGreater', 'A soma das quantidades solicitadas é maior que o total solicitado', (stocks = [], context) => {
                        const purchaseProduct = purchaseOrderProducts.find((item) => item.purchaseOrderProductId === context.parent?.id?.toString());
                        const quantityPurchaseOrder = purchaseProduct?.quantityPurchaseOrder || 0;
                        const totalStockQuantity = stocks.reduce((acc, cur) => acc + (cur.quantity || 0), 0);

                        return totalStockQuantity <= (Number(quantityPurchaseOrder) || 0) || !stocks.length;
                    })
                    .test('isCheckedValidation', '', (stocks = []) => {
                        return stocks.length ? stocks.some((item) => item.isChecked) : true;
                    })
                    .default([]),
            })
        ).default([]),
    });
};

const quantityBiggerThanStock = (value = 0, context?: TestContext<any>) => {
    if (!value) {
        return context?.createError({ message: dictionary.validation.required, type: 'required' });
    }

    return value <= (context?.parent?.quantityInStock || 0);
};

export const stockProductsRefundSchema = object({
    incomingStock: number().required(dictionary.validation.required),
    date: string().required(dictionary.validation.required),
    products: array(
        object({
            quantityInStock: number().optional(),
            stockProduct: number(),
            quantity: number().test('quantityBiggerThanStock', 'Quantidade maior que a quantidade em estoque', quantityBiggerThanStock),
        })
    ).min(1, dictionary.validation.array.min(1)),
});
