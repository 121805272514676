import ModalFilter from 'components/core/modal/filter';
import { useForm } from 'react-hook-form';

import { useEffect } from 'react';
import ReceiptOrderFilterFields from './fields';

type Payload = any;

type ReceiptOrderFilterProps = {
    onClose: () => void;
    onSubmit: (data?: Payload) => void;
    filters?: any;
};

const ReceiptOrderFilter = ({ onClose, filters, onSubmit }: ReceiptOrderFilterProps) => {
    const methods = useForm<Payload>({
        mode: 'onSubmit',
        defaultValues: {
            paid: 'all',
        },
    });

    const { handleSubmit, reset } = methods;

    const clear = () => onSubmit(undefined);

    useEffect(() => {
        reset(filters);
    }, [filters, reset]);

    return (
        <ModalFilter formMethods={methods} onClose={onClose} onSubmit={handleSubmit(onSubmit)} onClear={clear} className="!w-[700px] max-w-100%">
            <div className="gap-4 flex flex-col md:grid grid-cols-2">
                <ReceiptOrderFilterFields formMethods={methods} />
            </div>
        </ModalFilter>
    );
};

export default ReceiptOrderFilter;
