import { BaseModel } from './base';
import { Classification } from './classification';
import { Company } from './company';
import { Customer } from './customer';
import { Document } from './document';
import { Person } from './person';
import { Product } from './product';
import { Task } from './task';
import { Branch } from './branch';
import { SaleReceiptTransactions } from './sales-notes';
import { GetOrderServicesBalanceTotalReponse } from 'services/queries/order-services/use-get-order-services-balance-total';

export type SaleOrder = {
    customerId: number;
    companyId: number;
    personId: number;
    classificationTypeId: number;
    code: string;
    contractDate: string;
    saleOrderProducts: Partial<SaleOrderProduct>[];
    saleOrderDocuments: Partial<SaleOrderDocument>[];
    classification: Partial<Classification>;
    company: Partial<Company>;
    customer: Partial<Customer>;
    person: Partial<Person>;
    discount: number;
    addition: number;
    grossValue: number;
    netValue: number;
    origin: SaleOrderOrigin;
    type: SaleOrderType;
    branch?: Partial<Branch>;
    products: Partial<SaleOrderProduct>[];
    saleProducts: Partial<SaleOrderProduct>[];
    consultant: Partial<Person>;
    startContractDate: string;
    endContractDate: string;
    transactions: Partial<
        SaleOrderProduct & {
            dueDate: string;
            paid: boolean;
        } & Pick<SaleReceiptTransactions, 'paymentForm'>
    >[];
    commission?: number;
} & BaseModel;

export type SaleOrderProduct = {
    saleOrderId: number;
    productId: number;
    quantity: number;
    balance: number;
    grossValue: number;
    netValue: number;
    discount: number;
    addition: number;
    product: Partial<Product>;
    saleOrder: Partial<SaleOrder>;
    stockBalance?: number;
    costValue?: number;
} & BaseModel;

export type SaleOrderDocument = {
    id: number;
    saleOrderId: number;
    saleOrder: Partial<SaleOrder>;
    document: Document;
};

export enum SaleOrderType {
    public = 1,
    private,
    incentivizedProjects,
    socialCounterparts,
}

export enum SaleOrderOrigin {
    service = 1,
    sale,
}

export type ProgressStatus = {
    id: number;
    order: number;
    slug: 'pending' | 'partial' | 'stopped' | 'done';
    title: string;
};

export type BoardManager = {
    id: number;
    progressStatus: ProgressStatus;
    startDate?: string;
    endDate?: string;
    items: Array<{
        id: number;
        order: number;
        task: Task;
    }>;
};

export enum SaleOrderScheduleStatus {
    Canceled = -1,
    Requested = 1,
    Delivered,
    Rescheduled,
}

export const saleOrderScheduleStatus = {
    [SaleOrderScheduleStatus.Canceled]: {
        label: 'Cancelado',
        color: 'error',
        title: 'Infomar cancelamento',
    },
    [SaleOrderScheduleStatus.Requested]: {
        label: 'Solicitado',
        color: 'info',
        title: 'Solicitação',
    },
    [SaleOrderScheduleStatus.Delivered]: {
        label: 'Entregue',
        color: 'success',
        title: 'Informar retirada',
    },
    [SaleOrderScheduleStatus.Rescheduled]: {
        label: 'Reagendado',
        color: 'warning',
        title: 'Reagendamento',
    },
};

export type SaleOrderSchedulesResponse = {
    id: number;
    day: {
        id: number;
        day: string | Date;
        time: string | Date;
        open: boolean;
    };
    requester: Partial<Person>;
    deliveryman: Partial<Person>;
    scheduleStatus: SaleOrderScheduleStatus;
    withdrawalDate: string | Date | null;
    observations: string | null;
};

export type SaleOrderSchedulePayload = {
    day: string;
    hour: string;
};

export enum SaleOrderStatus {
    InContract = 'in-contract',
    ContractDelivered = 'contract-delivered',
    AwaitingPayment = 'awaiting-payment',
    InformedPayment = 'informed-payment',
    AwaitingDelivery = 'awaiting-delivery',
    Delivered = 'delivered',
    Finalized = 'finished',
    Canceled = 'canceled',
    InstallationInProgress = 'installation-in-progress',
    AwaitingInstallation = 'awaiting-installation',
}

export enum ConsultantSaleOrderType {
    orderService = 'orderService',
    saleOrder = 'saleOrder',
}

type SaleOrderFinancialKeys = 'total' | 'totalCost' | 'totalCostPercentage' | 'commission' | 'commissionPercentage' | 'tax' | 'taxPercentage' | 'result' | 'resultPercentage';

export type SaleOrderFinancialBalanceResponse = {
    [K in SaleOrderFinancialKeys]: number;
} & { taxes: GetOrderServicesBalanceTotalReponse['taxes'] };
