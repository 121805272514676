import useCreateBankAccountTransactionIncome from 'services/queries/bank-accounts/use-create-bank-account-transaction-income';
import CreateIncome from './create';
import { useOutletContext, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { CreateIncomePayload } from 'types/models/bank-accounts';

const CreateIncomePage = () => {
    const { bankAccountId } = useParams();

    const { queryFilters } = useOutletContext<{ queryFilters }>();

    const { mutateAsync: createBankAccountTransactionIncome, isLoading: isSubmitting } = useCreateBankAccountTransactionIncome(queryFilters, bankAccountId);

    const handleSubmit = async (data: CreateIncomePayload) => {
        try {
            const payload = {
                ...data,
                ...(Boolean(data?.dueDate) && { dueDate: dayjs(data?.dueDate).format('YYYY-MM-DD') }),
            };

            await createBankAccountTransactionIncome(payload);
        } catch (error) {
            console.error(error);
        }
    };

    return <CreateIncome isSubmitting={isSubmitting} onSubmit={handleSubmit} />;
};
export default CreateIncomePage;
