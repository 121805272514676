import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import Text from 'components/core/text';
import Spinner from 'components/core/spinner';
import { formatDate } from 'utils/date';
import Badge from 'components/core/badge';
import { formatMoney } from 'utils/money';
import { Transaction, TransactionInvoiceStatus, TransactionType } from 'types/models/transaction';
import dayjs from 'dayjs';
import TableFooter from '@mui/material/TableFooter/TableFooter';
import { useMemo } from 'react';
import TableContainer from '@mui/material/TableContainer/TableContainer';

type TransactionsProps = {
    isBuildingTransactions: boolean;
    transactions?: Transaction[];
};

const Transactions = ({ isBuildingTransactions, transactions = [] }: TransactionsProps) => {
    const renderBadge = (data: Transaction) => {
        if (!data) {
            return '-';
        }

        if (data.paid) {
            return <Badge variant="success">Pago</Badge>;
        } else {
            if (Boolean(data.payday) && data.invoiceStatus === TransactionInvoiceStatus.WaitingToBeSent && data.type === TransactionType.Outcome) {
                return <Badge variant="warning">Pagamento informado - enviar documento</Badge>;
            } else {
                const today = dayjs();
                const dueDate = dayjs(data.dueDate);

                if (today.format('YYYY-MM-DD') === dueDate.format('YYYY-MM-DD')) {
                    return <Badge variant="info">Realizar pagamento</Badge>;
                }

                if (today.isAfter(dueDate)) {
                    return <Badge variant="error">Atrasado</Badge>;
                }

                return <Badge variant="warning">Aguardando pagamento</Badge>;
            }
        }
    };

    const consolidated = useMemo(() => transactions.reduce((acc, curr) => acc + Number(curr.netValue) || 0, 0), [transactions]);

    if (isBuildingTransactions) {
        return (
            <div className="px-6 mb-6">
                <div className="border border-base-300 p-4 rounded-[14px]">
                    <Spinner fixed={false} size={24} wrapperClasses="p-2 bg-base-100 rounded-full" />
                </div>
            </div>
        );
    }

    return (
        <div className="px-6 mb-6">
            <div className="border border-base-300 rounded-[14px] overflow-hidden">
                <Text as="h6" variant="h5" className="text-heading p-4">
                    Movimentações a serem agrupadas
                </Text>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Text className="text-base-500 uppercase">Previsão</Text>
                                </TableCell>
                                <TableCell>
                                    <Text className="text-base-500 uppercase">Vencimento</Text>
                                </TableCell>
                                <TableCell>
                                    <Text className="text-base-500 uppercase">Código</Text>
                                </TableCell>
                                <TableCell>
                                    <Text className="text-base-500 uppercase">Setor</Text>
                                </TableCell>
                                <TableCell>
                                    <Text className="text-base-500 uppercase">Categoria</Text>
                                </TableCell>
                                <TableCell>
                                    <Text className="text-base-500 uppercase">Origem</Text>
                                </TableCell>
                                <TableCell>
                                    <Text className="text-base-500 uppercase">Sub-origem</Text>
                                </TableCell>
                                <TableCell>
                                    <Text className="text-base-500 uppercase">Favorecido</Text>
                                </TableCell>
                                <TableCell>
                                    <Text className="text-base-500 uppercase">Empresa</Text>
                                </TableCell>
                                <TableCell>
                                    <Text className="text-base-500 uppercase">Status</Text>
                                </TableCell>
                                <TableCell>
                                    <Text className="text-base-500 uppercase">Valor</Text>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!transactions?.length ? (
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell colSpan={4}>
                                        <Text className="text-base-500 block">Nenhuma OP encontrada.</Text>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                transactions.map((item) => {
                                    return (
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={item.id}>
                                            <TableCell>
                                                <Text className="text-heading whitespace-nowrap">{formatDate(item.paymentForecast)}</Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text className="text-heading whitespace-nowrap">{formatDate(item.dueDate)}</Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text className="text-heading whitespace-nowrap">{item.code || '-'}</Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text className="text-heading whitespace-nowrap">{item.expense?.sector?.name || '-'}</Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text className="text-heading whitespace-nowrap">{item.expense?.category?.name || '-'}</Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text className="text-heading whitespace-nowrap">{item.expense?.classificationOrigin?.name || '-'}</Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text className="text-heading whitespace-nowrap">{item.expense?.classificationSubOrigin?.name || '-'}</Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text className="text-heading whitespace-nowrap">{item.expense?.favored?.title || '-'}</Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text className="text-heading whitespace-nowrap">{item.expense?.company?.name || '-'}</Text>
                                            </TableCell>
                                            <TableCell>{renderBadge(item)}</TableCell>
                                            <TableCell>
                                                <Text className="text-heading whitespace-nowrap">{formatMoney(Number(item.netValue))}</Text>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow className="bg-base-200">
                                <TableCell className="border-b-0" colSpan={10}>
                                    <Text className="text-base-500 uppercase font-bold">Total</Text>
                                </TableCell>
                                <TableCell className="border-b-0">
                                    <Text className="text-heading font-bold">{formatMoney(consolidated)}</Text>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};

export default Transactions;
