import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import Spinner from 'components/core/spinner';
import Text from 'components/core/text';
import Empty from 'components/empty';
import { memo, useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import useGetProductStocks from 'services/queries/stocks/use-get-products-stocks';
import useTheme from '@mui/material/styles/useTheme';
import StockDeliveryItemRow from './row';
import TableFooter from '@mui/material/TableFooter/TableFooter';
import ErrorMessage from 'components/error/message';
import { formatNumberToPTBR } from 'utils/money';

export type StockDeliveryPayload = {
    purchaseOrderProducts: Array<{
        id: number;
        stocks: Array<{
            id: number;
            quantity?: number;
            name: string;
            quantityInStock: number;
            isChecked: boolean;
        }>;
    }>;
};

type StockDeliveryItemProps = {
    index: number;
    productIds: {
        purchaseOrderProductId: string;
        productId: string;
        quantityPurchaseOrder: string;
        unit: string;
    };
};

const StockDeliveryItem = ({ productIds, index }: StockDeliveryItemProps) => {
    const { palette } = useTheme();
    const { data: product, isLoading } = useGetProductStocks(productIds.productId, productIds.purchaseOrderProductId);

    const { control, formState, setValue, watch, trigger } = useFormContext<StockDeliveryPayload>();

    const stocksPath = `purchaseOrderProducts.${index}.stocks` as const;

    const stocks = watch(stocksPath, []);

    const totalQuantity = stocks.reduce((acc, curr) => Number(acc || 0) + Number(curr?.quantity || 0), 0);

    const quantityGreaterThanTotal = formState.errors?.purchaseOrderProducts?.[index]?.stocks?.message;

    useFieldArray({ control, name: stocksPath });

    useEffect(() => {
        if (!!product) {
            setValue(`purchaseOrderProducts.${index}.id`, Number(productIds.purchaseOrderProductId || 0));
            setValue(
                stocksPath,
                product.stocks.map((item) => ({
                    id: item.id || 0,
                    quantityInStock: item.quantity || 0,
                    name: product.name,
                    isChecked: false,
                }))
            );
        }
    }, [product, setValue, index, productIds.purchaseOrderProductId, stocksPath]);

    useEffect(() => {
        const validateForm = async () => {
            await trigger(stocksPath);
        };

        validateForm();
    }, [stocksPath, totalQuantity, trigger, stocks]);

    return (
        <div className="rounded-[14px] overflow-hidden border border-base-300 mb-6 last:mb-0">
            {isLoading ? (
                <div className="p-4">
                    <Spinner color={palette.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={4} className="py-3">
                                <div className="flex">
                                    <Text variant="body.medium.2xs" className="text-heading flex-1">
                                        {product?.name}
                                    </Text>
                                    <Text variant="body.medium.2xs" className="text-base-500">
                                        <span>Total solicitado: </span>
                                        <strong className="text-secondary-500">
                                            {productIds.quantityPurchaseOrder} {productIds.unit}
                                        </strong>
                                    </Text>
                                </div>
                            </TableCell>
                        </TableRow>
                        {Boolean(product?.stocks?.length) && (
                            <TableRow className="bg-base-200">
                                <TableCell className="py-2 border-r border-r-base-300" />
                                <TableCell className="py-2 border-r border-r-base-300">
                                    <Text className="text-base-500">Estoque</Text>
                                </TableCell>
                                <TableCell className="py-2 border-r border-r-base-300">
                                    <Text className="text-base-500 text-right block">Qtd em estoque</Text>
                                </TableCell>
                                <TableCell className="py-2">
                                    <Text className="text-base-500 text-right block">Qtd solicitada</Text>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableHead>
                    <TableBody>
                        {Boolean(product?.stocks?.length) ? (
                            <>
                                {product?.stocks?.map((item, ix) => {
                                    return <StockDeliveryItemRow {...item} purchaseOrderProductIndex={index} stockIndex={ix} key={item.id} />;
                                })}
                                {quantityGreaterThanTotal && (
                                    <TableRow sx={{ '&:last-child td': { borderBottom: 0 } }}>
                                        <TableCell colSpan={4}>
                                            <ErrorMessage visible={true}>{quantityGreaterThanTotal}</ErrorMessage>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </>
                        ) : (
                            <>
                                <TableRow sx={{ '&:last-child td': { borderBottom: 0 } }}>
                                    <TableCell colSpan={4}>
                                        <Empty title="Nenhum estoque encontrado para este produto" />
                                    </TableCell>
                                </TableRow>
                            </>
                        )}
                    </TableBody>
                    {Boolean(product?.stocks?.length) && (
                        <TableFooter className="bg-base-200 border-t border-t-300">
                            <TableRow sx={{ '&:last-child td': { borderBottom: 0 } }}>
                                <TableCell colSpan={4}>
                                    <Text className="text-base-500 text-right block">
                                        Total selecionado:
                                        <strong className="ml-1 text-secondary-500">
                                            {formatNumberToPTBR(totalQuantity)} {productIds.unit}
                                        </strong>
                                    </Text>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    )}
                </Table>
            )}
        </div>
    );
};

export default memo(StockDeliveryItem);
