import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import { getAllKey } from '../crud/keys';
import { getPersonKey } from './keys';

const useChangePersonContractStatus = (contractId?: number, personId?: number, ctx?: any) => {
    const queryClient = useQueryClient();

    const request = async (payload: any) => api.post<any>(`/people/${personId}/contracts/${contractId}/status`, payload).then(({ data }) => data);

    return useMutation(request, {
        onSuccess: () => {
            queryClient.invalidateQueries(getPersonKey(String(personId!)));

            queryClient.invalidateQueries(
                getAllKey('personContract', {
                    limit: ctx?.limit,
                    offset: ctx?.offset,
                    orderBy: ctx?.orderBy,
                    page: ctx?.page,
                    where: ctx?.where,
                })
            );
        },
    });
};

export default useChangePersonContractStatus;
