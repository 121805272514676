import { useQuery } from 'react-query';
import { getPendingPurchaseOrderProductsApiKey } from './keys';
import api from 'services/api';
import { PurchaseOrderProduct } from 'types/models/purchase-order';

const useGetPurchaseOrderProductsApi = (purchaseOrderId?: string) => {
    const request = async () => {
        const { data } = await api.get<PurchaseOrderProduct[]>(`/purchase-order/${purchaseOrderId}/products`);

        return data;
    };

    return useQuery(getPendingPurchaseOrderProductsApiKey(purchaseOrderId), request, { enabled: Boolean(purchaseOrderId) });
};

export default useGetPurchaseOrderProductsApi;
