import Button from 'components/core/button';
import Textarea from 'components/core/form/textarea';
import useDebounce from 'hooks/keyboard/use-debounce';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import TaskComment from './comment';
import useCreateTaskComment from 'services/queries/tasks/use-create-task-comment';
import { TaskComment as TaskCommentModel } from 'types/models/task';

type TaskCommentsProps = {
    items: TaskCommentModel[];
};

const TaskComments = ({ items }: TaskCommentsProps) => {
    const { taskId } = useParams();
    const [text, setText] = useState('');

    const debounceText = useDebounce(text, 500);

    const { mutateAsync: createComment, isLoading } = useCreateTaskComment(taskId);

    const handleSubmit = async () => {
        try {
            if (!debounceText) {
                return alert('O campo comentário é obrigatório');
            }

            await createComment({ text: debounceText });

            setText('');
        } catch (error) {
            console.log('TaskComments -> handleSubmit: ', error);
        }
    };

    return (
        <div className="p-6">
            <div className="border border-base-300 p-3 rounded-[14px] mb-6">
                <Textarea value={text} className="border-0 !p-0 rounded-none min-h-[70px]" placeholder="Adicione um comentário" onChange={(e) => setText(e.currentTarget.value)} />
                <Button disabled={isLoading} loading={isLoading} variant="contained" color="secondary" type="button" onClick={handleSubmit}>
                    Enviar
                </Button>
            </div>
            <div>
                {items.map((item) => {
                    return <TaskComment key={item.id} {...item} />;
                })}
            </div>
        </div>
    );
};

export default TaskComments;
