import useToast from 'hooks/toast/use-toast';
import { useQuery } from 'react-query';
import apiGraphQl from 'services/graphql';
import { getPersonDetailsTabKey } from './keys';

type Aggregate = {
    aggregate: { count: number };
};

type Response = {
    totalContracts: Aggregate;
};

const useGetPersonDetailsTab = (personId?: string) => {
    const { showToast } = useToast();

    const fetcher = async () => {
        const { totalContracts } = await apiGraphQl<Response>(
            `query GetPersonDetailsTab($personId: Int!) {
                totalContracts: personContractAggregate(where: {personId: {_eq: $personId}}) {
                    aggregate { count }
                }
            }`,
            { personId }
        );

        return {
            totalContracts: totalContracts.aggregate.count,
        };
    };

    return useQuery(getPersonDetailsTabKey(personId), fetcher, {
        enabled: !!personId,
        onError: () => {
            showToast('Tivemos um problema ao obter os detalhes dessa pessoa. Tente novamente.', 'danger');
        },
    });
};

export default useGetPersonDetailsTab;
