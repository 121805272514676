import TableCell from '@mui/material/TableCell/TableCell';
import Badge from 'components/core/badge';
import { CrudPageProps } from 'types/graphql';
import { PurchaseStatus } from 'types/models/purchase-order';
import { formatDate } from 'utils/date';
import { purchaseStatuses } from 'utils/statics';

export const orderPurchasePageListConfig = (orderServiceId?: string): CrudPageProps<{}> => ({
    title: 'Ordens de compra',
    customTableOptions: {
        selectableRows: 'none',
    },
    graphql: {
        table: 'purchaseOrder',
        searchableField: 'code',
        columns: [
            {
                name: 'id',
                options: { display: 'excluded' },
            },
            {
                label: 'Código',
                name: 'code',
            },
            {
                label: 'Data',
                name: 'createdAt',
                options: {
                    customBodyRender: (value) => formatDate(value),
                },
            },
            {
                label: 'Status',
                name: 'purchaseStatus',
                options: {
                    customBodyRender: (value) => {
                        const status = purchaseStatuses.find((item) => item.value === value);

                        const variants = {
                            [PurchaseStatus.Canceled]: 'error',
                            [PurchaseStatus.Finished]: 'success',
                            [PurchaseStatus.Open]: 'grey',
                            [PurchaseStatus.Processed]: 'info',
                        };

                        return <Badge variant={variants[status?.value!] ?? 'grey'}>{status?.label}</Badge>;
                    },
                },
            },
            {
                label: 'Ações',
                name: 'actions',
                options: {
                    customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />,
                },
            },
        ],
        where: {
            purchaseOrderOrigin: {
                purchaseOrderOriginOrderService: {
                    orderServiceId: { _eq: orderServiceId },
                },
            },
        },
    },
});
