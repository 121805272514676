import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import ListPage from 'pages/shared/crud/list';
import { useParams } from 'react-router-dom';
import { stockBalanceListConfig } from './utils';

const StockBalancePage = ({ page }: WithPagePermissionsProps) => {
    const { stockId } = useParams();
    const listConfig = stockBalanceListConfig(stockId);

    return <ListPage title="Balanço" page={page} {...listConfig} />;
};

export default StockBalancePage;
