import useTheme from '@mui/material/styles/useTheme';
import Button from 'components/core/button';
import { useSaleOrderContext } from '../context';
import { Steps } from '../context/types';
import Text from 'components/core/text';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useGetClassifications from 'services/queries/classifications/use-get-classifications';
import { generateClassificationQuery } from 'services/queries/classifications/utils';
import { ClassificationType } from 'types/models/classification';
import Select from 'components/core/form/select';
import { formatMoney } from 'utils/money';
import { useCallback, useEffect, useMemo } from 'react';
import sumBy from 'lodash/sumBy';
import { installments } from '../../service-order/create-or-update/financial';
import ErrorMessage from 'components/error/message';
import { serviceOrderFinancialSchema } from '../../service-order/create-or-update/utils';
import { useParams } from 'react-router-dom';
import FinancialItem from '../../service-order/create-or-update/financial-item';
import UpdateFinancial from '../../service-order/create-or-update/update-financial';
import classNames from 'classnames';
import SvgIcoArrowLeft from 'components/core/icon/files/ico-arrow-left';
import SvgIcoArrowRight from 'components/core/icon/files/ico-arrow-right';

type Payload = {
    installments: number;
    transactions: Array<{
        order: number;
        description: string;
        grossValue: number;
        paymentForm?: number;
        dueDate: string;
    }>;
};

const MIN_INSTALLMENTS = 2;

const SaleOrderFinancial = () => {
    const { palette } = useTheme();
    const { goToStep, completeStep, setContent, state } = useSaleOrderContext();
    const { saleOrderId } = useParams();

    const { data: paymentForms = [] } = useGetClassifications(generateClassificationQuery(ClassificationType.PaymentForm));

    const saleScopeTotalValue = useMemo(() => state.content.products.reduce((acc, curr) => acc + (curr.quantity || 0) * (curr.netValue || 0), 0) || 0, [state.content.products]);

    const budgetAverage = (value: Payload['transactions'] = [], context: any) => {
        if (!Array.isArray(value)) {
            return true;
        }

        const total = (value.reduce((acc, curr) => acc + (curr.grossValue || 0), 0) * 100) / 100;

        if (Number(total.toFixed(2)) > Number(saleScopeTotalValue.toFixed(2))) {
            return false;
        }

        clearErrors(context.path);

        return true;
    };

    const methods = useForm<Payload>({
        mode: 'onSubmit',
        defaultValues: { transactions: [] },
        resolver: yupResolver(serviceOrderFinancialSchema(saleScopeTotalValue, budgetAverage)) as any,
    });

    const { formState, handleSubmit, control, watch, clearErrors, reset, trigger, getValues } = methods;

    useFieldArray({ control, name: 'transactions', shouldUnregister: true });

    const transactions = watch('transactions');

    const handleBack = () => {
        setContent({ transactions: getValues('transactions') });
        goToStep('previous');
    };

    const total = sumBy(transactions, 'grossValue');
    const remainingValue = Number(saleScopeTotalValue.toFixed(2)) - Number(total.toFixed(2));

    const handleSelectInstallments = useCallback(
        (option: any) => {
            const installments = option.value;

            const arr = Array.from(Array(installments), (_, index) => {
                const getGrossValue = () => {
                    if (installments <= MIN_INSTALLMENTS) {
                        return saleScopeTotalValue / installments;
                    }

                    const halfValue = saleScopeTotalValue / MIN_INSTALLMENTS;

                    if (!index) {
                        return halfValue;
                    }

                    const countWithoutFirstElement = installments - 1;
                    const restValue = saleScopeTotalValue - halfValue;

                    return restValue / countWithoutFirstElement;
                };

                return {
                    order: index + 1,
                    description: index + 1 === 1 ? 'Parcela 1/sinal' : `Parcela ${index + 1}`,
                    dueDate: new Date().toISOString(),
                    grossValue: getGrossValue(),
                };
            });

            reset({ installments, transactions: arr });
            trigger('transactions');
        },
        [reset, trigger, saleScopeTotalValue]
    );

    const submit = (data: Payload) => {
        setContent(data);
        completeStep(Steps.Financial);
        goToStep('next');
    };

    useEffect(() => {
        if (!!state.content?.transactions?.length) {
            reset({
                transactions: state.content.transactions || [],
                installments: state.content.installments,
            });
        }
    }, [state, reset]);

    return (
        <FormProvider {...methods}>
            <form className="h-full" onSubmit={handleSubmit(submit)}>
                <div className="flex flex-col-reverse max-h-[calc(100%-312px)] md:max-h-[calc(100%-275px)] md:flex-row">
                    {!saleOrderId && (
                        <div className="w-full md:w-[320px] flex flex-col border-t border-t-base-300 md:border-r md:border-t-0 md:border-base-300">
                            <Controller
                                control={control}
                                name="installments"
                                render={({ field }) => {
                                    const value = installments.find((item) => item.value === field.value);

                                    return (
                                        <Select
                                            value={value}
                                            onChange={handleSelectInstallments}
                                            options={installments}
                                            placeholder="Selecione a quantidade de parcelas"
                                            label="Parcelas"
                                            parentClassName="p-6 flex-1"
                                        />
                                    );
                                }}
                            />
                            <div className="bg-secondary-100 py-4 px-6 bg-opacity-20">
                                <div className="flex justify-between mb-1">
                                    <Text className="text-base-700">Total da despesa:</Text>
                                    <Text variant="body.medium.2xs" className="text-heading">
                                        {formatMoney(saleScopeTotalValue)}
                                    </Text>
                                </div>
                                <div className="flex justify-between">
                                    <Text className="text-base-700">Somatórios das parcelas:</Text>
                                    <Text variant="body.medium.2xs" className="text-heading">
                                        {formatMoney(total)}
                                    </Text>
                                </div>
                                {Boolean(remainingValue) && (
                                    <div className="flex justify-between bg-system-danger-100 bg-opacity-30 -mx-6 px-6 -mb-4 mt-2 py-2">
                                        <Text className="text-system-danger-700">Diferença:</Text>
                                        <Text as="strong" variant="body.medium.2xs" className="text-system-danger-700">
                                            {formatMoney(remainingValue)}
                                        </Text>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {Boolean(transactions.length) ? (
                        <div className={classNames('flex-1 overflow-auto max-h-full', Boolean(saleOrderId) ? 'py-6' : 'p-6')}>
                            <ErrorMessage className={classNames('block mb-4', Boolean(saleOrderId) ? 'px-4' : '')} visible={Boolean(formState.errors.transactions?.message)}>
                                {formState.errors.transactions?.message}
                            </ErrorMessage>
                            {!saleOrderId &&
                                transactions.map((item, index) => (
                                    <FinancialItem key={item.order} paymentForms={paymentForms} firstPaymentForm={transactions[0].paymentForm} index={index} item={item} />
                                ))}

                            {Boolean(saleOrderId) && <UpdateFinancial totalSaleScope={saleScopeTotalValue} transactions={transactions} paymentForms={paymentForms} />}
                        </div>
                    ) : (
                        <div className="p-6 flex-1 self-center">
                            <div className="flex items-center justify-center">
                                <Text as="p" variant="body.regular.sm" className="italic text-center text-base-500">
                                    Selecione uma data para buscar os faturamentos diretos.
                                </Text>
                            </div>
                        </div>
                    )}
                </div>
                <div className="border-t p-2 flex justify-between">
                    <Button startIcon={<SvgIcoArrowLeft width={18} color={palette.grey[700]} />} type="button" variant="text" color="inherit" className="min-w-[100px]" onClick={handleBack}>
                        Voltar
                    </Button>
                    <Button endIcon={<SvgIcoArrowRight width={18} color={palette.secondary[500]} />} type="submit" variant="text" color="secondary" className="min-w-[100px]">
                        Avançar
                    </Button>
                </div>
            </form>
        </FormProvider>
    );
};

export default SaleOrderFinancial;
