import PageHeader from 'components/page/header';
import TasksColumn from './column';
import Spinner from 'components/core/spinner';
import { Outlet, useParams } from 'react-router-dom';
import useGetBoardManager from 'services/queries/sales-order/use-get-board-manager';
import { DragDropContext, DropResult } from '@hello-pangea/dnd';
import useUpdateTaskStatus from 'services/queries/tasks/use-update-task-status';

const TimelinePage = () => {
    const { saleOrderId, orderServiceId } = useParams();

    const { data: columns = [], isLoading } = useGetBoardManager(saleOrderId, orderServiceId);
    const { mutateAsync: updateTaskStatus, isLoading: isSubmitting } = useUpdateTaskStatus(saleOrderId, orderServiceId);

    const handleDragEnd = async (event: DropResult) => {
        try {
            const { destination, draggableId, source } = event;

            // dropped outside any list
            if (!destination || source.droppableId === destination.droppableId) {
                return;
            }

            const payload = {
                taskId: +draggableId,
                taskStatusId: +destination.droppableId,
            };

            await updateTaskStatus(payload);
        } catch (error) {
            console.log('handleDragEnd', error);
        }
    };

    return (
        <div className="-mx-6 h-full">
            {(isLoading || isSubmitting) && <Spinner fixed={true} parentClasses="bg-base-200/[.5]" />}
            <div className="flex flex-col h-full overflow-hidden">
                <PageHeader title="Cronograma" className="mb-7 sm:px-6" />
                <div className="flex flex-1 overflow-x-auto gap-4 sm:gap-8">
                    <DragDropContext onDragEnd={handleDragEnd}>
                        {columns.map((status) => (
                            <TasksColumn key={status.id} isLoading={isLoading} {...status} />
                        ))}
                    </DragDropContext>
                </div>
            </div>
            <Outlet />
        </div>
    );
};

export default TimelinePage;
