import { useMutation } from 'react-query';
import useToast from 'hooks/toast/use-toast';
import api from 'services/api';

const useResetPeoplePassword = (peopleId?: number) => {
    const { showToast } = useToast();

    const request = async (payload: { id: number }) => api.post(`people/${peopleId}/reset-password`, payload);

    return useMutation(request, {
        onSuccess: () => {
            showToast(`Senha renovada com sucesso`, 'success', { duration: 5000 });
        },
        onError: (error) => {
            console.log(error);
            showToast(`Ocorreu um erro ao renovar a senha do usuário em nosso sistema`, 'danger');
        },
    });
};

export default useResetPeoplePassword;
