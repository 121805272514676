import Select from 'components/core/form/select';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Button from 'components/core/button';
import { useTableAdvancedSearch } from 'components/core/table/datatable/components/advanced-search';

import qs from 'qs';

import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';

import useGetOptions from 'services/queries/crud/use-get-options';
import { queryConfigCompanyPartners } from 'pages/private/bank-accounts/create-or-update/utils';
import { getCompanyPartnersKey } from 'services/queries/bank-accounts/keys';

import { getCompanyBankAccountsOptionsKeys } from 'services/queries/contribution/keys';
import { getCompanyOptionsKey } from 'services/queries/companies/keys';
import { queryConfigCompanies } from 'pages/private/contributions/utils';
import { queryCompanyBankAccountsOptions } from 'services/queries/bank-accounts/utils';
import FilterPeriod from 'pages/private/payment-order/filter/period';

type Payload = {
    companies?: number[];
    bankAccounts?: number[];
    partners?: number[];
    startPayday: string;
    endPayday: string;
};

type ContributionsFiltersProps = {
    onSetPage: (page: number) => void;
};

const whereClause = (companyIds: number[]) => ({
    company: {
        id: { _in: companyIds },
    },
});

const whereClauseBankAccounts = (companyIds: number[]) => ({
    companyId: { _in: companyIds },
});

const ContributionsFilters = ({ onSetPage }: ContributionsFiltersProps) => {
    const { search } = useLocation();

    const filters = useMemo(() => qs.parse(search, { ignoreQueryPrefix: true }) as any, [search]);

    const companies = useMemo(() => filters?.filter?.companies, [filters?.filter?.companies]);
    const partners = useMemo(() => filters?.filter?.partners, [filters?.filter?.partners]);
    const bankAccounts = useMemo(() => filters?.filter?.bankAccounts, [filters?.filter?.bankAccounts]);
    const startPayday = useMemo(() => filters?.filter?.startPayday, [filters?.filter?.startPayday]);
    const endPayday = useMemo(() => filters?.filter?.endPayday, [filters?.filter?.endPayday]);

    const [companyIds, setCompanyIds] = useState<number[] | undefined>(Boolean(companies) ? (companies as number[]) : undefined);

    const { data: companyOptions } = useGetOptions(queryConfigCompanies, getCompanyOptionsKey, true);

    const { data: bankAccountsOptions } = useGetOptions(
        queryCompanyBankAccountsOptions(companyIds?.length ? whereClauseBankAccounts(companyIds) : undefined),
        getCompanyBankAccountsOptionsKeys(companyIds),
        Boolean(companies) || true,
        (items) => items.map((item) => ({ value: item?.bankAccount?.value, label: item?.bankAccount?.label }))
    );

    const { data: partnersOptions } = useGetOptions(
        queryConfigCompanyPartners(Boolean(companyIds) && companyIds?.length ? whereClause(companyIds!) : undefined),
        getCompanyPartnersKey(),
        Boolean(companyIds) || true,
        (items) => items.map((item) => ({ value: item?.value, label: item?.person?.label }))
    );

    const { onClose } = useTableAdvancedSearch();

    const { control, handleSubmit, reset, setValue, formState } = useForm<Payload>({
        mode: 'onSubmit',
        defaultValues: {
            startPayday: '',
            endPayday: '',
        },
    });

    const navigate = useNavigate();

    const submit = useCallback(
        (data: Payload) => {
            onSetPage(1);

            const payload = {
                startPayday: Boolean(data.startPayday) ? dayjs(data.startPayday).format('YYYY-MM-DD') : null,
                endPayday: Boolean(data.endPayday) ? dayjs(data.endPayday).format('YYYY-MM-DD') : null,
                companies: data.companies,
                bankAccounts: data.bankAccounts,
                partners: data.partners,
            };

            const filters = qs.stringify(
                { filter: payload },
                {
                    addQueryPrefix: true,
                    encode: false,
                    arrayFormat: 'brackets',
                    skipNulls: true,
                }
            );

            navigate(filters);

            onClose();
        },
        [onClose, navigate, onSetPage]
    );

    useEffect(() => {
        reset({
            ...(Boolean(startPayday) && { startPayday: dayjs(startPayday).toISOString() }),
            ...(Boolean(endPayday) && { endPayday: dayjs(endPayday).toISOString() }),
            ...(Boolean(companies?.length) && { companies }),
            ...(Boolean(bankAccounts?.length) && { bankAccounts }),
            ...(Boolean(partners?.length) && { partners }),
        });
    }, [bankAccounts, companies, endPayday, partners, reset, startPayday]);

    return (
        <form onSubmit={handleSubmit(submit)}>
            <div className="mb-5 grid grid-cols-1 gap-3">
                <Controller
                    name="companies"
                    control={control}
                    render={({ field }) => {
                        const fieldValuesNumber = field.value?.map((item) => Number(item));
                        const value = companyOptions?.filter((item) => fieldValuesNumber?.includes(Number(item?.value)));

                        return (
                            <Select
                                name={field.name}
                                ref={field.ref}
                                value={value}
                                isMulti={true}
                                options={companyOptions || []}
                                onChange={(value: any) => {
                                    const ids = value.map((item) => item.value);

                                    setCompanyIds(ids);
                                    field.onChange(ids);

                                    setValue('partners', undefined);
                                    setValue('bankAccounts', undefined);
                                }}
                                label="Empresas"
                            />
                        );
                    }}
                />
                <Controller
                    name="bankAccounts"
                    control={control}
                    render={({ field }) => {
                        const fieldValuesNumber = field.value?.map((item) => Number(item));
                        const value = bankAccountsOptions?.filter((item) => fieldValuesNumber?.includes(Number(item?.value)));

                        return (
                            <Select
                                name={field.name}
                                ref={field.ref}
                                value={value}
                                isMulti={true}
                                options={bankAccountsOptions || []}
                                onChange={(value: any) => {
                                    const ids = value.map((item) => item.value);
                                    field.onChange(ids);
                                }}
                                label="Contas bancárias"
                            />
                        );
                    }}
                />
                <Controller
                    name="partners"
                    control={control}
                    render={({ field }) => {
                        const fieldValuesNumber = field.value?.map((item) => Number(item));
                        const value = partnersOptions?.filter((item) => fieldValuesNumber?.includes(Number(item?.value)));

                        return (
                            <Select
                                name={field.name}
                                ref={field.ref}
                                isMulti={true}
                                options={partnersOptions || []}
                                value={value}
                                onChange={(value: any) => {
                                    const ids = value.map((item) => item.value);
                                    field.onChange(ids);
                                }}
                                label="Sócios"
                            />
                        );
                    }}
                />
                <FilterPeriod
                    control={control}
                    label="Período de Pagamento"
                    firstInput={{
                        inputProps: {
                            label: 'Início',
                            name: 'startPayday',
                            error: formState.errors.startPayday?.message,
                        },
                    }}
                    secondInput={{
                        inputProps: {
                            label: 'Fim',
                            name: 'endPayday',
                            error: formState.errors.endPayday?.message,
                        },
                    }}
                />
            </div>
            <div className="flex">
                <Button type="submit" variant="contained" color="secondary" className="min-w-[100px] ml-auto">
                    Filtrar
                </Button>
            </div>
        </form>
    );
};

export default memo(ContributionsFilters);
