import Datatable from 'components/core/table/datatable';
import { useMemo, useState } from 'react';
import useGetContributions from 'services/queries/contribution/use-get-contributions';
import { columnsContributionsTable } from './utils';
import ContributionFilters from 'components/advanced-search/contributions';
import qs from 'qs';
import { useLocation } from 'react-router-dom';
import PageHeader from 'components/page/header';
import useConfig from 'store/config/use-config';

const ContributionsPage = () => {
    const { config } = useConfig();
    const { search } = useLocation();

    const [page, setPage] = useState(1);
    const [searchText, setSearchText] = useState('');

    const filters = useMemo(() => qs.parse(search, { ignoreQueryPrefix: true }) as any, [search]);
    const companies = useMemo(() => filters?.filter?.companies?.map((item) => Number(item)), [filters?.filter?.companies]);
    const partners = useMemo(() => filters?.filter?.partners?.map((item) => Number(item)), [filters?.filter?.partners]);
    const bankAccounts = useMemo(() => filters?.filter?.bankAccounts?.map((item) => Number(item)), [filters?.filter?.bankAccounts]);
    const startPayday = useMemo(() => filters?.filter?.startPayday, [filters?.filter?.startPayday]);
    const endPayday = useMemo(() => filters?.filter?.endPayday, [filters?.filter?.endPayday]);

    const queryFilters = qs.stringify(
        {
            filter: {
                startPayday: !startPayday ? undefined : startPayday,
                endPayday: !endPayday ? undefined : endPayday,
                ...(Boolean(companies) && { companies }),
                ...(Boolean(bankAccounts) && { bankAccounts }),
                ...(Boolean(partners) && { partners }),
                ...(Boolean(searchText) && { searchText }),
            },
            page,
            totalPerPage: config.tables.rowsPerPage,
        },
        { addQueryPrefix: true, encode: false, arrayFormat: 'brackets', skipNulls: true }
    );

    const hasSelectedFilters = useMemo(() => queryFilters.includes('filter'), [queryFilters]);

    const { data: contributions, isLoading } = useGetContributions(queryFilters);

    return (
        <>
            <PageHeader title="Aportes" className="mb-3" />
            <Datatable
                columns={columnsContributionsTable}
                data={contributions?.items || []}
                loading={isLoading}
                options={{
                    sort: true,
                    selectableRowsHeader: false,
                    selectableRowsHideCheckboxes: true,
                    filter: false,
                    search: true,
                    searchAlwaysOpen: true,
                    print: false,
                    download: false,
                    viewColumns: false,
                    serverSide: true,
                    enableNestedDataAccess: '.',
                    pagination: true,
                    elevation: 21,
                    count: contributions?.total,
                    page,
                    searchPlaceholder: 'Buscar aporte',
                    onChangePage: setPage,
                    onSearchChange: (text) => setSearchText(text || ''),
                }}
                hasSelectedFilters={hasSelectedFilters}
                advancedSearchComponent={<ContributionFilters onSetPage={setPage} />}
            />
        </>
    );
};

export default ContributionsPage;
