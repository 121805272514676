import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { useParams } from 'react-router-dom';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import Input from 'components/core/form/input';
import Select from 'components/core/form/select';
import { yupResolver } from '@hookform/resolvers/yup';
import AddButton from 'components/buttons/add';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import FormButtons from 'components/form-buttons';
import Empty from 'components/empty';
import theme from 'settings/theme';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import IconButton from '@mui/material/IconButton/IconButton';
import ReportPageCreateOrUpdateBlockForm from './block-form';
import useCreateOrUpdateReportPage from 'services/queries/report-page/use-create-or-update-report-page';
import ErrorMessage from 'components/error/message';
import useGetReportPage from 'services/queries/report-page/use-get-report-page';
import _omit from 'lodash/omit';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';
import { pagesReportSchema } from './utils';
import { ReportPageBlockFormState, ReportPageFormPayload, ReportPageFormState } from 'types/models/reports';
import { reportPagesTransactionTypes } from 'utils/statics';
import SvgIcoChevronTop from 'components/core/icon/files/ico-chevron-top';
import SvgIcoChevronDown from 'components/core/icon/files/ico-chevron-down';
import SvgIcoEdit from 'components/core/icon/files/ico-edit';
import SvgIcoTrash from 'components/core/icon/files/ico-trash';

const ReportPageCreateOrUpdate = () => {
    const { reportPageId } = useParams();

    const goToParentRoute = useNavigateToParentRoute();

    const { mutate, isLoading: isLoadingCreateOrUpdate } = useCreateOrUpdateReportPage({
        id: reportPageId,
        onSuccess: goToParentRoute,
    });

    const { isLoading: isLoadingGet, data: reportPage } = useGetReportPage({
        id: reportPageId,
        onError: goToParentRoute,
    });

    const isLoading = isLoadingCreateOrUpdate || isLoadingGet;

    const isEdit = Boolean(reportPageId);

    const { control, handleSubmit, formState, watch, reset } = useForm<ReportPageFormState>({
        mode: 'onSubmit',
        resolver: yupResolver(pagesReportSchema) as any,
    });

    const wasResetCalled = useRef(false);

    const { remove, fields, update, swap, append } = useFieldArray({ name: 'blocks', control, rules: { minLength: 1 } });

    const setDefaultValue = useCallback(() => {
        if (!Boolean(reportPage) || wasResetCalled.current) {
            return;
        }

        wasResetCalled.current = true;

        const data = {
            ..._omit(reportPage, ['id', 'blocks', 'years', 'dataPageBlocks', 'dataPages']),
            years: reportPage?.years?.map(({ year }) => year),
        };

        reset(data as ReportPageFormState);

        const blocks =
            reportPage?.blocks?.map((block) => {
                const { filter } = block;

                const favoredValueIndex = Object.values(filter?.favored || {}).findIndex(Boolean);

                const favoredValues = filter?.favored ? Object.values(filter?.favored)?.[favoredValueIndex] : undefined;

                const { title, id } = favoredValues || {};

                const favored = Boolean(favoredValues?.id)
                    ? {
                          label: title,
                          value: id,
                          type: Object.keys(filter.favored || {})?.[favoredValueIndex],
                      }
                    : undefined;

                return _omit(
                    {
                        ...block,
                        filter: {
                            ...filter,
                            favored,
                        },
                    },
                    ['id']
                ) as any;
            }) || [];

        append(blocks);
    }, [reset, reportPage, append]);

    useEffect(() => {
        setDefaultValue();
    }, [setDefaultValue]);

    const [activeBlockIndex, setActiveBlockIndex] = useState<number>();

    const handleAddBlock = () => {
        setActiveBlockIndex(fields.length);
    };

    const handleDeleteBlock = (index: number) => () => remove(index);

    const handleEditBlock = (index: number) => () => setActiveBlockIndex(index);

    const handleMoveBlockUp = (index: number) => () => {
        if (index === 0) {
            return;
        }

        swap(index, index - 1);
    };

    const handleMoveBlockDown = (index: number) => () => {
        if (index === fields.length - 1) {
            return;
        }

        swap(index, index + 1);
    };

    const submit = (data: ReportPageFormState) => {
        const payload = {
            ...data,
            years: data?.years?.map((year: number) => ({
                year,
            })),
            blocks: data.blocks.map((item, index) => {
                const filter = {
                    ..._omit(item.filter, ['expenseStatus', 'transactionPaid', 'financialType']),
                    ...(Boolean(item.filter?.favored) && {
                        favored: {
                            [item.filter?.favored?.type!]: item.filter?.favored?.value,
                        },
                    }),
                };

                return {
                    ..._omit(item, 'id'),
                    order: index + 1,
                    filter,
                };
            }),
        };

        delete (payload as any)?.generated;

        mutate(payload as ReportPageFormPayload);
    };

    const isBlockFormVisible = activeBlockIndex !== undefined;
    const activeBlockData = fields?.[activeBlockIndex as number];

    const onCloseBlockForm = () => setActiveBlockIndex(undefined);

    const type = watch('type');

    const handleCreateOrUpdateBlock = (data: ReportPageBlockFormState) => {
        update(activeBlockIndex!, data);

        setActiveBlockIndex(undefined);
    };

    const blocksError = formState?.errors?.blocks?.message;

    const yearsOptions = useMemo(() => {
        const firstYear = 2014;
        const lastYear = new Date().getFullYear() + 2;

        const numberOfYears = lastYear - firstYear;

        return [...new Array(numberOfYears).keys()].map((index) => {
            const year = firstYear + index;
            return {
                value: year,
                label: year.toString(),
            };
        });
    }, []);

    return (
        <>
            <Modal contentClassnames="max-w-full w-[1200px]" onClose={goToParentRoute} closeOnClickOutside={false}>
                <form className="px-4 py-5 md:px-7" onSubmit={handleSubmit(submit)}>
                    <Text as="h3" variant="h4" className="text-heading mb-5">
                        {isEdit ? 'Atualizar' : 'Nova'} página de relatório
                    </Text>
                    <div className="flex flex-col md:grid md:grid-cols-3 gap-4 mb-6">
                        <Controller name="name" control={control} render={({ field }) => <Input {...field} label="Nome" error={formState.errors.name?.message} />} />
                        <Controller
                            name="type"
                            control={control}
                            render={({ field }) => {
                                const value = reportPagesTransactionTypes.find((item) => item.value === field.value);

                                return (
                                    <Select
                                        {...field}
                                        value={value}
                                        options={reportPagesTransactionTypes}
                                        label="Tipo"
                                        placeholder="Selecione uma opção"
                                        error={formState.errors.type?.message}
                                        onChange={(option: any) => field.onChange(option.value)}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="years"
                            control={control}
                            render={({ field }) => {
                                const value = yearsOptions.filter((item) => field?.value?.includes(item?.value));

                                return (
                                    <Select
                                        {...field}
                                        value={value}
                                        options={yearsOptions}
                                        label="Ano(s)"
                                        placeholder="Selecione"
                                        error={formState.errors.years?.message}
                                        onChange={(option: any) => {
                                            const ids = option.map((item) => item.value);

                                            field.onChange(ids);
                                        }}
                                        isMulti={true}
                                    />
                                );
                            }}
                        />
                    </div>
                    <div className="mb-6">
                        <Text as="h5" variant="h6" className="text-heading mb-3">
                            Centros de Custos
                        </Text>
                        <div className="gap-3 mb-4">
                            {Boolean(fields.length) ? (
                                <>
                                    {fields.map(({ name, id }, index) => (
                                        <div key={id} className="flex gap-3 items-center bg-base-100 border border-base-300 rounded-[14px] px-4 py-3 mb-2">
                                            <Tooltip title="Mover para cima" placement="top" onClick={handleMoveBlockUp(index)}>
                                                <IconButton size="medium" color="default" className="bg-base-300 hover:bg-system-success-100">
                                                    <SvgIcoChevronTop color={theme.extend.colors.base[500]} width={15} height={15} />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Mover para baixo" placement="top" onClick={handleMoveBlockDown(index)}>
                                                <IconButton size="medium" color="default" className="bg-base-300 hover:bg-system-danger-100">
                                                    <SvgIcoChevronDown color={theme.extend.colors.base[500]} width={15} height={15} />
                                                </IconButton>
                                            </Tooltip>
                                            <span className="text-base-500">{index + 1}.</span>
                                            <span className="flex-1 text-heading font-medium">{name}</span>
                                            <Tooltip title="Editar" placement="top" onClick={handleEditBlock(index)}>
                                                <IconButton size="large" color="default" className="bg-system-warning-100/70 hover:bg-system-warning-100">
                                                    <SvgIcoEdit color={theme.extend.colors.system.warning[900]} width={15} height={15} />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Remover" placement="top">
                                                <IconButton onClick={handleDeleteBlock(index)} size="large" color="default" className="bg-system-danger-100/70 hover:bg-system-danger-100">
                                                    <SvgIcoTrash color={theme.extend.colors.system.danger[900]} width={15} height={15} />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    ))}
                                </>
                            ) : (
                                <Empty title={type === undefined ? 'Selecione um tipo para criar um centro de custo' : 'Nenhum sócio adicionado ainda'} className="mb-2" />
                            )}
                            <ErrorMessage visible={Boolean(blocksError)}>{blocksError}</ErrorMessage>
                        </div>
                        <AddButton className="!border-0" onClick={handleAddBlock} disabled={type === undefined}>
                            Novo centro de custo
                        </AddButton>
                    </div>
                    <FormButtons isLoading={isLoading} cancelButton={{ onClick: goToParentRoute }} />
                </form>
            </Modal>
            {isBlockFormVisible && <ReportPageCreateOrUpdateBlockForm onSubmit={handleCreateOrUpdateBlock} type={type} item={activeBlockData} onClose={onCloseBlockForm} />}
        </>
    );
};

export default ReportPageCreateOrUpdate;
