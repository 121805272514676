import Text from 'components/core/text';
import { useFormContext, useWatch } from 'react-hook-form';
import { formatMoney } from 'utils/money';
import { ProductsPayload } from './index';

const TotalProducts = () => {
    const { control } = useFormContext<ProductsPayload>();

    const products = useWatch({ name: 'products', control });

    const consolidated = products.reduce((acc, curr) => (acc || 0) + (curr.total || 0), 0);

    return (
        <div className="bg-[#eefcff] py-4 px-6 text-right">
            <Text className="text-base-700">Total consolidado: </Text>
            <Text variant="body.medium.2xs" className="text-heading">
                {formatMoney(consolidated)}
            </Text>
        </div>
    );
};

export default TotalProducts;
