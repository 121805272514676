import { yupResolver } from '@hookform/resolvers/yup';
import useTheme from '@mui/material/styles/useTheme';
import Button from 'components/core/button';
import CurrencyInput from 'components/core/form/currency';
import Select from 'components/core/form/select';
import Text from 'components/core/text';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Product } from 'types/models/product';
import { formatMoney } from 'utils/money';
import { useServiceOrderContext } from './context';
import { Steps } from './types';
import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import RemoveButton from 'components/buttons/remove';
import { useLocation } from 'react-router-dom';
import DatePicker from 'components/core/datepicker';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import { excludeDates } from 'pages/private/payment-order/actions/ops-grouping/general';
import { formatPurchaseOrderProductsResponse, purchaseOrderProductsSchema } from 'pages/private/sales-orders/utils';
import useGetDebouncedProducts from 'services/queries/products/use-get-debounced-products';
import ButtonBase from '@mui/material/ButtonBase/ButtonBase';
import Alert from '@mui/material/Alert';
import { hexToRgba } from 'utils/string';
import ModalImportFile from './create-or-update/modal-import-file';
import useImportOSProducts from 'services/queries/files/use-import-os-products';
import uniqBy from 'lodash/uniqBy';
import { purchaseProductsTypeWhereClause } from './order-purchase/create-or-update/utils';
import SvgIcoArrowLeft from 'components/core/icon/files/ico-arrow-left';
import SvgIcoArrowRight from 'components/core/icon/files/ico-arrow-right';

export type ServiceOrderPurchaseScopePayload = {
    purchaseProducts: Item[];
};

type ProductsProps = {
    selectedProductsIds?: number[];
    onSubmit?: (data: ServiceOrderPurchaseScopePayload) => void;
    isSubmitting?: boolean;
};

type Item = {
    product: number;
    quantity: number;
    productValue: number;
    provision: string;
    name: string;
    code: string;
    id?: number;
};

const DEFAULT_PRODUCT = {
    quantity: 0,
    productValue: 0,
    provision: '',
};

const accept = {
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xls', '.xlsx'],
    'text/csv': ['.csv'],
};

const purchaseOrderProductsQueryFields = ['id', 'name', 'code', 'classification.name', 'classificationByClassificationUnitId.name'];

const ServiceOrderPurchaseScope = ({ selectedProductsIds = [], isSubmitting = false, onSubmit }: ProductsProps) => {
    const { palette } = useTheme();
    const { pathname } = useLocation();
    const { state, completeStep, setContent, goToStep } = useServiceOrderContext();

    const [isImportModalOpen, setIsImportModalOpen] = useState(false);

    const isSingleStep = pathname.includes('escopo-de-compra/adicionar-escopo-de-compra');

    const { mutateAsync: handleImportFile, isLoading: isImporting } = useImportOSProducts();

    const { isLoadingProducts, products, searchTerm, setSearchTerm } = useGetDebouncedProducts<Product, any[]>(purchaseOrderProductsQueryFields, formatPurchaseOrderProductsResponse, {
        ...(Boolean(selectedProductsIds?.length) && { id: { _nin: selectedProductsIds } }),
        ...purchaseProductsTypeWhereClause,
    });

    const { formState, handleSubmit, control, setValue, reset, watch, getValues } = useForm<ServiceOrderPurchaseScopePayload>({
        defaultValues: { purchaseProducts: [] },
        resolver: yupResolver(purchaseOrderProductsSchema) as any,
    });

    const { remove, append } = useFieldArray({ name: 'purchaseProducts', control });
    const selectedProducts = watch('purchaseProducts', []);

    const filteredProducts = useMemo(() => products.filter((item) => !selectedProducts.find((prod) => prod.product === item.id!)), [products, selectedProducts]);

    const handleBack = () => {
        setContent({ purchaseProducts: getValues('purchaseProducts') });
        goToStep('previous');
    };

    const handleSelectProducts = (option: any) => {
        append({
            ...DEFAULT_PRODUCT,
            product: option.id,
            code: option.code,
            name: option.name,
        });

        setSearchTerm('');
    };

    const handleRemoveProduct = (index: number) => () => {
        remove(index);
    };

    const consolidated = selectedProducts.reduce((acc, curr) => (acc || 0) + (curr.quantity || 0) * (curr.productValue || 0), 0);

    const submitButtonLabel = useMemo(() => {
        if (!isSingleStep) {
            return 'Avançar';
        }

        return isSingleStep ? 'Adicionar produto' : 'Atualizar pedido';
    }, [isSingleStep]);

    const handleWizard = (data: ServiceOrderPurchaseScopePayload) => {
        setContent(data);
        completeStep(Steps.PurchaseScope);
        goToStep('next');
    };

    const handleUploadFiles = useCallback(
        async ([file]: File[]) => {
            try {
                const data = await handleImportFile(file);

                const formattedProducts = data.map((purchaseProduct) => ({
                    name: purchaseProduct.product?.name || '',
                    code: purchaseProduct.product?.code || '',
                    productValue: purchaseProduct.value || 0,
                    quantity: purchaseProduct.quantity || 0,
                    provision: purchaseProduct.provision || '',
                    product: purchaseProduct.product?.id || 0,
                }));

                const distincted = uniqBy([...selectedProducts, ...formattedProducts], 'product');

                reset({ purchaseProducts: distincted });

                setIsImportModalOpen(false);
            } catch (error) {
                console.log('handleUploadFiles', error);
            }
        },
        [handleImportFile, reset, selectedProducts]
    );

    const submit = (data: ServiceOrderPurchaseScopePayload) => {
        if (Boolean(onSubmit)) {
            return onSubmit!(data);
        }

        return handleWizard(data);
    };

    useEffect(() => {
        if (!!state?.content?.purchaseProducts?.length) {
            reset({ purchaseProducts: state?.content?.purchaseProducts });
        }
    }, [state, reset]);

    return (
        <>
            <form onSubmit={handleSubmit(submit)}>
                <div className="p-6">
                    <Alert
                        severity="info"
                        className="mb-6"
                        action={
                            <ButtonBase
                                sx={{ ':hover': { backgroundColor: hexToRgba(palette.info[100], 0.5) } }}
                                className="py-2 px-3 rounded-[10px] mb-[2px]"
                                onClick={setIsImportModalOpen.bind(this, true)}>
                                Importar arquivo
                            </ButtonBase>
                        }>
                        Você pode importar um arquivo para auxiliar na criação dos produtos do escopo de compra
                    </Alert>
                    <Select
                        value={searchTerm}
                        options={!!searchTerm ? filteredProducts : []}
                        isLoading={isLoadingProducts}
                        label="Produto (código ou nome)"
                        placeholder="Digite o código ou nome do produto"
                        components={!Boolean(searchTerm) ? { NoOptionsMessage: () => null } : undefined}
                        onChange={handleSelectProducts}
                        onInputChange={setSearchTerm}
                        parentClassName="flex-1"
                    />
                    {Boolean(selectedProducts.length) && (
                        <TableContainer className="mt-6">
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Text className="text-base-500 uppercase">Produto</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text className="text-base-500 uppercase">Valor por unidade</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text className="text-base-500 uppercase">Quantidade</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text className="text-base-500 uppercase">Previsão</Text>
                                        </TableCell>
                                        <TableCell className="w-[160px]">
                                            <Text className="text-base-500 uppercase">Total</Text>
                                        </TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {selectedProducts.map((item, index) => {
                                        return (
                                            <TableRow key={item.code}>
                                                <TableCell>
                                                    <Text className="text-heading">
                                                        #{item.code} - {item.name}
                                                    </Text>
                                                </TableCell>
                                                <TableCell>
                                                    <Controller
                                                        name={`purchaseProducts.${index}.productValue`}
                                                        control={control}
                                                        render={({ field }) => {
                                                            return (
                                                                <CurrencyInput
                                                                    value={field.value}
                                                                    decimalSeparator=","
                                                                    thousandSeparator="."
                                                                    withFormat={false}
                                                                    fixedDecimalScale={true}
                                                                    decimalScale={4}
                                                                    placeholder="Ex: 1000"
                                                                    parentClassName="w-[120px]"
                                                                    error={formState.errors.purchaseProducts?.[index]?.quantity?.message}
                                                                    left={
                                                                        <Text as="span" variant="body.medium.sm" className="text-heading">
                                                                            R$
                                                                        </Text>
                                                                    }
                                                                    leftClasses="bg-base-200 px-4"
                                                                    onValueChange={({ floatValue }) => field.onChange(floatValue || 0)}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Controller
                                                        name={`purchaseProducts.${index}.quantity`}
                                                        control={control}
                                                        render={({ field }) => {
                                                            return (
                                                                <CurrencyInput
                                                                    value={field.value}
                                                                    onValueChange={({ floatValue }) => setValue(`purchaseProducts.${index}.quantity`, floatValue || 0)}
                                                                    placeholder="Ex: 1000"
                                                                    parentClassName="w-[120px]"
                                                                    error={formState.errors.purchaseProducts?.[index]?.quantity?.message}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Controller
                                                        name={`purchaseProducts.${index}.provision`}
                                                        control={control}
                                                        render={({ field }) => {
                                                            return (
                                                                <DatePicker
                                                                    containerClassNames="w-[300px]"
                                                                    inputProps={{
                                                                        placeholderText: 'Selecione a data de previsão',
                                                                        ...field,
                                                                    }}
                                                                    muiDatePickerProps={{
                                                                        shouldDisableDate: excludeDates,
                                                                    }}
                                                                    withProvisionLogic={true}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Text variant="h6" className="text-heading">
                                                        {formatMoney(item.quantity * item.productValue)}
                                                    </Text>
                                                </TableCell>
                                                <TableCell>{<RemoveButton className="!static opacity-100 mt-0" onClick={handleRemoveProduct(index)} />}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </div>
                {Boolean(selectedProducts.length) && (
                    <div className="bg-secondary-100 py-4 px-6 bg-opacity-20 text-right">
                        <Text className="text-base-700">Total consolidado: </Text>
                        <Text variant="body.medium.2xs" className="text-heading">
                            {formatMoney(consolidated)}
                        </Text>
                    </div>
                )}
                <div className="border-t p-2 flex justify-between">
                    {isSingleStep ? (
                        <div />
                    ) : (
                        <Button startIcon={<SvgIcoArrowLeft width={18} color={palette.grey[700]} />} type="button" variant="text" color="inherit" className="min-w-[100px]" onClick={handleBack}>
                            Voltar
                        </Button>
                    )}
                    <Button
                        disabled={!formState.isValid}
                        loading={isSubmitting}
                        endIcon={<SvgIcoArrowRight width={18} color={!formState.isValid ? palette.grey[400] : palette.secondary[500]} />}
                        type="submit"
                        variant="text"
                        color={!formState.isValid ? 'inherit' : 'secondary'}
                        className="min-w-[100px]">
                        {submitButtonLabel}
                    </Button>
                </div>
            </form>
            {isImportModalOpen && <ModalImportFile accept={accept} isLoading={isImporting} onClose={setIsImportModalOpen.bind(this, false)} onUploadFiles={handleUploadFiles} />}
        </>
    );
};

export default ServiceOrderPurchaseScope;
