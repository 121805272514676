import Text from '../text';
import { NavLink, useLocation } from 'react-router-dom';
import useBreadcrumb from 'store/breadcrumb/use-breadcrumb';
import classNames from 'classnames';

type BreadcrumbProps = {
    className?: string;
};

const Breadcrumb = ({ className }: BreadcrumbProps) => {
    const { pathname } = useLocation();
    const { breadcrumbs } = useBreadcrumb();

    const containerClasses = classNames('flex items-baseline flex-wrap', className);

    return (
        <div className="sm:flex sm:items-baseline">
            <Text as="p" variant="body.regular.xs" className="text-base-500 italic mr-2 whitespace-nowrap">
                Você está em:
            </Text>
            <ul className={containerClasses}>
                {breadcrumbs.map(({ breadcrumb, match }, index) => {
                    return (
                        <li key={match.pathname}>
                            {pathname === match.pathname ? (
                                <Text as="span" variant="body.medium.2xs" className="text-base-500">
                                    {breadcrumb}
                                </Text>
                            ) : (
                                <NavLink to={match.pathname} className="text-secondary-500 font-semibold text-2xs hover:text-secondary-700 transition-[color]">
                                    {breadcrumb}
                                </NavLink>
                            )}
                            {index !== breadcrumbs.length - 1 && (
                                <Text as="span" className="mx-2 text-base-500" variant="body.regular.xs">
                                    {'>'}
                                </Text>
                            )}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default Breadcrumb;
