import Datatable from 'components/core/table/datatable';
import ScheduleHeader from './header';
import { schedulesColumns } from './utils';
import useGetSchedules from 'services/queries/schedules/use-get-schedules';
import { useState } from 'react';
import qs from 'qs';
import useGetQueryParam from 'hooks/router/use-get-query-param';
import { Outlet, useLocation } from 'react-router-dom';
import useConfig from 'store/config/use-config';

type SchedulePageProps = {
    title: string;
};
const SchedulePage = ({ title }: SchedulePageProps) => {
    const { config } = useConfig();
    const { search } = useLocation();

    const [page, setPage] = useState(1);

    const queryFiltersParsed = qs.parse(search, { ignoreQueryPrefix: true }) as any;

    const endDate = useGetQueryParam('filter[endDate]');
    const startDate = useGetQueryParam('filter[startDate]');

    const queryFilters = qs.stringify(
        {
            filter: {
                startDate,
                endDate,
                ...(Boolean(queryFiltersParsed.filter?.status) && { status: queryFiltersParsed.filter?.status }),
            },
            page,
            totalPerPage: config.tables.rowsPerPage,
        },
        { addQueryPrefix: true, encode: false, arrayFormat: 'brackets', skipNulls: true }
    );

    const { data: schedules, isLoading: isLoadingSchedules } = useGetSchedules(queryFilters);

    return (
        <>
            <ScheduleHeader title={title} />
            <Datatable
                data={schedules?.items || []}
                columns={schedulesColumns}
                loading={isLoadingSchedules}
                options={{
                    sort: true,
                    filter: false,
                    search: true,
                    print: false,
                    download: false,
                    viewColumns: false,
                    serverSide: true,
                    searchAlwaysOpen: true,
                    enableNestedDataAccess: '.',
                    searchPlaceholder: 'Buscar Agendamento',
                    pagination: true,
                    elevation: 21,
                    count: schedules?.total,
                    page,
                    selectableRowsHideCheckboxes: true,
                    onChangePage: setPage,
                }}
            />
            <Outlet context={{ queryFilters }} />
        </>
    );
};

export default SchedulePage;
