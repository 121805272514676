import { literalByTaxRegime } from 'settings/routes/companies/utils';
import { CompanyTaxRegime } from 'types/models/company';

export const companyTaxRegimeOptions = [
    {
        label: literalByTaxRegime[CompanyTaxRegime.Simple],
        value: CompanyTaxRegime.Simple
    },
    {
        label: literalByTaxRegime[CompanyTaxRegime.PresumedProfit],
        value: CompanyTaxRegime.PresumedProfit
    },
    {
        label: literalByTaxRegime[CompanyTaxRegime.RealProfit],
        value: CompanyTaxRegime.RealProfit
    }
];
