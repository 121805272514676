import IconButton from '@mui/material/IconButton/IconButton';
import useTheme from '@mui/material/styles/useTheme';
import SvgIcoDots from 'components/core/icon/files/ico-dots';
import Menu, { MenuItem } from 'components/menu';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SaleOrderScheduleStatus } from 'types/models/sale-order';

type ScheduleActionsProps = {
    scheduleId: number;
    scheduleStatus: SaleOrderScheduleStatus;
};

const ScheduleActions = ({ scheduleId, scheduleStatus }: ScheduleActionsProps) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const navigate = useNavigate();

    const handleClose = () => setAnchorEl(null);
    const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

    const handleClickAction = (url: string) => {
        handleClose();
        navigate(url);
    };

    const { palette } = useTheme();

    const voucher = {
        label: 'Voucher',
        icon: {
            color: palette.grey[500],
            name: 'ico-discount',
        },
        onClick: () => {
            handleClose();
            alert('Em desenvolvimento.');
        },
    };

    const scheduleActionsContent: MenuItem[] = [
        ...(scheduleStatus === SaleOrderScheduleStatus.Requested
            ? [
                  {
                      label: 'Informar Retirada',
                      icon: {
                          color: palette.grey[500],
                          name: 'ico-send-outline',
                      },
                      onClick: handleClickAction.bind(this, `${scheduleId}/status/${SaleOrderScheduleStatus.Delivered}`),
                  },
                  {
                      label: 'Reprogramar',
                      icon: {
                          color: palette.grey[500],
                          name: 'ico-clock',
                      },
                      onClick: handleClickAction.bind(this, `${scheduleId}/status/${SaleOrderScheduleStatus.Rescheduled}`),
                  },
                  {
                      label: 'Cancelar',
                      icon: {
                          color: palette.grey[500],
                          name: 'ico-close-circle',
                      },
                      onClick: handleClickAction.bind(this, `${scheduleId}/status/${SaleOrderScheduleStatus.Canceled}`),
                  },
                  voucher,
              ]
            : [voucher]),
    ];

    return (
        <>
            <IconButton onClick={handleOpen}>
                <SvgIcoDots width={16} height={16} color={palette.grey[500]} />
            </IconButton>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} content={scheduleActionsContent} />
        </>
    );
};

export default ScheduleActions;
