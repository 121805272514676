import { lazy, Suspense } from 'react';
import { BranchCity } from 'types/models/sales-notes';
import { useReportsScreen } from '../../hooks/use-reports-screen';
import DREHeader from './header/index';
import Spinner from 'components/core/spinner';

type ReportDREPageProps = {
    title: string;
    city?: BranchCity;
};

const DREContent = lazy(() => import('./content'));

const ReportDREPage = ({ title, city }: ReportDREPageProps) => {
    const { year, fontSize, setYear, setFontSize } = useReportsScreen();

    return (
        <>
            <DREHeader title={title} {...{ year, setYear, setFontSize }} />
            <Suspense fallback={<Spinner fixed={false} parentClasses="p-6" />}>
                <DREContent fontSize={fontSize} year={year} city={city} />
            </Suspense>
        </>
    );
};

export default ReportDREPage;
