import { ReportsContextProvider } from '../../context';
import { ProductsReportContentFiltersParams } from '../../hooks/use-get-data-content-filters';
import ReportProductsContent from './content';

type ReportProductsPageProps = {
    endpoint: string;
    filtersContentConfig: ProductsReportContentFiltersParams;
    title: string;
};

const ReportProductsPage = (props: ReportProductsPageProps) => {
    return (
        <ReportsContextProvider>
            <ReportProductsContent {...props} />
        </ReportsContextProvider>
    );
};

export default ReportProductsPage;
