import * as React from 'react';
const SvgIcoSettings = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M.31 4.772C-.429 5.983.275 7.235 1.124 7.7l.174.095L1.294 8l.005.205-.174.096c-.849.464-1.553 1.716-.816 2.928l.984 1.616c.688 1.13 2.029 1.18 2.848.731l.159-.087c.197.128.402.247.612.357v.03c0 1.087.858 2.124 2.104 2.124h1.968c1.246 0 2.104-1.037 2.104-2.123v-.03c.21-.11.415-.23.613-.358l.158.087c.82.45 2.16.398 2.848-.731l.984-1.616c.737-1.212.033-2.464-.816-2.928l-.175-.096.006-.205-.005-.205.174-.095c.849-.465 1.553-1.717.816-2.928l-.984-1.616c-.688-1.13-2.029-1.18-2.848-.732l-.159.087a7.747 7.747 0 0 0-.612-.357v-.03C11.088 1.037 10.23 0 8.984 0H7.016C5.77 0 4.912 1.037 4.912 2.124v.03c-.21.11-.415.23-.612.357l-.159-.087c-.82-.448-2.16-.398-2.848.732L.31 4.772Zm4.154-.144L3.29 3.984c-.175-.095-.39-.052-.48.097l-.984 1.616c-.091.15-.023.347.15.443l1.242.679a4.795 4.795 0 0 0 0 2.362l-1.241.68c-.174.096-.242.293-.151.442l.984 1.616c.09.15.305.192.48.097l1.174-.643a4.945 4.945 0 0 0 2.224 1.294v1.21c0 .19.147.345.328.345h1.968a.337.337 0 0 0 .328-.345v-1.21a4.945 4.945 0 0 0 2.224-1.294l1.174.643c.174.095.39.053.48-.097l.984-1.616c.091-.15.023-.346-.15-.442l-1.242-.68a4.795 4.795 0 0 0 0-2.362l1.241-.68c.174-.095.242-.293.151-.442l-.984-1.616c-.09-.15-.306-.192-.48-.097l-1.174.644a4.935 4.935 0 0 0-2.224-1.294v-1.21a.337.337 0 0 0-.328-.346H7.016a.337.337 0 0 0-.328.346v1.21a4.936 4.936 0 0 0-2.224 1.294Zm3.533 6.039A2.665 2.665 0 0 0 10.66 8a2.665 2.665 0 0 0-2.663-2.667A2.665 2.665 0 0 0 5.333 8a2.665 2.665 0 0 0 2.664 2.667ZM9 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
            clipRule="evenodd"
        />
    </svg>
);
export default SvgIcoSettings;
