import { originOptions, paidStatuses, receiptClassificationTypes } from 'utils/statics';
import { Controller, UseFormReturn } from 'react-hook-form';
import Select from 'components/core/form/select';
import useGetCompaniesOptions from 'services/queries/companies/use-get-company-options';
import useGetClassifications from 'services/queries/classifications/use-get-classifications';
import { generateClassificationQuery } from 'services/queries/classifications/utils';
import useGetCustomerOptions from 'services/queries/customers/use-get-customer-options';
import { generateCustomerOptionsQuery } from 'services/queries/customers/utils';
import { ClassificationType } from 'types/models/classification';
import useGetOptions from 'services/queries/crud/use-get-options';
import { generateGetBranchOptionsQuery } from 'settings/routes/branches/utils';

type Props = {
    formMethods: UseFormReturn<any, any>;
    path?: string;
};

const ReportPageCreateOrUpdateBlockFormIncome = ({ formMethods, path }: Props) => {
    const { control } = formMethods;

    const getFieldName = (name: string) => (Boolean(path) ? `${path}.${name}` : name);

    const companies = useGetCompaniesOptions();
    const costumers = useGetCustomerOptions(generateCustomerOptionsQuery());
    const classificationTypesOptions = useGetClassifications(generateClassificationQuery(ClassificationType.SaleOrderType));
    const { data: branches = [], isLoading: isLoadingBranches } = useGetOptions(generateGetBranchOptionsQuery(), ['branch', 'options']);

    return (
        <>
            <Controller
                name={getFieldName('customer')}
                control={control}
                render={({ field }) => {
                    const value = costumers?.data?.find((item) => item.value === +field.value);

                    return (
                        <Select
                            {...field}
                            value={value}
                            options={costumers?.data || []}
                            label="Cliente"
                            placeholder="Selecione uma opção"
                            onChange={(option: any) => field.onChange(option.value)}
                            isLoading={costumers?.isLoading}
                            isClearable={true}
                        />
                    );
                }}
            />

            <Controller
                name={getFieldName('companies')}
                control={control}
                render={({ field }) => {
                    const value = companies?.data?.filter((item) => field?.value?.includes(item?.value));

                    return (
                        <Select
                            {...field}
                            value={value}
                            options={companies?.data}
                            label="Empresa"
                            placeholder="Selecione"
                            onChange={(option: any) => {
                                const ids = option.map((item) => item.value);

                                field.onChange(ids);
                            }}
                            isLoading={companies?.isLoading}
                            isMulti={true}
                            isClearable={true}
                        />
                    );
                }}
            />

            <Controller
                name={getFieldName('saleOrderOrigin')}
                control={control}
                render={({ field }) => {
                    const value = originOptions.find((item) => item.value === field.value);

                    return (
                        <Select
                            {...field}
                            value={value}
                            options={originOptions}
                            label="Origem"
                            placeholder="Selecione uma opção"
                            onChange={(option: any) => field.onChange(option.value)}
                            isClearable={true}
                            isMulti={false}
                        />
                    );
                }}
            />

            <Controller
                name={getFieldName('saleOrderClassification')}
                control={control}
                render={({ field }) => {
                    const value = classificationTypesOptions?.data?.filter((item) => field?.value?.includes(item?.value));

                    return (
                        <Select
                            {...field}
                            value={value}
                            options={classificationTypesOptions?.data}
                            label="Tipo"
                            placeholder="Selecione"
                            onChange={(option: any) => {
                                const ids = option.map((item) => item.value);

                                field.onChange(ids);
                            }}
                            isLoading={classificationTypesOptions?.isLoading}
                            isMulti={true}
                            isClearable={true}
                        />
                    );
                }}
            />

            <Controller
                name={getFieldName('saleOrderType')}
                control={control}
                render={({ field }) => {
                    const value = receiptClassificationTypes.filter((item) => field?.value?.includes(item?.value));

                    return (
                        <Select
                            {...field}
                            value={value}
                            options={receiptClassificationTypes}
                            label="Tipos de Projetos"
                            placeholder="Selecione"
                            onChange={(option: any) => {
                                const ids = option.map((item) => item.value);

                                field.onChange(ids);
                            }}
                            isClearable={true}
                            isMulti={true}
                        />
                    );
                }}
            />

            <Controller
                control={control}
                name={getFieldName('branch')}
                render={({ field }) => {
                    const value = branches.find((company) => company?.value === field?.value);

                    return (
                        <Select
                            options={branches}
                            value={value}
                            isLoading={isLoadingBranches}
                            isClearable={true}
                            placeholder="Selecione uma filial"
                            label="Filial"
                            onChange={(option: any) => field.onChange(option.value)}
                        />
                    );
                }}
            />
            <Controller
                name={getFieldName('paid')}
                control={control}
                render={({ field }) => {
                    const value = paidStatuses.find((item) => item.value === field.value);

                    return (
                        <Select
                            {...field}
                            value={value || null}
                            options={paidStatuses}
                            label="Status"
                            placeholder="Selecione uma opção"
                            onChange={(option: any) => field.onChange(!!option ? option.value : null)}
                            isClearable={true}
                        />
                    );
                }}
            />
        </>
    );
};

export default ReportPageCreateOrUpdateBlockFormIncome;
