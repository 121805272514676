import Datatable from 'components/core/table/datatable';
import useConfig from 'store/config/use-config';
import { Transaction, TransactionType } from 'types/models/transaction';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import { monthReportOPsColumns } from './utils';
import TableFooter from '@mui/material/TableFooter/TableFooter';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import { formatMoney } from 'utils/money';
import Button from 'components/core/button';
import QueryString from 'qs';

type SortParams = {
    [key: string]: 'desc' | 'asc';
};

type MonthReportDetailsOPsTabProps = {
    isLoading: boolean;
    queryParams: any;
    reportPageType: TransactionType;
    data: Transaction[];
    totalItems: number;
    totalOrders: number;
    onSort: (params: SortParams) => void;
    onChangePage: (nextPage: number) => void;
};

const MonthReportDetailsOPsTab = ({ isLoading, totalItems, totalOrders = 0, data = [], queryParams, reportPageType, onSort, onChangePage }: MonthReportDetailsOPsTabProps) => {
    const { config } = useConfig();

    const handleSort = (changedColumn: string, direction: 'desc' | 'asc') => onSort({ [changedColumn]: direction });

    const handleTestFilters = () => {
        const query = QueryString.stringify(queryParams.filters, {
            addQueryPrefix: true,
            encode: false,
            arrayFormat: 'brackets',
            skipNulls: true,
        });

        const path = reportPageType === TransactionType.Income ? '/app/ordens-de-recebimento' : '/app/ordens-de-pagamento';

        return window.open(path + query, '_blank');
    };

    return (
        <TableContainer>
            <Datatable
                data={data}
                columns={monthReportOPsColumns}
                loading={isLoading}
                options={{
                    count: totalItems,
                    elevation: 0,
                    enableNestedDataAccess: '.',
                    filter: false,
                    searchOpen: false,
                    pagination: true,
                    download: false,
                    print: false,
                    viewColumns: false,
                    rowsPerPage: config.tables.rowsPerPage,
                    page: queryParams.filters.page,
                    sort: true,
                    search: false,
                    serverSide: true,
                    onColumnSortChange: handleSort,
                    onChangePage,
                    customTableBodyFooterRender: () => {
                        return (
                            <TableFooter className="border-t border-base-300">
                                <TableRow className="bg-base-300">
                                    <TableCell className="border-none !text-black font-semibold text-opacity-[0.87] !text-[0.875rem]">TOTAL</TableCell>
                                    <TableCell className="border-none !text-black text-opacity-[0.87] !text-[0.875rem]">
                                        <strong>{formatMoney(totalOrders)}</strong>
                                    </TableCell>
                                    <TableCell className="border-none !text-black text-opacity-[0.87] !text-[0.875rem]" colSpan={6}>
                                        <Button size="small" variant="contained" onClick={handleTestFilters} type="button">
                                            Testar filtros
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        );
                    },
                }}
                className="toolbar-without-search"
            />
        </TableContainer>
    );
};

export default MonthReportDetailsOPsTab;
