import Input from 'components/core/form/input';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FormPayload } from 'services/queries/forms/use-create-or-update-form';
import get from 'lodash/get';
import { booleanOptions, formFieldTypes } from 'utils/statics';
import Select from 'components/core/form/select';
import { FormFieldType } from 'types/models/form';
import FormItemSubfieldOptions from './item-subfield-options';
import RemoveButton from 'components/buttons/remove';

type FormItemSubfieldProps = {
    subfieldsPath: `groups.${number}.fields.${number}.options.${number}.subfields`;
    subfieldIndex: number;
    onRemoveSubfield: (subfieldIndex: number) => void;
};

const fieldsWithOptions = [FormFieldType.Checkbox, FormFieldType.Radio, FormFieldType.Select, FormFieldType.SelectSimple, FormFieldType.CheckboxWithField, FormFieldType.RadioWithField];

const FormItemSubfield = ({ subfieldIndex, subfieldsPath, onRemoveSubfield }: FormItemSubfieldProps) => {
    const { control, formState, setValue } = useFormContext<FormPayload>();

    const getErrorSubfield = (field: string) => get(formState.errors, `${subfieldsPath}.${subfieldIndex}.${field}.message`);

    const handleSelectSubfieldType = (subfieldIndex: number) => (option) => {
        setValue(`${subfieldsPath}.${subfieldIndex}.type`, option?.value);

        const hasOptions = fieldsWithOptions.includes(option.value);

        setValue(`${subfieldsPath}.${subfieldIndex}.options`, hasOptions ? [{ name: '' }] : undefined);
    };

    const subfieldType = useWatch({ control, name: `${subfieldsPath}.${subfieldIndex}.type` });

    const hasOptions = !!subfieldType ? fieldsWithOptions.includes(subfieldType) : false;

    return (
        <>
            <div className="flex flex-row items-end gap-4 p-4">
                <Controller
                    control={control}
                    defaultValue=""
                    name={`${subfieldsPath}.${subfieldIndex}.name`}
                    render={({ field }) => {
                        return <Input {...field} label="Nome" parentClassName="flex-1" error={getErrorSubfield('name')} />;
                    }}
                />
                <Controller
                    control={control}
                    name={`${subfieldsPath}.${subfieldIndex}.type`}
                    render={({ field }) => {
                        const value = formFieldTypes.find((item) => item.value === field.value);

                        return (
                            <Select
                                {...field}
                                value={value}
                                options={formFieldTypes}
                                label="Tipo"
                                parentClassName="w-[255px]"
                                error={getErrorSubfield('type')}
                                onChange={handleSelectSubfieldType(subfieldIndex)}
                            />
                        );
                    }}
                />
                <Controller
                    control={control}
                    name={`${subfieldsPath}.${subfieldIndex}.required`}
                    render={({ field }) => {
                        const value = booleanOptions.find((item) => item.value === field.value);

                        return (
                            <Select
                                {...field}
                                value={value}
                                options={booleanOptions}
                                label="Campo obrigatório?"
                                parentClassName="w-[142px]"
                                error={getErrorSubfield('required')}
                                onChange={(option: any) => field.onChange(option.value)}
                            />
                        );
                    }}
                />
                {!!subfieldIndex && <RemoveButton className="!static !m-0 opacity-100 mb-[7px]" onClick={onRemoveSubfield.bind(this, subfieldIndex)} />}
            </div>
            {hasOptions && <FormItemSubfieldOptions subfieldIndex={subfieldIndex} subfieldsPath={subfieldsPath} />}
        </>
    );
};

export default FormItemSubfield;
