import ContributionsPage from 'pages/private/contributions';
import Page from 'pages/shared/page';
import { RouteObject } from 'react-router-dom';
import { Rule } from 'types/permissions';

const contributions: RouteObject[] = [
    {
        // @ts-ignore
        breadcrumb: 'Aportes',
        path: 'aportes',
        element: <Page Component={ContributionsPage} code="financeiro-aportes" permissions={[Rule.List]} />,
    },
];

export default contributions;
