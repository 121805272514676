import Modal from 'components/core/modal';
import Text from 'components/core/text';
import Table from '@mui/material/Table/Table';
import TableRow from '@mui/material/TableRow/TableRow';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import { GetOrderServicesProductsCategoriesExpense } from 'services/queries/order-services/use-get-order-services-products-categories';
import { formatNumberToPTBR } from 'utils/money';
import { useEffect, useState } from 'react';
import useGetClassifications from 'services/queries/classifications/use-get-classifications';
import { generateClassificationQuery } from 'services/queries/classifications/utils';
import { ClassificationType } from 'types/models/classification';
import Select from 'components/core/form/select';
import useUpdateOrderServicesProductsCategories from 'services/queries/order-services/use-update-order-services-products-categories';
import { Link, useParams } from 'react-router-dom';
import Spinner from 'components/core/spinner';
import FormButtons from 'components/form-buttons';

type UpdateSubCategoryProps = {
    onClose: () => void;
    selectedItems: GetOrderServicesProductsCategoriesExpense[];
};

const UpdateSubCategory = ({ onClose, selectedItems = [] }: UpdateSubCategoryProps) => {
    const { orderServiceId, saleOrderId } = useParams();

    const [formState, setFormState] = useState<{ classification: number; id: number }[]>([]);

    const { data: classificationsOptions, isLoading: isLoadingClassifications } = useGetClassifications(generateClassificationQuery(ClassificationType.ProductSubCategory));

    const { isLoading: isLoadingUpdate, mutate } = useUpdateOrderServicesProductsCategories({ orderServiceId, saleOrderId, onSucess: onClose });

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        mutate(formState);
    };

    const handleChangeClassification = ({ classification, index }: { classification: number; index: number }) => {
        if (!classification) {
            return;
        }

        const updatedState = [...formState];

        updatedState[index].classification = classification;

        setFormState(updatedState);
    };

    const isLoading = isLoadingUpdate || isLoadingClassifications;

    useEffect(() => {
        setFormState(
            selectedItems?.map(({ id, purchaseOrderProduct }) => ({
                classification: purchaseOrderProduct?.classification?.id,
                id,
            })) || []
        );
    }, [selectedItems]);

    return (
        <>
            {isLoading && <Spinner fixed={true} />}
            <Modal
                contentClassnames="w-[800px]"
                headerLeft={
                    <Text as="h3" variant="h4" className="text-heading">
                        Editar subcategorias
                    </Text>
                }
                closeOnClickOutside={false}
                onClose={onClose}>
                <form className="p-6 pt-0" onSubmit={onSubmit}>
                    <Table className="mb-6">
                        <TableHead className="border-b">
                            <TableRow>
                                <TableCell>
                                    <Text as="span" className="text-base-500">
                                        Produto
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text as="span" className="text-base-500">
                                        Subcategoria
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text as="span" className="text-base-500">
                                        Despesa
                                    </Text>
                                </TableCell>

                                <TableCell>
                                    <Text as="span" className="text-base-500">
                                        Quantidade
                                    </Text>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {selectedItems?.map((item, index) => {
                                const value = classificationsOptions?.find((item) => item?.value === formState?.[index]?.classification);

                                return (
                                    <TableRow key={`${item.id}_${index}`} classes={{ root: 'hover:bg-base-200 hover:bg-opacity-50' }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell>
                                            <Text as="span" className="text-base-500">
                                                {item?.product?.name || '-'}
                                            </Text>
                                        </TableCell>
                                        <TableCell>
                                            <Select
                                                isLoading={isLoadingClassifications}
                                                name="classification"
                                                value={value}
                                                options={classificationsOptions}
                                                label="Subcategoria"
                                                placeholder="Selecione uma opção"
                                                onChange={(option: any) => handleChangeClassification({ index, classification: option?.value })}
                                                isClearable={false}
                                                parentClassName="mb-4 min-w-[240px]"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Link to={`/app/despesas/${item?.expense?.id}`} target="_blank">
                                                <Text as="span" className="text-secondary-700 font-medium">
                                                    {item?.expense?.code || '-'}
                                                </Text>
                                            </Link>
                                        </TableCell>
                                        <TableCell>
                                            <Text as="span" className="text-base-500">
                                                {formatNumberToPTBR(item.quantity)}
                                            </Text>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                    <FormButtons isLoading={isLoading} />
                </form>
            </Modal>
        </>
    );
};

export default UpdateSubCategory;
