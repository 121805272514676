import Button from 'components/core/button';
import Select from 'components/core/form/select';
import { useEffect, useMemo } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { Steps } from '../../context/types';
import isEmpty from 'lodash/isEmpty';
import { Product } from 'types/models/product';
import { useImportFlowContext } from '../../context';
import { useTheme } from '@mui/material/styles';
import useGetDebouncedProducts from 'services/queries/products/use-get-debounced-products';
import { Option } from 'types/general';
import ProductsTable from './table';
import { yupResolver } from '@hookform/resolvers/yup';
import { importFlowProductsTabSchema } from '../utils';
import SvgIcoArrowLeft from 'components/core/icon/files/ico-arrow-left';
import SvgIcoArrowRight from 'components/core/icon/files/ico-arrow-right';

const transformer = (data: Partial<Pick<Product, 'id' | 'name' | 'code'>>[]) => data.map<Option>((item) => ({ value: item.id!, label: `#${item.code} - ${item.name}`! }));

const Products = () => {
    const { palette } = useTheme();
    const { goToStep, state, completeStep, setContent } = useImportFlowContext();

    const { isLoadingProducts, products, searchTerm, setSearchTerm } = useGetDebouncedProducts<Pick<Product, 'id' | 'name' | 'code'>, Option[]>(['id', 'name', 'code'], transformer);

    const methods = useForm<any>({
        mode: 'all',
        reValidateMode: 'onChange',
        criteriaMode: 'all',
        resolver: yupResolver(importFlowProductsTabSchema),
        defaultValues: { products: [] },
    });

    const { formState, handleSubmit, control, reset, getValues } = methods;

    const { append, remove, fields: selectedProducts } = useFieldArray({ name: 'products', control });

    const filteredProducts = useMemo(() => products.filter((item) => !selectedProducts.find((prod: any) => prod.product === item.value!)), [products, selectedProducts]);

    const handleBack = () => {
        setContent({ products: getValues('products') });
        goToStep('previous');
    };

    const handleSelectProducts = (option: any) => {
        append({
            name: option.label,
            product: option.value,
            quantity: 0,
            baseValue: 0,
            complementaryValue: 0,
        });

        setSearchTerm('');
    };

    const handleWizard = (products: any[]) => {
        setContent({ products });
        completeStep(Steps.Products);
        goToStep('next');
    };

    const submit = (data: any) => {
        const hasNoErrors = isEmpty(formState.errors);

        if (!hasNoErrors) {
            return;
        }

        return handleWizard(data.products);
    };

    useEffect(() => {
        if (!!state.content?.products?.length) {
            reset({ products: state.content.products || [] });
        }
    }, [state, reset]);

    return (
        <form onSubmit={handleSubmit(submit)}>
            <FormProvider {...methods}>
                <div className="p-6">
                    <div className="mb-6">
                        <Select
                            value={searchTerm}
                            options={!!searchTerm ? filteredProducts : []}
                            isLoading={isLoadingProducts}
                            label="Produto (código ou nome)"
                            placeholder="Digite o código ou nome do produto"
                            components={!Boolean(searchTerm) ? { NoOptionsMessage: () => null } : undefined}
                            onChange={handleSelectProducts}
                            onInputChange={setSearchTerm}
                            error={(formState.errors as any)?.products?.message}
                        />
                    </div>
                    {Boolean(selectedProducts.length) && <ProductsTable selectedProducts={selectedProducts} onRemoveProduct={remove} />}
                </div>
                <div className="border-t p-2 flex justify-between">
                    <Button startIcon={<SvgIcoArrowLeft width={18} color={palette.grey[700]} />} type="button" variant="text" color="inherit" className="min-w-[100px]" onClick={handleBack}>
                        Voltar
                    </Button>
                    <Button endIcon={<SvgIcoArrowRight width={18} color={palette.secondary[500]} />} type="submit" variant="text" className="min-w-[100px]" color="secondary">
                        Avançar
                    </Button>
                </div>
            </FormProvider>
        </form>
    );
};

export default Products;
