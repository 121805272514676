import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from '@mui/material/TableRow/TableRow';
import DatePicker from 'components/core/datepicker';
import Checkbox from 'components/core/form/checkbox';
import Text from 'components/core/text';
import { Controller, useFormContext } from 'react-hook-form';
import { SaleScopeItemPayload, ServiceOrderSaleScopePayload } from './sale-scope';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import { formatMoney, formatNumberToPTBR } from 'utils/money';
import CurrencyInput from 'components/core/form/currency';
import tooltipClasses from '@mui/material/Tooltip/tooltipClasses';
import { useQueryClient } from 'react-query';
import { getOrderServiceKey } from 'services/queries/order-services/keys';
import { useParams } from 'react-router-dom';
import { OrderService, OrderServiceStatusEnum } from 'types/models/order-service';

type SaleScopeItemProps = {
    index: number;
    isOrderServiceUpdate?: boolean;
} & Partial<SaleScopeItemPayload>;

const SaleScopeItem = ({ product, index, isRegisteredSaleProduct = false, actualQuantity = 0, isOrderServiceUpdate = false }: SaleScopeItemProps) => {
    const { orderServiceId } = useParams();

    const { control, formState, watch, setValue, setError, clearErrors, trigger, resetField } = useFormContext<ServiceOrderSaleScopePayload>();

    const queryClient = useQueryClient();
    const orderService = queryClient.getQueryData<Partial<OrderService>>(getOrderServiceKey(orderServiceId));
    const isOrderServiceFinished = orderService?.orderServiceStatus?.slug === OrderServiceStatusEnum.Finished;

    const getPath = (key: keyof SaleScopeItemPayload) => `saleProducts.${index}.${key}` as any;

    const [isChecked, balance, value, quantity] = watch([getPath('checked'), getPath('balance'), getPath('value'), getPath('quantity')]);

    const handleToggleCheck = async () => {
        setValue(`saleProducts.${index}.checked`, !isChecked);

        resetField(`saleProducts.${index}.quantity`);
        resetField(`saleProducts.${index}.predictedStartDate`);
        resetField(`saleProducts.${index}.predictedEndDate`);

        if (isChecked) {
            await trigger(`saleProducts.${index}`);
        }
    };

    const handleChangeQuantity = (onChange: (...event: any[]) => void) => {
        return ({ floatValue = 0 }) => {
            onChange(floatValue);

            const message = { message: 'Quantidade maior que saldo disponível' };

            if (isOrderServiceUpdate) {
                const maximumQuantity = balance + actualQuantity;

                if (floatValue > maximumQuantity) {
                    return setError(getPath('quantity') as any, message);
                }

                return clearErrors(getPath('quantity') as any);
            }

            if (floatValue > balance) {
                return setError(getPath('quantity') as any, message);
            }

            clearErrors(getPath('quantity') as any);
        };
    };

    const quantityError = formState.errors.saleProducts?.[index]?.quantity?.message;
    const startDateError = formState.errors.saleProducts?.[index]?.predictedStartDate?.message;
    const endDateError = formState.errors.saleProducts?.[index]?.predictedEndDate?.message;

    const isFieldsDisabled = (isRegisteredSaleProduct && !isChecked) || (!isRegisteredSaleProduct && (!balance || !isChecked)) || isOrderServiceFinished;

    return (
        <TableRow classes={{ root: !balance && !isChecked ? 'bg-base-200' : '' }}>
            <TableCell className="px-[22px] py-0 w-[60px] border-r border-r-base-300">
                <Checkbox checked={isChecked} disabled={isOrderServiceFinished || (!balance && !isRegisteredSaleProduct)} onChange={handleToggleCheck} />
            </TableCell>
            <TableCell className="p-0 pl-4 border-r border-r-base-300 pr-4">
                <Text as="span" className={`whitespace-nowrap ${isFieldsDisabled ? 'text-base-400' : 'text-base-500'} block`}>
                    {product?.name || ''}
                </Text>
            </TableCell>
            <TableCell className="p-0 pl-4 border-r border-r-base-300">
                <Text as="span" className={`${isFieldsDisabled ? 'text-base-400' : 'text-base-500'} block`}>
                    {formatNumberToPTBR(balance || 0)}
                </Text>
            </TableCell>
            <TableCell className="p-0 pl-4 border-r border-r-base-300 pr-4">
                <Text as="span" className={`whitespace-nowrap ${isFieldsDisabled ? 'text-base-400' : 'text-base-500'} block`}>
                    {formatMoney(value || 0, undefined, 4)}
                </Text>
            </TableCell>
            <TableCell className="p-0 border-r border-r-base-300">
                <Controller
                    control={control}
                    name={getPath('quantity') as any}
                    render={({ field }) => {
                        return (
                            <Tooltip
                                open={!!quantityError}
                                placement="top-end"
                                slotProps={{
                                    popper: {
                                        sx: {
                                            [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]: {
                                                marginBottom: 0,
                                            },
                                        },
                                    },
                                }}
                                title={quantityError}
                                PopperProps={{
                                    disablePortal: true,
                                }}
                                disableFocusListener={true}
                                arrow={true}>
                                <span>
                                    <CurrencyInput
                                        value={field.value}
                                        autoFocus={false}
                                        disabled={(isRegisteredSaleProduct && !isChecked) || (!isRegisteredSaleProduct && (!balance || !isChecked)) || isOrderServiceFinished}
                                        placeholder="Digite uma quantidade"
                                        inputWrapperClasses="border-none rounded-none"
                                        className={`focus:outline-2 -outline-offset-2 outline ${!!quantityError ? 'text-system-danger-500 outline-system-danger-100' : 'outline-primary-100'}`}
                                        onValueChange={handleChangeQuantity(field.onChange)}
                                    />
                                </span>
                            </Tooltip>
                        );
                    }}
                />
            </TableCell>
            <TableCell className="p-0 border-r border-r-base-300">
                <Controller
                    name={getPath('predictedStartDate') as any}
                    control={control}
                    render={({ field }) => {
                        return (
                            <Tooltip
                                open={!!startDateError}
                                placement="top-end"
                                slotProps={{
                                    popper: {
                                        sx: {
                                            [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]: {
                                                marginBottom: 0,
                                            },
                                        },
                                    },
                                }}
                                title={startDateError}
                                disableFocusListener={true}
                                arrow={true}
                                PopperProps={{
                                    disablePortal: true,
                                }}>
                                <span>
                                    <DatePicker
                                        containerClassNames={!!startDateError ? 'border-2 border-system-danger-100' : ''}
                                        inputProps={{
                                            autoFocus: false,
                                            disabled: isFieldsDisabled,
                                            className: !!startDateError ? 'text-system-danger-500' : '',
                                            inputWrapperClasses: 'border-none rounded-none',
                                            placeholderText: 'Selecione uma data',
                                            ...field,
                                        }}
                                    />
                                </span>
                            </Tooltip>
                        );
                    }}
                />
            </TableCell>
            <TableCell className="p-0 border-r border-r-base-300">
                <Controller
                    name={getPath('predictedEndDate') as any}
                    control={control}
                    render={({ field }) => {
                        return (
                            <Tooltip
                                open={!!endDateError}
                                placement="top-end"
                                slotProps={{
                                    popper: {
                                        sx: {
                                            [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]: {
                                                marginBottom: 0,
                                            },
                                        },
                                    },
                                }}
                                title={endDateError}
                                disableFocusListener={true}
                                arrow={true}
                                PopperProps={{
                                    disablePortal: true,
                                }}>
                                <span>
                                    <DatePicker
                                        containerClassNames={!!startDateError ? 'border-2 border-system-danger-100' : ''}
                                        inputProps={{
                                            autoFocus: false,
                                            disabled: isFieldsDisabled,
                                            className: !!endDateError ? 'text-system-danger-500' : '',
                                            inputWrapperClasses: 'border-none rounded-none',
                                            placeholderText: 'Selecione uma data',
                                            ...field,
                                        }}
                                    />
                                </span>
                            </Tooltip>
                        );
                    }}
                />
            </TableCell>
            <TableCell className="p-0 pl-4">
                <Text as="span" className={`${isFieldsDisabled ? 'text-base-400' : 'text-base-500'} block`}>
                    {formatMoney((quantity || 0) * (value || 0))}
                </Text>
            </TableCell>
        </TableRow>
    );
};

export default SaleScopeItem;
