import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import { getPendingPurchaseOrderProductsApiKey } from './keys';
import { useShowApiErrors } from 'hooks/error/show-api-errors';

type Payload = {
    action: 'approve' | 'disapprove' | 'cancel' | 'entry';
    purchaseOrderProducts: number[];
};

const useChangePurchaseOrderProductStatus = (purchaseOrderId?: string) => {
    const { showToast } = useToast();
    const { showToastErrors } = useShowApiErrors();
    const queryClient = useQueryClient();

    const request = async ({ action, purchaseOrderProducts }: Payload) => api.patch(`/purchase-order-products/actions/${action}`, { purchaseOrderProducts });

    return useMutation(request, {
        onSuccess: (_, payload) => {
            const variants = {
                approve: 'aprovado(s)',
                disapprove: 'não aprovado(s)',
                cancel: 'cancelado(s)',
            };

            if (purchaseOrderId) {
                queryClient.invalidateQueries(getPendingPurchaseOrderProductsApiKey(purchaseOrderId));
            }

            showToast(`Produto(s) ${variants[payload.action] || 'aprovar'} com sucesso`, 'success');
        },
        onError: (error: any, payload) => {
            const variants = {
                approve: 'aprovar',
                disapprove: 'não aprovar',
                cancel: 'cancelar',
            };

            const errorMessage = error?.response?.data?.title || `Ocorreu um erro ao ${variants[payload.action] || 'aprovar'} o(s) produto(s)`;

            showToastErrors(error, errorMessage);
        },
    });
};

export default useChangePurchaseOrderProductStatus;
