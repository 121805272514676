import TableCell from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import ReportColumnValuePercentage from '../../components/value-percentage';
import { ValuePercentage } from '../types';
import { generateClassesByValue } from './utils';
import { useReportsContext } from '../../context';

type ResumesReportFooterPropsKeys = 'columnValues1' | 'columnValues2' | 'columnValues3';

type ResumesReportFooterProps = {
    [K in ResumesReportFooterPropsKeys]: ValuePercentage;
} & {
    withFooterClassesByValue?: boolean;
};

const ResumesReportTableFooter = ({ columnValues1, columnValues2, columnValues3, withFooterClassesByValue = false }: ResumesReportFooterProps) => {
    const generateClasses = generateClassesByValue(withFooterClassesByValue);

    const { showValues } = useReportsContext();

    return (
        <TableFooter className="border-t border-base-300">
            <TableRow className="bg-base-300">
                <TableCell className="border-none !text-black font-semibold text-opacity-[0.87] !text-[0.875rem]">TOTAL</TableCell>
                <TableCell className="border-none !text-black text-opacity-[0.87] !text-[0.875rem]">
                    <ReportColumnValuePercentage showValues={showValues} value={columnValues1.value} percentage={columnValues1.percentage} classes={generateClasses(columnValues1.value)} />
                </TableCell>
                <TableCell className="border-none !text-black text-opacity-[0.87] !text-[0.875rem]">
                    <ReportColumnValuePercentage showValues={showValues} value={columnValues2.value} percentage={columnValues2.percentage} classes={generateClasses(columnValues2.value)} />
                </TableCell>
                <TableCell className="border-none !text-black text-opacity-[0.87] !text-[0.875rem]">
                    <ReportColumnValuePercentage showValues={showValues} value={columnValues3.value} percentage={columnValues3.percentage} classes={generateClasses(columnValues3.value)} />
                </TableCell>
            </TableRow>
        </TableFooter>
    );
};

export default ResumesReportTableFooter;
