import { useLocation, useMatches, useNavigate } from 'react-router-dom';

const useNavigateToParentRoute = () => {
    const matches = useMatches();
    const location = useLocation();
    const navigate = useNavigate();

    return () => {
        // This condition check if the current url is stand alone
        if (location.key !== 'default') {
            return navigate(-1);
        }

        const lastRoute = matches[matches.length - 1];

        if (!lastRoute) {
            return;
        }

        const ids = lastRoute.id?.split('-');

        ids?.pop();

        const route = matches.find((item) => item.id === ids.join('-'));

        if (!route) {
            return;
        }

        return navigate(route.pathname, { replace: true });
    };
};

export default useNavigateToParentRoute;
