import { Person } from 'types/models/person';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import ListPage from 'pages/shared/crud/list';
import { personContractsListConfig } from './utils';

const PersonDetailsContracts = ({ page }: WithPagePermissionsProps) => {
    const navigate = useNavigate();
    const { id } = useOutletContext<Partial<Person>>();

    const handleClickReactivateContract = (contractId: number) => navigate(`/app/pessoas/${id}/contratos/reativar/${contractId}`);

    const listConfig = personContractsListConfig(id, handleClickReactivateContract);

    return <ListPage page={page} {...listConfig} />;
};

export default PersonDetailsContracts;
