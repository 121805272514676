import { useMutation, useQueryClient } from 'react-query';
import { getAllKey } from '../crud/keys';
import useToast from 'hooks/toast/use-toast';
import { Customer } from 'types/models/customer';
import { GraphqlPaginationVariables } from 'types/graphql';
import { useShowApiErrors } from 'hooks/error/show-api-errors';
import apiGraphQl from 'services/graphql';
import queries from './keys';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';

type Payload = {
    id: number;
    data: any;
};

const useUpdateProvider = (params?: GraphqlPaginationVariables<Customer>) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const { showToastErrors } = useShowApiErrors();
    const goToParentRoute = useNavigateToParentRoute();

    const request = async (payload: Payload) => {
        const { data, id } = payload;

        return apiGraphQl(queries.editProvider, { id, payload: data });
    };

    return useMutation(request, {
        onSuccess: () => {
            queryClient.invalidateQueries(getAllKey('customer', params));

            goToParentRoute();

            showToast('Fornecedor editado com sucesso', 'success');
        },
        onError: (err: any) => {
            const errMessage = err?.response?.errors?.[0]?.message;

            if (errMessage.includes('duplicate')) {
                if (errMessage.includes('provider_document_key')) {
                    showToast('CPF já cadastrado no sistema', 'danger');
                }

                if (errMessage.includes('provider_juridic_document_key')) {
                    showToast('CNPJ já cadastrado no sistema', 'danger');
                }
            } else {
                showToastErrors(err);
            }
        },
    });
};

export default useUpdateProvider;
