import { useQuery } from 'react-query';
import { getPaymentFlowKey } from './keys';
import api from 'services/api';
import { PaymentFlow } from 'types/models/payment-flow';

const useGetPaymentFlow = (paymentFlowId?: string) => {
    const request = async () => {
        const { data } = await api.get<PaymentFlow>(`/payment-flows/${paymentFlowId}`);

        return data;
    };

    return useQuery(getPaymentFlowKey(paymentFlowId), request, { enabled: Boolean(paymentFlowId) });
};

export default useGetPaymentFlow;
