import Button from 'components/core/button';
import Modal from 'components/core/modal';
import Text from 'components/core/text';

export type ConfirmModalProps = {
    title: string;
    description: string | React.ReactNode;
    isLoading: boolean;
    color?: 'error' | 'primary' | 'secondary' | 'success' | 'warning' | 'info' | 'inherit';
    onClose: () => void;
    hideCancelButton?: boolean;
    onConfirmAction: () => void;
};

const ConfirmModal = ({ onConfirmAction, onClose, color = 'error', description, isLoading, title, hideCancelButton = false }: ConfirmModalProps) => {
    return (
        <Modal
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    {title}
                </Text>
            }
            closeOnClickOutside={false}
            contentClassnames="xs:w-full sm:min-w-[500px]"
            onClose={onClose}>
            <div className="px-6 py-4 sm:p-6">
                <Text as="p" variant="body.regular.sm" className="text-base-700 mb-6">
                    {description}
                </Text>
                <div className="flex items-center justify-end">
                    <Button disabled={isLoading} loading={isLoading} type="submit" variant="contained" color={color} className="min-w-[100px] mr-4" onClick={onConfirmAction}>
                        Enviar
                    </Button>
                    {!hideCancelButton && (
                        <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={onClose}>
                            Cancelar
                        </Button>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmModal;
