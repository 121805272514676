import * as React from 'react';
const SvgIcoHome = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        className="ico-home_svg__feather ico-home_svg__feather-home"
        viewBox="0 0 24 24"
        width="1em"
        height="1em"
        {...props}>
        <path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
        <path d="M9 22V12h6v10" />
    </svg>
);
export default SvgIcoHome;
