import { useShowApiErrors } from 'hooks/error/show-api-errors';
import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import { GraphqlPaginationVariables } from 'types/graphql';
import { getAllKey } from 'services/queries/crud/keys';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';

type Payload = {
    movements: Array<{
        quantity: number;
        stockProduct: number;
        movements: Array<{
            product: number;
            quantity: number;
        }>;
    }>;
};

const useIndustrialization = (stockId?: string, params?: GraphqlPaginationVariables) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const { showToastErrors } = useShowApiErrors();
    const goToParentRoute = useNavigateToParentRoute();

    const request = async (payload: Payload) => api.post(`/stocks/${stockId}/orders/industrialization`, payload);

    return useMutation(request, {
        onSuccess: async () => {
            showToast('Produtos industrializados com sucesso', 'success');

            await queryClient.invalidateQueries(getAllKey('stockProduct', params));

            goToParentRoute();
        },
        onError: (error: any) => {
            showToastErrors(error, error?.response?.data?.title || 'Ocorreu um erro ao converter os produtos');
        },
    });
};

export default useIndustrialization;
