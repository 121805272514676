import classNames from 'classnames';
import { optionsParser } from 'pages/private/sales-orders/utils';
import { DREBlockType, DREBlockValuesContent, DREClassType, TableItemValuesValue } from 'types/models/reports';
import ExternalButton from 'components/buttons/external';
import Badge from 'components/core/badge';
import { DatatableColumn } from 'types/graphql';
import { SaleOrderOrigin } from 'types/models/sale-order';
import { formatDate } from 'utils/date';
import { formatMoney } from 'utils/money';
import { saleOrderOriginsLiteral } from 'utils/statics';
import dictionary from 'utils/dictionary';
import { getNumberValueOrZero } from 'pages/private/expenses/details/tabs/transactions/utils';

export const randomNumber = (rand = 10000000) => Math.ceil(Math.random() * rand);

export const generateDREIIValues = (): TableItemValuesValue[] => {
    const months: TableItemValuesValue[] = [...new Array(4).keys()].map(() => ({
        type: 'number',
        value: randomNumber(10000),
    }));

    const tri: TableItemValuesValue[] = [
        ...months,
        {
            type: 'percentage',
            value: randomNumber(100),
        },
    ];

    return [
        ...tri,
        ...tri,
        ...tri,
        ...tri,
        {
            type: 'number',
            value: randomNumber(10000),
        },
        {
            type: 'percentage',
            value: randomNumber(100),
        },
    ];
};

const initialDREDate = 2024;

export const generateDREYearsOptions = Array.from({ length: new Date().getFullYear() - initialDREDate + 1 }, (_, index) => {
    const value = initialDREDate + index;

    return optionsParser(value, value.toString());
});

const quarterlyResult = { title: 'Resultado trimestre', colSpan: 2 };

export const generateDreTableHeaderData = (year: string) => [
    [
        { title: '1º Trimestre', colSpan: 8 },
        { title: '2º Trimestre', colSpan: 8 },
        { title: '3º Trimestre', colSpan: 8 },
        { title: '4º Trimestre', colSpan: 8 },
        { title: year, rowSpan: 2, colSpan: 2 },
    ],
    [
        { title: 'Janeiro', colSpan: 2 },
        { title: 'Fevereiro', colSpan: 2 },
        { title: 'Março', colSpan: 2 },
        quarterlyResult,
        { title: 'Abril', colSpan: 2 },
        { title: 'Maio', colSpan: 2 },
        { title: 'Junho', colSpan: 2 },
        quarterlyResult,
        { title: 'Julho', colSpan: 2 },
        { title: 'Agosto', colSpan: 2 },
        { title: 'Setembro', colSpan: 2 },
        quarterlyResult,
        { title: 'Outubro', colSpan: 2 },
        { title: 'Novembro', colSpan: 2 },
        { title: 'Dezembro', colSpan: 2 },
        quarterlyResult,
    ],
];

export const generateSummaryColumnClasses = (classType: DREClassType, className?: string) => {
    return classNames('font-semibold whitespace-nowrap', classType === 'success' ? 'text-system-success-500' : 'text-system-danger-500', className);
};

const DREMonthValuesCommonColumns = (withOrigin = false, withCompany = false): DatatableColumn[] => {
    return [
        {
            name: 'id',
            options: {
                display: 'excluded',
            },
        },
        {
            name: 'code',
            label: 'Código',
            options: {
                customBodyRender: (value, tableMeta) => {
                    return Boolean(value) ? <ExternalButton href={tableMeta.rowData[2]}>{value}</ExternalButton> : '-';
                },
            },
        },
        {
            name: 'link',
            options: {
                display: 'excluded',
            },
        },
        ...(withOrigin
            ? [
                  {
                      name: 'origin',
                      label: 'Origem',
                      options: {
                          customBodyRender: (value) => {
                              return value in SaleOrderOrigin ? <Badge variant="info">{saleOrderOriginsLiteral[value]}</Badge> : '-';
                          },
                      },
                  },
              ]
            : []),
        {
            name: 'contractDate',
            label: 'Data do contrato',
            options: {
                customBodyRender: (value) => formatDate(value),
            },
        },
        ...(withCompany
            ? [
                  {
                      name: 'company',
                      label: 'Empresa',
                      options: {
                          customBodyRender: (value = '-') => <span className="whitespace-nowrap">{value}</span>,
                      },
                  },
              ]
            : []),
        {
            name: 'branch',
            label: 'Filial',
            options: {
                customBodyRender: (value) => value || '-',
            },
        },
        {
            name: 'version',
            label: 'Versão',
            options: {
                customBodyRender: (value) => (Boolean(value) ? value.toUpperCase() : '-'),
            },
        },
    ];
};

export const DREMonthValuesReceiptsColumns: DatatableColumn[] = [
    ...DREMonthValuesCommonColumns(true, true),
    {
        name: 'netValue',
        label: 'Valor',
        options: {
            customBodyRender: (value) => formatMoney(value),
        },
    },
];

export const DREMonthValuesCostsColumns: DatatableColumn[] = [
    ...DREMonthValuesCommonColumns(),
    {
        name: 'cost',
        label: 'Custo',
        options: {
            customBodyRender: (value) => formatMoney(value),
        },
    },
];

export const DREMonthValuesCommissionsColumns: DatatableColumn[] = [
    ...DREMonthValuesCommonColumns(true, true),
    {
        name: 'commission',
        label: 'Comissão',
        options: {
            customBodyRender: (value) => formatMoney(value),
        },
    },
];

export const DREMonthValuesOperationalExpensesColumns: DatatableColumn[] = [
    {
        name: 'id',
        options: {
            display: 'excluded',
        },
    },
    {
        name: 'code',
        label: 'Código',
        options: {
            customBodyRender: (value, tableMeta) => {
                return Boolean(value) ? <ExternalButton href={tableMeta.rowData[2]}>{value}</ExternalButton> : '-';
            },
        },
    },
    {
        name: 'link',
        options: {
            display: 'excluded',
        },
    },
    {
        name: 'contractDate',
        label: 'Data do contrato',
        options: {
            customBodyRender: (value) => formatDate(value),
        },
    },
    {
        name: 'competence',
        label: 'Competência',
        options: {
            customBodyRender: (value = '-') => value,
        },
    },
    {
        name: 'category.title',
        label: 'Categoria',
        options: {
            customBodyRender: (value = '-') => <span className="whitespace-nowrap">{value}</span>,
        },
    },
    {
        name: 'sector.title',
        label: 'Setor',
        options: {
            customBodyRender: (value = '-') => <span className="whitespace-nowrap">{value}</span>,
        },
    },
    {
        name: 'origin.title',
        label: 'Origem',
        options: {
            customBodyRender: (value = '-') => <span className="whitespace-nowrap">{value}</span>,
        },
    },
    {
        name: 'subOrigin.title',
        label: 'Sub Origem',
        options: {
            customBodyRender: (value = '-') => <span className="whitespace-nowrap">{value}</span>,
        },
    },
    {
        name: 'instalments',
        label: 'Parcelas',
        options: {
            customBodyRender: (value = '-') => <span className="whitespace-nowrap">{value}</span>,
        },
    },
    {
        name: 'version',
        label: 'Versão',
        options: {
            customBodyRender: (value) => (Boolean(value) ? value.toUpperCase() : '-'),
        },
    },
    {
        name: 'netValue',
        label: 'Valor',
        options: {
            customBodyRender: (value) => formatMoney(value),
        },
    },
];

export const DREMonthValuesTaxesKeys = ['cofins', 'inss', 'iss', 'pis', 'icms', 'ipi', 'others'];
export const DREMonthValuesFederalTaxesKeys = ['irpj', 'irpjAdditional', 'csll'];

const generateDREMonthValuesTaxesTableColumns = (keys: string[], taxKey?: keyof typeof dictionary.content.taxes) => {
    const tableColumnsArr: DatatableColumn[] = keys.map((item) => ({
        name: `taxes.${item}.value`,
        label: dictionary.content.taxes[item].toUpperCase(),
        options: {
            setCellProps: () => ({ className: classNames(taxKey === item && 'bg-slate-100') }),
            setCellHeaderProps: () => ({ className: classNames(taxKey === item && '!bg-slate-100') }),
            customBodyRender: (value) => formatMoney(getNumberValueOrZero(value?.toFixed(2))),
        },
    }));

    return [
        ...DREMonthValuesCommonColumns(),
        ...tableColumnsArr,
        {
            name: '',
            label: 'Total',
            options: {
                customBodyRender: (_, { rowData }) => {
                    const taxes = rowData.at(-1);
                    const total = keys.reduce((acc, key) => acc + getNumberValueOrZero(taxes?.[key]?.value?.toFixed(2)), 0);

                    return formatMoney(total);
                },
                sort: false,
            },
        },
        {
            name: 'taxes',
            options: {
                display: 'excluded',
            },
        },
    ] as DatatableColumn[];
};

export const getDREMonthValuesColumnsAndKeysByBlockType = (blockType: DREBlockType, taxKey?: keyof typeof dictionary.content.taxes) => {
    const blockTaxesMap: Partial<Record<DREBlockType, { columns: DatatableColumn[]; taxKeys?: string[] }>> = {
        [DREBlockType.Taxes]: {
            columns: generateDREMonthValuesTaxesTableColumns(DREMonthValuesTaxesKeys, taxKey),
            taxKeys: DREMonthValuesTaxesKeys,
        },
        [DREBlockType.FederalTaxes]: {
            columns: generateDREMonthValuesTaxesTableColumns(DREMonthValuesFederalTaxesKeys, taxKey),
            taxKeys: DREMonthValuesFederalTaxesKeys,
        },
        [DREBlockType.CostsTaxes]: {
            columns: DREMonthValuesCostsColumns,
        },
        [DREBlockType.Receipts]: {
            columns: DREMonthValuesReceiptsColumns,
        },
        [DREBlockType.VariableExpensesTaxes]: {
            columns: DREMonthValuesCommissionsColumns,
        },
        [DREBlockType.OperationalExpensesTaxes]: {
            columns: DREMonthValuesOperationalExpensesColumns,
        },
        [DREBlockType.OtherAssetsExpensesTaxes]: {
            columns: DREMonthValuesOperationalExpensesColumns,
        },
    };

    return blockTaxesMap[blockType] || { columns: [], taxKeys: [] };
};

const versionsKeysByBlockTypeMap = {
    [DREBlockType.CostsTaxes]: 'cost',
    [DREBlockType.VariableExpensesTaxes]: 'commission',
} as const;

const generateTotalTaxes = (data: DREBlockValuesContent[], taxKey?: keyof typeof dictionary.content.taxes) => {
    return data.reduce((acc, curr) => {
        acc += curr.taxes?.[taxKey!]?.value || 0;

        return acc;
    }, 0);
};

const generateVersionsValues = (data: DREBlockValuesContent[], blockType: DREBlockType) => {
    return data.reduce(
        (acc, curr) => {
            const value = getNumberValueOrZero(curr[versionsKeysByBlockTypeMap[blockType] || 'netValue']?.toFixed(2));

            if (curr.version === 'v1') {
                acc.firstVersion += value;
            } else {
                acc.secondVersion += value;
            }

            return acc;
        },
        {
            firstVersion: 0,
            secondVersion: 0,
        }
    );
};

export const generateDREMonthValuesHeadValues = (blockType: DREBlockType, data: DREBlockValuesContent[], taxKey?: keyof typeof dictionary.content.taxes) => {
    const { firstVersion = 0, secondVersion = 0 } = generateVersionsValues(data, blockType) || {};

    const versionsValues = {
        firstVersion,
        secondVersion,
        totalComparasion: getNumberValueOrZero((firstVersion + secondVersion).toFixed(2)),
    };

    const taxesValues = {
        firstVersion,
        secondVersion,
        totalComparasion: getNumberValueOrZero(generateTotalTaxes(data, taxKey).toFixed(2)),
    };

    const taxesBlocksTypeMap = {
        [DREBlockType.Taxes]: taxesValues,
        [DREBlockType.FederalTaxes]: taxesValues,
    };

    return taxesBlocksTypeMap[blockType] || versionsValues;
};
