import { ExpenseDetailsResponseFavored } from 'services/queries/expenses/types';
import Card from 'components/core/card';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import useTheme from '@mui/material/styles/useTheme';
import SvgIcoShow from 'components/core/icon/files/ico-show';

type FavoredProps = { favored?: ExpenseDetailsResponseFavored };

const Favored = ({ favored }: FavoredProps) => {
    const { palette } = useTheme();

    const favoredPerson = favored?.person;
    const personOrProviderDetailsPath = !!favoredPerson ? `/app/pessoas/${favoredPerson.id}/detalhes` : `/app/fornecedores/${favored?.provider?.id}`;

    return (
        <Card className="p-6">
            <div className="flex items-center  mb-4">
                <h3 className="text-heading text-xl font-medium flex-1">Favorecido</h3>
                {!!favored?.id && (
                    <Tooltip placement="top" title="Ver detalhes do favorecido" disableFocusListener={true}>
                        <IconButton
                            component={Link}
                            to={personOrProviderDetailsPath}
                            TouchRippleProps={{ classes: { child: 'text-system-info-100' } }}
                            className="hover:bg-opacity-30 hover:bg-system-info-100">
                            <SvgIcoShow width={16} height={16} />
                        </IconButton>
                    </Tooltip>
                )}
            </div>
            {!favored?.id ? (
                <p className="text-sm break-words" style={{ color: palette.heading }}>
                    Nenhum favorecido foi selecionado.
                </p>
            ) : (
                <>
                    <div className="flex flex-col mb-4">
                        <p className="mb-1 uppercase" style={{ color: palette.grey[500] }}>
                            Nome
                        </p>
                        <p className="text-sm break-words" style={{ color: palette.heading }}>
                            {favoredPerson?.title || favored?.provider?.title || '-'}
                        </p>
                    </div>
                    <div className="flex flex-col mb-4">
                        <p className="mb-1 uppercase" style={{ color: palette.grey[500] }}>
                            E-mail
                        </p>
                        {!!favoredPerson ? (
                            <p className="text-sm break-words" style={{ color: palette.heading }}>
                                {favoredPerson.email || '-'}
                            </p>
                        ) : (
                            <p className="text-sm break-words" style={{ color: palette.heading }}>
                                {favored?.provider?.emails?.map(({ email }) => email).join(', ') || '-'}
                            </p>
                        )}
                    </div>
                    <div className="flex flex-col mb-4">
                        <p className="mb-1 uppercase" style={{ color: palette.grey[500] }}>
                            CPF/CNPJ
                        </p>
                        <p className="text-sm break-words" style={{ color: palette.heading }}>
                            {favoredPerson?.document || favored?.provider?.juridicDocument || '-'}
                        </p>
                    </div>
                </>
            )}
        </Card>
    );
};

export default Favored;
