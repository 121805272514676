import { RouteObject } from 'react-router-dom';
import { Rule } from 'types/permissions';
import Page from 'pages/shared/page';
import CreateOrUpdateLoanPage from 'pages/private/loans/create-or-update';
import LoansPage from 'pages/private/loans';
import { ExpenseNature } from 'types/models/expense';
import LoanPage from 'pages/private/loans/view';

const loans: RouteObject[] = [
    {
        // @ts-ignore
        breadcrumb: 'Empréstimos',
        path: 'emprestimos',
        element: <Page Component={LoansPage} code="emprestimos" permissions={[Rule.List]} />,
        children: [
            {
                path: 'novo',
                element: <Page Component={CreateOrUpdateLoanPage} nature={ExpenseNature.Normal} code="emprestimos" permissions={[Rule.Create]} withModal={true} />,
            },
            {
                path: 'editar/:loanId',
                element: <Page Component={CreateOrUpdateLoanPage} code="emprestimos" title="Editar empréstimo" permissions={[Rule.Update]} withModal={true} />,
            },
            {
                // @ts-ignore
                path: ':loanId',
                element: <Page Component={LoanPage} code="emprestimos" permissions={[Rule.Show]} withModal={true} />,
            },
        ],
    },
];

export default loans;
