import Select from 'components/core/form/select';
import { Controller, useFormContext } from 'react-hook-form';
import { Option, Weekdays } from 'types/general';
import Text from 'components/core/text';
import DatePicker from 'components/core/datepicker';
import _get from 'lodash/get';
import { useCallback, useMemo, useState } from 'react';
import useGetClassifications from 'services/queries/classifications/use-get-classifications';
import { generateClassificationQuery } from 'services/queries/classifications/utils';
import { ClassificationType } from 'types/models/classification';
import FavoredSelect from 'components/favored-select';
import useGetCompaniesOptions from 'services/queries/companies/use-get-company-options';
import { PurchaseOrderExpensePayload } from './index';
import Textarea from 'components/core/form/textarea';
import useGetBankAccountsByEntity from 'services/queries/bank-accounts/use-get-bank-accounts-by-entity';
import useGetOptions from 'services/queries/crud/use-get-options';
import { generateGetBranchOptionsQuery } from 'settings/routes/branches/utils';
import { favoredPeopleWhere } from 'pages/private/expenses/crud/general';
import dayjs, { Dayjs } from 'dayjs';
import { provisionWeekDays } from 'pages/private/expenses/crud/utils';
import ModalProviderConsulting from 'pages/private/providers/view/modal-provider-consulting';

export const excludeDates = (date: Dayjs) => {
    if (!date) {
        return true;
    }

    const weekday = date.day();
    const alloweds = [Weekdays.Monday, Weekdays.Thursday];

    return !alloweds.includes(weekday as any);
};

const General = () => {
    const [providerId, setProviderId] = useState<number | null>(null);

    const { control, formState, watch } = useFormContext<PurchaseOrderExpensePayload>();

    const { data: companies = [], isLoading: isLoadingCompanies } = useGetCompaniesOptions();
    const { data: branches = [], isLoading: isLoadingBranches } = useGetOptions(generateGetBranchOptionsQuery(), ['branch', 'options']);
    const { data: paymentForms = [], isLoading: isLoadingPaymentForms } = useGetClassifications(generateClassificationQuery(ClassificationType.PaymentForm));
    const { data: fiscalCodes = [], isLoading: isLoadingFiscalCodes } = useGetClassifications(generateClassificationQuery(ClassificationType.ExpenseFiscalCode));

    const getError = useCallback((name: string) => _get(formState.errors, `${name}.message`) as any, [formState]);

    const paymentForm = watch('paymentForm');
    const formFavored = watch('formFavored');

    const isBankAccountVisible = useMemo(() => {
        const options = ['pix', 'débito em conta', 'ted | doc | tef'];

        return options.includes(paymentForm?.label?.toLowerCase()) && Boolean(formFavored);
    }, [paymentForm?.label, formFavored]);

    const { data: favoredBankAccounts = [], isLoading: isLoadingBankAccounts } = useGetBankAccountsByEntity(formFavored, isBankAccountVisible);

    return (
        <>
            <div className="px-6 mb-6">
                <div className="border border-base-300 p-4 rounded-[14px]">
                    <Text as="h6" variant="h5" className="text-heading mb-4">
                        Geral
                    </Text>
                    <div className="md:grid md:grid-cols-3 gap-4">
                        <Controller
                            name="company"
                            control={control}
                            render={({ field }) => {
                                const value = companies.find((item) => item.value === field.value);

                                return (
                                    <Select
                                        {...field}
                                        isLoading={isLoadingCompanies}
                                        value={value}
                                        options={companies}
                                        label="Empresa"
                                        placeholder="Selecione uma opção"
                                        onChange={(option: any) => field.onChange(option.value)}
                                        error={getError('company')}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="branch"
                            control={control}
                            render={({ field }) => {
                                const value = branches.find((item) => item.value === field.value);

                                return (
                                    <Select
                                        {...field}
                                        isLoading={isLoadingBranches}
                                        value={value}
                                        options={branches}
                                        label="Filial"
                                        placeholder="Selecione uma opção"
                                        onChange={(option: any) => field.onChange(option.value)}
                                        error={getError('branch')}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="paymentForm"
                            control={control}
                            render={({ field }) => {
                                const value = paymentForms.find((item) => item.value === field?.value?.value);

                                return (
                                    <Select
                                        {...field}
                                        isLoading={isLoadingPaymentForms}
                                        value={value}
                                        options={paymentForms}
                                        label="Forma de Pagamento*"
                                        placeholder="Selecione uma opção"
                                        error={getError('paymentForm')}
                                        required={true}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="formFavored"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <FavoredSelect
                                        error={formState.errors?.formFavored?.message}
                                        peopleWhere={favoredPeopleWhere}
                                        onChange={(option: Option) => field.onChange(option)}
                                        value={field.value}
                                        onOpenProviderConsultingModal={setProviderId.bind(this, field.value?.value!)}
                                    />
                                );
                            }}
                        />
                        {isBankAccountVisible && (
                            <Controller
                                name="bankAccount"
                                control={control}
                                render={({ field }) => {
                                    const value = favoredBankAccounts.find((item) => item.value === field.value);

                                    return (
                                        <Select
                                            {...field}
                                            isLoading={isLoadingBankAccounts}
                                            value={value}
                                            options={favoredBankAccounts}
                                            label="Conta bancária"
                                            placeholder="Selecione uma opção"
                                            onChange={(option: any) => field.onChange(option.value)}
                                            error={getError('expenseType')}
                                        />
                                    );
                                }}
                            />
                        )}
                        <Controller
                            name="provision"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <DatePicker
                                        inputProps={{
                                            label: 'Data de provisionamento',
                                            placeholderText: 'Selecione uma data',
                                            error: getError('provision'),
                                            ...field,
                                        }}
                                        muiDatePickerProps={{
                                            shouldDisableDate: excludeDates,
                                            ...(Boolean(provisionWeekDays.includes(dayjs().day())) && {
                                                minDate: dayjs(),
                                            }),
                                        }}
                                        withProvisionLogic={true}
                                        removeFirstDate={false}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="fiscalCode"
                            control={control}
                            render={({ field }) => {
                                const value = fiscalCodes.find((item) => item.value === field.value);

                                return (
                                    <Select
                                        {...field}
                                        isLoading={isLoadingFiscalCodes}
                                        value={value}
                                        options={fiscalCodes}
                                        label="CFOP"
                                        placeholder="Selecione uma opção"
                                        onChange={(option: any) => field.onChange(option.value)}
                                        error={getError('provisioned')}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="competence"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <DatePicker
                                        inputProps={{
                                            label: 'Competência',
                                            placeholderText: 'Selecione uma data',
                                            error: getError('competence'),
                                            ...field,
                                        }}
                                        muiDatePickerProps={{
                                            minDate: dayjs().month(0).year(2024),
                                            shouldDisableYear: (date) => dayjs().year() < date.year(),
                                            views: ['month', 'year'],
                                            format: 'MM/YYYY',
                                        }}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="title"
                            control={control}
                            render={({ field }) => (
                                <Textarea {...field} required={true} error={getError('title')} label="Descrição*" placeholder="Adicione alguma descrição" parentClassName="col-span-3" />
                            )}
                        />
                    </div>
                </div>
            </div>
            {Boolean(providerId) && <ModalProviderConsulting providerId={providerId!} onClose={setProviderId.bind(this, null)} />}
        </>
    );
};

export default General;
