import { useOutletContext, useParams } from 'react-router-dom';
import TableCell from '@mui/material/TableCell/TableCell';
import ListPage from 'pages/shared/crud/list';
import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import { formatMoney, formatNumberToPTBR } from 'utils/money';
import TableFooter from '@mui/material/TableFooter/TableFooter';
import TableRow from '@mui/material/TableRow/TableRow';
import Text from 'components/core/text';
import { generateProductListColSpan, saleOrderProductsListConfig } from 'pages/private/sales-orders/utils';
import { getNumberValueOrZero } from 'pages/private/expenses/details/tabs/transactions/utils';
import { parseCurrency } from '@brazilian-utils/brazilian-utils';
import { SaleOrder, SaleOrderOrigin } from 'types/models/sale-order';

const SaleOrderProductsPage = ({ page }: WithPagePermissionsProps) => {
    const { saleOrderId } = useParams();

    const { origin } = useOutletContext<Partial<SaleOrder>>();

    const isOrderServiceOrigin = origin === SaleOrderOrigin.service;

    const colSpan = generateProductListColSpan(origin);
    const listConfig = saleOrderProductsListConfig(saleOrderId, origin);

    return (
        <ListPage
            customTableOptions={{
                customTableBodyFooterRender: ({ data }) => {
                    const { consolidated = 0, originalTotal = 0 } = data.reduce(
                        (acc, curr) => {
                            const netValue = curr.data[3] || 0;
                            const balance = parseCurrency(curr.data[8]) || 0;
                            const quantity = parseCurrency(curr.data[7]) || 0;

                            return {
                                consolidated: acc.consolidated + netValue * balance,
                                originalTotal: acc.originalTotal + netValue * quantity,
                            };
                        },
                        {
                            consolidated: 0,
                            originalTotal: 0,
                        }
                    );

                    const percentage = getNumberValueOrZero((consolidated / originalTotal) * 100);

                    return (
                        <TableFooter>
                            <TableRow classes={{ root: 'border-t' }} className="bg-base-200">
                                <TableCell className="border-b-0" colSpan={colSpan}>
                                    <Text className="text-base-500 uppercase font-bold">Total</Text>
                                </TableCell>
                                <TableCell className="border-b-0">
                                    <Text className="text-heading font-bold">{formatMoney(originalTotal)}</Text>
                                </TableCell>
                                {isOrderServiceOrigin && (
                                    <TableCell className="border-b-0">
                                        <Text className="text-heading font-bold">
                                            {formatMoney(consolidated)} <small className="ml-0.5">{formatNumberToPTBR(percentage)}%</small>
                                        </Text>
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableFooter>
                    );
                },
            }}
            page={page}
            {...listConfig}
        />
    );
};

export default SaleOrderProductsPage;
