import TableCell from '@mui/material/TableCell/TableCell';
import { VariableType, jsonToGraphQLQuery } from 'json-to-graphql-query';
import { CreateOrUpdateProps } from 'pages/shared/crud/create-or-update';
import { FormFactoryField } from 'types/form-factory';
import { Status } from 'types/general';
import { CrudPageProps } from 'types/graphql';
import { StockType } from 'types/models/stock';
import dictionary from 'utils/dictionary';
import { pkColumnsKey } from 'utils/environment';
import { stockTypes } from 'utils/statics';
import { number, object, string } from 'yup';

type FormProps = {
    name: string;
    type: StockType;
};

const formFields: FormFactoryField<FormProps>[] = [
    {
        name: 'name',
        type: 'text',
    },
    {
        name: 'type',
        type: 'select',
        staticOptions: stockTypes,
        disabled: true,
    },
];

const schema = object({
    name: string().required(dictionary.validation.required),
    type: number().oneOf([StockType.Default, StockType.OrderService]).required(dictionary.validation.required).default(StockType.Default),
});

const form: CreateOrUpdateProps<FormProps> = {
    modalClassName: 'w-[600px]',
    formClassName: '!grid-cols-2 gap-4',
    fields: formFields,
    schema,
    setCustomFields: (data) => {
        const arr = formFields.map((item) => {
            if (data?.type === StockType.Default) {
                return {
                    ...item,
                    disabled: true,
                };
            }

            return item;
        });

        return arr;
    },
};

const createOrUpdateStockMutation = (isEdit = false) => {
    return jsonToGraphQLQuery(
        {
            mutation: {
                __name: 'CreateOrUpdateStock',
                __variables: {
                    ...(isEdit
                        ? {
                              payload: 'StockSetInput',
                              id: 'Int!',
                          }
                        : {
                              payload: 'StockInsertInput!',
                          }),
                },
                item: {
                    __aliasFor: isEdit ? 'updateStockByPk' : 'insertStockOne',
                    __args: {
                        ...(isEdit
                            ? {
                                  [pkColumnsKey]: { id: new VariableType('id') },
                                  _set: new VariableType('payload'),
                              }
                            : {
                                  object: new VariableType('payload'),
                              }),
                    },
                    id: true,
                },
            },
        },
        { pretty: true }
    );
};

const config: CrudPageProps<{}>[] = [
    {
        title: 'Estoques',
        graphql: {
            table: 'stock',
            searchableField: 'name',
            columns: [
                {
                    name: 'id',
                    options: { display: 'excluded' },
                },
                {
                    name: 'name',
                    label: 'Nome',
                },
                {
                    name: 'type',
                    label: 'Tipo',
                    options: {
                        customBodyRender: (value: StockType) => {
                            const finderStatus = stockTypes.find((status) => status.value === value);

                            return finderStatus?.label;
                        },
                    },
                },
                {
                    name: 'branch.name',
                    label: 'Filial',
                    options: {
                        customBodyRender: (branchName: string) => branchName || '-',
                    },
                },
                {
                    name: 'actions',
                    label: 'Ações',
                    options: {
                        customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />,
                        customBodyRender: (value) => {
                            // Workaround to remove update option from dropdown menu
                            // Adding `system` property to item object (row data), the update option is removed from dropdown
                            if (value.props.item.type === StockType.Default) {
                                value.props.item['system'] = true;
                            }

                            return value;
                        },
                    },
                },
            ],
            where: {
                status: { _eq: Status.Active },
                finalized: { _eq: false },
            },
            orderBy: { name: 'ASC' },
        },
    },
    {
        title: 'Atualizar estoque',
        graphql: {
            table: 'stock',
            query: createOrUpdateStockMutation(true),
        },
        defaultValues: { name: '' },
        form,
    },
];

export default config;
