import * as React from 'react';
const SvgIcoExternal = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.822 32.822" width="1em" height="1em" {...props}>
        <g transform="translate(0 .822)">
            <path
                fill="currentColor"
                d="M24 22v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h5a2 2 0 0 0 2-2 2 2 0 0 0-2-2H3a3 3 0 0 0-3 3v22a3 3 0 0 0 3 3h22a3 3 0 0 0 3-3v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2Z"
            />
            <rect width={16} height={4} fill="currentColor" rx={2} transform="translate(16)" />
            <rect width={16} height={4} fill="currentColor" rx={2} transform="rotate(90 16 16)" />
            <rect width={32.296} height={3.971} fill="currentColor" rx={1.986} transform="rotate(-45 30.162 2.342)" />
        </g>
    </svg>
);
export default SvgIcoExternal;
