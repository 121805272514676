import { yupResolver } from '@hookform/resolvers/yup';
import AddButton from 'components/buttons/add';
import RemoveButton from 'components/buttons/remove';
import Input from 'components/core/form/input';
import Select from 'components/core/form/select';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import Empty from 'components/empty';
import FormButtons from 'components/form-buttons';
import { useEffect, useMemo } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useOutletContext, useParams } from 'react-router-dom';
import useCreateCompanyPartners from 'services/queries/companies/use-create-company-partners';
import useDeleteCompanyPartners from 'services/queries/companies/use-delete-company-partners';
import crudQueries from 'services/queries/crud/keys';
import useCreateOrUpdate from 'services/queries/crud/use-create-or-update';
import useGetDetails from 'services/queries/crud/use-get-details';
import { CrudDetails, CrudPageProps, GraphqlPaginationVariables } from 'types/graphql';
import { Company, CompanyPayload } from 'types/models/company';
import dictionary from 'utils/dictionary';
import { array, number, object, string } from 'yup';
import { companyTaxRegimeOptions } from './utils';
import { PersonType } from 'types/models/person';

const schema = object({
    name: string().required(dictionary.validation.required),
    taxRegime: number().required(dictionary.validation.required),
    companyPartners: array(
        object({
            person: object({
                name: string().required(dictionary.validation.required),
            }),
        })
    ),
});

const CreateOrUpdateCompanyPage = ({ title }: CrudPageProps<{}>) => {
    const { companyId } = useParams();
    const ctx = useOutletContext<GraphqlPaginationVariables<any>>();

    const detailsConfig: CrudDetails = {
        table: 'company',
        fields: ['name', 'taxRegime', 'companyPartners.person.name'],
    };

    const queryConfig = useMemo(
        () => ({
            queryName: 'CreateOrUpdateCompany',
            create: {
                mutation: 'insertCompanyOne',
                payloadType: 'CompanyInsertInput!',
            },
            update: {
                mutation: 'updateCompanyByPk',
                payloadType: 'CompanySetInput!',
            },
            id: companyId,
        }),
        [companyId]
    );

    const { mutateAsync: createOrUpdateCompany, isLoading: isSubmitting } = useCreateOrUpdate(
        {
            id: companyId,
            graphql: {
                query: crudQueries.createOrUpdate(queryConfig),
                table: 'company',
            },
        },
        ctx
    );

    const { formState, control, handleSubmit, reset } = useForm<CompanyPayload>({
        mode: 'onSubmit',
        defaultValues: {
            name: '',
            companyPartners: [],
        },
        resolver: yupResolver(schema) as any,
    });

    const { data: company } = useGetDetails<Company>(detailsConfig, companyId);

    const { mutateAsync: deleteCompanyPartners } = useDeleteCompanyPartners();
    const { mutateAsync: createCompanyPartners } = useCreateCompanyPartners();

    const { append, remove, fields } = useFieldArray({ name: 'companyPartners', control });

    const handleAdd = () => {
        return append({ person: { name: '' } });
    };

    const handleDelete = (index: number) => () => remove(index);

    const submit = async (data: CompanyPayload) => {
        try {
            if (Boolean(companyId)) {
                // Update company

                await createOrUpdateCompany({ name: data.name, taxRegime: data.taxRegime });

                // Delete all partners
                await deleteCompanyPartners(companyId!);

                // Insert new partners
                await createCompanyPartners(
                    data.companyPartners.map((item) => ({
                        companyId,
                        person: {
                            data: { name: item.person.name, type: PersonType.External },
                        },
                    }))
                );
            } else {
                const payload = {
                    name: data.name,
                    taxRegime: data.taxRegime,
                    companyPartners: {
                        data: data.companyPartners.map((item) => ({
                            person: {
                                data: { name: item.person.name, type: PersonType.External },
                            },
                        })),
                    },
                };

                // Create new company
                await createOrUpdateCompany(payload);
            }
        } catch (error) {
            console.log('submit', error);
        }
    };

    useEffect(() => {
        if (company) {
            reset(company);
        }
    }, [company, reset]);

    return (
        <Modal
            contentClassnames="w-[600px]"
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    {title}
                </Text>
            }
            closeOnClickOutside={false}>
            <form className="px-6 pb-6" onSubmit={handleSubmit(submit)}>
                <div className="mb-4">
                    <Controller name="name" control={control} render={({ field }) => <Input {...field} autoFocus={true} error={formState.errors.name?.message} label="Nome*" />} />
                </div>
                <div className="mb-4">
                    <Controller
                        name="taxRegime"
                        control={control}
                        render={({ field }) => {
                            const value = companyTaxRegimeOptions.find((taxRegime) => taxRegime.value === field.value);
                            return (
                                <Select
                                    {...field}
                                    value={value}
                                    options={companyTaxRegimeOptions}
                                    error={formState.errors.taxRegime?.message}
                                    label="Regime tributário*"
                                    onChange={(option: any) => field.onChange(option.value)}
                                />
                            );
                        }}
                    />
                </div>
                <div className="border border-base-300 rounded-[14px] p-4 mb-4">
                    <Text as="h5" variant="h6" className="text-heading mb-3">
                        Sócios
                    </Text>
                    {Boolean(fields.length) ? (
                        fields.map((item, index) => {
                            return (
                                <div key={item.id} className="border border-base-300 rounded-[14px] p-4 mb-4 group relative">
                                    <RemoveButton onClick={handleDelete(index)} />
                                    <Controller
                                        name={`companyPartners.${index}.person.name`}
                                        control={control}
                                        render={({ field }) => <Input {...field} autoFocus={true} error={formState.errors.companyPartners?.[index]?.person?.name?.message} label="Nome*" />}
                                    />
                                </div>
                            );
                        })
                    ) : (
                        <Empty title="Nenhum sócio adicionado ainda" className="mb-2" />
                    )}
                    <AddButton onClick={handleAdd}>Novo sócio</AddButton>
                </div>
                <FormButtons isLoading={isSubmitting} />
            </form>
        </Modal>
    );
};

export default CreateOrUpdateCompanyPage;
