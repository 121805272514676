import { useQueries } from 'react-query';
import api from 'services/api';
import { getResumesReportsKey } from './keys';
import qs from 'qs';
import { GenericReportFilterPayload } from 'pages/private/reports/screens/types';
import { ResumesConfigs } from 'types/models/reports';

const useGetResumesReports = (configs: ResumesConfigs[], filters?: GenericReportFilterPayload) => {
    const query = qs.stringify(filters, { addQueryPrefix: true, encode: false, arrayFormat: 'brackets', skipNulls: true });

    const request = async (endpoint: string) => {
        const url = endpoint + query;
        const { data } = await api.get(url);

        return data;
    };

    const requests = configs.map((config) => ({
        queryKey: getResumesReportsKey(config.endpoint, query),
        queryFn: () => request(config.endpoint),
        enabled: Boolean(config.endpoint),
    }));

    return useQueries(requests);
};

export default useGetResumesReports;
