import { useMemo } from 'react';
import pick from 'lodash/pick';
import { useOutletContext, useParams } from 'react-router-dom';
import CustomerOrProviderForm from 'components/forms/company/form';
import useCreateOrUpdateCustomer from 'services/queries/customers/use-create-or-update-customer';
import useGetCustomer from 'services/queries/customers/use-get-customer';
import useUploadFile from 'services/queries/files/use-upload-file';
import { CrudPageProps, GraphqlPaginationVariables } from 'types/graphql';
import { Status } from 'types/general';
import { AddressClassNames } from 'types/models/address';
import { customerDetailsQueryFields } from './utils';
import { PersonContractType } from 'types/models/person';
import omit from 'lodash/omit';

type CreateOrUpdateCustomerPageProps = {
    onClose?: () => void;
    onSuccess?: (customerId: number, title: string) => void;
} & CrudPageProps<{}>;

const CreateOrUpdateCustomerPage = ({ title, onClose, onSuccess }: CreateOrUpdateCustomerPageProps) => {
    const { customerId } = useParams();
    const params = useOutletContext<GraphqlPaginationVariables<any>>();

    const isUpdate = !!customerId;

    const { mutateAsync: createOrUpdateCustomer, isLoading: isSubmittingCreate } = useCreateOrUpdateCustomer(params, !onSuccess && !customerId);

    const { data: customer, isLoading: isLoadingCustomerDetails } = useGetCustomer(customerDetailsQueryFields, customerId);

    const { isLoading: isLoadingFile, uploadFile } = useUploadFile();

    const defaultValues = useMemo(
        () => ({
            id: customer?.id,
            title: customer?.title || '',
            ...(customer?.type === PersonContractType.Juridic && {
                juridicName: customer?.juridicName || '',
                juridicFantasyName: customer?.juridicFantasyName || '',
                juridicDocument: customer?.juridicDocument || '',
                hasStateRegistration: customer?.hasStateRegistration || false,
            }),
            ...(customer?.type === PersonContractType.Personal && {
                document: customer?.document || '',
            }),
            phones: customer?.phones || [],
            emails: customer?.emails || [],
            status: Status.Active,
            type: customer?.type,
            file: customer?.file,
            customerContacts: (customer?.customerContacts || []).map((item) => ({
                id: item.id,
                name: item.person?.name,
                title: item.person?.title,
                gender: item.person?.gender,
                email: item.person?.email,
                personId: item.person?.id,
                phones: item.person?.phones,
            })),
            ...(!isUpdate && {
                address: {
                    ...customer?.addressCustomers?.[0]?.address,
                    stateId: customer?.addressCustomers?.[0]?.address?.stateId,
                    cityId: customer?.addressCustomers?.[0]?.address?.cityId,
                    compliment: customer?.addressCustomers?.[0]?.address?.compliment || '',
                },
            }),
        }),
        [customer, isUpdate]
    );

    const handleSubmit = async (data: any) => {
        try {
            const hasChangedImage = !data.file?.id && !!data.file?.path;

            if (hasChangedImage) {
                await uploadFile(data?.file, ({ id }) => (data.fileId = id), true);
            } else {
                data.fileId = data?.file?.id ?? null;
            }

            const addressCustomers = pick(data.address, ['cityId', 'stateId', 'compliment', 'neighbourhood', 'number', 'street', 'zip']);
            delete data.file;

            if (isUpdate) {
                const rest = omit(data, 'address', 'bankAccount', 'customerContacts');

                const payload = {
                    id: +customerId,
                    data: rest,
                };

                await createOrUpdateCustomer(payload);
            } else {
                delete data.address;

                const payload: any = {
                    status: data.status,
                    title: data.title,
                    type: data?.type,
                    ...(Boolean(data.customerContacts.length) && {
                        customerContacts: {
                            data: data.customerContacts.map((item) => ({
                                person: { data: item },
                            })),
                        },
                    }),
                    ...(Boolean(data.type === PersonContractType.Juridic) && {
                        juridicDocument: data.juridicDocument,
                        juridicFantasyName: data.juridicFantasyName,
                        juridicName: data.juridicName,
                        hasStateRegistration: data.hasStateRegistration,
                    }),
                    ...(Boolean(data.type === PersonContractType.Personal) && {
                        document: data.document,
                    }),
                    ...(Boolean(data.phones.length) && { phones: data.phones }),
                    ...(Boolean(data.emails.length) && { emails: data.emails }),
                    ...(Boolean(data.fileId) && { fileId: data.fileId }),
                    ...(Boolean(addressCustomers.stateId) && {
                        addressCustomers: {
                            data: {
                                address: {
                                    data: {
                                        cityId: addressCustomers.cityId,
                                        stateId: addressCustomers.stateId,
                                        className: AddressClassNames.Customer,
                                        compliment: addressCustomers.compliment,
                                        neighbourhood: addressCustomers.neighbourhood,
                                        number: addressCustomers.number,
                                        street: addressCustomers.street,
                                        zip: addressCustomers.zip,
                                    },
                                },
                            },
                        },
                    }),
                };

                const { item: customer } = await createOrUpdateCustomer(payload);

                onSuccess?.(customer.id, customer.title);
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    return (
        <CustomerOrProviderForm
            isCustomer={true}
            bankAccountFieldName="bankAccount"
            contactFieldName="customerContacts"
            defaultValues={defaultValues}
            isBuilding={isLoadingCustomerDetails}
            isSubmitting={isSubmittingCreate || isLoadingFile}
            title={title || ''}
            onSubmit={handleSubmit}
            onClose={onClose}
            showImage={true}
        />
    );
};

export default CreateOrUpdateCustomerPage;
