import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import useTheme from '@mui/material/styles/useTheme';
import classNames from 'classnames';
import Card from 'components/core/card';
import SvgIcoTrash from 'components/core/icon/files/ico-trash';
import Spinner from 'components/core/spinner';
import Text from 'components/core/text';
import { useCallback, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useParams } from 'react-router-dom';
import useGetOrderServiceWorkReport from 'services/queries/order-services/use-get-order-service-work-report';

import { OrderServiceWorkReport } from 'types/models/order-service';

type WorkReportGalleryItemProps = {
    item: OrderServiceWorkReport;
    index: number;
    onSetWorkReportToDeleteId: (workReportId: number) => void;
};

const WorkReportGalleryItem = ({ item, index, onSetWorkReportToDeleteId }: WorkReportGalleryItemProps) => {
    const [activeId, setActiveId] = useState<number>();
    const [actualIndexPhoto, setActualIndexPhoto] = useState(0);

    const { palette } = useTheme();
    const { orderServiceId, saleOrderId } = useParams();

    const { data: workReport, isLoading: isLoadingWorkReport } = useGetOrderServiceWorkReport(orderServiceId, saleOrderId, activeId);

    const photosUrl = workReport?.files?.map((item) => item.url) || [];

    const handleNextPhoto = useCallback(() => setActualIndexPhoto((actualIndexPhoto + 1) % photosUrl.length), [actualIndexPhoto, photosUrl.length]);

    const handlePrevPhoto = useCallback(() => setActualIndexPhoto((actualIndexPhoto + photosUrl.length - 1) % photosUrl.length), [actualIndexPhoto, photosUrl.length]);

    const handleRemoveWorkReport: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        event.stopPropagation();

        onSetWorkReportToDeleteId(item.id);
    };

    return (
        <>
            <Card onClick={setActiveId.bind(this, item.id)} className="group hover:shadow-sm shadow-md border-gray-300 border !border-solid rounded-md" key={index}>
                <div className="min-h-[200px] max-h-[200px] w-full relative">
                    <img
                        className={classNames('hover:cursor-pointer w-full min-h-[200px] max-h-[200px] object-cover rounded-t-md', isLoadingWorkReport && actualIndexPhoto === item.id && 'opacity-50')}
                        src={item.file.url}
                        alt={item.description}
                    />
                    {isLoadingWorkReport && <Spinner />}
                    <div className="bg-white shadow-lg border border-gray-200 rounded-full hidden h-[32px] w-[32px] group-hover:flex absolute top-[10px] right-[10px]">
                        <Tooltip title="Remover relatório de obra">
                            <IconButton size="small" type="button" color="error" onClick={handleRemoveWorkReport}>
                                <SvgIcoTrash width={16} height={16} color={palette.error[500]} />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                <div className="p-3 mb-2">
                    <Text className="max-w-full text-ellipsis overflow-hidden line-clamp-2 text-heading !text-base font-medium" as="p" title={item?.description || '-'}>
                        {item.description || '-'}
                    </Text>
                    <Text className="text-base-500" variant="body.medium.xs" as="span">
                        {item.title}
                    </Text>
                </div>
            </Card>
            {Boolean(photosUrl.length) && (
                <Lightbox
                    mainSrc={photosUrl[actualIndexPhoto] || ''}
                    nextSrc={photosUrl[(actualIndexPhoto + 1) % photosUrl.length] || ''}
                    prevSrc={photosUrl[(actualIndexPhoto + photosUrl.length - 1) % photosUrl.length] || ''}
                    onMovePrevRequest={handlePrevPhoto}
                    onMoveNextRequest={handleNextPhoto}
                    onImageLoad={window.dispatchEvent.bind(this, new Event('resize'))}
                    onCloseRequest={setActiveId.bind(this, undefined)}
                />
            )}
        </>
    );
};

export default WorkReportGalleryItem;
