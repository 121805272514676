import { Controller, useFormContext } from 'react-hook-form';
import { PropsWithChildren } from 'react';
import Select from 'components/core/form/select';
import { handleChangeReportSelect } from '../screens/utils';
import { DataContentFiltersConfig, ReportFilterPayload } from '../screens/types';
import { saleOrderTypes } from 'utils/statics';
import FilterPeriod from 'pages/private/payment-order/filter/period';

type GenericReportFiltersContentProps = {
    withConsultants?: boolean;
    labelPeriod?: string;
    withTypes?: boolean;
    dataContentFiltersConfig: DataContentFiltersConfig;
};

const GenericReportFiltersContent = ({
    withConsultants = true,
    labelPeriod = 'Período',
    children,
    withTypes = true,
    dataContentFiltersConfig,
}: PropsWithChildren<GenericReportFiltersContentProps>) => {
    const { companies: companies = [], branches: branches = [], consultants: consultants = [] } = dataContentFiltersConfig;

    const { control, formState } = useFormContext<ReportFilterPayload['filter']>();

    return (
        <>
            <Controller
                control={control}
                name="branch"
                render={({ field }) => {
                    const value = branches.find((item) => item.value === field?.value);

                    return (
                        <Select
                            {...field}
                            options={branches}
                            value={value}
                            isDisabled={!branches.length}
                            isClearable={true}
                            placeholder="Selecione uma filial"
                            label="Filial"
                            error={formState.errors.branch?.message}
                            parentClassName="min-w-full"
                            onChange={handleChangeReportSelect(field.onChange)}
                        />
                    );
                }}
            />
            <Controller
                control={control}
                name="companies"
                render={({ field }) => {
                    const value = companies.filter((item) => field?.value?.includes(item.value));

                    return (
                        <Select
                            {...field}
                            options={companies}
                            value={value}
                            isDisabled={!companies.length}
                            isClearable={true}
                            isMulti={true}
                            placeholder="Selecione uma empresa"
                            label="Empresa"
                            error={formState.errors.companies?.message}
                            parentClassName="min-w-full"
                            onChange={handleChangeReportSelect(field.onChange)}
                        />
                    );
                }}
            />
            {withConsultants && (
                <Controller
                    control={control}
                    name="consultants"
                    render={({ field }) => {
                        const value = consultants.filter((item) => field?.value?.includes(item.value));

                        return (
                            <Select
                                {...field}
                                options={consultants}
                                value={value}
                                isDisabled={!consultants.length}
                                isClearable={true}
                                isMulti={true}
                                placeholder="Selecione um consultor"
                                label="Consultor"
                                error={formState.errors.consultants?.message}
                                parentClassName="min-w-full"
                                onChange={handleChangeReportSelect(field.onChange)}
                            />
                        );
                    }}
                />
            )}
            {children}
            {withTypes && (
                <Controller
                    control={control}
                    name="types"
                    render={({ field }) => {
                        const value = saleOrderTypes.filter((item) => field?.value?.includes(item.value));

                        return (
                            <Select
                                {...field}
                                options={saleOrderTypes}
                                value={value}
                                isClearable={true}
                                isMulti={true}
                                placeholder="Selecione um tipo do pedido"
                                label="Tipo do Pedido"
                                error={formState.errors.types?.message}
                                parentClassName="min-w-full"
                                onChange={handleChangeReportSelect(field.onChange)}
                            />
                        );
                    }}
                />
            )}
            <FilterPeriod
                control={control}
                label={labelPeriod}
                firstInput={{
                    inputProps: {
                        label: 'Início',
                        name: 'startDate',
                        error: formState.errors.startDate?.message,
                    },
                }}
                secondInput={{
                    inputProps: {
                        label: 'Fim',
                        name: 'endDate',
                        error: formState.errors.endDate?.message,
                    },
                }}
            />
        </>
    );
};

export default GenericReportFiltersContent;
