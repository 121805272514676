import ServiceOrderPurchaseScope, { ServiceOrderPurchaseScopePayload } from '../purchase-scope';
import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import useCreateOrderServicePurchaseScope from 'services/queries/order-services/use-create-order-service-purchase-scope';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { getOrderServiceKey } from 'services/queries/order-services/keys';
import { formatDate } from 'utils/date';

const CreatePurchaseScopePage = ({ page }: WithPagePermissionsProps) => {
    const { saleOrderId, orderServiceId } = useParams();

    const queryClient = useQueryClient();

    const { mutateAsync: createPurchaseScope, isLoading: isCreatingPurchaseScope } = useCreateOrderServicePurchaseScope(saleOrderId, orderServiceId);

    const handleSubmit = async (data: ServiceOrderPurchaseScopePayload) => {
        try {
            const payload = {
                purchaseProducts: data.purchaseProducts.map((item) => ({
                    product: item.product,
                    quantity: item.quantity,
                    value: item.productValue,
                    provision: formatDate(item.provision, 'YYYY-MM-DD'),
                })),
            };

            await createPurchaseScope(payload);
            queryClient.invalidateQueries(getOrderServiceKey(orderServiceId));
        } catch (e) {
            console.error('CreatePurchaseScopePage - handleSubmit: ', e);
        }
    };

    return (
        <Modal
            contentClassnames="min-w-[90%] max-h-full"
            closeOnClickOutside={false}
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    {page?.title}
                </Text>
            }>
            <ServiceOrderPurchaseScope onSubmit={handleSubmit} isSubmitting={isCreatingPurchaseScope} />
        </Modal>
    );
};

export default CreatePurchaseScopePage;
