import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import Select from 'components/core/form/select';
import Text from 'components/core/text';
import { Option } from 'types/general';
import DatePicker from 'components/core/datepicker';
import CurrencyInput from 'components/core/form/currency';
import { ServiceOrderFinancialPayload } from './financial';
import Table from '@mui/material/Table/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import RemoveButton from 'components/buttons/remove';
import AddButton from 'components/buttons/add';
import { formatMoney } from 'utils/money';
import TableContainer from '@mui/material/TableContainer';

type FinancialItemUpdateProps = {
    paymentForms: Option[];
    transactions: ServiceOrderFinancialPayload['transactions'];
    totalSaleScope: number;
};

const UpdateFinancial = ({ paymentForms, transactions, totalSaleScope = 0 }: FinancialItemUpdateProps) => {
    const { formState, control, watch } = useFormContext<ServiceOrderFinancialPayload>();

    const { remove, append } = useFieldArray({ control, name: 'transactions' });

    const total = watch('transactions').reduce((acc, curr) => acc + (curr.grossValue || 0), 0);
    const remainingValue = Number(totalSaleScope.toFixed(2)) - Number(total.toFixed(2));

    const handleAddInstallment = () => {
        append(
            {
                dueDate: '',
                paymentForm: transactions[0].paymentForm,
                grossValue: 0,
                paid: false,
            },
            { shouldFocus: false }
        );
    };

    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Text className="text-base-500 uppercase">Referente a parcela</Text>
                            </TableCell>
                            <TableCell>
                                <Text className="text-base-500 uppercase">Vencimento</Text>
                            </TableCell>
                            <TableCell>
                                <Text className="text-base-500 uppercase">Valor da Parcela</Text>
                            </TableCell>
                            <TableCell>
                                <Text className="text-base-500 uppercase">Forma de Pagamento</Text>
                            </TableCell>
                            <TableCell>
                                <Text className="text-base-500 uppercase">Ações</Text>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {transactions.map((item, index) => {
                            const getError = (name: string) => formState?.errors?.installments?.[index]?.[name]?.message;
                            const getPath = (key: keyof ServiceOrderFinancialPayload['transactions'][0]) => `transactions.${index}.${key}` as const;

                            const canShowRemoveButton = !item.paid && index !== 0;

                            return (
                                <TableRow
                                    classes={{
                                        root: !!item.transactionId && !!item.paid ? 'bg-base-300 opacity-30 cursor-no-drop pointer-events-none' : 'opacity-100 hover:bg-base-200',
                                    }}
                                    key={(item as any).id || item.transactionId}>
                                    <TableCell>
                                        <span className="text-heading text-base font-medium">Parcela {index + 1}</span>
                                    </TableCell>
                                    <TableCell>
                                        <Controller
                                            name={getPath('dueDate') as any}
                                            control={control}
                                            render={({ field }) => (
                                                <DatePicker
                                                    inputProps={{
                                                        placeholderText: 'Vencimento',
                                                        error: getError('dueDate'),
                                                        ...field,
                                                    }}
                                                />
                                            )}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Controller
                                            name={getPath('grossValue')}
                                            control={control}
                                            render={({ field }) => {
                                                return (
                                                    <CurrencyInput
                                                        left={
                                                            <Text as="span" variant="body.medium.sm" className="text-heading">
                                                                R$
                                                            </Text>
                                                        }
                                                        value={field.value as number}
                                                        onValueChange={({ floatValue = 0 }) => field.onChange(floatValue)}
                                                        placeholder="Valor"
                                                        parentClassName="w-[150px]"
                                                        error={getError('value')}
                                                    />
                                                );
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Controller
                                            name={getPath('paymentForm')}
                                            control={control}
                                            render={({ field }) => {
                                                const value = paymentForms.find((item) => item.value === field.value);

                                                return (
                                                    <Select
                                                        {...field}
                                                        value={value}
                                                        options={paymentForms}
                                                        placeholder="Forma de Pagamento"
                                                        error={getError('paymentForm')}
                                                        onChange={(option: any) => field.onChange(option.value)}
                                                    />
                                                );
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>{canShowRemoveButton && <RemoveButton className="!static opacity-100 mt-0" onClick={remove.bind(this, index)} />}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="bg-secondary-100 py-4 px-6 bg-opacity-20 flex items-center">
                <AddButton onClick={handleAddInstallment}>Nova parcela</AddButton>
                <div className="flex-1 text-right">
                    <div>
                        <Text className="text-base-700">Total das parcelas: </Text>
                        <Text variant="body.medium.2xs" className="text-heading">
                            {formatMoney(total)}
                        </Text>
                    </div>
                    {!!remainingValue && (
                        <div>
                            <Text className="text-system-danger-500">{Math.sign(remainingValue) !== -1 ? 'Faltando: ' : 'Ultrapassando: '}</Text>
                            <Text variant="body.medium.2xs" className="text-system-danger-500">
                                {formatMoney(Math.abs(remainingValue))}
                            </Text>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default UpdateFinancial;
