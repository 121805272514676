import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';
import useToast from 'hooks/toast/use-toast';
import { useMutation } from 'react-query';
import api from 'services/api';

type OrderServicePurchaseScopePayload = {
    purchaseProducts: Array<{
        product: number;
        value: number;
        quantity: number;
        provision: string;
    }>;
};

const useCreateOrderServicePurchaseScope = (saleOrderId?: string, orderServiceId?: string) => {
    const { showToast } = useToast();
    const goToParentRoute = useNavigateToParentRoute();

    const request = async (payload: OrderServicePurchaseScopePayload) => {
        return api.post(`/sale-orders/${saleOrderId}/service-orders/${orderServiceId}/purchase-products`, payload);
    };

    return useMutation(request, {
        onError: () => {
            showToast('Ocorreu um erro ao criar um escopo de compra, por favor, tente novamente.', 'danger');
        },
        onSuccess: () => {
            showToast('Escopo de compra cadastrado com sucesso!', 'success');
            goToParentRoute();
        },
    });
};

export default useCreateOrderServicePurchaseScope;
