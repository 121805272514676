import InterMenuTab, { InterMenuTabProps } from './tab';

const InterMenuMobile = (props: InterMenuTabProps) => {
    return (
        <div className="lg:hidden bg-base-100 border-r border-r-base-300 w-full flex flex-col fixed bottom-0 left-0 border-t border-t-base-300 z-10">
            <InterMenuTab {...props} />
        </div>
    );
};

export default InterMenuMobile;
