import { pkColumnsKey } from 'utils/environment';

const queries = {
    createProvider: `mutation CreateProvider($payload: ProviderInsertInput!) {
        item: insertProviderOne(object: $payload) {
          id
          title
        }
      }`,
    editProvider: `mutation UpdateProvider($id: Int!, $payload: ProviderSetInput!) {
        item: updateProviderByPk(${pkColumnsKey}: {id: $id}, _set: $payload) { id }
    }`,
    getProvider: (fields: string) => `query GetProvider($id: Int!) {
        provider: providerByPk(id: $id) {
            ${fields}
        }
    }`,
    createContact: `mutation CreateProviderContact($payload: ProviderContactInsertInput!) {
        item: insertProviderContactOne(object: $payload) { id }
    }`,
    updateContact: `mutation EditProviderContactPerson($id: Int!, $payload: PersonSetInput!) {
        item: updatePersonByPk(${pkColumnsKey}: {id: $id}, _set: $payload) { id }
    }`,
    createBankAccount: `mutation CreateProviderBankAccount($payload: ProviderBankAccountInsertInput!) {
        item: insertProviderBankAccountOne(object: $payload) { id }
    }`,
    updateBankAccount: `mutation UpdateProviderBankAccount($id: Int!, $payload: BankAccountSetInput!) {
        item: updateBankAccountByPk(${pkColumnsKey}: {id: $id}, _set: $payload) { id }
    }`,
};

export default queries;

export const getExternalProviderKey = (providerId: number) => ['provider', providerId, 'external'];
