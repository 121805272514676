import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import { getPendingPurchaseOrderProductsApiKey } from './keys';
import api from 'services/api';

type Payload = {
    purchaseOrderProducts: number[];
};

const useDeletePurchaseOrderProducts = (purchaseOrderId?: string) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const request = async (payload: Payload) => {
        return api({
            method: 'delete',
            url: '/purchase-order-products/actions/delete',
            data: payload,
        });
    };

    return useMutation(request, {
        onSuccess: () => {
            if (purchaseOrderId) {
                queryClient.invalidateQueries(getPendingPurchaseOrderProductsApiKey(purchaseOrderId));
            }

            showToast(`Produto(s) apagado(s) com sucesso`, 'success');
        },
        onError: () => {
            showToast(`Ocorreu um erro ao apagar o(s) produto(s)`, 'danger');
        },
    });
};

export default useDeletePurchaseOrderProducts;
