import TableCell from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Text from 'components/core/text';
import React from 'react';
import { formatMoney } from 'utils/money';

type ExpenseProductTableFooterPropsKeys = 'withholdingTaxIss' | 'withholdingTaxInss' | 'discount' | 'addition' | 'total';

type ExpenseProductsTableFooterProps = {
    [K in ExpenseProductTableFooterPropsKeys]: number;
} & {
    decimalPlaces?: number;
};

const ExpenseProductTableFooter = ({ withholdingTaxIss = 0, withholdingTaxInss = 0, discount = 0, addition = 0, total = 0, decimalPlaces = 2 }: ExpenseProductsTableFooterProps) => {
    return (
        <TableFooter>
            <TableRow>
                <TableCell classes={{ root: 'border-none' }} colSpan={5} />
                <TableCell className="sticky right-0" classes={{ root: 'border-none' }} colSpan={2}>
                    <Text className="uppercase mb-2 text-system-danger-500 block text-right">
                        ISS Retido: <span className="ml-4">{formatMoney(withholdingTaxIss)}</span>
                    </Text>
                    <Text className="uppercase mb-2 text-system-danger-500 block text-right">
                        INSS Retido: <span className="ml-4">{formatMoney(withholdingTaxInss)}</span>
                    </Text>
                    <Text className="uppercase mb-2 text-system-danger-500 block text-right">
                        Descontos: <span className="ml-4">{formatMoney(discount)}</span>
                    </Text>
                    <Text className="uppercase mb-6 text-system-success-500 block text-right">
                        Acréscimos: <span className="ml-4">{formatMoney(addition)}</span>
                    </Text>
                    <Text className="uppercase text-base-700 font-bold block text-right">
                        Total: <span className="ml-4">{formatMoney(total, undefined, decimalPlaces)}</span>
                    </Text>
                </TableCell>
            </TableRow>
        </TableFooter>
    );
};

export default ExpenseProductTableFooter;
