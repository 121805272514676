import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { useForm } from 'react-hook-form';
import { useOutletContext, useParams } from 'react-router-dom';
import FormFactory from 'components/core/form/factory';
import { FormFactoryField } from 'types/form-factory';
import { yupResolver } from '@hookform/resolvers/yup';
import { AnyObjectSchema } from 'yup';
import useCreateOrUpdate from 'services/queries/crud/use-create-or-update';
import { CrudDetails, CrudPageProps, GraphqlPaginationVariables } from 'types/graphql';
import useGetDetails from 'services/queries/crud/use-get-details';
import FormButtons from 'components/form-buttons';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';

export type CreateOrUpdateProps<T> = {
    fields: FormFactoryField<T>[];
    beforeSend?: (data?: any) => any;
    beforeSetInitialData?: (data: any) => any;
    setCustomFields?: (data: any) => FormFactoryField<T>[];
    modalClassName?: string;
    formClassName?: string;
    schema?: AnyObjectSchema;
};

const CreateOrUpdatePage = <T,>({ title, form, graphql, api, details, defaultValues }: Pick<CrudPageProps<T>, 'title' | 'form' | 'graphql' | 'api' | 'details' | 'defaultValues'>) => {
    const { id } = useParams();
    const goToParentRoute = useNavigateToParentRoute();

    const ctx = useOutletContext<GraphqlPaginationVariables<any>>();

    const detailsConfig: CrudDetails = {
        table: graphql?.table,
        fields: form?.fields?.map(({ name }) => name) || ([] as any[]),
        query: details?.query,
    };

    const { data: detailsData } = useGetDetails(detailsConfig, id);

    const { mutate, isLoading } = useCreateOrUpdate({ graphql, api, id }, ctx);

    const { fields = [], modalClassName = 'w-[600px]', formClassName, schema, beforeSetInitialData, beforeSend, setCustomFields } = form || {};

    const customFields = setCustomFields?.(detailsData) || fields || [];

    const methods = useForm<any>({
        mode: 'onSubmit',
        defaultValues,
        ...(schema && { resolver: yupResolver(schema) }),
    });

    const submit = (data: T) => {
        const payload = beforeSend?.(data);

        mutate(payload || data);
    };

    return (
        <Modal
            contentClassnames={modalClassName}
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    {title}
                </Text>
            }
            closeOnClickOutside={false}>
            <form className="px-6 pb-6" onSubmit={methods.handleSubmit(submit)}>
                <FormFactory beforeSetInitialData={beforeSetInitialData} fields={customFields} className={formClassName} methods={methods} initialData={detailsData} />
                <FormButtons
                    isLoading={isLoading}
                    cancelButton={{
                        onClick: goToParentRoute,
                    }}
                />
            </form>
        </Modal>
    );
};

export default CreateOrUpdatePage;
