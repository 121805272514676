import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ExternalButton from 'components/buttons/external';
import Badge from 'components/core/badge';
import SvgIcoShow from 'components/core/icon/files/ico-show';
import DownloadButton from 'components/download';
import { MUIDataTableColumn } from 'mui-datatables';
import { getNumberValueOrZero } from 'pages/private/expenses/details/tabs/transactions/utils';
import { DatatableColumn } from 'types/graphql';
import { ExpenseFavored } from 'types/models/expense';
import { PaymentFlowTransactionStatus } from 'types/models/payment-flow';
import dictionary from 'utils/dictionary';
import { formatMoney } from 'utils/money';
import { object, string } from 'yup';

export const transactionsTableColumns: DatatableColumn[] = [
    {
        name: 'id',
        options: { display: 'excluded' },
    },
    {
        name: 'transaction.id',
        options: { display: 'excluded' },
    },
    {
        name: 'transaction.code',
        label: 'Código',
        options: {
            customBodyRender: (code, { rowData }) => {
                return !!code ? <ExternalButton to={`/app/ordens-de-pagamento/${rowData[1]}`}>{code}</ExternalButton> : '-';
            },
        },
    },
    {
        name: 'transaction.description',
        label: 'Descrição',
        options: { customBodyRender: (description = '-') => description },
    },
    {
        name: 'transaction.expense.favored',
        label: 'Favorecido',
        options: {
            customBodyRender: (favored: Partial<ExpenseFavored>) => {
                const path = favored.juridic ? `/app/fornecedores/${favored.favoredId}` : `/app/pessoas/${favored.favoredId}`;

                return <ExternalButton to={path}>{favored.title}</ExternalButton>;
            },
        },
    },
    {
        name: 'transaction.paymentForm.name',
        label: 'Forma de pagamento',
        options: { customBodyRender: (paymentForm = '-') => paymentForm },
    },
    {
        name: 'eligibleForRemittance',
        label: 'Habilitado para remessa',
        options: { customBodyRender: (eligibleForRemittance = false) => <Badge variant={eligibleForRemittance ? 'success' : 'error'}>{eligibleForRemittance ? 'Sim' : 'Não'}</Badge> },
    },
    {
        name: 'inRemittance',
        label: 'Na remessa',
        options: { customBodyRender: (inRemittance = false) => <Badge variant={inRemittance ? 'success' : 'error'}>{inRemittance ? 'Sim' : 'Não'}</Badge> },
    },
    {
        name: 'paymentFlowTransactionStatus',
        label: 'Status',
        options: {
            customBodyRender: (paymentFlowTransactionStatus) => {
                const types = {
                    [PaymentFlowTransactionStatus.Deleted]: { label: 'Apagado', variant: 'error' },
                    [PaymentFlowTransactionStatus.Waiting]: { label: 'Aguardando', variant: 'warning' },
                    [PaymentFlowTransactionStatus.Paid]: { label: 'Pago', variant: 'success' },
                    [PaymentFlowTransactionStatus.Transferred]: { label: 'Transferido para outro fluxo', variant: 'info' },
                    [PaymentFlowTransactionStatus.Debt]: { label: 'Dívida', variant: 'info' },
                };

                const status = types[paymentFlowTransactionStatus] || types[PaymentFlowTransactionStatus.Waiting];

                return <Badge variant={status.variant}>{status.label}</Badge>;
            },
        },
    },
    {
        name: 'transaction.netValue',
        label: 'Valor',
        options: { customBodyRender: (netValue = 0) => formatMoney(netValue) },
    },
];

export const paymentFlowModalConfirmationActions = {
    delete: {
        title: 'Apagar fluxos',
        description: (
            <>
                Você tem certeza que deseja <strong className="text-system-danger-500">apagar</strong> estes fluxos?
            </>
        ),
    },
    transfer: {
        title: 'Transferir para novo fluxo',
        description: <>Você tem certeza que deseja fazer a transferência para um novo fluxo desses fluxos?</>,
    },
};

export const paymentFlowModalSchema = object({
    provision: string().required(dictionary.validation.required),
});

export const remittanceFilesColumns = (onSetRemittanceToShowId: React.Dispatch<React.SetStateAction<number | null>>): MUIDataTableColumn[] => [
    {
        name: 'id',
        options: {
            display: 'excluded',
        },
    },
    {
        name: 'total',
        label: 'Valor',
        options: {
            customBodyRender: (value) => formatMoney(getNumberValueOrZero(value)),
        },
    },
    {
        name: '',
        label: 'Arquivo',
        options: {
            customBodyRender: (_, tableMeta) => {
                const fileId = tableMeta.rowData[4];
                const filename = tableMeta.rowData[3];

                return <DownloadButton fileId={fileId} fileName={filename} />;
            },
        },
    },
    {
        name: 'file.originalName',
        options: { display: 'excluded' },
    },
    {
        name: 'file.id',
        options: { display: 'excluded' },
    },
    {
        name: 'actions',
        label: 'Ações',
        options: {
            customBodyRender: (_, tableMeta) => {
                const remittanceId = tableMeta.rowData[0];

                return (
                    <div className="flex items-center gap-2">
                        <Tooltip placement="top" title="Ver detalhes da remessa" disableFocusListener={true}>
                            <IconButton
                                TouchRippleProps={{ classes: { child: 'text-system-info-100' } }}
                                className="hover:bg-opacity-30 hover:bg-system-info-100"
                                onClick={onSetRemittanceToShowId.bind(this, remittanceId)}>
                                <SvgIcoShow width={16} height={16} className="text-system-info-500" />
                            </IconButton>
                        </Tooltip>
                    </div>
                );
            },
        },
    },
];
