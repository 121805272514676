import { useCallback, useEffect, useMemo, useState } from 'react';
import _at from 'lodash/at';
import _set from 'lodash/set';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import { useLocation } from 'react-router-dom';
import List from './list';
import useGetAll from 'services/queries/crud/use-get-all';
import { CrudPageProps, GraphqlPaginationVariables } from 'types/graphql';
import ConfirmModal from 'components/core/modal/confirm';
import { Rule } from 'types/permissions';
import { Page } from 'types/models/page';
import { slugify } from 'utils/string';
import { ListPageContextProvider } from './context';
import { MUIDataTableOptions } from 'mui-datatables';
import useDeleteMany from 'services/queries/crud/use-delete-many';
import { useQueryClient } from 'react-query';
import useToast from 'hooks/toast/use-toast';
import { getAllKey } from 'services/queries/crud/keys';
import useDelete from 'services/queries/crud/use-delete';
import useConfig from 'store/config/use-config';

type ListPageProps<T> = {
    page?: Page;
    additionalContextData?: any;
    className?: string;
    selectedRowsIndexesHook?: [number[], React.Dispatch<React.SetStateAction<number[]>>];
    onSelectRows?: (arr: any[]) => void;
    customActions?: (item: any) => any[];
    customFooterActions?: (item: any) => any[];
} & CrudPageProps<T>;

const INITAL_GRAPHQL = {
    columns: [],
    table: '',
    searchableField: '',
};

const ListPage = <T,>({
    additionalContextData,
    graphql = INITAL_GRAPHQL,
    title,
    createPagePath = 'novo',
    api,
    hideHeader = false,
    hideCreateButton = false,
    withBorder = false,
    page,
    advancedSearchComponent,
    customTableOptions,
    className,
    queriesToInvalidate,
    selectedRowsIndexesHook,
    onSelectRows,
    customActions,
    customFooterActions,
}: ListPageProps<T>) => {
    const location = useLocation();
    const { showToast } = useToast();
    const { config } = useConfig();
    const queryClient = useQueryClient();

    const getInitialParams = useCallback(() => {
        const orderBy = graphql.orderBy || {};

        const [firstColumn] = graphql?.columns?.filter(({ options }) => options?.display !== 'excluded') || [];

        if (!Object.keys(orderBy)?.length) {
            // using 'set' here to manipulate nested items
            _set(orderBy, firstColumn?.name, 'ASC');
        }

        return {
            limit: config.tables.rowsPerPage,
            page: 1,
            offset: 0,
            where: graphql.where,
            orderBy,
        };
    }, [graphql.orderBy, graphql?.columns, graphql.where, config.tables.rowsPerPage]);

    const [params, setParams] = useState<GraphqlPaginationVariables<any>>(getInitialParams());
    const [deletePayload, setDeletePayload] = useState<Array<{ id?: number; slug?: string }>>([]);

    const list = useGetAll(graphql, params);

    const { mutateAsync: deleteItem, isLoading: isDeletingSingle } = useDelete({
        queryConfig: graphql,
        variables: params,
        apiPayload: api,
    });

    const { mutateAsync: deleteManyItem, isLoading: isDeletingMany } = useDeleteMany();

    const isLoading: any = !!list?.isLoading || isDeletingMany;

    const cantCreateRecord = useMemo(() => {
        const hasPermission = page?.rules.some((item) => item.key === Rule.Create);

        return hideCreateButton || !hasPermission;
    }, [page?.rules, hideCreateButton]);

    const excludedColumns = useMemo(() => {
        const columns = ['id', 'actions'];
        if (Boolean(graphql.excludedSearchColumnsName)) {
            columns.push(...graphql.excludedSearchColumnsName!);
        }

        return columns;
    }, [graphql.excludedSearchColumnsName]);

    const columnsToSearch = useMemo(() => graphql?.columns?.filter((column) => !excludedColumns.includes(column.name)).map((column) => column.name), [graphql.columns, excludedColumns]);

    const tableOptions = useMemo<MUIDataTableOptions>(() => {
        const [selectedRowsIndexes, setSelectedRowsIndexes] = selectedRowsIndexesHook || [];

        return {
            ...customTableOptions,
            ...(Boolean(onSelectRows || selectedRowsIndexesHook?.length) && {
                rowsSelected: selectedRowsIndexes || [],
                onRowSelectionChange: (_: any, __: any, rowsSelected) => {
                    setSelectedRowsIndexes?.(rowsSelected || []);

                    const arr = _at(list.data?.items || [], rowsSelected || []);

                    onSelectRows?.(arr);
                },
            }),
            onRowsDelete: (rowsDeleted) => {
                const itemsToDelete = rowsDeleted.data.map((item) => {
                    const dataItem = list.data?.items[item.index];

                    return {
                        id: dataItem?.id || 0,
                        ...(Boolean(dataItem?.slug) && { slug: dataItem?.slug }),
                    };
                });

                setDeletePayload(itemsToDelete as any[]);

                return false;
            },
        };
    }, [list.data, customTableOptions, onSelectRows, selectedRowsIndexesHook]);

    const handleChangePage = (page: number) => {
        setParams({
            ...params,
            page,
            offset: (page || 1) * params.limit - params.limit,
        });
    };

    const handleChangeRowsPerPage = (numberOfRows: number) => setParams({ ...params, limit: numberOfRows, offset: 0 });

    // DO NOT CHANGE TO UPPERCASE HERE
    const handleColumnSortChange = (changedColumn: string, direction: 'asc' | 'desc') => {
        const orderBy = {};

        // using 'set' here to manipulate nested items
        if (!!changedColumn) {
            _set(orderBy, changedColumn, direction.toUpperCase()); // INSTEAD, TRANSFORM CASE HERE
        }

        setParams({
            ...params,
            orderBy,
        });
    };

    const handleDelete = async () => {
        try {
            if (deletePayload.length === 1) {
                if (deletePayload[0].slug) {
                    deletePayload[0].slug = slugify(`${deletePayload[0].slug.trim()} ${new Date().getTime()}`);
                }

                await deleteItem(deletePayload[0]);

                if (!!queriesToInvalidate?.length) {
                    queriesToInvalidate.forEach((query) => {
                        queryClient.invalidateQueries(query);
                    });
                }

                return setDeletePayload([]);
            }

            for (const item of deletePayload) {
                if (!item || !api) {
                    return;
                }

                if (!!item.slug) {
                    item.slug = slugify(`${item.slug.trim()} ${new Date().getTime()}`);
                }

                await deleteManyItem(`${api?.endpoint}/${item.id}`);
            }

            queryClient.invalidateQueries(getAllKey(graphql.table, params));

            showToast('Registros apagados com sucesso', 'success');

            setDeletePayload([]);
        } catch (error) {
            console.log('handleDelete', error);
            showToast('Ocorreu um erro ao apagar os registros', 'danger');
        }
    };

    const handleDeletePayload = (id?: number, slug?: string) => setDeletePayload([{ id, slug }]);

    // Util to update a single param
    const handleChangeParam = (data: { name: string; value: any }) => {
        const { name, value } = data || {};

        const payload: any = {
            ...params,
            offset: 0,
            page: 1,
        };

        // lodash makes it possible to pass nested names
        if (value === undefined) {
            // Removing the filter from the param if it's undefined

            if (name) {
                setParams(_omit(payload, `where.${name}`.replace(/._eq|._ilike/, '')) as any);
                return;
            }

            setParams(_omit(payload, `where._or`) as any);

            return;
        }
        if (name) {
            _set(payload, `where.${name}`, value);
        } else {
            columnsToSearch?.forEach((column, index) => {
                _set(payload, `where._or.[${index}].${column}`, value);
            });
        }

        setParams(payload);
    };

    const handleSearchTextChange = (term: string | null) => {
        handleChangeParam({ name: graphql.searchableField!, value: term ? { _ilike: `%${term}%` } : undefined });
    };

    const getParam = (name: string) => _get(`${params}.where`, name);

    const handleChangeParams = (newParams: any) => {
        const payload: any = {
            ...params,
            offset: 0,
            page: 1,
        };

        _set(payload, 'where', {
            ...payload?.where,
            ...newParams,
        });

        setParams(payload);
    };

    useEffect(() => {
        setParams(getInitialParams());

        return () => {
            setParams(getInitialParams());
        };
    }, [location, getInitialParams]);

    return (
        <ListPageContextProvider params={params} onChangeParams={handleChangeParams} onChangeParam={handleChangeParam} getParam={getParam}>
            <List
                {...list}
                className={className}
                additionalContextData={additionalContextData}
                page={page}
                isLoading={isLoading}
                columns={graphql?.columns || []}
                params={params}
                title={title || ''}
                searchableField={graphql.searchableField!}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                onColumnSortChange={handleColumnSortChange}
                onDelete={handleDeletePayload}
                onSearchChange={handleSearchTextChange}
                hideHeader={hideHeader}
                hideCreateButton={cantCreateRecord}
                withBorder={withBorder}
                advancedSearchComponent={advancedSearchComponent}
                tableOptions={tableOptions}
                columnsToSearch={columnsToSearch}
                createPagePath={createPagePath}
                customActions={customActions}
                customFooterActions={customFooterActions}
            />
            {!isEmpty(deletePayload) && (
                <ConfirmModal
                    title="Apagar registro(s)"
                    description="Você tem certeza que deseja apagar este(s) registro(s)?"
                    isLoading={isDeletingMany || isDeletingSingle}
                    onClose={() => setDeletePayload([])}
                    onConfirmAction={handleDelete}
                />
            )}
        </ListPageContextProvider>
    );
};

export default ListPage;
