import Datatable from 'components/core/table/datatable';
import { expensesDatatableColumns } from 'pages/private/expenses/utils';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import useGetPaginatedWithFilters from 'services/queries/general/use-get-paginated-with-filters';
import { Expense } from 'types/models/expense';
import { Rule } from 'types/permissions';

const ImportFlowExpensesTabPage = () => {
    const [filters, setFilters] = useState<any>({});
    const [page, setPage] = useState(1);

    const { importFlowId } = useParams();

    const endpoint = `/imports/${importFlowId}/expenses`;

    const { data: expenses, isLoading: isLoadingExpenses } = useGetPaginatedWithFilters<Expense>({
        endpoint,
        filters,
        page,
    });

    return (
        <Datatable
            actions={[Rule.Show]}
            data={expenses?.items || []}
            columns={expensesDatatableColumns(true)}
            loading={isLoadingExpenses}
            options={{
                sort: true,
                filter: false,
                search: true,
                print: false,
                download: false,
                viewColumns: false,
                serverSide: true,
                searchAlwaysOpen: true,
                enableNestedDataAccess: '.',
                pagination: true,
                elevation: 21,
                count: expenses?.total,
                page,
                onChangePage: setPage,
                onSearchChange: (searchText) => {
                    setFilters((prev) => ({ ...prev, searchText }));
                },
            }}
        />
    );
};

export default ImportFlowExpensesTabPage;
