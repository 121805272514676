import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { useMemo } from 'react';
import General from './general';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { purchaseOrderExpenseSchema } from './utils';
import { Option } from 'types/general';
import { formatDate } from 'utils/date';
import useCrudExpense from 'services/queries/expenses/use-crud-expense';
import Transactions from './transactions';
import useGetTransactionsById from 'services/queries/transactions/use-get-transactions-by-id';
import { ExpenseNature } from 'types/models/expense';
import { Transaction } from 'types/models/transaction';
import { OPsActionsProps } from '../report-payment';
import FormButtons from 'components/form-buttons';
import { FormFavoredType } from 'types/models/favored';
import dayjs from 'dayjs';

export type PurchaseOrderExpensePayload = {
    branch: number;
    nature: ExpenseNature;
    title: string;
    dueDate: string;
    provision: string;
    company: number;
    formFavored?: Option & { type: FormFavoredType };
    bankAccount: number;
    paymentForm: Option;
    groupedTransactions: number[];
    financialType: number;
    fiscalCode: number;
    competence: string;
};

const OPsGrouping = ({ selectedItems, onClose, onSuccess }: OPsActionsProps) => {
    const selectedIds = selectedItems?.map(({ id }) => id) || [];

    const transactionsRequests = useGetTransactionsById(selectedIds);

    const { mutateAsync: createOrUpdateExpanse, isLoading: isSubmitting } = useCrudExpense(undefined, undefined, '.');

    const isBuildingTransactions = useMemo(() => transactionsRequests.some((item) => item.isLoading), [transactionsRequests]);

    const transactions = useMemo(() => (transactionsRequests.filter((item) => !!item.data).map((item) => item.data) || []) as Transaction[], [transactionsRequests]);

    const methods = useForm<PurchaseOrderExpensePayload>({
        mode: 'onChange',
        defaultValues: {
            nature: ExpenseNature.Normal,
            dueDate: '',
            provision: '',
            title: '',
            competence: dayjs().toISOString(),
        },
        resolver: yupResolver(purchaseOrderExpenseSchema) as any,
    });

    const submit = async (data: PurchaseOrderExpensePayload) => {
        try {
            const [competenceMonth, competenceYear] = formatDate(data?.competence, 'MM/YYYY', null)?.split('/') || [];

            const payload = {
                branch: data.branch,
                nature: data.nature,
                title: data.title,
                company: data.company,
                paymentForm: data.paymentForm.value,
                fiscalCode: data.fiscalCode,
                provision: formatDate(data.provision, 'YYYY-MM-DD'),
                dueDate: formatDate(data.provision, 'YYYY-MM-DD'),
                formFavored: {
                    [data.formFavored?.type || 'person']: data.formFavored?.value,
                    favoredBankAccount: data.bankAccount,
                },
                groupedTransactions: selectedIds.map(Number),
                ...(Boolean(competenceMonth) &&
                    Boolean(competenceYear) && {
                        competenceMonth: Number(competenceMonth),
                        competenceYear: Number(competenceYear),
                    }),
            };

            await createOrUpdateExpanse(payload as any);

            onSuccess?.();
        } catch (error) {
            console.log('submit', error);
        }
    };

    return (
        <Modal
            contentClassnames="min-w-[90%]"
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    Agrupar OPs
                </Text>
            }
            closeOnClickOutside={false}
            onClose={onClose}>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(submit)}>
                    <Transactions isBuildingTransactions={isBuildingTransactions} transactions={transactions} />
                    <General />
                    <FormButtons
                        containerClassName="p-6 pt-0"
                        isLoading={isSubmitting}
                        cancelButton={{
                            onClick: onClose,
                        }}
                    />
                </form>
            </FormProvider>
        </Modal>
    );
};

export default OPsGrouping;
