import React, { PropsWithChildren } from 'react';
import IconButton from '@mui/material/IconButton/IconButton';
import theme from 'settings/theme';
import Button from 'components/core/button';
import classNames from 'classnames';
import { UseFormReturn } from 'react-hook-form';
import SvgIcoClose from 'components/core/icon/files/ico-close';

type ModalFilterProps = {
    onClose: () => void;
    title?: string;
    onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
    onClear?: () => void;
    className?: string;
    formMethods: UseFormReturn<any, any>;
    FormActionsComponent?: (props: { formMethods: ModalFilterProps['formMethods'] }) => JSX.Element;
};

const ModalFilter = ({ onClose, title = 'Filtro', children, FormActionsComponent, onSubmit, onClear, className, formMethods }: PropsWithChildren<ModalFilterProps>) => {
    const FormActions = () => {
        return (
            <div className="flex items-center">
                <Button type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4">
                    Filtrar
                </Button>
                {Boolean(onClear) && (
                    <Button type="button" color="primary" className="min-w-[100px] underline" variant="text" onClick={onClear}>
                        Limpar filtros
                    </Button>
                )}
            </div>
        );
    };

    return (
        <div className={classNames('absolute overflow-y-auto top-[0px] right-0 h-full w-[400px] bg-white flex flex-col px-6 py-7 shadow-2xl z-[999] !max-w-full', className)}>
            <div className="flex items-center mb-6">
                <h3 className="text-2xl text-heading font-medium flex-1">{title}</h3>
                <IconButton className="bg-base-200" size="large" color="default" onClick={onClose}>
                    <SvgIcoClose color={theme.extend.colors.heading} width={10} height={10} />
                </IconButton>
            </div>
            <form onSubmit={onSubmit}>
                {children}
                <span className="block mb-6" />
                {!!FormActionsComponent ? <FormActionsComponent formMethods={formMethods} /> : <FormActions />}
            </form>
        </div>
    );
};

export default ModalFilter;
