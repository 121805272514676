import dayjs from 'dayjs';
import dictionary from 'utils/dictionary';
import { moreThanSchema } from 'utils/schema';
import { object, number, string, array, boolean, TestContext } from 'yup';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(isSameOrAfter);

const dateLowerThanHigherProvision = (highestProvision?: string) => (value: string, context: TestContext<any>) => {
    const hasMultipleInstallmentsAndIsProvisionField = context.parent?.transactions?.length > 1 && context.path === 'provision';

    if (!highestProvision || !value || hasMultipleInstallmentsAndIsProvisionField) {
        return true;
    }

    return dayjs(value).isSameOrAfter(highestProvision, 'day');
};

export const dateLowerThanHigherProvisionType = 'dateLowerThanHigherProvision';

export const purchaseOrderExpenseSchema = (highestProvision?: string) => {
    const purchaseOrderExpenseProvisionSchema = string()
        .required(dictionary.validation.required)
        .test(dateLowerThanHigherProvisionType, 'Data menor que a maior data de provisão dos produtos.', dateLowerThanHigherProvision(highestProvision));

    return object({
        nature: number().required(dictionary.validation.required),
        fiscalCode: number().required(dictionary.validation.required),
        company: number().required(dictionary.validation.required),
        sector: number().required(dictionary.validation.required),
        category: number().required(dictionary.validation.required).typeError(dictionary.validation.required),
        classificationOrigin: number().required(dictionary.validation.required),
        classificationSubOrigin: number().required(dictionary.validation.required).typeError(dictionary.validation.required),
        branch: number().required(dictionary.validation.required),
        formFavored: object({
            value: number().required(dictionary.validation.required),
            label: string().required(dictionary.validation.required),
            type: string().required(dictionary.validation.required),
        }).required(dictionary.validation.required),
        paymentForm: number().required(dictionary.validation.required),
        instalments: number().nullable().default(null).notRequired(),
        documentNumber: string().default(null).nullable().notRequired(),
        dueDate: string().required(dictionary.validation.required).typeError(dictionary.validation.required),
        provision: purchaseOrderExpenseProvisionSchema,
        provisioned: boolean().default(false),
        title: string().required(dictionary.validation.required).typeError(dictionary.validation.required),
        observations: string().notRequired(),
        products: array(
            object({
                productId: number().required(dictionary.validation.required),
                quantity: number().required(dictionary.validation.required),
                value: moreThanSchema(),
                justification: string(),
            })
        ).min(1, dictionary.validation.array.min(1)),
        budgets: array(
            object({
                provider: string(),
                file: number(),
                value: number(),
            })
        ),
        transactions: array(
            object({
                dueDate: string().required(dictionary.validation.required),
                provisioning: purchaseOrderExpenseProvisionSchema,
                provisioned: boolean().default(false).notRequired(),
                value: number().required(dictionary.validation.required),
                paymentForm: number().required(dictionary.validation.required),
            })
        ).when('instalments', ([instalments], schema) => (instalments > 1 ? schema.min(1, dictionary.validation.array.min(1)).required(dictionary.validation.required) : schema)),
        documents: array(
            object({
                file: number(),
                name: string(),
                classification: number(),
            })
        ),
        competence: string().required(dictionary.validation.required),
    });
};
