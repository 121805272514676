import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import ListPage from 'pages/shared/crud/list';
import { useParams } from 'react-router-dom';
import { getDeductionsListConfig } from './utils';
import DetailedCard from 'pages/private/reports/screens/sales/totals/detailed-card';
import { useGetDeductionTotals } from 'services/queries/deductions/use-get-deduction-totals';

const SaleOrderDeductionPage = ({ page }: WithPagePermissionsProps) => {
    const { saleOrderId } = useParams();

    const list = getDeductionsListConfig(Number(saleOrderId));

    const { data: deductionsTotals } = useGetDeductionTotals(saleOrderId);
    const { data: deductedTotals } = useGetDeductionTotals(saleOrderId, undefined, true);

    return (
        <>
            <div className="flex sm:flex-row flex-col items-center gap-4 mb-4">
                <DetailedCard title="Total deduzido" className="w-full" hasPercentage={false} value={deductedTotals?.aggregate?.sum?.value || 0} />
                <DetailedCard title="Total a deduzir" className="w-full" hasPercentage={false} value={deductionsTotals?.aggregate?.sum?.value || 0} />
            </div>
            <ListPage customTableOptions={{ selectableRowsHideCheckboxes: true }} {...list} page={page} hideHeader={true} />
        </>
    );
};

export default SaleOrderDeductionPage;
