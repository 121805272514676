import { useQuery } from 'react-query';
import api from 'services/api';
import { getProductStocksKey } from './keys';
import { Product } from 'types/models/product';
import qs from 'qs';

const useGetProductStocks = (productId?: string, purchaseOrderProductId?: string) => {
    const request = async () => {
        const query = qs.stringify({ ...(Boolean(purchaseOrderProductId) && { purchaseOrderProductId }) }, { skipNulls: true, addQueryPrefix: true, encode: false });

        const { data } = await api.get<Product>(`/products/${productId}/stocks${query}`);

        return data;
    };

    return useQuery(getProductStocksKey(productId), request, { enabled: !!productId });
};

export default useGetProductStocks;
