import Empty from 'components/empty';
import Spinner from 'components/core/spinner';
import Provider from './view';
import useBreadcrumb from 'store/breadcrumb/use-breadcrumb';
import { CrudDetailsProps } from 'types/graphql';
import { Customer as CustomerModel } from 'types/models/customer';

type Response = Partial<CustomerModel>;

const ProviderPage = ({ page, data, isLoading, error }: CrudDetailsProps<Response>) => {
    useBreadcrumb(data?.title);

    if (isLoading) {
        return (
            <div className="relative w-full h-full">
                <Spinner />
            </div>
        );
    }

    if (Boolean(error) || !data) {
        return <Empty title="Fornecedor não encontrado" />;
    }

    return <Provider subpages={page?.subpages || []} provider={data} />;
};

export default ProviderPage;
