import React, { useState } from 'react';
import Menu, { MenuItem } from 'components/menu';
import useTheme from '@mui/material/styles/useTheme';
import { useNavigate } from 'react-router-dom';
import Button from 'components/core/button';
import useResponsive from 'hooks/responsive/use-responsive';
import IconButton from '@mui/material/IconButton/IconButton';
import SvgIcoDots from 'components/core/icon/files/ico-dots';
import SvgIcoChevronDown from 'components/core/icon/files/ico-chevron-down';

const HeaderActions = () => {
    const { palette } = useTheme();
    const navigate = useNavigate();

    const { isMobile } = useResponsive();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleClose = () => setAnchorEl(null);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

    const handleAction = (url: string) => {
        handleClose();
        navigate(url);
    };

    const content: MenuItem[] = [
        {
            label: 'Nova Despesa',
            icon: {
                color: palette.grey[500],
                name: 'ico-plus',
            },
            onClick: handleAction.bind(this, '/app/despesas/novo'),
        },
        {
            label: 'Ordem de Pagamento',
            icon: {
                color: palette.grey[500],
                name: 'ico-dollar-sign',
            },
            onClick: handleAction.bind(this, '/app/ordens-de-pagamento'),
        },
        {
            label: 'Obras',
            icon: {
                color: palette.grey[500],
                name: 'ico-chart',
            },
            onClick: handleAction.bind(this, '/app/ordens-de-servico'),
        },
        {
            label: 'Pedidos de Venda',
            icon: {
                color: palette.grey[500],
                name: 'ico-cart',
            },
            onClick: handleAction.bind(this, '/app/pedidos-de-venda'),
        },
        {
            label: 'Nova comissão',
            icon: {
                color: palette.grey[500],
                name: 'ico-plus',
            },
            onClick: handleAction.bind(this, '/app/comissoes/novo'),
        },
    ];

    return (
        <>
            {isMobile ? (
                <IconButton onClick={handleOpen} title="Ações">
                    <SvgIcoDots width={14} height={14} color={palette.grey[500]} />
                </IconButton>
            ) : (
                <Button
                    type="button"
                    className="bg-transparent border border-solid border-gray-300 text-gray-500 mx-3"
                    variant="contained"
                    color="inherit"
                    onClick={handleOpen}
                    endIcon={<SvgIcoChevronDown width={10} color={palette.grey[500]} />}>
                    Ações
                </Button>
            )}
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} content={content} />
        </>
    );
};

export default HeaderActions;
