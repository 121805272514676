import { useMemo, useState } from 'react';
import CreateOrUpdateSalesNote from './create-or-update';
import { File as FileApiModel } from 'types/models/file';
import useCreateOrUpdateSaleNote from 'services/queries/sales-notes/use-create-or-update-sales-receipt';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import useUploadFile from 'services/queries/files/use-upload-file';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { GraphqlPaginationVariables } from 'types/graphql';
import useGetSaleReceiptById from 'services/queries/sales-notes/use-get-sale-receipt-by-id';
import { SalesNotePayload, SalesReceiptResponse } from 'types/models/sales-notes';
import { initialValues, salesNotesFiltersWhere, withheldsResetObject } from '../utils';
import set from 'lodash/set';
import omit from 'lodash/omit';
import { CompanyTaxRegime } from 'types/models/company';
import { SaleOrderOrigin } from 'types/models/sale-order';

const omittedValues: Partial<keyof SalesNotePayload | keyof SalesReceiptResponse>[] = [
    'deductionValues',
    'cityTax',
    'file',
    'taxRegime',
    'totalDeductionValue',
    'id',
    'finalized',
    'presumedInss',
    'simpleInss',
];

const CreateOrUpdateSaleNotesPage = () => {
    const { saleNoteId } = useParams();
    const [searchParams] = useSearchParams();
    const params = useOutletContext<GraphqlPaginationVariables<any>>();

    const { data: saleReceipt, isLoading: isLoadingSaleReceipt } = useGetSaleReceiptById(Number(saleNoteId));

    const { uploadFile, isLoading: isUploadingFile } = useUploadFile();

    const paramsFilters = salesNotesFiltersWhere(Object.fromEntries(searchParams));

    const { mutateAsync: createOrUpdateSaleNote, isLoading: isSubmittingSaleNote } = useCreateOrUpdateSaleNote(saleNoteId, params, paramsFilters);

    const fileHook = useState<Partial<(File & FileApiModel) | undefined>>();

    const [file] = fileHook;

    const isSubmitting = isUploadingFile || isSubmittingSaleNote;

    const handleSubmit = async (data: SalesNotePayload) => {
        try {
            const payload: Partial<SalesNotePayload & { cityTax: number | null; inss: number }> = {
                ...omit(initialValues, omittedValues),
                ...omit(data, omittedValues),
                ...(data.company?.taxRegime === CompanyTaxRegime.Simple && withheldsResetObject),
                ...(data.nature === SaleOrderOrigin.sale && { withImportedProducts: data.withImportedProducts }),
                inss: +data.simpleInss! || +data.presumedInss! || 0,
                company: data?.company?.id as any,
                emission: dayjs(data?.emission).format('YYYY-MM-DD'),
                salesOrders: (data?.deductionValues as any) || [],
                invoiceValue: +data?.invoiceValue! || 0,
                das: data?.das || 0,
                transactions: data?.transactions?.map((item) => item?.value!) || [],
                cityTax: data.cityTax?.value || null,
                customer: data.customer,
            };

            if (!!file?.id) {
                set(payload, 'file', file?.id);
            } else {
                await uploadFile(
                    file,
                    ({ id }) => {
                        set(payload, 'file', id);
                    },
                    true
                );
            }

            await createOrUpdateSaleNote(payload);
        } catch (error) {
            console.log('handleSubmit', error);
        }
    };

    const newDefaultValues = useMemo(() => {
        const isSaleNature = saleReceipt?.nature === SaleOrderOrigin.sale;
        const baseKeyName = isSaleNature ? 'saleOrder' : 'orderService';

        return {
            ...saleReceipt,
            salesOrders: saleReceipt?.salesOrders?.map((item) => ({ label: item.orderService?.code || item.saleOrder?.code, value: item.orderService?.id || item.saleOrder?.id })),
            transactions: saleReceipt?.transactions?.map((item) => ({ label: item?.code, value: item?.id, netValue: item.netValue })),
            deductionValues: saleReceipt?.salesOrders?.map(({ deductionValue, orderService, saleOrder }) => ({ deductionValue: +deductionValue, [baseKeyName]: orderService?.id || saleOrder.id })),
            simpleInss: saleReceipt?.inss || 0,
            presumedInss: saleReceipt?.inss || 0,
            withImportedProducts: saleReceipt?.withImportedProducts || false,
            customer: saleReceipt?.customer?.id,
            cityTax:
                {
                    label: saleReceipt?.cityTax?.city?.name,
                    value: saleReceipt?.cityTax?.id,
                    taxes: {
                        icms: saleReceipt?.cityTax?.icms,
                        iss: saleReceipt?.cityTax?.iss,
                    },
                } || null,
        };
    }, [saleReceipt]);

    return (
        <CreateOrUpdateSalesNote
            saleNoteId={+saleNoteId!}
            defaultValues={newDefaultValues}
            fileHook={fileHook}
            isSubmittingForm={isSubmitting}
            isLoading={isLoadingSaleReceipt}
            onSubmit={handleSubmit}
        />
    );
};

export default CreateOrUpdateSaleNotesPage;
