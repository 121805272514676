import useTheme from '@mui/material/styles/useTheme';
import classNames from 'classnames';
import ExternalButton from 'components/buttons/external';
import Card from 'components/core/card';
import SvgIcoPhone from 'components/core/icon/files/ico-phone';
import SvgIcoReadMail from 'components/core/icon/files/ico-read-mail';
import Text from 'components/core/text';
import { Customer } from 'types/models/customer';
import { generateFileUrl } from 'utils/file';
import { generateAddress } from 'utils/string';

type SaleOrderCustomerProps = {
    className?: string;
} & Partial<Customer>;

const SaleOrderCustomer = ({ className, ...customer }: SaleOrderCustomerProps) => {
    const { palette } = useTheme();

    return (
        <Card className={classNames('p-4', className)}>
            <div className="flex items-end">
                {Boolean(customer?.file?.filename) && (
                    <div
                        className="w-36 h-36 rounded-[14px] bg-center bg-no-repeat mr-4"
                        style={{ backgroundImage: `url(${generateFileUrl(customer?.file?.filename!, customer?.file?.path)}`, backgroundSize: '70%', backgroundColor: palette.grey[200] }}
                    />
                )}
                <div className="flex-1">
                    <div className="mb-3">
                        {Boolean(customer.id) && (
                            <ExternalButton href={`/app/clientes/${customer.id}`}>{`${customer?.title} ${!!customer?.juridicFantasyName ? `(${customer?.juridicFantasyName})` : ''}`}</ExternalButton>
                        )}
                        <Text className="text-base-500 mb-2 block">
                            {customer?.juridicDocument || ''}
                            {customer?.document || ''}
                        </Text>
                        {customer?.addressCustomers?.map((item, index) => {
                            return (
                                <Text className="text-base-500" key={index}>
                                    {generateAddress(item.address)}
                                </Text>
                            );
                        })}
                    </div>
                    <div className="flex flex-wrap">
                        {Boolean(customer?.phones?.length) && (
                            <div className="flex mb-1 mr-2">
                                <SvgIcoPhone className="text-base-700 mr-1" width={20} />
                                {customer?.phones?.map((item) => {
                                    return (
                                        <Text className="mr-2 text-base-500" key={item.number}>
                                            ({item.ddd}) {item.number}
                                        </Text>
                                    );
                                })}
                            </div>
                        )}
                        {Boolean(customer?.emails?.length) && (
                            <div className="flex">
                                <SvgIcoReadMail className="text-base-700 mr-1" width={20} />
                                {customer?.emails?.map((item) => {
                                    return (
                                        <ExternalButton href={`mailto:${item.email}`} key={item.email}>
                                            {item.email}
                                        </ExternalButton>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default SaleOrderCustomer;
