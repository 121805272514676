import { yupResolver } from '@hookform/resolvers/yup';
import useTheme from '@mui/material/styles/useTheme';
import Button from 'components/core/button';
import Select from 'components/core/form/select';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import useGetProducts from 'services/queries/products/use-get-products';
import { Product, ProductType } from 'types/models/product';
import { useSaleOrderContext } from '../../context';
import { Steps } from '../../context/types';
import useDebounce from 'hooks/keyboard/use-debounce';
import { useLocation, useParams } from 'react-router-dom';
import { ApprovalStatus } from 'types/general';
import useGetSaleOrderProduct from 'services/queries/sales-order/use-get-sale-order-product';
import useGetSaleOrderProducts from 'services/queries/sales-order/use-get-sale-order-products';
import { formatSaleOrderProductsResponse, getPurchaseOrderProductsQuery, getSaleOrderProductByPkQuery, getSaleOrderProductsQuery, saleOrderProductsSchema } from 'pages/private/sales-orders/utils';
import isEmpty from 'lodash/isEmpty';
import { SaleOrderOrigin } from 'types/models/sale-order';
import TotalProducts from './total';
import ProductsTable from './table';
import SvgIcoArrowLeft from 'components/core/icon/files/ico-arrow-left';
import SvgIcoArrowRight from 'components/core/icon/files/ico-arrow-right';

export type ProductsItem = {
    name: string;
    code: string;
    quantity: number;
    total: number;
    productId: number;
    quantityInStock: number;
    netValue?: number;
    unitName: string;
    id?: string;
    actualQuantity?: number;
};

export type ProductsPayload = {
    products: ProductsItem[];
};

const Products = () => {
    const { palette } = useTheme();
    const { pathname } = useLocation();
    const { saleOrderId, saleOrderProductId } = useParams();
    const { goToStep, state, completeStep, setContent } = useSaleOrderContext();

    const [selectedProduct, setSelectedProduct] = useState('');

    const isCreate = pathname.includes('escopo-de-venda/novo');
    const isUpdate = !!saleOrderProductId;
    const isSingleStep = isUpdate || isCreate;
    const isOriginSale = state.content.origin === SaleOrderOrigin.sale;

    const debouncedSelectedProduct = useDebounce(selectedProduct, 500);

    const { data: saleOrderProducts = [] } = useGetSaleOrderProducts(getSaleOrderProductsQuery(saleOrderId), saleOrderId);

    const selectedProductsIds = useMemo(() => saleOrderProducts.map((item) => item.product?.id!), [saleOrderProducts]);

    const { data: saleOrderProduct } = useGetSaleOrderProduct(getSaleOrderProductByPkQuery(saleOrderProductId), saleOrderId);

    const getProductsWhere = useMemo(
        () => ({
            approvalStatus: { _eq: ApprovalStatus.Approved },
            type: { _in: [ProductType.Sale, ProductType.Both] },
            ...(Boolean(selectedProductsIds?.length) && { id: { _nin: selectedProductsIds } }),
            ...(Boolean(debouncedSelectedProduct) && {
                _or: [
                    {
                        name: { _ilike: `%${debouncedSelectedProduct}%` },
                    },
                    {
                        code: { _ilike: `%${debouncedSelectedProduct}%` },
                    },
                ],
            }),
        }),
        [debouncedSelectedProduct, selectedProductsIds]
    );

    const getProductsQuery = getPurchaseOrderProductsQuery(getProductsWhere, state.content?.branch?.value);

    const { data: products = [] } = useGetProducts<Product>(getProductsQuery, !!debouncedSelectedProduct, formatSaleOrderProductsResponse);

    const methods = useForm<ProductsPayload>({
        mode: 'all',
        reValidateMode: 'onChange',
        criteriaMode: 'all',
        defaultValues: { products: [] },
        resolver: yupResolver(saleOrderProductsSchema(state.content.origin)) as any,
    });

    const { formState, handleSubmit, control, reset, getValues } = methods;

    const { append, remove, fields: selectedProducts } = useFieldArray({ name: 'products', control });

    const filteredProducts = useMemo(() => products.filter((item) => !selectedProducts.find((prod) => prod.productId === item.id!)), [products, selectedProducts]);

    const handleBack = () => {
        setContent({ products: getValues('products') });
        goToStep('previous');
    };

    const handleSelectProducts = (option: any) => {
        append({
            name: option.name,
            code: option.code,
            productId: option.value,
            netValue: option.netValue,
            quantityInStock: option.stockProducts.reduce((acc, curr) => acc + curr.quantity || 0, 0),
            unitName: option.classificationByClassificationUnitId?.name,
            quantity: 0,
            total: 0,
        });

        setSelectedProduct('');
    };

    const submitButtonLabel = useMemo(() => {
        if (!isSingleStep) {
            return 'Avançar';
        }

        return isCreate ? 'Adicionar produto' : 'Atualizar pedido';
    }, [isSingleStep, isCreate]);

    const handleWizard = (products: ProductsItem[]) => {
        setContent({ products });
        completeStep(Steps.Products);
        goToStep('next');
    };

    const submit = (data: ProductsPayload) => {
        const hasNoErrors = isEmpty(formState.errors);

        if (!hasNoErrors) {
            return;
        }

        const payload = !isOriginSale ? data.products : data.products.filter((item) => !!item.quantityInStock || !!item.id);

        return handleWizard(payload);
    };

    useEffect(() => {
        if (saleOrderProduct) {
            reset({
                products: [
                    {
                        name: saleOrderProduct.product?.name,
                        code: saleOrderProduct.product?.code,
                        netValue: saleOrderProduct.netValue,
                        quantity: saleOrderProduct.quantity,
                        productId: saleOrderProduct.productId,
                        unitName: saleOrderProduct.classificationByClassificationUnitId?.name,
                        total: (saleOrderProduct.quantity || 0) * (saleOrderProduct.netValue || 0),
                        quantityInStock: saleOrderProduct.product?.stockProducts?.reduce((acc, curr) => acc + (curr.quantity || 0), 0) || 0,
                    },
                ],
            });
        }
    }, [saleOrderProduct, reset]);

    useEffect(() => {
        if (!!state.content?.products?.length) {
            reset({ products: state.content.products || [] });
        }
    }, [state, reset]);

    useEffect(() => {
        return () => {
            if (isSingleStep) {
                setContent({});
            }
        };
        // eslint-disable-next-line
    }, []);

    return (
        <form onSubmit={handleSubmit(submit)}>
            <FormProvider {...methods}>
                <div className="p-6">
                    {!isUpdate && (
                        <div className="mb-6">
                            <Select
                                value={debouncedSelectedProduct}
                                options={!!debouncedSelectedProduct ? filteredProducts : []}
                                label="Produto (código ou nome)"
                                placeholder="Digite o código ou nome do produto"
                                components={!Boolean(debouncedSelectedProduct) ? { NoOptionsMessage: () => null } : undefined}
                                onChange={handleSelectProducts}
                                onInputChange={setSelectedProduct}
                            />
                        </div>
                    )}
                    {Boolean(selectedProducts.length) && <ProductsTable isOriginSale={isOriginSale} selectedProducts={selectedProducts} onRemoveProduct={remove} />}
                </div>
                {Boolean(selectedProducts?.length) && !isUpdate && <TotalProducts />}
                <div className="border-t p-2 flex justify-between">
                    {isSingleStep ? (
                        <div />
                    ) : (
                        <Button startIcon={<SvgIcoArrowLeft width={18} color={palette.grey[700]} />} type="button" variant="text" color="inherit" className="min-w-[100px]" onClick={handleBack}>
                            Voltar
                        </Button>
                    )}
                    <Button
                        disabled={!formState.isValid}
                        endIcon={<SvgIcoArrowRight width={18} color={!formState.isValid ? palette.grey[400] : palette.secondary[500]} />}
                        type="submit"
                        variant="text"
                        color={!formState.isValid ? 'inherit' : 'secondary'}
                        className="min-w-[100px]">
                        {submitButtonLabel}
                    </Button>
                </div>
            </FormProvider>
        </form>
    );
};

export default Products;
