import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import ListPage from 'pages/shared/crud/list';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { stockProductsListConfig } from './utils';
import FabNav from 'components/fab-nav';
import { MenuItem } from 'components/menu';
import { Stock, StockType } from 'types/models/stock';

const StockProductsPage = ({ page }: WithPagePermissionsProps) => {
    const navigate = useNavigate();
    const { stockId } = useParams();

    const stock = useOutletContext<Pick<Stock, 'type' | 'name' | 'id'>>();

    const listConfig = stockProductsListConfig(stockId);

    const generateFabOptions = () => {
        const arr: MenuItem[] = [];

        if (stock.type === StockType.OrderService) {
            arr.push({
                label: 'Estorno para o estoque principal',
                onClick: () => navigate('estorno-estoque-principal'),
            });
        } else {
            arr.push({
                label: 'Industrialização',
                onClick: () => navigate('industrializacao'),
            });
        }

        return arr;
    };

    return (
        <>
            <ListPage page={page} customTableOptions={{ selectableRows: 'none' }} {...listConfig} />
            <FabNav options={generateFabOptions()} />
        </>
    );
};

export default StockProductsPage;
