import { ApprovalStatus } from 'types/general';
import { BaseModel } from './base';
import { Classification } from './classification';
import { StockProduct } from './stock';

export enum ProductType {
    Sale = 1,
    Purchase,
    Both,
}

export type Product = {
    classificationCategoryId: number;
    classificationUnitId: number;
    classificationId: number;
    code: string;
    name: string;
    value: number;
    approvalStatus: ApprovalStatus;
    saleOrderProducts: any[]; // TODO: type
    classification: Partial<Classification>;
    category: Partial<Classification>;
    classificationByClassificationId: Partial<Classification>;
    classificationByClassificationUnitId: Partial<Classification>;
    unit: Partial<Classification>;
    stocks: Partial<StockProduct>[];
    stockProducts: Partial<StockProduct>[];
    type: ProductType;
} & BaseModel;

export type ProductPayload = {
    name: string;
    category: number;
    unit: number;
    classification: number;
    type: ProductType;
};

type ProductsFilterFieldsKeys = 'classifications' | 'categories' | 'types';

export type ProductsFilterPayload = {
    [K in ProductsFilterFieldsKeys]: number[];
};

export type ProductsFilters = Partial<
    {
        searchText: string | null;
    } & ProductsFilterPayload
>;
