import React, { useState } from 'react';
import Menu, { MenuItem } from 'components/menu';
import useTheme from '@mui/material/styles/useTheme';
import Button from 'components/core/button';
import SvgIcoChevronDown from 'components/core/icon/files/ico-chevron-down';

type OriginExpenseReportActionsProps = {
    formRef: React.RefObject<HTMLFormElement>;
};

const OriginExpenseReportActions = ({ formRef }: OriginExpenseReportActionsProps) => {
    const { palette } = useTheme();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleClose = () => setAnchorEl(null);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

    const content: MenuItem[] = [
        {
            label: 'Filtrar',
            icon: {
                color: palette.grey[500],
                name: 'ico-filter',
            },
            onClick: () => formRef?.current?.requestSubmit(),
        },
    ].map((item) => ({
        ...item,
        onClick: () => {
            handleClose();
            item.onClick();
        },
    }));

    return (
        <>
            <Button type="button" variant="contained" color="secondary" onClick={handleOpen} endIcon={<SvgIcoChevronDown width={13} color="#fff" />}>
                Ações
            </Button>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} content={content} />
        </>
    );
};

export default OriginExpenseReportActions;
