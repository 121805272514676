import DatePicker from 'components/core/datepicker';
import Select from 'components/core/form/select';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import FormButtons from 'components/form-buttons';
import { useMemo, useState } from 'react';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import useGetOptions from 'services/queries/crud/use-get-options';
import { getStocksKey } from 'services/queries/stocks/keys';
import useGetStockProducts from 'services/queries/stocks/use-get-stock-products';
import { getStocksQuery } from 'services/queries/stocks/utils';
import { Option } from 'types/general';
import useCreateStockProductRefund, { RefundStockProductPayload } from 'services/queries/stocks/use-create-stock-product-refund';
import { stockProductsRefundSchema } from '../../utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatDate } from 'utils/date';
import StockProductRefundTotal from './total';
import StockProductRefundTable from './table';

const StockProductsRefundPage = () => {
    const [selectedProduct, setSelectedProduct] = useState<Option | null>(null);

    const { stockId } = useParams();

    const { mutateAsync: createStockProductRefund, isLoading: isSubmitting } = useCreateStockProductRefund(stockId);
    const { data: mainStocks = [], isLoading: isLoadingMainStocks } = useGetOptions(getStocksQuery, getStocksKey, true);
    const { data: stockProducts = [], isLoading: isLoadingStockProducts } = useGetStockProducts(stockId);

    const methods = useForm<RefundStockProductPayload>({
        mode: 'onSubmit',
        resolver: yupResolver(stockProductsRefundSchema) as any,
        defaultValues: {
            date: '',
            products: [],
        },
    });

    const { append, remove, fields: selectedProducts } = useFieldArray({ control: methods.control, name: 'products' });

    const handleSelectProducts = (option: any) => {
        setSelectedProduct(null);
        append(option, { shouldFocus: false });
    };

    const submit = async (data: RefundStockProductPayload) => {
        try {
            const payload: RefundStockProductPayload = {
                incomingStock: data.incomingStock,
                date: formatDate(data.date, 'YYYY-MM-DD'),
                products: data.products.map((item) => ({
                    stockProduct: item.stockProduct,
                    quantity: item.quantity,
                })),
            };

            await createStockProductRefund(payload);
        } catch (error) {
            console.error('submit - stockProductsRefund: ', error);
        }
    };

    const formattedStockProducts = useMemo(() => {
        return stockProducts.map((item) => ({
            label: `${item.product.code} - ${item.product.name}`,
            quantityInStock: item.quantity,
            value: item.id,
            productValue: item.value,
            stockProduct: item.id,
            quantity: 0,
        }));
    }, [stockProducts]);

    const filteredProducts = useMemo(() => formattedStockProducts.filter((item) => !selectedProducts.find((prod) => prod.value === item.value!)), [formattedStockProducts, selectedProducts]);

    return (
        <Modal
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    Estorno para o estoque principal
                </Text>
            }
            contentClassnames="xs:w-full md:w-[1200px]"
            closeOnClickOutside={false}>
            <FormProvider {...methods}>
                <form className="p-6" onSubmit={methods.handleSubmit(submit)}>
                    <div className="grid sm:grid-cols-2 gap-4">
                        <Controller
                            control={methods.control}
                            name="incomingStock"
                            render={({ field }) => {
                                const value = mainStocks.find((item) => item.value === field.value);

                                return (
                                    <Select
                                        {...field}
                                        value={value}
                                        options={mainStocks}
                                        isLoading={isLoadingMainStocks}
                                        label="Estoque"
                                        placeholder="Selecione uma opção"
                                        onChange={(option: any) => field.onChange(option.value)}
                                        error={methods.formState.errors.incomingStock?.message}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="date"
                            control={methods.control}
                            render={({ field }) => {
                                return (
                                    <DatePicker
                                        inputProps={{
                                            placeholderText: 'Selecione a data de movimentação',
                                            label: 'Data de Movimentação',
                                            error: methods.formState.errors?.date?.message,
                                            ...field,
                                        }}
                                    />
                                );
                            }}
                        />
                        <Select
                            value={selectedProduct}
                            options={filteredProducts}
                            isLoading={isLoadingStockProducts}
                            label="Produto (código/nome)"
                            placeholder="Selecione um ou mais produtos"
                            parentClassName="sm:col-span-2"
                            error={methods.formState.errors.products?.message}
                            onChange={handleSelectProducts}
                        />
                        {Boolean(selectedProducts.length) && (
                            <>
                                <StockProductRefundTable selectedProducts={selectedProducts} onRemove={remove} />
                                <StockProductRefundTotal />
                            </>
                        )}
                    </div>
                    <FormButtons containerClassName="mt-6" isLoading={isSubmitting} />
                </form>
            </FormProvider>
        </Modal>
    );
};

export default StockProductsRefundPage;
