import PurchaseOrderProducts from 'pages/private/sales-orders/service-order/purchase-order/products';
import PurchaseOrderProductsFilters from 'components/advanced-search/purchase-order-products';
import { PurchaseOrderProductsFiltersPayload, PurchaseProductStatus } from 'types/models/purchase-order';
import useGetAllPurchaseOrderProductsApi from 'services/queries/purchase-order/use-get-all-purchase-order-products-api';
import Empty from 'components/empty';
import { useState } from 'react';
import PageHeader from 'components/page/header';
import { Outlet, useLocation } from 'react-router-dom';
import useConfig from 'store/config/use-config';
import QueryString from 'qs';
import { purchaseOrderProductsColumns, purchaseOrderProductsOrderByMap } from './utils';
import { ApprovalStatus } from 'types/general';

const cannotSelectStatuses = [PurchaseProductStatus.Finished, PurchaseProductStatus.Canceled];

type PurchaseOrderListProps = {
    title: string;
};

const PurchaseOrderList = ({ title }: PurchaseOrderListProps) => {
    const { config } = useConfig();
    const { pathname } = useLocation();

    const [filters, setFilters] = useState<Partial<PurchaseOrderProductsFiltersPayload>>({
        purchaseProductStatus: [PurchaseProductStatus.Waiting],
        approvalStatus: ApprovalStatus.Approved,
    });
    const [page, setPage] = useState(1);
    const [orderBy, setOrderBy] = useState<any>({});

    const isStockProducts = pathname.includes('estoques');

    const query = {
        page,
        totalPerPage: config.tables.rowsPerPage,
        filter: filters,
        orderBy,
    };

    const parsed = QueryString.stringify(query, { addQueryPrefix: true, encode: false, arrayFormat: 'brackets', skipNulls: true });
    const baseEndpoint = isStockProducts ? 'stock-purchase-order-products' : 'purchase-order-products';
    const endpointWithParams = baseEndpoint + parsed;

    const { data: products, isLoading: isLoadingProducts, isError: isErrorProductsPurchaseOrder } = useGetAllPurchaseOrderProductsApi(endpointWithParams);

    if (isErrorProductsPurchaseOrder) {
        return <Empty title="Nenhum produto encontrado" />;
    }

    return (
        <>
            <PageHeader title={title} className="mb-6" />
            <PurchaseOrderProducts
                listEndpoint={endpointWithParams}
                canDoActions={true}
                isLoadingProducts={isLoadingProducts}
                products={products?.items || []}
                advancedSearchComponent={<PurchaseOrderProductsFilters onSetPage={setPage} filters={filters} onSetFilters={setFilters} />}
                columns={purchaseOrderProductsColumns(isStockProducts)}
                tableOptions={{
                    pagination: true,
                    count: products?.total,
                    page,
                    serverSide: true,
                    onChangePage: setPage,
                    isRowSelectable: (dataIndex) => {
                        const product = (products?.items || [])[dataIndex];

                        if (cannotSelectStatuses.includes(product.purchaseProductStatus)) {
                            return false;
                        }

                        return true;
                    },
                    onSearchChange: (searchText) => setFilters((prev) => ({ ...prev, searchText })),
                    onColumnSortChange: (changedColumn, direction) => {
                        const orderByKeyName = purchaseOrderProductsOrderByMap[changedColumn];

                        if (!orderByKeyName) {
                            return;
                        }

                        setOrderBy({ [orderByKeyName]: direction.toUpperCase() });
                    },
                }}
            />
            <Outlet context={{ listEndpoint: endpointWithParams }} />
        </>
    );
};

export default PurchaseOrderList;
