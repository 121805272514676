import { RouteObject } from 'react-router-dom';
import { Rule } from 'types/permissions';
import Page from 'pages/shared/page';
import VisitPage from 'pages/private/visits/view';
import VisitsPage from 'pages/private/visits';

const visits: RouteObject[] = [
    {
        // @ts-ignore
        breadcrumb: 'Visitar',
        path: 'visitas',
        element: <Page Component={VisitsPage} code="visitas" title="Visitas" permissions={[Rule.List]} />,
        children: [
            {
                path: ':visitId',
                element: <Page Component={VisitPage} code="visitas" permissions={[Rule.Show]} withModal={true} />,
            },
        ],
    },
];

export default visits;
