import { useQuery } from 'react-query';
import apiGraphQl from 'services/graphql';
import { Product } from 'types/models/product';
import { getProductKey } from './keys';

const useGetProduct = (query: string, productId?: string) => {
    const request = async () => apiGraphQl<{ productByPk: Partial<Product> }>(query).then(({ productByPk }) => productByPk);

    return useQuery(getProductKey(productId), request, { enabled: Boolean(productId) });
};

export default useGetProduct;
