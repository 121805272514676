import { BaseModel } from './base';
import { AddressCustomer } from './customer';

export type Address = {
    neighbourhood: string;
    number: string;
    cityId: number;
    stateId: number;
    street: string;
    zip: string;
    compliment?: string;
    city: Partial<City>;
    state: Partial<State>;
    className: AddressClassNames;
    full?: string;
    addressCustomer: Partial<AddressCustomer>;
    addressPerson: any; // TODO: typo
    addressProvider: any; // TODO: typo
    latitude?: string;
    longitude?: string;
} & BaseModel;

export type State = {
    id: number;
    name: string;
    initials: string;
    countryId: number;
    cities: City[];
};

export type City = {
    id: number;
    name: string;
    state: State;
    stateId: number;
};

export enum AddressClassNames {
    Customer = 'customer',
    Provider = 'provider',
    Person = 'person',
    OrderService = 'orderService',
}

export type PostmonAddress = {
    bairro: string;
    cidade: string;
    logradouro: string;
    estado_info: {
        area_km2: string;
        codigo_ibge: string;
        nome: string;
    };
    cep: string;
    cidade_info: {
        area_km2: string;
        codigo_ibge: string;
    };
    estado: string;
};
