import Avatar from 'components/core/avatar';
import Badge from 'components/core/badge';
import Text from 'components/core/text';
import { Person, PersonContract } from 'types/models/person';
import useTheme from '@mui/material/styles/useTheme';
import PersonDetailsSidebarActions from './actions';
import { Modify } from 'types/general';

type PersonDetailsSidebarProps = {
    person: Modify<Person, { contracts?: PersonContract }>;
};

const PersonDetailsSidebar = ({ person }: PersonDetailsSidebarProps) => {
    const { palette } = useTheme();

    return (
        <div className="bg-base-100 border-r border-r-base-300 w-[300px] h-full overflow-y-auto flex flex-col">
            <div className="items-center flex flex-col border-b border-b-base-300 mb-4 pb-6">
                <Avatar firstLetter={person?.name?.charAt(0)} size="large" className="my-4" src={person?.profileImage?.url} />
                <Text variant="h5" as="h3" className="mb-2 text-center mx-2" style={{ color: palette.heading }}>
                    {person?.title}
                </Text>
                {Boolean(person?.contracts) && (
                    <Badge variant="info" className="ml-2 w-[137px] text-center">
                        {person.contracts?.personContractStatus?.title}
                    </Badge>
                )}
            </div>
            <div className="flex flex-col px-4 items-center mb-4 pt-2">
                <PersonDetailsSidebarActions personId={person?.id} hasUser={!!person.user} mainContractId={person.contracts?.id} />
            </div>
        </div>
    );
};

export default PersonDetailsSidebar;
