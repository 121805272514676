import { useQuery } from 'react-query';
import api from 'services/api';
import useToast from 'hooks/toast/use-toast';
import { getTransactionKey } from './keys';
import { Transaction } from 'types/models/transaction';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';

const useGetTransaction = (transactionId?: string | number) => {
    const { showToast } = useToast();
    const goToParentRoute = useNavigateToParentRoute();

    const fetcher = async () => {
        const { data } = await api.get<Transaction>(`/transactions/${transactionId}`);

        return data;
    };

    return useQuery(getTransactionKey(transactionId), fetcher, {
        enabled: !!transactionId,
        onError: () => {
            showToast(`Ocorreu um erro ao obter os detalhes da operação`, 'danger');
            goToParentRoute();
        },
    });
};

export default useGetTransaction;
