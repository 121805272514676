import { BaseModel } from './base';
import { Branch } from './branch';
import { Person } from './person';
import { Classification } from './classification';
import { Product } from './product';
import { OrderService, OrderServiceProductPurchase, PurchaseScopeActions } from './order-service';
import { ApprovalStatus, Option, Priority } from 'types/general';
import { Stock } from './stock';
import { ExpenseInstallments } from 'pages/private/expenses/crud/installments';
import { ExpenseNature, ExpenseProduct, ExpenseProducts } from './expense';
import { Company } from './company';
import { FormFavoredType } from './favored';

export enum PurchaseStatus {
    Open,
    Processed,
    Finished,
    Canceled,
}

export type PurchaseOrder = {
    branchId: number;
    purchaseOrderOriginId: number;
    personId: number;
    personRequesterId: number;
    code: string;
    priority: Priority;
    purchaseStatus: PurchaseStatus;
    purchaseOrderProductExtras: any; // TODO:
    purchaseOrderProducts: Partial<PurchaseOrderProduct>[];
    branch: Partial<Branch>;
    person: Partial<Person>;
    personByPersonRequesterId: Partial<Person>;
    purchaseOrderOrigin: Partial<PurchaseOrderOrigin>;
    origin: Partial<PurchaseOrderOrigin>;
    company: Partial<Company>;
    justification: string;
} & BaseModel;

export enum PurchaseProductStatus {
    Canceled = -1,
    Waiting,
    Bought,
    Delivered,
    WaitingPayment,
    WaitingDelivered,
    Finished,
}

export type PurchaseOrderProduct = {
    purchaseOrderId: number;
    classificationId: number;
    orderServiceProductPurchaseId: number;
    quantity: number;
    justification: string;
    purchaseProductStatus: PurchaseProductStatus;
    purchaseOrderProductExtras: any; // TODO:
    classification: Partial<Classification>;
    orderServiceProductPurchase: Partial<OrderServiceProductPurchase>;
    product: Partial<Product> | Partial<Product>;
    purchaseOrder: Partial<PurchaseOrder> | Partial<PurchaseOrder>;
    approvalStatus: ApprovalStatus;
    approved: boolean;
    parentPurchaseOrderProduct: any; //TODO:
    stockBalance: number;
    systemJustification: string;
    totalApproved: number;
    totalDemand: number;
    expenseProduct: Partial<ExpenseProduct>;
    billed: boolean;
    provision?: string | null;
    purchaseValue?: number | null;
} & BaseModel;

export enum FinancialType {
    Analytic = 1,
    Financial,
}

export type PurchaseProduct = {
    approvalStatus: ApprovalStatus;
    balance: number;
    justification: string;
    product: Partial<Product>;
    provision: string;
    quantity: number;
    value: number;
    total: number;
    requests?: PurchaseProductRequest[];
    action?: PurchaseScopeActions;
} & BaseModel;

export type PurchaseProductRequest = {
    balance: number;
    date: string;
    id: number;
    person: Partial<Person>;
    product: Partial<Product>;
    provision: string;
    quantity: number;
    value: number;
    justification?: string;
};

export type PurchaseOrderOrigin = {
    className: 'orderService' | 'stock';
    purchaseOrders: Partial<PurchaseOrder>[];
    purchaseOrderOriginOrderService: Partial<PurchaseOrderOriginOrderService>;
    orderService: Partial<PurchaseOrderOriginOrderService>;
    purchaseOrderOriginStock: Partial<PurchaseOrderOriginStock>;
    stock: Partial<PurchaseOrderOriginStock>;
} & BaseModel;

export type PurchaseOrderOriginOrderService = {
    id: number;
    order_service_id: number;
    orderService: Partial<OrderService>;
    purchaseOrderOrigin: Partial<PurchaseOrderOrigin>;
};

export type PurchaseOrderOriginStock = {
    id: number;
    stock_id: number;
    stock: Partial<Stock>;
    purchaseOrderOrigin: Partial<PurchaseOrderOrigin>;
};

export type PurchaseOrderExpensePayload = {
    company: number;
    fiscalCode: number;
    sector: number;
    category: number;
    classificationOrigin: number;
    classificationSubOrigin: number;
    branch: number;
    formFavored?: Option & { type: FormFavoredType };
    favoredBankAccount?: number;
    paymentForm: number;
    instalments: number;
    transactions: ExpenseInstallments[];
    documentNumber: string;
    dueDate: string;
    provision: string;
    provisioned: boolean;
    title: string;
    observations: string;
    products: ExpenseProducts[];
    budgets?: Array<{
        provider: string;
        file?: number;
        value?: number;
    }>;
    nature: ExpenseNature;
    documents: Array<{
        localFile: File;
        name: string;
        classification?: number;
        file?: number;
    }>;
    competence?: string;
};

export enum PurchaseOrderProductsActions {
    Approve = 'approve',
    Disapprove = 'disapprove',
    Cancel = 'cancel',
    Delete = 'delete',
    Entry = 'entry',
}

export type PurchaseOrderPayload = {
    company: number;
    formOrigin: { stock: number };
    products: Array<{
        id: number;
        balance?: number;
        quantity?: number;
        orderServiceProductPurchase?: number | null;
        product: {
            code: string;
            name: string;
            classificationByClassificationUnitId: { name: string };
        };
        justification?: string;
        preApproved: boolean;
        orderServiceProductSale?: number;
        billed: boolean;
        value?: number;
        provision: string;
        purchaseValue?: number;
    }>;
};

export type PurchaseOrderProductsFiltersPayload = {
    purchaseProductStatus: number[];
    company: number;
    approvalStatus: number;
    billed: boolean | null;
    startProvision: string;
    endProvision: string;
};
