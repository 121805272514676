import Select from 'components/core/form/select';
import { Controller, useFormContext } from 'react-hook-form';
import Input from 'components/core/form/input';
import { Option } from 'types/general';
import Text from 'components/core/text';
import DatePicker from 'components/core/datepicker';
import Textarea from 'components/core/form/textarea';
import _get from 'lodash/get';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import useGetClassifications from 'services/queries/classifications/use-get-classifications';
import { generateClassificationQuery } from 'services/queries/classifications/utils';
import { ClassificationSectorCode, ClassificationType } from 'types/models/classification';
import useGetOptions from 'services/queries/crud/use-get-options';
import { generateGetBranchOptionsQuery } from 'settings/routes/branches/utils';
import FavoredSelect from 'components/favored-select';
import Spinner from 'components/core/spinner';
import { PurchaseOrderExpensePayload } from 'types/models/purchase-order';
import { useParams } from 'react-router-dom';
import useGetCompaniesOptions from 'services/queries/companies/use-get-company-options';
import { booleanOptions } from 'utils/statics';
import { excludeDates } from 'pages/private/payment-order/actions/ops-grouping/general';
import { natures } from 'utils/statics';
import { ExpenseNature } from 'types/models/expense';
import useGetBankAccountsByEntity from 'services/queries/bank-accounts/use-get-bank-accounts-by-entity';
import { favoredPeopleWhere } from 'pages/private/expenses/crud/general';
import dayjs from 'dayjs';
import ModalProviderConsulting from 'pages/private/providers/view/modal-provider-consulting';

const purchaseOrdersExpenseStatuses = [ExpenseNature.PurchaseOrder, ExpenseNature.StockPurchaseOrder, ExpenseNature.PurchaseOrderBilled];

const General = () => {
    const [providerId, setProviderId] = useState<number | null>(null);

    const params = useParams();
    const purchaseProductsIds = params.purchaseProductsIds?.split(',') || [];

    const { control, formState, watch, setValue } = useFormContext<PurchaseOrderExpensePayload>();

    const nature = watch('nature');

    const isPurchaseOrder = purchaseOrdersExpenseStatuses.includes(nature);

    const { data: companies = [] } = useGetCompaniesOptions();

    const { data: sectors = [], isLoading: isLoadingSectors } = useGetClassifications(
        generateClassificationQuery(
            ClassificationType.Sector,
            Boolean(purchaseProductsIds.length)
                ? {
                      code: { _in: [ClassificationSectorCode.Commercial.toString(), ClassificationSectorCode.OrderService.toString()] },
                  }
                : undefined
        )
    );

    const sector = watch('sector');

    const { data: categories = [], isLoading: isLoadingCategories } = useGetClassifications(
        generateClassificationQuery(ClassificationType.TransactionCategory, { classificationId: { _eq: sector } }),
        !!sector
    );

    const category = watch('category');

    const { data: origins = [], isLoading: isLoadingOrigins } = useGetClassifications(
        generateClassificationQuery(ClassificationType.TransactionOrigin, { classificationId: { _eq: category } }),
        !!category
    );

    const [origin, paymentForm, favored] = watch(['classificationOrigin', 'paymentForm', 'formFavored']);

    const { data: subOrigins = [], isLoading: isLoadingSubOrigins } = useGetClassifications(
        generateClassificationQuery(ClassificationType.TransactionSubOrigin, { classificationId: { _eq: origin } }),
        !!origin
    );

    const { data: paymentForms = [], isLoading: isLoadingPaymentForms } = useGetClassifications(generateClassificationQuery(ClassificationType.PaymentForm));
    const { data: branches = [], isLoading: isLoadingBranches } = useGetOptions(generateGetBranchOptionsQuery(), ['branch', 'options']);

    const { data: fiscalCodes = [], isLoading: isLoadingFiscalCodes } = useGetClassifications(
        generateClassificationQuery(
            ClassificationType.ExpenseFiscalCode,
            isPurchaseOrder && {
                data: {
                    _contains: { isOrderService: nature === ExpenseNature.PurchaseOrder || nature === ExpenseNature.StockPurchaseOrder },
                },
            }
        )
    );

    const isBankAccountFieldVisible = useMemo(() => {
        return paymentForms
            .filter((item) => item.data.withBankAccount)
            .map(({ value }) => value)
            .includes(paymentForm);
    }, [paymentForm, paymentForms]);

    const { data: bankAccounts = [], isLoading: isLoadingBankAccounts } = useGetBankAccountsByEntity(favored, isBankAccountFieldVisible);

    const isBuilding = useMemo(() => isLoadingBranches || isLoadingSectors || isLoadingPaymentForms, [isLoadingBranches, isLoadingPaymentForms, isLoadingSectors]);

    const getError = useCallback((name: string) => _get(formState.errors, `${name}.message`) as any, [formState]);

    useEffect(() => {
        if (isPurchaseOrder) {
            if (Boolean(sectors.length)) {
                const construction = sectors.find(({ label }) => label === 'Obras');

                if (!construction) {
                    return;
                }

                setValue('sector', construction.value);
            }

            if (Boolean(categories.length)) {
                const materials = categories.find(({ label }) => label === 'Materiais');

                if (!materials) {
                    return;
                }

                setValue('category', materials.value);
            }
        }
    }, [isPurchaseOrder, categories, sectors, setValue]);

    if (isBuilding) {
        return (
            <div className="p-6 mb-6">
                <div className="border border-base-300 p-4 rounded-[14px]">
                    <Spinner fixed={false} size={24} wrapperClasses="p-2 bg-base-100 rounded-full" />
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="px-6 mb-6">
                <div className="border border-base-300 p-4 rounded-[14px]">
                    <Text as="h6" variant="h5" className="text-heading mb-4">
                        Geral
                    </Text>
                    <div className="md:grid md:grid-cols-4 gap-4">
                        <Controller
                            name="company"
                            control={control}
                            render={({ field }) => {
                                const value = companies.find((item) => item.value === field.value);

                                return (
                                    <Select
                                        {...field}
                                        isDisabled={true}
                                        value={value}
                                        options={companies}
                                        label="Empresa"
                                        placeholder="Selecione uma opção"
                                        parentClassName="col-span-2"
                                        onChange={(option: any) => field.onChange(option.value)}
                                        autoFocus={true}
                                        error={getError('company')}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="branch"
                            control={control}
                            render={({ field }) => {
                                const value = branches.find((item) => item.value === field.value);

                                return (
                                    <Select
                                        {...field}
                                        isLoading={isLoadingBranches}
                                        value={value}
                                        options={branches}
                                        label="Filial"
                                        placeholder="Selecione uma opção"
                                        onChange={(option: any) => field.onChange(option.value)}
                                        error={getError('branch')}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="nature"
                            control={control}
                            render={({ field }) => {
                                const value = natures.find((item) => item.value === field.value);

                                return (
                                    <Select
                                        {...field}
                                        isDisabled={true}
                                        value={value}
                                        options={natures}
                                        label="Natureza"
                                        placeholder="Selecione uma opção"
                                        onChange={(option: any) => field.onChange(option.value)}
                                        autoFocus={true}
                                        error={getError('nature')}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="sector"
                            control={control}
                            render={({ field }) => {
                                const value = sectors.find((item) => item.value === field.value);

                                return (
                                    <Select
                                        {...field}
                                        isDisabled={isPurchaseOrder}
                                        isLoading={isLoadingSectors}
                                        value={value}
                                        options={sectors}
                                        label="Setor"
                                        placeholder="Selecione uma opção"
                                        onChange={(option: any) => field.onChange(option.value)}
                                        error={getError('sector')}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="category"
                            control={control}
                            render={({ field }) => {
                                const value = categories.find((item) => item.value === field.value);

                                return (
                                    <Select
                                        {...field}
                                        isLoading={isLoadingCategories}
                                        value={value}
                                        options={categories}
                                        label="Categoria*"
                                        required={true}
                                        placeholder="Selecione uma opção"
                                        onChange={(option: any) => field.onChange(option.value)}
                                        error={getError('category')}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="classificationOrigin"
                            control={control}
                            render={({ field }) => {
                                const value = origins.find((item) => item.value === field.value);

                                return (
                                    <Select
                                        {...field}
                                        isLoading={isLoadingOrigins}
                                        value={value}
                                        options={origins}
                                        label="Origem"
                                        placeholder="Selecione uma opção"
                                        onChange={(option: any) => field.onChange(option.value)}
                                        required={true}
                                        error={getError('origin')}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="classificationSubOrigin"
                            control={control}
                            render={({ field }) => {
                                const value = subOrigins.find((item) => item.value === field.value);

                                return (
                                    <Select
                                        {...field}
                                        isLoading={isLoadingSubOrigins}
                                        value={value}
                                        options={subOrigins}
                                        label="Sub-origem*"
                                        placeholder="Selecione uma opção"
                                        onChange={(option: any) => field.onChange(option.value)}
                                        error={getError('subOrigin')}
                                        required={true}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="formFavored"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <FavoredSelect
                                        peopleWhere={favoredPeopleWhere}
                                        error={formState.errors?.formFavored?.value?.message}
                                        onChange={(option: Option) => field.onChange(option)}
                                        value={field.value}
                                        onOpenProviderConsultingModal={setProviderId.bind(this, field.value?.value!)}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="paymentForm"
                            control={control}
                            render={({ field }) => {
                                const value = paymentForms.find((item) => item.value === field.value);

                                return (
                                    <Select
                                        {...field}
                                        isLoading={isLoadingPaymentForms}
                                        value={value}
                                        options={paymentForms}
                                        label="Forma de Pagamento*"
                                        placeholder="Selecione uma opção"
                                        onChange={(option: any) => field.onChange(option.value)}
                                        error={getError('paymentForm')}
                                        required={true}
                                    />
                                );
                            }}
                        />
                        {isBankAccountFieldVisible && (
                            <Controller
                                name="favoredBankAccount"
                                control={control}
                                render={({ field }) => {
                                    const value = bankAccounts.find((item) => item.value === field.value);

                                    return (
                                        <Select
                                            {...field}
                                            value={value}
                                            options={bankAccounts}
                                            isDisabled={!Boolean(bankAccounts?.length)}
                                            isLoading={isLoadingBankAccounts}
                                            label="Conta bancária"
                                            placeholder="Selecione uma opção"
                                            onChange={(option: any) => field.onChange(option.value)}
                                            error={getError('favoredBankAccount')}
                                            required={true}
                                        />
                                    );
                                }}
                            />
                        )}
                        <Controller
                            name="documentNumber"
                            control={control}
                            render={({ field }) => <Input {...field} error={getError('documentNumber')} label="Documento" autoComplete="nope" placeholder="Documento" />}
                        />
                        <Controller
                            name="dueDate"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <DatePicker
                                        inputProps={{
                                            label: 'Vencimento',
                                            placeholderText: 'Selecione uma data',
                                            error: getError('dueDate'),
                                            required: true,
                                            ...field,
                                        }}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="competence"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <DatePicker
                                        inputProps={{
                                            label: 'Competência',
                                            placeholderText: 'Selecione uma data',
                                            error: getError('competence'),
                                            ...field,
                                        }}
                                        muiDatePickerProps={{
                                            minDate: dayjs().month(0).year(2024),
                                            shouldDisableYear: (date) => dayjs().year() < date.year(),
                                            views: ['month', 'year'],
                                            format: 'MM/YYYY',
                                        }}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="provision"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <DatePicker
                                        inputProps={{
                                            label: 'Programação',
                                            placeholderText: 'Selecione uma data',
                                            error: getError('provision'),
                                            required: true,
                                            ...field,
                                        }}
                                        muiDatePickerProps={{
                                            shouldDisableDate: excludeDates,
                                        }}
                                        withProvisionLogic={true}
                                    />
                                );
                            }}
                        />
                        {!isPurchaseOrder && (
                            <Controller
                                name="provisioned"
                                control={control}
                                render={({ field }) => {
                                    const value = booleanOptions.find((item) => !!item.value === field.value);

                                    return (
                                        <Select
                                            {...field}
                                            value={value}
                                            options={booleanOptions}
                                            label="Provisionada"
                                            placeholder="Selecione uma opção"
                                            onChange={(option: any) => field.onChange(option.value)}
                                            error={getError('provisioned')}
                                        />
                                    );
                                }}
                            />
                        )}
                        <Controller
                            name="fiscalCode"
                            control={control}
                            render={({ field }) => {
                                const value = fiscalCodes.find((item) => item.value === (field.value as any));

                                return (
                                    <Select
                                        {...field}
                                        isLoading={isLoadingFiscalCodes}
                                        value={value}
                                        options={fiscalCodes}
                                        label="CFOP"
                                        placeholder="Selecione uma opção"
                                        onChange={(option: any) => field.onChange(option.value)}
                                        error={getError('fiscalCode')}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="title"
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    error={getError('title')}
                                    autoComplete="nope"
                                    type="text"
                                    placeholder="Adicione alguma descrição"
                                    label="Descrição*"
                                    required={true}
                                    parentClassName="col-span-4"
                                />
                            )}
                        />
                        <Controller
                            name="observations"
                            control={control}
                            render={({ field }) => <Textarea {...field} error={getError('observations')} label="Observações" placeholder="Adicione alguma observação" parentClassName="col-span-4" />}
                        />
                    </div>
                </div>
            </div>
            {Boolean(providerId) && <ModalProviderConsulting providerId={providerId!} onClose={setProviderId.bind(this, null)} />}
        </>
    );
};

export default memo(General);
