import Empty from 'components/empty';
import StockProductHeader from 'pages/private/stocks/view/products/stock-product-header';
import FinancialBalanceStockMovements from './movements';
import { OrderServiceFinancialBalance } from 'types/models/order-service';

type OrderServiceFinancialBalanceMovementsProps = {
    stockProduct?: OrderServiceFinancialBalance['stockProduct'];
    productUnit?: string;
};

const OrderServiceFinancialBalanceMovements = ({ stockProduct, productUnit }: OrderServiceFinancialBalanceMovementsProps) => {
    const { value = 0, quantity = 0, movements = [] } = stockProduct || {};

    if (!stockProduct) {
        return <Empty className="p-4" title="Não há estoques a serem mostrados." />;
    }

    return (
        <div className="mb-4">
            <StockProductHeader containerClassName="mb-4" value={value} quantity={quantity} unitName={productUnit} />
            <div className="px-4">
                <FinancialBalanceStockMovements movements={movements} productUnit={productUnit} />
            </div>
        </div>
    );
};

export default OrderServiceFinancialBalanceMovements;
