import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CurrencyInput from 'components/core/form/currency';
import Spinner from 'components/core/spinner';
import Text from 'components/core/text';
import { Controller, useFormContext } from 'react-hook-form';
import { Option } from 'types/general';

type DeductionValuesTableProps = {
    selectedOrdersServices: Option[];
    isSaleNature?: boolean;
    isLoadingSaleOrders?: boolean;
};

const DeductionValuesTable = ({ selectedOrdersServices, isSaleNature = false, isLoadingSaleOrders }: DeductionValuesTableProps) => {
    const { control, setValue, formState, watch } = useFormContext();

    const deductionValues = watch('deductionValues');

    return (
        <div className="col-span-4 mb-2 max-h-[385px] overflow-y-auto border border-base-300 rounded-[14px]">
            {isLoadingSaleOrders ? (
                <Spinner fixed={false} size={24} wrapperClasses="p-2 bg-base-100 rounded-full" />
            ) : (
                <Table stickyHeader={true} aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                    Código
                                </Text>
                            </TableCell>
                            <TableCell>
                                <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                    Dedução
                                </Text>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {selectedOrdersServices.map((item: Option, index: number) => {
                            const deductionValue = deductionValues?.find((itemDeduction) => {
                                return itemDeduction?.orderService === item.value || itemDeduction?.saleOrder === item.value;
                            });

                            return (
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={index}>
                                    <TableCell>{item?.label}</TableCell>
                                    <TableCell>
                                        <div className="max-w-[300px]">
                                            <Controller
                                                name={`deductionValues.${index}`}
                                                control={control}
                                                render={({ field }) => {
                                                    return (
                                                        <CurrencyInput
                                                            disabled={isSaleNature}
                                                            ref={field.ref}
                                                            name={field.name}
                                                            value={deductionValue?.deductionValue || 0}
                                                            placeholder="Valor da dedução"
                                                            decimalScale={2}
                                                            min={0}
                                                            withFormat={true}
                                                            fixedDecimalScale={true}
                                                            left={
                                                                <Text as="span" variant="body.medium.sm" className="text-heading">
                                                                    R$
                                                                </Text>
                                                            }
                                                            error={formState?.errors?.deductionValues?.[index]?.deductionValue?.message}
                                                            onValueChange={({ floatValue }) => setValue(`deductionValues.${index}`, { deductionValue: floatValue || 0, orderService: item?.value })}
                                                            onChange={() => {
                                                                const totalDeductionsValues = deductionValues?.reduce((acc, act) => (acc += act?.deductionValue), 0) || 0;

                                                                setValue('deductionValue', totalDeductionsValues);
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                        </div>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            )}
        </div>
    );
};

export default DeductionValuesTable;
