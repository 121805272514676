import * as React from 'react';
const SvgIcoChat = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M1.752 1.752a.861.861 0 0 1 .61-.252H13.64a.861.861 0 0 1 .861.861v8.056a.861.861 0 0 1-.861.862H3.972a.75.75 0 0 0-.53.22L1.5 13.44V2.361a.86.86 0 0 1 .252-.609ZM2.362 0A2.361 2.361 0 0 0 0 2.361v12.89a.75.75 0 0 0 1.28.53l3.003-3.002h9.357A2.361 2.361 0 0 0 16 10.417V2.361A2.361 2.361 0 0 0 13.64 0H2.36Zm1.38 4a.75.75 0 0 0 0 1.5h8.508a.75.75 0 0 0 0-1.5H3.743Zm.008 3a.75.75 0 0 0 0 1.5h5.503a.75.75 0 1 0 0-1.5H3.75Z"
            clipRule="evenodd"
        />
    </svg>
);
export default SvgIcoChat;
