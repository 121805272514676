import classNames from 'classnames';
import Badge from 'components/core/badge';
import Modal from 'components/core/modal';
import Spinner from 'components/core/spinner';
import Text from 'components/core/text';
import Empty from 'components/empty';
import { useParams } from 'react-router';
import useGetForm from 'services/queries/forms/use-get-form';
import { FormGroupType, QuizType } from 'types/models/form';
import { formFieldTypes } from 'utils/statics';

const FormPage = () => {
    const { formId } = useParams();

    const { data: form, isLoading } = useGetForm(Number(formId));

    return (
        <Modal
            contentClassnames="w-[900px]"
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    {form?.title || 'Formulário'}
                </Text>
            }
            headerRight={<Badge variant="grey">{form?.type === QuizType.Visit ? 'Visita' : 'Outro form'}</Badge>}>
            {isLoading ? (
                <div className="relative p-8">
                    <Spinner size={20} />
                </div>
            ) : !form ? (
                <Empty title="Formulário não encontrado" />
            ) : (
                <div className="px-6 pb-6">
                    {form.groups.map((group) => {
                        const isConditionalGroup = group.type === FormGroupType.Conditional;

                        return (
                            <div className={classNames('box-with-border p-0 mb-4 last:mb-0 relative', isConditionalGroup ? 'border-system-warning-200' : '')} key={group.id}>
                                <div className="flex flex-row items-center justify-between p-4 border-b border-b-base-300 sticky left-0 top-0 rounded-t-[14px] bg-white z-20">
                                    <Text variant="h5" className="text-primary-500">
                                        {group.title}
                                    </Text>
                                </div>
                                {isConditionalGroup && (
                                    <div className="py-2 px-4 bg-system-warning-100 bg-opacity-50 border-b border-b-system-warning-200">
                                        <Text variant="body.regular.xs" className="text-system-warning-900 flex-1">
                                            Este é um grupo condicional. Seus campos só irão aparecer quando o usuário responder a pergunta:{' '}
                                            <strong className="block">"{group.conditionalFieldTitle}"</strong>
                                        </Text>
                                    </div>
                                )}
                                <div className="p-4">
                                    {group.fields.map((field) => {
                                        return (
                                            <div className="box-with-border p-0 mb-4 last:mb-0" key={field.id}>
                                                <div className={classNames('flex flex-row items-center justify-between p-4', Boolean(field.options.length) ? 'border-b border-b-base-300' : '')}>
                                                    <Text variant="h6" className="text-secondary-900">
                                                        {field.name}
                                                    </Text>
                                                    <div>
                                                        <Badge variant="grey" className="mr-2">
                                                            Tipo: {formFieldTypes.find((item) => item.value === field.type)?.label}
                                                        </Badge>
                                                        <Badge variant="info">Obrigatório: {field.required ? 'Sim' : 'Não'}</Badge>
                                                    </div>
                                                </div>
                                                {Boolean(field.options.length) && (
                                                    <div className="box-with-border p-0 m-4">
                                                        <div className="flex flex-row items-center justify-between p-4 border-b border-b-base-300">
                                                            <Text variant="body.medium.sm" className="text-secondary-700 flex-1">
                                                                Opções
                                                            </Text>
                                                        </div>
                                                        <div className="m-4">
                                                            {field.options.map((option, index) => {
                                                                const hasSubfields = !!option.subfields?.length;

                                                                return (
                                                                    <div
                                                                        className={classNames('box-with-border p-0 mb-4 last:mb-0', hasSubfields ? 'pb-4 border-system-warning-200' : '')}
                                                                        key={option.id}>
                                                                        <div
                                                                            className={classNames('flex items-center px-4 py-3', hasSubfields ? 'border-b border-b-base-300 last:mb-0' : '')}
                                                                            key={option.value}>
                                                                            <Text
                                                                                as="strong"
                                                                                className="bg-secondary-100 bg-opacity-50 text-xs text-secondary-900 rounded-full leading-none mr-2 w-7 h-7 flex items-center justify-center">
                                                                                {index + 1}
                                                                            </Text>
                                                                            <Text className="text-base-700 flex-1">{option.label}</Text>
                                                                        </div>
                                                                        {hasSubfields && (
                                                                            <div className="py-2 px-4 bg-system-warning-100 bg-opacity-50 border-b border-b-system-warning-200 mb-4">
                                                                                <Text variant="body.regular.xs" className="text-system-warning-900 flex-1">
                                                                                    Os campos abaixo só aparece quando o usuário selecionar esta opção.
                                                                                </Text>
                                                                            </div>
                                                                        )}
                                                                        {Boolean(option.subfields?.length) && (
                                                                            <div className="px-4 flex flex-col gap-3">
                                                                                {option.subfields!.map((subfield) => {
                                                                                    return (
                                                                                        <div key={subfield.id} className="flex flex-row items-center justify-between">
                                                                                            <Text variant="body.medium.sm" className="text-secondary-900">
                                                                                                {subfield.name}
                                                                                            </Text>
                                                                                            <div>
                                                                                                <Badge variant="grey" className="mr-2">
                                                                                                    Tipo: {formFieldTypes.find((item) => item.value === subfield.type)?.label}
                                                                                                </Badge>
                                                                                                <Badge variant="info">Obrigatório: {subfield.required ? 'Sim' : 'Não'}</Badge>
                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                        {/* {!!field.subfields?.length && (
                                                            <div className="bg-base-200 py-2 px-3 rounded-b-[14px] border-t border-t-base-300">
                                                                <Text className="text-base-700 font-bold mr-1">Campo adicional</Text>
                                                                <Text className="text-base-700">{field.subfield}</Text>
                                                            </div>
                                                        )} */}
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </Modal>
    );
};

export default FormPage;
