import { useQuery } from 'react-query';
import api from 'services/api';
import { getSaleOrderExpensesKey } from './keys';
import { Pagination } from 'types/pagination';

const useGetSaleOrderExpenses = (saleOrderId?: string, filters?: any) => {
    const request = async () => {
        const { data } = await api.get<Pagination<any>>(`/sale-orders/${saleOrderId}/expenses${filters || ''}`);

        return data;
    };

    return useQuery(getSaleOrderExpensesKey(saleOrderId), request, { enabled: Boolean(saleOrderId) });
};

export default useGetSaleOrderExpenses;
