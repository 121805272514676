import classNames from 'classnames';
import DatePicker from 'components/core/datepicker';
import Select from 'components/core/form/select';
import FavoredSelect from 'components/favored-select';
import { excludeDates } from 'pages/private/payment-order/actions/ops-grouping/general';
import { useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import useGetBankAccountsByEntity from 'services/queries/bank-accounts/use-get-bank-accounts-by-entity';
import useGetClassifications from 'services/queries/classifications/use-get-classifications';
import { generateClassificationQuery } from 'services/queries/classifications/utils';
import useGetConsultants from 'services/queries/people/use-get-consultants';
import useAuth from 'store/auth/use-auth';
import { Option } from 'types/general';
import { ClassificationType } from 'types/models/classification';

type ConfigKey = 'sector' | 'category' | 'origin' | 'subOrigin';

export type ExpenseFormConfig = {
    [key in ConfigKey]: {
        type: ClassificationType;
        where?: any;
        isEnabled?: boolean;
        isStaticField?: boolean;
        isHided?: boolean;
    };
} & {
    favored: {
        fieldLabel: string;
        isConsultant: boolean;
        isEnabled?: boolean;
    };
};

type ExpenseFormProps = { config: ExpenseFormConfig };

export type ExpenseFormPayload = {
    classificationOrigin: Option;
    classificationSubOrigin: Option;
    provision: string;
    sector: Option;
    category: Option;
    fiscalCode: Option;
    formFavored: {
        person: Option;
        favoredBankAccount?: Option;
    };
    paymentForm: Option;
};

const haveBankAccount = ['pix', 'débito em conta', 'ted | doc | tef'];

const ExpenseForm = ({ config }: ExpenseFormProps) => {
    const { auth } = useAuth();
    const { pathname } = useLocation();

    const isCreateCommissionPage = pathname.includes('comissoes');

    const { control, formState, watch, setValue } = useFormContext<ExpenseFormPayload>();

    const [sector, category, origin, favored, paymentForm] = watch(['sector', 'category', 'classificationOrigin', 'formFavored.person', 'paymentForm']);

    const formFavored = {
        ...favored,
        type: 'person' as any,
    };

    const isBankAccountVisible = useMemo(() => {
        return haveBankAccount.includes(paymentForm?.label?.toLowerCase()) && Boolean(favored);
    }, [paymentForm, favored]);

    const { data: consultants = [], isLoading: isLoadingConsultants } = useGetConsultants(!isCreateCommissionPage, config.favored.isConsultant);
    const { data: favoredBankAccounts = [], isLoading: isLoadingBankAccounts } = useGetBankAccountsByEntity(formFavored, isBankAccountVisible);
    const { data: paymentForms = [], isLoading: isLoadingPaymentForms } = useGetClassifications(generateClassificationQuery(ClassificationType.PaymentForm));
    const { data: fiscalCodes = [], isLoading: isLoadingFiscalCodes } = useGetClassifications(generateClassificationQuery(ClassificationType.ExpenseFiscalCode));
    const { data: origins = [], isLoading: isLoadingOrigins } = useGetClassifications(generateClassificationQuery(config.origin.type, config.origin.where), !!category && !config.origin.isHided);
    const { data: categories = [], isLoading: isLoadingCategories } = useGetClassifications(generateClassificationQuery(config.category.type, config.category.where), !!sector);
    const { data: sectors = [], isLoading: isLoadingSectors } = useGetClassifications(generateClassificationQuery(config.sector.type, config.sector.where), !!config.sector.isEnabled);
    const { data: subOrigins = [], isLoading: isLoadingSubOrigins } = useGetClassifications(
        generateClassificationQuery(config.subOrigin.type, config.subOrigin.where),
        !!origin && !config.subOrigin.isHided
    );

    const handleChangePaymentForm = (onChange, option) => {
        if (!haveBankAccount.includes(option.label.toLowerCase())) {
            setValue('formFavored.favoredBankAccount', undefined);
        }

        onChange(option);
    };

    const newConsultantsOptions = useMemo(() => {
        if (isCreateCommissionPage) {
            return consultants.map((item) => ({
                value: item.id,
                label: item.title,
                activeContract: item.activeContract,
            }));
        }

        return consultants;
    }, [consultants, isCreateCommissionPage]);

    useEffect(() => {
        if (Boolean(sectors.length) && config.sector.isStaticField) {
            setValue('sector', sectors[0]);
        }
    }, [sectors, config.sector.isStaticField, setValue]);

    useEffect(() => {
        if (Boolean(categories.length) && config.category.isStaticField) {
            setValue('category', categories[0]);
        }
    }, [categories, setValue, config.category.isStaticField]);

    useEffect(() => {
        if (Boolean(origins.length) && config.origin.isStaticField && !config.origin.isHided) {
            setValue('classificationOrigin', origins[0]);
        }
    }, [origins, setValue, config.origin.isStaticField, config.origin.isHided]);

    useEffect(() => {
        if (isCreateCommissionPage && Boolean(newConsultantsOptions.length) && !favored) {
            const finded = (newConsultantsOptions as Option[]).find((item) => item.value === auth.credentials.user.person?.id);

            if (!!finded) {
                setValue('formFavored.person', finded);
            }
        }
    }, [auth.credentials.user.person?.id, favored, isCreateCommissionPage, newConsultantsOptions, setValue]);

    return (
        <>
            <Controller
                name="sector"
                control={control}
                render={({ field }) => {
                    const value = sectors.find((item) => item.value === field.value?.value);

                    return (
                        <Select
                            {...field}
                            isDisabled={config.sector.isStaticField}
                            isLoading={isLoadingSectors}
                            value={value}
                            options={sectors}
                            label="Setor"
                            placeholder="Selecione uma opção"
                            onChange={(option: any) => field.onChange(option)}
                            error={formState.errors.sector?.message}
                        />
                    );
                }}
            />
            <Controller
                name="category"
                control={control}
                render={({ field }) => {
                    const value = categories.find((item) => item.value === field.value?.value);

                    return (
                        <Select
                            {...field}
                            isDisabled={config.category.isStaticField}
                            isLoading={isLoadingCategories}
                            value={value}
                            options={categories}
                            label="Categoria"
                            placeholder="Selecione uma opção"
                            onChange={(option: any) => field.onChange(option)}
                            error={formState.errors.category?.message}
                        />
                    );
                }}
            />
            {!config.origin.isHided && (
                <Controller
                    name="classificationOrigin"
                    control={control}
                    render={({ field }) => {
                        const value = origins.find((item) => item.value === field.value?.value);

                        return (
                            <Select
                                {...field}
                                isDisabled={config.origin.isStaticField}
                                isLoading={isLoadingOrigins}
                                value={value}
                                options={origins}
                                label="Origem"
                                placeholder="Selecione uma opção"
                                onChange={(option: any) => field.onChange(option)}
                                error={formState.errors.classificationOrigin?.message}
                            />
                        );
                    }}
                />
            )}
            {!config.subOrigin.isHided && (
                <Controller
                    name="classificationSubOrigin"
                    control={control}
                    render={({ field }) => {
                        const value = subOrigins.find((item) => item.value === field.value?.value);

                        return (
                            <Select
                                {...field}
                                isLoading={isLoadingSubOrigins}
                                value={value}
                                options={subOrigins}
                                label="Sub-origem"
                                placeholder="Selecione uma opção"
                                onChange={(option: any) => field.onChange(option)}
                                error={formState.errors.classificationSubOrigin?.message}
                            />
                        );
                    }}
                />
            )}
            {config.favored.isConsultant ? (
                <Controller
                    name="formFavored.person"
                    control={control}
                    render={({ field }) => {
                        return (
                            <Select
                                {...field}
                                isDisabled={!config.favored.isEnabled}
                                isLoading={isLoadingConsultants}
                                options={newConsultantsOptions}
                                label={config.favored.fieldLabel}
                                placeholder="Selecione uma opção"
                                onChange={(option: any) => field.onChange(option)}
                                error={formState.errors.formFavored?.person?.message}
                                parentClassName={classNames(!config.origin.isHided && !config.subOrigin.isHided && 'sm:col-span-2')}
                            />
                        );
                    }}
                />
            ) : (
                <Controller
                    name="formFavored.person"
                    control={control}
                    render={({ field }) => {
                        return <FavoredSelect hideActions={true} value={field.value} error={formState.errors?.formFavored?.person?.message} onChange={(option: Option) => field.onChange(option)} />;
                    }}
                />
            )}
            <Controller
                control={control}
                name="paymentForm"
                render={({ field }) => {
                    const value = paymentForms.find((paymentForm) => paymentForm.value === field.value?.value);

                    return (
                        <Select
                            options={paymentForms}
                            isLoading={isLoadingPaymentForms}
                            label="Forma de Pagamento"
                            value={value}
                            onChange={handleChangePaymentForm.bind(this, field.onChange)}
                            error={formState.errors.paymentForm?.message}
                        />
                    );
                }}
            />
            {isBankAccountVisible && (
                <Controller
                    name="formFavored.favoredBankAccount"
                    control={control}
                    render={({ field }) => {
                        const value = favoredBankAccounts.find((item) => item.value === field.value?.value);

                        return (
                            <Select
                                {...field}
                                isLoading={isLoadingBankAccounts}
                                value={value}
                                options={favoredBankAccounts}
                                label="Conta bancária"
                                placeholder="Selecione uma opção"
                                onChange={(option: any) => field.onChange(option)}
                                error={formState.errors.formFavored?.favoredBankAccount?.message}
                            />
                        );
                    }}
                />
            )}
            <Controller
                name="provision"
                control={control}
                render={({ field }) => {
                    return (
                        <DatePicker
                            inputProps={{
                                placeholderText: 'Selecione a data de previsão',
                                label: 'Provisão',
                                error: formState.errors.provision?.message,
                                ...field,
                            }}
                            muiDatePickerProps={{
                                shouldDisableDate: excludeDates,
                            }}
                            withProvisionLogic={true}
                        />
                    );
                }}
            />
            <Controller
                name="fiscalCode"
                control={control}
                render={({ field }) => {
                    const value = fiscalCodes.find((item) => item.value === field.value?.value);

                    return (
                        <Select
                            {...field}
                            isLoading={isLoadingFiscalCodes}
                            value={value}
                            options={fiscalCodes}
                            label="CFOP"
                            placeholder="Selecione uma opção"
                            onChange={(option: any) => field.onChange(option)}
                            error={formState.errors.fiscalCode?.message}
                        />
                    );
                }}
            />
        </>
    );
};

export default ExpenseForm;
