import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import { getAllKey } from '../crud/keys';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';

type Payload = {
    deductions: Array<{
        transaction: number;
        value: number;
    }>;
};

const useCreateOrUpdateDeduction = (saleOrderId?: string, orderServiceId?: string, params?: any) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const goToParentRoute = useNavigateToParentRoute();

    const request = (payload: Payload) => api.post(`/sale-orders/${saleOrderId}/service-orders/${orderServiceId}/deductions`, payload);

    return useMutation(request, {
        onSuccess: () => {
            queryClient.invalidateQueries(getAllKey('orderServiceDeduction', params));

            goToParentRoute();

            showToast('Dedução cadastrada com sucesso', 'success');
        },
        onError: () => {
            showToast('Ocorreu um erro ao cadastrar a dedução', 'danger');
        },
    });
};

export default useCreateOrUpdateDeduction;
