import { forwardRef, memo, useEffect, useMemo, useRef, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import Form from './form';
import useGetCity from 'services/queries/cities/use-get-city';
import useGetState from 'services/queries/states/use-get-state';
import useGetStateOptions from 'services/queries/states/use-get-state-options';
import useGetCitiesOptions from 'services/queries/cities/use-get-cities-options';

type AddressFormProps = {
    className?: string;
} & UseFormReturn<any, any>;

const AddressForm = forwardRef<HTMLInputElement, AddressFormProps>((props, zipRef) => {
    const [stateId, setStateId] = useState<number>();
    const [cityName, setCityName] = useState<string>();
    const [stateName, setStateName] = useState<string>();

    const filterCity = useMemo(
        () => ({
            where: {
                name: { _eq: cityName },
            },
        }),
        [cityName]
    );

    const filterState = useMemo(
        () => ({
            where: {
                name: { _eq: stateName },
            },
        }),
        [stateName]
    );

    const wasInitialStateIdSetted = useRef(false);

    const watchStateId = props?.watch('address.stateId');

    const { data: states } = useGetStateOptions();
    const { data: cities } = useGetCitiesOptions(stateId);
    const { data: city } = useGetCity('name', filterCity);
    const { data: state } = useGetState('name', filterState);

    useEffect(() => {
        if (!wasInitialStateIdSetted.current && !!watchStateId) {
            wasInitialStateIdSetted.current = true;

            setStateId(watchStateId);
        }
    }, [watchStateId]);

    return <Form {...props} ref={zipRef} city={city?.id} state={state?.id} cities={cities || []} states={states || []} onGetCity={setCityName} onGetState={setStateName} onSelectState={setStateId} />;
});

export default memo(AddressForm);
