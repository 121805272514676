import Select from 'components/core/form/select';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { memo, useMemo } from 'react';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { CrudPageProps } from 'types/graphql';
import { formatDate } from 'utils/date';
import { createOrUpdateOrderPurchaseSchema, formatProductsResponse, generateStocksOptionsQuery } from './utils';
import { Product } from 'types/models/product';
import FormButtons from 'components/form-buttons';
import { yupResolver } from '@hookform/resolvers/yup';
import useGetCompaniesOptions from 'services/queries/companies/use-get-company-options';
import useGetOptions from 'services/queries/crud/use-get-options';
import { getStocksOptionsKey } from 'services/queries/stocks/keys';
import useCreateOrUpdateStockPurchaseOrder from 'services/queries/stocks/use-create-or-update-stock-purchase-order';
import useGetDebouncedProducts from 'services/queries/products/use-get-debounced-products';
import OrderServicePurchaseOrderTable from 'pages/private/sales-orders/service-order/order-purchase/create-or-update/products-table';
import { PurchaseOrderPayload } from 'types/models/purchase-order';
import { purchaseProductsTypeWhereClause } from 'pages/private/sales-orders/service-order/order-purchase/create-or-update/utils';

const productQueryFields = ['id', 'name', 'code', 'classificationByClassificationUnitId.name'];
const stocksWhereClause = {
    main: {
        _eq: true,
    },
};

const CreateOrUpdateStockPurchaseOrderPage = ({ title }: CrudPageProps<{}>) => {
    const methods = useForm<PurchaseOrderPayload>({
        defaultValues: { products: [] },
        resolver: yupResolver(createOrUpdateOrderPurchaseSchema) as any,
    });

    const { control, formState, handleSubmit } = methods;

    const { append, remove, fields: selectedProducts } = useFieldArray({ name: 'products', control, keyName: 'fieldId' });

    const { data: stocks = [], isLoading: isLoadingStocks } = useGetOptions(generateStocksOptionsQuery(stocksWhereClause), getStocksOptionsKey, true);
    const { data: companies = [], isLoading: isLoadingCompanies } = useGetCompaniesOptions(undefined, undefined, true);
    const { isLoadingProducts, products, searchTerm, setSearchTerm } = useGetDebouncedProducts<Product, any[]>(productQueryFields, formatProductsResponse, purchaseProductsTypeWhereClause);

    const { mutateAsync: createOrUpdatePurchaseOrder, isLoading: isSubmitting } = useCreateOrUpdateStockPurchaseOrder();

    const filteredProducts = useMemo(() => {
        return products.filter((item) => !selectedProducts.find((prod) => prod.id === item.id!));
    }, [products, selectedProducts]);

    const handleSelectProducts = (option: any) => {
        append({
            id: option.value,
            product: {
                code: option.code,
                name: option.name,
                classificationByClassificationUnitId: { name: option.classificationByClassificationUnitId.name },
            },
            value: 0,
            preApproved: false,
            billed: false,
            provision: '',
            purchaseValue: 0,
        });

        setSearchTerm('');
    };

    const submit = async (data: PurchaseOrderPayload) => {
        try {
            const payload = {
                company: data.company,
                formOrigin: data.formOrigin,
                products: data.products.map((item) => ({
                    quantity: item.quantity || 0,
                    product: item.id,
                    justification: item.justification,
                    billed: item.billed,
                    provision: formatDate(item.provision, 'YYYY-MM-DD'),
                    ...(item.billed && {
                        purchaseValue: item.purchaseValue,
                    }),
                })),
            };

            await createOrUpdatePurchaseOrder(payload);
        } catch (error) {
            console.log('submit', error);
        }
    };

    return (
        <Modal
            contentClassnames="w-[90%]"
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    {title}
                </Text>
            }
            headerRight={
                <Text as="label" className="pl-0 block text-base-500">
                    Data do pedido: <Text className="font-bold">{formatDate(new Date())}</Text>
                </Text>
            }
            closeOnClickOutside={false}>
            <FormProvider {...methods}>
                <form className="p-6 pt-0" onSubmit={handleSubmit(submit)}>
                    <div className="gap-4 mb-4 grid grid-cols-3">
                        <Controller
                            control={control}
                            name="company"
                            render={({ field }) => {
                                const value = companies.find((item) => item.value === field.value);

                                return (
                                    <Select
                                        {...field}
                                        isLoading={isLoadingCompanies}
                                        value={value}
                                        options={companies}
                                        label="Empresa"
                                        placeholder="Selecione uma opção"
                                        error={formState.errors.company?.message}
                                        onChange={(option: any) => field.onChange(option.value)}
                                    />
                                );
                            }}
                        />
                        <Controller
                            control={control}
                            name="formOrigin.stock"
                            render={({ field }) => {
                                const value = stocks.find((item) => item.value === field.value);

                                return (
                                    <Select
                                        {...field}
                                        isLoading={isLoadingStocks}
                                        value={value}
                                        options={stocks}
                                        label="Estoque"
                                        placeholder="Selecione uma opção"
                                        error={formState.errors?.formOrigin?.stock?.message}
                                        onChange={(option: any) => field.onChange(option.value)}
                                    />
                                );
                            }}
                        />
                    </div>
                    <div className="mb-6">
                        <Select
                            value={searchTerm}
                            options={!!searchTerm ? filteredProducts : []}
                            label="Produto (código ou nome)"
                            placeholder="Digite o código ou nome do produto"
                            components={!Boolean(searchTerm) ? { NoOptionsMessage: () => null } : undefined}
                            onChange={handleSelectProducts}
                            onInputChange={setSearchTerm}
                            isLoading={isLoadingProducts}
                        />
                    </div>
                    {Boolean(selectedProducts.length) && <OrderServicePurchaseOrderTable onRemoveProduct={remove} selectedProducts={selectedProducts} withBalance={false} withSaleProducts={false} />}
                    {Boolean(selectedProducts.length) && <FormButtons isLoading={isSubmitting} containerClassName="mt-6" />}
                </form>
            </FormProvider>
        </Modal>
    );
};

export default memo(CreateOrUpdateStockPurchaseOrderPage);
