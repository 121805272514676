import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import ListPage from 'pages/shared/crud/list';
import { useNavigate, useParams } from 'react-router-dom';
import { getServiceSalesNotesConfig } from 'pages/private/sales-orders/utils';

const OrderServiceSalesNotesPage = ({ page }: WithPagePermissionsProps) => {
    const navigate = useNavigate();
    const { orderServiceId, saleOrderId } = useParams();

    const listConfig = getServiceSalesNotesConfig(navigate, Number(orderServiceId), Number(saleOrderId));

    return <ListPage hideCreateButton={true} page={page} {...listConfig} />;
};

export default OrderServiceSalesNotesPage;
