import classNames from 'classnames';
import ExternalButton from 'components/buttons/external';
import { DatatableColumn } from 'types/graphql';
import { formatMoney, formatNumber } from 'utils/money';

export const serviceOrderExpensesListColumns: DatatableColumn[] = [
    {
        name: 'id',
        options: { display: 'excluded' },
    },
    {
        name: 'expense.id',
        options: { display: 'excluded' },
    },
    {
        name: 'expense.code',
        label: 'Código Despesa',
        options: { customBodyRender: (code, { rowData }) => (!!code ? <ExternalButton to={`/app/despesas/${rowData[1]}`}>{code}</ExternalButton> : '-') },
    },
    {
        name: 'code',
        label: 'Código Ordem Pagamento',
    },
    {
        name: 'description',
        label: 'Descrição',
    },
    {
        name: 'totalDeductions',
        label: 'Deduções (quantidade)',
        options: { customBodyRender: formatNumber },
    },
    {
        name: 'paid',
        label: 'Pago',
        options: {
            customBodyRender: (value: boolean) => <span className={classNames('font-medium', !!value ? 'text-system-success-500' : 'text-system-danger-500')}>{!!value ? 'Sim' : 'Não'}</span>,
        },
    },
    {
        name: 'percentageByOrderService',
        label: 'Percentual Referente a OS',
        options: { customBodyRender: (value) => `${value}%` },
    },
    {
        name: 'valueByOrderService',
        label: 'Valor Referente a OS',
        options: { customBodyRender: (value) => formatMoney(value) },
    },
];
