import { useQuery } from 'react-query';
import api from 'services/api';
import { getOrderServicesPurchaseScopeTotal } from './keys';

const useGetOrderServicesPurchaseScopeTotal = (endpoint: string) => {
    const request = async () => {
        const { data } = await api.get<Response[]>(endpoint);

        if (!data[0]) {
            throw new Error();
        }

        const { totalBalance = 0, totalPurchase = 0 } = data[0];

        return {
            totalBalance: Number(totalBalance),
            totalPurchase: Number(totalPurchase),
            totalSpent: Number(totalPurchase) - Number(totalBalance),
        };
    };

    return useQuery(getOrderServicesPurchaseScopeTotal(endpoint), request, { enabled: true });
};

type Response = {
    totalBalance: number;
    totalPurchase: number;
};

export default useGetOrderServicesPurchaseScopeTotal;
