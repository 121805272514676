import { RefObject, useLayoutEffect, useState } from 'react';

type UseIsOverflowParams = {
    id: number;
    ref: RefObject<HTMLDivElement>;
    isVerticalOverflow?: boolean;
    callback?: (value: boolean) => void;
};

const useIsOverflow = ({ ref, isVerticalOverflow, callback, id }: UseIsOverflowParams) => {
    const [isOverflow, setIsOverflow] = useState(false);

    useLayoutEffect(() => {
        const { current } = ref;
        const { clientWidth = 0, scrollWidth = 0, clientHeight = 0, scrollHeight = 0 } = current || {};

        const trigger = () => {
            const hasOverflow = isVerticalOverflow ? scrollHeight > clientHeight : scrollWidth > clientWidth;

            setIsOverflow(hasOverflow);

            if (!!callback) {
                callback(hasOverflow);
            }
        };

        if (Boolean(current)) {
            trigger();
        }
    }, [callback, ref, isVerticalOverflow, id]);

    return isOverflow;
};

export default useIsOverflow;
