import Datatable from 'components/core/table/datatable';
import { useState } from 'react';
import useConfig from 'store/config/use-config';
import { generateReportValuePercentageData, reportDatatableOptions } from '../utils';
import { RESUME_REPORT_FOOTER_TOTALS } from './utils';
import ResumesReportTableFooter from './resumes-report-table-footer';
import ReportPageHeader from '../../components/report-page-header';
import { ReportConfigFilters, ResumesContentConfig } from 'types/models/reports';
import ContractsReportFilters from '../contracts/header/filters';
import { DataContentFiltersConfig } from '../types';
import { useReportsContext } from '../../context';
import { datatableColumnSort } from 'utils/datatable';

type ResumesReportTableProps = {
    title: string;
    withFooterClassesByValue?: boolean;
    contents: ResumesContentConfig[];
    configFilters: ReportConfigFilters;
    dataContentFiltersConfig: DataContentFiltersConfig;
};

const ResumesReportContent = ({ title, withFooterClassesByValue = false, contents, configFilters, dataContentFiltersConfig }: ResumesReportTableProps) => {
    const [page, setPage] = useState(0);
    const { config } = useConfig();
    const { showValues } = useReportsContext();

    return (
        <>
            <ReportPageHeader title={title} onOpenFilters={configFilters.onToggleFilters.bind(this, true)} />
            <div className="flex flex-col md:grid md:grid-cols-2 gap-4">
                {contents.map((content, index) => (
                    <Datatable
                        key={index}
                        title={content.title}
                        data={content.data}
                        columns={content.columns(showValues)}
                        options={{
                            ...reportDatatableOptions,
                            searchPlaceholder: content.searchPlaceholder,
                            searchOpen: false,
                            searchAlwaysOpen: false,
                            search: true,
                            page,
                            rowsPerPage: config.tables.rowsPerPage,
                            onChangePage: setPage,
                            customSort: datatableColumnSort,
                            customTableBodyFooterRender: ({ data: tableData }) => {
                                if (!tableData.length) {
                                    return null;
                                }

                                const newPage = config.tables.rowsPerPage >= content.data?.length ? 0 : page;

                                const startIndex = newPage * config.tables.rowsPerPage;
                                const endIndex = startIndex + config.tables.rowsPerPage;
                                const displayedData = tableData.slice(startIndex, endIndex);

                                const { columnValues1, columnValues2, columnValues3 } = displayedData.reduce((acc, { data }) => {
                                    const { value: columnValue1 = 0, percentage: columnPercentage1 = 0 } = data.at(1)?.props || {};
                                    const { value: columnValue2 = 0, percentage: columnPercentage2 = 0 } = data.at(3)?.props || {};
                                    const { value: columnValue3 = 0, percentage: columnPercentage3 = 0 } = data.at(5)?.props || {};

                                    return {
                                        columnValues1: generateReportValuePercentageData(acc.columnValues1.value + columnValue1, acc.columnValues1.percentage + columnPercentage1),
                                        columnValues2: generateReportValuePercentageData(acc.columnValues2.value + columnValue2, acc.columnValues2.percentage + columnPercentage2),
                                        columnValues3: generateReportValuePercentageData(acc.columnValues3.value + columnValue3, acc.columnValues3.percentage + columnPercentage3),
                                    };
                                }, RESUME_REPORT_FOOTER_TOTALS);

                                return (
                                    <ResumesReportTableFooter
                                        withFooterClassesByValue={withFooterClassesByValue}
                                        columnValues1={columnValues1}
                                        columnValues2={columnValues2}
                                        columnValues3={columnValues3}
                                    />
                                );
                            },
                        }}
                        loading={content.isLoading}
                        className="resumes-reports-tables"
                    />
                ))}
            </div>
            {configFilters.isFilterVisible && (
                <ContractsReportFilters
                    dataContentFiltersConfig={dataContentFiltersConfig}
                    onSubmit={configFilters.onSubmitFilters}
                    filters={configFilters.filters}
                    withConsultants={false}
                    onCloseFilters={configFilters.onToggleFilters.bind(this, false)}
                    onClear={configFilters.onClearFilters}
                />
            )}
        </>
    );
};

export default ResumesReportContent;
