import { User } from './user';
import { BaseModel } from './base';
import { File } from './file';
import { BankAccount } from './bank';
import { Address } from './address';
import { Classification } from './classification';

export enum MaritalStatus {
    Single = 1,
    Married,
    Divorced,
    Widowed,
}

export enum Gender {
    Female = 1,
    Male,
    Other,
}

export type Credentials = {
    user: User;
};

export type Phone = {
    ddd: string;
    number: string;
};

export enum EmailType {
    Personal = 1,
    Professional,
}

export type Email = {
    type: EmailType;
    email: string;
};

export enum PersonType {
    Internal = 1,
    External,
}

export type Person = {
    name: string;
    title: string;
    profileImage: File | null;
    document: string;
    birthday: string;
    gender: Gender;
    maritalStatus?: MaritalStatus;
    phones: Phone[];
    account?: Partial<any>;
    address?: any;
    contracts?: Partial<PersonContract>[];
    personContracts?: Partial<PersonContract>[];
    projects?: any[];
    user: User;
    file?: File;
    email?: string;
    manager?: boolean;
    expeditionDate?: string;
    emails?: Email[];
    type?: PersonType;
} & BaseModel;

export enum PersonContractType {
    Personal = 1,
    Juridic,
}

export type PersonContract = {
    contractStatusId: PersonContractStatusTitle;
    personId: number;
    positionId: number;
    sectorId: number;
    startDate: string;
    finalDate: string;
    type: PersonContractType;
    salary: number;
    juridicName: string;
    juridicFantasyName: string;
    juridicDocument: string;
    classification: Partial<Classification>;
    classificationBySectorId: Partial<Classification>;
    personContractStatus: Partial<PersonContractStatus>;
    person: Partial<Person>;
} & BaseModel;

export type PersonContractStatus = {
    title: string;
    isVisible: boolean;
    personContracts: Partial<PersonContract>[];
    person: Partial<Person>;
} & BaseModel;

export type CreatePersonContractPayload = {
    position: number;
    sector: number;
    salary: number;
    startDate: string;
    finalDate: string;
    juridicName: string;
    juridicFantasyName: string;
    juridicDocument: string;
    type: PersonContractType;
    consultant?: boolean;
};

export type CreatePersonPayload = {
    name: string;
    title: string;
    email: string;
    gender: Gender;
    maritalStatus: MaritalStatus;
    phones: Phone[];
    emails: Email[];
    document: string;
    birthday: string;
    address: Pick<Address, 'cityId' | 'stateId' | 'compliment' | 'neighbourhood' | 'number' | 'street' | 'zip'>;
    contracts: CreatePersonContractPayload;
    user?: {
        username?: string;
        profile?: number;
    };
    isUser?: boolean;
};

export type PersonBankAccount = {
    id: number;
    personId: number;
    bankAccount: Partial<BankAccount>;
    person: Partial<Person>;
};

export enum PersonContractStatusTitle {
    Active = 1,
    MaternityLeave = 2,
    SicknessAllowance = 3,
    Resignation = 4,
    Finished = 5,
    Transferred = 6,
    Vacation = 7,
    MedicalCertificate = 8,
}
