import { useQuery } from 'react-query';
import api from 'services/api';
import { getOrderServicesKey } from './keys';
import { useShowApiErrors } from 'hooks/error/show-api-errors';

export type OrderServicesResponse = {
    code: string;
    id: number;
    title: string;
    value: number;
    totalDeducted: number;
    totalToBeDeducted: number;
};

const useGetOrderServices = (filters: string) => {
    const { showToastErrors } = useShowApiErrors();

    const request = async () => {
        const { data } = await api.get<OrderServicesResponse[]>(`/order-services${filters}`);

        return data;
    };

    return useQuery(getOrderServicesKey(filters), request, {
        enabled: Boolean(filters),
        onError: (error: any) => {
            const fallbackMessage = 'Ocorreu um erro ao obter os dados das ordens de serviços, por favor, tente novamente.';
            showToastErrors(error, error?.response?.data?.title || fallbackMessage);
        },
    });
};

export default useGetOrderServices;
