import SvgIcoLightning from '../icon/files/ico-lightning';
import Text from '../text';
import useUpdate from 'store/update/use-update';

const AlertUpdate = () => {
    const { update } = useUpdate();

    const handleUpdate = () => {
        if (!!update.serviceWorkerRegistration?.waiting) {
            window.location.reload();
        }
    };

    if (!update.isServiceWorkerUpdated) {
        return null;
    }

    return (
        <div className="bg-[#f7f8fa] border-b-4 border-b-[#00b36c] rounded-md fixed py-4 pr-6 pl-14 top-3 right-3 transition-all ease-in duration-200 z-[99999] animate-bounceInRight shadow-[rgba(9,30,66,0.31)_0px_0px_1px,rgba(9,30,66,0.25)_0px_20px_32px_-8px]">
            <SvgIcoLightning width={24} height={24} className="absolute left-[18px] top-1/2" style={icoStyle} />
            <Text className="text-base-500 text-[15px]">Uma nova versão do Topfield SGI está disponível.</Text>
            <button className="block text-sm mt-1 hover:underline text-[#00b36c]" onClick={handleUpdate}>
                Clique para atualizar
            </button>
        </div>
    );
};

export default AlertUpdate;

const icoStyle = { transform: 'translateY(-50%)' };
