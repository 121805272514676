import ListPage from 'pages/shared/crud/list';
import CreateOrUpdatePagesPage from 'pages/private/pages/create-or-update';
import { RouteObject } from 'react-router-dom';
import { Rule } from 'types/permissions';
import Page from 'pages/shared/page';
import config from './utils';

const [list] = config;

const pages: RouteObject[] = [
    {
        // @ts-ignore
        breadcrumb: 'Páginas',
        path: 'paginas',
        element: <Page Component={ListPage} code="paginas" permissions={[Rule.List]} {...list} />,
        children: [
            {
                path: 'novo',
                element: <Page Component={CreateOrUpdatePagesPage} code="paginas" permissions={[Rule.Create]} withModal={true} />,
            },
            {
                path: 'editar/:pageId',
                element: <Page Component={CreateOrUpdatePagesPage} code="paginas" permissions={[Rule.Update]} withModal={true} />,
            },
        ],
    },
];

export default pages;
