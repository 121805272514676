import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import Text from 'components/core/text';
import Modal from 'components/core/modal';
import Spinner from 'components/core/spinner';
import theme from 'settings/theme';
import { useShowApiErrors } from 'hooks/error/show-api-errors';
import { BankAccount } from 'types/models/bank';
import FormButtons from 'components/form-buttons';
import BankAccountForm from 'components/forms/bank-account';
import { banckAccountSchema } from 'pages/private/bank-accounts/create-or-update/utils';

type PeopleProps = {
    isBuilding: boolean;
    isSubmitting: boolean;
    title: string;
    onSubmit: (data: any) => void;
    defaultValues?: Partial<CreateOrUpdatePersonBankAccountPayload>;
    error?: any;
};

export type CreateOrUpdatePersonBankAccountPayload = {
    bank: number;
} & Pick<BankAccount, 'agency' | 'agencyDigit' | 'account' | 'accountDigit' | 'pix' | 'pixType'>;

const CreateOrUpdatePersonBankAccount = ({ isBuilding, isSubmitting, title, onSubmit, defaultValues, error }: PeopleProps) => {
    const methods = useForm<CreateOrUpdatePersonBankAccountPayload>({
        mode: 'onSubmit',
        resolver: yupResolver(banckAccountSchema) as any,
        defaultValues,
    });

    const { handleSubmit, reset, setError } = methods;

    const { showFormErrors } = useShowApiErrors();

    useEffect(() => {
        if (!!error) {
            showFormErrors({ error, setError });
        }
    }, [showFormErrors, error, setError]);

    useEffect(() => {
        if (!!defaultValues) {
            reset(defaultValues);
        }
    }, [defaultValues, reset]);

    return (
        <Modal
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    {title}
                </Text>
            }
            closeOnClickOutside={false}
            contentClassnames="w-[800px]">
            {isBuilding ? (
                <div className="p-4">
                    <Spinner color={theme.extend.colors.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <FormProvider {...methods}>
                    <form className="pb-6 px-6" onSubmit={handleSubmit(onSubmit)}>
                        <BankAccountForm withAccountType={false} className="mb-6" />
                        <FormButtons isLoading={isSubmitting} />
                    </form>
                </FormProvider>
            )}
        </Modal>
    );
};

export default CreateOrUpdatePersonBankAccount;
