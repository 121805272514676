import * as React from 'react';
const SvgIcoArrowUp = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 10 16" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M.216 5.17a.83.83 0 0 0 1.173.055L3.83 3V15.17a.83.83 0 1 0 1.66 0V3l2.442 2.224a.83.83 0 1 0 1.118-1.228L4.661 0 .271 3.997A.83.83 0 0 0 .216 5.17Z"
            clipRule="evenodd"
        />
    </svg>
);
export default SvgIcoArrowUp;
