import Avatar from '@mui/material/Avatar/Avatar';
import useTheme from '@mui/material/styles/useTheme';
import Card from 'components/core/card';
import { formatMoney } from 'utils/money';
import { hexToRgba } from 'utils/string';
import classNames from 'classnames';
import SvgIcoDollar from 'components/core/icon/files/ico-dollar';

type DetailedCardProps = {
    title: string;
    value: number;
    percentage?: number;
    hasPercentage?: boolean;
    className?: string;
};

const DetailedCard = ({ title, value, percentage, hasPercentage = true, className }: DetailedCardProps) => {
    const { palette } = useTheme();

    const cardClassNames = classNames('p-4', className);

    return (
        <Card className={cardClassNames}>
            <div className="flex flex-col xl:flex-row items-center overflow-x-auto w-full max-w-full justify-center">
                <Avatar className="mb-4 xl:mr-5 xl:mb-0" sx={{ bgcolor: hexToRgba(palette.secondary[100], 0.2), borderRadius: '14px !important', width: 88, height: 88 }} aria-label="recipe">
                    <SvgIcoDollar color={palette.secondary[500]} width={48} height={48} />
                </Avatar>
                <div className="flex flex-col justify-center w-full text-center xl:text-left mb-2 xl:mb-0">
                    <p className="text-base-500 text-base font-normal">{title}</p>
                    <strong className="font-medium text-3xl text-heading mr-3 flex-1">{formatMoney(value || 0)}</strong>
                </div>
                {hasPercentage && (
                    <div className="flex flex-col items-center xl:items-end">
                        <p className="text-base text-system-success-500 flex items-center text-right">
                            <span>{formatMoney(percentage || 0).replace('R$', '')}%</span>
                        </p>
                    </div>
                )}
            </div>
        </Card>
    );
};

export default DetailedCard;
