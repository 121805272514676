import { PropsWithChildren } from 'react';
import WithheldCheckbox, { WithheldCheckboxProps } from '../withheld-checkbox';

const TaxWithheldWithPercentage = ({ name, children }: PropsWithChildren<WithheldCheckboxProps>) => {
    return (
        <div className="flex items-center gap-2 mx-4">
            {children}
            <WithheldCheckbox name={name} />
        </div>
    );
};

export default TaxWithheldWithPercentage;
