import { useQuery } from 'react-query';
import api from 'services/api';
import { OrderServiceWorkReport } from 'types/models/order-service';
import { getOrderServiceWorkReportKey } from './keys';

const useGetOrderServiceWorkReport = (orderServiceId?: string, saleOrderId?: string, workReportId?: number) => {
    const request = async () => {
        const endpoint = `/sale-orders/${saleOrderId}/service-orders/${orderServiceId}/galleries/${workReportId}`;
        const { data } = await api.get<OrderServiceWorkReport>(endpoint);

        return data;
    };

    return useQuery(getOrderServiceWorkReportKey(orderServiceId, saleOrderId, workReportId), request, {
        enabled: Boolean(orderServiceId) && Boolean(saleOrderId) && Boolean(workReportId),
    });
};

export default useGetOrderServiceWorkReport;
