import TableCell from '@mui/material/TableCell/TableCell';
import omit from 'lodash/omit';
import { CrudPageProps } from 'types/graphql';
import dictionary from 'utils/dictionary';
import { number, object, array } from 'yup';
import { StockBalanceResponse } from 'services/queries/stocks/use-get-stock-balance';
import { formatDate } from 'utils/date';

export const stockBalanceProductsFormatResponse = (data) => {
    return data.map((item) => ({
        ...omit(item, 'value', 'quantity'),
        currentValue: item.stockProducts?.[0]?.value || 0,
        currentQuantity: item.stockProducts?.[0]?.quantity || 0,
        label: `${item.code} ${item.name}`,
    }));
};

export const stockBalanceListConfig = (stockId?: string): CrudPageProps<{}> => ({
    graphql: {
        table: 'stockBalance',
        statusPath: 'status',
        columns: [
            {
                name: 'id',
                options: { display: 'excluded' },
            },
            {
                name: 'code',
                label: 'Código',
            },
            {
                name: 'createdAt',
                label: 'Data',
                options: {
                    customBodyRender: (value) => formatDate(value),
                },
            },
            {
                name: 'person.name',
                label: 'Pessoa',
            },
            {
                name: 'actions',
                label: 'Ações',
                options: {
                    customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />,
                },
            },
        ],
        orderBy: { code: 'ASC' },
        where: {
            stockId: { _eq: stockId },
        },
    },
});

export const stockBalanceSchema = object({
    products: array(
        object({
            value: number().required(dictionary.validation.required),
            quantity: number().required(dictionary.validation.required),
        })
    ),
});

export const generateDetailsCard = (data?: StockBalanceResponse) => [
    {
        label: 'Código',
        value: data?.code || '-',
    },
    {
        label: 'Data de cadastro',
        value: formatDate(data?.created),
    },
    {
        label: 'Pessoa',
        value: data?.person?.name || '-',
    },
];
