import dictionary from 'utils/dictionary';
import { array, number, object, string } from 'yup';

export const pagesReportSchema = object({
    name: string().typeError(dictionary.validation.required).required(dictionary.validation.required).trim(),
    type: number().typeError(dictionary.validation.required).required(dictionary.validation.required),
    blocks: array(
        object({
            name: string().typeError(dictionary.validation.required).required(dictionary.validation.required).trim(),
        })
    ).min(1, 'Adicione pelo menos 1 centro de custos'),
    years: array().min(1, 'Adicione pelo menos 1 ano').required(dictionary.validation.required),
});
