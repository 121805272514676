import { useQuery } from 'react-query';
import apiGraphQl from 'services/graphql';
import { Option, Status } from 'types/general';
import { getFavoredOptionsKey } from './keys';
import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';

type Response = {
    people: Option[];
    providers: Option[];
};

const MAX_REGISTERS = 6;

const generateQuery = (title: string, peopleWhere?: any, providersWhere?: any) => {
    const graphqlWhere = {
        title: { _ilike: `%${title}%` },
        status: { _eq: Status.Active },
    };

    return jsonToGraphQLQuery(
        {
            query: {
                __name: 'GetFavoredOptions',
                people: {
                    __aliasFor: 'person',
                    __args: {
                        where: {
                            ...graphqlWhere,
                            ...(Boolean(peopleWhere) && peopleWhere),
                        },
                        limit: MAX_REGISTERS,
                        orderBy: { title: new EnumType('ASC') },
                    },
                    value: { __aliasFor: 'id' },
                    label: { __aliasFor: 'title' },
                },
                providers: {
                    __aliasFor: 'provider',
                    __args: {
                        where: {
                            ...graphqlWhere,
                            ...(Boolean(providersWhere) && providersWhere),
                        },
                        limit: MAX_REGISTERS,
                        orderBy: { title: new EnumType('ASC') },
                    },
                    value: { __aliasFor: 'id' },
                    label: { __aliasFor: 'title' },
                },
            },
        },
        { pretty: true }
    );
};

const useGetFavoredOptions = (title?: string, peopleWhere?: any, providersWhere?: any) => {
    const request = async () => {
        const data = await apiGraphQl<Response>(generateQuery(title!, peopleWhere, providersWhere));

        if (!data?.people || !data?.providers) {
            throw new Error();
        }

        return data;
    };

    return useQuery(getFavoredOptionsKey(title), request, { enabled: !!title });
};

export default useGetFavoredOptions;
