import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';
import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import { getPaginatedWithFiltersKey } from '../general/keys';
import { GetPaginatedWithFiltersParams } from '../general/use-get-paginated-with-filters';
import useConfig from 'store/config/use-config';

const useCreateOrUpdateLoan = (listParams?: GetPaginatedWithFiltersParams) => {
    const { config } = useConfig();
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const goToParentRoute = useNavigateToParentRoute();

    const request = async (payload) => api.post('loans', payload);

    return useMutation(request, {
        onSuccess: () => {
            if (!!listParams) {
                queryClient.invalidateQueries(getPaginatedWithFiltersKey(listParams.endpoint, listParams.filters, listParams.page, config.tables.rowsPerPage, listParams.orderBy));
            }

            goToParentRoute();
        },
        onError: (error: any) => {
            showToast(error?.response?.data?.title || 'Ocorreu um erro ao cadastrar o empréstimo', 'danger');
        },
    });
};

export default useCreateOrUpdateLoan;
