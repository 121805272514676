import Spinner from 'components/core/spinner';
import { Outlet, useParams } from 'react-router-dom';
import useGetExpense from 'services/queries/expenses/use-get-expense';
import useBreadcrumb from 'store/breadcrumb/use-breadcrumb';
import Empty from 'components/empty';
import Tabs from '@mui/material/Tabs/Tabs';
import Tab from '@mui/material/Tab/Tab';
import { useMemo } from 'react';
import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import { UseTabRouteChildren, useTabRoute } from 'hooks/tab/use-tab-route';

const ExpenseDetails = ({ page }: WithPagePermissionsProps) => {
    const { expenseId } = useParams();

    const { data: expense, isLoading, isError } = useGetExpense(expenseId);

    const nav = useMemo(() => {
        return (
            page?.subpages
                ?.filter(({ link }) => !['aprovar', 'reprovar'].includes(link))
                ?.map<UseTabRouteChildren>((item) => ({
                    label: item.title,
                    path: item.link,
                })) || []
        );
    }, [page?.subpages]);

    const { handleChangeTab, activeTabIndex, tabLabels } = useTabRoute(nav);

    useBreadcrumb(expense?.code);

    if (isLoading) {
        return (
            <div className="relative w-full h-full">
                <Spinner />
            </div>
        );
    }

    if (isError || !expense) {
        return <Empty title="Despesa não encontrada" />;
    }

    return (
        <div className="bg-base-100 flex -ml-6 -mr-4 -my-6 h-[calc(100%+48px)] max-h-[calc(100%+48px)] overflow-hidden">
            <section className="flex-1 bg-base-200 overflow-y-auto relative w-full h-full">
                <div className="bg-base-100 mb-6 pl-6 pr-4">
                    <Tabs variant="scrollable" indicatorColor="secondary" value={activeTabIndex} onChange={handleChangeTab}>
                        {tabLabels.map((label) => (
                            <Tab label={label} key={label} className="text-base py-5" />
                        ))}
                    </Tabs>
                </div>
                <div className="px-6">
                    <Outlet context={expense} />
                </div>
            </section>
        </div>
    );
};

export default ExpenseDetails;
