import { RouteObject } from 'react-router-dom';
import { Rule } from 'types/permissions';
import Page from 'pages/shared/page';
import PendingOrderPaymentPage from 'pages/private/pending-order-payment';
import PendingMoneyOrdersPage from 'pages/private/pending-money-orders';
import CreatePendingMoneyDeductionPage from 'pages/private/pending-money-orders/create-or-update/create-pending-money-deduction';

const financials: RouteObject[] = [
    {
        path: 'ordens-de-pagamentos-pendentes',
        element: <Page Component={PendingOrderPaymentPage} code="ordens-pagamento-pendentes" title="Ordens de pagamento pendentes" permissions={[Rule.List]} />,
    },
    {
        path: 'enviar-notas-fiscais',
        element: <Page Component={PendingMoneyOrdersPage} code="ordens-pagamento-pendentes-nf" title="Ordens de pagamento pendentes NF" permissions={[Rule.List]} />,
        children: [
            {
                path: ':transactionId',
                element: <Page Component={CreatePendingMoneyDeductionPage} code="ordens-pagamento-pendentes-nf" withModal={true} permissions={[Rule.Show]} />,
            },
        ],
    },
];

export default financials;
