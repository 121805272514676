import useCreateOrUpdateSaleOrderSchedule from 'services/queries/sales-order/use-create-or-update-sale-order-schedule';
import CreateOrUpdateSaleOrderSchedule from './create-or-update';
import { useParams } from 'react-router-dom';
import { SaleOrderSchedulePayload } from 'types/models/sale-order';

const CreateOrUpdateSaleOrdersSchedulesPage = () => {
    const { saleOrderId } = useParams();

    const { mutateAsync: createOrUpdateSaleOrderSchedule, isLoading: isSubmitting } = useCreateOrUpdateSaleOrderSchedule(saleOrderId);
    const handleSubmit = async (data: SaleOrderSchedulePayload) => {
        try {
            const payload = {
                day: data?.day,
            };

            await createOrUpdateSaleOrderSchedule(payload);
        } catch (e) {
            console.error('Ocorreu um erro ao cadastrar o agendamento.');
        }
    };

    return <CreateOrUpdateSaleOrderSchedule isSubmitting={isSubmitting} onSubmit={handleSubmit} />;
};

export default CreateOrUpdateSaleOrdersSchedulesPage;
