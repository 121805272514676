import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import Card from 'components/core/card';
import { useMemo } from 'react';
import _map from 'lodash/map';
import { Doughnut } from 'react-chartjs-2';
import { getReportPageBlockColor } from '../../utils';
import { ReportPageBlock } from 'types/models/reports';
import { getNumberValueOrZero } from 'pages/private/expenses/details/tabs/transactions/utils';

ChartJS.register(ArcElement, Tooltip);

type ReportPageDetailsChartDoughnutProps = {
    blocks: ReportPageBlock[];
    currentYearTotal: number;
};

const ReportPageDetailsChartDoughnut = ({ blocks = [], currentYearTotal }: ReportPageDetailsChartDoughnutProps) => {
    const values = useMemo(() => {
        return blocks
            .map(({ resume, name, ...block }, index) => {
                const color = block?.color ?? getReportPageBlockColor(index);

                return {
                    percentage: getNumberValueOrZero((100 * resume?.total) / currentYearTotal),
                    name,
                    color,
                };
            })
            .sort((a, b) => b.percentage - a.percentage);
    }, [blocks, currentYearTotal]);

    const data = useMemo(() => {
        return {
            labels: _map(values, 'name'),
            datasets: [
                {
                    data: _map(values, 'percentage'),
                    backgroundColor: _map(values, 'color'),
                    borderWidth: 0,
                    borderRadius: 30,
                    spacing: 4,
                    rotation: 234,
                },
            ],
        };
    }, [values]);

    return (
        <Card className="p-5">
            <div id="doughnut-chart-card">
                <div className="h-[280px] mb-3 flex justify-center">
                    <Doughnut
                        data={data}
                        options={{
                            cutout: '75%',
                            plugins: {
                                legend: { display: false },
                                tooltip: { enabled: true },
                            },
                        }}
                    />
                </div>
                <div className="flex flex-col gap-1">
                    {values?.map(({ name, percentage = 0, color }, index) => {
                        const bgColor = color || getReportPageBlockColor(index);

                        return (
                            <div key={`legend_${index}`} className="flex items-center gap-1">
                                <div className="w-[20px] h-[20px] rounded-lg" style={{ backgroundColor: bgColor }} />
                                <span className="text-sm text-base-500 flex-1">
                                    {percentage.toFixed(2)}% &bull; {name}
                                </span>
                            </div>
                        );
                    })}
                </div>
            </div>
        </Card>
    );
};

export default ReportPageDetailsChartDoughnut;
