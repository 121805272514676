import Card from 'components/core/card';
import Empty from 'components/empty';
import { useOutletContext } from 'react-router-dom';
import { ExpenseDetailsResponse } from 'services/queries/expenses/types';
import ExpenseProductsTable from './products-table';

const ExpenseProducts = () => {
    const data = useOutletContext<ExpenseDetailsResponse>();

    const { products = [], transferBetweenStocks } = data;

    if (!products?.length) {
        return <Empty title="Nenhum produto selecionado." />;
    }

    const decimalPlaces = transferBetweenStocks ? 4 : 2;

    return (
        <Card>
            <ExpenseProductsTable products={products} decimalPlaces={decimalPlaces} />
        </Card>
    );
};

export default ExpenseProducts;
