import { useFieldArray, useFormContext } from 'react-hook-form';
import Empty from 'components/empty';
import Text from 'components/core/text';
import AddButton from 'components/buttons/add';
import ErrorMessage from 'components/error/message';
import RemoveButton from 'components/buttons/remove';
import ContactsFields from './fields';
import classNames from 'classnames';

type ContactsProps = {
    fieldName?: string;
    className?: string;
};

const DEFAULT_CONTACT = {
    name: '',
    title: '',
};

const Contacts = ({ fieldName = 'contacts', className }: ContactsProps) => {
    const { control, formState } = useFormContext();
    const { fields, append, remove } = useFieldArray({ name: fieldName, control });

    const handleAdd = () => append(DEFAULT_CONTACT);

    const handleDelete = (index: number) => () => remove(index);

    const containerClasses = classNames('border border-base-300 rounded-[14px] p-4', className);

    return (
        <div className={containerClasses}>
            <Text as="h5" variant="h6" className="text-heading mb-3">
                Contatos
            </Text>
            {Boolean(fields.length) ? (
                fields.map((item, index) => {
                    return (
                        <div key={item.id} className="border border-base-300 rounded-[14px] p-4 mb-4 group relative">
                            <RemoveButton onClick={handleDelete(index)} />
                            <ContactsFields baseName={fieldName} index={index} />
                        </div>
                    );
                })
            ) : (
                <Empty title="Nenhum contato adicionado ainda" className="mb-2" />
            )}
            <AddButton onClick={handleAdd}>contato</AddButton>
            <ErrorMessage visible={Boolean(formState.errors?.[fieldName]?.message)} className="block mt-4">
                {(formState as any).errors?.[fieldName]?.message}
            </ErrorMessage>
        </div>
    );
};

export default Contacts;
