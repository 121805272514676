import dictionary from 'utils/dictionary';
import { TestContext, array, number, object, string } from 'yup';
import { optionSchema } from 'utils/schema';

export const totalConsultantsPercentageBiggerThanCommission = (_, context?: TestContext<any>) => {
    const maximumPercentage = context?.parent?.commission || 0;

    const consultantPercentage = context?.parent?.consultant?.percentage || 0;
    const managerPercentage = context?.parent?.manager?.percentage || 0;

    const totalPercentage = consultantPercentage + managerPercentage;

    return totalPercentage <= maximumPercentage;
};

export const commissionSchema = (hasManager = false) => {
    return object({
        provision: string().required(dictionary.validation.required),
        sector: optionSchema.required(dictionary.validation.required),
        category: optionSchema.required(dictionary.validation.required),
        fiscalCode: optionSchema.required(dictionary.validation.required),
        formFavored: object({
            person: optionSchema,
            favoredBankAccount: object({
                value: number().optional(),
                label: string().optional(),
            }),
        }),
        paymentForm: optionSchema,
        company: number().required(dictionary.validation.required),
        branch: number().required(dictionary.validation.required),
        commissions: array(
            object({
                commission: number(),
                consultant: object({
                    percentage: number(),
                }).test('totalConsultantsBiggerThanCommission', 'Porcentagem maior que a comissão', totalConsultantsPercentageBiggerThanCommission),
                ...(hasManager && {
                    manager: object({
                        percentage: number(),
                    }).test('totalConsultantsBiggerThanCommission', 'Porcentagem maior que a comissão', totalConsultantsPercentageBiggerThanCommission),
                }),
            })
        ),
        saleOrderType: number().required(dictionary.validation.required),
    });
};
