import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Text from 'components/core/text';
import ProductItem from './item';
import { Option } from 'types/general';
import { PurchaseOrderPayload } from 'types/models/purchase-order';

type OrderServicePurchaseOrderTableProps = {
    selectedProducts: PurchaseOrderPayload['products'];
    withSaleProducts?: boolean;
    saleProductsOptions?: Option[];
    withBalance?: boolean;
    onRemoveProduct: (index: number) => void;
};

const OrderServicePurchaseOrderTable = ({ selectedProducts = [], withSaleProducts = true, saleProductsOptions = [], withBalance = true, onRemoveProduct }: OrderServicePurchaseOrderTableProps) => {
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className="pl-0">
                            <Text className="text-base-500 uppercase">Produto</Text>
                        </TableCell>
                        {withSaleProducts && (
                            <TableCell className="pl-0">
                                <Text className="text-base-500 uppercase">Escopo de venda</Text>
                            </TableCell>
                        )}
                        {withBalance && (
                            <TableCell className="pl-0">
                                <Text className="text-base-500 uppercase">Saldo</Text>
                            </TableCell>
                        )}
                        <TableCell className="pl-0">
                            <Text className="text-base-500 uppercase">Quantidade</Text>
                        </TableCell>
                        <TableCell className="pl-0 whitespace-nowrap">
                            <Text className="text-base-500 uppercase">Pré-aprovado</Text>
                        </TableCell>
                        <TableCell className="pl-0">
                            <Text className="text-base-500 uppercase">Provisão</Text>
                        </TableCell>
                        <TableCell className="pl-0">
                            <Text className="text-base-500 uppercase">Faturada</Text>
                        </TableCell>
                        <TableCell className="pl-0">
                            <Text className="text-base-500 uppercase">Valor unitário</Text>
                        </TableCell>
                        <TableCell className="pl-0">
                            <Text className="text-base-500 uppercase">Justificativa</Text>
                        </TableCell>
                        <TableCell className="pl-0" />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {selectedProducts.map((item, index) => {
                        return (
                            <ProductItem
                                withBalance={withBalance}
                                withSaleProducts={withSaleProducts}
                                saleProducts={saleProductsOptions}
                                item={item}
                                index={index}
                                key={item.id}
                                onRemove={onRemoveProduct}
                            />
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default OrderServicePurchaseOrderTable;
