import classNames from 'classnames';
import SvgIcoDollar from 'components/core/icon/files/ico-dollar';
import Text from 'components/core/text';
import React from 'react';
import { Option } from 'types/general';
import { formatMoney } from 'utils/money';

type CardsDetailsProps = {
    className?: string;
    withIcon?: boolean;
    variant?: 'list' | 'details';
    valueClassName?: string;
    content?: React.ReactElement;
    iconClassName?: string;
} & Partial<Option>;

const CardsDetails = ({ variant = 'details', label, value, className, withIcon = false, valueClassName, content, iconClassName }: CardsDetailsProps) => {
    const containerClasses = classNames(
        'py-4 px-6 flex items-center justify-between flex-wrap',
        variant === 'details' ? 'border-r border-r-base-300 border-b border-b-base-300 last:border-r-0' : 'bg-base-100 rounded-[14px]',
        className
    );

    const valueClasses = classNames('font-medium text-xl text-heading flex-1 mb-3', valueClassName);

    return (
        <div className={containerClasses}>
            <div>
                {content || (
                    <>
                        <Text as="p" className="text-base-500 font-normal text-sm mb-2">
                            {label}
                        </Text>
                        <Text as="strong" className={valueClasses}>
                            {formatMoney(value)}
                        </Text>
                    </>
                )}
            </div>
            {withIcon && (
                <div className={classNames('flex justify-center items-center w-[45px] h-[45px] rounded-xl bg-secondary-100 text-secondary-500 !bg-opacity-40', iconClassName)}>
                    <SvgIcoDollar width={25} height={25} />
                </div>
            )}
        </div>
    );
};

export default CardsDetails;
