import { useQuery } from 'react-query';
import api from 'services/api';
import useToast from 'hooks/toast/use-toast';
import qs from 'qs';
import { getOrderServicesReceiptsKey } from './keys';
import { Pagination } from 'types/pagination';
import { Transaction } from 'types/models/transaction';

type Params = {
    endpoint: string;
    filters: any;
};

type TransactionFields = 'paymentForm' | 'code' | 'order' | 'description' | 'paid' | 'dueDate' | 'netValue';

type Response = Pick<Transaction, TransactionFields>;

const useGetOrderServicesReceipts = ({ endpoint, filters }: Params) => {
    const { showToast } = useToast();

    const request = async () => {
        const query = qs.stringify(filters, { addQueryPrefix: true, encode: false, arrayFormat: 'brackets' });

        const { data } = await api.get<Pagination<Response>>(endpoint + query);

        return data;
    };

    return useQuery(getOrderServicesReceiptsKey(endpoint, filters), request, {
        enabled: !!endpoint,
        onError: () => {
            showToast(`Ocorreu um erro ao obter a listagem. Tente novamente.`, 'danger');
        },
    });
};

export default useGetOrderServicesReceipts;
