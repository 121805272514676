import { Status } from 'types/general';

export const getAllPagesKey = ['pages'];
export const getPageKey = (pageId?: string) => ['pages', pageId];

const queries = {
    getAll: `query GetAllPages {
        items: page(where: {status: {_eq: ${Status.Active}}}, orderBy: {name: ASC}) {
            id
            name
            rules: pageRules {
                rule { id name }
            }
        }
    }`,
};

export default queries;
