import Button from 'components/core/button';
import Menu, { MenuItem } from 'components/menu';
import { useMemo, useState } from 'react';
import useTheme from '@mui/material/styles/useTheme';
import { CreateProviderResponse } from 'services/queries/providers/use-create-provider';
import CreateOrUpdateProviderPage from 'pages/private/providers/create-or-update';
import { useQueryClient } from 'react-query';
import { getFavoredOptionsKey } from 'services/queries/expenses/keys';
import CreateOrUpdatePersonPage from 'pages/private/people/create-or-update';
import { Person } from 'types/models/person';
import { FormFavoredType } from 'types/models/favored';
import { Option } from 'types/general';
import SvgIcoChevronDown from 'components/core/icon/files/ico-chevron-down';

type FavoredSelectActionsProps = {
    onSelect: (value: any) => void;
    value?: Option & { type?: FormFavoredType };
    onOpenProviderConsultingModal?: () => void;
};

const FavoredSelectActions = ({ value, onSelect, onOpenProviderConsultingModal }: FavoredSelectActionsProps) => {
    const { palette } = useTheme();
    const queryClient = useQueryClient();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [createType, setCreateType] = useState<FormFavoredType>();

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const handleToggleModal = (value?: FormFavoredType) => () => setCreateType(value);

    const content = useMemo(() => {
        const handleOpenOption = (option: FormFavoredType) => () => {
            handleToggleModal(option)();
            handleClose();
        };

        const handleOpenProviderConsultingModal = () => {
            onOpenProviderConsultingModal?.();
            handleClose();
        };

        const contentArr: MenuItem[] = [
            {
                label: 'Novo funcionário',
                icon: {
                    color: palette.grey[500],
                    name: 'ico-add-user',
                },
                onClick: handleOpenOption('person'),
            },
            {
                label: 'Novo fornecedor',
                icon: {
                    color: palette.grey[500],
                    name: 'ico-add-user',
                },
                onClick: handleOpenOption('provider'),
            },
        ];

        if (value?.type === 'provider' && !!onOpenProviderConsultingModal) {
            contentArr.push({
                label: 'Consultar CNPJ',
                icon: {
                    color: palette.grey[500],
                    name: 'ico-show',
                },
                onClick: handleOpenProviderConsultingModal,
            });
        }

        return contentArr;
    }, [value, palette.grey, onOpenProviderConsultingModal]);

    const handleSuccessCreateProvider = (data: CreateProviderResponse['item']) => {
        const payload = {
            label: data.title,
            type: 'provider',
            value: data.id,
        };

        handleToggleModal()();
        queryClient.invalidateQueries(getFavoredOptionsKey(''));

        onSelect(payload);
    };

    const handleSuccessCreatePerson = (data: Person) => {
        const payload = {
            label: data.title,
            type: 'provider',
            value: data.id,
        };

        handleToggleModal()();
        queryClient.invalidateQueries(getFavoredOptionsKey(''));

        onSelect(payload);
    };

    return (
        <>
            <Button className="h-[48px] mt-[26px] px-3 rounded-l-none" type="button" variant="contained" color="primary" onClick={handleOpen} endIcon={<SvgIcoChevronDown width={10} color="#fff" />}>
                <span className="text-xs">Ações</span>
            </Button>
            <Menu className="z-[99999]" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} content={content} />
            {createType === 'provider' && <CreateOrUpdateProviderPage title="Novo fornecedor" onClose={handleToggleModal()} onSuccess={handleSuccessCreateProvider} />}
            {createType === 'person' && <CreateOrUpdatePersonPage title="Nova pessoa" onClose={handleToggleModal()} onSuccess={handleSuccessCreatePerson} />}
        </>
    );
};

export default FavoredSelectActions;
