import ListPage from 'pages/shared/crud/list';
import { Navigate, RouteObject } from 'react-router-dom';
import { Rule } from 'types/permissions';
import Page from 'pages/shared/page';
import config from './utils';
import StockPage from 'pages/private/stocks/view';
import ListStockPurchaseOrderPage from 'pages/private/stocks/list';
import CreateOrUpdateStockPurchaseOrderPage from 'pages/private/stocks/create-or-update-purchase-order';
import StockPurchaseOrderPage from 'pages/private/stocks/purchase-order';
import PurchaseOrderExpensesCreateOrUpdatePage from 'pages/private/purchase-orders/expenses';
import StockProductsPage from 'pages/private/stocks/view/products';
import StockProductPage from 'pages/private/stocks/view/products/product';
import StockDeliveryPage from 'pages/private/stocks/stock-delivery';
import StockRemittancePage from 'pages/private/stocks/view/remittance';
import { ExpenseNature } from 'types/models/expense';
import CreateOrUpdatePage from 'pages/shared/crud/create-or-update';
import PurchaseOrderList from '../purchases-orders/list';
import BalanceDetailsPage from 'pages/private/stocks/view/balance/details';
import StockBalancePage from 'pages/private/stocks/view/balance';
import CreateOrUpdateStockBalancePage from 'pages/private/stocks/view/balance/create-or-update';
import IndustrializationPage from 'pages/private/stocks/view/industrialization';
import ImportFlowPage from 'pages/private/import-flow';
import CreateOrUpdateImportFlowPage from 'pages/private/import-flow/create-or-update';
import ImportFlowDetailsPage from 'pages/private/import-flow/details';
import ImportFlowNationalizePage from 'pages/private/import-flow/details/actions/nationalize';
import StockProductsRefundPage from 'pages/private/stocks/view/refund';
import ImportFlowNationalizationsTabPage from 'pages/private/import-flow/details/tabs/nationalizations';
import ImportFlowExpensesTabPage from 'pages/private/import-flow/details/tabs/expenses';
import ImportFlowDetailsTabPage from 'pages/private/import-flow/details/tabs/details';
import ImportFlowProductNationalizationPage from 'pages/private/import-flow/details/product-nationalization';

const [stocksListConfig, stocksUpdateConfig] = config;

const stocks: RouteObject[] = [
    {
        // @ts-ignore
        breadcrumb: 'Estoques',
        path: 'estoques',
        element: <Page Component={ListPage} code="estoques" permissions={[Rule.List]} {...stocksListConfig} />,
        children: [
            {
                path: 'editar/:id',
                element: <Page Component={CreateOrUpdatePage} code="estoques" permissions={[Rule.Update]} withModal={true} {...stocksUpdateConfig} />,
            },
        ],
    },
    {
        path: 'estoques/:stockId',
        element: <Page Component={StockPage} code="estoques" permissions={[Rule.Show]} withModal={false} />,
        children: [
            {
                index: true,
                element: <Navigate to="produtos" replace={true} />,
            },
            {
                path: 'ordens-de-compra',
                element: <Page Component={ListStockPurchaseOrderPage} code="estoques-ordens-de-compra" permissions={[Rule.Show]} withModal={false} />,
            },
            {
                path: 'ordens-de-compra/:purchaseOrderId',
                element: <Page Component={StockPurchaseOrderPage} code="ordens-de-compra-de-estoque" permissions={[Rule.List]} source="stock" />,
                children: [
                    {
                        path: 'despesas/novo/compra',
                        element: (
                            <Page
                                Component={PurchaseOrderExpensesCreateOrUpdatePage}
                                code="ordens-de-compra-de-estoque"
                                nature={ExpenseNature.StockPurchaseOrder}
                                permissions={[Rule.Create]}
                                withModal={true}
                            />
                        ),
                    },
                    {
                        path: 'entrega-do-estoque',
                        element: <Page Component={StockDeliveryPage} code="ordens-de-compra-de-estoque" permissions={[Rule.Create]} withModal={true} />,
                    },
                    {
                        path: 'entrega-direta/despesas/novo/compra',
                        element: <Page Component={PurchaseOrderExpensesCreateOrUpdatePage} nature={ExpenseNature.PurchaseOrderBilled} code="despesas" permissions={[Rule.Create]} withModal={true} />,
                    },
                ],
            },
            {
                path: 'remessas',
                element: <Page Component={StockRemittancePage} code="estoques-remessas" permissions={[Rule.Show]} withModal={false} />,
            },
            {
                path: 'produtos',
                element: <Page Component={StockProductsPage} code="estoques-produtos" permissions={[Rule.Show]} withModal={false} />,
                children: [
                    {
                        path: ':stockProductId',
                        element: <Page Component={StockProductPage} code="estoques-produtos" permissions={[Rule.Show]} withModal={true} />,
                    },
                    {
                        path: 'industrializacao',
                        element: <Page Component={IndustrializationPage} code="estoques-produtos" permissions={[Rule.Show]} withModal={true} />,
                    },
                    {
                        path: 'estorno-estoque-principal',
                        element: <Page Component={StockProductsRefundPage} code="estoques-produtos-estorno" permissions={[Rule.Create]} withModal={true} />,
                    },
                ],
            },
            {
                // @ts-ignore
                breadcrumb: 'Balanço',
                path: 'balanco',
                element: <Page Component={StockBalancePage} code="estoques-balanco" permissions={[Rule.List]} />,
                children: [
                    {
                        path: 'novo',
                        element: <Page Component={CreateOrUpdateStockBalancePage} code="estoques-balanco" permissions={[Rule.Create]} withModal={true} />,
                    },
                    {
                        path: ':balanceId',
                        element: <Page Component={BalanceDetailsPage} code="estoques-balanco" permissions={[Rule.Show]} withModal={true} />,
                    },
                ],
            },
        ],
    },
    {
        path: 'ordens-de-compra-de-estoque',
        element: <Page Component={ListStockPurchaseOrderPage} code="ordens-de-compra-de-estoque" permissions={[Rule.List]} />,
        children: [
            {
                path: 'novo',
                element: (
                    <Page Component={CreateOrUpdateStockPurchaseOrderPage} code="ordens-de-compra-de-estoque" title="Nova ordem de compra de estoque" permissions={[Rule.Create]} withModal={true} />
                ),
            },
        ],
    },
    {
        path: 'ordens-de-compra-de-estoque/:purchaseOrderId',
        element: <Page Component={StockPurchaseOrderPage} code="ordens-de-compra-de-estoque" title="Atualizar documento" permissions={[Rule.List, Rule.Show]} />,
        children: [
            {
                path: 'despesas/novo/compra',
                element: <Page Component={PurchaseOrderExpensesCreateOrUpdatePage} nature={ExpenseNature.StockPurchaseOrder} code="despesas" permissions={[Rule.Create]} withModal={true} />,
            },
            {
                path: 'entrega-do-estoque',
                element: <Page Component={StockDeliveryPage} code="ordens-de-compra-de-estoque" permissions={[Rule.Create]} withModal={true} />,
            },
            {
                path: 'entrega-direta/despesas/novo/compra',
                element: <Page Component={PurchaseOrderExpensesCreateOrUpdatePage} nature={ExpenseNature.PurchaseOrderBilled} code="despesas" permissions={[Rule.Create]} withModal={true} />,
            },
        ],
    },
    {
        // @ts-ignore
        breadcrumb: 'Produtos das ordens de compra de estoque',
        path: 'estoques-produtos-dos-pedidos',
        element: (
            <Page
                Component={PurchaseOrderList}
                code="estoques-produtos-dos-pedidos"
                nature={ExpenseNature.StockPurchaseOrder}
                permissions={[Rule.List]}
                title="Produtos das ordens de compra de estoque"
            />
        ),
        children: [
            {
                path: 'despesas/novo/compra',
                element: <Page Component={PurchaseOrderExpensesCreateOrUpdatePage} nature={ExpenseNature.StockPurchaseOrder} code="despesas" permissions={[Rule.Create]} withModal={true} />,
            },
            {
                path: 'entrega-do-estoque',
                element: <Page Component={StockDeliveryPage} code="pedido-venda-ordem-compra" permissions={[Rule.Create]} withModal={true} />,
            },
            {
                path: 'entrega-direta/despesas/novo/compra',
                element: <Page Component={PurchaseOrderExpensesCreateOrUpdatePage} nature={ExpenseNature.PurchaseOrderBilled} code="despesas" permissions={[Rule.Create]} withModal={true} />,
            },
        ],
    },
    {
        //@ts-ignore
        breadcrumb: 'Importações',
        path: 'importacao',
        element: <Page Component={ImportFlowPage} code="estoque-importacao" permissions={[Rule.List]} />,
        children: [
            {
                path: 'novo',
                element: <Page Component={CreateOrUpdateImportFlowPage} code="estoque-importacao" permissions={[Rule.List]} />,
            },
        ],
    },
    {
        path: 'importacao/:importFlowId',
        element: <Page Component={ImportFlowDetailsPage} code="estoque-importacao" permissions={[Rule.Show]} />,
        children: [
            {
                index: true,
                element: <Page Component={ImportFlowDetailsTabPage} code="estoque-importacao" permissions={[Rule.Show]} />,
            },
            {
                path: 'nacionalizar',
                element: <Page Component={ImportFlowNationalizePage} code="estoque-importacao-nacionalizar" permissions={[Rule.Create]} withModal={true} />,
            },
            {
                path: 'nacionalizacoes',
                element: <Page Component={ImportFlowNationalizationsTabPage} code="estoque-importacao-nacionalizacoes" permissions={[Rule.List]} />,
                children: [
                    {
                        path: ':productNationalizationId',
                        element: <Page Component={ImportFlowProductNationalizationPage} code="estoque-importacao-nacionalizacoes" permissions={[Rule.Show]} withModal={true} />,
                    },
                ],
            },
            {
                path: 'despesas',
                element: <Page Component={ImportFlowExpensesTabPage} code="estoque-importacao-despesas" permissions={[Rule.List, Rule.Show]} />,
            },
        ],
    },
];

export default stocks;
