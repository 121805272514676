import TableCell from '@mui/material/TableCell/TableCell';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import Icon from 'components/core/icon';
import theme from 'settings/theme';
import { CrudPageProps } from 'types/graphql';
import dictionary from 'utils/dictionary';

const config: CrudPageProps<{}>[] = [
    {
        graphql: {
            table: 'menuGroup',
            searchableField: 'name',
            orderBy: { name: 'ASC' },
            columns: [
                {
                    name: 'id',
                    options: { display: 'excluded' }
                },
                {
                    name: 'name',
                    label: dictionary.content.graphql['name']
                },
                {
                    name: 'icon',
                    label: dictionary.content.graphql['icon'],
                    options: {
                        customBodyRender: (value) => (
                            <Tooltip placement="right" title={value} disableFocusListener={true} className="float-left">
                                <span>
                                    <Icon name={value} color={theme.extend.colors.secondary[500]} />
                                </span>
                            </Tooltip>
                        )
                    }
                },
                {
                    name: 'orderMenu',
                    label: dictionary.content.graphql['orderMenu']
                },
                {
                    name: 'actions',
                    label: 'Ações',
                    options: {
                        customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />
                    }
                }
            ]
        },
        title: 'Menu'
    }
];

export default config;
