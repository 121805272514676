import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import { getPaymentFlowKey } from './keys';
import { Modify } from 'types/general';
import { PaymentFlow } from 'types/models/payment-flow';

export type GenerateRemittancePayload = Modify<
    Pick<PaymentFlow, 'paymentFlowTransactions'>,
    {
        paymentFlowTransactions: number[];
    }
>;

const useGenerateRemittance = (paymentFlowId?: string) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const request = async (payload: GenerateRemittancePayload) => api.post(`/payment-flows/${paymentFlowId}/remittance-files`, payload);

    return useMutation(request, {
        onSuccess: () => {
            showToast('Arquivo gerado com sucesso', 'success');
            queryClient.invalidateQueries(getPaymentFlowKey(paymentFlowId));
        },
        onError: () => {
            showToast('Ocorreu um erro ao gerar o arquivo. Tente novamente', 'danger');
        },
    });
};

export default useGenerateRemittance;
