import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Text from 'components/core/text';
import { FieldArrayWithId } from 'react-hook-form';
import { OrderServiceWorkReportPayload } from 'types/models/order-service';
import WorksReportFileItem from './item';

type WorksReportFilesTableProps = {
    files: FieldArrayWithId<OrderServiceWorkReportPayload, 'files', 'id'>[];
    onRemoveFile: (index: number) => void;
};

const WorksReportFilesTable = ({ files = [], onRemoveFile }: WorksReportFilesTableProps) => {
    return (
        <TableContainer className="col-span-2 mb-6">
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Text as="span" className="text-secondary-500">
                                Arquivo
                            </Text>
                        </TableCell>
                        <TableCell width={90}>
                            <Text as="span" className="text-secondary-500">
                                Ações
                            </Text>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {files.map((file, index) => (
                        <WorksReportFileItem index={index} key={file.id} item={file} onRemoveFile={onRemoveFile} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default WorksReportFilesTable;
