import React, { useState } from 'react';
import Menu, { MenuItem } from 'components/menu';
import useTheme from '@mui/material/styles/useTheme';
import Button from 'components/core/button';
import { useNavigate } from 'react-router-dom';
import SvgIcoChevronDown from 'components/core/icon/files/ico-chevron-down';

type PaymentOrderDetailsActionsProps = {
    onOpenRefundModal: (value: boolean) => void;
    isPaid: boolean;
};

const PaymentOrderDetailsActions = ({ onOpenRefundModal, isPaid = false }: PaymentOrderDetailsActionsProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const { palette } = useTheme();
    const navigate = useNavigate();

    const handleClose = () => setAnchorEl(null);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const content: MenuItem[] = [
        ...(isPaid
            ? [
                  {
                      label: 'Estorno',
                      icon: {
                          color: palette.grey[500],
                          name: 'ico-dollar-sign',
                      },
                      onClick: () => {
                          handleClose();
                          onOpenRefundModal(true);
                      },
                  },
              ]
            : []),
        {
            label: 'Editar',
            icon: {
                color: palette.grey[500],
                name: 'ico-edit',
            },
            onClick: () => {
                handleClose();
                navigate('editar');
            },
        },
    ];

    return (
        <>
            <Button type="button" variant="contained" color="secondary" onClick={handleOpen} endIcon={<SvgIcoChevronDown width={13} color="#fff" />}>
                Ações
            </Button>
            <Menu className="z-[9999]" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} content={content} />
        </>
    );
};

export default PaymentOrderDetailsActions;
