import React, { useCallback, useState } from 'react';
import Menu, { MenuItem } from 'components/menu';
import useTheme from '@mui/material/styles/useTheme';
import Button from 'components/core/button';
import { useNavigate } from 'react-router-dom';
import useDelete from 'services/queries/crud/use-delete';
import Spinner from 'components/core/spinner';
import ConfirmModal from 'components/core/modal/confirm';
import { CustomProfile } from 'pages/shared/crud/list/list';
import { PersonContractType } from 'types/models/person';
import PersonContractStatus from 'components/core/modal/person-contract-status';
import useResetPeoplePassword from 'services/queries/people/use-reset-people-password';
import SvgIcoChevronDown from 'components/core/icon/files/ico-chevron-down';

type PersonDetailsSidebarActionsProps = {
    personId?: number;
    hasUser: boolean;
    mainContractId?: number;
};

const PersonDetailsSidebarActions = ({ personId, mainContractId, hasUser }: PersonDetailsSidebarActionsProps) => {
    const { palette } = useTheme();
    const navigate = useNavigate();

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [showResetPasswordModal, setResetPasswordModal] = useState(false);
    const [contractFlowType, setContractFlowType] = useState<PersonContractType>();

    const { mutateAsync: resetPeoplePassword, isLoading: isSubmittingPassword } = useResetPeoplePassword(personId!);

    const { mutateAsync: deleteItem, isLoading: isLoadingDelete } = useDelete<CustomProfile>({
        apiPayload: { endpoint: `/people`, useApiTodelete: true },
        onSuccess: () => navigate('/app/pessoas'),
    });

    const handleClose = () => setAnchorEl(null);
    const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

    const handleToggleResetPasswordModal = (value: boolean) => () => setResetPasswordModal(value);

    const handleConfirmDelete = useCallback(async () => {
        try {
            setShowDeleteModal(false);

            await deleteItem({ id: personId! });
        } catch (error) {
            console.log('handleConfirmDelete', error);
        }
    }, [deleteItem, personId]);

    const generateRoute = (path: string) => `/app/pessoas/${personId}/${path}`;

    const handleConfirmResetPassword = useCallback(async () => {
        try {
            await resetPeoplePassword({ id: personId! });

            handleToggleResetPasswordModal(false)();
        } catch (error) {
            console.log('handleConfirmResetPassword', error);
        }
    }, [resetPeoplePassword, personId]);

    const content: MenuItem[] = [
        {
            label: 'Editar',
            icon: {
                color: palette.grey[500],
                name: 'ico-edit',
            },
            onClick: () => navigate(generateRoute('editar')),
        },
        {
            label: 'Excluir',
            icon: {
                color: palette.grey[500],
                name: 'ico-trash',
            },
            onClick: () => setShowDeleteModal(true),
        },
        ...(hasUser
            ? [
                  {
                      label: 'Resetar senha',
                      icon: {
                          color: palette.grey[500],
                          name: 'ico-hide',
                      },
                      onClick: () => setResetPasswordModal(true),
                  },
              ]
            : []),
        {
            label: 'Alterar foto',
            icon: {
                color: palette.grey[500],
                name: 'ico-image',
            },
            onClick: () => navigate(generateRoute('alterar-foto')),
        },
        {
            label: 'Novo contrato',
            icon: {
                color: palette.grey[500],
                name: 'ico-plus',
            },
            onClick: () => navigate(generateRoute('contratos/novo')),
        },
        ...(!!mainContractId
            ? [
                  {
                      label: 'Situação Contratual',
                      icon: {
                          color: palette.grey[500],
                          name: 'ico-edit',
                      },
                      onClick: () => setContractFlowType('situation' as any),
                  },
                  {
                      label: 'Promoção',
                      icon: {
                          color: palette.grey[500],
                          name: 'ico-dollar',
                      },
                      onClick: () => setContractFlowType('promotion' as any),
                  },
                  {
                      label: 'Demissão',
                      icon: {
                          color: palette.grey[500],
                          name: 'ico-minus',
                      },
                      onClick: () => setContractFlowType('resignation' as any),
                  },
              ]
            : []),
    ].map((item) => ({
        ...item,
        onClick: () => {
            handleClose();
            item.onClick();
        },
    }));

    return (
        <>
            <Button type="button" variant="contained" color="primary" onClick={handleOpen} endIcon={<SvgIcoChevronDown width={13} color="#fff" />}>
                Ações
            </Button>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} content={content} />
            {isLoadingDelete && <Spinner fixed={true} parentClasses="bg-base-100/[.6] z-[999]" />}
            {contractFlowType && <PersonContractStatus type={contractFlowType} onClose={() => setContractFlowType(undefined)} contractId={mainContractId!} personId={personId!} />}
            {showDeleteModal && (
                <ConfirmModal
                    title="Apagar pessoa?"
                    description="Você tem certeza que deseja apagar esta pessoa?"
                    isLoading={isLoadingDelete}
                    onClose={() => setShowDeleteModal(false)}
                    onConfirmAction={handleConfirmDelete}
                />
            )}
            {showResetPasswordModal && (
                <ConfirmModal
                    title="Resetar senha?"
                    description="Você tem certeza que deseja resetar a senha desta pessoa?"
                    isLoading={isSubmittingPassword}
                    onClose={handleToggleResetPasswordModal(false)}
                    onConfirmAction={handleConfirmResetPassword}
                />
            )}
        </>
    );
};

export default PersonDetailsSidebarActions;
