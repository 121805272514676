import { useQuery } from 'react-query';
import api from 'services/api';
import { getSchedulesKey } from './keys';
import { SaleOrder, SaleOrderSchedulesResponse } from 'types/models/sale-order';
import { Pagination } from 'types/pagination';

type ScheduleResponse = SaleOrderSchedulesResponse & Pick<SaleOrder, 'id' | 'code'>;

const useGetSchedules = (filters?: string) => {
    const request = async () => {
        const endpoint = `/schedules${filters}`;
        const { data } = await api.get<Pagination<ScheduleResponse>>(endpoint);

        return data;
    };

    return useQuery(getSchedulesKey(filters), request, { enabled: Boolean(filters) });
};

export default useGetSchedules;
