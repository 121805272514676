import { useQuery } from 'react-query';
import api from 'services/api';
import { Loan } from 'types/models/loan';
import { getLoanKey } from './keys';

const useGetLoan = (loanId?: string) => {
    const request = async () => {
        const { data } = await api.get<Loan>(`/loans/${loanId}`);

        return data;
    };

    return useQuery(getLoanKey(loanId), request, { enabled: !!loanId });
};

export default useGetLoan;
