import TableCell from '@mui/material/TableCell/TableCell';
import Badge from 'components/core/badge';
import Button from 'components/core/button';
import _get from 'lodash/get';
import { CrudPageProps } from 'types/graphql';
import { PersonContractStatusTitle, PersonContractType } from 'types/models/person';
import { formatDate } from 'utils/date';
import dictionary from 'utils/dictionary';
import { formatMoney } from 'utils/money';

export const personContractsListConfig = (id = 0, onClickStatus: (id: any) => void): CrudPageProps<{}> => ({
    title: 'Contratos',
    graphql: {
        columns: [
            {
                name: 'id',
                options: { display: 'excluded' },
            },
            {
                name: 'startDate',
                label: dictionary.content.graphql['startDate'],
                options: {
                    customBodyRender: (value) => formatDate(value, 'DD/MM/YYYY'),
                },
            },
            {
                name: 'finalDate',
                label: dictionary.content.graphql['finalDate'],
                options: {
                    customBodyRender: (value) => formatDate(value, 'DD/MM/YYYY'),
                },
            },
            {
                name: 'salary',
                label: dictionary.content.graphql['salary'],
                options: {
                    customBodyRender: (value = 0) => formatMoney(value || 0),
                },
            },
            {
                name: 'classification.name',
                label: 'Cargo',
                options: {
                    customBodyRender: (value) => value || '-',
                },
            },
            {
                name: 'classificationBySectorId.name',
                label: 'Setor',
                options: {
                    customBodyRender: (value) => value || '-',
                },
            },
            {
                name: 'personContractStatus.id',
                label: dictionary.content.graphql['status'],
                options: {
                    customBodyRender: (value = 0, tableMeta) => {
                        const label = _get(dictionary.content.personContractStatusTitle, PersonContractStatusTitle[value], '-');
                        const isEligibleToReactivate = [
                            PersonContractStatusTitle.MaternityLeave,
                            PersonContractStatusTitle.MedicalCertificate,
                            PersonContractStatusTitle.Vacation,
                            PersonContractStatusTitle.SicknessAllowance,
                        ].includes(value);

                        if (!isEligibleToReactivate) {
                            return label;
                        }

                        const [id] = tableMeta.rowData;

                        return (
                            <div className="flex flex-col">
                                <span className="mb-2">{label}</span>
                                <Button
                                    onClick={() => onClickStatus(id)}
                                    size="small"
                                    type="button"
                                    variant="contained"
                                    color="secondary"
                                    className="text-[0.6rem] py-[3px] px-[5px] mr-auto text-center">
                                    Reativar contrato
                                </Button>
                            </div>
                        );
                    },
                },
            },
            {
                name: 'type',
                label: dictionary.content['type'],
                options: {
                    customBodyRender: (value: any) => _get(dictionary.content.contractTypes, PersonContractType[value]?.toLocaleLowerCase(), ''),
                },
            },
            {
                name: 'juridicName',
                label: dictionary.content.graphql['juridicName'],
                options: {
                    customBodyRender: (value) => value || '-',
                },
            },
            {
                name: 'consultant',
                label: 'Consultor(a)',
                options: {
                    customBodyRender: (value) => <Badge variant={Boolean(value) ? 'success' : 'error'}>{Boolean(value) ? 'Sim' : 'Não'}</Badge>,
                },
            },
            {
                name: 'actions',
                label: 'Ações',
                options: {
                    customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />,
                },
            },
        ],
        table: 'personContract',
        searchableField: 'juridicName',
        where: {
            personId: { _eq: id },
        },
    },
    hideHeader: true,
});
