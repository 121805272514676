import classnames from 'classnames';
import { memo } from 'react';
import { TableHeader } from 'types/models/reports';
import SvgIcoChevronDown from 'components/core/icon/files/ico-chevron-down';

type DRETableBodyHeaderProps = {
    onToggle: () => void;
    title: string;
    numberOfCols: number;
    isClosed: boolean;
    headers?: TableHeader[];
    hasToggleButton?: boolean;
};

const DRETableBodyHeader = ({ onToggle, title, numberOfCols, isClosed, headers = [], hasToggleButton = true }: DRETableBodyHeaderProps) => {
    const description = classnames(`text-left p-2 break-words`);

    return (
        <tr>
            {Boolean(title) && (
                <th onClick={hasToggleButton ? onToggle : () => {}} className={classnames(description, 'pt-4', hasToggleButton ? 'cursor-pointer' : '')}>
                    <div className="flex items-center">
                        <span className="mr-2">{title}</span>
                        {hasToggleButton && <SvgIcoChevronDown width={14} height={8} className={classnames('text-heading')} style={isClosed ? { transform: 'rotate(90deg)' } : undefined} />}
                    </div>
                </th>
            )}
            {Boolean(headers?.length)
                ? headers.map(({ title, ...rest }, i) => (
                      <td {...rest} className={classnames('dre-currency  px-2 border-slate-100', rest?.className)} key={`${title}-blank-td-${i}`}>
                          {title}
                      </td>
                  ))
                : [...new Array(numberOfCols).keys()].map((_, i) => <td className="dre-currency border-dashed px-2 border-slate-100" key={`${title}-blank-td-${i}`}></td>)}
        </tr>
    );
};

export default memo(DRETableBodyHeader);
