import { createActions, createReducer } from 'reduxsauce';
import api from 'services/api';
import { ReduxAction } from 'types/general';
import { AuthActionsType, AuthState, AuthActions } from './types';

export const { Creators, Types } = createActions<Record<AuthActionsType, AuthActionsType>, AuthActions>({
    setAuth: ['payload'],
    logout: [],
});

export const AUTH_INITIAL_STATE: AuthState = {
    isAuthenticated: false,
    token: '',
    credentials: {
        user: {
            username: '',
            superAdmin: false,
        },
    },
};

const setAuth = (state = AUTH_INITIAL_STATE, action: ReduxAction<AuthState>): AuthState => ({
    ...state,
    ...action.payload,
    isAuthenticated: true,
});

const logout = (state = AUTH_INITIAL_STATE): AuthState => {
    delete api.defaults.headers.common.Authorization;

    return { ...state, ...AUTH_INITIAL_STATE };
};

export default createReducer<AuthState>(AUTH_INITIAL_STATE, {
    [Types.SET_AUTH]: setAuth,
    [Types.LOGOUT]: logout,
});
