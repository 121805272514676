import { createActions, createReducer } from 'reduxsauce';
import { ConfigActionsType, ConfigState, ConfigActions } from './types';

export const { Creators, Types } = createActions<Record<ConfigActionsType, ConfigActionsType>, ConfigActions>({
    setConfig: ['key', 'payload'],
});

const INITIAL_STATE: ConfigState = {
    operational: {
        isSidebarOpen: true,
        isNavbarOpen: true,
    },
    tables: {
        rowsPerPage: 10,
        pages: {},
    },
};

const setConfig = (state = INITIAL_STATE, action: { type: string; key: keyof ConfigState; payload: any }): ConfigState => ({
    ...state,
    [action.key]: {
        ...state[action.key],
        ...action.payload,
    },
});

export default createReducer<ConfigState>(INITIAL_STATE, {
    [Types.SET_CONFIG]: setConfig,
});
