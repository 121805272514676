import Button from 'components/core/button';
import Input from 'components/core/form/input';
import Select from 'components/core/form/select';
import FavoredSelect from 'components/favored-select';
import get from 'lodash/get';
import { useCallback, useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import useGetClassifications from 'services/queries/classifications/use-get-classifications';
import { generateClassificationQuery } from 'services/queries/classifications/utils';
import useGetCompaniesOptions from 'services/queries/companies/use-get-company-options';
import useGetOptions from 'services/queries/crud/use-get-options';
import { generateGetBranchOptionsQuery } from 'settings/routes/branches/utils';
import { Option } from 'types/general';
import { ClassificationCategoryCode, ClassificationType } from 'types/models/classification';
import { useImportFlowContext } from '../../context';
import { Steps } from '../../context/types';
import useToast from 'hooks/toast/use-toast';
import { useTheme } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';
import DatePicker from 'components/core/datepicker';
import { excludeDates } from 'pages/private/payment-order/actions/ops-grouping/general';
import { yupResolver } from '@hookform/resolvers/yup';
import { importFlowGeneralTabSchema } from '../utils';
import SvgIcoArrowRight from 'components/core/icon/files/ico-arrow-right';

const ORIGIN_WHERE_QUERY = {
    type: { _eq: ClassificationType.TransactionOrigin },
    classification: {
        code: { _eq: ClassificationCategoryCode.Materials },
        type: { _eq: ClassificationType.TransactionCategory },
        name: { _eq: 'Materiais' },
    },
};

const generateSubOriginWhereClause = (origin: number) => {
    return {
        classificationId: { _eq: origin },
    };
};

const General = () => {
    const { data: companies = [], isLoading: isLoadingCompanies } = useGetCompaniesOptions();
    const { data: branches = [], isLoading: isLoadingBranches } = useGetOptions(generateGetBranchOptionsQuery(), ['branch', 'options']);
    const { data: origins = [], isLoading: isLoadingOrigins } = useGetClassifications(generateClassificationQuery(ClassificationType.TransactionOrigin, ORIGIN_WHERE_QUERY));
    const { data: fiscalCodes = [], isLoading: isLoadingFiscalCodes } = useGetClassifications(generateClassificationQuery(ClassificationType.ExpenseFiscalCode));

    const { showToast } = useToast();
    const { palette } = useTheme();

    const { state, setContent, goToStep, completeStep } = useImportFlowContext();

    const { control, formState, handleSubmit, reset } = useForm<any>({
        mode: 'onSubmit',
        shouldFocusError: true,
        resolver: yupResolver(importFlowGeneralTabSchema),
        defaultValues: {
            invoice: '',
        },
    });

    const origin = useWatch({ control, name: 'classificationOrigin' });

    const { data: subOrigins = [], isLoading: isLoadingSubOrigins } = useGetClassifications(
        generateClassificationQuery(ClassificationType.TransactionSubOrigin, generateSubOriginWhereClause(origin)),
        !!origin
    );

    const getError = useCallback((name: string) => get(formState.errors, `${name}.message`) as any, [formState]);

    const submit = async (data: any) => {
        try {
            setContent({ general: data });
            completeStep(Steps.GeneralInfo);
            goToStep('next');
        } catch (error) {
            console.log('submit', error);
            showToast('Ocorreu um erro. Tente novamente', 'danger');
        }
    };

    useEffect(() => {
        if (!isEmpty(state.content?.general)) {
            reset({ ...state.content?.general });
        }
    }, [state.content?.general, reset]);

    return (
        <form>
            <div className="p-6 flex flex-col md:grid grid-cols-3 gap-4 mb-4">
                <Controller
                    name="company"
                    control={control}
                    render={({ field }) => {
                        const value = companies.find((item) => item.value === field.value);

                        return (
                            <Select
                                {...field}
                                isLoading={isLoadingCompanies}
                                value={value}
                                options={companies}
                                label="Empresa"
                                placeholder="Selecione uma opção"
                                onChange={(option: any) => field.onChange(option.value)}
                                autoFocus={true}
                                error={getError('company')}
                            />
                        );
                    }}
                />
                <Controller
                    name="branch"
                    control={control}
                    render={({ field }) => {
                        const value = branches.find((item) => item.value === field.value);

                        return (
                            <Select
                                {...field}
                                value={value}
                                options={branches}
                                isLoading={isLoadingBranches}
                                label="Filial"
                                placeholder="Selecione uma opção"
                                onChange={(option: any) => field.onChange(option.value)}
                                error={getError('branch')}
                            />
                        );
                    }}
                />

                <Controller
                    name="invoice"
                    control={control}
                    render={({ field }) => {
                        return <Input {...field} placeholder="Ex: 0129" value={field.value} label="Invoice" autoFocus={true} error={getError('invoice')} />;
                    }}
                />
                <Controller
                    name="formFavoredManufacturer"
                    control={control}
                    render={({ field }) => {
                        return (
                            <FavoredSelect
                                customLabel="Fabricante"
                                hideActions={true}
                                value={field.value}
                                error={(formState.errors as any)?.formFavoredManufacturer?.value?.message}
                                onChange={(option: Option) => field.onChange(option)}
                            />
                        );
                    }}
                />
                <Controller
                    name="dueDateManufacturer"
                    control={control}
                    render={({ field }) => {
                        return (
                            <DatePicker
                                inputProps={{
                                    label: 'Vencimento do fabricante',
                                    placeholderText: 'Selecione uma data',
                                    error: getError('dueDateManufacturer'),
                                    ...field,
                                }}
                            />
                        );
                    }}
                />
                <Controller
                    name="provisionManufacturer"
                    control={control}
                    render={({ field }) => {
                        return (
                            <DatePicker
                                inputProps={{
                                    placeholderText: 'Selecione a data de previsão',
                                    label: 'Previsão do fabricante',
                                    error: getError('provisionManufacturer'),
                                    ...field,
                                }}
                                muiDatePickerProps={{
                                    shouldDisableDate: excludeDates,
                                }}
                                withProvisionLogic={true}
                            />
                        );
                    }}
                />
                <Controller
                    name="classificationOrigin"
                    control={control}
                    render={({ field }) => {
                        const value = origins.find((item) => item.value === field.value);

                        return (
                            <Select
                                {...field}
                                value={value}
                                options={origins}
                                isLoading={isLoadingOrigins}
                                label="Origem"
                                placeholder="Selecione uma opção"
                                onChange={(option: any) => field.onChange(option.value)}
                                error={getError('classificationOrigin')}
                            />
                        );
                    }}
                />
                <Controller
                    name="classificationSubOrigin"
                    control={control}
                    render={({ field }) => {
                        const value = subOrigins.find((item) => item.value === field.value);

                        return (
                            <Select
                                {...field}
                                isLoading={isLoadingSubOrigins}
                                value={value}
                                options={subOrigins}
                                label="Sub-origem"
                                placeholder="Selecione uma opção"
                                onChange={(option: any) => field.onChange(option.value)}
                                error={getError('classificationSubOrigin')}
                            />
                        );
                    }}
                />
                <Controller
                    name="fiscalCode"
                    control={control}
                    render={({ field }) => {
                        const value = fiscalCodes.find((item) => item.value === field.value);

                        return (
                            <Select
                                {...field}
                                isLoading={isLoadingFiscalCodes}
                                value={value}
                                options={fiscalCodes}
                                label="CFOP"
                                placeholder="Selecione uma opção"
                                onChange={(option: any) => field.onChange(option.value)}
                                error={getError('fiscalCode')}
                            />
                        );
                    }}
                />
            </div>
            <div className="border-t p-2 flex">
                <Button
                    endIcon={<SvgIcoArrowRight width={18} color={palette.secondary[500]} />}
                    variant="text"
                    color="secondary"
                    className="min-w-[100px] ml-auto"
                    onClick={
                        handleSubmit(submit) // we need to use submit as button click to prevent parent forms to be submitted too
                    }>
                    Avançar
                </Button>
            </div>
        </form>
    );
};

export default General;
