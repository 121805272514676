import { useQuery } from 'react-query';
import { getOrderServiceWorkReportsKey } from './keys';
import api from 'services/api';
import { Pagination } from 'types/pagination';
import { OrderServiceWorkReport } from 'types/models/order-service';

const useGetOrderServiceWorkReports = (orderServiceId?: string, saleOrderId?: string) => {
    const request = async () => {
        const { data } = await api.get<Pagination<OrderServiceWorkReport>>(`/sale-orders/${saleOrderId}/service-orders/${orderServiceId}/galleries`);

        return data;
    };

    return useQuery(getOrderServiceWorkReportsKey(orderServiceId, saleOrderId), request, { enabled: Boolean(orderServiceId) && Boolean(saleOrderId) });
};

export default useGetOrderServiceWorkReports;
