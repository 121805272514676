import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SvgIcoShow from 'components/core/icon/files/ico-show';

type FinancialBalanceHeadCardProps = {
    onOpenTaxModal: () => void;
};

const FinancialBalanceTaxHeaderCard = ({ onOpenTaxModal }: FinancialBalanceHeadCardProps) => {
    return (
        <div className="flex items-center gap-1">
            <p className="text-base-500 text-base font-normal">Imposto</p>
            <Tooltip placement="top" title="Ver impostos" disableFocusListener={true}>
                <IconButton onClick={onOpenTaxModal} TouchRippleProps={{ classes: { child: 'text-system-info-100' } }} className="text-system-info-500 hover:bg-opacity-30 hover:bg-system-info-100">
                    <SvgIcoShow width={16} height={16} />
                </IconButton>
            </Tooltip>
        </div>
    );
};

export default FinancialBalanceTaxHeaderCard;
