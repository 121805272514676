import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import queries from './keys';
import useToast from 'hooks/toast/use-toast';
import { CompanyPayload } from 'types/models/company';
import apiGraphQl from 'services/graphql';

export type CreateProviderResponse = {
    item: {
        id: number;
        title: string;
    };
};

const useCreateProvider = (onSucess?: (data: CreateProviderResponse['item']) => void) => {
    const navigate = useNavigate();
    const { showToast } = useToast();

    const request = async (payload: CompanyPayload) => apiGraphQl<CreateProviderResponse>(queries.createProvider, { payload });

    return useMutation(request, {
        onSuccess: ({ item }) => {
            showToast('Fornecedor criado com sucesso', 'success');

            if (!!onSucess) {
                return onSucess(item);
            }

            navigate(`../${item.id}`, { replace: true });
        },
        onError: (err: any) => {
            const errMessage = err?.response?.errors?.[0]?.message;

            if (errMessage.includes('duplicate')) {
                if (errMessage.includes('provider_document_key')) {
                    showToast('CPF já cadastrado no sistema', 'danger');
                }

                if (errMessage.includes('provider_juridic_document_key')) {
                    showToast('CNPJ já cadastrado no sistema', 'danger');
                }
            }
        },
    });
};

export default useCreateProvider;
