import { OrderServiceWorkReportPayload } from 'types/models/order-service';
import CreateOrUpdateOrderServiceWorkReport from './create-or-update';
import { useParams } from 'react-router-dom';
import useUploadFile from 'services/queries/files/use-upload-file';
import useCreateOrUpdateOrderServiceWorkReport from 'services/queries/order-services/use-create-or-update-order-service-work-report';
import { useQueryClient } from 'react-query';
import { getOrderServiceWorkReportsKey } from 'services/queries/order-services/keys';
import dayjs from 'dayjs';

const CreateOrUpdateOrderServiceWorkReportPage = () => {
    const { saleOrderId, orderServiceId } = useParams();

    const { isLoading: isSubmittingWorkReport, mutateAsync: createOrUpdateOrderServiceWorkReport } = useCreateOrUpdateOrderServiceWorkReport(saleOrderId, orderServiceId);

    const { uploadFile, isLoading: isSubmittingFiles } = useUploadFile();
    const queryClient = useQueryClient();

    const isSubmitting = isSubmittingFiles || isSubmittingWorkReport;

    const handleSubmit = async (data: OrderServiceWorkReportPayload) => {
        const files: any = [];

        try {
            if (Boolean(data?.files?.length)) {
                for (const photo of data.files!) {
                    if (!photo?.localFile) {
                        throw new Error();
                    }

                    if (photo?.file) {
                        files.push(photo.file);
                    } else {
                        try {
                            const responseDocument = await uploadFile(photo.localFile, undefined, true);
                            files.push(responseDocument?.id);
                        } catch (error) {
                            console.error('Houve um erro ao enviar o arquivo.', error);
                        }
                    }
                }
            }

            await createOrUpdateOrderServiceWorkReport({
                title: dayjs().format('DD/MM/YYYY HH:mm'),
                description: data.description,
                files,
            });

            queryClient.invalidateQueries(getOrderServiceWorkReportsKey(orderServiceId, saleOrderId));
        } catch (e) {
            console.error(e);
        }
    };
    return <CreateOrUpdateOrderServiceWorkReport isSubmitting={isSubmitting} onSubmit={handleSubmit} />;
};

export default CreateOrUpdateOrderServiceWorkReportPage;
