import useTheme from '@mui/material/styles/useTheme';
import IconButton from '@mui/material/IconButton/IconButton';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import Empty from 'components/empty';
import Text from 'components/core/text';
import AddButton from 'components/buttons/add';
import Input from 'components/core/form/input';
import { hexToRgba } from 'utils/string';
import SvgIcoTrash from 'components/core/icon/files/ico-trash';

type PhonesProps = {
    accessor?: string;
    index: number;
    className?: string;
    withLabel?: boolean;
};

export const defaultPhone = {
    ddd: '',
    number: '',
};

const Phones = ({ accessor = 'phones', index, className, withLabel = false }: PhonesProps) => {
    const { palette } = useTheme();
    const { control, formState } = useFormContext();

    const phoneFieldName = accessor || `external.${index}.phones`;

    const { fields, append, remove } = useFieldArray({ name: phoneFieldName, control });

    return (
        <div className={className}>
            {withLabel && (
                <Text as="label" variant="body.regular.sm" className="block mb-1.5 text-base-500">
                    Telefone(s)
                </Text>
            )}
            {Boolean(fields.length) ? (
                fields.map((item, phoneIndex) => {
                    const dddError = formState.errors?.[accessor]?.[index]?.phones?.[phoneIndex]?.ddd?.message;

                    return (
                        <div key={item.id} className="relative mb-2">
                            <div className="absolute right-[10px] top-[50%]" style={{ transform: 'translateY(-50%)' }}>
                                <IconButton
                                    size="large"
                                    type="button"
                                    color="error"
                                    sx={{ backgroundColor: palette.grey[100], '&:hover': { backgroundColor: hexToRgba(palette.error[100], 0.5) } }}
                                    onClick={remove.bind(this, phoneIndex)}>
                                    <SvgIcoTrash width={12} height={12} color={palette.error[500]} />
                                </IconButton>
                            </div>
                            <Controller
                                name={`${phoneFieldName}.${phoneIndex}.number`}
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <Input
                                            {...field}
                                            error={formState.errors?.[phoneFieldName]?.[phoneIndex]?.number?.message}
                                            placeholder="Ex: 99999-9999"
                                            mask={String(field?.value)?.charAt(0) === '9' ? '99999-9999' : '9999-9999'}
                                            left={
                                                <Controller
                                                    name={`${phoneFieldName}.${phoneIndex}.ddd`}
                                                    control={control}
                                                    render={({ field: ddd }) => (
                                                        <Input
                                                            {...ddd}
                                                            mask="99"
                                                            error={dddError}
                                                            placeholder="(99)"
                                                            className={`bg-transparent border-r ${Boolean(dddError) && 'border border-solid border-system-danger-500 my-[1px] rounded-l-[14px]'}`}
                                                            inputWrapperClasses="border-0 border-r border-r-base-300 rounded-none"
                                                            parentClassName="w-[65px]"
                                                            showErrorMessage={false}
                                                        />
                                                    )}
                                                />
                                            }
                                            leftClasses="bg-base-200"
                                        />
                                    );
                                }}
                            />
                        </div>
                    );
                })
            ) : (
                <Empty title="Nenhum telefone adicionado até o momento" className="mb-4" />
            )}
            <div className="py-2 -mt-2">
                <AddButton onClick={append.bind(this, defaultPhone)}>Mais telefones</AddButton>
            </div>
        </div>
    );
};

export default Phones;
