import { useQuery } from 'react-query';
import { Option } from 'types/general';
import apiGraphQl from 'services/graphql';
import { Pagination } from 'types/pagination';
import { getClassificationsOptionsKey } from './keys';

const useGetClassifications = (query?: string, enabled = true) => {
    const request = async () => apiGraphQl<Pagination<Option>>(query || '').then(({ items }) => items);

    return useQuery(getClassificationsOptionsKey(query), request, { enabled });
};

export default useGetClassifications;
