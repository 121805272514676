import { yupResolver } from '@hookform/resolvers/yup';
import Select from 'components/core/form/select';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import FormButtons from 'components/form-buttons';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import useGetCompaniesOptions from 'services/queries/companies/use-get-company-options';
import useGetOptions from 'services/queries/crud/use-get-options';
import { generateGetBranchOptionsQuery } from 'settings/routes/branches/utils';
import { ClassificationCategoryCode, ClassificationSectorCode, ClassificationType } from 'types/models/classification';
import CommissionTable, { CreateCommissionPayload } from './table';
import { formatDate } from 'utils/date';
import useCreateCommission from 'services/queries/expenses/use-create-commission';
import ExpenseForm, { ExpenseFormConfig } from 'components/forms/expense';
import useGetConsultantSaleOrders from 'services/queries/sales-order/use-get-consultant-sale-orders';
import { useEffect, useState } from 'react';
import { commissionSchema } from './utils';
import { getNumberValueOrZero } from 'pages/private/expenses/details/tabs/transactions/utils';
import { saleOrderTypes } from 'utils/statics';

const CreateCommissionPage = () => {
    const [hasConsultantManager, setHasConsultantManager] = useState(false);

    const methods = useForm<CreateCommissionPayload>({
        defaultValues: {
            provision: '',
            commissions: [],
        },
        mode: 'onSubmit',
        resolver: yupResolver(commissionSchema(hasConsultantManager)) as any,
    });

    const { control, formState, handleSubmit, watch, setValue } = methods;

    const [sector, consultant, saleOrderType] = watch(['sector', 'formFavored.person', 'saleOrderType']);

    const { mutateAsync: createCommission, isLoading: isSubmitting } = useCreateCommission();
    const { data: companies = [], isLoading: isLoadingCompanies } = useGetCompaniesOptions();
    const { data: branches = [], isLoading: isLoadingBranches } = useGetOptions(generateGetBranchOptionsQuery(), ['branch', 'options']);
    const { data: saleOrders = [], isLoading: isLoadingSaleOrders } = useGetConsultantSaleOrders(consultant?.value, saleOrderType);

    const expenseConfig: ExpenseFormConfig = {
        category: {
            type: ClassificationType.TransactionCategory,
            where: {
                code: { _eq: ClassificationCategoryCode.Commissions.toString() },
                classificationId: { _eq: sector?.value },
            },
            isEnabled: !!sector,
            isStaticField: true,
        },
        origin: {
            type: ClassificationType.TransactionOrigin,
            isHided: true,
        },
        sector: {
            type: ClassificationType.Sector,
            where: {
                code: { _eq: ClassificationSectorCode.Commercial.toString() },
            },
            isEnabled: true,
            isStaticField: true,
        },
        subOrigin: {
            type: ClassificationType.TransactionSubOrigin,
            isHided: true,
        },
        favored: {
            fieldLabel: 'Consultor',
            isConsultant: true,
            isEnabled: true, //auth.credentials.user.superAdmin,
        },
    };

    const formattedSaleOrders = saleOrders.map((item) => ({
        ...item,
        value: item.id,
        label: item.code,
    }));

    const submit = async (data: CreateCommissionPayload) => {
        try {
            const totalOfCommissions = data.commissions.reduce((acc, curr) => {
                const consultantValue = getNumberValueOrZero((curr.netValue || 0) * ((curr.consultant?.percentage || 0) / 100));
                const managerValue = getNumberValueOrZero((curr.netValue || 0) * ((curr.manager?.percentage || 0) / 100));

                const totalRowComissions = consultantValue + managerValue;

                return acc + totalRowComissions;
            }, 0);

            const payload = {
                provision: formatDate(data.provision, 'YYYY-MM-DD'),
                value: totalOfCommissions,
                company: data.company,
                branch: data.branch,
                fiscalCode: data.fiscalCode.value,
                formFavored: {
                    person: data.formFavored.person.value,
                    ...(Boolean(data.formFavored.favoredBankAccount) && {
                        favoredBankAccount: data.formFavored.favoredBankAccount?.value,
                    }),
                },
                paymentForm: data.paymentForm.value,
                commissions: data.commissions.map((item) => {
                    return {
                        // item.type: orderService | saleOrder
                        [item.type]: item.id,
                        percentage: item.consultant?.percentage || 0,
                        ...(Boolean(item.manager?.percentage) && {
                            managerCommission: item.manager?.percentage,
                        }),
                    };
                }),
                saleOrderType: data.saleOrderType,
            };

            await createCommission(payload);
        } catch (error) {
            console.log('submit', error);
        }
    };

    useEffect(() => {
        // always reset comissions when consultant select change
        setValue('commissions', []);
        setHasConsultantManager(Boolean(consultant?.activeContract?.manager));
    }, [consultant, setValue]);

    useEffect(() => {
        // always reset comissions when saleOrderType select change
        setValue('commissions', []);
    }, [saleOrderType, setValue]);

    return (
        <Modal
            containerClassnames="bg-base-700/80"
            contentClassnames="w-[1600px]"
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    Nova comissão
                </Text>
            }
            closeOnClickOutside={false}>
            <FormProvider {...methods}>
                <form className="px-6 pb-6" onSubmit={handleSubmit(submit)}>
                    <div className="grid sm:grid-cols-2 md:grid-cols-3 xl:gri-cols-4 grid-cols-1 gap-4 mb-6 border border-base-300 p-4 rounded-[14px]">
                        <Controller
                            control={control}
                            name="company"
                            render={({ field }) => {
                                const value = companies.find((company) => company?.value === field?.value);

                                return (
                                    <Select
                                        options={companies}
                                        value={value}
                                        isLoading={isLoadingCompanies}
                                        isClearable={true}
                                        placeholder="Selecione uma empresa"
                                        label="Empresa"
                                        error={formState.errors.company?.message}
                                        onChange={(option: any) => field.onChange(option.value)}
                                    />
                                );
                            }}
                        />
                        <Controller
                            control={control}
                            name="branch"
                            render={({ field }) => {
                                const value = branches.find((company) => company?.value === field?.value);

                                return (
                                    <Select
                                        options={branches}
                                        value={value}
                                        isLoading={isLoadingBranches}
                                        isClearable={true}
                                        placeholder="Selecione uma filial"
                                        label="Filial"
                                        error={formState.errors.branch?.message}
                                        onChange={(option: any) => field.onChange(option.value)}
                                    />
                                );
                            }}
                        />
                        <ExpenseForm config={expenseConfig} />
                        {hasConsultantManager && (
                            <div>
                                <Text as="h3" className="text-base-500 mb-1.5">
                                    Gerente
                                </Text>
                                <div className="border border-gray-200 rounded-[14px] min-h-[48px] flex items-center p-3 bg-gray-100">
                                    <Text as="span" className="text-heading">
                                        {consultant?.activeContract?.manager?.title || '-'}
                                    </Text>
                                </div>
                            </div>
                        )}
                        <Controller
                            control={control}
                            name="saleOrderType"
                            render={({ field }) => {
                                const value = saleOrderTypes.find((item) => item.value === field.value);

                                return (
                                    <Select
                                        value={value}
                                        options={saleOrderTypes}
                                        label="Tipo de pedido"
                                        placeholder="Selecione uma opção"
                                        parentClassName="mb-4 md:mb-0 sm:min-w-[250px]"
                                        error={formState.errors.saleOrderType?.message}
                                        onChange={(option: any) => field.onChange(option.value)}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="commissions"
                            control={control}
                            render={({ field }) => {
                                const value = formattedSaleOrders.filter((item) => field?.value?.find((order) => order.id === item?.id!));

                                return (
                                    <Select
                                        {...field}
                                        isLoading={isLoadingSaleOrders}
                                        isMulti={true}
                                        value={value}
                                        options={formattedSaleOrders}
                                        label="Pedidos de venda"
                                        placeholder="Selecione uma opção"
                                        error={formState.errors.commissions?.message}
                                        required={true}
                                        parentClassName="md:col-span-4 sm:col-span-2"
                                        onChange={(options: any) => field.onChange(options)}
                                    />
                                );
                            }}
                        />
                    </div>
                    <CommissionTable />
                    <FormButtons isLoading={isSubmitting} />
                </form>
            </FormProvider>
        </Modal>
    );
};

export default CreateCommissionPage;
