import classnames from 'classnames';
import { memo } from 'react';
import { TableItemValuesValue } from 'types/models/reports';
import { formatMoney } from 'utils/money';

type DRETableBodyItemProps = TableItemValuesValue & { className?: string };

const DRETableBodyItem = ({ type, value, className, customComponent, onClick }: DRETableBodyItemProps) => {
    const currency = classnames(`dre-currency  px-2 border-slate-100`);

    if ([undefined, null].includes(value as any)) {
        type = 'blank';
    }

    if (!type) {
        type = 'custom';
    }

    switch (type) {
        case 'currency':
            return (
                <td onClick={onClick} className={classnames(currency, className, Boolean(onClick) && 'cursor-pointer')}>
                    <div className="flex justify-between px-2">
                        <span>R$</span>
                        <span>{formatMoney(value as number)?.replace('R$', '')}</span>
                    </div>
                </td>
            );

        case 'percentage':
            return (
                <td onClick={onClick} className={classnames(currency, className, 'font-bold text-center', Boolean(onClick) && 'cursor-pointer')}>
                    {formatMoney(value as number)?.replace('R$', '')}%
                </td>
            );

        case 'blank':
            return <td onClick={onClick} className={classnames(currency, className, 'border-dashed', Boolean(onClick) && 'cursor-pointer')}></td>;

        case 'custom':
            return (
                <td onClick={onClick} className={classnames(currency, className, Boolean(onClick) && 'cursor-pointer')}>
                    {customComponent!(value)}
                </td>
            );

        default:
            return (
                <td onClick={onClick} className={classnames(currency, className, 'text-center', Boolean(onClick) && 'cursor-pointer')}>
                    {formatMoney(value as number)?.replace('R$', '')}
                </td>
            );
    }
};

export default memo(DRETableBodyItem);
