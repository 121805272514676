import Text from 'components/core/text';
import ProviderConsultingIconContent from './icon-content';
import { ProviderConsultingResponse, ProviderConsultingStatus } from 'types/models/provider';
import { formatMoney } from 'utils/money';
import { formatCNPJ } from '@brazilian-utils/brazilian-utils';
import { formatDate } from 'utils/date';
import { formatPhoneNumber, generateAddress } from 'utils/string';
import Badge from 'components/core/badge';
import { menuItemStyles } from 'components/nav/item';
import { useMemo } from 'react';
import SimpleTable from 'components/core/table/simple';
import Empty from 'components/empty';
import { providerConsultingTypeMap } from 'utils/statics';
import SvgIcoReadMail from 'components/core/icon/files/ico-read-mail';
import SvgIcoChart from 'components/core/icon/files/ico-chart';
import SvgIcoDollar from 'components/core/icon/files/ico-dollar';
import SvgIcoContacts from 'components/core/icon/files/ico-contacts';
import SvgIcoTravel from 'components/core/icon/files/ico-travel';
import SvgIcoPushPin from 'components/core/icon/files/ico-push-pin';
import SvgIcoCalendar from 'components/core/icon/files/ico-calendar';
import SvgIcoTag from 'components/core/icon/files/ico-tag';
import SvgIcoTasksCheck from 'components/core/icon/files/ico-tasks-check';
import SvgIcoCloseCircle from 'components/core/icon/files/ico-close-circle';
import SvgIcoPhone from 'components/core/icon/files/ico-phone';
import SvgIcoMail from 'components/core/icon/files/ico-mail';
import SvgIcoMap from 'components/core/icon/files/ico-map';
import SvgIcoPin from 'components/core/icon/files/ico-pin';

type ProviderConsultingModalContentProps = {
    data: ProviderConsultingResponse;
};

const ModalProviderConsultingContent = ({ data }: ProviderConsultingModalContentProps) => {
    const activitiesItems = useMemo(() => {
        const activitiesArr = [data.mainActivity, ...(data.sideActivities || [])];

        return activitiesArr.map((item, index) => ({
            id: item.id,
            contents: [
                {
                    label: <Badge variant={index === 0 ? 'success' : 'info'}>{index === 0 ? 'Atividade Principal' : 'Atividade Secundária'}</Badge>,
                    className: 'w-[250px]',
                },
                {
                    label: item.id.toString().replace(/(\d{4})(\d)(\d{2})/, '$1-$2/$3'),
                    className: 'w-[150px] whitespace-nowrap',
                },
                {
                    label: item.text,
                    className: 'whitespace-nowrap',
                },
            ],
        }));
    }, [data]);

    const membersItems = useMemo(() => {
        return data.company?.members.map((item, index) => ({
            id: index,
            contents: [
                {
                    label: item.person.type in providerConsultingTypeMap ? <Badge variant="info">{providerConsultingTypeMap[item.person.type] || '-'}</Badge> : '-',
                    className: 'w-[250px]',
                },
                {
                    label: formatDate(item.since),
                    className: 'w-[150px]',
                },
                {
                    label: (
                        <div className="space-y-0.5">
                            <Text as="p" className="font-semibold">
                                {item.person.name}
                            </Text>
                            <div className="flex items-center gap-2">
                                <Text as="span" className="text-xs">
                                    {item.person.taxId}
                                </Text>
                                <Text as="span" className="text-xs flex gap-1 items-center">
                                    <span className={menuItemStyles.bullet} />
                                    {item.person.age}
                                </Text>
                            </div>
                            <Text as="span" className="block text-xs">
                                {item.role.id} - {item.role.text}
                            </Text>
                        </div>
                    ),
                    className: 'whitespace-nowrap',
                },
            ],
        }));
    }, [data.company?.members]);

    return (
        <div className="p-6">
            <div className="flex flex-col sm:flex-row items-stretch gap-4 mb-4">
                <div className="basis-80 sm:max-w-80 border border-gray-200 rounded-2xl p-4">
                    <Text as="h4" className="text-heading font-semibold mb-4 !text-lg">
                        Cadastro Empresarial
                    </Text>
                    <div className="flex flex-row sm:flex-col gap-4 flex-wrap">
                        <ProviderConsultingIconContent icon={{ name: SvgIcoReadMail }} content={data.company?.name} title="Razão Social" />
                        <ProviderConsultingIconContent icon={{ name: SvgIcoChart }} content={data.company?.size?.text} title="Porte" />
                        <ProviderConsultingIconContent icon={{ name: SvgIcoDollar }} content={formatMoney(data.company?.equity)} title="Capital Social" />
                        <ProviderConsultingIconContent icon={{ name: SvgIcoContacts }} content={data.company?.nature?.text} title="Natureza Jurídica" />
                    </div>
                </div>

                <div className="flex-1 border border-gray-200 rounded-2xl p-4">
                    <Text as="h4" className="text-heading font-semibold mb-4 !text-lg">
                        Dados do Estabelecimento
                    </Text>
                    <div className="grid md:grid-cols-2 gap-4">
                        <ProviderConsultingIconContent icon={{ name: SvgIcoTravel }} content={formatCNPJ(data.taxId)} title="CNPJ" />
                        {Boolean(data.head) && <ProviderConsultingIconContent icon={{ name: SvgIcoPushPin }} content="Matriz" title="Tipo" />}
                        <ProviderConsultingIconContent icon={{ name: SvgIcoCalendar }} content={formatDate(data.founded)} title="Data de Abertura" />
                        <ProviderConsultingIconContent icon={{ name: SvgIcoTag }} content={data.alias || data.company?.name} title="Nome Fantasia" />
                        <ProviderConsultingIconContent
                            icon={{
                                name: data.status?.id === ProviderConsultingStatus.Active ? SvgIcoTasksCheck : SvgIcoCloseCircle,
                                color: data.status?.id === ProviderConsultingStatus.Active ? 'text-system-success-500' : 'text-system-danger-500',
                            }}
                            content={
                                <>
                                    <span className="block">
                                        {data.status.text} - Desde {formatDate(data.statusDate)}
                                    </span>
                                    {!!data.reason && <span>{data.reason.text}</span>}
                                </>
                            }
                            title="Situação Cadastral"
                        />
                        <ProviderConsultingIconContent
                            icon={{ name: SvgIcoPhone }}
                            content={data.phones.map((item) => formatPhoneNumber(item.area + item.number)).join(', ') || '-'}
                            title="Telefones"
                        />
                        <ProviderConsultingIconContent icon={{ name: SvgIcoMail }} content={data.emails.map((item) => item.address).join(', ') || '-'} title="E-mail" />
                        <ProviderConsultingIconContent icon={{ name: SvgIcoMap }} content={data.address?.municipality?.toString()} title="Município IBGE" />
                        <ProviderConsultingIconContent
                            icon={{ name: SvgIcoPin }}
                            className="max-w-[280px] block"
                            content={generateAddress({
                                street: data.address?.street,
                                city: {
                                    name: data.address?.city,
                                },
                                number: `${data.address?.number || 'S/N'} ${Boolean(data.address?.details) ? '- ' + data.address.details : ''}`,
                                zip: data.address?.zip,
                                neighbourhood: data.address?.district,
                                state: {
                                    initials: data.address?.state,
                                },
                            } as any)}
                            title="Endereço"
                        />
                    </div>
                </div>
            </div>

            <div className="border border-gray-200 rounded-2xl p-4 mb-4">
                <Text as="h4" className="text-heading font-semibold mb-4 !text-lg">
                    Atividades Econômicas
                </Text>
                {Boolean(activitiesItems.length) ? (
                    <SimpleTable containerClassName="max-h-[360px] overflow-auto" className="-mx-4" columns={['Tipo', 'Código', 'Descrição']} items={activitiesItems} />
                ) : (
                    <Empty title="Não há atividades a serem mostradas." />
                )}
            </div>

            <div className="border border-gray-200 rounded-2xl p-4">
                <Text as="h4" className="text-heading font-semibold mb-4 !text-lg">
                    Sócios e Administradores
                </Text>
                {Boolean(membersItems?.length) ? (
                    <SimpleTable containerClassName="max-h-[360px] overflow-auto" className="-mx-4" columns={['Tipo', 'Entrada', 'Sócio']} items={membersItems!} />
                ) : (
                    <Empty title="Não há membros a serem mostrados." />
                )}
            </div>
        </div>
    );
};

export default ModalProviderConsultingContent;
