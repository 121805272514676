import Spinner from 'components/core/spinner';
import Empty from 'components/empty';
import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import { memo, useMemo, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import useBreadcrumb from 'store/breadcrumb/use-breadcrumb';
import Tabs from '@mui/material/Tabs/Tabs';
import Tab from '@mui/material/Tab/Tab';
import { useTabRoute, UseTabRouteChildren } from 'hooks/tab/use-tab-route';
import SaleOrderActions from './actions';
import useGetSaleOrder from 'services/queries/sales-order/use-get-sale-order';
import { SaleOrderOrigin } from 'types/models/sale-order';
import useDelete from 'services/queries/crud/use-delete';
import ConfirmModal from 'components/core/modal/confirm';

const formatSubpage = (item) => ({ label: item.title, path: item.link });

const subpagesToExclude = ['sale-order-expense', 'sale-order-revenues', 'sale-order-schedule', 'sale-order-financial-balance'];

const SaleOrderPage = ({ page }: WithPagePermissionsProps) => {
    const navigate = useNavigate();
    const { saleOrderId, orderServiceId } = useParams();

    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

    const { data: saleOrder, isLoading, isError } = useGetSaleOrder(saleOrderId);

    const { mutateAsync: cancelSaleOrder, isLoading: isDeletingSaleOrder } = useDelete({
        apiPayload: {
            endpoint: '/sale-orders',
            useApiTodelete: true,
        },
    });

    const nav = useMemo<UseTabRouteChildren[]>(() => {
        const subpages = page?.subpages || [];

        const isSale = saleOrder?.origin === SaleOrderOrigin.sale;

        if (isSale) {
            return subpages.filter((item) => item.key !== 'sale-order-service-order').map(formatSubpage);
        }

        return subpages.filter((item) => !subpagesToExclude.includes(item.key)).map(formatSubpage);
    }, [page, saleOrder?.origin]);

    const { handleChangeTab, activeTabIndex, tabLabels } = useTabRoute(nav);

    useBreadcrumb(saleOrder?.code);

    const handleCancelSaleOrder = async () => {
        try {
            if (!!saleOrderId) {
                await cancelSaleOrder({ id: Number(saleOrderId) });

                navigate('/app/pedidos-de-venda');
            }
        } catch (error) {
            console.log('handleCancelSaleOrder', error);
        }
    };

    if (isLoading) {
        return (
            <div className="relative w-full h-full">
                <Spinner />
            </div>
        );
    }

    if (isError || !saleOrder) {
        return <Empty title="Pedido de venda não encontrado" />;
    }

    return (
        <>
            <div className="bg-base-100 flex relative -m-4 h-[calc(100%+48px)] overflow-hidden sm:flex-row sm:-ml-6 sm:-mr-4 sm:-mb-6 sm:-mt-[17px] md:-mt-[25px]">
                <section className="bg-base-200 overflow-y-auto relative w-full sm:h-full pb-[52px] sm:pb-0">
                    {!Boolean(orderServiceId) && (
                        <div className="bg-base-100 mb-6">
                            <Tabs variant="scrollable" value={activeTabIndex} onChange={handleChangeTab}>
                                {tabLabels.map((label) => (
                                    <Tab label={label} key={label} className="text-base py-5" />
                                ))}
                            </Tabs>
                        </div>
                    )}
                    <div className="px-6 h-full">
                        <Outlet context={saleOrder} />
                        {!Boolean(orderServiceId) && <SaleOrderActions onCancelSaleOrder={setIsConfirmModalOpen.bind(this, true)} />}
                    </div>
                </section>
            </div>
            {isConfirmModalOpen && (
                <ConfirmModal
                    title="Cancelar pedido de venda"
                    description="Você tem certeza que deseja cancelar este pedido de venda?"
                    isLoading={isDeletingSaleOrder}
                    onClose={setIsConfirmModalOpen.bind(this, false)}
                    onConfirmAction={handleCancelSaleOrder}
                />
            )}
        </>
    );
};

export default memo(SaleOrderPage);
