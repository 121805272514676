import useTheme from '@mui/material/styles/useTheme';
import Button from 'components/core/button';
import DatePicker from 'components/core/datepicker';
import ExpenseForm, { ExpenseFormConfig, ExpenseFormPayload } from 'components/forms/expense';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { ClassificationCategoryCode, ClassificationSectorCode, ClassificationType } from 'types/models/classification';
import { useSaleOrderContext } from '../context';
import { Steps } from '../context/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { expenseFormSchema } from 'components/forms/expense/utils';
import { number, string } from 'yup';
import dictionary from 'utils/dictionary';
import CurrencyInput from 'components/core/form/currency';
import Text from 'components/core/text';
import Textarea from 'components/core/form/textarea';
import { useEffect } from 'react';
import SvgIcoArrowLeft from 'components/core/icon/files/ico-arrow-left';
import SvgIcoArrowRight from 'components/core/icon/files/ico-arrow-right';

export type SaleOrderExpensePayload = {
    dueDate: string;
    title: string;
    value: number;
} & ExpenseFormPayload;

const schema = expenseFormSchema.shape({
    dueDate: string().required(dictionary.validation.required),
    title: string().required(dictionary.validation.required),
    value: number().required(dictionary.validation.required),
});

const Expense = () => {
    const { palette } = useTheme();
    const { state, goToStep, setContent, completeStep } = useSaleOrderContext();

    const methods = useForm<SaleOrderExpensePayload>({
        mode: 'onSubmit',
        defaultValues: {
            dueDate: '',
            title: '',
        },
        resolver: yupResolver(schema) as any,
    });

    const { control, handleSubmit, formState, watch, reset, getValues } = methods;

    const [sector, category, origin] = watch(['sector', 'category', 'classificationOrigin']);

    const expenseConfig: ExpenseFormConfig = {
        category: {
            type: ClassificationType.TransactionCategory,
            where: {
                code: { _eq: ClassificationCategoryCode.DecorativeExpense.toString() },
                classificationId: { _eq: sector?.value },
            },
            isEnabled: !!sector,
            isStaticField: true,
        },
        origin: {
            type: ClassificationType.TransactionOrigin,
            where: {
                classificationId: { _eq: category?.value },
            },
            isEnabled: !!category,
        },
        sector: {
            type: ClassificationType.Sector,
            where: {
                code: { _eq: ClassificationSectorCode.Commercial.toString() },
            },
            isEnabled: true,
            isStaticField: true,
        },
        subOrigin: {
            type: ClassificationType.TransactionSubOrigin,
            where: { classificationId: { _eq: origin?.value } },
            isEnabled: !!origin,
        },
        favored: {
            fieldLabel: 'Favorecido',
            isConsultant: false,
        },
    };

    const handleBack = () => {
        goToStep('previous');

        setContent({ expense: getValues() });
    };

    const submit = async (data: SaleOrderExpensePayload) => {
        try {
            setContent({ expense: data });
            completeStep(Steps.Expense);
            goToStep('next');
        } catch (error) {
            console.log('submit', error);
        }
    };

    useEffect(() => {
        if (Boolean(state.content.expense)) {
            reset(state.content.expense);
        }
    }, [state.content.expense, reset]);

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(submit)}>
                <div className="grid md:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-4 p-6">
                    <ExpenseForm config={expenseConfig} />
                    <Controller
                        control={control}
                        name="dueDate"
                        render={({ field }) => {
                            return (
                                <DatePicker
                                    inputProps={{
                                        label: 'Data de vencimento',
                                        error: formState.errors.dueDate?.message,
                                        placeholderText: 'Ex: 00/00/0000',
                                        ...field,
                                    }}
                                />
                            );
                        }}
                    />
                    <Controller
                        control={control}
                        name="value"
                        render={({ field }) => {
                            return (
                                <CurrencyInput
                                    left={
                                        <Text as="span" variant="body.medium.sm" className="text-heading">
                                            R$
                                        </Text>
                                    }
                                    label="Valor"
                                    placeholder="Ex: 1000"
                                    error={formState.errors.value?.message}
                                    value={field.value}
                                    onValueChange={({ floatValue }) => field.onChange(floatValue)}
                                />
                            );
                        }}
                    />
                    <Controller
                        control={control}
                        name="title"
                        render={({ field }) => {
                            return <Textarea {...field} label="Descrição" error={formState.errors.title?.message} parentClassName="sm:col-span-2 md:col-span-4" />;
                        }}
                    />
                </div>
                <div className="border-t p-2 flex justify-between">
                    <Button startIcon={<SvgIcoArrowLeft width={18} color={palette.grey[700]} />} type="button" variant="text" color="inherit" className="min-w-[100px]" onClick={handleBack}>
                        Voltar
                    </Button>
                    <Button
                        disabled={!formState.isValid}
                        endIcon={<SvgIcoArrowRight width={18} color={!formState.isValid ? palette.grey[400] : palette.secondary[500]} />}
                        type="submit"
                        variant="text"
                        color={!formState.isValid ? 'inherit' : 'secondary'}
                        className="min-w-[100px]">
                        Avançar
                    </Button>
                </div>
            </form>
        </FormProvider>
    );
};

export default Expense;
