import TableCell from '@mui/material/TableCell/TableCell';
import ExternalButton from 'components/buttons/external';
import Badge from 'components/core/badge';
import { DatatableColumn } from 'types/graphql';

export const modalConfirmActionsContents = {
    approve: {
        title: 'Aprovar pedidos de compra',
        description: (
            <>
                Você tem certeza que deseja <strong className="text-system-success-500">aprovar</strong> estes pedidos de compra?
            </>
        ),
    },
    disapprove: {
        title: 'Reprovar pedidos de compra',
        description: (
            <>
                Você tem certeza que deseja <strong className="text-system-success-500">reprovar</strong> estes pedidos de compra?
            </>
        ),
    },
    cancel: {
        title: 'Cancelar pedidos de compra',
        description: (
            <>
                Você tem certeza que deseja <strong className="text-system-success-500">cancelar</strong> estes pedidos de compra?
            </>
        ),
    },
    delete: {
        title: 'Apagar pedidos de compra',
        description: (
            <>
                Você tem certeza que deseja <strong className="text-system-danger-500">apagar</strong> estes pedidos de compra?
            </>
        ),
    },
    entry: {
        title: 'Entrega direta',
        description: <>Você tem certeza que deseja fazer a entrega direta para estes produtos?</>,
    },
};

export const purchaseOrdersTableColumns = (setJustification): DatatableColumn[] => [
    {
        name: 'id',
        options: { display: 'excluded' },
    },
    {
        name: 'purchaseOrder.code',
        label: 'Pedido',
        options: {
            customBodyRender: (value = '-', { rowData }) => {
                return <ExternalButton to={`/app/pedidos-de-venda/${rowData[4]}/ordens-de-servico/${rowData[3]}/ordens-de-compra/${rowData[2]}`}>{value}</ExternalButton>;
            },
        },
    },
    {
        name: 'purchaseOrder.id',
        options: { display: 'excluded' },
    },
    {
        name: 'purchaseOrder.origin.orderService.id',
        options: { display: 'excluded' },
    },
    {
        name: 'purchaseOrder.origin.orderService.saleOrder.id',
        options: { display: 'excluded' },
    },
    {
        name: 'product.code',
        options: { display: 'excluded' },
    },
    {
        name: 'product.name',
        label: 'Produto',
        options: {
            customBodyRender: (value, { rowData }) => {
                const billed = rowData[18];
                const code = rowData[5];

                if (!value) {
                    return '-';
                }

                return (
                    <>
                        <span className="whitespace-nowrap !block">{`${value} (${code})`}</span>
                        {billed && <Badge variant="warning">Faturado</Badge>}
                    </>
                );
            },
        },
    },
    {
        name: 'purchaseOrder.origin.stock',
        options: { display: 'excluded' },
    },
    {
        name: 'purchaseOrder.origin.orderService.title',
        label: 'Obra/Estoque',
        options: {
            customBodyRender: (value, { rowData }) => {
                const name = value || rowData[7].name;

                const url = !!value ? `/app/pedidos-de-venda/${rowData[4]}/ordens-de-servico/${rowData[3]}` : `/app/estoques/${rowData[7].id}`;

                return <ExternalButton to={url}>{name}</ExternalButton>;
            },
        },
    },
    {
        name: 'expenseProduct.expense',
        label: 'Despesa',
        options: {
            sort: false,
            customBodyRender: (value) => {
                const billed = value?.billed;

                if (!value?.id) {
                    return '-';
                }

                return (
                    <>
                        <ExternalButton to={`/app/despesas/${value?.id?.toString()}`}>{value?.code}</ExternalButton>
                        {billed && <Badge variant="warning">Faturada</Badge>}
                    </>
                );
            },
        },
    },
    {
        name: 'orderServiceProductPurchase.balance',
        label: 'Saldo atual',
        options: { customBodyRender: (value = 0) => <span className="whitespace-nowrap">{value}</span> },
    },
    {
        name: 'quantity',
        label: 'Qtd solicitada',
        options: { customBodyRender: (value, { rowData }) => `${value} ${rowData[17]}` },
    },
    {
        name: 'orderServiceProductPurchase.quantity',
        label: 'Escopo inicial',
    },
    {
        name: 'totalApproved',
        label: 'Comprados',
    },
    {
        name: 'totalDemand',
        label: 'Pedidos não pagos',
    },
    {
        name: 'justification',
        label: 'Justificativa do pedido',
        options: {
            customBodyRender: (value?: string) => {
                if (!value) {
                    return '-';
                }

                return <ExternalButton onClick={() => setJustification(value)}>Visualizar</ExternalButton>;
            },
        },
    },
    {
        name: 'systemJustification',
        label: 'Erro',
        options: {
            customBodyRender: (value?: string) => {
                if (!value) {
                    return '-';
                }

                return <ExternalButton onClick={() => setJustification(value)}>Visualizar</ExternalButton>;
            },
        },
    },
    {
        name: 'product.unit.name',
        options: { display: 'excluded' },
    },
    {
        name: 'billed',
        options: { display: 'excluded' },
    },
    {
        name: 'actions',
        label: 'Ações',
        options: {
            customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />,
        },
    },
];
