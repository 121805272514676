import { jsonToGraphQLQuery } from 'json-to-graphql-query/lib/jsonToGraphQLQuery';
import { VariableType } from 'json-to-graphql-query/lib/types/VariableType';
import { pkColumnsKey } from 'utils/environment';

export const getCustomerOptionsKey = ['customer', 'options'];

const queries = {
    getCustomer: (fields: string) => `query GetCustomer($id: Int!) {
        customer: customerByPk(id: $id) {
            ${fields}
        }
    }`,
    createCustomerAddress: jsonToGraphQLQuery({
        mutation: {
            __name: 'CreateCustomerAddress',
            __variables: {
                payload: 'AddressCustomerInsertInput!',
            },
            item: {
                __aliasFor: 'insertAddressCustomerOne',
                __args: {
                    object: new VariableType('payload'),
                },
                id: true,
            },
        },
    }),
    createContact: jsonToGraphQLQuery({
        mutation: {
            __name: 'CreateCustomerContact',
            __variables: {
                payload: 'CustomerContactInsertInput!',
            },
            item: {
                __aliasFor: 'insertCustomerContactOne',
                __args: {
                    object: new VariableType('payload'),
                },
                id: true,
            },
        },
    }),
    editContact: jsonToGraphQLQuery({
        mutation: {
            __name: 'EditCustomerContactPerson',
            __variables: {
                id: 'Int!',
                payload: 'PersonSetInput!',
            },
            item: {
                __aliasFor: 'updatePersonByPk',
                __args: {
                    [pkColumnsKey]: {
                        id: new VariableType('id'),
                    },
                    _set: new VariableType('payload'),
                },
                id: true,
            },
        },
    }),

    createOrUpdateCustomer: (isEdit = false, hasAddressId = false) => {
        return jsonToGraphQLQuery(
            {
                mutation: {
                    __name: 'CreateOrUpdateCustomer',
                    __variables: {
                        ...(isEdit
                            ? {
                                  payload: 'CustomerSetInput!',
                                  id: 'Int!',
                                  ...(hasAddressId && {
                                      addressId: 'Int!',
                                      addressPayload: 'AddressSetInput!',
                                  }),
                              }
                            : {
                                  payload: 'CustomerInsertInput!',
                              }),
                    },
                    item: {
                        __aliasFor: isEdit ? 'updateCustomerByPk' : 'insertCustomerOne',
                        __args: {
                            ...(isEdit
                                ? {
                                      [pkColumnsKey]: { id: new VariableType('id') },
                                      _set: new VariableType('payload'),
                                  }
                                : {
                                      object: new VariableType('payload'),
                                  }),
                        },
                        id: true,
                        title: true,
                    },
                    ...(hasAddressId && {
                        updateAddressByPk: {
                            __args: {
                                [pkColumnsKey]: { id: new VariableType('addressId') },
                                _set: new VariableType('addressPayload'),
                            },
                            id: true,
                        },
                    }),
                },
            },
            { pretty: true }
        );
    },
};

export default queries;
