import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import { getExpenseKey } from '../expenses/keys';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';
import api from 'services/api';

export type EditTransactionPayload = {
    dueDate: string;
    companyBankAccount: number;
    paymentForm: number;
    fiscalDocument: string;
    netValue?: number;
    barCode?: string;
};

type Params = {
    expenseId?: number;
    transactionId?: number;
};

const useCreateOrEditTransaction = ({ expenseId, transactionId }: Params = {}) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const goToParentRoute = useNavigateToParentRoute();

    const request = async (payload: EditTransactionPayload) => {
        return api.patch(`/transactions/${transactionId}`, payload);
    };

    const isUpdate = !!transactionId;

    return useMutation(request, {
        onSuccess: () => {
            if (expenseId) {
                queryClient.invalidateQueries(getExpenseKey(String(expenseId)));
            }

            goToParentRoute();
            showToast(`Operação ${isUpdate ? 'atualizada' : 'criada'} com sucesso`, 'success');
        },
        onError: () => {
            showToast(`Ocorreu um erro ao ${isUpdate ? 'atualizar' : 'criar'} a operação`, 'danger');
        },
    });
};

export default useCreateOrEditTransaction;
