import { useEffect } from 'react';
import Select from 'components/core/form/select';
import { Controller, useForm } from 'react-hook-form';
import _omitBy from 'lodash/omitBy';
import { formatDate } from 'utils/date';
import { useNavigate } from 'react-router-dom';
import Switch from 'components/core/switch';
import Text from 'components/core/text';
import useGetCompanyOptions from 'services/queries/companies/use-get-company-options';
import ModalFilter from 'components/core/modal/filter';
import QueryString from 'qs';
import dayjs from 'dayjs';
import { Status } from 'types/general';
import { saleOrderOrigins } from 'utils/statics';
import FilterPeriod from 'pages/private/payment-order/filter/period';

type FormState = {
    nature: number;
    company: number;
    finalized: boolean;
    initialPeriodStartDate?: string;
    initialPeriodEndDate?: string;
    issWithheld?: boolean;
    status: Status;
};

type SalesNotesFilterProps = {
    onClose: () => void;
};

const statuses = [
    { label: 'Finalizado', value: true },
    { label: 'Não finalizado', value: false },
];

const SalesNotesFilter = ({ onClose }: SalesNotesFilterProps) => {
    const { data: companyOptions, isLoading } = useGetCompanyOptions();

    const navigate = useNavigate();

    const methods = useForm<FormState>({
        mode: 'onSubmit',
        defaultValues: { status: Status.Active },
    });

    const { control, formState, handleSubmit, reset } = methods;

    const closeFilter = (path = '/app/notas-de-venda') => {
        onClose();
        navigate(path);
    };

    const submit = (data: FormState) => {
        const newParams = {
            ...data,
            ...(data.initialPeriodStartDate && { initialPeriodStartDate: formatDate(data.initialPeriodStartDate, 'YYYYMMDD') }),
            ...(data.initialPeriodEndDate && { initialPeriodEndDate: formatDate(data.initialPeriodEndDate, 'YYYYMMDD') }),
        };

        const result = _omitBy(newParams, (value) => !value || value === '-');

        if (!Object.keys(result)) {
            return closeFilter();
        }

        const queryParams = QueryString.stringify(result);

        closeFilter(`/app/notas-de-venda?${queryParams}`);
    };

    useEffect(() => {
        if (!!window.location?.search) {
            const params: any = QueryString.parse(window.location?.search, { ignoreQueryPrefix: true });

            const newParams = {
                ...params,
                initialPeriodStartDate: params.initialPeriodStartDate ? dayjs(params.initialPeriodStartDate).toDate() : undefined,
                initialPeriodEndDate: params.initialPeriodEndDate ? dayjs(params.initialPeriodEndDate).toDate() : undefined,
            };

            const result = _omitBy(newParams, (value) => !value || value === '-');

            if (!!Object.keys(result)?.length) {
                reset(result);
            }
        }
    }, [reset]);

    return (
        <ModalFilter formMethods={methods} onClose={onClose} onSubmit={handleSubmit(submit)} onClear={() => closeFilter()}>
            <div className="gap-4 flex flex-col">
                <Controller
                    name="nature"
                    control={control}
                    render={({ field }) => {
                        const value = saleOrderOrigins.find((item) => item?.value === +field?.value);

                        return (
                            <Select
                                {...field}
                                value={value}
                                options={saleOrderOrigins}
                                label="Tipo"
                                placeholder="Selecione uma opção"
                                onChange={(option: any) => field.onChange(option?.value)}
                                isClearable={true}
                            />
                        );
                    }}
                />
                <Controller
                    name="finalized"
                    control={control}
                    render={({ field }) => {
                        const value = statuses.find((item) => item?.value?.toString() === field?.value?.toString());

                        return (
                            <Select
                                {...field}
                                value={value}
                                options={statuses}
                                label="Status"
                                placeholder="Selecione uma opção"
                                onChange={(option: any) => {
                                    field.onChange(option?.value.toString());
                                }}
                                isClearable={true}
                            />
                        );
                    }}
                />
                <Controller
                    name="company"
                    control={control}
                    render={({ field }) => {
                        const value = companyOptions?.find((item) => item.value === +field.value);

                        return (
                            <Select
                                {...field}
                                value={value}
                                options={companyOptions}
                                label="Empresa"
                                isLoading={isLoading}
                                placeholder="Selecione uma opção"
                                error={formState.errors.company?.message}
                                onChange={(option: any) => field.onChange(option?.value)}
                                isClearable={true}
                            />
                        );
                    }}
                />
                <FilterPeriod
                    control={control}
                    label="Período de Emissão"
                    firstInput={{
                        inputProps: {
                            label: 'Data inicial',
                            name: 'initialPeriodStartDate',
                            error: formState.errors.initialPeriodStartDate?.message,
                        },
                    }}
                    secondInput={{
                        inputProps: {
                            label: 'Data final',
                            name: 'initialPeriodEndDate',
                            error: formState.errors.initialPeriodEndDate?.message,
                        },
                    }}
                />
                <div>
                    <Text as="p" className="text-base-500 mb-2 block">
                        ISS Retido
                    </Text>
                    <Controller name="issWithheld" control={control} defaultValue={false} render={({ field }) => <Switch value={field?.value!} onChange={() => field.onChange(!field?.value)} />} />
                </div>
            </div>
        </ModalFilter>
    );
};

export default SalesNotesFilter;
