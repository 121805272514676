import { useQuery } from 'react-query';
import apiGraphQl from 'services/graphql';
import { getAllSalesReceiptsSumsKeys } from './keys';
import { generateQuery } from 'utils/query';

export type SumsTaxes = {
    cofins: number;
    cpp: number;
    csll: number;
    das: number;
    deductionValue: number;
    icms: number;
    inss: number;
    invoiceValue: number;
    irpj: number;
    irpjAdditional: number;
    iss: number;
    pis: number;
    totalDeductionValue: number;
};

export type WithheldsSumsTaxes = {
    issWithheld: boolean;
    inssWithheld: boolean;
    iss: number;
    inss: number;
};

type Response = {
    salesReceiptAggregate: {
        aggregate: { sum: SumsTaxes };
        nodes: WithheldsSumsTaxes[];
    };
};

const useGetSalesReceiptsSums = (where: {}) => {
    const request = async () => {
        const query = generateQuery({
            name: 'GetSalesReceiptsSums',
            table: 'salesReceiptAggregate',
            fields: [
                'aggregate.sum.deductionValue',
                'aggregate.sum.totalDeductionValue',
                'aggregate.sum.invoiceValue',
                'aggregate.sum.das',
                'aggregate.sum.irpj',
                'aggregate.sum.irpjAdditional',
                'aggregate.sum.csll',
                'aggregate.sum.cofins',
                'aggregate.sum.pis',
                'aggregate.sum.inss',
                'aggregate.sum.cpp',
                'aggregate.sum.iss',
                'aggregate.sum.icms',
                'nodes.issWithheld',
                'nodes.inssWithheld',
                'nodes.iss',
                'nodes.inss',
            ],
            orderByKey: 'createdAt',
            where,
        });

        const { salesReceiptAggregate } = await apiGraphQl<Response>(query);

        return {
            sums: salesReceiptAggregate?.aggregate?.sum,
            withhelds: salesReceiptAggregate?.nodes,
        };
    };

    return useQuery(getAllSalesReceiptsSumsKeys(where), request, { enabled: !!where });
};

export default useGetSalesReceiptsSums;
