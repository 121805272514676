import Spinner from 'components/core/spinner';
import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import ListPage from 'pages/shared/crud/list';
import { useOutletContext } from 'react-router-dom';
import { Provider } from 'types/models/provider';
import { providerContactsListConfig } from '../utils';

const ProviderContacts = ({ page }: WithPagePermissionsProps) => {
    const { id } = useOutletContext<Partial<Provider>>();

    const listConfig = providerContactsListConfig(id);

    if (!id) {
        return (
            <div className="relative w-full h-full">
                <Spinner />
            </div>
        );
    }

    return <ListPage page={page} {...listConfig} />;
};

export default ProviderContacts;
