import { useMutation } from 'react-query';
import apiGraphQl from 'services/graphql';
import { VariableType, jsonToGraphQLQuery } from 'json-to-graphql-query';
import api from 'services/api';
import { Status } from 'types/general';
import { affectedRowsKey } from 'utils/environment';

type Payload = Array<{
    where: {
        id: { _eq: number };
    };
    _set: { status: Status };
}>;

const deleteManyProductsQuery = (name?: string, payloadType?: string, mutation?: string) => {
    return jsonToGraphQLQuery(
        {
            mutation: {
                __name: name,
                __variables: { payload: payloadType },
                [mutation || '']: {
                    __args: { updates: new VariableType('payload') },
                    [affectedRowsKey]: true,
                },
            },
        },
        { pretty: true }
    );
};

const useDeleteMany = (name?: string, payloadType?: string, mutation?: string) => {
    const request = async (payload: Payload | string) => {
        if (typeof payload === 'string') {
            return api.delete(payload);
        }

        return apiGraphQl(deleteManyProductsQuery(name, payloadType, mutation), { payload });
    };

    return useMutation(request);
};

export default useDeleteMany;
