import { StylesConfig } from 'react-select';
import theme from 'settings/theme';
import { hexToRgba } from 'utils/string';

const getBorderColor = (isFocused, hasError) => {
    if (hasError) {
        return `${theme.extend.colors.system.danger[500]} !important`;
    }

    return isFocused ? theme.extend.colors.primary[200] : theme.extend.colors.base[300];
};

const selectStyles = (size: 'small' | 'medium', hasError: boolean): StylesConfig => {
    const isMedium = size === 'medium';

    return {
        indicatorSeparator: () => ({ display: 'none' }),
        indicatorsContainer: (base) =>
            ({
                ...base,
                padding: 0,
            } as any),
        control: (base, { isFocused }) =>
            ({
                ...base,
                borderColor: getBorderColor(isFocused, hasError),
                borderRadius: '14px',
                boxShadow: 'none',
                cursor: 'pointer',
                padding: '4px 16px',
                flex: 1,
                minHeight: isMedium ? 48 : 40,
                transition: 'border-color .2s ease',
                ':hover': {
                    borderColor: theme.extend.colors.base[400],
                },
            } as any),
        placeholder: (base) =>
            ({
                ...base,
                color: theme.extend.colors.base[400],
                fontSize: '0.875rem',
                lineHeight: 1,
                margin: 0,
            } as any),
        option: () =>
            ({
                borderRadius: 14,
                color: theme.extend.colors.heading,
                cursor: 'pointer',
                fontSize: '0.875rem',
                padding: '8px 14px',
                transition: 'background .2s ease',
                ':hover': {
                    backgroundColor: theme.extend.colors.base[200],
                },
            } as any),
        menu: (base) =>
            ({
                ...base,
                backgroundColor: theme.extend.colors.base[100],
                borderRadius: 14,
                boxShadow: 'unset',
                margin: 0,
                marginTop: '4px !important',
                filter: 'drop-shadow(0px 20px 50px rgba(44, 63, 88, 0.15))',
                zIndex: 10,
            } as any),
        menuList: (base) =>
            ({
                ...base,
                padding: 12,
            } as any),
        valueContainer: (base) =>
            ({
                ...base,
                padding: 0,
                marginRight: 8,
            } as any),
        menuPortal: (base) =>
            ({
                ...base,
                zIndex: 9999,
            } as any),
        noOptionsMessage: (base) =>
            ({
                ...base,
                color: theme.extend.colors.base[400],
                fontSize: '0.875rem',
            } as any),
        multiValue: (base) =>
            ({
                ...base,
                backgroundColor: hexToRgba(theme.extend.colors.primary[100], 0.3),
                color: theme.extend.colors.primary[500],
                borderRadius: 8,
                zIndex: 19999,
            } as any),
        multiValueLabel: (base) =>
            ({
                ...base,
                color: theme.extend.colors.primary[500],
            } as any),
        multiValueRemove: (base) =>
            ({
                ...base,
                borderRadius: '0 8px 8px 0',
            } as any),
        singleValue: (base) =>
            ({
                ...base,
                color: theme.extend.colors.heading,
                fontSize: '0.875rem',
            } as any),
    };
};

export default selectStyles;
