import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import isPlainObject from 'lodash/isPlainObject';

export const removeFalsyValues = (objectData: object): any => {
    const cleanObject = (obj: object) => {
        return Object.entries(obj).reduce(
            (result, [key, value]) => {
                const cleanedValue = isPlainObject(value) ? cleanObject(value) : value;

                if (Boolean(value) && (!Array.isArray(cleanedValue) || Boolean(cleanedValue.length)) && (!isPlainObject(cleanedValue) || !isEmpty(cleanedValue))) {
                    result[key] = cleanedValue;
                }

                return result;
            },
            Array.isArray(objectData) ? [] : {}
        );
    };

    return cleanObject(objectData);
};

export const flattenObject = <T>(object: T, initialPathPrefix = '') => {
    if (!object || typeof object !== 'object') {
        return [{ [initialPathPrefix]: object }];
    }

    const prefix = initialPathPrefix ? (Array.isArray(object) ? initialPathPrefix : `${initialPathPrefix}.`) : '';

    return Object.keys(object)
        .flatMap((key) => flattenObject(object[key], Array.isArray(object) ? `${prefix}[${key}]` : `${prefix}${key}`))
        .reduce((acc, path) => ({ ...acc, ...path }));
};

export const removeEmptyValues = (obj) => pickBy(obj, (value) => !isEmpty(value));
