import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import Button from 'components/core/button';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { modalConfirmActionsContents } from './utils';

type ModalConfirmActionProps = {
    action: 'approve' | 'disapprove' | 'cancel' | 'delete' | 'entry';
    isSubmitting: boolean;
    items?: any[];
    onSubmit: () => void;
    onClose: () => void;
};

const dangerActions = ['delete', 'cancel'];

const ModalConfirmAction = ({ action, isSubmitting, items = [], onClose, onSubmit }: ModalConfirmActionProps) => {
    return (
        <Modal
            contentClassnames="min-w-[650px]"
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    {modalConfirmActionsContents[action || 'disapprove'].title}
                </Text>
            }
            closeOnClickOutside={false}
            onClose={onClose}>
            <div className="p-6 pt-0">
                <Text as="p" className="text-base-500 mb-3 text-base block">
                    {modalConfirmActionsContents[action || 'disapprove'].description}
                </Text>
                <Table className="mb-6">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Text as="span" className="text-base-500">
                                    Pedido
                                </Text>
                            </TableCell>
                            <TableCell>
                                <Text as="span" className="text-base-500">
                                    Produto
                                </Text>
                            </TableCell>
                            <TableCell>
                                <Text as="span" className="text-base-500">
                                    Quantidade
                                </Text>
                            </TableCell>
                            <TableCell>
                                <Text as="span" className="text-base-500">
                                    Origem
                                </Text>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((item) => (
                            <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell>
                                    <Text as="span" className="text-base-500">
                                        {item.purchaseOrder?.code}
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text as="span" className="text-base-500">
                                        {item.product?.code} - {item.product?.name}
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text as="span" className="text-base-500">
                                        {item.quantity || 0}
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text as="span" className="text-base-500">
                                        {item.purchaseOrder?.origin?.orderService?.title || item.purchaseOrder?.origin?.stock?.name}
                                    </Text>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <div className="flex items-center">
                    <Button
                        disabled={isSubmitting}
                        loading={isSubmitting}
                        type="submit"
                        variant="contained"
                        color={dangerActions.includes(action) ? 'error' : 'success'}
                        className="min-w-[100px] mr-4 !text-white"
                        onClick={onSubmit}>
                        Confirmar
                    </Button>
                    <Button disabled={isSubmitting} color="inherit" className="min-w-[100px]" variant="outlined" onClick={onClose}>
                        Cancelar
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ModalConfirmAction;
