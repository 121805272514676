export const reorderArray = (arr, startIndex, endIndex) => {
    const [removed] = arr.splice(startIndex, 1);

    arr.splice(endIndex, 0, removed);

    return arr;
};

export const convertArrayToObject = (arr: string[], obj = {}) => {
    arr.forEach((item) => {
        const keys = item.split('.');
        keys.reduce((currObj, key, i) => {
            if (!currObj.hasOwnProperty(key)) {
                currObj[key] = i === keys.length - 1 ? true : {};
            }

            return currObj[key];
        }, obj);
    });

    return obj;
};

export const arrayMove = <T>(array: readonly T[], from: number, to: number) => {
    const slicedArray = array.slice();

    slicedArray.splice(to < 0 ? array.length + to : to, 0, slicedArray.splice(from, 1)[0]);

    return slicedArray;
};
