import { useQuery } from 'react-query';
import apiGraphQl from 'services/graphql';
import { getReceiptsByIdsKey } from './keys';

const useGetReceiptsBySaleOrdersId = (query: string, selectedsOrdersServiceIds: number[]) => {
    const request = async () => apiGraphQl(query).then(({ items }: any) => items);

    return useQuery(getReceiptsByIdsKey(selectedsOrdersServiceIds), request, { enabled: !!selectedsOrdersServiceIds.length });
};

export default useGetReceiptsBySaleOrdersId;
