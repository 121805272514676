import { useQuery } from 'react-query';
import api from 'services/api';
import qs from 'qs';
import useToast from 'hooks/toast/use-toast';
import { formatDate } from 'utils/date';
import { removeFalsyValues } from 'utils/object';
import { getTransactionsOutcomeKey } from './keys';
import { Pagination } from 'types/pagination';
import { Transaction } from 'types/models/transaction';

type Params = {
    endpoint: string;
    filters?: any;
    type?: string;
    enabled?: boolean;
};

const useGetTransactionsOutcome = <T = Pagination<Transaction>>({ filters: propFilters, endpoint, type, enabled = true }: Params) => {
    const { showToast } = useToast();

    const request = async () => {
        const { paid, invoiceStatus, billed, provisioned, ...restFilters } = propFilters || {};

        const filters = removeFalsyValues(restFilters);
        const { page, totalPerPage, orderBy, ...rest } = filters;

        const params = {
            filter: {
                ...rest,
                ...(paid !== 'all' && paid !== null && { paid }),
                ...(Boolean(rest?.provisionStartDate) && { provisionStartDate: formatDate(rest?.provisionStartDate, 'YYYY-MM-DD') }),
                ...(Boolean(rest?.provisionEndDate) && { provisionEndDate: formatDate(rest?.provisionEndDate, 'YYYY-MM-DD') }),
                ...(Boolean(rest?.startPayday) && { startPayday: formatDate(rest?.startPayday, 'YYYY-MM-DD') }),
                ...(Boolean(rest?.endPayday) && { endPayday: formatDate(rest?.endPayday, 'YYYY-MM-DD') }),
                ...(Boolean(rest?.startDueDate) && { startDueDate: formatDate(rest?.startDueDate, 'YYYY-MM-DD') }),
                ...(Boolean(rest?.endDueDate) && { endDueDate: formatDate(rest?.endDueDate, 'YYYY-MM-DD') }),
                ...(invoiceStatus !== null && { invoiceStatus }),
                ...(provisioned !== null && { provisioned }),
                ...(billed !== null && { billed }),
            },
            ...(Boolean(page) && { page }),
            ...(Boolean(totalPerPage) && { totalPerPage }),
            ...(Boolean(orderBy) && { orderBy }),
            ...(Boolean(type) && { type }),
        };

        const query = qs.stringify(params, { addQueryPrefix: true, encode: false, arrayFormat: 'brackets' });

        const { data } = await api.get<T>(endpoint + query);

        return data;
    };

    return useQuery(getTransactionsOutcomeKey(endpoint, propFilters, type), request, {
        enabled,
        onError: () => {
            showToast(`Ocorreu um erro ao obter a listagem. Tente novamente.`, 'danger');
        },
    });
};

export default useGetTransactionsOutcome;
