import qs from 'qs';
import { useQuery } from 'react-query';
import api from 'services/api';
import { getOrderServicesReportKey } from './keys';
import { GetOrderServicesBalanceTotalReponse } from '../order-services/use-get-order-services-balance-total';
import { OrderServicesReportFiltersPayload } from 'types/models/reports';
import { GenericReportFilterPayload } from 'pages/private/reports/screens/types';

const useGetOrderServicesReport = (filters?: GenericReportFilterPayload<OrderServicesReportFiltersPayload>) => {
    const queryFilters = qs.stringify(filters, { addQueryPrefix: true, encode: false, arrayFormat: 'brackets', skipNulls: true });

    const request = async () => {
        const { data } = await api.get<GetOrderServicesBalanceTotalReponse[]>('/reports/order-services' + queryFilters);
        return data;
    };

    return useQuery(getOrderServicesReportKey(queryFilters), request);
};

export default useGetOrderServicesReport;
