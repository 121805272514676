import apiGraphQl from 'services/graphql';
import { getDeductionsTotalKey, getDeductionsTotalQuery } from './keys';
import { useQuery } from 'react-query';

type DeductionTotalsResponse = {
    totals: {
        aggregate: {
            sum: {
                value: number;
            };
        };
    };
};

export const useGetDeductionTotals = (saleOrderId?: string, orderServiceId?: string, isDeducted = false) => {
    const request = async () => {
        const { totals } = await apiGraphQl<DeductionTotalsResponse>(getDeductionsTotalQuery(Number(saleOrderId), Number(orderServiceId), isDeducted));

        return totals;
    };

    return useQuery(getDeductionsTotalKey(saleOrderId, orderServiceId, isDeducted), request, {
        enabled: Boolean(saleOrderId) || Boolean(orderServiceId),
    });
};
