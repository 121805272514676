import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import { CreateTransferBetweenAccountsPayload } from 'types/models/bank-accounts';
import { getBankAccountByIdKey, getBankAccountTransactionsKey } from './keys';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';

const useCreateTransferBankAccount = (queryFilters: string, bankAccountId?: string) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const goToParentRoute = useNavigateToParentRoute();

    const request = (payload: CreateTransferBetweenAccountsPayload) => api.post(`company-bank-accounts/${bankAccountId}/transactions/transfer`, payload);

    return useMutation(request, {
        onSuccess: () => {
            showToast('Transferência entre contas criada com sucesso', 'success');

            queryClient.invalidateQueries(getBankAccountByIdKey(Number(bankAccountId)));
            queryClient.invalidateQueries(getBankAccountTransactionsKey(Number(bankAccountId), queryFilters));

            goToParentRoute();
        },
        onError: () => {
            showToast('Houve um erro ao criar uma transferência entre contas', 'danger');
        },
    });
};

export default useCreateTransferBankAccount;
