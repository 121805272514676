import { useQuery } from 'react-query';
import apiGraphQl from 'services/graphql';
import { Option } from 'types/general';

const useGetOptions = (query: string, key: any[], enabled?: boolean, serializer?: (data: any) => Option[]) => {
    const request = () => apiGraphQl<{ items: Option[] }>(query).then(({ items = [] }) => serializer?.(items) || items);

    return useQuery(key, request, { enabled });
};

export default useGetOptions;
