import Button from 'components/core/button';
import { useCallback, useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Steps } from '../../context/types';
import isEmpty from 'lodash/isEmpty';
import { useImportFlowContext } from '../../context';
import { useTheme } from '@mui/material/styles';
import CurrencyInput from 'components/core/form/currency';
import FavoredSelect from 'components/favored-select';
import { Option } from 'types/general';
import get from 'lodash/get';
import Text from 'components/core/text';
import useCreateOrUpdateImportFlow from 'services/queries/import-flow/use-create-or-update-import-flow';
import DatePicker from 'components/core/datepicker';
import { excludeDates } from 'pages/private/payment-order/actions/ops-grouping/general';
import { formatDate } from 'utils/date';
import { useOutletContext } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { importFlowExpensesTabSchema } from '../utils';
import SvgIcoArrowLeft from 'components/core/icon/files/ico-arrow-left';

const Expenses = () => {
    const { palette } = useTheme();
    const { importFlowListFilters } = useOutletContext<any>() || {};
    const { goToStep, state, completeStep, setContent } = useImportFlowContext();

    const { mutateAsync: createOrUpdateImportFlow, isLoading: isSubmitting } = useCreateOrUpdateImportFlow(importFlowListFilters);

    const total = (state.content?.products || [])?.reduce((acc, curr) => {
        acc += (curr.quantity || 0) * (curr.complementaryValue || 0);

        return acc;
    }, 0);

    const methods = useForm<any>({
        mode: 'all',
        reValidateMode: 'onChange',
        criteriaMode: 'all',
        resolver: yupResolver(importFlowExpensesTabSchema),
    });

    const { formState, handleSubmit, control, reset, getValues } = methods;

    const handleBack = () => {
        setContent({ expense: getValues() });
        completeStep(Steps.Expenses);
        goToStep('previous');
    };

    const submit = async (data: any) => {
        try {
            const hasNoErrors = isEmpty(formState.errors);

            if (!hasNoErrors) {
                return;
            }

            const formFavoredCustomBrokerBaseType = data.formFavoredCustomBroker?.type;
            const formFavoredManufacturerBaseType = state.content?.general?.formFavoredManufacturer?.type;

            const payload = {
                ...state.content?.general,
                formFavoredManufacturer: {
                    [formFavoredManufacturerBaseType]: state.content?.general?.formFavoredManufacturer?.value,
                },
                formFavoredCustomBroker: {
                    [formFavoredCustomBrokerBaseType]: data?.formFavoredCustomBroker?.value,
                },
                products: state.content?.products?.map((item) => ({
                    product: item.product,
                    quantity: item.quantity,
                    baseValue: item.baseValue,
                    complementaryValue: item.complementaryValue,
                })),
                dueDateManufacturer: formatDate(state.content?.general?.dueDateManufacturer, 'YYYY-MM-DD'),
                provisionManufacturer: formatDate(state.content?.general?.provisionManufacturer, 'YYYY-MM-DD'),
                dueDateCustomBroker: formatDate(data.dueDateCustomBroker, 'YYYY-MM-DD'),
                provisionCustomBroker: formatDate(data.provisionCustomBroker, 'YYYY-MM-DD'),
            };

            await createOrUpdateImportFlow(payload);
        } catch (e) {
            console.error('sumit - expense: ', e);
        }
    };

    useEffect(() => {
        if (!!state.content?.expense?.formFavoredCustomBroker) {
            reset({ formFavoredCustomBroker: state.content.expense?.formFavoredCustomBroker });
        }
    }, [state, reset]);

    const getError = useCallback((name: string) => get(formState.errors, `${name}.message`) as any, [formState]);

    return (
        <form onSubmit={handleSubmit(submit)}>
            <FormProvider {...methods}>
                <div className="p-6 flex flex-col md:grid grid-cols-2 gap-4 mb-4">
                    <CurrencyInput
                        left={
                            <Text as="span" variant="body.medium.sm" className="text-heading">
                                R$
                            </Text>
                        }
                        disabled={true}
                        label="Total da Importação"
                        value={total}
                        decimalScale={2}
                        decimalSeparator="."
                        fixedDecimalScale={true}
                        placeholder="Ex: R$ 2.500,40"
                    />
                    <Controller
                        name="formFavoredCustomBroker"
                        control={control}
                        render={({ field }) => {
                            return (
                                <FavoredSelect
                                    customLabel="Despachante"
                                    hideActions={true}
                                    value={field.value}
                                    error={(formState?.errors as any)?.formFavoredCustomBroker?.value?.message}
                                    onChange={(option: Option) => field.onChange(option)}
                                />
                            );
                        }}
                    />
                    <Controller
                        name="dueDateCustomBroker"
                        control={control}
                        render={({ field }) => {
                            return (
                                <DatePicker
                                    inputProps={{
                                        label: 'Vencimento do despachante',
                                        placeholderText: 'Selecione uma data',
                                        error: getError('dueDateCustomBroker'),
                                        ...field,
                                    }}
                                />
                            );
                        }}
                    />
                    <Controller
                        name="provisionCustomBroker"
                        control={control}
                        render={({ field }) => {
                            return (
                                <DatePicker
                                    inputProps={{
                                        placeholderText: 'Selecione a data de previsão',
                                        label: 'Previsão Despachante',
                                        error: getError('provisionCustomBroker'),
                                        ...field,
                                    }}
                                    muiDatePickerProps={{
                                        shouldDisableDate: excludeDates,
                                    }}
                                    withProvisionLogic={true}
                                />
                            );
                        }}
                    />
                </div>
                <div className="border-t p-2 flex justify-between">
                    <Button startIcon={<SvgIcoArrowLeft width={18} color={palette.grey[700]} />} type="button" variant="text" color="inherit" className="min-w-[100px]" onClick={handleBack}>
                        Voltar
                    </Button>
                    <Button loading={isSubmitting} type="submit" variant="text" color="secondary" className="min-w-[100px]">
                        Finalizar
                    </Button>
                </div>
            </FormProvider>
        </form>
    );
};

export default Expenses;
