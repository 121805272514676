import Card from 'components/core/card';
import { useNavigate, useOutletContext } from 'react-router-dom';
import CardHeader from '@mui/material/CardHeader/CardHeader';
import Avatar from '@mui/material/Avatar/Avatar';
import { formatMoney } from 'utils/money';
import { hexToRgba } from 'utils/string';
import useTheme from '@mui/material/styles/useTheme';
import { Option } from 'types/general';
import { ExpenseDetailsResponse } from 'services/queries/expenses/types';
import { formatDate } from 'utils/date';
import Text from 'components/core/text';
import ExpenseSidebarActions from '../../actions';
import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import Favored from './favored';
import { useMemo, useState } from 'react';
import ConfirmModal from 'components/core/modal/confirm';
import useDelete from 'services/queries/crud/use-delete';
import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import Alert from '@mui/material/Alert';
import ExternalButton from 'components/buttons/external';
import ExpenseBadges from 'pages/private/payment-order/view/expense-badges';
import SvgIcoDollarSign from 'components/core/icon/files/ico-dollar-sign';

const ExpenseTabDetails = ({ page }: WithPagePermissionsProps) => {
    const { palette } = useTheme();
    const navigate = useNavigate();

    const data = useOutletContext<ExpenseDetailsResponse>();

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const { mutateAsync: deleteBankAccountMutate, isLoading: isCancelingExpense } = useDelete<any>({
        apiPayload: { endpoint: `/expenses`, useApiTodelete: true },
    });

    const totalPaid = useMemo(() => {
        return data?.transactions?.filter((item) => item.paid).reduce((acc, curr) => acc + curr.netValue, 0);
    }, [data.transactions]);

    const items = useMemo(() => {
        const itemsArr: Option<any>[] = [
            {
                value: data?.title,
                label: 'Descrição',
            },
            {
                value: data?.company?.name,
                label: 'Empresa',
            },
            {
                value: data?.branch?.name,
                label: 'Filial',
            },
            {
                value: data?.sector?.name,
                label: 'Setor',
            },
            {
                value: data?.category?.name,
                label: 'Categoria',
            },
            {
                value: data?.classificationOrigin?.name,
                label: 'Origem',
            },
            {
                value: data?.classificationSubOrigin?.name,
                label: 'Sub-origem',
            },
            {
                value: data?.paymentForm?.name,
                label: 'Forma de Pagamento',
            },
            {
                value: data?.instalments,
                label: 'Parcelas',
            },
            {
                value: data?.provisioned ? 'Sim' : 'Não',
                label: 'Provisionada',
            },
            {
                value: data?.fiscalCode?.name,
                label: 'CFOP',
            },
        ];

        if (Boolean(data?.expenseOrigin?.saleOrder?.id) && Boolean(data?.expenseOrigin?.orderService?.id)) {
            itemsArr.push({
                label: 'Ordem de Serviço',
                value: (
                    <ExternalButton href={`/app/pedidos-de-venda/${data.expenseOrigin.saleOrder.id}/ordens-de-servico/${data.expenseOrigin.orderService.id}`}>
                        {data.expenseOrigin.orderService.code}
                    </ExternalButton>
                ),
            });
        }

        return itemsArr;
    }, [data]);

    const handleCancelExpense = async () => {
        try {
            if (!!data?.id) {
                await deleteBankAccountMutate({ id: data.id });

                navigate('/app/despesas');
            }
        } catch (error) {
            console.log('handleCancelExpense', error);
        }
    };

    const decimalPlaces = data.transferBetweenStocks ? 4 : 2;

    return (
        <>
            <div>
                <div className="flex items-center  mb-6">
                    <Text as="h1" variant="h4" className="text-heading flex-1">
                        Despesa <span className="text-gray-500">#{data?.code}</span>
                    </Text>
                    <div className="flex items-center gap-2">
                        <ExpenseBadges approvalStatus={data.approvalStatus} billed={data.billed} edited={data.edited} payday={data.payday} provisioned={data.provisioned} />
                        <ExpenseSidebarActions rules={page?.rules} subpages={page?.subpages} id={data?.id} approvalStatus={data?.approvalStatus} onDelete={setIsDeleteModalOpen.bind(this, true)} />
                    </div>
                </div>
                {Boolean(data.reasonValueChange) && (
                    <Card className="mb-6">
                        <Alert severity="warning">
                            <Text as="p" className="mb-1">
                                Esta despesa sofreu alteração no valor com a seguinte justificativa:
                            </Text>
                            <Text className="italic whitespace-break-spaces">"{data.reasonValueChange}"</Text>
                        </Alert>
                    </Card>
                )}
                <div className="flex flex-col flex-1 md:grid grid-cols-2 gap-6 mb-6">
                    <Card className="p-4 flex items-center justify-center">
                        <CardHeader
                            avatar={
                                <Avatar sx={{ bgcolor: hexToRgba(palette.success[100], 0.2), borderRadius: '14px !important', width: 48, height: 48 }} aria-label="recipe">
                                    <SvgIcoDollarSign color={palette.success[500]} />
                                </Avatar>
                            }
                            classes={{ root: 'p-0 flex-col text-center', avatar: 'mr-0 mb-[10px]' }}
                            title={formatMoney(Number(data.value || 0), undefined, decimalPlaces)}
                            subheader="Valor total da despesa"
                            titleTypographyProps={{ color: palette.heading, fontSize: 32, fontWeight: 500 }}
                            subheaderTypographyProps={{ color: palette.grey[500] }}
                        />
                    </Card>
                    <Card className="p-4">
                        <CardHeader
                            avatar={
                                <Avatar sx={{ bgcolor: hexToRgba(palette.info[100], 0.2), borderRadius: '14px !important', width: 48, height: 48 }} aria-label="recipe">
                                    <SvgIcoDollarSign color={palette.info[500]} />
                                </Avatar>
                            }
                            classes={{ root: 'p-0 flex-col text-center', avatar: 'mr-0 mb-[10px]' }}
                            title={formatMoney(totalPaid, undefined, decimalPlaces)}
                            subheader="Valor total pago"
                            titleTypographyProps={{ color: palette.heading, fontSize: 32, fontWeight: 500 }}
                            subheaderTypographyProps={{ color: palette.grey[500] }}
                        />
                    </Card>
                </div>
                <Card className="overflow-x-auto mb-6">
                    <Table className="mb-4">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Text className="text-base-500 uppercase">Vencimento</Text>
                                </TableCell>
                                <TableCell>
                                    <Text className="text-base-500 uppercase">Pagamento</Text>
                                </TableCell>
                                <TableCell>
                                    <Text className="text-base-500 uppercase">Programação</Text>
                                </TableCell>
                                <TableCell>
                                    <Text className="text-base-500 uppercase">Cadastro</Text>
                                </TableCell>
                                <TableCell>
                                    <Text className="text-base-500 uppercase">Competência</Text>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Text className="text-heading font-normal">{formatDate(data?.dueDate)}</Text>
                                </TableCell>
                                <TableCell>
                                    <Text className="text-heading font-normal">{formatDate(data?.payday)}</Text>
                                </TableCell>
                                <TableCell>
                                    <Text className="text-heading font-normal">{formatDate(data?.provision)}</Text>
                                </TableCell>
                                <TableCell>
                                    <Text className="text-heading font-normal">{formatDate(data?.createdAt)}</Text>
                                </TableCell>
                                <TableCell>
                                    <Text className="text-heading font-normal">{String(data?.competenceMonth).padStart(2, '0') + '/' + data?.competenceYear}</Text>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Card>
                <div className="flex flex-col md:grid grid-cols-2 gap-6 mb-8">
                    <Card className="p-6">
                        <h3 className="text-heading text-xl font-medium mb-4">Detalhes</h3>
                        <div className="grid grid-cols-2 gap-4 mb-4">
                            {items.map(({ label, value }) => (
                                <div key={label} className="flex flex-col">
                                    <p className="mb-1" style={{ color: palette.grey[500] }}>
                                        {label}
                                    </p>
                                    <p className="text-sm break-words" style={{ color: palette.heading }}>
                                        {value || '-'}
                                    </p>
                                </div>
                            ))}
                        </div>
                        <div>
                            <Text className="text-gray-500 mb-1 block !text-base">Observações</Text>
                            <Text className="text-sm text-heading">{data.observations || '-'}</Text>
                        </div>
                    </Card>
                    <Favored favored={data?.favored} />
                </div>
            </div>
            {isDeleteModalOpen && (
                <ConfirmModal
                    title="Cancelar despesa"
                    description="Você tem certeza que deseja cancelar esta despesa?"
                    isLoading={isCancelingExpense}
                    onClose={setIsDeleteModalOpen.bind(this, false)}
                    onConfirmAction={handleCancelExpense}
                />
            )}
        </>
    );
};

export default ExpenseTabDetails;
