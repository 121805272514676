export const datatableColumnSort = (data: any[], colIndex: number, order: string) => {
    const sortedData = data.sort((a, b) => {
        const firstValue = a.data[colIndex];
        const secondValue = b.data[colIndex];

        const isNumeric = (value?: string | number) => !isNaN(Number(value)) && isFinite(Number(value));

        const isAsc = order === 'asc';

        if (isNumeric(firstValue) && isNumeric(secondValue)) {
            return isAsc ? Number(firstValue) - Number(secondValue) : Number(secondValue) - Number(firstValue);
        }

        return isAsc ? firstValue.localeCompare(secondValue) : secondValue.localeCompare(firstValue);
    });

    return sortedData;
};
