import React, { useMemo, useState } from 'react';
import Menu, { MenuItem } from 'components/menu';
import useTheme from '@mui/material/styles/useTheme';
import Button from 'components/core/button';
import { useNavigate } from 'react-router-dom';
import { ApprovalStatus } from 'types/general';
import { PermissionsProps, Rule } from 'types/permissions';
import { Page } from 'types/models/page';
import SvgIcoChevronDown from 'components/core/icon/files/ico-chevron-down';

type ExpenseSidebarActionsProps = {
    id?: number;
    approvalStatus?: ApprovalStatus;
    onDelete: () => void;
    rules?: PermissionsProps['rules'];
    subpages?: Page[];
};

const ExpenseSidebarActions = ({ id, approvalStatus, rules = [], subpages = [], onDelete }: ExpenseSidebarActionsProps) => {
    const { palette } = useTheme();
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleClose = () => setAnchorEl(null);
    const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

    const content = useMemo(() => {
        const hasUpdatePermission = rules.some((item) => item.key === Rule.Update);

        const arr: MenuItem[] = [];

        if (hasUpdatePermission) {
            arr.push({
                label: 'Editar',
                icon: { color: palette.grey[500], name: 'ico-edit' },
                onClick: () => navigate(`/app/despesas/${id}/editar`),
            });
        }

        const subpagesFiltered = subpages.filter((item) => item.rules.find((rule) => rule.key === Rule.Update));

        if (approvalStatus === ApprovalStatus.Waiting && Boolean(subpagesFiltered.length)) {
            const actions = subpagesFiltered.map((item) => ({
                label: item.title,
                icon: {
                    color: palette.grey[500],
                    name: item.link.includes('aprovar') ? 'ico-check' : 'ico-close',
                },
                onClick: () => navigate(item.link),
            }));

            arr.push(...actions);
        }

        return arr;
    }, [approvalStatus, rules, id, subpages, navigate, palette.grey]);

    const footerActions = useMemo(() => {
        const arr: MenuItem[] = [];
        const hasDeletePermission = rules.some((item) => item.key === Rule.Delete);

        if (hasDeletePermission) {
            arr.push({
                className: 'text-system-danger-500',
                label: 'Apagar',
                icon: { color: palette.error.main, name: 'ico-trash' },
                onClick: onDelete,
            });
        }

        return arr;
    }, [rules, palette.error.main, onDelete]);

    if (!Boolean(content.length) && !Boolean(footerActions.length)) {
        return null;
    }

    return (
        <>
            <Button type="button" variant="contained" color="primary" onClick={handleOpen} endIcon={<SvgIcoChevronDown width={13} color="#fff" />}>
                Ações
            </Button>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} content={content} footer={footerActions} />
        </>
    );
};

export default ExpenseSidebarActions;
