import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
import { getStocksPurchaseOrderKey } from './keys';

type Payload = {
    formOrigin: { stock: number };
    company: number;
    products: Array<{
        quantity: number;
        product: number;
    }>;
};

const useCreateOrUpdateStockPurchaseOrder = () => {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const request = async (payload: Payload) => api.post('/stock-purchase-orders', payload);

    return useMutation(request, {
        onSuccess: ({ data }) => {
            showToast('Ordem de compra criada com sucesso', 'success');

            queryClient.invalidateQueries(getStocksPurchaseOrderKey);

            navigate(`../${data.id}`);
        },
        onError: (error: any) => {
            const defaultMessage = 'Ocorreu um erro ao criar a ordem de compra';
            const apiErrorMessage = error?.response?.data?.title || defaultMessage;

            showToast(apiErrorMessage?.toLowerCase()?.includes('validation') ? defaultMessage : apiErrorMessage, 'danger');
        },
    });
};

export default useCreateOrUpdateStockPurchaseOrder;
