import classNames from 'classnames';
import { formatMoney } from 'utils/money';
import ReportColumnPercentage from './column-percentage';

type ReportColumnValuePercentageProps = {
    classes?: string;
    value: number;
    percentage?: number;
    showPercentage?: boolean;
    showValues?: boolean;
};

const ReportColumnValuePercentage = ({ value = 0, percentage = 0, classes = '', showPercentage = true, showValues = true }: ReportColumnValuePercentageProps) => {
    return (
        <div className={classNames('whitespace-nowrap flex gap-1 items-center', classes)}>
            <div className={classNames(!showValues && !showPercentage && 'bg-gray-200 rounded-lg w-20 h-5')}>{showValues && <span>{formatMoney(value)}</span>}</div>
            {showPercentage && <ReportColumnPercentage percentage={percentage} showValues={showValues} />}
        </div>
    );
};

export default ReportColumnValuePercentage;
