import { useParams } from 'react-router-dom';
import Table from '@mui/material/Table/Table';
import TableRow from '@mui/material/TableRow/TableRow';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import Empty from 'components/empty';
import Card from 'components/core/card';
import Text from 'components/core/text';
import { formatMoney, formatNumberToPTBR } from 'utils/money';
import Spinner from 'components/core/spinner';
import IconButton from '@mui/material/IconButton';
import useTheme from '@mui/material/styles/useTheme';
import { Fragment, useState } from 'react';
import useGetOrderServicesExpenseProducts from 'services/queries/order-services/use-get-order-services-expense-products';
import OrderServiceExpenseTableProducts from './expense-products-table';
import TableContainer from '@mui/material/TableContainer';
import SvgIcoMinus from 'components/core/icon/files/ico-minus';
import SvgIcoPlus from 'components/core/icon/files/ico-plus';

const ServiceOrderExpenseProductsPage = () => {
    const [activeProductId, setActiveProductId] = useState<number>();

    const { palette } = useTheme();
    const { orderServiceId, saleOrderId } = useParams();

    const { data = [], isLoading } = useGetOrderServicesExpenseProducts({ orderServiceId, saleOrderId });

    return (
        <>
            <Text as="h3" variant="h5" className="text-heading mb-4">
                Produtos / Despesas
            </Text>
            {isLoading ? (
                <Spinner fixed={false} parentClasses="p-4" />
            ) : (
                <Card>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell width={50} />
                                    <TableCell>
                                        <Text as="span" className="text-base-500">
                                            Produto
                                        </Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text as="span" className="text-base-500">
                                            Quantidade
                                        </Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text as="span" className="text-base-500">
                                            Total
                                        </Text>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Boolean(data.length) ? (
                                    data.map((item) => {
                                        const isActive = item.id === activeProductId;
                                        const Icon = isActive ? SvgIcoMinus : SvgIcoPlus;

                                        return (
                                            <Fragment key={item.id}>
                                                <TableRow classes={{ root: 'hover:bg-base-200 hover:bg-opacity-50' }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell>
                                                        <IconButton
                                                            color="secondary"
                                                            size="small"
                                                            sx={{
                                                                backgroundColor: palette.secondary.main,
                                                                '&:hover': { backgroundColor: palette.secondary.dark },
                                                            }}
                                                            onClick={() => setActiveProductId((oldId) => (oldId === item.id ? undefined : item.id))}>
                                                            <Icon width={13} height={13} className="text-white" />
                                                        </IconButton>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Text as="span" className="text-base-500">
                                                            {Boolean(item.name) && Boolean(item.code) ? `#${item.code} - ${item.name}` : '-'}
                                                        </Text>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Text as="span" className="text-base-500">
                                                            {formatNumberToPTBR(Number(item.quantity || 0))}
                                                        </Text>
                                                    </TableCell>

                                                    <TableCell>
                                                        <Text as="span" className="text-base-500">
                                                            {formatMoney(Number(item.total || 0))}
                                                        </Text>
                                                    </TableCell>
                                                </TableRow>

                                                {isActive && (
                                                    <>
                                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                            <TableCell colSpan={4} className="pt-5">
                                                                <Text as="p" className="text-base-500 mb-5">
                                                                    Despesas feitas no produto <strong className="text-secondary-500">{item.name}</strong>
                                                                </Text>
                                                                <Card elevation={0} className="border">
                                                                    <OrderServiceExpenseTableProducts expenses={item.expenses} />
                                                                </Card>{' '}
                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                )}
                                            </Fragment>
                                        );
                                    })
                                ) : (
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell colSpan={3}>
                                            <Empty title="Não há produtos a serem mostrados." />
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            )}
        </>
    );
};

export default ServiceOrderExpenseProductsPage;
