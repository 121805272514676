import Spinner from 'components/core/spinner';
import { useParams } from 'react-router-dom';
import useBreadcrumb from 'store/breadcrumb/use-breadcrumb';
import Empty from 'components/empty';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';
import useGetLoan from 'services/queries/loans/use-get-loan';
import ExternalButton from 'components/buttons/external';
import dayjs from 'dayjs';
import { Fragment } from 'react';
import { formatMoney } from 'utils/money';
import { formatDate } from 'utils/date';

const LoanPage = () => {
    const { loanId } = useParams();
    const goToParentRoute = useNavigateToParentRoute();

    const { data: loan, isLoading } = useGetLoan(loanId);

    useBreadcrumb(loan?.code);

    return (
        <Modal
            contentClassnames="w-[1200px]"
            onClose={goToParentRoute}
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    {!!loan?.code ? `#${loan.code}` : 'Formulário'}
                </Text>
            }>
            {isLoading ? (
                <div className="relative p-8">
                    <Spinner size={20} />
                </div>
            ) : !loan ? (
                <Empty title="Empréstimo não encontrado" />
            ) : (
                <>
                    <div className="px-4 grid grid-cols-4 gap-4 mb-6">
                        <Text className="text-base-500 block mr-2">
                            Data do empréstimo: <strong>{formatDate(loan.loanDate, 'DD/MM/YYYY')}</strong>
                        </Text>
                        <Text className="text-base-500 block mr-2">
                            Principal:{' '}
                            <strong>
                                {formatMoney(Number(loan.value || 0))} em {loan.instalments || 0}x
                            </strong>
                        </Text>
                        <Text className="text-base-500 block mr-2">
                            Valor pago: <strong>{formatMoney(loan.paidValue)}</strong>
                        </Text>
                        <Text className="text-base-500 block mr-2">
                            Conta bancária: <strong>{loan.bankAccount.name || '-'}</strong>
                        </Text>
                        <Text className="text-base-500 block mr-2">
                            Empresa: <strong>{loan.company.name || '-'}</strong>
                        </Text>
                        <Text className="text-base-500 block mr-2">
                            Filial: <strong>{loan.branch.name || '-'}</strong>
                        </Text>
                    </div>
                    {!!loan.receipt && (
                        <div className="border border-base-300 rounded-[14px] mb-4 mx-4">
                            <div className="mb-4 sm:mb-1 sm:flex items-center justify-between border-b border-b-base-300 p-4">
                                <Text as="h4" variant="body.medium.sm" className="text-heading">
                                    Recibo #{loan.receipt.code}
                                </Text>
                            </div>
                            <div className="p-4 grid grid-cols-2 sm:grid-cols-3 gap-4">
                                <Text className="text-base-500 block mr-2">
                                    Título: <strong>{loan.receipt.title || '-'}</strong>
                                </Text>
                                <Text className="text-base-500 block mr-2">
                                    Valor: <strong>{formatMoney(Number(loan.receipt.grossValue))}</strong>
                                </Text>
                                <Text className="text-base-500 block mr-2">
                                    Valor final: <strong>{formatMoney(Number(loan.receipt.netValue))}</strong>
                                </Text>
                            </div>
                        </div>
                    )}
                    <div className="px-4 pb-4">
                        {Boolean(loan.expenses?.length) && (
                            <div className="border border-base-300 rounded-[14px]">
                                {loan.expenses?.map((item) => {
                                    return (
                                        <Fragment key={item.id}>
                                            <div className="p-4 mb-4 sm:mb-1 sm:flex items-center justify-between border-b border-b-base-300">
                                                <Text as="h4" variant="body.medium.sm" className="text-heading">
                                                    Despesa #{item?.code}
                                                </Text>
                                                <ExternalButton to={`/app/despesas/${item?.id}`}>Ir para a despesa</ExternalButton>
                                            </div>
                                            <div className="p-4 grid grid-cols-2 sm:grid-cols-3 gap-4">
                                                <Text className="text-base-500 block mr-2">
                                                    Título: <strong>{item.title || '-'}</strong>
                                                </Text>
                                                <Text className="text-base-500 block mr-2">
                                                    Valor da despesa: <strong>{formatMoney(item.value)}</strong>
                                                </Text>
                                                <Text className="text-base-500 block mr-2">
                                                    Vencimento: <strong>{dayjs.tz(item.dueDate).format('DD/MM/YYYY')}</strong>
                                                </Text>
                                                <Text className="text-base-500 block mr-2">
                                                    CFOP: <strong>{item.fiscalCode?.name || '-'}</strong>
                                                </Text>
                                                <Text className="text-base-500 block mr-2">
                                                    Forma de pagamento: <strong>{item.paymentForm?.name || '-'}</strong>
                                                </Text>
                                            </div>
                                        </Fragment>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </>
            )}
        </Modal>
    );
};

export default LoanPage;
