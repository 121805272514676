import RemoveButton from 'components/buttons/remove';
import Dropzone from 'components/dropzone';
import { useEffect } from 'react';
import ItemFooter from './item-footer';
import { useExpensesContext } from './context';
import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import Text from 'components/core/text';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import Input from 'components/core/form/input';
import { ClassificationType } from 'types/models/classification';
import { generateClassificationQuery } from 'services/queries/classifications/utils';
import useGetClassifications from 'services/queries/classifications/use-get-classifications';
import Select from 'components/core/form/select';
import { Steps } from './context/types';
import SvgIcoFileText from 'components/core/icon/files/ico-file-text';

type FormState = {
    documents: Array<{
        localFile: File;
        file?: number;
        name: string;
        classification?: number;
    }>;
};

const Documents = () => {
    const { state, setContent, goToStep, completeStep } = useExpensesContext();

    const { data: classifications = [] } = useGetClassifications(generateClassificationQuery(ClassificationType.DocumentTransaction));

    const { formState, handleSubmit, control, watch, reset, getValues } = useForm<FormState>({
        defaultValues: { documents: [] },
    });

    const { append, remove } = useFieldArray({ name: 'documents', control });

    const handleChangeFile = async (filesList: File[]) => {
        try {
            for (const localFile of filesList) {
                const fileName = localFile?.name?.split('.')?.[0];

                append({
                    name: fileName || '',
                    localFile,
                });
            }
        } catch (error) {
            console.log('handleChangeFile', error);
        }
    };

    const removeFile = (index: number) => () => remove(index);

    const handleBack = () => {
        setContent({ documents: getValues().documents as any[] });
        goToStep('previous');
    };

    const submit = (data: FormState) => {
        setContent({ documents: data?.documents as any[] });
        completeStep(Steps.Documents);
        goToStep('next');
    };

    const documents = watch('documents');

    useEffect(() => {
        if (!!state?.content?.documents?.length) {
            reset({
                documents: state?.content?.documents,
            });
        }
    }, [state?.content?.documents, reset]);

    return (
        <form onSubmit={handleSubmit(submit)}>
            <div className="p-6">
                <div className="w-full overflow-x-auto">
                    <Table className="mb-6">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Text className="text-base-500 uppercase">Nome</Text>
                                </TableCell>
                                <TableCell>
                                    <Text className="text-base-500 uppercase">Classificação</Text>
                                </TableCell>
                                <TableCell>
                                    <Text className="text-base-500 uppercase">Arquivo</Text>
                                </TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!documents?.length ? (
                                <TableRow>
                                    <TableCell colSpan={3}>
                                        <Text className="text-base-500 mt-4 ml-3 mb-3 block">Nenhum arquivo adicionado ainda.</Text>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                documents?.map(({ localFile }, index) => {
                                    const fileUrl = (localFile as any)?.url || !!localFile ? URL.createObjectURL(localFile) : '';

                                    return (
                                        <TableRow key={`file_${index}_${localFile?.name}`}>
                                            <TableCell>
                                                <Controller
                                                    name={`documents.${index}.name`}
                                                    control={control}
                                                    render={({ field }) => <Input {...field} error={formState?.errors?.documents?.[index]?.name?.message} autoComplete="nope" placeholder="Nome" />}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Controller
                                                    name={`documents.${index}.classification`}
                                                    control={control}
                                                    render={({ field }) => {
                                                        const value = classifications.find((item) => item.value === field.value);

                                                        return (
                                                            <Select
                                                                {...field}
                                                                value={value}
                                                                options={classifications}
                                                                placeholder="Selecione uma opção"
                                                                onChange={(option: any) => field.onChange(option.value)}
                                                                error={formState?.errors?.documents?.[index]?.classification?.message}
                                                            />
                                                        );
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <div className="flex items-center">
                                                    <SvgIcoFileText width={16} height={16} className="mr-1 text-base-500" />
                                                    <a className="hover:underline" href={fileUrl} rel="noreferrer" target="_blank">
                                                        <Text className="text-secondary-500 font-medium">{localFile?.name}</Text>
                                                    </a>
                                                </div>
                                            </TableCell>
                                            <TableCell width={42}>
                                                <RemoveButton className="!static opacity-100 mt-0" onClick={removeFile(index)} />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            )}
                        </TableBody>
                    </Table>
                </div>
                <Dropzone multiple={true} onUploadFiles={handleChangeFile} containerClasses="mb-6" />
            </div>
            <ItemFooter isSubmitting={false} isValid={true} onGoBack={handleBack} />
        </form>
    );
};

export default Documents;
