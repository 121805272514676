import Select from 'components/core/form/select';
import Button from 'components/core/button';
import { useTableAdvancedSearch } from 'components/core/table/datatable/components/advanced-search';
import { statuses } from 'utils/statics';
import { FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import qs from 'qs';
import useGetQueryParam from 'hooks/router/use-get-query-param';
import { Status } from 'types/general';

const statusesOptionsWithoutDeleted = statuses.filter((item) => item.value !== Status.Deleted);

const getStatusById = (statusId?: string | null) => {
    const statusById = statusesOptionsWithoutDeleted.find((item) => item.value.toString() === statusId);

    return statusById;
};

const BankAccountsAdvancedSearch = () => {
    const navigate = useNavigate();
    const { onClose } = useTableAdvancedSearch();
    const status = useGetQueryParam('filter[status]');

    const [statusValue, setStatusValue] = useState<string | null>(status);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const filters = qs.stringify(
            {
                filter: {
                    status: statusValue,
                },
            },
            {
                addQueryPrefix: true,
                encode: false,
                arrayFormat: 'brackets',
            }
        );

        navigate(filters);
        onClose();
    };

    const handleChangeStatus = (option: any) => setStatusValue(option?.value?.toString());

    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-5">
                <Select
                    name="status"
                    value={getStatusById(statusValue)}
                    options={statusesOptionsWithoutDeleted}
                    label="Status"
                    placeholder="Selecione uma opção"
                    onChange={handleChangeStatus}
                    isClearable={true}
                />
            </div>

            <div className="flex">
                <Button type="submit" variant="contained" color="secondary" className="min-w-[100px] ml-auto">
                    Filtrar
                </Button>
            </div>
        </form>
    );
};

export default BankAccountsAdvancedSearch;
