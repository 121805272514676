import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import ConfirmModal from 'components/core/modal/confirm';
import Datatable from 'components/core/table/datatable';
import PageHeader from 'components/page/header';
import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { Link, Outlet } from 'react-router-dom';
import useDelete from 'services/queries/crud/use-delete';
import { getPaginatedWithFiltersKey } from 'services/queries/general/keys';
import useGetPaginatedWithFilters from 'services/queries/general/use-get-paginated-with-filters';
import useConfig from 'store/config/use-config';
import { Rule } from 'types/permissions';
import { importFlowColumns } from './utils';
import { ImportFlow } from 'types/models/import-flow';
import SvgIcoPlus from 'components/core/icon/files/ico-plus';

const ENDPOINT = '/imports';

const ImportFlowPage = ({ page: pageProps }: WithPagePermissionsProps) => {
    const [filters, setFilters] = useState<any>({});
    const [page, setPage] = useState(1);
    const [importFlowToDeleteId, setImportFlowToDeleteId] = useState<any>(null);

    const { palette } = useTheme();
    const queryClient = useQueryClient();
    const { config } = useConfig();

    const { data } = useGetPaginatedWithFilters<ImportFlow>({
        endpoint: ENDPOINT,
        filters,
        page,
    });

    const { mutateAsync: deleteImportFlow, isLoading: isDeletingImportFlow } = useDelete({
        apiPayload: { endpoint: `/imports`, useApiTodelete: true },
    });

    const handleDeleteImportFlow = async () => {
        try {
            if (!!importFlowToDeleteId) {
                await deleteImportFlow({ id: importFlowToDeleteId });

                queryClient.invalidateQueries(getPaginatedWithFiltersKey(ENDPOINT, filters, page, config.tables.rowsPerPage));
                setImportFlowToDeleteId(null);
            }
        } catch (e) {
            console.error('handleDeleteSaleOrder: ', e);
        }
    };

    const actions = pageProps?.rules.map((item) => item.key);
    const canCreateSaleOrder = actions?.includes(Rule.Create);

    return (
        <>
            <PageHeader
                className="mb-6"
                title="Importações"
                right={
                    canCreateSaleOrder ? (
                        <IconButton component={Link} to="novo" color="secondary" size="large" sx={{ backgroundColor: palette.secondary.main, '&:hover': { backgroundColor: palette.secondary.dark } }}>
                            <SvgIcoPlus width={16} height={16} color={palette.grey[100]} />
                        </IconButton>
                    ) : undefined
                }
            />
            <Datatable
                data={data?.items || []}
                columns={importFlowColumns}
                actions={actions}
                onDelete={setImportFlowToDeleteId}
                options={{
                    searchPlaceholder: 'Buscar importação',
                    sort: true,
                    filter: false,
                    search: true,
                    print: false,
                    download: false,
                    viewColumns: false,
                    serverSide: true,
                    searchAlwaysOpen: true,
                    enableNestedDataAccess: '.',
                    pagination: true,
                    elevation: 21,
                    selectableRows: 'none',
                    count: data?.total,
                    page,
                    onChangePage: setPage,
                    onSearchChange: (searchText) => {
                        setFilters((prev) => ({ ...prev, searchText }));
                    },
                }}
            />
            <Outlet
                context={{
                    importFlowListFilters: {
                        ...filters,
                        page,
                        endpoint: ENDPOINT,
                    },
                }}
            />
            {Boolean(importFlowToDeleteId) && (
                <ConfirmModal
                    onConfirmAction={handleDeleteImportFlow}
                    onClose={setImportFlowToDeleteId.bind(this, null)}
                    description="Você tem certeza que deseja apagar este fluxo de importação?"
                    title="Apagar fluxo de importação"
                    isLoading={isDeletingImportFlow}
                />
            )}
        </>
    );
};

export default ImportFlowPage;
