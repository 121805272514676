import { useQuery } from 'react-query';
import api from 'services/api';
import { getExpenseTransactionsKey } from './keys';
import { Pagination } from 'types/pagination';
import { Transaction } from 'types/models/transaction';

const useGetExpenseTransactions = (expenseId?: string, queryFilters?: string) => {
    const endpoint = `/expenses/${expenseId}/transactions${queryFilters}`;

    const request = async () => {
        const { data } = await api.get<Pagination<Transaction>>(endpoint);

        return data;
    };

    return useQuery(getExpenseTransactionsKey(expenseId, queryFilters), request, {
        enabled: Boolean(expenseId) || Boolean(queryFilters),
    });
};

export default useGetExpenseTransactions;
