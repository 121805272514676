import { useCallback, useMemo, useState } from 'react';
import _sumBy from 'lodash/sumBy';
import { months } from 'utils/date';
import { BlockFilter, ReportPageBlock, TableItem } from 'types/models/reports';
import Text from 'components/core/text';
import Table from 'pages/private/reports/table';
import { TransactionType } from 'types/models/transaction';
import ModalMonthReportDetails from 'pages/private/reports/table/modal';
import _max from 'lodash/max';
import { getReportPageBlockColor } from '../utils';
import map from 'lodash/map';
import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';
import { getPercentage } from './utils';
import QuarterItem from './table/quarter-item';
import Card from 'components/core/card';
import Tabs from '@mui/material/Tabs/Tabs';
import Tab from '@mui/material/Tab/Tab';
import Empty from 'components/empty';
import { Pie } from 'react-chartjs-2';
import CardValue from './card-value';

export type ReportPageDetailsBlockType = 'block' | 'resume';

type ReportPageDetailsBlockProps = {
    type?: ReportPageDetailsBlockType;
    title?: string;
    reportPageType: TransactionType;
    currentYear?: number;
    previousYear?: number;
    currentYearData?: ReportPageBlock;
    previousYearData?: ReportPageBlock;
    color: string;
    currentYearTotal?: number;
    blockBranches?: ReportPageBlock['reportBranches'];
    filter?: BlockFilter;
};

const ReportPageDetailsBlock = ({
    type = 'block',
    title,
    currentYear,
    previousYear,
    currentYearData,
    previousYearData,
    reportPageType,
    color,
    currentYearTotal = 0,
    blockBranches = [],
    filter,
}: ReportPageDetailsBlockProps) => {
    const [selectedValues, setSelectedValues] = useState<TableItem['values'][0]>();

    const isIncome = reportPageType === TransactionType.Income;

    const [tab, setTab] = useState<'BRANCHES' | 'QUARTERS'>(isIncome ? 'BRANCHES' : 'QUARTERS');

    const handleChangeTab = (_: any, nextSlug: any) => setTab(nextSlug);
    const fontSize = 11;

    const { reportMonths } = currentYearData || {};

    const valuesTable = useMemo(() => {
        const headerType = new Map<ReportPageDetailsBlockType, string[]>([
            ['block', ['R$', '% x Total', '% x Média', 'Média']],
            ['resume', ['R$', '% MA', '% M', 'Média']],
        ]);

        const titles = headerType.get(type)!.map((title) => ({ title }));

        const headers: { title: string }[][] = [titles];

        const formatValue = ({ month, value, resultData, legacyData }: ReportPageBlock['reportMonths'][0]) => {
            const { percentage, averagePercentage, average, percentageValue = 0, percentageAverage = 0 } = resultData || {};

            const monthTitle = months[month - 1];

            if (type === 'block') {
                return {
                    title: monthTitle,
                    monthNumber: month,
                    modalTitle: `${title} - ${monthTitle}/${currentYear}`,
                    monthTotal: value,
                    legacyData,
                    values: [
                        { value, type: 'currency' as const },
                        { value: percentage, type: 'percentage' as const },
                        { value: averagePercentage, type: 'percentage' as const },
                        { value: average, type: 'currency' as const },
                    ],
                };
            }

            return {
                title: monthTitle,
                values: [
                    { value, type: 'currency' as const },
                    { value: percentageValue, type: 'percentage' as const },
                    { value: percentageAverage, type: 'percentage' as const },
                    { value: average, type: 'currency' as const },
                ],
            };
        };

        const totalValue = _sumBy(reportMonths, 'value');

        const total = {
            title: 'Total',
            className: 'font-bold',
            values: [
                {
                    type: 'currency' as const,
                    value: totalValue,
                },
                { type: 'blank' as const },
                { type: 'blank' as const },
                { type: 'blank' as const },
            ],
        };

        const items = [
            {
                title: 'Meses',
                values: [...(reportMonths?.map(formatValue) || []), total],
            },
        ];

        return {
            items,
            headers,
        };
    }, [reportMonths, type, currentYear, title]);

    const quartersTable = useMemo(() => {
        const headers = [[{ title: currentYear?.toString() }, { title: `% ${currentYear} x ${previousYear}` }, { title: previousYear?.toString() }]];

        const quarters = currentYearData?.reportQuarters || [];

        const getQuarterPercentage = (quarters: number[], value: number) => {
            if (value <= 0) {
                return 0;
            }

            const biggestQuarterValue = _max(quarters) || 0;

            return (value / biggestQuarterValue) * 100;
        };

        const itemsValues =
            quarters?.map((value, index) => {
                const previousQuarterValue = previousYearData?.reportQuarters?.[index] || 0;

                const previousQuarterPercentage = getQuarterPercentage(previousYearData?.reportQuarters || [], previousQuarterValue);
                const currentYearQuarterPercentage = getQuarterPercentage(quarters || [], value);

                const percentage = getPercentage(previousQuarterValue, value);

                return {
                    title: `${index + 1}º Trimestre`,

                    values: [
                        {
                            value,
                            type: 'custom' as const,
                            className: '!px-0 relative',
                            customComponent: (value = 0) => <QuarterItem value={value} percentage={currentYearQuarterPercentage} color={color} />,
                        },
                        {
                            value: percentage,
                            type: 'percentage' as const,
                        },
                        {
                            value: previousQuarterValue,
                            type: 'custom' as const,
                            className: '!px-0 relative',
                            customComponent: (value = 0) => <QuarterItem value={value} percentage={previousQuarterPercentage} color={color} />,
                        },
                    ],
                };
            }) || [];

        const items = [
            {
                title: 'Períodos',
                values: [
                    ...itemsValues,
                    {
                        title: `Total`,
                        className: 'font-bold',
                        values: [
                            {
                                value: _sumBy(itemsValues, 'values[0].value'),
                                type: 'currency' as const,
                            },
                            {
                                type: 'blank' as const,
                            },
                            {
                                value: _sumBy(itemsValues, 'values[2].value'),
                                type: 'currency' as const,
                            },
                        ],
                    },
                ],
            },
        ];

        return {
            headers,
            items,
        };
    }, [previousYear, currentYear, currentYearData, previousYearData, color]);

    const generateValues = useCallback(() => {
        if (!!blockBranches.length) {
            const totalValues = sumBy(blockBranches, (branch) => +branch.value);

            return map(blockBranches, (blockBranch) => {
                const percentageValue = (blockBranch.value / totalValues) * 100;
                return {
                    name: blockBranch.branch?.name || '-',
                    percentage: isFinite(percentageValue) ? percentageValue : 0,
                };
            });
        }

        const allYearsBranches = [...(currentYearData?.branches || []), ...(previousYearData?.branches || [])];

        const summedTotalValues = sumBy(allYearsBranches, 'value');
        const aggregatedValues = groupBy(allYearsBranches, 'branch');

        return map(aggregatedValues, (values, branch) => {
            const summedValues = sumBy(values, 'value');
            const percentageValue = (summedValues / summedTotalValues) * 100;
            return {
                name: branch,
                percentage: isFinite(percentageValue) ? percentageValue : 0,
            };
        });
    }, [blockBranches, currentYearData, previousYearData]);

    const data = useMemo(() => {
        return {
            labels: !!blockBranches.length ? map(blockBranches, 'branch.name') : map(currentYearData?.branches, 'branch'),
            datasets: [
                {
                    data: generateValues().map((item) => item.percentage.toFixed(2)),
                    backgroundColor: !!blockBranches.length
                        ? blockBranches?.map((_, index) => getReportPageBlockColor(index))
                        : currentYearData?.branches?.map((_, index) => getReportPageBlockColor(index)),
                    borderWidth: 1,
                },
            ],
        };
    }, [currentYearData, blockBranches, generateValues]);

    const hasPositiveValues = data?.datasets[0].data.some((item) => !!Number(item));

    return (
        <>
            <Text as="h2" variant="h4" className="text-heading mb-6 capitalize">
                {title}
            </Text>
            <div className="xl:grid xl:grid-cols-2 gap-6 mb-8">
                <Table title="Valores" className="mb-6 xl:mb-0" fontSize={fontSize} {...valuesTable} onClickRow={type === 'block' ? setSelectedValues : undefined} />
                <div className="flex flex-col">
                    <CardValue
                        title={`Resumo ${currentYear}`}
                        className="mb-6"
                        currentYear={currentYear}
                        previousYear={previousYear}
                        resumeCurrentYear={currentYearData?.resume}
                        resumePreviousYear={previousYearData?.resume}
                        reportPageType={reportPageType}
                        currentYearTotal={currentYearTotal}
                        type={type}
                        averageFromValues={currentYearData?.resume?.average}
                    />
                    <Card className="flex-1">
                        <Tabs variant="scrollable" indicatorColor="secondary" value={tab} onChange={handleChangeTab}>
                            {reportPageType === TransactionType.Income && <Tab value="BRANCHES" label="Filiais" />}
                            <Tab value="QUARTERS" label="Trimestres" />
                        </Tabs>
                        {tab === 'QUARTERS' && <Table withCard={false} title="" className="flex-1 !p-0" rounded={false} fontSize={fontSize} {...(quartersTable as any)} />}
                        {tab === 'BRANCHES' && (
                            <div className="flex flex-col justify-center items-center h-[calc(100%-49px)]">
                                <div className="h-[280px] my-3 flex justify-center items-center">
                                    {hasPositiveValues ? (
                                        <Pie
                                            data={data}
                                            options={{
                                                plugins: {
                                                    legend: { display: false },
                                                    tooltip: { enabled: true },
                                                },
                                            }}
                                        />
                                    ) : (
                                        <Empty title="Não existem dados positivos a serem mostrados." />
                                    )}
                                </div>
                                {hasPositiveValues && (
                                    <div className="flex flex-col gap-2 items-center justify-center mt-10">
                                        {generateValues().map(({ name, percentage = 0 }, index) => {
                                            const color = getReportPageBlockColor(index);

                                            return (
                                                <div key={`legend_${index}`} className="flex items-center gap-1">
                                                    <div className="w-[20px] h-[20px] rounded-lg" style={{ background: color }}></div>
                                                    <span className="text-sm text-base-500">
                                                        {percentage.toFixed(2)}% &bull; {name}
                                                    </span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        )}
                    </Card>
                </div>
            </div>
            {!!selectedValues && (
                <ModalMonthReportDetails currentYear={currentYear!} reportPageType={reportPageType} filter={filter} selectedValues={selectedValues} onClose={setSelectedValues.bind(this, undefined)} />
            )}
        </>
    );
};

export default ReportPageDetailsBlock;
