import { number, object, string } from 'yup';

export const addressSchema = object({
    cityId: number(),
    compliment: string().optional(),
    neighbourhood: string().trim(),
    number: string().trim(),
    stateId: number(),
    street: string().trim(),
    zip: string().trim(),
});
