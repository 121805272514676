import ModalFilter from 'components/core/modal/filter';
import { UseFormReturn, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import PaymentOrderFilterFields from './fields';

type PaymentOrderFilterProps = {
    onClose(): void;
    onSubmit(data?: any): void;
    filters?: any;
    visibleFields?: string[];
    FormActionsComponent?: (props: { formMethods: UseFormReturn<any, any> }) => JSX.Element;
};

const PaymentOrderFilter = ({ onClose, onSubmit, filters, visibleFields, FormActionsComponent }: PaymentOrderFilterProps) => {
    const methods = useForm({
        mode: 'onSubmit',
        defaultValues: {
            provisioned: null,
            invoiceStatus: null,
            paid: null,
        },
    });

    const { handleSubmit, reset } = methods;

    const clear = () => onSubmit(undefined);

    useEffect(() => {
        reset(filters);
    }, [filters, reset]);

    return (
        <ModalFilter onClose={onClose} onSubmit={handleSubmit(onSubmit)} FormActionsComponent={FormActionsComponent} formMethods={methods} onClear={clear} className="!w-[700px] max-w-full">
            <div className="flex flex-col gap-4 md:grid grid-cols-2">
                <PaymentOrderFilterFields visibleFields={visibleFields} formMethods={methods} />
            </div>
        </ModalFilter>
    );
};

export default PaymentOrderFilter;
