import { RouteObject } from 'react-router-dom';
import ErrorPage from 'pages/shared/error';
import LoginPage from 'pages/public/login';
import ForgotPasswordPage from 'pages/public/forgot-password';
import ForgotPasswordTokenPage from 'pages/public/forgot-password/token';
import MainPage from 'pages/private/main';
import PRIVATE_ROUTES from './private';
import PrivacyPolicyPage from 'pages/public/privacy-policy';

const routes: RouteObject[] = [
    {
        // @ts-ignore
        breadcrumb: null,
        path: '/',
        element: <LoginPage />,
    },
    {
        path: 'resetar-senha',
        element: <ForgotPasswordPage />,
        children: [
            {
                path: ':token',
                element: <ForgotPasswordTokenPage />,
            },
        ],
    },
    {
        path: 'politica-de-privacidade',
        element: <PrivacyPolicyPage />,
    },
    {
        // @ts-ignore
        breadcrumb: null,
        path: 'app',
        element: <MainPage />,
        children: PRIVATE_ROUTES,
        errorElement: <ErrorPage title="Ops!" subtitle="Tivemos um problema" />,
    },
    {
        path: '*',
        element: <ErrorPage subtitle="Página não encontrada." description="Esta página não existe mais ou foi movida para outro lugar." />,
        errorElement: <ErrorPage subtitle="Página não encontrada." description="Esta página não existe mais ou foi movida para outro lugar." />,
    },
];

export default routes;
