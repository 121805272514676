import { Status } from 'types/general';
import { PurchaseScopeActions } from 'types/models/order-service';
import { SaleOrder } from 'types/models/sale-order';
import { QueryConfig } from 'utils/query';

export const getOrderServiceColumnOrderBy = (keyColumn: string) => {
    const mappedColumns = {
        'saleOrder.branch.name': 'branch.name',
        'saleOrder.company.name': 'company.name',
    };

    return mappedColumns[keyColumn] || keyColumn;
};

export const saleOrdersFilterConfig: QueryConfig<SaleOrder> = {
    name: 'GetSaleOrdersOptions',
    table: 'saleOrder',
    fields: ['id', 'code'],
    where: {
        status: {
            _eq: Status.Active,
        },
    },
};

export const generateFinalApprovalActionsText = (action: PurchaseScopeActions) => {
    if (!action) {
        return '-';
    }

    const finalApprovalTextsMap = {
        [PurchaseScopeActions.New]: 'Novo Produto Adicionado',
        [PurchaseScopeActions.Reprove]: 'Solicitação de alteração',
        [PurchaseScopeActions.Delete]: 'Produto Removido',
    };

    return finalApprovalTextsMap[action];
};
