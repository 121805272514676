import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import ListPage from 'pages/shared/crud/list';
import { useParams } from 'react-router-dom';
import Text from 'components/core/text';
import { serviceOrderSaleScopeListConfig } from 'pages/private/sales-orders/utils';

type ServiceOrderSaleScopePageProps = {
    isOrderServiceReceiptsPage?: boolean;
};

const ServiceOrderSaleScopePage = ({ page, isOrderServiceReceiptsPage = false }: WithPagePermissionsProps & ServiceOrderSaleScopePageProps) => {
    const { orderServiceId } = useParams();

    const listConfig = serviceOrderSaleScopeListConfig(orderServiceId, isOrderServiceReceiptsPage);

    return (
        <>
            <div className="flex items-center mb-6 flex-row justify-between">
                <Text as="h3" variant="h5" className="text-heading mr-1">
                    Escopo de venda
                </Text>
            </div>
            <ListPage {...listConfig} page={page} customTableOptions={{ selectableRows: 'none' }} />
        </>
    );
};

export default ServiceOrderSaleScopePage;
