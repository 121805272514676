import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import { FormFieldType, FormGroupType, QuizType } from 'types/models/form';
import { GetPaginatedWithFiltersParams } from '../general/use-get-paginated-with-filters';
import { getPaginatedWithFiltersKey } from '../general/keys';
import useConfig from 'store/config/use-config';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';

export type FormPayload = {
    title: string;
    type: QuizType;
    groups: Array<{
        title: string;
        order: number;
        type: FormGroupType;
        conditionalFieldTitle?: string;
        conditionalFieldType?: FormFieldType.Checkbox | FormFieldType.SelectSimple;
        fields: Array<{
            name: string;
            type: FormFieldType;
            order: number;
            required: boolean;
            options?: Array<{
                name: string;
                subfields?: Array<{
                    name?: string;
                    type?: FormFieldType;
                    required?: boolean;
                    order?: number;
                    options?: Array<{ name?: string }>;
                }>;
            }>;
        }>;
    }>;
};

const useCreateOrUpdateForm = (listParams?: GetPaginatedWithFiltersParams) => {
    const { config } = useConfig();
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const goToParentRoute = useNavigateToParentRoute();

    const request = async (payload: FormPayload) => api.post('/quizzes', payload);

    return useMutation(request, {
        onSuccess: () => {
            if (!!listParams) {
                queryClient.invalidateQueries(getPaginatedWithFiltersKey(listParams.endpoint, listParams.filters, listParams.page, config.tables.rowsPerPage, listParams.orderBy));
            }

            goToParentRoute();
        },
        onError: (error: any) => {
            showToast(error?.response?.data?.title || 'Ocorreu um problema ao criar o formulário', 'danger');
        },
    });
};

export default useCreateOrUpdateForm;
