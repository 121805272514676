import { formatNumberToPTBR } from 'utils/money';

type ReportColumnPercentageProps = {
    percentage: number;
    className?: string;
    showValues?: boolean;
};

const ReportColumnPercentage = ({ percentage, className, showValues = true }: ReportColumnPercentageProps) => {
    if (showValues) {
        return <small className={className}>({formatNumberToPTBR(percentage)}%)</small>;
    }

    return <span className={className}>{formatNumberToPTBR(percentage)}%</span>;
};

export default ReportColumnPercentage;
