import classNames from 'classnames';
import SvgIcoArrowDown from 'components/core/icon/files/ico-arrow-down';
import SvgIcoArrowUp from 'components/core/icon/files/ico-arrow-up';
import { useMemo } from 'react';
import { formatNumberToPTBR } from 'utils/money';

type ImportFlowNationalizationsPercentageProps = {
    value?: number;
};

const ImportFlowNationalizationsPercentage = ({ value = 0 }: ImportFlowNationalizationsPercentageProps) => {
    const valueClasses = useMemo(() => {
        if (value > 0) {
            return 'text-system-danger-500';
        }
        return value === 0 ? 'text-base-500' : 'text-system-success-500';
    }, [value]);

    const Icon = value < 0 ? SvgIcoArrowDown : SvgIcoArrowUp;

    return (
        <small className={classNames('flex items-baseline', valueClasses)}>
            {value !== 0 && <Icon width={9} height={9} />}
            {formatNumberToPTBR(value)}%
        </small>
    );
};

export default ImportFlowNationalizationsPercentage;
