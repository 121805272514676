import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from '@mui/material/TableRow/TableRow';
import Text from 'components/core/text';
import Select from 'components/core/form/select';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import Badge from 'components/core/badge';
import selectStyles from 'components/core/form/select/styles';
import CurrencyInput from 'components/core/form/currency';
import { NumberFormatValues } from 'react-number-format';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import useTheme from '@mui/material/styles/useTheme';
import classNames from 'classnames';
import { formatNumberToPTBR } from 'utils/money';
import { Option } from 'types/general';
import { PurchaseOrderPayload } from 'types/models/purchase-order';
import { currentDateWithoutTimezone, formatDate, isValidDate } from 'utils/date';
import { getProvisioningOptions } from 'pages/private/expenses/crud/utils';
import Switch from 'components/core/switch';
import Textarea from 'components/core/form/textarea';
import RemoveButton from 'components/buttons/remove';
import TruncatedText from 'components/core/text/truncated-text';

type ProductItemProps = {
    index: number;
    item: PurchaseOrderPayload['products'][0];
    onRemove: (index: number) => void;
    saleProducts?: Option[];
    withSaleProducts?: boolean;
    withBalance?: boolean;
};

const provisionOptions = getProvisioningOptions({ date: currentDateWithoutTimezone, skipDatesCount: 2, maxRegisters: 3 });

const ProductItem = ({ index, item, saleProducts = [], onRemove, withSaleProducts = true, withBalance = true }: ProductItemProps) => {
    const { palette } = useTheme();
    const { formState, control, clearErrors, setValue } = useFormContext<PurchaseOrderPayload>();

    const getPath = (key: keyof PurchaseOrderPayload['products'][0]) => `products.${index}.${key}` as any;

    const handleRemove = () => onRemove(index);

    const balance = useWatch({ control, name: getPath('balance') });
    const preApproved = useWatch({ control, name: getPath('preApproved') });
    const quantity = useWatch({ control, name: getPath('quantity') });
    const isBilled = useWatch({ control, name: getPath('billed') });

    const validateQuantity = () => {
        if (!preApproved) {
            return;
        }

        clearErrors(getPath('quantity') as any);
    };

    const handleChangeQuantity = (values: NumberFormatValues) => {
        const qtd = values.floatValue;

        setValue(getPath('quantity') as any, qtd);
    };

    const quantityError = formState.errors.products?.[index]?.quantity?.message;
    const orderServiceProductSaleError = formState.errors.products?.[index]?.orderServiceProductSale?.message;
    const justificationError = formState.errors.products?.[index]?.justification?.message;
    const provisionError = formState.errors.products?.[index]?.provision?.message;
    const purchaseValueError = formState.errors.products?.[index]?.purchaseValue?.message;

    const isQuantityGreaterThanBalance = quantity > balance;

    const rowClassName = classNames((!item.preApproved || isQuantityGreaterThanBalance) && 'bg-system-danger-100 bg-opacity-20');

    return (
        <TableRow className={rowClassName}>
            <TableCell className="p-0 px-4 border-r border-r-base-300">
                <TruncatedText dataId={index} className={classNames('text-heading', !withBalance && !withSaleProducts && 'max-w-[400px]')}>
                    #{item.product.code} - {item.product.name}
                </TruncatedText>
            </TableCell>
            {withSaleProducts && (
                <TableCell className="p-0 border-r border-r-base-300 max-w-[250px] min-w-[250px]">
                    <Controller
                        control={control}
                        name={getPath('orderServiceProductSale')}
                        render={({ field }) => {
                            const value = saleProducts.find((item) => item.value === field.value);

                            return (
                                <Tooltip open={true} placement="top" title={orderServiceProductSaleError} disableFocusListener={true}>
                                    <span>
                                        <Select
                                            {...field}
                                            value={value}
                                            options={saleProducts}
                                            placeholder="Selecione uma opção"
                                            error={orderServiceProductSaleError}
                                            selectStyles={{
                                                control: (base, data) =>
                                                    ({
                                                        ...selectStyles('medium', Boolean(orderServiceProductSaleError)).control!(base, data),
                                                        backgroundColor: 'transparent',
                                                        borderRadius: 0,
                                                        borderColor: Boolean(orderServiceProductSaleError) ? palette.error[500] : 'transparent',
                                                    } as any),
                                            }}
                                            showError={false}
                                            onChange={(option: any) => field.onChange(option.value)}
                                        />
                                    </span>
                                </Tooltip>
                            );
                        }}
                    />
                </TableCell>
            )}
            {withBalance && (
                <TableCell className="p-0 pl-4 border-r border-r-base-300 max-w-[130px] min-w-[130px]">
                    <Text>{formatNumberToPTBR(item.balance || 0)}</Text>
                </TableCell>
            )}
            <TableCell className="p-0 border-r border-r-base-300 w-44 max-w-[200px] min-w-[200px]">
                <Controller
                    control={control}
                    name={getPath('quantity')}
                    render={({ field }) => {
                        return (
                            <Tooltip open={true} placement="top" title={quantityError} disableFocusListener={true}>
                                <CurrencyInput
                                    value={field.value}
                                    placeholder="Ex: 10"
                                    rightClasses="bg-base-200 px-4"
                                    right={
                                        <Text as="span" variant="body.medium.sm" className="text-heading">
                                            {item.product.classificationByClassificationUnitId.name}
                                        </Text>
                                    }
                                    error={quantityError}
                                    showErrorMessage={false}
                                    inputWrapperClasses="border-transparent rounded-none"
                                    className={`focus:outline-2 -outline-offset-2 outline bg-transparent ${
                                        !!quantityError ? 'text-system-danger-500 outline-system-danger-100' : 'outline-primary-100'
                                    }`}
                                    onBlur={() => validateQuantity()}
                                    onValueChange={handleChangeQuantity}
                                />
                            </Tooltip>
                        );
                    }}
                />
            </TableCell>
            <TableCell className="p-0 pl-4 border-r border-r-base-300">
                <Badge variant={item.preApproved ? 'success' : 'error'}>{item.preApproved ? 'Sim' : 'Não'}</Badge>
            </TableCell>
            <TableCell className="p-0 border-r border-r-base-300 min-w-[180px] max-w-[180px]">
                <Controller
                    name={getPath('provision')}
                    control={control}
                    render={({ field }) => {
                        const value =
                            provisionOptions.find((item) => item.value === field.value) || (isValidDate(field.value) && !!field.value)
                                ? {
                                      value: field.value,
                                      label: `${formatDate(field.value, 'DD/MM/YYYY - ddd')}`,
                                  }
                                : undefined;

                        return (
                            <Tooltip open={true} placement="top" title={provisionError} disableFocusListener={true}>
                                <span>
                                    <Select
                                        {...field}
                                        value={value}
                                        options={provisionOptions}
                                        placeholder="Selecione uma opção"
                                        onChange={(option: any) => field.onChange(option.value)}
                                        error={provisionError}
                                        showError={false}
                                        selectStyles={{
                                            control: (base, data) => {
                                                return {
                                                    ...selectStyles('medium', Boolean(provisionError)).control!(base, data),
                                                    backgroundColor: 'transparent',
                                                    borderRadius: 0,
                                                    borderColor: Boolean(provisionError) ? palette.error[500] : 'transparent',
                                                } as any;
                                            },
                                        }}
                                    />
                                </span>
                            </Tooltip>
                        );
                    }}
                />
            </TableCell>
            <TableCell className="p-0 pl-4 border-r border-r-base-300">
                <Controller name={getPath('billed')} control={control} render={({ field }) => <Switch value={!!field?.value} onChange={field.onChange.bind(this, !field.value)} />} />
            </TableCell>
            <TableCell className="p-0 border-r border-r-base-300 max-w-[145px] min-w-[145px]">
                <Tooltip open={true} placement="top" title={purchaseValueError} disableFocusListener={true}>
                    <Controller
                        name={getPath('purchaseValue')}
                        control={control}
                        render={({ field }) => (
                            <CurrencyInput
                                disabled={!isBilled}
                                value={field.value}
                                onValueChange={({ floatValue = 0 }) => field.onChange(floatValue)}
                                placeholder="Ex: 1,0000"
                                withFormat={false}
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={4}
                                fixedDecimalScale={true}
                                inputWrapperClasses="!border-0 !rounded-none"
                            />
                        )}
                    />
                </Tooltip>
            </TableCell>
            <TableCell className="p-0 border-r border-r-base-300">
                <Controller
                    control={control}
                    name={getPath('justification')}
                    render={({ field }) => (
                        <Textarea
                            {...field}
                            error={justificationError}
                            showErrorMessage={false}
                            disabled={(isQuantityGreaterThanBalance && withBalance) || isBilled ? false : item.preApproved}
                            placeholder="Digite uma justificativa"
                            className="block !rounded-[0px] border-transparent min-h-[unset] h-12"
                            required={(isQuantityGreaterThanBalance && withBalance) || !item.preApproved || isBilled}
                        />
                    )}
                />
            </TableCell>
            <TableCell className="p-0 pl-4">
                <RemoveButton size="medium" className="!static opacity-100 mt-0" onClick={handleRemove} />
            </TableCell>
        </TableRow>
    );
};

export default ProductItem;
