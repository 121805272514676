import Modal from 'components/core/modal';
import Text from 'components/core/text';
import Empty from 'components/empty';
import useGetExternalProvider from 'services/queries/providers/use-get-external-provider';
import ModalProviderConsultingContent from './content';
import Spinner from 'components/core/spinner';

type ProviderConsultingModalProps = {
    providerId: number;
    onClose: () => void;
};

const ModalProviderConsulting = ({ providerId, onClose }: ProviderConsultingModalProps) => {
    const { data, isLoading, isError } = useGetExternalProvider(providerId);

    return (
        <Modal
            onClose={onClose}
            contentClassnames="w-[1300px]"
            closeOnClickOutside={false}
            headerClassNames="border-b border-gray-200"
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading md:text-3xl text-xl">
                    Dados fiscais
                </Text>
            }>
            {isLoading ? (
                <Spinner fixed={false} parentClasses="p-6" />
            ) : isError ? (
                <Empty className="p-6" title="Nenhum dado fiscal relacionado a este fornecedor foi encontrado." />
            ) : (
                <ModalProviderConsultingContent data={data!} />
            )}
        </Modal>
    );
};

export default ModalProviderConsulting;
