import { useState } from 'react';
import DatatableToolbarActions from '../sales-orders/service-order/purchase-order/actions';
import { MenuItem } from 'components/menu';

type CustomToolbarSelectProps = {
    content: MenuItem[];
    footer?: MenuItem[];
};

const Toolbar = ({ content, footer }: CustomToolbarSelectProps) => {
    const toolbarAnchor = useState<HTMLElement | null>(null);

    return <DatatableToolbarActions toolbarAnchor={toolbarAnchor} content={content} footer={footer} />;
};

export default Toolbar;
