import ServiceOrderFinancialBalanceTotalsTable, { ServiceOrderFinancialBalanceTotalsTableItem } from './table';
import ExpenseTotalCard from '../../expenses/total-card';
import { GetOrderServicesBalanceTotalReponse } from 'services/queries/order-services/use-get-order-services-balance-total';
import { useState } from 'react';
import FinancialBalanceModalTaxes from './modal-taxes';
import FinancialBalanceTaxLabel from './financial-balance-table-label';

export type ServiceOrderFinancialBalanceTotalsProps = {
    totals?: GetOrderServicesBalanceTotalReponse;
};

const headContainerClassName = 'items-center xl:!items-end';
const valueContentClassName = '!w-[unset] !text-right items-center xl:items-end';

const ServiceOrderFinancialBalanceTotals = ({ totals }: ServiceOrderFinancialBalanceTotalsProps) => {
    const [isModalTaxOpen, setIsModalTaxOpen] = useState(false);

    const data: ServiceOrderFinancialBalanceTotalsTableItem[][] = [
        [
            {
                label: 'Saldo Líquido',
                className: (totals?.balance?.netValue?.projected || 0) < 0 ? 'text-system-danger-500' : 'text-system-success-500',
                secondClassName: (totals?.balance?.netValue?.current || 0) < 0 ? 'text-system-danger-500' : 'text-system-success-500',
                value: totals?.balance?.netValue?.projected,
                secondValue: totals?.balance?.netValue?.current,
                percentage: totals?.balance?.netValue?.projectedPercentage,
                percentageSecondValue: totals?.balance?.netValue?.currentPercentage,
            },
            {
                label: 'Saldo operacional',
                className: (totals?.balance?.operational?.projected || 0) < 0 ? 'text-system-danger-500' : 'text-system-success-500',
                secondClassName: (totals?.balance?.operational?.current || 0) < 0 ? 'text-system-danger-500' : 'text-system-success-500',
                value: totals?.balance?.operational?.projected,
                secondValue: totals?.balance?.operational?.current,
                percentage: totals?.balance?.operational?.projectedPercentage,
                percentageSecondValue: totals?.balance?.operational?.currentPercentage,
            },
        ],
        [
            {
                label: 'Impostos',
                value: totals?.balance?.tax,
                percentage: totals?.balance?.taxPercentage,
                customLabelElement: <FinancialBalanceTaxLabel onOpenTaxModal={setIsModalTaxOpen.bind(this, true)} />,
            },
            {
                label: 'Comissões',
                value: totals?.balance?.commission,
                percentage: totals?.balance?.commissionPercentage,
            },
            {
                label: 'Total Despesas Administrativas',
                value: totals?.expenses?.administrative?.total,
            },
            {
                label: 'Total Despesas Administrativas Pagas',
                className: 'text-system-danger-500',
                value: totals?.expenses?.administrative?.paid,
            },
        ],
        [
            {
                label: 'A pagar (Realizado)',
                value: totals?.expenses?.toPayRealized,
            },
            {
                label: 'A pagar (Não Realizado)',
                className: 'text-system-danger-500',
                value: totals?.expenses?.toPayNotRealized,
            },
            {
                label: 'Estornos',
                value: totals?.expenses?.refund,
                className: (totals?.expenses?.refund || 0) > 0 ? 'text-system-success-500' : '',
            },
            {
                label: 'A pagar (total)',
                value: totals?.expenses?.toPay,
            },
            {
                label: 'Total Operacional',
                withSpace: true,
                value: totals?.expenses?.totalOperational,
            },
        ],
        [
            {
                label: 'Total Receitas',
                className: 'text-heading font-medium',
                value: totals?.receipts?.total || 0,
            },
            {
                label: 'Recebido',
                value: totals?.receipts?.paid,
                className: 'text-system-success-500',
            },
            {
                label: 'A receber',
                value: totals?.receipts?.open,
            },
            {
                label: 'Desconto/Acréscimo',
                value: totals?.receipts?.difference,
                className: (totals?.receipts?.difference || 0) < 0 ? 'text-system-danger-500' : 'text-system-success-500',
            },
        ],
        [
            {
                label: 'Saldo atual (Realizado)',
                className: (totals?.results?.currentRealized || 0) < 0 ? 'text-system-danger-500' : 'text-system-success-500',
                value: totals?.results?.currentRealized,
                percentage: totals?.results?.currentRealizedPercentage || 0,
            },
            {
                label: 'Budget Atualizado',
                percentage: totals?.results?.potentialPercentage,
                className: (totals?.results?.potential || 0) < 0 ? 'text-system-danger-500' : 'text-system-success-500',
                value: totals?.results?.potential,
            },
        ],
        [
            ...(totals?.expenses?.categories?.map((category) => {
                const value = Number(category?.value || 0);

                return {
                    label: category.name,
                    className: 'text-base-500',
                    value,
                };
            }) || []),
        ],
    ].map((item) => item.map((i) => ({ ...i, id: Math.random() })));

    return (
        <>
            <div className="flex flex-col lg:grid grid-cols-2 gap-5 mb-7">
                <ExpenseTotalCard
                    containerClassName="!justify-between"
                    variant="total"
                    title="Total Budget"
                    value={{
                        data: totals?.totalBudget || 0,
                        contentContainerClassName: valueContentClassName,
                    }}
                    percentage={{
                        data: totals?.totalBudgetPercentage,
                    }}
                    head={{
                        containerClassName: headContainerClassName,
                    }}
                />
                <ExpenseTotalCard
                    containerClassName="!justify-between"
                    variant="total"
                    title="Total Pago"
                    value={{
                        data: totals?.total || 0,
                        contentContainerClassName: valueContentClassName,
                    }}
                    percentage={{
                        data: totals?.totalPercentage,
                    }}
                    head={{
                        containerClassName: headContainerClassName,
                    }}
                />
                {data.map((items, index) => (
                    <ServiceOrderFinancialBalanceTotalsTable key={`table_${index}`} items={items} />
                ))}
            </div>
            {isModalTaxOpen && <FinancialBalanceModalTaxes onClose={setIsModalTaxOpen.bind(this, false)} taxes={totals?.taxes} />}
        </>
    );
};

export default ServiceOrderFinancialBalanceTotals;
