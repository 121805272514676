import NotificationsPage from 'pages/private/notifications';
import Page from 'pages/shared/page';
import { RouteObject } from 'react-router-dom';
import { Rule } from 'types/permissions';

const notifications: RouteObject[] = [
    {
        path: 'notificacoes',
        element: <Page Component={NotificationsPage} code="notificacoes" permissions={[Rule.List]} title="Notificações" />,
    },
];

export default notifications;
