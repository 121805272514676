import { useOutletContext } from 'react-router-dom';
import { Person } from 'types/models/person';
import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import ListPage from 'pages/shared/crud/list';
import { personBankAccountsListConfig } from './utils';

const PersonBankAccountsTab = ({ page }: WithPagePermissionsProps) => {
    const { id: personId } = useOutletContext<Partial<Person>>();

    const list = personBankAccountsListConfig(personId);

    return <ListPage {...list} page={page} />;
};

export default PersonBankAccountsTab;
