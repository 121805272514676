import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SvgIcoShow from 'components/core/icon/files/ico-show';
import Text from 'components/core/text';

type FinancialBalanceTaxLabelProps = {
    onOpenTaxModal: () => void;
};

const FinancialBalanceTaxLabel = ({ onOpenTaxModal }: FinancialBalanceTaxLabelProps) => {
    return (
        <div className="flex items-center gap-2">
            <Text as="span" className="text-gray-600 font-medium uppercase">
                Imposto
            </Text>
            <Tooltip placement="top" title="Ver impostos" disableFocusListener={true}>
                <IconButton TouchRippleProps={{ classes: { child: 'text-system-info-100' } }} className="hover:bg-opacity-30 hover:bg-system-info-100" onClick={onOpenTaxModal}>
                    <SvgIcoShow width={16} height={16} className="text-system-info-500" />
                </IconButton>
            </Tooltip>
        </div>
    );
};

export default FinancialBalanceTaxLabel;
