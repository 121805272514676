import { useQuery } from 'react-query';
import { getSaleOrderDocumentKey } from './keys';
import apiGraphQl from 'services/graphql';
import { SaleOrderDocument } from 'types/models/sale-order';
import { getSaleOrderDocument } from './utils';

type Response = {
    saleOrderDocumentByPk: Partial<SaleOrderDocument>;
};

const useGetSaleOrderDocument = (fields: string[], saleOrderId?: string, saleOrderDocumentId?: string) => {
    const request = async () => apiGraphQl<Response>(getSaleOrderDocument(fields, saleOrderDocumentId)).then((data) => data.saleOrderDocumentByPk);

    return useQuery(getSaleOrderDocumentKey(saleOrderId, saleOrderDocumentId), request, { enabled: Boolean(saleOrderDocumentId) });
};

export default useGetSaleOrderDocument;
