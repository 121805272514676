import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import Text from 'components/core/text';
import Modal from 'components/core/modal';
import AddressForm from 'components/forms/address';
import Spinner from 'components/core/spinner';
import Input from 'components/core/form/input';
import Select from 'components/core/form/select';
import DatePicker from 'components/core/datepicker';
import PersonContract from 'components/forms/person-contract';
import theme from 'settings/theme';
import personSchema from './utils';
import { genders, maritalStatuses } from 'utils/statics';
import { CreatePersonPayload } from 'types/models/person';
import { useShowApiErrors } from 'hooks/error/show-api-errors';
import Emails from 'components/emails';
import Phones from 'components/phones';
import FormButtons from 'components/form-buttons';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';
import Switch from 'components/core/switch';
import { hexToRgba } from 'utils/string';
import { Option } from 'types/general';

type PeopleProps = {
    isBuilding: boolean;
    isSubmitting: boolean;
    title: string;
    defaultValues: Partial<CreatePersonPayload>;
    error?: any;
    profiles: Option[];
    isUser?: boolean;
    onClose?: () => void;
    onSubmit: (data: CreatePersonPayload) => void;
    onChangeUser: (value: boolean) => void;
};

const CreateOrUpdatePerson = ({ isBuilding, isSubmitting, title, onClose, onSubmit, defaultValues, error, onChangeUser, profiles, isUser }: PeopleProps) => {
    const { personId } = useParams();
    const goToParentRoute = useNavigateToParentRoute();

    const methods = useForm<CreatePersonPayload>({
        mode: 'onSubmit',
        resolver: yupResolver(personSchema(!!personId, isUser)) as any,
        defaultValues,
    });

    const { formState, control, handleSubmit, reset, setError, setValue } = methods;

    const { showFormErrors } = useShowApiErrors();

    const handleAbleUser = (value: boolean) => {
        onChangeUser(value);

        if (!value) {
            setValue('user', undefined);
        }
    };

    const handleClose = () => {
        if (!!onClose) {
            return onClose();
        }

        goToParentRoute();
    };

    const isUpdate = !!personId;

    useEffect(() => {
        if (!!error) {
            showFormErrors({ error, setError });
        }
    }, [showFormErrors, error, setError]);

    useEffect(() => {
        if (!!defaultValues) {
            reset(defaultValues);
        }
    }, [defaultValues, reset]);

    const handleChangeSelect = (onChange: (...event: any[]) => void) => (option: any) => onChange(option.value);

    return (
        <Modal closeOnClickOutside={false} onClose={handleClose} contentClassnames="w-[1024px]">
            {isBuilding ? (
                <div className="p-4">
                    <Spinner color={theme.extend.colors.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <FormProvider {...methods}>
                    <form className="py-5 px-7" onSubmit={handleSubmit(onSubmit)}>
                        <Text as="h3" variant="h4" className="text-heading mb-5">
                            {title}
                        </Text>
                        <div className="grid grid-cols-3 gap-4 mb-4">
                            <Controller name="name" control={control} render={({ field }) => <Input {...field} label="Nome" error={formState.errors.name?.message} />} />
                            <Controller name="title" control={control} render={({ field }) => <Input {...field} label="Nome para exibição" error={formState.errors.title?.message} />} />
                            <Controller
                                name="document"
                                control={control}
                                render={({ field }) => <Input {...field} label="CPF" autoComplete="nope" mask="999.999.999-99" error={formState.errors.document?.message} />}
                            />
                            <Controller
                                name="birthday"
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        inputProps={{
                                            label: 'Data de nascimento',
                                            placeholderText: 'Selecione uma data',
                                            error: formState.errors?.birthday?.message,
                                            ...field,
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                name="maritalStatus"
                                control={control}
                                render={({ field }) => {
                                    const value = maritalStatuses.find((item) => item.value === field.value);

                                    return (
                                        <Select
                                            {...field}
                                            value={value}
                                            options={maritalStatuses}
                                            label="Estado civil"
                                            placeholder="Selecione uma opção"
                                            error={formState.errors.maritalStatus?.message}
                                            onChange={handleChangeSelect(field.onChange)}
                                        />
                                    );
                                }}
                            />
                            <Controller
                                name="gender"
                                control={control}
                                render={({ field }) => {
                                    const value = genders.find((item) => item.value === field.value);

                                    return (
                                        <Select
                                            {...field}
                                            value={value}
                                            options={genders}
                                            label="Gênero"
                                            placeholder="Selecione uma opção"
                                            error={formState.errors.gender?.message}
                                            onChange={handleChangeSelect(field.onChange)}
                                        />
                                    );
                                }}
                            />
                            <Controller name="email" control={control} render={({ field }) => <Input {...field} type="email" label="E-mail principal" error={formState.errors.email?.message} />} />
                        </div>
                        <div className="border border-base-300 p-4 rounded-[14px] mb-5">
                            <Text as="h4" variant="h5" className="text-heading mb-3">
                                E-mail(s)
                            </Text>
                            <Emails />
                        </div>
                        <div className="border border-base-300 p-4 rounded-[14px] mb-5">
                            <Text as="h4" variant="h5" className="text-heading mb-3">
                                Telefone(s)
                            </Text>
                            <Phones accessor="phones" index={0} />
                        </div>
                        <div className="border border-base-300 p-4 rounded-[14px] mb-5">
                            <Text as="h4" variant="h5" className="text-heading mb-3">
                                Endereço
                            </Text>
                            <AddressForm {...methods} />
                        </div>
                        {!isUpdate && (
                            <div className="border border-base-300 p-4 rounded-[14px] mb-5">
                                <Text as="h4" variant="h5" className="text-heading mb-3">
                                    Contrato
                                </Text>
                                <PersonContract {...methods} baseName="contracts" hideFinalDate={true} />
                            </div>
                        )}

                        <div className="-mx-7 px-7 py-5 mb-5" style={{ backgroundColor: hexToRgba(theme.extend.colors.secondary[100], 0.2) }}>
                            <div className="flex justify-between items-center">
                                <Text variant="body.medium.sm" as="span" className="text-secondary-700">
                                    Esta pessoa será usuária do sistema?
                                </Text>
                                <Switch value={isUser as boolean} onChange={handleAbleUser} />
                            </div>
                            {isUser && (
                                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mt-4">
                                    <Controller
                                        name="user.username"
                                        control={control}
                                        render={({ field }) => <Input {...field} label="Nome de usuário" error={formState.errors.user?.username?.message} />}
                                    />
                                    <Controller
                                        name="user.profile"
                                        control={control}
                                        render={({ field }) => {
                                            const value = profiles.find((item) => item.value === field.value);

                                            return (
                                                <Select
                                                    {...field}
                                                    value={value}
                                                    options={profiles}
                                                    label="Perfil de usuário"
                                                    placeholder="Selecione uma opção"
                                                    error={formState.errors.user?.profile?.message}
                                                    onChange={handleChangeSelect(field.onChange)}
                                                />
                                            );
                                        }}
                                    />
                                </div>
                            )}
                        </div>

                        <FormButtons isLoading={isSubmitting} />
                    </form>
                </FormProvider>
            )}
        </Modal>
    );
};

export default CreateOrUpdatePerson;
