import { ReportsContextProvider } from '../../context';
import ReportOrderServicesContent from './content';

const ReportOrderServicesPage = () => {
    return (
        <ReportsContextProvider>
            <ReportOrderServicesContent />
        </ReportsContextProvider>
    );
};

export default ReportOrderServicesPage;
