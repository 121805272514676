import DocumentsForm, { DocumentPayload } from 'components/documents';
import { useMemo } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import useCreateOrUpdateSaleOrderDocument from 'services/queries/sales-order/use-create-or-update-sale-order-document';
import useGetSaleOrderDocument from 'services/queries/sales-order/use-get-sale-order-document';
import { CrudPageProps } from 'types/graphql';
import { ClassificationType } from 'types/models/classification';

const fields = ['id', 'document.name', 'document.observation', 'document.description', 'document.classificationId', 'document.file.id', 'document.file.filename', 'document.file.path'];

const CreateOrUpdateSaleOrderDocumentPage = ({ title = 'Documento' }: CrudPageProps<{}>) => {
    const ctx = useOutletContext();
    const { saleOrderId, saleOrderDocumentId } = useParams();

    const { isLoading: isSubmitting, mutate } = useCreateOrUpdateSaleOrderDocument(saleOrderId, saleOrderDocumentId, ctx);
    const { isLoading: isBuilding, data: saleOrderDocument } = useGetSaleOrderDocument(fields, saleOrderId, saleOrderDocumentId);

    const defaultValues = useMemo<Partial<DocumentPayload>>(
        () => ({
            file: saleOrderDocument?.document?.file,
            classification: saleOrderDocument?.document?.classificationId,
            name: saleOrderDocument?.document?.name,
            description: saleOrderDocument?.document?.description,
            observation: saleOrderDocument?.document?.observation,
        }),
        [saleOrderDocument]
    );

    return (
        <DocumentsForm classificationType={ClassificationType.DocumentSaleOrder} defaultValues={defaultValues} title={title} onSubmit={mutate} isSubmitting={isSubmitting} isBuilding={isBuilding} />
    );
};

export default CreateOrUpdateSaleOrderDocumentPage;
