import Block from '../block';
import Empty from 'components/empty';
import theme from 'settings/theme';
import ReportPageDetailsChartBar from '../charts/bar';
import ReportPageDetailsChartDoughnut from '../charts/doughnut';
import { getMonthsAverage, getReportPageBlockColor } from '../utils';
import Spinner from 'components/core/spinner';
import { ReportPageByYearResponse } from 'types/models/reports';
import { TransactionType } from 'types/models/transaction';

type ReportPageDetailsContentProps = {
    haveSelectedYears: boolean;
    isError: boolean;
    currentYear?: ReportPageByYearResponse;
    reportPageType?: TransactionType;
    previousYear?: ReportPageByYearResponse;
    year?: number;
    compareYear?: number;
    isGenerated: boolean;
    isLoading: boolean;
};

const ReportPageDetailsContent = ({ haveSelectedYears, isError, currentYear, reportPageType, previousYear, year, compareYear, isGenerated, isLoading }: ReportPageDetailsContentProps) => {
    const monthsAverage = getMonthsAverage(currentYear?.months);

    const currentYearMainBlock: any = {
        reportMonths: currentYear?.months,
        reportQuarters: currentYear?.quarters,
        resume: currentYear?.resume,
        branches: (currentYear as any)?.branches,
    };

    const previousYearMainBlock: any = {
        reportMonths: previousYear?.months,
        reportQuarters: previousYear?.quarters,
        resume: previousYear?.resume,
        branches: (previousYear as any)?.branches,
    };

    if (isLoading) {
        return <Spinner />;
    }

    if (!isGenerated) {
        return <Empty className="flex w-full h-72 items-center justify-center" titleClassName="!text-lg" title="Esse relatório ainda está sendo gerado. Por favor, volte mais tarde." />;
    }

    if (!haveSelectedYears) {
        return <Empty className="flex w-full h-72 items-center justify-center" title="Selecione os anos para fazer uma consulta." />;
    }

    if (isError) {
        return <Empty className="flex w-full h-72 items-center justify-center" title="Ocorreu um erro, por favor, tente novamente." />;
    }

    return (
        <>
            <div className="flex flex-col xl:grid grid-cols-4 gap-6 mb-8 mt-5 ">
                <ReportPageDetailsChartBar blocks={currentYear?.blocks || []} monthsAverage={monthsAverage} className="col-span-3" />
                <ReportPageDetailsChartDoughnut blocks={currentYear?.blocks || []} currentYearTotal={currentYear?.resume?.total!} />
            </div>
            {Boolean(currentYear?.months) && (
                <Block
                    reportPageType={reportPageType!}
                    currentYear={year!}
                    previousYear={compareYear!}
                    currentYearData={currentYearMainBlock}
                    previousYearData={previousYearMainBlock}
                    type="resume"
                    color={theme.extend.colors.heading}
                />
            )}
            {currentYear?.blocks?.map((block, index) => {
                const compareBlock = previousYear?.blocks?.find(({ id }) => id === block.id);
                const color = block?.color ?? getReportPageBlockColor(index);

                return (
                    <Block
                        key={block.id}
                        title={block?.name}
                        reportPageType={reportPageType!}
                        currentYear={year!}
                        previousYear={compareYear!}
                        currentYearData={block}
                        previousYearData={compareBlock!}
                        color={color}
                        currentYearTotal={currentYear?.resume?.total}
                        blockBranches={(block as any).reportBranches}
                        filter={block.filter}
                    />
                );
            })}
        </>
    );
};

export default ReportPageDetailsContent;
