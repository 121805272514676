import Modal from 'components/core/modal';
import { useEffect } from 'react';
import Text from 'components/core/text';
import { useOutletContext, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Spinner from 'components/core/spinner';
import useCreateOrUpdate from 'services/queries/crud/use-create-or-update';
import queries from 'services/queries/providers/keys';
import useGetDetails from 'services/queries/crud/use-get-details';
import { ProviderBankAccount } from 'types/models/provider';
import useTheme from '@mui/material/styles/useTheme';
import FormButtons from 'components/form-buttons';
import { BankAccountClassNames } from 'types/models/bank';
import { providerBankAccountConfig } from '../utils';
import BankAccountForm from 'components/forms/bank-account';
import { CreateOrUpdateBankAccountPayload } from 'types/models/bank-accounts';
import { banckAccountSchema } from 'pages/private/bank-accounts/create-or-update/utils';
import { GraphqlPaginationVariables } from 'types/graphql';

const CreateOrUpdateProviderBankAccountPage = () => {
    const { palette } = useTheme();
    const { providerBankAccountId, providerId = '' } = useParams();

    const isUpdate = !!providerBankAccountId;

    const ctx = useOutletContext<GraphqlPaginationVariables<any>>();

    const methods = useForm<CreateOrUpdateBankAccountPayload>({
        mode: 'onSubmit',
        defaultValues: {
            account: '',
            accountDigit: '',
            agency: '',
            className: BankAccountClassNames.Provider,
            name: '',
            pix: '',
        },
        resolver: yupResolver(banckAccountSchema) as any,
    });

    const { handleSubmit, reset } = methods;

    const { data: providerBankAccount, isLoading: isLoadingDetails } = useGetDetails<ProviderBankAccount>(providerBankAccountConfig, providerBankAccountId);

    const { mutateAsync: createOrUpdateBankAccount, isLoading: isSubmitting } = useCreateOrUpdate(
        {
            id: providerBankAccount?.id?.toString(),
            graphql: {
                query: isUpdate ? queries.updateBankAccount : queries.createBankAccount,
                table: 'providerBankAccount',
            },
        },
        ctx
    );

    const submit = async (data: CreateOrUpdateBankAccountPayload) => {
        try {
            if (isUpdate) {
                const { bank, ...rest } = data;

                const payload = {
                    ...rest,
                    bankId: bank,
                };

                await createOrUpdateBankAccount(payload);
            } else {
                const payload = {
                    providerId,
                    bankAccount: {
                        data: {
                            bankId: data.bank,
                            account: data.account,
                            agency: data.agency,
                            className: data.className,
                            name: data.name,
                            type: data.type,
                            ...(Boolean(data.pix) && { pix: data.pix }),
                            ...(Boolean(data.pixType) && { pixType: data.pixType }),
                            ...(Boolean(data.agencyDigit) && { agencyDigit: data.agencyDigit }),
                            ...(Boolean(data.accountDigit) && { accountDigit: data.accountDigit }),
                        },
                    },
                };

                await createOrUpdateBankAccount(payload);
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    useEffect(() => {
        if (!!providerBankAccount?.bankAccount) {
            const { bankAccount } = providerBankAccount;

            reset({
                account: bankAccount.account,
                agency: bankAccount.agency,
                bank: bankAccount.bankId,
                className: bankAccount.className,
                name: bankAccount.name,
                type: bankAccount.type,
                accountDigit: bankAccount.accountDigit || undefined,
                agencyDigit: bankAccount.agencyDigit || undefined,
                pix: bankAccount.pix || undefined,
                pixType: bankAccount.pixType || undefined,
            });
        }
    }, [providerBankAccount, reset]);

    return (
        <Modal closeOnClickOutside={false} contentClassnames="w-[800px]">
            {isLoadingDetails ? (
                <div className="p-4">
                    <Spinner color={palette.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <FormProvider {...methods}>
                    <form className="py-5 px-7" onSubmit={handleSubmit(submit)}>
                        <Text as="h3" variant="h4" className="text-heading mb-5">
                            {isUpdate ? 'Atualizar conta bancária' : 'Nova conta bancária'}
                        </Text>
                        <BankAccountForm isCompanyForm={false} className="mb-6" withAccountType={true} />
                        <FormButtons isLoading={isSubmitting} />
                    </form>
                </FormProvider>
            )}
        </Modal>
    );
};

export default CreateOrUpdateProviderBankAccountPage;
