import { CrudPageProps } from 'types/graphql';
import TableCell from '@mui/material/TableCell/TableCell';
import { useOutletContext } from 'react-router-dom';
import IconButton from '@mui/material/IconButton/IconButton';
import useTheme from '@mui/material/styles/useTheme';
import { useNavigate } from 'react-router-dom';
import dictionary from 'utils/dictionary';
import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import ListPage from 'pages/shared/crud/list';
import { ExpenseDetailsResponse } from 'services/queries/expenses/types';
import DownloadButton from 'components/download';
import SvgIcoPlus from 'components/core/icon/files/ico-plus';

const ExpenseDocuments = ({ page }: WithPagePermissionsProps) => {
    const { palette } = useTheme();
    const navigate = useNavigate();

    const { id } = useOutletContext<ExpenseDetailsResponse>();

    const listPageProps: CrudPageProps<any, any> = {
        title: 'Documentos',
        graphql: {
            columns: [
                {
                    name: 'id',
                    options: { display: 'excluded' },
                },

                {
                    name: 'document.name',
                    label: dictionary.content.graphql['name'],
                    options: {
                        customBodyRender: (value = '-') => value,
                    },
                },
                {
                    name: 'document.classification.name',
                    label: dictionary.content.graphql['classification'],
                    options: {
                        customBodyRender: (value = '-') => value,
                    },
                },
                {
                    name: 'document.file.id',
                    options: { display: 'excluded' },
                },
                {
                    name: 'document.file.originalName',
                    label: 'Arquivo',
                    options: {
                        customBodyRender: (value, tableMeta) => {
                            if (!value) {
                                return '-';
                            }

                            const [, , , fileId, filename] = tableMeta.rowData;

                            return <DownloadButton fileName={filename} fileId={fileId} />;
                        },
                    },
                },
                {
                    name: 'actions',
                    label: 'Ações',
                    options: {
                        customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />,
                    },
                },
            ],
            table: 'expenseDocument',
            searchableField: 'document.name',
            where: {
                expenseId: { _eq: id },
            },
            statusPath: 'document.status',
        },
        hideHeader: true,
        api: {
            useApiTodelete: true,
            endpoint: `/expenses/${id}/documents`,
        },
    };

    return (
        <div>
            <div className="flex mb-5">
                <div className="flex-1" />
                <IconButton
                    className="ml-auto"
                    color="secondary"
                    size="large"
                    sx={{ backgroundColor: palette.secondary.main, '&:hover': { backgroundColor: palette.secondary.dark } }}
                    onClick={() => navigate('novo')}>
                    <SvgIcoPlus width={16} height={16} color={palette.grey[100]} />
                </IconButton>
            </div>
            <ListPage page={page} {...listPageProps} />
        </div>
    );
};

export default ExpenseDocuments;
