import IconButton from '@mui/material/IconButton/IconButton';
import useTheme from '@mui/material/styles/useTheme';
import PageHeader from 'components/page/header';
import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import { useMemo, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import useGetPaginatedWithFilters from 'services/queries/general/use-get-paginated-with-filters';
import { Rule } from 'types/permissions';
import IcoPlus from 'components/core/icon/files/ico-plus';
import Datatable from 'components/core/table/datatable';
import ProductsAdvancedSearch from 'components/advanced-search/products';
import { productsColumns, productsStocksOrderByMap } from './utils';
import useDelete from 'services/queries/crud/use-delete';
import { getPaginatedWithFiltersKey } from 'services/queries/general/keys';
import { useQueryClient } from 'react-query';
import ConfirmModal from 'components/core/modal/confirm';
import { removeFalsyValues } from 'utils/object';
import { Product, ProductsFilters } from 'types/models/product';
import ModalProductStocks from './modal-stocks';
import useConfig from 'store/config/use-config';

const ENDPOINT = '/products';

const ProductsPage = ({ page: pageProps }: WithPagePermissionsProps) => {
    const [page, setPage] = useState(1);
    const [filters, setFilters] = useState<ProductsFilters>({});
    const [orderBy, setOrderBy] = useState<{ [K: string]: string }>({});
    const [selectedProductId, setSelectedProductId] = useState<number | null>(null);
    const [selectedProductToDeleteId, setSelectedProductToDeleteId] = useState<number>();

    const { data, isLoading } = useGetPaginatedWithFilters<Partial<Product>>({ page, endpoint: ENDPOINT, filters, orderBy });
    const { mutateAsync: deleteProduct, isLoading: isDeletingProduct } = useDelete({
        apiPayload: { endpoint: `/products`, useApiTodelete: true },
    });

    const queryClient = useQueryClient();
    const { palette } = useTheme();
    const { config } = useConfig();

    const actions = pageProps?.rules.map((item) => item.key);
    const canCreateProduct = actions?.includes(Rule.Create);

    const handleDeleteProduct = async () => {
        try {
            if (!!selectedProductToDeleteId) {
                await deleteProduct({ id: selectedProductToDeleteId });

                queryClient.invalidateQueries(getPaginatedWithFiltersKey(ENDPOINT, filters, page, config.tables.rowsPerPage));
                setSelectedProductToDeleteId(undefined);
            }
        } catch (e) {
            console.error('handleDelete: ', e);
        }
    };

    const hasSelectedFilters = useMemo(() => {
        const cleanedFilters = removeFalsyValues(filters);

        return Boolean(Object.values(cleanedFilters).length);
    }, [filters]);

    const submitFilters = (data: ProductsFilters) => {
        setPage(1);
        setFilters((prev) => ({ ...prev, ...data }));
    };

    return (
        <>
            <PageHeader
                className="mb-6"
                title="Produtos"
                right={
                    canCreateProduct ? (
                        <IconButton component={Link} to="novo" color="secondary" size="large" sx={{ backgroundColor: palette.secondary.main, '&:hover': { backgroundColor: palette.secondary.dark } }}>
                            <IcoPlus width={16} height={16} color={palette.grey[100]} />
                        </IconButton>
                    ) : undefined
                }
            />
            <Datatable
                data={data?.items || []}
                columns={productsColumns(setSelectedProductId)}
                actions={actions}
                loading={isLoading}
                onDelete={setSelectedProductToDeleteId}
                hasSelectedFilters={hasSelectedFilters}
                advancedSearchComponent={<ProductsAdvancedSearch filters={filters} onSubmit={submitFilters} />}
                options={{
                    searchPlaceholder: 'Buscar produto',
                    sort: true,
                    filter: false,
                    search: true,
                    print: false,
                    download: false,
                    viewColumns: false,
                    serverSide: true,
                    searchAlwaysOpen: true,
                    enableNestedDataAccess: '.',
                    pagination: true,
                    elevation: 21,
                    selectableRows: 'none',
                    count: data?.total,
                    page,
                    onChangePage: setPage,
                    onSearchChange: (searchText) => {
                        setPage(1);
                        setFilters((prev) => ({ ...prev, searchText }));
                    },
                    onColumnSortChange: (changedColumn, direction) => {
                        setOrderBy({
                            [productsStocksOrderByMap[changedColumn] || changedColumn]: direction.toUpperCase(),
                        });
                    },
                }}
            />
            <Outlet />
            {Boolean(selectedProductToDeleteId) && (
                <ConfirmModal
                    onConfirmAction={handleDeleteProduct}
                    onClose={setSelectedProductToDeleteId.bind(this, undefined)}
                    description="Você tem certeza que deseja apagar este produto?"
                    title="Apagar produto"
                    isLoading={isDeletingProduct}
                />
            )}
            {Boolean(selectedProductId) && <ModalProductStocks productId={selectedProductId!} onClose={setSelectedProductId.bind(this, null)} />}
        </>
    );
};

export default ProductsPage;
