import * as React from 'react';
const SvgIcoMap = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" width="1em" height="1em" {...props}>
        <path
            fill="currentColor"
            d="M7.364 3a.747.747 0 0 0-.384.105l-4.602 2.63A.75.75 0 0 0 2 6.386v10.546a.75.75 0 0 0 1.122.651l4.265-2.437 4.9 2.45a.746.746 0 0 0 .733-.02l4.602-2.63a.75.75 0 0 0 .378-.65V3.75a.75.75 0 0 0-1.122-.651l-4.265 2.437-4.9-2.45A.746.746 0 0 0 7.37 3h-.006Zm6.022 12.64 3.114-1.78V5.042l-3.114 1.78v8.817Zm-1.5-8.79L8.114 4.964v8.868l3.772 1.886V6.85ZM3.5 6.822l3.114-1.78v8.818L3.5 15.64V6.821Z"
        />
    </svg>
);
export default SvgIcoMap;
