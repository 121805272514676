import { useQuery } from 'react-query';
import api from 'services/api';
import { getTransactionDocumentKey } from './keys';
import { DocumentPayload } from 'components/documents';
import { Modify } from 'types/general';
import { File } from 'types/models/file';

type TransactionDocumentResponse = Modify<
    Pick<Partial<DocumentPayload>, 'classification' | 'description' | 'type' | 'name' | 'observation' | 'file'>,
    {
        file: File;
    }
>;

const useGetTransactionDocument = (transactionId?: string, documentId?: string) => {
    const request = async () => {
        const { data } = await api.get<TransactionDocumentResponse>(`transactions/${transactionId}/documents/${documentId}`);

        return data;
    };

    return useQuery(getTransactionDocumentKey(transactionId, documentId), request, {
        enabled: !!transactionId && !!documentId,
    });
};

export default useGetTransactionDocument;
