import { useQuery } from 'react-query';
import api from 'services/api';
import { getExpenseKey } from './keys';
import { ExpenseDetailsResponse } from './types';

const useGetExpense = (id?: string | number) => {
    const request = async () => {
        const { data } = await api.get<ExpenseDetailsResponse>(`/expenses/${id}`);

        return data;
    };

    return useQuery(getExpenseKey(id), request, { enabled: !!id });
};

export default useGetExpense;
