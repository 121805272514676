import TableCell from '@mui/material/TableCell/TableCell';
import ExternalButton from 'components/buttons/external';
import Badge from 'components/core/badge';
import Button from 'components/core/button';
import { CrudPageProps } from 'types/graphql';
import { TransactionInvoiceStatus } from 'types/models/transaction';
import { formatMoney } from 'utils/money';

export const orderServiceDeductionsListConfig = (handleChangeInvoiceStatus: any, isSubmittingChangeInvoiceStatus: boolean, orderServiceId?: string, saleOrderId?: string): CrudPageProps<{}> => ({
    title: 'Deduções',
    customTableOptions: {
        selectableRows: 'none',
    },
    graphql: {
        table: 'orderServiceDeduction',
        searchableField: 'transaction.code',
        statusPath: 'status',
        columns: [
            {
                name: 'id',
                options: { display: 'excluded' },
            },
            {
                name: 'transaction.id',
                options: { display: 'excluded' },
            },
            {
                name: 'transaction.code',
                label: 'Código',
                options: {
                    customBodyRender: (code, { rowData }) => {
                        return !!code ? <ExternalButton to={`/app/ordens-de-pagamento/${rowData[1]}`}>{code}</ExternalButton> : '-';
                    },
                },
            },
            {
                name: 'transaction.paid',
                label: 'Paga',
                options: {
                    customBodyRender: (value) => <Badge variant={Boolean(value) ? 'success' : 'error'}>{Boolean(value) ? 'Pagamento confirmado' : 'Aguardando pagamento'}</Badge>,
                },
            },
            {
                name: 'transaction.invoiceStatus',
                label: 'Com nota fiscal',
                options: {
                    customBodyRender: (value) => {
                        const isSent = value === TransactionInvoiceStatus.Sent;

                        return <Badge variant={isSent ? 'success' : 'error'}>{isSent ? 'Sim' : 'Não'}</Badge>;
                    },
                },
            },
            {
                name: 'deducted',
                label: 'Foi deduzido',
                options: {
                    customBodyRender: (value) => <Badge variant={Boolean(value) ? 'success' : 'error'}>{Boolean(value) ? 'Sim' : 'Não'}</Badge>,
                },
            },
            {
                name: 'invoiceSent',
                label: 'Foi enviado NC',
                options: {
                    customBodyRender: (invoiceSent, { rowData }) => {
                        const [orderServiceDeductionId] = rowData;

                        return (
                            <div className="flex items-center">
                                <Badge variant={invoiceSent ? 'success' : 'error'}>{invoiceSent ? 'Sim' : 'Não'}</Badge>
                                {!invoiceSent && (
                                    <Button
                                        disabled={isSubmittingChangeInvoiceStatus}
                                        loading={isSubmittingChangeInvoiceStatus}
                                        size="small"
                                        className="ml-2"
                                        onClick={handleChangeInvoiceStatus(orderServiceDeductionId)}>
                                        Marcar como enviado
                                    </Button>
                                )}
                            </div>
                        );
                    },
                },
            },
            {
                name: 'value',
                label: 'Valor',
                options: { customBodyRender: (value) => formatMoney(value) },
            },
            {
                name: 'actions',
                label: 'Ações',
                options: {
                    customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />,
                },
            },
        ],
        where: {
            orderServiceId: { _eq: orderServiceId },
        },
    },
    api: {
        endpoint: `/sale-orders/${saleOrderId}/service-orders/${orderServiceId}/deductions`,
        useApiTodelete: true,
    },
});
