import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';
import { getStockOptionsQuery } from 'pages/private/stocks/utils';
import { Status } from 'types/general';
import { WhereClause } from 'types/graphql';
import { OrderServiceProductPurchase } from 'types/models/order-service';
import { StockType } from 'types/models/stock';
import { convertArrayToObject } from 'utils/array';
import dictionary from 'utils/dictionary';
import { array, boolean, number, object, string } from 'yup';

export const generateOrderServicePurchaseProductsQuery = (fields: string[], where?: WhereClause<Partial<OrderServiceProductPurchase>>['where']) => {
    if (!fields.length) {
        throw new Error('Missing query fields');
    }

    const fieldsToGraphql = convertArrayToObject(fields);

    return jsonToGraphQLQuery(
        {
            query: {
                __name: 'GetOrderServicePurchaseProducts',
                product: {
                    __aliasFor: 'orderServiceProductPurchase',
                    __args: {
                        orderBy: {
                            product: { name: new EnumType('ASC') },
                        },
                        where: {
                            status: { _eq: Status.Active },
                            ...(Boolean(where) && where),
                        },
                    },
                    ...fieldsToGraphql,
                },
            },
        },
        { pretty: true }
    );
};

export const createOrUpdateOrderPurchaseSchema = object({
    products: array(
        object({
            id: number(),
            balance: number(),
            quantity: number().min(1, 'Quantidade precisa ser maior que 1').required(dictionary.validation.required),
            value: number(),
            product: object({
                code: string(),
                name: string(),
                classificationByClassificationUnitId: object({ name: string() }),
            }),
            preApproved: boolean().required(dictionary.validation.required).default(false),
            billed: boolean().default(false),
            provision: string().required(dictionary.validation.required),
            justification: string().required(dictionary.validation.required),
            purchaseValue: number().when('billed', ([billed], schema) => {
                return billed ? schema.required(dictionary.validation.required).moreThan(0, dictionary.validation.number.moreThan(0)) : schema;
            }),
        })
    ),
    company: number().required(dictionary.validation.required),
    formOrigin: object({
        stock: number().required(dictionary.validation.required),
    }),
});

export const servicePurchaseProductsQueryFields = ['id', 'product.id', 'product.name', 'product.code', 'product.classificationByClassificationUnitId.name', 'quantity', 'value', 'balance'];

export const formatProductsResponse = (data) => {
    return data.map((item) => ({
        ...item,
        value: item.id,
        label: `${item.code} ${item.name}`,
    }));
};

export const generateStocksOptionsQuery = (whereClause?: object) => getStockOptionsQuery({ type: { _eq: StockType.Default }, ...whereClause });
