import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import { getPeopleDetailsKey, getPersonDetailsTabKey } from './keys';
import useToast from 'hooks/toast/use-toast';
import { Person } from 'types/models/person';
import { useShowApiErrors } from 'hooks/error/show-api-errors';

const useCreateOrUpdatePerson = (personId?: string, onSuccess?: (person: Person) => void) => {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const { showToastErrors } = useShowApiErrors();

    const isUpdate = !!personId;

    const request = async (payload: any) => {
        const method = isUpdate ? api.patch : api.post;
        const endpoint = isUpdate ? `/people/${personId}` : `/people`;

        return method<Person>(endpoint, payload);
    };

    return useMutation(request, {
        onSuccess: async ({ data }) => {
            if (!!onSuccess) {
                return onSuccess(data);
            }

            navigate(`/app/pessoas/${data?.id}`, { replace: true });

            if (isUpdate) {
                await queryClient.invalidateQueries(getPersonDetailsTabKey(personId));
                await queryClient.invalidateQueries(getPeopleDetailsKey(personId));
            }

            showToast(`Pessoa ${isUpdate ? 'atualizada' : 'criada'} com sucesso`, 'success');
        },
        onError: (err: any) => {
            showToastErrors(err);
        },
    });
};

export default useCreateOrUpdatePerson;
