import { useNavigate, useParams } from 'react-router-dom';
import { useServiceOrderContext } from '../context';
import { Steps } from '../types';
import { useCallback, useEffect } from 'react';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import Tabs from '@mui/material/Tabs/Tabs';
import Tab from '@mui/material/Tab/Tab';
import ServiceOrderGeneral from '../general';
import ServiceOrderSaleScope from './sale-scope';
import ServiceOrderFinancial from './financial';
import { SaleOrder } from 'types/models/sale-order';
import ServiceOrderPurchaseScope from '../purchase-scope';
import useGetServiceOrder from 'services/queries/order-services/use-get-service-order';
import Spinner from 'components/core/spinner';
import isEqual from 'lodash/isEqual';
import { OrderServiceStatusEnum } from 'types/models/order-service';

type ServiceOrderCreateProps = {
    title: string;
} & Partial<SaleOrder>;

const CreateOrUpdateOrderService = ({ title, customer, products = [] }: ServiceOrderCreateProps) => {
    const { saleOrderId, orderServiceId } = useParams();
    const navigate = useNavigate();

    const handleClose = () => navigate('..');

    const { state, changeStep, setContent, hideStep } = useServiceOrderContext();

    const { data: serviceOrder, isLoading: isLoadingServiceOrder } = useGetServiceOrder(saleOrderId, orderServiceId);

    const canUpdatePurchaseScope = [OrderServiceStatusEnum.AwaitingRelease, OrderServiceStatusEnum.AwaitingPurchaseProducts].includes(serviceOrder?.orderServiceStatus?.slug!);

    const getComponent = (slug: Steps) => {
        const items = new Map<Steps, any>([
            [Steps.GeneralInfo, ServiceOrderGeneral],
            [Steps.SaleScope, ServiceOrderSaleScope],
            [Steps.PurchaseScope, ServiceOrderPurchaseScope],
            [Steps.Financial, ServiceOrderFinancial],
        ]);

        const Component = items.get(slug);

        return <Component customerId={customer?.id} saleOrderProducts={products} canUpdatePurchaseScope={canUpdatePurchaseScope} /> ?? null;
    };

    const handleChangeTab = useCallback((_: any, nextSlug: any) => changeStep(state.currentStep, nextSlug), [state.currentStep, changeStep]);

    useEffect(() => {
        if (serviceOrder) {
            const saleProducts = products.map((saleProduct) => {
                const registeredSaleProduct = (serviceOrder?.saleProducts || []).find((item) => item.product?.id === saleProduct.product?.id);
                const isChecked = Boolean(registeredSaleProduct);

                return {
                    checked: isChecked,
                    isRegisteredSaleProduct: Boolean(registeredSaleProduct),
                    predictedEndDate: registeredSaleProduct?.predictedEndDate || '',
                    predictedStartDate: registeredSaleProduct?.predictedStartDate || '',
                    quantity: registeredSaleProduct?.quantity || 0,
                    value: Number(registeredSaleProduct?.value || saleProduct.netValue),
                    product: {
                        id: registeredSaleProduct?.product?.id || saleProduct.product?.id || 0,
                        name: registeredSaleProduct?.product?.name || saleProduct.product?.name || '',
                    },
                    balance: registeredSaleProduct?.balance || saleProduct?.balance || 0,
                    actualQuantity: registeredSaleProduct?.quantity || 0,
                    saleProductId: registeredSaleProduct?.id,
                };
            });

            const content = {
                contractor: {
                    value: serviceOrder.contractor?.id || 0,
                    label: serviceOrder.contractor?.name || '',
                },
                engineer: {
                    value: serviceOrder.engineer?.id || 0,
                    label: serviceOrder.engineer?.name || '',
                },
                observations: serviceOrder.observations || '',
                competenceDate: serviceOrder.competenceDate,
                startDate: serviceOrder.startDate || '',
                title: serviceOrder.title || '',
                endDate: serviceOrder.endDate || '',
                installments: serviceOrder.receipt?.transactions?.length || 0,
                transactions: serviceOrder.receipt?.transactions?.map((item) => ({
                    description: item.description,
                    dueDate: item.dueDate,
                    grossValue: item.grossValue,
                    order: item.order,
                    paymentForm: item.paymentForm?.id,
                    paid: item.paid,
                    transactionId: item.id,
                })),
                address: {
                    zip: serviceOrder.address?.zip,
                    street: serviceOrder.address?.street,
                    number: serviceOrder.address?.number,
                    neighbourhood: serviceOrder.address?.neighbourhood,
                    compliment: serviceOrder.address?.compliment,
                    stateId: serviceOrder.address?.state?.id,
                    cityId: serviceOrder.address?.city?.id,
                },
                customerAddress: false, // TODO: verificar como popular este atributo
                purchaseProducts:
                    (serviceOrder.purchaseProducts?.map((item) => ({
                        id: item.id,
                        name: item.product?.name || '',
                        code: item.product?.code || '',
                        value: item.id || 0,
                        classification: { name: item.product?.category?.name || '' },
                        classificationByClassificationUnitId: { name: item.product?.classification?.name || '' },
                        label: item.product?.name || '',
                        productValue: item.value || 0,
                        quantity: item.quantity || 0,
                        provision: item.provision || '',
                        product: item.product?.id || 0,
                    })) as any[]) || [],
                saleProducts,
                canRegisterPurchaseScope: canUpdatePurchaseScope && Boolean(serviceOrder.purchaseProducts?.length),
            };

            hideStep({ slug: Steps.PurchaseScope, hide: !content.canRegisterPurchaseScope });

            if (!isEqual(state.content, content)) {
                setContent(content);
            }
        }
        // eslint-disable-next-line
    }, [serviceOrder, products]);

    if (isLoadingServiceOrder) {
        return (
            <div className="p-4">
                <Spinner />
            </div>
        );
    }

    return (
        <Modal
            contentClassnames="min-w-[90%] max-h-full"
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    {title}
                </Text>
            }
            onClose={handleClose}
            closeOnClickOutside={false}>
            <Tabs variant="scrollable" value={state.currentStep} onChange={handleChangeTab}>
                {state.steps
                    .filter(({ hide = false }) => !hide)
                    .map((item, index) => {
                        const isDisabled = !state.completeds.find((step) => step === item.slug) && item.slug !== state.currentStep && !orderServiceId;

                        return <Tab value={item.slug} disabled={isDisabled} key={`${item.slug}_${index}`} label={item.label.toUpperCase()} />;
                    })}
            </Tabs>
            {getComponent(state.currentStep)}
        </Modal>
    );
};

export default CreateOrUpdateOrderService;
