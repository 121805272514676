import apiGraphQl from 'services/graphql';
import useAuth from 'store/auth/use-auth';
import { Notification } from 'types/models/notification';
import { getAllNotificationsQuery, getNotificationsKey } from './keys';
import { useQuery } from 'react-query';

const useGetNofications = () => {
    const { auth } = useAuth();

    const request = async () => {
        const { items } = await apiGraphQl<{ items: Notification[] }>(getAllNotificationsQuery, { userId: auth.credentials.user.id });

        return items;
    };

    return useQuery(getNotificationsKey, request, {
        enabled: Boolean(auth.credentials.user?.id),
        refetchInterval: 20000,
        refetchIntervalInBackground: true,
        refetchOnWindowFocus: true,
    });
};

export default useGetNofications;
