import { useCallback } from 'react';
import Dropzone from 'components/dropzone';
import useToast from 'hooks/toast/use-toast';
import useUploadFile from 'services/queries/files/use-upload-file';
import useTransactionSendPendingOrderPayment from 'services/queries/transactions/use-transaction-send-pending-order-payment';
import { File as FileApiModel } from 'types/models/file';
import classNames from 'classnames';

type UploadNfProps = {
    data: any[];
};

const UploadPendingOrderPayment = ({ data }: UploadNfProps) => {
    const { showToast } = useToast();

    const { mutateAsync: sendPaymentOrderPayment, isSuccess, isLoading: isSubmitting } = useTransactionSendPendingOrderPayment(data[0]);

    const handleSendPendingOrderPayment = async (file: FileApiModel) => {
        try {
            if (!file?.id) {
                return alert('Arquivo obrigatório');
            }

            await sendPaymentOrderPayment({ file: file.id });

            showToast('Ordem de pagamento pendente enviado com sucesso.', 'success');
        } catch (error) {
            console.log('handleSendPendingOrderPayment', error);

            showToast('Houve um erro ao enviar a ordem de pagamento de pendente.', 'danger');
        }
    };

    const { error, uploadFile, isLoading: isUploading } = useUploadFile(handleSendPendingOrderPayment);

    const handleUploadFiles = useCallback(
        async (files: File[]) => {
            try {
                const [localFile] = files;

                await uploadFile(localFile);
            } catch (error) {
                console.log('handleUploadFiles', error);
            }
        },
        [uploadFile]
    );

    const dropzoneContainerClasses = classNames('h-[48px] flex !flex-row mr-2', !isSuccess ? 'cursor-pointer' : '');

    return (
        <div className="flex items-center">
            <Dropzone
                isLoading={isUploading || isSubmitting}
                isSuccess={isSuccess}
                error={error ? 'Erro ao fazer o upload do arquivo' : ''}
                svgClasses="!mb-0 m-auto"
                containerClasses={dropzoneContainerClasses}
                multiple={false}
                disabled={isSuccess}
                onUploadFiles={handleUploadFiles}
                hasText={false}
            />
        </div>
    );
};

export default UploadPendingOrderPayment;
