import { useParams } from 'react-router-dom';
import useGetOrderServicesProducts from 'services/queries/order-services/use-get-order-services-products';
import Table from '@mui/material/Table/Table';
import TableRow from '@mui/material/TableRow/TableRow';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import Empty from 'components/empty';
import Card from 'components/core/card';
import Text from 'components/core/text';
import { formatNumber } from 'utils/money';
import Spinner from 'components/core/spinner';
import IconButton from '@mui/material/IconButton';
import useTheme from '@mui/material/styles/useTheme';
import { Fragment, useState } from 'react';
import OrderServicePurchaseOrderTableProducts from './purchase-order-table-products';
import SvgIcoMinus from 'components/core/icon/files/ico-minus';
import SvgIcoPlus from 'components/core/icon/files/ico-plus';

const ServiceOrderProducts = () => {
    const { palette } = useTheme();

    const { orderServiceId, saleOrderId } = useParams();

    const { data, isLoading } = useGetOrderServicesProducts({ orderServiceId, saleOrderId });

    const [activeProductId, setActiveProductId] = useState<number>();

    return (
        <>
            {isLoading && <Spinner fixed={true} />}
            <div className="flex items-center mb-6 flex-row justify-between">
                <Text as="h3" variant="h5" className="text-heading mr-1">
                    Produtos
                </Text>
            </div>
            <Card className="p-0">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell width={50} />
                            <TableCell>
                                <Text as="span" className="text-base-500">
                                    Produto
                                </Text>
                            </TableCell>
                            <TableCell>
                                <Text as="span" className="text-base-500">
                                    Quantidade
                                </Text>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Boolean(data?.length) ? (
                            data?.map((item) => {
                                const isActive = item.id === activeProductId;
                                const Icon = isActive ? SvgIcoMinus : SvgIcoPlus;

                                return (
                                    <Fragment key={item.id}>
                                        <TableRow
                                            classes={{ root: 'hover:bg-base-200 hover:bg-opacity-50 cursor-pointer' }}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            onClick={() => setActiveProductId((oldId) => (oldId === item.id ? undefined : item.id))}>
                                            <TableCell>
                                                <IconButton
                                                    color="secondary"
                                                    size="small"
                                                    sx={{
                                                        backgroundColor: palette.secondary.main,
                                                        '&:hover': { backgroundColor: palette.secondary.dark },
                                                    }}>
                                                    <Icon width={13} height={13} className="text-white" />
                                                </IconButton>
                                            </TableCell>
                                            <TableCell>
                                                <Text as="span" className="text-base-500">
                                                    {Boolean(item.name) && Boolean(item.code) ? `#${item.code} - ${item.name}` : '-'}
                                                </Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text as="span" className="text-base-500">
                                                    {formatNumber(Number(item.quantity || 0))}
                                                </Text>
                                            </TableCell>
                                        </TableRow>
                                        {isActive && (
                                            <>
                                                <TableRow className="bg-base-200" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell colSpan={3} className="pt-5">
                                                        <Text as="p" className="text-base-500 mb-5">
                                                            Ordens de compras feitas para o produto <strong className="text-secondary-500">{item.name}</strong>
                                                        </Text>
                                                        <Card elevation={0} className="border">
                                                            <OrderServicePurchaseOrderTableProducts purchaseOrders={item.purchaseOrders} />
                                                        </Card>
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        )}
                                    </Fragment>
                                );
                            })
                        ) : (
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell colSpan={3}>
                                    <Empty title="Nenhum produto até o momento." />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Card>
        </>
    );
};

export default ServiceOrderProducts;
