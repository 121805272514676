import { DatatableColumn } from 'types/graphql';
import { array, number, object } from 'yup';
import { generateReportDefaultValuePercentage, generateReportValuePercentageData, genericReportSchema } from '../utils';
import { SalesOrderReportTotalsValues } from '../types';
import { SalesOrdersReportResponse } from 'types/models/reports';
import { generateReportsPercentageValue } from '../order-services/utils';
import ExternalButton from 'components/buttons/external';
import { saleOrderTypes } from 'utils/statics';
import { getNumberValueOrZero } from 'pages/private/expenses/details/tabs/transactions/utils';
import ReportColumnValuePercentage from '../../components/value-percentage';

export const salesOrdersReportColumns = (showValues = true): DatatableColumn[] => {
    return [
        {
            name: 'id',
            options: {
                display: 'excluded',
            },
        },
        {
            name: 'code',
            label: 'Código',
            options: {
                customBodyRender: (value, tableMeta) => {
                    const [id] = tableMeta.rowData;

                    return Boolean(value) ? <ExternalButton href={`/app/pedidos-de-venda/${id}`}>#{value}</ExternalButton> : '-';
                },
            },
        },
        {
            name: 'type',
            label: 'Tipo do Pedido',
            options: {
                customBodyRender: (type) => {
                    const findedType = saleOrderTypes.find((item) => item.value === type);

                    return <span className="whitespace-nowrap">{findedType?.label || '-'}</span>;
                },
            },
        },
        {
            name: 'company.name',
            label: 'Empresa',
            options: {
                customBodyRender: (value = '-') => <span className="whitespace-nowrap">{value}</span>,
            },
        },
        {
            name: 'branch.name',
            label: 'Filial',
            options: {
                customBodyRender: (value = '-') => <span className="whitespace-nowrap">{value}</span>,
            },
        },
        {
            name: 'customer.title',
            label: 'Cliente',
            options: {
                customBodyRender: (value = '-') => <span className="whitespace-nowrap overflow-hidden inline-block max-w-[300px] text-ellipsis">{value}</span>,
            },
        },
        {
            name: 'classification.name',
            label: 'Tipo de Obra',
            options: {
                customBodyRender: (value = '-') => <span className="whitespace-nowrap">{value}</span>,
            },
        },
        {
            name: 'consultant.name',
            label: 'Consultor',
            options: {
                customBodyRender: (value = '-') => <span className="whitespace-nowrap">{value}</span>,
            },
        },
        {
            name: 'total',
            label: 'Venda',
            options: {
                customBodyRender: (value) => {
                    const formattedValue = getNumberValueOrZero(value);

                    return <ReportColumnValuePercentage showValues={showValues} value={formattedValue} showPercentage={false} classes="text-system-success-500" />;
                },
            },
        },
        {
            name: 'totalCost',
            label: 'Custo Total',
            options: {
                customBodyRender: (value = 0, tableMeta) => {
                    const formattedValue = getNumberValueOrZero(value);

                    const { columnIndex, rowData } = tableMeta;
                    const percentage = rowData[columnIndex + 1]; // get the next column value (percentage)

                    return <ReportColumnValuePercentage showValues={showValues} value={formattedValue} percentage={percentage} classes="text-system-danger-500" />;
                },
            },
        },
        {
            name: 'totalCostPercentage',
            options: {
                display: 'excluded',
            },
        },
        {
            name: 'tax',
            label: 'Imposto',
            options: {
                customBodyRender: (value = 0, tableMeta) => {
                    const formattedValue = getNumberValueOrZero(value);

                    const { columnIndex, rowData } = tableMeta;
                    const percentage = rowData[columnIndex + 1]; // get the next column value (percentage)

                    return <ReportColumnValuePercentage showValues={showValues} value={formattedValue} percentage={percentage} classes="text-system-danger-500" />;
                },
            },
        },
        {
            name: 'taxPercentage',
            options: {
                display: 'excluded',
            },
        },
        {
            name: 'commission',
            label: 'Comissão',
            options: {
                customBodyRender: (value = 0, tableMeta) => {
                    const formattedValue = getNumberValueOrZero(value);

                    const { columnIndex, rowData } = tableMeta;
                    const percentage = rowData[columnIndex + 1]; // get the next column value (percentage)

                    return <ReportColumnValuePercentage showValues={showValues} value={formattedValue} percentage={percentage} classes="text-system-danger-500" />;
                },
            },
        },
        {
            name: 'commissionPercentage',
            options: {
                display: 'excluded',
            },
        },
        {
            name: 'result',
            label: 'Resultado',
            options: {
                customBodyRender: (value = 0, tableMeta) => {
                    const formattedValue = getNumberValueOrZero(value);

                    const { columnIndex, rowData } = tableMeta;
                    const percentage = rowData[columnIndex + 1]; // get the next column value (percentage)

                    const spanClassByValue = formattedValue <= 0 ? 'text-system-danger-500' : 'text-system-success-500';

                    return <ReportColumnValuePercentage showValues={showValues} value={formattedValue} percentage={percentage} classes={spanClassByValue} />;
                },
            },
        },
        {
            name: 'resultPercentage',
            options: {
                display: 'excluded',
            },
        },
    ];
};

export const INITIAL_SALES_ORDER_REPORT_TOTALS = {
    sale: generateReportDefaultValuePercentage(false),
    totalCost: generateReportDefaultValuePercentage(),
    tax: generateReportDefaultValuePercentage(),
    commission: generateReportDefaultValuePercentage(),
    result: generateReportDefaultValuePercentage(),
};

const INITIAL_SALES_ORDER_REPORT_TOTALS_VALUES = {
    sale: 0,
    totalCost: 0,
    tax: 0,
    commission: 0,
    result: 0,
};

export const generateSalesOrderReportTotals = (salesOrderReports: SalesOrdersReportResponse[]): SalesOrderReportTotalsValues => {
    const { sale, totalCost, tax, commission, result } = salesOrderReports.reduce((acc, curr) => {
        return {
            sale: acc.sale + curr.total,
            totalCost: acc.totalCost + curr.totalCost,
            tax: acc.tax + curr.tax,
            commission: acc.commission + curr.commission,
            result: acc.result + curr.result,
        };
    }, INITIAL_SALES_ORDER_REPORT_TOTALS_VALUES);

    return salesOrderReports.reduce((acc, curr) => {
        const generatePercentage = generateReportsPercentageValue(sale);

        const totalsTotalCostValue = acc.totalCost.value + (curr.totalCost || 0);
        const totalsTaxValue = acc.tax.value + (curr.tax || 0);
        const totalsCommissionValue = acc.commission.value + (curr.commission || 0);
        const totalsResultValue = acc.result.value + (curr.result || 0);

        return {
            sale: generateReportValuePercentageData(sale),
            totalCost: generateReportValuePercentageData(totalsTotalCostValue, generatePercentage(totalCost)),
            tax: generateReportValuePercentageData(totalsTaxValue, generatePercentage(tax)),
            commission: generateReportValuePercentageData(totalsCommissionValue, generatePercentage(commission)),
            result: generateReportValuePercentageData(totalsResultValue, generatePercentage(result)),
        };
    }, INITIAL_SALES_ORDER_REPORT_TOTALS);
};

export const salesOrderReportSchema = genericReportSchema().concat(
    object({
        classifications: array(number()).optional(),
    })
);
