import { useMutation } from 'react-query';
import apiGraphQl from 'services/graphql';
import { affectedRowsKey } from 'utils/environment';

const useDeleteSubPages = () => {
    const request = async (pageParentId: number) => {
        return apiGraphQl(
            `mutation DeleteSubPages($pageParentId: Int!) {
                item: deleteSubpage(where: {pageParentId: {_eq: $pageParentId}}) {
                    ${affectedRowsKey}
                }
            }`,
            { pageParentId }
        );
    };

    return useMutation(request);
};

export default useDeleteSubPages;
