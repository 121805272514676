import useToast from 'hooks/toast/use-toast';
import { RefundModalPayload } from 'pages/private/payment-order/view/refund-modal';
import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import { getTransactionKey } from './keys';

const useTransactionRefund = (transactionId: string) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const request = async (payload: RefundModalPayload) => api.patch(`/transactions/${transactionId}/refund`, payload);

    return useMutation(request, {
        onSuccess: () => {
            showToast('Estorno realizado com sucesso!', 'success');
            queryClient.invalidateQueries(getTransactionKey(transactionId));
        },
        onError: () => {
            showToast('Ocorreu um erro no estorno, por favor, tente novamente.', 'danger');
        },
    });
};

export default useTransactionRefund;
