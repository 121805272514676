import ConfirmModal from 'components/core/modal/confirm';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';
import { useParams } from 'react-router-dom';
import useChangeExpenseStatus from 'services/queries/expenses/use-change-expense-status';

type ApproveAndDisapproveExpenseProps = {
    mode: 'approve' | 'disapprove';
};

const ApproveOrDisapproveExpensePage = ({ mode }: ApproveAndDisapproveExpenseProps) => {
    const { expenseId } = useParams();
    const goToParentRoute = useNavigateToParentRoute();
    const { mutateAsync: approveOrDisapproveExpense, isLoading: isSubmitting } = useChangeExpenseStatus({ expenseId });

    const handleConfirmApproval = async () => {
        try {
            await approveOrDisapproveExpense({ mode });
        } catch (error) {
            console.log('handleConfirmApproval', error);
        }
    };

    const isApprove = mode === 'approve';

    return (
        <ConfirmModal
            color={isApprove ? 'success' : 'error'}
            title={isApprove ? 'Aprovar' : 'Reprovar'}
            description={`Você tem certeza que deseja ${isApprove ? 'Aprovar' : 'Reprovar'} essa despesa?`}
            isLoading={isSubmitting}
            onClose={goToParentRoute}
            onConfirmAction={handleConfirmApproval}
        />
    );
};

export default ApproveOrDisapproveExpensePage;
