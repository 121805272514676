import { useQuery } from 'react-query';
import apiGraphQl from 'services/graphql';
import { Profile } from 'types/models/profile';
import { getProfileKey } from './keys';

const useGetProfile = (query: string, profileId?: string) => {
    const request = async () => apiGraphQl<{ profileByPk: Partial<Profile> }>(query).then(({ profileByPk }) => profileByPk);

    return useQuery(getProfileKey(profileId), request, { enabled: !!profileId });
};

export default useGetProfile;
