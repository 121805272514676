import Datatable from 'components/core/table/datatable';
import useGetCompanyBankAccounts from 'services/queries/bank-accounts/use-get-bank-accounts';
import { columnDatatableBankAccounts, mappedOrderByBankAccountsColumns } from './utils';
import PageHeader from 'components/page/header';
import IconButton from '@mui/material/IconButton';
import useTheme from '@mui/material/styles/useTheme';
import { Link, Outlet } from 'react-router-dom';
import { useCallback, useState } from 'react';
import useDelete from 'services/queries/crud/use-delete';
import ConfirmModal from 'components/core/modal/confirm';
import { useQueryClient } from 'react-query';
import { getBankAccountsKey } from 'services/queries/bank-accounts/keys';
import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import qs from 'qs';
import { Pagination } from 'types/pagination';
import { ResponseBankAccount } from 'types/models/bank-accounts';
import BankAccountsAdvancedSearch from 'components/advanced-search/bank-accounts';
import useGetQueryParam from 'hooks/router/use-get-query-param';
import useConfig from 'store/config/use-config';
import SvgIcoPlus from 'components/core/icon/files/ico-plus';

export type OrderBy = { name: string; direction: string };

const BankAccountsListPage = ({ page: pageWithPermissions }: WithPagePermissionsProps) => {
    const { palette } = useTheme();
    const { config } = useConfig();
    const queryClient = useQueryClient();

    const [page, setPage] = useState(1);
    const [searchText, setSearchText] = useState<string | null>(null);
    const [orderBy, setOrderBy] = useState<OrderBy | null>(null);
    const [bankAccountIdToDelete, setBankAccountIdToDelete] = useState<number>();

    const status = useGetQueryParam('filter[status]');

    const queryFilters = qs.stringify(
        {
            filter: {
                status,
                searchText,
            },
            ...(Boolean(orderBy) && {
                orderBy: {
                    [orderBy!.name]: orderBy!.direction,
                },
            }),
            page,
            totalPerPage: config.tables.rowsPerPage,
        },
        { addQueryPrefix: true, encode: false, arrayFormat: 'brackets', skipNulls: true }
    );

    const { data: bankAccountsData, isLoading: isLoadingCompanyBankAccounts } = useGetCompanyBankAccounts<Pagination<ResponseBankAccount[]>>(queryFilters);

    const { mutateAsync: deleteBankAccountMutate, isLoading: isDeletingBankAccount } = useDelete<any>({
        apiPayload: { endpoint: `/company-bank-accounts`, useApiTodelete: true },
    });

    const handleDeleteBankAccount = useCallback(async () => {
        try {
            if (!bankAccountIdToDelete) {
                return;
            }

            await deleteBankAccountMutate({ id: bankAccountIdToDelete });

            queryClient.invalidateQueries(getBankAccountsKey(queryFilters));

            setBankAccountIdToDelete(undefined);
        } catch (error) {
            console.error('Houve um erro ao deletar', error);
        }
    }, [bankAccountIdToDelete, deleteBankAccountMutate, queryClient, queryFilters]);

    const handleChangeOrderBy = (changedColumn: string, direction: 'desc' | 'asc') => {
        const columnToOrder = mappedOrderByBankAccountsColumns[changedColumn];
        const upperCasedDirection = direction.toUpperCase();

        if (!columnToOrder) {
            return;
        }

        setOrderBy({ name: columnToOrder, direction: upperCasedDirection });
    };

    const rules = pageWithPermissions?.rules.map((item) => item.key) || [];

    return (
        <>
            <PageHeader
                className="mb-8"
                title="Contas Bancárias"
                right={
                    <IconButton component={Link} to="novo" color="secondary" size="large" sx={{ backgroundColor: palette.secondary.main, '&:hover': { backgroundColor: palette.secondary.dark } }}>
                        <SvgIcoPlus width={16} height={16} color={palette.grey[100]} />
                    </IconButton>
                }
            />
            <Datatable
                actions={rules}
                data={bankAccountsData?.items || []}
                loading={isLoadingCompanyBankAccounts}
                options={{
                    elevation: 21,
                    enableNestedDataAccess: '.',
                    filter: false,
                    searchAlwaysOpen: true,
                    searchOpen: true,
                    searchPlaceholder: 'Pesquisar',
                    count: bankAccountsData?.total,
                    page,
                    pagination: true,
                    download: false,
                    filterArrayFullMatch: false,
                    print: false,
                    viewColumns: false,
                    search: true,
                    serverSide: true,
                    onChangePage: setPage,
                    onSearchChange: setSearchText,
                    onColumnSortChange: handleChangeOrderBy,
                }}
                columns={columnDatatableBankAccounts(true)}
                onDelete={setBankAccountIdToDelete}
                advancedSearchComponent={<BankAccountsAdvancedSearch />}
                hasSelectedFilters={Boolean(status)}
            />
            {Boolean(bankAccountIdToDelete) && (
                <ConfirmModal
                    title="Apagar conta bancária?"
                    description="Você tem certeza que deseja apagar esta conta bancária?"
                    isLoading={isDeletingBankAccount}
                    onClose={() => setBankAccountIdToDelete(undefined)}
                    onConfirmAction={handleDeleteBankAccount}
                />
            )}
            <Outlet context={queryFilters} />
        </>
    );
};

export default BankAccountsListPage;
