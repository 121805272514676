import { Option } from 'types/general';
import { ExpenseInstallments } from '../installments';
import { ExpenseProducts } from '../products';
import { ExpenseNature, ExpenseType } from 'types/models/expense';
import { FormFavoredType } from 'types/models/favored';

export type ReducerAction = {
    type: ContextActions;
    payload: any;
};

export type ContextActions = 'SET_STEP_COMPLETED' | 'SET_CURRENT_STEP' | 'SET_CONTENT' | 'SET_STEPS';

export type Step = {
    slug: Steps;
    label: string;
    hide?: boolean;
};

export type General = {
    title: string;
    dueDate?: string;
    provision?: string;
    amount: string;
    withProducts?: boolean;
    provisioned: boolean;
    installments: number;
    company: number;
    branch: number;
    favored?: Option & { type: FormFavoredType };
    paymentForm: Option;
    sector?: number | null;
    category?: number | null;
    origin?: number | null;
    subOrigin?: number | null;
    orderService?: number | null;
    fiscalCode?: number;
    documentNumber?: string;
    monthsToRepeat?: number;
    repeated?: boolean;

    // Fields not being sent to api
    observations?: string;
    code?: string;
    favoredBankAccount?: number;
    nature?: ExpenseNature;
    type?: ExpenseType;
    constructionRelated?: boolean;
    competence?: string;
};

export type ExpenseDocument = {
    id: number;
    localFile: {
        mimeType: string;
        url: string;
        id: number;
        path: string;
        pathTmp: any;
        title: any;
        originalName: string;
        filename: string;
    } & File;
    file: number;
    classification: any;
    name: any;
    description: any;
    observation: any;
};

export type Context = {
    general?: General;
    products?: ExpenseProducts[];
    installments?: ExpenseInstallments[];
    documents?: ExpenseDocument[];
};

export type ExpensesState = {
    completeds: Steps[];
    currentStep: Steps;
    steps: Step[];
    content?: Context;
};

export enum Steps {
    GeneralInfo = 'GENERAL',
    Products = 'PRODUCTS',
    Documents = 'DOCUMENTS',
    Summary = 'SUMMARY',
    Installments = 'INSTALLMENTS',
}

export type ContextType = {
    state: ExpensesState;
    setCurrentStep: (slug: Steps) => void;
    changeStep: (currentStepSlug: Steps, nextStepSlug?: Steps, previousStepSlug?: Steps) => void;
    completeStep: (step: Steps) => void;
    getStep: (slug: Steps) => void;
    setContent: (content: ExpensesState['content']) => void;
    hideStep: (payload: { slug: Steps; hide: boolean }) => void;
    setSteps: (payload: Step[]) => void;
    goToStep: (action: 'previous' | 'next') => void;
};
