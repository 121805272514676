import { useQuery } from 'react-query';
import api from 'services/api';
import { getBankAccountsKey } from './keys';

const useGetCompanyBankAccounts = <T>(filters?: string) => {
    const request = async () => {
        const { data } = await api.get<T>(`/company-bank-accounts${Boolean(filters) ? filters : ''}`);

        return data;
    };

    return useQuery(getBankAccountsKey(filters), request);
};

export default useGetCompanyBankAccounts;
