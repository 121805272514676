import { useQuery } from 'react-query';
import { getStockKey } from './keys';
import apiGraphQl from 'services/graphql';
import { QueryConfig, generateQuery } from 'utils/query';
import { PurchaseOrder } from 'types/models/purchase-order';
import { Stock } from 'types/models/stock';

type Response = {
    stockByPk: Partial<Stock>;
};

const useGetStock = (config: QueryConfig<PurchaseOrder>, stockId?: string) => {
    const request = async () => {
        const { stockByPk } = await apiGraphQl<Response>(generateQuery<Stock>(config));

        return stockByPk;
    };

    return useQuery(getStockKey(stockId), request);
};

export default useGetStock;
