import { useQuery } from 'react-query';
import api from 'services/api';
import useToast from 'hooks/toast/use-toast';
import { getOrderServicesProductsKey } from './keys';
import { PurchaseOrder } from 'types/models/purchase-order';
import { Product } from 'types/models/product';

type Params = {
    orderServiceId?: string;
    saleOrderId?: string;
};

type Response = Array<
    {
        purchaseOrders: PurchaseOrder[];
        quantity: string;
    } & Pick<Product, 'id' | 'code' | 'name'>
>;

const useGetOrderServicesProducts = (props: Params) => {
    const { orderServiceId, saleOrderId } = props || {};
    const { showToast } = useToast();

    const request = async () => {
        const { data } = await api.get<Response>(`/sale-orders/${saleOrderId}/service-orders/${orderServiceId}/products`);

        return data;
    };

    return useQuery(getOrderServicesProductsKey({ orderServiceId, saleOrderId }), request, {
        enabled: !!orderServiceId && !!saleOrderId,
        onError: () => {
            showToast(`Ocorreu um erro ao obter a listagem de produtos. Tente novamente.`, 'danger');
        },
    });
};

export default useGetOrderServicesProducts;
