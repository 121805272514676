import { useMemo } from 'react';
import { Link, Outlet, useParams } from 'react-router-dom';
import { formatMoney, formatNumberToPTBR } from 'utils/money';
import { TableHeader, TableItem, TableItemValuesValue } from 'types/models/reports';
import useGetImportFlowNationalizations from 'services/queries/import-flow/use-get-import-flow-nationalizations';
import DRETable from '../../../reports/table/index';
import Spinner from 'components/core/spinner';
import Empty from 'components/empty';
import ImportFlowNationalizationsPercentage from '../nationalizations-percentage';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import qs from 'qs';
import { generateQuantityValueArr } from '../../utils';
import SvgIcoShow from 'components/core/icon/files/ico-show';

const ImportFlowNationalizationsTabPage = () => {
    const { importFlowId } = useParams();

    const { data = [], isLoading, isError } = useGetImportFlowNationalizations(importFlowId);

    const generateTableContents = useMemo(() => {
        const content: TableItem[] = [];
        const headers: TableHeader[][] = [[], []];
        const nationalizationsIds = new Set<number>();

        for (const product of data) {
            const productValues: TableItemValuesValue[] = product.nationalizations.flatMap((nationalization): TableItemValuesValue[] => [
                {
                    type: 'custom',
                    className: 'text-center',
                    value: nationalization.quantity,
                    customComponent: (value = 0) => (Boolean(value) ? formatNumberToPTBR(value) : '-'),
                },
                {
                    type: 'custom',
                    className: 'text-center',
                    value: nationalization.value,
                    customComponent: (value = 0) => (Boolean(value) ? formatMoney(value) : '-'),
                },
                {
                    type: 'custom',
                    className: 'text-center',
                    value: nationalization.finalValue as number,
                    customComponent: (value) => {
                        return Number.isFinite(value) ? (
                            <span className="flex items-center justify-center gap-1">
                                {formatMoney(value)}
                                {Number.isFinite(nationalization.percentage) && <ImportFlowNationalizationsPercentage value={nationalization.percentage!} />}
                            </span>
                        ) : (
                            '-'
                        );
                    },
                },
            ]);

            productValues.push(
                {
                    type: 'custom',
                    className: 'text-center',
                    value: product.quantityTotal,
                    customComponent: (value = 0) => (Boolean(value) ? formatNumberToPTBR(value) : '-'),
                },
                {
                    type: 'custom',
                    className: 'text-center',
                    value: product.average,
                    customComponent: (value = 0) => (Boolean(value) ? formatMoney(value) : '-'),
                }
            );

            const productValuesQuery = qs.stringify(
                {
                    productName: product.product.name,
                    baseValue: product.baseValue,
                    complementaryValue: product.complementaryValue,
                },
                {
                    addQueryPrefix: true,
                    encode: false,
                    arrayFormat: 'brackets',
                }
            );

            content.push({
                title: '',
                hasToggleButton: false,
                values: [
                    {
                        title: (
                            <div className="flex items-center justify-between gap-4">
                                <span>{product.product.name || '-'}</span>
                                {Boolean(product.product.id) && (
                                    <Tooltip placement="top" title="Ver valores" disableFocusListener={true}>
                                        <IconButton
                                            component={Link}
                                            to={product.product.id!.toString() + productValuesQuery}
                                            className="hover:bg-opacity-30 hover:bg-system-info-100 text-system-info-500">
                                            <SvgIcoShow width={16} height={16} />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </div>
                        ),
                        className: 'whitespace-nowrap font-semibold',
                        values: productValues,
                    },
                ],
            });

            for (const nationalization of product.nationalizations) {
                const hasSameNationalization = nationalizationsIds.has(nationalization.id);

                if (hasSameNationalization) {
                    continue;
                }

                nationalizationsIds.add(nationalization.id);

                headers[0].push({
                    title: nationalization.title || '-',
                    colSpan: 3,
                });

                headers[1].push(...generateQuantityValueArr());
            }
        }

        headers[0].push({
            title: 'Média',
            colSpan: 2,
        });

        headers[1].push(...generateQuantityValueArr(false));

        return {
            content,
            headers,
        };
    }, [data]);

    return (
        <>
            {isLoading ? (
                <Spinner fixed={false} parentClasses="p-4" />
            ) : Boolean(data.length) && !isError ? (
                <DRETable className="p-4" fontSize={11} items={generateTableContents.content} headers={generateTableContents.headers} />
            ) : (
                <Empty title="Não há nacionalizações a serem mostradas." />
            )}
            <Outlet />
        </>
    );
};

export default ImportFlowNationalizationsTabPage;
