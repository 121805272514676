import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';
import { Status } from 'types/general';
import { ClassificationType } from 'types/models/classification';

export const generateClassificationQuery = (type?: ClassificationType, whereClause?: any) => {
    return jsonToGraphQLQuery(
        {
            query: {
                __name: 'GetClassificationsOptions',
                items: {
                    __aliasFor: 'classification',
                    __args: {
                        orderBy: { name: new EnumType('ASC') },
                        ...(Boolean(type) && {
                            where: {
                                type: { _eq: type },
                                status: { _eq: Status.Active },
                                ...whereClause,
                            },
                        }),
                    },
                    value: { __aliasFor: 'id' },
                    label: { __aliasFor: 'name' },
                    data: true,
                },
            },
        },
        { pretty: true }
    );
};
