import { useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import Text from 'components/core/text';
import DatePicker from 'components/core/datepicker';
import Input from 'components/core/form/input';
import Select from 'components/core/form/select';
import { PersonContractType } from 'types/models/person';
import { contractTypes } from 'utils/statics';
import _get from 'lodash/get';
import CurrencyInput from 'components/core/form/currency';
import useGetClassifications from 'services/queries/classifications/use-get-classifications';
import { ClassificationType } from 'types/models/classification';
import { generateClassificationQuery } from 'services/queries/classifications/utils';
import Switch from 'components/core/switch';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import theme from 'settings/theme';
import { hexToRgba } from 'utils/string';

type PersonContractProps = {
    className?: string;
    baseName?: string;
    hideFinalDate?: boolean;
} & UseFormReturn<any, any>;

const PersonContract = ({ className = '', baseName, hideFinalDate = false, ...methods }: PersonContractProps) => {
    const { pathname } = useLocation();

    const { control, formState, watch, setValue } = methods;

    const { data: sectors = [] } = useGetClassifications(generateClassificationQuery(ClassificationType.Sector));
    const { data: positions = [] } = useGetClassifications(generateClassificationQuery(ClassificationType.Position));

    const getName = (name: string) => {
        if (!baseName) {
            return name;
        }

        return `${baseName}.${name}`;
    };

    const getError = (name: string): string => _get(formState.errors, `${getName(name)}.message`) as any;

    const contractType = watch(getName('type'));

    const isJuridic = useMemo(() => contractType === PersonContractType.Juridic, [contractType]);

    const isPersonConsultant = watch(getName('consultant'));
    const isContractFormPage = pathname.includes('contratos');

    return (
        <>
            <div className={`grid grid-cols-3 mb-4 gap-4 ${className}`}>
                <Controller
                    name={getName('startDate')}
                    control={control}
                    render={({ field }) => (
                        <DatePicker
                            inputProps={{
                                label: 'Data inicial',
                                placeholderText: 'Selecione uma data',
                                error: getError('startDate'),
                                ...field,
                            }}
                        />
                    )}
                />
                {!hideFinalDate && (
                    <Controller
                        name={getName('finalDate')}
                        control={control}
                        render={({ field }) => (
                            <DatePicker
                                inputProps={{
                                    disabled: !field.value,
                                    label: 'Data final',
                                    placeholderText: 'Selecione uma data',
                                    error: getError('finalDate'),
                                    ...field,
                                }}
                            />
                        )}
                    />
                )}
                <Controller
                    name={getName('salary')}
                    control={control}
                    render={({ field: input }) => {
                        const { value, onChange, ...field } = input;

                        return (
                            <CurrencyInput
                                {...field}
                                value={value}
                                onValueChange={({ floatValue }) => onChange(floatValue)}
                                label="Salário"
                                placeholder=""
                                error={getError('salary')}
                                left={
                                    <Text as="span" variant="body.medium.sm" className="text-heading">
                                        R$
                                    </Text>
                                }
                                leftClasses="bg-base-200 px-4"
                            />
                        );
                    }}
                />
                <Controller
                    name={getName('sector')}
                    control={control}
                    render={({ field }) => {
                        const value = sectors.find((item) => item?.value === field.value);

                        return (
                            <Select
                                {...field}
                                value={value}
                                options={sectors}
                                label="Setor"
                                placeholder="Selecione uma opção"
                                error={getError('type')}
                                onChange={(option: any) => field.onChange(option.value)}
                            />
                        );
                    }}
                />
                <Controller
                    name={getName('position')}
                    control={control}
                    render={({ field }) => {
                        const value = positions.find((item) => item?.value === field.value);

                        return (
                            <Select
                                {...field}
                                value={value}
                                options={positions}
                                label="Cargo"
                                placeholder="Selecione uma opção"
                                error={getError('type')}
                                onChange={(option: any) => field.onChange(option.value)}
                            />
                        );
                    }}
                />
                <Controller
                    name={getName('type')}
                    control={control}
                    render={({ field }) => {
                        const value = contractTypes.find((item) => item?.value === field.value);

                        return (
                            <Select
                                {...field}
                                value={value}
                                options={contractTypes}
                                label="Tipo de contratação"
                                placeholder="Selecione uma opção"
                                error={getError('type')}
                                onChange={(option: any) => field.onChange(option.value)}
                            />
                        );
                    }}
                />
                {isJuridic && (
                    <>
                        <Controller name={getName('juridicName')} control={control} render={({ field }) => <Input {...field} label="Nome jurídico" error={getError('juridicName')} />} />
                        <Controller name={getName('juridicFantasyName')} control={control} render={({ field }) => <Input {...field} label="Nome fantasia" error={getError('juridicFantasyName')} />} />
                        <Controller
                            name={getName('juridicDocument')}
                            control={control}
                            render={({ field }) => <Input {...field} label="CNPJ" autoComplete="nope" mask="99.999.999/9999-99" error={getError('juridicDocument')} />}
                        />
                    </>
                )}
            </div>
            <div
                className={classNames('flex justify-between items-center px-7 py-5', isContractFormPage ? '-mx-7 mb-4' : '-mx-4')}
                style={{ backgroundColor: hexToRgba(theme.extend.colors.secondary[100], 0.2) }}>
                <Text variant="body.medium.sm" as="span" className="text-secondary-700">
                    Esta pessoa é consultora?
                </Text>
                <Switch value={isPersonConsultant} onChange={setValue.bind(this, 'consultant')} />
            </div>
        </>
    );
};

export default PersonContract;
