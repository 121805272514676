import { RouteObject } from 'react-router-dom';
import ListPage from 'pages/shared/crud/list';
import { Rule } from 'types/permissions';
import Page from 'pages/shared/page';
import config from './utils';
import CrudDetails from 'pages/shared/crud/details';
import { UseTabRouteChildren } from 'hooks/tab/use-tab-route';
import CreateOrUpdateProviderPage from 'pages/private/providers/create-or-update';
import ProviderTabDetails from 'pages/private/providers/view/details';
import ProviderContacts from 'pages/private/providers/view/contacts';
import ProviderContactsForm from 'pages/private/providers/view/contacts/form';
import ProviderBankAccounts from 'pages/private/providers/view/bank-accounts';
import CreateOrUpdateProviderBankAccountPage from 'pages/private/providers/view/bank-accounts/create-or-update';
import CustomerProviderAddressesPage from 'pages/private/customer-provider-address';
import CreateOrUpdateAddressPage from 'pages/private/customer-provider-address/create-or-update';

const [list, create, update, details] = config;

const providers: RouteObject[] = [
    {
        path: 'fornecedores',
        element: <Page Component={ListPage} code="fornecedores" permissions={[Rule.List]} {...list} />,
        children: [
            {
                path: 'novo',
                element: <Page Component={CreateOrUpdateProviderPage} withModal={true} code="fornecedores" permissions={[Rule.Create]} {...create} />,
            },
            {
                path: 'editar/:providerId',
                element: <Page Component={CreateOrUpdateProviderPage} withModal={true} code="fornecedores" permissions={[Rule.Update]} {...update} />,
            },
        ],
    },
    {
        path: 'fornecedores/:providerId',
        element: <Page Component={CrudDetails} code="fornecedores" permissions={[Rule.Show]} {...details} />,
        children: [
            {
                index: true,
                element: <Page Component={ProviderTabDetails} code="fornecedores" permissions={[Rule.Show]} />,
                label: 'Detalhes',
            },
            {
                path: 'contatos',
                element: <Page Component={ProviderContacts} code="fornecedores-contatos" permissions={[Rule.List]} />,
                children: [
                    {
                        path: 'novo',
                        element: <Page Component={ProviderContactsForm} withModal={true} code="fornecedores-contatos" permissions={[Rule.Create]} />,
                    },
                    {
                        path: 'editar/:contactId',
                        element: <Page Component={ProviderContactsForm} withModal={true} code="fornecedores-contatos" permissions={[Rule.Update]} />,
                    },
                ],
                label: 'Contatos',
            },
            {
                path: 'contas-bancarias',
                element: <Page Component={ProviderBankAccounts} code="fornecedores-contas-bancarias" permissions={[Rule.List]} />,
                children: [
                    {
                        path: 'novo',
                        element: <Page Component={CreateOrUpdateProviderBankAccountPage} withModal={true} code="fornecedores-contas-bancarias" permissions={[Rule.Create]} />,
                    },
                    {
                        path: 'editar/:providerBankAccountId',
                        element: <Page Component={CreateOrUpdateProviderBankAccountPage} withModal={true} code="fornecedores-contas-bancarias" permissions={[Rule.Update]} />,
                    },
                ],
                label: 'Contas bancárias',
            },
            {
                path: 'enderecos',
                element: <Page Component={CustomerProviderAddressesPage} code="clientes-enderecos" permissions={[Rule.List]} />,
                children: [
                    {
                        path: 'novo',
                        element: <Page Component={CreateOrUpdateAddressPage} withModal={true} code="clientes-enderecos" permissions={[Rule.Create]} />,
                    },
                    {
                        path: 'editar/:entityAddressId',
                        element: <Page Component={CreateOrUpdateAddressPage} withModal={true} code="clientes-enderecos" permissions={[Rule.Update]} />,
                    },
                ],
                label: 'Endereços',
            },
        ] as UseTabRouteChildren[],
    },
];

export default providers;
