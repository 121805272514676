import Button from 'components/core/button';
import PageHeader from 'components/page/header';
import { useMemo, useState } from 'react';
import ReceiptOrderFilter from './filters';
import Datatable from 'components/core/table/datatable';
import useGetTransactionsOutcome from 'services/queries/transactions/use-get-transactions-outcome';
import { receiptOrderListColumns } from './utils';
import useConfig from 'store/config/use-config';
import at from 'lodash/at';
import { formatMoney } from 'utils/money';
import { Transaction } from 'types/models/transaction';
import { MenuItem } from 'components/menu';
import RescheduleTransaction from '../payment-order/actions/reschedule';
import ReportPaymentOrReceipt from '../payment-order/actions/report-payment';
import Text from 'components/core/text';
import Toolbar from 'components/core/table/datatable/components/toolbar';
import { useQueryClient } from 'react-query';
import { getTransactionsOutcomeKey } from 'services/queries/transactions/keys';
import { Outlet, useLocation } from 'react-router-dom';
import qs from 'qs';
import { queryStringURLNumberDecoder } from 'utils/url';
import SvgIcoFilter from 'components/core/icon/files/ico-filter';

const ENDPOINT = '/transactions/incomes';

const ReceiptOrderPage = () => {
    const { config } = useConfig();
    const queryClient = useQueryClient();
    const { search } = useLocation();

    const filtersParams = qs.parse(search, {
        ignoreQueryPrefix: true,
        decoder: queryStringURLNumberDecoder,
    });

    const [page, setPage] = useState(1);
    const [filters, setFilters] = useState<any>(filtersParams);
    const [selectedItems, setSelectedItems] = useState<Transaction[]>([]);
    const [selectedRowsIndexes, setSelectedRowsIndexes] = useState<number[]>([]);
    const [currentModal, setCurrentModal] = useState<string>();
    const [isFilterVisible, setIsFilterVisible] = useState(false);

    const { data, isLoading: isLoadingList } = useGetTransactionsOutcome({
        endpoint: ENDPOINT,
        filters: {
            ...filters,
            page,
            totalPerPage: config.tables.rowsPerPage,
        },
    });

    const { data: totalOrders } = useGetTransactionsOutcome<{ total: number }>({
        endpoint: ENDPOINT,
        filters: {
            ...filters,
            page,
            totalPerPage: config.tables.rowsPerPage,
        },
        type: 'numbers',
    });

    const { items = [] } = data || {};

    const handleSubmitFilter = (data: any) => {
        setFilters(data);
        setIsFilterVisible(false);
    };

    const receiptOrderActions = useMemo<MenuItem[]>(() => {
        const handleOpenModal = (modal: string) => () => {
            setSelectedRowsIndexes([]);
            setCurrentModal(modal);
        };

        return [
            {
                label: 'Informar recebimento',
                onClick: handleOpenModal('informar-recebimento'),
            },
            {
                label: 'Reprogramar',
                onClick: handleOpenModal('reprogramar'),
            },
            // {
            //     label: 'Agrupar ORs',
            //     onClick: () => {
            //         const hasForbiddenStatus = selectedItems.some((item: any) => item.payday && item.paid);

            //         if (hasForbiddenStatus) {
            //             return alert('Somente as ORs em aberto podem ser agrupadas');
            //         }

            //         handleOpenModal('agrupar-ors')();
            //     },
            // },
        ];
    }, []);

    const Component = useMemo(() => {
        const modals = {
            'informar-recebimento': ReportPaymentOrReceipt,
            // 'agrupar-ops': OPsGrouping,
            reprogramar: RescheduleTransaction,
        };

        return modals[currentModal!] ?? null;
    }, [currentModal]);

    const handleUpdateReceipt = () => {
        queryClient.invalidateQueries(getTransactionsOutcomeKey(ENDPOINT, filters));
        setCurrentModal(undefined);
        setSelectedItems([]);
    };

    return (
        <>
            {isFilterVisible && <ReceiptOrderFilter filters={filters} onSubmit={handleSubmitFilter} onClose={setIsFilterVisible.bind(this, false)} />}

            <PageHeader
                className="mb-6"
                title="Ordens de Recebimento"
                right={
                    <div className="flex gap-2">
                        <div className="flex items-center rounded-xl bg-primary-100 bg-opacity-20">
                            <Text variant="body.regular.sm" className="text-heading py-1 px-3 !font-bold">
                                Total ORs: {formatMoney(totalOrders?.total)}
                            </Text>
                        </div>
                        <Button
                            color="inherit"
                            variant="outlined"
                            type="button"
                            size="medium"
                            className="bg-white"
                            onClick={setIsFilterVisible.bind(this, true)}
                            endIcon={<SvgIcoFilter width={17} height={17} color="currentColor" />}>
                            Filtrar
                        </Button>
                    </div>
                }
            />
            <Datatable
                withBorder={false}
                data={items}
                options={{
                    sort: true,
                    filter: false,
                    search: true,
                    searchAlwaysOpen: true,
                    searchPlaceholder: 'Buscar ordem de recebimento',
                    print: false,
                    download: false,
                    viewColumns: true,
                    serverSide: true,
                    enableNestedDataAccess: '.',
                    pagination: false,
                    elevation: 21,
                    count: data?.total,
                    page,
                    rowsSelected: selectedRowsIndexes,
                    onSearchChange: (searchText) => setFilters((prev) => ({ ...prev, searchText })),
                    onChangePage: setPage,
                    customToolbarSelect: () => {
                        const total = at(items, selectedRowsIndexes).reduce((acc, curr) => acc + (curr.netValue || 0), 0);

                        return (
                            <div className="flex items-center">
                                <Text variant="body.regular.sm" className="text-heading bg-primary-100 bg-opacity-20 py-1 px-3 rounded-lg !font-bold">
                                    Total ORs selecionadas: {formatMoney(total)}
                                </Text>
                                <Toolbar content={receiptOrderActions} />
                            </div>
                        );
                    },
                    onRowSelectionChange: (_: any, __: any, rowsSelected) => {
                        setSelectedRowsIndexes?.(rowsSelected || []);

                        const arr = at(items || [], rowsSelected || []);

                        setSelectedItems(arr);
                    },
                }}
                columns={receiptOrderListColumns}
                loading={isLoadingList}
                withItemsPerPage={false}
            />
            {!!currentModal && <Component isReceipt={true} selectedItems={selectedItems} onClose={setCurrentModal.bind(this, undefined)} onSuccess={handleUpdateReceipt} />}
            <Outlet />
        </>
    );
};

export default ReceiptOrderPage;
