import Modal from 'components/core/modal';
import Spinner from 'components/core/spinner';
import Empty from 'components/empty';
import { useParams } from 'react-router-dom';
import useGetOrderServiceFinancialBalance from 'services/queries/order-services/use-get-order-service-financial-balance';
import OrderServiceFinancialBalanceDetailsTabs from './tabs';
import Text from 'components/core/text';
import Divider from '@mui/material/Divider';
import CardsDetails from 'pages/private/sales-notes/components/cards';
import { useMemo } from 'react';
import classNames from 'classnames';
import { formatMoney, formatNumberToPTBR } from 'utils/money';
import { generateFinancialBalanceResumeClasses } from '../utils';

const ServiceOrderFinancialBalanceDetailsPage = () => {
    const { saleOrderId, orderServiceId, financialBalanceId } = useParams();

    const { data, isLoading, isError } = useGetOrderServiceFinancialBalance(orderServiceId, saleOrderId, financialBalanceId);

    const detailsCardsDataArr = useMemo(() => {
        return [
            { label: 'Quantidade', value: data?.summary?.quantity, withIcon: true, valueClassName: '!text-sm' },
            { label: 'Valor Unitário', value: data?.summary?.value, withIcon: true, valueClassName: '!text-sm' },
            { label: 'Budget', value: data?.summary?.budget, withIcon: true, valueClassName: '!text-sm' },
            {
                label: 'Estorno',
                value: data?.summary?.refund,
                withIcon: true,
                valueClassName: classNames('!text-sm', Boolean(data?.summary?.refund) && '!text-system-success-500'),
                iconClassName: classNames(Boolean(data?.summary?.refund) && '!text-system-success-500 !bg-system-success-100'),
            },
            {
                label: 'Total Despesa',
                value: data?.summary?.totalExpense,
                withIcon: true,
                valueClassName: classNames('!text-sm', Boolean(data?.summary?.totalExpense) && '!text-system-danger-500'),
                iconClassName: classNames(Boolean(data?.summary?.totalExpense) && '!text-system-danger-500 !bg-system-danger-100'),
            },
            {
                label: 'Total Pago',
                value: data?.summary?.totalExpensePaid,
                withIcon: true,
                valueClassName: classNames('!text-sm', Boolean(data?.summary?.totalExpensePaid) && '!text-system-success-500'),
                iconClassName: classNames(Boolean(data?.summary?.totalExpensePaid) && '!text-system-success-500 !bg-system-success-100'),
            },
            {
                label: 'A Pagar',
                value: data?.summary?.totalExpenseOpen,
                withIcon: true,
                valueClassName: generateFinancialBalanceResumeClasses(data?.summary?.totalExpenseOpen, true).value,
                iconClassName: generateFinancialBalanceResumeClasses(data?.summary?.totalExpenseOpen, true).icon,
            },
            {
                withIcon: true,
                iconClassName: generateFinancialBalanceResumeClasses(data?.summary?.expenseBalance).icon,
                content: (
                    <>
                        <Text as="p" className="text-base-500 font-normal text-sm mb-2">
                            Saldo
                        </Text>
                        <Text as="strong" className={classNames('font-medium', generateFinancialBalanceResumeClasses(data?.summary?.expenseBalance).value)}>
                            {formatMoney(data?.summary?.expenseBalance)} <small>({formatNumberToPTBR(data?.summary?.expensePercentageBalance || 0)}%)</small>
                        </Text>
                    </>
                ),
            },
        ];
    }, [data]);

    return (
        <Modal
            closeOnClickOutside={false}
            contentClassnames="w-[1200px]"
            headerLeft={
                <div className="flex flex-col sm:flex-row gap-2 sm:items-center">
                    <Text as="h5" variant="h5" className="text-heading !font-bold">
                        {data?.product?.name || '-'}
                    </Text>
                    {Boolean(data?.product?.code) && (
                        <Text as="span" variant="body.regular.xs" className="font-normal text-base-500 italic">
                            #{data!.product.code}
                        </Text>
                    )}
                </div>
            }>
            <Divider />
            {isLoading ? (
                <Spinner fixed={false} parentClasses="p-4" />
            ) : isError ? (
                <Empty title="Saldo financeiro não encontrado." className="p-4" />
            ) : (
                <>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4">
                        {detailsCardsDataArr.map((item, index) => (
                            <CardsDetails key={index} {...item} />
                        ))}
                    </div>
                    <OrderServiceFinancialBalanceDetailsTabs purchaseOrders={data?.purchaseOrders} stockProduct={data?.stockProduct} expenses={data?.expenses} productUnit={data?.product.unit?.name} />
                </>
            )}
        </Modal>
    );
};

export default ServiceOrderFinancialBalanceDetailsPage;
