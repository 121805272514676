import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from '@mui/material/TableRow/TableRow';
import Text from 'components/core/text';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import CurrencyInput from 'components/core/form/currency';
import ExternalButton from 'components/buttons/external';
import { formatMoney } from 'utils/money';
import Textarea from 'components/core/form/textarea';
import { NumberFormatValues } from 'react-number-format/types/types';
import { useMemo, useRef } from 'react';
import { ExpenseProducts, ExpenseProductsPayload } from 'types/models/expense';
import classNames from 'classnames';
import { formatDate } from 'utils/date';

type ProductItemProps = {
    item: ExpenseProducts;
    index: number;
    isQuantityDisabled: boolean;
    isJustificationEnabled?: boolean;
};

const generateErrorClasses = (errorCondition: boolean) => {
    return classNames(errorCondition ? 'text-system-danger-500 outline-system-danger-100' : 'outline-primary-100');
};

const ProductItem = ({ item, index, isQuantityDisabled, isJustificationEnabled = false }: ProductItemProps) => {
    const prevValue = useRef(item.value);
    const prevQuantity = useRef(item.quantity);

    const { control, formState, setValue } = useFormContext<ExpenseProductsPayload>();

    const value = useWatch({ control, name: `products.${index}.value` });
    const quantity = useWatch({ control, name: `products.${index}.quantity` });

    const handleChangeValue = (item: ExpenseProducts, index: number) => (values: NumberFormatValues) => {
        const { floatValue: value = 0 } = values;

        setValue(`products.${index}.value`, value);
        setValue(`products.${index}.total`, item.quantity * value);
    };

    const handleChangeQuantity = (item: ExpenseProducts, index: number) => (values: NumberFormatValues) => {
        const { floatValue: quantity = 0 } = values;

        setValue(`products.${index}.quantity`, quantity);
        setValue(`products.${index}.total`, quantity * item.value);
    };

    const isJustificationDisabled = useMemo(
        () => value <= prevValue.current && quantity === prevQuantity.current && !isJustificationEnabled,
        [value, prevValue, quantity, prevQuantity, isJustificationEnabled]
    );

    return (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} className={!isJustificationDisabled ? 'bg-system-danger-100 bg-opacity-20' : ''}>
            <TableCell>
                <Text className="text-heading">{item.name}</Text>
            </TableCell>
            <TableCell>
                <Text className="text-heading">{item.classificationByClassificationUnitId?.name}</Text>
            </TableCell>
            <TableCell>
                <Text className="text-heading whitespace-nowrap">{formatDate(item.provision)}</Text>
            </TableCell>
            <TableCell>
                <Controller
                    name={`products.${index}.value`}
                    control={control}
                    render={({ field }) => {
                        return (
                            <CurrencyInput
                                left={
                                    <Text as="span" variant="body.medium.sm" className="text-heading">
                                        R$
                                    </Text>
                                }
                                value={field.value}
                                disabled={item.billed && Boolean(prevValue.current)}
                                onValueChange={handleChangeValue(item, index)}
                                placeholder="Ex: 1000"
                                withFormat={false}
                                decimalScale={4}
                                thousandSeparator="."
                                decimalSeparator=","
                                fixedDecimalScale={true}
                                parentClassName="w-[150px]"
                                className={classNames(generateErrorClasses(value > prevValue.current))}
                                error={formState.errors.products?.[index]?.value?.message}
                            />
                        );
                    }}
                />
            </TableCell>
            <TableCell>
                <div className="flex items-baseline">
                    <ExternalButton
                        href={`/app/pedidos-de-venda/${item.orderService?.saleOrderId}/ordens-de-servico/${item.orderService?.id}/ordens-de-compra/${item.purchaseOrder?.id}`}
                        className="mr-2">
                        {item.purchaseOrder?.code}
                    </ExternalButton>
                    {' / '}
                    <ExternalButton href={`/app/pedidos-de-venda/${item.orderService?.saleOrderId}/ordens-de-servico/${item.orderService?.id}`} className="ml-2">
                        {item.orderService?.title}
                    </ExternalButton>
                </div>
            </TableCell>
            <TableCell>
                <Controller
                    name={`products.${index}.quantity`}
                    control={control}
                    render={({ field }) => {
                        return (
                            <CurrencyInput
                                value={field.value}
                                disabled={isQuantityDisabled}
                                fixedDecimalScale={true}
                                onValueChange={handleChangeQuantity(item, index)}
                                placeholder="Ex: 1000"
                                parentClassName="w-[120px]"
                                className={classNames(generateErrorClasses(quantity !== prevQuantity.current))}
                                error={formState.errors.products?.[index]?.quantity?.message}
                            />
                        );
                    }}
                />
            </TableCell>
            <TableCell>
                <Text variant="h6" className="text-heading whitespace-nowrap">
                    {formatMoney(item.total)}
                </Text>
            </TableCell>
            <TableCell>
                <Controller
                    control={control}
                    name={`products.${index}.justification`}
                    render={({ field }) => (
                        <Textarea
                            {...field}
                            disabled={isJustificationDisabled}
                            required={!isJustificationDisabled}
                            error={formState.errors.products?.[index]?.justification?.message}
                            placeholder="Digite uma justificativa"
                            className="min-h-[unset] h-12"
                        />
                    )}
                />
            </TableCell>
        </TableRow>
    );
};

export default ProductItem;
