import { Status } from 'types/general';

export const getAllBanksOptionsKey = ['get', 'all', 'banks'];
export const getPersonKey = (id?: string) => ['people', id];
export const getPeopleDetailsKey = (id?: string) => ['peopleDetails', id];
export const getPersonDetailsTabKey = (id?: string) => [...getPeopleDetailsKey(id), 'tab'];
export const getPersonBankAccountKey = (personId?: string, bankAccountId?: string) => ['person', personId, 'bankAccount', bankAccountId];
export const getPeopleOptionsKey = ['people', 'options'];
export const getAllPersonContractStatusOptionsKey = ['personContractStatus', 'all', 'options'];
export const getPersonContractKey = (id?: string, isDetails?: boolean) => ['personContract', id, isDetails];
export const getConsultantsKey = ['consultants'];

const queries = {
    getAllBanks: `query GetAllBanksOptions {
        items: bank(orderBy: {name: ASC}) {
            value: id
            label: name
        }
    }`,
    getAllPersonContractStatusOptions: `query GetPersonContractStatusOptions {
        items: personContractStatus(orderBy: {title: ASC}, where: {status: {_eq: ${Status.Active}}, isVisible: {_eq: true}}) {
            value: id
            label: title
        }
    }`,
};

export default queries;
