import { jsonToGraphQLQuery } from 'json-to-graphql-query';

export const getProfileQuery = (profileId?: string) => {
    return jsonToGraphQLQuery(
        {
            query: {
                __name: 'GetProfile',
                profileByPk: {
                    __args: { id: Number(profileId || 0) },
                    name: true,
                    dashboardTemplateId: true,
                    profilePageRules: {
                        pageId: true,
                        ruleId: true
                    }
                }
            }
        },
        { pretty: true }
    );
};
