import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { NavLink, resolvePath } from 'react-router-dom';
import Icon from 'components/core/icon';
import Text from 'components/core/text';
import theme from 'settings/theme';
import { MenuItem } from 'types/models/menu';
import useRoute from 'hooks/router/use-route';
import Submenu from './components/submenu';
import { hexToRgba } from 'utils/string';

type NavItemProps = {
    item: MenuItem;
    index: number;
    activeHook: [number, React.Dispatch<React.SetStateAction<number>>];
    isSidebarSmall: boolean;
};

const NavItem = ({ activeHook, index, item, isSidebarSmall }: NavItemProps) => {
    const route = useRoute();
    const [active, setActive] = activeHook;
    const [isMouseHover, setIsMouseHover] = useState(false);

    const isOpen = active === index;

    const linkClasses = classNames(menuItemStyles.linkCommon, isOpen ? 'active-group text-heading' : '');

    const checkSubmenu = useCallback(() => {
        const isActive = (item.pages || []).some((item) => {
            const path = resolvePath(item.link, '/app');

            return route?.pathname.includes(path.pathname);
        });

        if (isActive) {
            setActive(index);
        }
    }, [item, index, setActive, route]);

    const handleToggleSubnav = () => {
        if (active === index) {
            return handleCloseSubnav();
        }

        setActive(index);
    };

    const handleCloseSubnav = () => setActive(-1);

    const handleMouseHover = () => {
        if (isSidebarSmall) {
            setIsMouseHover(true);
            setActive(index);
            checkSubmenu();
        }
    };

    const handleMouseLeave = () => {
        if (isSidebarSmall) {
            setIsMouseHover(false);
            handleCloseSubnav();
            checkSubmenu();
        }
    };

    const handleNavLinkClasses = ({ isActive }) => classNames(subItemClasses, menuItemStyles.subItemText, isActive ? 'active-link' : null);

    useEffect(() => {
        checkSubmenu();
        // eslint-disable-next-line
    }, [isSidebarSmall]);

    if (item.single) {
        return (
            <li title={item.name}>
                <NavLink to={item.pages[0].link} className={handleNavLinkClasses} onClick={handleCloseSubnav}>
                    <i className={menuItemStyles.tick} />
                    <div className={`${!isSidebarSmall ? 'mr-4' : 'w-[100%] flex justify-center'}`}>
                        <Icon color={isOpen ? theme.extend.colors.heading : theme.extend.colors.base[500]} name={item.icon || 'ico-grid'} className="transition-[color]" width={16} height={16} />
                    </div>
                    {!isSidebarSmall && (
                        <Text as="span" variant="body.regular.2xs" className={`${menuItemStyles.labelText} ${isOpen ? 'text-heading' : 'theme-base-500'}`}>
                            {item.name}
                        </Text>
                    )}
                </NavLink>
            </li>
        );
    }

    return (
        <li className="relative" onMouseOver={handleMouseHover} onMouseLeave={handleMouseLeave} title={item.name}>
            <button type="button" className={`${linkClasses} ${isSidebarSmall ? dropdownFloatedStyles.buttonWithItemsCenterAligned : ''}`} onClick={!isSidebarSmall ? handleToggleSubnav : undefined}>
                <span className={menuItemStyles.tick} />
                <div className={`w-4 ${!isSidebarSmall ? 'mr-4' : ''}`}>
                    <Icon color={isOpen ? theme.extend.colors.heading : theme.extend.colors.base[500]} name={item.icon || 'ico-grid'} className="transition-[color]" width={16} height={16} />
                </div>
                {!isSidebarSmall && (
                    <Text as="span" variant="body.regular.2xs" className={`${menuItemStyles.labelText} ${isOpen ? 'text-heading' : 'theme-base-500'}`}>
                        {item.name}
                    </Text>
                )}
                <div className={`${isSidebarSmall ? dropdownFloatedStyles.pointerToSubmenu : ''}`}>
                    <Icon name="ico-chevron-right" color={hexToRgba(theme.extend.colors.heading, 0.25)} className={`ml-4 chevron ${!isSidebarSmall ? 'expanded-menu' : ''}`} width={10} height={10} />
                </div>
            </button>
            {((isSidebarSmall && isMouseHover) || (!isSidebarSmall && isOpen)) && <Submenu handleNavLinkClasses={handleNavLinkClasses} isSidebarSmall={isSidebarSmall} item={item} />}
        </li>
    );
};

export const menuItemStyles = {
    bullet: 'w-[4px] h-[4px] rounded-full bg-base-500 block',
    bulletWrapper: 'w-4 mr-4 flex justify-center',
    labelText: 'flex-1',
    subItemText: 'text-base-500',
    linkCommon: 'text-2xs flex items-center w-full p-4 relative group bg-base-100 hover:bg-[#f4f4f4] transition-[background-color] text-base-500',
    tick: 'tick absolute h-full bg-secondary-600 w-1 -left-1 top-0 rounded-r-md transition-[left] group-hover:left-0',
};

export const subItemClasses = classNames(menuItemStyles.linkCommon, menuItemStyles.labelText, 'block flex-1');

export const dropdownFloatedStyles = {
    dropdownFloated: 'absolute top-0 left-[100%] z-[999] max-h-[324px] w-[300px] overflow-auto shadow-[2px_2px_4px_0_rgba(0,0,0,0.3)] bg-white rounded-r-lg',
    buttonWithItemsCenterAligned: 'd-flex justify-center',
    pointerToSubmenu: 'absolute right-[7px]',
};

export const dropdownFloatedClasses = classNames(dropdownFloatedStyles.dropdownFloated);

export default NavItem;
