import { useQuery } from 'react-query';
import apiGraphQl from 'services/graphql';
import { Option } from 'types/general';
import { getCustomerOptionsKey } from './keys';

type Response = {
    items: Option[];
};

const useGetCustomerOptions = (query: string) => {
    const request = async () => {
        const { items } = await apiGraphQl<Response>(query);

        return items;
    };

    return useQuery(getCustomerOptionsKey, request);
};

export default useGetCustomerOptions;
