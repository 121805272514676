import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import { getBankAccountByIdKey } from './keys';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';

export type ExportFilesPayload = {
    month: number;
    year: number;
};

const useExportBankAccountsFiles = (bankAccountId?: string) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const goToParentRoute = useNavigateToParentRoute();

    const request = async (payload: ExportFilesPayload) => {
        const { data } = await api.get<{ url: string }>(`company-bank-accounts/${bankAccountId}/export/${payload.year}/${payload.month}`);

        return data;
    };

    return useMutation(request, {
        onSuccess: (data) => {
            showToast('Arquivos baixados com sucesso!', 'success');

            window.open(data.url, '_blank');

            queryClient.invalidateQueries(getBankAccountByIdKey(Number(bankAccountId)));

            goToParentRoute();
        },
        onError: () => {
            showToast('Houve um erro ao baixar os arquivos.', 'danger');
        },
    });
};

export default useExportBankAccountsFiles;
