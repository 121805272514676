import { ReportsContextProvider } from '../../context';
import ReportSaleOrdersContent from './content';

const SalesOrdersReportPage = () => {
    return (
        <ReportsContextProvider>
            <ReportSaleOrdersContent />
        </ReportsContextProvider>
    );
};

export default SalesOrdersReportPage;
