import { ElementRef, forwardRef, memo } from 'react';
import classNames from 'classnames';
import ReactSelect, { Props, StylesConfig } from 'react-select';
import Text from 'components/core/text';
import theme from 'settings/theme';
import selectDefaultStyles from './styles';
import ErrorMessage from 'components/error/message';
import SvgIcoChevronDown from 'components/core/icon/files/ico-chevron-down';

export type SelectProps = {
    error?: string;
    label?: string;
    size?: 'small' | 'medium';
    parentClassName?: string;
    selectStyles?: StylesConfig;
    errorMessageClasses?: string;
    labelClassName?: string;
    showError?: boolean;
} & Props;

export const DropdownIndicator = ({ size }: { size: 'small' | 'medium' }) => {
    const isMedium = size === 'medium';

    return <SvgIcoChevronDown color={theme.extend.colors.base[400]} width={isMedium ? 16 : 8.05} height={isMedium ? 9.41 : 8.05} />;
};

export type SelectRef = ElementRef<typeof ReactSelect>;

const Select = forwardRef<SelectRef, SelectProps>(
    (
        {
            errorMessageClasses,
            placeholder = 'Selecione uma opção',
            showError = true,
            isSearchable = true,
            label,
            components,
            error,
            parentClassName,
            size = 'medium',
            selectStyles,
            labelClassName,
            ...rest
        },
        ref
    ) => {
        const container = classNames('relative', parentClassName);
        const labelClasses = classNames(labelClassName, 'pl-0 block mb-1.5', error ? 'text-system-danger-500' : 'text-base-500');

        return (
            <div className={container}>
                {Boolean(label) && (
                    <Text as="label" className={labelClasses}>
                        {label}
                    </Text>
                )}
                <ReactSelect
                    {...rest}
                    placeholder={placeholder}
                    styles={{ ...selectDefaultStyles(size, Boolean(error)), ...selectStyles }}
                    components={{
                        DropdownIndicator: () => <DropdownIndicator size={size} />,
                        ...components,
                    }}
                    noOptionsMessage={() => 'Nenhum resultado'}
                    loadingMessage={() => 'Carregando...'}
                    classNamePrefix="react-select"
                    isSearchable={isSearchable}
                    ref={ref as any}
                    menuPortalTarget={document.body}
                />
                <ErrorMessage className={errorMessageClasses} visible={showError && Boolean(error)}>
                    {error}
                </ErrorMessage>
            </div>
        );
    }
);

export default memo(Select);
