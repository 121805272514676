import { useLayoutEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import useGetReportPage from 'services/queries/report-page/use-get-report-page';
import useGetReportPageByYears from 'services/queries/report-page/use-get-report-page-by-years';
import ReportPageByYearsDetails from './details';
import PageHeader from 'components/page/header';

const ReportPagesGroupedYearsPage = () => {
    const [years, setYears] = useState<number[]>([]);

    const { reportPageId } = useParams();

    const { data: reportPageDetails, isLoading: isLoadingDetails } = useGetReportPage({ id: reportPageId });

    const reportPageByYearsRequests = useGetReportPageByYears({ id: reportPageId!, years });

    const isLoading = useMemo(() => reportPageByYearsRequests.some((item) => item.isLoading) || isLoadingDetails, [reportPageByYearsRequests, isLoadingDetails]);

    const reportPageYearsData = useMemo(() => {
        if (!isLoading) {
            return reportPageByYearsRequests.map(({ data }) => data!);
        }
    }, [reportPageByYearsRequests, isLoading]);

    useLayoutEffect(() => {
        if (!!reportPageDetails?.years?.length) {
            const formattedYears = reportPageDetails.years.map(({ year }) => year);

            setYears(formattedYears);
        }
    }, [reportPageDetails?.years]);

    return (
        <>
            <PageHeader title="Resumo" className="mb-6" />
            <ReportPageByYearsDetails detailsData={reportPageDetails} dataYears={reportPageYearsData || []} isLoading={isLoading} years={years} />
        </>
    );
};

export default ReportPagesGroupedYearsPage;
