import ChangeImageModal from 'components/core/modal/change-image';
import { useParams } from 'react-router-dom';
import useChangePersonImage from 'services/queries/people/use-person-change-image';

const ChangePersonImagePage = () => {
    const { personId } = useParams();

    const { isLoading, mutate } = useChangePersonImage({ id: personId });

    return <ChangeImageModal onSubmit={mutate} isLoading={isLoading} />;
};

export default ChangePersonImagePage;
