import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ExternalButton from 'components/buttons/external';
import Badge from 'components/core/badge';
import Text from 'components/core/text';
import React from 'react';
import { ExpenseProduct } from 'types/models/expense';
import { formatMoney, formatNumberToPTBR } from 'utils/money';

type ExpenseProductsTableProps = {
    products: ExpenseProduct[];
    decimalPlaces?: number;
    footer?: React.ReactElement;
};

const ExpenseProductsTable = ({ products = [], decimalPlaces = 2, footer }: ExpenseProductsTableProps) => {
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Text className="text-base-500 uppercase">Produto</Text>
                        </TableCell>
                        <TableCell>
                            <Text className="text-base-500 uppercase whitespace-nowrap">OC / OS</Text>
                        </TableCell>
                        <TableCell>
                            <Text className="text-base-500 uppercase">Justificativa</Text>
                        </TableCell>
                        <TableCell>
                            <Text className="text-base-500 uppercase">Unidade</Text>
                        </TableCell>
                        <TableCell>
                            <Text className="text-base-500 uppercase whitespace-nowrap">Valor por unidade</Text>
                        </TableCell>
                        <TableCell>
                            <Text className="text-base-500 uppercase">Quantidade</Text>
                        </TableCell>
                        <TableCell>
                            <Text className="text-base-500 uppercase">Total</Text>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {products.map(({ netValue = 0, quantity = 0, ...rest }) => {
                        const orderServiceId = rest.purchaseOrderProduct?.orderServiceProductPurchase?.orderService?.id;
                        const saleOrderId = rest.purchaseOrderProduct?.orderServiceProductPurchase?.orderService?.saleOrder?.id;
                        const purchaseOrderId = rest.purchaseOrderProduct?.purchaseOrder?.id;

                        const isStockPurchaseOrder = !rest.purchaseOrderProduct?.orderServiceProductPurchase && Boolean(purchaseOrderId);
                        const isPurchaseOrder = !!purchaseOrderId && !!orderServiceId && !!saleOrderId;

                        return (
                            <TableRow key={rest.id}>
                                <TableCell>
                                    <Text className="text-heading whitespace-nowrap block">
                                        {rest.product?.code} - {rest.product?.name}
                                    </Text>
                                    {Boolean(rest.purchaseOrderProduct?.billed) && <Badge variant="warning">Faturado</Badge>}
                                </TableCell>
                                <TableCell>
                                    {isPurchaseOrder ? (
                                        <div className="flex rests-center gap-2">
                                            <ExternalButton href={`/app/pedidos-de-venda/${saleOrderId}/ordens-de-servico/${orderServiceId}/ordens-de-compra/${purchaseOrderId}`}>
                                                {rest.purchaseOrderProduct?.purchaseOrder?.code}
                                            </ExternalButton>
                                            <span>/</span>
                                            <ExternalButton href={`/app/pedidos-de-venda/${saleOrderId}/ordens-de-servico/${orderServiceId}`}>
                                                {rest.purchaseOrderProduct?.orderServiceProductPurchase?.orderService?.code}
                                            </ExternalButton>
                                        </div>
                                    ) : isStockPurchaseOrder ? (
                                        <ExternalButton href={`/app/ordens-de-compra-de-estoque/${purchaseOrderId}`}>{rest.purchaseOrderProduct?.purchaseOrder?.code}</ExternalButton>
                                    ) : (
                                        <span className="text-heading">-</span>
                                    )}
                                </TableCell>
                                <TableCell>
                                    <Text className="text-heading whitespace-nowrap">{rest.justification || '-'}</Text>
                                </TableCell>
                                <TableCell>
                                    <Text className="text-heading">{rest.product?.unit?.name || '-'}</Text>
                                </TableCell>
                                <TableCell className="text-heading">{formatMoney(Number(netValue.toFixed(decimalPlaces)), undefined, decimalPlaces)}</TableCell>
                                <TableCell className="text-heading">{formatNumberToPTBR(quantity)}</TableCell>
                                <TableCell>
                                    <Text className="text-heading font-semibold">{formatMoney(quantity * Number(netValue.toFixed(decimalPlaces)), undefined, decimalPlaces)}</Text>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
                {footer}
            </Table>
        </TableContainer>
    );
};

export default ExpenseProductsTable;
