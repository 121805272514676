import ListPage from 'pages/shared/crud/list';
import { RouteObject } from 'react-router-dom';
import { Rule } from 'types/permissions';
import Page from 'pages/shared/page';
import CreateOrUpdatePage from 'pages/shared/crud/create-or-update';
import config from './utils';

const [list, create, update] = config;

const positions: RouteObject[] = [
    {
        // @ts-ignore
        breadcrumb: 'Cargos',
        path: 'cargos',
        element: <Page Component={ListPage} code="cargos" permissions={[Rule.List]} {...list} />,
        children: [
            {
                path: 'novo',
                element: <Page Component={CreateOrUpdatePage} code="cargos" permissions={[Rule.Create]} withModal={true} {...create} />,
            },
            {
                path: 'editar/:id',
                element: <Page Component={CreateOrUpdatePage} code="cargos" permissions={[Rule.Update]} withModal={true} {...update} />,
            },
        ],
    },
];

export default positions;
