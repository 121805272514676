import Select from 'components/core/form/select';
import { Controller, useForm } from 'react-hook-form';
import Input from 'components/core/form/input';
import { Option } from 'types/general';
import CurrencyInput from 'components/core/form/currency';
import Text from 'components/core/text';
import DatePicker from 'components/core/datepicker';
import Textarea from 'components/core/form/textarea';
import _get from 'lodash/get';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useExpensesContext } from './context';
import { Steps } from './context/types';
import useToast from 'hooks/toast/use-toast';
import useGetCompaniesOptions from 'services/queries/companies/use-get-company-options';
import useGetClassifications from 'services/queries/classifications/use-get-classifications';
import { generateClassificationQuery } from 'services/queries/classifications/utils';
import { ClassificationType } from 'types/models/classification';
import useGetOptions from 'services/queries/crud/use-get-options';
import { generateGetBranchOptionsQuery } from 'settings/routes/branches/utils';
import { getProvisioningOptions } from './utils';
import FavoredSelect from 'components/favored-select';
import { yupResolver } from '@hookform/resolvers/yup';
import { currentDateWithoutTimezone, formatDate, isValidDate } from 'utils/date';
import { queries, getAllServiceOrdersKey } from 'services/queries/sales-notes/keys';
import { generalSchema } from './utils';
import { booleanOptions, expenseTypes, natures } from 'utils/statics';
import { GeneralPayload } from './types';
import useGetBankAccountsByEntity from 'services/queries/bank-accounts/use-get-bank-accounts-by-entity';
import { ExpenseNature } from 'types/models/expense';
import { useLocation, useParams } from 'react-router-dom';
import Button from 'components/core/button';
import useTheme from '@mui/material/styles/useTheme';
import { PersonType } from 'types/models/person';
import dayjs from 'dayjs';
import ModalProviderConsulting from 'pages/private/providers/view/modal-provider-consulting';
import SvgIcoArrowRight from 'components/core/icon/files/ico-arrow-right';

type GeneralProps = {
    nature: ExpenseNature;
};

export const favoredPeopleWhere = {
    type: { _eq: PersonType.Internal },
};

export const OCExpenseNatures = [ExpenseNature.PurchaseOrder, ExpenseNature.StockPurchaseOrder];

const General = ({ nature }: GeneralProps) => {
    const [providerId, setProviderId] = useState<number | null>(null);

    const { palette } = useTheme();
    const { showToast } = useToast();
    const { expenseId } = useParams();
    const { pathname } = useLocation();
    const { setContent, hideStep, state, goToStep, completeStep } = useExpensesContext();

    const isRootExpense = pathname.includes('/app/despesas'); // This flag is used to hide nature field for global expenses

    const isUpdate = !!expenseId;

    const { control, formState, handleSubmit, reset, setValue, watch } = useForm<GeneralPayload>({
        mode: 'onSubmit',
        shouldFocusError: true,
        resolver: yupResolver(generalSchema(isRootExpense, isUpdate, nature)) as any,
        defaultValues: {
            competence: dayjs().toISOString(),
            repeated: false,
            monthsToRepeat: 1,
        },
    });

    const [sector, category, origin, favored, paymentForm, withProducts, constructionRelated, repeated] = watch([
        'sector',
        'category',
        'origin',
        'favored',
        'paymentForm',
        'withProducts',
        'constructionRelated',
        'repeated',
    ]);

    const isSaleOrderNature = nature === ExpenseNature.SaleOrder;

    const generalState = useMemo(() => state?.content?.general, [state]);

    const { data: companies = [], isLoading: isLoadingCompanies } = useGetCompaniesOptions();
    const { data: sectors = [], isLoading: isLoadingSectors } = useGetClassifications(generateClassificationQuery(ClassificationType.Sector));

    const { data: categories = [], isLoading: isLoadingCategories } = useGetClassifications(
        generateClassificationQuery(ClassificationType.TransactionCategory, { classificationId: { _eq: sector } }),
        !!sector
    );

    const { data: origins = [], isLoading: isLoadingOrigins } = useGetClassifications(
        generateClassificationQuery(ClassificationType.TransactionOrigin, { classificationId: { _eq: category } }),
        !!category
    );

    const { data: subOrigins = [], isLoading: isLoadingSubOrigins } = useGetClassifications(
        generateClassificationQuery(ClassificationType.TransactionSubOrigin, { classificationId: { _eq: origin } }),
        !!origin
    );

    const { data: paymentForms = [] } = useGetClassifications(generateClassificationQuery(ClassificationType.PaymentForm));
    const { data: branches = [] } = useGetOptions(generateGetBranchOptionsQuery(), ['branch', 'options']);

    const { data: serviceOrders = [], isLoading: isLoadingServiceOrders } = useGetOptions(queries.getAllServiceOrders, getAllServiceOrdersKey, true, (data) => {
        return data.map((item) => ({
            value: item.value,
            label: `${item.label} ${item.title}`,
        }));
    });

    const { data: fiscalCodes = [], isLoading: isLoadingFiscalCodes } = useGetClassifications(
        generateClassificationQuery(ClassificationType.ExpenseFiscalCode, {
            ...(!expenseId && {
                data: {
                    _contains: { isOrderService: OCExpenseNatures.includes(nature) },
                },
            }),
        })
    );

    const isBankAccountFieldVisible = useMemo(() => {
        return paymentForms
            .filter((item) => item.data.withBankAccount)
            .map(({ value }) => value)
            .includes(paymentForm?.value);
    }, [paymentForm, paymentForms]);

    const { data: bankAccounts = [], isLoading: isLoadingBankAccounts } = useGetBankAccountsByEntity(favored, isBankAccountFieldVisible);

    const schedules = getProvisioningOptions({ date: currentDateWithoutTimezone });

    const getError = useCallback((name: string) => _get(formState.errors, `${name}.message`) as any, [formState]);

    const submit = async (data: GeneralPayload) => {
        try {
            setContent({ general: data });
            completeStep(Steps.GeneralInfo);
            goToStep('next');
        } catch (error) {
            console.log('submit', error);
            showToast('Ocorreu um erro. Tente novamente', 'danger');
        }
    };

    const hasConstructionRelated = constructionRelated === booleanOptions[0].value;

    const handleSelectSector = (onChange: (...event: any[]) => void, option: any) => {
        setValue('category', null);
        setValue('origin', null);
        setValue('subOrigin', null);

        onChange(option.value);
    };

    const handleSelectCategory = (onChange: (...event: any[]) => void, option: any) => {
        setValue('origin', null);
        setValue('subOrigin', null);

        onChange(option.value);
    };

    const handleSelectOrigin = (onChange: (...event: any[]) => void, option: any) => {
        setValue('subOrigin', null);

        onChange(option.value);
    };

    const handleChangeWithProducts = (onChange: (...event: any[]) => void) => (option: any) => {
        hideStep({ slug: Steps.Products, hide: !option?.value });

        if (option.value) {
            setValue('amount', undefined);
        }

        onChange(option?.value);
    };

    const handleChangeInstallment = (onChange: (...event: any[]) => void) => (event: any) => {
        hideStep({ slug: Steps.Installments, hide: Number(event.target.value) < 2 });
        onChange(Number(event.target.value));
    };

    const handleChangeRepeated = (onChange: (...event: any[]) => void) => (option: any) => {
        if (Boolean(option.value)) {
            setValue('installments', 1);
        }

        onChange(option.value);
    };

    useEffect(() => {
        if (!!generalState) {
            reset(generalState);
        }
    }, [generalState, reset]);

    useEffect(() => {
        if (isSaleOrderNature) {
            setValue('nature', nature);

            if (Boolean(sectors.length)) {
                const construction = sectors.find(({ label }) => label === 'Comercial');

                if (!construction) {
                    return;
                }

                setValue('sector', construction.value);
            }

            if (Boolean(categories.length)) {
                const materials = categories.find(({ label }) => label === 'Despesas de Decorativa');

                if (!materials) {
                    return;
                }

                setValue('category', materials.value);
            }
        }
    }, [isSaleOrderNature, categories, sectors, nature, setValue]);

    const showAmountField = isUpdate ? false : !withProducts;

    return (
        <>
            <form>
                <div className="p-6">
                    <div className="flex flex-col md:grid grid-cols-4 gap-4 mb-4">
                        <Controller
                            name="company"
                            control={control}
                            render={({ field }) => {
                                const value = companies.find((item) => item.value === field.value);

                                return (
                                    <Select
                                        {...field}
                                        isLoading={isLoadingCompanies}
                                        value={value}
                                        options={companies}
                                        label="Empresa"
                                        placeholder="Selecione uma opção"
                                        parentClassName="col-span-2"
                                        onChange={(option: any) => field.onChange(option.value)}
                                        autoFocus={true}
                                        error={getError('company')}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="branch"
                            control={control}
                            render={({ field }) => {
                                const value = branches.find((item) => item.value === field.value);

                                return (
                                    <Select
                                        {...field}
                                        value={value}
                                        options={branches}
                                        label="Filial"
                                        placeholder="Selecione uma opção"
                                        onChange={(option: any) => field.onChange(option.value)}
                                        error={getError('branch')}
                                    />
                                );
                            }}
                        />
                        {!isRootExpense && (
                            <Controller
                                name="nature"
                                control={control}
                                render={({ field }) => {
                                    const value = natures.find((item) => item.value === field.value);

                                    return (
                                        <Select
                                            {...field}
                                            isDisabled={true}
                                            value={value}
                                            options={natures}
                                            label="Natureza"
                                            placeholder="Selecione uma opção"
                                            onChange={(option: any) => field.onChange(option.value)}
                                            autoFocus={true}
                                            error={getError('nature')}
                                        />
                                    );
                                }}
                            />
                        )}
                        <Controller
                            name="sector"
                            control={control}
                            render={({ field }) => {
                                const value = sectors.find((item) => item.value === field.value) || null;

                                return (
                                    <Select
                                        {...field}
                                        isLoading={isLoadingSectors}
                                        isDisabled={isSaleOrderNature}
                                        value={value}
                                        options={sectors}
                                        label="Setor"
                                        placeholder="Selecione uma opção"
                                        onChange={handleSelectSector.bind(this, field.onChange)}
                                        error={getError('sector')}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="category"
                            control={control}
                            render={({ field }) => {
                                const value = categories.find((item) => item.value === field.value) || null;

                                return (
                                    <Select
                                        {...field}
                                        isLoading={isLoadingCategories}
                                        isDisabled={isSaleOrderNature}
                                        value={value}
                                        options={categories}
                                        label="Categoria*"
                                        required={true}
                                        placeholder="Selecione uma opção"
                                        onChange={handleSelectCategory.bind(this, field.onChange)}
                                        error={getError('category')}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="origin"
                            control={control}
                            render={({ field }) => {
                                const value = origins.find((item) => item.value === field.value) || null;

                                return (
                                    <Select
                                        {...field}
                                        isLoading={isLoadingOrigins}
                                        value={value}
                                        options={origins}
                                        label="Origem"
                                        placeholder="Selecione uma opção"
                                        onChange={handleSelectOrigin.bind(this, field.onChange)}
                                        error={getError('origin')}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="subOrigin"
                            control={control}
                            render={({ field }) => {
                                const value = subOrigins.find((item) => item.value === field.value) || null;

                                return (
                                    <Select
                                        {...field}
                                        isLoading={isLoadingSubOrigins}
                                        value={value}
                                        options={subOrigins}
                                        label="Sub-origem*"
                                        placeholder="Selecione uma opção"
                                        onChange={(option: any) => field.onChange(option.value)}
                                        error={getError('subOrigin')}
                                        required={true}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="favored"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <FavoredSelect
                                        peopleWhere={favoredPeopleWhere}
                                        error={formState.errors?.favored?.message}
                                        onChange={(option: Option) => field.onChange(option)}
                                        value={field.value}
                                        onOpenProviderConsultingModal={setProviderId.bind(this, field.value?.value!)}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="paymentForm"
                            control={control}
                            render={({ field }) => {
                                const value = paymentForms.find((item) => item.value === field.value?.value);

                                return (
                                    <Select
                                        {...field}
                                        isDisabled={!favored}
                                        value={value}
                                        options={paymentForms}
                                        label="Forma de Pagamento*"
                                        placeholder="Selecione uma opção"
                                        error={getError('paymentForm')}
                                        required={true}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="favoredBankAccount"
                            control={control}
                            render={({ field }) => {
                                const value = bankAccounts.find((item) => item.value === field.value);

                                return (
                                    <Select
                                        {...field}
                                        isDisabled={!(Boolean(bankAccounts?.length) && isBankAccountFieldVisible)}
                                        value={value}
                                        options={bankAccounts}
                                        isLoading={isLoadingBankAccounts}
                                        label="Conta bancária"
                                        placeholder="Selecione uma opção"
                                        onChange={(option: any) => field.onChange(option.value)}
                                        error={getError('favoredBankAccount')}
                                        required={true}
                                    />
                                );
                            }}
                        />
                        {!isUpdate && (
                            <>
                                {nature === ExpenseNature.Normal && (
                                    <>
                                        <Controller
                                            name="repeated"
                                            control={control}
                                            render={({ field }) => {
                                                const value = booleanOptions.find((item) => item.value === (field.value as any));

                                                return (
                                                    <Select
                                                        {...field}
                                                        value={value}
                                                        options={booleanOptions}
                                                        label="Esta despesa se repete?"
                                                        placeholder="Selecione uma opção"
                                                        onChange={handleChangeRepeated(field.onChange)}
                                                        error={getError('repeated')}
                                                    />
                                                );
                                            }}
                                        />
                                        {repeated && (
                                            <Controller
                                                name="monthsToRepeat"
                                                control={control}
                                                render={({ field }) => (
                                                    <Input
                                                        {...field}
                                                        min={1}
                                                        error={getError('monthsToRepeat')}
                                                        autoComplete="nope"
                                                        type="number"
                                                        placeholder="Ex: 1"
                                                        step={1}
                                                        label="Ela se repete por mais quantos meses?"
                                                        value={field.value}
                                                    />
                                                )}
                                            />
                                        )}
                                    </>
                                )}
                                <Controller
                                    name="installments"
                                    control={control}
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            disabled={repeated}
                                            onChange={handleChangeInstallment(field.onChange)}
                                            autoComplete="nope"
                                            type="number"
                                            placeholder="0"
                                            label="Parcelas"
                                            step={1}
                                            inputWrapperClasses="col-span-1"
                                            error={getError('installments')}
                                        />
                                    )}
                                />
                            </>
                        )}
                        <Controller
                            name="documentNumber"
                            control={control}
                            render={({ field }) => <Input {...field} error={getError('documentNumber')} label="Documento" autoComplete="nope" placeholder="Documento" />}
                        />
                        <Controller
                            name="dueDate"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <DatePicker
                                        inputProps={{
                                            label: 'Vencimento',
                                            placeholderText: 'Selecione uma data',
                                            error: getError('dueDate'),
                                            ...field,
                                        }}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="competence"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <DatePicker
                                        inputProps={{
                                            label: 'Competência',
                                            placeholderText: 'Selecione uma data',
                                            error: getError('competence'),
                                            ...field,
                                        }}
                                        muiDatePickerProps={{
                                            minDate: dayjs().month(0).year(2024),
                                            shouldDisableYear: (date) => dayjs().year() < date.year(),
                                            views: ['month', 'year'],
                                            format: 'MM/YYYY',
                                        }}
                                    />
                                );
                            }}
                        />
                        {!isUpdate && (
                            <Controller
                                name="provision"
                                control={control}
                                render={({ field }) => {
                                    const value =
                                        schedules.find((item) => item.value === field.value) || (isValidDate(field.value) && !!field.value)
                                            ? {
                                                  value: field.value,
                                                  label: `${formatDate(field.value, 'DD/MM/YYYY - ddd')}`,
                                              }
                                            : undefined;

                                    return (
                                        <Select
                                            {...field}
                                            value={value}
                                            options={schedules}
                                            label="Provisão"
                                            placeholder="Selecione uma opção"
                                            onChange={(option: any) => field.onChange(option.value)}
                                            error={getError('provision')}
                                        />
                                    );
                                }}
                            />
                        )}
                        {!isSaleOrderNature && (
                            <>
                                <Controller
                                    name="withProducts"
                                    control={control}
                                    render={({ field }) => {
                                        const value = booleanOptions.find((item) => item.value === field.value);

                                        return (
                                            <Select
                                                {...field}
                                                value={value}
                                                options={booleanOptions}
                                                label="Com produto/serviços?"
                                                placeholder="Selecione uma opção"
                                                onChange={handleChangeWithProducts(field.onChange)}
                                                error={getError('withProducts')}
                                            />
                                        );
                                    }}
                                />
                                <Controller
                                    name="provisioned"
                                    control={control}
                                    render={({ field }) => {
                                        const value = booleanOptions.find((item) => item.value === field.value);

                                        return (
                                            <Select
                                                {...field}
                                                value={value}
                                                options={booleanOptions}
                                                label="Provisionada"
                                                placeholder="Selecione uma opção"
                                                onChange={(option: any) => field.onChange(option.value)}
                                                error={getError('provisioned')}
                                            />
                                        );
                                    }}
                                />
                                <Controller
                                    name="constructionRelated"
                                    control={control}
                                    render={({ field }) => {
                                        const value = booleanOptions.find((item) => item.value === field.value);

                                        return (
                                            <Select
                                                {...field}
                                                value={value}
                                                options={booleanOptions}
                                                label="Despesa relacionada à uma OS?"
                                                placeholder="Selecione uma opção"
                                                onChange={(option: any) => {
                                                    field.onChange(option.value);

                                                    if (!option.value) {
                                                        setValue('orderService', null);
                                                    }
                                                }}
                                                error={getError('constructionRelated')}
                                            />
                                        );
                                    }}
                                />
                                {hasConstructionRelated && (
                                    <Controller
                                        name="orderService"
                                        control={control}
                                        render={({ field }) => {
                                            const value = serviceOrders.find((item) => item.value === field.value);

                                            return (
                                                <Select
                                                    {...field}
                                                    isLoading={isLoadingServiceOrders}
                                                    value={value}
                                                    options={serviceOrders}
                                                    label="OS"
                                                    placeholder="Selecione uma opção"
                                                    onChange={(option: any) => field.onChange(option.value)}
                                                    error={getError('orderService')}
                                                />
                                            );
                                        }}
                                    />
                                )}
                            </>
                        )}
                        {showAmountField && (
                            <Controller
                                name="amount"
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <CurrencyInput
                                            value={field?.value}
                                            left={
                                                <Text as="span" variant="body.medium.sm" className="text-heading">
                                                    R$
                                                </Text>
                                            }
                                            label="Valor"
                                            placeholder="0,00"
                                            onValueChange={(values) => setValue('amount', values?.floatValue)}
                                            error={getError('amount')}
                                        />
                                    );
                                }}
                            />
                        )}
                        <Controller
                            name="fiscalCode"
                            control={control}
                            render={({ field }) => {
                                const value = fiscalCodes.find((item) => item.value === (field.value as any));

                                return (
                                    <Select
                                        {...field}
                                        isLoading={isLoadingFiscalCodes}
                                        value={value}
                                        options={fiscalCodes}
                                        label="CFOP"
                                        placeholder="Selecione uma opção"
                                        onChange={(option: any) => field.onChange(option.value)}
                                        error={getError('fiscalCode')}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="type"
                            control={control}
                            render={({ field }) => {
                                const value = expenseTypes.find((item) => item.value === (field.value as any));

                                return (
                                    <Select
                                        {...field}
                                        value={value}
                                        options={expenseTypes}
                                        label="Tipo"
                                        placeholder="Selecione uma opção"
                                        onChange={(option: any) => field.onChange(option.value)}
                                        error={getError('type')}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="title"
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    error={getError('title')}
                                    autoComplete="nope"
                                    type="text"
                                    placeholder="Adicione alguma descrição"
                                    label="Descrição*"
                                    required={true}
                                    parentClassName="col-span-4"
                                />
                            )}
                        />
                        <Controller
                            name="observations"
                            control={control}
                            render={({ field }) => <Textarea {...field} error={getError('observations')} label="Observações" placeholder="Adicione alguma observação" parentClassName="col-span-4" />}
                        />
                    </div>
                </div>
                <div className="border-t p-2 flex">
                    <Button
                        endIcon={<SvgIcoArrowRight width={18} color={palette.secondary[500]} />}
                        variant="text"
                        color="secondary"
                        className="min-w-[100px] ml-auto"
                        onClick={
                            handleSubmit(submit) // we need to use submit as button click to prevent parent forms to be submitted too
                        }>
                        Avançar
                    </Button>
                </div>
            </form>
            {Boolean(providerId) && <ModalProviderConsulting providerId={providerId!} onClose={setProviderId.bind(this, null)} />}
        </>
    );
};

export default General;
