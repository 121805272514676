import TableCell from '@mui/material/TableCell/TableCell';
import { DatatableColumn } from 'types/graphql';
import { formatDate } from 'utils/date';
import { formatMoney } from 'utils/money';
import UploadPendingOrderPayment from './upload-pending-order-payment';

export const listColumns: DatatableColumn[] = [
    {
        name: 'id',
        options: { display: 'excluded' },
    },
    {
        name: 'code',
        label: 'Código',
        options: { customBodyRender: (value = '-') => value },
    },
    {
        name: 'paymentForecast',
        label: 'Previsão',
        options: { customBodyRender: (value) => formatDate(value, 'DD/MM/YYYY') },
    },
    {
        name: 'dueDate',
        label: 'Vencimento',
        options: { customBodyRender: (value) => formatDate(value, 'DD/MM/YYYY') },
    },
    {
        name: 'payday',
        label: 'Pagamento',
        options: { customBodyRender: (value) => formatDate(value, 'DD/MM/YYYY') },
    },
    {
        name: 'expense.company.name',
        label: 'Empresa',
        options: { customBodyRender: (value = '-') => value },
    },
    {
        name: 'description',
        label: 'Descrição',
        options: { customBodyRender: (value = '-') => value },
    },
    {
        name: 'expense.sector.name',
        label: 'Setor',
        options: { customBodyRender: (value = '-') => value },
    },
    {
        name: 'expense.category.name',
        label: 'Categoria',
        options: { customBodyRender: (value = '-') => value },
    },
    {
        name: 'expense.favored.title',
        label: 'Favorecido',
        options: { customBodyRender: (value = '-') => value },
    },
    {
        name: 'netValue',
        label: 'Valor',
        options: { customBodyRender: (value) => formatMoney(value || 0) },
    },
    {
        name: 'paymentForm.name',
        label: 'Forma de pagamento',
        options: { customBodyRender: (value = '-') => value },
    },
    {
        name: 'file',
        options: {
            customHeadRender: () => <TableCell key="actions" />,
            customBodyRender: (_, tableMeta) => <UploadPendingOrderPayment data={tableMeta.rowData} />,
        },
    },
];
