import dayjs from 'dayjs';
import CreateContribution from './create';
import useCreateBankAccountContribution from 'services/queries/bank-accounts/use-create-contribution';
import { useOutletContext, useParams } from 'react-router-dom';
import { CreateContributionPayload } from 'types/models/bank-accounts';

const CreateContributionPage = () => {
    const { bankAccountId } = useParams();

    const { queryFilters } = useOutletContext<{ queryFilters: string }>();

    const { mutateAsync: createBankAccountTransactionIncome, isLoading: isSubmitting } = useCreateBankAccountContribution(queryFilters, bankAccountId);

    const handleSubmit = async (data: CreateContributionPayload) => {
        try {
            const payload = {
                ...data,
                ...(Boolean(data?.dueDate) && { dueDate: dayjs(data?.dueDate).format('YYYY-MM-DD') }),
            };

            await createBankAccountTransactionIncome(payload);
        } catch (error) {
            console.error('handleSubmit', error);
        }

        return;
    };

    return <CreateContribution onSubmit={handleSubmit} isSubmitting={isSubmitting} />;
};

export default CreateContributionPage;
