import { useQuery } from 'react-query';
import apiGraphQl from 'services/graphql';
import queries, { getAllBanksOptionsKey } from './keys';
import { Option } from 'types/general';

type Response = {
    items: Option[];
};

const useGetAllBanksOptions = () => {
    const request = async () => apiGraphQl<Response>(queries.getAllBanks).then(({ items }) => items);

    return useQuery(getAllBanksOptionsKey, request);
};

export default useGetAllBanksOptions;
