import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { OrderServiceProductPurchase } from 'types/models/order-service';
import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import { formatDate } from 'utils/date';
import { formatMoney } from 'utils/money';

type ModalProductsProps = {
    products: Partial<OrderServiceProductPurchase>[];
    onClose: () => void;
};

const ModalProducts = ({ products, onClose }: ModalProductsProps) => {
    return (
        <Modal
            contentClassnames="w-[1000px]"
            headerLeft={
                <Text variant="h4" as="h4" className="text-heading">
                    Escopo de compra
                </Text>
            }
            onClose={onClose}>
            <div className="px-6 pb-2">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nome</TableCell>
                            <TableCell>Unidade</TableCell>
                            <TableCell>Previsão de compra</TableCell>
                            <TableCell>Quantidade</TableCell>
                            <TableCell>Saldo</TableCell>
                            <TableCell>Valor máximo</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {products.map((item) => {
                            return (
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={item.id}>
                                    <TableCell>{item.product?.name || '-'}</TableCell>
                                    <TableCell>{item.product?.classificationByClassificationUnitId?.name || '-'}</TableCell>
                                    <TableCell>{formatDate(item.provision)}</TableCell>
                                    <TableCell>{item.quantity || 0}</TableCell>
                                    <TableCell>{item.quantity || 0}</TableCell>
                                    <TableCell>{formatMoney(item.value)}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </div>
        </Modal>
    );
};

export default ModalProducts;
