import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import { getPendingPurchaseOrderProductsApiKey } from '../purchase-order/keys';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';

type Payload = {
    purchaseOrderProducts: Array<{
        id: number;
        stocks: Array<{
            id: number;
            quantity: number;
        }>;
    }>;
};

const useReceiveProductsFromStock = (purchaseOrderId?: string) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const goToParentRoute = useNavigateToParentRoute();

    const request = async (payload: Payload) => api.patch('/purchase-order-products/actions/receive', payload);

    return useMutation(request, {
        onSuccess: () => {
            showToast('Ordem de compra criada com sucesso', 'success');

            if (!!purchaseOrderId) {
                queryClient.invalidateQueries(getPendingPurchaseOrderProductsApiKey(purchaseOrderId));
            }

            goToParentRoute();
        },
        onError: (error: any) => {
            const defaultMessage = 'Ocorreu um erro ao criar a ordem de compra';
            const apiErrorMessage = error?.response?.data?.title || defaultMessage;

            showToast(apiErrorMessage?.toLowerCase()?.includes('validation') ? defaultMessage : apiErrorMessage, 'danger');
        },
    });
};

export default useReceiveProductsFromStock;
