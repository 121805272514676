import * as React from 'react';
const SvgIcoText = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 16" width="1em" height="1em" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M2.2 0H8a.75.75 0 0 1 .53.22l4.35 4.35c.141.14.22.331.22.53v8.7c0 1.198-1.002 2.2-2.2 2.2H2.2A2.222 2.222 0 0 1 0 13.8V2.2C0 1.002 1.002 0 2.2 0Zm0 1.5a.72.72 0 0 0-.7.7v11.6c0 .371.329.7.7.7h8.7a.72.72 0 0 0 .7-.7V5.85H8a.75.75 0 0 1-.75-.75V1.5H2.2Zm6.55 1.06 1.79 1.79H8.75V2.56ZM2.9 5.826a.75.75 0 0 1 .75-.75H5.1a.75.75 0 1 1 0 1.5H3.65a.75.75 0 0 1-.75-.75Zm.75 2.15a.75.75 0 1 0 0 1.5h5.8a.75.75 0 0 0 0-1.5h-5.8Zm0 2.9a.75.75 0 0 0 0 1.5h5.8a.75.75 0 0 0 0-1.5h-5.8Z"
            clipRule="evenodd"
        />
    </svg>
);
export default SvgIcoText;
