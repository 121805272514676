import useTheme from '@mui/material/styles/useTheme';
import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableFooter from '@mui/material/TableFooter/TableFooter';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import Button from 'components/core/button';
import ConfirmModal from 'components/core/modal/confirm';
import Text from 'components/core/text';
import useToast from 'hooks/toast/use-toast';
import { useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import useCreateOrUpdateSaleOrder from 'services/queries/sales-order/use-create-or-update-sale-order';
import { GraphqlPaginationVariables } from 'types/graphql';
import { formatDate } from 'utils/date';
import { formatMoney, formatNumberToPTBR } from 'utils/money';
import { useSaleOrderContext } from '../context';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import { useShowApiErrors } from 'hooks/error/show-api-errors';
import useGetClassifications from 'services/queries/classifications/use-get-classifications';
import { generateClassificationQuery } from 'services/queries/classifications/utils';
import { ClassificationType } from 'types/models/classification';
import { getAllKey } from 'services/queries/crud/keys';
import { SaleOrderOrigin } from 'types/models/sale-order';
import { Steps } from '../context/types';
import { getSaleOrderKey } from 'services/queries/sales-order/keys';
import SvgIcoArrowLeft from 'components/core/icon/files/ico-arrow-left';
import SvgIcoArrowRight from 'components/core/icon/files/ico-arrow-right';

const Summary = () => {
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);

    const { palette } = useTheme();
    const navigate = useNavigate();
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const { showToastErrors } = useShowApiErrors();
    const { state, goToStep, completeStep } = useSaleOrderContext();
    const { saleOrderId } = useParams();

    const isSaleOrderUpdate = Boolean(saleOrderId);

    const params = useOutletContext<GraphqlPaginationVariables<any>>();

    const { mutateAsync: createOrUpdateSaleOrder, isLoading: isSubmitting } = useCreateOrUpdateSaleOrder(saleOrderId);
    const { data: paymentForms = [] } = useGetClassifications(generateClassificationQuery(ClassificationType.PaymentForm));

    const finalTotalValue = useMemo(() => {
        return state.content.products?.reduce((acc, curr) => {
            const total = (curr.quantity || 0) * (curr.netValue || 0);

            return acc + total;
        }, 0);
    }, [state.content.products]);

    const handleToggleConfirmModal = (value: boolean) => () => setIsConfirmModalVisible(value);

    const handleBack = () => {
        completeStep(Steps.Summary);
        goToStep('previous');
    };

    const handleCreateSaleOrder = async () => {
        const expense = state.content.expense;

        try {
            const payload = {
                origin: state.content.origin,
                type: state.content.type,
                classification: state.content.classification.value,
                company: state.content.company.value,
                contractDate: state.content.contractDate,
                customer: state.content.customer.value,
                consultant: state.content.consultant.value,
                branch: state.content.branch.value,
                withFreight: !!state.content.withFreight,
                freightValue: state.content.freightValue,
                products: state.content.products.map((item) => ({
                    product: item.productId,
                    quantity: item.quantity,
                    grossValue: item.netValue,
                    ...(Boolean(item.id) && {
                        id: item.id,
                    }),
                })),
                ...(Boolean(state.content.transactions?.length) && {
                    transactions: state.content.transactions!.map((item, index) => ({
                        order: index + 1,
                        description: index === 0 ? `Parcela ${index + 1}/sinal` : `Parcela ${index + 1}`,
                        dueDate: formatDate(item.dueDate, 'YYYY-MM-DD'),
                        grossValue: Number(item.grossValue.toFixed(2)),
                        paymentForm: item.paymentForm,
                    })),
                }),
                ...(Boolean(expense) && {
                    expense: {
                        title: expense.title,
                        classificationSubOrigin: expense.classificationSubOrigin?.value,
                        provision: formatDate(expense.provision, 'YYYY-MM-DD'),
                        dueDate: formatDate(expense.dueDate, 'YYYY-MM-DD'),
                        value: expense.value,
                        fiscalCode: expense.fiscalCode?.value,
                        formFavored: {
                            person: expense.formFavored?.person?.value,
                            favoredBankAccount: expense.formFavored?.favoredBankAccount?.value,
                        },
                        paymentForm: expense.paymentForm?.value,
                    },
                }),
                ...(state.content.origin === SaleOrderOrigin.service && {
                    startContractDate: formatDate(state.content.contractPeriodStartDate, 'YYYY-MM-DD'),
                    endContractDate: formatDate(state.content.contractPeriodEndDate, 'YYYY-MM-DD'),
                    commission: state.content.commission,
                }),
            };

            const { data } = await createOrUpdateSaleOrder(payload);

            await queryClient.invalidateQueries(getAllKey('saleOrder', params));

            if (isSaleOrderUpdate) {
                await queryClient.invalidateQueries(getSaleOrderKey(saleOrderId));
            }

            showToast(`Pedido de venda ${isSaleOrderUpdate ? 'atualizado' : 'criado'} com sucesso`, 'success');

            navigate(`/app/pedidos-de-venda/${data.id}`);
        } catch (error: any) {
            console.log('handleCreateSaleOrder', error);

            if (!!error?.response?.data?.title) {
                showToast(error.response.data.title, 'danger');
            } else {
                showToastErrors(error, 'Ocorreu um erro ao criar o pedido de venda');
            }
        }
    };

    return (
        <>
            <div className="p-6">
                <div className="border border-base-300 rounded-[14px] p-4 mb-4">
                    <Text as="label" variant="h6" className="text-heading block mb-6">
                        Informações gerais
                    </Text>
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-4">
                        <Text as="p" className="text-base-500">
                            Filial:{' '}
                            <Text as="span" className="text-heading">
                                {state.content.branch.label || '-'}
                            </Text>
                        </Text>
                        <Text as="p" className="text-base-500">
                            Consultor:{' '}
                            <Text as="span" className="text-heading">
                                {state.content.consultant.label || '-'}
                            </Text>
                        </Text>
                        <Text as="p" className="text-base-500">
                            Empresa:{' '}
                            <Text as="span" className="text-heading">
                                {state.content.company.label || '-'}
                            </Text>
                        </Text>
                        <Text as="p" className="text-base-500">
                            Tipo:{' '}
                            <Text as="span" className="text-heading">
                                {state.content.classification.label || '-'}
                            </Text>
                        </Text>
                        <Text as="p" className="text-base-500">
                            Data do pedido:{' '}
                            <Text as="span" className="text-heading">
                                {formatDate(state.content.contractDate)}
                            </Text>
                        </Text>
                        <Text as="p" className="text-base-500">
                            Cliente:{' '}
                            <Text as="span" className="text-heading">
                                {state.content.customer.label || '-'}
                            </Text>
                        </Text>
                        {!!state.content.freightValue && state.content.withFreight && (
                            <Text as="p" className="text-base-500">
                                Frete:{' '}
                                <Text as="span" className="text-heading">
                                    {formatMoney(state.content.freightValue)}
                                </Text>
                            </Text>
                        )}
                    </div>
                </div>
                <div className="border border-base-300 rounded-[14px] p-4 mb-4">
                    <Text as="label" variant="h6" className="text-heading block mb-2">
                        Produtos
                    </Text>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Text className="text-base-500 uppercase">Produto</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text className="text-base-500 uppercase">Valor Unitário</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text className="text-base-500 uppercase">Quantidade</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text className="text-base-500 uppercase">Total</Text>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {state.content.products?.map((item) => {
                                    return (
                                        <TableRow key={item.productId}>
                                            <TableCell>
                                                <Text className="text-heading">{Boolean(item.code) && Boolean(item.name) ? `#${item.code} - ${item.name}` : '-'}</Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text className="text-heading">{formatMoney(item.netValue, undefined, 4)}</Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text className="text-heading">{formatNumberToPTBR(item.quantity || 0)}</Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text className="text-heading">{formatMoney((item.netValue || 0) * (item.quantity || 0))}</Text>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                            <TableFooter>
                                <TableRow className="bg-base-200">
                                    <TableCell className="border-b-0" colSpan={3}>
                                        <Text className="text-base-500 uppercase font-bold">Total</Text>
                                    </TableCell>
                                    <TableCell className="border-b-0">
                                        <Text className="text-heading font-bold">{formatMoney(finalTotalValue)}</Text>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </div>
                {Boolean(state.content.transactions?.length) && (
                    <div className="border border-base-300 rounded-[14px] p-4">
                        <Text as="label" variant="h6" className="text-heading block mb-2">
                            Parcelas
                        </Text>
                        <div className="w-full overflow-x-auto">
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Text className="text-base-500 uppercase">Referente a parcela</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text className="text-base-500 uppercase">Vencimento</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text className="text-base-500 uppercase">Valor da Parcela</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text className="text-base-500 uppercase">Forma de Pagamento</Text>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {state.content.transactions?.map((item, index) => {
                                        const paymentForm = paymentForms.find(({ value }) => value === item.paymentForm)?.label;

                                        return (
                                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={`installment_${index}`}>
                                                <TableCell>
                                                    <Text className="text-heading">Parcela {index + 1}</Text>
                                                </TableCell>
                                                <TableCell>
                                                    <Text className="text-heading">{formatDate(item.dueDate, 'DD/MM/YYYY')}</Text>
                                                </TableCell>
                                                <TableCell>
                                                    <Text className="text-heading">{formatMoney(item.grossValue)}</Text>
                                                </TableCell>
                                                <TableCell>
                                                    <Text className="text-heading">{paymentForm}</Text>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                )}
                {Boolean(state.content.expense) && (
                    <div className="border border-base-300 rounded-[14px] p-4 mt-4">
                        <Text as="label" variant="h6" className="text-heading block mb-6">
                            Despesa
                        </Text>
                        <div className="grid grid-cols-1 gap-4 sm:grid-cols-4">
                            <Text as="p" className="text-base-500">
                                Setor:{' '}
                                <Text as="span" className="text-heading">
                                    {state.content.expense.sector?.label || '-'}
                                </Text>
                            </Text>
                            <Text as="p" className="text-base-500">
                                Categoria:{' '}
                                <Text as="span" className="text-heading">
                                    {state.content.expense.category?.label || '-'}
                                </Text>
                            </Text>
                            <Text as="p" className="text-base-500">
                                Origem:{' '}
                                <Text as="span" className="text-heading">
                                    {state.content.expense.classificationOrigin?.label || '-'}
                                </Text>
                            </Text>
                            <Text as="p" className="text-base-500">
                                Sub-origem:{' '}
                                <Text as="span" className="text-heading">
                                    {state.content.expense.classificationSubOrigin?.label || '-'}
                                </Text>
                            </Text>
                            <Text as="p" className="text-base-500">
                                Favorecido:{' '}
                                <Text as="span" className="text-heading">
                                    {state.content.expense.formFavored?.person?.label || '-'}
                                </Text>
                            </Text>
                            <Text as="p" className="text-base-500">
                                Forma de pagamento:{' '}
                                <Text as="span" className="text-heading">
                                    {state.content.expense.paymentForm?.label || '-'}
                                </Text>
                            </Text>
                            <Text as="p" className="text-base-500">
                                Conta bancária:{' '}
                                <Text as="span" className="text-heading">
                                    {state.content.expense.formFavored?.favoredBankAccount?.label || '-'}
                                </Text>
                            </Text>
                            <Text as="p" className="text-base-500">
                                Provisão:{' '}
                                <Text as="span" className="text-heading">
                                    {formatDate(state.content.expense.provision)}
                                </Text>
                            </Text>
                            <Text as="p" className="text-base-500">
                                CFOP:{' '}
                                <Text as="span" className="text-heading">
                                    {state.content.expense.fiscalCode?.label || '-'}
                                </Text>
                            </Text>
                            <Text as="p" className="text-base-500">
                                Vencimento:{' '}
                                <Text as="span" className="text-heading">
                                    {formatDate(state.content.expense.dueDate)}
                                </Text>
                            </Text>
                            <Text as="p" className="text-base-500">
                                Valor:{' '}
                                <Text as="span" className="text-heading">
                                    {formatMoney(state.content.expense.value)}
                                </Text>
                            </Text>
                            <div className="col-span-4">
                                <Text as="p" className="text-base-500">
                                    Descrição:
                                </Text>
                                <Text as="span" className="text-heading">
                                    {state.content.expense.title || '-'}
                                </Text>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="border-t p-2 flex justify-between">
                <Button startIcon={<SvgIcoArrowLeft width={18} color={palette.grey[700]} />} type="button" variant="text" color="inherit" className="min-w-[100px]" onClick={handleBack}>
                    Voltar
                </Button>
                <Button
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    endIcon={<SvgIcoArrowRight width={18} color={isSubmitting ? palette.grey[400] : palette.secondary[500]} />}
                    variant="text"
                    color={isSubmitting ? 'inherit' : 'secondary'}
                    className="min-w-[100px]"
                    onClick={handleToggleConfirmModal(true)}>
                    {isSaleOrderUpdate ? 'Atualizar' : 'Criar'} pedido de venda
                </Button>
            </div>
            {isConfirmModalVisible && (
                <ConfirmModal
                    color="secondary"
                    title={`${isSaleOrderUpdate ? 'Atualizar' : 'Novo'} pedido de venda`}
                    description={`Você tem certeza que deseja ${isSaleOrderUpdate ? 'atualizar este' : 'um novo'} pedido de venda?`}
                    isLoading={isSubmitting}
                    onClose={handleToggleConfirmModal(false)}
                    onConfirmAction={handleCreateSaleOrder}
                />
            )}
        </>
    );
};

export default Summary;
