import * as React from 'react';
const SvgIcoPhone = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" width="1em" height="1em" {...props}>
        <path
            fill="currentColor"
            d="M6.87 3.671a.71.71 0 0 0-.469-.171H4.207a.71.71 0 0 0-.67.48.703.703 0 0 0-.034.284 13.63 13.63 0 0 0 2.12 5.972l.004.006a13.454 13.454 0 0 0 4.143 4.135l.006.004a13.696 13.696 0 0 0 5.957 2.117.71.71 0 0 0 .708-.421.84.84 0 0 0 .06-.332l-.001-2.139v-.019a.703.703 0 0 0-.606-.715c-.755-.1-1.496-.284-2.209-.55h-.001a.71.71 0 0 0-.747.158h-.002l-.922.922a.75.75 0 0 1-.9.121 12.403 12.403 0 0 1-4.656-4.646.75.75 0 0 1 .122-.902l.924-.923a.704.704 0 0 0 .158-.741l-.001-.002a10.077 10.077 0 0 1-.55-2.205.704.704 0 0 0-.24-.433Zm-.48-1.67a2.21 2.21 0 0 1 2.205 1.895v.007c.086.643.243 1.273.47 1.88v-.001a2.202 2.202 0 0 1-.498 2.327l-.003.003-.51.509a10.903 10.903 0 0 0 3.314 3.306l.514-.514a2.209 2.209 0 0 1 2.328-.496c.608.227 1.24.384 1.883.468l.007.001a2.209 2.209 0 0 1 1.9 2.228v2.145a2.33 2.33 0 0 1-.186.92 2.204 2.204 0 0 1-2.221 1.312l-.014-.001a15.196 15.196 0 0 1-6.615-2.348 14.952 14.952 0 0 1-4.6-4.592A15.131 15.131 0 0 1 2.01 4.416l-.001-.012a2.202 2.202 0 0 1 1.308-2.216A2.21 2.21 0 0 1 4.207 2H6.39Z"
        />
    </svg>
);
export default SvgIcoPhone;
