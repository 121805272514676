import { useQuery } from 'react-query';
import api from 'services/api';
import { getExpenseCommissionsKey } from './keys';

type ExpenseCommissionsResponse = {
    id: number;
    orderService: {
        id: number;
        code: string;
        title: string;
        saleOrder: {
            id: number;
            code: string;
        };
    } | null;
    saleOrder: {
        id: number;
        code: string;
    };
};

const useGetExpenseCommissions = (expenseId?: string) => {
    const request = async () => {
        const { data } = await api.get<ExpenseCommissionsResponse[]>(`/expenses/${expenseId}/commissions`);

        return data;
    };

    return useQuery(getExpenseCommissionsKey(expenseId), request, {
        enabled: Boolean(expenseId),
    });
};

export default useGetExpenseCommissions;
