import { Address } from './address';
import { BankAccount } from './bank';
import { BaseModel } from './base';
import { Customer } from './customer';
import { Person, PersonContractType } from './person';

export type Provider = {
    type: PersonContractType;
    name: string;
    document: string;
    addressProviders: Partial<AddressProvider>[];
    providerContacts: Partial<ProviderContact>[];
    providerBankAccounts: Partial<ProviderBankAccount>[];
} & BaseModel &
    Pick<Customer, 'fileId' | 'file' | 'title' | 'juridicName' | 'juridicDocument' | 'juridicFantasyName' | 'phones' | 'emails'>;

export type AddressProvider = {
    id: number;
    providerId: number;
    address: Address;
    provider: Provider;
};

export type ProviderContact = {
    providerId: number;
    personId: number;
    provider: Provider;
    person: Person;
} & BaseModel;

export type ProviderBankAccount = {
    id: number;
    providerId: number;
    bankAccount: Partial<BankAccount>;
    provider: Partial<Provider>;
};

export enum ProviderConsultingType {
    Natural = 'NATURAL',
    Legal = 'LEGAL',
    Foreign = 'FOREIGN',
    Unknown = 'UNKNOWN',
}

export type ProviderConsultingActivity = {
    id: number;
    text: string;
};

type ProviderConsultingPerson = {
    [K in 'id' | 'name' | 'taxId' | 'age']: string;
};

export enum ProviderConsultingStatus {
    Null = 1,
    Active,
    Suspended,
    Closed,
    Unfit,
}

export type ProviderConsultingResponse = {
    updated: string;
    taxId: string;
    company: {
        id: number;
        name: string;
        equity: number;
        nature: {
            id: number;
            text: string;
        };
        size: {
            id: number;
            acronym: string;
            text: string;
        };
        members: Array<{
            since: string;
            role: {
                id: number;
                text: string;
            };
            person: ProviderConsultingPerson & {
                type: ProviderConsultingType;
            };
        }>;
    };
    alias: string;
    founded: string;
    head: boolean;
    statusDate: string;
    status: {
        id: ProviderConsultingStatus;
        text: string;
    };
    address: {
        municipality: number;
        street: string;
        number: string;
        details: string;
        district: string;
        city: string;
        state: string;
        zip: string;
        country: {
            id: number;
            name: string;
        };
    };
    phones: Array<{
        area: string;
        number: string;
    }>;
    emails: Array<{
        address: string;
        domain: string;
    }>;
    reason?: {
        id: number;
        text: string;
    };
    mainActivity: ProviderConsultingActivity;
    sideActivities: ProviderConsultingActivity[];
};
