import { jsonToGraphQLQuery } from 'json-to-graphql-query';

export const customerDetailsQueryFields = jsonToGraphQLQuery({
    id: true,
    juridicDocument: true,
    juridicFantasyName: true,
    juridicName: true,
    document: true,
    title: true,
    phones: true,
    emails: true,
    type: true,
    hasStateRegistration: true,
    addressCustomers: {
        address: {
            id: true,
            compliment: true,
            neighbourhood: true,
            number: true,
            street: true,
            zip: true,
            cityId: true,
            stateId: true,
        },
    },
    customerContacts: {
        id: true,
        person: {
            id: true,
            name: true,
            title: true,
            gender: true,
            email: true,
            phones: true,
        },
    },
    file: {
        id: true,
        filename: true,
        path: true,
        originalName: true,
    },
});
