import ExternalButton from 'components/buttons/external';
import Badge from 'components/core/badge';
import Text from 'components/core/text';
import { useNavigate } from 'react-router-dom';
import { Transaction, TransactionType } from 'types/models/transaction';
import ExpensesContentDetails from './expenses-details-content';
import ReceiptsContentDetails from './receipts-details-content';
import PaymentOrderDetailsActions from './actions';
import { useState } from 'react';
import RefundModal from './refund-modal';
import { generateExpenseTransactionStatus, getExpenseTransactionStatusTextAndVariant } from 'pages/private/expenses/details/tabs/transactions/utils';
import { ColorVariant } from 'types/colors';
import { Person } from 'types/models/person';
import { Provider } from 'types/models/provider';

const PaymentOrderDetails = ({ favoredBankAccount, ...transaction }: Transaction) => {
    const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);

    const navigate = useNavigate();

    const isReceipt = transaction?.type === TransactionType.Income;

    const expenseTransactionParams = { paid: transaction?.paid, payday: transaction.payday, dueDate: transaction.dueDate };

    const { variant } = getExpenseTransactionStatusTextAndVariant(expenseTransactionParams);

    const favored = transaction?.expense?.favored?.person || transaction?.expense?.favored?.provider;

    const handleGoToFavored = () => {
        const path = !!transaction?.expense?.favored?.provider ? `/app/fornecedores/${transaction?.expense.favored.favoredId}` : `/app/pessoas/${transaction?.expense?.favored?.favoredId}`;
        const receiptPath = `/app/clientes/${transaction?.receipt?.origin?.saleOrder?.customer?.id}`;
        navigate(isReceipt ? receiptPath : path, { replace: true });
    };

    const renderFavoredOrCustomerTitle = () => {
        const customerOrFavoredTitle = isReceipt ? transaction?.receipt?.origin?.saleOrder?.customer?.title : favored?.title;

        return Boolean(customerOrFavoredTitle) ? (
            <ExternalButton onClick={handleGoToFavored}>{customerOrFavoredTitle}</ExternalButton>
        ) : (
            <Text as="span" variant="body.regular.xs" className="text-base-500">
                -
            </Text>
        );
    };

    const isPerson = (favored?: Partial<Person> | Partial<Provider>): favored is Person => 'email' in (favored || {});

    return (
        <div className="p-6 sm:p-8">
            <div className="flex items-baseline justify-between mb-6">
                <div className="flex-1">
                    <Text as="h2" variant="h4" className="text-heading">
                        {isReceipt ? 'Ordem de recebimento' : 'Ordem de pagamento'}
                    </Text>
                </div>
                <div className="flex items-center">
                    {!!transaction?.expense?.billed && (
                        <Badge variant="warning" className="mr-2">
                            Faturada
                        </Badge>
                    )}
                    {
                        <div className="flex items-center gap-4">
                            {generateExpenseTransactionStatus(expenseTransactionParams)}
                            <PaymentOrderDetailsActions isPaid={Boolean(transaction?.paid)} onOpenRefundModal={setIsRefundModalOpen} />
                        </div>
                    }
                </div>
            </div>
            <div className="flex mb-6">
                <Badge variant={(variant || 'grey') as ColorVariant} className="w-[150px] h-[60px] flex flex-col justify-end pb-5 px-5 text-center mr-4 sm:mr-8 rounded-md">
                    <Text as="span">{transaction?.code}</Text>
                </Badge>
                <div className="flex-1 flex flex-col">
                    {Boolean(favoredBankAccount?.account) && Boolean(favoredBankAccount?.accountDigit) && (
                        <>
                            <Text as="span" variant="body.regular.xs" className="text-base-500 block font-semibold mb-3">
                                Conta bancária (Saída)
                            </Text>
                            <Text as="span" variant="body.regular.xs" className="text-base-500 block mb-3">
                                {favoredBankAccount.account} - {favoredBankAccount.accountDigit}
                            </Text>
                        </>
                    )}
                    <div className="flex-1 flex flex-col justify-start">
                        <Text as="span" variant="body.regular.xs" className="text-base-500 font-semibold block mb-3">
                            {isReceipt ? 'Cliente' : 'Favorecido'}
                        </Text>
                        {renderFavoredOrCustomerTitle()}
                        {Boolean(favoredBankAccount?.account) && Boolean(favoredBankAccount?.accountDigit) && (
                            <Text as="span" variant="body.regular.xs" className="text-base-500">
                                {favoredBankAccount.account} - {favoredBankAccount.accountDigit}
                            </Text>
                        )}
                        {!!favored?.phones?.length && (
                            <Text as="span" variant="body.regular.xs" className="text-base-500">
                                {favored.phones.map((item) => `${item.ddd ? `(${item.ddd})` : ''} ${item.number}`).join(', ')}
                            </Text>
                        )}
                        {!!transaction?.expense?.favored?.document && (
                            <Text as="span" variant="body.regular.xs" className="text-base-500">
                                {transaction.expense.favored.document}
                            </Text>
                        )}
                        {((isPerson(favored) && !!favored.email) || !!favored?.emails?.length) && (
                            <Text as="span" variant="body.regular.xs" className="text-base-500">
                                {isPerson(favored) ? favored?.email : favored?.emails?.map((item) => item.email)?.join(', ')}
                            </Text>
                        )}
                    </div>
                </div>
            </div>
            <div>
                <Text as="h3" variant="h6" className="text-heading mb-2">
                    Detalhes
                </Text>
                <Text className="text-base-500 block mb-8">{transaction?.description || '-'}</Text>
                {isReceipt ? <ReceiptsContentDetails {...transaction} /> : <ExpensesContentDetails {...transaction} />}
            </div>
            {isRefundModalOpen && <RefundModal onClose={setIsRefundModalOpen.bind(this, false)} />}
        </div>
    );
};

export default PaymentOrderDetails;
