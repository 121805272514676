import { APIProvider, AdvancedMarker, MapProps, Map as RGMap } from '@vis.gl/react-google-maps';
import classNames from 'classnames';

const REACT_APP_GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const Map = (props: MapProps) => {
    const className = classNames('w-full h-full outline-none google-maps-container', props.className);

    if (!REACT_APP_GOOGLE_MAPS_API_KEY) {
        return null;
    }

    return (
        <APIProvider apiKey={REACT_APP_GOOGLE_MAPS_API_KEY}>
            <RGMap {...props} className={className} defaultZoom={16} gestureHandling="cooperative">
                <AdvancedMarker position={props.defaultCenter} />
            </RGMap>
        </APIProvider>
    );
};

export default Map;
