import Table from '@mui/material/Table/Table';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableBody from '@mui/material/TableBody/TableBody';
import Text from 'components/core/text';
import classNames from 'classnames';
import { formatDate } from 'utils/date';
import { formatMoney } from 'utils/money';
import { PurchaseProductRequest } from 'types/models/purchase-order';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import { CSSProperties } from 'react';

type ProductsSimpleTableProps = {
    requests: PurchaseProductRequest[];
    diffProduct?: boolean;
    diffProvision?: boolean;
    diffQuantity?: boolean;
    diffBalance?: boolean;
    diffValue?: boolean;
    withVersionsHeader?: boolean;
    className?: string;
    style?: CSSProperties;
};

const ProductsSimpleTable = ({ requests, diffBalance, diffProduct, diffProvision, diffQuantity, diffValue, withVersionsHeader, className, style }: ProductsSimpleTableProps) => {
    return (
        <TableContainer className={className} style={style}>
            <Table>
                <TableHead>
                    {withVersionsHeader && (
                        <TableRow className="bg-base-200">
                            <TableCell colSpan={6}>Solicitações de alteração (versões anteriores)</TableCell>
                        </TableRow>
                    )}
                    <TableRow>
                        <TableCell>
                            <Text variant="body.regular.sm" as="strong" className="text-base-500 whitespace-nowrap">
                                Data de solicitação
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text variant="body.regular.sm" as="strong" className="text-base-500 whitespace-nowrap">
                                Produto
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text variant="body.regular.sm" as="strong" className="text-base-500 whitespace-nowrap">
                                Previsão de compra
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text variant="body.regular.sm" as="strong" className="text-base-500 whitespace-nowrap">
                                Quantidade
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text variant="body.regular.sm" as="strong" className="text-base-500 whitespace-nowrap">
                                Saldo
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text variant="body.regular.sm" as="strong" className="text-base-500 whitespace-nowrap">
                                Valor máximo
                            </Text>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {requests.map((requestItem, index) => {
                        return (
                            <TableRow sx={{ '&:last-child > td': { border: 'none' } }} key={`${requestItem.id}_${index}`}>
                                <TableCell>
                                    <Text variant="body.regular.sm" as="span" className="bg-transparent text-base-500">
                                        {formatDate(requestItem.date, 'DD/MM/YYYY HH:mm')}
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text
                                        variant="body.regular.sm"
                                        as="span"
                                        className={classNames('whitespace-nowrap', diffProduct ? 'bg-system-danger-100 px-1 text-system-danger-900 bg-opacity-50' : 'bg-transparent text-base-500')}>
                                        {requestItem.product?.name || '-'}
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text
                                        variant="body.regular.sm"
                                        as="span"
                                        className={classNames(diffProvision ? 'bg-system-danger-100 px-1 text-system-danger-900 bg-opacity-50' : 'bg-transparent text-base-500')}>
                                        {formatDate(requestItem.provision)}
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text
                                        variant="body.regular.sm"
                                        as="span"
                                        className={classNames(diffQuantity ? 'bg-system-danger-100 px-1 text-system-danger-900 bg-opacity-50' : 'bg-transparent text-base-500')}>
                                        {requestItem.quantity || 0}
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text
                                        variant="body.regular.sm"
                                        as="span"
                                        className={classNames(diffBalance ? 'bg-system-danger-100 px-1 text-system-danger-900 bg-opacity-50' : 'bg-transparent text-base-500')}>
                                        {requestItem.balance || 0}
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text
                                        variant="body.regular.sm"
                                        as="span"
                                        className={classNames(diffValue ? 'bg-system-danger-100 px-1 text-system-danger-900 bg-opacity-50' : 'bg-transparent text-base-500')}>
                                        {formatMoney(requestItem.value)}
                                    </Text>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ProductsSimpleTable;
