import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';
import { Status } from 'types/general';
import { WhereClause } from 'types/graphql';
import { Customer } from 'types/models/customer';

export const generateCustomerOptionsQuery = (where?: WhereClause<Partial<Customer>>['where']) => {
    return jsonToGraphQLQuery(
        {
            query: {
                __name: 'GetCustomerOptions',
                items: {
                    __aliasFor: 'customer',
                    __args: {
                        orderBy: { title: new EnumType('ASC') },
                        where: {
                            status: { _eq: Status.Active },
                            ...(Boolean(where) && where)
                        }
                    },
                    value: { __aliasFor: 'id' },
                    label: { __aliasFor: 'title' }
                }
            }
        },
        { pretty: true }
    );
};
