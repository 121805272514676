import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import { getReportPageKey } from './keys';
import { ReportPageFormPayload } from 'types/models/reports';

const useCreateOrUpdateReportPage = ({ id, onSuccess }: { id?: string; onSuccess?(): void }) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const request = async (payload: ReportPageFormPayload) => {
        const isEdit = Boolean(id);

        const endpoint = isEdit ? `/reports/pages/${id}` : '/reports/pages';

        return await api[isEdit ? 'patch' : 'post'](endpoint, payload);
    };

    return useMutation(request, {
        onSuccess: () => {
            queryClient.invalidateQueries(getReportPageKey(id));

            queryClient.invalidateQueries(['crud', 'all', 'reportPage']);

            onSuccess?.();

            showToast(`Página de relatório ${!!id ? 'atualizada' : 'criada'} com sucesso`, 'success');
        },
        onError: () => {
            showToast(`Ocorreu um erro ao tentar ${!!id ? 'atualizar' : 'criar'} a página de relatório`, 'danger');
        },
    });
};

export default useCreateOrUpdateReportPage;
