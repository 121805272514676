import CreateOrUpdateBankAccountPage from 'pages/private/bank-accounts/create-or-update';
import CreateContributionPage from 'pages/private/bank-accounts/create-or-update/contribution';
import CreateIncomePage from 'pages/private/bank-accounts/create-or-update/income';
import CreateTransferBetweenAccountsPage from 'pages/private/bank-accounts/create-or-update/transfer';

import BankAccountsListPage from 'pages/private/bank-accounts';
import BankAccountsDetails from 'pages/private/bank-accounts/view/details';
import Page from 'pages/shared/page';
import { RouteObject } from 'react-router-dom';
import { Rule } from 'types/permissions';
import TransactionUpdatePage from 'pages/private/expenses/details/tabs/transactions/update';
import ExportFilesPage from 'pages/private/bank-accounts/create-or-update/export-files';
import PaymentOrderPage from 'pages/private/payment-order/view';
import CreateOrUpdateTransactionDocumentPage from 'pages/private/payment-order/view/documents/create-or-update';

const bankAccounts: RouteObject[] = [
    {
        // @ts-ignore
        breadcrumb: 'Contas bancárias',
        path: 'contas-bancarias',
        element: <Page Component={BankAccountsListPage} code="contas-bancarias" permissions={[Rule.List]} />,
        children: [
            {
                path: 'novo',
                element: <Page Component={CreateOrUpdateBankAccountPage} code="contas-bancarias" permissions={[Rule.Create]} withModal={true} />,
            },
            {
                path: 'editar/:bankAccountId',
                element: <Page Component={CreateOrUpdateBankAccountPage} code="contas-bancarias" permissions={[Rule.Update]} withModal={true} />,
            },
        ],
    },
    {
        path: 'contas-bancarias/:bankAccountId',
        element: <Page Component={BankAccountsDetails} code="contas-bancarias" permissions={[Rule.Show]} />,
        children: [
            {
                path: 'nova-receita',
                element: <Page Component={CreateIncomePage} code="contas-bancarias-nova-receita" permissions={[Rule.Create]} withModal={true} />,
            },
            {
                path: 'inclusao-aportes',
                element: <Page Component={CreateContributionPage} code="contas-bancarias-inclusao-aportes" permissions={[Rule.Create]} withModal={true} />,
            },
            {
                path: 'transferencia-contas',
                element: <Page Component={CreateTransferBetweenAccountsPage} code="contas-bancarias-transferencia-contas" permissions={[Rule.Create]} withModal={true} />,
            },
            {
                path: 'transacoes/:transactionId',
                element: <Page Component={PaymentOrderPage} code="contas-bancarias-transacoes" permissions={[Rule.Show]} withModal={true} />,
                children: [
                    {
                        path: 'editar',
                        element: <Page Component={TransactionUpdatePage} code="contas-bancarias-transacoes" permissions={[Rule.Update]} withModal={true} />,
                    },
                    {
                        path: 'documentos/novo',
                        element: <Page Component={CreateOrUpdateTransactionDocumentPage} code="contas-bancarias-transacoes-documentos" permissions={[Rule.Create]} withModal={true} />,
                    },
                    {
                        path: 'documentos/:documentId/editar',
                        element: <Page Component={CreateOrUpdateTransactionDocumentPage} code="contas-bancarias-transacoes-documentos" permissions={[Rule.Update]} withModal={true} />,
                    },
                ],
            },
            {
                path: 'exportar-arquivos',
                element: <Page Component={ExportFilesPage} code="contas-bancarias-exportar-arquivos" permissions={[Rule.Create]} withModal={true} />,
            },
        ],
    },
];

export default bankAccounts;
