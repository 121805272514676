import classNames from 'classnames';
import Input from 'components/core/form/input';
import useDebounce from 'hooks/keyboard/use-debounce';
import React, { useEffect, useRef, useState } from 'react';
import TableAdvancedSearch from '../advanced-search';

type DataTableSearchInputProps = {
    searchText: string;
    handleSearch: (text: string) => void;
    searchPlaceholder: string;
    advancedSearchComponent?: React.ReactNode;
    className?: string;
    hasSelectedFilters?: boolean;
};

const DataTableSearchInput = ({ searchText, hasSelectedFilters, handleSearch, searchPlaceholder, advancedSearchComponent, className }: DataTableSearchInputProps) => {
    const [text, setText] = useState((searchText || '')?.replace(/%/g, ''));

    const debounceText = useDebounce(text, 500);

    const isReadyToChange = useRef(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        isReadyToChange.current = true;
        setText((event?.target?.value || '')?.replace(/%/g, ''));
    };

    useEffect(() => {
        // Clean text when the table's input is blank

        if (searchText === '') {
            setText(searchText);
        }
    }, [searchText, searchPlaceholder]);

    useEffect(() => {
        // preventing to call handleSearch without input changes
        if (!isReadyToChange.current) {
            return;
        }

        handleSearch(debounceText);
    }, [debounceText, handleSearch]);

    return (
        <div className={classNames('relative items-center', className)}>
            <Input autoComplete="nope" placeholder={searchPlaceholder} value={(text || '')?.replace(/%/g, '')} onChange={handleChange} />
            {advancedSearchComponent && <TableAdvancedSearch hasSelectedFilters={hasSelectedFilters} component={advancedSearchComponent} />}
        </div>
    );
};

export default DataTableSearchInput;
