import TableCell from '@mui/material/TableCell/TableCell';
import CustomerPage from 'pages/private/customers/view';
import { CrudPageProps } from 'types/graphql';
import dictionary from 'utils/dictionary';
import queries from 'services/queries/customers/keys';
import { jsonToGraphQLQuery } from 'json-to-graphql-query';
import Badge from 'components/core/badge';
import { PersonContractType } from 'types/models/person';

const queryFields = jsonToGraphQLQuery({
    id: true,
    juridicDocument: true,
    juridicFantasyName: true,
    juridicName: true,
    title: true,
    type: true,
    document: true,
    file: {
        path: true,
        filename: true,
    },
    emails: true,
    phones: true,
    customerContacts: {
        id: true,
        person: {
            name: true,
            title: true,
            gender: true,
            email: true,
            phones: true,
            file: {
                path: true,
                filename: true,
            },
            birthday: true,
            document: true,
        },
    },
    addressCustomers: {
        address: {
            compliment: true,
            neighbourhood: true,
            number: true,
            street: true,
            zip: true,
            city: { name: true },
            state: { initials: true },
        },
    },
});

const config: CrudPageProps<{}>[] = [
    {
        title: 'Clientes',
        graphql: {
            table: 'customer',
            searchableField: 'title',
            columns: [
                {
                    name: 'id',
                    options: { display: 'excluded' },
                },
                {
                    name: 'title',
                    label: dictionary.content.graphql['title'],
                },
                {
                    name: 'type',
                    label: dictionary.content.graphql['type'],
                    options: {
                        customBodyRender: (value) => <Badge variant="info">{value === PersonContractType.Juridic ? 'Pessoa jurídica' : 'Pessoa física'}</Badge>,
                    },
                },
                {
                    name: 'actions',
                    label: 'Ações',
                    options: {
                        customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />,
                    },
                },
            ],
            orderBy: {
                title: 'ASC',
            },
        },
        api: {
            endpoint: '/customers',
        },
    },
    { title: 'Novo cliente' },
    { title: 'Atualizar cliente' },
    {
        details: {
            table: 'customer',
            query: queries.getCustomer(queryFields),
            component: CustomerPage,
            paramName: 'customerId',
            formatResponse: ({ customer }: any) => ({
                ...customer,
                juridicName: customer?.juridicName || '',
                juridicDocument: customer?.juridicDocument || '',
            }),
        },
    },
];

export default config;
