import { BaseModel } from './base';
import { Branch } from './branch';
import { Classification } from './classification';
import { Company } from './company';
import { Document } from './document';
import { OrderService } from './order-service';
import { Person } from './person';
import { Product } from './product';
import { Provider } from './provider';
import { Transaction } from './transaction';
import { PurchaseOrderProduct } from './purchase-order';
import { ApprovalStatus } from 'types/general';

export enum ExpenseNature {
    Normal = 1,
    PurchaseOrder,
    StockPurchaseOrder,
    OrderService,
    SaleOrder,
    Import,
    PurchaseOrderBilled,
    StockTransfer,
}

export type ExpenseTransaction = {
    expenseId: number;
    transactionId: number;
    expense: Partial<Expense>;
    transaction: Partial<Transaction>;
};

export type ExpenseProduct = {
    expenseId: number;
    productId: number;
    quantity: number;
    grossValue: number;
    netValue: number;
    discount: number;
    addition: number;
    purchaseOrderProductId: number;
    justification: string;
    expense: Partial<Expense>;
    product: Partial<Product>;
    purchaseOrderProduct: Partial<PurchaseOrderProduct>;
} & BaseModel;

export type Expense = {
    companyId: number;
    branchId: number;
    personId: number;
    expenseFavoredId: number;
    classificationPaymentFormId: number;
    classificationSectorId: number;
    classificationCategoryId: number;
    classificationOriginId: number;
    classificationSubOriginId: number;
    nature: ExpenseNature;
    code: string;
    value: number;
    instalments: number;
    dueDate: string;
    payday: string;
    title: string;
    provisioned: boolean;
    edited: boolean;
    billed: boolean;
    approvalStatus: ApprovalStatus;
    classificationFiscalCodeId: number;
    orderServiceId: number;
    expenseBudgets: any[]; //TODO:
    expenseDocuments: Partial<Document>[];
    expenseHasTransactions: Partial<ExpenseTransaction>[];
    expenseProducts: Partial<ExpenseProduct>[];
    products: ExpenseProduct[];
    transactions: Partial<Transaction>[];
    branch: Partial<Branch>;
    classification: Partial<Classification>;
    category: Partial<Classification>;
    classificationByClassificationSectorId: Partial<Classification>;
    sector: Partial<Classification>;
    classificationByClassificationFiscalCodeId: Partial<Classification>;
    fiscalCode: Partial<Classification>;
    classificationByClassificationOriginId: Partial<Classification>;
    classificationOrigin: Partial<Classification>;
    classificationByClassificationPaymentFormId: Partial<Classification>;
    paymentForm: Partial<Classification>;
    classificationByClassificationSubOriginId: Partial<Classification>;
    classificationSubOrigin: Partial<Classification>;
    company: Partial<Company>;
    expenseFavored: any; //TODO:
    favored: Partial<ExpenseFavored>;
    orderService: Partial<OrderService>;
    person: Partial<Person>;
    type: ExpenseType;
    documentNumber?: string;
    groupedTransactions: Partial<Transaction>[];
} & BaseModel;

export type ExpenseFavored = {
    className: string;
    title: string;
    expenses: Partial<Expense>[];
    expenseFavoredPerson: Partial<Person>;
    person: Partial<Person>;
    expenseFavoredProvider: Partial<Provider>;
    provider: Partial<Provider>;
    juridic: boolean;
    favoredId: number;
    document?: string;
} & BaseModel;

export type ExpenseProducts = {
    id: number;
    name: string;
    code: string;
    value: number;
    classification: { name: string };
    classificationByClassificationUnitId: { name: string };
    label: string;
    quantity: number;
    discount: number;
    addition: number;
    updatedValue: number;
    productId: number;
    total: number;
    justification?: string;
    purchaseOrder?: {
        id?: number;
        code?: string;
    };
    purchaseOrderProduct?: number;
    orderService?: {
        id?: number;
        title?: string;
        saleOrderId?: number;
    };
    provision?: string;
    billed: boolean;
};

export type ExpenseProductsPayload = {
    products: Array<ExpenseProducts>;
    budgets?: Array<{
        provider: string;
        file?: number;
        value?: number;
    }>;
};

export enum ExpenseType {
    Fixed = 1,
    Probable,
    Variable,
}
