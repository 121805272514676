import React, { PropsWithChildren, useMemo } from 'react';
import useTheme from '@mui/material/styles/useTheme';
import Text from 'components/core/text';
import classNames from 'classnames';
import { Link, To } from 'react-router-dom';
import SvgIcoExternal from 'components/core/icon/files/ico-external';

type ExternalButtonProps = {
    className?: string;
    disabled?: boolean;
    href?: string;
    to?: To;
    type?: 'button' | 'submit';
    onClick?: () => void;
};

const ExternalButton = ({ href, children, className, to = '', type = 'button', disabled = false, onClick }: PropsWithChildren<ExternalButtonProps>) => {
    const { palette } = useTheme();

    const classes = classNames('flex items-center whitespace-nowrap overflow-hidden text-ellipsis', className);

    const currentElement = useMemo(() => {
        if (!!href) {
            return {
                element: 'a',
                attrs: {
                    target: '_blank',
                    href,
                },
            };
        }

        if (!!to) {
            return {
                element: Link,
                attrs: { to },
            };
        }

        return {
            element: 'button',
            attrs: { onClick },
        };
    }, [href, to, onClick]);

    return React.createElement(
        currentElement.element,
        {
            className: classes,
            type,
            disabled,
            ...currentElement.attrs,
        } as any,
        <Text variant="body.regular.sm" as="span" className="text-secondary-700 mr-1 overflow-hidden text-ellipsis">
            {children}
        </Text>,
        <SvgIcoExternal height={10} width={10} color={palette.secondary.main} />
    );
};

export default ExternalButton;
