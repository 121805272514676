import { parseCurrency } from '@brazilian-utils/brazilian-utils';

export const maskToCurrency = ({ nextState }) => {
    const { value } = nextState || {};

    let amountFormatted = value?.replace?.(/\D/g, '');

    amountFormatted = amountFormatted?.replace?.(/^0+/g, '');

    if (amountFormatted?.length === 2) {
        return {
            ...nextState,
            value: amountFormatted,
            selection: {
                start: amountFormatted.length + 3,
                end: amountFormatted.length + 3,
            },
        };
    }

    const amountFormattedWithComma = amountFormatted?.replace?.(/(?=\d{2})(\d{2})$/, '.$1');
    const amountFormattedWithDot = amountFormattedWithComma?.replace?.(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

    if (amountFormattedWithDot) {
        return {
            ...nextState,
            value: amountFormattedWithDot,
            selection: {
                start: amountFormattedWithDot.length + 3,
                end: amountFormattedWithDot.length + 3,
            },
        };
    }

    return nextState;
};

export const currencyToFloat = (currency = '') => parseFloat(currency.replace(/[^0-9.-]+/g, ''));

export const formatMoney = (number = 0, fallBackMsg = 'R$ 0,00', minimumFractionDigits = 2) => {
    if (!number) {
        return fallBackMsg;
    }

    return number?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits });
};

export const convertIntToFloat = (value: number) => (Math.round(value * 100) / 100).toFixed(2);

export const formatNumberToPTBR = (value: string | number, minimumFractionDigits = 2, maximumFractionDigits = 2) => {
    return Number(value || 0).toLocaleString('pt-BR', {
        currency: 'BRL',
        minimumFractionDigits,
        maximumFractionDigits: minimumFractionDigits > maximumFractionDigits ? minimumFractionDigits : maximumFractionDigits,
    });
};

/**
 * This function will format the number to 1k (thousand), 1M (million), or 1B (billion)
 * @param num number
 * @returns string
 */
export const formatNumber = (num = 0) => {
    if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
    }
    if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    return Number(num).toLocaleString('pt-BR');
};

/**
 * This function normalize a division assigning a greater value to one installment if the division is not equal
 * @param num number
 * @returns string
 */
export const generateInstallments = (value, installments) => {
    const result = value / installments;

    const installmentsArray = Array.from({ length: installments }, () => +result.toFixed(2));

    const sum = installmentsArray.reduce((acc, val) => acc + val, 0).toFixed(2);
    const sumParsed = parseCurrency(sum);

    const lastValue = installmentsArray[installments - 1];
    const diff = value - sumParsed;

    installmentsArray[installments - 1] = lastValue + diff;

    return installmentsArray;
};
