import { yupResolver } from '@hookform/resolvers/yup';
import CurrencyInput from 'components/core/form/currency';
import Select from 'components/core/form/select';
import Text from 'components/core/text';
import { useEffect, useMemo } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { NumberFormatValues } from 'react-number-format/types/types';
import { Product } from 'types/models/product';
import dictionary from 'utils/dictionary';
import { formatMoney } from 'utils/money';
import { array, number, object } from 'yup';
import { useExpensesContext } from './context';
import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import RemoveButton from 'components/buttons/remove';
import ItemFooter from './item-footer';
import _sum from 'lodash/sum';
import useGetDebouncedProducts from 'services/queries/products/use-get-debounced-products';
import { ExpenseNature } from 'types/models/expense';
import { OCExpenseNatures } from './general';
import { Steps } from './context/types';
import { purchaseProductsTypeWhereClause } from 'pages/private/sales-orders/service-order/order-purchase/create-or-update/utils';
import { moreThanSchema } from 'utils/schema';

export type ExpenseProducts = {
    id: number;
    name: string;
    code: string;
    value: number;
    classification: {
        name: string;
    };
    classificationByClassificationUnitId: {
        name: string;
    };
    label: string;
    quantity: number;
    discount: number;
    addition: number;
    updatedValue: number;
    productId: number;
    total: number;
    expenseProduct?: number;
};

type Payload = {
    products: Array<ExpenseProducts>;
};

const queryFields = ['id', 'name', 'code', 'classification.name', 'classificationByClassificationUnitId.name'];

const DEFAULT_PRODUCT = {
    quantity: 0,
    discount: 0,
    addition: 0,
    updatedValue: 0,
    value: 0,
};

const schema = object({
    products: array(
        object({
            productId: number().required(dictionary.validation.required),
            quantity: number().required(dictionary.validation.required),
            value: moreThanSchema(),
        })
    ).min(1, dictionary.validation.array.min(1)),
});

const transformer = (data) => data.map((item) => ({ ...item, label: `${item.code} ${item.name}` }));

const Products = ({ nature }) => {
    const { state, setContent, goToStep, completeStep } = useExpensesContext();

    const { isLoadingProducts, products, searchTerm, setSearchTerm } = useGetDebouncedProducts<Product, any[]>(queryFields, transformer, purchaseProductsTypeWhereClause);

    const { formState, handleSubmit, control, watch, setValue, reset, getValues } = useForm<Payload>({
        defaultValues: {
            products: [],
        },
        resolver: yupResolver(schema) as any,
    });

    const productsState = useMemo(() => state?.content?.products || [], [state]);

    const { append, remove } = useFieldArray({ name: 'products', control });

    const selectedProducts = watch('products');

    const handleBack = () => {
        // Save state temporarily
        setContent({ products: getValues().products || [] });

        goToStep('previous');
    };

    const handleSelectProducts = (option: any) => {
        append({
            ...option,
            ...DEFAULT_PRODUCT,
            productId: option.id,
        });

        setSearchTerm('');
    };

    const handleChangeQuantity = (item: ExpenseProducts, index: number) => (values: NumberFormatValues) => {
        const { floatValue: quantity = 0 } = values;

        setValue(`products.${index}.quantity`, quantity, { shouldValidate: true });
        setValue(`products.${index}.total`, Number((quantity * item.value).toFixed(2)));
    };

    const handleChangeValue = (item: ExpenseProducts, index: number) => (values: NumberFormatValues) => {
        const { floatValue: value = 0 } = values;

        setValue(`products.${index}.value`, value, { shouldValidate: true });
        setValue(`products.${index}.total`, item.quantity * value);
    };

    const handleRemoveProduct = (index: number) => () => remove(index);

    const consolidated = _sum(selectedProducts.map(({ value, quantity }) => quantity * value));

    const submit = async (data: Payload) => {
        setContent({ products: data.products });
        completeStep(Steps.Products);
        goToStep('next');
    };

    const isFormValid = !!formState.isValid && consolidated > 0;

    useEffect(() => {
        if (!!productsState?.length) {
            reset({ products: productsState as any });
        }
    }, [productsState, reset]);

    return (
        <form onSubmit={handleSubmit(submit)}>
            <div className="p-6">
                {!OCExpenseNatures.includes(nature) && (
                    <div className="mb-6">
                        <Select
                            value={searchTerm}
                            options={!!searchTerm ? products : []}
                            isLoading={isLoadingProducts}
                            label="Produto (código ou nome)"
                            placeholder="Digite o código ou nome do produto"
                            components={!Boolean(searchTerm) ? { NoOptionsMessage: () => null } : undefined}
                            onChange={handleSelectProducts}
                            onInputChange={setSearchTerm}
                        />
                    </div>
                )}
                <div className="w-full overflow-x-auto">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Text className="text-base-500 uppercase">Produto</Text>
                                </TableCell>
                                <TableCell>
                                    <Text className="text-base-500 uppercase">Unidade</Text>
                                </TableCell>
                                <TableCell>
                                    <Text className="text-base-500 uppercase">Valor por unidade</Text>
                                </TableCell>
                                <TableCell>
                                    <Text className="text-base-500 uppercase">Quantidade</Text>
                                </TableCell>
                                <TableCell>
                                    <Text className="text-base-500 uppercase">Total</Text>
                                </TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {selectedProducts?.map((item, index) => {
                                return (
                                    <TableRow key={item.id}>
                                        <TableCell>
                                            <Text className="text-heading">{item.name}</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text className="text-heading">{item.classificationByClassificationUnitId?.name}</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Controller
                                                name={`products.${index}.value`}
                                                control={control}
                                                render={({ field }) => {
                                                    return (
                                                        <CurrencyInput
                                                            left={
                                                                <Text as="span" variant="body.medium.sm" className="text-heading">
                                                                    R$
                                                                </Text>
                                                            }
                                                            disabled={ExpenseNature.PurchaseOrder === nature}
                                                            value={field.value}
                                                            withFormat={false}
                                                            decimalScale={4}
                                                            thousandSeparator="."
                                                            decimalSeparator=","
                                                            fixedDecimalScale={true}
                                                            onValueChange={handleChangeValue(item, index)}
                                                            placeholder="Ex: 1000"
                                                            parentClassName="w-[150px]"
                                                            error={formState.errors.products?.[index]?.value?.message}
                                                        />
                                                    );
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Controller
                                                name={`products.${index}.quantity`}
                                                control={control}
                                                render={({ field }) => {
                                                    return (
                                                        <CurrencyInput
                                                            disabled={ExpenseNature.PurchaseOrder === nature}
                                                            name={field?.name}
                                                            ref={field.ref}
                                                            value={field?.value}
                                                            autoComplete="nope"
                                                            placeholder="Ex: 1000,0000"
                                                            parentClassName="w-[120px]"
                                                            error={formState.errors.products?.[index]?.quantity?.message}
                                                            onValueChange={handleChangeQuantity(item, index)}
                                                        />
                                                    );
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Text variant="h6" className="text-heading">
                                                {formatMoney(item.total)}
                                            </Text>
                                        </TableCell>
                                        <TableCell>{ExpenseNature.PurchaseOrder !== nature && <RemoveButton className="!static opacity-100 mt-0" onClick={handleRemoveProduct(index)} />}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
            </div>
            {Boolean(selectedProducts.length) && (
                <div className="bg-secondary-100 py-4 px-6 bg-opacity-20 text-right">
                    <Text className="text-base-700">Total consolidado: </Text>
                    <Text variant="body.medium.2xs" className="text-heading">
                        {formatMoney(consolidated)}
                    </Text>
                </div>
            )}
            <ItemFooter isValid={isFormValid} onGoBack={handleBack} />
        </form>
    );
};

export default Products;
