import { CrudPageProps } from 'types/graphql';
import { ExpensesContextProvider } from './context';
import CreateOrUpdateExpense from './create-or-update';
import { ExpenseNature } from 'types/models/expense';

type CreateOrUpdateExpensePageProps = {
    nature?: ExpenseNature;
} & CrudPageProps<{}>;

const CreateOrUpdateExpensePage = ({ nature, title }: CreateOrUpdateExpensePageProps) => {
    return (
        <ExpensesContextProvider>
            <CreateOrUpdateExpense nature={nature} title={title || 'Nova despesa'} />
        </ExpensesContextProvider>
    );
};

export default CreateOrUpdateExpensePage;
