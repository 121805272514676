import { booleanOptions, financialTypes, originOptions, receiptClassificationTypes } from 'utils/statics';
import FilterPeriod from 'pages/private/payment-order/filter/period';
import { Controller, UseFormReturn } from 'react-hook-form';
import Select from 'components/core/form/select';
import useGetCompaniesOptions from 'services/queries/companies/use-get-company-options';
import useGetClassifications from 'services/queries/classifications/use-get-classifications';
import { generateClassificationQuery } from 'services/queries/classifications/utils';
import useGetCustomerOptions from 'services/queries/customers/use-get-customer-options';
import { generateCustomerOptionsQuery } from 'services/queries/customers/utils';
import { ClassificationType } from 'types/models/classification';
import { fieldExists } from 'utils/validation';

type Props = {
    formMethods: UseFormReturn<any, any>;
    path?: string;
    visibleFields?: string[];
};

const ReceiptOrderFilterFields = ({ formMethods, path, visibleFields }: Props) => {
    const { control } = formMethods;

    const getFieldName = (name: string) => (Boolean(path) ? `${path}.${name}` : name);

    const companies = useGetCompaniesOptions();
    const costumers = useGetCustomerOptions(generateCustomerOptionsQuery());
    const classificationTypesOptions = useGetClassifications(generateClassificationQuery(ClassificationType.SaleOrderType));

    return (
        <>
            {fieldExists('customer', visibleFields) && (
                <Controller
                    name={getFieldName('customer')}
                    control={control}
                    render={({ field }) => {
                        const value = costumers?.data?.find((item) => item.value === +field?.value);

                        return (
                            <Select
                                {...field}
                                value={value}
                                options={costumers?.data || []}
                                label="Cliente"
                                placeholder="Selecione uma opção"
                                onChange={(option: any) => field.onChange(option?.value)}
                                isLoading={costumers?.isLoading}
                                isClearable={true}
                            />
                        );
                    }}
                />
            )}

            {fieldExists('companies', visibleFields) && (
                <Controller
                    name={getFieldName('companies')}
                    control={control}
                    render={({ field }) => {
                        const value = companies?.data?.filter((item) => field?.value?.includes(item?.value));

                        return (
                            <Select
                                {...field}
                                value={value}
                                options={companies?.data}
                                label="Empresa"
                                placeholder="Selecione"
                                onChange={(option: any) => {
                                    const ids = option.map((item) => item.value);

                                    field.onChange(ids);
                                }}
                                isLoading={companies?.isLoading}
                                isMulti={true}
                                isClearable={true}
                            />
                        );
                    }}
                />
            )}

            {fieldExists('provisionStartDate', visibleFields) && fieldExists('provisionEndDate', visibleFields) && (
                <FilterPeriod
                    control={control}
                    label="Período de previsão"
                    firstInput={{
                        inputProps: {
                            name: getFieldName('provisionStartDate'),
                            label: 'Inicio',
                        },
                    }}
                    secondInput={{
                        inputProps: {
                            name: getFieldName('provisionEndDate'),
                            label: 'Fim',
                        },
                    }}
                />
            )}

            {fieldExists('startPayday', visibleFields) && fieldExists('endPayday', visibleFields) && (
                <FilterPeriod
                    control={control}
                    label="Período de pagamento"
                    firstInput={{
                        inputProps: {
                            name: getFieldName('startPayday'),
                            label: 'Inicio',
                        },
                    }}
                    secondInput={{
                        inputProps: {
                            name: getFieldName('endPayday'),
                            label: 'Fim',
                        },
                    }}
                />
            )}

            {fieldExists('startDueDate', visibleFields) && fieldExists('endDueDate', visibleFields) && (
                <FilterPeriod
                    control={control}
                    label="Período de vencimento"
                    firstInput={{
                        inputProps: {
                            name: getFieldName('startDueDate'),
                            label: 'Inicio',
                        },
                    }}
                    secondInput={{
                        inputProps: {
                            name: getFieldName('endDueDate'),
                            label: 'Fim',
                        },
                    }}
                />
            )}

            {fieldExists('paid', visibleFields) && (
                <Controller
                    name={getFieldName('paid')}
                    control={control}
                    render={({ field }) => {
                        const newOptions = [...booleanOptions, { value: 'all', label: 'Todos' }];
                        const value = newOptions.find((item) => `${item.value}` === `${field.value}`);

                        return (
                            <Select
                                {...field}
                                value={value}
                                options={newOptions}
                                label="Pago"
                                placeholder="Selecione uma opção"
                                onChange={(option: any) => field.onChange(option.value)}
                                isClearable={false}
                                isMulti={false}
                            />
                        );
                    }}
                />
            )}

            {fieldExists('financialType', visibleFields) && (
                <Controller
                    name={getFieldName('financialType')}
                    control={control}
                    render={({ field }) => {
                        const value = financialTypes?.find((item) => item.value === field.value);

                        return (
                            <Select
                                {...field}
                                value={value}
                                options={financialTypes}
                                label="Analítico e Financeiro"
                                placeholder="Selecione uma opção"
                                onChange={(option: any) => field.onChange(option.value)}
                                isClearable={true}
                                isMulti={false}
                            />
                        );
                    }}
                />
            )}

            {fieldExists('saleOrderOrigin', visibleFields) && (
                <Controller
                    name={getFieldName('saleOrderOrigin')}
                    control={control}
                    render={({ field }) => {
                        const value = originOptions.find((item) => item.value === field.value);

                        return (
                            <Select
                                {...field}
                                value={value}
                                options={originOptions}
                                label="Origem"
                                placeholder="Selecione uma opção"
                                onChange={(option: any) => field.onChange(option.value)}
                                isClearable={true}
                                isMulti={false}
                            />
                        );
                    }}
                />
            )}

            {fieldExists('saleOrderClassification', visibleFields) && (
                <Controller
                    name={getFieldName('saleOrderClassification')}
                    control={control}
                    render={({ field }) => {
                        const value = classificationTypesOptions?.data?.find((item) => field?.value?.includes(item?.value));

                        return (
                            <Select
                                {...field}
                                value={value}
                                options={classificationTypesOptions?.data}
                                label="Tipo"
                                placeholder="Selecione"
                                onChange={(option: any) => {
                                    const ids = option.map((item) => item.value);

                                    field.onChange(ids);
                                }}
                                isLoading={classificationTypesOptions?.isLoading}
                                isMulti={true}
                                isClearable={true}
                            />
                        );
                    }}
                />
            )}

            {fieldExists('saleOrderType', visibleFields) && (
                <Controller
                    name={getFieldName('saleOrderType')}
                    control={control}
                    render={({ field }) => {
                        const value = receiptClassificationTypes.filter((item) => field?.value?.includes(item?.value));

                        return (
                            <Select
                                {...field}
                                value={value}
                                options={receiptClassificationTypes}
                                label="Tipos de Projetos"
                                placeholder="Selecione"
                                onChange={(option: any) => {
                                    const ids = option.map((item) => item.value);

                                    field.onChange(ids);
                                }}
                                isClearable={true}
                                isMulti={true}
                            />
                        );
                    }}
                />
            )}
        </>
    );
};

export default ReceiptOrderFilterFields;
