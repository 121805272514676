import Table from '@mui/material/Table/Table';
import TableRow from '@mui/material/TableRow/TableRow';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import Text from 'components/core/text';
import TableFooter from '@mui/material/TableFooter/TableFooter';
import { formatMoney } from 'utils/money';
import { useMemo } from 'react';
import { Transaction } from 'types/models/transaction';
import { formatDate } from 'utils/date';

type SelectedItemsTableProps = {
    items: Transaction[];
    isReceiptOrders?: boolean;
};

const SelectedItemsTable = ({ items, isReceiptOrders = false }: SelectedItemsTableProps) => {
    const total = useMemo(() => items.reduce((acc, curr) => acc + curr?.netValue || 0, 0), [items]);

    return (
        <div className="border border-base-300 rounded-[14px] mb-6 overflow-hidden">
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Text as="strong" className="text-base-500">
                                Código
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text as="strong" className="text-base-500">
                                Vencimento
                            </Text>
                        </TableCell>
                        {!isReceiptOrders && (
                            <TableCell>
                                <Text as="strong" className="text-base-500">
                                    Previsão
                                </Text>
                            </TableCell>
                        )}
                        <TableCell>
                            <Text as="strong" className="text-base-500">
                                Forma de pagamento
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text as="strong" className="text-base-500">
                                Valor
                            </Text>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item) => (
                        <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell>
                                <Text as="span" className="text-base-500">
                                    {item.code}
                                </Text>
                            </TableCell>
                            <TableCell>
                                <Text as="span" className="text-base-500">
                                    {formatDate(item.dueDate)}
                                </Text>
                            </TableCell>
                            {!isReceiptOrders && (
                                <TableCell>
                                    <Text as="span" className="text-base-500">
                                        {formatDate(item.paymentForecast)}
                                    </Text>
                                </TableCell>
                            )}
                            <TableCell>
                                <Text as="span" className="text-base-500">
                                    {item.paymentForm?.name || '-'}
                                </Text>
                            </TableCell>
                            <TableCell>
                                <Text as="span" className="text-base-500">
                                    {formatMoney(item.netValue)}
                                </Text>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow className="bg-base-200 border-t border-t-base-300">
                        <TableCell className="border-b-0" colSpan={isReceiptOrders ? 3 : 4}>
                            <Text className="text-base-500 uppercase font-bold">Total</Text>
                        </TableCell>
                        <TableCell className="border-b-0">
                            <Text className="text-heading font-bold">{formatMoney(total)}</Text>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </div>
    );
};

export default SelectedItemsTable;
