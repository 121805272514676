import { useEffect, useState } from 'react';
import ProductsReportHeader from './header';
import { productsReportColumns } from './utils';
import Datatable from 'components/core/table/datatable';
import useGetDataContentConfigFilters, { ProductsReportContentFiltersParams } from '../../hooks/use-get-data-content-filters';
import { GenericReportFilterPayload } from '../types';
import { ReportProductsFiltersPayload } from 'types/models/reports';
import useGetProductsReport from 'services/queries/reports/use-get-products-report';
import useConfig from 'store/config/use-config';
import { useMainContext } from 'pages/private/main/context';
import { initialReportFilters } from '../utils';
import { useReportsContext } from '../../context';
import { datatableColumnSort } from 'utils/datatable';

type ReportProductsContentProps = {
    endpoint: string;
    filtersContentConfig: ProductsReportContentFiltersParams;
    title: string;
};

const ReportProductsContent = ({ endpoint, filtersContentConfig, title }: ReportProductsContentProps) => {
    const [page, setPage] = useState(0);
    const [filters, setFilters] = useState<GenericReportFilterPayload<ReportProductsFiltersPayload>>(initialReportFilters);

    const { handleToggleSidebar } = useMainContext();
    const { showValues } = useReportsContext();

    const { config } = useConfig();
    const { data = [], isLoading } = useGetProductsReport({ endpoint, filters });
    const dataContentFiltersConfig = useGetDataContentConfigFilters(filtersContentConfig);

    const handleChangeFilters = (data?: GenericReportFilterPayload<ReportProductsFiltersPayload>) => {
        setFilters(data);
        setPage(0);
    };

    useEffect(() => {
        handleToggleSidebar(true);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <ProductsReportHeader
                title={title}
                dataContentFiltersConfig={dataContentFiltersConfig}
                endpoint={endpoint}
                filters={{ ...filters!, type: 'numbers' }}
                onSetFilters={handleChangeFilters}
                isSale={filtersContentConfig?.isSale || false}
            />
            <Datatable
                data={data}
                withPagination={true}
                loading={isLoading}
                columns={productsReportColumns(showValues, filtersContentConfig?.isSale || false)}
                options={{
                    sort: true,
                    filter: false,
                    search: true,
                    searchAlwaysOpen: true,
                    print: false,
                    download: false,
                    viewColumns: false,
                    serverSide: false,
                    enableNestedDataAccess: '.',
                    pagination: true,
                    elevation: 21,
                    searchPlaceholder: 'Buscar produto',
                    page,
                    rowsPerPage: config.tables.rowsPerPage,
                    onChangePage: setPage,
                    customSort: datatableColumnSort,
                }}
            />
        </>
    );
};

export default ReportProductsContent;
