import * as React from 'react';
const SvgIcoPushPin = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 20 20" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M12.657 11.426c.574.268.954.795 1.141 1.581a3.354 3.354 0 0 1-.221 2.256.595.595 0 0 1-.35.317.589.589 0 0 1-.468-.016l-3.731-1.738-2.416 4.02a.358.358 0 0 1-.175.136.268.268 0 0 1-.213-.009l-.009-.004c-.156-.073-.212-.195-.168-.366l1.32-4.55-3.513-1.638a.589.589 0 0 1-.314-.348.595.595 0 0 1 .018-.47 3.359 3.359 0 0 1 1.587-1.62c.724-.363 1.372-.41 1.946-.144l2.091-4.48a1.176 1.176 0 0 1-.627-.696 1.19 1.19 0 0 1 .037-.942c.141-.303.374-.514.698-.633.323-.12.636-.108.938.032l5.565 2.593c.302.14.511.373.628.697a1.19 1.19 0 0 1-.037.942 1.193 1.193 0 0 1-.699.633c-.323.119-.636.108-.937-.032l-2.091 4.48ZM7.97 9.596a.3.3 0 0 0 .171-.164l1.82-3.904a.3.3 0 0 0 .016-.237.296.296 0 0 0-.162-.171.297.297 0 0 0-.236-.015.3.3 0 0 0-.171.164l-1.82 3.905a.3.3 0 0 0-.016.236.296.296 0 0 0 .162.171c.08.038.16.043.236.015Z"
            clipRule="evenodd"
        />
    </svg>
);
export default SvgIcoPushPin;
