import TableCell from '@mui/material/TableCell/TableCell';
import { jsonToGraphQLQuery, VariableType } from 'json-to-graphql-query';
import { DatatableColumn } from 'types/graphql';
import dictionary from 'utils/dictionary';
import { pkColumnsKey } from 'utils/environment';
import { productTypes } from 'utils/statics';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import { formatNumberToPTBR } from 'utils/money';
import IconButton from '@mui/material/IconButton/IconButton';
import IcoShow from 'components/core/icon/files/ico-show';

export const getProductQuery = (productId?: string) => {
    return jsonToGraphQLQuery({
        query: {
            __name: 'GetProduct',
            productByPk: {
                __args: { id: Number(productId || 0) },
                id: true,
                name: true,
                code: true,
                approvalStatus: true,
                classificationCategoryId: true,
                classificationId: true,
                classificationUnitId: true,
                type: true,
            },
        },
    });
};

export const createOrUpdateProductMutation = (isEdit = false) => {
    return jsonToGraphQLQuery(
        {
            mutation: {
                __name: 'CreateOrUpdateProduct',
                __variables: {
                    ...(isEdit
                        ? {
                              payload: 'ProductSetInput',
                              id: 'Int!',
                          }
                        : {
                              payload: 'ProductInsertInput!',
                          }),
                },
                item: {
                    __aliasFor: isEdit ? 'updateProductByPk' : 'insertProductOne',
                    __args: {
                        ...(isEdit
                            ? {
                                  [pkColumnsKey]: { id: new VariableType('id') },
                                  _set: new VariableType('payload'),
                              }
                            : {
                                  object: new VariableType('payload'),
                              }),
                    },
                    id: true,
                },
            },
        },
        { pretty: true }
    );
};

export const productsColumns = (onSetSelectedProductId: React.Dispatch<React.SetStateAction<number | null>>): DatatableColumn[] => [
    {
        name: 'id',
        options: { display: 'excluded' },
    },
    {
        name: 'name',
        label: dictionary.content.graphql['name'],
    },
    {
        name: 'code',
        label: dictionary.content.graphql['code'],
    },
    {
        name: 'type',
        label: dictionary.content.graphql['type'],
        options: {
            sort: false,
            customBodyRender: (value) => {
                return productTypes.find((item) => item.value === value)?.label || '-';
            },
        },
    },
    {
        name: 'classification.name',
        label: dictionary.content.graphql['classification'],
    },
    {
        name: 'category.name',
        label: dictionary.content.graphql['category'],
    },
    {
        name: 'unit.name',
        label: dictionary.content.graphql['unit'],
    },
    {
        name: 'stockBalance',
        label: 'Estoque',
        options: {
            sort: false,
            customBodyRender: (value, { rowData }) => {
                return (
                    <div className="flex items-center gap-0.5">
                        {formatNumberToPTBR(value)}
                        <Tooltip placement="top" title="Ver estoque">
                            <IconButton onClick={onSetSelectedProductId.bind(this, rowData[0])} color="info">
                                <IcoShow width={16} height={16} />
                            </IconButton>
                        </Tooltip>
                    </div>
                );
            },
        },
    },
    {
        name: 'actions',
        label: 'Ações',
        options: {
            customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />,
        },
    },
];

export const productsStocksOrderByMap = {
    ['category.name']: 'category',
    ['classification.name']: 'classification',
    ['unit.name']: 'unit',
};
