import { DatatableColumn } from 'types/graphql';
import { getNumberValueOrZero } from 'pages/private/expenses/details/tabs/transactions/utils';
import ExternalButton from 'components/buttons/external';
import { saleOrderTypes } from 'utils/statics';
import ReportColumnValuePercentage from '../../components/value-percentage';

export const contractsReportColumns = (showValues: boolean): DatatableColumn[] => {
    return [
        {
            name: 'id',
            options: {
                display: 'excluded',
            },
        },
        {
            name: 'code',
            label: 'Código',
            options: {
                customBodyRender: (value, tableMeta) => {
                    const [id] = tableMeta.rowData;

                    return Boolean(value) ? <ExternalButton href={`/app/pedidos-de-venda/${id}`}>#{value}</ExternalButton> : '-';
                },
            },
        },
        {
            name: 'type',
            label: 'Tipo do Pedido',
            options: {
                customBodyRender: (type) => {
                    const findedType = saleOrderTypes.find((item) => item.value === type);

                    return <span className="whitespace-nowrap">{findedType?.label || '-'}</span>;
                },
            },
        },
        {
            name: 'company.name',
            label: 'Empresa',
            options: {
                customBodyRender: (value = '-') => <span className="whitespace-nowrap">{value}</span>,
            },
        },
        {
            name: 'branch.name',
            label: 'Filial',
            options: {
                customBodyRender: (value = '-') => <span className="whitespace-nowrap">{value}</span>,
            },
        },
        {
            name: 'customer.title',
            label: 'Cliente',
            options: {
                customBodyRender: (value = '-') => <span className="whitespace-nowrap overflow-hidden inline-block max-w-[300px] text-ellipsis">{value}</span>,
            },
        },
        {
            name: 'classification.name',
            label: 'Tipo de Obra',
            options: {
                customBodyRender: (value = '-') => <span className="whitespace-nowrap">{value}</span>,
            },
        },
        {
            name: 'consultant.name',
            label: 'Consultor',
            options: {
                customBodyRender: (value = '-') => <span className="whitespace-nowrap">{value}</span>,
            },
        },
        {
            name: 'saleOrderProducts.budget',
            label: 'VC',
            options: {
                customBodyRender: (value = 0) => {
                    const formattedValue = getNumberValueOrZero(value);

                    const spanClassByValue = formattedValue <= 0 ? 'text-system-danger-500' : 'text-system-success-500';

                    return <ReportColumnValuePercentage showValues={showValues} value={formattedValue} showPercentage={false} classes={spanClassByValue} />;
                },
            },
        },
        {
            name: 'saleOrderProducts.balance',
            label: 'VSC',
            options: {
                customBodyRender: (value = 0, tableMeta) => {
                    const formattedValue = getNumberValueOrZero(value);

                    const { columnIndex, rowData } = tableMeta;
                    const percentage = rowData[columnIndex + 1]; // get the next column value (percentage)

                    const spanClassByValue = formattedValue <= 0 ? 'text-system-danger-500' : 'text-system-success-500';

                    return <ReportColumnValuePercentage showValues={showValues} value={formattedValue} percentage={percentage} classes={spanClassByValue} />;
                },
            },
        },
        {
            name: 'saleOrderProducts.percentage',
            options: {
                display: 'excluded',
            },
        },
        {
            name: 'receipt',
            label: 'Receita',
            options: {
                customBodyRender: (value) => {
                    const formattedValue = getNumberValueOrZero(value);

                    return <ReportColumnValuePercentage showValues={showValues} value={formattedValue} showPercentage={false} classes="text-system-success-500" />;
                },
            },
        },
        {
            name: 'budget',
            label: 'Custo Original',
            options: {
                customBodyRender: (value = 0, tableMeta) => {
                    const formattedValue = getNumberValueOrZero(value);

                    const { columnIndex, rowData } = tableMeta;
                    const percentage = rowData[columnIndex + 1]; // get the next column value (percentage)

                    return <ReportColumnValuePercentage showValues={showValues} value={formattedValue} percentage={percentage} classes="text-system-danger-500" />;
                },
            },
        },
        {
            name: 'budgetPercentage',
            options: {
                display: 'excluded',
            },
        },
        {
            name: 'budgetCurrent',
            label: 'Custo Atualizado',
            options: {
                customBodyRender: (value = 0, tableMeta) => {
                    const formattedValue = getNumberValueOrZero(value);

                    const { columnIndex, rowData } = tableMeta;
                    const percentage = rowData[columnIndex + 1]; // get the next column value (percentage)

                    return <ReportColumnValuePercentage showValues={showValues} value={formattedValue} percentage={percentage} classes="text-system-danger-500" />;
                },
            },
        },
        {
            name: 'budgetCurrentPercentage',
            options: {
                display: 'excluded',
            },
        },
        {
            name: 'budgetDifference',
            label: 'Diferença',
            options: {
                customBodyRender: (value = 0, tableMeta) => {
                    const formattedValue = getNumberValueOrZero(value);

                    const { columnIndex, rowData } = tableMeta;
                    const percentage = rowData[columnIndex + 1]; // get the next column value (percentage)

                    const spanClassByValue = formattedValue <= 0 ? 'text-system-danger-500' : 'text-system-success-500';

                    return <ReportColumnValuePercentage showValues={showValues} value={formattedValue} percentage={percentage} classes={spanClassByValue} />;
                },
            },
        },
        {
            name: 'budgetDifferencePercentage',
            options: {
                display: 'excluded',
            },
        },
        {
            name: 'tax',
            label: 'Imposto',
            options: {
                customBodyRender: (value = 0, tableMeta) => {
                    const formattedValue = getNumberValueOrZero(value);

                    const { columnIndex, rowData } = tableMeta;
                    const percentage = rowData[columnIndex + 1]; // get the next column value (percentage)

                    const spanClassByValue = 'text-system-danger-500';

                    return <ReportColumnValuePercentage showValues={showValues} value={formattedValue} percentage={percentage} classes={spanClassByValue} />;
                },
            },
        },
        {
            name: 'taxPercentage',
            options: {
                display: 'excluded',
            },
        },
        {
            name: 'commission',
            label: 'Comissão',
            options: {
                customBodyRender: (value = 0, tableMeta) => {
                    const formattedValue = getNumberValueOrZero(value);

                    const { columnIndex, rowData } = tableMeta;
                    const percentage = rowData[columnIndex + 1]; // get the next column value (percentage)

                    const spanClassByValue = 'text-system-danger-500';

                    return <ReportColumnValuePercentage showValues={showValues} value={formattedValue} percentage={percentage} classes={spanClassByValue} />;
                },
            },
        },
        {
            name: 'commissionPercentage',
            options: {
                display: 'excluded',
            },
        },
        {
            name: 'balanceProjected',
            label: 'Saldo Líquido',
            options: {
                customBodyRender: (value = 0, tableMeta) => {
                    const formattedValue = getNumberValueOrZero(value);

                    const { columnIndex, rowData } = tableMeta;
                    const percentage = rowData[columnIndex + 1]; // get the next column value (percentage)

                    const spanClassByValue = formattedValue <= 0 ? 'text-system-danger-500' : 'text-system-success-500';

                    return <ReportColumnValuePercentage showValues={showValues} value={formattedValue} percentage={percentage} classes={spanClassByValue} />;
                },
            },
        },
        {
            name: 'balanceProjectedPercentage',
            options: {
                display: 'excluded',
            },
        },
        {
            name: 'balanceCurrent',
            label: 'Saldo Líquido Atualizado',
            options: {
                customBodyRender: (value = 0, tableMeta) => {
                    const formattedValue = getNumberValueOrZero(value);

                    const { columnIndex, rowData } = tableMeta;
                    const percentage = rowData[columnIndex + 1]; // get the next column value (percentage)

                    const spanClassByValue = formattedValue <= 0 ? 'text-system-danger-500' : 'text-system-success-500';

                    return <ReportColumnValuePercentage showValues={showValues} value={formattedValue} percentage={percentage} classes={spanClassByValue} />;
                },
            },
        },
        {
            name: 'balanceCurrentPercentage',
            options: {
                display: 'excluded',
            },
        },
    ];
};

export const salesReportTotalsAcessors = [
    'receipt',
    'budget',
    'budgetCurrent',
    'balanceProjected',
    'balanceCurrent',
    'saleOrderProducts.budget',
    'saleOrderProducts.balance',
    null,
    'tax',
    'commission',
];
