import PageHeader from 'components/page/header';
import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import { useState } from 'react';
import Datatable from 'components/core/table/datatable';
import useGetTransactionsOutcome from 'services/queries/transactions/use-get-transactions-outcome';
import { listColumns } from './utils';
import useConfig from 'store/config/use-config';

const endpoint = '/transactions/outcomes/pending';

const PendingOrderPaymentPage = ({ page: pageProps }: WithPagePermissionsProps) => {
    const { config } = useConfig();

    const [filters, setFilters] = useState<any>({
        page: 1,
        searchText: '',
    });

    const { data, isLoading: isLoadingList } = useGetTransactionsOutcome({
        endpoint,
        filters: {
            ...filters,
            totalPerPage: config.tables.rowsPerPage,
        },
    });

    return (
        <>
            <PageHeader title={pageProps?.title} className="mb-6" />
            <Datatable
                withBorder={false}
                data={data?.items || []}
                options={{
                    sort: true,
                    filter: false,
                    search: true,
                    searchAlwaysOpen: true,
                    print: false,
                    download: false,
                    viewColumns: true,
                    serverSide: true,
                    selectableRows: 'none',
                    enableNestedDataAccess: '.',
                    pagination: true,
                    elevation: 21,
                    count: data?.total,
                    page: filters.page,
                    onChangePage: (currentPage) => {
                        setFilters((prev) => ({ ...prev, page: currentPage }));
                    },
                    onSearchChange: (searchText) => {
                        setFilters((prev) => ({ ...prev, searchText }));
                    },
                    onColumnSortChange: (changedColumn, direction) => {
                        setFilters((prev) => ({ ...prev, [changedColumn]: direction.toUpperCase() }));
                    },
                }}
                columns={listColumns}
                loading={isLoadingList}
                withItemsPerPage={false}
            />
        </>
    );
};

export default PendingOrderPaymentPage;
