import Text from 'components/core/text';
import { Transaction } from 'types/models/transaction';
import { formatDate } from 'utils/date';
import { formatMoney } from 'utils/money';

const ExpensesContentDetails = ({ paymentForm, dueDate, expense, createdAt, paymentForecast, netValue, grossValue, discount, addition, payday }: Partial<Transaction>) => {
    return (
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
            <Text className="text-base-500 block">
                Valor Bruto: <strong className="block">{formatMoney(grossValue)}</strong>
            </Text>
            <Text className="text-base-500 block">
                Acréscimos: <strong className="block">{formatMoney(addition)}</strong>
            </Text>
            <Text className="text-base-500 block">
                Descontos: <strong className="block">{formatMoney(discount)}</strong>
            </Text>
            <Text className="text-base-500 block">
                Valor líquido: <strong className="block">{formatMoney(netValue)}</strong>
            </Text>
            <Text className="text-base-500 block">
                Cadastro OP: <strong className="block">{formatDate(createdAt)}</strong>
            </Text>
            <Text className="text-base-500 block">
                Cadastro despesa: <strong className="block">{formatDate(expense?.createdAt)}</strong>
            </Text>
            <Text className="text-base-500 block">
                Previsão: <strong className="block">{formatDate(paymentForecast)}</strong>
            </Text>
            <Text className="text-base-500 block">
                Vencimento: <strong className="block">{formatDate(dueDate)}</strong>
            </Text>
            <Text className="text-base-500 block">
                Data de pagamento: <strong className="block">{formatDate(payday)}</strong>
            </Text>
            <Text className="text-base-500 block">
                Forma de pagamento: <strong className="block">{paymentForm?.name || '-'}</strong>
            </Text>
        </div>
    );
};

export default ExpensesContentDetails;
