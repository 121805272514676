import useToast from 'hooks/toast/use-toast';
import QueryString from 'qs';
import { useMutation } from 'react-query';
import api from 'services/api';

const usePrintTransactions = () => {
    const { showToast } = useToast();

    const request = async (payload) => {
        const query = QueryString.stringify(payload, { addQueryPrefix: true, encode: false, arrayFormat: 'brackets' });

        const { data } = await api.get<{ file: string }>('/transactions/outcomes' + query);

        return data;
    };

    return useMutation(request, {
        onError: () => {
            showToast(`Ocorreu um erro ao tentar imprimir o arquivo.`, 'danger');
        },
    });
};

export default usePrintTransactions;
