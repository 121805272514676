import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
import { File as FileApiModel } from 'types/models/file';
import { ExpenseNature } from 'types/models/expense';
import { ExpenseInstallments } from 'pages/private/purchase-orders/expenses/installments';
import { useShowApiErrors } from 'hooks/error/show-api-errors';
import { getPendingPurchaseOrderProductsApiKey, getPurchaseOrderKey } from '../purchase-order/keys';
import { getExpenseKey } from './keys';

export type CreateExpensePayload = {
    title: string;
    dueDate: string;
    provision: string;
    value: number;
    withProducts?: boolean;
    provisioned: boolean;
    instalments: number;
    company: number;
    branch: number;
    formFavored: {
        person?: number;
        provider?: number;
        favoredBankAccount?: number;
    };
    paymentForm: number;
    sector: number;
    category: number;
    classificationOrigin: number;
    classificationSubOrigin: number;
    transactions?: ExpenseInstallments[];
    products?: Array<{
        product: number;
        grossValue: number;
        quantity: number;
    }>;
    documents: Array<{
        file?: number;
        localFile: File;
        name: string;
        classification?: number;
    }>;
    nature?: ExpenseNature;
    competenceMonth: number;
    competenceYear: number;
};

const useCrudExpense = (expenseId?: string | number, purchaseOrderId?: string, redirectPath?: any) => {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const { showToastErrors } = useShowApiErrors();

    const isUpdate = !!expenseId;

    const uploadFile = async (file: File) => {
        const formData = new FormData();

        formData.append('file', file);

        const { data } = await api.post<FileApiModel>(`/files`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });

        if (!data?.id) {
            throw new Error();
        }

        return data?.id;
    };

    const request = async (payload: CreateExpensePayload) => {
        const documents: CreateExpensePayload['documents'] = [];

        if (!!payload?.documents?.length) {
            for (const document of payload.documents) {
                if (document?.localFile instanceof File) {
                    const fileId = await uploadFile(document?.localFile);

                    document.file = fileId;
                }

                documents.push(document);
            }
        }

        const { data } = await api({
            method: isUpdate ? 'patch' : 'post',
            url: isUpdate ? `/expenses/${expenseId}` : '/expenses',
            data: {
                ...payload,
                documents: documents.map(({ name, classification, file }: any) => ({
                    name,
                    classification,
                    file,
                })),
            },
        });

        return data;
    };

    return useMutation(request, {
        onSuccess: ({ id }) => {
            navigate(redirectPath || `/app/despesas/${id}`, { replace: true });

            if (isUpdate) {
                queryClient.invalidateQueries(getExpenseKey(expenseId));
            }

            if (!!purchaseOrderId) {
                queryClient.invalidateQueries(getPurchaseOrderKey(purchaseOrderId));
                queryClient.invalidateQueries(getPendingPurchaseOrderProductsApiKey(purchaseOrderId));
            }

            showToast(`Despesa ${isUpdate ? 'atualizada' : 'criada'} com sucesso`, 'success');
        },
        onError: (error: any) => {
            showToastErrors(error, error?.response?.data?.title);
        },
    });
};

export default useCrudExpense;
