import { DatatableColumn } from 'types/graphql';
import { TransactionDocumentType } from 'types/models/transaction';
import ExternalButton from 'components/buttons/external';
import Badge from 'components/core/badge';
import { formatDate } from 'utils/date';
import { natures } from 'utils/statics';
import { formatMoney } from 'utils/money';
import NFStatus from './nf-status';
import { generateExpenseTransactionStatus } from '../expenses/details/tabs/transactions/utils';
import DatatableRowColumn from 'components/core/table/datatable/components/column';

export const ordersTableColumns = (isTruncatedColumns = false): DatatableColumn[] => {
    return [
        {
            name: 'id',
            options: { display: 'excluded' },
        },
        {
            name: 'code',
            label: 'Código',
            options: {
                customBodyRender: (value, { rowData }) => {
                    const billed = rowData.at(-1);

                    if (!value) {
                        return '-';
                    }

                    return (
                        <>
                            <ExternalButton to={rowData[0].toString()}>{value}</ExternalButton>
                            {billed && <Badge variant="warning">Faturada</Badge>}
                        </>
                    );
                },
            },
        },
        {
            name: 'paymentForecast',
            label: 'Previsão',
            options: {
                customBodyRender: (value) => formatDate(value),
            },
        },
        {
            name: 'dueDate',
            label: 'Vencimento',
            options: {
                customBodyRender: (value) => formatDate(value),
            },
        },
        {
            name: 'createdAt',
            label: 'Cadastro',
            options: {
                customBodyRender: (value) => formatDate(value),
            },
        },
        {
            name: 'payday',
            label: 'Pagamento',
            options: {
                customBodyRender: (value) => formatDate(value),
            },
        },
        {
            name: 'paid',
            options: { display: 'excluded' },
        },
        {
            name: 'invoiceStatus',
            options: { display: 'excluded' },
        },
        {
            name: 'type',
            options: { display: 'excluded' },
        },
        {
            name: 'expense.sector.name',
            label: 'Setor',
            options: { customBodyRender: (value) => value || '-' },
        },
        {
            name: 'expense.category.name',
            label: 'Categoria',
            options: { customBodyRender: (value) => <span className="whitespace-nowrap">{value || '-'}</span> },
        },
        {
            name: 'expense.classificationOrigin.name',
            label: 'Origem',
            options: { customBodyRender: (value) => <span className="whitespace-nowrap">{value || '-'}</span> },
        },
        {
            name: 'expense.classificationSubOrigin.name',
            label: 'Sub-origem',
            options: { customBodyRender: (value) => <span className="whitespace-nowrap">{value || '-'}</span> },
        },
        {
            name: 'expense.nature',
            label: 'Natureza',
            options: {
                customBodyRender: (value) => {
                    const nature = natures.find((item) => item.value === value);

                    return <span className="whitespace-nowrap">{nature?.label || '-'}</span>;
                },
            },
        },
        {
            name: 'expense.title',
            label: 'Descrição',
            options: {
                customBodyRender: (description, { rowData }) => {
                    const installmentDescription = rowData.at(-2);

                    return (
                        <DatatableRowColumn dataId={rowData[0]} isTruncatedColumn={isTruncatedColumns}>
                            {description}
                            <br />
                            {installmentDescription}
                        </DatatableRowColumn>
                    );
                },
            },
        },
        {
            name: 'expense.favored',
            label: 'Favorecido',
            options: {
                customBodyRender: (value) => {
                    const { provider, person, title, document = '-' } = value || {};

                    return (
                        <div className="flex flex-col gap-1">
                            <span className="whitespace-nowrap">{title ?? person?.title ?? provider?.title ?? '-'}</span>
                            <span>{document}</span>
                        </div>
                    );
                },
            },
        },
        {
            name: 'expense.company.name',
            label: 'Empresa',
            options: { customBodyRender: (value) => <span className="whitespace-nowrap">{value || '-'}</span> },
        },
        {
            name: 'netValue',
            label: 'Valor',
            options: { customBodyRender: (value = 0) => formatMoney(Number(value)) },
        },
        {
            name: 'paymentForm.name',
            label: 'Forma de pagamento',
            options: { customBodyRender: (value) => value || '-' },
        },
        {
            name: 'fiscalDocument.file.id',
            options: { display: 'excluded' },
        },
        {
            name: 'fiscalDocument.name',
            label: 'Nota fiscal',
            options: {
                customBodyRender: (fiscalDocumentName, { rowData }) => {
                    const invoiceStatus = rowData[7] as number;
                    const fiscalDocumentId = rowData[19] as number;

                    return <NFStatus fiscalDocumentId={fiscalDocumentId} fiscalDocumentName={fiscalDocumentName} invoiceStatus={invoiceStatus} />;
                },
            },
        },
        {
            name: '',
            label: 'Status',
            options: {
                customBodyRender: (_, tableMeta) => {
                    const [, , , apiDueDate, , payday, paid] = tableMeta.rowData;

                    return generateExpenseTransactionStatus({ paid, payday, dueDate: apiDueDate });
                },
            },
        },
        {
            name: 'expense.approved',
            label: 'Status da despesa',
            options: {
                customBodyRender: (approved) => {
                    if (!approved) {
                        return <Badge variant="warning">Aguardando aprovação</Badge>;
                    }

                    return <Badge variant="success">Aprovada</Badge>;
                },
            },
        },
        {
            name: 'installmentDescription',
            options: { display: 'excluded' },
        },
        {
            name: 'expense.billed',
            options: { display: 'excluded' },
        },
    ];
};

export const movementDocumentsColumns = [
    {
        label: 'Nome',
    },
    {
        label: 'Descrição',
    },
    {
        label: 'Tipo',
    },
    {
        label: 'Arquivo',
    },
    {
        label: '',
    },
];

export const getTransactionDocumentByType = (type: TransactionDocumentType) => {
    const transactionDocumentTypeMap = {
        [TransactionDocumentType.Bill]: 'Conta',
        [TransactionDocumentType.Invoice]: 'Fatura',
        [TransactionDocumentType.PaymentVoucher]: 'Comprovante de pagamento',
    };

    return transactionDocumentTypeMap[type] || '-';
};
