import ExternalButton from 'components/buttons/external';
import Text from 'components/core/text';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { BankAccount } from 'types/models/bank';
import { Expense } from 'types/models/expense';
import { TransactionType } from 'types/models/transaction';
import { formatMoney } from 'utils/money';
import ExpenseProductsTable from 'pages/private/expenses/details/tabs/products/products-table';
import ExpenseProductTableFooter from './expense-product-table-footer';
import ExpenseBadges from './expense-badges';
import { natures } from 'utils/statics';

type PaymentOrderExpenseProps = {
    addition?: number;
    discount?: number;
    withholdingTaxInss?: number;
    withholdingTaxIss?: number;
    barCode?: string;
    favoredBankAccount?: Partial<BankAccount>;
    companyBankAccount?: Partial<BankAccount>;
    transactionType?: TransactionType;
    transferBetweenStocks?: boolean;
} & Expense;

const PaymentOrderExpense = ({
    approvalStatus,
    barCode,
    code,
    billed,
    instalments,
    provisioned,
    dueDate,
    edited,
    classificationOrigin,
    classificationSubOrigin,
    paymentForm,
    category,
    title,
    fiscalCode,
    company,
    branch,
    sector,
    id,
    favoredBankAccount,
    companyBankAccount,
    products = [],
    value = 0,
    addition = 0,
    discount = 0,
    withholdingTaxInss = 0,
    withholdingTaxIss = 0,
    transferBetweenStocks = false,
    payday,
    nature: natureProp,
}: PaymentOrderExpenseProps) => {
    const total = useMemo(() => {
        const discounts = withholdingTaxInss + withholdingTaxIss + discount;

        return value + addition - discounts;
    }, [value, discount, withholdingTaxInss, withholdingTaxIss, addition]);

    const nature = useMemo(() => {
        const finded = natures.find((item) => item.value === natureProp);

        if (!finded) {
            return '-';
        }

        return finded.label;
    }, [natureProp]);

    const decimalPlaces = transferBetweenStocks ? 4 : 2;

    return (
        <div className="p-6 sm:p-8">
            <div className="py-2 px-4 md:py-4 md:px-8 bg-base-300 bg-opacity-30 -m-6 sm:-mx-8 sm:-mt-8 -md:-m-8 mb-6 overflow-x-auto md:overflow-visible md:flex items-center justify-between">
                <Text as="span" variant="h6" className="text-secondary-500 block flex-1 mb-2 md:mb-0">
                    {formatMoney(Number(value || 0))} em {instalments || 0}x
                </Text>
                <ExpenseBadges approvalStatus={approvalStatus} billed={billed} edited={edited} payday={payday} provisioned={provisioned} />
            </div>
            <div className="mb-4 sm:mb-1 sm:flex items-center justify-between">
                <Text as="h2" variant="h4" className="text-heading mb-1 sm:mb-0">
                    #{code}
                </Text>
                <ExternalButton href={`/app/despesas/${id}`}>Ir para a despesa</ExternalButton>
            </div>
            {Boolean(title) && <Text className="text-base-500 block mb-8">{title}</Text>}
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 border-b border-b-base-300 mb-4 pb-4">
                <Text className="text-base-500 block mr-2">
                    Empresa: <strong>{company?.name || '-'}</strong>
                </Text>
                <Text className="text-base-500 block mr-2">
                    Filial: <strong>{branch?.name || '-'}</strong>
                </Text>
                <Text className="text-base-500 block mr-2">
                    Setor: <strong>{sector?.name || '-'}</strong>
                </Text>
                <Text className="text-base-500 block mr-2">
                    Vencimento: <strong>{dayjs.tz(dueDate).format('DD/MM/YYYY')}</strong>
                </Text>
                <Text className="text-base-500 block mr-2">
                    CFOP: <strong>{fiscalCode?.name || '-'}</strong>
                </Text>
                <Text className="text-base-500 block mr-2">
                    Categoria: <strong>{category?.name || '-'}</strong>
                </Text>
                <Text className="text-base-500 block mr-2">
                    Origem: <strong>{classificationOrigin?.name || '-'}</strong>
                </Text>
                <Text className="text-base-500 block mr-2">
                    Sub-origem: <strong>{classificationSubOrigin?.name || '-'}</strong>
                </Text>
                <Text className="text-base-500 block">
                    Natureza: <strong>{nature}</strong>
                </Text>
                <Text className="text-base-500 block mr-2">
                    Forma de pagamento: <strong>{paymentForm?.name || '-'}</strong>
                </Text>
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 mb-10">
                <Text className="text-base-500 block">
                    Conta bancária recebimento:{' '}
                    <strong className="block">
                        {Boolean(companyBankAccount) ? (
                            <>
                                Banco: {companyBankAccount?.bank?.name} <br />
                                Agência: {`${companyBankAccount?.agency}${!!companyBankAccount?.agencyDigit ? `-${companyBankAccount?.agencyDigit}` : ''}`} <br />
                                Conta: {`${companyBankAccount?.account}${!!companyBankAccount?.accountDigit ? `-${companyBankAccount?.accountDigit}` : ''}`} <br />
                                PIX: {companyBankAccount?.pix || '-'}
                            </>
                        ) : (
                            '-'
                        )}
                    </strong>
                </Text>
                <Text className="text-base-500 block">
                    Conta bancária favorecido:{' '}
                    <strong className="block">
                        {Boolean(favoredBankAccount) ? (
                            <>
                                Banco: {favoredBankAccount?.bank?.name} <br />
                                Agência: {`${favoredBankAccount?.agency}${!!favoredBankAccount?.agencyDigit ? `-${favoredBankAccount?.agencyDigit}` : ''}`} <br />
                                Conta: {`${favoredBankAccount?.account}${!!favoredBankAccount?.accountDigit ? `-${favoredBankAccount?.accountDigit}` : ''}`} <br />
                                PIX: {favoredBankAccount?.pix || '-'}
                            </>
                        ) : (
                            '-'
                        )}
                    </strong>
                </Text>
                <Text className="text-base-500 block">
                    Código de barras: <strong className="block">{barCode || '-'}</strong>
                </Text>
            </div>
            {Boolean(products.length) && (
                <ExpenseProductsTable
                    decimalPlaces={decimalPlaces}
                    products={products}
                    footer={
                        <ExpenseProductTableFooter
                            decimalPlaces={decimalPlaces}
                            withholdingTaxIss={withholdingTaxIss}
                            withholdingTaxInss={withholdingTaxInss}
                            addition={addition}
                            discount={discount}
                            total={total}
                        />
                    }
                />
            )}
        </div>
    );
};

export default PaymentOrderExpense;
