import Text from 'components/core/text';
import { Option } from 'types/general';
import { formatMoney } from 'utils/money';

type TotalcardsTaxesProps = {
    item: Option & { percentage?: number };
    index: number;
};

const TotalsCardTaxes = ({ item, index }: TotalcardsTaxesProps) => {
    return (
        <div className={`flex-1 ${index !== 0 ? 'px-4' : 'pr-4'}`}>
            <div>
                <Text as="p" className="text-base-500 font-normal text-sm">
                    {item.label}
                </Text>
                <div className="mt-2">
                    <Text as="p" className="text-heading font-medium text-[18px]">
                        {formatMoney(item.value || 0)}
                    </Text>
                </div>
                <div>
                    <Text as="span" className="text-[13px] text-red">
                        {item.percentage ? item.percentage.toFixed(2) : 0}%
                    </Text>
                </div>
            </div>
        </div>
    );
};

export default TotalsCardTaxes;
