import Text from 'components/core/text';
import SubfieldOption from './subfield-option';
import { FormField, FormFieldType } from 'types/models/form';
import SvgIcoCheckboxFilled from 'components/core/icon/files/ico-checkbox-filled';
import SvgIcoCheckboxOutline from 'components/core/icon/files/ico-checkbox-outline';
import classNames from 'classnames';
import theme from 'settings/theme';

type FieldOptionProps = {
    field: FormField & {
        answer: {
            selectedValue?: string | null;
            id?: string | undefined;
            value?: any;
            subfields?: any[];
        };
    };
    option: {
        id: number;
        label: string;
        value: string;
        subfields?: Array<Pick<FormField, 'id' | 'name' | 'type' | 'order' | 'required' | 'options' | 'value'> & { selectedValue: any }>;
    };
};

const optionClasses = 'box-with-border px-3 py-2';

const FieldOption = ({ field, option }: FieldOptionProps) => {
    const isTheOptionAnswer =
        field.type === FormFieldType.Checkbox && field.answer.selectedValue ? field.answer.selectedValue?.split(', ').includes(option.value) : option.value === field.answer.selectedValue;

    const IconOption = isTheOptionAnswer ? SvgIcoCheckboxFilled : SvgIcoCheckboxOutline;

    return (
        <div className={classNames(optionClasses, 'mb-2 last:mb-0')} key={option.id}>
            <div className="flex flex-row items-center">
                <Text as="p" className="text-base-500 flex-1 text-[13px]">
                    {option.label}
                </Text>
                <IconOption width={20} height={20} color={isTheOptionAnswer ? theme.extend.colors.system.success[500] : theme.extend.colors.base['400']} />
            </div>
            {Boolean(option.subfields?.length) && isTheOptionAnswer && (
                <div className={classNames(optionClasses, '!p-0 mt-2 overflow-hidden')}>
                    <Text variant="body.medium.xs" as="label" className="block text-base-700 bg-base-300 py-2 px-3">
                        Campo(s) adicional(is)
                    </Text>
                    <div className="p-3 gap-2">
                        {option.subfields?.map((subfield) => {
                            return (
                                <div className="mb-2 last:mb-0" key={subfield.id}>
                                    <Text as="p" variant="body.regular.xs" className="font-semibold text-base-700 mb-1">
                                        {subfield.name}
                                    </Text>
                                    {Boolean(subfield.options.length) ? (
                                        <div className="mb-1 last:mb-0">
                                            {subfield.options.map((subOption) => (
                                                <SubfieldOption subOption={subOption} subfield={subfield} key={`${subfield.id}_${subOption.value}`} />
                                            ))}
                                        </div>
                                    ) : (
                                        <Text as="p" variant="body.regular.xs" className="text-base-500">
                                            {subfield.selectedValue || 'Não informado'}
                                        </Text>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};

export default FieldOption;
