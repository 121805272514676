import { useQuery } from 'react-query';
import api from 'services/api';
import { getSalesOrdersReportKey } from './keys';
import qs from 'qs';
import { SalesOrderReportFiltersPayload, SalesOrdersReportResponse } from 'types/models/reports';
import { GenericReportFilterPayload } from 'pages/private/reports/screens/types';

const useGetSalesOrdersReport = (filters?: GenericReportFilterPayload<SalesOrderReportFiltersPayload>) => {
    const queryFilters = qs.stringify(filters, { addQueryPrefix: true, encode: false, arrayFormat: 'brackets', skipNulls: true });

    const request = async () => {
        const { data } = await api.get<SalesOrdersReportResponse[]>('/reports/sale-orders' + queryFilters);

        return data;
    };

    return useQuery(getSalesOrdersReportKey(queryFilters), request);
};

export default useGetSalesOrdersReport;
