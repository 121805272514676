import ListPage from 'pages/shared/crud/list';
import { useOutletContext, useParams } from 'react-router-dom';
import { SaleOrder } from 'types/models/sale-order';
import { saleOrderProductsListConfig } from '../utils';
import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import useGetSaleOrderFinancialBalance from 'services/queries/sales-order/use-get-sale-order-financial-balance';
import ExpenseTotalCard from '../service-order/expenses/total-card';
import Spinner from 'components/core/spinner';
import FinancialBalanceTaxHeaderCard from './expense-card-tax-header';
import { useState } from 'react';
import FinancialBalanceModalTaxes from '../service-order/financial-balance/totals/modal-taxes';

const valueContentClasses = 'flex-col xl:!flex-row !items-center gap-2 !text-base';

const SaleOrderFinancialBalancePage = ({ page }: WithPagePermissionsProps) => {
    const [isModalTaxOpen, setIsModalTaxOpen] = useState(false);

    const { saleOrderId } = useParams();
    const { origin } = useOutletContext<Partial<SaleOrder>>();

    const { data: financialBalance, isLoading: isLoadingFinancialBalance } = useGetSaleOrderFinancialBalance(saleOrderId);

    const listConfig = saleOrderProductsListConfig(saleOrderId, origin, true);

    return (
        <>
            {isLoadingFinancialBalance && <Spinner fixed={true} />}
            <div className="grid 2xl:grid-cols-5 xl:grid-cols-4 sm:grid-cols-2 gap-4 mb-6">
                <ExpenseTotalCard
                    variant="total"
                    title="Venda"
                    value={{
                        data: financialBalance?.total || 0,
                        contentContainerClassName: '!text-base',
                    }}
                />
                <ExpenseTotalCard
                    variant="toBePaid"
                    title="Custo Total"
                    value={{
                        data: financialBalance?.totalCost || 0,
                        contentContainerClassName: valueContentClasses,
                    }}
                    percentage={{
                        data: financialBalance?.totalCostPercentage || 0,
                    }}
                />
                <ExpenseTotalCard
                    variant="toBePaid"
                    title="Imposto"
                    value={{
                        data: financialBalance?.tax || 0,
                        contentContainerClassName: valueContentClasses,
                    }}
                    percentage={{
                        data: financialBalance?.taxPercentage || 0,
                    }}
                    head={{
                        data: <FinancialBalanceTaxHeaderCard onOpenTaxModal={setIsModalTaxOpen.bind(this, true)} />,
                    }}
                />
                <ExpenseTotalCard
                    variant="toBePaid"
                    title="Comissão"
                    value={{
                        data: financialBalance?.commission || 0,
                        contentContainerClassName: valueContentClasses,
                    }}
                    percentage={{
                        data: financialBalance?.commissionPercentage || 0,
                    }}
                />
                <ExpenseTotalCard
                    variant={(financialBalance?.result || 0) >= 0 ? 'paid' : 'toBePaid'}
                    title="Resultado"
                    value={{
                        data: financialBalance?.result || 0,
                        contentContainerClassName: valueContentClasses,
                    }}
                    percentage={{
                        data: financialBalance?.resultPercentage || 0,
                    }}
                />
            </div>

            <ListPage page={page} hideHeader={true} {...listConfig} />
            {isModalTaxOpen && <FinancialBalanceModalTaxes onClose={setIsModalTaxOpen.bind(this, false)} taxes={financialBalance?.taxes!} />}
        </>
    );
};

export default SaleOrderFinancialBalancePage;
