import { Outlet, useParams } from 'react-router-dom';
import Tabs from '@mui/material/Tabs/Tabs';
import Tab from '@mui/material/Tab/Tab';
import { useMemo } from 'react';
import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import { UseTabRouteChildren, useTabRoute } from 'hooks/tab/use-tab-route';
import Spinner from 'components/core/spinner';
import Empty from 'components/empty';
import useGetStock from 'services/queries/stocks/use-get-stock';
import useBreadcrumb from 'store/breadcrumb/use-breadcrumb';
import { StockType } from 'types/models/stock';

const getStockConfig = {
    fields: ['id', 'name', 'type'],
    name: 'GetStock',
    table: 'stockByPk',
};

const StockPage = ({ page }: WithPagePermissionsProps) => {
    const { stockId } = useParams();

    const { data: stock, isLoading, isError } = useGetStock({ ...getStockConfig, id: stockId }, stockId);

    const nav = useMemo(() => {
        const { subpages: pageSubpages = [] } = page || {};
        const subpagesArr: UseTabRouteChildren[] = [];

        for (const subpage of pageSubpages) {
            if (stock?.type === StockType.OrderService && subpage.key === 'stock-balance') {
                continue;
            }

            subpagesArr.push({
                label: subpage.title,
                path: subpage.link,
            });
        }

        return subpagesArr;
    }, [page, stock?.type]);

    const { handleChangeTab, activeTabIndex, tabLabels } = useTabRoute(nav);

    useBreadcrumb(stock?.name);

    if (isLoading) {
        return (
            <div className="relative w-full h-full">
                <Spinner />
            </div>
        );
    }

    if (isError || !stock) {
        return <Empty title="Estoque não encontrado" />;
    }

    return (
        <div className="bg-base-100 flex -ml-6 -mr-4 -my-6 h-[calc(100%+48px)] max-h-[calc(100%+48px)] overflow-hidden">
            <section className="flex-1 bg-base-200 overflow-y-auto relative w-full h-full">
                <div className="bg-base-100 mb-6 pl-6 pr-4">
                    <Tabs variant="scrollable" indicatorColor="secondary" value={activeTabIndex} onChange={handleChangeTab}>
                        {tabLabels.map((label) => (
                            <Tab label={label} key={label} className="text-base py-5" />
                        ))}
                    </Tabs>
                </div>
                <div className="px-6">
                    <Outlet context={stock} />
                </div>
            </section>
        </div>
    );
};

export default StockPage;

// TODO:
//  - Detalhes: layout de invoice
