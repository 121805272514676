import { PropsWithChildren } from 'react';
import classNames from 'classnames';
import Text, { TextElementVariant, TextVariant } from 'components/core/text';

type ErrorMessageProps = {
    visible: boolean;
    as?: TextElementVariant;
    variant?: TextVariant;
    className?: string;
};

const ErrorMessage = ({ as = 'span', variant = 'body.regular.xs', children, className, visible }: PropsWithChildren<ErrorMessageProps>) => {
    const textClasses = classNames(errorText, className);

    if (!visible) {
        return null;
    }

    return (
        <Text as={as} variant={variant} className={textClasses}>
            {children}
        </Text>
    );
};

const { errorText } = {
    errorText: 'text-system-danger-500 italic'
};

export default ErrorMessage;
