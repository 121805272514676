import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';

const PAYMENT_FORM_TYPE = 0;

export const generateGetTransactionsQuery = (where = {}) => {
    return jsonToGraphQLQuery(
        {
            query: {
                __name: 'GetTransactions',
                items: {
                    __aliasFor: 'transaction',
                    __args: {
                        orderBy: { code: new EnumType('ASC') },
                        where: {
                            ...where,
                            type: { _eq: PAYMENT_FORM_TYPE },
                        },
                    },
                    id: true,
                    code: true,
                    expense: {
                        company: { name: true },
                    },
                    grossValue: true,
                    classificationByClassificationPaymentFormId: { name: true },
                    invoiceStatus: true,
                },
            },
        },
        { pretty: true }
    );
};
