import { useCallback, useMemo } from 'react';
import Modal from 'components/core/modal';
import { useNavigate, useParams } from 'react-router-dom';
import Text from 'components/core/text';
import useTheme from '@mui/material/styles/useTheme';
import { Option } from 'types/general';
import useGetPersonContract from 'services/queries/people/use-get-person-contract';
import { formatDate } from 'utils/date';
import { formatMoney } from 'utils/money';
import _get from 'lodash/get';
import dictionary from 'utils/dictionary';
import Button from 'components/core/button';
import { PersonContractStatusTitle, PersonContractType } from 'types/models/person';
import IconButton from '@mui/material/IconButton/IconButton';
import Badge from 'components/core/badge';
import SvgIcoEdit from 'components/core/icon/files/ico-edit';

type Response = {
    id: number;
    contractStatus: {
        id: number;
        title: string;
    };
    position: {
        id: number;
        name: string;
    };
    contractType?: any;
    startDate: Date;
    finalDate?: any;
    earlyWarningStartDate?: any;
    earlyWarningFinalDate?: any;
    type: number;
    salary: string;
    juridicName?: any;
    juridicFantasyName?: any;
    juridicDocument?: any;
    vacationSchedules: any[];
    documents: any[];
    consultant: boolean;
};

const PersonContractDetails = () => {
    const { palette } = useTheme();
    const { contractId, personId } = useParams();

    const { data: personContract } = useGetPersonContract<Response>({ contractId, personId, isDetails: true });

    const navigate = useNavigate();

    const handleClose = () => navigate(`/app/pessoas/${personId}/contratos`);

    const handleClickEdit = () => navigate(`/app/pessoas/${personId}/contratos/editar/${contractId}`);

    const handleClickReactivateContract = useCallback(() => {
        navigate(`/app/pessoas/${personId}/contratos/reativar/${contractId}`);
    }, [personId, contractId, navigate]);

    const { contractStatus, startDate, finalDate, salary, position, type, juridicName, juridicFantasyName, juridicDocument, consultant } = personContract || {};

    const status = useMemo(() => {
        if (!contractStatus) {
            return;
        }

        const label = _get(dictionary.content.personContractStatusTitle, PersonContractStatusTitle[contractStatus.id!], '-');

        const isEligibleToReactivate = [
            PersonContractStatusTitle.MaternityLeave,
            PersonContractStatusTitle.MedicalCertificate,
            PersonContractStatusTitle.Vacation,
            PersonContractStatusTitle.SicknessAllowance,
        ].includes(contractStatus.id);

        if (!isEligibleToReactivate) {
            return label;
        }

        return (
            <div className="flex flex-col">
                <span className="mb-2">{label}</span>
                <Button onClick={handleClickReactivateContract} size="small" type="button" variant="contained" color="secondary" className="text-[0.6rem] py-[3px] px-[5px] mr-auto text-center">
                    Reativar contrato
                </Button>
            </div>
        );
    }, [handleClickReactivateContract, contractStatus]);

    const details: Option<any>[] = useMemo(() => {
        const arr = [
            {
                label: 'data inicial',
                value: formatDate(startDate),
            },
            {
                label: 'data final',
                value: formatDate(finalDate, undefined, 'Em andamento'),
            },
            {
                label: 'status',
                value: status,
            },
            {
                label: 'salário',
                value: formatMoney(Number(salary)),
            },
            {
                label: 'Cargo',
                value: position?.name,
            },
            {
                label: 'Tipo de contratação',
                value: _get(dictionary.content.contractTypes, PersonContractType[type!]?.toLocaleLowerCase(), ''),
            },
            {
                label: 'Consultor(a)',
                value: <Badge variant={Boolean(consultant) ? 'success' : 'error'}>{Boolean(consultant) ? 'Sim' : 'Não'}</Badge>,
            },
        ];

        if (type === PersonContractType.Juridic) {
            arr.push(
                {
                    label: 'Nome jurídico',
                    value: juridicName,
                },
                {
                    label: 'Nome fantasia',
                    value: juridicFantasyName,
                },
                {
                    label: 'CNPJ',
                    value: juridicDocument,
                }
            );
        }

        return arr.map(({ value, label }) => ({ value: value || '-', label }));
    }, [finalDate, juridicDocument, juridicFantasyName, juridicName, position, salary, startDate, status, type, consultant]);

    return (
        <Modal onClose={handleClose} contentClassnames="w-[1200px] max-w-full" closeOnClickOutside={false} containerClassnames="!z-[999]">
            <IconButton
                className="w-[34px] h-[34px] absolute right-[60px] top-4"
                color="warning"
                size="large"
                sx={{ backgroundColor: palette.warning.main, '&:hover': { backgroundColor: palette.warning.dark } }}
                onClick={handleClickEdit}>
                <SvgIcoEdit width={13} height={13} color={palette.grey[100]} />
            </IconButton>
            <div className="py-5 px-7">
                <Text as="h3" variant="h4" className="text-heading mb-6 mr-auto">
                    Detalhes do contrato
                </Text>
                <div className="grid grid-cols-4 gap-4 mb-6">
                    {details.map(({ label, value }, i) => (
                        <div key={`contract_detail_${i}`} className="flex flex-col">
                            <Text variant="body.medium.sm" as="span" className="mb-2 uppercase" style={{ color: palette.grey[500] }}>
                                {label}
                            </Text>
                            <Text variant="body.medium.sm" as="span" className="mb-1" style={{ color: palette.heading }}>
                                {value}
                            </Text>
                        </div>
                    ))}
                </div>
            </div>
        </Modal>
    );
};

export default PersonContractDetails;
