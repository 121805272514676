import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Text from 'components/core/text';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import CurrencyInput from 'components/core/form/currency';
import RemoveButton from 'components/buttons/remove';
import { useParams } from 'react-router-dom';
import { NumberFormatValues } from 'react-number-format/types/types';
import { formatMoney } from 'utils/money';

type ProductsRowProps = {
    item: any;
    index: number;
    onRemoveProduct: (index: number) => void;
};

const ProductsRow = ({ item, index, onRemoveProduct }: ProductsRowProps) => {
    const { importFlowId } = useParams();

    const isUpdate = Boolean(importFlowId);

    const { control, formState } = useFormContext<any>();

    const quantity = useWatch({ control, name: `products.${index}.quantity` }) || 0;
    const baseValue = useWatch({ control, name: `products.${index}.baseValue` }) || 0;
    const complementaryValue = useWatch({ control, name: `products.${index}.complementaryValue` }) || 0;

    const total = quantity * (baseValue + complementaryValue);

    const handleChangeValue = (onChange: (...event: any[]) => void) => async (values: NumberFormatValues) => {
        const { floatValue = 0 } = values;

        onChange(floatValue);
    };

    return (
        <TableRow classes={{ root: 'opacity-100 hover:bg-base-200' }}>
            <TableCell>
                <Text className="text-base-500">{item.name || '-'}</Text>
            </TableCell>
            <TableCell>
                <Controller
                    name={`products.${index}.quantity`}
                    control={control}
                    render={({ field }) => {
                        return (
                            <CurrencyInput
                                value={field.value}
                                onValueChange={handleChangeValue(field.onChange)}
                                placeholder="Ex: 1000"
                                parentClassName="w-[120px]"
                                error={formState.errors.products?.[index]?.quantity?.message}
                            />
                        );
                    }}
                />
            </TableCell>
            <TableCell>
                <Controller
                    name={`products.${index}.baseValue`}
                    control={control}
                    render={({ field }) => {
                        return (
                            <CurrencyInput
                                value={field.value}
                                onValueChange={handleChangeValue(field.onChange)}
                                placeholder="Ex: 1000"
                                withFormat={false}
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={4}
                                fixedDecimalScale={true}
                                parentClassName="min-w-[150px]"
                                error={formState.errors.products?.[index]?.baseValue?.message}
                            />
                        );
                    }}
                />
            </TableCell>
            <TableCell>
                <Controller
                    name={`products.${index}.complementaryValue`}
                    control={control}
                    render={({ field }) => {
                        return (
                            <CurrencyInput
                                value={field.value}
                                onValueChange={handleChangeValue(field.onChange)}
                                placeholder="Ex: 1000"
                                withFormat={false}
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={4}
                                fixedDecimalScale={true}
                                parentClassName="w-[120px]"
                                error={formState.errors.products?.[index]?.complementaryValue?.message}
                            />
                        );
                    }}
                />
            </TableCell>
            <TableCell>{formatMoney(total)}</TableCell>
            <TableCell>{!isUpdate && <RemoveButton className="!static opacity-100 mt-0" onClick={onRemoveProduct.bind(this, index)} />}</TableCell>
        </TableRow>
    );
};

export default ProductsRow;
