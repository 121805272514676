import useTheme from '@mui/material/styles/useTheme';
import Button from 'components/core/button';
import { useServiceOrderContext } from '../context';
import { Steps } from '../types';
import Text from 'components/core/text';
import Select from 'components/core/form/select';
import { formatMoney } from 'utils/money';
import { pluralize } from 'utils/string';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useGetClassifications from 'services/queries/classifications/use-get-classifications';
import { generateClassificationQuery } from 'services/queries/classifications/utils';
import { ClassificationType } from 'types/models/classification';
import { formatDate } from 'utils/date';
import { useCallback, useEffect, useMemo } from 'react';
import useCreateOrUpdateServiceOrder from 'services/queries/sales-order/use-create-or-update-service-order';
import { useOutletContext, useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { removeFalsyValues } from 'utils/object';
import ErrorMessage from 'components/error/message';
import { serviceOrderFinancialSchema } from './utils';
import classNames from 'classnames';
import FinancialItem from './financial-item';
import UpdateFinancial from './update-financial';
import omit from 'lodash/omit';
import SvgIcoArrowLeft from 'components/core/icon/files/ico-arrow-left';
import SvgIcoArrowRight from 'components/core/icon/files/ico-arrow-right';

export type ServiceOrderFinancialPayload = {
    installments: number;
    transactions: Partial<{
        order: number;
        description: string;
        dueDate: string;
        grossValue: number;
        paymentForm: number;
        transactionId?: number;
        paid?: boolean;
    }>[];
};

export const installments = Array.from(Array(24), (_, index) => ({
    value: index + 1,
    label: pluralize(index + 1, 'parcelas', 'parcela'),
}));

const ServiceOrderFinancial = () => {
    const { palette } = useTheme();
    const { saleOrderId, orderServiceId } = useParams();
    const { completeStep, setContent, state, goToStep } = useServiceOrderContext();

    const ctx = useOutletContext<any>();

    const { data: paymentForms = [] } = useGetClassifications(generateClassificationQuery(ClassificationType.PaymentForm));
    const { mutateAsync: createOrUpdateServiceOrder, isLoading: isSubmitting } = useCreateOrUpdateServiceOrder(saleOrderId, orderServiceId, ctx);

    const saleScopeTotalValue = useMemo(
        () => state.content.saleProducts?.filter((item) => item.checked).reduce((acc, curr) => acc + (curr.quantity || 0) * (curr.value || 0), 0) || 0,
        [state.content.saleProducts]
    );

    const budgetAverage = (value: any, context: any) => {
        if (!Array.isArray(value)) {
            return true;
        }

        const total = (value || []).reduce((acc, curr) => acc + (curr.grossValue || 0), 0);

        if (Number(total.toFixed(2)) !== Number(saleScopeTotalValue.toFixed(2))) {
            return false;
        }

        clearErrors(context.path);
        return true;
    };

    const methods = useForm<ServiceOrderFinancialPayload>({
        defaultValues: { transactions: [] },
        shouldFocusError: false,
        mode: 'onSubmit',
        resolver: yupResolver(serviceOrderFinancialSchema(saleScopeTotalValue, budgetAverage)) as any,
    });

    const { control, formState, watch, handleSubmit, reset, setValue, clearErrors, getValues } = methods;

    const { replace } = useFieldArray({ control, name: 'transactions' });

    const handleBack = () => {
        setContent({ transactions: getValues('transactions') });
        goToStep('previous');
    };

    const transactions = watch('transactions') || [];

    const handleSelectInstallments = useCallback(
        (option: any) => {
            setValue('installments', option.value);

            const installmentValue = saleScopeTotalValue / option.value;
            const roundedInstallmentValue = parseFloat(installmentValue.toFixed(2));

            const remainingAmount = saleScopeTotalValue - roundedInstallmentValue * (option.value - 1);
            const roundedRemainingAmount = parseFloat(remainingAmount.toFixed(2));

            const arr = Array.from(Array(option.value), (_, index) => ({
                order: index + 1,
                description: index + 1 === 1 ? 'Parcela 1/sinal' : `Parcela ${index + 1}`,
                dueDate: '',
                grossValue: index + 1 === option.value ? roundedRemainingAmount : roundedInstallmentValue,
            }));

            replace(arr);
        },
        [replace, setValue, saleScopeTotalValue]
    );

    const submit = async (data: ServiceOrderFinancialPayload) => {
        try {
            setContent(data);

            const { address, purchaseProducts = [], ...content } = state.content;

            delete content['installments'];

            const payload = {
                ...content,
                competenceDate: formatDate(content.competenceDate, 'YYYY-MM-DD'),
                startDate: formatDate(content.startDate, 'YYYY-MM-DD'),
                endDate: formatDate(content.endDate, 'YYYY-MM-DD'),
                contractor: content.contractor?.value,
                engineer: content.engineer?.value,
                ...(state.content.canRegisterPurchaseScope && {
                    purchaseProducts: purchaseProducts.map((item) => ({
                        product: item.product,
                        value: item.productValue,
                        quantity: item.quantity,
                        provision: formatDate(item.provision, 'YYYY-MM-DD'),
                        ...(Boolean(item.id) && {
                            id: item.id,
                        }),
                    })),
                }),
                saleProducts: content.saleProducts
                    ?.filter((item) => item.checked)
                    .map((item) => ({
                        product: item.product?.id,
                        value: item.value,
                        quantity: item.quantity,
                        predictedStartDate: formatDate(item.predictedStartDate, 'YYYY-MM-DD'),
                        predictedEndDate: formatDate(item.predictedEndDate, 'YYYY-MM-DD'),
                        ...(Boolean(item.saleProductId) && {
                            id: item.saleProductId,
                        }),
                    })),
                transactions: data.transactions.map((item, index) => ({
                    ...omit(item, ['paid', 'transactionId']),
                    order: index + 1,
                    description: index === 0 ? `Parcela ${index + 1}/sinal` : `Parcela ${index + 1}`,
                    grossValue: Number(item.grossValue?.toFixed(2)),
                    dueDate: formatDate(item.dueDate, 'YYYY-MM-DD'),
                    ...(Boolean(item.transactionId) && {
                        id: item.transactionId,
                    }),
                })),
                ...(!isEmpty(removeFalsyValues(address)) && { address }),
            };

            await createOrUpdateServiceOrder(payload);
        } catch (error) {
            console.log('submit', error);
        }
    };

    useEffect(() => {
        if (!!state.content.transactions?.length) {
            reset({
                installments: state.content.transactions.length,
                transactions: state.content.transactions,
            });

            replace(state.content.transactions);
        }
    }, [state, reset, replace]);

    useEffect(() => {
        completeStep(Steps.Financial);
        // eslint-disable-next-line
    }, []);

    return (
        <FormProvider {...methods}>
            <form className="h-full" onSubmit={handleSubmit(submit)}>
                <div className="flex flex-col-reverse max-h-[calc(100%-312px)] md:max-h-[calc(100%-275px)] md:flex-row">
                    {!orderServiceId && (
                        <div className="w-full md:w-[290px] flex flex-col border-t border-t-base-300 md:border-r md:border-t-0 md:border-base-300">
                            <Controller
                                control={control}
                                name="installments"
                                render={({ field }) => {
                                    const value = installments.find((item) => item.value === field.value);

                                    return (
                                        <Select
                                            value={value}
                                            onChange={handleSelectInstallments}
                                            options={installments}
                                            placeholder="Selecione a quantidade de parcelas"
                                            label="Parcelas"
                                            parentClassName="p-6 flex-1"
                                        />
                                    );
                                }}
                            />
                            <div className="bg-secondary-100 py-4 px-6 bg-opacity-20">
                                <Text className="text-base-700">Total do escopo de venda: </Text>
                                <Text variant="body.medium.2xs" className="text-heading block">
                                    {formatMoney(saleScopeTotalValue)}
                                </Text>
                            </div>
                        </div>
                    )}
                    {Boolean(transactions.length) ? (
                        <div className={classNames('flex-1 overflow-auto max-h-full', !orderServiceId ? 'p-6' : '')}>
                            <ErrorMessage className={classNames('block mb-4', Boolean(orderServiceId) ? 'p-6' : '')} visible={Boolean(formState.errors.transactions?.message)}>
                                {formState.errors.transactions?.message}
                            </ErrorMessage>
                            {!orderServiceId &&
                                transactions.map((item, index) => (
                                    <FinancialItem key={item.order} paymentForms={paymentForms} firstPaymentForm={transactions[0].paymentForm} index={index} item={item} />
                                ))}

                            {Boolean(orderServiceId) && <UpdateFinancial totalSaleScope={saleScopeTotalValue} paymentForms={paymentForms} transactions={transactions} />}
                        </div>
                    ) : (
                        <div className="p-6 flex-1 self-center">
                            <div className="flex items-center justify-center">
                                <Text as="p" variant="body.regular.sm" className="italic text-center text-base-500">
                                    Selecione uma data para buscar os faturamentos diretos.
                                </Text>
                            </div>
                        </div>
                    )}
                </div>
                <div className="border-t p-2 flex justify-between bg-base-100">
                    <Button startIcon={<SvgIcoArrowLeft width={18} color={palette.grey[700]} />} type="button" variant="text" color="inherit" className="min-w-[100px]" onClick={handleBack}>
                        Voltar
                    </Button>
                    <Button
                        disabled={isSubmitting}
                        loading={isSubmitting}
                        endIcon={<SvgIcoArrowRight width={18} color={isSubmitting ? palette.grey[400] : palette.secondary[500]} />}
                        variant="text"
                        color={isSubmitting ? 'inherit' : 'secondary'}
                        className="min-w-[100px]"
                        type="submit">
                        Finalizar
                    </Button>
                </div>
            </form>
        </FormProvider>
    );
};

export default ServiceOrderFinancial;
