import { getNumberValueOrZero } from 'pages/private/expenses/details/tabs/transactions/utils';
import { DatatableColumn } from 'types/graphql';
import ReportColumnValuePercentage from '../../components/value-percentage';
import { generateReportDefaultValuePercentage } from '../utils';

const resumesReceiptsReportsColumns = (showValues = true): DatatableColumn[] => {
    return [
        {
            name: 'contracts',
            label: 'Contratos',
            options: {
                customBodyRender: (value = 0, tableMeta) => {
                    const formattedValue = getNumberValueOrZero(value);

                    const { columnIndex, rowData } = tableMeta;
                    const percentage = rowData[columnIndex + 1]; // get the next column value (percentage)

                    return <ReportColumnValuePercentage showValues={showValues} value={formattedValue} percentage={percentage} />;
                },
            },
        },
        {
            name: 'contractsPercentage',
            options: {
                display: 'excluded',
            },
        },
        {
            name: 'saleOrders',
            label: 'Pedidos de venda',
            options: {
                customBodyRender: (value = 0, tableMeta) => {
                    const formattedValue = getNumberValueOrZero(value);

                    const { columnIndex, rowData } = tableMeta;
                    const percentage = rowData[columnIndex + 1]; // get the next column value (percentage)

                    return <ReportColumnValuePercentage showValues={showValues} value={formattedValue} percentage={percentage} />;
                },
            },
        },
        {
            name: 'saleOrdersPercentage',
            options: {
                display: 'excluded',
            },
        },
        {
            name: 'result',
            label: 'Total',
            options: {
                customBodyRender: (value = 0, tableMeta) => {
                    const formattedValue = getNumberValueOrZero(value);

                    const { columnIndex, rowData } = tableMeta;
                    const percentage = rowData[columnIndex + 1]; // get the next column value (percentage)

                    return <ReportColumnValuePercentage showValues={showValues} value={formattedValue} percentage={percentage} />;
                },
            },
        },
        {
            name: 'resultPercentage',
            options: {
                display: 'excluded',
            },
        },
    ];
};

const resumesReceiptsConsultantsColumns = (showValues = true): DatatableColumn[] => {
    return [
        {
            name: 'consultant.name',
            label: 'Consultor',
            options: {
                customBodyRender: (value = '-') => <span className="whitespace-nowrap overflow-hidden inline-block max-w-[280px] text-ellipsis">{value}</span>,
            },
        },
        ...resumesReceiptsReportsColumns(showValues),
    ];
};

const resumesReceiptsCompaniesColumns = (showValues = true): DatatableColumn[] => {
    return [
        {
            name: 'company.name',
            label: 'Empresa',
            options: {
                customBodyRender: (value = '-') => <span className="whitespace-nowrap overflow-hidden inline-block max-w-[280px] text-ellipsis">{value}</span>,
            },
        },
        ...resumesReceiptsReportsColumns(showValues),
    ];
};

export const resumesReceiptsBranchesColumns = (showValues = true): DatatableColumn[] => {
    return [
        {
            name: 'branch.name',
            label: 'Filial',
            options: {
                customBodyRender: (value = '-') => <span className="whitespace-nowrap overflow-hidden inline-block max-w-[280px] text-ellipsis">{value}</span>,
            },
        },
        ...resumesReceiptsReportsColumns(showValues),
    ];
};

export const resumeReceiptsTypesColumns = (showValues = true): DatatableColumn[] => {
    return [
        {
            name: 'type.title',
            label: 'Tipo',
            options: {
                customBodyRender: (value = '-') => <span className="whitespace-nowrap overflow-hidden inline-block max-w-[280px] text-ellipsis">{value}</span>,
            },
        },
        ...resumesReceiptsReportsColumns(showValues),
    ];
};

export const RESUME_REPORT_FOOTER_TOTALS = {
    columnValues1: generateReportDefaultValuePercentage(),
    columnValues2: generateReportDefaultValuePercentage(),
    columnValues3: generateReportDefaultValuePercentage(),
};

const resumesBalancesReportsColumns = (showValues = true): DatatableColumn[] => {
    return [
        {
            name: 'receipt',
            label: 'Receita',
            options: {
                customBodyRender: (value = 0, tableMeta) => {
                    const formattedValue = getNumberValueOrZero(value);

                    const { columnIndex, rowData } = tableMeta;
                    const percentage = rowData[columnIndex + 1]; // get the next column value (percentage)

                    return <ReportColumnValuePercentage showValues={showValues} value={formattedValue} percentage={percentage} classes="text-system-success-500" />;
                },
            },
        },
        {
            name: 'receiptPercentage',
            options: {
                display: 'excluded',
            },
        },
        {
            name: 'balanceProjected',
            label: 'Saldo Líquido',
            options: {
                customBodyRender: (value = 0, tableMeta) => {
                    const formattedValue = getNumberValueOrZero(value);

                    const { columnIndex, rowData } = tableMeta;
                    const percentage = rowData[columnIndex + 1]; // get the next column value (percentage)

                    const classes = formattedValue <= 0 ? 'text-system-danger-500' : 'text-system-success-500';

                    return <ReportColumnValuePercentage showValues={showValues} value={formattedValue} percentage={percentage} classes={classes} />;
                },
            },
        },
        {
            name: 'balanceProjectedPercentage',
            options: {
                display: 'excluded',
            },
        },
        {
            name: 'balanceCurrent',
            label: 'Saldo Líquido Atualizado',
            options: {
                customBodyRender: (value = 0, tableMeta) => {
                    const formattedValue = getNumberValueOrZero(value);

                    const { columnIndex, rowData } = tableMeta;
                    const percentage = rowData[columnIndex + 1]; // get the next column value (percentage)

                    const classes = formattedValue <= 0 ? 'text-system-danger-500' : 'text-system-success-500';

                    return <ReportColumnValuePercentage showValues={showValues} value={formattedValue} percentage={percentage} classes={classes} />;
                },
            },
        },
        {
            name: 'balanceCurrentPercentage',
            options: {
                display: 'excluded',
            },
        },
    ];
};

const resumesBalancesConsultantsColumns = (showValues = true) => {
    return [
        {
            ...resumesReceiptsConsultantsColumns(showValues)[0],
        },
        ...resumesBalancesReportsColumns(showValues),
    ];
};

const resumesBalancesCompaniesColumns = (showValues = true): DatatableColumn[] => {
    return [
        {
            ...resumesReceiptsCompaniesColumns(showValues)[0],
        },
        ...resumesBalancesReportsColumns(showValues),
    ];
};

const resumesBalancesBranchesColumns = (showValues = true): DatatableColumn[] => {
    return [
        {
            ...resumesReceiptsBranchesColumns(showValues)[0],
        },
        ...resumesBalancesReportsColumns(showValues),
    ];
};

const resumesBalancesTypesColumns = (showValues = true): DatatableColumn[] => {
    return [
        {
            ...resumeReceiptsTypesColumns(showValues)[0],
        },
        ...resumesBalancesReportsColumns(showValues),
    ];
};

export const generateClassesByValue = (withClassesByValue = false) => {
    return (value = 0) => {
        if (withClassesByValue) {
            return value <= 0 ? 'text-system-danger-500' : 'text-system-success-500';
        }
    };
};

export const resumesReceiptsConfigs = [
    {
        endpoint: '/reports/summary/consultants/receipts',
        columns: resumesReceiptsConsultantsColumns,
    },
    {
        endpoint: '/reports/summary/companies/receipts',
        columns: resumesReceiptsCompaniesColumns,
    },
    {
        endpoint: '/reports/summary/branches/receipts',
        columns: resumesReceiptsBranchesColumns,
    },
    {
        endpoint: '/reports/summary/types/receipts',
        columns: resumeReceiptsTypesColumns,
    },
];

export const resumesBalancesConfigs = [
    {
        endpoint: 'reports/summary/consultants/balances',
        columns: resumesBalancesConsultantsColumns,
    },
    {
        endpoint: 'reports/summary/companies/balances',
        columns: resumesBalancesCompaniesColumns,
    },
    {
        endpoint: 'reports/summary/branches/balances',
        columns: resumesBalancesBranchesColumns,
    },
    {
        endpoint: 'reports/summary/types/balances',
        columns: resumesBalancesTypesColumns,
    },
];
