import Badge from 'components/core/badge';
import { Status } from 'types/general';
import { CrudPageProps } from 'types/graphql';
import { formatDate } from 'utils/date';

export const notificationListConfig = (userId?: number): CrudPageProps<{}> => ({
    graphql: {
        table: 'notification',
        searchableField: 'text',
        columns: [
            {
                name: 'id',
                options: { display: 'excluded' },
            },
            {
                label: 'Assunto',
                name: 'subject',
                options: {
                    customBodyRender: (value = '-') => <span className="whitespace-nowrap">{value}</span>,
                },
            },
            {
                label: 'Mensagem',
                name: 'text',
                options: {
                    customBodyRender: (value = '-') => <span className="whitespace-nowrap">{value}</span>,
                },
            },
            {
                label: 'Data criação',
                name: 'createdAt',
                options: {
                    customBodyRender: (value) => formatDate(value),
                },
            },
            {
                label: 'Lida',
                name: 'read',
                options: {
                    customBodyRender: (value) => {
                        return <Badge variant={Boolean(value) ? 'success' : 'error'}>{Boolean(value) ? 'Sim' : 'Não'}</Badge>;
                    },
                },
            },
            {
                label: 'Data de leitura',
                name: 'readDate',
                options: {
                    customBodyRender: (value) => formatDate(value),
                },
            },
        ],
        where: {
            status: { _eq: Status.Active },
            userId: { _eq: userId },
        },
        orderBy: {
            createdAt: 'DESC',
        },
    },
});
