import { DatatableColumn } from 'types/graphql';
import { formatNumberToPTBR } from 'utils/money';
import { array, number, object, string } from 'yup';
import { genericReportSchema } from '../utils';
import { getNumberValueOrZero } from 'pages/private/expenses/details/tabs/transactions/utils';
import ReportColumnValuePercentage from '../../components/value-percentage';

export const productsReportColumns = (showValues = true, isSale: boolean): DatatableColumn[] => {
    return [
        {
            name: 'id',
            options: {
                display: 'excluded',
            },
        },
        {
            name: 'code',
            label: 'Código',
            options: {
                customBodyRender: (value) => {
                    return Boolean(value) ? `#${value}` : '-';
                },
            },
        },
        {
            name: 'name',
            label: 'Nome',
            options: {
                customBodyRender: (value = '-') => value,
            },
        },
        {
            name: isSale ? 'classification.name' : 'category.name',
            label: isSale ? 'Classificação' : 'Categoria',
            options: {
                customBodyRender: (value = '-') => value,
            },
        },
        {
            name: 'quantity',
            label: 'Quantidade',
            options: {
                customBodyRender: (value = 0, tableMeta) => {
                    const unit = tableMeta.rowData.at(-1);
                    // using dangerouslySetInnerHTML for unit (abbreviation) that may contain HTML Entities.
                    return <span dangerouslySetInnerHTML={{ __html: `${formatNumberToPTBR(value)} ${unit}` }} />;
                },
            },
        },
        {
            name: 'value',
            label: 'Valor',
            options: {
                customBodyRender: (value = 0, tableMeta) => {
                    const formattedValue = getNumberValueOrZero(value);

                    const { columnIndex, rowData } = tableMeta;
                    const percentage = rowData[columnIndex + 2]; // get the next column value (percentage)

                    return <ReportColumnValuePercentage showValues={showValues} value={formattedValue} percentage={percentage} classes="text-base !text-sm" />;
                },
            },
        },
        {
            name: 'average',
            label: 'Valor Unitário',
            options: {
                customBodyRender: (value = 0) => {
                    const formattedValue = getNumberValueOrZero(value);

                    return <span dangerouslySetInnerHTML={{ __html: `R$ ${formatNumberToPTBR(formattedValue)}` }} />;
                },
            },
        },
        {
            name: 'percentage',
            options: {
                display: 'excluded',
            },
        },
        {
            name: 'unit.abbreviation',
            options: {
                display: 'excluded',
            },
        },
    ];
};

export const saleTypeOptions = [
    {
        label: 'Todos',
        value: 'all',
    },
    {
        label: 'Contratos',
        value: 'orderService',
    },
    {
        label: 'Pedido de venda',
        value: 'saleOrder',
    },
];

export const reportProductsFiltersSchema = genericReportSchema(false, false).concat(
    object({
        products: array(number()).optional(),
        saleType: string().optional(),
        classifications: array(number()).optional(),
    })
);
