import Button from 'components/core/button';
import PageHeader from 'components/page/header';
import { useReportsContext } from '../context';
import IconButton from '@mui/material/IconButton/IconButton';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import SvgIcoShow from 'components/core/icon/files/ico-show';
import SvgIcoHide from 'components/core/icon/files/ico-hide';
import SvgIcoFilter from 'components/core/icon/files/ico-filter';

type ReportPageHeaderProps = {
    title: string;
    onOpenFilters: () => void;
};

const ReportPageHeader = ({ title, onOpenFilters }: ReportPageHeaderProps) => {
    const { showValues, toggleShowValues } = useReportsContext();

    const Icon = showValues ? SvgIcoShow : SvgIcoHide;

    return (
        <PageHeader
            title={title}
            className="mb-8"
            right={
                <div className="flex gap-2 items-center">
                    <Tooltip placement="top" title={`${showValues ? 'Esconder' : 'Mostrar'} valores`}>
                        <IconButton color="default" onClick={toggleShowValues}>
                            <Icon />
                        </IconButton>
                    </Tooltip>
                    <Button
                        color="inherit"
                        variant="outlined"
                        type="button"
                        size="medium"
                        className="bg-white"
                        onClick={onOpenFilters}
                        endIcon={<SvgIcoFilter width={17} height={17} color="currentColor" />}>
                        Filtrar
                    </Button>
                </div>
            }
        />
    );
};

export default ReportPageHeader;
