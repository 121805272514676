import classNames from 'classnames';
import Button from 'components/core/button';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';
import { MouseEventHandler } from 'react';

type FormButtonsProps = {
    isLoading: boolean;
    containerClassName?: string;
    submitButton?: Partial<{
        className: string;
        type: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
        label: string;
        isDisabled: boolean;
        onClick: MouseEventHandler<HTMLButtonElement>;
    }>;
    cancelButton?: Partial<{
        className: string;
        label: string;
        onClick: () => void;
    }>;
};

const FormButtons = ({ isLoading, containerClassName, cancelButton, submitButton }: FormButtonsProps) => {
    const goToParentRoute = useNavigateToParentRoute();

    const { label: submitLabel = 'Enviar', className: submitClassName, isDisabled = false, type = 'submit', onClick: onClickSend } = submitButton || {};
    const { label: cancelLabel = 'Cancelar', className: cancelClassName, onClick: onCancel } = cancelButton || {};

    const containerClasses = classNames('flex items-center', containerClassName);
    const submitClasses = classNames('min-w-[100px] mr-4', submitClassName);
    const cancelClasses = classNames('min-w-[100px]', cancelClassName);

    const handleCancel = () => {
        if (!onCancel) {
            return goToParentRoute();
        }

        onCancel();
    };

    return (
        <div className={containerClasses}>
            <Button
                disabled={isLoading || isDisabled}
                loading={isLoading}
                type={type}
                variant="contained"
                color="secondary"
                className={submitClasses}
                onClick={type === 'button' ? onClickSend : undefined}>
                {submitLabel}
            </Button>
            <Button color="inherit" className={cancelClasses} variant="outlined" onClick={handleCancel} disabled={isLoading}>
                {cancelLabel}
            </Button>
        </div>
    );
};

export default FormButtons;
