import { useQuery } from 'react-query';
import api from 'services/api';
import { getContractsReportKey } from './keys';
import qs from 'qs';
import { ContractsReportResponse } from 'types/models/reports';
import { GenericReportFilterPayload } from 'pages/private/reports/screens/types';

const useGetContractsReport = (filters?: GenericReportFilterPayload) => {
    const queryFilters = qs.stringify(filters, { addQueryPrefix: true, encode: false, arrayFormat: 'brackets', skipNulls: true });

    const request = async () => {
        const { data } = await api.get<ContractsReportResponse[]>('/reports/contracts' + queryFilters);

        return data;
    };

    return useQuery(getContractsReportKey(queryFilters), request);
};

export default useGetContractsReport;
