import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import { getAllKey } from '../crud/keys';
import useToast from 'hooks/toast/use-toast';
import { getPersonKey } from './keys';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';

const useCreateOrUpdatePersonContract = (personId?: string, contractId?: string, ctx?: any) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const goToParentRoute = useNavigateToParentRoute();

    const isEdit = !!contractId;

    const request = async (payload: any) => {
        const apiService = isEdit ? api.patch : api.post;
        const endpoint = isEdit ? `/people/${personId}/contracts/${contractId}` : `/people/${personId}/contracts`;

        return apiService<any>(endpoint, payload).then(({ data }) => data);
    };

    return useMutation(request, {
        onSuccess: () => {
            queryClient.invalidateQueries(getPersonKey(String(personId!)));

            queryClient.invalidateQueries(
                getAllKey('personContract', {
                    limit: ctx?.limit,
                    offset: ctx?.offset,
                    orderBy: ctx?.orderBy,
                    page: ctx?.page,
                    where: ctx?.where,
                })
            );

            goToParentRoute();

            showToast(`Contrato ${isEdit ? 'atualizado' : 'criado'} com sucesso`, 'success');
        },
        onError: () => {
            showToast(`Ocorreu um erro ao ${isEdit ? 'atualizar' : 'criar'} o contrato`, 'danger');
        },
    });
};

export default useCreateOrUpdatePersonContract;
