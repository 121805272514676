import { memo, useState } from 'react';
import classnames from 'classnames';
import DRETableBodyItem from './item';
import DRETableBodyHeader from './header';
import { TableItem } from 'types/models/reports';

type DRETableBodyProps = {
    items: TableItem[];
    numberOfCols: number;
    onClickRow?: (values: TableItem['values'][0]) => void;
};

const borderColor = `border-slate-100`;
const descriptionItem = `dre-description-item pl-5 p-2 break-words`;

const DRETableBody = ({ items, numberOfCols, onClickRow }: DRETableBodyProps) => {
    const tbody = classnames('border-b-2', borderColor);

    const getInitialClosedItems = () => {
        return (items?.map(({ isClosed = false }, index) => (isClosed ? index : null)).filter((i) => i !== null) as number[]) || [];
    };

    const [closedItems, setClosedItems] = useState<number[]>(getInitialClosedItems());

    const toggleItem = (index: number) => () => setClosedItems((old) => (old.includes(index) ? old.filter((i) => i !== index) : [...old, index]));

    const handleClickRow = (itemValue) => {
        if (typeof itemValue.title === 'string' && itemValue.title.toLowerCase() === 'total') {
            return;
        }

        onClickRow?.(itemValue);
    };

    return (
        <>
            {items.map((item, index) => {
                const isClosed = closedItems.includes(index);

                return (
                    <tbody className={tbody} key={`${item.title}-${index}`}>
                        <DRETableBodyHeader
                            hasToggleButton={item?.hasToggleButton}
                            headers={item?.headers || []}
                            onToggle={toggleItem(index)}
                            title={item?.title || ''}
                            numberOfCols={numberOfCols}
                            isClosed={isClosed}
                        />

                        {isClosed
                            ? null
                            : item?.values?.map((itemValue, index) => {
                                  const { values, title, className, customBodyComponent } = itemValue;

                                  if (customBodyComponent) {
                                      return customBodyComponent(values);
                                  }

                                  const isNotClickable = (typeof itemValue.title === 'string' && itemValue.title.toLowerCase() === 'total') || !onClickRow;
                                  const remainingColumns = numberOfCols - values?.length > 0 ? [...new Array(numberOfCols - values?.length).keys()] : [];
                                  const rowClasses = classnames('dre-tr bg-white', isNotClickable ? '' : 'cursor-pointer hover:bg-base-300');

                                  return (
                                      <>
                                          <tr className={rowClasses} key={`${title}_${itemValue.title}_${index}`} onClick={handleClickRow.bind(this, itemValue)}>
                                              {Boolean(title) && <td className={classnames(descriptionItem, className)}>{title}</td>}
                                              {values.map((value, ix) => {
                                                  const key = `tr_${title}_${itemValue.title}_${value.value}_${ix}`;

                                                  return <DRETableBodyItem className={classnames(className, value.className, !title && index === 0 ? 'rounded-tl-lg' : '')} {...value} key={key} />;
                                              })}
                                              {Boolean(itemValue?.hasToggleButton) &&
                                                  remainingColumns.map((indexBlank) => <DRETableBodyItem value={0} type="blank" key={`${title}_${itemValue.title}_blank_${index}_${indexBlank}`} />)}
                                          </tr>
                                      </>
                                  );
                              })}
                    </tbody>
                );
            })}
        </>
    );
};

export default memo(DRETableBody);
