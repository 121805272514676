import DownloadButton from 'components/download';
import { Status } from 'types/general';
import { CrudPageProps, DatatableColumn } from 'types/graphql';
import { formatDate } from 'utils/date';
import { formatMoney } from 'utils/money';

export const expenseLogsColumns: DatatableColumn[] = [
    {
        name: 'user.person.title',
        label: 'Funcionário',
        options: {
            customBodyRender: (value = '-') => <span className="whitespace-nowrap">{value}</span>,
        },
    },
    {
        name: 'description',
        label: 'Descrição',
        options: {
            customBodyRender: (value = '-') => <span className="whitespace-nowrap">{value}</span>,
        },
    },
    {
        name: 'createdAt',
        label: 'Data',
        options: {
            customBodyRender: (value) => formatDate(value, 'DD/MM/YYYY HH:mm'),
        },
    },
];

export const expenseBudgetsListConfig = (expenseId?: string): CrudPageProps<{}> => ({
    graphql: {
        table: 'expenseBudget',
        searchableField: 'provider',
        columns: [
            {
                name: 'id',
                options: { display: 'excluded' },
            },
            {
                name: 'provider',
                label: 'Fornecedor',
                options: {
                    customBodyRender: (value) => <span className="whitespace-nowrap">{value || '-'}</span>,
                },
            },
            {
                name: 'value',
                label: 'Valor',
                options: {
                    customBodyRender: (value) => formatMoney(value),
                },
            },
            {
                name: '',
                label: 'Arquivo',
                options: {
                    customBodyRender: (_, tableMeta) => {
                        const fileId = tableMeta.rowData[5];
                        const filename = tableMeta.rowData[4];

                        return <DownloadButton fileId={fileId} fileName={filename} />;
                    },
                },
            },
            {
                name: 'file.originalName',
                options: { display: 'excluded' },
            },
            {
                name: 'file.id',
                options: { display: 'excluded' },
            },
        ],
        where: {
            expenseId: {
                _eq: +expenseId!,
            },
            status: {
                _eq: Status.Active,
            },
        },
    },
});
