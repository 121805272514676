import * as React from 'react';
const SvgIcoUploadCloud = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 20" width="1em" height="1em" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M7.817 5.09c1.085-1.946 3.258-2.844 5.278-2.636 1.987.204 3.626 1.44 3.9 3.637.07.563.55.986 1.117.986 2.062 0 3.362 1.586 3.6 3.657.236 2.036-.63 3.985-2.386 4.665h-3.329a1.125 1.125 0 1 0 0 2.25h3.525c.12 0 .24-.02.355-.058 3.202-1.067 4.39-4.348 4.07-7.115-.292-2.538-1.918-5.127-4.894-5.58-.75-2.858-3.18-4.418-5.727-4.68-2.469-.254-5.206.681-6.897 2.898-1.352-.412-2.68.063-3.545.971-.84.881-1.286 2.208-.959 3.535C.382 8.845-.145 10.839.033 12.608c.207 2.059 1.41 4.244 3.627 4.983.114.038.234.058.355.058h3.523a1.125 1.125 0 1 0 .002-2.25H4.215c-1.043-.43-1.802-1.606-1.944-3.017-.148-1.464.409-2.752 1.533-3.302a1.125 1.125 0 0 0 .48-1.573c-.383-.662-.225-1.395.227-1.87.422-.442 1.035-.6 1.698-.158a1.125 1.125 0 0 0 1.607-.388Zm4.747 2.886-.796-.796-.795.796L7.449 11.5a1.125 1.125 0 0 0 1.59 1.59l1.604-1.603v7.152a1.125 1.125 0 0 0 2.25 0v-7.152l1.604 1.604a1.125 1.125 0 0 0 1.591-1.591l-3.524-3.524Z"
            clipRule="evenodd"
        />
    </svg>
);
export default SvgIcoUploadCloud;
