import { useQuery } from 'react-query';
import { getPurchaseOrderProductsKey } from './keys';
import apiGraphQl from 'services/graphql';
import { generateQuery } from 'utils/query';
import { PurchaseOrderProduct } from 'types/models/purchase-order';

type Response = {
    purchaseOrderProduct: Partial<PurchaseOrderProduct>[];
};

const useGetPurchaseOrderProducts = (purchaseOrderProductIds?: string[]) => {
    const query = generateQuery({
        fields: [
            'id',
            'quantity',
            'orderServiceProductPurchase.orderService.id',
            'orderServiceProductPurchase.orderService.saleOrderId',
            'orderServiceProductPurchase.orderService.title',
            'product.id',
            'product.name',
            'product.code',
            'product.classification.name',
            'product.classificationByClassificationUnitId.name',
            'purchaseOrder.id',
            'purchaseOrder.code',
            'purchaseValue',
            'orderServiceProductPurchase.value',
            'provision',
            'billed',
        ],
        name: 'GetPurchaseOrderProducts',
        table: 'purchaseOrderProduct',
        where: {
            id: { _in: purchaseOrderProductIds },
        },
    });

    const request = async () => apiGraphQl<Response>(query).then(({ purchaseOrderProduct }) => purchaseOrderProduct);

    return useQuery(getPurchaseOrderProductsKey(purchaseOrderProductIds), request, { enabled: Boolean(purchaseOrderProductIds?.length) });
};

export default useGetPurchaseOrderProducts;
