import * as React from 'react';
const SvgIcoHide = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 8" width="1em" height="1em" {...props}>
        <path
            fill="currentColor"
            d="M.292.356a.75.75 0 0 1 1.05.134l.003.004.02.023a5.84 5.84 0 0 0 .522.526c.386.344.979.793 1.785 1.192C4.729 2.758 6.162 3.2 8 3.2c1.837 0 3.27-.442 4.328-.965a8.104 8.104 0 0 0 1.785-1.192 5.858 5.858 0 0 0 .522-.526c.01-.011.016-.02.02-.023l.002-.003a.75.75 0 0 1 1.187.916l-.001.002-.002.002-.004.005-.012.016a3.347 3.347 0 0 1-.18.208c-.122.133-.3.315-.535.524a9.391 9.391 0 0 1-1.45 1.053l1.222 2.392a.75.75 0 1 1-1.336.682l-1.229-2.407c-.98.4-2.168.71-3.567.794V6.95a.75.75 0 1 1-1.5 0V4.678a11.14 11.14 0 0 1-3.568-.794L2.453 6.291a.75.75 0 1 1-1.336-.682L2.34 3.217a9.389 9.389 0 0 1-1.45-1.053 7.332 7.332 0 0 1-.675-.684 3.022 3.022 0 0 1-.04-.048l-.012-.016-.004-.005-.002-.002A.75.75 0 0 1 .292.355Z"
        />
    </svg>
);
export default SvgIcoHide;
