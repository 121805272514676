import TableCell from '@mui/material/TableCell/TableCell';
import { CrudPageProps } from 'types/graphql';
import Badge from 'components/core/badge';
import theme from 'settings/theme';
import { TransactionType } from 'types/models/transaction';

export const configReportPages = (onGoTo?: (path: string) => void): CrudPageProps<{}>[] => {
    const createCustomActions = (item: any) => {
        const arr = [
            {
                label: 'Abrir',
                icon: { name: 'ico-show', color: theme.extend.colors.base[500] },
                onClick: onGoTo?.(item?.id?.toString()),
            },
            {
                label: 'Editar',
                icon: { name: 'ico-edit', color: theme.extend.colors.base[500] },
                onClick: onGoTo?.(`editar/${item?.id}`),
            },
            {
                label: 'Visualizar agrupados',
                icon: { name: 'ico-show', color: theme.extend.colors.base[500] },
                onClick: onGoTo?.(`${item?.id?.toString()}/agrupados-por-anos`),
            },
        ];

        return arr;
    };

    return [
        {
            customActions: createCustomActions,
            title: 'Páginas de Relatório',
            graphql: {
                table: 'reportPage',
                orderBy: { name: 'DESC' },
                columns: [
                    {
                        name: 'id',
                        options: { display: 'excluded' },
                    },
                    { name: 'name', label: 'Nome' },
                    {
                        name: 'type',
                        label: 'Tipo',
                        options: {
                            customBodyRender: (value) => {
                                const types = new Map<TransactionType, { label: string; variant: any }>([
                                    [TransactionType.Outcome, { label: 'Despesa', variant: 'error' }],
                                    [TransactionType.Income, { label: 'Receita', variant: 'success' }],
                                ]);

                                const type = types.get(value);

                                if (!type) {
                                    return null;
                                }

                                return <Badge variant={type.variant}>{type.label}</Badge>;
                            },
                        },
                    },
                    {
                        name: 'actions',
                        label: 'Ações',
                        options: {
                            customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />,
                        },
                    },
                ],
            },
        },
        { title: 'Nova página de relatório' },
        { title: 'Atualizar página de relatório' },
    ];
};
