import { useMutation } from 'react-query';
import api from 'services/api';

const useFinalizeSaleNote = (saleNoteId: number) => {
    const request = async () => api.patch(`/sales-notes/${saleNoteId}/status`, { status: 'finalize' });

    return useMutation(request);
};

export default useFinalizeSaleNote;
