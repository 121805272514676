import { useQuery } from 'react-query';
import api from 'services/api';
import { getExpenseLogsKey } from './keys';
import { removeFalsyValues } from 'utils/object';
import qs from 'qs';
import { Expense } from 'types/models/expense';
import { Person } from 'types/models/person';
import { User } from 'types/models/user';
import { Transaction } from 'types/models/transaction';
import useConfig from 'store/config/use-config';

type GetExpensesLogsParams = {
    expenseId?: string;
    filters: any;
};

type ExpenseLogsResponse = Array<
    {
        data: Partial<Expense>;
    } & Pick<Person, 'email' | 'createdAt'> &
        Pick<User, 'person' | 'username'> &
        Pick<Transaction, 'description'>
>;

const useGetExpenseLogs = ({ expenseId, filters: propFilters }: GetExpensesLogsParams) => {
    const { config } = useConfig();

    const request = async () => {
        const filters = removeFalsyValues(propFilters || {});
        const { page, ...rest } = filters;

        const params = {
            filter: rest,
            totalPerPage: config.tables.rowsPerPage,
            ...(Boolean(page) && { page }),
        };

        const query = qs.stringify(params, { addQueryPrefix: true, encode: false, arrayFormat: 'brackets' });

        const endpoint = `/expenses/${expenseId}/logs` + query;

        const { data } = await api.get<ExpenseLogsResponse>(endpoint);

        return data;
    };

    return useQuery(getExpenseLogsKey(expenseId), request, { enabled: Boolean(expenseId) });
};

export default useGetExpenseLogs;
