import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from '@mui/material/TableRow/TableRow';
import CurrencyInput from 'components/core/form/currency';
import { Controller, useFormContext } from 'react-hook-form';
import { FinishOsPayload } from 'services/queries/order-services/use-finish-os';
import useTheme from '@mui/material/styles/useTheme';
import { Option } from 'types/general';
import Select from 'components/core/form/select';
import selectStyles from 'components/core/form/select/styles';
import SvgIcoTrash from 'components/core/icon/files/ico-trash';

type FinishOSItemProps = {
    stockIndex: number;
    productIndex: number;
    stocks: Option[];
    isLoadingStocks: boolean;
    onRemoveStock: () => void;
};

const FinishOSItem = ({ productIndex, stockIndex, stocks, isLoadingStocks, onRemoveStock }: FinishOSItemProps) => {
    const { palette } = useTheme();

    const { formState, control, watch } = useFormContext<FinishOsPayload>();

    const getFieldName = (field: keyof FinishOsPayload['products'][0]['stocks'][0]) => `products.${productIndex}.stocks.${stockIndex}.${field}` as const;

    const stockError = formState.errors?.products?.[productIndex]?.stocks?.[stockIndex]?.stockId?.message;

    const selectedStocks = watch(`products.${productIndex}.stocks`);
    const stocksFiltered = stocks.filter((item) => !selectedStocks.some((stock) => stock.stockId === item.value));

    return (
        <TableRow sx={{ '&:last-child td': { borderBottom: 0 } }}>
            <TableCell className="w-[49px] p-0 h-[49px]">
                <div className="w-full h-full">
                    <button className="flex items-center justify-center w-full h-full hover:bg-system-danger-100 hover:bg-opacity-50" onClick={onRemoveStock}>
                        <SvgIcoTrash width={16} height={16} color={palette.error[500]} />
                    </button>
                </div>
            </TableCell>
            <TableCell className="border-x border-x-base-300 p-0">
                <Controller
                    control={control}
                    name={getFieldName('stockId')}
                    render={({ field }) => {
                        const value = stocks.find((item) => item.value === field.value);

                        return (
                            <Select
                                {...field}
                                isLoading={isLoadingStocks}
                                value={value}
                                options={stocksFiltered}
                                placeholder="Selecione uma opção"
                                error={stockError}
                                showError={false}
                                selectStyles={{
                                    control: (base, data) => ({
                                        ...(selectStyles('medium', Boolean(stockError)).control!(base, data) as any),
                                        borderRadius: 0,
                                        borderColor: Boolean(stockError) ? palette.error[500] : 'transparent',
                                    }),
                                }}
                                onChange={(option: any) => field.onChange(option.value)}
                            />
                        );
                    }}
                />
            </TableCell>
            <TableCell className="p-0">
                <Controller
                    control={control}
                    name={getFieldName('quantity')}
                    render={({ field }) => {
                        return (
                            <CurrencyInput
                                value={field.value}
                                withFormat={false}
                                decimalScale={2}
                                className="border-none bg-transparent text-right"
                                inputWrapperClasses="border-none rounded-none"
                                placeholder="Digite uma quantidade"
                                onValueChange={({ floatValue }) => field.onChange(floatValue)}
                            />
                        );
                    }}
                />
            </TableCell>
        </TableRow>
    );
};

export default FinishOSItem;
