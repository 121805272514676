import classNames from 'classnames';
import CurrencyInput from 'components/core/form/currency';
import Input from 'components/core/form/input';
import Select from 'components/core/form/select';
import get from 'lodash/get';
import { Controller, useFormContext } from 'react-hook-form';
import useGetCompaniesOptions from 'services/queries/companies/use-get-company-options';
import useGetAllBanksOptions from 'services/queries/people/use-get-banks-options';
import { Status } from 'types/general';
import { CreateOrUpdateBankAccountPayload } from 'types/models/bank-accounts';
import { bankAccountTypes, pixTypes, statuses } from 'utils/statics';

type BankAccountFormProps = {
    baseFormPath?: string;
    isCompanyForm?: boolean;
    className?: string;
    withAccountType?: boolean;
};

const statusesWithoutDeleted = statuses.filter((status) => status.value !== Status.Deleted);

const BankAccountForm = ({ baseFormPath, isCompanyForm = false, className, withAccountType = false }: BankAccountFormProps) => {
    type Payload = typeof baseFormPath extends string ? { [baseFormPath: string]: CreateOrUpdateBankAccountPayload } : typeof baseFormPath extends undefined ? CreateOrUpdateBankAccountPayload : any;

    const containerClasses = classNames('md:grid md:grid-cols-3 gap-4', className);

    const { control, formState } = useFormContext<Payload>();

    const { data: banks = [], isLoading: isLoadingBanksOptions } = useGetAllBanksOptions();
    const { data: companies = [], isLoading: isLoadingCompanies } = useGetCompaniesOptions(undefined, undefined, isCompanyForm);

    const getFieldName = (fieldName: string) => [baseFormPath, fieldName].filter(Boolean).join('.');

    const getError = (fieldName: string) => get(formState.errors, getFieldName(fieldName))?.message as string;

    return (
        <div className={containerClasses}>
            <Controller name={getFieldName('name')} control={control} render={({ field }) => <Input {...field} label="Nome" error={getError('name')} parentClassName="col-span-2" />} />
            {!isCompanyForm && (
                <>
                    <Controller
                        name={getFieldName('type')}
                        control={control}
                        render={({ field }) => {
                            const value = bankAccountTypes.find((item) => item.value === field.value);

                            return (
                                <Select
                                    {...field}
                                    isDisabled={!withAccountType}
                                    placeholder="Selecione um tipo"
                                    label="Tipo de conta bancária"
                                    options={bankAccountTypes}
                                    value={value}
                                    error={getError('type')}
                                    onChange={(option: any) => field.onChange(option.value)}
                                />
                            );
                        }}
                    />
                </>
            )}
            <Controller
                name={getFieldName('bank')}
                control={control}
                render={({ field }) => {
                    const value = banks.find((item) => item.value === field.value);

                    return (
                        <Select
                            {...field}
                            isLoading={isLoadingBanksOptions}
                            placeholder="Selecione um banco"
                            label="Banco"
                            options={banks}
                            value={value}
                            error={getError('bank')}
                            onChange={(option: any) => field.onChange(option.value)}
                        />
                    );
                }}
            />
            <Controller
                name={getFieldName('agency')}
                control={control}
                render={({ field }) => (
                    <Input
                        {...field}
                        label="Agência"
                        autoComplete="nope"
                        mask="999999999999999999999"
                        placeholder="Ex: 9999"
                        maskPlaceholder={null}
                        error={getError('agency')}
                        right={
                            <Controller
                                name={getFieldName('agencyDigit')}
                                control={control}
                                render={({ field: agencyDigit }) => (
                                    <>
                                        <span className="w-2 bg-base-400 h-[1px]" />
                                        <Input
                                            {...agencyDigit}
                                            error={getError('agencyDigit')}
                                            placeholder="Ex: 9"
                                            className="bg-transparent my-[1px] rounded-l-[14px]"
                                            inputWrapperClasses="border-0 rounded-none"
                                            parentClassName="w-[62px]"
                                            showErrorMessage={false}
                                        />
                                    </>
                                )}
                            />
                        }
                    />
                )}
            />
            <Controller
                name={getFieldName('account')}
                control={control}
                render={({ field }) => (
                    <Input
                        {...field}
                        label="Conta"
                        autoComplete="nope"
                        mask="999999999999999999999"
                        placeholder="Ex: 9999"
                        maskPlaceholder={null}
                        error={getError('account')}
                        right={
                            <Controller
                                name={getFieldName('accountDigit')}
                                control={control}
                                render={({ field: accountDigit }) => (
                                    <>
                                        <span className="w-2 bg-base-400 h-[1px]" />
                                        <Input
                                            {...accountDigit}
                                            error={getError('accountDigit')}
                                            placeholder="Ex: 9"
                                            className="bg-transparent my-[1px] rounded-l-[14px]"
                                            inputWrapperClasses="border-0 rounded-none"
                                            parentClassName={classNames('w-[62px]', !!getError('accountDigit') && 'bg-system-danger-100 bg-opacity-30')}
                                            showErrorMessage={false}
                                        />
                                    </>
                                )}
                            />
                        }
                    />
                )}
            />
            {!isCompanyForm && (
                <>
                    <Controller
                        name={getFieldName('pixType')}
                        control={control}
                        render={({ field }) => {
                            const value = pixTypes.find((item) => item.value === field.value);

                            return (
                                <Select
                                    {...field}
                                    placeholder="Selecione um tipo"
                                    label="Tipo de chave PIX"
                                    options={pixTypes}
                                    value={value}
                                    error={getError('pixType')}
                                    onChange={(option: any) => field.onChange(option.value)}
                                />
                            );
                        }}
                    />
                    <Controller name={getFieldName('pix')} control={control} render={({ field }) => <Input {...field} label="Pix" error={getError('pix')} />} />
                </>
            )}
            {isCompanyForm && (
                <>
                    <Controller
                        name={getFieldName('openingBalance')}
                        control={control}
                        render={({ field }) => {
                            return (
                                <CurrencyInput
                                    placeholder="Ex: R$ 0,00"
                                    name={field.name}
                                    ref={field.ref}
                                    value={field.value}
                                    decimalScale={2}
                                    defaultValue={0}
                                    decimalSeparator="."
                                    fixedDecimalScale={true}
                                    label="Saldo Inicial"
                                    onValueChange={({ floatValue }) => field.onChange(floatValue)}
                                />
                            );
                        }}
                    />
                    <Controller
                        name={getFieldName('company')}
                        control={control}
                        render={({ field }) => {
                            const value = companies.find((company) => company?.value === field?.value);

                            return (
                                <Select
                                    options={companies}
                                    value={value}
                                    isLoading={isLoadingCompanies}
                                    isClearable={true}
                                    placeholder="Selecione uma empresa"
                                    label="Empresa"
                                    error={getError('company')}
                                    parentClassName="col-span-2"
                                    onChange={(option: any) => field.onChange(option.value)}
                                />
                            );
                        }}
                    />
                    <Controller
                        name={getFieldName('status')}
                        control={control}
                        render={({ field }) => {
                            const value = statusesWithoutDeleted.find((item) => item.value === field.value);

                            return (
                                <Select
                                    {...field}
                                    options={statusesWithoutDeleted}
                                    label="Status"
                                    value={value}
                                    onChange={(option: any) => field.onChange(option.value)}
                                    error={(formState.errors.status as any)?.message}
                                />
                            );
                        }}
                    />
                </>
            )}
        </div>
    );
};

export default BankAccountForm;
