import { createActions, createReducer } from 'reduxsauce';
import { BreadcrumbActionsType, BreadcrumbState, BreadcrumbActions } from './types';
import { RouteObject } from 'react-router-dom';

export const { Creators, Types } = createActions<Record<BreadcrumbActionsType, BreadcrumbActionsType>, BreadcrumbActions>({
    setRoutes: ['payload'],
});

export const BREADCRUMB_INITIAL_STATE: BreadcrumbState = {
    routes: [],
};

const setRoutes = (state = BREADCRUMB_INITIAL_STATE, action: { type: string; payload: RouteObject[] }): BreadcrumbState => ({
    ...state,
    routes: action.payload,
});

export default createReducer<BreadcrumbState>(BREADCRUMB_INITIAL_STATE, {
    [Types.SET_ROUTES]: setRoutes,
});
