import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter/TableFooter';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import classNames from 'classnames';
import CurrencyInput from 'components/core/form/currency';
import Text from 'components/core/text';
import { getNumberValueOrZero } from 'pages/private/expenses/details/tabs/transactions/utils';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { NumberFormatValues } from 'react-number-format';
import { Option } from 'types/general';
import { ConsultantSaleOrderType, SaleOrderType } from 'types/models/sale-order';
import { formatMoney } from 'utils/money';

export type CreateCommissionPayload = {
    provision: string;
    company: number;
    branch: number;
    sector: Option;
    category: Option;
    fiscalCode: Option;
    formFavored: {
        person: Option & {
            activeContract: {
                consultantManager: boolean;
                manager: {
                    id: number;
                    title: string;
                } | null;
            };
        };
        favoredBankAccount?: Option;
    };
    paymentForm: Option;
    commissions: Array<{
        id: number;
        saleOrder?: number;
        orderService?: number;
        percentage: number;
        code: string;
        netValue: number;
        commission: number;
        type: ConsultantSaleOrderType;
        consultant: {
            percentage: number;
        };
        manager: {
            percentage: number;
        };
    }>;
    saleOrderType: SaleOrderType;
};

const CommissionTable = () => {
    const { control, formState, watch } = useFormContext<CreateCommissionPayload>();

    useFieldArray({ control, name: 'commissions' });

    const [commissions, consultant] = watch(['commissions', 'formFavored.person']);

    const hasConsultantManager = Boolean(consultant?.activeContract?.manager);

    const handleChangeQuantity = (item: CreateCommissionPayload['commissions'][0], onChange: (...event: any[]) => void) => async (values: NumberFormatValues) => {
        const { floatValue: percentage = 0 } = values;

        onChange(percentage > 100 ? 100 : percentage);
    };

    const totalOfCommissions = commissions.reduce((acc, curr) => {
        const consultantValue = getNumberValueOrZero((curr.netValue || 0) * ((curr?.consultant?.percentage || 0) / 100));
        const managerValue = getNumberValueOrZero((curr.netValue || 0) * ((curr?.manager?.percentage || 0) / 100));

        const totalRowComissions = consultantValue + managerValue;

        return acc + totalRowComissions;
    }, 0);

    if (!commissions.length) {
        return null;
    }

    return (
        <div className="border border-base-300 rounded-[14px] mb-6 overflow-hidden">
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Text className="text-base-500 uppercase">Código</Text>
                            </TableCell>
                            <TableCell className="min-w-[150px]">
                                <Text className="text-base-500 uppercase">Valor total</Text>
                            </TableCell>
                            <TableCell className="min-w-[150px]">
                                <Text className="text-base-500 uppercase">Comissão</Text>
                            </TableCell>
                            <TableCell className="w-[160px] min-w-[160px]">
                                <Text className="text-base-500 uppercase">% Consultor</Text>
                            </TableCell>
                            <TableCell className="min-w-[150px]">
                                <Text className="text-base-500 uppercase">Valor consultor</Text>
                            </TableCell>
                            {hasConsultantManager && (
                                <>
                                    <TableCell className="w-[160px] min-w-[160px]">
                                        <Text className="text-base-500 uppercase">% Gerente</Text>
                                    </TableCell>
                                    <TableCell className="min-w-[150px]">
                                        <Text className="text-base-500 uppercase">Valor gerente</Text>
                                    </TableCell>
                                </>
                            )}
                            <TableCell className="min-w-[150px]">
                                <Text className="text-base-500 uppercase">Total</Text>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {commissions.map((item, index) => {
                            const consultantPercentageError = formState.errors.commissions?.[index]?.consultant?.message;
                            const managerPercentageError = formState.errors.commissions?.[index]?.manager?.message;

                            const commissionValue = getNumberValueOrZero(((item.netValue || 0) * (item.commission || 0)) / 100);
                            const consultantValue = getNumberValueOrZero((item.netValue || 0) * ((item.consultant?.percentage || 0) / 100));
                            const managerValue = getNumberValueOrZero((item.netValue || 0) * ((item.manager?.percentage || 0) / 100));

                            const commissionsTotalRow = consultantValue + managerValue;

                            return (
                                <TableRow key={item.id} classes={{ root: 'hover:bg-base-200 hover:bg-opacity-50' }}>
                                    <TableCell className="py-[14px] border-r border-r-base-300">
                                        <Text className="text-heading">{item.code || '-'}</Text>
                                    </TableCell>
                                    <TableCell className="py-[14px] border-r border-r-base-300">
                                        <Text className="text-heading">{formatMoney(item.netValue || 0)}</Text>
                                    </TableCell>
                                    <TableCell className="py-[14px] border-r border-r-base-300">
                                        <div className="flex items-center justify-between gap-2">
                                            <Text className="text-heading">{item.commission || 0}%</Text>
                                            <Text className="text-heading">{formatMoney(commissionValue)}</Text>
                                        </div>
                                    </TableCell>
                                    <TableCell className="border-r border-r-base-300 p-0">
                                        <Controller
                                            control={control}
                                            name={`commissions.${index}.consultant.percentage`}
                                            render={({ field }) => {
                                                return (
                                                    <Tooltip open={!!consultantPercentageError} placement="top" title={consultantPercentageError} disableFocusListener={true}>
                                                        <CurrencyInput
                                                            value={item.consultant?.percentage || field.value}
                                                            onValueChange={handleChangeQuantity(item, field.onChange)}
                                                            placeholder="Ex: 100"
                                                            maxLength={7}
                                                            inputWrapperClasses="border-none rounded-none"
                                                            className={classNames(
                                                                'focus:outline-2 -outline-offset-2 outline',
                                                                !!consultantPercentageError ? 'text-system-danger-500 outline-system-danger-100' : 'outline-primary-100'
                                                            )}
                                                            rightClasses="bg-base-200 px-4"
                                                            right={
                                                                <Text as="span" variant="body.medium.sm" className="text-heading">
                                                                    %
                                                                </Text>
                                                            }
                                                        />
                                                    </Tooltip>
                                                );
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell className="py-[14px] border-r border-r-base-300">
                                        <Text className="text-heading">{formatMoney(consultantValue)}</Text>
                                    </TableCell>

                                    {hasConsultantManager && (
                                        <>
                                            <TableCell className="border-r border-r-base-300 p-0">
                                                <Controller
                                                    control={control}
                                                    name={`commissions.${index}.manager.percentage`}
                                                    render={({ field }) => {
                                                        return (
                                                            <Tooltip open={!!managerPercentageError} placement="top" title={managerPercentageError} disableFocusListener={true}>
                                                                <CurrencyInput
                                                                    value={item.manager?.percentage || field.value}
                                                                    maxLength={7}
                                                                    onValueChange={handleChangeQuantity(item, field.onChange)}
                                                                    placeholder="Ex: 1000"
                                                                    inputWrapperClasses="border-none rounded-none"
                                                                    className={classNames(
                                                                        'focus:outline-2 -outline-offset-2 outline',
                                                                        !!managerPercentageError ? 'text-system-danger-500 outline-system-danger-100' : 'outline-primary-100'
                                                                    )}
                                                                    rightClasses="bg-base-200 px-4"
                                                                    right={
                                                                        <Text as="span" variant="body.medium.sm" className="text-heading">
                                                                            %
                                                                        </Text>
                                                                    }
                                                                />
                                                            </Tooltip>
                                                        );
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell className="py-[14px] border-r border-r-base-300">
                                                <Text className="text-heading">{formatMoney(managerValue)}</Text>
                                            </TableCell>
                                        </>
                                    )}

                                    <TableCell>
                                        <Text className="text-heading">{formatMoney(commissionsTotalRow)}</Text>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                    <TableFooter>
                        <TableRow className="bg-base-200">
                            <TableCell colSpan={hasConsultantManager ? 7 : 5} className="border-b-0">
                                <Text className="text-base-500 uppercase font-bold">Total</Text>
                            </TableCell>
                            <TableCell className="border-b-0">
                                <Text className="text-heading font-bold">{formatMoney(totalOfCommissions)}</Text>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </div>
    );
};

export default CommissionTable;
