import apiGraphQl from 'services/graphql';
import { getCompaniesKeys } from './keys';
import { useQuery } from 'react-query';
import { generateCompanies } from './utils';
import { WhereClause } from 'types/graphql';

const useGetCompanies = <T>(queryFields: string[], where?: WhereClause<unknown>) => {
    const request = async () => apiGraphQl<{ company: T }>(generateCompanies(queryFields, where)).then(({ company }) => company);

    return useQuery(getCompaniesKeys, request);
};

export default useGetCompanies;
