import { useQuery } from 'react-query';
import api from 'services/api';
import { getContributionsKey } from './keys';
import { TransactionsContributions } from 'types/models/contributions';
import { Pagination } from 'types/pagination';

type ContributionsResponse = Pagination<TransactionsContributions>;

const useGetContributions = (queryFilters?: string) => {
    const request = async () => {
        const { data } = await api.get<ContributionsResponse>(`transactions/contributions${Boolean(queryFilters) ? queryFilters : ''}`);

        return data;
    };

    return useQuery(getContributionsKey(queryFilters!), request, { enabled: Boolean(queryFilters) });
};

export default useGetContributions;
