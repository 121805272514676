import Tab from '@mui/material/Tab/Tab';
import Tabs from '@mui/material/Tabs/Tabs';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { useState } from 'react';
import MonthReportDetailsLegacyTab from './legacy';
import MonthReportDetailsOPsTab from './ops';
import { BlockFilter, TableItem } from 'types/models/reports';
import { TransactionType } from 'types/models/transaction';
import { formatMoney } from 'utils/money';
import dayjs from 'dayjs';
import useConfig from 'store/config/use-config';
import useGetTransactionsOutcome from 'services/queries/transactions/use-get-transactions-outcome';

type ModalMonthReportDetailsProps = {
    currentYear: number;
    reportPageType: TransactionType;
    filter?: BlockFilter;
    selectedValues: TableItem['values'][0];
    onClose: () => void;
};

const ModalMonthReportDetails = ({ currentYear, filter, reportPageType, selectedValues, onClose }: ModalMonthReportDetailsProps) => {
    const { config } = useConfig();

    const [activeTab, setActiveTab] = useState(0);
    const [filters, setFilters] = useState<any>({ ...filter, page: 1 });

    const handleChangeTab = (_: any, nextSlug: any) => setActiveTab(nextSlug);

    const endpoint = reportPageType === TransactionType.Outcome ? '/transactions/outcomes' : '/transactions/incomes';

    const selectedDate = dayjs()
        .year(currentYear)
        .month(selectedValues.monthNumber! - 1);

    const queryParams = {
        endpoint,
        filters: {
            ...filters,
            totalPerPage: config.tables.rowsPerPage,
            startPayday: selectedDate.startOf('month').format('YYYY-MM-DD'),
            endPayday: selectedDate.endOf('month').format('YYYY-MM-DD'),
        },
    };

    const { data: orders, isLoading: isLoadingOPs } = useGetTransactionsOutcome(queryParams);
    const { data: totalOrders } = useGetTransactionsOutcome({ ...queryParams, type: 'numbers' });

    const handleChangePage = (nextPage: number) => setFilters((prev) => ({ ...prev, page: nextPage }));

    const handleSort = (orderBy) => setFilters((prev) => ({ ...prev, orderBy }));

    return (
        <Modal
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    {selectedValues.modalTitle}
                </Text>
            }
            headerRight={
                <Text variant="body.regular.sm" className="text-heading !font-bold">
                    {formatMoney(selectedValues.monthTotal || 0)}
                </Text>
            }
            contentClassnames="xs:w-full sm:min-w-[500px] md:min-w-[800px] w-[1600px]"
            headerClassNames="border-b border-b-base-300"
            onClose={onClose}>
            <Tabs variant="scrollable" value={activeTab} onChange={handleChangeTab} className="border-b border-b-base-300">
                <Tab label="OPs" />
                <Tab label="Legado" />
            </Tabs>
            {activeTab === 0 && (
                <MonthReportDetailsOPsTab
                    data={orders?.items || []}
                    isLoading={isLoadingOPs}
                    queryParams={queryParams}
                    reportPageType={reportPageType}
                    totalItems={orders?.total || 0}
                    totalOrders={totalOrders?.total || 0}
                    onChangePage={handleChangePage}
                    onSort={handleSort}
                />
            )}
            {activeTab === 1 && <MonthReportDetailsLegacyTab data={selectedValues.legacyData || []} />}
        </Modal>
    );
};

export default ModalMonthReportDetails;
