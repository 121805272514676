import TableCell from '@mui/material/TableCell/TableCell';
import { CrudPageProps } from 'types/graphql';
import { CompanyTaxRegime } from 'types/models/company';
import dictionary from 'utils/dictionary';

export const literalByTaxRegime = {
    [CompanyTaxRegime.Simple]: 'Simples Nacional',
    [CompanyTaxRegime.PresumedProfit]: 'Lucro Presumido',
    [CompanyTaxRegime.RealProfit]: 'Lucro Real'
};

const config: CrudPageProps<{ name: string }>[] = [
    {
        title: 'Empresas',
        graphql: {
            table: 'company',
            searchableField: 'name',
            columns: [
                {
                    name: 'id',
                    options: { display: 'excluded' }
                },
                {
                    name: 'name',
                    label: dictionary.content.graphql['name']
                },
                {
                    name: 'taxRegime',
                    label: dictionary.content.graphql['taxRegime'],
                    options: {
                        customBodyRender: (_, tableMeta) => {
                            const taxRegime = tableMeta.rowData.at(-1)?.props?.item?.taxRegime;
                            return <span>{literalByTaxRegime[taxRegime] || ''}</span>;
                        }
                    }
                },
                {
                    name: 'actions',
                    label: 'Ações',
                    options: {
                        customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />
                    }
                }
            ]
        }
    },
    { title: 'Nova empresa' },
    { title: 'Atualizar empresa' }
];

export default config;
