import Modal from 'components/core/modal';
import Text from 'components/core/text';
import FormButtons from 'components/form-buttons';
import { PageProps } from 'pages/shared/page';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import AddButton from 'components/buttons/add';
import Input from 'components/core/form/input';
import FormItemGroup from './item-groups';
import useCreateOrUpdateForm, { FormPayload } from 'services/queries/forms/use-create-or-update-form';
import { FormFieldType, QuizType } from 'types/models/form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DEFAULT_FORM_GROUP, formSchema } from './utils';
import { useOutletContext } from 'react-router-dom';
import { GetPaginatedWithFiltersParams } from 'services/queries/general/use-get-paginated-with-filters';
import { FormGroupType } from 'types/models/form';

const CreateOrUpdateFormPage = ({ title }: PageProps) => {
    const listParams = useOutletContext<GetPaginatedWithFiltersParams>();

    const { mutateAsync: createOrUpdateForm, isLoading: isSubmitting } = useCreateOrUpdateForm(listParams);

    const methods = useForm<FormPayload>({
        defaultValues: {
            title: '',
            type: QuizType.Visit,
            groups: [DEFAULT_FORM_GROUP],
        },
        resolver: yupResolver(formSchema) as any,
    });

    const { control, formState, handleSubmit } = methods;

    const { fields, append, remove } = useFieldArray({ control, name: 'groups' });

    const submit = async (data: FormPayload) => {
        try {
            const payload = {
                ...data,
                groups: data.groups.map((group) => ({
                    ...group,
                    ...(group.type === FormGroupType.Conditional && { conditionalFieldType: FormFieldType.Checkbox }),
                    fields: group.fields.map((field) => ({
                        ...field,
                        ...(Boolean(field.options?.length) && {
                            options: field.options!.map((option) => ({
                                name: option.name,
                                ...(Boolean(option.subfields?.length) && { subfields: option.subfields }),
                            })),
                        }),
                    })),
                })),
            };

            await createOrUpdateForm(payload as any);
        } catch (error) {
            console.log('submit', error);
        }
    };

    return (
        <Modal
            contentClassnames="w-[1024px]"
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    {title}
                </Text>
            }
            closeOnClickOutside={false}>
            <FormProvider {...methods}>
                <form className="border-t border-t-base-300 pt-6" onSubmit={handleSubmit(submit)}>
                    <Controller control={control} name="title" render={({ field }) => <Input {...field} label="Título" error={formState.errors.title?.message} parentClassName="px-6 mb-6" />} />
                    <div className="px-6">
                        {fields.map((item, index) => {
                            return <FormItemGroup index={index} key={item.id} onRemove={remove} />;
                        })}
                    </div>
                    <div className="p-6">
                        <AddButton onClick={append.bind(this, { ...DEFAULT_FORM_GROUP, order: fields.length + 1 })}>Adicionar grupo</AddButton>
                    </div>
                    <FormButtons isLoading={isSubmitting} containerClassName="border-t border-t-base-300 p-6" />
                </form>
            </FormProvider>
        </Modal>
    );
};

export default CreateOrUpdateFormPage;
