import { BaseModel } from './base';
import { Stock, StockOrder, StockProduct } from './stock';

export type InventoryMovement = {
    stockOrderId: number;
    stockId: number;
    stockProductId: number;
    type: InventoryMovementType;
    quantity: number;
    value: number;
    stock: Partial<Stock>;
    stockOrder: Partial<StockOrder>;
    stockProduct: Partial<StockProduct>;
    reversal: boolean;
} & BaseModel;

export enum InventoryMovementType {
    Outcome = 1,
    Income,
}
