import { useQuery } from 'react-query';
import apiGraphQl from 'services/graphql';
import { Page } from 'types/models/page';
import { getPageKey } from './keys';

const useGetPage = (query: string, pageId?: string) => {
    const request = async () => apiGraphQl<{ pageByPk: Partial<Page> }>(query).then(({ pageByPk }) => pageByPk);

    return useQuery(getPageKey(pageId), request, { enabled: Boolean(pageId) });
};

export default useGetPage;
