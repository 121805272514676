import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import ListPage from 'pages/shared/crud/list';
import { memo } from 'react';
import { useParams } from 'react-router-dom';
import { orderPurchasePageListConfig } from './utils';

const OrderPurchasePage = ({ page }: WithPagePermissionsProps) => {
    const { orderServiceId } = useParams();

    const listConfig = orderPurchasePageListConfig(orderServiceId);

    return <ListPage {...listConfig} page={page} />;
};

export default memo(OrderPurchasePage);
