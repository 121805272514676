import * as React from 'react';
const SvgIcoPrinter = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M2.9.75A.75.75 0 0 1 3.65 0h8.7a.75.75 0 0 1 .75.75v4.325h.7a2.2 2.2 0 0 1 2.199 2.2v3.624a2.2 2.2 0 0 1-2.2 2.2h-.7v2.15a.75.75 0 0 1-.75.75h-8.7a.75.75 0 0 1-.75-.75v-2.15h-.7A2.2 2.2 0 0 1 0 10.9V7.275a2.2 2.2 0 0 1 2.2-2.2h.7V.75Zm1.5 11.602v-2.153h7.2v4.3H4.4v-2.147Zm-1.5-.753h-.7a.7.7 0 0 1-.7-.7V7.275a.7.7 0 0 1 .7-.7h11.599a.7.7 0 0 1 .7.7v3.624a.7.7 0 0 1-.7.7h-.7V9.45a.75.75 0 0 0-.75-.75h-8.7a.75.75 0 0 0-.75.75v2.15ZM11.6 1.5v3.575H4.4V1.5h7.2Z"
            clipRule="evenodd"
        />
    </svg>
);
export default SvgIcoPrinter;
