import Tooltip from '@mui/material/Tooltip/Tooltip';
import Checkbox from 'components/core/form/checkbox';
import { Controller, useFormContext } from 'react-hook-form';
import { SalesNotePayload } from 'types/models/sales-notes';

export type WithheldCheckboxProps = {
    name: keyof SalesNotePayload;
};

const WithheldCheckbox = ({ name }: WithheldCheckboxProps) => {
    const { control } = useFormContext<SalesNotePayload>();

    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => {
                return (
                    <Tooltip placement="top" title="Retido" disableFocusListener={true}>
                        <Checkbox name={field?.name} ref={field?.ref} checked={!!field?.value} onChange={() => field.onChange(!field.value)} />
                    </Tooltip>
                );
            }}
        />
    );
};

export default WithheldCheckbox;
