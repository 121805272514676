import SalesOrderReportTotals from './totals';
import { DataContentFiltersConfig, GenericReportFilterPayload, SalesOrderReportTotalsValues } from '../../types';
import { memo, useState } from 'react';
import ReportPageHeader from '../../../components/report-page-header';
import SalesOrderReportFilters from './filters';
import { SalesOrderReportFiltersPayload } from 'types/models/reports';

type SalesOrderReportHeaderProps = {
    onSetFilters: (data?: GenericReportFilterPayload<SalesOrderReportFiltersPayload>) => void;
    salesOrderTotals: SalesOrderReportTotalsValues;
    filters?: GenericReportFilterPayload<SalesOrderReportFiltersPayload>;
    dataContentFiltersConfig: DataContentFiltersConfig;
};

const SalesOrderReportHeader = ({ salesOrderTotals, filters, onSetFilters, dataContentFiltersConfig }: SalesOrderReportHeaderProps) => {
    const [isFilterVisible, setIsFilterVisible] = useState(false);

    return (
        <>
            <ReportPageHeader onOpenFilters={setIsFilterVisible.bind(this, true)} title="Pedidos de venda" />
            <SalesOrderReportTotals salesOrderTotals={salesOrderTotals} />
            {isFilterVisible && (
                <SalesOrderReportFilters dataContentFiltersConfig={dataContentFiltersConfig} filters={filters} onSetFilters={onSetFilters} onCloseFilters={setIsFilterVisible.bind(this, false)} />
            )}
        </>
    );
};

export default memo(SalesOrderReportHeader);
