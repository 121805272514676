import Text from 'components/core/text';
import { useOutletContext } from 'react-router-dom';
import { OrderService, OrderServiceStatusEnum } from 'types/models/order-service';
import OSData from './os-data';
import Badge from 'components/core/badge';
import Button from 'components/core/button';
import ModalFinishOS from './finish-os';
import { useMemo, useState } from 'react';
import { orderServicesBadgesMap } from '../../utils';
import { ColorVariant } from 'types/colors';
import classNames from 'classnames';
import SvgIcoCheck from 'components/core/icon/files/ico-check';

const ServiceOrderDetailsPage = () => {
    const orderService = useOutletContext<Partial<OrderService>>();

    const [isFinishOSModalVisible, setIsFinishOSModalVisible] = useState(false);

    const canFinishOS = useMemo(() => {
        return orderService.orderServiceStatus?.slug === 'waiting-for-completion';
    }, [orderService.orderServiceStatus?.slug]); // Check if os is finished and has no tasks unfinished

    const { variant = 'grey' } = orderServicesBadgesMap[orderService.orderServiceStatus?.slug!] || {};

    return (
        <>
            <div className="mb-6 flex justify-between">
                <Text as="h3" variant="h5" className="text-heading">
                    {orderService.title || ''} #{orderService.code || ''}
                </Text>
                <div className="flex items-center">
                    {Boolean(orderService.orderServiceStatus?.title) && (
                        <Badge
                            className={classNames(orderService.orderServiceStatus?.slug! === OrderServiceStatusEnum.NotStarted && 'border border-gray-300')}
                            variant={variant as ColorVariant}
                            bgOpacity={0.4}>
                            {orderService.orderServiceStatus?.title}
                        </Badge>
                    )}
                    {canFinishOS && (
                        <Button
                            startIcon={<SvgIcoCheck color="#FFF" width={12} height={12} />}
                            size="small"
                            variant="contained"
                            color="primary"
                            className="p-1 px-3 ml-2"
                            onClick={setIsFinishOSModalVisible.bind(this, true)}>
                            Encerrar a OS
                        </Button>
                    )}
                </div>
            </div>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
                <OSData />
            </div>
            {isFinishOSModalVisible && <ModalFinishOS onClose={setIsFinishOSModalVisible.bind(this, false)} />}
        </>
    );
};

export default ServiceOrderDetailsPage;
