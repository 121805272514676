import { RouteObject } from 'react-router-dom';
import ListPage from 'pages/shared/crud/list';
import CreateOrUpdateProfilePage from 'pages/private/profiles/create-or-update';
import { Rule } from 'types/permissions';
import Page from 'pages/shared/page';
import config from './utils';

const [list] = config;

const profile: RouteObject[] = [
    {
        path: 'perfis',
        element: <Page code="perfis" permissions={[Rule.List]} Component={ListPage} {...list} />,
        children: [
            {
                path: 'novo',
                element: <Page code="perfis" permissions={[Rule.Create]} Component={CreateOrUpdateProfilePage} withModal={true} />,
            },
            {
                path: 'editar/:profileId',
                element: <Page code="perfis" permissions={[Rule.Update]} Component={CreateOrUpdateProfilePage} withModal={true} />,
            },
        ],
    },
];

export default profile;
