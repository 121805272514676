import { useState } from 'react';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import classNames from 'classnames';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

type TaskLightboxProps = {
    items: Array<{
        url: string;
        urlThumb: string | null;
    }>;
    className?: string;
};

const TaskLightbox = ({ items, className }: TaskLightboxProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    const largeImages = items.map((item) => item.url);

    const imageClasses = classNames('w-[70px] h-[70px] bg-no-repeat bg-center bg-cover rounded-xl', className);

    return (
        <>
            {items.map((item) => {
                return (
                    <Tooltip key={item.url} placement="top" title="Ver imagem" disableFocusListener={true}>
                        <button onClick={setIsOpen.bind(this, true)} className={imageClasses} style={{ backgroundImage: `url(${item.urlThumb!})` }} />
                    </Tooltip>
                );
            })}
            {isOpen && (
                <Lightbox
                    mainSrc={largeImages[photoIndex]}
                    nextSrc={largeImages[(photoIndex + 1) % largeImages.length]}
                    prevSrc={largeImages[(photoIndex + items.length - 1) % largeImages.length]}
                    onCloseRequest={setIsOpen.bind(this, false)}
                    onMovePrevRequest={setPhotoIndex.bind(this, (photoIndex + largeImages.length - 1) % largeImages.length)}
                    onMoveNextRequest={setPhotoIndex.bind(this, (photoIndex + 1) % largeImages.length)}
                    clickOutsideToClose={true}
                    onImageLoad={window.dispatchEvent.bind(this, new Event('resize'))}
                />
            )}
        </>
    );
};

export default TaskLightbox;
