export const getOrderServiceKey = (orderServiceId?: string) => ['order-service', orderServiceId];
export const getOrderServiceStatusesKey = ['order-service', 'statuses', 'options'];
export const getOrderServicesPurchaseScopeKey = (endpoint: string, filters?: any) => ['order-services', 'service-orders', endpoint, JSON.stringify(filters || {})];
export const getOrderServicesReceiptsKey = (endpoint: string, filters?: any) => ['order-services', 'service-orders', 'receipts', endpoint, JSON.stringify(filters || {})];
export const getOrderServicesPurchaseScopeTotal = (endpoint: string) => ['order-services', 'service-orders', 'total', endpoint];
export const getOrderServicesPurchaseScopeProductsKey = (endpoint: string) => ['order-services', 'service-orders', 'products', endpoint];
export const getOrderServicesProductsKey = ({ orderServiceId, saleOrderId }: any) => ['order-services', 'products', orderServiceId, saleOrderId];
export const getOrderServicesProductsCategoriesKey = ({ orderServiceId, saleOrderId }: any) => ['order-services', 'products-categories', orderServiceId, saleOrderId];
export const getOrderServicesExpensesKeyTotal = (endpoint: string) => ['order-services', 'expenses', 'total', endpoint];
export const getOrderServicesExpenseProductsKey = ({ orderServiceId, saleOrderId }: any) => ['order-services', 'expense-products`', orderServiceId, saleOrderId];
export const getOrderServicesBalanceTotalKey = (endpoint: string) => ['order-services', 'balance', 'total', endpoint];
export const getOrderServiceWorkReportsKey = (orderServiceId?: string, saleOrderId?: string) => ['order-services', orderServiceId, 'sale-order', saleOrderId, 'galleries'];
export const getOrderServicesKey = (filters?: string) => ['order-services', filters];
export const getOrderServiceStockProductsKey = (saleOrderId?: string, orderServiceId?: string) => ['order-services', 'stock-products', saleOrderId, orderServiceId];
export const getOrderServicePurchaseProductsKey = (orderServiceId?: string) => ['order-services', orderServiceId, 'purchase-product'];
export const getOrderServiceWorkReportKey = (orderServiceId?: string, saleOrderId?: string, workReportId?: number) => [
    ...getOrderServiceWorkReportsKey(orderServiceId, saleOrderId),
    'work-report',
    workReportId,
];
export const getOrderServiceFinancialBalanceKey = (orderServiceId?: string, saleOrderId?: string, financialBalanceId?: string) => [
    'order-service',
    orderServiceId,
    'sale-order',
    saleOrderId,
    'financial-balance',
    financialBalanceId,
];

export const getOrderServiceMovementsKey = (orderServiceId?: string, saleOrderId?: string) => ['order-service', orderServiceId, 'sale-order', saleOrderId, 'movements'];
