import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from '@mui/material/TableRow/TableRow';
import DatePicker from 'components/core/datepicker';
import CurrencyInput from 'components/core/form/currency';
import Text from 'components/core/text';
import Select from 'components/core/form/select';
import { Product } from 'types/models/product';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { ManageProductsPayload } from './index';
import ManageProductsAction from './actions';
import Textarea from 'components/core/form/textarea';
import { memo } from 'react';
import { PurchaseScopeActions } from 'types/models/order-service';
import classNames from 'classnames';
import { Dayjs } from 'dayjs';

type ManageProductsRowProps = {
    rowIndex: number;
    filteredProducts: Partial<Product>[];
    onRemove: () => void;
    item: Partial<ManageProductsPayload['purchaseProducts'][0]>;
};

const ManageProductsRow = ({ filteredProducts, rowIndex, item, onRemove }: ManageProductsRowProps) => {
    const { control, getValues, setValue, formState } = useFormContext<ManageProductsPayload>();

    const hasError = Boolean(formState.errors?.purchaseProducts?.[rowIndex]?.action?.message);

    const action = useWatch({ control, name: `purchaseProducts.${rowIndex}.action` });
    const purchaseProducId = useWatch({ control, name: `purchaseProducts.${rowIndex}.purchaseProductId` });
    const purchaseProduct = useWatch({ control, name: `purchaseProducts.${rowIndex}.product` });

    const isDisabled = Boolean(action) && (action === PurchaseScopeActions.Approve || action === PurchaseScopeActions.Delete);

    const handleCheckApprove = (field, fieldValue) => {
        const value = formState.defaultValues?.purchaseProducts?.[rowIndex]?.[field];
        const isInitial = value === fieldValue;

        if (isInitial) {
            return setValue(`purchaseProducts.${rowIndex}.action`, undefined);
        }

        if (value !== fieldValue) {
            return setValue(`purchaseProducts.${rowIndex}.action`, PurchaseScopeActions.Reprove);
        }
    };

    const approveValidator = (field: string, value: any) => {
        if ([PurchaseScopeActions.Delete, PurchaseScopeActions.New].includes(action!)) {
            return;
        }

        return handleCheckApprove(field, value);
    };

    const handleSelectProduct = (onChange: (...event: any[]) => void) => (option: any) => {
        const selecteds = getValues('purchaseProducts');
        const isSelected = selecteds.some((item) => item.product?.value === option.value);

        if (isSelected) {
            onChange(undefined);
            return alert('Este produto já foi selecionado');
        }

        onChange(option);

        approveValidator('product', option.value);
    };

    const handleChange = (field: string, onChange: (...event: any[]) => void) => {
        return ({ floatValue }) => {
            onChange(floatValue || 0);

            approveValidator(field, floatValue);
        };
    };

    const handleChangeJustification = (onChange: (...event: any[]) => void) => (e) => {
        const value = e.target.value;

        onChange(value);

        approveValidator('justification', value);
    };

    const handleChangeProvision = (onChange: (...event: any[]) => void) => (date: Dayjs | null) => {
        const iso = date?.toISOString();

        onChange(date);
        approveValidator('provision', iso);
    };

    return (
        <TableRow sx={{ '&:last-child > td': { border: 'none' } }} className={classNames(hasError && 'bg-system-danger-100 bg-opacity-30')}>
            <TableCell className="p-2 pl-4">
                {Boolean(purchaseProducId) ? (
                    <Text className="text-heading whitespace-nowrap">{purchaseProduct?.label || '-'}</Text>
                ) : (
                    <Controller
                        control={control}
                        name={`purchaseProducts.${rowIndex}.product`}
                        render={({ field }) => {
                            const value = filteredProducts.find((filtered) => filtered.value === purchaseProduct?.value);

                            return (
                                <Select
                                    {...field}
                                    isDisabled={isDisabled}
                                    value={value || null}
                                    options={filteredProducts}
                                    placeholder="Selecione um produto"
                                    parentClassName="w-[400px]"
                                    onChange={handleSelectProduct(field.onChange)}
                                    error={formState.errors.purchaseProducts?.[rowIndex]?.product?.value?.message}
                                    showError={false}
                                />
                            );
                        }}
                    />
                )}
            </TableCell>
            <TableCell className="p-2">
                <Controller
                    control={control}
                    name={`purchaseProducts.${rowIndex}.provision`}
                    render={({ field }) => (
                        <DatePicker
                            containerClassNames="w-[170px]"
                            inputProps={{
                                disabled: isDisabled,
                                placeholderText: 'Selecione uma data',
                                error: formState.errors.purchaseProducts?.[rowIndex]?.provision?.message,
                                showErrorMessage: false,
                                ...field,
                                onChange: handleChangeProvision(field.onChange) as any,
                            }}
                        />
                    )}
                />
            </TableCell>
            <TableCell className="p-2">
                <Controller
                    control={control}
                    name={`purchaseProducts.${rowIndex}.quantity`}
                    render={({ field }) => (
                        <CurrencyInput
                            value={field.value}
                            disabled={isDisabled}
                            placeholder="Ex: 10"
                            parentClassName="!w-[120px]"
                            onValueChange={handleChange('quantity', field.onChange)}
                            error={formState.errors.purchaseProducts?.[rowIndex]?.quantity?.message}
                            showErrorMessage={false}
                        />
                    )}
                />
            </TableCell>
            <TableCell className="p-2">
                <Controller
                    control={control}
                    name={`purchaseProducts.${rowIndex}.value`}
                    render={({ field }) => (
                        <CurrencyInput
                            value={field.value}
                            disabled={isDisabled}
                            placeholder="Ex: 10"
                            left={
                                <Text as="span" variant="body.medium.sm" className="text-heading">
                                    R$
                                </Text>
                            }
                            withFormat={false}
                            decimalScale={4}
                            fixedDecimalScale={true}
                            thousandSeparator="."
                            decimalSeparator=","
                            leftClasses="bg-base-200 px-4"
                            parentClassName="!w-[170px]"
                            onValueChange={handleChange('value', field.onChange)}
                            error={formState.errors.purchaseProducts?.[rowIndex]?.value?.message}
                            showErrorMessage={false}
                        />
                    )}
                />
            </TableCell>
            <TableCell className="p-2">
                <Controller
                    control={control}
                    name={`purchaseProducts.${rowIndex}.justification`}
                    render={({ field }) => (
                        <Textarea
                            {...field}
                            disabled={isDisabled && action !== PurchaseScopeActions.Delete}
                            error={formState.errors?.purchaseProducts?.[rowIndex]?.justification?.message}
                            showErrorMessage={false}
                            placeholder="Adicione alguma justificativa"
                            parentClassName="!w-[300px]"
                            className="min-h-[50px]"
                            onChange={handleChangeJustification(field.onChange)}
                        />
                    )}
                />
            </TableCell>
            <TableCell className="p-2 pr-4">
                <ManageProductsAction item={item} action={action} index={rowIndex} onRemove={onRemove} />
            </TableCell>
        </TableRow>
    );
};

export default memo(ManageProductsRow);
