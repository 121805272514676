import { BaseModel } from './base';
import { PersonBankAccount } from './person';
import { ProviderBankAccount } from './provider';

export type Bank = {
    id: number;
    name: string;
    number: number;
    bankAccounts: Partial<BankAccount>[];
};

export enum BankAccountType {
    Open = 1,
    Savings,
}

export type BankAccount = {
    bankId: number;
    name: string;
    type: BankAccountType;
    agency: string;
    account: string;
    agencyDigit: string;
    accountDigit: string;
    pix: string;
    pixType: string;
    className: BankAccountClassNames;
    bank: Partial<Bank>;
    personBankAccount: Partial<PersonBankAccount>;
    providerBankAccount: Partial<ProviderBankAccount>;
} & BaseModel;

export enum BankAccountClassNames {
    Provider = 'provider',
    Person = 'person',
}

export enum PixType {
    Phone = '01',
    Email = '02',
    CpfCnpj = '03',
    Random = '04',
}
