import { useQuery } from 'react-query';
import api from 'services/api';
import { getOrderServicesBalanceTotalKey } from './keys';
import { SalesNotesTaxesResponse } from '../sales-notes/use-get-sales-notes-taxes';

export type GetOrderServicesBalanceTotalReponse = {
    totalBudget: number;
    totalBudgetPercentage: number;
    total: number;
    totalPercentage: number;
    balance: {
        netValue: {
            current: number;
            currentPercentage: number;
            projected: number;
            projectedPercentage: number;
        };
        operational: {
            current: number;
            currentPercentage: number;
            projected: number;
            projectedPercentage: number;
        };
        commission: number;
        commissionPercentage: number;
        tax: number;
        taxPercentage: number;
    };
    expenses: {
        administrative: {
            total: number;
            paid: number;
        };
        categories: Array<{
            name: string;
            value: string;
        }>;
        toPayRealized: number;
        toPayNotRealized: number;
        toPay: number;
        refund: number;
        totalOperational: number;
        total: number;
    };
    receipts: {
        total: number;
        paid: number;
        open: number;
        difference: number;
    };
    results: {
        current: number;
        currentRealized: number;
        potential: number;
        potentialPercentage: number;
        currentRealizedPercentage: number;
    };
    taxes: SalesNotesTaxesResponse;
};

const useGetOrderServicesBalanceTotal = (endpoint: string) => {
    const request = async (): Promise<GetOrderServicesBalanceTotalReponse> => {
        const { data } = await api.get<GetOrderServicesBalanceTotalReponse>(endpoint);

        return data;
    };

    return useQuery(getOrderServicesBalanceTotalKey(endpoint), request, {
        enabled: !!endpoint,
    });
};

export default useGetOrderServicesBalanceTotal;
