import classNames from 'classnames';
import Text from 'components/core/text';
import React from 'react';

type ProviderConsultingIconContentProps = {
    icon: {
        name: (props) => JSX.Element;
        color?: string;
    };
    title: string;
    content?: string | React.ReactNode;
    className?: string;
};

const ProviderConsultingIconContent = ({ title, content = '-', icon, className }: ProviderConsultingIconContentProps) => {
    return (
        <div className={classNames('flex items-center gap-3', className)}>
            <icon.name className={classNames('text-base-700', icon.color)} width={24} height={24} />
            <div className="flex flex-col gap-1">
                <Text as="span" className="block text-base-700 font-semibold">
                    {title}
                </Text>
                <Text as="span" className="text-heading font-medium text-xs">
                    {content}
                </Text>
            </div>
        </div>
    );
};

export default ProviderConsultingIconContent;
