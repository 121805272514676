import { jsonToGraphQLQuery } from 'json-to-graphql-query';
import { CrudDetails } from 'types/graphql';
import { Provider } from 'types/models/provider';
import useGetDetails from '../crud/use-get-details';
import provider from './keys';

type Response = {
    provider: Partial<Provider>;
};

const queryFields = jsonToGraphQLQuery({
    id: true,
    type: true,
    document: true,
    juridicDocument: true,
    juridicFantasyName: true,
    juridicName: true,
    title: true,
    phones: true,
    emails: true,
    providerContacts: {
        person: {
            name: true,
            title: true,
            gender: true,
            email: true,
            phones: true,
        },
    },
    addressProviders: {
        address: {
            id: true,
            compliment: true,
            neighbourhood: true,
            number: true,
            street: true,
            zip: true,
            cityId: true,
            stateId: true,
            city: { id: true, name: true },
            state: { id: true, initials: true },
        },
    },
    file: {
        id: true,
        filename: true,
        path: true,
        originalName: true,
    },
    providerBankAccounts: {
        bankAccount: {
            account: true,
            accountDigit: true,
            agency: true,
            agencyDigit: true,
            bank: { name: true },
        },
    },
});

const useGetProvider = (providerId?: string) => {
    const config: CrudDetails<Response> = {
        table: 'customer',
        query: provider.getProvider(queryFields),
        formatResponse: ({ provider }) => provider,
    };

    return useGetDetails<Partial<Provider>>(config, providerId);
};

export default useGetProvider;
