import { useQuery } from 'react-query';
import apiGraphQl from 'services/graphql';
import { MenuGroup } from 'types/models/menu';
import { getMenuGroupKey } from './keys';

const useGetMenuGroup = (query: string, menuGroupId?: string) => {
    const request = async () => {
        return apiGraphQl<{ menuGroupByPk: Partial<MenuGroup> }>(query).then(({ menuGroupByPk }) => ({
            ...menuGroupByPk,
            menuPages: menuGroupByPk.menuPages?.map((item) => ({
                value: item.page.id,
                label: item.page.name,
                orderMenu: item.orderMenu,
            })),
        }));
    };

    return useQuery(getMenuGroupKey(menuGroupId), request, { enabled: !!menuGroupId });
};

export default useGetMenuGroup;
