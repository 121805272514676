import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import { getPaymentFlowKey } from './keys';

type Payload = {
    transactions: number[];
};

type Response = Array<{
    id: number;
    message: string;
    success: boolean;
}>;

const useCheckTransactionEligility = (paymentFlowId?: string) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const request = async (payload: Payload) => api.patch<Response>(`/payment-flows/${paymentFlowId}/transactions/eligible`, payload);

    return useMutation(request, {
        onSuccess: ({ data }) => {
            for (const item of data) {
                showToast(item.message, item.success ? 'success' : 'danger');
            }

            queryClient.invalidateQueries(getPaymentFlowKey(paymentFlowId));
        },
        onError: () => {
            showToast('Ocorreu um erro ao verificar a eligibilidate', 'danger');
        },
    });
};

export default useCheckTransactionEligility;
