import Modal from 'components/core/modal';
import Text from 'components/core/text';
import TransactionsTable from './transactions-table';
import useGetPaymentFlowRemittances from 'services/queries/payment-flows/get-payment-flow-remittances';
import { useParams } from 'react-router-dom';
import Spinner from 'components/core/spinner';

type RemittanceDetailsProps = {
    remittanceId: number | null;
    onClose?: () => void;
};

const RemittanceDetails = ({ remittanceId, onClose }: RemittanceDetailsProps) => {
    const { paymentFlowId } = useParams();

    const { data: paymentFlowRemittances, isLoading: isLoadingRemittances } = useGetPaymentFlowRemittances(paymentFlowId, remittanceId);

    return (
        <Modal
            contentClassnames="w-[800px]"
            closeOnClickOutside={false}
            onClose={onClose}
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    Detalhes da remessa
                </Text>
            }>
            <div className="my-3 relative p-6">
                {isLoadingRemittances ? <Spinner fixed={false} parentClasses="p-8" /> : <TransactionsTable isRemittanceDetails={true} transactions={paymentFlowRemittances?.transactions || []} />}
            </div>
        </Modal>
    );
};

export default RemittanceDetails;
