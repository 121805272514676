import { CrudDetails } from 'types/graphql';
import { Customer } from 'types/models/customer';
import useGetDetails from '../crud/use-get-details';
import customer from './keys';

type Response = {
    customer: Partial<Customer>;
};

const useGetCustomer = (queryFields: string, customerId?: string) => {
    const config: CrudDetails<Response> = {
        table: 'customer',
        query: customer.getCustomer(queryFields),
        formatResponse: ({ customer }) => ({
            ...customer,
            juridicName: customer?.juridicName || '',
            juridicDocument: customer?.juridicDocument || '',
        }),
    };

    return useGetDetails<Partial<Customer>>(config, customerId);
};

export default useGetCustomer;
