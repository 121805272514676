import Badge from 'components/core/badge';
import { formatMoney } from 'utils/money';

type ContentWithheldProps = {
    data?: number;
    isWithheld?: boolean;
};
const ContentWithheld = ({ data, isWithheld = false }: ContentWithheldProps) => {
    return (
        <>
            {formatMoney(data)}
            {isWithheld && (
                <div>
                    <Badge variant="error">Retido</Badge>
                </div>
            )}
        </>
    );
};

export default ContentWithheld;
