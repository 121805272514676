import { useMutation } from 'react-query';
import apiGraphQl from 'services/graphql';
import { affectedRowsKey } from 'utils/environment';

const useDeleteCompanyPartners = () => {
    const request = async (companyId: string) => {
        return apiGraphQl(
            `mutation DeleteCompanyPartners($companyId: Int!) {
                item: deleteCompanyPartner(where: {companyId: {_eq: $companyId}}) {
                    ${affectedRowsKey}
                }
            }`,
            { companyId }
        );
    };

    return useMutation(request);
};

export default useDeleteCompanyPartners;
