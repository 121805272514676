import useToast from 'hooks/toast/use-toast';
import { useQuery } from 'react-query';
import api from 'services/api';
import { getReportPageByYearKey } from './keys';
import { ReportPageByYearResponse } from 'types/models/reports';
import QueryString from 'qs';

type Params = {
    id?: string;
    year?: number;
    enabled: boolean;
    filters?: any;
    onError?: () => void;
};

const useGetReportPageByYear = ({ id, onError, year, enabled, filters }: Params) => {
    const { showToast } = useToast();

    const request = async () => {
        const query = QueryString.stringify(filters, { addQueryPrefix: true, encode: false, arrayFormat: 'brackets', skipNulls: true });

        const { data } = await api.get<ReportPageByYearResponse>(`/reports/pages/${id}/${year}${query}`);

        return data;
    };

    return useQuery(getReportPageByYearKey(id, year), request, {
        onError: () => {
            onError?.();

            showToast(`Ocorreu um erro ao tentar obter os dados da página de relatório`, 'danger');
        },
        enabled,
    });
};

export default useGetReportPageByYear;
