import React, { useMemo } from 'react';
import { MUIDataTableFooter } from 'mui-datatables';
import Pagination from '@mui/material/Pagination/Pagination';
import Text from 'components/core/text';
import Spinner from 'components/core/spinner';
import Select from 'components/core/form/select';
import classNames from 'classnames';
import useConfig from 'store/config/use-config';

type DatatableFooterProps = {
    loading: boolean;
    withItemsPerPage: boolean;
    withPagination: boolean;
    footerLeft?: React.ReactNode;
    isServerSide: boolean;
} & MUIDataTableFooter;

const DatatableFooter = (props: DatatableFooterProps) => {
    const { config, setConfig } = useConfig();

    const total = props.rowCount || 0;
    const itemsPerPage = config.tables.rowsPerPage;
    const page = props.isServerSide ? props.page : (props.page || 0) + 1;

    const containerClasses = classNames(
        'flex-col sm:flex-row flex items-center p-6 border-t border-t-[#E8E9EB]',
        props.footerLeft ? 'justify-between' : !props.withItemsPerPage ? 'justify-end' : 'justify-between'
    );

    const options = useMemo(() => {
        return (
            props.options?.rowsPerPageOptions?.map((item) => ({
                value: item,
                label: item.toString(),
            })) || []
        );
    }, [props.options]);

    const value = options.find((item) => item.value === itemsPerPage);

    const pages = useMemo(() => Math.ceil(total / itemsPerPage) ?? 1, [total, itemsPerPage]);

    const handleChangeItemsPerPage = (option) => {
        setConfig('tables', { rowsPerPage: option.value });
        props.changeRowsPerPage?.(option.value);
    };

    const handleChangePage = (_: any, page: number) => props.changePage?.(props.isServerSide ? page : page - 1);

    return (
        <footer className={containerClasses}>
            {props.footerLeft ||
                (props.withItemsPerPage ? (
                    <div className="flex items-center mb-4 sm:mb-0">
                        <Select value={value} options={options} size="small" menuPlacement="top" onChange={handleChangeItemsPerPage} />
                        <Text as="span" variant="body.regular.2xs" className="text-base-500 ml-3">
                            Exibindo {(props.rowCount || 0) < itemsPerPage ? props.rowCount : itemsPerPage} de {props.rowCount || '0'}
                        </Text>
                    </div>
                ) : null)}
            {props.withPagination && (
                <Pagination
                    count={pages}
                    color="secondary"
                    defaultPage={1}
                    page={page}
                    shape="rounded"
                    showFirstButton={true}
                    showLastButton={true}
                    onChange={handleChangePage}
                    disabled={itemsPerPage >= total}
                />
            )}
            {props.loading && <Spinner size={24} fixed={false} parentClasses="!justify-start" />}
        </footer>
    );
};

export default DatatableFooter;
