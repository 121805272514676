import { BoardManager } from 'types/models/sale-order';
import TaskCard from './card';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import { taskTopColors } from '../utils';
import classNames from 'classnames';

type TasksColumnProps = {
    isLoading: boolean;
} & BoardManager;

const TasksColumn = ({ id, isLoading, progressStatus, items = [] }: TasksColumnProps) => {
    const showEmptyMessage = items.length === 0 && !isLoading;

    const { background: taskLineBackground } = taskTopColors.get(progressStatus.slug) || {};

    return (
        <Droppable droppableId={progressStatus.id.toString()}>
            {(provided, snapshot) => {
                return (
                    <div className="w-[350px] max-w-[80%] sm:max-w-none flex-none first:ml-6 last:mr-6" {...provided.droppableProps} ref={provided.innerRef}>
                        <div className="bg-base-100 rounded-[20px] ml-2 sm:ml-0 p-4 flex flex-col relative max-h-full shadow border-t lg:border-t-0">
                            <span className={classNames('absolute left-1/2 -ml-[32px] top-0 w-[64px] h-[5px] rounded-b-md bg-base-300', taskLineBackground)} />

                            <div className="flex items-center mb-4">
                                <div className="flex items-center flex-1">
                                    <p className="mr-2 text-base-700 font-medium text-lg uppercase">{progressStatus.title}</p>
                                    <span className="bg-base-300 rounded-md min-w-[20px] h-[20px] text-xs flex items-center justify-center px-1 text-base-500 font-semibold">{items.length}</span>
                                </div>
                            </div>
                            <div className="flex flex-col overflow-y-auto h-auto column-custom-scroll pr-2" ref={provided.innerRef}>
                                {showEmptyMessage ? (
                                    snapshot?.isDraggingOver ? null : (
                                        <p className="text-center text-base-400 text-sm flex items-center justify-center py-11">Nenhuma tarefa foi adicionada ainda</p>
                                    )
                                ) : (
                                    items.map((item: BoardManager['items'][0], index) => {
                                        const isLastChild = items.length - 1 === index;
                                        return (
                                            <Draggable key={`status_${id}_${item.id}`} draggableId={item.task.id?.toString()} index={index}>
                                                {(providedDrag, snapshotDrag) => (
                                                    <div ref={providedDrag.innerRef} {...providedDrag.draggableProps} {...providedDrag.dragHandleProps} className={!isLastChild ? 'mb-4' : ''}>
                                                        <TaskCard key={`status_${id}_${item.id}`} isDragging={snapshotDrag.isDragging} task={item.task} />
                                                    </div>
                                                )}
                                            </Draggable>
                                        );
                                    })
                                )}
                                {provided.placeholder}
                            </div>
                        </div>
                    </div>
                );
            }}
        </Droppable>
    );
};

export default TasksColumn;
