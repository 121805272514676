import classNames from 'classnames';
import { Accept, useDropzone } from 'react-dropzone';
import Uploader from 'components/uploader';
import ErrorMessage from 'components/error/message';
import Text from 'components/core/text';
import useTheme from '@mui/material/styles/useTheme';
import SvgIcoCheck from 'components/core/icon/files/ico-check';

type DropzoneProps = {
    onUploadFiles: (files: File[]) => void;
    accept?: Accept;
    containerClasses?: string;
    svgClasses?: string;
    disabled?: boolean;
    error?: string;
    multiple?: boolean;
    isSuccess?: boolean;
    successMessage?: string;
    hasText?: boolean;
    isLoading?: boolean;
    file?: File;
    onClear?: () => void;
};

const Dropzone = ({
    accept,
    containerClasses,
    disabled = false,
    error,
    multiple = false,
    onUploadFiles,
    svgClasses,
    successMessage = '',
    isSuccess = false,
    hasText = true,
    isLoading = false,
    file,
    onClear,
}: DropzoneProps) => {
    const { palette } = useTheme();

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: onUploadFiles,
        accept,
        disabled,
        multiple,
    });

    const containerStyles = classNames(
        'bg-base-200 border-base-400 border border-dashed rounded-[14px] w-full h-[120px] flex flex-col items-center justify-center hover:bg-base-200 hover:bg-opacity-50 p-4 relative',
        isDragActive ? 'opacity-50 dropzone-scale' : '',
        disabled && 'cursor-no-drop opacity-50',
        containerClasses
    );

    const successStyles = classNames(containerStyles, 'bg-system-success-100 bg-opacity-20 border-system-success-500');

    if (isSuccess) {
        return (
            <div className={successStyles}>
                <SvgIcoCheck width={16} height={16} color={palette.success[500]} />
                {hasText && (
                    <Text as="p" variant="body.regular.sm" className="text-system-success-500 text-center whitespace-nowrap ml-2">
                        {successMessage}
                    </Text>
                )}
            </div>
        );
    }

    return (
        <>
            <div className={containerStyles} {...getRootProps()}>
                <Uploader disabled={disabled} file={file} isLoading={isLoading} hasText={hasText} svgClasses={svgClasses} inputProps={getInputProps()} onClear={onClear} />
            </div>
            <ErrorMessage visible={Boolean(error)}>{error}</ErrorMessage>
        </>
    );
};

export default Dropzone;
