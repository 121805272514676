import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { banckAccountSchema } from './utils';
import FormButtons from 'components/form-buttons';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { CreateOrUpdateBankAccountPayload } from 'types/models/bank-accounts';
import BankAccountForm from 'components/forms/bank-account';
import Spinner from 'components/core/spinner';
import useTheme from '@mui/material/styles/useTheme';
import { useShowApiErrors } from 'hooks/error/show-api-errors';

type CreateOrUpdateBankAccountProps = {
    isSubmitting: boolean;
    isBuilding: boolean;
    defaultValues: Partial<CreateOrUpdateBankAccountPayload>;
    error?: any;
    onSubmit: (data: CreateOrUpdateBankAccountPayload) => void;
};

const CreateOrUpdateBankAccount = ({ defaultValues, isBuilding, isSubmitting, error, onSubmit }: CreateOrUpdateBankAccountProps) => {
    const { palette } = useTheme();
    const { bankAccountId } = useParams();
    const { showFormErrors } = useShowApiErrors();

    const methods = useForm<CreateOrUpdateBankAccountPayload>({
        mode: 'onSubmit',
        defaultValues,
        resolver: yupResolver(banckAccountSchema) as any,
    });

    const { reset, setError } = methods;

    useEffect(() => {
        if (Boolean(defaultValues) && Boolean(bankAccountId)) {
            reset(defaultValues);
        }
    }, [defaultValues, bankAccountId, reset]);

    useEffect(() => {
        if (!!error) {
            showFormErrors({ error, setError });
        }
    }, [showFormErrors, error, setError]);

    return (
        <Modal
            contentClassnames="w-[800px]"
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    {Boolean(bankAccountId) ? 'Atualizar' : 'Nova'} conta bancária
                </Text>
            }
            closeOnClickOutside={false}>
            {isBuilding ? (
                <div className="pt-4 pb-8">
                    <Spinner color={palette.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <FormProvider {...methods}>
                    <form className="px-6 pb-6" onSubmit={methods.handleSubmit(onSubmit)}>
                        <BankAccountForm isCompanyForm={true} withAccountType={true} />
                        <FormButtons containerClassName="mt-5" isLoading={isSubmitting} />
                    </form>
                </FormProvider>
            )}
        </Modal>
    );
};

export default CreateOrUpdateBankAccount;
