import PageHeader from 'components/page/header';
import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import { useState } from 'react';
import Button from 'components/core/button';
import Datatable from 'components/core/table/datatable';
import useGetTransactionsOutcome from 'services/queries/transactions/use-get-transactions-outcome';
import { TransactionInvoiceStatus } from 'types/models/transaction';
import PaymentOrderFilter from '../payment-order/filter';
import { pendingMoneyOrdersListColumns } from './utils';
import { Outlet } from 'react-router-dom';
import useConfig from 'store/config/use-config';
import SvgIcoFilter from 'components/core/icon/files/ico-filter';

const endpoint = '/transactions/outcomes';

const INITIAL_FILTERS = {
    invoiceStatus: TransactionInvoiceStatus.ReleasedShippingSystem,
    page: 1,
    searchText: '',
};

const PendingMoneyOrdersPage = ({ page: pageProps }: WithPagePermissionsProps) => {
    const { config } = useConfig();

    const [isFilterVisible, setIsFilterVisible] = useState(false);

    const [filters, setFilters] = useState<any>(INITIAL_FILTERS);

    const {
        data,
        isLoading: isLoadingList,
        refetch,
    } = useGetTransactionsOutcome({
        endpoint,
        filters: {
            ...filters,
            totalPerPage: config.tables.rowsPerPage,
        },
    });

    const listColumns = pendingMoneyOrdersListColumns(refetch);

    const handleSubmitFilter = (data: any) => {
        setFilters((prev) => {
            if (!data) {
                return INITIAL_FILTERS;
            }

            return {
                ...prev,
                ...data,
                page: 1,
            };
        });

        handleToggleFiltersModal(false)();
    };

    const handleToggleFiltersModal = (value: boolean) => () => setIsFilterVisible(value);

    return (
        <>
            {isFilterVisible && (
                <PaymentOrderFilter
                    visibleFields={['sectors', 'categories', 'favored', 'provisionStartDate', 'provisionEndDate']}
                    filters={filters}
                    onSubmit={handleSubmitFilter}
                    onClose={handleToggleFiltersModal(false)}
                />
            )}
            <PageHeader
                title={pageProps?.title}
                className="mb-6"
                right={
                    <Button
                        color="inherit"
                        variant="outlined"
                        type="button"
                        size="medium"
                        className="bg-white"
                        onClick={() => setIsFilterVisible(true)}
                        endIcon={<SvgIcoFilter width={17} height={17} color="currentColor" />}>
                        Filtrar
                    </Button>
                }
            />
            <Datatable
                actions={pageProps?.rules?.map((item) => item.key) || []}
                withBorder={false}
                data={data?.items || []}
                options={{
                    sort: true,
                    filter: false,
                    search: true,
                    searchAlwaysOpen: true,
                    print: false,
                    download: false,
                    viewColumns: true,
                    serverSide: true,
                    selectableRows: 'none',
                    enableNestedDataAccess: '.',
                    pagination: true,
                    elevation: 21,
                    count: data?.total,
                    page: filters.page,
                    onChangePage: (currentPage) => {
                        setFilters((prev) => ({ ...prev, page: currentPage }));
                    },
                    onSearchChange: (searchText) => {
                        setFilters((prev) => ({ ...prev, searchText }));
                    },
                    onColumnSortChange: (changedColumn, direction) => {
                        setFilters((prev) => ({ ...prev, orderBy: { [changedColumn]: direction.toUpperCase() } }));
                    },
                }}
                columns={listColumns}
                loading={isLoadingList}
            />
            <Outlet context={{ filters }} />
        </>
    );
};

export default PendingMoneyOrdersPage;
