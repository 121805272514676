import { useCallback, useLayoutEffect, useState } from 'react';
import { RouteObject, useLocation, useNavigate } from 'react-router-dom';
import _get from 'lodash/get';

export type UseTabRouteChildren = {
    label: string;
} & RouteObject;

/**
 *
 * A simple hook to help you to use tabs with routes.
 * This hook will identify and manage the active tab based on your routes.
 *
 * @param childrens UseTabRouteChildren[]
 *
 */
export const useTabRoute = (childrens: UseTabRouteChildren[]) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const [tab, setTab] = useState(0);

    const getActiveTab = useCallback(() => {
        if (!pathname) {
            return;
        }

        // const currentOutlet = childrens.findIndex(({ path = 'index' }) => !!pathname?.includes(path));
        const currentOutlet = childrens.findIndex(({ path = 'index' }) => {
            if (!path) {
                return;
            }

            return !!pathname?.includes('/' + path) && !pathname?.includes('/' + path + '-');
        });

        setTab(currentOutlet < 0 ? 0 : currentOutlet);
    }, [pathname, childrens]);

    useLayoutEffect(() => {
        getActiveTab();
    }, [getActiveTab]);

    const handleChangeTab = (_, value: number) => {
        setTab(value);

        // Sends the user to the correct route and lets 'getActiveTab' decides the correct tab
        navigate(_get(childrens, `[${value}].path`, ''));
    };

    return {
        activeTabIndex: tab,
        handleChangeTab,
        tabLabels: childrens?.map(({ label }) => label) || [],
    };
};
