import useDebounce from 'hooks/keyboard/use-debounce';
import { useState } from 'react';
import useGetProducts from '../products/use-get-products';
import { getStockProductsKey } from './keys';
import { getDebouncedStockProductsQuery } from './utils';

const useGetDebouncedStockProducts = <T, K>(stockId?: string, transformer?: (data: Partial<T>[]) => K) => {
    const [searchTerm, setSearchTerm] = useState('');

    const debouncedSelectedProduct = useDebounce(searchTerm, 500);

    const { data: products = [], isLoading: isLoadingProducts } = useGetProducts<T>(
        getDebouncedStockProductsQuery(stockId, searchTerm),
        !!debouncedSelectedProduct,
        transformer,
        getStockProductsKey.bind(this, stockId)
    );

    return {
        products: products as K,
        isLoadingProducts,
        searchTerm,
        setSearchTerm,
    };
};

export default useGetDebouncedStockProducts;
