import { useMemo, useState } from 'react';
import DatatableToolbarActions from 'pages/private/sales-orders/service-order/purchase-order/actions';
import { PurchaseProduct } from 'types/models/purchase-order';

type ServiceOrderPurchaseScopePageActionsProps = {
    onClick: (route: string) => (selectedItems: any[]) => void;
    selectedRows: any;
    displayData: any;
    setSelectedRows: (rows: number[]) => void;
    items: PurchaseProduct[];
};

const ServiceOrderPurchaseScopePageActions = ({ onClick, items = [], selectedRows }: ServiceOrderPurchaseScopePageActionsProps) => {
    const toolbarAnchor = useState<HTMLElement | null>(null);

    const selectedItems = useMemo<any[]>(() => {
        const formatData = ({ product, balance, id }: PurchaseProduct) => {
            const { name, code, unit, ...restProduct } = product;

            return {
                ...restProduct,
                preApproved: true,
                balance,
                product: {
                    name,
                    code,
                    classificationByClassificationUnitId: unit,
                },
                orderServiceProductPurchase: id,
            };
        };

        return items?.filter((_, itemIndex) => selectedRows?.data?.some(({ index }) => index === itemIndex))?.map(formatData);
    }, [items, selectedRows]);

    const purchaseOrderActions = useMemo(() => {
        return {
            content: [
                {
                    label: 'Nova ordem de compra',
                    onClick: () => onClick('nova-ordem-de-compra')(selectedItems),
                },
            ],
        };
    }, [onClick, selectedItems]);

    return <DatatableToolbarActions toolbarAnchor={toolbarAnchor} {...purchaseOrderActions} />;
};

export default ServiceOrderPurchaseScopePageActions;
