import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useMemo } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import useGetImportFlow from 'services/queries/import-flow/use-get-import-flow';
import { MenuItem } from 'components/menu';
import FabNav from 'components/fab-nav';
import useBreadcrumb from 'store/breadcrumb/use-breadcrumb';
import Empty from 'components/empty';
import Spinner from 'components/core/spinner';
import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import { UseTabRouteChildren, useTabRoute } from 'hooks/tab/use-tab-route';

const ImportFlowDetailsPage = ({ page }: WithPagePermissionsProps) => {
    const navigate = useNavigate();
    const { importFlowId } = useParams();

    const { data: importFlow, isLoading: isLoadingImportFlow, isError } = useGetImportFlow(importFlowId);

    useBreadcrumb(importFlow?.code);

    const fabOptions: MenuItem[] = [
        {
            label: 'Nacionalizar',
            onClick: () => navigate('nacionalizar'),
        },
    ];

    const nav = useMemo<UseTabRouteChildren[]>(() => {
        const subpages = page?.subpages || [];
        const filteredSubpages = subpages.filter((item) => item.key !== 'stock-import-nationalizate');

        return filteredSubpages.map((item) => ({ label: item.title.toUpperCase(), path: item.link }));
    }, [page]);

    const { handleChangeTab, activeTabIndex, tabLabels } = useTabRoute(nav);

    if (isLoadingImportFlow) {
        return <Spinner fixed={false} parentClasses="h-full w-full" />;
    }

    if (!importFlow?.id || isError) {
        return <Empty title="Importação não encontrada." />;
    }

    return (
        <>
            <div className="bg-base-100 -m-6 mb-6">
                <Tabs variant="scrollable" value={activeTabIndex} onChange={handleChangeTab}>
                    {tabLabels.map((label) => (
                        <Tab label={label} key={label} className="text-base py-5" />
                    ))}
                </Tabs>
            </div>
            <div>
                <Outlet context={{ importFlow }} />
            </div>
            {Boolean(fabOptions.length) && <FabNav options={fabOptions} />}
        </>
    );
};

export default ImportFlowDetailsPage;
