import { DocumentPayload } from 'components/documents';
import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import useUploadFile from '../files/use-upload-file';
import { getAllKey } from '../crud/keys';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';

const useCreateOrUpdateSaleOrderDocument = (saleOrderId?: string, saleOrderDocumentId?: string, variables?: any) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const { uploadFile } = useUploadFile();
    const goToParentRoute = useNavigateToParentRoute();

    const isUpdate = Boolean(saleOrderDocumentId);

    const request = async (payload: DocumentPayload) => {
        if (payload?.file?.id) {
            payload.file = payload.file.id;
        } else {
            await uploadFile(
                payload.file,
                ({ id }) => {
                    payload.file = id;
                },
                true
            );
        }

        if (!payload?.file) {
            throw new Error();
        }

        const method = isUpdate ? api.patch : api.post;
        const endpoint = isUpdate ? `/sale-orders/${saleOrderId}/documents/${saleOrderDocumentId}` : `/sale-orders/${saleOrderId}/documents`;

        return method(endpoint, payload);
    };

    return useMutation(request, {
        onSuccess: () => {
            if (!!variables) {
                queryClient.invalidateQueries(getAllKey('saleOrderDocument', variables));
            }

            goToParentRoute();
            showToast(`Documento ${isUpdate ? 'atualizado' : 'criado'} com sucesso`, 'success');
        },
        onError: () => {
            showToast(`Ocorreu um erro ao ${isUpdate ? 'atualizar' : 'criar'} o documento`, 'danger');
        },
    });
};

export default useCreateOrUpdateSaleOrderDocument;
