import OrderServicesReportTotals from '../../order-services/header/totals';
import { DataContentFiltersConfig, OrderServicesReportTotalsValues } from '../../types';
import ContractsReportFilters from './filters';
import ReportPageHeader from '../../../components/report-page-header';
import { ReportConfigFilters } from 'types/models/reports';

type ContractsReportHeaderProps = {
    reportTotals: OrderServicesReportTotalsValues;
    configFilters: ReportConfigFilters;
    dataContentFiltersConfig: DataContentFiltersConfig;
};

const ContractsReportHeader = ({ reportTotals, configFilters, dataContentFiltersConfig }: ContractsReportHeaderProps) => {
    return (
        <>
            <ReportPageHeader title="Contratos" onOpenFilters={configFilters.onToggleFilters.bind(this, true)} />
            <OrderServicesReportTotals orderServicesTotals={reportTotals} isContractReport={true} />
            {configFilters.isFilterVisible && (
                <ContractsReportFilters
                    dataContentFiltersConfig={dataContentFiltersConfig}
                    filters={configFilters.filters}
                    onClear={configFilters.onClearFilters}
                    onSubmit={configFilters.onSubmitFilters}
                    onCloseFilters={configFilters.onToggleFilters.bind(this, false)}
                />
            )}
        </>
    );
};

export default ContractsReportHeader;
