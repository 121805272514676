import ListPage from 'pages/shared/crud/list';
import { RouteObject } from 'react-router-dom';
import { Rule } from 'types/permissions';
import Page from 'pages/shared/page';
import config from './utils';

const [list] = config;

const users: RouteObject[] = [
    {
        // @ts-ignore
        breadcrumb: 'Usuários',
        path: 'usuarios',
        element: <Page Component={ListPage} code="usuarios" permissions={[Rule.List]} {...list} />,
    },
];

export default users;
