import { Product } from './product';
import { PurchaseOrder } from './purchase-order';
import { Customer } from './customer';
import { SaleOrder, SaleOrderFinancialBalanceResponse, SaleOrderOrigin, SaleOrderType } from './sale-order';
import { OrderServiceProductPurchase } from './order-service';
import { ExpenseDetailsResponse } from 'services/queries/expenses/types';
import { BaseModel } from './base';
import { ReportFilterPayload } from 'pages/private/reports/screens/types';
import { DatatableColumn } from 'types/graphql';
import React from 'react';
import { TransactionType } from './transaction';
import { FormFavoredType } from './favored';
import { Modify, Option } from 'types/general';
import { Months } from 'utils/date';
import { SalesNotesTaxesResponse } from 'services/queries/sales-notes/use-get-sales-notes-taxes';
import dictionary from 'utils/dictionary';

export type OriginExpensesResponse = {
    days: {
        day: string | Date;
        origins: {
            code: string;
            name: string;
            value: number;
            expenses: {
                id: number;
                code: string;
            }[];
        }[];
        total: number;
    }[];
    origins: {
        code: string;
        name: string;
        total: number;
    }[];
};

type ValuePercentage = {
    value: number;
    percentage: number;
};

export type SalesReportResponse = {
    sales: {
        id: number;
        saleOrder: {
            id: number;
            code: string;
            type: SaleOrderType;
        };
        code: string;
        title: string;
        startDate: string | Date;
        endDate: string | Date;
        report: {
            contract: number;
            budget: ValuePercentage;
            paid: ValuePercentage;
            operatingBalance: ValuePercentage;
            operatingMargin: ValuePercentage;
            commissions: ValuePercentage;
            taxes: ValuePercentage;
            netProfit: ValuePercentage;
        };
        month: number;
        name: string;
        year: number;
    }[];
    totals: {
        received: ValuePercentage;
        budget: ValuePercentage;
        operating: ValuePercentage;
        open: ValuePercentage;
        balance: ValuePercentage;
        operatingBalance: ValuePercentage;
        results: ValuePercentage;
    };
};

export type NewBusinessReportResponse = {
    sales: Array<{
        title: string;
        branch: {
            name: string;
            id: number;
        };
        consultant: {
            name: string;
            title: string;
        };
        customer: Pick<Customer, 'title' | 'juridicName' | 'juridicFantasyName'>;
        company: {
            id: number;
            name: string;
            taxRegime: number;
        };
        date: string;
        origin: number;
        type: number;
        classification: {
            id: number;
            name: string;
        };
        saleOrderStatus: {
            id: number;
            title: string;
            slug: string;
        };
        definition: string;
        report: {
            total: string;
            financialBalance: number;
            deductionBalance: number;
            classifications: Array<{
                id: number;
                name: string;
                report: {
                    value: number;
                };
            }>;
        };
    }>;
    totals: {
        prospects: number;
        total: number;
        financialBalance: number;
        deductionBalance: number;
        classifications: Array<{
            id: number;
            name: string;
            report: {
                value: number;
            };
        }>;
    };
};

export type FinancialPlanningReportResponse = {
    days: Array<{
        day: string | Date;
        result: number;
        projected: number;
        transactions: Array<{
            id: number;
            code: string;
            type: number;
            description: string;
            paid: boolean;
            grossValue: number;
            netValue: number;
            discount: number;
            addition: number;
            favoredCustomer: string;
        }>;
    }>;
    currentBalance?: number;
};

export type TableItemValuesValue = {
    value?: number | string;
    type?: 'number' | 'currency' | 'percentage' | 'blank' | 'text' | 'custom';
    className?: string;
    customComponent?(value: any): React.ReactNode;
    onClick?: () => void;
};

export type LegacyDataResume = {
    id: number;
    title: string;
    code?: string;
};

export type LegacyData = {
    expense: LegacyDataResume;
    sector: LegacyDataResume;
    category: LegacyDataResume;
    origin: LegacyDataResume;
    subOrigin: LegacyDataResume;
    netValue: string;
    code: string;
    provision: string;
    dueDate: string;
    payday: string;
};

export type TableItem = {
    values: {
        values: TableItemValuesValue[];
        title: string | React.ReactNode;
        modalTitle?: string;
        monthTotal?: number;
        monthNumber?: number;
        className?: string;
        hasToggleButton?: boolean;
        legacyData?: LegacyData[];
        customBodyComponent?(values?: TableItemValuesValue[]): React.ReactNode;
        onClick?: () => void;
    }[];
    hasToggleButton?: boolean;
    title?: string;
    headers?: TableHeader[];
    isClosed?: boolean;
};

export type TableHeader = {
    title: string;
    colSpan?: number;
    rowSpan?: number;
    className?: string;
};

export type DRETableProps = {
    fontSize: number;
    items: TableItem[];
    headers: TableHeader[][];
    minHeaderWidth?: number;
    className?: string;
    title?: string;
    hasFirstEmptyHeaderColumn?: boolean;
    isLoading?: boolean;
    emptyHeaderColumnWidth?: string;
};

export enum SalesStatus {
    Waiting = 1,
    InProgress,
    Completed,
}

export type ProductsExpenseReportResponse = {
    id: number;
    favored: {
        title: string;
    };
    code: string;
    value: number | string;
    title: string;
    payday: string | Date | null;
    dueDate: string | Date | null;
    products: Array<{
        id: number;
        product: Pick<Product, 'code' | 'name' | 'id'>;
        purchaseOrderProduct: {
            id: number;
            purchaseOrder: Pick<PurchaseOrder, 'id' | 'code'>;
            orderServiceProductPurchase: OrderServiceProductPurchase;
            justification: string;
        };
        quantity: number;
        netValue: number;
    }>;
};

type ReportMonth = {
    id: number;
    month: number;
    legacyData: LegacyData[];
    resultData: {
        percentage: number;
        average: number;
        averagePercentage: number;
        percentageAverage?: number;
        percentageValue?: number;
    };
    value: number;
    year: number;
} & Pick<BaseModel, 'id'>;

export type ReportPageByYearResponse = {
    blocks: Array<ReportPageBlock>;
    months: Array<{
        id: number;
        year: number;
        month: number;
        value: number;
        resultData: {
            average: number;
            m: number;
            ma: number;
        };
    }>;
    quarters: Array<number>;
    resume: {
        total: number;
    };
    branches: Array<{
        branch: string;
        value: number;
    }>;
};

export type BlockFilter = Partial<{
    sectors: number[];
    categories: number[];
    origins: number[];
    subOrigins: number[];
    companies: number[];
    natures: number[];
    transactionProvisioned: number;
    invoiceStatus: number;
    customer: number;
    saleOrderOrigin: number;
    saleOrderType: number[];
    saleOrderClassification: number[];
}>;

export type ReportPageBlock = {
    id: number;
    name: string;
    color?: string;
    reportMonths: ReportMonth[];
    reportQuarters: Array<number>;
    resume: {
        total: number;
        average: number;
        percentage: number;
    };
    reportBranches: Array<Pick<ReportMonth, 'value' | 'year'> & Pick<ExpenseDetailsResponse, 'branch'> & Pick<BaseModel, 'id'>>;
    branches?: ReportPageByYearResponse['branches'];
    filter?: BlockFilter;
};

export type ContractsReportKeys =
    | 'receipt'
    | 'budget'
    | 'budgetPercentage'
    | 'budgetCurrent'
    | 'budgetCurrentPercentage'
    | 'budgetDifference'
    | 'budgetDifferencePercentage'
    | 'balanceProjected'
    | 'balanceProjectedPercentage'
    | 'balanceCurrent'
    | 'balanceCurrentPercentage'
    | 'balanceDifference'
    | 'balanceDifferencePercentage';

export type ContractsReportResponse = {
    [K in ContractsReportKeys]: number;
} & Partial<SaleOrder>;

export type SalesOrdersReportResponse = Pick<SaleOrder, 'id' | 'branch' | 'classification' | 'code' | 'company' | 'consultant' | 'contractDate' | 'customer' | 'origin' | 'type'> &
    SaleOrderFinancialBalanceResponse;

export type ProductsReportResponse = Pick<Product, 'id' | 'code' | 'name' | 'value' | 'classification'> & {
    quantity: number;
    percentage: number;
    unit: {
        name: string;
        abbreviation: string;
    };
};

export type OrderServicesReportFiltersPayload = {
    saleOrders: number[];
    orderServiceStatus: number[];
    startConclusionDate: string;
    endConclusionDate: string;
};

export type ReportProductsFiltersPayload = {
    products: number[];
    saleType?: string;
    classifications: number[];
};

export type SalesOrderReportFiltersPayload = {
    classifications: number[];
};

export type ProductsReportNumbersResponse = {
    name: string;
    value: number;
    quantity?: number;
    percentage?: number;
};

export type ReportConfigFilters = {
    filters?: ReportFilterPayload;
    isFilterVisible: boolean;
    onSubmitFilters: (data?: ReportFilterPayload['filter']) => void;
    onClearFilters: () => void;
    onToggleFilters: (value: boolean) => void;
};

export type ResumesConfigs = {
    endpoint: string;
    columns: (showValues?: boolean) => DatatableColumn[];
};

export type ResumesContentConfig = {
    title: string;
    columns: (showValues?: boolean) => DatatableColumn[];
    data: any[];
    isLoading: boolean;
    searchPlaceholder: string;
};

export type ReportPageBlockFilter<T = false> = {
    sectors: number[];
    categories: number[];
    origins: number[];
    subOrigins: number[];
    favored: T extends false
        ? {
              [K in FormFavoredType]: {
                  id: string;
                  title: string;
              };
          }
        : Option & { type: FormFavoredType };
    companies: number[];
    natures: number[];
    transactionProvisioned: string | null;
    customer: Customer;
    saleOrderOrigin: SaleOrderOrigin;
    saleOrderType: number[];
    saleOrderClassification: number[];
};

export type ReportPageResponse = {
    id: number;
    name: string;
    type: TransactionType;
    generated: boolean;
    years: {
        year: number;
    }[];
    blocks: {
        id: number;
        name: string;
        order: number;
        color: string;
        filter: Partial<ReportPageBlockFilter>;
    }[];
};

export type ReportPageBlockFormState = {
    id?: string | number;
    name: string;
    color: string;
    filter?: Partial<ReportPageBlockFilter<true>>;
    order?: number;
};

export type ReportPageFormState = {
    name: string;
    type: TransactionType;
    blocks: ReportPageBlockFormState[];
    years: number[];
};

export type ReportPageFormPayload = Modify<
    ReportPageFormState,
    {
        years: ReportPageResponse['years'];
    }
>;

export type DREClassType = 'success' | 'danger';

export type DREBlockValuesContent = {
    id: number;
    branch: string;
    code: string;
    company: string;
    contractDate: string;
    link: string;
    netValue: number;
    origin: SaleOrderOrigin;
    type: 'saleOrder' | 'orderService';
    version: 'v1' | 'v2';
    cost?: number;
    taxes?: Partial<SalesNotesTaxesResponse & { [K in 'ipi' | 'others']: SalesNotesTaxesResponse['cofins'] }>;
};

type DREBlock = {
    result: ValuePercentage & {
        class?: DREClassType;
    };
    title: string;
    class?: DREClassType;
    key?: keyof typeof dictionary.content.taxes;
    values: Array<
        {
            type: 'month' | 'quarter';
            month?: Months | null;
            class?: DREClassType;
            content: DREBlockValuesContent[];
        } & ValuePercentage
    >;
};

export enum DREBlockType {
    Receipts = 1,
    Taxes,
    CostsTaxes,
    VariableExpensesTaxes,
    OperationalExpensesTaxes,
    DepreciationTaxes,
    OtherAssetsExpensesTaxes,
    FederalTaxes,
}

export type ReportDREResponse = {
    id: number;
    year: number;
    data: {
        blocks: Array<{
            title: string;
            items: DREBlock[];
            summary: DREBlock[];
            withMonthDetails: boolean;
            type: DREBlockType;
        }>;
    };
};

export type DRESelectedMonthValues = {
    title: string;
    total: number;
    data: DREBlockValuesContent[];
    type: DREBlockType;
    taxKey?: keyof typeof dictionary.content.taxes;
};
