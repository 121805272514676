import RemoveButton from 'components/buttons/remove';
import Input from 'components/core/form/input';
import Text from 'components/core/text';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { FormPayload } from 'services/queries/forms/use-create-or-update-form';
import get from 'lodash/get';
import { booleanOptions } from 'utils/statics';
import Select from 'components/core/form/select';
import classNames from 'classnames';
import { FormFieldType } from 'types/models/form';
import FormItemSubfield from './item-subfield';
import Button from 'components/core/button';
import theme from 'settings/theme';
import SvgIcoPlus from 'components/core/icon/files/ico-plus';

type FormItemOptionsProps = {
    fieldIndex: number;
    groupIndex: number;
    optionIndex: number;
    onRemoveOption: (optionIndex: number) => void;
};

const FORM_SUBFIELD_DEFAULT = {
    type: FormFieldType.Text,
    required: false,
    name: '',
    order: 1,
};

const FormItemOption = ({ fieldIndex, groupIndex, optionIndex, onRemoveOption }: FormItemOptionsProps) => {
    const { control, formState, setValue } = useFormContext<FormPayload>();

    const itemPath = `groups.${groupIndex}.fields.${fieldIndex}` as const;
    const subfieldsPath = `groups.${groupIndex}.fields.${fieldIndex}.options.${optionIndex}.subfields` as const;

    const getErrorOptionField = (field: string) => get(formState.errors, `${itemPath}.options.${optionIndex}.${field}.message`);

    const handleSelectAdditionalField = (option) => {
        if (!option?.value) {
            return removeSubfield();
        }

        setValue(subfieldsPath, [FORM_SUBFIELD_DEFAULT]);
    };

    const { fields: subfields, append: appendSubfield, remove: removeSubfield } = useFieldArray({ control, name: subfieldsPath });

    const additionalFieldValue = booleanOptions.find((item) => item.value === Boolean(subfields.length));

    return (
        <div className={classNames('box-with-border gap-4 mt-4 first:mt-0', Boolean(subfields.length) ? 'border border-system-warning-200' : '')}>
            <div className="flex flex-row items-end gap-4">
                <Controller
                    control={control}
                    name={`${itemPath}.options.${optionIndex}.name`}
                    render={({ field }) => {
                        return <Input {...field} label="Nome" error={getErrorOptionField('name')} parentClassName="flex-1" />;
                    }}
                />
                <Select
                    value={additionalFieldValue}
                    options={booleanOptions}
                    label="Campo adicional?"
                    error={getErrorOptionField('required')}
                    parentClassName="w-[130px]"
                    onChange={handleSelectAdditionalField}
                />
                {!!optionIndex && <RemoveButton className="!static !m-0 opacity-100 mb-[7px]" onClick={onRemoveOption.bind(this, optionIndex)} />}
            </div>
            {Boolean(subfields.length) && (
                <div className="box-with-border mt-4 border border-system-warning-200 p-0">
                    <div className="py-2 px-4 bg-system-warning-100 bg-opacity-50 border-b border-b-system-warning-200 rounded-t-[14px] flex flex-row items-center">
                        <Text variant="body.regular.xs" className="text-system-warning-900 flex-1">
                            Este(s) campo(s) só aparecerá(ão) no formulário quando o usuário selecionar esta opção.
                        </Text>
                        <Button
                            startIcon={<SvgIcoPlus width={12} height={12} color={theme.extend.colors.system.warning[900]} />}
                            type="button"
                            size="small"
                            color="warning"
                            variant="outlined"
                            className="p-1 px-3 border-0 text-system-warning-900"
                            sx={{ '&:hover': { border: 'none', backgroundColor: theme.extend.colors.system.warning[100] } }}
                            onClick={() => appendSubfield({ ...FORM_SUBFIELD_DEFAULT, order: subfields.length + 1 })}>
                            Adicionar campo
                        </Button>
                    </div>
                    {subfields.map((subfield, subfieldIndex) => {
                        return <FormItemSubfield subfieldIndex={subfieldIndex} subfieldsPath={subfieldsPath} key={subfield.id} onRemoveSubfield={removeSubfield} />;
                    })}
                </div>
            )}
        </div>
    );
};

export default FormItemOption;
