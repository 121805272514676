import { Option } from 'types/general';
import { City } from './address';
import { File } from './file';
import { SaleOrderOrigin, SaleOrderStatus } from './sale-order';
import { Customer, CustomerContact } from './customer';

export enum BranchCity {
    PE = 1,
    RJ,
}

export type SalesNotePayload = {
    company?: {
        id: number;
        taxRegime: number;
    };
    transactions?: (Option & { netValue: number })[] | [];
    cityTax?: {
        label?: string;
        value?: number;
        taxes?: {
            icms?: string;
            iss?: string;
        };
    } | null;
    salesOrders?: Option[];
    number?: string;
    invoiceValue?: number;
    deductionValues?: { deductionValue: number; orderService?: number; saleOrder?: number }[];
    deductionValue?: number;
    emission?: string;
    nature?: SaleOrderOrigin;
    orderServiceNumber?: string;
    file?: File;
    das?: number;
    dasAliquot?: number;
    irpjAdditional?: number;
    irpj?: number;
    csll?: number;
    cofins?: number;
    pis?: number;
    simpleInss?: number;
    presumedInss?: number;
    cpp?: number;
    iss?: number;
    icms?: number;
    issWithheld?: boolean;
    icmsWithheld?: boolean;
    irpjWithheld?: boolean;
    irpjAdditionalWithheld?: boolean;
    csllWithheld?: boolean;
    cofinsWithheld?: boolean;
    pisWithheld?: boolean;
    inssWithheld?: boolean;
    cppWithheld?: boolean;
    customer?: number;
    withImportedProducts?: boolean;
};

export enum SalesReceiptStatus {
    Waiting = 1,
    Initialized,
    Finalized,
    Stopped,
    Cancelled,
    Processing,
    NotFinalized,
}

export type PaymentForm = {
    id: number;
    name: string;
    abbreviation?: null;
    code?: null;
    type: number;
};

export type SaleReceiptTransactions = {
    code: string;
    description: string;
    dueDate: Date;
    grossValue: number;
    id: number;
    installmentDescription: string;
    invoiceStatus: number;
    netValue: number;
    order: number;
    paid: boolean;
    payday: Date | null;
    paymentForm: PaymentForm;
    provisioned: boolean;
    transfer: boolean;
    type: number;
};

export type SalesReceiptResponse = {
    id: number;
    number: string;
    finalized: boolean;
    company: any;
    emission: string;
    nature: SaleOrderOrigin;
    orderServiceNumber: string;
    invoiceValue: number;
    deductionValues: { deductionValue: number; orderService: number }[] | null;
    deductionValue: number;
    totalDeductionValue: number;
    cityTax: {
        id: number;
        city: Pick<City, 'id' | 'name'>;
        icms: string;
        iss: string;
    };
    taxRegime: number;
    salesOrders: {
        id: number;
        saleOrder: Pick<SaleReceiptTransactions, 'netValue' | 'grossValue' | 'code' | 'id'> & {
            addition: number;
            discount: number;
            saleOrderStatus: {
                title: string;
                slug: SaleOrderStatus;
            };
        } & Pick<CustomerContact, 'customer'>;

        orderService: {
            title: string;
            id: number;
            saleOrder: {
                grossValue: number;
                netValue: number;
                discount: number;
                addition: number;
                customer: {
                    id: number;
                    title: string;
                };
            };
            orderServiceStatus: {
                id: number;
                title: string;
            };
            code: string;
        };
        deductionValue: string;
    }[];
    transactions: SaleReceiptTransactions[];
    file: File;
    das?: number;
    inss?: number;
    inssWithheld: boolean;
    iss?: number;
    issWithheld: boolean;
    icms?: number;
    icmsWithheld: boolean;
    pis?: number;
    pisWithheld: boolean;
    cofins?: number;
    cofinsWithheld: boolean;
    cpp?: number;
    cppWithheld: boolean;
    irpjAdditional?: number;
    irpjAdditionalWithheld: boolean;
    irpj?: number;
    irpjWithheld: boolean;
    csll?: number;
    csllWithheld: boolean;
    customer?: Pick<Customer, 'id' | 'title'>;
} & Pick<SalesNotePayload, 'dasAliquot' | 'withImportedProducts'>;
