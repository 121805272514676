import { useQuery } from 'react-query';
import api from 'services/api';
import { getStockBalanceKey } from './keys';
import { ApprovalStatus } from 'types/general';
import { Person } from 'types/models/person';
import { StockProduct } from 'types/models/stock';

export type StockBalanceResponse = {
    approvalStatus: ApprovalStatus;
    code: string;
    id: number;
    justification: string;
    person: Partial<Person>;
    created: string;
    products: Array<{
        currentQuantity: number;
        currentValue: number;
        id: number;
        stockProduct: Partial<StockProduct>;
        value: number;
        quantity: number;
    }>;
};

const useGetStockBalance = (stockId?: string, balanceId?: string) => {
    const endpoint = `/stocks/${stockId}/balances/${balanceId}`;

    const request = async () => {
        const { data } = await api.get<StockBalanceResponse>(endpoint);

        return data;
    };

    return useQuery(getStockBalanceKey(stockId, balanceId), request, {
        enabled: !!stockId && !!balanceId,
    });
};

export default useGetStockBalance;
