import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from '@mui/material/TableRow/TableRow';
import Checkbox from 'components/core/form/checkbox';
import CurrencyInput from 'components/core/form/currency';
import { Controller, ControllerRenderProps, FieldValues, useFieldArray, useFormContext } from 'react-hook-form';
import { formatMoney } from 'utils/money';
import { PendingMoneyDeductionsOrderServices } from './create-pending-money-deduction';
import RemoveButton from 'components/buttons/remove';
import { NumberFormatValues } from 'react-number-format';

type PendingMoneyDeductionItemProps = {
    item: PendingMoneyDeductionsOrderServices;
    index: number;
};

type FieldProp = ControllerRenderProps<FieldValues, `orderServices.${number}.${string}`>;

const PendingMoneyDeductionItem = ({ item, index }: PendingMoneyDeductionItemProps) => {
    const { control, formState } = useFormContext();

    const getPath = (key: string) => `orderServices.${index}.${key}` as const;

    const handleChangeCheckbox = (field: FieldProp) => field.onChange(!item.isChecked);

    const { remove } = useFieldArray({ control, name: 'orderServices' });

    const handleRemove = () => remove(index);

    const handleValueChange = (field: FieldProp) => (values: NumberFormatValues) => {
        field.onChange(values.floatValue);
    };

    return (
        <TableRow
            className="relative group"
            sx={{
                '&:last-child td': { borderBottom: 0 },
            }}>
            <TableCell>
                <Controller
                    name={getPath('isChecked')}
                    control={control}
                    render={({ field }) => <Checkbox {...field} checked={item?.isChecked} title="Selecionar" onChange={handleChangeCheckbox.bind(this, field)} />}
                />
            </TableCell>
            <TableCell>{item.code || '-'}</TableCell>
            <TableCell>{formatMoney(item.value)}</TableCell>
            <TableCell>{formatMoney(item.totalToBeDeducted)}</TableCell>
            <TableCell>{formatMoney(item.totalDeducted)}</TableCell>
            <TableCell>
                <Controller
                    name={getPath('valueToDeduct')}
                    control={control}
                    render={({ field }) => {
                        return (
                            <div>
                                <CurrencyInput
                                    name={field.name}
                                    ref={field.ref}
                                    parentClassName="!w-[150px]"
                                    value={item.valueToDeduct}
                                    disabled={!item.isChecked}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    onValueChange={handleValueChange(field)}
                                    error={formState?.errors?.orderServices?.[index]?.valueToDeduct?.message}
                                />
                            </div>
                        );
                    }}
                />
            </TableCell>
            <TableCell>
                <RemoveButton className="right-0" onClick={handleRemove} />
            </TableCell>
        </TableRow>
    );
};

export default PendingMoneyDeductionItem;
