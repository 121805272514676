import * as React from 'react';
const SvgIcoEdit = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M13.182 1.5a1.319 1.319 0 0 0-.933.386L11.076 3.06l1.865 1.865 1.173-1.173a1.318 1.318 0 0 0-.932-2.251ZM11.88 5.984 10.016 4.12l-7.498 7.497-.7 2.564 2.565-.7 7.497-7.497Zm.223-5.77a2.819 2.819 0 0 1 3.072 4.597l-9.873 9.872a.75.75 0 0 1-.333.194L.947 15.974a.75.75 0 0 1-.92-.921l1.096-4.022a.75.75 0 0 1 .194-.333L11.189.825c.261-.261.572-.469.914-.61Z"
            clipRule="evenodd"
        />
    </svg>
);
export default SvgIcoEdit;
