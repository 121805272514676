import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';
import { Status } from 'types/general';

export const getRulesKey = ['rules', 'all'];

export const getRulesQuery = jsonToGraphQLQuery({
    query: {
        __name: 'GetRulesOptions',
        items: {
            __aliasFor: 'rule',
            __args: {
                where: {
                    status: { _eq: Status.Active },
                },
                orderBy: { name: new EnumType('ASC') },
            },
            value: { __aliasFor: 'id' },
            label: { __aliasFor: 'name' },
        },
    },
});
