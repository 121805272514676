import SimpleTable from 'components/core/table/simple';
import PageHeader from 'components/page/header';
import { useParams } from 'react-router-dom';
import useGetOrderServiceMovements from 'services/queries/order-services/use-get-order-service-movements';
import { orderServicesBadgesMap } from '../../utils';
import Badge from 'components/core/badge';
import Empty from 'components/empty';
import { formatDate } from 'utils/date';
import Card from 'components/core/card';
import Spinner from 'components/core/spinner';
import { ColorVariant } from 'types/colors';

const OrderServiceMovementsPage = () => {
    const { orderServiceId, saleOrderId } = useParams();

    const { data = [], isLoading, isError } = useGetOrderServiceMovements(orderServiceId, saleOrderId);

    const movements = data.map((item) => {
        const { variant = 'grey', bgOpacity = 0.3 } = orderServicesBadgesMap[item.orderServiceStatus.slug] || {};

        return {
            id: item.id,
            contents: [
                {
                    label: Boolean(item.orderServiceStatus.slug) ? (
                        <Badge variant={variant as ColorVariant} bgOpacity={bgOpacity}>
                            {item.orderServiceStatus.title}
                        </Badge>
                    ) : (
                        '-'
                    ),
                },
                {
                    textClassName: 'whitespace-nowrap',
                    label: item.observation || '-',
                },
                {
                    textClassName: 'whitespace-nowrap',
                    label: formatDate(item.created, 'DD/MM/YYYY HH:mm'),
                },
            ],
        };
    });

    return (
        <>
            <PageHeader title="Movimentações" className="mb-4" />
            {isLoading ? (
                <Spinner fixed={false} parentClasses="p-4" />
            ) : Boolean(data.length) || !isError ? (
                <Card>
                    <SimpleTable columns={['Status', 'Observações', 'Data e hora']} items={movements} />
                </Card>
            ) : (
                <Empty title="Não há movimentações a serem mostradas." />
            )}
        </>
    );
};

export default OrderServiceMovementsPage;
