import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import ListPage from 'pages/shared/crud/list';
import { useParams } from 'react-router-dom';
import { expenseBudgetsListConfig } from '../utils';

const ExpenseBudgetsPage = ({ page }: WithPagePermissionsProps) => {
    const { expenseId } = useParams();

    return <ListPage {...expenseBudgetsListConfig(expenseId)} page={page} />;
};

export default ExpenseBudgetsPage;
