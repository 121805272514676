import SimpleTable, { SimpleTableContent } from 'components/core/table/simple';
import { useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { PurchaseOrderProduct } from 'types/models/purchase-order';
import { EntryBuyProductsPayload } from '.';
import { formatNumberToPTBR } from 'utils/money';
import CurrencyInput from 'components/core/form/currency';
import Empty from 'components/empty';

type EntryProductsTableProps = {
    selectedProducts: PurchaseOrderProduct[];
};

const EntryProductsTable = ({ selectedProducts = [] }: EntryProductsTableProps) => {
    const { control, formState, reset } = useFormContext<EntryBuyProductsPayload>();

    const items = useMemo((): SimpleTableContent[] => {
        return selectedProducts.map((item, index) => ({
            id: item.id,
            contents: [
                {
                    label: `${item.product.name} - ${item.product.code}`,
                },
                {
                    label: formatNumberToPTBR(item.quantity),
                },
                {
                    label: (
                        <Controller
                            name={`purchaseOrderProducts.${index}.quantity`}
                            control={control}
                            render={({ field }) => (
                                <CurrencyInput
                                    ref={field.ref}
                                    name={field.name}
                                    value={field.value}
                                    onValueChange={({ floatValue }) => field.onChange(floatValue)}
                                    error={formState.errors.purchaseOrderProducts?.[index]?.quantity?.message}
                                />
                            )}
                        />
                    ),
                },
            ],
        }));
    }, [selectedProducts, formState.errors.purchaseOrderProducts, control]);

    useEffect(() => {
        if (Boolean(selectedProducts.length)) {
            reset({
                purchaseOrderProducts: selectedProducts.map((item) => ({
                    purchaseOrderProductId: item.id,
                    quantity: item.quantity,
                })),
            });
        }
    }, [selectedProducts, reset]);

    return Boolean(selectedProducts.length) ? (
        <SimpleTable columns={['Produto', 'Quantidade solicitada', 'Quantidade entregue']} items={items} />
    ) : (
        <Empty className="p-4" title="Não há produtos a serem mostrados." />
    );
};

export default EntryProductsTable;
