import { useQuery } from 'react-query';
import apiGraphQl from 'services/graphql';
import queries, { getAllPagesKey } from './keys';

export type PagesResponse = {
    items: Array<{
        id: number;
        name: string;
        rules: Array<{
            rule: {
                id: number;
                name: string;
            };
        }>;
    }>;
};

const useGetPages = () => {
    const getAllPages = async () => apiGraphQl<PagesResponse>(queries.getAll).then(({ items }) => items);

    return useQuery(getAllPagesKey, getAllPages);
};

export default useGetPages;
