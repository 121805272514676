import Divider from '@mui/material/Divider';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import useGetQueryParam from 'hooks/router/use-get-query-param';
import { getNumberValueOrZero } from 'pages/private/expenses/details/tabs/transactions/utils';
import CardsDetails from 'pages/private/sales-notes/components/cards';

const ImportFlowProductNationalizationPage = () => {
    const productName = useGetQueryParam('productName');
    const complementaryValue = useGetQueryParam('complementaryValue');
    const baseValue = useGetQueryParam('baseValue');

    return (
        <Modal
            contentClassnames="w-[600px]"
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    {productName || '-'}
                </Text>
            }>
            <>
                <Divider />
                <div className="grid sm:grid-cols-2">
                    <CardsDetails label="Valor Base" value={getNumberValueOrZero(baseValue)} />
                    <CardsDetails label="Valor Complementar" value={getNumberValueOrZero(complementaryValue)} />
                </div>
            </>
        </Modal>
    );
};

export default ImportFlowProductNationalizationPage;
