import { RouteObject } from 'react-router-dom';
import { Rule } from 'types/permissions';
import Page from 'pages/shared/page';
import PurchaseOrderList from './list';
import PurchaseOrderExpensesCreateOrUpdatePage from 'pages/private/purchase-orders/expenses';
import StockDeliveryPage from 'pages/private/stocks/stock-delivery';
import { ExpenseNature } from 'types/models/expense';
import PurchaseOrderListPage from 'pages/private/purchase-orders/list';

const purchasesOrders: RouteObject[] = [
    {
        // @ts-ignore
        breadcrumb: 'Ordens de compra',
        path: 'ordens-de-compra',
        element: <Page Component={PurchaseOrderListPage} code="pedidos-compra" permissions={[Rule.List]} />,
    },
    {
        // @ts-ignore
        breadcrumb: 'Produtos das ordens de compra',
        path: 'produtos-pedidos-de-venda',
        element: <Page Component={PurchaseOrderList} code="pedidos-compra-produtos" permissions={[Rule.List]} title="Produtos das ordens de compra" />,
        children: [
            {
                path: 'despesas/novo/compra',
                element: <Page Component={PurchaseOrderExpensesCreateOrUpdatePage} code="despesas" nature={ExpenseNature.PurchaseOrder} permissions={[Rule.Create]} withModal={true} />,
            },
            {
                path: 'entrega-do-estoque',
                element: <Page Component={StockDeliveryPage} code="pedido-venda-ordem-compra" permissions={[Rule.Create]} withModal={true} />,
            },
            {
                path: 'entrega-direta/despesas/novo/compra',
                element: <Page Component={PurchaseOrderExpensesCreateOrUpdatePage} nature={ExpenseNature.PurchaseOrderBilled} code="despesas" permissions={[Rule.Create]} withModal={true} />,
            },
        ],
    },
    {
        path: 'entrega-direta/despesas/novo/compra',
        element: <Page Component={PurchaseOrderExpensesCreateOrUpdatePage} nature={ExpenseNature.PurchaseOrderBilled} code="despesas" permissions={[Rule.Create]} withModal={true} />,
    },
];

export default purchasesOrders;
