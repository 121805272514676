import Table from '@mui/material/Table/Table';
import TableRow from '@mui/material/TableRow/TableRow';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import Card from 'components/core/card';
import Text from 'components/core/text';
import { GetOrderServicesProductsCategoriesExpense } from 'services/queries/order-services/use-get-order-services-products-categories';
import { formatMoney, formatNumberToPTBR } from 'utils/money';
import Button from 'components/core/button';
import { useState } from 'react';
import UpdateSubCategory from './update-sub-category';

type ServiceOrderProductsCategoriesSubTableProps = {
    expenses: GetOrderServicesProductsCategoriesExpense[];
};

const ServiceOrderProductsCategoriesSubTable = ({ expenses }: ServiceOrderProductsCategoriesSubTableProps) => {
    const [isEditVisible, setIsEditVisible] = useState(false);

    return (
        <>
            {isEditVisible && <UpdateSubCategory onClose={() => setIsEditVisible(false)} selectedItems={expenses} />}

            <Card elevation={0} className="border mb-4">
                <Table>
                    <TableHead className="border-b">
                        <TableRow>
                            <TableCell>
                                <Text as="span" className="text-base-500">
                                    Nome
                                </Text>
                            </TableCell>
                            <TableCell>
                                <Text as="span" className="text-base-500">
                                    Unidade
                                </Text>
                            </TableCell>
                            <TableCell>
                                <Text as="span" className="text-base-500">
                                    Despesa
                                </Text>
                            </TableCell>
                            <TableCell>
                                <Text as="span" className="text-base-500">
                                    Quantidade
                                </Text>
                            </TableCell>
                            <TableCell>
                                <Text as="span" className="text-base-500">
                                    Valor
                                </Text>
                            </TableCell>
                            <TableCell>
                                <Text as="span" className="text-base-500">
                                    Total Despesa
                                </Text>
                            </TableCell>
                            <TableCell>
                                <Text as="span" className="text-base-500">
                                    Total Despesa Paga
                                </Text>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {expenses?.map((item, index) => {
                            return (
                                <TableRow key={`${item.id}_${index}`} classes={{ root: 'hover:bg-base-200 hover:bg-opacity-50' }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell>
                                        <Text as="span" className="text-base-500">
                                            {item?.expense?.title || '-'}
                                        </Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text as="span" className="text-base-500">
                                            {item?.product?.unit?.name ?? '-'}
                                        </Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text as="span" className="text-base-500">
                                            {item?.expense?.code || '-'}
                                        </Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text as="span" className="text-base-500">
                                            {formatNumberToPTBR(item?.quantity || 0)}
                                        </Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text as="span" className="text-base-500">
                                            {formatMoney(Number(item?.netValue || 0))}
                                        </Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text as="span" className="text-base-500">
                                            {formatMoney(Number((item?.netValue || 0) * (item?.quantity || 0)))}
                                        </Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text as="span" className="text-base-500">
                                            {!!item?.expense?.payday ? formatMoney(Number((item?.netValue || 0) * (item?.quantity || 0))) : '-'}
                                        </Text>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Card>
            <Button type="submit" variant="contained" color="secondary" className="min-w-[100px] ml-auto flex mb-3" onClick={() => setIsEditVisible(true)}>
                Editar subcategorias
            </Button>
        </>
    );
};

export default ServiceOrderProductsCategoriesSubTable;
