import IconButton from '@mui/material/IconButton/IconButton';
import HeaderUser from './user';
import Breadcrumb from 'components/core/breadcrumb';
import theme from 'settings/theme';
import useAuth from 'store/auth/use-auth';
import useResponsive from 'hooks/responsive/use-responsive';
import { useState } from 'react';
import ResetUserPassword from 'components/reset-password';
import HeaderActions from './actions';
import SvgIcoMenu from 'components/core/icon/files/ico-menu';
import Notifications from './notifications';

type HeaderProps = {
    onToggleSidebar: () => void;
};

const Header = ({ onToggleSidebar }: HeaderProps) => {
    const { auth, logout } = useAuth();
    const { isTabletLandscape } = useResponsive();

    const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false);

    const handleLogout = () => logout();

    const handleResetPasswordModal = (value: boolean) => () => setIsResetPasswordModalOpen(value);

    return (
        <>
            <header className={styles.container}>
                <div className={styles.centerY}>
                    <IconButton color="primary" className="p-[10px] mr-4" onClick={() => onToggleSidebar()}>
                        <SvgIcoMenu color={theme.extend.colors.heading} width={16} height={16} />
                    </IconButton>
                    {isTabletLandscape && <Breadcrumb />}
                </div>
                <div className={styles.user}>
                    <HeaderActions />
                    <Notifications />
                    <HeaderUser name={auth.credentials.user.person?.title || ''} onLogout={handleLogout} onOpenResetPasswordModal={handleResetPasswordModal(true)} />
                </div>
            </header>
            {isResetPasswordModalOpen && <ResetUserPassword onResetPasswordModal={handleResetPasswordModal(false)} />}
        </>
    );
};

const styles = {
    centerY: 'flex items-center',
    user: 'flex items-center overflow-hidden gap-2',
    container: 'bg-base-100 border-b border-b-base-300 p-4 flex items-center justify-between h-[70px] sticky top-0 z-50',
    divider: 'w-px h-[30px] bg-base-300 mx-2',
    name: 'text-heading ml-3 mr-1.5',
};

export default Header;
