import Badge from 'components/core/badge';
import { ApprovalStatus } from 'types/general';
import ExternalButton from 'components/buttons/external';
import { formatMoney, formatNumberToPTBR } from 'utils/money';
import { MUIDataTableColumn } from 'mui-datatables';
import PurchaseProductStatusBadge from 'components/badges/purchase-product-status';
import ApprovalBadge from 'components/badges/approval';
import { formatDate } from 'utils/date';

export const purchaseOrderProductsColumns = (isStockProducts): MUIDataTableColumn[] => {
    return [
        {
            name: 'id',
            options: { display: 'excluded' },
        },
        {
            name: 'purchaseOrder',
            options: { display: 'excluded' },
        },
        {
            name: 'product',
            options: { display: 'excluded' },
        },
        {
            label: 'Pedido',
            name: 'purchaseOrder.code',
            options: {
                customBodyRender: (_, { rowData }) => {
                    const [, purchaseOrder] = rowData;

                    if (!purchaseOrder) {
                        return '-';
                    }

                    const stockPurchaseOrderLink = `/app/ordens-de-compra-de-estoque/${purchaseOrder?.id}`;
                    const purchaseOrderLink = `/app/pedidos-de-venda/${purchaseOrder?.origin?.orderService?.saleOrder?.id}/ordens-de-servico/${purchaseOrder?.origin?.orderService?.id}/ordens-de-compra/${purchaseOrder?.id}`;

                    return <ExternalButton href={isStockProducts ? stockPurchaseOrderLink : purchaseOrderLink}>{purchaseOrder?.code || '-'}</ExternalButton>;
                },
            },
        },
        {
            label: 'Produto',
            name: 'product',
            options: {
                customBodyRender: (value, { rowData }) => {
                    const billedIndex = isStockProducts ? 13 : 15;

                    const billed = rowData[billedIndex];

                    if (!value.name || !value.code) {
                        return '-';
                    }

                    return (
                        <>
                            <span className="whitespace-nowrap !block">{`${value.name} (${value.code})`}</span>
                            {billed && <Badge variant="warning">Faturado</Badge>}
                        </>
                    );
                },
            },
        },
        ...(!isStockProducts
            ? [
                  {
                      label: 'Obra',
                      name: 'purchaseOrder',
                      options: {
                          customBodyRender: (value) => {
                              return !!value?.origin?.orderService ? (
                                  <ExternalButton href={`/app/pedidos-de-venda/${value?.origin?.orderService?.saleOrder?.id}/ordens-de-servico/${value?.origin?.orderService?.id}`}>
                                      {value?.origin?.orderService?.code}
                                  </ExternalButton>
                              ) : (
                                  '-'
                              );
                          },
                      },
                  },
              ]
            : []),
        {
            label: 'Empresa',
            name: 'purchaseOrder.company.name',
            options: {
                customBodyRender: (company = '-') => <span className="whitespace-nowrap">{company}</span>,
            },
        },
        ...(!isStockProducts
            ? [
                  {
                      label: 'Saldo atual',
                      name: 'orderServiceProductPurchase.balance',
                      options: {
                          sort: false,
                          customBodyRender: (balance = 0) => formatNumberToPTBR(balance),
                      },
                  },
              ]
            : []),
        {
            label: 'Quantidade solicitada',
            name: 'quantity',
            options: {
                customBodyRender: (quantity, { rowData }) => {
                    const [, , product] = rowData;

                    // using dangerouslySetInnerHTML for unit (abbreviation) that may contain HTML Entities.
                    return <span dangerouslySetInnerHTML={{ __html: `${formatNumberToPTBR(quantity)} ${product?.unit?.abbreviation}` }} />;
                },
            },
        },
        {
            label: 'Valor de compra',
            name: 'purchaseValue',
            options: {
                customBodyRender: (value) => (Number.isFinite(value) ? formatMoney(value, undefined, 4) : '-'),
            },
        },
        {
            label: 'Em estoque',
            name: 'stockBalance',
            options: {
                sort: false,
                customBodyRender: (stockBalance) => formatNumberToPTBR(stockBalance || 0),
            },
        },
        {
            name: 'expenseProduct.expense',
            label: 'Despesa',
            options: {
                sort: false,
                customBodyRender: (value) => {
                    const billed = value?.billed;

                    if (!value?.id) {
                        return '-';
                    }

                    return (
                        <>
                            <ExternalButton to={`/app/despesas/${value?.id?.toString()}`}>{value?.code}</ExternalButton>
                            {billed && <Badge variant="warning">Faturada</Badge>}
                        </>
                    );
                },
            },
        },
        {
            name: 'provision',
            label: 'Provisão',
            options: {
                customBodyRender: (value) => formatDate(value),
            },
        },
        {
            label: 'Status',
            name: 'purchaseProductStatus',
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return <PurchaseProductStatusBadge purchaseProductStatus={value} />;
                },
            },
        },
        {
            label: 'Aprovado',
            name: 'approvalStatus',
            options: {
                sort: false,
                customBodyRender: (value: ApprovalStatus) => <ApprovalBadge status={value} />,
            },
        },
        {
            name: 'billed',
            options: { display: 'excluded' },
        },
    ];
};

export const purchaseOrderProductsOrderByMap = {
    purchaseOrder: 'os.code',
    product: 'pd.name',
    quantity: 'quantity',
    'purchaseOrder.company.name': 'c.name',
    'purchaseOrder.code': 'po.code',
};
