import { getBankAccountByIdKey } from './keys';
import { useQuery } from 'react-query';
import api from 'services/api';
import { ResponseBankAccount } from 'types/models/bank-accounts';

const useGetBankAccountById = (bankAccountId: number) => {
    const request = async () => {
        const { data } = await api.get<ResponseBankAccount>(`company-bank-accounts/${bankAccountId}`);

        return data;
    };

    return useQuery(getBankAccountByIdKey(bankAccountId), request, {
        enabled: Boolean(bankAccountId),
    });
};

export default useGetBankAccountById;
