import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import ListPage from 'pages/shared/crud/list';
import { notificationListConfig } from 'settings/routes/notifications/utils';
import useAuth from 'store/auth/use-auth';

const NotificationsPage = ({ page }: WithPagePermissionsProps) => {
    const { auth } = useAuth();

    return <ListPage title="Notificações" page={page} {...notificationListConfig(auth.credentials?.user?.id)} />;
};

export default NotificationsPage;
