import { useEffect, useState } from 'react';
import { GenericReportFilterPayload, ReportFilterPayload } from '../types';
import { useMainContext } from 'pages/private/main/context';
import useGetResumesReports from 'services/queries/reports/use-get-resumes-reports';
import ResumesReportContent from './resumes-report-content';
import { ResumesConfigs } from 'types/models/reports';
import { formatDate } from 'utils/date';
import useGetDataContentConfigFilters from '../../hooks/use-get-data-content-filters';
import { initialReportFilters } from '../utils';
import isEmpty from 'lodash/isEmpty';
import { ReportsContextProvider } from '../../context';

type ReportResumesPageProps = {
    title: string;
    configs: ResumesConfigs[];
    withFooterClassesByValue?: boolean;
};

const ReportResumesPage = ({ title, configs, withFooterClassesByValue = false }: ReportResumesPageProps) => {
    const [filters, setFilters] = useState<GenericReportFilterPayload>(initialReportFilters);
    const [isFilterVisible, setIsFilterVisible] = useState(false);

    const { handleToggleSidebar } = useMainContext();

    const dataContentFiltersConfig = useGetDataContentConfigFilters({
        withConsultants: false,
    });

    const [consultantsRequest, companiesRequest, branchesRequest, typesRequest] = useGetResumesReports(configs, filters);

    const contents = [
        {
            title: 'Consultores',
            columns: configs[0].columns,
            data: consultantsRequest.data || [],
            isLoading: consultantsRequest.isLoading,
            searchPlaceholder: 'Buscar consultor',
        },
        {
            title: 'Empresas',
            columns: configs[1].columns,
            data: companiesRequest.data || [],
            isLoading: companiesRequest.isLoading,
            searchPlaceholder: 'Buscar empresa',
        },
        {
            title: 'Filiais',
            columns: configs[2].columns,
            data: branchesRequest.data || [],
            isLoading: branchesRequest.isLoading,
            searchPlaceholder: 'Buscar filial',
        },
        {
            title: 'Tipos',
            columns: configs[3].columns,
            data: typesRequest.data || [],
            isLoading: typesRequest.isLoading,
            searchPlaceholder: 'Buscar tipo',
        },
    ];

    const configFilters = {
        isFilterVisible,
        filters,
        onClearFilters: () => {
            setFilters(undefined);
            setIsFilterVisible(false);
        },
        onSubmitFilters: (data?: ReportFilterPayload['filter']) => {
            if (!isEmpty(data)) {
                const { startDate, endDate, ...rest } = data;

                setFilters({
                    filter: {
                        ...rest,
                        ...(!!startDate && {
                            startDate: formatDate(startDate, 'YYYY-MM-DD'),
                        }),
                        ...(!!endDate && {
                            endDate: formatDate(endDate, 'YYYY-MM-DD'),
                        }),
                    },
                });
            }

            setIsFilterVisible(false);
        },
        onToggleFilters: (value: boolean) => setIsFilterVisible(value),
    };

    useEffect(() => {
        handleToggleSidebar(true);
        // eslint-disable-next-line
    }, []);

    return (
        <ReportsContextProvider>
            <ResumesReportContent
                dataContentFiltersConfig={dataContentFiltersConfig}
                withFooterClassesByValue={withFooterClassesByValue}
                title={title}
                contents={contents}
                configFilters={configFilters}
            />
        </ReportsContextProvider>
    );
};

export default ReportResumesPage;
