import { useMutation } from 'react-query';
import useToast from 'hooks/toast/use-toast';
import api from 'services/api';
import { UserResetPasswordPayload } from 'components/reset-password/reset-password-form';

const useChangePassword = () => {
    const { showToast } = useToast();

    const request = async (payload: UserResetPasswordPayload) => api.patch(`users/password`, payload);

    return useMutation(request, {
        onSuccess: () => {
            showToast(`Senha alterada com sucesso`, 'success', { duration: 5000 });
        },
        onError: (error) => {
            console.error(error);
            showToast(`Ocorreu um erro ao renovar a sua senha em nosso sistema`, 'danger');
        },
    });
};

export default useChangePassword;
