import ApprovalBadge from 'components/badges/approval';
import PurchaseProductStatusBadge from 'components/badges/purchase-product-status';
import ExternalButton from 'components/buttons/external';
import Badge from 'components/core/badge';
import get from 'lodash/get';
import { ApprovalStatus } from 'types/general';
import { DatatableColumn } from 'types/graphql';
import { ExpenseProduct } from 'types/models/expense';
import { PurchaseOrderProduct, PurchaseOrderProductsActions, PurchaseProductStatus } from 'types/models/purchase-order';
import { formatDate } from 'utils/date';
import dictionary from 'utils/dictionary';
import { formatMoney, formatNumberToPTBR } from 'utils/money';
import { array, number, object } from 'yup';

export const getPurchaseOrderConfig = (purchaseOrderId?: string) => ({
    name: 'GetPurchaseOrderByPk',
    table: 'purchaseOrderByPk',
    id: Number(purchaseOrderId) || 0,
    fields: [
        'id',
        'code',
        'purchaseStatus',
        'createdAt',
        'person.id',
        'person.title',
        'branch.name',
        'purchaseOrderOrigin.purchaseOrderOriginStock.stock.value',
        'purchaseOrderOrigin.purchaseOrderOriginStock.stock.type',
        'purchaseOrderOrigin.purchaseOrderOriginStock.stock.name',
        'company.id',
        'company.name',
    ],
});

export const orderServicePurchaseProductColumns: DatatableColumn[] = [
    {
        name: 'id',
        options: { display: 'excluded' },
    },
    {
        name: 'product.code',
        options: { display: 'excluded' },
    },
    {
        label: 'Produto',
        name: 'product.name',
        options: {
            customBodyRender: (value, { rowData }) => {
                const billed = rowData[18];
                const code = rowData.at(1);

                if (!value) {
                    return '-';
                }

                return (
                    <>
                        <span className="whitespace-nowrap !block">{`${value} (${code})`}</span>
                        {billed && <Badge variant="warning">Faturado</Badge>}
                    </>
                );
            },
        },
    },
    {
        name: 'orderServiceProductSale.product.name',
        label: 'Escopo de venda',
        options: { customBodyRender: (value = '-') => <span className="whitespace-nowrap">{value}</span> },
    },
    {
        label: 'Em estoque',
        name: '',
        options: {
            customBodyRender: (_, { rowData }) => {
                const stockBalance = rowData[17] || 0;

                return formatNumberToPTBR(stockBalance);
            },
        },
    },
    {
        label: 'Saldo atual',
        name: 'orderServiceProductPurchase.balance',
        options: {
            customBodyRender: (balance = 0) => {
                return formatNumberToPTBR(balance);
            },
        },
    },
    {
        name: 'product.unit.abbreviation',
        options: { display: 'excluded' },
    },
    {
        label: 'Quantidade solicitada',
        name: 'quantity',
        options: {
            customBodyRender: (value = 0, tableMeta) => {
                const unit = get(tableMeta, 'rowData[6]');
                // using dangerouslySetInnerHTML for unit (abbreviation) that may contain HTML Entities.
                return <span dangerouslySetInnerHTML={{ __html: `${formatNumberToPTBR(value)} ${unit}` }} />;
            },
        },
    },
    {
        label: 'Valor de compra',
        name: 'purchaseValue',
        options: {
            customBodyRender: (value) => (Number.isFinite(value) ? formatMoney(value, undefined, 4) : '-'),
        },
    },
    {
        label: 'Despesa',
        name: 'expenseProduct',
        options: {
            customBodyRender: (expenseProduct: ExpenseProduct) => {
                if (!expenseProduct) {
                    return '-';
                }

                const billed = expenseProduct.expense.billed;

                return (
                    <>
                        <ExternalButton to={`/app/despesas/${expenseProduct.expense.id}`}>{expenseProduct.expense.code || '-'}</ExternalButton>
                        {billed && <Badge variant="warning">Faturada</Badge>}
                    </>
                );
            },
        },
    },
    {
        label: 'Empresa',
        name: 'purchaseOrder.company.name',
        options: {
            customBodyRender: (value = '-') => <span className="whitespace-nowrap">{value}</span>,
        },
    },
    {
        label: 'Provisão',
        name: 'provision',
        options: {
            customBodyRender: (value) => (Boolean(value) ? formatDate(value) : '-'),
        },
    },
    {
        label: 'Status',
        name: 'purchaseProductStatus',
        options: {
            customBodyRender: (value) => {
                return <PurchaseProductStatusBadge purchaseProductStatus={value} />;
            },
        },
    },
    {
        label: 'Aprovado',
        name: 'approvalStatus',
        options: {
            customBodyRender: (value: ApprovalStatus) => <ApprovalBadge status={value} />,
        },
    },
    {
        name: 'purchaseOrder.code',
        options: { display: 'excluded' },
    },
    {
        name: 'purchaseOrder.origin.orderService.title',
        options: { display: 'excluded' },
    },
    {
        name: 'purchaseOrder.origin.stock.name',
        options: { display: 'excluded' },
    },
    {
        name: 'stockBalance',
        options: { display: 'excluded' },
    },
    {
        name: 'billed',
        options: { display: 'excluded' },
    },
];

export const purchaseOrderChangeStatusValidator = (selectedRows: PurchaseOrderProduct[], action: PurchaseOrderProductsActions) => {
    return selectedRows.some((item) => {
        if (action === PurchaseOrderProductsActions.Entry) {
            const isNotApproved = item.approvalStatus !== ApprovalStatus.Approved;
            const isNotWaitingDelivered = item.purchaseProductStatus !== PurchaseProductStatus.WaitingDelivered;
            const hasMixedPurchaseOrderExpense = Boolean(item.expenseProduct?.expense?.id) !== Boolean(selectedRows[0].expenseProduct?.expense?.id);
            const hasMixedProductProvision = Boolean(item.provision) !== Boolean(selectedRows[0].provision);

            //Action on products are forbidden if the purchase order product is not approved, the purchase product status is not WaitingDelivered, the product is not billed or there are products with expenses and others without expenses or with products with provision and others without provision.
            return isNotApproved || isNotWaitingDelivered || !item.billed || hasMixedPurchaseOrderExpense || hasMixedProductProvision;
        }

        // Action on products are forbidden if action is different of 'cancel' and status is different of waiting
        if (![PurchaseOrderProductsActions.Cancel, PurchaseOrderProductsActions.Delete].includes(action)) {
            return item.approvalStatus !== ApprovalStatus.Waiting;
        }

        // Actions different of 'cancel' and status equal to disapproved are forbidden
        return item.approvalStatus === ApprovalStatus.Disapproved;
    });
};

export const entryBuyProductsSchema = object({
    purchaseOrderProducts: array(
        object({
            purchaseOrderProductId: number(),
            quantity: number().required(dictionary.validation.required),
        })
    ),
});
