import { useMutation } from 'react-query';
import apiGraphQl from 'services/graphql';
import { ProfilePageRule } from 'types/models/profile';
import queries from './keys';

type ProfilePageRulePayload = Array<Pick<ProfilePageRule, 'pageId' | 'ruleId' | 'profileId'>>;

const useCreateProfilePageRule = () => {
    const request = (payload: ProfilePageRulePayload) => apiGraphQl(queries.createProfilePageRules, { payload });

    return useMutation(request);
};

export default useCreateProfilePageRule;
