import { jsonToGraphQLQuery } from 'json-to-graphql-query';
import { Status } from 'types/general';
import dictionary from 'utils/dictionary';
import { formatMoney } from 'utils/money';
import { TestContext, array, boolean, number, object, string } from 'yup';

export const getServiceOrderGraphQuery = (orderServiceId?: string) => {
    return jsonToGraphQLQuery(
        {
            query: {
                __name: 'GetServiceOrderByPk',
                orderServiceByPk: {
                    id: true,
                    saleOrder: {
                        customerId: true,
                        id: true,
                        saleOrderProducts: {
                            id: true,
                            balance: true,
                            quantity: true,
                            netValue: true,
                            product: { name: true },
                            productId: true,
                            __args: {
                                where: {
                                    status: { _eq: Status.Active },
                                },
                            },
                        },
                    },
                    __args: { id: Number(orderServiceId || 0) },
                },
            },
        },
        { pretty: true }
    );
};

export const customerAddressQuery = jsonToGraphQLQuery({
    id: true,
    addressCustomers: {
        address: {
            id: true,
            compliment: true,
            neighbourhood: true,
            number: true,
            street: true,
            zip: true,
            cityId: true,
            stateId: true,
            city: { name: true },
            state: { initials: true },
        },
    },
});

const quantityBiggerThanBalance = (isUpdate = false) => {
    return (value = 0, context?: TestContext<any>) => {
        if (!context?.parent.checked) {
            return true;
        }

        if (isUpdate) {
            const maxQuantityValue = (context?.parent?.balance || 0) + (context?.parent?.actualQuantity || 0);

            return value <= maxQuantityValue;
        }

        if (!value) {
            return false;
        }

        return value <= (context?.parent?.balance || 0);
    };
};

export const serviceOrderSaleScopeSchema = (isUpdate = false) => {
    return object({
        saleProducts: array(
            object({
                product: object({
                    id: number().required(dictionary.validation.required),
                    name: string().required(dictionary.validation.required),
                }).when('checked', ([checked], schema) => (checked ? schema.required(dictionary.validation.required) : schema.optional())),
                value: number().when('checked', ([checked], schema) => (checked ? schema.required(dictionary.validation.required) : schema.optional())),
                balance: number(),
                quantity: number().test('quantityBiggerThanBalance', 'Quantidade maior que o saldo', quantityBiggerThanBalance(isUpdate)),
                predictedStartDate: string().when('checked', ([checked], schema) => (checked ? schema.required(dictionary.validation.required) : schema)),
                predictedEndDate: string().when('checked', ([checked], schema) => (checked ? schema.required(dictionary.validation.required) : schema)),
                checked: boolean().required(dictionary.validation.required).default(false),
                isRegisteredSaleProduct: boolean().default(false),
                actualQuantity: number().default(0),
                saleProductId: number().optional(),
            })
        ),
    });
};

export const serviceOrderFinancialSchema = (saleScopeTotal: number, budgetAverage: (value: any, context: any) => boolean) => {
    return object({
        transactions: array(
            object({
                order: number(),
                description: string(),
                dueDate: string().required(dictionary.validation.required),
                grossValue: number().required(dictionary.validation.required),
                paymentForm: number().required(dictionary.validation.required),
            })
        ).test('financialAverage', `A soma dos pagamentos não é igual ao escopo de venda: ${formatMoney(saleScopeTotal)}`, budgetAverage),
    });
};
