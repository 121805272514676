import { useQuery } from 'react-query';
import { getPersonBankAccountKey } from './keys';
import { BankAccount } from 'types/models/bank';
import api from 'services/api';

const useGetPersonBankAccount = (personId?: string, bankAccountId?: string) => {
    const request = async () => {
        const { data } = await api.get<BankAccount>(`/people/${personId}/bank-accounts/${bankAccountId}`);

        return data;
    };

    return useQuery(getPersonBankAccountKey(personId, bankAccountId), request, { enabled: !!bankAccountId });
};

export default useGetPersonBankAccount;
