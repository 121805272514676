import * as React from 'react';
const SvgIcoTrash = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M6.05 1.705a.7.7 0 0 1 .496-.205h2.9a.7.7 0 0 1 .7.7v.7h-4.3v-.7a.7.7 0 0 1 .205-.495ZM4.347 2.9v-.7a2.2 2.2 0 0 1 2.2-2.2h2.9a2.2 2.2 0 0 1 2.2 2.2v.7h2.875a.75.75 0 0 1 0 1.5h-.7v9.4a2.2 2.2 0 0 1-2.2 2.2H4.37a2.2 2.2 0 0 1-2.2-2.2V4.4h-.7a.75.75 0 1 1 0-1.5h2.875ZM3.67 13.8V4.4h8.65v9.4a.7.7 0 0 1-.7.7H4.37a.7.7 0 0 1-.7-.7Zm2.875-7.275a.75.75 0 0 1 .75.75v4.35a.75.75 0 0 1-1.5 0v-4.35a.75.75 0 0 1 .75-.75Zm3.65.75a.75.75 0 0 0-1.5 0v4.35a.75.75 0 0 0 1.5 0v-4.35Z"
            clipRule="evenodd"
        />
    </svg>
);
export default SvgIcoTrash;
