import TableCell from '@mui/material/TableCell/TableCell';
import { jsonToGraphQLQuery, VariableType } from 'json-to-graphql-query';
import { CreateOrUpdateProps } from 'pages/shared/crud/create-or-update';
import { CrudPageProps } from 'types/graphql';
import dictionary from 'utils/dictionary';
import { pkColumnsKey } from 'utils/environment';
import { object, string } from 'yup';

export type RulePayload = {
    name: string;
    keyRule: string;
};

const createOrUpdateRuleMutation = (isEdit = false) => {
    return jsonToGraphQLQuery(
        {
            mutation: {
                __name: 'CreateOrUpdateRule',
                __variables: {
                    ...(isEdit
                        ? {
                              payload: 'RuleSetInput',
                              id: 'Int!',
                          }
                        : {
                              payload: 'RuleInsertInput!',
                          }),
                },
                item: {
                    __aliasFor: isEdit ? 'updateRuleByPk' : 'insertRuleOne',
                    __args: {
                        ...(isEdit
                            ? {
                                  [pkColumnsKey]: { id: new VariableType('id') },
                                  _set: new VariableType('payload'),
                              }
                            : {
                                  object: new VariableType('payload'),
                              }),
                    },
                    id: true,
                },
            },
        },
        { pretty: true }
    );
};

const schema = object({
    name: string().required(dictionary.validation.required),
    keyRule: string().required(dictionary.validation.required),
});

const form: CreateOrUpdateProps<RulePayload> = {
    formClassName: 'grid-cols-1 md:!grid-cols-2',
    fields: [
        {
            type: 'text',
            name: 'name',
        },
        {
            type: 'text',
            name: 'keyRule',
        },
    ],
    schema,
};

const config: CrudPageProps<RulePayload>[] = [
    {
        graphql: {
            table: 'rule',
            searchableField: 'name',
            columns: [
                {
                    name: 'id',
                    options: { display: 'excluded' },
                },
                {
                    name: 'name',
                    label: dictionary.content.graphql['name'],
                },
                {
                    name: 'keyRule',
                    label: dictionary.content.graphql['keyRule'],
                },
                {
                    name: 'actions',
                    label: 'Ações',
                    options: {
                        customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />,
                    },
                },
            ],
        },
        title: 'Permissões',
    },
    {
        title: 'Nova permissão',
        graphql: {
            table: 'rule',
            query: createOrUpdateRuleMutation(),
        },
        defaultValues: {
            keyRule: '',
            name: '',
        },
        form,
    },
    {
        title: 'Atualizar permissão',
        graphql: {
            table: 'rule',
            query: createOrUpdateRuleMutation(true),
        },
        defaultValues: {
            keyRule: '',
            name: '',
        },
        form,
    },
];

export default config;
