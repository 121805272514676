import { useMutation } from 'react-query';
import api from 'services/api';
import { SalesNotePayload } from 'types/models/sales-notes';

type SalesNotesTaxesPayload = Pick<SalesNotePayload, 'invoiceValue' | 'nature' | 'deductionValue' | 'customer'> & {
    emissionDate: string;
    cityTax?: number;
    stateTax?: number;
};

export type SalesNotesTaxesResponse = {
    [K in keyof (Pick<SalesNotePayload, 'irpj' | 'irpjAdditional' | 'csll' | 'cofins' | 'pis' | 'iss' | 'icms'> & { inss: number })]: {
        editable: boolean;
        value: number;
        percentage: number;
    };
};

const useGetSalesNotesTaxes = (companyId?: number) => {
    const request = async (payload: SalesNotesTaxesPayload) => {
        const { data } = await api.post<SalesNotesTaxesResponse>(`/sales-notes/${companyId}/taxes`, payload);

        return data;
    };

    return useMutation(request);
};

export default useGetSalesNotesTaxes;
