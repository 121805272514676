import Tooltip from '@mui/material/Tooltip';

type ContractsReportTotalsCardHeadProps = {
    title: string;
    tooltipTitle?: string;
};

const ContractsReportTotalsCardHead = ({ title, tooltipTitle }: ContractsReportTotalsCardHeadProps) => {
    return (
        <div className="relative flex items-center justify-center gap-2">
            <p className="text-base-500 text-center xl:text-end text-sm font-normal">{title}</p>
            {Boolean(tooltipTitle) && (
                <Tooltip placement="top" title={tooltipTitle} disableFocusListener={true}>
                    <div className="flex items-center justify-center 0 w-6 h-6 cursor-pointer transition-all ease-in-out hover:bg-system-info-200 bg-opacity-40 rounded-full group">
                        <span className="text-system-info-500 font-medium group-hover:text-white">?</span>
                    </div>
                </Tooltip>
            )}
        </div>
    );
};

export default ContractsReportTotalsCardHead;
