import { useShowApiErrors } from 'hooks/error/show-api-errors';
import useToast from 'hooks/toast/use-toast';
import { useMutation } from 'react-query';
import api from 'services/api';
import { PurchaseProduct } from 'types/models/purchase-order';

type Response = Pick<PurchaseProduct, 'product' | 'quantity' | 'total' | 'provision' | 'value'>;

const useImportOSProducts = () => {
    const { showToast } = useToast();
    const { showToastErrors } = useShowApiErrors();

    const request = async (payload: File) => {
        const formData = new FormData();

        formData.append('file', payload);

        const { data } = await api.post<Response[]>(`/file-order-service-products`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });

        return data;
    };

    return useMutation(request, {
        onSuccess: () => {
            showToast('Produtos importados com sucesso', 'success');
        },
        onError: (error: any) => {
            showToastErrors(error, error?.response?.data?.ttile || 'Ocorreu um erro ao importar os produtos');
        },
    });
};

export default useImportOSProducts;
