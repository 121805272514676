import { useOutletContext, useParams } from 'react-router-dom';
import useCreateTransferBankAccount from 'services/queries/bank-accounts/use-create-transfer-bank-account';
import dayjs from 'dayjs';
import { CreateTransferBetweenAccountsPayload } from 'types/models/bank-accounts';
import CreateTransferBetweenAccounts from './create';
import omit from 'lodash/omit';
import useUploadFile from 'services/queries/files/use-upload-file';

type Context = {
    queryFilters: string;
    companyId: number;
    actualDatatablePage: number;
};

const CreateTransferBetweenAccountsPage = () => {
    const { bankAccountId } = useParams();

    const { queryFilters, companyId } = useOutletContext<Context>();

    const { uploadFile, isLoading: isUploading } = useUploadFile();
    const { mutateAsync: createBankAccountTransfer, isLoading: isCreating } = useCreateTransferBankAccount(queryFilters, bankAccountId);

    const isSubmitting = isUploading || isCreating;

    const handleSubmit = async (data: CreateTransferBetweenAccountsPayload) => {
        try {
            const file = await uploadFile(data.document);

            const payload = {
                ...omit(data, ['company', 'document']),
                ...(Boolean(data?.dueDate) && { dueDate: dayjs(data?.dueDate).format('YYYY-MM-DD') }),
                ...(!!file?.id && { documents: [{ file: file.id }] }),
            };

            await createBankAccountTransfer(payload);
        } catch (error) {
            console.log('handleSubmit', error);
        }
    };

    return <CreateTransferBetweenAccounts companyId={companyId} isSubmitting={isSubmitting} onSubmit={handleSubmit} />;
};

export default CreateTransferBetweenAccountsPage;
