import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import TableFooter from '@mui/material/TableFooter/TableFooter';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import ExternalButton from 'components/buttons/external';
import Text from 'components/core/text';
import { useMemo } from 'react';
import { StockProduct } from 'types/models/stock';
import { formatNumberToPTBR } from 'utils/money';

type ModalProductStocksTableProps = {
    stocks?: Partial<StockProduct>[];
};

const ModalProductStocksTable = ({ stocks = [] }: ModalProductStocksTableProps) => {
    const totalQuantity = useMemo(() => stocks.reduce((acc, curr) => acc + (curr.quantity || 0), 0), [stocks]);

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className="border-r border-r-base-300">Estoque</TableCell>
                        <TableCell className="text-right">Quantidade</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {stocks.map((item, index) => (
                        <TableRow key={index} sx={{ '&:last-child td': { borderBottom: 0 } }}>
                            <TableCell className="border-r border-r-base-300">
                                <ExternalButton href={`/app/estoques/${item.stock?.id}/produtos`}>{item.stock?.name}</ExternalButton>
                            </TableCell>
                            <TableCell className="text-right">{formatNumberToPTBR(item.quantity || 0)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter className="bg-base-200 border-t border-t-300">
                    <TableRow sx={{ '&:last-child td': { borderBottom: 0 } }}>
                        <TableCell colSpan={2}>
                            <Text className="text-base-500 text-right block">
                                Total:
                                <strong className="ml-1 text-secondary-500">{formatNumberToPTBR(totalQuantity)}</strong>
                            </Text>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
};

export default ModalProductStocksTable;
