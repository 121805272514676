import Badge from 'components/core/badge';
import DatatableRowColumn from 'components/core/table/datatable/components/column';
import { DatatableColumn } from 'types/graphql';
import { formatDate } from 'utils/date';
import { formatMoney } from 'utils/money';

export const monthReportOPsColumns: DatatableColumn[] = [
    {
        name: 'id',
        options: { display: 'excluded' },
    },
    {
        name: 'code',
        label: 'Código',
        options: {
            customBodyRender: (value, { rowData }) => {
                const billed = rowData.at(-1);

                if (!value) {
                    return '-';
                }

                return (
                    <>
                        <span>{value}</span>
                        {billed && <Badge variant="warning">Faturada</Badge>}
                    </>
                );
            },
        },
    },
    {
        name: 'netValue',
        label: 'Valor',
        options: { customBodyRender: (value = 0) => formatMoney(Number(value)) },
    },
    {
        name: 'expense.title',
        label: 'Descrição',
        options: {
            customBodyRender: (description, { rowData }) => {
                const installmentDescription = rowData.at(-2);

                return (
                    <DatatableRowColumn dataId={rowData[0]} isTruncatedColumn={false}>
                        {description}
                        <br />
                        {installmentDescription}
                    </DatatableRowColumn>
                );
            },
        },
    },
    {
        name: 'payday',
        label: 'Pagamento',
        options: {
            customBodyRender: (value) => formatDate(value),
        },
    },
    {
        name: 'expense.sector.name',
        label: 'Setor',
        options: { customBodyRender: (value) => value || '-' },
    },
    {
        name: 'expense.category.name',
        label: 'Categoria',
        options: { customBodyRender: (value) => <span className="whitespace-nowrap">{value || '-'}</span> },
    },
    {
        name: 'expense.classificationOrigin.name',
        label: 'Origem',
        options: { customBodyRender: (value) => <span className="whitespace-nowrap">{value || '-'}</span> },
    },
    {
        name: 'expense.classificationSubOrigin.name',
        label: 'Sub-origem',
        options: { customBodyRender: (value) => <span className="whitespace-nowrap">{value || '-'}</span> },
    },
    {
        name: 'installmentDescription',
        options: { display: 'excluded' },
    },
    {
        name: 'expense.billed',
        options: { display: 'excluded' },
    },
];

export const monthReportLegacyColumns: DatatableColumn[] = [
    {
        name: 'id',
        options: { display: 'excluded' },
    },
    {
        name: 'code',
        label: 'Código',
    },
    {
        name: 'netValue',
        label: 'Valor',
        options: {
            customBodyRender: (value) => formatMoney(Number(value)),
        },
    },
    {
        name: 'expense',
        label: 'Despesa',
        options: {
            customBodyRender: (value) => (
                <span className="text-one-line">
                    {value.title} (#{value.code})
                </span>
            ),
        },
    },
    {
        name: 'payday',
        label: 'Pagamento',
        options: { customBodyRender: (value) => formatDate(value) },
    },
    {
        name: 'sector.title',
        label: 'Setor',
        options: { customBodyRender: (value) => <span className="text-one-line">{value}</span> },
    },
    {
        name: 'category.title',
        label: 'Categoria',
        options: { customBodyRender: (value) => <span className="text-one-line">{value}</span> },
    },
    {
        name: 'origin.title',
        label: 'Origem',
        options: { customBodyRender: (value) => <span className="text-one-line">{value}</span> },
    },
    {
        name: 'subOrigin.title',
        label: 'Sub-origem',
        options: { customBodyRender: (value) => <span className="text-one-line">{value}</span> },
    },
    {
        name: 'dueDate',
        label: 'Vencimento',
        options: { customBodyRender: (value) => formatDate(value) },
    },
    {
        name: 'provision',
        label: 'Previsão',
        options: { customBodyRender: (value) => formatDate(value) },
    },
];
