import Badge from 'components/core/badge';
import { formatMoney } from 'utils/money';
import { formatDate } from 'utils/date';
import { SalesReceiptResponse } from 'types/models/sales-notes';
import { SalesNotePayload, SalesReceiptStatus } from 'types/models/sales-notes';
import dictionary from 'utils/dictionary';
import { array, boolean, mixed, number, object, string } from 'yup';
import { isEmpty, pick } from 'lodash';
import ContentWithheld from './components/content-withheld';
import { CompanyTaxRegime } from 'types/models/company';
import { SumsTaxes, WithheldsSumsTaxes } from 'services/queries/sales-notes/use-get-sales-receipts-sums';
import { File as FileApiModel } from 'types/models/file';
import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';
import { Status } from 'types/general';
import { CrudPageProps, WhereClause } from 'types/graphql';
import { convertArrayToObject } from 'utils/array';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import Text from 'components/core/text';
import TableCell from '@mui/material/TableCell/TableCell';
import { SaleOrderOrigin, SaleOrderStatus } from 'types/models/sale-order';
import { removeFalsyValues } from 'utils/object';
import { SalesNotesTaxesResponse } from 'services/queries/sales-notes/use-get-sales-notes-taxes';

const taxesKeysGraphql = ['das', 'cofins', 'cpp', 'csll', 'cofins', 'irpj', 'irpjAdditional', 'inss', 'iss', 'icms', 'pis'];

export const SaleOrderLiteral = {
    [SaleOrderOrigin.sale]: 'Venda',
    [SaleOrderOrigin.service]: 'Serviço',
};

export const statusLiterals = new Map([
    [SalesReceiptStatus.Initialized, { variant: 'info', label: 'Iniciado' }],
    [SalesReceiptStatus.Cancelled, { variant: 'error', label: 'Cancelado' }],
    [SalesReceiptStatus.Processing, { variant: 'warning', label: 'Processando' }],
    [SalesReceiptStatus.Stopped, { variant: 'error', label: 'Parado' }],
    [SalesReceiptStatus.Waiting, { variant: 'grey', label: 'Aguardando' }],
    [SalesReceiptStatus.Finalized, { variant: 'success', label: 'Finalizado' }],
    [SalesReceiptStatus.NotFinalized, { variant: 'info', label: 'Não finalizado' }],
]);

export const saleOrderStatusMap = new Map([
    [SaleOrderStatus.InContract, { variant: 'info' }],
    [SaleOrderStatus.ContractDelivered, { variant: 'success' }],
    [SaleOrderStatus.AwaitingDelivery, { variant: 'warning' }],
    [SaleOrderStatus.InformedPayment, { variant: 'info' }],
    [SaleOrderStatus.AwaitingPayment, { variant: 'warning' }],
    [SaleOrderStatus.Delivered, { variant: 'success' }],
    [SaleOrderStatus.Finalized, { variant: 'success' }],
    [SaleOrderStatus.Canceled, { variant: 'error' }],
    [SaleOrderStatus.InstallationInProgress, { variant: 'info' }],
    [SaleOrderStatus.AwaitingDelivery, { variant: 'warning' }],
]);

export const schema = (companyTaxRegime: CompanyTaxRegime, origin: SaleOrderOrigin | null) =>
    object({
        company: object({
            id: number().required(dictionary.validation.required),
            taxRegime: number(),
        }).required(dictionary.validation.required),
        number: string().required(dictionary.validation.required),
        invoiceValue: number().required(dictionary.validation.required),
        transactions: array().min(1, dictionary.validation.array.min(1)).required(dictionary.validation.required),
        salesOrders: array(object({ value: number().required(dictionary.validation.required), label: string().required(dictionary.validation.required) }))
            .min(1, dictionary.validation.array.min(1))
            .required(dictionary.validation.required),
        deductionValue: number().required(dictionary.validation.required),
        deductionValues: array(object({ deductionValue: number().required(dictionary.validation.required) })),
        emission: string().required(dictionary.validation.required),
        nature: number().required(dictionary.validation.required),
        withImportedProducts: boolean().default(false),
        file: mixed()
            .required(dictionary.validation.required)
            .test('fileName', 'File not found', (value: Partial<File & FileApiModel>) => !!value?.name || !!value?.originalName),

        ...(Boolean(companyTaxRegime === CompanyTaxRegime.Simple) && {
            das: number().required(dictionary.validation.required),
            simpleInss: number().required(dictionary.validation.required),
        }),
        ...(Boolean(companyTaxRegime === CompanyTaxRegime.PresumedProfit) && {
            irpj: number().required(dictionary.validation.required),
            irpjAdditional: number().required(dictionary.validation.required),
            csll: number().required(dictionary.validation.required),
            cofins: number().required(dictionary.validation.required),
            pis: number().required(dictionary.validation.required),
            presumedInss: number().required(dictionary.validation.required),
            icms: number().required(dictionary.validation.required),
        }),
        ...(origin === SaleOrderOrigin.service && {
            orderServiceNumber: string().required(dictionary.validation.required),
        }),
    });

export const initialValues: Partial<SalesNotePayload> = {
    das: 0,
    dasAliquot: 0,
    cofins: 0,
    csll: 0,
    irpjAdditional: 0,
    icms: 0,
    simpleInss: 0,
    presumedInss: 0,
    irpj: 0,
    iss: 0,
    pis: 0,
    cofinsWithheld: false,
    csllWithheld: false,
    irpjAdditionalWithheld: false,
    icmsWithheld: false,
    inssWithheld: false,
    irpjWithheld: false,
    issWithheld: false,
    pisWithheld: false,
    cppWithheld: false,
    orderServiceNumber: '',
    number: '',
    deductionValues: [],
    withImportedProducts: false,
};

export const calculationBasisIrpjAdditional = 8;
export const irpjAdditionalPercentage = 10;

const generateTaxesKeys = (data?: SalesReceiptResponse, isSaleNotesType = false) => {
    const isPresumedProfit = data?.company.taxRegime === CompanyTaxRegime.PresumedProfit;

    const presumedKeys = ['irpj', 'irpjAdditional', 'csll', 'cofins', 'pis', ...(isSaleNotesType ? ['inss', 'iss'] : ['icms'])];
    const simpleKeys = ['das', 'inss'];

    return isPresumedProfit ? presumedKeys : simpleKeys;
};

const generateTaxContentByCompanyTaxRegime = (data?: SalesReceiptResponse, isSaleNotesType = false) => {
    const isPresumedProfit = data?.company.taxRegime === CompanyTaxRegime.PresumedProfit;
    const taxesKeys = generateTaxesKeys(data, isSaleNotesType);

    const taxesEntries = Object.entries(pick(data, taxesKeys));

    const totalTaxes = taxesEntries.reduce((acc, [key, value]) => {
        const keyWithheld = key + 'Withheld';

        if (!!data?.[keyWithheld]) {
            acc += 0;
        } else {
            acc += value;
        }

        return acc;
    }, 0);

    const presumedArr = ['IRPJ', 'IRPJ Adicional', 'CSLL', 'COFINS', 'PIS', 'INSS', 'ISS', 'ICMS', 'Total'];
    const simpleArr = ['DAS Alíquota %', 'DAS', 'INSS', 'Total'];

    const presumedContentArr = [
        <ContentWithheld data={data?.irpj} isWithheld={!!data?.irpjWithheld} key={1} />,
        <ContentWithheld data={data?.irpjAdditional} isWithheld={!!data?.irpjAdditionalWithheld} key={2} />,
        <ContentWithheld data={data?.csll} isWithheld={!!data?.csllWithheld} key={3} />,
        <ContentWithheld data={data?.cofins} isWithheld={!!data?.cofinsWithheld} key={4} />,
        <ContentWithheld data={data?.pis} isWithheld={!!data?.pisWithheld} key={5} />,
        <ContentWithheld data={data?.iss} isWithheld={!!data?.issWithheld} key={6} />,
        <ContentWithheld data={data?.inss} isWithheld={!!data?.inssWithheld} key={7} />,
        <ContentWithheld data={data?.icms} isWithheld={!!data?.icmsWithheld} key={8} />,
    ];

    const simpleContentArr = [
        <ContentWithheld data={data?.dasAliquot} isWithheld={false} key={1} />,
        <ContentWithheld data={data?.das} isWithheld={false} key={2} />,
        <ContentWithheld data={data?.inss} isWithheld={!!data?.inssWithheld} key={3} />,
    ];

    const contentArr = isPresumedProfit ? presumedContentArr : simpleContentArr;

    return {
        headTable: isPresumedProfit ? presumedArr : simpleArr,
        bodyTable: [[...contentArr, formatMoney(totalTaxes || 0)]],
    };
};

export const accordionsData = (data?: SalesReceiptResponse) => {
    const isSaleNotesType = data?.nature === SaleOrderOrigin.sale;

    const { bodyTable = [], headTable = [] } = generateTaxContentByCompanyTaxRegime(data, isSaleNotesType);

    return [
        {
            title: isSaleNotesType ? 'Pedidos de venda' : 'Ordens de serviço',
            content: {
                headTable: ['Código', 'Empresa', 'Cliente', ...(!isSaleNotesType ? ['Obra (Apelido)'] : []), 'Total (R$)', 'Dedução (R$)'],
                bodyTable: data?.salesOrders.map((item, index) => [
                    <span key={index} className="whitespace-nowrap">
                        {item.orderService?.code || item.saleOrder?.code || '-'}
                    </span>,
                    <span className="whitespace-nowrap" key={index}>
                        {data?.company?.name || '-'}
                    </span>,
                    <span className="whitespace-nowrap" key={index}>
                        {item.orderService?.saleOrder?.customer?.title || item.saleOrder?.customer?.title || '-'}
                    </span>,
                    ...(!isSaleNotesType
                        ? [
                              <span className="whitespace-nowrap" key={index}>
                                  {item.orderService?.title || '-'}
                              </span>,
                          ]
                        : []),
                    formatMoney(item.orderService?.saleOrder?.netValue || item.saleOrder?.netValue),
                    formatMoney(+item.deductionValue),
                ]),
            },
        },
        {
            title: 'Ordens de Recebimentos',
            content: {
                headTable: ['Código', 'Parcela', 'Vencimento', 'Forma de Pagamento', 'Pago', 'Total'],
                bodyTable: data?.transactions?.map((item, index) => [
                    item.code || '-',
                    item.description || '-',
                    formatDate(item.dueDate, 'DD/MM/YYYY') || '-',
                    item.paymentForm?.name || '-',
                    <Badge key={index} variant={!!item.paid ? 'success' : ('error' as any)}>
                        {!!item.paid ? 'Sim' : 'Não'}
                    </Badge>,
                    formatMoney(item.netValue),
                ]),
            },
        },
        {
            title: 'Impostos',
            content: {
                headTable,
                bodyTable,
            },
        },
    ];
};

type TotalCardsProp = {
    sums?: SumsTaxes;
    withhelds?: WithheldsSumsTaxes[];
};

export const totalsCards = (data: TotalCardsProp) => {
    const taxesWithheldGraphql = ['issWithheld', 'inssWithheld'];

    const withhelds = (data?.withhelds || [])?.reduce(
        (acc, item) => {
            const onlyWithhelds = pick(item, taxesWithheldGraphql);
            const withheldsEntries = Object.entries(onlyWithhelds);

            withheldsEntries.forEach(([key, value]) => {
                const taxWithheldName = key.replace('Withheld', '');

                acc[taxWithheldName] = (acc[taxWithheldName] || 0) + (value ? item[taxWithheldName] || 0 : 0);
            });

            return acc;
        },
        {
            inss: 0,
            iss: 0,
        }
    );

    const onlyTaxes = pick(data?.sums, taxesKeysGraphql);

    const taxesEntries = Object.values(onlyTaxes);
    const totalTaxes = taxesEntries.reduce((acc, value) => (acc += value as number), 0);

    return {
        totals: [
            {
                label: 'Valor total',
                value: data?.sums?.invoiceValue || 0,
            },
            {
                label: 'Valor Total Dedução',
                value: data?.sums?.deductionValue || 0,
            },
            {
                label: 'Total Impostos',
                value: totalTaxes || 0,
            },
        ],
        taxes: [
            {
                label: 'DAS',
                value: data?.sums?.das || 0,
                percentage: data?.sums?.invoiceValue ? (data?.sums?.das / data?.sums?.invoiceValue) * 100 : 0,
            },
            {
                label: 'IRPJ',
                value: data?.sums?.irpj || 0,
                percentage: data?.sums?.invoiceValue ? (data?.sums?.irpj / data?.sums?.invoiceValue) * 100 : 0,
            },
            {
                label: 'IRPJ Adicional',
                value: data?.sums?.irpjAdditional || 0,
                percentage: data?.sums?.invoiceValue ? (data?.sums?.irpjAdditional / data?.sums?.invoiceValue) * 100 : 0,
            },
            {
                label: 'CSLL',
                value: data?.sums?.csll || 0,
                percentage: data?.sums?.invoiceValue ? (data?.sums?.csll / data?.sums?.invoiceValue) * 100 : 0,
            },
            {
                label: 'COFINS',
                value: data?.sums?.cofins || 0,
                percentage: data?.sums?.invoiceValue ? (data?.sums?.cofins / data?.sums?.invoiceValue) * 100 : 0,
            },
            {
                label: 'PIS',
                value: data?.sums?.pis || 0,
                percentage: data?.sums?.invoiceValue ? (data?.sums?.pis / data?.sums?.invoiceValue) * 100 : 0,
            },
            {
                label: 'INSS',
                value: (data?.sums?.inss || 0) - withhelds.inss || 0,
                percentage: data?.sums?.invoiceValue ? (data?.sums?.inss / data?.sums?.invoiceValue) * 100 : 0,
            },
            {
                label: 'CPP',
                value: data?.sums?.cpp || 0,
                percentage: data?.sums?.invoiceValue ? (data?.sums?.cpp / data?.sums?.invoiceValue) * 100 : 0,
            },
            {
                label: 'ISS',
                value: (data?.sums?.iss || 0) - withhelds.iss || 0,
                percentage: data?.sums?.invoiceValue ? (data?.sums?.iss / data?.sums?.invoiceValue) * 100 : 0,
            },
            {
                label: 'ICMS',
                value: data?.sums?.icms || 0,
                percentage: data?.sums?.invoiceValue ? (data?.sums?.icms / data?.sums?.invoiceValue) * 100 : 0,
            },
        ],
        withhelds: [
            {
                label: 'ISS Retido',
                value: withhelds.iss || 0,
                percentage: data?.sums?.invoiceValue ? (withhelds.iss / data?.sums?.invoiceValue) * 100 : 0,
            },
            {
                label: 'INSS Retido',
                value: withhelds.inss || 0,
                percentage: data?.sums?.invoiceValue ? (withhelds.inss / data?.sums?.invoiceValue) * 100 : 0,
            },
        ],
    };
};

export const taxesPercentages = {
    irpj: 1.2,
    csll: 1.08,
    cofins: 3.0,
    pis: 0.65,
    inss: 11,
    issRj: 3,
    issPe: 5,
    icmsRj: 20,
    icmsPe: 18,
};

export const citiesOptions = [
    { label: 'Rio de Janeiro', value: 1 },
    { label: 'Recife', value: 2 },
    { label: 'Outra', value: 3 },
];

export const fixedDiscountIRS = 60000;

export const getAndSetValue = (values: any, setValue: any) => (key: keyof SalesNotePayload, tax?: number) => {
    if (tax) {
        return setValue(key, tax);
    }

    const valueTaxes = (values?.invoiceValue * taxesPercentages[key]) / 100;

    setValue(key, valueTaxes > 0 ? valueTaxes : initialValues[key]);
};

export const resetTaxesFields = (setValue: any) => {
    const taxesKeys: Partial<keyof SalesNotePayload>[] = ['irpj', 'irpjAdditional', 'csll', 'cofins', 'pis', 'presumedInss', 'iss', 'icms', 'simpleInss', 'das', 'dasAliquot'];

    taxesKeys.forEach((key) => {
        if (!!key) {
            setValue(key, initialValues[key]);
        }
    });
};

export const withheldsResetObject = {
    cofinsWithheld: false,
    csllWithheld: false,
    irpjAdditionalWithheld: false,
    icmsWithheld: false,
    inssWithheld: false,
    irpjWithheld: false,
    issWithheld: false,
    pisWithheld: false,
    cppWithheld: false,
};

export const generateCompanies = (fields: string[], where?: WhereClause<Partial<unknown>>['where']) => {
    if (!fields.length) {
        throw new Error('Missing query fields');
    }

    const fieldsToGraphql = convertArrayToObject(fields);

    return jsonToGraphQLQuery(
        {
            query: {
                __name: 'GetCompaniesWithTaxRegime',
                company: {
                    __aliasFor: 'company',
                    __args: {
                        orderBy: { name: new EnumType('ASC') },
                        where: {
                            status: { _eq: Status.Active },
                            ...(Boolean(where) && where),
                        },
                    },
                    ...fieldsToGraphql,
                },
            },
        },
        { pretty: true }
    );
};

export const salesNotesFiltersWhere = (filters: any) => {
    let salesNotesFilters = {};

    if (!!Object.keys(filters || {})?.length) {
        const { initialPeriodStartDate, initialPeriodEndDate, company, issWithheld, nature, finalized } = filters || {};

        salesNotesFilters = {
            ...(Boolean(filters?.company) && { company: { id: { _eq: company } } }),
            ...(Boolean(filters?.finalized) && { finalized: { _eq: finalized } }),
            ...(Boolean(filters?.issWithheld) && { issWithheld: { _eq: issWithheld } }),
            ...(Boolean(filters?.nature) && { nature: { _eq: nature } }),
            ...(Boolean(filters?.initialPeriodEndDate || filters?.initialPeriodStartDate) && {
                emission: {
                    _gte: initialPeriodStartDate,
                    _lte: initialPeriodEndDate,
                },
            }),
        };
    }

    return Object.values(salesNotesFilters).length ? salesNotesFilters : {};
};

export const salesNoteListConfig = (where: {}): CrudPageProps<{}> => {
    return {
        title: '',
        graphql: {
            table: 'salesReceipt',
            searchableField: 'number',
            columns: [
                {
                    name: 'id',
                    options: { display: 'excluded' },
                },
                {
                    name: 'number',
                    label: 'Número NF',
                    options: {
                        customBodyRender: (value) => value || '-',
                    },
                },
                {
                    name: 'invoiceValue',
                    label: 'Total Nota',
                    options: {
                        customBodyRender: (value) => {
                            return formatMoney(value);
                        },
                    },
                },
                {
                    name: 'orderServiceNumber',
                    label: 'Código da Obra',
                    options: {
                        customBodyRender: (value) => value || '-',
                    },
                },
                {
                    name: 'company.name',
                    label: 'Empresa',
                    options: {
                        customBodyRender: (value) => <span className="whitespace-nowrap">{value || '-'}</span>,
                    },
                },
                {
                    name: 'salesReceiptOrders.orderService.title',
                    label: 'Ordens de serviço',
                    options: {
                        customBodyRender: (_, tableMeta) => {
                            const orders = removeFalsyValues(tableMeta?.rowData.at(-1)?.props?.item?.salesReceiptOrders);

                            const isEmptyOrders = orders.every((item) => isEmpty(item));

                            return !isEmptyOrders ? (
                                <Tooltip
                                    enterTouchDelay={700}
                                    title={
                                        <div>
                                            <Text as="strong" variant="body.medium.2xs">
                                                Ordens de serviço:{' '}
                                            </Text>
                                            <div className="max-h-[100px] overflow-auto">
                                                {orders?.map((item, index) => (
                                                    <Text as="p" variant="body.regular.sm" key={index}>
                                                        - {item?.orderService?.title}
                                                    </Text>
                                                ))}
                                            </div>
                                        </div>
                                    }
                                    placement="top">
                                    <span className="max-w-[200px] overflow-hidden">
                                        <Text as="p" className="text-ellipsis max-w-[150px] overflow-hidden inline-block">
                                            {orders?.map((item, index) => {
                                                return orders.length > 1 && orders.length - 1 !== index ? item?.orderService?.title + ', ' : item?.orderService?.title;
                                            })}
                                        </Text>
                                    </span>
                                </Tooltip>
                            ) : (
                                <span>-</span>
                            );
                        },
                    },
                },
                {
                    name: 'nature',
                    label: 'Tipo',
                    options: {
                        customBodyRender: (value) => {
                            return SaleOrderLiteral[value] ?? '';
                        },
                    },
                },
                {
                    name: 'emission',
                    label: 'DT Emissão',
                    options: {
                        customBodyRender: (value) => {
                            return formatDate(value, 'DD/MM/YYYY');
                        },
                    },
                },
                {
                    name: 'deductionValue',
                    label: 'Dedução',
                    options: {
                        customBodyRender: (value) => {
                            return formatMoney(value);
                        },
                    },
                },
                {
                    name: 'das',
                    label: 'DAS',
                    options: {
                        customBodyRender: (value) => {
                            return formatMoney(value);
                        },
                    },
                },
                {
                    name: 'irpj',
                    label: 'IRPJ',
                    options: {
                        customBodyRender: (value, tableMeta) => {
                            const isIRPJWithheld = tableMeta?.rowData.at(-1)?.props?.item?.irpjWithheld;
                            return <ContentWithheld data={value || 0} isWithheld={!!isIRPJWithheld} />;
                        },
                    },
                },
                {
                    name: 'irpjAdditional',
                    label: 'IRPJ Adicional',
                    options: {
                        customBodyRender: (value, tableMeta) => {
                            const isIRPJAdditionalWithheld = tableMeta?.rowData.at(-1)?.props?.item?.irpjAdditionalWithheld;
                            return <ContentWithheld data={value || 0} isWithheld={!!isIRPJAdditionalWithheld} />;
                        },
                    },
                },
                {
                    name: 'csll',
                    label: 'CSLL',
                    options: {
                        customBodyRender: (value, tableMeta) => {
                            const isCSLLWithheld = tableMeta?.rowData.at(-1)?.props?.item?.csllWithheld;
                            return <ContentWithheld data={value || 0} isWithheld={!!isCSLLWithheld} />;
                        },
                    },
                },
                {
                    name: 'cofins',
                    label: 'COFINS',
                    options: {
                        customBodyRender: (value, tableMeta) => {
                            const isCOFINSWithheld = tableMeta?.rowData.at(-1)?.props?.item?.cofinsWithheld;
                            return <ContentWithheld data={value || 0} isWithheld={!!isCOFINSWithheld} />;
                        },
                    },
                },
                {
                    name: 'pis',
                    label: 'PIS',
                    options: {
                        customBodyRender: (value, tableMeta) => {
                            const isPISWithheld = tableMeta?.rowData.at(-1)?.props?.item?.pisWithheld;
                            return <ContentWithheld data={value || 0} isWithheld={!!isPISWithheld} />;
                        },
                    },
                },
                {
                    name: 'inss',
                    label: 'INSS',
                    options: {
                        customBodyRender: (value, tableMeta) => {
                            const isINSSWithheld = tableMeta?.rowData.at(-1)?.props?.item?.inssWithheld;
                            return <ContentWithheld data={value || 0} isWithheld={!!isINSSWithheld} />;
                        },
                    },
                },
                {
                    name: 'cpp',
                    label: 'CPP (INSS)',
                    options: {
                        customBodyRender: (value, tableMeta) => {
                            const isCPPWithheld = tableMeta?.rowData.at(-1)?.props?.item?.cppWithheld;
                            return <ContentWithheld data={value || 0} isWithheld={!!isCPPWithheld} />;
                        },
                    },
                },
                {
                    name: 'iss',
                    label: 'ISS',
                    options: {
                        customBodyRender: (value, tableMeta) => {
                            const isISSWithheld = tableMeta?.rowData.at(-1)?.props?.item?.issWithheld;
                            return <ContentWithheld data={value || 0} isWithheld={!!isISSWithheld} />;
                        },
                    },
                },
                {
                    name: 'icms',
                    label: 'ICMS',
                    options: {
                        customBodyRender: (value, tableMeta) => {
                            const isICMSWithheld = tableMeta?.rowData.at(-1)?.props?.item?.icmsWithheld;
                            return <ContentWithheld data={value || 0} isWithheld={!!isICMSWithheld} />;
                        },
                    },
                },
                {
                    name: '',
                    label: 'Total Impostos',
                    options: {
                        customBodyRender: (_, tableMeta) => {
                            const rowData = tableMeta?.rowData.at(-1)?.props?.item;

                            const onlyTaxesKeysDataObject = pick(rowData, taxesKeysGraphql);

                            const taxesEntries = Object.entries(onlyTaxesKeysDataObject);

                            const totalsTaxes = taxesEntries.reduce((acc, [key, value]) => {
                                const keyWithheld = key + 'Withheld';

                                if (!!rowData?.[keyWithheld]) {
                                    acc += 0;
                                } else {
                                    acc += value;
                                }

                                return acc;
                            }, 0);

                            return formatMoney(totalsTaxes || 0);
                        },
                    },
                },
                {
                    name: 'finalized',
                    label: 'Status',
                    options: {
                        customBodyRender: (value) => {
                            const statusLabelColor = value ? SalesReceiptStatus.Finalized : SalesReceiptStatus.NotFinalized;

                            return (
                                <Badge className="text-center" variant={(statusLiterals.get(statusLabelColor)?.variant || 'grey') as any}>
                                    {statusLiterals.get(statusLabelColor)?.label}
                                </Badge>
                            );
                        },
                    },
                },
                {
                    name: 'irpjWithheld',
                    options: { display: 'excluded' },
                },
                {
                    name: 'irpjAdditionalWithheld',
                    options: { display: 'excluded' },
                },
                {
                    name: 'csllWithheld',
                    options: { display: 'excluded' },
                },
                {
                    name: 'cofinsWithheld',
                    options: { display: 'excluded' },
                },
                {
                    name: 'inssWithheld',
                    options: { display: 'excluded' },
                },
                {
                    name: 'pisWithheld',
                    options: { display: 'excluded' },
                },
                {
                    name: 'cppWithheld',
                    options: { display: 'excluded' },
                },
                {
                    name: 'issWithheld',
                    options: { display: 'excluded' },
                },
                {
                    name: 'icmsWithheld',
                    options: { display: 'excluded' },
                },
                {
                    name: 'actions',
                    label: 'Ações',
                    options: {
                        customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />,
                    },
                },
            ],
            orderBy: { createdAt: 'DESC' },
            where,
        },
        api: {
            useApiTodelete: true,
            endpoint: '/sales-notes',
        },
    };
};

export const verifyIsTaxFieldDisabled = (taxes?: SalesNotesTaxesResponse) => (key: keyof SalesNotesTaxesResponse) => {
    return !taxes?.[key]?.editable;
};
