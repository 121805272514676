import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';
import Badge from 'components/core/badge';
import dayjs from 'dayjs';
import { number, object, string } from 'yup';
import { CompanyBankAccount } from 'types/models/company';
import { Transaction } from 'types/models/transaction';
import { DatatableColumn } from 'types/graphql';
import { formatMoney } from 'utils/money';
import { formatDate } from 'utils/date';
import TableCell from '@mui/material/TableCell/TableCell';
import dictionary from 'utils/dictionary';
import { ColorVariant } from 'types/colors';

export const favoredBankAccountsKeys = (favoredId?: number) => ['personBankAccount', favoredId, 'options'];
export const companyBankAccountsKeys = ['providerBankAccount', 'options'];

export const getFavoredBankAccountsQuery = (isPersonProvider: boolean, favoredId?: number) => {
    const whereKey = isPersonProvider ? 'personId' : 'providerId';

    return jsonToGraphQLQuery(
        {
            query: {
                __name: 'GetPersonBankAccountsOptions',
                items: {
                    __aliasFor: isPersonProvider ? 'personBankAccount' : 'providerBankAccount',
                    id: true,
                    bankAccount: { name: true },
                    __args: {
                        orderBy: {
                            bankAccount: { name: new EnumType('ASC') },
                        },
                        where: {
                            [whereKey]: { _eq: favoredId },
                        },
                    },
                },
            },
        },
        { pretty: true }
    );
};

export const getCompanyBankAccountsQuery = jsonToGraphQLQuery(
    {
        query: {
            __name: 'GetCompanyBankAccountsOptions',
            items: {
                __aliasFor: 'companyBankAccount',
                id: true,
                bankAccount: { name: true },
                __args: {
                    orderBy: {
                        bankAccount: { name: new EnumType('ASC') },
                    },
                },
            },
        },
    },
    { pretty: true }
);

export const formatBankAccountResponse = (data: Pick<CompanyBankAccount, 'id' | 'bankAccount'>[] = []) => data.map(({ id, bankAccount }) => ({ value: id, label: bankAccount?.name || '-' }));

export const getExpenseTransactionStatusTextAndVariant = ({ paid, payday = '', dueDate = '' }: Pick<Transaction, 'paid' | 'dueDate' | 'payday'>) => {
    const [date] = dueDate.split('T') || '';

    const today = dayjs().startOf('day');
    const dueDateTime = dayjs(date).startOf('day').utc(false).toDate();

    const isSameDay = today.isSame(dueDateTime, 'day');
    const isAfter = today.isAfter(dueDateTime);
    const isBefore = today.isBefore(dueDateTime);

    if (paid) {
        return { variant: 'success', text: 'Pago' };
    }

    if (Boolean(payday)) {
        return { variant: 'warning', text: 'Pagamento informado - enviar documento' };
    }

    if (isSameDay) {
        return { variant: 'warning', text: 'Realizar Pagamento' };
    }

    if (isAfter) {
        return { variant: 'error', text: 'Atrasado' };
    }

    if (isBefore) {
        return { variant: 'info', text: 'Aguardando Pagamento' };
    }

    return { variant: null, text: '-' };
};

export const generateExpenseTransactionStatus = (params: Pick<Transaction, 'paid' | 'dueDate' | 'payday'>) => {
    const { variant, text } = getExpenseTransactionStatusTextAndVariant(params);

    if (!variant) {
        return null;
    }
    return <Badge variant={variant as ColorVariant}>{text}</Badge>;
};

export const expenseTransactionSchema = object({
    companyBankAccountId: number().optional(),
    classificationPaymentFormId: number().optional(),
    favoredBankAccountId: number().optional(),
    barCode: string().optional(),
    fiscalDocument: string().optional(),
    dueDate: string().required(dictionary.validation.required),
});

export const expenseTransactionsColumns = (decimalPlaces = 2): DatatableColumn[] => [
    {
        name: 'code',
        label: 'Código',
        options: {
            customBodyRender: (value) => value || '-',
        },
    },
    {
        name: 'description',
        label: 'Título',
        options: {
            customBodyRender: (value) => <span className="whitespace-nowrap"> {value || '-'}</span>,
        },
    },
    {
        name: 'grossValue',
        label: 'Valor',
        options: {
            customBodyRender: (value = 0) => formatMoney(value, undefined, decimalPlaces),
        },
    },
    {
        name: 'paymentForecast',
        label: 'Previsão de pagamento',
        options: {
            customBodyRender: (paymentForecast) => formatDate(paymentForecast),
        },
    },
    {
        name: 'dueDate',
        label: 'Vencimento',
        options: {
            customBodyRender: (dueDate?: string) => formatDate(dueDate),
        },
    },
    {
        name: 'netValue',
        label: 'Valor final',
        options: {
            customBodyRender: (value) => formatMoney(value, undefined, decimalPlaces),
        },
    },
    {
        name: 'paid',
        label: 'Status',
        options: {
            customBodyRender: (paid, { rowData }) => generateExpenseTransactionStatus({ paid, payday: rowData[7], dueDate: rowData[4] }),
        },
    },
    {
        name: 'payday',
        options: { display: 'excluded' },
    },
    {
        name: 'actions',
        customRoutePath: (item) => item?.id?.toString(),
        options: {
            customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />,
        },
    },
];

export const getNumberValueOrZero = (value: unknown) => {
    const newValue = Number(value);
    if (Number.isNaN(newValue) || !newValue || !isFinite(newValue)) {
        return 0;
    }

    return newValue;
};
