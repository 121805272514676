import DocumentsForm, { DocumentPayload } from 'components/documents';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useCreateOrUpdateExpenseDocument from 'services/queries/expenses/use-create-or-update-expense-document';
import useGetExpenseDocument from 'services/queries/expenses/use-get-expense-document';
import { ClassificationType } from 'types/models/classification';

const ExpenseDocumentsForm = () => {
    const { documentId, expenseId } = useParams();

    const title = !!documentId ? 'Editar documento' : 'Novo documento';

    const { isLoading, mutate } = useCreateOrUpdateExpenseDocument(expenseId, documentId);
    const { isLoading: isBuilding, data: document } = useGetExpenseDocument({ documentId, expenseId });

    const defaultValues = useMemo<Partial<DocumentPayload>>(
        () => ({
            file: document?.document?.file,
            classification: document?.document?.classificationId,
            name: document?.document?.name,
            description: document?.document?.description,
            observation: document?.document?.observation,
        }),
        [document]
    );

    const onSubmit = (data: any) => {
        mutate(data);
    };

    return (
        <DocumentsForm classificationType={ClassificationType.DocumentTransaction} defaultValues={defaultValues} title={title} onSubmit={onSubmit} isSubmitting={isLoading} isBuilding={isBuilding} />
    );
};

export default ExpenseDocumentsForm;
