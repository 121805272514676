import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from '@mui/material/TableRow/TableRow';
import RemoveButton from 'components/buttons/remove';
import Text from 'components/core/text';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import CurrencyInput from 'components/core/form/currency';
import classNames from 'classnames';
import { formatMoney, formatNumberToPTBR } from 'utils/money';
import { RefundStockProductPayload } from 'services/queries/stocks/use-create-stock-product-refund';

type StockRefundProductRowProps = {
    index: number;
    item: RefundStockProductPayload['products'][0];
    onRemove: (index: number) => void;
};

const StockRefundProductRow = ({ index, item, onRemove }: StockRefundProductRowProps) => {
    const { formState, control } = useFormContext<RefundStockProductPayload>();

    const getPath = (key: keyof RefundStockProductPayload['products'][0]) => `products.${index}.${key}` as const;

    const handleRemove = () => onRemove(index);

    const quantity = useWatch({ control, name: getPath('quantity'), defaultValue: 0 }) as number;
    const value = useWatch({ control, name: getPath('productValue'), defaultValue: 0 }) as number;

    const quantityError = formState.errors.products?.[index]?.quantity?.message;

    return (
        <TableRow className={classNames(quantityError && 'bg-system-danger-100 bg-opacity-20')}>
            <TableCell className="p-0 px-4 border-r border-r-base-300 sm:min-w-[400px]">
                <Text className="text-heading whitespace-nowrap text-ellipsis overflow-hidden">{item.label}</Text>
            </TableCell>
            <TableCell className="p-0 pl-4 border-r border-r-base-300 sm:min-w-[150px]">{formatMoney(item.productValue)}</TableCell>
            <TableCell className="p-0 pl-4 border-r border-r-base-300 sm:min-w-[150px]">
                <Text>{formatNumberToPTBR(item.quantityInStock || 0)}</Text>
            </TableCell>
            <TableCell className="p-0 border-r border-r-base-300 w-44 sm:min-w-[150px]">
                <Controller
                    control={control}
                    name={getPath('quantity')}
                    render={({ field }) => {
                        return (
                            <CurrencyInput
                                value={field.value}
                                placeholder="Ex: 10"
                                rightClasses="bg-base-200 px-4"
                                error={quantityError}
                                showErrorMessage={false}
                                inputWrapperClasses="border-transparent rounded-none"
                                className={`focus:outline-2 -outline-offset-2 outline bg-transparent ${!!quantityError ? 'text-system-danger-500 outline-system-danger-100' : 'outline-primary-100'}`}
                                onValueChange={({ floatValue = 0 }) => field.onChange(floatValue)}
                            />
                        );
                    }}
                />
            </TableCell>
            <TableCell className="p-0 pl-4 border-r border-r-base-300 w-44 sm:min-w-[200px]">{formatMoney(value * quantity)}</TableCell>
            <TableCell className="p-0 px-4">
                <RemoveButton size="medium" className="!static opacity-100 mt-0" onClick={handleRemove} />
            </TableCell>
        </TableRow>
    );
};

export default StockRefundProductRow;
