import Modal from 'components/core/modal';
import Spinner from 'components/core/spinner';
import Text from 'components/core/text';
import Empty from 'components/empty';
import useGetProductStocks from 'services/queries/stocks/use-get-products-stocks';
import ModalProductStocksTable from './table';

type ModalProductStocksProps = {
    productId: number;
    onClose: () => void;
};

const ModalProductStocks = ({ productId, onClose }: ModalProductStocksProps) => {
    const { data, isLoading } = useGetProductStocks(productId.toString());

    return (
        <Modal
            contentClassnames="w-[800px]"
            headerLeft={
                !isLoading ? (
                    <Text as="h3" variant="h4" className="text-heading text-xl">
                        {data?.name} - Estoque
                    </Text>
                ) : undefined
            }
            headerClassNames="border-b border-gray-200"
            closeOnClickOutside={false}
            onClose={onClose}>
            {isLoading ? (
                <Spinner fixed={false} parentClasses="p-6" />
            ) : (
                <div className="p-6">
                    <div className="rounded-[14px] overflow-auto max-h-80 border border-base-300">
                        {!!data?.stocks?.length ? <ModalProductStocksTable stocks={data.stocks} /> : <Empty className="p-4" title="Não há estoque para este produto." />}
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default ModalProductStocks;
