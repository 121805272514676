import { ColorVariant } from 'types/colors';
import { PurchaseStatus } from 'types/models/purchase-order';

export const purchaseStatusesConfig = {
    [PurchaseStatus.Canceled]: {
        label: 'Cancelada',
        variant: 'error' as ColorVariant
    },
    [PurchaseStatus.Finished]: {
        label: 'Finalizada',
        variant: 'success' as ColorVariant
    },
    [PurchaseStatus.Open]: {
        label: 'Aberta',
        variant: 'info' as ColorVariant
    },
    [PurchaseStatus.Processed]: {
        label: 'Processada',
        variant: 'warning' as ColorVariant
    }
};
