import { Status } from 'types/general';
import { Address } from './address';
import { BaseModel } from './base';
import { Classification } from './classification';
import { File as FileType } from './file';
import { Person } from './person';
import { Product } from './product';
import { SaleOrder } from './sale-order';
import { Transaction } from './transaction';
import { Receipt } from './receipt';
import { ExpenseProduct } from './expense';
import { PurchaseOrder, PurchaseProductStatus } from './purchase-order';
import { StockOrder, StockProduct } from './stock';
import { InventoryMovement } from './inventory-movements';

export type OrderService = {
    saleOrderId: number;
    orderServiceStatusId: number;
    personEngineerId: number;
    personContractorId: number;
    classificationTypeId: number;
    code: string;
    title: string;
    approved: boolean;
    startDate: string;
    competenceDate: string;
    endDate: string;
    completionDate: string;
    observations: string;
    addressOrderServices: any[]; // TODO: typo
    orderServiceDocuments: any[]; // TODO: typo
    orderServiceProductPurchases: Partial<OrderServiceProductPurchase>[];
    purchaseProducts: Partial<OrderServiceProductPurchase>[];
    orderServiceProductSales: any[]; // TODO: typo
    saleProducts: Partial<OrderServiceProductSale>[];
    receiptOriginSaleOrders: any[]; // TODO: typo
    classification: Partial<Classification>;
    orderServiceStatus: Partial<OrderServiceStatus>;
    person: Partial<Person>;
    contractor: Partial<Person>;
    engineer: Partial<Person>;
    personByPersonContractorId: Partial<Person>;
    saleOrder: Partial<SaleOrder>;
    address: Partial<Address>;
    receipt: Partial<Receipt>;
    tax: number;
    commission: number;
} & BaseModel;

export type OrderServiceProductPurchase = {
    orderServiceId: number;
    productId: number;
    quantity: number;
    value: number;
    provision: string;
    balance: number;
    approved: boolean;
    justification: string | null;
    orderService: Partial<OrderService>;
    product: Partial<Product>;
} & BaseModel;

export type OrderServiceProductSale = {
    predictedEndDate: string;
    predictedStartDate: string;
    value: string;
    quantity: number;
    balance: number;
    product: Partial<Product>;
} & BaseModel;

export type OrderServiceStatus = {
    title: string;
    slug: OrderServiceStatusEnum;
} & BaseModel;

export type OrderServiceDeduction = {
    orderServiceId: number;
    transactionId: number;
    value: number;
    deducted: boolean;
    invoiceSent: boolean;
    orderService: Partial<OrderService>;
    transaction: Partial<Transaction>;
} & BaseModel;

export type OrderServiceWorkReport = {
    title: string;
    description: string;
    files?: FileType[];
    file: Partial<FileType>;
    status: Status;
} & BaseModel;

export type OrderServiceWorkReportPayload = Pick<OrderServiceWorkReport, 'title' | 'description'> & {
    files: Array<{
        file?: number;
        name?: string;
        localFile?: File;
    }>;
};

export enum PurchaseScopeActions {
    Approve = 'approve',
    Reprove = 'reprove',
    Delete = 'delete',
    New = 'new',
}

export enum OrderServiceStatusEnum {
    AwaitingPurchaseProducts = 'awaiting-purchaseproducts',
    AwaitingRelease = 'awaiting-release',
    InProductValidation = 'in-product-validation',
    AwaitingProductApproval = 'awaiting-product-approval',
    NotStarted = 'not-started',
    Started = 'started',
    WaitingForCompletion = 'waiting-for-completion',
    Finished = 'finished',
    Canceled = 'canceled',
    Stopped = 'stopped',
}

export type OrderServiceFinancialBalance = {
    expenses: ExpenseProduct[];
    product: Partial<Product>;
    purchaseOrders: Array<
        PurchaseOrder & {
            purchaseProductStatus?: PurchaseProductStatus;
        }
    >;
    stockProduct: {
        movements: Array<
            Pick<InventoryMovement, 'createdAt' | 'value' | 'type' | 'quantity' | 'reversal'> & {
                order: Partial<StockOrder>[];
            }
        >;
    } & Pick<StockProduct, 'id' | 'quantity' | 'value'>;
    summary: {
        budget: number;
        expenseBalance: number;
        expensePercentageBalance: number;
        quantity: number;
        refund: number;
        totalExpense: number;
        totalExpenseOpen: number;
        totalExpensePaid: number;
        value: number;
    };
};

export type OrderServiceMovements = {
    created: string;
    id: number;
    observation: string | null;
    orderServiceStatus: OrderServiceStatus;
    person: Partial<Person> | null;
    title: string | null;
};
