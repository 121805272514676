import Badge from 'components/core/badge';
import { CrudPageProps } from 'types/graphql';
import { TransactionInvoiceStatus } from 'types/models/transaction';
import { formatMoney } from 'utils/money';

export const getDeductionsListConfig = (saleOrderId: number): CrudPageProps<{}> => ({
    title: 'Deduções',
    graphql: {
        table: 'orderServiceDeduction',
        searchableField: 'orderService.title',
        statusPath: 'status',
        columns: [
            {
                name: 'id',
                options: { display: 'excluded' },
            },
            {
                name: 'orderService.title',
                label: 'Ordem de serviço',
            },
            {
                name: 'transaction.code',
                label: 'Ordem de pagamento',
            },
            {
                name: 'transaction.paid',
                label: 'Paga',
                options: {
                    customBodyRender: (value) => <Badge variant={Boolean(value) ? 'success' : 'error'}>{Boolean(value) ? 'Pagamento confirmado' : 'Aguardando pagamento'}</Badge>,
                },
            },
            {
                name: 'transaction.invoiceStatus',
                label: 'Com nota fiscal',
                options: {
                    customBodyRender: (value) => {
                        const isSent = value === TransactionInvoiceStatus.Sent;

                        return (
                            <div className="flex items-center">
                                <Badge variant={isSent ? 'success' : 'error'}>{isSent ? 'Sim' : 'Não'}</Badge>
                            </div>
                        );
                    },
                },
            },
            {
                name: 'deducted',
                label: 'Foi deduzido',
                options: {
                    customBodyRender: (value) => <Badge variant={Boolean(value) ? 'success' : 'error'}>{Boolean(value) ? 'Sim' : 'Não'}</Badge>,
                },
            },
            {
                name: 'invoiceSent',
                label: 'Foi enviado NC',
                options: {
                    customBodyRender: (value) => <Badge variant={Boolean(value) ? 'success' : 'error'}>{Boolean(value) ? 'Sim' : 'Não'}</Badge>,
                },
            },
            {
                name: 'value',
                label: 'Valor',
                options: { customBodyRender: (value) => formatMoney(value) },
            },
        ],
        where: {
            orderService: { saleOrderId: { _eq: saleOrderId } },
        },
    },
});
