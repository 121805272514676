import * as React from 'react';
const SvgIcoDots = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 4 20" width="1em" height="1em" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M2 15.85a1.95 1.95 0 1 1 0 3.9 1.95 1.95 0 0 1 0-3.9Zm0-7.8a1.95 1.95 0 1 1 0 3.9 1.95 1.95 0 0 1 0-3.9ZM3.95 2.2a1.95 1.95 0 1 0-3.9 0 1.95 1.95 0 0 0 3.9 0Z"
            clipRule="evenodd"
        />
    </svg>
);
export default SvgIcoDots;
