import * as React from 'react';
const SvgIcoFilter = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" width="1em" height="1em" {...props}>
        <path
            fill="currentColor"
            d="M2.75 12.515a.757.757 0 0 0-.75.763c0 .422.336.763.75.763h5.639c.414 0 .75-.341.75-.763a.757.757 0 0 0-.75-.763H2.75Zm14.5 1.526h-4.89v1.696a.757.757 0 0 1-.75.763.757.757 0 0 1-.75-.763v-2.435a.962.962 0 0 1 0-.048V10.82c0-.422.337-.764.75-.764.415 0 .75.342.75.764v1.695h4.89c.413 0 .75.342.75.763a.757.757 0 0 1-.75.763Zm-8-7.32c0-.42.336-.762.75-.762h7.25c.414 0 .75.341.75.763a.757.757 0 0 1-.75.763H10a.757.757 0 0 1-.75-.763Zm-6.5-.762a.757.757 0 0 0-.75.763c0 .421.336.763.75.763h3.277V9.18c0 .422.336.763.75.763.415 0 .75-.341.75-.763V6.748a.78.78 0 0 0 0-.052V4.263a.757.757 0 0 0-.75-.763.757.757 0 0 0-.75.763v1.696H2.75Z"
        />
    </svg>
);
export default SvgIcoFilter;
