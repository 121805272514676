import React, { createContext, useContext, useMemo, useState } from 'react';
import IconButton from '@mui/material/IconButton/IconButton';
import theme from 'settings/theme';
import MuiMenu from '@mui/material/Menu/Menu';
import { menuConfig } from 'components/menu';
import _set from 'lodash/set';
import classNames from 'classnames';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import SvgIcoFilter from 'components/core/icon/files/ico-filter';

type TableAdvancedSearchProps = {
    component: React.ReactNode;
    hasSelectedFilters?: boolean;
    buttonClassName?: string;
};

const TableAdvancedSearch = ({ component, hasSelectedFilters, buttonClassName }: TableAdvancedSearchProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleClose = () => setAnchorEl(null);
    const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

    const config = useMemo(() => {
        const defaultConfig = menuConfig;

        // Best way to edit nested items without messing the original object
        _set(defaultConfig, 'PaperProps.style.marginTop', 7);
        _set(defaultConfig, 'PaperProps.style.width', 400);

        return defaultConfig;
    }, []);

    return (
        <>
            <Tooltip title="Filtrar">
                <IconButton
                    onClick={handleOpen}
                    className={classNames('bg-base-200 p-0 w-[40px] h-[40px] absolute right-[4px] top-[4px] rounded-[14px]', buttonClassName)}
                    size="large"
                    color="default">
                    {hasSelectedFilters && <div className="w-2 h-2 absolute top-[2px] right-[2px] bg-system-danger-500 rounded-full" />}
                    <SvgIcoFilter color={theme.extend.colors.heading} width={20} height={20} />
                </IconButton>
            </Tooltip>

            <MuiMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} {...config}>
                <div className="py-5 px-6">
                    <p className="text-heading text-2xl font-medium mb-5">Filtro</p>
                    <TableAdvancedSearchContext.Provider value={{ onClose: handleClose }}>{component}</TableAdvancedSearchContext.Provider>
                </div>
            </MuiMenu>
        </>
    );
};

const TableAdvancedSearchContext = createContext({ onClose: () => {} });

export const useTableAdvancedSearch = () => {
    return useContext(TableAdvancedSearchContext);
};

export default TableAdvancedSearch;
