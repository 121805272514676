import { gql, GraphQLClient } from 'graphql-request';
import { RequestConfig } from 'graphql-request/build/esm/types';
import { router } from 'index';
import api from 'services/api';
import { store } from 'store';
import { Creators as AuthActions } from 'store/auth';

const logout = () => {
    // Clear store
    store.dispatch(AuthActions.logout());
    // Remove token from axios header
    delete api.defaults.headers.common.Authorization;
    // Redirect to login page
    router.navigate('/');
};

const apiGraphQl = async <T>(query: string, variables?: any) => {
    const reduxState = store.getState();

    if (!reduxState.auth.isAuthenticated) {
        logout();
    }

    const queryName = query.match(/(?<=\bquery\s)(\w+)/g);

    const { REACT_APP_GRAPHQL_API_URL, REACT_APP_GRAPHQL_API_URL_VERSION } = process.env;
    const endpoint = `${REACT_APP_GRAPHQL_API_URL! + REACT_APP_GRAPHQL_API_URL_VERSION!}/graphql?n=${queryName || ''}`;

    const options: RequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${reduxState.auth.token}`,
        },
        method: 'POST',
    };

    const client = new GraphQLClient(endpoint, options);

    // prettier-ignore
    return client.request<T>(gql`${query}`, variables);
};

export default apiGraphQl;
