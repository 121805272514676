import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { useOutletContext, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import FormButtons from 'components/form-buttons';
import Editor from 'components/core/form/editor';
import useUpdateScheduleStatus from 'services/queries/schedules/use-update-schedule-status';
import { useEffect } from 'react';
import { schedulesWithObservationInput, scheduleUpdateStatusSchema, validScheduleStatusType } from '../utils';
import { SaleOrderScheduleStatus, saleOrderScheduleStatus } from 'types/models/sale-order';
import Select from 'components/core/form/select';
import useGetSaleOrderScheduleDaysOptions from 'services/queries/sales-order/use-get-sale-order-schedules-days-options';
import useGetSaleOrderScheduleHoursOptions from 'services/queries/sales-order/use-get-sale-order-schedules-hours-options';
import { ScheduleUpdateStatusPayload } from 'types/models/schedule';
import { yupResolver } from '@hookform/resolvers/yup';
import useNavigateToParentRoute from 'hooks/router/use-navigate-to-parent-route';

const UpdateScheduleStatus = () => {
    const { scheduleId, statusType } = useParams();
    const goToParentRoute = useNavigateToParentRoute();

    const { queryFilters } = useOutletContext<{ queryFilters: string }>();

    const isWithdrawn = +statusType! === SaleOrderScheduleStatus.Delivered;
    const isRescheduling = +statusType! === SaleOrderScheduleStatus.Rescheduled;
    const hasObservation = schedulesWithObservationInput.includes(+statusType!);

    const { handleSubmit, control, formState, watch, setValue } = useForm<ScheduleUpdateStatusPayload>({
        mode: 'onSubmit',
        resolver: yupResolver(scheduleUpdateStatusSchema(+statusType!)) as any,
    });

    const { mutateAsync: updateScheduleStatus, isLoading: isUpdatingScheduleStatus } = useUpdateScheduleStatus(scheduleId, queryFilters);

    const scheduleDay = watch('day');

    const { data: scheduleDaysOptions = [] } = useGetSaleOrderScheduleDaysOptions();
    const { data: scheduleHoursOptions = [] } = useGetSaleOrderScheduleHoursOptions(scheduleDay);

    const submit = async (data: ScheduleUpdateStatusPayload) => {
        try {
            const payload = {
                status: +statusType!,
                ...(Boolean(data?.observations) && { observations: data.observations }),
                ...(Boolean(data?.day) && { day: data.day }),
            };

            await updateScheduleStatus(payload);
        } catch (e) {
            console.error('Ocorreu um erro. Por favor, tente novamente!', e);
        }
    };

    useEffect(() => {
        const isValidScheduleStatus = validScheduleStatusType.includes(+statusType!);

        if (!isValidScheduleStatus || !Boolean(scheduleId)) {
            goToParentRoute();
        }
    }, [scheduleId, statusType, goToParentRoute]);

    return (
        <Modal
            contentClassnames="w-[800px]"
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    {saleOrderScheduleStatus[statusType!]?.title || '-'}
                </Text>
            }
            closeOnClickOutside={false}>
            <form className="px-6 pb-6" onSubmit={handleSubmit(submit)}>
                {hasObservation && (
                    <Controller
                        name="observations"
                        control={control}
                        render={({ field }) => <Editor parentClasses="my-4" {...field} error={formState.errors?.observations?.message} label={isWithdrawn ? 'Observações' : 'Motivo'} />}
                    />
                )}
                {isRescheduling && (
                    <div className="my-4">
                        <Controller
                            name="day"
                            control={control}
                            render={({ field }) => {
                                const value = scheduleDaysOptions.find((item) => +item.value === +field.value!);

                                return (
                                    <Select
                                        {...field}
                                        parentClassName="mb-4"
                                        error={formState.errors.day?.message}
                                        label="Data do Agendamento"
                                        value={value}
                                        options={scheduleDaysOptions || []}
                                        onChange={(option: any) => {
                                            field.onChange(option?.value);
                                            setValue('hour', '');
                                        }}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="hour"
                            control={control}
                            render={({ field }) => {
                                const value = scheduleHoursOptions.find((item) => +item.value === +field?.value!);
                                return (
                                    <Select
                                        {...field}
                                        parentClassName="mb-4"
                                        error={formState.errors.hour?.message}
                                        label="Hora do Agendamento"
                                        value={value}
                                        options={scheduleHoursOptions || []}
                                        onChange={(option: any) => field.onChange(option?.value)}
                                    />
                                );
                            }}
                        />
                    </div>
                )}
                <FormButtons isLoading={isUpdatingScheduleStatus} />
            </form>
        </Modal>
    );
};

export default UpdateScheduleStatus;
